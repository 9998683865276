import styled from 'styled-components';

// 공통
export const Wrapper = styled.div`
  @media screen and (max-width: 768px) {
    table {
      flex-wrap: wrap;
      thead,
      tbody,
      td,
      th {
        width: 100%;
        box-sizing: border-box;
      }
      thead {
        background: ${(props) => props.theme.colors.gray100};
        color: ${(props) => props.theme.colors.txt500};
      }
      tbody {
        input {
          width: 100%;
        }
      }
    }
  }
`;

// 테이블을 감싸는 컨테이너
export const AWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0rem 0 4rem 0;
  gap: 2rem;
`;
// 테이블 요소
export const AContents = styled.table`
  border-top: 2px solid ${(props) => props.theme.colors.gray400};
  background-color: transparent;
`;
// 테이블 헤더
export const AContentsHead = styled.thead`
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
`;
// 테이블 바디
export const AContentsBody = styled.tbody``;
// 테이블 행
export const AContentsRow = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;
// 테이블 헤더 셀
export const AContentsHeadCell = styled.th`
  width: 20%;
  padding: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  font-size: 1.4rem;
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
  @media screen and (max-width: 768px) {
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.txt500};
  }
`;
// 테이블 바디 셀
export const AContentsBodyCell = styled.td`
  width: 100% - 0.5rem;
  padding: 1.25rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  font-size: 1.3rem;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;
// 테이블을 감싸는 컨테이너
export const BWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0rem 0 4rem 0;
  gap: 2rem;
  /* tbody {
    td {
      display: flex;
    }
  } */
`;
// 테이블 요소
export const BContents = styled.table`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.gray400};
  background-color: transparent;
`;
// 테이블 헤더
export const BContentsHead = styled.thead`
  width: 20%;
  display: flex;
`;
// 테이블 바디
export const BContentsBody = styled.tbody``;
// 테이블 행
export const BContentsRow = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;
// 테이블 헤더 셀
export const BContentsHeadCell = styled.td`
  width: 20%;
  padding: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
  font-size: 1.4rem;
  text-align: center;
  @media screen and (max-width: 768px) {
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.txt500};
  }
`;
// 테이블 바디 셀
export const BContentsBodyCell = styled.td`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.85rem 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  font-size: 1.3rem;
`;
// 버튼 컨테이너
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

// error
export const ValidationError = styled.span`
  font-size: 1.2rem;
  color: red;
  margin-left: 10px;
`;

export const ValidationMessage = styled.span`
  font-size: 1.2rem;
  color: gray;
  margin-left: 10px;
`;
