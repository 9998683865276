import * as SS from '@/styles/selectBox.styles';
import React, { useRef, useState } from 'react';
import BaseButton from '../../../../components/Atom/BaseButton';
import { useAddTable } from '../../../../components/hooks/customs/addressBook/useAddTable';
import * as S from './tableDataAdd.styled';

interface IAddTableData {
  setTableAddOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddTableData = ({ setTableAddOpen }: IAddTableData) => {
  const {
    register,
    handleSubmit,
    onAddTableHandler,
    selectArrValue,
    setSelectSwitch,
    selectSwitch,
    selectState,
    onClickSelectListButton,
    layoutRef,
  } = useAddTable({ propSetTableAddOpen: setTableAddOpen });

  /*  */
  const [byHeight, setByHeight] = useState<string | number>('auto');
  const selectRef = useRef<HTMLUListElement>(null);
  return (
    <S.AddTalbeComponent className={`${selectSwitch ? 'active' : null}`}>
      {/* Select / input / Fun Button Wrap */}
      <form style={{ paddingBottom: byHeight }} action="/" method="POST">
        <S.AddTalbeGroup>
          <div className="inputwrap">
            {/* 그룹 Select */}
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path
                  d="M16.0288 7.82507C16.0288 7.99083 15.963 8.14981 15.8458 8.26702C15.7285 8.38423 15.5696 8.45007 15.4038 8.45007H9.15381V14.7001C9.15381 14.8658 9.08796 15.0248 8.97075 15.142C8.85354 15.2592 8.69457 15.3251 8.52881 15.3251C8.36305 15.3251 8.20408 15.2592 8.08687 15.142C7.96966 15.0248 7.90381 14.8658 7.90381 14.7001V8.45007H1.65381C1.48805 8.45007 1.32908 8.38423 1.21187 8.26702C1.09466 8.14981 1.02881 7.99083 1.02881 7.82507C1.02881 7.65931 1.09466 7.50034 1.21187 7.38313C1.32908 7.26592 1.48805 7.20007 1.65381 7.20007H7.90381V0.950073C7.90381 0.784313 7.96966 0.625342 8.08687 0.508132C8.20408 0.390921 8.36305 0.325073 8.52881 0.325073C8.69457 0.325073 8.85354 0.390921 8.97075 0.508132C9.08796 0.625342 9.15381 0.784313 9.15381 0.950073V7.20007H15.4038C15.5696 7.20007 15.7285 7.26592 15.8458 7.38313C15.963 7.50034 16.0288 7.65931 16.0288 7.82507Z"
                  strokeWidth="0.5"
                />
              </svg>
            </p>
            <SS.SelectBoxMax ref={layoutRef} className={`selBox ${selectSwitch ? 'active' : null}`}>
              <button
                className="txtMax"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectSwitch(!selectSwitch);
                }}
              >
                <p>{selectState.groupNm}</p>
                <svg
                  className={`switcharrow ${selectSwitch ? 'active' : null}`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="6"
                  viewBox="0 0 14 6"
                  fill="none"
                >
                  <path
                    d="M12.9144 0.156925L7.44885 4.76826C7.32959 4.8694 7.16862 4.92613 7.00087 4.92613C6.83312 4.92613 6.67214 4.8694 6.55289 4.76826L1.08615 0.157925C0.965866 0.057316 0.804471 0.00100048 0.636419 0.00100048C0.468366 0.00100048 0.306971 0.057316 0.18669 0.157925C0.127602 0.207306 0.0806439 0.266326 0.0485811 0.331507C0.0165183 0.396689 0 0.466713 0 0.537453C0 0.608192 0.0165183 0.678216 0.0485811 0.743398C0.0806439 0.80858 0.127602 0.8676 0.18669 0.91698L5.6511 5.52732C6.01102 5.83027 6.49529 6 6.9997 6C7.50412 6 7.98838 5.83027 8.3483 5.52732L13.8127 0.91698C13.872 0.867584 13.9191 0.808502 13.9513 0.743228C13.9834 0.677954 14 0.607813 14 0.536953C14 0.466093 13.9834 0.395951 13.9513 0.330677C13.9191 0.265403 13.872 0.206321 13.8127 0.156925C13.6924 0.056316 13.531 0 13.363 0C13.1949 0 13.0335 0.056316 12.9133 0.156925"
                    fill="currentColor"
                  />
                </svg>
              </button>
              {selectSwitch ? (
                <ul ref={selectRef}>
                  <div>
                    {selectArrValue.map((item) => {
                      return (
                        <li key={item.id}>
                          <button
                            type="button"
                            onClick={() => {
                              onClickSelectListButton(item);
                              setSelectSwitch(false);
                            }}
                          >
                            {item.koname}
                          </button>
                        </li>
                      );
                    })}
                  </div>
                </ul>
              ) : null}
            </SS.SelectBoxMax>
            <input type="text" placeholder="이름" {...register('buddyNm')} />
            <input type="text" placeholder="연락처" {...register('keyCommNo')} />
            <input type="text" placeholder="이메일" {...register('emailId')} />
            <input type="text" placeholder="메모" {...register('etcInfo')} />
          </div>
          <div className="btnGroup">
            <BaseButton className="btn delBtn" type="button" onClick={() => setTableAddOpen(false)}>
              취소
            </BaseButton>
            <BaseButton className="btn addBtn" type="submit" onClick={handleSubmit(onAddTableHandler)}>
              저장
            </BaseButton>
          </div>
        </S.AddTalbeGroup>
      </form>
    </S.AddTalbeComponent>
  );
};

export default AddTableData;
