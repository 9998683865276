import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.5rem;
  height: 1.6rem;
`;

const IconDownload: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, width, height, fill }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      width={width ?? '15'}
      height={height ?? '16'}
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M7.5 12.0454L3.70821 8.25468L4.46679 7.48325L6.96429 9.98075V0.712891H8.03571V9.98075L10.5321 7.48432L11.2918 8.25468L7.5 12.0454ZM1.73143 15.7129C1.23786 15.7129 0.826071 15.5479 0.496071 15.2179C0.166071 14.8879 0.000714286 14.4757 0 13.9815V11.3854H1.07143V13.9815C1.07143 14.1465 1.14 14.2979 1.27714 14.4357C1.41429 14.5736 1.56536 14.6422 1.73036 14.6415H13.2696C13.4339 14.6415 13.585 14.5729 13.7229 14.4357C13.8607 14.2986 13.9293 14.1472 13.9286 13.9815V11.3854H15V13.9815C15 14.475 14.835 14.8868 14.505 15.2168C14.175 15.5468 13.7629 15.7122 13.2686 15.7129H1.73143Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconDownload;
