import * as S from './addressHeadMenu.styles';
import React from 'react';
import { Link } from 'react-router-dom';
import AddressBookFunBtn from 'src/pages/main/components/addressLeftZone/addressHeadMenu/addAddressBook/AddressBookFunBtn';
import AddressSearch from '@/pages/main/components/addressLeftZone/addressHeadMenu/addressSearch/AddressSearch';
import Logo from '@/components/Atom/Logo';
import IconClose from '@/components/Atom/icon/IconClose';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mobileBuddyListRecoil, windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { activeGroupRecoil } from '@/recoil/atoms/softPhone';
/**
 * @title AddressHeadMenu.tsx 주소록 상단 버튼 컴포넌트
 *
 * @author 정휘학
 * @since 2024.03.14
 *
 * @new 임혜원 addressHeadGroup  < 폴더 복사본
 *
 * */

const AddressHeadMenu = () => {
  const setMobileBuddyListState = useSetRecoilState(mobileBuddyListRecoil);
  const activeGroupS = useRecoilValue(activeGroupRecoil);
  const windowWidth = useRecoilValue(windowWidthRecoil);

  return (
    <S.AddressHeadMenu className="addressHeadMenu">
      {/* 기본 주소록 컴포넌트의 상단 버튼 컴포넌트 */}
      <div className="responsiveTab">
        {/* 로고 */}
        <button
          className="back"
          onClick={() => {
            setMobileBuddyListState(false);
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="22" viewBox="0 0 6 15" fill="none">
            <path
              d="M0.141703 8.30149L5.23331 14.7787C5.31827 14.8868 5.43114 14.9471 5.54851 14.9471C5.66588 14.9471 5.77875 14.8868 5.86371 14.7787L5.86919 14.7714C5.91052 14.719 5.94343 14.6559 5.96592 14.5859C5.9884 14.516 6 14.4407 6 14.3646C6 14.2885 5.9884 14.2131 5.96592 14.1432C5.94343 14.0733 5.91052 14.0102 5.86919 13.9578L1.07451 7.85869L5.86919 1.76207C5.91052 1.70965 5.94343 1.64655 5.96592 1.57662C5.9884 1.50668 6 1.43137 6 1.35526C6 1.27915 5.9884 1.20384 5.96592 1.13391C5.94343 1.06397 5.91052 1.00087 5.86919 0.948455L5.86371 0.941135C5.77875 0.833005 5.66588 0.772684 5.54851 0.772684C5.43114 0.772684 5.31827 0.833005 5.23331 0.941135L0.141703 7.41834C0.0969239 7.4753 0.0612752 7.54381 0.0369174 7.61972C0.0125596 7.69562 -3.13402e-07 7.77734 -3.09793e-07 7.85991C-3.06183e-07 7.94249 0.0125596 8.02421 0.0369174 8.10011C0.0612752 8.17602 0.096924 8.24453 0.141703 8.30149Z"
              fill="#8B8B8B"
            />
          </svg>
        </button>
        {windowWidth > 768 ? (
          <Logo eType="Reload" sType="B" />
        ) : (
          <h2>{activeGroupS?.groupNm ? activeGroupS?.groupNm : '전체 그룹'}</h2>
        )}
      </div>
      <div className="searchGroup">
        {/* 검색창 */}
        <AddressSearch />
        {/* 주소록 기능 버튼 */}
        <AddressBookFunBtn />
      </div>
    </S.AddressHeadMenu>
  );
};

export default AddressHeadMenu;
