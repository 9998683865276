/* eslint-disable */

import React, { useEffect } from 'react';

import { useRecycleBin } from '@/components/hooks/customs/addressBook/recycleBin/useRecycleBin';
import { useAddTable } from '@/components/hooks/customs/addressBook/useAddTable';
import { useAddressSelect } from '@/components/hooks/customs/addressBook/useAddressSelect';
import { useDeleteGroup } from '@/components/hooks/customs/addressBook/useDeleteGroup';
import { useDeleteTableList } from '@/components/hooks/customs/addressBook/useDeleteTableList';
import { useGroupList } from '@/components/hooks/customs/newAddressBook/useGroupList';
import AddressBookTable from '@/pages/main/components/addressLeftZone/addressBookTable/AddressBookTable';
import AddressHeadMenu from '@/pages/main/components/addressLeftZone/addressHeadMenu/addressHeadMenu';
import GroupList from '@/pages/main/components/addressLeftZone/groupList/GroupList';
import TrashBoxPopup from '@/pages/main/components/modal/trashBox/TrashBoxPopup';
import { addressOpenToggleRecoil } from '@/recoil/atoms/messageSend/messageSend';
import {
  listToggleRecoil,
  mobileBuddyListRecoil,
  mobileNavRecoil,
  windowWidthRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as S from './AddressLeftZone.styles';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @title AddressLeftZone.tsx 주소록 관련 좌측 컴포넌트
 *
 * @author 정휘학, 김남규
 * @since 2024.03.14
 *
 * */
const AddressLeftZone = () => {
  const [listToggleState] = useRecoilState(listToggleRecoil);
  const [addressOpenToggleState] = useRecoilState(addressOpenToggleRecoil);
  // Hooks
  const selectHooks = useAddressSelect();
  const newGroupListHooks = useGroupList();
  const deleteGroupHooks = useDeleteGroup();
  const reCycleBinHooks = useRecycleBin();
  const addTableHooks = useAddTable({});
  const deleteTableListHooks = useDeleteTableList();

  const mobileBuddyListState = useRecoilValue(mobileBuddyListRecoil);
  const mobileNavState = useRecoilValue(mobileNavRecoil);

  const mainTableCheckDataFormatS = useRecoilValue(mainTableCheckDataFormatRecoil);

  const setMobileBuddyListState = useSetRecoilState(mobileBuddyListRecoil);

  const navigate = useNavigate();

  // 현재 경로 가져오기
  const location = useLocation();

  // 반응형 넓이 가져오는 RECOIL 768이하 부터 반응형
  const windowWidth = useRecoilValue(windowWidthRecoil);

  // useEffect(() => {
  //   if (!(windowWidth > 768)) {
  //     const handleBackButton = (e: any) => {
  //       e.preventDefault();
  //       // 여기에 뒤로가기 버튼 클릭 시 실행할 동작 작성
  //       setMobileBuddyListState(false);
  //     };

  //     // popstate 이벤트를 감지하여 뒤로가기 동작 제어
  //     window.addEventListener('popstate', handleBackButton);

  //     // 컴포넌트 언마운트 시 이벤트 제거
  //     return () => {
  //       window.removeEventListener('popstate', handleBackButton);
  //     };
  //   }
  // }, []);

  return (
    <>
      {reCycleBinHooks.recycleBinOpen && (
        /* 좌측 그룹 리스트 휴지통 컴포넌트 */
        <TrashBoxPopup
          binAllBtnState={reCycleBinHooks.binAllBtnState}
          recycleBinClickHandle={reCycleBinHooks.recycleBinClickHandle}
          groupList={newGroupListHooks.groupList}
          recycleBinOpen={reCycleBinHooks.recycleBinOpen}
          deleteBinAllListOnClick={reCycleBinHooks.deleteBinAllListOnClick}
          restoreBinAllListOnClick={reCycleBinHooks.restoreBinAllListOnClick}
        />
      )}
      <S.AddressLeftZone
        style={{
          opacity: !listToggleState || addressOpenToggleState ? '1' : '0',
          top: !listToggleState || addressOpenToggleState ? '0' : '-400%',
          left: !listToggleState || addressOpenToggleState ? '' : '-200%',
          order: !listToggleState || addressOpenToggleState ? '1' : '2',
          height: !listToggleState || addressOpenToggleState ? '' : '0',
        }}
      >
        <S.HeadAndtMiddle className="1">
          {/* end head 링크 / 버튼 영역 */}
          <S.AddressLeftMiddle className="addressLeftMiddle">
            {/* 반응형 태블릿 부터 나올 영역 */}
            {/* <ResLeftBtnZone
              addGroupOpenHandle={newGroupListHooks.addGroupOpenHandle}
              deleteGroupOnClickHandler={deleteGroupHooks.deleteGroupOnClickHandler}
              onDeleteTableList={deleteTableListHooks.onDeleteTableList}
              tableAddOpenHandle={addTableHooks.tableAddOpenHandle}
              delGroupState={deleteGroupHooks.delGroupState}
              toggleCopySelect={selectHooks.toggleCopySelect}
              setToggleCopySelect={selectHooks.setToggleCopySelect}
            /> */}
            {/* 전체 그룹 영역 */}
            <GroupList
              clickStyle={newGroupListHooks.clickStyle}
              childrenClickStyle={newGroupListHooks.childrenClickStyle}
              groupList={newGroupListHooks.groupList}
              groupListIsLoading={newGroupListHooks.groupListIsLoading}
              setCheckedGroup={newGroupListHooks.setCheckedGroup}
              addGroupOpenHandle={newGroupListHooks.addGroupOpenHandle}
              addGroupOpen={newGroupListHooks.addGroupOpen}
              deleteBinAllListOnClick={reCycleBinHooks.deleteBinAllListOnClick}
              recycleBinClickHandle={reCycleBinHooks.recycleBinClickHandle}
              setGroupClickStyles={newGroupListHooks.setGroupClickStyles}
              clickGroupStyles={newGroupListHooks.clickGroupStyles}
              recycleBinOpen={reCycleBinHooks.recycleBinOpen}
              binAllBtnState={reCycleBinHooks.binAllBtnState}
              delLoadingS={deleteGroupHooks.delLoadingS}
              postAdressBinAllListIsLoading={reCycleBinHooks.postAdressBinAllListIsLoading}
              setOpenLargeToggle={addTableHooks.setOpenLargeToggle}
              resTableSwitchS={newGroupListHooks.resTableSwitchS}
              onClickGroupComponentToggle={newGroupListHooks.onClickGroupComponentToggle}
            />
            {/* end 전체 그룹 영역 */}

            <S.AddressTable
              className="addressTable"
              isActive={mobileBuddyListState === true}
              mobileNavState={mobileNavState}
            >
              <AddressHeadMenu />
              {/* 주소록 메인 테이블 */}
              <AddressBookTable />
              {/* Start 대량추가 테스트 */}
            </S.AddressTable>

            {/* End 대량추가 테스트 */}
            {/* end 테이블 영역 */}
          </S.AddressLeftMiddle>
        </S.HeadAndtMiddle>
      </S.AddressLeftZone>
    </>
  );
};

export default AddressLeftZone;
