import React from 'react';
import styled from 'styled-components';

const CustomCheckbox = styled.input`
  /* 체크되지 않은 상태의 스타일 */
  cursor: pointer;
  position: relative;
  appearance: none;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 50rem;
  border: 0.2rem solid ${(props) => props.theme.colors.buttonDisabledBg};
  outline: none; /* 포커스 효과 제거 */
  margin: 0;

  /* 체크된 상태의 스타일 */
  &:checked {
    border-color: ${(props) => props.theme.colors.main};
  }

  /* 체크 표시 제거 */
  &:after {
    content: ''; /* 가상 요소 생성 */
    display: block;
    width: 0.5rem;
    height: 0.9rem;
    border: solid transparent;
    border-width: 0 0.2rem 0.2rem 0; /* 체크 모양 생성 */
    transform: rotate(45deg);
    position: absolute;
    top: 0.25rem;
    left: 0.6rem;
    border-color: ${(props) => props.theme.colors.buttonDisabledBg};
  }

  /* 체크된 상태일 때 체크 표시 보이기 */
  &:checked:after {
    border-color: ${(props) => props.theme.colors.main};
  }
`;
const AgreementLabel = styled.label`
  color: ${(props) => props.theme.colors.buttonActiveBg};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
`;

const AgreementCheckBoxInner = ({ id, checkLabel, checkItemHandler, checked }: any) => {
  const handleAgreement = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkItemHandler(e.target.id, e.target.checked);
  };
  return (
    <AgreementLabel htmlFor={checkLabel}>
      <CustomCheckbox id={id} name={checkLabel} checked={checked.has(id)} onChange={handleAgreement} type="checkbox" />
    </AgreementLabel>
  );
};

export default AgreementCheckBoxInner;
