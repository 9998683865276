import React, { useEffect } from 'react';
import * as SS from '@/widgets/ars/components/arsTree/components/arsCardModal/ui/ArsCardModal.styles';
import { ArsCardModalProps } from '@/components/say015/Modal/arsCards/ArsCardModal';
import { useArsGuideTts } from '@/components/hooks/customs/say015/say105Handle/useArsGuideTts';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useArsGuidePlayAudio } from '@/components/hooks/customs/say015/say105Handle/useArsGuidePlayAudio';
import { useRecoilState } from 'recoil';
import { simpleViewArsTreeRecoil } from '@/recoil/atoms/say015Atom';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { useArsCardTitle } from '@/components/hooks/customs/say015/say105Handle/useArsCardTitle';

const ArsCardSpeak2 = ({
  data,
  type,
  handleUpdateIdState,
  handleCancel,
  arsData,
  updatedJsonFile,
  cardTitle,
}: ArsCardModalProps) => {
  /* 간편보기 사용여부 */
  const [simpleViewArsTreeS] = useRecoilState(simpleViewArsTreeRecoil);

  const { deleteArs, findDataById } = use015ArsJSON({ arsData, data, updatedJsonFile });

  const audioPlayHooks = useArsGuidePlayAudio({ data: findDataById(arsData, data?.id) });

  /* TTS 저장 및 수정 */
  const { handleSave, handleTtsText, isChangeTTS, ttsTextState, maxByte } = useArsGuideTts({
    data,
    arsData,
    updatedJsonFile,
    cardTitle,
  });

  const { confirmModal } = useModalHook();

  useEffect(() => {
    if (
      audioPlayHooks.ttsAudioUrl &&
      audioPlayHooks.activePlayRecordIdS &&
      audioPlayHooks.activePlayRecordIdS === data?.id
    ) {
      audioPlayHooks.handlePlay();
    } else {
      audioPlayHooks.handleEnded();
    }
  }, [audioPlayHooks.activePlayRecordIdS, audioPlayHooks.ttsAudioUrl]);

  // 저장 버튼 클릭시 분기처리
  const onClickSave = () => {
    if (handleCancel) handleCancel();
    if (type === 'add') {
      handleSave();
    } else {
      confirmModal(
        async () => {
          handleSave();
        },
        '안내멘트 저장',
        '기존 안내멘트 텍스트와 녹음은 제거되고, 현재 설정으로 저장합니다.',
        true,
        undefined,
        async () => {},
      );
    }
  };

  return (
    <>
      {/* 간편보기 O */}
      {simpleViewArsTreeS && (
        <div className={'simpleCard'}>
          <div className="txtTop">
            <div className="group">
              {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
              <p
                className="icon"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!handleUpdateIdState) return;
                  handleUpdateIdState(data?.id);
                  audioPlayHooks.handleAudioPlay(null);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
                  <path
                    d="M15.762 1.08863C15.8578 0.929055 16.013 0.814068 16.1936 0.768966C16.3742 0.723863 16.5653 0.752341 16.7249 0.848133L16.7277 0.850005L16.7305 0.851876L16.7399 0.857491C16.7764 0.87965 16.812 0.90338 16.8466 0.928612C16.9121 0.974467 17.001 1.04091 17.1058 1.127C17.3135 1.30013 17.5849 1.55934 17.8563 1.91121C18.4009 2.61961 18.9371 3.70234 18.9371 5.19308C18.9371 6.68381 18.4009 7.76654 17.8563 8.47494C17.5849 8.82681 17.3135 9.08603 17.1058 9.25915C16.9895 9.35601 16.8676 9.446 16.7408 9.52866L16.7315 9.53428L16.7277 9.53615L16.7258 9.53708C16.7258 9.53708 16.7249 9.53802 16.3637 8.9363L16.7249 9.53802C16.5659 9.62823 16.3781 9.65287 16.2012 9.60672C16.0244 9.56056 15.8725 9.44723 15.7779 9.29085C15.6833 9.13446 15.6535 8.94734 15.6947 8.76928C15.736 8.59123 15.845 8.43628 15.9987 8.33738L16.0034 8.33364L16.0408 8.30837C16.0783 8.28217 16.1353 8.24006 16.2065 8.1811C16.3496 8.06132 16.5462 7.87603 16.7427 7.61962C17.1338 7.11148 17.5334 6.32259 17.5334 5.19308C17.5334 4.06356 17.1338 3.27468 16.7436 2.76653C16.5856 2.56033 16.4055 2.37205 16.2065 2.20505C16.1414 2.1508 16.0736 2.09988 16.0034 2.05251L15.9987 2.04877C15.8405 1.95237 15.7269 1.79734 15.6825 1.61748C15.6382 1.43762 15.6667 1.24753 15.762 1.08863Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.8531 2.71964C14.774 2.67206 14.6863 2.64054 14.5951 2.62688C14.5038 2.61321 14.4108 2.61768 14.3213 2.64002C14.2317 2.66236 14.1475 2.70213 14.0733 2.75706C13.9992 2.81199 13.9366 2.881 13.8892 2.96014L14.1231 3.9184L14.1297 3.92308C14.2329 4.00072 14.3252 4.09201 14.4039 4.19447C14.621 4.48133 14.7345 4.83331 14.7258 5.19297C14.7258 5.66742 14.5602 5.98841 14.4039 6.19241C14.3252 6.29487 14.2329 6.38616 14.1297 6.46379L14.1222 6.46754C13.9666 6.56592 13.8559 6.72149 13.8139 6.90074C13.7719 7.07998 13.8021 7.26855 13.8978 7.42579C13.9935 7.58302 14.1472 7.69635 14.3257 7.74134C14.5043 7.78633 14.6933 7.75938 14.8521 7.6663H14.854L14.8559 7.66443L14.8587 7.6635L14.8652 7.65882L14.8839 7.64759C14.9552 7.60201 15.0231 7.55135 15.087 7.49599C15.2077 7.39586 15.3621 7.248 15.5165 7.04774C15.9224 6.51537 16.1382 5.86236 16.1295 5.19297C16.1382 4.52358 15.9224 3.87057 15.5165 3.33821C15.3506 3.12288 15.1546 2.93252 14.9345 2.77298C14.9178 2.76115 14.901 2.7496 14.8839 2.73835L14.8652 2.72712L14.8587 2.72245L14.8559 2.72151L14.854 2.72057C14.854 2.72057 14.8531 2.71964 14.4918 3.32136L14.8531 2.71964Z"
                    fill="currentColor"
                  />
                  <path
                    d="M11.3027 3.71045C10.2039 3.66035 9.10324 3.67145 8.0057 3.74369C4.2469 3.99343 1.25353 7.02903 1.00737 10.8408C0.959782 11.5947 0.959782 12.3508 1.00737 13.1047C1.09721 14.4927 1.7108 15.7783 2.43399 16.8635C2.85353 17.6226 2.57683 18.5704 2.13932 19.3996C1.82489 19.997 1.66678 20.2953 1.79345 20.5109C1.91922 20.7265 2.20221 20.7337 2.76728 20.7472C3.88576 20.7741 4.63949 20.4579 5.23781 20.0168C5.5765 19.7662 5.74629 19.6413 5.86308 19.6269C5.97987 19.6125 6.21075 19.7078 6.67071 19.8964C7.08397 20.0671 7.5646 20.1722 8.0048 20.2019C9.28498 20.2863 10.6227 20.2863 11.9055 20.2019C15.6634 19.9521 18.6568 16.9156 18.903 13.1047C18.9215 12.8101 18.933 12.5097 18.9371 12.2144M6.81086 14.4604H13.0995M6.81086 9.96851H9.95516"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
              {type !== 'view' && <h3 className="txt">텍스트 재생</h3>}
              {type === 'view' && (
                <button
                  className={`${audioPlayHooks.activePlayRecordIdS === data?.id ? 'voicePlayBtn active' : 'voicePlayBtn'}`}
                  onClick={() => audioPlayHooks.handleAudioPlay(data?.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <circle cx="9.21643" cy="9.21643" r="9.21643" fill="currentColor" />
                    <path
                      d="M8.41834 5.66236C7.84234 5.32353 7.11621 5.73883 7.11621 6.4071V12.0261C7.11621 12.6944 7.84234 13.1097 8.41834 12.7708L13.1945 9.96134C13.7624 9.62726 13.7624 8.80594 13.1945 8.47185L8.41834 5.66236Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
            </div>
            {/* 삭제 버튼 */}
            {type === 'update' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
          {type !== 'view' && (
            <div className="list">
              <textarea
                placeholder={`음성변환 할 텍스트를 입력해주세요.
매일 5건의 작성회수를 제공하며,
무료 변환 건수 소진 시 건당 100원의 요금이 발생합니다.`}
                defaultValue={data?.s015Data?.ttsText}
                onChange={handleTtsText}
              />
            </div>
          )}
        </div>
      )}
      {/* 간편보기 X */}
      {!simpleViewArsTreeS && (
        <div className={'card'}>
          {/* <div className="txtTop"> */}
          {/* <div className="group"> */}
          {/* 드래그앤 드롭시 필요한 그랩 아이콘 */}
          {/* {type === 'view' && (
                <button className="grab">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.33333 1.66667C3.33333 2.58712 2.58712 3.33333 1.66667 3.33333C0.746208 3.33333 0 2.58712 0 1.66667C0 0.746192 0.746208 0 1.66667 0C2.58712 0 3.33333 0.746192 3.33333 1.66667ZM1.66667 9.16667C2.58712 9.16667 3.33333 8.42046 3.33333 7.5C3.33333 6.57954 2.58712 5.83333 1.66667 5.83333C0.746208 5.83333 0 6.57954 0 7.5C0 8.42046 0.746208 9.16667 1.66667 9.16667ZM1.66667 15C2.58712 15 3.33333 14.2538 3.33333 13.3333C3.33333 12.4129 2.58712 11.6667 1.66667 11.6667C0.746208 11.6667 0 12.4129 0 13.3333C0 14.2538 0.746208 15 1.66667 15Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.66732 1.66667C9.66732 2.58712 8.92111 3.33333 8.00065 3.33333C7.08019 3.33333 6.33398 2.58712 6.33398 1.66667C6.33398 0.746192 7.08019 0 8.00065 0C8.92111 0 9.66732 0.746192 9.66732 1.66667ZM8.00065 9.16667C8.92111 9.16667 9.66732 8.42046 9.66732 7.5C9.66732 6.57954 8.92111 5.83333 8.00065 5.83333C7.08019 5.83333 6.33398 6.57954 6.33398 7.5C6.33398 8.42046 7.08019 9.16667 8.00065 9.16667ZM8.00065 15C8.92111 15 9.66732 14.2538 9.66732 13.3333C9.66732 12.4129 8.92111 11.6667 8.00065 11.6667C7.08019 11.6667 6.33398 12.4129 6.33398 13.3333C6.33398 14.2538 7.08019 15 8.00065 15Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )} */}
          {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
          {/* <p className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
                  <path
                    d="M15.762 1.08863C15.8578 0.929055 16.013 0.814068 16.1936 0.768966C16.3742 0.723863 16.5653 0.752341 16.7249 0.848133L16.7277 0.850005L16.7305 0.851876L16.7399 0.857491C16.7764 0.87965 16.812 0.90338 16.8466 0.928612C16.9121 0.974467 17.001 1.04091 17.1058 1.127C17.3135 1.30013 17.5849 1.55934 17.8563 1.91121C18.4009 2.61961 18.9371 3.70234 18.9371 5.19308C18.9371 6.68381 18.4009 7.76654 17.8563 8.47494C17.5849 8.82681 17.3135 9.08603 17.1058 9.25915C16.9895 9.35601 16.8676 9.446 16.7408 9.52866L16.7315 9.53428L16.7277 9.53615L16.7258 9.53708C16.7258 9.53708 16.7249 9.53802 16.3637 8.9363L16.7249 9.53802C16.5659 9.62823 16.3781 9.65287 16.2012 9.60672C16.0244 9.56056 15.8725 9.44723 15.7779 9.29085C15.6833 9.13446 15.6535 8.94734 15.6947 8.76928C15.736 8.59123 15.845 8.43628 15.9987 8.33738L16.0034 8.33364L16.0408 8.30837C16.0783 8.28217 16.1353 8.24006 16.2065 8.1811C16.3496 8.06132 16.5462 7.87603 16.7427 7.61962C17.1338 7.11148 17.5334 6.32259 17.5334 5.19308C17.5334 4.06356 17.1338 3.27468 16.7436 2.76653C16.5856 2.56033 16.4055 2.37205 16.2065 2.20505C16.1414 2.1508 16.0736 2.09988 16.0034 2.05251L15.9987 2.04877C15.8405 1.95237 15.7269 1.79734 15.6825 1.61748C15.6382 1.43762 15.6667 1.24753 15.762 1.08863Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.8531 2.71964C14.774 2.67206 14.6863 2.64054 14.5951 2.62688C14.5038 2.61321 14.4108 2.61768 14.3213 2.64002C14.2317 2.66236 14.1475 2.70213 14.0733 2.75706C13.9992 2.81199 13.9366 2.881 13.8892 2.96014L14.1231 3.9184L14.1297 3.92308C14.2329 4.00072 14.3252 4.09201 14.4039 4.19447C14.621 4.48133 14.7345 4.83331 14.7258 5.19297C14.7258 5.66742 14.5602 5.98841 14.4039 6.19241C14.3252 6.29487 14.2329 6.38616 14.1297 6.46379L14.1222 6.46754C13.9666 6.56592 13.8559 6.72149 13.8139 6.90074C13.7719 7.07998 13.8021 7.26855 13.8978 7.42579C13.9935 7.58302 14.1472 7.69635 14.3257 7.74134C14.5043 7.78633 14.6933 7.75938 14.8521 7.6663H14.854L14.8559 7.66443L14.8587 7.6635L14.8652 7.65882L14.8839 7.64759C14.9552 7.60201 15.0231 7.55135 15.087 7.49599C15.2077 7.39586 15.3621 7.248 15.5165 7.04774C15.9224 6.51537 16.1382 5.86236 16.1295 5.19297C16.1382 4.52358 15.9224 3.87057 15.5165 3.33821C15.3506 3.12288 15.1546 2.93252 14.9345 2.77298C14.9178 2.76115 14.901 2.7496 14.8839 2.73835L14.8652 2.72712L14.8587 2.72245L14.8559 2.72151L14.854 2.72057C14.854 2.72057 14.8531 2.71964 14.4918 3.32136L14.8531 2.71964Z"
                    fill="currentColor"
                  />
                  <path
                    d="M11.3027 3.71045C10.2039 3.66035 9.10324 3.67145 8.0057 3.74369C4.2469 3.99343 1.25353 7.02903 1.00737 10.8408C0.959782 11.5947 0.959782 12.3508 1.00737 13.1047C1.09721 14.4927 1.7108 15.7783 2.43399 16.8635C2.85353 17.6226 2.57683 18.5704 2.13932 19.3996C1.82489 19.997 1.66678 20.2953 1.79345 20.5109C1.91922 20.7265 2.20221 20.7337 2.76728 20.7472C3.88576 20.7741 4.63949 20.4579 5.23781 20.0168C5.5765 19.7662 5.74629 19.6413 5.86308 19.6269C5.97987 19.6125 6.21075 19.7078 6.67071 19.8964C7.08397 20.0671 7.5646 20.1722 8.0048 20.2019C9.28498 20.2863 10.6227 20.2863 11.9055 20.2019C15.6634 19.9521 18.6568 16.9156 18.903 13.1047C18.9215 12.8101 18.933 12.5097 18.9371 12.2144M6.81086 14.4604H13.0995M6.81086 9.96851H9.95516"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
              <h3 className="txt">텍스트 재생</h3> */}
          {/* ARS TTS 카드 재생 버튼. 클래스명은 임의로 저렇게 해둠 */}
          {/* {type === 'view' && (
                <button
                  className={`${audioPlayHooks.activePlayRecordIdS === data?.id ? 'voicePlayBtn active' : 'voicePlayBtn'}`}
                  onClick={() => audioPlayHooks.handleAudioPlay(data?.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <circle cx="9.21643" cy="9.21643" r="9.21643" fill="currentColor" />
                    <path
                      d="M8.41834 5.66236C7.84234 5.32353 7.11621 5.73883 7.11621 6.4071V12.0261C7.11621 12.6944 7.84234 13.1097 8.41834 12.7708L13.1945 9.96134C13.7624 9.62726 13.7624 8.80594 13.1945 8.47185L8.41834 5.66236Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )} */}
          {/* </div> */}
          {/* 삭제 버튼 */}
          {/* {type !== 'add' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )} */}
          {/* </div> */}
          <div
            className="list"
            onClick={() => {
              if (!handleUpdateIdState) return;
              handleUpdateIdState(data?.id);
              audioPlayHooks.handleAudioPlay(null);
            }}
          >
            {type === 'view' && <p>{data?.s015Data?.ttsText}</p>}
            {type === 'update' && (
              <div>
                <textarea
                  placeholder={`음성변환 할 텍스트를 입력해주세요.
  매일 5건의 작성회수를 제공하며,
  무료 변환 건수 소진 시 건당 100원의 요금이 발생합니다.`}
                  value={ttsTextState}
                  onChange={handleTtsText}
                />
                <SS.TxtByte className="txtByte">
                  <p>
                    {calMsgByte(ttsTextState)} / {maxByte} Byte
                  </p>
                </SS.TxtByte>
              </div>
            )}
            {type === 'add' && (
              <div>
                <textarea
                  placeholder={`음성변환 할 텍스트를 입력해주세요.
매일 5건의 작성회수를 제공하며,
무료 변환 건수 소진 시 건당 100원의 요금이 발생합니다.`}
                  value={ttsTextState}
                  onChange={handleTtsText}
                />
                <SS.TxtByte className="txtByte">
                  <p>
                    {calMsgByte(ttsTextState)} / {maxByte} Byte
                  </p>
                </SS.TxtByte>
              </div>
            )}
          </div>
        </div>
      )}
      {/* 하단 버튼 */}
      {type !== 'view' && (
        <div className="btnGroup">
          <button className="cancelBtn" onClick={handleCancel}>
            취소
          </button>
          {/* <button className="saveBtn" onClick={() => onClickSave()} disabled={!isChangeTTS || !ttsTextState}> */}
          <button className="saveBtn" onClick={() => onClickSave()}>
            저장
          </button>
        </div>
      )}

      {audioPlayHooks.audioRef && audioPlayHooks.ttsAudioUrl && (
        <>
          <audio
            style={{ display: 'none' }}
            ref={audioPlayHooks.audioRef}
            onLoadedMetadata={audioPlayHooks.handleLoadedMetadata}
            onEnded={() => {
              audioPlayHooks.handleAudioPlay(null);
              audioPlayHooks.handleEnded();
            }}
          >
            <source src={audioPlayHooks.ttsAudioUrl} type="audio/wav" />
            <track kind="captions" src="" label="인사말 설정 음성파일입니다." />
          </audio>
        </>
      )}
    </>
  );
};

export default ArsCardSpeak2;
