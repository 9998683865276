import styled from 'styled-components';

export const Say015NotInfo = styled.div``;
export const AWrapper = styled.div`
  > h2 {
    margin-bottom: 6vh;
  }
`;
export const StateGroup = styled.div`
  text-align: center;
  font-weight: 500;
  margin-bottom: 5vh;
  h2 {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  p {
    font-size: 1.4rem;
  }
`;
export const ButtonWrapper = styled.div`
  text-align: center;
  button {
    width: auto;
    height: auto;
    background: ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.buttonText};
    font-size: 1.4rem;
    font-weight: 700;
    padding: 1rem 2rem;
    border-radius: 5px;
  }
`;
