import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.2em;
  height: 1em;
`;

const IconAddAddress: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 26 22"
      fill="none"
    >
      <path
        d="M17.3567 9.53553H21.1783M21.1783 9.53553H25M21.1783 9.53553V5.78553M21.1783 9.53553V13.2855M1 21V19.75C1 17.4294 1.93949 15.2038 3.61179 13.5628C5.28409 11.9219 7.55221 11 9.9172 11M9.9172 11C12.2822 11 14.5503 11.9219 16.2226 13.5628C17.8949 15.2038 18.8344 17.4294 18.8344 19.75V21M9.9172 11C11.2686 11 12.5647 10.4732 13.5203 9.53553C14.4759 8.59785 15.0127 7.32608 15.0127 6C15.0127 4.67392 14.4759 3.40215 13.5203 2.46447C12.5647 1.52678 11.2686 1 9.9172 1C8.56578 1 7.2697 1.52678 6.31411 2.46447C5.35851 3.40215 4.82166 4.67392 4.82166 6C4.82166 7.32608 5.35851 8.59785 6.31411 9.53553C7.2697 10.4732 8.56578 11 9.9172 11Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconAddAddress;
