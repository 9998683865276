import { atom } from 'recoil';

/**
 * @title 회원정보
 */
export type UserInfoRecoilType = {
  addr: string;
  birthDt: string;
  cpAddr: string;
  cpZipCd: string;
  domain: string;
  emailRcvYn: string;
  jobGb: string;
  lunGb: string;
  marryDt: string;
  marryGb: string;
  nickNm: string;
  phnId: string;
  smsRcvYn: string;
  userTel1: string;
  userTel2: string;
  userTel3: string;
  usrEmail: string;
  usrNm: string;
  zipCd: string;
};
export const userInfoRecoil = atom<UserInfoRecoilType>({
  key: 'userInfoRecoil',
  default: {
    addr: '',
    birthDt: '',
    cpAddr: '',
    cpZipCd: '',
    domain: '',
    emailRcvYn: '',
    jobGb: '',
    lunGb: '',
    marryDt: '',
    marryGb: '',
    nickNm: '',
    phnId: '',
    smsRcvYn: '',
    userTel1: '',
    userTel2: '',
    userTel3: '',
    usrEmail: '',
    usrNm: '',
    zipCd: '',
  },
});
