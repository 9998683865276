import styled from 'styled-components';

// 사업자 리스트, 결제 내역
export const ListContainer = styled.div`
  display: flex;
  font-size: 1.2rem;
  margin: 6rem 0 0;
  gap: 3rem;
  li,
  ul,
  ol {
    list-style: none;
  }
  @media screen and (max-width: 1080px) {
    flex-wrap: wrap;
    > div {
      width: 100%;
    }
  }
`;

export const CorpInfoListContainer = styled.div`
  width: 70%;
`;

export const titleCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    > h2 {
      margin: 0 0 1.4rem;
    }
    button {
      width: 100%;
      padding: 1rem;
    }
  }
`;

export const CorpInfoTable = styled.table`
  width: 100%;
  margin: 3rem 0 0;
`;

export const TableThead = styled.thead`
  border-top: 2px solid ${(props) => props.theme.colors.gray400};
  font-size: 1.4rem;
  font-weight: bold;
`;

export const TR = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  background: ${(props) => props.theme.colors.gray100};
  line-height: 3.5rem;

  & td {
    text-align: center;
  }

  & td:nth-of-type(1) {
    display: flex;
    justify-content: center;
  }

  & label {
    margin-right: 0;
    justify-content: center;
  }
`;

// 결제내역 리스트
export const PayListContainer = styled.div`
  width: 30%;
  clear: both;
  > h2 {
    margin: 0 0 1rem;
  }
  & > button {
    float: right;
    padding: 1rem 2rem;
  }
  @media screen and (max-width: 768px) {
    & > button {
      float: unset;
      width: 100%;
    }
  }
`;

export const BaseGuideP = styled.p`
  color: ${(props) => props.theme.colors.txt400};
  margin-bottom: 2.4rem;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const tableContainer = styled.div`
  width: 100%;
  margin-bottom: 3.5rem;
  max-height: 26.4rem;
  overflow-y: auto;
  table {
    border-top: 0;

    thead {
      z-index: 1;
      position: sticky;
      top: 0;
    }
  }
`;

export const PaymentHistoryTable = styled.table`
  width: 100%;
  border-bottom: 1px solid #a1a1a1;
  line-height: 3.5rem;
  text-align: center;
  margin-bottom: 10px;
`;

export const TableTBody = styled.tbody`
  font-size: 1.3rem;
`;

export const CorpUlHead = styled.ul`
  display: flex;
  width: 100%;
  margin-top: 3rem;
  border-top: 2px solid ${(props) => props.theme.colors.gray400};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  font-size: 1.4rem;
  font-weight: bold;
  background: ${(props) => props.theme.colors.gray100};

  & > li:nth-of-type(1) {
    width: 10%;
  }

  & > li:nth-of-type(2) {
    width: 30%;
  }

  & > li:nth-of-type(3) {
    width: 35%;
  }

  & > li:nth-of-type(4) {
    width: 25%;
  }

  & > li:nth-of-type(5) {
    width: 0;
  }
  @media screen and (max-width: 420px) {
    font-size: 1.2rem;
  }
  /* @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    & > li:nth-of-type(even),
    & > li:nth-of-type(odd) {
      width: 100%;
    }
  } */
`;

export const CorpUl = styled.ul`
  width: 100%;
`;

export const CorpLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  @media screen and (max-width: 768px) {
    padding: 1rem 0;
    height: auto;
    font-size: 1.5rem;
  }
  @media screen and (max-width: 430px) {
    font-size: 1.3rem;
  }
`;

export const CorpLiBody = styled.li`
  display: flex;
  width: 100%;
  height: 3.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  font-size: 1.3rem;

  & * {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:nth-of-type(1) {
    width: 10%;
    margin-right: 0;
  }

  & > div:nth-of-type(2) {
    width: 30%;
  }

  & > div:nth-of-type(3) {
    width: 30%;
  }

  & > div:nth-of-type(4) {
    width: 20%;
  }

  & > div:nth-of-type(5) {
    width: 10%;
  }
  @media screen and (max-width: 768px) {
    div {
      padding: 1rem 0;
      height: auto;
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 420px) {
    div {
      font-size: 1.3rem;
    }
  }
  /* @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
    & > div:nth-of-type(even),
    & > div:nth-of-type(odd) {
      padding: 0.5rem;
      width: 100%;
    }
  } */
`;

export const BottomArrow = styled.div`
  cursor: pointer;

  &.on {
    /* margin-top: 0.9rem; */
    transform: rotate(180deg);
  }

  & .row {
    width: 1.2rem;
    height: 1.2rem;
    border-left: 2px solid #919091;
    border-top: 2px solid #919091;
    transform: rotate(-135deg);
  }
  .arrow {
    color: ${(props) => props.theme.colors.txt500};
    transform: rotate(90deg);
    font-size: 1.4rem;
  }

  &.on .row {
    transform: rotate(-315deg);
  }
`;

// 사업자 리스트 Detail
export const CorpInfoDetail = styled.li`
  padding: 4rem 7rem 3rem 3rem;

  &.on {
    display: block;
  }

  &.off {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    padding: 4rem 0;
  }
`;

export const TopCorpInfoDetail = styled.ul`
  margin-bottom: 3.5rem;
`;

export const EditEnterBtn = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0.5rem 0;

    button {
      width: 100%;
      padding: 1rem;
    }
  }
`;

export const BottomCorpInfoDetail = styled.ul`
  display: flex;
  flex-wrap: wrap;

  & > li {
    width: 50%;
  }

  & > li:nth-of-type(2),
  & > li:nth-of-type(4) {
    /* width: 40%; */
  }

  & > li:nth-of-type(3) strong,
  & > li:nth-of-type(5) strong {
    width: 40px;
  }

  & > button {
    margin: 25px auto 5px auto;
  }

  @media screen and (max-width: 768px) {
    & > li {
      width: 100%;

      input {
        width: calc(100% - 50px);
      }
    }

    & > li strong,
    & > li:nth-of-type(3) strong,
    & > li:nth-of-type(5) strong {
      width: 50px;
      margin-right: 0;
    }
  }
`;

export const LiRow = styled.li`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  > h1 {
    font-size: 2rem;
    color: ${(props) => props.theme.colors.txt500};
  }
  input {
    padding: 0.4rem;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    > * {
      width: 100%;
      margin: 0;
      margin-left: 0 !important;
      margin-bottom: 1rem;
    }

    > button {
      width: auto;
      padding: 0.5rem 2rem;
      height: auto;
    }
    input {
      padding: 1rem;
    }

    &.flexWrap {
      flex-wrap: nowrap;
      gap: 1rem;

      > * {
        width: auto;
      }
    }
  }
`;

export const LabelStrong = styled.strong`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.txt500};
  font-weight: bold;
  margin-right: 1.5rem;
  width: 10rem;
  display: block;
  white-space: nowrap;
  line-height: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const TextP = styled.p`
  font-size: 1.4rem;
  span {
    font-weight: 700;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    /* background: #eebc31; */
    color: #eebc31;
    border: 1px solid #eebc31;
  }

  small {
    font-size: 0.8em;
    margin: 0 0 0 0.2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.7rem;
  }
`;

export const InputSpanCover = styled.span`
  display: block;
`;

export const corpInput = styled.input`
  border: 0;
  border-bottom: 1px solid #a1a1a1;
  background-color: transparent;
  outline: 0;
  font-size: 1.4rem;

  @media screen and (max-width: 768px) {
    font-size: 1.7rem;
    line-height: normal;
    padding: 1rem;
  }

  @media screen and (max-width: 1080px) {
    &.address {
      width: 100% !important;
    }
  }
`;

// 주소
export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999;
  overflow: auto;
`;

export const InputAddresBox = styled.div`
  width: 100%;
  margin-top: 5px;
`;

export const PayInput = styled.input`
  width: 10rem;
  text-align: right;
  border: 0;
  font-size: 1.3rem;
`;
