import styled, { keyframes } from 'styled-components';

export const ChatroomContainer = styled.section`
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.chatBack};
`;

export const ChatroomChatting = styled.div`
  width: 100%;
`;

export const EtcChattingDateText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0 3rem;
  text-align: center;

  &:nth-of-type(n + 2) {
    margin: 3rem 0;
  }

  span {
    padding: 0.5rem 2rem;
    border-radius: 10rem;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.4);
    color: ${(props) => props.theme.colors.txt100};
  }
`;

export const MessageItem = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
`;

/* Start My */

export const EtcChattingMyZone = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
`;

export const EtcChattingContentWrap = styled.div`
  // My, Your 공용
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  max-width: 80%;

  &:last-of-type {
    @media screen and (max-width: 768px) {
      /* margin-bottom: 20px; */
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 90%;
  }
`;

export const EtcChattingHourText = styled.span`
  // My, Your 공용
  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: end;

  color: ${(props) => props.theme.colors.txt300};
  @media screen and (max-width: 540px) {
    font-size: 1.1rem;
  }
`;

export const EtcChattingSnedCancelBtn = styled.span`
  // 전송취소 버튼
  margin-right: 0.4rem;
  font-size: 1.4rem;
  cursor: pointer;
`;

export const EtcChattingMyTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 1.5rem;
  min-height: 5rem;
  border-radius: 1.5rem 0px 1.5rem 1.5rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.main};

  :hover {
    background-color: ${(props) => props.theme.colors.activeMain};
  }

  > p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6em;
    word-break: break-word;
    white-space: pre-wrap;
    color: ${(props) => props.theme.colors.txt100};
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;
