import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import AddArsCard from '@/components/say015/Modal/addArsCard/AddArsCard';
import ArsCardModal, { TArsCard } from '@/components/say015/Modal/arsCards/ArsCardModal';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';

interface IPlusIcon {
  className?: string;
  handleAddIdState: (isAddId: string | null) => void;
  isAddIdS: string | null;
  id: string | null;
  updatedJsonFile(): void; // ARS Json 업데이트
  arsData: any;
  selectAddCardS: TArsCard | null;
  setSelectAddCardS: React.Dispatch<React.SetStateAction<TArsCard | null>>;
  lastItem: any;
}

const Plus = styled.button`
  position: relative;
  width: 24rem;
  height: 10rem;
  background: transparent;
  border-radius: 1rem;
  border: 1px dashed ${(props) => props.theme.colors.gray400};

  &.active {
    display: none;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 1.2rem;
    height: 0.2rem;
    border-radius: 20px;
    background: ${(props) => props.theme.colors.gray500};
    :nth-of-type(2) {
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
      left: 50%;
    }
  }
`;

const PlusIcon = ({
  arsData,
  isAddIdS,
  handleAddIdState,
  className,
  id,
  updatedJsonFile,
  setSelectAddCardS,
  selectAddCardS,
  lastItem,
}: IPlusIcon) => {
  const { findDataById } = use015ArsJSON({ arsData });
  const cardRef = useRef<any>({});

  useEffect(() => {
    if (id && isAddIdS && cardRef.current[id]) {
      if (id === isAddIdS) {
        cardRef.current[id].focus();
        cardRef.current[id].scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
      }
    }
  }, [isAddIdS, id, cardRef]);

  const setRef = (key: any) => (element: any) => {
    cardRef.current[key] = element;
  };

  // if (lastItem !== '초기값' && selectAddCardS && isAddIdS === id && updatedJsonFile && handleAddIdState) {
  if (selectAddCardS && isAddIdS === id) {
    return (
      <>
        <Plus className="plus active edit" onClick={() => handleAddIdState(null)}>
          <span />
          <span />
        </Plus>
        <ArsCardModal
          content={selectAddCardS}
          type={'add'}
          handleAddIdState={handleAddIdState}
          updatedJsonFile={updatedJsonFile}
          data={findDataById(arsData, id)}
          arsData={arsData}
        />
      </>
    );
  }
  return (
    <>
      <Plus
        ref={setRef(id)}
        className={`plus ${isAddIdS === id ? ' active' : ''} ${lastItem === '초기값' ? 'ab' : ''}`}
        onClick={() => handleAddIdState(id)}
      >
        <span />
        <span />
      </Plus>
      {isAddIdS === id && (
        <AddArsCard
          className={className}
          setSelectAddCardS={setSelectAddCardS}
          id={id}
          handleAddIdState={handleAddIdState}
        />
      )}
    </>
  );
};

export default PlusIcon;
