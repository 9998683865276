import { getGroupList } from '@/apis/api/addressApis';
import { useQuery } from '@tanstack/react-query';

interface GroupItem {
  groupSeqNo: number;
  usrKey: string;
  buddyCount: number;
  groupNm: string;
}

interface GroupListResponse {
  groupList: GroupItem[];
  recycleCount: number;
  nextPage?: number | null;
}

export const useGroupListData = (debouncedSearchValue: string) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<GroupListResponse>({
    queryKey: ['groupList2', debouncedSearchValue], // queryKey에 debouncedSearchValue 추가
    queryFn: () => getGroupList(debouncedSearchValue), // 검색어를 파라미터로 전달
  });

  const groupData = data;
  const groupLoading = isLoading;

  return {
    groupData,
    groupLoading,
  };
};
