import { atom } from 'recoil';

/**
 *
 * @title 주소록 메인 좌측 그룹 리스트 관련 recoil
 *
 * */

/* 그룹 추가 컴포넌트 toggle STATE */
export const addGroupOpenRecoil = atom<boolean>({
  key: 'addGroupOpenRecoil',
  default: false,
});

/* 그룹 검색 컴포넌트 toggle STATE */
export const searchGroupOpenRecoil = atom<boolean>({
  key: 'searchGroupOpenRecoil',
  default: false,
});

/* 반응형 그룹 리스트 컴포넌트 toggle STATE */
export const resTableSwitchRecoil = atom<boolean>({
  key: 'resTableSwitchRecoil',
  default: false,
});

/* 그룹리스트 검색 keyword Recoil */
export const groupSearchTextRecoil = atom<string>({
  key: 'groupSearchTextRecoil',
  default: '',
});

/* 그룹리스트 api호출 enabled 상태 정할 Recoil */
export const groupListEnabledRecoil = atom<boolean>({
  key: 'groupListEnabledRecoil',
  default: true,
});
