import styled from 'styled-components';

export const SelectQA = styled.div`
  > h2 {
    padding: 2rem 0 0;
  }
  .selectTop {
    /* leftGroup, rightGroup */
    > div {
      @media screen and (max-width: 640px) {
        width: 100%;
        > div {
          width: 100%;
        }
        .classifyTab button {
          width: 100%;
        }
        form,
        input {
          width: 100%;
        }
      }
    }
    .rightGroup {
      gap: 0.6rem;
    }
    .buttonGroup {
      @media screen and (max-width: 1080px) {
        width: 100%;
        .classifyTab {
          column-gap: 0;
          justify-content: space-between;
          > button {
            width: calc(100% / 3 - 1rem);
          }
        }
      }
      @media screen and (max-width: 640px) {
        .classifyTab {
          > button {
            width: calc(100% / 2 - 1rem);
          }
        }
      }
    }
  }
  > h2 {
    font-size: 2rem;
    color: ${(props) => props.theme.colors.txt500};
    font-weight: 600;
    margin-bottom: 2.4rem;
  }
  .flexBox {
    display: flex;
    flex-wrap: wrap;
  }
  .flexBox-ajs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
`;
export const SelectTop = styled.div`
  margin-bottom: 1.6rem;
  gap: 1rem;
`;
export const flexButtonBox = styled.div`
  .classifyTab {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    button {
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 700;
      text-align: center;
      width: 9rem;
      height: 3rem;
      padding: 0 1.6rem;
      border: 0;
      color: ${(props) => props.theme.colors.gray600};
      border: 1px solid ${(props) => props.theme.colors.gray600};
      border-radius: 0.5rem;
      &.active {
        color: ${(props) => props.theme.colors.main};
        border-color: ${(props) => props.theme.colors.main};
      }

      svg {
        fill: ${(props) => props.theme.colors.txt500};
      }
    }
  }
`;
export const SelectBox = styled.div`
  > div {
    position: relative;
  }
  .classifyTab.top {
    z-index: 2;
  }
  button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 11.2rem;
    height: 3rem;
    padding: 0 1.6rem;
    border: 0;
    color: ${(props) => props.theme.colors.txt500};
    svg {
      fill: ${(props) => props.theme.colors.gray600};
    }
  }
  .classifyTab > button {
    border-radius: 0.5rem;
    border: 1px solid ${(props) => props.theme.colors.gray500};
  }
  ul {
    z-index: 1;
    display: none;
    position: absolute;
    top: calc(100% + 0.6rem);
    left: 0;
    width: 100%;
    background: ${(props) => props.theme.colors.gray200};
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 1rem;

    li {
      position: relative;
      margin: 0 0.6rem;
      :first-of-type {
        margin-top: 0.6rem;
      }
      :last-of-type {
        margin-bottom: 0.6rem;
      }
      :hover {
        button {
          background: ${(props) => props.theme.colors.hover};
        }
      }

      button {
        width: 100%;
        padding: 1rem;
        background: transparent;
        font-size: 1.2rem;
        border-radius: 0.5rem;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  > div.active {
    ul {
      display: block;
    }

    > button {
      border-bottom-color: #d6d6dc;
    }

    svg {
      transform: rotate(180deg);
    }
  }
`;

// 검색창
export const SearchBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  input {
    width: 24rem;
    height: 3rem;
    /* background: ${(props) => props.theme.colors.gray100}; */
    border: 1px solid ${(props) => props.theme.colors.gray500};
    border-radius: 0.5rem;
    padding: 0rem 3.4rem 0rem 1.5rem;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);

    :focus {
      outline: none;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
    }

    ::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
    }
  }

  button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    width: 17px;
    height: 18px;
    padding: 0;
    border: 0;
    background: transparent;
  }
`;

export const BulletinBoard = styled.div`
  ol {
    > li {
      position: relative;
      margin-bottom: 6px;
      font-size: 1.3rem;
      font-weight: 500;
      cursor: pointer;
      svg {
        path {
          fill: #ababad;
        }
      }
      /* 제목 */
      &.active {
        svg {
          color: ${(props) => props.theme.colors.main};
        }
        .dateGroup {
          display: flex;
          text-align: right;
          .date {
            display: none;
          }
          .arrowBtn {
            display: block !important;
          }
        }
      }
      .subjectGroup {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${(props) => props.theme.colors.white};
        border-radius: 5px;
        text-align: center;
        border: 1px solid ${(props) => props.theme.colors.gray300};
        > * {
          /* height: 3.7rem; */
          /* display: flex;
					align-items: center; */
          padding: 1.2rem 1rem;
        }
        > h2 {
          width: 15%;
          border-right: 1px solid ${(props) => props.theme.colors.gray100};
          justify-content: center;
        }
        .subject {
          text-align: left;
          margin-left: 2rem;
          width: calc(100% - 15% - 10rem);
        }
        .dateGroup {
          width: 10rem;
          text-align: right;
          justify-content: right;
          .arrowBtn {
            display: none;
          }
        }
        @media screen and (max-width: 1040px) {
          flex-wrap: wrap;
          > * {
            height: auto;
            padding: 1rem;
            margin: 0;
          }
          > h2 {
            order: -2;
            width: calc(100% - 10rem);
            text-align: left;
            border: 0;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
          }
          .subject {
            margin-left: 0;
            width: 100%;
            p {
              /* width: 90%; */
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          .dateGroup {
            order: -1;
            width: 10rem;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
          }
        }
      }
      /* 자세한 내용 */
      ul {
        z-index: 2;
        opacity: 0;
        /* position: absolute;
				top: 100%; */
        width: 100%;
        height: 0;
        max-height: 50vh;
        background: ${(props) => props.theme.colors.white};
        overflow-y: auto;
        border-top: 1px solid ${(props) => props.theme.colors.gray100};
        border-radius: 0 0 5px 5px;
        transition: all 0.1s linear;

        ::-webkit-scrollbar {
          width: 0.4rem;
          height: 0.4rem;
          background-color: #d6d6dc;
        }

        ::-webkit-scrollbar-thumb {
          background: ${(props) => props.theme.colors.gray600};
        }

        li {
          padding: 1.4rem 3rem 1.4rem calc(15% + 3rem);
          line-height: 2em;
          img {
            width: 100%;
          }
        }
      }
    }

    /* active */
    > li.active {
      .subjectGroup {
        border-radius: 0.5rem 0.5rem 0 0;
      }
      ul {
        opacity: 1;
        height: 26.2rem;
        border: 1px solid ${(props) => props.theme.colors.gray300};
        border-top: 0;
      }
    }
  }
  @media screen and (max-width: 1040px) {
    ol {
      li {
        ul {
          li {
            padding: 1rem;
          }
        }
      }
    }
  }
`;

export const Pagination = styled.div`
  margin: 2rem 0;
  .sendResultPaging {
    justify-content: center;
    ol {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
`;
