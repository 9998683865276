import { useRecoilState } from 'recoil';
import { globalModalStateRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useGlobalModalReset } from '@/components/hooks/customs/useGlobalModalReset';

type ModalContent = React.ReactNode;

const useGlobalModalHook = () => {
  const [modalState, setModalState] = useRecoilState(globalModalStateRecoil);

  // 모달 off시 recoil 관리
  const resetHooks = useGlobalModalReset();

  // closeOnOverlayClick을 옵션으로 받도록 수정
  const openModal = (content: ModalContent, closeOnOverlayClick: boolean = true) => {
    setModalState({ isOpen: true, content, closeOnOverlayClick });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, content: null, closeOnOverlayClick: true });
    resetHooks.modalReset();
  };

  return {
    isOpen: modalState.isOpen,
    content: modalState.content,
    closeOnOverlayClick: modalState.closeOnOverlayClick,
    openModal,
    closeModal,
  };
};

export default useGlobalModalHook;
