import { updateStcMsgApi } from '@/apis/api/stContentDetailApis';
import { softPhone010CacheUpdate } from '@/components/cache/softPhone010CacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useMutation } from '@tanstack/react-query';

export interface IUseMutationUpdateStMsgDetail {
  sndMsg: string;
  subject: string;
  sndDttm: string;
  prepayPayNo: string;
  callback: string;
}

export const useMutationUpdateStMsgDetail = ({ sndDttm }: IUseMutationUpdateStMsgDetail) => {
  const { successModal, warningModal } = useModalHook();
  const { cacheUpdateEditReserve } = softPhone010CacheUpdate();

  const { mutate: mutationUpdateMsg, isLoading: isUpdateLoading } = useMutation(updateStcMsgApi, {
    onSuccess: (data, variables: IUseMutationUpdateStMsgDetail) => {
      onSuccessEditMsg(variables);
    },
    onError: (error: any) => {
      warningModal('예약전송 수정', error.response.data, true);
    },
  });

  // ==============================
  // 예약전송 수정 성공 hooks
  // ==============================
  const onSuccessEditMsg = (variables: IUseMutationUpdateStMsgDetail) => {
    cacheUpdateEditReserve(variables, sndDttm);
    successModal('예약전송 수정', '메시지 수정이 완료되었습니다.', true);
  };

  return {
    mutationUpdateMsg,
    isUpdateLoading,
  };
};
