/* eslint-disable */

import React from 'react';
import * as S from './MessageManagement.styles';
import { useMessageManagement } from '@/components/hooks/customs/useMessageManagement';

interface IMessageManagement {
  type?: string;
}

// 소프트폰 라우팅
const MessageManagement = ({ type }: IMessageManagement) => {
  const hooks = useMessageManagement();

  return (
    <S.MessageManagementWrap>
      {hooks.singleComponents[hooks.softPhoneTopTabState] && !type
        ? hooks.singleComponents[hooks.softPhoneTopTabState]
        : hooks.components[hooks.softPhoneComponent]}
    </S.MessageManagementWrap>
  );
};

export default MessageManagement;
