import React, { useEffect, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { instance } from '@/shared/lib/clientAxios';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { Spin } from 'antd';
import { useRecoilState } from 'recoil';
import { mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import * as S from './MobileMessageAdd.style';
import * as L from '../mobileMessageList/MobileMessageList.style';
import { useContactsData } from '../../mobileHooks/useContactsData';
import IconChatUser from '@/styles/icons/IconChatUser';

interface Buddy {
  buddySeqNo: number;
  buddyNm: string;
  keyCommNo: string;
}

interface PageResponse {
  content: Buddy[];
  totalElements: number;
  totalPages: number;
}

const fetchContacts = async ({ pageParam = 0, queryKey }: any) => {
  const [, searchData] = queryKey;
  const response = await instance.get<PageResponse>('/soft-phone/contacts/mobile', {
    params: { searchData, page: pageParam, size: 20 },
  });
  return response.data;
};

const MobileMessageAdd = () => {
  const [searchValue, setSearchValue] = useState<string>(''); // 검색어
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>(searchValue);

  // 추가한 대화상대 리코일로 관리
  const [mobileBuddyListAdd, setMobileBuddyListAdd] = useRecoilState(mobileBuddyListAddRecoil);

  // 훅
  const { contactsData, handleScroll, contactsLoading, isFetchingNextPage } = useContactsData(debouncedSearchValue);

  // Debounce effect
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 400);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  const handleContainerClick = (buddy: Buddy) => {
    if (mobileBuddyListAdd.some((item) => item.buddySeqNo === buddy.buddySeqNo)) {
      // 이미 선택된 경우 선택 해제
      setMobileBuddyListAdd([]);
    } else {
      // 새 친구를 첫 번째로 추가
      setMobileBuddyListAdd([buddy]); // 배열에 추가
    }
  };

  return (
    <>
      <S.MessageAddSearchContainer className="messageAddSearch">
        <S.MessageAddSearchInput
          type="text"
          placeholder="이름, 전화번호 검색"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </S.MessageAddSearchContainer>

      <S.MessageAddContainer className="messageAddContainer" onScroll={handleScroll}>
        <S.MessageAddListContainer>
          {contactsData.map((buddy) => (
            <L.MessageListContainer
              key={buddy.buddySeqNo}
              onClick={() => handleContainerClick(buddy)}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <L.BuddyImg>
                <IconChatUser />
              </L.BuddyImg>
              <L.MessageContents className="messageContents">
                <L.MessageContentsTop>
                  <h3 style={{ maxWidth: '10ch' }}>{buddy.buddyNm}</h3>
                  <div>{TelHyphen(buddy.keyCommNo)}</div>
                </L.MessageContentsTop>
              </L.MessageContents>

              {/* 선택된 항목의 동그라미 아이콘 */}
              <div
                className={`mobileBuddyListAdd ${mobileBuddyListAdd[0]?.buddySeqNo === buddy.buddySeqNo ? 'active' : ''}`}
              >
                {mobileBuddyListAdd && mobileBuddyListAdd[0]?.buddySeqNo === buddy.buddySeqNo && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white" // 내부 색상을 비워둡니다.
                    stroke="white" // 체크 표시의 색상
                    strokeWidth=".8" // 체크 표시의 두께 조정
                    style={{
                      position: 'absolute',
                      width: '1.6rem',
                      height: '1.6rem',
                    }}
                  >
                    <path d="M10 15.172L4.828 10 3.414 11.414 10 18l12-12-1.414-1.414z" />
                  </svg>
                )}
              </div>
            </L.MessageListContainer>
          ))}

          {isFetchingNextPage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.6rem',
                padding: '2rem 0',
              }}
            >
              <Spin size="default" />
              <span style={{ marginLeft: 8 }}>로딩 중...</span>
            </div>
          )}
        </S.MessageAddListContainer>
      </S.MessageAddContainer>
    </>
  );
};

export default MobileMessageAdd;
