import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './styles/Header.styles';
import { useRecoilState, useRecoilValue } from 'recoil';

import { addressOpenToggleRecoil } from '@/recoil/atoms/messageSend/messageSend';
import { listToggleRecoil, softPhoneTopTabRecoil, windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import ReactGA from 'react-ga4';
import Logo from '../Atom/Logo';

const Header = () => {
  const [pathName, setPathName] = useState<string>('');
  const [softPhoneTopTabS] = useRecoilState(softPhoneTopTabRecoil);
  const [listToggleS] = useRecoilState(listToggleRecoil);
  const [addressOpenToggle] = useRecoilState(addressOpenToggleRecoil);

  const location = useLocation();

  // 반응형 넓이 가져오는 RECOIL 768이하 부터 반응형
  const windowWidth = useRecoilValue(windowWidthRecoil);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname, // useRouter를 사용하여, pathname값을 가져옵니다.
      });
      setPathName(window.location.pathname);
    }
  }, [location]);

  return (addressOpenToggle || !listToggleS) && (pathName === '/signin' || pathName === '/') ? null : (
    <S.Header className="header" style={{ display: windowWidth > 768 ? '' : 'none' }}>
      <S.HeaderWrap>
        <Logo eType="Home" sType="B" />
      </S.HeaderWrap>
    </S.Header>
  );
};

export default Header;
