import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconTaxWon: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2.4rem'}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.01761 1.4143C1.85781 1.4143 1.70456 1.47778 1.59157 1.59077C1.47857 1.70377 1.41509 1.85702 1.41509 2.01682V22.9816C1.41509 23.1414 1.47857 23.2946 1.59157 23.4076C1.70456 23.5206 1.85781 23.5841 2.01761 23.5841H19.0516C19.2114 23.5841 19.3646 23.5206 19.4776 23.4076C19.5906 23.2946 19.6541 23.1414 19.6541 22.9816V17.7401C19.6541 17.3493 19.9709 17.0325 20.3616 17.0325C20.7524 17.0325 21.0692 17.3493 21.0692 17.7401V22.9816C21.0692 23.5167 20.8566 24.0299 20.4782 24.4083C20.0999 24.7866 19.5867 24.9992 19.0516 24.9992H2.01761C1.48251 24.9992 0.96932 24.7866 0.590944 24.4083C0.212569 24.0299 0 23.5167 0 22.9816V2.01682C0 1.48171 0.212569 0.968526 0.590944 0.590151C0.969319 0.211775 1.48251 -0.000793457 2.01761 -0.000793457H11.1899C11.5807 -0.000793457 11.8975 0.315986 11.8975 0.706754C11.8975 1.09752 11.5807 1.4143 11.1899 1.4143H2.01761ZM5.24088 13.8093C5.24088 13.4185 5.55766 13.1017 5.94843 13.1017H11.1899C11.5807 13.1017 11.8975 13.4185 11.8975 13.8093C11.8975 14.2 11.5807 14.5168 11.1899 14.5168H5.94843C5.55766 14.5168 5.24088 14.2 5.24088 13.8093ZM5.24088 19.0508C5.24088 18.66 5.55766 18.3432 5.94843 18.3432H15.1208C15.5115 18.3432 15.8283 18.66 15.8283 19.0508C15.8283 19.4415 15.5115 19.7583 15.1208 19.7583H5.94843C5.55766 19.7583 5.24088 19.4415 5.24088 19.0508Z"
        fill={fill ?? 'currentColor'}
      />
      <path
        d="M15.3417 4.54307C15.2214 4.20563 14.8304 4.02485 14.4717 4.13532C14.1129 4.2458 13.9174 4.61337 14.0356 4.94881L15.1097 7.9597H14.6886C14.3084 7.9597 14.0012 8.24693 14.0012 8.60246C14.0012 8.95798 14.3084 9.24521 14.6886 9.24521H15.5673L16.7853 12.6618C16.882 12.933 17.1591 13.1118 17.4641 13.1017C17.7692 13.0917 18.0312 12.8928 18.1043 12.6156L19.0065 9.24521H19.9947L20.8969 12.6156C20.97 12.8928 21.2321 13.0917 21.5371 13.1017C21.8421 13.1118 22.1193 12.933 22.2159 12.6618L23.434 9.24521H24.3126C24.6928 9.24521 25 8.95798 25 8.60246C25 8.24693 24.6928 7.9597 24.3126 7.9597H23.8915L24.9656 4.94881C25.0859 4.61136 24.8904 4.24781 24.5317 4.13532C24.1729 4.02284 23.782 4.20563 23.6617 4.54106L22.4415 7.9597H21.0666L20.1666 4.58927C20.0914 4.30405 19.8164 4.10319 19.5006 4.10319C19.1848 4.10319 18.9099 4.30405 18.8347 4.58927L17.9324 7.9597H16.5576L15.3417 4.54307ZM17.0173 9.24521H17.5887L17.3438 10.1611L17.0173 9.24521ZM19.3502 7.9597L19.5006 7.39529L19.651 7.9597H19.3502ZM21.4125 9.24521H21.9839L21.6574 10.1611L21.4125 9.24521Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconTaxWon;
