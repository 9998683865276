import styled from 'styled-components';

export const AddArsCardWrap = styled.div`
  z-index: 3;
  position: absolute;
  top: calc(50% - 2rem);
  right: -20rem;
  width: 20rem;
  font-size: 1.4rem;

  &.selectBottom {
    top: calc(100% + 7rem);
    right: auto;
    left: 4.8rem;
  }
  button {
    cursor: pointer;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.txt500};
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    min-height: 4rem;
    padding: 0.6rem 1rem;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 0.8rem;
    margin-bottom: 0.6rem;
    :hover {
      border-color: ${(props) => props.theme.colors.main};
    }
  }
  > .top {
    color: ${(props) => props.theme.colors.txt100};
    background: ${(props) => props.theme.colors.main};
  }

  @media screen and (max-width: 540px) {
    button {
      font-size: 1.3rem;
      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
`;
