import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { my015StatusRecoil } from '@/recoil/atoms/use015Status';
import { pushAlertRecoil } from '@/recoil/atoms/atoms';
import { useMutationUpdateNotiStatus } from '@/components/hooks/mutations/getApis';
/**
 * @title 메세지 푸시 알림 hook
 *
 * @author 정휘학
 * @since 2024.05.21
 * */
export const usePushNotifyCationSMS = () => {
  /* 015 사용자의 정보 데이터 */
  const my015StatusS = useRecoilValue(my015StatusRecoil);
  /* 웹 푸시 알람 toggle */
  const setPushAlertS = useSetRecoilState(pushAlertRecoil);

  // ===================================================================================================================
  // 메세지 푸시 알림 상태 변경 GET API
  // ===================================================================================================================
  const {
    mutate: updateNotiStatusM,
    isLoading: updateNotiStatusL,
    data: updateNotiStatusD,
  } = useMutation(useMutationUpdateNotiStatus);

  // ===================================================================================================================
  // 기존에 설정되어 있는 메세지 푸시알림 상태 적용
  // ===================================================================================================================
  useEffect(() => {
    if (!my015StatusS) return;
    setPushAlertS((prevData) => {
      return {
        ...prevData,
        smsAlertStatus: my015StatusS.messageNotiStatus,
      };
    });
  }, [my015StatusS]);
  // ===================================================================================================================
  // 메세지 알림 handle toggle hook
  // ===================================================================================================================
  const handlePushAlertToggleToMsg = () => {
    updateNotiStatusM();
  };

  useEffect(() => {
    if (updateNotiStatusD && !updateNotiStatusL) {
      if (updateNotiStatusD === '변경 실패') return;
      setPushAlertS((prevData) => {
        return {
          ...prevData,
          smsAlertStatus: !prevData.smsAlertStatus,
        };
      });
    }
  }, [updateNotiStatusD, updateNotiStatusL]);

  return {
    handlePushAlertToggleToMsg,
  };
};
