import React from 'react';
import * as S from '../MobileHeader.style';
import IconAddAddress from '@/styles/icons/IconAddAddress';
import { useSetRecoilState } from 'recoil';
import { addModalOpenRecoil, addModalTitleRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

const AddressBuddyListHeader = () => {
  // 추가 방 상태 리코일
  const setAddModalOpenS = useSetRecoilState(addModalOpenRecoil);
  // 추가 방 제목 리코일
  const setAddModalTitleS = useSetRecoilState(addModalTitleRecoil);

  const onClickAddModalOpen = () => {
    setAddModalOpenS(true);
    setAddModalTitleS('주소록 추가');
    window.location.hash = '#add';
  };

  return (
    <>
      {/* 주소록 추가 버튼 */}
      <S.IconContainer className="addressBuddyListHeader">
        <S.HeaderRightButtonIcon
          onClick={() => {
            onClickAddModalOpen();
          }}
        >
          <IconAddAddress />
        </S.HeaderRightButtonIcon>
      </S.IconContainer>
    </>
  );
};

export default AddressBuddyListHeader;
