import React from 'react';
import styled from 'styled-components';
import * as TS from '@/styles/table.styles';

const CallingNumberInputContainer = styled.div`
  table {
    border-top: 0;
  }
  th {
    font-weight: 600;
  }
  width: 100%;
  @media screen and (max-width: 540px) {
    th,
    td,
    input {
      font-size: 1.6rem;
    }
    td {
      span {
        display: block;
        margin-top: 1rem;
        font-size: 1.4rem;
      }
      textarea {
        font-size: 1.6rem;
      }
    }
  }
`;

const PhoneNumberInput = styled.input`
  cursor: pointer;
  width: 100%;
  max-width: 31rem;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt500};
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
  @media screen and (max-width: 640px) {
    max-width: 100%;
  }
`;

const PhoneNumberTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  min-height: 10rem;
  padding: 0.5rem;
  border: none;
  background-color: ${(props) => props.theme.colors.gray200};
  text-align: start;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt500};
  /* font-weight: lighter; */
  resize: none;
`;

const ValidationError = styled.span`
  font-size: 1rem;
  color: red;
  margin-left: 10px;
`;

const CallingNumberInput = ({ register, errors }: { register: any; errors: any }) => {
  return (
    <CallingNumberInputContainer className="callingNumberInput">
      <TS.MyPageTable className="borTop0 thBorderLeft thBackStyles">
        <tbody>
          <tr>
            <th>등록할 발신번호</th>
            <td>
              <PhoneNumberInput
                id="postcallnumber"
                type="text"
                maxLength={11}
                onChange={() => {}}
                placeholder="숫자만 입력해주세요."
                {...register('callbackNo')}
              />
              <ValidationError>{errors.callbackNo?.message}</ValidationError>
            </td>
          </tr>

          <tr>
            <th>결과통지번호</th>
            <td>
              <PhoneNumberInput
                id="postalarmPhone"
                name="alarmPhone"
                type="text"
                maxLength={11}
                onChange={() => {}}
                placeholder="숫자만 입력해주세요."
                {...register('alarmPhone')}
              />
              <ValidationError>{errors.alarmPhone?.message}</ValidationError>
            </td>
          </tr>

          <tr>
            <th>등록할 서류</th>
            <td>
              <PhoneNumberInput id="postfile" type="file" onChange={() => {}} {...register('file')} />
              <span className="red"> 10MB이하 파일 첨부 가능</span>
            </td>
          </tr>

          <tr>
            <th style={{ verticalAlign: 'middle' }}>메모</th>
            <td>
              <PhoneNumberTextarea
                id="postremark"
                onChange={() => {}}
                {...register('remark')}
                // placeholder="숫자만 입력해주세요."
              />
            </td>
          </tr>
        </tbody>
      </TS.MyPageTable>
    </CallingNumberInputContainer>
  );
};

export default CallingNumberInput;
