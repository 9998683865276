import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface BaseInputProps {
  type?: string;
  name?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  backgroundColor?: string;
  marginBotttom?: string;
  marginLeft?: string;
  marginRight?: string;
  padding?: string;
  paddingLeft?: string;
  paddingRight?: string;
  border?: string;
  borderBottom?: string;
  hidden?: boolean;
  value?: string | number | string[];
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  borderColor?: any;
  autoFocus?: boolean;
  maxLength?: number;
}

const StyledInput = styled.input<BaseInputProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.gray100)};
  margin-left: ${(props) => props.marginLeft};
  color: ${(props) => (props.color ? props.color : props.theme.colors.txt500)};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBotttom};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  padding: ${(props) => props.padding};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  /* border-color: ${(props) => (props.borderColor ? props.borderColor : props.theme.colors.buttonBorder)}; */
  box-sizing: border-box;
  transition: all 0.1s;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.gray200};
  }
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.main};
  }
`;

const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      type,
      name,
      width,
      height,
      backgroundColor,
      placeholder,
      marginLeft,
      marginRight,
      marginBotttom,
      padding,
      paddingLeft,
      paddingRight,
      border,
      borderBottom,
      hidden,
      value,
      onChange,
      onKeyPress,
      onKeyDown,
      onKeyUp,
      onFocus,
      onBlur,
      disabled,
      borderColor,
      autoFocus,
      maxLength,
    },
    ref,
  ) => {
    return (
      <StyledInput
        ref={ref}
        type={type}
        name={name}
        disabled={disabled}
        width={width}
        height={height}
        backgroundColor={backgroundColor}
        placeholder={placeholder}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginBotttom={marginBotttom}
        padding={padding}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        border={border}
        borderBottom={borderBottom}
        hidden={hidden}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        borderColor={borderColor}
        autoFocus={autoFocus}
        maxLength={maxLength}
        className="edit-input"
      />
    );
  },
);

BaseInput.defaultProps = {
  marginLeft: '0px',
  marginRight: '0px',
  padding: '.3rem .5rem',
};

export default BaseInput;
