import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  activeChatRoomIdRecoil,
  amlQueryParamsRecoil,
  chatRoomTypeRecoil,
  chatSaveNumberRecoil,
  chatUserInfoRecoil,
  mhQueryParamsRecoil,
} from '@/recoil/atoms/chatRoom';
import {
  IClickEachListH,
  IUseQueryAllMessageList,
} from '@/components/softPhone/DisplayGroups/MessageManagement/ChatRoom/ChatRoom.types';
import { msg015ChatRoomToggleRecoil, msgResultToggleRecoil } from '@/recoil/atoms/say015Atom';
import { softPhoneSaveMessageRecoil, softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';
import { useEffect, useState } from 'react';
import { fetchInfinite, InfiniteQueryKeyType } from '@/components/hooks/queries/fetchInfinite';
import { useQueryClient } from '@tanstack/react-query';
import { environmentRecoil, resultDataRecoil, resultOpenRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

/**
 * @title 015 개별 채팅방 리스트 Hooks
 * */
export type Message015Type = {
  batch: boolean;
  buddyName: string;
  direction: 'mt' | 'mo';
  msgDttm: string;
  msgType: 'SMS' | 'LMS' | 'MMS' | 'VMS' | 'FILE';
  phoneNumber: string;
  rcvMsgCount: number;
  sndMsg: string;
  chatRoomId: string;
  chatName: string;
};

export const useEachChatRoomHook = () => {
  /* 소프트폰 채팅방 리스트 GET API PARAMS */
  const [amlQueryParams] = useRecoilState(amlQueryParamsRecoil);
  /* 좌측 컴포넌트 표출상태 recoil */
  const [msg015ChatRoomToggleR, setMsg015ChatRoomToggleR] = useRecoilState(msg015ChatRoomToggleRecoil);
  /* 전송결과 디테일페이지 */
  const setMsgResultToggleR = useSetRecoilState(msgResultToggleRecoil);
  /* 현재 활성화된 채팅방 아이디 */
  const [activeChatRoomId, setActiveChatRoomId] = useRecoilState(activeChatRoomIdRecoil);

  /* messageHistory api 요청 params */
  const [, setMhQueryParams] = useRecoilState(mhQueryParamsRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const [chatUserInfo, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);
  /* 메인 주소록 테이블 width  */
  const [mainTableWidth, setMainTableWidth] = useRecoilState(mainTableWidthRecoil);
  /* 마지막 활성화 채팅 상대방 번호 */
  const [, setChatSaveNumber] = useRecoilState(chatSaveNumberRecoil);
  /* 그룹채팅방인지 1:1 채팅방인지 구분하는 Recoil */
  const [chatRoomTypeS, setChatRoomTypeS] = useRecoilState(chatRoomTypeRecoil);
  /* 단체결과페이지토글 */
  const [resultOpenS, setResultOpens] = useRecoilState(resultOpenRecoil);
  const [resultDataS, setResultDataS] = useRecoilState(resultDataRecoil);
  const [amlDataS, setAmlDataS] = useState<Message015Type[]>([]);
  const environmentS = useRecoilValue(environmentRecoil);

  const queryClient = useQueryClient();
  const authorityData: any = queryClient.getQueryData(['authorityData']);
  const [softPhoneTopTab] = useRecoilState(softPhoneTopTabRecoil);
  const [saveMessageToggle, setSaveMessageToggle] = useRecoilState(softPhoneSaveMessageRecoil);
  /**
   * @title 소프트폰 채팅방 리스트 GET API
   * */
  const queryKey: InfiniteQueryKeyType<IUseQueryAllMessageList> = ['/smt/history/latest/app', { ...amlQueryParams }];
  const { inFiniteResult, handleInfiniteScroll } = fetchInfinite<IUseQueryAllMessageList, any>({
    params: amlQueryParams,
    method: 'GET',
    queryKey,
    /* testCode수정 */
    enabled: softPhoneTopTab === '0' && authorityData && authorityData.say015User === true,
    // enabled: false,
  });

  useEffect(() => {
    const { data, isLoading } = inFiniteResult;
    if (!isLoading && data) {
      const flatData: Message015Type[] = data.pages?.flatMap((item) =>
        item.content.map((contentItem: Message015Type) => contentItem),
      );
      setAmlDataS(flatData);
    }
  }, [inFiniteResult.isLoading, inFiniteResult.data]);

  // 메세지 저장함 상태 확인해서 채팅룸 false
  useEffect(() => {
    if (saveMessageToggle) {
      setMsg015ChatRoomToggleR(false);
      setMsgResultToggleR(false);
      setChatUserInfo({ phoneNumber: null, buddyName: null });
      setActiveChatRoomId(null);
    }
  }, [saveMessageToggle]);

  /**
   * @title 개별 채팅방 리스트 클릭시 015 채팅 내역 버튼 활성화 ( 좌측 채팅방 표출 )
   * */
  const clickEachListH = ({ buddyName, phoneNumber, batch }: IClickEachListH) => {
    // 마지막 활성화 채팅방 user number 저장 recoil
    setChatSaveNumber(phoneNumber);
    if (batch) {
      setChatRoomTypeS('multi');
    } else {
      setChatRoomTypeS('single');
    }
    setResultOpens(false);
    // 주소록 width 값 줄이기

    const tableWidth = (document.getElementById('mainTable')?.clientWidth ?? 1100) - 450;

    if (phoneNumber === chatUserInfo.phoneNumber) {
      setMainTableWidth(0);
      setChatUserInfo({ phoneNumber: null, buddyName: null });
      setActiveChatRoomId(null);
      setMhQueryParams({ pageSize: 30, pageNumber: 0, contactNumber: null });
      setMsg015ChatRoomToggleR(false);
      setMsgResultToggleR(false);
    } else {
      setSaveMessageToggle(false); // 채팅방이 열리면 메세지 저장함 false
      setMsgResultToggleR(false);
      setMainTableWidth(tableWidth);
      setMsg015ChatRoomToggleR(true);
      setChatUserInfo({ buddyName, phoneNumber });
      setActiveChatRoomId(phoneNumber);
      setMhQueryParams((prevState: any) => {
        return {
          ...prevState,
          contactNumber: phoneNumber,
        };
      });
    }
  };

  return {
    clickEachListH,
    handleInfiniteScroll,
    inFiniteResult,
    amlDataS,
    activeChatRoomId,
  };
};
