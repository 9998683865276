import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';
import { ChangeEvent, useEffect, useState } from 'react';

/* ARS CARD TITLE 수정을 위한 HOOKS */
interface IUseArsCardTitle {
  data: NextItem;
}

export const useArsCardTitle = (props: IUseArsCardTitle) => {
  // 표시, 제출할 제목을 담을 STATE
  const [cardTitle, setCardTitle] = useState<string>(props.data?.s015Data.title);

  // 제목을 수정할 onChange Hook
  const onChangeCardTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setCardTitle(e.target.value);
  };

  useEffect(() => {
    setCardTitle(props.data?.s015Data.title);
  }, [props.data?.s015Data.title]);

  return { onChangeCardTitle, cardTitle };
};
