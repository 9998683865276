import React from 'react';
import * as S from '../MessageSend.styles';

interface IMessageSendImgModal {
  fileResult: any;
  onClickSendImgToggle: () => void;
  reSendFileState: any;
  setFileModal: React.Dispatch<React.SetStateAction<boolean>>;
}
/**
 * @title MessageSend.tsx 컴포넌트의 이미지 확대 모달 컴포넌트
 *
 * @author 김남규
 * @since 2024-04-09
 */
const MessageSendImgModal = (props: IMessageSendImgModal) => {
  return (
    <S.MessageSendImgModal className="MessageSendImgModal">
      <div>
        <button onClick={props.onClickSendImgToggle}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 11 10" fill="none">
            <path
              d="M10.5371 9.13821C10.5835 9.18466 10.6204 9.23981 10.6455 9.3005C10.6707 9.36119 10.6836 9.42624 10.6836 9.49194C10.6836 9.55763 10.6707 9.62268 10.6455 9.68337C10.6204 9.74407 10.5835 9.79922 10.5371 9.84567C10.4906 9.89212 10.4355 9.92897 10.3748 9.95411C10.3141 9.97925 10.249 9.99219 10.1833 9.99219C10.1176 9.99219 10.0526 9.97925 9.99191 9.95411C9.93121 9.92897 9.87607 9.89212 9.82961 9.84567L5.68359 5.69902L1.53757 9.84567C1.44376 9.93948 1.31652 9.99219 1.18384 9.99219C1.05117 9.99219 0.923929 9.93948 0.830114 9.84567C0.736298 9.75185 0.683594 9.62461 0.683594 9.49194C0.683594 9.35926 0.736298 9.23202 0.830114 9.13821L4.97676 4.99219L0.830114 0.846168C0.736298 0.752353 0.683594 0.625112 0.683594 0.492438C0.683594 0.359763 0.736298 0.232522 0.830114 0.138707C0.923929 0.0448922 1.05117 -0.0078125 1.18384 -0.0078125C1.31652 -0.0078125 1.44376 0.0448922 1.53757 0.138707L5.68359 4.28535L9.82961 0.138707C9.92343 0.0448922 10.0507 -0.0078125 10.1833 -0.0078125C10.316 -0.0078125 10.4433 0.0448922 10.5371 0.138707C10.6309 0.232522 10.6836 0.359763 10.6836 0.492438C10.6836 0.625112 10.6309 0.752353 10.5371 0.846168L6.39043 4.99219L10.5371 9.13821Z"
              fill="#fff"
            />
          </svg>
        </button>
        {props.reSendFileState === props.fileResult ? (
          // 재전송시 받아온 데이터 이미지
          <img className="largeImg" src={`data:image/jpeg;base64,${props.fileResult}`} alt="largeImg" />
        ) : (
          //  input 태그를 통한 이미지
          <img className="largeImg" src={props.fileResult} alt="largeImg" />
        )}
      </div>
    </S.MessageSendImgModal>
  );
};
export default MessageSendImgModal;
