import { resultDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import { useRecoilState } from 'recoil';

export const useMessageResultDetail = () => {
  const [resultDetailParamsS, setResultDetailParamsS] = useRecoilState(resultDetailParamsRecoil);

  const onClickMessage = (data: any) => {
    setResultDetailParamsS({ prepayPayNo: data.id, callback: data.callback });
  };

  return { onClickMessage, resultDetailParamsS };
};
