import { deleteDelGroups } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { mainTableCacheUpdate } from '@/components/cache/mainTableCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { checkGroupListState } from '@/recoil/atoms/addressList';
import { delLoadingRecoil, mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { activeGroupRecoil } from '@/recoil/atoms/softPhone';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMainTableReset } from '@/components/hooks/customs/resetRecoil/useMainTableReset';
import { editModeRecoil } from '@/recoil/atoms/router/mainRouterRecoil';

/**
 * @title 그룹 리스트 데이터 DELETE Hooks
 *
 * @author 김남규
 * @since 2024.03.15
 */

export const useDeleteGroup = () => {
  /* 삭제할 그룹 groupSeqNo 배열 STATE */
  const [mainTableCheckDataFormatR, setMainTableCheckDataFormatR] = useRecoilState(mainTableCheckDataFormatRecoil);
  /* API 중복 요청 방지 버튼 STATE */
  const [delGroupState, setDelGroupState] = useState(false);
  const [, setCheckedGroupList] = useRecoilState(checkGroupListState);
  /* 좌측 그룹 active 를 원격으로 설정가능한 recoil */
  const [activeGroupS, setActiveGroupS] = useRecoilState(activeGroupRecoil);
  /* 주소록 메인 테이블 캐싱 데이터 업데이트 hook */
  const { mainTableGroupDeleteToCacheUpdate } = mainTableCacheUpdate();
  /* 좌측 그룹, 주소록 체크박스 초기화 */
  const { mainTableResetRecoil } = useMainTableReset();
  /* 로더를 위한 STATE */
  const [delLoadingS, setDelLoadingS] = useRecoilState<boolean>(delLoadingRecoil);
  const { confirmModal, warningModal, successModal } = useModalHook();

  /* 반응형 그룹 수정 토글 RECOIL */
  const setEditMode = useSetRecoilState(editModeRecoil);

  const cacheHooks = groupListCacheUpdate();

  // ==============================
  // 그룹 리스트 데이터 DELETE API Mutation
  // ==============================
  const { mutate: deleteDelGroupsMutate, isLoading: deleteDelGroupsIsLoading } = useMutation(deleteDelGroups);

  useEffect(() => {
    if (delLoadingS !== deleteDelGroupsIsLoading) setDelLoadingS(deleteDelGroupsIsLoading);
  }, [deleteDelGroupsIsLoading]);

  // ==============================
  // 그룹 리스트 삭제 validate 검증 후 success, error 처리 Hook
  // ==============================
  const deleteGroupOnClickHandler = () => {
    if (deleteDelGroupsIsLoading) return;
    setDelGroupState(true);
    const groupCheckArr = mainTableCheckDataFormatR
      ?.filter((item) => item.groupCheck)
      .flatMap((item) => item.groupSeqNo);

    if (!(groupCheckArr && groupCheckArr.length > 0)) {
      setDelGroupState(false);
      warningModal('그룹 삭제', '선택된 그룹이 없습니다. 삭제할 그룹을 선택해주세요.', true);
      return;
    }
    confirmModal(
      async () => {
        if (deleteDelGroupsIsLoading) return;
        deleteDelGroupsMutate(groupCheckArr, {
          onSuccess: (data, variables) => {
            setDelGroupState(false);
            handleDeleteSuccess(variables as number[]);
            // 반응형 상태값 변경
            setEditMode(false);
            setCheckedGroupList([]);
            setMainTableCheckDataFormatR(null);
          },
          onError: (error: any) => {
            setDelGroupState(false);
            warningModal('그룹 삭제', error.response.message, true);
            // 반응형 상태값 변경
            setEditMode(false);
            setCheckedGroupList([]);
            setMainTableCheckDataFormatR(null);
          },
        });
      },
      '그룹 삭제',
      '해당 그룹에 속한 연락처는 "휴지통"으로 이동합니다.</br>삭제 하시겠습니까?',
      true,
    );
  };

  const deleteGroup = (el: any) => {
    confirmModal(
      async () => {
        if (deleteDelGroupsIsLoading) return;
        deleteDelGroupsMutate([el], {
          onSuccess: (data, variables) => {
            setDelGroupState(false);
            handleDeleteSuccess(variables as number[]);
          },
          onError: (error: any) => {
            setDelGroupState(false);
            warningModal('그룹 삭제', error.response.message, true);
          },
        });
      },
      '그룹 삭제',
      '해당 그룹에 속한 연락처는 "휴지통"으로 이동합니다.</br>삭제 하시겠습니까?',
      true,
    );
  };

  // ==============================
  // 그룹삭제 성공시 실행 hook
  // ==============================
  const handleDeleteSuccess = (variables: number[]) => {
    /* 체크박스 초기화 */
    mainTableResetRecoil();
    /* 주소록 메인 테이블 그룹 삭제에 따른 캐싱 업데이트 */
    mainTableGroupDeleteToCacheUpdate(variables);
    /* 만약 삭제된 그룹의 주소록을 표출하고 있었으면 전체그룹으로 이동 */
    if (activeGroupS?.groupSeqNo && variables.includes(activeGroupS?.groupSeqNo)) {
      setActiveGroupS({
        groupSeqNo: null,
        groupNm: null,
        change: true,
      });
    }
    // 그룹리스트 cache update
    cacheHooks.handleGroupDeleteCacheUpdate(variables);

    setDelGroupState(false);
    setCheckedGroupList([]);

    successModal('그룹 삭제', '그룹을 삭제했습니다.', true);
  };

  // ==============================
  // 개별 그룹 삭제시 실행 hook
  // ==============================
  const onClickOneGroupDelete = (groupSeqNo: number) => {
    if (deleteDelGroupsIsLoading) return;
    confirmModal(
      async () => {
        setDelGroupState(true);
        deleteDelGroupsMutate([groupSeqNo], {
          onSuccess: (data, variables) => {
            setDelGroupState(false);
            handleDeleteSuccess(variables as number[]);
          },
          onError: (error: any) => {
            setDelGroupState(false);
            warningModal('그룹 삭제', error.response.message, true);
          },
        });
      },
      '그룹 삭제',
      '해당 그룹에 속한 연락처는 "휴지통"으로 이동합니다.</br>삭제 하시겠습니까?',
      true,
    );
  };

  return { deleteGroupOnClickHandler, delGroupState, delLoadingS, onClickOneGroupDelete, deleteGroup };
};
