import { useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult } from '@tanstack/react-query';
import React from 'react';
import { instance } from '@/shared/lib/clientAxios';
import { calScrollEvent, calScrollUpEvent } from '@/apis/hooks/useScrollInfinity';

// queryKey 타입
export type InfiniteQueryKeyType<K> = [string, Record<keyof K, any>];

interface IFetchInfinite<P, K> extends UseInfiniteQueryOptions<K> {
  params: P;
  queryKey: InfiniteQueryKeyType<K>;
  refetchOnWindowFocus?: boolean;
  method?: 'GET' | 'POST';
  scrollType?: 'DOWN' | 'UP';
}

/**
 * @title useInfiniteQuery 공통 함수
 *
 * @author 정휘학
 * @since 2024.05.24
 * @params {P, K} P : params 제네릭 타입, K : queryKey 제네릭 타입
 * */
export const fetchInfinite = <P, K>({
  params,
  queryKey,
  refetchOnWindowFocus = false,
  method,
  scrollType = 'DOWN',
  ...props
}: IFetchInfinite<P, K>) => {
  // ===================================================================================================================
  // Method 타입이 GET 일 경우
  // ===================================================================================================================
  const fetchInfiniteGetResource = async ({ pageParam = 0 }) => {
    const paramsData = {
      ...params,
      pageNumber: pageParam,
    };
    const response = await instance.get(`${queryKey[0]}`, { params: paramsData });

    return response.data;
  };
  // ===================================================================================================================
  // Method 타입이 POST 일 경우
  // ===================================================================================================================
  const fetchInfinitePostResource = async ({ pageParam = 0 }) => {
    const data = {
      ...params,
      pageNumber: pageParam,
    };
    const response = await instance.post(`${queryKey[0]}`, data);

    return response.data;
  };

  // ===================================================================================================================
  // InfiniteQuery scroll 트리거 함수
  // ===================================================================================================================
  const handleInfiniteScroll = (event: React.UIEvent<any>, props: UseInfiniteQueryResult) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    // 상단 스크롤 감지
    if (scrollType === 'UP') {
      if (
        calScrollUpEvent({
          scrollTop,
          scrollHeight,
          clientHeight,
        }) &&
        props.hasNextPage &&
        !props.isFetchingNextPage
      ) {
        props.fetchNextPage();
      }
    }
    // 하단 스크롤 감지
    if (scrollType === 'DOWN') {
      if (
        calScrollEvent({
          scrollTop,
          scrollHeight,
          clientHeight,
        }) &&
        props.hasNextPage &&
        !props.isFetchingNextPage
      ) {
        props.fetchNextPage();
      }
    }
  };

  // ===================================================================================================================
  // Method 타입에 따른 함수 분기 로직
  // ===================================================================================================================
  let methodFunc: any;

  if (method === 'GET') methodFunc = fetchInfiniteGetResource;
  if (method === 'POST') methodFunc = fetchInfinitePostResource;

  // ===================================================================================================================
  // useInfiniteQuery 로직
  // ===================================================================================================================
  const inFiniteResult = useInfiniteQuery<K>(queryKey, methodFunc, {
    getNextPageParam: (lastPage: any) => {
      const nextPage = lastPage.pageable.pageNumber + 1;
      return nextPage < lastPage.totalPages ? nextPage : undefined;
    },
    refetchOnWindowFocus,
    ...props,
  });

  return {
    inFiniteResult,
    handleInfiniteScroll,
  };
};
