import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { ENQUIRY_PHONE } from '@/shared/constants';
import Logo from '../Atom/Logo';

const Container = styled.footer`
  width: 100%;
  /* background-color: ${(props) => props.theme.colors.gray800}; */
  background-color: #212121;
  position: relative;
  font-size: 1.4rem;
  color: #f4f4f4;
`;

const Inner = styled.div`
  margin: 0 auto;
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  @media screen and (max-width: 760px) {
    flex-wrap: wrap;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 760px) {
    padding-top: 0;
    margin-bottom: 2rem;
  }
`;

const Left = styled.div``;

const Right = styled.div`
  @media screen and (max-width: 760px) {
    order: -1;
  }
  img {
    max-width: 100%;
  }
`;

const H3 = styled.h3`
  font-weight: bold;
  color: #f5f5f5;
`;

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1.2rem 0;
  gap: 1rem;
`;

const TopEl = styled.div`
  color: #f5f5f5;
  padding-right: 30px;
`;

const Bottom = styled.div``;

const Info = styled.div`
  display: flex;
  padding: 12px 0;
  color: #f5f5f5;
  @media screen and (max-width: 760px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

const BottomEl = styled(Link)`
  padding: 0 1.4rem;
  text-decoration: underline;
  border-right: 1px solid #ccc;
  color: #f5f5f5;

  :first-child {
    padding-left: 0;
  }

  :last-child {
    border: none;
  }
  @media screen and (max-width: 760px) {
    flex-wrap: wrap;
    padding: 0;
    padding-right: 1rem;
    padding-left: 0;
  }
`;

const CopyRight = styled.p``;

const Footer = () => {
  const topEl = [
    '대표이사 : 이주호',
    '서울시 강남구 봉은사로 18길 84 4층',
    '사업자등록번호 : 114-86-19326',
    '통신판매업신고번호 : 강남-11625',
  ];
  const bottomEl = ['이용약관', '개인정보처리방침', '청소년보호정책', '스팸정책', '전자메일무단수집거부'];

  const LinkList = ['ComUseContent', 'ComProtect', 'ComYouthpolicy', 'ComSpampolicy', 'ComEmailveto'];

  const [pathName, setPathName] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPathName(window.location.pathname);
    }
  }, [navigate]);

  return pathName === '/addressbook' || pathName === '/' || pathName === '/1' || pathName === '/2' ? null : (
    <Container className="footer">
      <Inner>
        <Left>
          <H3>회사 소개</H3>
          <Top>
            {topEl.map((el) => {
              return <TopEl key={el}>{el}</TopEl>;
            })}
          </Top>
          {/*<H3>고객센터 : 02-3485-6720(6755)</H3>*/}
          <H3>고객센터 : {ENQUIRY_PHONE}</H3>
          <Bottom>
            <Info>
              {bottomEl.map((el, idx) => {
                // eslint-disable-next-line react/no-array-index-key
                return (
                  <BottomEl to={`/${LinkList[idx]}`} key={el}>
                    {el}
                  </BottomEl>
                );
              })}
            </Info>
          </Bottom>
          <CopyRight>© Seoul Mobile telecom, Inc. All rights reserved.</CopyRight>
        </Left>
        <Right>
          <LogoWrapper>
            <Logo eType="Home" sType="W" />
          </LogoWrapper>
        </Right>
      </Inner>
    </Container>
  );
};

export default Footer;
