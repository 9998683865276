import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconBook: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2.4rem'}
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M5.06625 25.5C4.21467 25.5 3.4905 25.1991 2.89375 24.5972C2.29792 23.9935 2 23.262 2 22.4028V3.59722C2 2.73704 2.29792 2.00556 2.89375 1.40278C3.49142 0.800926 4.21558 0.5 5.06625 0.5H17.125C17.7355 0.5 18.258 0.719907 18.6925 1.15972C19.1288 1.59861 19.347 2.12639 19.347 2.74306V18.5556C19.347 19.1722 19.1293 19.7 18.6939 20.1389C18.2585 20.5796 17.7355 20.8 17.125 20.8H5.06625C4.607 20.8 4.21054 20.9579 3.87687 21.2736C3.54229 21.5912 3.375 21.9833 3.375 22.45C3.375 22.9167 3.54229 23.3102 3.87687 23.6306C4.21146 23.9509 4.60837 24.1111 5.06762 24.1111H21.7794C22.026 24.1111 22.2285 24.031 22.3871 23.8708C22.5457 23.7106 22.625 23.506 22.625 23.2569V3.97222C22.625 3.775 22.691 3.61019 22.823 3.47778C22.955 3.34537 23.1186 3.2787 23.3139 3.27778C23.5091 3.27685 23.6723 3.34352 23.8034 3.47778C23.9345 3.61204 24 3.77685 24 3.97222V23.2569C24 23.8736 23.7823 24.4014 23.3469 24.8403C22.9115 25.2792 22.389 25.4991 21.7794 25.5H5.06625ZM8.02938 19.4111H17.125C17.3725 19.4111 17.5751 19.3306 17.7328 19.1694C17.8913 19.0093 17.9706 18.8046 17.9706 18.5556V2.74444C17.9706 2.49444 17.8913 2.28935 17.7328 2.12917C17.5742 1.96898 17.3716 1.88889 17.125 1.88889H8.02938V19.4111ZM6.65438 19.4111V1.88889H5.06625C4.58683 1.88889 4.18488 2.05787 3.86038 2.39583C3.53679 2.73194 3.375 3.13241 3.375 3.59722V19.9722C3.6225 19.8139 3.88558 19.6806 4.16425 19.5722C4.44292 19.4639 4.74404 19.4097 5.06762 19.4097L6.65438 19.4111Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconBook;
