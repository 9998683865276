import React, { useEffect } from 'react';
import * as S from './MobileMessageChatRoomFooter.style';
import { useChatRoom015Foot } from '@/components/hooks/customs/chatRoom015/useChatRoom015Foot';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { footerHeightRecoil } from '@/recoil/atoms/chatRoom';
import SendMsgByte from '@/components/softPhone/commons/SendMsgByte';
import IconPlus from '@/styles/icons/IconPlus';
import IconSend from '@/styles/icons/IconSend';
import { useLocation } from 'react-router-dom';
import { lastPathSegment } from '@/shared/util/appUtil';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { multiChatRoomIdRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

interface IMobileMessageChatRoomFooter {
  chatRoomRef: React.RefObject<HTMLDivElement>;
  chatFootRef: React.RefObject<HTMLDivElement>;
}

const MobileMessageChatRoomFooter = (props: IMobileMessageChatRoomFooter) => {
  const location = useLocation();
  const hooks = useChatRoom015Foot({ chatRoomRef: props.chatRoomRef, chatFootRef: props.chatFootRef });
  /* 메세지 내용 작성 foot 컴포넌트 height 상태 변환 로직 */
  const chatFootCurrent = props.chatFootRef.current;
  const setFooterHeightS = useSetRecoilState(footerHeightRecoil);
  const { confirmModal } = useModalHook();

  //단체 채팅방 아이디
  const multiChatRoomId = useRecoilValue(multiChatRoomIdRecoil);

  const newPath = location.pathname;

  useEffect(() => {
    if (chatFootCurrent) {
      setFooterHeightS(chatFootCurrent.offsetHeight);
    }
  }, [chatFootCurrent]);

  return (
    <form className="etcChattingFoot" onSubmit={hooks.sendMsgHandler}>
      <S.EtcChattingFoot ref={props.chatFootRef}>
        {hooks.sendMsgText.length > 0 ? (
          <S.EtcChattingTextByteText className="byteText">
            <SendMsgByte
              sndMsg={hooks.sendMsgText}
              file={hooks.sendImgFileUrl}
              phoneType={'015'}
              adMessage={hooks.adMessageS}
              byteS={hooks.byteS}
              setByteS={hooks.setByteS}
              msgTypeS={hooks.msgTypeS}
              setMsgTypeS={hooks.setMsgTypeS}
            />
            {/*SMS {hooks.sendMsgByte}/2,000 Byte*/}
          </S.EtcChattingTextByteText>
        ) : null}
        <S.EtcChattingSendWrap>
          {hooks?.sendImgFileUrl && hooks?.sendImgFile && (
            <S.EtcChattingFootAddImageWrap className="etcChattingFootAddImageWrap">
              {(() => {
                const { type, name } = hooks?.sendImgFile ?? { type: '', name: '' }; // 기본값 제공

                // 파일 미리보기
                if (type?.startsWith('image/')) {
                  return <S.EtcChattingFootAddImage src={hooks?.sendImgFileUrl} alt={name} />;
                }
                if (type?.startsWith('video/')) {
                  return <S.EtcChattingFootAddVideo controls src={hooks?.sendImgFileUrl} />;
                }
                if (type?.startsWith('audio/')) {
                  return <S.EtcChattingFootAddAudio controls src={hooks?.sendImgFileUrl} />;
                }
                return <div className="fileName">{name}</div>; // 파일 타입이 지원되지 않는 경우
              })()}

              {/* 파일 삭제 버튼 */}
              <S.EtcChattingFootAddImageCloseBtn type="button" onClick={hooks.cancelImgFile}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                  <path
                    d="M6.82843 6.82854C6.76592 6.89106 6.68113 6.92617 6.59272 6.92617C6.50432 6.92617 6.41953 6.89106 6.35702 6.82854L4 4.47152L1.64298 6.82854C1.58047 6.89106 1.49568 6.92617 1.40728 6.92617C1.31887 6.92617 1.23408 6.89106 1.17157 6.82854C1.10906 6.76603 1.07394 6.68125 1.07394 6.59284C1.07394 6.50444 1.10906 6.41965 1.17157 6.35714L3.5286 4.00012L1.17157 1.64309C1.10906 1.58058 1.07394 1.4958 1.07394 1.40739C1.07394 1.31899 1.10906 1.2342 1.17157 1.17169C1.23408 1.10918 1.31887 1.07406 1.40728 1.07406C1.49568 1.07406 1.58047 1.10918 1.64298 1.17169L4 3.52871L6.35702 1.17169C6.41953 1.10918 6.50432 1.07406 6.59272 1.07406C6.68113 1.07406 6.76592 1.10918 6.82843 1.17169C6.89094 1.2342 6.92606 1.31899 6.92606 1.40739C6.92606 1.4958 6.89094 1.58058 6.82843 1.64309L4.4714 4.00012L6.82843 6.35714C6.89094 6.41965 6.92606 6.50444 6.92606 6.59284C6.92606 6.68125 6.89094 6.76603 6.82843 6.82854Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  />
                </svg>
              </S.EtcChattingFootAddImageCloseBtn>
            </S.EtcChattingFootAddImageWrap>
          )}
          {hooks.chatRoomRef &&
            hooks.chatRoomRef?.scrollHeight !== hooks.chatRoomRef?.clientHeight &&
            hooks.rcvMsgPopupToggle && (
              <S.EctChattingNewMsgBtn type={'button'} onClick={() => hooks.onClickRcvPopup()}>
                <S.EctChattingNewMsgBtnText>새 메세지가 있습니다.</S.EctChattingNewMsgBtnText>
              </S.EctChattingNewMsgBtn>
            )}

          <S.EtcChattingFootGroupWrap>
            <S.EtcChattingImageAddBtn type={'button'}>
              <S.EtcChattingImageAddlabel
                onClick={() => {
                  hooks.onClickInputFile();
                }}
              >
                <IconPlus />
              </S.EtcChattingImageAddlabel>

              <S.EtcChattingImageAddInput
                type="file"
                ref={hooks.sendImgFileRef}
                id="fileAdd"
                name="fileAdd"
                // TODO - hotfix : 010 모드와 동일하게 파일을 받습니다.
                accept=".jpg, .jpeg"
                // accept={hooks.acceptFile015Types}
                onChange={hooks.onChangeUploadImg}
              />
            </S.EtcChattingImageAddBtn>

            <S.EtcChattingSendTextArea
              ref={hooks.textAreaRef}
              rows={1}
              value={hooks.sendMsgText}
              disabled={hooks.isProcessingToSend}
              placeholder="메세지 입력"
              onKeyDown={async (e: any) => {
                if (e.key === 'Enter' && e.shiftKey) {
                  // 단체 전송방에서 보낼 때
                  if (newPath === '/mobile/message/messageList/multiChatroom') {
                    hooks.multiSendMsgHandler(e, multiChatRoomId);
                  } else {
                    // 개별 채팅방에서 보낼 때
                    if (hooks.isProcessingToSend) return;
                    await hooks.sendMsgHandler(e);
                  }
                }
              }}
              onChange={(e) => {
                hooks.onChangeSendMsgText(e);
                setFooterHeightS(chatFootCurrent ? chatFootCurrent.offsetHeight : 0);
              }}
            />

            <S.EtcChattingSendBtn
              type="button"
              className={`chattingSendBtn ${hooks.sendMsgL || hooks.sendMsgInfoL ? 'loadingActive' : ''}`}
              disabled={hooks.sendMsgL || hooks.sendMsgInfoL}
              onClick={(e) => {
                if (newPath === '/mobile/message/messageList/multiChatroom') {
                  hooks.multiSendMsgHandler(e, multiChatRoomId);
                } else {
                  // 개별 채팅방에서 보낼 때
                  hooks.sendMsgHandler(e);
                }
              }}
            >
              <IconSend />
            </S.EtcChattingSendBtn>
          </S.EtcChattingFootGroupWrap>
        </S.EtcChattingSendWrap>
      </S.EtcChattingFoot>
    </form>
  );
};
export default MobileMessageChatRoomFooter;
