import * as S from '@/pages/main/components/etcLeftZone/ui/EtcLeftZone.styles';
import React from 'react';
import { useEtcLeftZone } from '@/pages/main/components/etcLeftZone/hooks/useEtcLeftZone';

/**
 * @title 주소록과 병행하는 좌측 컴포넌트 ( 010 전송결과, 010 예약내역, 015 ars )
 *
 * @author 정휘학
 * @since 2024.03.19
 * */
const EtcLeftZoneComponent = () => {
  const hooks = useEtcLeftZone();

  return (
    <S.SendResultLeftZone
      style={{
        opacity: hooks.listToggleState && !hooks.addressOpenToggleState ? '1' : '0',
        top: hooks.listToggleState && !hooks.addressOpenToggleState ? '0' : '-300%',
        left: hooks.listToggleState && !hooks.addressOpenToggleState ? '' : '-200%',
        order: hooks.listToggleState && !hooks.addressOpenToggleState ? '1' : '2',
        height: hooks.listToggleState && !hooks.addressOpenToggleState ? '' : '0',
      }}
    >
      {hooks.components[hooks.leftZoneComponentS]}
    </S.SendResultLeftZone>
  );
};

export default EtcLeftZoneComponent;
