import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postSleepIdLeave } from '../api/signUpApis';

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();

  const { successModal, warningModal, confirmModal } = useModalHook();

  // 휴먼아이디 삭제하는 mutation
  const { mutate, isLoading: deleteLoading } = useMutation(postSleepIdLeave, {
    onSuccess: (res) => {
      if (res.code === '200') {
        queryClient.refetchQueries(['/users/id']);
        successModal('성공', '아이디 삭제에 성공하셨습니다.', true);
      } else {
        warningModal('실패', '아이디 삭제에 실패하셨습니다.', true);
      }
    },
    onError: (error: any) => {
      console.error('error', error);
    },
  });

  const postDeleteData = ({ datas }: any) => {
    mutate(datas);
  };

  const handleAccountDelete = ({ e, data }: any) => {
    const datas = {
      encodeData: data,
      phnId: e.target.value,
    };
    confirmModal(async () => postDeleteData({ datas }), '경고', '아이디를 삭제하시겠습니까?', true);
  };

  return { handleAccountDelete, deleteLoading };
};
