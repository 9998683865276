import { BuddyData } from '@/types/index.types';
import { itemsByGroupProps } from '@/components/hooks/customs/types/Hooks.types';

/* 엑셀파일 keyMap */
const excelKeyMap: { [key: string]: string } = {
  이름: 'buddyNm',
  휴대폰번호: 'keyCommNo',
  이메일: 'emailId',
  메모: 'etcInfo',
  기타정보: 'etcInfo', // 구 아레오 메모 key 임
  '015번호': 'number015',
  회사이름: 'coNm',
  회사: 'coNm', // 구 아레오 회사이름 key
  부서: 'coDept',
  직함: 'coHandle',
  직급: 'coHandle', // 구 아레오 직함
  회사전화: 'coNumber',
  팩스: 'faxNumber',
  회사우편번호: 'coZipCd',
  회사주소: 'coAddr',
  집전화: 'homeNumber',
  전화번호: 'homeNumber', // 구 아레오 집전화
  집우편번호: 'homeZipCd',
  집주소: 'homeAddr',
  홈페이지: 'homepageUrl',
  메신저: 'messenger',
  생일: 'birthday',
  결혼기념일: 'marriageDay',
  성별: 'genderFlag',
  주민번호: 'residentNo',
  계좌번호: 'accountNo',
};

/* 주소록 컬럼 항목 선택 value */
const addressColumViewValue = [
  // 'groupNm', // 그룹
  'buddyNm', // 이름
  'keyCommNo', // 휴대폰번호
  'number015', // 015번호
  'emailId', // 이메일
  'coNm', // 회사이름
  'coDept', // 부서
  'coHandle', // 직함
  'coNumber', // 회사전화
  'faxNumber',
  'coZipCd',
  'coAddr', // 회사주소
  'homeNumber', // 집전화
  'homeZipCd',
  'homeAddr', // 집주소
  'homepageUrl',
  'messenger',
  'birthday',
  'marriageDay',
  'genderFlag',
  'etcInfo', // 메모
  'residentNo',
  'accountNo', // 계좌번호
];
/* 주소록 컬럼 항목 선택 label */
const addressColumViewLabel = [
  // '그룹',
  '이름',
  '휴대폰번호',
  '015번호',
  '이메일',
  '회사이름',
  '부서',
  '직함',
  '회사전화',
  '팩스',
  '회사우편번호',
  '회사주소',
  '집전화',
  '집우편번호',
  '집주소',
  '홈페이지',
  '메신저',
  '생일',
  '결혼기념일',
  '성별',
  '메모',
  '주민번호',
  '계좌번호',
];

/* 주소록 선택항목 팝업에 표출되는 아이템 */
const addressItemsByGroup: itemsByGroupProps[] = [
  {
    head: '기본정보',
    children: [
      { id: 'buddyNm', value: 'buddyNm', label: '이름' },
      { id: 'keyCommNo', value: 'keyCommNo', label: '휴대폰번호' },
      { id: 'number015', value: 'number015', label: '015번호' },
      { id: 'emailId', value: 'emailId', label: '이메일' },
    ],
  },

  {
    head: '직장',
    children: [
      { id: 'coNm', value: 'coNm', label: '회사이름' },
      { id: 'coDept', value: 'coDept', label: '부서' },
      { id: 'coHandle', value: 'coHandle', label: '직함' },
      { id: 'coNumber', value: 'coNumber', label: '회사전화' },
      { id: 'faxNumber', value: 'faxNumber', label: '팩스' },
      { id: 'coZipCd', value: 'coZipCd', label: '회사우편번호' },
      { id: 'coAddr', value: 'coAddr', label: '회사주소' },
    ],
  },

  {
    head: '집',
    children: [
      { id: 'homeNumber', value: 'homeNumber', label: '집전화' },
      { id: 'homeZipCd', value: 'homeZipCd', label: '집우편번호' },
      { id: 'homeAddr', value: 'homeAddr', label: '집주소' },
    ],
  },

  {
    head: '상세정보',
    children: [
      { id: 'homepageUrl', value: 'homepageUrl', label: '홈페이지' },
      { id: 'messenger', value: 'messenger', label: '메신저' },
      { id: 'birthday', value: 'birthday', label: '생일' },
      { id: 'marriageDay', value: 'marriageDay', label: '결혼기념일' },
      { id: 'genderFlag', value: 'genderFlag', label: '성별' },
      { id: 'etcInfo', value: 'etcInfo', label: '메모' },
      { id: 'residentNo', value: 'residentNo', label: '주민번호' },
      { id: 'accountNo', value: 'accountNo', label: '계좌번호' },
    ],
  },
];

/* 주소록 테이블 리스트 데이터 형식 */
const addressBuddyData: BuddyData = {
  groupSeqNo: 0,
  buddySeqNo: 0,
  groupNm: '',
  buddyNm: '',
  accountNo: '',
  birthday: '',
  coAddr: '',
  coDept: '',
  coHandle: '',
  coNm: '',
  coNumber: '',
  coZipCd: '',
  emailId: '',
  etcInfo: '',
  faxNumber: '',
  genderFlag: '',
  homeAddr: '',
  homeNumber: '',
  homepageUrl: '',
  homeZipCd: '',
  keyCommNo: '',
  marriageDay: '',
  messenger: '',
  natCd: '',
  number015: '',
  regDt: '',
  residentNo: '',
  siteId: '',
  updDt: '',
  usrId: '',
  usrKey: '',
};

/* 주소록 정렬 데이터 */
type AddressSortListData =
  | 'buddyNm'
  | 'groupNm'
  | 'keyCommNo'
  | 'emailId'
  | 'etcInfo'
  | 'number015'
  | 'faxNumber'
  | 'coZipCd'
  | 'coNm'
  | 'coDept'
  | 'coHandle'
  | 'coNumber'
  | 'coAddr'
  | 'homeZipCd'
  | 'homeNumber'
  | 'homeAddr'
  | 'homepageUrl'
  | 'messenger'
  | 'birthday'
  | 'marriageDay'
  | 'genderFlag'
  | 'residentNo'
  | 'accountNo';

export {
  excelKeyMap,
  addressBuddyData,
  addressItemsByGroup,
  addressColumViewValue,
  addressColumViewLabel,
  AddressSortListData,
};
