import styled from 'styled-components';

export const BottomTextContainer = styled.div`
  font-size: 1.6rem;
  margin: 6rem 0 0;

  & > span {
    display: block;
    font-weight: bold;
    margin: 0 0 1rem;
  }
`;

export const TextP = styled.p`
  line-height: 1.8em;
  font-size: 1.3rem;
`;
