import { amountFormat } from '@/shared/util/format/amountFormatUtil';
import { useCallingNumberAdmin } from '@/components/hooks/customs/myPage/useCallingNumberAdmin';
import React from 'react';
import * as SB from '../../../../../styles/button.styles';
import * as S from './JoinCompletion.styles';
import { WORKS015_ANNUAL, WORKS015_MONTHLY } from '@/shared/constants';
import { join015ToggleRecoil } from '@/recoil/atoms/say015Atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { softPhoneComponentRecoil, softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useMessageManagement } from '@/components/hooks/customs/useMessageManagement';
import { useToolBarListPage } from '@/components/hooks/customs/softPhone/useToolBarListPage';

interface IJoinCompletion {
  setOpen015SubPage?: any;
  initial015SubPage?: any;
  say015SubHandle?: any;
  pickNumber?: any;
  authorityData?: any;
  authorityDataRefetch: any;
  authorityDataState: any;
}

const JoinCompletion = ({
  setOpen015SubPage,
  initial015SubPage,
  say015SubHandle,
  pickNumber,
  authorityData,
  authorityDataRefetch,
  authorityDataState,
}: IJoinCompletion) => {
  const user = localStorage?.getItem('user');

  // 소프트폰 top tap Recoil
  const softPhoneTopTabS = useRecoilValue(softPhoneTopTabRecoil);

  const callnumHook = useCallingNumberAdmin();
  const navHooks = useToolBarListPage();
  const msgHooks = useMessageManagement();

  // 월간가격
  const monthlyPrice = parseInt(WORKS015_MONTHLY as string, 10);

  // 연간가격
  const annualPrice = parseInt(WORKS015_ANNUAL as string, 10);

  const handleChangePage = () => {
    if (softPhoneTopTabS === '0') {
      navHooks.onClickTap('0');
      msgHooks.handleChangeComponent('chatroom');
    }
    if (softPhoneTopTabS === '2') {
      navHooks.onClickTap('2');
      msgHooks.handleChangeComponent('ars');
    }
  };

  const subOkHandle = () => {
    authorityDataRefetch();
    // callnumHook.callingNumberListRefetch();
    callnumHook.allCallingNumberListRefetch();

    handleChangePage();
  };

  return (
    <S.JoinCompletionWrap>
      <div className="completionGroup">
        <div className="completionTextBox">
          <h4>
            <span className="user">{user}</span> 님
          </h4>
          <div className="phoneGroup">
            <h3>
              <span className="number">{`015-${pickNumber.firstPart}-${pickNumber.secondPart}`}</span>으로
              <br />
              서비스 이용 신청이 완료되었습니다.
            </h3>
          </div>
          {/* <div className="description">
            <p>
              이벤트 기간 이후, 회원정보에서 월 통신료({amountFormat(monthlyPrice)}원) 결제 및 연간 통신료(17%할인)
              결제를 하실 수 있습니다. 이벤트 기간 이후 월 구독료 {authorityDataState.monthlyPrice}원이 서울이동통신
              충전 캐쉬로 청구됩니다.
              <span className="gray">
                (서비스 구독 취소는 회원정보에서 가능하며, 이용기간 만료 안내는 만료 14일 전 푸시 알림으로 공지됩니다.)
              </span>
            </p>
          </div> */}
        </div>
        <SB.Btn100>
          <button className="startBtn" onClick={subOkHandle}>
            확인
          </button>
        </SB.Btn100>
      </div>
    </S.JoinCompletionWrap>
  );
};

export default JoinCompletion;
