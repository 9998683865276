import { useSendResultDetailTable } from '@/components/hooks/customs/addressBook/useSendResultDetailTable';
import { useCloseResult } from '@/components/hooks/customs/useCloseResult';
import { useSendResultDetail } from '@/components/hooks/customs/useSendResultDetail';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { underLine } from '@/shared/util/format/contentFormatUtil';
import { formatDateBase } from '@/shared/util/format/dateFormatUtil';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import React, { useEffect, useState } from 'react';
import * as IS from '@/styles/input.styles';
import * as S from './ResultDetailBox.styles';
import * as SS from '../sendRoom.styles';
import * as IMS from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/ChatRoom015Body.styled';
import { useChatRoom015Image } from '@/components/hooks/customs/chatRoom015/useChatRoom015Image';
import { Link } from 'react-router-dom';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';

const ResultDetailBox = () => {
  /* 전송결과 상세페이지 HOOKS */
  const hooks = useSendResultDetail();
  const closeHooks = useCloseResult();
  /* Hooks */
  const sendResultTableHooks = useSendResultDetailTable({
    setSearchAllValue: hooks.setSearchAllValue,
  });
  const imageHooks = useChatRoom015Image();

  // 전송결과 온클릭이벤트 색상표시
  type TActiveStatus = '전송완료' | '전송중' | '전송실패' | string | null;
  type selectItem = { label: string; value: string | null };

  const [activeStatus, setActiveStatus] = useState<TActiveStatus>(null);
  const [popupState, setPopupState] = useState<boolean>(false);

  const sendClick = (item: any) => {
    if (item && item.label) {
      setActiveStatus((prevStatus) => (prevStatus === item.label ? null : item.label));
      sendResultTableHooks.onChangeRsltValue(item); // item 전달
    }
  };

  useEffect(() => {
    setPopupState(!popupState);
  }, []);

  const popupClick = () => {
    setPopupState(!popupState);
  };

  useEffect(() => {
    activeStatus === null && sendResultTableHooks.onChangeRsltValue({ label: '전체', value: null }); // item 전달
  }, [activeStatus]);

  return (
    // SendResultDetail - 전송결과
    <S.ResultDetail className="resultDetail">
      <SS.SendRoomWrap>
        <div className="topWrap">
          <button className="closeBtn" onClick={() => closeHooks.onClickCloseBtn()}>
            <IconArrowLongLeft />
          </button>
          <p>{hooks.detailInfo && hooks?.detailInfo.callback ? formatPhoneNumber(hooks.detailInfo?.callback) : 'ㅡ'}</p>
        </div>
        <SS.MessageContent>
          <div className="messageGroup">
            {/* 제목 : SMS 존재X, LMS, MMS 일 떄 존재하면 제목, 없으면 제목없음으로 표기 */}
            {hooks.detailInfo && hooks.detailInfo.msgGb !== 'SMS' && hooks.detailInfo.subject && (
              <h2 className="title">{hooks.detailInfo.subject}</h2>
            )}
            {/* 이미지 */}
            {hooks.detailInfo && hooks.detailInfo.imageData && (
              <div className="imgWrap">
                <img
                  src={`data:image/jpeg;base64,${hooks.detailInfo.imageData}`}
                  alt="이미지"
                  onClick={() => imageHooks.closeUpImgHook(hooks.detailInfo.imageData)}
                  onError={imageHooks.handleImgError}
                />
              </div>
            )}
            {/* 내용 */}
            <p className="messageTxt">{hooks.detailInfo && underLine(hooks.detailInfo?.sndMsg ?? '')}</p>
          </div>
          <div className="stateWrap">
            <div>
              <p className="date">
                {hooks.detailInfo && hooks.detailInfo?.sndDttm ? formatDateBase(hooks.detailInfo?.sndDttm) : 'ㅡ'}
              </p>
            </div>
            <div className="byte">
              {/* MMS Byte */}
              <p>
                {hooks.detailInfo && hooks.detailInfo?.msgGb ? hooks.detailInfo.msgGb : 'SMS'}{' '}
                {hooks.detailInfo && hooks.detailInfo?.msgGb ? calMsgByte(hooks.detailInfo.sndMsg) : '0'} Byte
              </p>
            </div>
          </div>
        </SS.MessageContent>

        <SS.SendContent className="sendContent">
          <ul>
            {sendResultTableHooks.rsltValArr.map((item) => {
              return (
                <>
                  <li
                    key={item.value}
                    className={item.label === activeStatus ? 'active' : ''}
                    onClick={() => sendClick(item)}
                  >
                    <>
                      <p className="txt">{item.label}</p>
                      <p className="sendCount">
                        {item.value === '-100' && hooks.detailInfo?.successCount}
                        {item.value === '99' && hooks.detailInfo?.sendingCount}
                        {item.value === '100' && hooks.detailInfo?.failCnt}
                      </p>
                    </>
                  </li>
                </>
              );
            })}
          </ul>
        </SS.SendContent>

        {/* 검색 - 현재는 연락처로 고정 검색이 되는데 api 수정되면 keyword값 삭제할 예정 */}
        <form onSubmit={sendResultTableHooks.onClickSearchButton}>
          <IS.InputBox className="searchInput">
            <input
              type="text"
              placeholder="검색"
              value={sendResultTableHooks.keywordState}
              onChange={(e: any) => {
                sendResultTableHooks.setKeywordState(e.currentTarget.value);
              }}
            />
            <button type="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path
                  d="M12.2346 11.9104L15.3457 15.0215M1.3457 7.24371C1.3457 8.89394 2.00126 10.4766 3.16815 11.6435C4.33504 12.8104 5.91769 13.4659 7.56793 13.4659C9.21816 13.4659 10.8008 12.8104 11.9677 11.6435C13.1346 10.4766 13.7901 8.89394 13.7901 7.24371C13.7901 5.59347 13.1346 4.01082 11.9677 2.84393C10.8008 1.67704 9.21816 1.02148 7.56793 1.02148C5.91769 1.02148 4.33504 1.67704 3.16815 2.84393C2.00126 4.01082 1.3457 5.59347 1.3457 7.24371Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </IS.InputBox>
        </form>
        <SS.SendResultWrap className="sendResultWrap" onScroll={(e) => hooks.handleScroll(e)}>
          <ol>
            {hooks.tableData.length > 0 ? (
              hooks.tableData.map((item) => {
                return (
                  // 전송결과 리스트
                  <li key={item.cmpMsgId}>
                    <h4>{item.buddyName ? item.buddyName : item.rcvPhnId}</h4>
                    {/* 전송 상태에 탭에 따라 달라질 데이터 */}
                    {/* 탭 클릭 X - 전체 리스트 */}
                    {!activeStatus && (
                      <p>
                        {String(item?.rsltVal) === '-100' && '성공'}
                        {String(item?.rsltVal) === '99' && '전송중'}
                        {!(String(item?.rsltVal) === '99' || String(item?.rsltVal) === '-100') && '실패'}
                      </p>
                    )}
                    {/* 전송완료 탭 */}
                    {activeStatus === '전송완료' && <p>{formatDateBase(item.sndDttm)}</p>}
                    {/* 전송중 탭 */}
                    {activeStatus === '전송중' && <p>전송중</p>}
                    {/* 전송실패 탭 */}
                    {activeStatus === '전송실패' && <p>{item?.failedMessage ?? null}</p>}
                  </li>
                );
              })
            ) : (
              <SS.Nodata>No Data</SS.Nodata>
            )}
          </ol>

          {activeStatus === '전송실패' && popupState && (
            <SS.SnedPopupContainer>
              <SS.SendPopupContents>
                전송 실패 건은 24시간 이내에 자동 환불됩니다.
                <br />
                캐쉬 차감 및 환불 내역은 [마이페이지 &gt;사용내역]에서 확인 할 수 있습니다.
              </SS.SendPopupContents>
              <div>
                <SS.StyledLink to="/usage">사용 내역 페이지 바로 가기</SS.StyledLink>
              </div>
              <SS.CloseBtn onClick={popupClick} />
            </SS.SnedPopupContainer>
          )}
        </SS.SendResultWrap>

        {/* ******************************************************************************************* */}
        {/* 전체 재전송 만들어 지면 이 로직으로 바꾸기!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
        {/* ******************************************************************************************* */}
        {/* {activeStatus === '전송실패' ? (
          <>
            {hooks.tableData.length > 0 && (
              <SS.SendEdit className="marT20 sendEdit">
                <button className="delBtn" type="button" onClick={() => sendResultTableHooks.reSendMsg()}>
                  전체 재전송
                </button>
              </SS.SendEdit>
            )}
          </>
        ) : (
          <SS.SendEdit className="marT20 sendEdit">
            <button className="delBtn" type="button" onClick={() => sendResultTableHooks.deleteMsg()}>
              전송 내역 삭제하기
            </button>
          </SS.SendEdit>
        )} */}

        <SS.SendEdit className="marT20 sendEdit">
          <button className="delBtn" type="button" onClick={() => sendResultTableHooks.deleteMsg()}>
            전송 내역 삭제하기
          </button>
        </SS.SendEdit>
      </SS.SendRoomWrap>

      {/* 이미지 확대 */}
      {imageHooks.modalSwitch ? (
        <IMS.EtcChattingBigImageModal onClick={imageHooks.autoClosePopup}>
          <IMS.EtcChattingBigImageWrap id={'closeUpView'} ref={imageHooks.closeUpViewRef}>
            <IMS.EtcChattingBigImage src={`data:image/jpeg;base64,${imageHooks.closeUpImg}`} alt="testimage" />
            <IMS.EtcChattingBigImageModalcloseBtn type="button" onClick={imageHooks.closeUpImgPopupClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                <path
                  opacity="0.9"
                  d="M4.54078 29.389C4.1518 29.778 3.62424 29.9966 3.07414 29.9966C2.52404 29.9966 1.99648 29.778 1.6075 29.389C1.21853 29 1 28.4724 1 27.9223C1 27.3722 1.21853 26.8446 1.6075 26.4556L12.5659 15.5L1.61095 4.54098C1.22198 4.15198 1.00345 3.62439 1.00345 3.07426C1.00345 2.52413 1.22198 1.99654 1.61095 1.60754C1.99993 1.21854 2.52749 0.999999 3.07759 0.999999C3.62769 0.999999 4.15525 1.21854 4.54423 1.60754L15.4991 12.5666L26.4575 1.61099C26.8465 1.22199 27.374 1.00345 27.9241 1.00345C28.4742 1.00345 29.0018 1.22199 29.3908 1.61099C29.7798 1.99999 29.9983 2.52758 29.9983 3.07771C29.9983 3.62784 29.7797 4.15544 29.3908 4.54444L18.4324 15.5L29.3925 26.459C29.7815 26.848 30 27.3756 30 27.9257C30 28.4759 29.7815 29.0035 29.3925 29.3925C29.0035 29.7815 28.476 30 27.9259 30C27.3758 30 26.8482 29.7815 26.4592 29.3925L15.4991 18.4335L4.54078 29.389Z"
                  fill="white"
                />
              </svg>
            </IMS.EtcChattingBigImageModalcloseBtn>
          </IMS.EtcChattingBigImageWrap>
        </IMS.EtcChattingBigImageModal>
      ) : null}
    </S.ResultDetail>
  );
};
export default ResultDetailBox;
