import { WORKS015_STT } from '@/shared/constants';
import { Modal } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// 모달 스타일 시작 ====================
const ConfirmModalWrap = styled.div`
  font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;

  .flexBox {
    display: flex;
  }
  h3 {
    font-weight: 600;
  }
  .text {
    margin-bottom: 1.4rem;
    font-size: 1.4rem;
    line-height: 1.4em;
    p {
      color: #7f7f7f;
    }
  }
`;
/* width: ${(props) => props.fillPercentage || '0%'}; */
// 모달 스타일 종료 ====================
const StateGroup = styled.div`
  font-size: 1.2rem;
  .flexBox {
    justify-content: space-between;
    color: #7f7f7f;
  }
  .top {
  }
  .bottom {
    p {
      :nth-of-type(1) {
        color: #4863b3;
      }
    }
  }
`;
const GaugeBar = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background: #eaeaea;
  border-radius: 10px;
`;

const Bar = styled.span<{ width: number }>`
  position: absolute;
  width: 0;
  top: 0;
  left: 0;
  width: 0;
  height: 6px;
  display: block;
  border-radius: 10px;
  background: #4863b3;
  animation: barActive 1s linear forwards;
  @keyframes barActive {
    to {
      /* remainPercentage */
      width: ${(props) => props.width}%;
    }
  }
`;

const TimeBox = styled.div`
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    height: 4rem;
    background: #f5f5f5;
    font-size: 1.4rem;
    border-radius: 1rem;
    gap: 10%;
    > div {
      width: 46%;
      max-width: 96px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .num {
        font-weight: 700;
      }
    }
  }
  > p {
    font-size: 1.2rem;
    margin-top: 0.6rem;
    color: #b3b3b3;
  }
`;

interface IUseModalReturn {
  confirmModal: (
    onOk: () => Promise<void> | void,
    title?: string,
    content?: any,
    data?: { duration: number; sttTime: number } | undefined,
    centered?: boolean,
    path?: string,
    onCancel?: () => Promise<void> | void,
  ) => void;
}

export const useSttModal = (): IUseModalReturn => {
  // stt price
  const sttPrice: number = parseInt(WORKS015_STT as string, 10);
  const splitedSttContentFun = (content: any, data: { duration: number; sttTime: number } | undefined) => {
    // duration = stt 사용시간, sttTime : 남은 무료시간
    if (data) {
      const allTime = 300; // 무료 총시간
      const usedTime = data?.sttTime; // 무료 남은시간
      const remainTime = allTime - usedTime; // 사용한시간
      const remainPercentage = (usedTime / allTime) * 100; // 잔여시간
      const usePercentage = (remainTime / allTime) * 100; // 총 사용시간
      const usePoint = data.duration >= data.sttTime ? (data.duration - data.sttTime) * sttPrice : 0; // 사용할 캐시

      const splitedContent = (
        <ConfirmModalWrap className="ConfirmModalWrap">
          {/* 무료시간 충분 */}
          {content === 1 && (
            <>
              <div className="text">
                <h3>녹음된 내용을 텍스트로 변환해드리는 서비스입니다.</h3>
                {/* TODO - STT 주석 */}
                {/* <p>
                  매 월 5분({allTime}초)의 무료 변환시간을 제공하며, 무료 변환시간 소진 시 초당 {sttPrice}원의 요금이
                  발생합니다.
                </p> */}
              </div>
              <StateGroup>
                {/* <div className="flexBox top">
                  <p>잔여 시간: {usedTime}초</p>
                  <p>무료 변환시간</p>
                </div> */}
                {/* TODO - STT 주석 */}
                {/* <GaugeBar>
                  <Bar className="bar" width={remainPercentage} />
                </GaugeBar> */}
                <div className="flexBox bottom">
                  {/* TODO - STT 주석 */}
                  {/* <p>잔여 시간: {usedTime}초</p> */}
                  {/* <p>{usedTime}</p>
                  <p>{allTime}</p> */}
                </div>
              </StateGroup>
            </>
          )}

          {/* 포인트 충분 */}
          {content === 2 && (
            <>
              <div className="text">
                <h3>녹음된 내용을 텍스트로 변환해드리는 서비스입니다.</h3>
                {/* TODO - STT 주석 */}
                {/* <p>
                  매 월 5분({allTime}초)의 무료 변환시간을 제공하며, 무료 변환시간 소진 시 초당 {sttPrice}원의 요금이
                  발생합니다.
                </p> */}
              </div>
              {/* TODO - STT 주석 */}
              {/* <TimeBox>
                <div className="box">
                  <div>
                    <p>변환 시간:</p>
                    <p className="num">{data.duration}초</p>
                  </div>
                  <div>
                    <p>잔여 시간:</p>
                    <p className="num">{usedTime}초</p>
                  </div>
                </div>
                <p>* 초과시간에 대해 요금이 부과될 예정입니다. &#40;{usePoint}원&#41;</p>
              </TimeBox> */}
            </>
          )}
          {/* 포인트 부족 */}
          {content === 3 && (
            <div className="text">
              <h3>보유금액이 부족합니다. 충전소로 이동하시겠습니까?</h3>
            </div>
          )}
        </ConfirmModalWrap>
      );
      return splitedContent;
    }
    return null;
  };
  const navigate = useNavigate();

  const confirmModal = (
    onOk: () => Promise<void> | void,
    title?: string,
    content?: any,
    data?: { duration: number; sttTime: number },
    centered?: boolean,
    path?: string,
    onCancel?: () => void,
  ) => {
    const splitedContent = splitedSttContentFun(content, data);

    Modal.confirm({
      title,
      content: splitedContent,
      centered,
      okText: '확인',
      cancelText: '취소',
      onOk: async () => {
        await onOk();
        if (path) {
          navigate(path);
        }
      },
      onCancel,
    });
  };

  return {
    confirmModal,
  };
};
