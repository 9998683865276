import * as CH from '@/styles/checkBox.styles';
import React from 'react';
import * as S from '@/features/ars/arsScenario/ui/ArsScenario.styles';
import { useScenario } from '@/features/ars/arsScenario/hooks/useScenario';

const ArsScenarioListComponent = () => {
  const {
    arsScenarioInfoListS,
    arsMemoS,
    selectedArsScenarioInfoS,
    handleScenarioSelected,
    arsTitleS,
    handleArsTitle,
    arsActiveS,
    handleDeleteARS,
    arsSelectClick,
    handleAddARS,
    arsSelect,
    handleArsMemo,
    handleArsActive,
    arsFileNameS,
  } = useScenario();
  return (
    // TODO - style display none 삭제시 시나리오 표출
    // <S.ArsScenarioWrap className="arsScenarioWrap" style={{ display: 'none' }}>
    <S.ArsScenarioWrap className="arsScenarioWrap">
      <S.Subtitle>ARS 시나리오</S.Subtitle>

      <div className="scenarioSetWrap">
        {/* 시나리오 선택 */}
        <div className={`scenarioSelect ${arsSelect ? 'active' : ''}`}>
          {/* 선택된 시나리오 */}
          <div className="pick" onClick={arsSelectClick}>
            {selectedArsScenarioInfoS && (
              <button key={selectedArsScenarioInfoS.fileName}>
                {/*<p>{selectedArsScenarioInfoS.title ? selectedArsScenarioInfoS.title : `새로운 시나리오`}</p>*/}
                <p>{arsTitleS ? arsTitleS : `새로운 시나리오`}</p>
                {/*<span className="gray">&#40;기본&#41;</span>*/}
              </button>
            )}
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
              <path
                d="M6.18968 5.06373L10.5078 1.66933C10.5799 1.61269 10.6201 1.53744 10.6201 1.4592C10.6201 1.38095 10.5799 1.3057 10.5078 1.24906L10.5029 1.24541C10.468 1.21786 10.4259 1.19592 10.3793 1.18093C10.3327 1.16593 10.2825 1.1582 10.2317 1.1582C10.181 1.1582 10.1308 1.16593 10.0842 1.18093C10.0375 1.19592 9.99547 1.21786 9.96053 1.24541L5.89448 4.44186L1.83007 1.24541C1.79512 1.21786 1.75306 1.19592 1.70643 1.18093C1.65981 1.16593 1.6096 1.1582 1.55886 1.1582C1.50812 1.1582 1.45792 1.16593 1.41129 1.18093C1.36467 1.19592 1.3226 1.21786 1.28766 1.24541L1.28278 1.24906C1.21069 1.3057 1.17048 1.38095 1.17048 1.45919C1.17048 1.53744 1.21069 1.61269 1.28278 1.66933L5.60092 5.06373C5.63889 5.09359 5.68456 5.11735 5.73517 5.13359C5.78577 5.14983 5.84025 5.1582 5.8953 5.1582C5.95035 5.1582 6.00483 5.14983 6.05543 5.13359C6.10603 5.11735 6.1517 5.09359 6.18968 5.06373Z"
                fill="currentColor"
                stroke="currentColor"
              />
            </svg>
          </div>
          {/* 사용자 시나리오 list */}
          <ul>
            {arsScenarioInfoListS?.map((item) => {
              return (
                <li key={item.fileName} onClick={() => handleScenarioSelected(item.fileName)}>
                  <button className={`${item.fileName === arsFileNameS ? 'selected' : ''}`}>
                    <p>{item.title ? item.title : `새로운 시나리오`}</p>
                    {!item.active && (
                      <div className="iconTrash" onClick={(e) => handleDeleteARS(e, `${item.fileName}`)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <path
                            d="M2.87457 3.10975V13.8902C2.87446 14.1981 2.93501 14.503 3.05277 14.7874C3.17053 15.0719 3.34319 15.3303 3.56088 15.548C3.77857 15.7657 4.03702 15.9384 4.32147 16.0561C4.60592 16.1739 4.91078 16.2344 5.21864 16.2343H11.7814C12.4028 16.2343 12.9988 15.9874 13.4383 15.548C13.8777 15.1086 14.1246 14.5126 14.1246 13.8911V3.10889M1 3.10975H16"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.68726 3.57834V2.17155C5.68726 1.98685 5.72365 1.80396 5.79436 1.63333C5.86507 1.4627 5.9687 1.30767 6.09935 1.17711C6.22999 1.04654 6.38508 0.943001 6.55576 0.872398C6.72643 0.801794 6.90934 0.765512 7.09404 0.765625H9.9059C10.0906 0.765512 10.2735 0.801794 10.4442 0.872398C10.6149 0.943001 10.77 1.04654 10.9006 1.17711C11.0312 1.30767 11.1349 1.4627 11.2056 1.63333C11.2763 1.80396 11.3127 1.98685 11.3127 2.17155V3.57834M6.6254 13.0348V8.34919M10.3754 13.0348V8.34919"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                  </button>
                </li>
              );
            })}
            {/* 시나리오 추가하기 */}
            {arsScenarioInfoListS && arsScenarioInfoListS.length < 2 && (
              <div className="addScenario">
                <button onClick={handleAddARS}>시나리오 추가하기</button>
              </div>
            )}
          </ul>
        </div>

        {/* 시나리오 이름 설정 */}
        <div className="scenarioName">
          <input
            type="text"
            placeholder="시나리오 이름을 설정해주세요. (선택)"
            value={arsTitleS ? arsTitleS : ''}
            onChange={handleArsTitle}
            maxLength={10}
          />
        </div>

        {/* 메모 */}
        <div className="scenarioMemo">
          <textarea
            name="memo"
            placeholder="메모"
            value={arsMemoS ? arsMemoS : ''}
            onChange={handleArsMemo}
            maxLength={50}
          />
        </div>
        {/* 사용 토글 */}
        <S.ScenarioSetting className="scenarioSetting">
          <div className="top">
            <h3>기본 ARS 시나리오 설정</h3>
            <CH.SwitchButton>
              <input id="chargeFree" type="checkbox" checked={arsActiveS} onChange={handleArsActive} />
              <label htmlFor="chargeFree">
                <span className="button" />
              </label>
            </CH.SwitchButton>
          </div>
          <p>* 생성한 여러 시나리오 중 기본 ARS 시나리오로 설정된 시나리오가 재생됩니다.</p>
        </S.ScenarioSetting>
      </div>
    </S.ArsScenarioWrap>
  );
};

export default ArsScenarioListComponent;
