import React, { useState } from 'react';
import ATitle from '@/components/Atom/ATitle';
import TaxInvoiceAuto from '@/components/Organism/Charge/TaxInvoiceAuto';
import * as S from './MobileEtcTaxInvoiceAuto.style';
import TaxInvoiceHistoryTable from '@/components/Organism/Charge/TaxInvoiceHistoryTable';
import MbrCorpInsert from '@/components/Molecules/Charge/TaxInvoiceAuto/MbrCorpInsert';
import { usePublishTaxBill } from '@/components/hooks/customs/charge/TaxInvoiceAuto/usePublishTaxBill';
import TaxInvoiceTop from '../../../Molecules/Charge/TaxInvoiceAuto/TaxInvoiceTop';
import BaseButton from '@/components/Atom/BaseButton';
import PublishTaxBill from '@/components/Molecules/Charge/TaxInvoiceAuto/PublishTaxBill';
import TaxInvoiceBottom from '@/components/Molecules/Charge/TaxInvoiceAuto/TaxInvoiceBottom';

const MobileEtcTaxInvoiceAuto = () => {
  const [isInsertVisible, setIsInsertVisible] = useState(false);

  const { MbrCorpData, handleInsertButtonClick } = usePublishTaxBill(isInsertVisible, setIsInsertVisible);

  return (
    <div className="mobileEtcTaxInvoiceAuto">
      <>
        <ATitle type="sub" text="세금계산서 자동 발행" />
        {/* 설명 */}
        <TaxInvoiceTop />
        {isInsertVisible && <MbrCorpInsert isInsertVisible={isInsertVisible} setIsInsertVisible={setIsInsertVisible} />}
        {/* isInsertVisible이 true이면 MbrCorpInsert 표시합니다. */}
        {/* 사업자 등록 */}
        {/* {MbrCorpData?.msgId === null && isInsertVisible === false ? ( */}
        {MbrCorpData === undefined && isInsertVisible === false ? (
          <S.TextAreaContainer>
            <S.TextArea>
              등록된 사업자 정보가 없습니다.
              <br />
              사업자 정보를 등록해주세요.
            </S.TextArea>
            <BaseButton
              type="button"
              width="140px"
              height="30px"
              fontSize="1.4rem"
              fontWeight="bold"
              onClick={handleInsertButtonClick} // 이 클릭 핸들러를 추가합니다.
            >
              사업자 정보 등록하기
            </BaseButton>
          </S.TextAreaContainer>
        ) : (
          // 사업자 , 결제 리스트
          // MbrCorpData?.msgId !== null &&
          <PublishTaxBill isInsertVisible={isInsertVisible} setIsInsertVisible={setIsInsertVisible} />
        )}
        <TaxInvoiceBottom />
      </>
      <TaxInvoiceHistoryTable />
    </div>
  );
};
export default MobileEtcTaxInvoiceAuto;
