import { useAddressDetail } from '@/components/hooks/customs/useAddressDetail';
import { formatPhoneNumber, removeHyphens } from '@/shared/util/format/phoneNumberFormatUtil';
import * as SB from '@/styles/selectBox.styles';
import { BuddyData } from '@/types/index.types';
import React, { useEffect, useState } from 'react';
import GroupListSelect from '../../groupList/GroupListSelect';
import * as S from './AddressHistory.styles';
import { Select } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import MobileAddressHistory from './MobileAddressHistory';

interface IAddressHistory {
  onClickCloseDetailBtn: () => void;
  buddyData: BuddyData | null; // 해당 항목 클릭시 넘어오는 BuddyData
}

const AddressHistory = (props: IAddressHistory) => {
  const detailHooks = useAddressDetail();

  return (
    <S.Wrapper>
      <S.AddressCrose>
        <button className="closeBtn" type="button" onClick={() => props.onClickCloseDetailBtn()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path
              d="M15 1.5L1 15.5M1 1.5L15 15.5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </S.AddressCrose>

      {window.innerWidth > 768 ? (
        <S.AddressHistoryWrap>
          <div className="wrap">
            <div className="top">
              <div className="customer">
                {/* TODO - h2 더블클릭시 input block none 처리 */}
                {detailHooks.columnTagS.buddyNm ? (
                  <input
                    type="text"
                    name="buddyNm"
                    placeholder={props.buddyData?.buddyNm}
                    value={detailHooks.editValueS.buddyNm}
                    onChange={detailHooks.onChangeEditValue}
                  />
                ) : (
                  <h2>{props.buddyData?.buddyNm ?? '이름없음'}</h2>
                )}
              </div>
              <div className="groupWrap">
                <p onClick={() => detailHooks.onClickGroupBtn()}>{props.buddyData?.groupNm}</p>
                {/* TODO - active */}
                <SB.SelectInput className={`selectInput ${detailHooks.groupSelectS ? 'active' : ''}`}>
                  <GroupListSelect
                    onClickClose={detailHooks.onClickoutLine}
                    onClickfunction={detailHooks.onClickEditGroup}
                    data={props.buddyData}
                    item={props.buddyData}
                    type="move"
                  />
                </SB.SelectInput>
              </div>
            </div>
            {/* 기본 정보 탭 */}
            <S.Information className="information">
              <div>
                <h4>Tel.</h4>
                <div>
                  {detailHooks.columnTagS.keyCommNo ? (
                    <input
                      type="text"
                      name="keyCommNo"
                      placeholder={formatPhoneNumber(props.buddyData?.keyCommNo)}
                      defaultValue={removeHyphens(detailHooks.editValueS.keyCommNo)}
                      onChange={detailHooks.onChangeEditValue}
                      style={{ borderColor: detailHooks.errors.keyCommNo ? 'red' : '' }}
                    />
                  ) : (
                    <p>{formatPhoneNumber(props.buddyData?.keyCommNo)}</p>
                  )}
                </div>
              </div>
              <div>
                <h4>Mail.</h4>
                <div className={props.buddyData?.emailId ? '' : 'notText'}>
                  {detailHooks.columnTagS.emailId ? (
                    <input
                      type="text"
                      name="emailId"
                      placeholder={props.buddyData?.emailId}
                      value={detailHooks.editValueS.emailId}
                      onChange={detailHooks.onChangeEditValue}
                    />
                  ) : (
                    <p>{props.buddyData?.emailId ? props.buddyData?.emailId : 'email@example.com'}</p>
                  )}
                </div>
              </div>
              <div>
                <h4>Memo.</h4>
                {/* 내용이 없을때 표출 */}
                <div className={props.buddyData?.etcInfo ? '' : 'notText'}>
                  {detailHooks.columnTagS.etcInfo ? (
                    <textarea
                      name="etcInfo"
                      placeholder={props.buddyData?.etcInfo ?? 'memo'}
                      value={detailHooks.editValueS.etcInfo ?? ''}
                      onChange={detailHooks.onChangeEditValue}
                    />
                  ) : (
                    <p>{props.buddyData?.etcInfo ? props.buddyData?.etcInfo : 'memo'}</p>
                  )}
                </div>
              </div>
            </S.Information>
            {/* li > div에 있는 p가 기본이고 더블클릭이나 클릭이번트 발생시 input이 활성화 */}
            {/*  */}
            <S.CardWrap className="cardWrap">
              <div className={detailHooks.columnSelectS.companySelect ? 'active' : ''}>
                <button onClick={() => detailHooks.onClickColumnSelect('companySelect')}>
                  <p>직장</p>
                  <p className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                      <path
                        d="M4.80437 3.93061L0.764606 1.38481C0.697167 1.34233 0.659546 1.2859 0.659546 1.22721C0.659546 1.16852 0.697167 1.11209 0.764606 1.06961L0.769171 1.06687C0.801864 1.0462 0.841217 1.02975 0.884835 1.01851C0.928453 1.00726 0.975425 1.00146 1.02289 1.00146C1.07036 1.00146 1.11733 1.00726 1.16095 1.01851C1.20457 1.02975 1.24392 1.0462 1.27661 1.06687L5.08053 3.46421L8.88293 1.06687C8.91562 1.0462 8.95497 1.02975 8.99859 1.01851C9.04221 1.00726 9.08918 1.00146 9.13665 1.00146C9.18412 1.00146 9.23109 1.00726 9.27471 1.01851C9.31832 1.02975 9.35768 1.0462 9.39037 1.06687L9.39493 1.06961C9.46237 1.11209 9.5 1.16852 9.5 1.22721C9.5 1.2859 9.46237 1.34233 9.39493 1.38481L5.35517 3.93061C5.31965 3.953 5.27692 3.97083 5.22958 3.98301C5.18224 3.99518 5.13127 4.00146 5.07977 4.00146C5.02827 4.00146 4.9773 3.99518 4.92996 3.98301C4.88262 3.97083 4.83989 3.953 4.80437 3.93061Z"
                        stroke="currentColor"
                        strokeWidth="0.887761"
                      />
                    </svg>
                  </p>
                </button>
                <ul>
                  <li>
                    <h4>회사이름</h4>
                    <div className={props.buddyData?.coNm ? '' : 'notText'}>
                      {detailHooks.columnTagS.coNm ? (
                        <input
                          type="text"
                          name="coNm"
                          placeholder={props.buddyData?.coNm ?? '회사이름'}
                          value={detailHooks.editValueS.coNm ?? ''}
                          onChange={detailHooks.onChangeEditValue}
                        />
                      ) : (
                        <p>{props.buddyData?.coNm ? props.buddyData?.coNm : '회사이름'}</p>
                      )}
                    </div>
                  </li>
                  <li>
                    <h4>부서</h4>
                    <div className={props.buddyData?.coDept ? '' : 'notText'}>
                      {detailHooks.columnTagS.coDept ? (
                        <input
                          type="text"
                          name="coDept"
                          placeholder={props.buddyData?.coDept ?? '부서'}
                          value={detailHooks.editValueS.coDept ?? ''}
                          onChange={detailHooks.onChangeEditValue}
                        />
                      ) : (
                        <p>{props.buddyData?.coDept ? props.buddyData?.coDept : '부서'}</p>
                      )}
                    </div>
                  </li>
                  <li>
                    <h4>직함</h4>
                    <div className={props.buddyData?.coHandle ? '' : 'notText'}>
                      {detailHooks.columnTagS.coHandle ? (
                        <input
                          type="text"
                          name="coHandle"
                          placeholder={props.buddyData?.coHandle ?? '직함'}
                          value={detailHooks.editValueS.coHandle ?? ''}
                          onChange={detailHooks.onChangeEditValue}
                        />
                      ) : (
                        <p>{props.buddyData?.coHandle ? props.buddyData?.coHandle : '직함'}</p>
                      )}
                    </div>
                  </li>
                  <li>
                    <h4>회사주소</h4>
                    <div className={props.buddyData?.coAddr ? '' : 'notText'}>
                      {detailHooks.columnTagS.coAddr ? (
                        <input
                          type="text"
                          name="coAddr"
                          placeholder={props.buddyData?.coAddr ?? '회사주소'}
                          value={detailHooks.editValueS.coAddr ?? ''}
                          onChange={detailHooks.onChangeEditValue}
                        />
                      ) : (
                        <p>{props.buddyData?.coAddr ? props.buddyData?.coAddr : '회사주소'}</p>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
              <div className={detailHooks.columnSelectS.homeSelect ? 'active' : ''}>
                <button onClick={() => detailHooks.onClickColumnSelect('homeSelect')}>
                  <p>집</p>
                  <p className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                      <path
                        d="M4.80437 3.93061L0.764606 1.38481C0.697167 1.34233 0.659546 1.2859 0.659546 1.22721C0.659546 1.16852 0.697167 1.11209 0.764606 1.06961L0.769171 1.06687C0.801864 1.0462 0.841217 1.02975 0.884835 1.01851C0.928453 1.00726 0.975425 1.00146 1.02289 1.00146C1.07036 1.00146 1.11733 1.00726 1.16095 1.01851C1.20457 1.02975 1.24392 1.0462 1.27661 1.06687L5.08053 3.46421L8.88293 1.06687C8.91562 1.0462 8.95497 1.02975 8.99859 1.01851C9.04221 1.00726 9.08918 1.00146 9.13665 1.00146C9.18412 1.00146 9.23109 1.00726 9.27471 1.01851C9.31832 1.02975 9.35768 1.0462 9.39037 1.06687L9.39493 1.06961C9.46237 1.11209 9.5 1.16852 9.5 1.22721C9.5 1.2859 9.46237 1.34233 9.39493 1.38481L5.35517 3.93061C5.31965 3.953 5.27692 3.97083 5.22958 3.98301C5.18224 3.99518 5.13127 4.00146 5.07977 4.00146C5.02827 4.00146 4.9773 3.99518 4.92996 3.98301C4.88262 3.97083 4.83989 3.953 4.80437 3.93061Z"
                        stroke="currentColor"
                        strokeWidth="0.887761"
                      />
                    </svg>
                  </p>
                </button>
                <ul>
                  <li>
                    <h4>집주소</h4>
                    <div className={props.buddyData?.homeAddr ? '' : 'notText'}>
                      {detailHooks.columnTagS.homeAddr ? (
                        <input
                          type="text"
                          name="homeAddr"
                          placeholder={props.buddyData?.homeAddr ?? '집주소'}
                          value={detailHooks.editValueS.homeAddr ?? ''}
                          onChange={detailHooks.onChangeEditValue}
                        />
                      ) : (
                        <p>{props.buddyData?.homeAddr ? props.buddyData?.homeAddr : '집주소'}</p>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
              <div className={detailHooks.columnSelectS.etcSelect ? 'active' : ''}>
                <button onClick={() => detailHooks.onClickColumnSelect('etcSelect')}>
                  <p>상세정보</p>
                  <p className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                      <path
                        d="M4.80437 3.93061L0.764606 1.38481C0.697167 1.34233 0.659546 1.2859 0.659546 1.22721C0.659546 1.16852 0.697167 1.11209 0.764606 1.06961L0.769171 1.06687C0.801864 1.0462 0.841217 1.02975 0.884835 1.01851C0.928453 1.00726 0.975425 1.00146 1.02289 1.00146C1.07036 1.00146 1.11733 1.00726 1.16095 1.01851C1.20457 1.02975 1.24392 1.0462 1.27661 1.06687L5.08053 3.46421L8.88293 1.06687C8.91562 1.0462 8.95497 1.02975 8.99859 1.01851C9.04221 1.00726 9.08918 1.00146 9.13665 1.00146C9.18412 1.00146 9.23109 1.00726 9.27471 1.01851C9.31832 1.02975 9.35768 1.0462 9.39037 1.06687L9.39493 1.06961C9.46237 1.11209 9.5 1.16852 9.5 1.22721C9.5 1.2859 9.46237 1.34233 9.39493 1.38481L5.35517 3.93061C5.31965 3.953 5.27692 3.97083 5.22958 3.98301C5.18224 3.99518 5.13127 4.00146 5.07977 4.00146C5.02827 4.00146 4.9773 3.99518 4.92996 3.98301C4.88262 3.97083 4.83989 3.953 4.80437 3.93061Z"
                        stroke="currentColor"
                        strokeWidth="0.887761"
                      />
                    </svg>
                  </p>
                </button>
                <ul>
                  <li>
                    <h4>계좌번호</h4>
                    <div className={props.buddyData?.accountNo ? '' : 'notText'}>
                      {detailHooks.columnTagS.accountNo ? (
                        <input
                          type="text"
                          name="accountNo"
                          placeholder={props.buddyData?.accountNo}
                          value={detailHooks.editValueS.accountNo}
                          onChange={detailHooks.onChangeEditValue}
                        />
                      ) : (
                        <p>{props.buddyData?.accountNo ? props.buddyData?.accountNo : '계좌번호'}</p>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </S.CardWrap>
          </div>
        </S.AddressHistoryWrap>
      ) : (
        // 768보다 작을 때 !!!!!!!!!!!!! ********************************************************************************************************************
        <MobileAddressHistory data={props.buddyData} onClickCloseDetailBtn={props.onClickCloseDetailBtn} />
      )}
      {window.innerWidth > 768 ? (
        <S.AddressEdit>
          <button className="editBtn" type="button" onClick={() => detailHooks.onClickEditTagsBtn()}>
            {detailHooks.allEditBtn ? '취소' : '편집'}
          </button>
          {detailHooks.allEditBtn && (
            <button className="addBtn" type="button" onClick={() => detailHooks.onClickSaveBtn()}>
              저장
            </button>
          )}
        </S.AddressEdit>
      ) : null}
    </S.Wrapper>
  );
};
export default AddressHistory;
