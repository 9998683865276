/**
 * @title 메인 주소록 메인 hook
 * */
import { useAddressBookMutations } from '@/components/hooks/mutations/addressBook/useAddressBookMutations';
import { fetchInfinite, InfiniteQueryKeyType } from '@/components/hooks/queries/fetchInfinite';
import { addressColumViewLabelRecoil, addressColumViewValueRecoil } from '@/recoil/atoms/addressColum';
import { groupBuddyListParamsRecoil, groupBuddyListParamsType } from '@/recoil/atoms/mainTableRecoil';
import { checkGroupArrR } from '@/recoil/atoms/messageSend/messageSend';
import { AddressSortListData } from '@/shared/constants';
import { validateAddressRow } from '@/shared/util/AddressUtils';
import { fomatOnlyNum } from '@/shared/util/format/phoneNumberFormatUtil';
import { BuddyData } from '@/types/index.types';
import { useVirtualizer } from '@tanstack/react-virtual';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

/**
 * @title 주소록 메인 테이블의 기능 hook
 *
 * @author 정휘학
 * @since 2024.06.05
 * */

export const useAddressBookTable = () => {
  /* 주소록 메인 테이블 ref */
  const tableRef = useRef<HTMLTableElement>(null);
  /* 주소록 리스트 각 아이템 useRef */
  const listItemRefs = useRef<{ [key: string]: HTMLInputElement | HTMLTextAreaElement | null }>({});
  /* 수정 상태인 ( focus ) td 의 key 값 */
  const [focusTargetKeyS, setFocusTargetKeyS] = useState<string>('');
  /* 주소록 리스트 데이터 get api 요청의 params */
  const groupBuddyListParamsS = useRecoilValue(groupBuddyListParamsRecoil);
  /* 주소록 체크박스 상태 */
  const checkGroupArrS = useRecoilValue(checkGroupArrR);
  /* 주소록 groupBuddyList data state */
  const [groupBuddyListDataS, setGroupBuddyListDataS] = useState<BuddyData[]>([]);
  /* 주소록 정렬 상태 */
  const [groupBuddyListSortS, setGroupBuddyListSortS] = useState<{
    key: AddressSortListData;
    type: 'asc' | 'desc' | 'none';
  }>({ key: 'groupNm', type: 'none' });
  /* 표출 항목선택 recoil */
  const [addressColumViewValueR, setAddressColumViewValueR] = useRecoilState(addressColumViewValueRecoil);
  const [addressColumViewLabelR, setAddressColumViewLabelR] = useRecoilState(addressColumViewLabelRecoil);

  /* number로 받아오는 성별을 문자열로 변환 */
  const genderLabels = ['선택 안 함', '여', '남'];

  /* 주소록 메인 테이블 api hook */
  const { updatedM } = useAddressBookMutations();

  // ===================================================================================================================
  // 주소록 리스트 데이터 GET API
  // ===================================================================================================================
  const queryKey: InfiniteQueryKeyType<groupBuddyListParamsType> = ['/groupBuddyList', { ...groupBuddyListParamsS }];
  const { inFiniteResult, handleInfiniteScroll } = fetchInfinite({
    params: groupBuddyListParamsS,
    queryKey,
    method: 'GET',
    cacheTime: 6000 * 10 * 60,
    staleTime: 6000 * 10 * 60,
  });
  // =========================================
  // 주소록 리스트 스크롤 상단에 위치 시키는 useEffect
  // =========================================
  useEffect(() => {
    const mainTableCurrent = tableRef.current;
    if (mainTableCurrent) mainTableCurrent.scrollTop = 0;
  }, [groupBuddyListParamsS.groupSeqNo]);

  // ==============================================
  // groupBuddyListSortS 상태에 따라 주소록 정렬하는 hook
  // ==============================================
  const handleSortedData = (data: BuddyData[]): BuddyData[] => {
    const { key, type } = groupBuddyListSortS;

    if (type === 'none') {
      return data;
    }

    return data.sort((a: any, b: any) => {
      const aValue = a[key];
      const bValue = b[key];

      // Handle empty strings and null values
      if (aValue === '' || aValue === null) {
        return 1;
      }
      if (bValue === '' || bValue === null) {
        return -1;
      }

      if (type === 'asc') {
        return aValue.localeCompare(bValue, 'ko-KR');
      }
      if (type === 'desc') {
        return bValue.localeCompare(aValue, 'ko-KR');
      }

      return 0;
    });
  };
  // =================================================
  // 주소록 메인 테이블의 데이터 정렬 상태를 변경하는 handle hook
  // =================================================
  const handleGroupBuddyListSort = (key: AddressSortListData) => {
    setGroupBuddyListSortS((prevState) => {
      const updatedSort: { key: AddressSortListData; type: 'asc' | 'desc' | 'none' } = { key, type: 'none' };
      if (prevState.key !== key) {
        return { key, type: 'asc' };
      }
      if (prevState.type === 'asc') updatedSort.type = 'desc';
      if (prevState.type === 'desc') updatedSort.type = 'none';
      if (prevState.type === 'none') updatedSort.type = 'asc';
      return updatedSort;
    });
  };
  // ======================================
  // 주소록 리스트 데이터 state 에 담는 useEffect
  // ======================================
  useEffect(() => {
    const { data, isLoading } = inFiniteResult;
    if (data && !isLoading) {
      let flatData: BuddyData[] = data.pages.flatMap((item: any) =>
        item.content.map((contentItem: any) => contentItem),
      );

      flatData = handleSortedData(flatData);
      // 중복된 buddySeqNo 제거
      const uniqueBuddySeqNos: number[] = [];
      const uniqueFlatData = flatData.filter((item) => {
        if (!uniqueBuddySeqNos.includes(item.buddySeqNo)) {
          uniqueBuddySeqNos.push(item.buddySeqNo);
          return true;
        }
        return false;
      });
      setGroupBuddyListDataS(uniqueFlatData);
    }
  }, [inFiniteResult.data, inFiniteResult.isLoading, groupBuddyListSortS]);

  // =======================================================================
  // focus 상태인 td 가 있을 경우 해당 td 영역에 속하지 않은 영역을 클릭시 focus 상태 제거
  // =======================================================================
  const handleClickOutside = (event: any) => {
    const focusElement = document.querySelectorAll('td.focus');
    if (focusElement[0] && !focusElement[0].contains(event.target as Node)) {
      setFocusTargetKeyS('');
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // ========================================
  // 주소록 리스트 아이템 자동 focus 해주는 useEffect
  // ========================================
  useEffect(() => {
    if (focusTargetKeyS && listItemRefs.current[focusTargetKeyS]) {
      listItemRefs.current[focusTargetKeyS]?.focus();
    }
  }, [focusTargetKeyS]);
  // =======================================
  // 메인 주소로의 전체 체크박스 상태를 관리하는 hook
  // =======================================
  const checkingAllCheckBox = () => {
    if (groupBuddyListParamsS.groupSeqNo) {
      /* 개별 그룹에 관한 메인 주소록 전체 체크박스 상태 관리 */
      const findGroupData = checkGroupArrS?.find((f) => f.groupSeqNo === groupBuddyListParamsS.groupSeqNo);
      return findGroupData?.isChecked && !findGroupData.isChildrenChecked;
    }
    /* 전체 그룹에 관한 메인 주소록 전체 체크박스 상태 관리*/
    return checkGroupArrS
      ? checkGroupArrS?.filter((f) => f.isChecked && !f.isChildrenChecked).length === checkGroupArrS?.length
      : false;
  };
  // ==========================
  // focus 상태로 변경하는 hook
  // ==========================
  const handleChangeForm = (value: string) => {
    setFocusTargetKeyS(value);
  };
  // =======================================================================
  // 주소록 수정 이벤트 트리거 hook 으로 들어온 수정 데이터를 기존 데이터에 갈아끼우는 hook
  // =======================================================================
  const handleChangeUpdatedData = (
    updateArray: {
      key: keyof BuddyData;
      value: string | number;
    }[],
    prevData: BuddyData,
  ): false | BuddyData => {
    try {
      const updatedData: BuddyData = { ...prevData };
      updateArray.forEach((item) => {
        if (item.key in updatedData) {
          (updatedData[item.key] as string | number) = item.value;
        }
      });
      if (!validateAddressRow(updatedData)) return false;
      return updatedData;
    } catch (error) {
      return false;
    }
  };
  // =========================
  // 주소록 input 타입 데이터 수정 이벤트 트리거 hook
  // =========================
  const triggerUpdateToInput = (event: KeyboardEvent<HTMLInputElement>, prevData: BuddyData, key: keyof BuddyData) => {
    if (event.key === 'Enter') {
      new Promise((resolve) => {
        const result = handleChangeUpdatedData([{ key, value: event.currentTarget.value }], prevData);
        if (result !== false) resolve(result);
      }).then((result) => {
        updatedM({ ...(result as BuddyData), keyCommNo: fomatOnlyNum((result as BuddyData).keyCommNo) } as BuddyData);
        setFocusTargetKeyS('');
      });
    }
  };
  // =========================
  // 주소록 select 타입 데이터 수정 이벤트 트리거 hook
  // =========================
  const triggerUpdateToSelect = (value: number, prevData: BuddyData, key: keyof BuddyData) => {
    new Promise((resolve) => {
      const result = handleChangeUpdatedData([{ key, value }], prevData);
      if (result !== false) resolve(result);
    }).then((result) => {
      updatedM({ ...(result as BuddyData), keyCommNo: fomatOnlyNum((result as BuddyData).keyCommNo) } as BuddyData);
      setFocusTargetKeyS('');
    });
  };
  // =========================================
  // 주소록 textarea ( 메모 ) 타입 데이터 수정 이벤트 트리거 hook
  // =========================================
  const triggerUpdateToTextarea = (
    event: KeyboardEvent<HTMLTextAreaElement>,
    prevData: BuddyData,
    key: keyof BuddyData,
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      new Promise((resolve) => {
        const result = handleChangeUpdatedData([{ key, value: event.currentTarget.value }], prevData);
        if (result !== false) resolve(result);
      }).then((result) => {
        updatedM(result as BuddyData);
        setFocusTargetKeyS('');
      });
    }
  };
  // =====================================
  // 주소록 그룹 데이터 수정 이벤트 트리거 hook
  // =====================================
  const triggerUpdateToLi = (groupSeqNo: number, groupNm: string, prevData: BuddyData) => {
    new Promise((resolve) => {
      const result = handleChangeUpdatedData(
        [
          { key: 'groupSeqNo', value: groupSeqNo },
          { key: 'groupNm', value: groupNm },
        ],
        prevData,
      );
      if (result !== false) resolve(result);
    }).then((result) => {
      updatedM(result as BuddyData);
      setFocusTargetKeyS('');
    });
  };

  const onClickUpdateLi = (group: any, data: BuddyData) => {
    triggerUpdateToLi(group.groupSeqNo, group.groupNm, data);
  };

  // // ====================================================
  // // 주소록 메인 테이블 리스트의를 감지해서 열 너비 사이즈를 맞춰주는 로직
  // // ====================================================
  const [tableTdWidth, setTableTdWidth] = useState<number>(200);
  const syncColumnWidths = () => {
    const table = document.getElementById('mainTable');

    if (table) {
      const thead = table.querySelector('#mainTable thead');
      if (!thead) return;
      setTableTdWidth(thead.clientWidth / addressColumViewValueR.size - 5);
    }
  };
  // // ====================================================
  // // 주소록 메인 테이블 리스트의를 감지해서 열 너비 사이즈를 맞춰주는 로직
  // // ====================================================
  useEffect(() => {
    // 열 너비 동기화 함수 호출
    syncColumnWidths();
    // 윈도우 리사이즈 이벤트 핸들러 등록
    window.addEventListener('resize', syncColumnWidths);
    // 컴포넌트 언마운트 시 이벤트 핸들러 제거 및 MutationObserver 해제
    return () => {
      window.removeEventListener('resize', syncColumnWidths);
      // observer.disconnect();
    };
  }, [addressColumViewValueR]);

  /* tanstack/react-virtual 테이블 최적화 */

  const rowVirtualizer = useVirtualizer({
    count: groupBuddyListDataS.length, // 총 항목 수를 지정
    getScrollElement: () => tableRef.current, // 스크롤 이벤트를 감지할 요소를 반환
    estimateSize: () => 42, // 각 항목의 예상 높이를 40픽셀로 지정
    overscan: 200, // 화면 밖에서 미리 렌더링할 추가 항목 수를 200으로 지정
  });

  return {
    checkingAllCheckBox,
    focusTargetKeyS,
    triggerUpdateToInput,
    handleChangeForm,
    triggerUpdateToLi,
    triggerUpdateToTextarea,
    listItemRefs,
    groupBuddyListDataS,
    inFiniteResult,
    handleInfiniteScroll,
    groupBuddyListSortS,
    handleGroupBuddyListSort,
    rowVirtualizer,
    tableRef,
    addressColumViewValueR,
    addressColumViewLabelR,
    tableTdWidth,
    setAddressColumViewValueR,
    setAddressColumViewLabelR,
    setGroupBuddyListDataS,
    setGroupBuddyListSortS,
    onClickUpdateLi,
    triggerUpdateToSelect,
    genderLabels,
  };
};
