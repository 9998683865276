// ******************************************
// MUTATION GET APIS
// ******************************************

import { instance } from '@/shared/lib/clientAxios';
import { IUseMutationBuddySeqNoList, IUseMutationGetRcvList, IUseMutationReadMessage } from './apiTypes';

/**
 * @title 015 채팅 입장시 수신 카운트 0 으로 변경하는 api
 *
 * @method [GET]
 * @author 정휘학
 * @since 2024.04.16
 * */
export const useMutationReadMessage = async ({ contactNumber }: IUseMutationReadMessage) => {
  const response = await instance.get('say015/readMessage', {
    params: {
      contactNumber,
    },
  });
  return response.data;
};

/**
 * @title 그룹에 속한 리스트의 buddySeqNo 를 가져오는 API
 *
 * @method [GET]
 * @author 정휘학
 * @since 2024.05.04
 * */
export const useMutationBuddySeqNoList = async ({ groupSeqNoArray }: IUseMutationBuddySeqNoList) => {
  const response = await instance.get(`/buddySeqNoList?groupSeqNos=${groupSeqNoArray}`);
  return response.data;
};

/**
 * @title 015 그룹채팅방 연락처 리스트
 *
 * @method [GET]
 * @author 정휘학
 * @since 2024.05.08
 * */
export const useMutationGetRcvList = async ({ chatRoomId }: IUseMutationGetRcvList) => {
  const response = await instance.get(`/say015/getRcvList`, {
    params: {
      chatRoomId,
    },
  });

  return response.data;
};

/**
 * @title 메세지 푸시 알림 상태
 *
 * @method [GET]
 * @author 정휘학
 * @since 2024.05.21
 * */
export const useMutationUpdateNotiStatus = async () => {
  const response = await instance.get('/say015/updateNotiStatus');
  return response.data;
};
