import React from 'react';
import * as S from './TransmissionResult.styles';
import SendSoftPhoneBottom from './components/SendSoftPhoneBottom';
import MessageSend from '@/components/softPhone/DisplayGroups/MessageSend/MessageSend';

// softPhone 010 탭 메뉴
const TransmissionResult = () => {
  // const hooks = useTrcContent();

  return (
    <S.TransmissionResultWrap>
      {/* 메시지 전송 컴포넌트 */}
      <MessageSend />

      {/* 전송 결과 조회 */}
      <SendSoftPhoneBottom />
    </S.TransmissionResultWrap>
  );
};

export default TransmissionResult;
