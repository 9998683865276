import * as S from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/ChatRoom015Body.styled';
import { removeWebCharacter, underLine } from '@/shared/util/format/contentFormatUtil';
import { formatTime } from '@/shared/util/format/dateFormatUtil';
import { CloseOutlined, RedoOutlined } from '@ant-design/icons';
import React, { Fragment } from 'react';
import ChatRoom015File from '../chatRoom015File/ChatRoom015File';

interface IChatRoom015MT {
  /* 채팅방 리스트 개별 데이터 */
  list: any;
  /* 채팅방 리스트 데이터 */
  content: any;
  /* 채팅방 리스트 인덱스 */
  index: number;
  /* 전송 실패한 메세지 재전송 Hook */
  reSend015Msg(messageId: string, msgType: string): void;
  /* 전송 실패한 메세지 삭제 Hook */
  cancelSend015Msg(messageId: string): void;
  /* 이미지 확대 Hook */
  closeUpImgHook(imgData: string): void;
  /* 이미지 표출 오류 Hook */
  handleImgError(e: any): void;
}

/**
 * @title 015 채팅방 MT 리스트 표출 컴포넌트
 *
 * @author 정휘학
 * @since 2024.04.18
 * */
const ChatRoom015MT = (props: IChatRoom015MT) => {
  return (
    <Fragment key={props.list.messageId}>
      {/* 이미지 */}
      {props.list.msgType === 'MMS' && (
        <>
          <S.EtcChattingMyZone>
            <S.EtcChattingContentWrap>
              {/* 전송 상태 표출 분기 처리 */}
              {removeWebCharacter(props.list.sndMsg, '[Web발신]') === '' && (
                <>
                  {props.list.rsltVal === -100 &&
                    formatTime(props.list?.messageDttm) !==
                      (props.content[1][props.index + 1]?.direction === 'mt'
                        ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                        : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>}
                  {props.list.rsltVal === 99 && <S.EtcChattingHourText>전송중</S.EtcChattingHourText>}
                  {props.list.rsltVal !== -100 && props.list.rsltVal !== 99 && (
                    <S.EtcChattingHourText>
                      {/* 채팅방 재발송 주석처리 합니다. reSend015Msg 사용하는 곳 주석풀면 됩니다. */}
                      {/* <S.EtcChattingReSendBtn
                        onClick={() => props.reSend015Msg(props.list.messageId, props.list.msgType)}
                      >
                        <RedoOutlined />
                      </S.EtcChattingReSendBtn> */}
                      <S.EtcChattingSnedCancelBtn onClick={() => props.cancelSend015Msg(props.list.messageId)}>
                        <CloseOutlined />
                      </S.EtcChattingSnedCancelBtn>
                    </S.EtcChattingHourText>
                  )}
                </>
              )}
              <S.EtcChattingMyImageBox onClick={() => props.closeUpImgHook(props.list.imgData)}>
                <img
                  src={`data:image/jpeg;base64,${props.list.imgData}`}
                  alt="img area"
                  onError={props.handleImgError}
                />
              </S.EtcChattingMyImageBox>
            </S.EtcChattingContentWrap>
          </S.EtcChattingMyZone>
          {removeWebCharacter(props.list.sndMsg, '[Web발신]') !== '' && (
            <S.EtcChattingMyZone>
              <S.EtcChattingContentWrap>
                {/* 100 : 전송 완료 , 99 : 전송 중 , 그 외 : 전송 실패 */}
                {props.list.rsltVal === -100 &&
                  formatTime(props.list?.messageDttm) !==
                    (props.content[1][props.index + 1]?.direction === 'mt'
                      ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                      : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>}
                {props.list.rsltVal === 99 && <S.EtcChattingHourText>전송중</S.EtcChattingHourText>}
                {props.list.rsltVal !== -100 && props.list.rsltVal !== 99 && (
                  <S.EtcChattingHourText>
                    {/* <S.EtcChattingReSendBtn
                      onClick={() => props.reSend015Msg(props.list.messageId, props.list.msgType)}
                    >
                      <RedoOutlined />
                    </S.EtcChattingReSendBtn> */}
                    <S.EtcChattingSnedCancelBtn onClick={() => props.cancelSend015Msg(props.list.messageId)}>
                      <CloseOutlined />
                    </S.EtcChattingSnedCancelBtn>
                  </S.EtcChattingHourText>
                )}
                <S.EtcChattingMyTextBox className="chattingMyTextBox">
                  <p>{underLine(removeWebCharacter(props.list.sndMsg, '[Web발신]'))}</p>
                </S.EtcChattingMyTextBox>
              </S.EtcChattingContentWrap>
            </S.EtcChattingMyZone>
          )}
        </>
      )}
      {/* 파일 */}
      {props.list.msgType === 'FILE' && (
        <ChatRoom015File
          list={props.list}
          content={props.content}
          index={props.index}
          direction={props.list.direction}
          cancelSend015Msg={props.cancelSend015Msg}
        />
      )}
      {/* 내용 */}
      {!(props.list.msgType === 'MMS') && removeWebCharacter(props.list.sndMsg, '[Web발신]') !== '' && (
        <S.EtcChattingMyZone>
          <S.EtcChattingContentWrap>
            {/* 100 : 전송 완료 , 99 : 전송 중 , 그 외 : 전송 실패 */}
            {props.list.rsltVal === -100 &&
              formatTime(props.list?.messageDttm) !==
                (props.content[1][props.index + 1]?.direction === 'mt'
                  ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                  : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>}
            {props.list.rsltVal === 99 && <S.EtcChattingHourText>전송중</S.EtcChattingHourText>}
            {props.list.rsltVal !== -100 && props.list.rsltVal !== 99 && (
              <S.EtcChattingHourText>
                {/* <S.EtcChattingReSendBtn onClick={() => props.reSend015Msg(props.list.messageId, props.list.msgType)}>
                  <RedoOutlined />
                </S.EtcChattingReSendBtn> */}
                <S.EtcChattingSnedCancelBtn onClick={() => props.cancelSend015Msg(props.list.messageId)}>
                  <CloseOutlined />
                </S.EtcChattingSnedCancelBtn>
              </S.EtcChattingHourText>
            )}
            <S.EtcChattingMyTextBox className="chattingMyTextBox">
              <p>{underLine(removeWebCharacter(props.list.sndMsg, '[Web발신]'))}</p>
            </S.EtcChattingMyTextBox>
          </S.EtcChattingContentWrap>
        </S.EtcChattingMyZone>
      )}
    </Fragment>
  );
};

export default ChatRoom015MT;
