import { addGroupOpenRecoil, groupSearchTextRecoil, searchGroupOpenRecoil } from '@/recoil/atoms/addressBookGroup';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const useTopGroup = () => {
  /* 그룹 리스트 추가 컴포넌트 표출제어 recoil */
  const setAddGroupOpenR = useSetRecoilState(addGroupOpenRecoil);
  /* 그룹 리스트 검색 컴포넌트 표출제어 recoil */
  const setSearchGroupOpenS = useSetRecoilState(searchGroupOpenRecoil);
  /* 그룹리스트 검색 keyword 받을 Recoil */
  const groupSearchTextS = useRecoilValue(groupSearchTextRecoil);

  // ==============================
  // onClick 그룹추가 버튼
  // ==============================
  const onClickAddGroupBtn = () => {
    // 그룹 검색창은 off
    setSearchGroupOpenS(false);
    // 그룹 추가창 토글
    setAddGroupOpenR((prev) => !prev);
  };

  // ==============================
  // onClick 그룹검색 버튼
  // ==============================
  const onClickSearchGroupBtn = () => {
    // 그룹 추가창은 off
    setAddGroupOpenR(false);
    // 그룹 검색창은 토글
    setSearchGroupOpenS((prev) => !prev);
  };

  return { onClickAddGroupBtn, onClickSearchGroupBtn, groupSearchTextS };
};
