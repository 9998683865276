import { atom } from 'recoil';

/**
 * 발신번호 담는 Recoil
 */

type callingNumberListType = {
  callback: string;
  phnId: string;
  regMethod: string;
  rsrvdId: string;
  usedDttm: string;
  verifiedYn: string;
  wrtDttm: string;
};

export const callbackListRecoil = atom<callingNumberListType[] | null>({
  key: 'callbackListRecoil',
  default: null,
});
