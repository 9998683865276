import styled from 'styled-components';

export const CallingNumberAdminWrap = styled.div`
  > h2 {
    margin-bottom: 2rem;
  }
`;

// 테이블을 감싸는 컨테이너
export const BWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  /* flex-direction: column; */
  margin: 0 0 4rem 0;
  gap: 2rem;
  border-top: 2px solid ${(props) => props.theme.colors.gray500};

  @media screen and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;

// 라디오, 인증 묶음
export const ContentWrap = styled.div`
  display: flex;
  width: 70%;

  > div {
    :nth-of-type(2) {
      width: calc(100% - 16rem);
      min-height: 30rem;
    }
    &:has(.mine) {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }
  @media screen and (max-width: 1080px) {
    width: 100%;
    > div {
      :nth-of-type(2) {
        min-height: unset;
      }
    }
  }
  @media screen and (max-width: 540px) {
    flex-direction: column;
    gap: 1.4rem;
    > div {
      width: 100%;
      :nth-of-type(2) {
        width: 100%;
      }
      &:has(.mine) {
        border-left: 0;
      }
    }
    .radioWrapper {
      flex-direction: unset;
      label {
        width: 50%;
      }
    }
  }
`;

export const CertifyWrapper = styled.div`
  .document {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }
`;

// 라디오 Wrap
export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label {
    cursor: pointer;
    width: 16rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 3rem;
    font-size: 1.4rem;
    height: 4rem;
    color: ${(props) => props.theme.colors.txt200};
    input {
      appearance: none;
      left: 0;
      width: 5px;
      height: 100%;
      background: ${(props) => props.theme.colors.disable};
      margin: 0;
      padding: 0;
      :checked {
        background: ${(props) => props.theme.colors.active};
      }
    }
    &:has(input:checked) {
      background: ${(props) => props.theme.colors.gray100};
      color: ${(props) => props.theme.colors.active};
    }
  }
  @media screen and (max-width: 768px) {
    label {
      font-size: 1.6rem;
      height: auto;
      p {
        padding: 1.4rem 0;
      }
    }
  }
`;

// 버튼 컨테이너
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    button {
      /* width: 100%; */
      height: auto;
      padding: 1rem;
      font-size: 1.6rem;
    }
  }
`;
