import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import { useToolBarCallingNum } from '@/components/hooks/customs/softPhone/useTollBarCallingNum';
import * as SS from '@/styles/selectBox.styles';
import { theme } from '@/styles/theme';
import React from 'react';
import styled from 'styled-components';

const ToolBarCallingNumWrap = styled.div``;

/**
 * @title softPhone 상단 발신번호 Select 컴포넌트
 *
 * @author 김남규
 * @since 2024.03.22
 **/
const ToolBarCallingNum = () => {
  const toolBarNumHooks = useToolBarCallingNum();

  return (
    <ToolBarCallingNumWrap className="ToolBarCallingNum">
      {(toolBarNumHooks.softPhoneTopTabState === '0' || toolBarNumHooks.softPhoneTopTabState === '2') &&
        toolBarNumHooks.selectNumber['015'] && (
          <SS.SelectBox className={`${toolBarNumHooks.selectToggle ? 'active' : null}`}>
            <button type="button" onClick={() => toolBarNumHooks.setSelectToggle((prev) => !prev)}>
              {formatPhoneNumber(toolBarNumHooks.selectNumber['015'])}
              <svg role="img" xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                <path
                  d="M4.35542 4.21967L7.61256 1.1647C7.66693 1.11373 7.69727 1.046 7.69727 0.975581C7.69727 0.905157 7.66693 0.837435 7.61256 0.786462L7.60888 0.783172C7.58252 0.758376 7.55079 0.738631 7.51562 0.725138C7.48045 0.711646 7.44258 0.704688 7.40431 0.704688C7.36604 0.704688 7.32817 0.711646 7.293 0.725138C7.25783 0.738631 7.2261 0.758376 7.19974 0.783172L4.13275 3.65998L1.06699 0.783172C1.04063 0.758375 1.0089 0.73863 0.973737 0.725137C0.938568 0.711645 0.900697 0.704687 0.862425 0.704687C0.824153 0.704687 0.786281 0.711645 0.751114 0.725137C0.715946 0.73863 0.684217 0.758375 0.657857 0.783172L0.654177 0.786461C0.599802 0.837435 0.569469 0.905156 0.569469 0.97558C0.569469 1.046 0.599802 1.11373 0.654177 1.1647L3.91132 4.21967C3.93996 4.24653 3.97441 4.26792 4.01258 4.28254C4.05075 4.29715 4.09184 4.30469 4.13337 4.30469C4.17489 4.30469 4.21598 4.29715 4.25415 4.28254C4.29232 4.26792 4.32677 4.24653 4.35542 4.21967Z"
                  fill="currentColor"
                  stroke="currentColor"
                />
              </svg>
            </button>
            {toolBarNumHooks.selectToggle && (
              <ul>
                {toolBarNumHooks.callbackListS?.map(
                  (item) =>
                    (((toolBarNumHooks.softPhoneTopTabState === '0' || toolBarNumHooks.softPhoneTopTabState === '2') &&
                      item.callback.startsWith('015')) ||
                      (toolBarNumHooks.softPhoneTopTabState === '1' && !item.callback.startsWith('015'))) && (
                      <li key={item.callback}>
                        <button type="button" onClick={() => toolBarNumHooks.onClickSelectNumber(item.callback)}>
                          {formatPhoneNumber(item.callback)}
                        </button>
                      </li>
                    ),
                )}
              </ul>
            )}
          </SS.SelectBox>
        )}
      {toolBarNumHooks.softPhoneTopTabState === '1' && toolBarNumHooks.selectNumber['010'] && (
        <SS.SelectBox className={`${toolBarNumHooks.selectToggle ? 'active' : null}`}>
          <button type="button" onClick={() => toolBarNumHooks.setSelectToggle((prev) => !prev)}>
            {formatPhoneNumber(toolBarNumHooks.selectNumber['010'])}
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path
                d="M4.35542 4.21967L7.61256 1.1647C7.66693 1.11373 7.69727 1.046 7.69727 0.975581C7.69727 0.905157 7.66693 0.837435 7.61256 0.786462L7.60888 0.783172C7.58252 0.758376 7.55079 0.738631 7.51562 0.725138C7.48045 0.711646 7.44258 0.704688 7.40431 0.704688C7.36604 0.704688 7.32817 0.711646 7.293 0.725138C7.25783 0.738631 7.2261 0.758376 7.19974 0.783172L4.13275 3.65998L1.06699 0.783172C1.04063 0.758375 1.0089 0.73863 0.973737 0.725137C0.938568 0.711645 0.900697 0.704687 0.862425 0.704687C0.824153 0.704687 0.786281 0.711645 0.751114 0.725137C0.715946 0.73863 0.684217 0.758375 0.657857 0.783172L0.654177 0.786461C0.599802 0.837435 0.569469 0.905156 0.569469 0.97558C0.569469 1.046 0.599802 1.11373 0.654177 1.1647L3.91132 4.21967C3.93996 4.24653 3.97441 4.26792 4.01258 4.28254C4.05075 4.29715 4.09184 4.30469 4.13337 4.30469C4.17489 4.30469 4.21598 4.29715 4.25415 4.28254C4.29232 4.26792 4.32677 4.24653 4.35542 4.21967Z"
                fill={theme.colors.main}
                stroke={theme.colors.main}
              />
            </svg>
          </button>
          {toolBarNumHooks.selectToggle && (
            <ul>
              {toolBarNumHooks.callbackListS?.map(
                (item) =>
                  (((toolBarNumHooks.softPhoneTopTabState === '0' || toolBarNumHooks.softPhoneTopTabState === '2') &&
                    item.callback.startsWith('015')) ||
                    (toolBarNumHooks.softPhoneTopTabState === '1' && !item.callback.startsWith('015'))) && (
                    <li key={item.callback}>
                      <button type="button" onClick={() => toolBarNumHooks.onClickSelectNumber(item.callback)}>
                        {formatPhoneNumber(item.callback)}
                      </button>
                    </li>
                  ),
              )}
            </ul>
          )}
        </SS.SelectBox>
      )}
    </ToolBarCallingNumWrap>
  );
};
export default ToolBarCallingNum;
