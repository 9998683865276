import React from 'react';
import * as S from '../CashCharge.styles';
import { IMenus, useChargeMenuItems } from '@/components/hooks/customs/charge/chargeStation/useChargeMenuItems';

/**
 * @title 캐시 충전 페이지 - 결제 수단 선택 컴포넌트
 *
 * @author 정휘학
 * @since 2024.05.18
 * */
interface ICashChargeTop {
  // 결제 수단 변경 Hook
  handlerPayMethodTypes(payMethodTypeCode: string | undefined): void;
  // 결제 수단 코드
  payMethodTypeCodeState: IMenus;
}

const CashChargeTop = (props: ICashChargeTop) => {
  const itemHooks = useChargeMenuItems();

  return (
    <S.TopWrapper className="TopWrapper">
      {/* 캐시충전 리스트 */}
      <S.MenuWrapper className="MenuWrapper">
        {itemHooks.Menus.map((item) => (
          <S.MenuCard
            key={item.payMethodTypeCode}
            className={props.payMethodTypeCodeState.payMethodTypeCode === item.payMethodTypeCode ? 'active' : ''}
            onClick={() => props.handlerPayMethodTypes(item.payMethodTypeCode)}
          >
            <p className="title">{item.title}</p>
            <p className="subTitle">{item.subTitle}</p>
          </S.MenuCard>
        ))}
      </S.MenuWrapper>
    </S.TopWrapper>
  );
};

export default CashChargeTop;
