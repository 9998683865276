import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconChatDoubleFill: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '1.8rem'}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M10.2528 0.411621C8.55627 0.411487 6.88917 0.852852 5.41695 1.69192C3.94473 2.53099 2.71856 3.7386 1.86011 5.19491C1.00165 6.65123 0.540766 8.30563 0.523171 9.99395C0.505576 11.6823 0.931887 13.3458 1.7598 14.8196L0.568326 17.921C0.469279 18.1788 0.444154 18.459 0.495755 18.7302C0.547356 19.0014 0.673653 19.253 0.860555 19.4569C1.04746 19.6608 1.28762 19.809 1.55424 19.8851C1.82086 19.9611 2.10346 19.9619 2.37051 19.8874L6.09312 18.8491C7.41176 19.4691 8.85359 19.7862 10.312 19.7769C11.7704 19.7676 13.208 19.4321 14.5185 18.7953C15.8291 18.1585 16.979 17.2366 17.8832 16.0978C18.7874 14.959 19.4229 13.6325 19.7425 12.2163C20.0621 10.8002 20.0577 9.33067 19.7297 7.91644C19.4016 6.50221 18.7583 5.17946 17.8473 4.04604C16.9363 2.91261 15.7809 1.99751 14.4666 1.36841C13.1523 0.739319 11.7112 0.412326 10.2528 0.411621ZM14.6879 24.2461C13.2875 24.2471 11.9033 23.9468 10.6304 23.3657C9.35746 22.7846 8.22582 21.9365 7.31304 20.8795C8.17281 21.1106 9.05717 21.2391 9.94746 21.2623C11.3347 22.2344 12.9898 22.7563 14.6864 22.7564C16.0366 22.7564 17.3089 22.4332 18.4315 21.8626L18.6919 21.7286L22.9729 22.9218L21.5224 19.144L21.714 18.8312C22.4774 17.5888 22.919 16.129 22.919 14.5633C22.9217 12.9091 22.4184 11.2932 21.476 9.93051C21.4639 9.04431 21.3458 8.16269 21.1243 7.30425C22.1602 8.21156 22.9897 9.32842 23.5573 10.5802C24.1249 11.832 24.4176 13.19 24.4158 14.5633C24.4158 16.2779 23.9668 17.8897 23.1809 19.2885L24.3709 22.39C24.47 22.6478 24.4951 22.9279 24.4435 23.1991C24.3919 23.4703 24.2656 23.7219 24.0787 23.9258C23.8918 24.1298 23.6516 24.278 23.385 24.354C23.1184 24.43 22.8358 24.4308 22.5687 24.3563L18.8461 23.318C17.5843 23.9139 16.1728 24.2461 14.6849 24.2461"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconChatDoubleFill;
