import ATitle from '@/components/Atom/ATitle';
import * as TS from '@/styles/table.styles';
import React from 'react';
import styled from 'styled-components';
import BaseGuide from '../../Atom/BaseGuide';

// 테이블을 감싸는 컨테이너
const AWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 4rem 0;
  gap: 2rem;
  button {
    padding: 0.5rem;
    margin: 0;
    transition: 200ms;
    cursor: pointer;
    font-weight: 700;
    border: 1px solid ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.main};
    :hover {
      background-color: ${(props) => props.theme.colors.main};
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const UserNameChange = () => {
  const fileUrl = '/file/015WORKS_명의변경신청서.pdf';

  return (
    <>
      <AWrapper className="basicTable">
        <ATitle type="sub" text="명의 변경" />
        <TS.MyPageTable>
          <tbody>
            <tr>
              <th>전화</th>
              <td>015-8504-0006</td>
            </tr>
            <tr>
              <th>팩스</th>
              <td>02-3485-6710</td>
            </tr>
            <tr>
              <th>전자메일</th>
              <td>help@arreo.com</td>
            </tr>
            <tr>
              <th>명의변경 신청서</th>
              <td>
                <a href={fileUrl} download="015WORKS_명의변경신청서.pdf">
                  <button type="button">다운로드_PDF</button>
                </a>
              </td>
            </tr>
          </tbody>
        </TS.MyPageTable>
      </AWrapper>
      <BaseGuide
        text="본인(현재 아이디 소유자)의 신분증과 명의를 이전 받고자 하시는 분의 신분증 사본을 고객상담실로 보내주셔야 합니다.
                <br />명의변경 신청서를 다운받아 내용을 작성하여 팩스 및 전자메일로 보내주세요.
                <br />팩스 발송 후 고객상담실에서 내용 확인하여 회원님의 휴대폰으로 연락드린 후 바로 처리해 드리겠습니다. 
                <br />요청하는 개인정보는 고객의 업무처리 요청 및 동의에 따라 해당 업무처리를 위하여만 수집·이용하며, 업무처리 종료시에는 관계법령에 의거, 
                즉시 파기하며 이와 별도로 서비스 처리에 관한 단순 이력은 6개월간 보관 후 파기하오니, 서비스 이용에 참고하시기 바랍니다."
      />
    </>
  );
};
export default UserNameChange;
