/* eslint-disable */

import React, { useEffect, useId } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // 추가된 부분
import styled from 'styled-components';
import IconHuman from '../Atom/icon/IconHuman';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  mobileBuddyListRecoil,
  mobileNavRecoil,
  selectedComponentRecoil,
  softPhoneSaveMessageRecoil,
  TMNav,
  windowWidthRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import { useToolBarListPage } from '../hooks/customs/softPhone/useToolBarListPage';
import { useMessageManagement } from '../hooks/customs/useMessageManagement';
import IconStar from '../Atom/icon/IconStar';
import IconAddress from '../../styles/icons/IconAddress';
import IconMessage from '../Atom/icon/IconMessage';
import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';
import { msg015ChatRoomToggleRecoil, msgResultToggleRecoil } from '@/recoil/atoms/say015Atom';
import { activeChatRoomIdRecoil, chatUserInfoRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { useModalHook } from '../commons/modals/useModalHook';
import { customerRouterRecoil } from '@/recoil/atoms/customer/customer';
import IconCassetteTape from '@/styles/icons/IconCassetteTape';

const PageNavContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6rem;
  padding: 0.5rem 1.6rem;
  background-color: ${(props) => props.theme.colors.gray100};
  border-top: 1px solid ${(props) => props.theme.colors.gray200};

  @media screen and (max-width: 768px) {
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
`;

export const NavButton = styled.button<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  color: ${(props) => (props.isActive ? props.theme.colors.main : props.theme.colors.txt400)};
  font-size: 1.2rem;
`;

interface IPageNav {
  type: 'private' | 'custom';
}

const PageNav = (props: IPageNav) => {
  const [mobileNavState, setMobileNavState] = useRecoilState(mobileNavRecoil);

  const setSoftPhoneSaveMessageState = useSetRecoilState(softPhoneSaveMessageRecoil);
  const [, setMainTableWidth] = useRecoilState(mainTableWidthRecoil);
  const setMsg015ChatRoomToggleR = useSetRecoilState(msg015ChatRoomToggleRecoil);
  const setMsgResultToggleR = useSetRecoilState(msgResultToggleRecoil);
  const setMobileBuddyListS = useSetRecoilState(mobileBuddyListRecoil);

  // 고객센터 버튼 라우터 STATE
  const [, setCustomerRouterState] = useRecoilState(customerRouterRecoil);

  // 반응형때 유저페이지 nav 상태를 위한 STATE
  const [, setSelectedComponent] = useRecoilState(selectedComponentRecoil);

  /* 활성화 채팅방의 상대방 정보 */
  const [, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);
  /* 현재 활성화된 채팅방 아이디 */
  const [, setActiveChatRoomId] = useRecoilState(activeChatRoomIdRecoil);
  const windowWidth = useRecoilValue(windowWidthRecoil);

  // testCode수정 삭제
  const { warningModal } = useModalHook();

  const navigate = useNavigate();
  const location = useLocation();

  const hooks = props.type === 'private' ? useToolBarListPage() : null;
  const msgHooks = props.type === 'private' ? useMessageManagement() : null;

  useEffect(() => {
    if (!(windowWidth > 768)) {
      const handleBackButton = (e: PopStateEvent) => {
        e.preventDefault();
        handleClickNavBtn(mobileNavState);
      };

      // 현재 상태를 pushState로 기록하여 popstate 이벤트를 트리거할 수 있도록 함
      window.history.pushState(null, '', window.location.href);

      // popstate 이벤트를 감지하여 뒤로가기 동작 제어
      window.addEventListener('popstate', handleBackButton);

      // 컴포넌트 언마운트 시 이벤트 제거
      return () => {
        window.removeEventListener('popstate', handleBackButton);
      };
    }
  }, [windowWidth, mobileNavState]);

  const handleClickNavBtn = (label: TMNav) => {
    setChatUserInfo({ phoneNumber: null, buddyName: null });
    setActiveChatRoomId(null);
    setMainTableWidth(0);

    setMobileNavState(label);
    setSoftPhoneSaveMessageState(false);
    setMsg015ChatRoomToggleR(false);
    setMsgResultToggleR(false);

    // private 타입 일 때
    if (label === '회원정보') {
      setSelectedComponent('mypage');
      navigate('/mypage');
    }
    if (label === '인사말설정') {
      // testCode수정
      // warningModal('점검중', '해당 기능은 점검중 입니다.', true);
      navigate('/');
      hooks?.onClickTap('2');
      msgHooks?.handleChangeComponent('ars');
    }
    if (label === '주소록') {
      navigate('/');
      hooks?.onClickTap('0');
      msgHooks?.handleChangeComponent('chatroom');
      setMobileBuddyListS(false);
    }
    if (label === '메세지') {
      navigate('/');
      hooks?.onClickTap('0');
      msgHooks?.handleChangeComponent('chatroom');
    }
    // custom 타입 일 때
    if (label === '공지사항') {
      setCustomerRouterState(0);
    }
    if (label === 'QnA') {
      setCustomerRouterState(1);
    }
    if (label === 'VOC') {
      window.open('http://www.voc.standardnetworks.co.kr/?origin=standart&code=00');
    }
  };

  const navItems = [
    { id: useId(), icon: <IconAddress />, label: '주소록' },
    { id: useId(), icon: <IconCassetteTape />, label: '인사말설정' },
    { id: useId(), icon: <IconMessage />, label: '메세지' },
    { id: useId(), icon: <IconHuman />, label: '회원정보' },
  ];

  const navCustomItems = [
    { id: useId(), icon: <IconAddress />, label: '공지사항' },
    { id: useId(), icon: <IconStar />, label: 'QnA' },
    { id: useId(), icon: <IconMessage />, label: 'VOC' },
    { id: useId(), icon: <IconHuman />, label: '회원정보' },
  ];

  // 타입별로 배열을 매핑하는 객체
  const navItemsMap: { [key: string]: typeof navItems } = {
    private: navItems,
    custom: navCustomItems,
  };

  // props.type에 맞는 배열을 가져오고, 없다면 기본값으로 navItems 사용
  const itemsToRender = navItemsMap[props.type] || navItems;

  return (
    <PageNavContainer className="pageNav">
      {itemsToRender.map((item, index) => (
        <NavButton
          key={item.id}
          onClick={() => {
            handleClickNavBtn((item.label as TMNav) ?? '주소록');
          }}
          isActive={mobileNavState === item.label}
        >
          {item.icon}
          <p>{item.label}</p>
        </NavButton>
      ))}
    </PageNavContainer>
  );
};

export default PageNav;
