import PrivateRoute from '@/components/common/PrivateRoute';
import AdNoticePopupContent from '@/components/windowPopup/adNoticePopup/AdNoticePopupContent';
import HelpPopupContent from '@/components/windowPopup/helpPopup/HelpPopupContent';
import CustomerPage from '@/pages/CustomerPage/CustomerPage';
import Error404 from '@/pages/Error404';
import AccountFindResult from '@/pages/FindAccountPage/AccountFindResult/AccountFindResult';
import FindAccount from '@/pages/FindAccountPage/FindAccount';
import FindAccountList from '@/pages/FindAccountPage/FindAccountList';
import LayoutWithHeader from '@/pages/LayoutWithHeader';
import LinkSignInPage from '@/pages/LinkSignInPage/LinkSignInPage';
import MyPage from '@/pages/MyPage/MyPage';
import SignUp from '@/pages/SignUpPage/signUp/SignUp';
import SignUpDelete from '@/pages/SignUpPage/signUpDelete/SignUpDelete';
import SignUpExistence from '@/pages/SignUpPage/signUpExistence/SignUpExistence';
import SignUpForm from '@/pages/SignUpPage/signUpForm/SignUpForm';
import SignUpIdCheck from '@/pages/SignUpPage/signUpIdCheck/SignUpIdCheck';
import SignUpNiceResult from '@/pages/SignUpPage/signUpNiceResult/SignUpNiceResult';
import SignUpRecovery from '@/pages/SignUpPage/signUpRecovery/SignUpRecovery';
import SignUpResult from '@/pages/SignUpPage/signUpResult/SignUpResult';
import ComEmailveto from '@/pages/Terms/ComEmailveto';
import ComProtect from '@/pages/Terms/ComProtect';
import ComProtectBack20151030 from '@/pages/Terms/ComProtectBack20151030';
import ComProtectBack20180424 from '@/pages/Terms/ComProtectBack20180424';
import ComProtectBack20240704 from '@/pages/Terms/ComProtectBack20240704';
import ComProtectOld from '@/pages/Terms/ComProtectOld';
import ComSpampolicy from '@/pages/Terms/ComSpampolicy';
import ComUseContent from '@/pages/Terms/ComUseContent';
import ComUseContent015 from '@/pages/Terms/ComUseContent015';
import ComUsecontentBack20160618 from '@/pages/Terms/ComUsecontentBack20160618';
import ComUsecontentBack20171201 from '@/pages/Terms/ComUsecontentBack20171201';
import ComYouthpolicy from '@/pages/Terms/ComYouthpolicy';
import MainPage from '@/pages/main';
import SignIn from '@/pages/signIn/SignIn';
import AuthComponent from '@/shared/components/niceCertification/ui/AuthComponent';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Thanos from '@/pages/Thanos';
import ComUseContentBack20241010 from '@/pages/Terms/ComUseContentBack20241010';
import EasyComponent from '@/shared/components/easyPay/ui/EasyComponent';

import ComUseContentBack20241031 from '@/pages/Terms/ComUsecontentBack20241031';
import AppDownloadComponent from '@/pages/AppDownloadComponent';

const AppRoutes: React.FC = () => {
  return (
    // 앱과 나머지 분기 처리 ****************************************************************************************************************
    <>
      <Routes>
        {/* 앱 다운로드 페이지 */}
        <Route path="/app" element={<AppDownloadComponent />} />
        {/* 고객센터 */}
        <Route path="/customer/:customerId" element={<CustomerPage />} />
        {/* 도움말 */}
        <Route path="/helpContent" element={<HelpPopupContent />} />
        {/* 광고 공지 */}
        <Route path="/adNoticeContent" element={<AdNoticePopupContent />} />
        {/* 구 아레오 링크 타고 오는 컴포넌트 */}
        <Route path={'/linkSignIn'} element={<LinkSignInPage />} />
        {/* 나머지 모든 페이지 에러 */}
        <Route path="/*" element={<Error404 />} />

        {/* 메인 페이지 ********************************************************************************************************************************************************************************************** */}
        {/* 헤더 필요 없고 로그인 해야하는 페이지 ********************************************************************************************************************************************************************************************** */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<MainPage />} />
          {/* <Route path="thanos" element={<Thanos />} /> */}
        </Route>

        {/* 헤더 필요 ******************************************************************************************************* */}
        <Route element={<LayoutWithHeader />}>
          {/*로그인 필요 ***************************************************************************************************************** */}
          <Route element={<PrivateRoute />}>
            {/* 회원정보 */}
            <Route path="/mypage" element={<MyPage link="mypage" />} />
            <Route path="/userinfochange" element={<MyPage link="userinfochange" />} />
            <Route path="/passwordchange" element={<MyPage link="passwordchange" />} />
            <Route path="/phonenumberchange" element={<MyPage link="phonenumberchange" />} />
            <Route path="/usernamechange" element={<MyPage link="usernamechange" />} />
            <Route path="/callingnumber" element={<MyPage link="callingnumber" />} />
            <Route path="/memberleave" element={<MyPage link="memberleave" />} />

            {/* 회원정보 - My015 서비스 */}
            <Route path="/my015subinfo" element={<MyPage link="my015subinfo" />} />
            <Route path="/my015charge" element={<MyPage link="my015charge" />} />
            <Route path="/say015subinfo" element={<MyPage link="say015subinfo" />} />

            {/* 회원정보 - 충전소 */}
            <Route path="/charge" element={<MyPage link="charge" />} />
            <Route path="/charging" element={<MyPage link="charging" />} />
            <Route path="/payment" element={<MyPage link="payment" />} />
            <Route path="/payment/detail" element={<MyPage link="detail" />} />
            <Route path="/usage" element={<MyPage link="usage" />} />
            <Route path="/messageResult" element={<MyPage link="messageResult" />} />
            <Route path="/taxCashReceiptsDetail" element={<MyPage link="taxCashReceiptsDetail" />} />
            <Route path="/TaxInvoiceDetail" element={<MyPage link="taxInvoiceDetail" />} />
            <Route path="/TaxInvoiceAuto" element={<MyPage link="TaxInvoiceAuto" />} />
            <Route path="/RegisterTaxInvoice" element={<MyPage link="RegisterTaxInvoice" />} />
          </Route>

          {/*로그인 필요 없음  ***************************************************************************************************************** */}

          {/* 로그인 */}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/findaccount" element={<FindAccount />} />
          <Route path="/findaccountList" element={<FindAccountList />} />
          <Route path="/accountFindResult" element={<AccountFindResult />} />
          {/* 이용약관 */}
          <Route path="/ComUseContent" element={<ComUseContent />} />
          <Route path="/ComUseContent015" element={<ComUseContent015 />} />
          <Route path="/ComUseContentBack20241031" element={<ComUseContentBack20241031 />} />
          <Route path="/ComUseContentBack20241010" element={<ComUseContentBack20241010 />} />
          <Route path="/ComUsecontentBack20171201" element={<ComUsecontentBack20171201 />} />
          <Route path="/ComUsecontentBack20160618" element={<ComUsecontentBack20160618 />} />
          <Route path="/ComProtect" element={<ComProtect />} />
          <Route path="/ComProtectOld" element={<ComProtectOld />} />
          <Route path="/ComProtectBack20151030" element={<ComProtectBack20151030 />} />
          <Route path="/ComProtectBack20180424" element={<ComProtectBack20180424 />} />
          <Route path="/ComProtectBack20240704" element={<ComProtectBack20240704 />} />
          <Route path="/ComYouthpolicy" element={<ComYouthpolicy />} />
          <Route path="/ComSpampolicy" element={<ComSpampolicy />} />
          <Route path="/ComEmailveto" element={<ComEmailveto />} />
          {/* 회원가입 */}
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/SignUpForm" element={<SignUpForm />} />
          <Route path="/SignUpExistence" element={<SignUpExistence />} />
          <Route path="/SignUpNiceResult" element={<SignUpNiceResult />} />
          <Route path="/SignUpIdCheck" element={<SignUpIdCheck />} />
          <Route path="/SignUpRecovery" element={<SignUpRecovery />} />
          <Route path="/SignUpDelete" element={<SignUpDelete />} />
          <Route path="/SignUpResult" element={<SignUpResult />} />
          <Route path="/AuthComponent" element={<AuthComponent />} />
          <Route path="/EasyComponent" element={<EasyComponent />} />
        </Route>
        {/*여기까지가 pc ***************************************************************************************************************** */}
      </Routes>
    </>
  );
};
export default AppRoutes;
