import React from 'react';
import styled from 'styled-components';

/**
 * @title 무한스크롤 동작시 표시할 Loader 컴포넌트
 *
 * @author 김남규
 * @since 2024-04-28
 */

const NextLoaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  margin-bottom: 1.2rem;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  background: #f8f9fd;
`;

const NextLoader = () => {
  return <NextLoaderBox className="NextLoaderBox">다음페이지 로딩중...</NextLoaderBox>;
};
export default NextLoader;
