import { Select } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 5;
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%) translateY(-50%);
  max-width: 54rem;
  max-height: 62rem;
  width: 90%;
  height: calc(var(--vh, 100vh) - 20vh);
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 0.4rem 2rem 0 rgba(90, 90, 90, 0.3);
  border-radius: 1rem;
  .notText {
    color: ${(props) => props.theme.colors.txt200};
  }

  @media screen and (max-width: 768px) {
  }
`;

export const AddressCrose = styled.div`
  display: flex;
  justify-content: flex-end;
  .closeBtn {
    padding: 1.4rem;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const AddressHistoryWrap = styled.div`
  font-size: 1.4rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: center;
  ::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }

  ::-webkit-scrollbar {
    width: 1.4rem;
    height: 1.4rem;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: 0.5rem solid transparent;
    background-clip: padding-box;
  }

  .groupWrap {
    position: relative;
  }
  .selectInput {
    > div {
      top: calc(100% + 1rem);
      left: 50%;
      transform: translateX(-50%);
      right: auto;
    }
  }
  > .wrap {
    text-align: start;
    vertical-align: middle;
    display: inline-block;
    /* margin: 4.2rem auto; */
    width: calc(100% - 14rem);
  }
  .top {
    text-align: center;
    margin: 0 0 4rem;
    .customer {
      position: relative;
      font-size: 2.5rem;
      margin-bottom: 2rem;
      min-height: 2.5rem;
      input {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 2.5rem;
        text-align: center;
      }
    }
    .groupWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      > p {
        cursor: pointer;
        padding: 0 1rem;
        color: ${(props) => props.theme.colors.main};
      }
    }
  }

  @media screen and (max-width: 768px) {
    ::before {
      display: none;
    }
    > .wrap {
      padding-top: 1.2rem;
      text-align: start;
      vertical-align: baseline;
      display: inline-block;
      /* margin: 4.2rem auto; */
      width: 80%;
    }
  }
`;

export const Information = styled.div`
  margin: 0 0 4rem;
  > div {
    display: flex;
    align-items: center;
    margin: 0 0 2rem;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt500};
    :last-of-type {
      margin: 0;
    }
    h4 {
      width: 7rem;
      font-weight: 600;
    }
    > div {
      width: calc(100% - 7rem);
      p {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    input,
    textarea {
      width: 100%;
      border-radius: 0.5rem;
      border: 1px solid ${(props) => props.theme.colors.txt200};
      padding: 0.5rem 1rem;
      resize: none;
    }
  }

  @media screen and (max-width: 768px) {
    > div {
      justify-content: space-between;
      > h4 {
        width: 30%;
      }

      > div {
        width: 70%;
      }

      input,
      textarea {
        width: 100%;
        border-radius: 0.5rem;
        border: 1px solid ${(props) => props.theme.colors.txt200};
        padding: 0.5rem 1rem;
        resize: none;
      }
    }
  }
`;

export const CustomSelect = styled(Select)<{ active?: boolean }>`
  .ant-select-selector {
    border-color: ${(props) => (props.active ? '#1549CE' : 'rgb(176, 176, 176)')} !important; // active일 때 노란색
    height: 28px !important;
    font-size: 1.2rem;
  }

  &:hover .ant-select-selector {
    border-color: rgb(255, 215, 0) !important; // hover 시 보더 색상
  }
`;

// 상세 셀렉트
export const CardWrap = styled.div`
  /* 플러스 아이콘 */
  .plus {
    position: relative;
    width: 2rem;
    height: 2rem;
    background: ${(props) => props.theme.colors.main};
    border-radius: 50%;

    ::after,
    ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      background: ${(props) => props.theme.colors.gray100};
    }
    ::after {
      width: 50%;
      height: 1px;
    }
    ::before {
      width: 1px;
      height: 50%;
    }
  }

  > div {
    width: 100%;
    font-size: 1.4rem;
    margin: 0 0 1rem;
    font-weight: 400;
    &.active {
      ul {
        display: block;
      }
      .arrow {
        rotate: 180deg;
      }
    }
    :last-of-type {
      margin: 0;
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.7rem 1rem;
      border-radius: 0.5rem;
      color: ${(props) => props.theme.colors.txt400};
      background: ${(props) => props.theme.colors.gray200};
      gap: 1.2rem;
      font-size: 1.2rem;
    }
    ul {
      display: none;
      padding: 0 1rem 1rem;

      li {
        cursor: auto;
        display: flex;
        align-items: center;
        margin: 1.2rem auto 0;
        min-height: 3rem;
        h4 {
          width: 7rem;
          font-weight: 600;
        }
        > div {
          width: calc(100% - 7rem);
          p {
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        input,
        textarea {
          width: 100%;
          border-radius: 0.5rem;
          border: 1px solid ${(props) => props.theme.colors.txt200};
          padding: 0.5rem 1rem;
          resize: none;
        }
      }

      @media screen and (max-width: 768px) {
        li {
          justify-content: space-between;
          h4 {
            width: 30%;
            font-size: 1.2rem;
          }
          > div {
            width: 70%;
          }
        }
      }
    }
  }
`;

export const AddressEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1.4rem 1.4rem 3rem;
  button {
    padding: 1.2rem 4rem;
    border-radius: 0.5rem;
    color: ${(props) => props.theme.colors.txt100};
  }
  .editBtn {
    background: ${(props) => props.theme.colors.grayButton};
  }
  .addBtn {
    padding: 1.2rem 6rem;
    background: ${(props) => props.theme.colors.main};
  }
`;
