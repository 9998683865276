import styled from 'styled-components';

export const SayContent = styled.div<{ isActive: boolean }>`
  /* min-height: calc(var(--vh, 100vh)); */
  background: ${(props) => props.theme.colors.background};
  :has(.Say015Mockup) {
    height: 100%;
    background: linear-gradient(
      0deg,
      ${(props) => props.theme.colors.gray400},
      ${(props) => props.theme.colors.gray200}
    );
  }

  @media screen and (max-width: 768px) {
    z-index: ${(props) => (props.isActive ? 100 : -10000)};
  }
`;

export const Say015Wrap = styled.div`
  /* padding: 0 2.8rem 0 5%;
  @media screen and (max-width: 1440px) {
    padding: 0 5%;
  } */
  > div {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
`;

export const Say015NodataWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;

  > div {
    text-align: center;
  }
  button {
    margin: 2rem auto 0;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    background: ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.txt100};
    font-size: 2rem;
  }
  @media screen and (max-height: 200px) {
    display: inline-block;
    > div {
      padding: 2rem 0;
    }
  }
  @media screen and (max-width: 768px) {
    > div {
      padding: 2rem 0 8rem;
    }
  }
`;

// ARS 서비스 첫페이지
export const MockupWrap = styled.div``;

export const MockupText = styled.div`
  color: ${(props) => props.theme.colors.txt500};
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 4rem;
  span {
    color: ${(props) => props.theme.colors.main};
  }
  p {
    font-size: 2rem;
  }
  .line {
    display: block;
    margin: 1.6rem auto;
    width: 1px;
    height: 7.2rem;
    background: ${(props) => props.theme.colors.gray800};
  }
  small {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt400};
  }
  @media screen and (max-width: 640px) {
    p {
      font-size: 1.6rem;
      line-height: 1.6em;
    }
  }
  @media screen and (max-width: 460px) {
    p {
      font-size: 1rem;
    }
    .line {
      height: 2rem;
      margin: 1vw auto;
    }
  }
`;

export const MockupContent = styled.div`
  position: relative;
  margin-top: 8rem;

  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    margin: 16rem auto 3rem;
    li {
      background: ${(props) => props.theme.colors.white};
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
      > p {
        position: relative;
        height: 14.2rem;
        img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          max-width: 95%;
          max-height: 23rem;
          object-fit: contain;
        }
      }
      .txtBox {
        padding: 5.6rem 1.8rem 1.8rem;
        color: ${(props) => props.theme.colors.txt500};

        h3 {
          font-size: 2.6rem;
          color: ${(props) => props.theme.colors.main};
          font-weight: 600;
          margin-bottom: 2.4rem;
        }
        p {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.6em;
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    margin-bottom: 4rem;
    ul {
      grid-template-columns: repeat(2, 1fr);
      li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1.2rem;
        > p {
          width: 14rem;
          height: auto;
          display: flex;
          align-items: center;
          img {
            max-width: 100%;
            position: relative;
            left: unset;
            top: unset;
            transform: none;
          }
        }
        .txtBox {
          width: calc(100% - 14rem);
          padding: 0;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 460px) {
    margin-top: 4rem;
    ul {
      margin: 0 auto;
      li {
        flex-direction: column;
        > p {
          width: 10rem;
        }
        .txtBox {
          width: 100%;
          h3 {
            font-size: 2rem;
          }
        }
      }
    }
  }
`;

export const ServiceBtn = styled.button`
  cursor: pointer;
  display: block;
  margin: 6rem auto;
  width: 60%;
  height: 5rem;
  max-width: 24rem;
  font-size: 1.7rem;
  padding: 1.4rem;
  border-radius: 1rem;
  background: ${(props) => props.theme.colors.main};
  color: ${(props) => props.theme.colors.txt100};

  @media screen and (max-width: 640px) {
    height: auto;
  }
  @media screen and (max-width: 460px) {
    margin: 3rem auto;
    padding: 1rem 1.4rem;
    font-size: 1.4rem;
    width: 50%;
  }
`;
