import { useMutation } from '@tanstack/react-query';
import { instance } from '@/shared/lib/clientAxios';

interface CreateMultiMessageParams {
  groupSeqList?: number[];
  buddySeqList?: number[];
  etcPhoneNumberList?: string;
  name?: string;
}

interface MultiMessageRoomResponse {
  chatRoomId: string; // 채팅방 ID
  rcvPhnIds: string[]; // 수신자 전화번호 목록
  name: string; // 채팅방 이름
}

export const useCreateMultiChat = () => {
  const { mutateAsync, isLoading, isError, error } = useMutation(
    async ({
      groupSeqList,
      buddySeqList,
      etcPhoneNumberList,
      name,
    }: CreateMultiMessageParams): Promise<MultiMessageRoomResponse> => {
      try {
        const response = await instance.post('/room', {
          groupSeqList,
          buddySeqList,
          etcPhoneNumberList,
          name,
        });
        return response.data; // 응답 데이터 반환
      } catch (error) {
        console.error('Failed to create multi-message room:', error);
        throw error; // 오류를 상위로 전달
      }
    },
  );

  return { mutateAsync, isLoading, isError, error };
};
