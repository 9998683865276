import styled from 'styled-components';

export const CustomerPageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: calc(var(--vh, 50vh) - 21.8rem);
  /* 고객센터내 소프트폰 조정 */
  .header {
    > div {
      margin: 0;
      width: 100%;
    }
  }
  .SoftPhoneInner {
    height: 100%;
  }
  .MessageSendHistoryContainer {
    height: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    padding: 3rem 0;
    overflow: auto;
  }
  @media screen and (max-width: 768px) {
    min-height: calc(var(--vh, 10vh) - 6rem);
    .MessageSendHistoryContainer {
      height: calc(var(--vh, 10vh) - 6rem);
    }
  }
`;
export const Customer = styled.div`
  position: relative;
  width: calc(100% - 520px);
  padding: 0 3rem 0 5%;
  @media screen and (max-width: 1920px) {
    width: calc(100% - 450px);
  }
  @media screen and (max-width: 1440px) {
    width: 100%;
    padding: 0 5%;
  }
`;
