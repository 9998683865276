import styled from 'styled-components';

export const InfoWrap = styled.div`
  /* z-index: 99999; */
  padding-bottom: 30px;
`;

// 항목 styled
export const H2 = styled.h2`
  font-size: 1.4rem;
  font-weight: bold;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  margin-top: 20px;
`;

export const DivWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  row-gap: 5px;
`;

export const InputWrap = styled.div`
  display: flex;
  width: 110px;
  align-items: center;
  gap: 5px;
`;
