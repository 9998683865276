import { getReserveSendDetail } from '@/apis/api/stContentDetailApis';
import { scheduleDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import { formatDateBase } from '@/shared/util/format/dateFormatUtil';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useSchedule = () => {
  const pageSize: number = 10;
  const [tableData, setTableData] = useState<any>([{}]);
  const [detailInfo, setDetailInfo] = useState<any>([{}]);
  const [datePicker, setDatePicker] = useState<string>('');
  const [sndMsg, setSndMsg] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [msgGb, setMsgGb] = useState<'SMS' | 'MMS' | 'LMS' | null>(null);

  const scheduleDetailParamsState = useRecoilValue(scheduleDetailParamsRecoil);

  const [searchAllValue, setSearchAllValue] = useState<any>({
    pageSize,
    currentPage: 0,
    keywordValue: null,
    keyword: null,
    prepayPayNo: scheduleDetailParamsState.prepayPayNo,
    callback: scheduleDetailParamsState.callback,
  });

  // 리스트 클릭 대상 변경
  useEffect(() => {
    const changeListApi = {
      pageSize: 10,
      currentPage: 0,
      keywordValue: null,
      keyword: null,
      prepayPayNo: scheduleDetailParamsState.prepayPayNo,
      callback: scheduleDetailParamsState.callback,
    };
    setSearchAllValue(changeListApi);
  }, [scheduleDetailParamsState.prepayPayNo, scheduleDetailParamsState.callback]);

  // ? 예약전송 리스트 조회 Query
  const queryKey = ['getReserveSendDetail', searchAllValue];
  const { data, fetchNextPage, hasNextPage, isLoading, isError } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 0 }) => getReserveSendDetail({ ...searchAllValue, pageNumber: pageParam }),
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length;
        const isLastPage = nextPage * pageSize >= lastPage.sendList?.totalElements;
        return isLastPage ? undefined : nextPage; // 다음 페이지가 없으면 null
      },
      enabled: searchAllValue.prepayPayNo !== '',
    },
  );

  // 데이터를 업데이트하는 useEffect
  useEffect(() => {
    if (!isLoading && !isError && typeof data.pages[0] !== 'string') {
      const allPagesData = data.pages.flatMap((page) => page.sendList?.content);
      setTableData(allPagesData);
      const lastPageData = data.pages[data.pages.length - 1];
      setDetailInfo({
        ...lastPageData,
        failCnt: lastPageData.totalCount - (lastPageData.sendingCount + lastPageData.successCount),
      });

      setDatePicker(formatDateBase(lastPageData.sndDttm));
      setSndMsg(lastPageData.sndMsg);
      setSubject(lastPageData.subject);
      setMsgGb(lastPageData.msgGb);
    } else if (!isLoading && !isError) {
      setTableData([]);
      setDetailInfo({});
      setSndMsg('');
      setSubject('');
      setMsgGb(null);
    }
  }, [data, isLoading, isError]);

  // 무한 스크롤 이벤트 핸들러
  const handleScroll = (e: any) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && hasNextPage) {
      fetchNextPage(); // 페이지 끝에 도달하면 다음 페이지 불러오기
    }
  };

  return {
    pageSize,
    isLoading,
    detailInfo,
    datePicker,
    setDatePicker,
    sndMsg,
    setSndMsg,
    subject,
    setSubject,
    msgGb,
    tableData,
    setSearchAllValue,
    scheduleDetailParamsState,
    handleScroll,
  };
};
