import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconX: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, stroke, fill, strokeWidth }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '0.9rem'}
      viewBox="0 0 9 9"
      fill="none"
    >
      <path
        d="M7.80469 0.84082L0.804688 7.84082M0.804688 0.84082L7.80469 7.84082"
        stroke={stroke ?? 'currentColor'}
        strokeWidth={strokeWidth ?? '1.5'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconX;
