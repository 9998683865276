import { useMessageSendInfo } from '@/components/hooks/customs/addressBook/useMessageSendInfo';
import { openInfoModalRecoil, openModalRecoil } from '@/recoil/atoms/modalRecoil';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IMessageSendBottom } from './MessageSend.type';
import { softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';

const MessageSendInfo = (props: IMessageSendBottom) => {
  const [openModalS, setOpenModalS] = useRecoilState(openModalRecoil);
  const [openInfoModalS, setOpenInfoModalS] = useRecoilState(openInfoModalRecoil);
  // 소프트폰 탭 상태 - 0: 015, 1: 010
  const softPhoneTopTabR = useRecoilValue(softPhoneTopTabRecoil);

  const sendInfoHooks = useMessageSendInfo({
    onClickSendValidation: props.onClickSendValidation,
    softPhoneRowTabState: props.softPhoneRowTabState,
    fileResult: props.fileResult,
    sndMsgState: props.sndMsgState,
    onClickSend: props.onClickSend,
  });

  // 전송버튼
  const onClickSendBtn = () => {
    // 015홍보모달 표출
    setOpenModalS(true);
  };

  // 홍보모달 닫히고 전송하기 이벤트 시작
  useEffect(() => {
    if (openInfoModalS) {
      setOpenModalS(false);
      setOpenInfoModalS(false);
      sendInfoHooks.onClickSendConfirm();
    }
    if (localStorage.getItem('event2') && openModalS) {
      setOpenModalS(false);
      sendInfoHooks.onClickSendConfirm();
    }
  }, [openInfoModalS, openModalS]);

  return (
    // className={sendBtnState ? '컨펌전' : '컨펌후'}
    // 아래 주석된 코드는 메시지 전송 포맷을 지켰을 때 토글되는 코드
    // <div className={`${sendInfoHooks.sendBtnState ? '' : 'active'}`}>
    <div className="active">
      <button
        className="checkButton"
        onClick={() => {
          softPhoneTopTabR === '0' ? sendInfoHooks.onClickSendConfirm() : onClickSendBtn();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
          <path
            d="M20.2539 12.2539C21.6914 11.5798 21.6914 9.53353 20.253 8.86029L2.68245 0.62322C1.23966 -0.0526737 -0.333896 1.26466 0.0760576 2.80375L1.81041 9.30735L9.16661 9.30735C9.49418 9.31305 9.80642 9.44717 10.0361 9.68084C10.2657 9.91451 10.3944 10.229 10.3944 10.5567C10.3944 10.8843 10.2657 11.1988 10.0361 11.4325C9.80642 11.6661 9.49418 11.8003 9.16661 11.806H1.81041L0.0769438 18.3104C-0.333896 19.8486 1.24054 21.1651 2.68245 20.4901L20.2539 12.2539Z"
            fill="#366EFF"
          />
        </svg>
        {/* <div>전송하기</div> */}
      </button>
    </div>
  );
};
export default MessageSendInfo;
