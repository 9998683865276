import { useRecoilState } from 'recoil';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { unCheckChildrenArrR } from '@/recoil/atoms/messageSend/messageSend';
import { fetchMutation } from '@/components/hooks/mutations/fetchMutation';

type GetBuddySeqNoDType = {
  buddySeqNos: number[];
  groupSeqNo: number;
  groupNm: string;
};
/**
 * @title 유저의 버디 시퀀스들을 상황에 맞게 가져오는 함수 들을 모아둔 hook 파일
 *
 * @author 정휘학
 * @since 2024.06.05
 * */
export const useGetBuddySeqNo = () => {
  const [mainTableCheckDataFormatS] = useRecoilState(mainTableCheckDataFormatRecoil);
  const [unCheckChildrenArrS] = useRecoilState(unCheckChildrenArrR);

  // =======================================================
  // 그룹 시퀀스 넘버를 넘겨 해당하는 하위 버디 시퀀스 넘버 배열을 받는 api
  // =======================================================
  const { mutateAsync: getBuddySeqNoM } = fetchMutation<{ groupSeqNos: number[] } | null, GetBuddySeqNoDType[]>({
    mutationKey: ['/contacts/group/buddies'],
    method: 'GET',
  });
  // =====================================================
  // 주소록 메인 테이블에 체크된 모든 리스트의 버디 시퀀스 가져오는 hook
  // =====================================================
  const getBuddySeqNosToMainTableCheckDataFormatRecoil = async (): Promise<number[]> => {
    let needApiGroupSeqNoArr: number[] = [];
    if (!mainTableCheckDataFormatS) return [];
    /* 전체 체크되어있는 그룹 시퀀스 배열 */
    const checkingGroupSeqNoArr: number[] = mainTableCheckDataFormatS
      .filter((item) => item.groupCheck)
      .flatMap((item) => item.groupSeqNo);
    /* 전체체크 한 후 하위 리스트의 체크박스를 false 가 된 groupSeqNo 배열 */
    const unCheckingGroupSeqNoArr: number[] = mainTableCheckDataFormatS
      .filter((item) => !item.groupCheck && item.api)
      .flatMap((item) => item.groupSeqNo);
    /* 전체 체크했다가 하위 리스트 체크 박스를 개별로 체크 푼 버디 시퀀스 배열 */
    let removeBuddySeqNoArr: number[] = [];
    if (unCheckingGroupSeqNoArr && unCheckingGroupSeqNoArr.length > 0 && unCheckChildrenArrS) {
      unCheckingGroupSeqNoArr.forEach((item) => {
        const concatData: number[] = unCheckChildrenArrS
          .filter((f) => f.groupSeqNo === item)
          .flatMap((fm) => fm.buddySeqNo);
        removeBuddySeqNoArr = removeBuddySeqNoArr.concat(concatData);
      });
    }

    /* api 를 요청해서 버디 시퀀스를 가져와야 하는 그룹 시퀀스 배열 */
    needApiGroupSeqNoArr = checkingGroupSeqNoArr.concat(unCheckingGroupSeqNoArr);
    /* 하위 리스트를 개별로 체크한 버디 시퀀스 배열 */
    const onlyCheckingListSeqNoArr: (number | null)[] = mainTableCheckDataFormatS
      .filter((item) => item.buddySeqNo !== null)
      .flatMap((item) => item.buddySeqNo);
    const onlyCheckingBoolean = onlyCheckingListSeqNoArr.every((item) => typeof item === 'number');

    /* 직접 개별로 하위 리스트 체크한 값만 있을 경우 */
    if (!(needApiGroupSeqNoArr.length > 0) && onlyCheckingBoolean) {
      return onlyCheckingListSeqNoArr as number[];
    }
    /* api 를 요청해서 버디 시퀀스를 가공해야 하는 경우 */
    if (needApiGroupSeqNoArr.length > 0) {
      const data = await getBuddySeqNoM({ groupSeqNos: needApiGroupSeqNoArr });
      let flatBuddySeqNos = data.flatMap((fm) => fm.buddySeqNos);
      flatBuddySeqNos = flatBuddySeqNos.filter((f) => !removeBuddySeqNoArr?.includes(f));
      if (onlyCheckingBoolean) {
        flatBuddySeqNos = flatBuddySeqNos.concat(onlyCheckingListSeqNoArr as number[]);
      }
      return flatBuddySeqNos;
    }
    return [];
  };
  return {
    getBuddySeqNosToMainTableCheckDataFormatRecoil,
  };
};
