import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { mainTableCacheUpdate } from '@/components/cache/mainTableCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useAddressBookMutations } from '@/components/hooks/mutations/addressBook/useAddressBookMutations';
import React, { useEffect, useState } from 'react';
import { fetchMutation } from '../../mutations/fetchMutation';

export interface IEditData {
  groupSeqNo: number;
  groupNm?: string;
}

/**
 * @title 그룹 이름 수정 EDIT Hooks
 *
 * @author 김남규
 * @since 2024.03.15
 */

export const useEditGroup = () => {
  /* 그룹 수정 Input toggle STATE */
  // const [editOpen, setEditOpen] = useState<{ [key: number]: boolean }>({});
  const [editOpen, setEditOpen] = useState<{ [key: number]: boolean }>({});
  /* 그룹 수정 이름 Input value STATE */
  const [groupEditValue, setGroupEditValue] = useState<{ [key: string]: string }>({});
  /* 더블 클릭시 토글 groupSeqNo STATE */
  const [activeGroupSeqNos, setActiveGroupSeqNos] = useState<number[]>([]);
  /* 더블 클릭시 토글 groupSeqNo STATE */
  const [activeGroupSeqNo, setActiveGroupSeqNo] = useState<number | null>(null);
  const cacheHooks = groupListCacheUpdate();

  /* 주소록 메인 테이블 캐싱 업데이트 hook */
  const { mainTableGroupNmChangeToCacheUpdate } = mainTableCacheUpdate();
  /* 주소록 메인 테이블 mutation hook 에 있는 recoil reset 함수 */
  const { mainTableResetRecoil } = useAddressBookMutations();
  // ==============================
  // 그룹 이름 수정 mutate api (POST)
  // ==============================
  const { mutate: editGroupMutation } = fetchMutation<any, any>({
    mutationKey: ['/group/update'],
    method: 'POST',
  });

  const { warningModal } = useModalHook();

  // ==============================
  // 그룹 이름 유효성 검사 후 mutate로 보내고 onSuccess, onError 처리 hook
  // ==============================
  const patchGroupOnClickHandler = (item: number) => {
    // 빈 값이면 제출하지 않고 토글하고 이전 값으로 표시
    const groupNm = groupEditValue[item]?.trim();
    if (!groupNm) {
      setEditOpen((prevStates) => ({ ...prevStates, [item]: false }));
      return;
    }

    const groupEditValuesArray: IEditData = { groupSeqNo: item, groupNm };
    editGroupMutation([groupEditValuesArray], {
      onSuccess: () => {
        handleEditSuccess(groupEditValuesArray);
      },
      onError: (error: any) => {
        warningModal('그룹 수정', `에러 발생: ${error.message}`, true);
      },
    });
  };

  // ==============================
  // 그룹이름 업데이트성공시 실행 hook
  // ==============================
  const handleEditSuccess = (editData: IEditData) => {
    mainTableResetRecoil();
    mainTableGroupNmChangeToCacheUpdate(editData);
    cacheHooks.handleGroupEditCacheUpdate(editData);
    setGroupEditValue((prevValues) => ({ ...prevValues, [editData.groupSeqNo]: '' }));
    setEditOpen((prevStates) => ({ ...prevStates, [editData.groupSeqNo]: false }));
  };

  // =============================
  // 그룹 이름 더블클릭으로 변경시 이전값 혹은 수정된 값으로 제출하는 hook
  // ==============================
  const groupDoubleClickHandle = (groupSeqNo: number) => {
    // 이전 그룹의 입력 내용을 제출하고 토글
    if (activeGroupSeqNos.includes(groupSeqNo)) {
      patchGroupOnClickHandler(groupSeqNo);
      setActiveGroupSeqNos((prev) => prev.filter((seqNo) => seqNo !== groupSeqNo)); // 현재 그룹을 비활성화
    } else {
      setActiveGroupSeqNos((prev) => [...prev, groupSeqNo]); // 현재 그룹을 활성화
    }

    setEditOpen((prevStates) => ({
      ...prevStates,
      [groupSeqNo]: !prevStates[groupSeqNo],
    }));
  };

  // =============================
  // 여러 그룹 리스트를 한꺼번에 수정 상태로 변환
  // =============================

  const setMultipleGroupsToEditMode = (groupSeqNos: number[]) => {
    setEditOpen((prevStates) => {
      return groupSeqNos.reduce(
        (newState, groupSeqNo) => {
          return {
            ...newState,
            [groupSeqNo]: true,
          };
        },
        { ...prevStates },
      );
    });

    // activeGroupSeqNos 업데이트
    setActiveGroupSeqNos(groupSeqNos); // 선택된 그룹 번호 배열로 설정
  };

  // ==============================
  // 그룹 이름 onChange hook
  // ==============================
  const groupEditValueHandle = (value: string, groupSeqNo: any) => {
    setGroupEditValue((prevValues) => ({ ...prevValues, [groupSeqNo]: value }));
  };

  const groupEditSendHandle = (el: any) => {
    patchGroupOnClickHandler(el.groupSeqNo);
    groupDoubleClickHandle(el.groupSeqNo); // editOpen 상태를 토글
  };

  // ==============================
  // 그룹 이름 더블클릭 hook
  // ==============================
  const groupEditOnkeyDownHandle = (e: React.KeyboardEvent<HTMLInputElement>, el: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Enter 키의 기본 동작 방지
      patchGroupOnClickHandler(el.groupSeqNo);
      groupDoubleClickHandle(el.groupSeqNo); // editOpen 상태를 토글
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      // setGroupEditValue((prevValues) => ({ ...prevValues, [el.groupSeqNo]: '' }));
      setGroupEditValue({});
      setEditOpen({});
    }
  };

  // ==============================
  // 마우스 click시 edit input이 아니면 edit input 닫음
  // ==============================
  // useEffect(() => {
  //   document.addEventListener('click', (e: any) => {
  //     // input이 아닌 곳을 클릭했을 때 input을 토글하도록 설정
  //     const isClickedOutsideInput = !e.target.closest('.editInput');

  //     if (isClickedOutsideInput && activeGroupSeqNo) {
  //       // 여기에 input 토글하는 동작 추가
  //       setEditOpen((prevStates) => ({ ...prevStates, [activeGroupSeqNo]: false }));
  //       // 클릭시 자동으로 저장되게 하려면 아래코드 대신 patchGroupOnClickHandler에 activeGroupSeqNo 담아 실행
  //       setGroupEditValue((prevStates) => ({ ...prevStates, [activeGroupSeqNo]: '' }));
  //     }
  //   });
  // }, [activeGroupSeqNo]);

  return {
    patchGroupOnClickHandler,
    editOpen,
    groupDoubleClickHandle,
    groupEditOnkeyDownHandle,
    groupEditValueHandle,
    groupEditValue,
    setEditOpen,
    setMultipleGroupsToEditMode,
    activeGroupSeqNos,
    setActiveGroupSeqNos,
    setGroupEditValue,
    groupEditSendHandle,
  };
};
