import React from 'react';
import * as S from './styles/Say015NotInfo';
import BaseButton from '@/components/Atom/BaseButton';

const Say015NotInfo = ({ subHooks }: any) => {
  return (
    <S.Say015NotInfo>
      <S.AWrapper>
        <S.StateGroup>
          <h2>구독 정보가 없습니다.</h2>
          <p>아래 버튼을 클릭하면 015 서비스 구독페이지로 이동합니다.</p>
        </S.StateGroup>
        <S.ButtonWrapper>
          <BaseButton disabled={subHooks.btnState} onClick={subHooks.naviResub015Handle}>
            015 구독하러가기
          </BaseButton>
        </S.ButtonWrapper>
      </S.AWrapper>
    </S.Say015NotInfo>
  );
};
export default Say015NotInfo;
