import CashCharge from '@/pages/CashCharge/CashCharge';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileEtc015SubCharge from '../mobileEtc/mobileEtc015SubCharge/MobileEtc015SubCharge';
import styled from 'styled-components';

export const Container = styled.section`
  padding: 0 2rem;
`;

const Mobile015SubCharge = () => {
  return (
    <Container>
      <MobileEtc015SubCharge />
    </Container>
  );
};

export default Mobile015SubCharge;
