import { theme } from '@/styles/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useRecoveryId } from '@/components/hooks/customs/signUp/useRecoveryId';
import * as yup from 'yup';
import { SignUpContainer } from '../signUp/SignUp.style';
import * as S from './SignUpRecovery.style';

import { checkDataProps } from './SignUpRecovery.types';

import { postCertifyMsg, postCertifyNum, postResetPassword } from '@/apis/api/signUpApis';
import BaseButton from '@/components/Atom/BaseButton';
import BaseInput from '@/components/Atom/BaseInput';
import CertifyMessageWrap from '@/components/Organism/Recovery/CertifyMessageWrap';
import RecoveryTitle from '@/components/Organism/Recovery/RecoveryTitle';
import { useMutation } from '@tanstack/react-query';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useRecoilValue } from 'recoil';

const SignUpRecovery = () => {
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);

  const location = useLocation();
  const usernameRef: any = useRef(null); // useRef로 ref 생성

  const [certifiNum, setCertifiNum] = useState<any>('');
  // 인증 문자 전송여부
  const [sendCertifyMessage, setSendCertifyMessage] = useState(false);

  // 인증 input 활성화 상태
  const [isCertify, setIsCertify] = useState<boolean>(false);

  // 만료시간 데이터
  const [min, setMin] = useState<any>(3);
  const [sec, setSec] = useState<any>(0);

  const time: any = useRef(180); // useRef hook time   변수 저장
  const timerId: any = useRef(0);

  const number = location.state.certifyData.phnId;
  const isCertifyCate = location.state.cert;
  const isSame = location.state.sameNumber;
  const isDealGb = location.state.certifyData.dealGb;
  const isType = location.state.type;

  // 휴면아이디 인증 메세지 전송 /sleep/restore/send-message
  // 2024-07-17 TODO 테스트 필요
  const { mutate: certifyNum } = useMutation(postCertifyNum);

  // 인증 메세지 전송 확인 /sleep/restore/check-message
  // 2024-07-17 TODO 테스트 필요
  const { mutate: certifyMessageMutate } = useMutation(postCertifyMsg);

  // 비밀번호 재설정 /users/password
  const { mutate: resetPasswordMutate } = useMutation(postResetPassword, {
    onSuccess: (res: any) => {
      if (res === 'success') {
        successModal(
          '성공',
          '비밀번호 변경에 성공하셨습니다.',
          true,
          environmentS === 'app' ? '/mobile/mobileSignin' : '/signin',
        );
      }
    },
    onError: () => {
      warningModal('실패', '비밀번호 변경에 실패하셨습니다.', true);
    },
  });

  const { warningModal, successModal } = useModalHook();

  // 타이머 일시정지 함수
  function stopTimer() {
    clearInterval(timerId.current);
  }

  // 인증메세지 만료시간 계산 함수
  function timers() {
    timerId.current = setInterval(() => {
      const times = time.current / 60;
      setMin(Math.floor(times));
      setSec(time.current % 60);
      time.current -= 1;
    }, 1000);

    if (time.current <= 0) {
      stopTimer(); // 시간이 종료되면 타이머 중지
    }
  }

  // 타이머 다시 시작 함수
  function restartTimer() {
    stopTimer(); // 타이머 중지
    time.current = 180; // 초기값 설정 (원하는 초로 설정)
    setMin(3); // 초기값 설정 (원하는 분으로 설정)
    setSec(0); // 초기값 설정 (원하는 초로 설정)
    timers();
  }

  useEffect(() => {
    if (time.current <= 0) {
      stopTimer();
    }
  }, [time.current]);

  const schema = yup.object().shape({
    certifyNumber: yup.string(),

    // 비밀번호
    password: yup
      .string()
      .min(8, '최소 8자 이상 작성해야 합니다.')
      .max(16, '최대 16자까지 작성 가능합니다.')
      .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d\S]{8,16}$/, '형식에 알맞지 않은 비밀번호입니다.(문자, 숫자 필수)')
      .required('비밀번호를 입력해 주세요!'),

    // 비밀번호 확인
    ispassword: yup
      .string()
      .oneOf([yup.ref('password'), ''], '비밀번호가 일치하지 않습니다.')
      .required('반드시 입력해주세요'),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      certifyNumber: '',
      password: '',
      ispassword: '',
    },
  });

  const { password, ispassword, certifyNumber } = useRecoveryId({ control });

  // 입력값을 받아서 value에 따른 분기처리
  const handleUseFormInputChange = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
    onChange(value);
  };

  // 인증번호 문자 전송요청
  const handleCMSClick = () => {
    certifyNum(location.state.certifyData, {
      onSuccess: (res) => {
        timers();
        setCertifiNum(res.data.authNum);
        setSendCertifyMessage(true);
      },
      onError: (error: any) => {
        console.error(error);
        warningModal('실패', error.response.data, true);
      },
    });
  };

  useEffect(() => {
    setIsCertify(isCertifyCate);
  }, [isCertifyCate]);

  // 문자 재전송
  const handleResendCMS = () => {
    certifyNum(location.state.certifyData, {
      onSuccess: (res) => {
        successModal('성공', ' 인증번호 발송에 성공하였습니다.', true);
        restartTimer();
        setCertifiNum(res.authNum);
        setSendCertifyMessage(true);
      },
      onError: (error) => {
        warningModal('실패', '인증번호 발송에 실패하셨습니다.', true);
        console.error(error);
      },
    });
  };

  // 인증번호 확인 함수
  const handleCheckMessageClick = () => {
    const checkData: checkDataProps = {
      authNum: certifiNum,
      inputNum: certifyNumber.value,
    };
    certifyMessageMutate(checkData, {
      onSuccess: (res) => {
        if (res) {
          successModal('성공', '인증번호를 확인하였습니다.', true);
          setIsCertify(true);
          stopTimer();
        } else {
          warningModal('실패', '인증번호가 잘못되었습니다.', true);
        }
      },
      onError: (error) => {
        console.error(error);
        return error;
      },
    });
  };

  // 비밀번호 변경
  const handlePwChangeClick = (data: any) => {
    const resetData = {
      phnId: location.state.certifyData.phnId, // 회원 ID
      dealGb: isDealGb, // 회원 구분
      rsrvdId: location.state.certifyData.rsrvdId, // 회원 키값
      usrPass: data.password, // 회원이 새로 입력한 비밀번호
    };

    resetPasswordMutate(resetData);

    // 에러가 발생한 경우 ref를 이용하여 해당 input 요소에 focus를 줄 수 있습니다.
  };

  return (
    <SignUpContainer className="signUpContainer signUpRecovery">
      <S.SignUpFormWrapper>
        <RecoveryTitle number={number} isType={isType} isSame={isSame} isCertifyCate={isCertifyCate} />

        <CertifyMessageWrap
          onChange={handleUseFormInputChange}
          handleResndCMS={handleResendCMS}
          handleCheckMessageClick={handleCheckMessageClick}
          certifyNumber={certifyNumber}
          sendCertifyMessage={sendCertifyMessage}
          handleCMSClick={handleCMSClick}
          isCertifyCate={isCertifyCate}
          sec={sec}
          min={min}
          isCertify={isCertify}
        />

        <>
          {isCertify ? (
            <S.RecoveryPwWrap>
              <S.RecoveryPwInner>
                <div className="inputWrap">
                  <h3>비밀번호</h3>
                  <BaseInput
                    type="password"
                    placeholder="비밀번호를 입력하세요."
                    onChange={(e) => handleUseFormInputChange({ value: e.target.value, onChange: password.onChange })}
                    value={password.value}
                    ref={usernameRef}
                  />

                  <S.ValidationMessage>
                    <span className="red">!</span> 최소 8자. 최대 16자 이내로 입력해주세요. (문자, 숫자 필수)
                  </S.ValidationMessage>
                  <S.ValidationError>{errors.password?.message}</S.ValidationError>
                </div>

                <div className="inputWrap">
                  <h3>비밀번호 확인</h3>
                  <BaseInput
                    type="password"
                    placeholder="비밀번호를 확인해주세요."
                    onChange={(e) => handleUseFormInputChange({ value: e.target.value, onChange: ispassword.onChange })}
                    value={ispassword.value}
                  />
                  <S.ValidationError>{errors.ispassword?.message}</S.ValidationError>
                </div>
              </S.RecoveryPwInner>
            </S.RecoveryPwWrap>
          ) : null}

          <S.AgreementButtonWrap>
            <BaseButton disabled={!isCertify} onClick={handleSubmit(handlePwChangeClick)}>
              비밀번호 변경
            </BaseButton>
          </S.AgreementButtonWrap>
        </>
      </S.SignUpFormWrapper>
    </SignUpContainer>
  );
};

export default SignUpRecovery;
