/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

interface BarProps {
  delay: number; // delay prop의 타입 정의
}

const AudioWaveWrap = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;
  white-space: nowrap;
  overflow: hidden;

  .wrap {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

const Bar = styled.div<BarProps>`
  width: 0.2rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-radius: 10rem;
  /* 66% */
  background: ${(props) => props.theme.colors.main}a8;
  animation: movementBar 1.2s ease-in-out infinite;
  animation-delay: ${({ delay }) => `${delay}s`};

  :nth-of-type(4n + 4) {
    animation-delay: ${({ delay }) => `calc(${delay}s + .2s)`};
  }
  :nth-of-type(5n + 6) {
    animation-delay: ${({ delay }) => `calc(${delay}s + .7s)`};
  }

  @keyframes movementBar {
    0%,
    100% {
      height: 0.6rem;
    }
    50% {
      height: 2rem;
    }
  }
`;

const AudioWave = () => {
  const delays = [...Array(30)].map((_, index) => (index % 3) * 0.4);

  return (
    <AudioWaveWrap className="audioWave">
      <div className="wrap">
        {delays.map((e, index) => {
          return <Bar className="bar" key={index} delay={e} />;
        })}
      </div>
    </AudioWaveWrap>
  );
};

export default AudioWave;
