import EventBoard from '@/pages/CustomerPage/EventBoard/EventBoard';
import React from 'react';

const MobileEtcEvent = () => {
  return (
    <>
      <EventBoard />
    </>
  );
};
export default MobileEtcEvent;
