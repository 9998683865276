import { IoCloseOutline, IoCloseSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// 전송결과, 전송예약 공통스타일
export const SendRoomWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  height: 100%;
  border-left: 1px solid ${(props) => props.theme.colors.gray300};

  .topWrap {
    display: flex;
    gap: 1rem;
    padding: 1.5rem;
    font-size: 1.8rem;
    font-weight: 700;
    button {
      color: ${(props) => props.theme.colors.txt500};
    }
  }

  form {
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
  }
  .searchInput {
    min-height: 3.6rem;
    background: transparent;
    border-color: transparent;
    :has(input:focus) {
      background: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.gray500};
    }
    :has(input:not(:placeholder-shown)) {
      background: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.gray500};
    }
    input {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
      ::placeholder {
        color: transparent;
      }
    }
  }
`;

// 메세지 박스
export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 30rem;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};

  .messageGroup {
    display: flex;
    flex-direction: column;
    height: 100%;
    .title {
      font-size: 1.7rem;
      font-weight: 700;
      margin: 0 0 1rem;
      color: ${(props) => props.theme.colors.txt500};
      outline: none;
    }
    .imgWrap {
      max-width: 100%;
      max-height: 10rem;
      margin-bottom: 0.6rem;

      img {
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .messageTxt {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 1.5rem;
      resize: unset;
      line-height: normal;
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.txt500};
      background: ${(props) => props.theme.colors.gray100};
      word-wrap: break-word;
      overflow-y: auto;

      :focus {
        outline: none;
      }
    }
  }
  .stateWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.txt200};
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
    .date {
      color: ${(props) => props.theme.colors.txt500};
      > div {
        border: 0;
        padding: 0;
      }
    }
  }
`;

export const SendContent = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow-x: auto;
    white-space: nowrap;
    li {
      cursor: pointer;
      background: ${(props) => props.theme.colors.gray100};
      /* box-shadow: 0px 0.5rem 1rem 0px rgba(0, 0, 0, 0.05); */
      padding: 1rem;
      border-top: 1px solid ${(props) => props.theme.colors.gray200};
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
      &:last-of-type {
        border-right: 0;
      }
      &.active {
        background: ${(props) => props.theme.colors.main};
        .txt,
        .sendCount {
          color: ${(props) => props.theme.colors.buttonText};
        }
      }
      .txt {
        font-size: 1.4rem;
        min-width: 6rem;
        color: ${(props) => props.theme.colors.txt400};
        margin: 0 0 1rem;
      }
      .sendCount {
        font-size: 1.4rem;
        font-weight: 700;
        text-align: right;
        color: ${(props) => props.theme.colors.txt500};
      }
    }
  }
`;

export const SendResultWrap = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  ol {
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      justify-content: space-between;
      padding: 1.4rem;
      font-size: 1.4rem;
      font-weight: 700;
      color: ${(props) => props.theme.colors.txt300};
      border-top: 1px solid ${(props) => props.theme.colors.gray200};
      :nth-child(2n) {
        background: ${(props) => props.theme.colors.gray100};
      }

      :last-of-type {
        border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
      }
      h4 {
        color: ${(props) => props.theme.colors.txt400};
      }
    }
  }
`;

export const SendEdit = styled.div`
  width: 100%;
  margin: 0 0 1rem;
  padding: 0 1rem;
  button {
    width: 100%;
  }
  /* 버튼 1개 */
  button {
    padding: 1rem 0.2rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt100};
    background: ${(props) => props.theme.colors.grayButton2};
  }
  .editBtn {
    background: ${(props) => props.theme.colors.disable};
    &.active {
      background: ${(props) => props.theme.colors.main};
    }
  }
  .gray {
    background: ${(props) => props.theme.colors.gray600};
  }
  &.twoBtn {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
`;

export const SnedPopupContainer = styled.section`
  position: absolute;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);
  width: 90%;
  height: 12rem;
  background-color: ${(props) => props.theme.colors.grayButton}bf;
  border-radius: 0.8rem;
  color: white;
  padding: 1rem 1.2rem;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SendPopupContents = styled.div`
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.4;
`;

export const StyledLink = styled(Link)`
  display: inline !important;
  font-size: 1.2rem;
  font-weight: 300;
  color: ${(props) => props.theme.colors.gray500};
  text-decoration: underline;
`;

export const CloseBtn = styled(IoCloseSharp)`
  position: absolute;
  top: 0.7rem;
  right: 0.8rem;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.gray400};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.buttonText};
  }
`;

export const Nodata = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray600};
  position: absolute;
`;
