import { useQueryClient } from '@tanstack/react-query';
import { GetPointApiType } from '@/apis/api/pointApi';

export const useCashCalculate = () => {
  const queryClient = useQueryClient();
  const userPoint: GetPointApiType | undefined = queryClient.getQueryData(['/userPoint']);
  //   /* point 담아놓는 STATE */
  //   const [userPgState, setUserPgState] = useState<any>(null);

  //   // ==============================
  //   // 유저 포인트 변경시 STATE에 담아놓는 useEffect
  //   // ==============================
  //   useEffect(() => {
  //     setUserPgState(queryClient.getQueryData(['point']));
  //   }, [queryClient.getQueryData(['point'])]);

  // ==============================
  // stt 변환전 cash에 따른 서비스 이용 분기처리 hook
  // duration : 음성파일초(현재 1초 === 1원), free : 무료이용시간
  // ==============================
  interface ISttCalculate {
    type: string;
    pgResult: any;
  }
  const handleSttCalculate = (duration: number, free: number): ISttCalculate => {
    if (userPoint && userPoint.use_pg >= 0) {
      // 무료시간이 요청시간보다 많을 경우
      if (free >= duration) {
        return { type: 'freeE', pgResult: userPoint };
      }
      // 무료시간이 요청시간보다 적고, 포인트가 요청시간보다 많을 경우
      if (free < duration && userPoint.use_pg + free >= duration) {
        const changeState = { ...userPoint, use_pg: userPoint.use_pg + free - duration };
        return { type: 'cashE', pgResult: changeState };
      }
      // 무료시간이 요청시간보다 적고, 포인트가 요청시간보다 적을 경우
      if (free < duration && userPoint.use_pg + free < duration) {
        return { type: 'charge', pgResult: userPoint };
      }
    }
    return { type: 'error', pgResult: userPoint };
  };

  return { handleSttCalculate };
};
