import React from 'react';

import BaseButton from '../../../components/Atom/BaseButton';
import BaseCheckBox from '../../../components/Atom/BaseCheckBox';
import Agreement from '../../../components/Molecules/SignUp/Agreement';
import SignUpProcess from '../../../components/common/SignUpLayout/SignUpProcess';
import * as S from './SignUp.style';
import Loader from '../../../components/common/Loader';
import { useSignUp } from '@/components/hooks/customs/signUp/useSignUp';
import { AgreementProps } from '@/apis/utils/typeGuard/signUpGuard';
import { theme } from '@/styles/theme';
import MbrUsecntMsg from '@/pages/Terms/signupterms/MbrUsecntMsg';
import ComProtectM from '@/pages/Terms/signupterms/ComProtectM';
import ComProtectPer from '@/pages/Terms/signupterms/ComProtectPer';
import ComProtectInfo3rd from '@/pages/Terms/signupterms/ComProtectInfo3rd';

const SignUp = () => {
  const process: string = '1';

  const { onClickCertify, checkItemHandler, isAble, checkSize, checkItems, signUpLoading, setCheckItems } = useSignUp();

  const signUpData: any = [
    {
      id: 1,
      checkLabel: 'agree1',
      children: '서울이동통신 이용약관',
      contents: <MbrUsecntMsg />,
    },
    { id: 2, checkLabel: 'agree2', children: '개인정보 취급방침', contents: <ComProtectM /> },
    {
      id: 3,
      checkLabel: 'agree3',
      children: '개인정보 수집/이용 동의서',
      contents: <ComProtectPer />,
    },
    {
      id: 4,
      checkLabel: 'agree4',
      children: '개인정보 제3자 제공 및 활용 동의서',
      contents: <ComProtectInfo3rd />,
    },
  ];

  const toggleAllCheckedById = ({ target: { checked } }: any) => {
    if (checked) {
      const allChecked = new Set(signUpData.map(({ id }: any) => id));
      setCheckItems(allChecked);
    } else {
      setCheckItems(new Set());
    }
  };

  return (
    <S.SignUpContainer className="signUpContainer">
      {signUpLoading ? (
        <S.SignUpFormWrapper>
          <SignUpProcess process={process} />
          <div style={{ height: '100vw' }}>
            <Loader backgroundToggle />
          </div>
        </S.SignUpFormWrapper>
      ) : (
        <S.SignUpFormWrapper>
          <SignUpProcess process={process} />
          <S.TermsHeading>
            <p>
              서울이동통신은 원활한 서비스 이용과 익명사용자로 인한 명예 훼손 등의 피해를 방지하기 위해 안심인증과
              아이핀(i-PIN) 인증을 통한 가입을 시행하고 있습니다. 입력하신 소중한 개인정보는 회원님의 동의없이
              공개되거나 제3자에게 제공되지 않으며, 개인정보 취급방침에 따라 보호하고 있습니다.
            </p>
            <p className="gray">
              14세 이상만 회원가입이 가능합니다. 타인의 개인정보를 부정 사용하는 자는 3년 이하의 징역 또는 1천만원
              이하의 벌금이 부과될 수 있습니다.
            </p>
          </S.TermsHeading>

          <S.AgreementWrap>
            <BaseCheckBox
              className="roundCheck"
              label="전체 동의"
              onChange={toggleAllCheckedById}
              checked={checkSize === 4}
            />
            {signUpData.slice(0, 4).map((element: AgreementProps) => {
              return (
                <Agreement
                  key={element.id} // 각 요소에 고유한 키를 제공해야 합니다.
                  checkItemHandler={() => checkItemHandler(element.id)}
                  element={element}
                  checked={checkItems}
                />
              );
            })}
          </S.AgreementWrap>
          <S.AgreementButtonWrap>
            <BaseButton onClick={onClickCertify} disabled={isAble}>
              본인 인증
            </BaseButton>
            {/* ! 주석 복구 */}
            {/* <BaseButton
              width="138px"
              height="39px"
              disabled={isAble}
              backgroundColor="#000"
              fontWeight="bold"
              color="#fff"
              marginLeft="55px"
            >
              아이핀(i-PIN)
            </BaseButton> */}
          </S.AgreementButtonWrap>
        </S.SignUpFormWrapper>
      )}
    </S.SignUpContainer>
  );
};

export default SignUp;
