import React from 'react';
import * as S from './styles/My015SubInfo.styles';
import * as TS from '@/styles/table.styles';
import ATitle from '@/components/Atom/ATitle';
import Say015NotInfo from '@/components/Organism/MyPage/Say015NotInfo';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import { formatDateBase } from '@/shared/util/format/dateFormatUtil';
import BaseGuide from '@/components/Atom/BaseGuide';
import { useSay015SubInfo } from '@/components/hooks/customs/myPage/useSay015SubInfo';

/**
 * @title 015 이용요금 결제 상세 정보 페이지
 *
 * @author 정휘학
 * @since 2024.05.17
 * */

const My015SubInfo = () => {
  const subHooks = useSay015SubInfo();
  return (
    <>
      <S.AWrapper className="basicTable">
        <ATitle type="sub" text="015 발급 정보" />
        {subHooks.pageState === '미가입' ? (
          <Say015NotInfo subHooks={subHooks} />
        ) : (
          <TS.MyPageTable className="tw30">
            <tbody>
              {/* 유저 이름 */}
              <tr>
                <th>이름</th>

                <td>{subHooks.user}</td>
              </tr>
              {/* 015 발급 번호 */}
              <tr>
                <th>015 번호</th>

                <td>{formatPhoneNumber(subHooks.authorityData?.number015)}</td>
              </tr>
              {/* 이용기간 */}
              <tr>
                {subHooks.pageState === '구독만료' ? (
                  <>
                    <th>구독 만료일</th>
                    <td>{formatDateBase(subHooks.authorityData?.subsEndDate)}</td>
                  </>
                ) : (
                  <>
                    <th>이용기간</th>
                    <td>
                      {formatDateBase(subHooks.authorityData?.subsStartDate)} ~{' '}
                      {formatDateBase(subHooks.authorityData?.subsEndDate)}
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </TS.MyPageTable>
        )}
      </S.AWrapper>
      {/* <BaseGuide
        text="이용기간 도중 구독해지를 신청할 경우,
                <br />당월 결제기간 동안 서비스 이용이 가능하며,
                <br />서비스 이용 중단 6개월이 지나면, 발급된 015 번호와 내역은 모두 삭제됩니다."
      /> */}
      <BaseGuide
        text="이용기간 동안 서비스 이용이 가능하며,
              <br />서비스 이용 만료일로부터 6개월이 지나면, 발급된 015 번호와 내역은 모두 삭제됩니다."
      />
    </>
  );
};
export default My015SubInfo;
