import styled from 'styled-components';

export const TopTextContainer = styled.div`
  border-top: 2px solid ${(props) => props.theme.colors.gray500};
  border-bottom: 2px solid ${(props) => props.theme.colors.gray500};
  margin: 2rem 0 0;
  padding: 0 0 3rem;
  font-size: 1.3rem;

  & > div,
  & > p {
    line-height: 2em;
  }
  @media screen and (max-width: 768px) {
    & > div,
    & > p {
      font-size: 1.4rem;
    }
  }
`;

export const TextBlue = styled.p`
  color: ${(props) => props.theme.colors.main};
  margin-top: 2rem;

  & > span {
    font-weight: bold;
  }
`;
