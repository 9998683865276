import React from 'react';
import * as S from './MobileEtcUserInfo.style';
import { Link } from 'react-router-dom';

const MobileEtcUserInfo = () => {
  // 더보기 - 회원정보 - '이동할 페이지'
  const userInfoItem = [
    { to: '/mobile/etc/etcUserInfo/infoChange', label: '회원정보 변경' },
    { to: '/mobile/etc/etcUserInfo/numberChange', label: '휴대폰번호 변경' },
    { to: '/mobile/etc/etcUserInfo/subInfo', label: '발급정보' },
    { to: '/mobile/etc/etcUserInfo/userChange', label: '명의 변경' },
    { to: '/mobile/etc/etcUserInfo/memberLeave', label: '회원탈퇴' },
  ];

  return (
    <S.MobileEtcUserInfoContainer className="mobileEtcUserInfo">
      {/* <h4>회원정보</h4> */}
      {userInfoItem.map((item) => (
        <Link className="tabMenu" key={item.label} to={item.to}>
          <p>{item.label}</p>
        </Link>
      ))}
    </S.MobileEtcUserInfoContainer>
  );
};
export default MobileEtcUserInfo;
