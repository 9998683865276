/* eslint-disable */
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'dayjs/locale/ko';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

interface IDatePickerDateTime {
  onChange: any;
  sndDttm: string;
}

/**
 *
 * @title antd datePicker 컴포넌트
 *
 * */
const DatePickerDateTime = ({ onChange, sndDttm }: IDatePickerDateTime) => {
  const disabledDate = (current: Dayjs) => {
    // 오늘의 날짜를 가져옵니다.
    const today = dayjs();
    // 오늘로부터 1년 후의 날짜를 가져옵니다.
    const oneYearFromToday = dayjs().add(1, 'year');
    // 선택할 수 있는 날짜를 오늘부터 1년 후까지로 제한합니다.
    return current && (current < today || current > oneYearFromToday);
  };

  return (
    <DatePicker
      onChange={onChange}
      showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      locale={locale}
      value={dayjs(sndDttm)}
      disabledDate={disabledDate}
      suffixIcon={null} // 달력 아이콘 제거
      clearIcon // hover 시 표출되는 x 아이콘 제거
    />
  );
};
export default DatePickerDateTime;
