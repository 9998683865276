import { DefaultRowData } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { BuddyData } from '@/types/index.types';
import { useState } from 'react';
import { useMutationAdd } from '../../mutations/addressLargeTable/useMutationAdd';
import { mainTableCacheUpdate } from '@/components/cache/mainTableCacheUpdate';
import { validateLargeAddressRow } from '@/shared/util/AddressUtils';
import { fomatOnlyNum } from '@/shared/util/format/phoneNumberFormatUtil';

interface IUseAddLargeSubmit {
  handleLargeTableReset: () => void;
  inputRefs: any;
}

// 정규식 분리
export const useAddLargeSubmit = (props: IUseAddLargeSubmit) => {
  /* 저장버튼 클릭시 api 중복 요청 방지 STATE */
  const [isPostting, setIsPostting] = useState(false);

  const { handleLargeAddCacheUpdate } = groupListCacheUpdate();
  const { mainTableLargeAddToCacheUpdate } = mainTableCacheUpdate();
  const { successModal, warningModal } = useModalHook();

  const mutateHooks = useMutationAdd();

  /** 아이템(리스트) 추가 로직 */
  // 대량추가 유효성검사 로직
  const validateInput = (value: string, regex: any, errorMessage: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue.length >= 1 && !regex.test(trimmedValue)) {
      warningModal('대량 추가', errorMessage, true);
      return false;
    }
    return true;
  };

  // 주소록 저장 버튼 클릭
  const handleSaveChanges = (data: DefaultRowData[]) => {
    if (isPostting) return; // 이미 제출 중이면 중복 클릭 방지
    const filterData = data
      .map((m: any) => {
        if (m.buddyNm?.trim() || m.keyCommNo?.trim()) {
          return m;
        }
        return null;
      })
      .filter((f: any) => f !== null);

    if (!(filterData.length > 0)) {
      warningModal('연락처 등록', '입력된 정보가 없습니다.', true);
      return;
    }
    const validateBoolean = filterData.some((item, index) => {
      const { groupSeqNo } = item;
      if (!groupSeqNo) {
        warningModal('대량 추가', '그룹선택을 해주세요.', true);
        return true;
      }
      const validateReturn = validateLargeAddressRow(item);
      if (validateReturn) {
        warningModal('대량 추가', `${index + 2}번째 열에서 ${validateReturn}`, true);
        // 잘못된 데이터가 입력된 input에 포커스 설정

        if (props.inputRefs.current[index]) {
          const invalidInputRef: any = Object.values(props.inputRefs.current[index])[0]; // 첫 번째 잘못된 input 요소를 선택
          if (invalidInputRef) {
            invalidInputRef.focus();
          }
        }
        return true;
      }
      return false;
    });
    if (validateBoolean) return;

    // keyCommNo를 변환
    const transformedData = filterData.map((item) => ({
      ...item,
      keyCommNo: fomatOnlyNum(item.keyCommNo),
    }));

    setIsPostting(true);

    mutateHooks.postLargeListM(transformedData, {
      onSuccess: (data: BuddyData[], variables: DefaultRowData[]) => {
        onSuccessSubmit(data, variables);
      },
      onError: (error: any) => {
        setIsPostting(false);
        warningModal('연락처 등록', `에러 발생: ${error.message}`, true);
      },
    });
  };

  // 대량추가 성공시 실행할 hook
  const onSuccessSubmit = (data: BuddyData[], variables: DefaultRowData[]) => {
    setIsPostting(false);
    mainTableLargeAddToCacheUpdate(data, variables);
    handleLargeAddCacheUpdate(data);
    props.handleLargeTableReset();
    successModal('연락처 등록', '새로운 연락처를 등록하였습니다.', true);
  };

  return { handleSaveChanges };
};
