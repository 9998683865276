import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';
import { ChangeEvent, useState } from 'react';

interface IUseArsRecording {
  data: NextItem;
  arsData: NextItem;
  updatedJsonFile?(): void; // ARS Json 업데이트
}

/**
 * @title ARS 음성받기 카드
 *
 * */
export const useArsRecording = ({ data, arsData, updatedJsonFile }: IUseArsRecording) => {
  /* 음성녹음 받기 TITLE */
  const [recTitleState, setRecTitleState] = useState<string>(data?.s015Data.title);

  /* 음성받기 카드 Title STATE 핸들러 Hook */
  const handleRecTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setRecTitleState(e.target.value);
  };

  /* 015 ARS 음성녹음 받기 Hook */
  const updatedJSON = (data: any, id: string, title: string) => {
    if (data.id === id && data.s015Data) {
      data.s015Data.action = '음성녹음 받기';
      data.s015Data.type = 'VMS';
      data.target.type = 'VMS';
      data.target.level = title;
      data.source.userinput = data?.id.slice(-1);
      return true;
    }
    if (data.next && data.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        if (updatedJSON(data.next[i], id, title)) {
          return true;
        }
      }
    }
    return false;
  };
  /* 착신번호 수정 핸들러 Hook */
  const handleSaveRecording = () => {
    const updatedData = { ...arsData };
    if (updatedJSON(updatedData, data?.id, recTitleState) && updatedJsonFile) {
      updatedJsonFile();
    }
  };

  return {
    handleSaveRecording,
    handleRecTitle,
    recTitleState,
  };
};
