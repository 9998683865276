import styled from 'styled-components';

export const ResultContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  overflow-y: auto;
`;

export const ResultHeader = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  background-color: ${(props) => props.theme.colors.white};

  top: 0;
  left: 0;
  right: 0;

  padding: 1.2rem;
`;

export const ResultHeaderLeft = styled.div`
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;

  display: flex;
  gap: 1rem;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 1.4rem;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 1rem 1.4rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  background-color: ${(props) => props.theme.colors.gray100};
  ::placeholder {
    color: ${(props) => props.theme.colors.disable};
  }
`;

export const ResultMain = styled.section`
  width: 100%;
  height: 100%;
  /* background-color: ${(props) => props.theme.colors.gray100}; */
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.4rem;
  gap: 1rem;

  transition: background-color 0.4s ease-in-out; // 듀레이션 0.4초 추가
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  cursor: pointer;

  > div {
    display: flex;
    gap: 1rem;
  }
`;

export const BuddyImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  background-color: ${(props) => props.theme.colors.chatIconBack}; /* 초록색 배경 추가 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  font-size: 1.6rem; /* 아이콘 크기 조금 더 키움 */
  font-weight: 900;
  overflow: hidden;
  color: ${(props) => props.theme.colors.txt100}; /* 체크 시 흰색으로 */
  transition:
    background-color 0.3s ease,
    transform 0.3s ease; /* 부드러운 전환 효과 */
  overflow: hidden;
  svg {
    font-size: 3.4rem;
    margin: 1.2rem 0 0;
  }
`;

export const BuddyInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex: 1;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  font-weight: 400;
  h3 {
    display: inline-block;
    max-width: 16rem; /* 최대 너비를 40px로 설정 */
    white-space: nowrap;
    color: ${(props) => props.theme.colors.txt500};
    text-overflow: ellipsis; /* 말줄임표 효과 */
    font-weight: 700;
    overflow: hidden; /* 넘치는 텍스트 숨김 */
    @media screen and (max-width: 380px) {
      max-width: 10rem;
    }
  }
  > div {
    color: ${(props) => props.theme.colors.txt300};
  }

  @media screen and (max-width: 380px) {
    font-size: 1.2rem;
  }
`;
