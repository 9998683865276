import Header from '@/components/common/Header';
import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

/* 헤더가 필요한 페이지는 라우터에서 해당 컴포넌트 사용 */
const LayoutWithHeader: React.FC = () => (
  <>
    <Header />
    <Outlet />
  </>
);

export default LayoutWithHeader;
