/* eslint-disable */
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

interface IUseChatRoom015File {
  list: any;
}

export const useChatRoom015File = (props: IUseChatRoom015File) => {
  const environmentS = useRecoilValue(environmentRecoil);

  const { confirmModal, warningModal } = useModalHook();

  // 파일 미리보기 URL
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  // 파일 타입 담는 STATE
  const [fileCategory, setFileCategory] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');

  // Base64 문자열을 Blob으로 변환하는 함수
  const base64ToBlob = (base64: string, type: string): Blob => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  };

  // 파일 다운로드 함수
  const handleDownloadFile = () => {
    try {
      if (environmentS === 'app') {
        const base64Data = props.list.fileData.fileBytes;
        const fileName = props.list.fileData.fileName;
        const message = JSON.stringify({ base64Data, fileName });
        window.fromWebToAppReqDownload(message);
      } else {
        // 바이너리 데이터를 Blob으로 생성
        // 만약 base64 문자열로 인코딩되어 온다면 base64ToBlob 함수를 사용해서 디코딩 후 사용하면 됨
        // 위 상황이라면 이 코드 사용하면 됨 const blob = base64ToBlob(base64Data, 'application/octet-stream');
        // const blob = new Blob([props.list.fileData.fileBytes], { type: 'application/octet-stream' });
        const blob = base64ToBlob(props.list.fileData.fileBytes, 'application/octet-stream');
        const url = window.URL.createObjectURL(blob); // Blob URL 생성

        const a = document.createElement('a'); // 다운로드 링크 생성
        a.href = url;
        a.download = props.list.fileData.fileName; // 파일 이름 지정
        document.body.appendChild(a);
        a.click(); // 링크 클릭하여 다운로드
        document.body.removeChild(a); // 링크 제거
        window.URL.revokeObjectURL(url); // Blob URL 해제
      }
    } catch (error) {
      warningModal('파일 다운로드', '파일 다운로드에 실패했습니다.', true);
    }
  };

  // 파일 타입 정의 및 미리보기 URL 설정
  const defineFileType = () => {
    const extension = props.list.fileData.extension; // 파일 확장자 가져오기
    const fileName = props.list.fileData.fileName;

    // 파일 카테고리와 타입 설정
    const categoryMap: { [key: string]: { category: string; type: string } } = {
      jpeg: { category: '이미지', type: `${fileName}` },
      jpg: { category: '이미지', type: `${fileName}` },
      png: { category: '이미지', type: `${fileName}` },
      gif: { category: '이미지', type: `${fileName}` },
      svg: { category: '이미지', type: `${fileName}` },
      webp: { category: '이미지', type: `${fileName}` },
      mp3: { category: '오디오', type: `${fileName}` },
      wav: { category: '오디오', type: `${fileName}` },
      m4a: { category: '오디오', type: `${fileName}` },
      ogg: { category: '오디오', type: `${fileName}` },
      mp4: { category: '비디오', type: `${fileName}` },
      mov: { category: '비디오', type: `${fileName}` },
      avi: { category: '비디오', type: `${fileName}` },
      mkv: { category: '비디오', type: `${fileName}` },
      pdf: { category: '문서', type: `${fileName}` },
      doc: { category: '문서', type: `${fileName}` },
      docx: { category: '문서', type: `${fileName}` },
      odt: { category: '문서', type: `${fileName}` },
      rtf: { category: '문서', type: `${fileName}` },
      xls: { category: '스프레드시트', type: `${fileName}` },
      xlsx: { category: '스프레드시트', type: `${fileName}` },
      ppt: { category: '프레젠테이션', type: `${fileName}` },
      pptx: { category: '프레젠테이션', type: `${fileName}` },
      ods: { category: '스프레드시트', type: `${fileName}` },
      odp: { category: '프레젠테이션', type: `${fileName}` },
      hwp: { category: '한글', type: `${fileName}` },
      zip: { category: '압축', type: `${fileName}` },
      rar: { category: '압축', type: `${fileName}` },
      '7z': { category: '압축', type: `${fileName}` },
      gz: { category: '압축', type: `${fileName}` },
      tar: { category: '압축', type: `${fileName}` },
      txt: { category: '텍스트', type: `${fileName}` },
      json: { category: '데이터', type: `${fileName}` },
      xml: { category: '데이터', type: `${fileName}` },
      csv: { category: '데이터', type: `${fileName}` },
      yaml: { category: '데이터', type: `${fileName}` },
      yml: { category: '데이터', type: `${fileName}` },
      md: { category: '문서', type: `${fileName}` },
    };

    const fileInfo = categoryMap[extension] || { category: '알 수 없는', type: '알 수 없는 파일 형식' };
    setFileCategory(fileInfo.category);
    setFileType(fileInfo.type);

    // 미리보기 URL 설정
    if (['jpeg', 'jpg', 'png', 'gif', 'svg', 'webp', 'mp4', 'mov', 'avi', 'mp3', 'wav'].includes(extension)) {
      // const blob = new Blob([props.list.fileData.fileBytes]); // 바이너리 데이터를 Blob으로 생성
      const blob = base64ToBlob(props.list.fileData.fileBytes, 'application/octet-stream'); // 바이너리 데이터를 Blob으로 생성
      const previewUrl = URL.createObjectURL(blob); // 미리보기 URL 생성
      setPreviewUrl(previewUrl); // 파일 미리보기 URL 설정
    } else {
      setPreviewUrl(null); // 미리보기 불가능한 파일
    }
  };

  // 컴포넌트가 마운트되었을 때 파일 타입 정의
  useEffect(() => {
    defineFileType();
  }, []);

  // 다운로드 버튼 클릭이벤트
  const onClickFileDownload = () => {
    confirmModal(
      async () => {
        handleDownloadFile();
      },
      '파일 다운로드',
      '파일을 다운로드하시겠습니까?',
      true,
    );
  };
  return { onClickFileDownload, fileType, previewUrl, fileCategory };
};
