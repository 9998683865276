import { useRecoilState } from 'recoil';
import { useLayoutEffect } from 'react';
import { useMsgRecoilReset } from '@/components/hooks/customs/useMsgRecoilReset';
import { softPhoneTopTabRecoil, softPhoneSaveMessageRecoil } from '@/recoil/atoms/router/mainRouterRecoil';

/**
 * @title ComponentVariantRenderer.tsx Hooks
 *
 * @author 정휘학
 * @since 2024.03.14
 * */
export const useAddressBook = () => {
  const [softPhoneTopTabState] = useRecoilState(softPhoneTopTabRecoil);
  const [, setSaveMessage] = useRecoilState(softPhoneSaveMessageRecoil);
  const { resetMsgInfo, reSendMsgToggleState, resetReMsgInfo } = useMsgRecoilReset();

  // 소프트폰 탭 변경시 메세지 전송에 관련된 recoil 초기화
  useLayoutEffect(() => {
    resetMsgInfo();
    setSaveMessage(false); // 메세지 저장함 탭 클릭시 닫기
  }, [softPhoneTopTabState]);

  // 010 문자발송 재전송 toggle 변경시 재전송에 관련된 recoil 초기화
  useLayoutEffect(() => {
    resetReMsgInfo();
  }, [reSendMsgToggleState]);
};
