import React from 'react';
import styled from 'styled-components';

const MessageAddNotifyWrap = styled.div`
  z-index: 1;
  position: absolute;
  top: 14rem;
  left: 50%;
  width: 100%;
  max-width: 32rem;
  transform: translateX(-50%);
  background: ${(props) => props.theme.colors.main};
  color: ${(props) => props.theme.colors.txt100};
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  padding: 1.4rem 1rem;
  border-radius: 1rem;
  animation: 0.5s messagePop ease-in-out;
  opacity: 1;
  @keyframes messagePop {
    from {
      opacity: 0;
      top: 12rem;
      transform: translateX(-50%);
    }
  }
  @media screen and (max-width: 640px) {
    font-size: 1.4rem;
  }
`;

interface IMessageAddNotify {
  notiType?: 'add' | 'schedule' | null;
}

const MessageAddNotify = (props: IMessageAddNotify) => {
  return (
    <MessageAddNotifyWrap>
      {props.notiType === 'add' && <p>중복된 번호는 자동으로 제외되어 발송됩니다.</p>}
      {props.notiType === 'schedule' && <p>예약내역 수정 및 취소는 예약발송 시각 10분전까지만 가능합니다.</p>}
    </MessageAddNotifyWrap>
  );
};
export default MessageAddNotify;
