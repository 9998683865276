import styled from 'styled-components';

// 각 컨테이너 높이
export const MessageSendHistoryContainer = styled.div`
  height: calc(var(--vh, 10vh) - 22.4rem);
  border-radius: 0 0 2rem 2rem;
  overflow: hidden;
  border-top: 0;

  /* 스크롤바 숨김 */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  // overflow 스크롤 styles
  ::-webkit-scrollbar {
    width: 0.4rem;
    background-color: rgba(140, 140, 140, 0.4);
    border-radius: 40px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.main};
    border-radius: 40px;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    height: calc(var(--vh, 10vh) - 11rem - 6rem);
    border-radius: 0;
    border: 0;
  }
`;
