import React from 'react';
import * as S from './ArsCardModal.styles';
import ArsCardSpeak2 from '@/components/say015/Modal/arsCards/ArsCardSpeak2';
import ArsCardSpeak3 from '@/components/say015/Modal/arsCards/ArsCardSpeak3';
import ArsCardSpeak from '@/components/say015/Modal/arsCards/ArsCardSpeak';
import ArsCardMsg from '@/components/say015/Modal/arsCards/ArsCardMsg';
import ArsCardCall from '@/components/say015/Modal/arsCards/ArsCardCall';
import ArsCardRecord from '@/components/say015/Modal/arsCards/ArsCardRecord';
import { useArsCardModal } from '@/widgets/ars/components/arsTree/components/arsCardModal/hooks/useArsCardModal';
import { IArsCardModalComponent } from '@/widgets/ars/components/arsTree/components/arsCardModal/ui/ArsCardModal.types';

const ArsCardModalComponent = (props: IArsCardModalComponent) => {
  const { handleCancel } = useArsCardModal({
    type: props.type,
    handleUpdateIdState: props.handleUpdateIdState,
    handleAddIdState: props.handleAddIdState,
  });

  return (
    <S.ArsCardWrap className="arsCardWrap">
      {props.content && props.content === '인사말' && <ArsCardSpeak {...props} handleCancel={handleCancel} />}
      {props.content && props.content === '문자발송' && <ArsCardMsg {...props} handleCancel={handleCancel} />}
      {props.content && props.content === '착신전환' && <ArsCardCall {...props} handleCancel={handleCancel} />}
      {/* TTS 카드 추가시 사용되는 컴포넌트 */}
      {props.content && props.content === '텍스트재생' && <ArsCardSpeak2 {...props} handleCancel={handleCancel} />}
      {/* TTS 카드 표출시 사용되는 컴포넌트 */}
      {props.content && props.content === '안내멘트' && props.data.s015Data.ttsText !== '' && (
        <ArsCardSpeak2 {...props} handleCancel={handleCancel} />
      )}
      {/* 녹음 ARS 카드 추가 컴포넌트 표출 */}
      {props.content && props.content === '녹음재생' && <ArsCardSpeak3 {...props} handleCancel={handleCancel} />}
      {/* 녹음 ARS 카드 view 컴포넌트 표출 */}
      {props.content && props.content === '안내멘트' && props.data.s015Data.ttsText === '' && (
        <ArsCardSpeak3 {...props} handleCancel={handleCancel} />
      )}
      {props.content && props.content === '음성녹음 받기' && <ArsCardRecord {...props} handleCancel={handleCancel} />}
    </S.ArsCardWrap>
  );
};

export default ArsCardModalComponent;
