import { isDarkModeRecoil, TThemeMode } from '@/recoil/atoms/router/mainRouterRecoil';
import IconMoon from '@/styles/icons/IconMoon';
import IconSun from '@/styles/icons/IconSun';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

const ThemeChange = styled.div``;
interface ThemeButtonBoxProps {
  type: 'mobile' | 'pc';
}

const ThemeButtonBox: React.FC<ThemeButtonBoxProps> = ({ type }) => {
  const [isDarkModeS, setIsDarkModeS] = useRecoilState(isDarkModeRecoil);

  const onClickThemeChange = (theme: TThemeMode) => {
    setIsDarkModeS(theme);
    localStorage.setItem('theme', theme);
  };

  return (
    <ThemeChange>
      {isDarkModeS === 'darkTheme' && (
        <button type="button" onClick={() => onClickThemeChange('lightTheme')}>
          {/* 기본테마 */}
          <IconSun />
          {type === 'pc' && <p>기본테마</p>}
        </button>
      )}
      {isDarkModeS === 'lightTheme' && (
        <button type="button" onClick={() => onClickThemeChange('darkTheme')}>
          {/* 다크모드 */}
          <IconMoon />
          {type === 'pc' && <p>다크모드</p>}
        </button>
      )}
    </ThemeChange>
  );
};
export default ThemeButtonBox;
