import React, { useState } from 'react';

import ATitle from '@/components/Atom/ATitle';
import * as S from './style/TaxInvoiceAuto.styles';

import BaseButton from '../../Atom/BaseButton';
import { usePublishTaxBill } from '../../hooks/customs/charge/TaxInvoiceAuto/usePublishTaxBill';
import MbrCorpInsert from '../../Molecules/Charge/TaxInvoiceAuto/MbrCorpInsert';
import PublishTaxBill from '../../Molecules/Charge/TaxInvoiceAuto/PublishTaxBill';
import TaxInvoiceBottom from '../../Molecules/Charge/TaxInvoiceAuto/TaxInvoiceBottom';
import TaxInvoiceTop from '../../Molecules/Charge/TaxInvoiceAuto/TaxInvoiceTop';

const TaxInvoiceAuto = () => {
  const [isInsertVisible, setIsInsertVisible] = useState(false);

  const { MbrCorpData, handleInsertButtonClick } = usePublishTaxBill(isInsertVisible, setIsInsertVisible);

  // useEffect(() => {
  //   getMbrCorpList();
  // })
  return (
    <>
      <ATitle type="sub" text="세금계산서 자동 발행" />
      {/* 설명 */}
      <TaxInvoiceTop />
      {isInsertVisible && <MbrCorpInsert isInsertVisible={isInsertVisible} setIsInsertVisible={setIsInsertVisible} />}
      {/* isInsertVisible이 true이면 MbrCorpInsert 표시합니다. */}
      {/* 사업자 등록 */}
      {/* {MbrCorpData?.msgId === null && isInsertVisible === false ? ( */}
      {MbrCorpData === undefined && isInsertVisible === false ? (
        <S.TextAreaContainer>
          <S.TextArea>
            등록된 사업자 정보가 없습니다.
            <br />
            사업자 정보를 등록해주세요.
          </S.TextArea>
          <BaseButton
            type="button"
            width="140px"
            height="30px"
            fontSize="1.4rem"
            fontWeight="bold"
            onClick={handleInsertButtonClick} // 이 클릭 핸들러를 추가합니다.
          >
            사업자 정보 등록하기
          </BaseButton>
        </S.TextAreaContainer>
      ) : (
        // 사업자 , 결제 리스트
        // MbrCorpData?.msgId !== null &&
        <PublishTaxBill isInsertVisible={isInsertVisible} setIsInsertVisible={setIsInsertVisible} />
      )}
      <TaxInvoiceBottom />
    </>
  );
};

export default TaxInvoiceAuto;
