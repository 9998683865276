import styled from 'styled-components';

export const SignUpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${(props) => props.theme.colors.gray100};
  margin: -2rem 0 0;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 4rem;
  }
`;

export const SignUpFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 72rem;
  padding: 9rem 4rem;
  margin: 10rem 0;
  flex-shrink: 0;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.background};
  border-radius: 1rem;

  /* 서울이동통신 회원가입 */
  &::before {
    content: '계정조회';
    display: block;
    color: ${(props) => props.theme.colors.txt500};
    text-align: center;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 5rem;
  }
  @media screen and (max-width: 520px) {
    margin: 0;
    padding: 6rem 1.6rem;
  }
`;

export const Say015ApplyTitle = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.1vw;
`;

export const SignUpLine = styled.div`
  width: 100%;
  height: 0.1rem;
  background: ${(props) => props.theme.colors.gray500};
  &.top {
    margin: 5rem 0 0;
  }
  &.bottom {
    margin: 0 0 5rem;
  }
`;
export const DividingLine = styled.div`
  margin: 2rem auto;
  width: 100%;
  height: 0.1rem;
  background: ${(props) => props.theme.colors.gray200};
`;

export const SignUpTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
`;
export const SignUpSubTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  min-height: 4.2rem;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.txt500};
  background: ${(props) => props.theme.colors.gray100};
  &.dormant {
    color: ${(props) => props.theme.colors.txt300};
  }
`;

export const SignUpSub = styled.p`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt300};
`;

export const AgreementButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5rem auto;
  box-sizing: border-box;
  button {
    padding: 1.6rem 4.2rem;
    font-size: 1.8rem;
    font-weight: 700;
    border-radius: 0.8rem;
  }
`;

export const Say015ApplyServiceGuide = styled.div`
  width: 100%;
  margin: 5rem 0 0;
  font-size: 1.2rem;
  line-height: 1.8em;
  color: ${(props) => props.theme.colors.txt200};
`;

export const SignUpSubTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
`;

export const IdCheckBigWrap = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  margin: 1rem 0 0.5rem;
  button {
    padding: 1rem 1.2rem;
    font-size: 1.4rem;
    font-weight: 500;
    white-space: nowrap;
  }
  @media screen and (max-width: 420px) {
    flex-wrap: wrap;
  }
`;

export const IdCheckSmallWrap = styled.div`
  width: 100%;
`;
