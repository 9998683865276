import {
  MutationFunction,
  MutationKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { instance, instanceFile, client } from '@/shared/lib/clientAxios';
import qs from 'qs';

interface IFetchMutation<PT, RT> extends UseMutationOptions<RT, unknown, PT, unknown> {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT';
  mutationKey: MutationKey;
  file?: false | true;
  noToken?: boolean;
}

/**
 * @title useMutation 공통 함수
 *
 * @author 정휘학
 * @since 2024.06.05
 * */

export const fetchMutation = <PT, RT>({
  method,
  mutationKey,
  file,
  noToken,
  ...props
}: IFetchMutation<PT, RT>): UseMutationResult<RT, unknown, PT, unknown> => {
  /**
   * ==========================
   * @title Mutation GET Method
   * ==========================
   **/
  const fetchMutationGet: MutationFunction<RT, PT> = async (params: PT): Promise<RT> => {
    const axiosInstance = noToken ? client : instance;

    const response = await axiosInstance.get(`${mutationKey[0]}`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data;
  };
  /**
   * ==========================
   * @title Mutation POST Method
   * ==========================
   **/
  const fetchMutationPost: MutationFunction<RT, PT> = async (body: PT): Promise<RT> => {
    const axiosInstance = noToken ? client : instance;

    const response = await axiosInstance.post(`${mutationKey[0]}`, body);
    return response.data;
  };
  /**
   * ==================================
   * @title Mutation POST Method - FILE
   * ==================================
   **/
  const fetchMutationFilePost: MutationFunction<RT, PT> = async (body: PT): Promise<RT> => {
    const response = await instanceFile.post(`${mutationKey[0]}`, body);
    return response.data;
  };

  /**
   * =============================
   * @title Mutation DELETE Method
   * =============================
   **/
  const fetchMutationDelete: MutationFunction<RT, PT> = async (params: PT): Promise<RT> => {
    const axiosInstance = noToken ? client : instance;

    const response = await axiosInstance.delete(`${mutationKey[0]}`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response.data;
  };

  /**
   * =============================
   * @title Mutation PUT Method
   * =============================
   **/
  const fetchMutationPut: MutationFunction<RT, PT> = async (params: PT): Promise<RT> => {
    const axiosInstance = noToken ? client : instance;

    const response = await axiosInstance.put(`${mutationKey[0]}`, params);
    return response.data;
  };

  // ===================================================================================================================
  // Method 타입에 따른 함수 분기 로직
  // ===================================================================================================================
  let methodFunc: MutationFunction<RT, PT>;

  if (method === 'GET') {
    methodFunc = fetchMutationGet;
  } else if (method === 'POST') {
    methodFunc = file ? fetchMutationFilePost : fetchMutationPost;
  } else if (method === 'DELETE') {
    methodFunc = fetchMutationDelete;
  } else if (method === 'PUT') {
    methodFunc = fetchMutationPut;
  } else {
    throw new Error('Invalid method');
  }

  const mutationResult = useMutation<RT, unknown, PT, unknown>(methodFunc, { ...props });

  return {
    ...mutationResult,
  };
};
