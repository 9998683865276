import { use015ARSMock } from '@/components/hooks/customs/say015/say015ARS/use015ARSMock';
import { softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { arsSoftPhoneRecoil, say015PageState, userMockupViewRecoil } from '@/recoil/atoms/say015Atom';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ArsMockMsg from './ArsMockMsg';
import * as S from './ArsMockupPhone.styles';
import POBox015 from '../POBox015/POBox015';

const ArsMockupPhone = () => {
  const arsMockHooks = use015ARSMock();
  const [arsSoftPhoneS, setArsSoftPhoneS] = useRecoilState(arsSoftPhoneRecoil);
  const softPhoneTopTabS = useRecoilValue(softPhoneTopTabRecoil);
  const [pageState, setPageState] = useRecoilState(say015PageState);
  const [userMockupViewS, setUserMockupViewS] = useRecoilState(userMockupViewRecoil);

  useEffect(() => {
    if (pageState === '구독만료' || pageState === '미가입') {
      setArsSoftPhoneS('mock_s1');
    } else setArsSoftPhoneS('real_s1');
  }, [softPhoneTopTabS]);

  return (
    <>
      {/* 서비스 시작하기 버튼으로 소프트폰 화면 토글 */}
      {arsMockHooks.join015ToggleS ? (
        // 가입 화면
        <POBox015 />
      ) : (
        //  목업 화면
        <S.Wrap className="ArsMockupPhone">
          <div className="txtGroup">
            <h3>
              <span>{arsSoftPhoneS === 'mock_s1' ? '요식업 사장님 ' : null}</span>음성사서함 보기
            </h3>
            <p>
              설정된 인사말 부터 시나리오가 재생되며, 위젯 클릭 시 해당 위젯부터 재생됩니다. 실제 통화화면이 아닌,
              이해를 돕기 위한 가상의 전화 화면입니다.
            </p>
          </div>
          <S.ArsPhone>
            {arsMockHooks.arsMsgToggleS ? (
              <div className="wrap chatWrap">
                <ArsMockMsg />
              </div>
            ) : (
              <div className="wrap numWrap">
                <div className="people">
                  <h4>015-8504-1234</h4>
                  {/* <button
                className={`control ${arsMockHooks.isPlayS.intro ? 'active' : ''}`}
                onClick={arsSoftPhoneS === 'mock_s1' ? () => arsMockHooks.onClickPlayPauseBtn('intro') : () => {}}
                // disabled={arsMockHooks.handleDisabled('intro')}
              >
                <p className="stop">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path
                      d="M17.909 8.19315C18.3893 8.44856 18.791 8.82984 19.0712 9.29614C19.3514 9.76243 19.4994 10.2962 19.4994 10.8402C19.4994 11.3841 19.3514 11.9179 19.0712 12.3842C18.791 12.8505 18.3893 13.2317 17.909 13.4872L5.097 20.4542C3.034 21.5772 0.5 20.1172 0.5 17.8082V3.87315C0.5 1.56315 3.034 0.104151 5.097 1.22515L17.909 8.19315Z"
                      fill="white"
                    />
                  </svg>
                </p>
                <p className="start">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21" fill="none">
                    <path
                      d="M4 0.839844H2C1.46957 0.839844 0.96086 1.05056 0.585787 1.42563C0.210714 1.8007 0 2.30941 0 2.83984V18.8398C0 19.3703 0.210714 19.879 0.585787 20.2541C0.96086 20.6291 1.46957 20.8398 2 20.8398H4C4.53043 20.8398 5.03914 20.6291 5.41421 20.2541C5.78929 19.879 6 19.3703 6 18.8398V2.83984C6 2.30941 5.78929 1.8007 5.41421 1.42563C5.03914 1.05056 4.53043 0.839844 4 0.839844ZM14 0.839844H12C11.4696 0.839844 10.9609 1.05056 10.5858 1.42563C10.2107 1.8007 10 2.30941 10 2.83984V18.8398C10 19.3703 10.2107 19.879 10.5858 20.2541C10.9609 20.6291 11.4696 20.8398 12 20.8398H14C14.5304 20.8398 15.0391 20.6291 15.4142 20.2541C15.7893 19.879 16 19.3703 16 18.8398V2.83984C16 2.30941 15.7893 1.8007 15.4142 1.42563C15.0391 1.05056 14.5304 0.839844 14 0.839844Z"
                      fill="white"
                    />
                  </svg>
                </p>
              </button> */}
                </div>
                <div className="numberPad">
                  <button
                    className={arsMockHooks.isPlayS.choice1 ? 'active' : ''}
                    onClick={arsSoftPhoneS === 'mock_s1' ? () => arsMockHooks.onClickPlayPauseBtn('choice1') : () => {}}
                    // disabled={arsMockHooks.handleDisabled('choice1')}
                  >
                    1
                  </button>
                  <button onClick={arsSoftPhoneS === 'mock_s1' ? () => arsMockHooks.onClickArsMsgBtn() : () => {}}>
                    2
                  </button>
                  <button
                    className={arsMockHooks.isPlayS.choice3 ? 'active' : ''}
                    onClick={arsSoftPhoneS === 'mock_s1' ? () => arsMockHooks.onClickPlayPauseBtn('choice3') : () => {}}
                  >
                    3
                  </button>
                  <button
                    className={arsMockHooks.isPlayS.choice4 ? 'active' : ''}
                    onClick={arsSoftPhoneS === 'mock_s1' ? () => arsMockHooks.onClickPlayPauseBtn('choice4') : () => {}}
                    // disabled={arsMockHooks.handleDisabled('choice4')}
                  >
                    4
                  </button>
                  <button
                    className={arsMockHooks.isPlayS.calling ? 'active' : ''}
                    onClick={arsSoftPhoneS === 'mock_s1' ? () => arsMockHooks.onClickPlayPauseBtn('calling') : () => {}}
                    // disabled={arsMockHooks.handleDisabled('calling')}
                  >
                    5
                  </button>
                  <button>6</button>
                  <button>7</button>
                  <button>8</button>
                  <button>9</button>
                  <button>*</button>
                  <button>0</button>
                  <button>#</button>
                  {/* <button className="outCall" onClick={() => arsMockHooks.onClickStopAllars()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="21" viewBox="0 0 54 21" fill="none">
                  <path
                    d="M10.7678 19.5103L15.2237 15.9676C15.7492 15.5526 16.1738 15.0239 16.4656 14.4212C16.7574 13.8185 16.9088 13.1575 16.9084 12.4879L16.9085 6.69368C23.6515 4.49716 30.9177 4.49716 37.6607 6.69368L37.6607 12.5194C37.6607 13.8735 38.2748 15.1489 39.3454 15.9991L43.7699 19.5103C44.6272 20.1891 45.7043 20.529 46.796 20.4651C47.8877 20.4012 48.9177 19.9381 49.6901 19.1639L52.414 16.44C54.1932 14.6608 54.1932 11.7007 52.3038 10.0317C38.0228 -2.58028 16.5306 -2.56453 2.26536 10.0317C0.375929 11.7007 0.375931 14.6608 2.15514 16.44L4.87907 19.1639C6.4536 20.7699 8.98859 20.9116 10.7678 19.5103Z"
                    fill="currentColor"
                  />
                </svg>
              </button> */}
                  <button
                    className={`control ${!arsMockHooks.isPlayS.intro ? 'active' : ''}`}
                    onClick={arsSoftPhoneS === 'mock_s1' ? () => arsMockHooks.onClickPlayPauseBtn('intro') : () => {}}
                    // disabled={arsMockHooks.handleDisabled('intro')}
                  >
                    <p className="stop">
                      <svg xmlns="http://www.w3.org/2000/svg" width="54" height="21" viewBox="0 0 54 21" fill="none">
                        <path
                          d="M10.7678 19.5103L15.2237 15.9676C15.7492 15.5526 16.1738 15.0239 16.4656 14.4212C16.7574 13.8185 16.9088 13.1575 16.9084 12.4879L16.9085 6.69368C23.6515 4.49716 30.9177 4.49716 37.6607 6.69368L37.6607 12.5194C37.6607 13.8735 38.2748 15.1489 39.3454 15.9991L43.7699 19.5103C44.6272 20.1891 45.7043 20.529 46.796 20.4651C47.8877 20.4012 48.9177 19.9381 49.6901 19.1639L52.414 16.44C54.1932 14.6608 54.1932 11.7007 52.3038 10.0317C38.0228 -2.58028 16.5306 -2.56453 2.26536 10.0317C0.375929 11.7007 0.375931 14.6608 2.15514 16.44L4.87907 19.1639C6.4536 20.7699 8.98859 20.9116 10.7678 19.5103Z"
                          fill="currentColor"
                        />
                      </svg>
                    </p>
                    <p className="start">
                      <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                        <path
                          d="M48.7091 36.8241L41.0737 35.9524C40.1759 35.8469 39.2659 35.9463 38.412 36.2431C37.5581 36.5399 36.7826 37.0263 36.1438 37.6658L30.6126 43.1969C22.079 38.8569 15.1428 31.9207 10.8028 23.3871L16.364 17.8259C17.6566 16.5333 18.2878 14.7297 18.0774 12.896L17.2057 5.32071C17.0352 3.85429 16.3315 2.50175 15.2284 1.52061C14.1253 0.539466 12.6999 -0.00175029 11.2236 4.25262e-06L6.02314 4.25262e-06C2.62631 4.25262e-06 -0.199381 2.82569 0.0110427 6.22253C1.60425 31.8942 22.1356 52.3955 47.7772 53.9887C51.174 54.1991 53.9997 51.3734 53.9997 47.9766V42.7761C54.0298 39.74 51.7452 37.1848 48.7091 36.8241Z"
                          fill="currentColor"
                        />
                      </svg>
                    </p>
                  </button>
                </div>
              </div>
            )}
          </S.ArsPhone>
        </S.Wrap>
      )}
    </>
  );
};
export default ArsMockupPhone;
