import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.9rem;
  height: 1.9rem;
`;

const IconSpeaker: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, width, height, fill }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      width={width ?? '50'}
      height={height ?? '47'}
      viewBox="0 0 50 47"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5625 25.0369L45.3125 32.3276V5.40882L26.5625 12.6994V25.0369ZM45.7437 0.211944L21.875 9.49319H9.375C6.8886 9.49319 4.50403 10.4809 2.74587 12.2391C0.98772 13.9972 0 16.3818 0 18.8682C0 21.3546 0.98772 23.7392 2.74587 25.4973C4.50403 27.2555 6.8886 28.2432 9.375 28.2432H12.4031L15.7344 42.1338C16.0676 43.5183 16.8569 44.7503 17.9752 45.6318C19.0936 46.5132 20.476 46.9927 21.9 46.9932C26.6625 46.9932 29.725 41.9369 27.5156 37.7151L22.7344 28.5776L45.7437 37.5244C46.2173 37.7083 46.7285 37.774 47.2331 37.7158C47.7377 37.6576 48.2206 37.4772 48.6398 37.1904C49.059 36.9035 49.402 36.5188 49.639 36.0695C49.876 35.6202 49.9999 35.1199 50 34.6119V3.12444C49.9999 2.61647 49.876 2.11618 49.639 1.66689C49.402 1.21761 49.059 0.832874 48.6398 0.546008C48.2206 0.259143 47.7377 0.0787964 47.2331 0.0205866C46.7285 -0.0376233 46.2173 0.0280585 45.7437 0.211944ZM17.2188 28.2432H17.2687L23.3625 39.8869C23.4712 40.0945 23.5345 40.3227 23.5482 40.5565C23.562 40.7904 23.5259 41.0245 23.4424 41.2433C23.3588 41.4622 23.2298 41.6608 23.0637 41.8259C22.8976 41.9911 22.6982 42.1191 22.4789 42.2014C22.2596 42.2838 22.0253 42.3185 21.7916 42.3034C21.5578 42.2884 21.3299 42.2238 21.123 42.114C20.9161 42.0042 20.7349 41.8516 20.5914 41.6665C20.4479 41.4814 20.3454 41.2678 20.2906 41.0401L17.2188 28.2432ZM9.375 14.1807H21.875V23.5557H9.375C8.75943 23.5557 8.14989 23.4345 7.58117 23.1989C7.01246 22.9633 6.49571 22.618 6.06044 22.1828C5.62516 21.7475 5.27988 21.2307 5.04431 20.662C4.80875 20.0933 4.6875 19.4838 4.6875 18.8682C4.6875 18.2526 4.80875 17.6431 5.04431 17.0744C5.27988 16.5057 5.62516 15.9889 6.06044 15.5536C6.49571 15.1184 7.01246 14.7731 7.58117 14.5375C8.14989 14.3019 8.75943 14.1807 9.375 14.1807Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconSpeaker;
