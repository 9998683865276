/**
 * @title 메세지 알림 허용에 대한 hook
 * */
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { webSocketDataRecoil } from '@/recoil/atoms/webSocket';
import { pushAlertRecoil } from '@/recoil/atoms/atoms';

/**
 * @title 메세지 알림이 true 일 경우에 대한 로직 hook
 * */
export const useMessagePushAlert = () => {
  const queryClient = useQueryClient();
  const webSocketDataS = useRecoilValue(webSocketDataRecoil);
  const pushAlertS = useRecoilValue(pushAlertRecoil);

  useEffect(() => {
    if (!webSocketDataS || !pushAlertS.smsAlertStatus) return;
    const decodingWebSocketData = JSON.parse(webSocketDataS);
    if (decodingWebSocketData.direction === 'mo') queryClient.refetchQueries(['/userPoint']);
  }, [webSocketDataS]);
};
