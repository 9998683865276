import styled from 'styled-components';

export const PaginationStyle = styled.div`
  .sendResultPaging {
    display: flex;
    align-items: center;
    /* justify-content:space-between; */
    justify-content: center;
    button {
      color: ${(props) => props.theme.colors.main};
      padding: 0;
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      font-size: 1.3rem;
      svg {
        width: 1.2rem;
        height: 1.3rem;
      }
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 1.3rem;
      &:first-child {
        margin-right: 1.8rem;
      }
      &:last-child {
        margin-left: 1.8rem;
      }
    }
    ol {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.8rem;
      li {
        width: 3.8rem;
        text-align: center;
        border-radius: 2rem;
        border: 1px solid #919091;
        button {
          width: 100%;
          height: 100%;
          color: #919091;
        }
        &.active {
          border: 1px solid ${(props) => props.theme.colors.main};
          button {
            color: ${(props) => props.theme.colors.main};
          }
        }
      }
    }
  }
`;
