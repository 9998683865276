import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 2.2em;
  height: 1em;
`;

const IconCassetteTape: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '1.3rem'}
      viewBox="0 0 29 13"
      fill="none"
    >
      <path
        d="M22.3483 -0.00415039C21.1435 -0.00303577 19.9664 0.356723 18.9669 1.02928C17.9674 1.70185 17.1907 2.65676 16.7359 3.77233C16.281 4.88791 16.1686 6.11364 16.413 7.29333C16.6573 8.47303 17.2473 9.55327 18.1079 10.3964H10.9878C11.9925 9.41204 12.6239 8.10919 12.7741 6.7107C12.9243 5.31221 12.5839 3.90499 11.8112 2.72974C11.0385 1.55448 9.88142 0.684224 8.53794 0.267821C7.19446 -0.148582 5.74804 -0.0852573 4.4461 0.446964C3.14415 0.979185 2.06757 1.94723 1.40051 3.18552C0.733456 4.42381 0.517364 5.8554 0.789203 7.23541C1.06104 8.61542 1.80392 9.8581 2.89077 10.7509C3.97761 11.6437 5.3409 12.1311 6.74743 12.1298H22.3483C23.9573 12.1298 25.5005 11.4906 26.6383 10.3529C27.7761 9.21507 28.4153 7.67191 28.4153 6.06284C28.4153 4.45378 27.7761 2.91061 26.6383 1.77283C25.5005 0.635049 23.9573 -0.00415039 22.3483 -0.00415039ZM2.41386 6.06284C2.41386 5.20574 2.66802 4.36789 3.1442 3.65524C3.62037 2.94259 4.29719 2.38715 5.08904 2.05915C5.8809 1.73115 6.75223 1.64533 7.59286 1.81254C8.43349 1.97976 9.20566 2.39249 9.81172 2.99855C10.4178 3.60461 10.8305 4.37678 10.9977 5.2174C11.1649 6.05803 11.0791 6.92937 10.7511 7.72123C10.4231 8.51308 9.86768 9.18989 9.15502 9.66607C8.44237 10.1422 7.60452 10.3964 6.74743 10.3964C5.59809 10.3964 4.49583 9.93984 3.68313 9.12714C2.87043 8.31444 2.41386 7.21217 2.41386 6.06284ZM22.3483 10.3964C21.4912 10.3964 20.6533 10.1422 19.9407 9.66607C19.228 9.18989 18.6726 8.51308 18.3446 7.72123C18.0166 6.92937 17.9308 6.05803 18.098 5.2174C18.2652 4.37678 18.6779 3.60461 19.284 2.99855C19.89 2.39249 20.6622 1.97976 21.5028 1.81254C22.3435 1.64533 23.2148 1.73115 24.0066 2.05915C24.7985 2.38715 25.4753 2.94259 25.9515 3.65524C26.4277 4.36789 26.6818 5.20574 26.6818 6.06284C26.6818 7.21217 26.2253 8.31444 25.4126 9.12714C24.5999 9.93984 23.4976 10.3964 22.3483 10.3964Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconCassetteTape;
