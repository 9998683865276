import styled from 'styled-components';

export const SelectNumber = styled.div``;

export const SelectNumberWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 -1rem;
  padding: 0 1rem;
  height: 100%;
  button {
    cursor: pointer;
  }
  .flexBox-js {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

export const SelectGroup = styled.div`
  margin-bottom: 4rem;
  h2 {
    color: ${(props) => props.theme.colors.txt500};
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    margin-left: 6px;
  }
  .search {
    color: ${(props) => props.theme.colors.txt500};
    margin-left: 1rem;
  }
  .phoneGroup {
    color: ${(props) => props.theme.colors.txt500};
    font-size: 1.6rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    gap: 1rem;

    .numberGroup {
      position: relative;
      & + button {
        p.open {
          display: block;
        }
        p.close {
          display: none;
        }
      }
      &.active + button {
        p.open {
          display: none;
        }
        p.close {
          display: block;
        }
      }
      .number {
        display: flex;
        align-items: center;
        padding: 0.5rem 1.2rem;
        background: ${(props) => props.theme.colors.gray100};
        border: 1px solid ${(props) => props.theme.colors.gray600};
        gap: 0.5rem;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 6.4px;
        border-radius: 6rem;
        > p {
          width: 52px;
        }
        > span {
          width: 16px;
          letter-spacing: 6.4px;
        }
        > span:nth-child(4) {
          width: 18px;
          margin: -3px;
        }
        input {
          border: 0;
          outline: #333;
          font-size: 1.6rem;
          font-weight: 600;
          letter-spacing: 6.4px;
          width: 7rem;
          background-color: transparent;
          ::placeholder {
            color: ${(props) => props.theme.colors.placeholder};
            font-size: 1.6rem;
            font-weight: 600;
            letter-spacing: 6.4px;
          }
        }
        button {
          background: transparent;
          border: 0;
          line-height: 0;
          cursor: pointer;
        }
      }
      .recommendationNumberBox {
        z-index: 2;
        /* position: absolute;
				top: 100%;
				left: 0; */
        display: none;
        padding: 0.6rem 0;
        padding-right: 4px;
        background: ${(props) => props.theme.colors.gray100};
        width: 100%;
        border: 1px solid ${(props) => props.theme.colors.gray600};
        border-top: 0;
        border-radius: 0 0 1.5rem 1.5rem;
        overflow: hidden;
      }
      .recommendationNumber {
        width: 100%;
        height: 20vh;
        max-height: 30rem;
        overflow: auto;
        // overflow 스크롤 styles
        ::-webkit-scrollbar {
          width: 0.4rem;
          background-color: #d6d6dc;
          border-radius: 40px;
        }
        ::-webkit-scrollbar-thumb {
          background: #98999a;
          border-radius: 40px;
        }

        li {
          position: relative;
          button {
            width: 100%;
            font-size: 1.6rem;
            padding: 0.6rem 1rem 0.6rem 1.2rem;
            letter-spacing: 0.6rem;
            font-weight: 500;
            border: 0;
            text-align: left;
            letter-spacing: 6.4px;
            color: ${(props) => props.theme.colors.txt500};

            :hover {
              font-weight: 700;
              background: ${(props) => props.theme.colors.gray200};
              ::before {
                display: block;
              }
            }
          }
        }

        button {
        }
      }

      /* active css */
      &.active {
        .number {
          border-radius: 1.5rem 1.5rem 0 0;
        }
        .recommendationNumberBox {
          display: block;
        }
        /* .recommendationNumber{
					display: block;
				} */
      }
    }

    > button {
      font-size: 1.3rem;
      font-weight: 600;
      border-radius: 5rem;
      border: 0;
      color: ${(props) => props.theme.colors.txt100};
      background: ${(props) => props.theme.colors.grayButton2};
      box-shadow: 0.5rem 0.5rem 1rem 0 rgba(0, 0, 0, 0.03);
      padding: 0.85rem 1rem;
      width: 10rem;
    }
  }

  .numberStatus {
    .status {
      display: none;
      font-size: 1.3rem;
      margin-left: 1.2rem;
      color: ${(props) => props.theme.colors.txt300};
      font-weight: 500;
      &.active {
        display: flex;
      }
      svg {
        margin-right: 4px;
      }
    }
  }
`;

export const Provision = styled.div`
  h2 {
    color: ${(props) => props.theme.colors.txt500};
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .selectGroup {
    li {
      position: relative;
      margin-bottom: 1rem;
      :last-child {
        margin-bottom: 0;
      }
    }

    /* 실제 체크박스 */
    input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    input[type='checkbox'] + label {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.8rem 1.2rem;
      color: ${(props) => props.theme.colors.txt500};
      border: 1px solid transparent;
      border-radius: 5px;
      background: ${(props) => props.theme.colors.gray100};
      font-size: 1.3rem;
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      p {
        width: calc(100% - 3rem);
      }
      span.gray {
        margin-left: 2px;
        color: ${(props) => props.theme.colors.txt300};
      }
      svg {
        color: ${(props) => props.theme.colors.deactivation};
      }
    }
    input.active[type='checkbox'] + label {
      p {
        color: ${(props) => props.theme.colors.txt500};
      }
      svg {
        color: ${(props) => props.theme.colors.main};
      }
    }
    input.check_all[type='checkbox'] + label {
      border: 0.5px solid ${(props) => props.theme.colors.main};
      font-weight: 600;
    }

    /* 가상 체크박스 */
    input.check_all[type='checkbox']:checked + label {
      background: ${(props) => props.theme.colors.white};
    }
  }
`;
