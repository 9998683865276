import React from 'react';
import * as S from './HelpPopup.styles';
import { THelpPage } from '@/components/hooks/customs/windowPopup/helpPopup/useHelpContentPopup';

interface IPopupPages {
  page: THelpPage;
  index: number;
  handleSetRef: (index: number) => (el: HTMLDivElement | null) => void;
}

const HelpPopupPages = (props: IPopupPages) => {
  return (
    <>
      {props.page === '메인' && (
        <>
          <p className="row rowMain" ref={props.handleSetRef(props.index)} data-page={props.page} />
          <S.Page className="helpHomeWrap" key={props.page}>
            <img src="img/helpPage/helpPage_home.png" alt="helpPage_home" />
          </S.Page>
        </>
      )}
      {props.page === '주소록' && (
        <>
          <p className="row" ref={props.handleSetRef(props.index)} data-page={props.page} />
          <S.Page className="helpAddressWrap" key={props.page}>
            <img src="img/helpPage/helpPage_address.png" alt="helpPage_address" />
          </S.Page>
        </>
      )}
      {props.page === '간편추가' && (
        <>
          <p className="row" ref={props.handleSetRef(props.index)} data-page={props.page} />
          <S.Page className="helpAddWrap" key={props.page}>
            <img src="img/helpPage/helpPage_add.png" alt="helpPage_add" />
          </S.Page>
        </>
      )}
      {props.page === '대량추가' && (
        <>
          <p className="row" ref={props.handleSetRef(props.index)} data-page={props.page} />
          <S.Page className="helpLargeAddWrap" key={props.page}>
            <img src="img/helpPage/helpPage_largeAdd.png" alt="helpPage_largeAdd" />
          </S.Page>
        </>
      )}
      {props.page === '음성사서함' && (
        <>
          <p className="row" ref={props.handleSetRef(props.index)} data-page={props.page} />
          <S.Page className="helpPobWrap" key={props.page}>
            <img src="img/helpPage/helpPage_pob.png" alt="helpPage_pob" />
          </S.Page>
        </>
      )}
      {props.page === '015 문자발송' && (
        <>
          <p className="row" ref={props.handleSetRef(props.index)} data-page={props.page} />
          <S.Page className="helpSend015Wrap" key={props.page}>
            <img src="img/helpPage/helpPage_send015.png" alt="helpPage_send015" />
          </S.Page>
        </>
      )}
      {props.page === '010 문자발송' && (
        <>
          <p className="row" ref={props.handleSetRef(props.index)} data-page={props.page} />
          <S.Page className="helpSend010Wrap" key={props.page}>
            <img src="img/helpPage/helpPage_send010.png" alt="helpPage_send010" />
          </S.Page>
        </>
      )}
      {props.page === '010 예약 문자 발송' && (
        <>
          <p className="row" ref={props.handleSetRef(props.index)} data-page={props.page} />
          <S.Page className="helpReserve010Wrap" key={props.page}>
            <img src="img/helpPage/helpPage_reserve010.png" alt="helpPage_reserve010" />
          </S.Page>
        </>
      )}
    </>
  );
};
export default HelpPopupPages;
