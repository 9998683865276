import styled from 'styled-components';

export const TaxInvoiceHistoryWrap = styled.div`
  > h2 {
    margin-bottom: 2rem;
  }
`;

export const TableFoot = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2.5rem 0;
`;

export const TableDiv = styled.div`
  min-height: 10rem;

  & td {
    white-space: wrap;
    line-height: 1.8rem;
  }
`;
