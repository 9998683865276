import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconAddressSync: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11 10.9992C12.1045 10.9992 12.9999 10.1039 12.9999 8.99937C12.9999 7.89488 12.1045 6.99951 11 6.99951C9.89555 6.99951 9.00018 7.89488 9.00018 8.99937C9.00018 10.1039 9.89555 10.9992 11 10.9992Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9998 13.9991V12.9991C13.9998 12.4687 13.7891 11.9601 13.4141 11.585C13.039 11.21 12.5304 10.9993 12 10.9993H10.0001C9.46971 10.9993 8.96103 11.21 8.58599 11.585C8.21094 11.9601 8.00024 12.4687 8.00024 12.9991V13.9991C8.00024 14.2642 8.10559 14.5186 8.29312 14.7061C8.48064 14.8936 8.73497 14.999 9.00017 14.999H12.9999C13.2651 14.999 13.5194 14.8936 13.7069 14.7061C13.8945 14.5186 13.9998 14.2642 13.9998 13.9991Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11.0006C0.999737 8.93265 1.64065 6.91553 2.83448 5.22697C4.02831 3.53842 5.71633 2.26151 7.66607 1.57208C9.6158 0.882655 11.7313 0.814633 13.7213 1.37738C15.7114 1.94013 17.4779 3.10597 18.7778 4.71434M19.3333 1.00127V5.4454H14.8889M21 11.0006C21 13.0684 20.359 15.0853 19.165 16.7737C17.9711 18.4621 16.2831 19.7388 14.3334 20.4281C12.3837 21.1174 10.2683 21.1853 8.27838 20.6225C6.28849 20.0598 4.52202 18.894 3.22222 17.2857M2.66667 20.9998V16.5557H7.11111"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconAddressSync;
