import { leftZoneComponentRecoil, listToggleRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useSetRecoilState } from 'recoil';

interface IUseAddLargeCancel {
  handleLargeTableReset: () => void;
}

export const useAddLargeCancel = (props: IUseAddLargeCancel) => {
  /* 좌측 컴포넌트 상태 recoil */
  const setLeftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  const setListToggleS = useSetRecoilState(listToggleRecoil);

  // ==============================
  // onClick 대량추가 취소 버튼 hook
  // ==============================
  const onClickCancelBtn = () => {
    // 여기에 초기화 함수 추가해야합니다.
    setLeftZoneComponentS('address');
    setListToggleS(false);
    props.handleLargeTableReset();
  };

  return { onClickCancelBtn };
};
