import { instance } from '@/shared/lib/clientAxios';
import { IUseQueryMessageHistory } from '@/pages/main/components/etcLeftZone/chatRoom015/ChatRoom015.types';
import { useInfiniteQuery } from '@tanstack/react-query';

/**
 * @title 015 개별 채팅방 주고받은 메세지 (채팅형식)
 *
 * @author 정휘학
 * @since 2024.04.04
 **/
export const useQueryMessageHistory = (params: IUseQueryMessageHistory) => {
  const api = async (pageParam: any) => {
    const response = await instance.get('/smt/history/chat', {
      params: {
        ...params,
        pageNumber: pageParam,
      },
    });
    return response.data;
  };

  const {
    data: mhData,
    isLoading: mhLoading,
    status: mhStatus,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(['/smt/history/chat', params.contactNumber], async ({ pageParam = 0 }) => api(pageParam), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage: any) => {
      const nextPage = lastPage.pageable.pageNumber + 1;
      return nextPage < lastPage.totalPages ? nextPage : undefined;
    },
    enabled: params.contactNumber !== null,
  });

  return {
    mhData,
    mhLoading,
    mhStatus,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  };
};
