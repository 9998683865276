import styled from 'styled-components';

export const SignuptermsContainer = styled.div`
  font-size: 1.4rem;
  line-height: 1.4em;
  overflow: auto;
  height: 100%;
  color: ${(props) => props.theme.colors.txt500};

  > * {
    &:nth-child(1) {
      margin: 0 0 1rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin: 2rem 0 1rem;
  }
  h4,
  h5 {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 1rem;
  }

  ul {
    li {
      display: flex;
      gap: 0.5rem;
      margin: 0 0 0.5rem;
      > span {
      }
      > p {
      }
      &:has(ul),
      :has(table) {
        flex-wrap: wrap;
      }
      &:has(span) {
        p {
          width: calc(100% - 2.4rem);
        }
      }
      ul {
        padding: 0 0 0 1.4rem;
        width: 100%;
      }
    }
  }

  table {
    width: 100%;
    margin: 1rem 0;
    th,
    td {
      padding: 1rem;
      border: 1px solid ${(props) => props.theme.colors.gray400};
    }
  }
`;
