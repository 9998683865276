import { useEffect, useRef, useState } from 'react';
// 메인, 주소록, 간편추가, 대량추가, 음성사서함, 015전송, 010전송, 010예약
export type THelpPage =
  | '메인'
  | '주소록'
  | '간편추가'
  | '대량추가'
  | '음성사서함'
  | '015 문자발송'
  | '010 문자발송'
  | '010 예약 문자 발송';

export const useHelpContentPopup = () => {
  /* 해당 태그로 이동하기 위한 Ref */
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  /* 현재 페이지를 나타내는 STATE */
  const [currentPage, setCurrentPage] = useState<THelpPage>('메인');
  /* 전체 페이지 LIST */
  const helpPages: THelpPage[] = [
    '메인',
    '주소록',
    '간편추가',
    '대량추가',
    '음성사서함',
    '015 문자발송',
    '010 문자발송',
    '010 예약 문자 발송',
  ];

  // ==============================
  // onClick 페이지 변경하는 Btn 클릭 hook
  // ==============================
  const onClickPagesChange = (page: THelpPage) => {
    // 현재 페이지 변환
    setCurrentPage(page);
    // 클릭한 버튼이 바라보는 page index 추출
    const pageIndex = helpPages.indexOf(page);
    // ref를 통해 해당 index에 해당하는 태그가 존재한다면 해당 태그로 스크롤
    if (pageRefs.current[pageIndex]) {
      pageRefs.current[pageIndex]?.scrollIntoView({ behavior: 'auto' });
    }
  };

  // ref를 설정하기 위한 함수
  const handleSetRef = (index: number) => {
    return (el: HTMLDivElement | null) => {
      if (el) {
        pageRefs.current[index] = el;
      }
    };
  };

  // 해당 태그 위치 파악 후 헤더 버튼 스타일 변경하는 useEffect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // 상위 태그 우선 처리를 위한 로직
        let prioritizedTarget: Node | null = null;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target as HTMLElement;
            // 첫 번째로 발견된 태그를 우선으로 설정
            if (!prioritizedTarget || target.contains(prioritizedTarget)) {
              prioritizedTarget = target;
            }
          }
        });

        // 우선 처리된 타겟의 페이지 설정
        if (prioritizedTarget) {
          setCurrentPage((prioritizedTarget as HTMLElement).dataset.page as THelpPage);
        }
      },
      { threshold: 0.1 }, // 필요에 따라 rootMargin 설정
    );

    // 각 태그에 대해 Observer 등록
    pageRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      // 컴포넌트가 언마운트될 때 Observer 해제
      pageRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return { currentPage, helpPages, onClickPagesChange, pageRefs, handleSetRef };
};
