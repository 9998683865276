import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

interface ISOneTapSideNav {
  list: {
    label: string;
    to: string;
    children?: {
      label: string;
      to: string;
    }[];
  }[];
  handleNavigationClick: (component: string) => void;
}

const Ul = styled.ul`
  @media screen and (max-width: 1440px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 768px) {
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.08);
  }
`;
const Li = styled.li`
  font-size: 1.6rem;
  margin-bottom: 1.6rem;

  @media screen and (max-width: 1440px) {
    :last-child {
      a {
        border-right: 0;
      }
    }
    a {
      position: relative;
      border-right: 1px solid ${(props) => props.theme.colors.gray700};
      margin: 0 0.5rem 0 0;
      padding: 0 0.5rem 0;
    }
    a.active {
      ::after {
        content: '';
        width: calc(100% - 0.5rem);
        height: 2px;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        background: ${(props) => props.theme.colors.main};
      }
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    background: transparent;
    margin: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
    :last-of-type {
      border-bottom: 0;
      border-right: 0;
    }
    ::after,
    ::before {
      z-index: 1;
      content: '';
      display: block;
      width: 0.8rem;
      height: 0.1rem;
      background-color: ${(props) => props.theme.colors.gray600};
      position: absolute;
      top: 50%;
      right: 1rem;
      border-radius: 5rem;
      transform: translateY(calc(-50% - 0.26rem)) rotate(45deg);
    }
    ::before {
      transform: translateY(calc(-50% + 0.26rem)) rotate(-45deg);
    }
    a {
      display: block;
      width: 100%;
      padding: 2rem 1.5rem;
      font-size: 1.7rem;
      border-right: 0;
      background: ${(props) => props.theme.colors.white};

      color: ${(props) => props.theme.colors.txt500};
      &.active {
        ::after {
          content: unset;
        }
      }
    }
  }
`;

const SubLi = styled.li`
  font-size: 1.6rem;
  line-height: 30px;
  width: 120px;
  /* white-space: nowrap; */
  margin-left: 20px;
  &:hover {
    font-weight: bold;
  }
`;

const StyledNavLink = styled(NavLink).attrs({
  activeclassname: 'active',
})`
  white-space: nowrap;
  text-decoration: none;
  color: ${(props) => props.theme.colors.txt400};

  &.active {
    font-weight: bold;
    color: ${(props) => props.theme.colors.main};
  }
`;

const OneDepthSideNav = (props: ISOneTapSideNav) => {
  return (
    <Ul>
      {props.list.map((el) => (
        <Li key={uuidv4()} onClick={() => props.handleNavigationClick(el.label)}>
          {el.children ? (
            <div>
              <StyledNavLink activeclassname="active" to={el.to}>
                {el.label}
              </StyledNavLink>
              <Ul>
                {el.children.map((child) => (
                  <SubLi key={uuidv4()}>
                    <StyledNavLink
                      activeclassname="active"
                      style={{
                        fontWeight: window.location.pathname === '/payment' && child.label === '결제내역' ? 'bold' : '',
                      }}
                      to={child.to}
                    >
                      {child.label}
                    </StyledNavLink>
                  </SubLi>
                ))}
              </Ul>
            </div>
          ) : (
            <StyledNavLink activeclassname="active" to={el.to}>
              {el.label}
            </StyledNavLink>
          )}
        </Li>
      ))}
    </Ul>
  );
};

export default OneDepthSideNav;
