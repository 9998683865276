import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.7em;
  height: 1em;
`;

const IconArrowupTwo: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '.9rem'}
      viewBox="0 0 16 9"
      fill="none"
    >
      <path
        d="M7.33877 1.08266L1.16067 4.05276C1.05753 4.10232 0.999999 4.16816 0.999999 4.23663C0.999999 4.3051 1.05753 4.37094 1.16067 4.4205L1.16765 4.42369C1.21765 4.4478 1.27783 4.467 1.34454 4.48012C1.41125 4.49323 1.48308 4.5 1.55567 4.5C1.62827 4.5 1.7001 4.49323 1.76681 4.48012C1.83351 4.467 1.8937 4.4478 1.94369 4.42369L7.76111 1.6268L13.5762 4.4237C13.6262 4.4478 13.6864 4.467 13.7531 4.48012C13.8198 4.49324 13.8916 4.5 13.9642 4.5C14.0368 4.5 14.1086 4.49324 14.1754 4.48012C14.2421 4.467 14.3022 4.4478 14.3522 4.4237L14.3592 4.4205C14.4624 4.37094 14.5199 4.3051 14.5199 4.23663C14.5199 4.16816 14.4624 4.10232 14.3592 4.05277L8.18113 1.08266C8.1268 1.05654 8.06145 1.03575 7.98905 1.02154C7.91665 1.00733 7.83871 1 7.75995 1C7.68118 1 7.60324 1.00733 7.53084 1.02154C7.45844 1.03575 7.3931 1.05654 7.33877 1.08266Z"
        fill={fill ?? 'currentColor'}
        stroke={stroke ?? 'currentColor'}
      />
      <path
        d="M7.33877 4.58266L1.16067 7.55276C1.05753 7.60232 0.999999 7.66816 0.999999 7.73663C0.999999 7.8051 1.05753 7.87094 1.16067 7.9205L1.16765 7.92369C1.21765 7.9478 1.27783 7.967 1.34454 7.98012C1.41125 7.99323 1.48308 8 1.55567 8C1.62827 8 1.7001 7.99323 1.76681 7.98012C1.83351 7.967 1.8937 7.9478 1.94369 7.92369L7.76111 5.1268L13.5762 7.9237C13.6262 7.9478 13.6864 7.967 13.7531 7.98012C13.8198 7.99324 13.8916 8 13.9642 8C14.0368 8 14.1086 7.99324 14.1754 7.98012C14.2421 7.967 14.3022 7.9478 14.3522 7.9237L14.3592 7.9205C14.4624 7.87094 14.5199 7.8051 14.5199 7.73663C14.5199 7.66816 14.4624 7.60232 14.3592 7.55277L8.18113 4.58266C8.1268 4.55654 8.06145 4.53575 7.98905 4.52154C7.91665 4.50733 7.83871 4.5 7.75995 4.5C7.68118 4.5 7.60324 4.50733 7.53084 4.52154C7.45844 4.53575 7.3931 4.55654 7.33877 4.58266Z"
        fill={fill ?? 'currentColor'}
        stroke={stroke ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconArrowupTwo;
