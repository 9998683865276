import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Header = styled.header`
  /* padding:26px 0 34px 5%; */
  /* padding:2.6rem 0 3.4rem 0; */
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.txt500};
  width: 100%;
  /* background-color: #f8f9fd; */
  h1 {
    a {
      display: inline-block;
      height: 100%;
    }
  }
`;
export const HeaderWrap = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0 4rem;
  display: flex;
  align-items: center;
  /* height: 102px; */
  /* max-width: 1600px; */
  h1 {
    width: 50%;
  }

  div {
    cursor: pointer;
  }
  svg {
    max-width: 100%;
    max-height: 100%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  @media screen and (max-width: 768px) {
    width: calc(100% - 2rem);
    padding: 2rem 0 3rem;
  }
`;
