import styled from 'styled-components';

export const ChatRoom = styled.div`
  /* testCode수정 */
  /* position: relative; */

  .NodataBox {
    margin-bottom: 0;
    box-shadow: unset;
    cursor: unset;
    :hover {
      border: 0;
    }
  }
`;

/* testCode수정 */
export const Overlay = styled.div`
  z-index: 2; /* 최상단에 위치 */
  position: absolute;
  top: 0;
  left: -1rem;
  width: calc(100% + 2rem);
  height: 100%;
  background: ${(props) => props.theme.colors.gray200};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  h2 {
    text-align: center;
    font-size: large;
    font-weight: bold;
  }
`;
