import SignUpRecovery from '@/pages/SignUpPage/signUpRecovery/SignUpRecovery';
import React from 'react';

const MobileSignUpRecovery = () => {
  return (
    <>
      <SignUpRecovery />
    </>
  );
};
export default MobileSignUpRecovery;
