import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { use015ServiceStatus } from './use015ServiceStatus';
import { useCashCalculate } from './useCashCalculate';
import { postTtsData } from '@/apis/api/say015Apis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { WORKS015_TTS } from '@/shared/constants';
import { useRecoilValue } from 'recoil';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

/**
 * @title ARS TTS 변환시 금액 지불
 *
 * */
export const useTts = () => {
  const queryClient = useQueryClient();
  const navigator = useNavigate();

  /* tts price */
  const ttsPrice = parseInt(WORKS015_TTS as string, 10);
  /* tts cache hooks */
  const statusHooks = use015ServiceStatus();
  /* tts 분기처리 hooks */
  const calculateHooks = useCashCalculate();

  // TODO - 충전 : 앱 충전로직 끝나기 전까지 막을 기능
  const environmentS = useRecoilValue(environmentRecoil);

  const { confirmModal, warningModal } = useModalHook();

  /* TTS 변환 count POST API */
  const { mutate: ttsCountM } = useMutation(() => postTtsData(), {
    onSuccess: (usedTtsCount: any) => {
      if (usedTtsCount <= 5) {
        // 무료 횟수 status 캐싱작업
        statusHooks.updateCacheTtsStatus(usedTtsCount);
      }
      if (usedTtsCount === 100) {
        queryClient.refetchQueries(['/userPoint']);
      }
    },
    onError: () => {},
  });

  // ==============================
  // tts free count, cash 분기처리 hooks
  // ==============================
  const onClickTtsBtn = (mutateArsTTS: UseMutateFunction<any, unknown, void, unknown>) => {
    // tts 카운트 * tts가격 처리
    const ttsCountCash = (5 - statusHooks.statusData.ttsCount) * ttsPrice;
    // 여기서 포인트 여부에 따라 분기처리 후 해당 함수 실행
    const confirmTts = calculateHooks.handleSttCalculate(ttsPrice, ttsCountCash);
    if (confirmTts.type === 'freeE') {
      mutateArsTTS();
    }
    if (confirmTts.type === 'cashE') {
      // 컨펌모달 ok시 point 캐싱작업 추가해야합니다.
      confirmModal(
        async () => handleCashE(mutateArsTTS),
        '서비스 안내',
        '당일 무료 이용 건수를 모두 소진했습니다.</br> 안내멘트(인사말) 저장을 위해 100캐시를 사용합니다.',
        true,
      );
    }
    if (confirmTts.type === 'charge') {
      // TODO - 충전소 : 앱 충전소 기능 끝나면 변경
      const naviUrlS = environmentS === 'app' ? '/mobile/etc/etcCharge' : '/charge';

      confirmModal(
        async () => navigator(naviUrlS),
        '캐시 부족',
        `보유금액이 부족합니다. 충전소로 이동하시겠습니까?`,
        true,
      );
    }
    if (confirmTts.type === 'error') {
      warningModal('오류', '정보를 확인할 수 없습니다.</br>새로고침 또는 재로그인 후 이용해 주세요', true);
    }
  };

  // ==============================
  // tts 변환 포인트 차감시 hook
  // ==============================
  const handleCashE = (mutateArsTTS: UseMutateFunction<any, unknown, void, unknown>) => {
    mutateArsTTS();
  };

  return { onClickTtsBtn, ttsCountM };
};
