import styled from 'styled-components';

export const SearchGroupComponent = styled.form`
  z-index: 1;
  display: flex;
  gap: 1rem;
  background: ${(props) => props.theme.colors.gray200};
  border-top: 1px solid ${(props) => props.theme.colors.gray300};
  padding: 1rem 1.3rem;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0px 0.7rem 1.3rem 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
  button {
    cursor: pointer;
    padding: 0;
  }
  .searchdelBtn {
    position: absolute;
    top: 50%;
    right: 0.9rem;
    transform: translateY(-50%);
  }
  .addGroupRightWrap {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /* 검색창 스타일 */
  .searchGroup {
    width: 100%;
    position: relative;
    display: flex;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 0.5rem;
    outline: 1px solid ${(props) => props.theme.colors.gray300};
    :has(input:focus) {
      outline-color: ${(props) => props.theme.colors.gray400};
    }
  }
  .addBtn {
    margin: 0 0 0 1rem;
    color: ${(props) => props.theme.colors.gray600};
  }

  input {
    width: 100%;
    height: 3.8rem;
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.txt500};
    outline: unset;
    padding: 0 3rem 0 1rem;
    border: 0;
    border-radius: 0.5rem;
    font-weight: 600;
    ::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
      font-size: 1.4rem;
    }
  }
  /* 취소 스타일 */
  .btnGroup {
    display: flex;
    justify-content: space-between;
    button {
      margin-left: 1.5rem;
      font-size: 1.4rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors.gray600};
    }
  }
`;
