import { getFindId } from '@/apis/api/findAccountApis';
import { useDeleteAccount } from '@/apis/hooks/useDeleteAccount';
import useRecoveryAccount from '@/apis/hooks/useRecoveryAccount';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

export const useMobileSignUpIdCheck = () => {
  const location = useLocation();
  const signUpInfo = location.state;

  const { confirmModal } = useModalHook();
  const { handleAccountDelete, deleteLoading } = useDeleteAccount();
  const { handleIdRecovery } = useRecoveryAccount();

  const { data: fetchData, isLoading: dataListLoading } = useQuery(['/users/id'], () =>
    getFindId(signUpInfo.data.encodeData),
  );

  const navigate = useNavigate();

  const keepSignUp = () => {
    confirmModal(
      async () =>
        navigate('/mobile/mobileSignUpForm', {
          state: { data: fetchData.encodeData, data2: 'A' },
        }),
      '회원가입',
      `이미 사용중인 계정이 존재합니다. </br>
       계속 가입하실 경우 기존 계정은 휴면처리되고 진행합니다.`,
      true,
    );
  };

  return { handleIdRecovery, handleAccountDelete, keepSignUp, signUpInfo, fetchData, dataListLoading, deleteLoading };
};
