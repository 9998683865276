import { useAddressExcelColumModal } from '@/components/hooks/customs/addressBook/useAddressExcelColumModal';
import { addressItemsByGroup } from '@/shared/constants';
import { Button, Form, Modal } from 'antd';
import React from 'react';
import * as S from './AddressExcelColumModal.style';
import { IAddressExcelColumModal } from './AddressExcelColumModal.type';

/**
 * @title AddressAddColumModal.tsx 보여질 항목 선택 컴포넌트 모달 창
 *
 * @author 김남규
 * @since 2024.03.14
 * */
const AddressExcelColumModal = (props: IAddressExcelColumModal) => {
  // Hooks
  const hooks = useAddressExcelColumModal(props);

  return (
    <Modal
      title="엑셀에 표시할 항목 선택"
      open={props.isModalOpen}
      onCancel={hooks.handleCancel}
      footer={[
        <Button key="ok" type="primary" onClick={hooks.handleOk}>
          확인
        </Button>,
      ]}
    >
      <S.InfoWrap>
        <Form>
          {addressItemsByGroup.map((group) => (
            <div key={group.head}>
              <S.H2>{group.head}</S.H2>
              <S.DivWrap>
                {group.children.map((child) => (
                  <S.InputWrap key={`${child.id}`}>
                    <input
                      type="checkbox"
                      id={`${child.id}`}
                      name={child.label}
                      value={child.label}
                      onChange={hooks.checkHandler}
                      checked={hooks.excelColumS.has(child.label)}
                      //   disabled={disabledItems.includes(child.label)}
                    />
                    <label htmlFor={`${child.id}`}>{child.label}</label>
                  </S.InputWrap>
                ))}
              </S.DivWrap>
            </div>
          ))}
        </Form>
      </S.InfoWrap>
    </Modal>
  );
};

export default AddressExcelColumModal;
