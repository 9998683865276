import { TOpenModal } from '@/components/hooks/customs/useHotfixModal';
import { CheckCircleOutlined } from '@ant-design/icons'; // antd 아이콘 사용
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* 배경을 어둡게, 반투명으로 설정 */
  z-index: 97; /* 모달보다 뒤에 위치하도록 */
`;

const EventModalWrap = styled.div`
  z-index: 98;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.txt400};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.txt400};
    font-weight: bold;
    font-size: 2rem;
    text-align: left;

    .icon {
      font-size: 2rem;
      color: #52c41a; /* antd의 확인 색상 */

      margin-right: 8px;
    }
  }

  .content {
    padding: 1.5rem;
    text-align: center;
    font-size: 1.4rem;
    background-color: ${(props) => props.theme.colors.white};
    line-height: normal;
  }

  .btnGroup {
    display: flex;
    padding: 0 1rem 1rem;
    gap: 1rem;
    button {
      cursor: pointer;
      flex: 1;
      padding: 1rem;
      font-size: 1.4rem;
      border-radius: 0.5rem;
      border: none;
      outline: none;

      &:first-child {
        color: ${(props) => props.theme.colors.txt300};
        background: ${(props) => props.theme.colors.gray200};
      }

      &:last-child {
        color: ${(props) => props.theme.colors.txt100};
        background: ${(props) => props.theme.colors.gray700};
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.main};
        color: ${(props) => props.theme.colors.txt100};
      }
    }
  }
`;

interface IHotfixModal {
  onClickHotfixClose: (type: keyof TOpenModal, button?: '7days') => void;
}

const Event2Modal = (props: IHotfixModal) => {
  const handleBtn = (type: string) => {
    if (type === 'close') {
      props.onClickHotfixClose('event2');
    }
    if (type === '7days') {
      props.onClickHotfixClose('event2', type);
    }
  };

  return (
    <>
      <Overlay />
      <EventModalWrap>
        <div className="header">
          <CheckCircleOutlined className="icon" />
          <span>알림</span>
        </div>
        <div className="content">
          <p>
            지금 015Mode를 클릭 후, 015번호를 발급받고 양방향 문자전송 서비스를 이용해보세요. ARS 음성사서함도
            이용가능합니다.
          </p>
          <br />
          <p>(SMS : 14원 / LMS : 42원 / MMS : 84원)</p>
        </div>
        <div className="btnGroup">
          <button onClick={() => handleBtn('7days')}>일주일 간 보지 않기</button>
          <button onClick={() => handleBtn('close')}>닫기</button>
        </div>
      </EventModalWrap>
    </>
  );
};

export default Event2Modal;
