/**
 * @title ARS 기능 HOOK
 *
 * */
import { useState } from 'react';
import { TArsCard } from '@/components/say015/Modal/arsCards/ArsCardModal';

/**
 * @title ARS 기본적인 핸들러 함수 모음 HOOK
 *
 * */
export const use015ArsFC = () => {
  /* ars 카드 업데이트 boolean */
  const [isUpdatedIdS, setIsUpdatedIdS] = useState<string | null>(null);
  /* ars 카드 생성 boolean */
  const [isAddIdS, setIsAddIdS] = useState<string | null>(null);
  /* ars 카드 생성될 종류 content */
  const [selectAddCardS, setSelectAddCardS] = useState<TArsCard | null>(null);

  // =======================
  // ARS 수정될 카드의 ID 핸들러
  // =======================
  const handleUpdateIdState = (isUpdatedId: string | null) => {
    setIsUpdatedIdS(isUpdatedId);
  };

  // ========================
  // ARS 생성될 카드의 ID 핸들러
  // ========================
  const handleAddIdState = (isAddId: string | null) => {
    setIsAddIdS((prevState) => (prevState && prevState === isAddId ? null : isAddId));
    setSelectAddCardS(null);
  };

  return {
    isUpdatedIdS,
    isAddIdS,
    handleUpdateIdState,
    handleAddIdState,
    setSelectAddCardS,
    selectAddCardS,
  };
};
