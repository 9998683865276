import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useWindowPopup } from './windowPopup/useWindowPopup';

export const useInitCookies = () => {
  const token = localStorage.getItem('authorizationToken');
  const [appCookies] = useCookies(['hideHelpPopup']);

  const popupHooks = useWindowPopup();

  // 도움말 사이트 오픈시 자동 open
  // useEffect(() => {
  //   const popupOpened = localStorage.getItem('popupOpened');
  //   if (token && !appCookies.hideHelpPopup && !popupOpened) {
  //     popupHooks.openPopup({ url: '/helpContent', target: '_blank', size: '1' });
  //     localStorage.setItem('popupOpened', 'true');
  //   }
  // }, [token]);
};
