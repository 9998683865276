import Loader from '@/components/common/Loader';
import SkeletonBox from '@/components/common/SkeletonBox';
import { useAddressBookTable } from '@/components/hooks/customs/useAddressBookTable';
import { useAddressBookTableCheckBox } from '@/components/hooks/customs/useAddressBookTableCheckBox';
import { useAddressTableHover } from '@/components/hooks/customs/useAddressTableHover';
import * as S from '@/pages/main/components/addressLeftZone/addressBookTable/AddressBookTable.styles';
import TableDataAdd from '@/pages/main/components/addTable/tableDataAdd';
import { isModalOpenRecoil } from '@/recoil/atoms/addressColum';
import { tableAddOpenRecoil } from '@/recoil/atoms/addressList';
import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';
import { useLoaderTimer } from '@/shared/hooks/useLoaderTimer';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import * as CBS from '@/styles/checkBox.styles';
import * as SS from '@/styles/selectBox.styles';
import { BuddyData } from '@/types/index.types';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import GroupListSelect from '../groupList/GroupListSelect';
import AddressHistory from './addressHistory/AddressHistory';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { activeGroupRecoil } from '@/recoil/atoms/softPhone';

const AddressBookTable = () => {
  const queryClient = useQueryClient();
  /* 정렬 데이터 */
  const sortData = [
    'groupNm',
    'buddyNm', // 이름
    'keyCommNo', // 휴대폰번호
    'number015', // 015번호
    'emailId', // 이메일
    'faxNumber', // 팩스
    'coNm', // 회사이름
    'coNumber', // 회사전화
    'coDept', // 부서
    'coHandle', // 직함
    'coZipCd', // 회사주소
    'coAddr', // 회사주소
    'homeZipCd', // 집주소
    'homeAddr', // 집주소
    'homeNumber', // 집전화
    'etcInfo', // 메모
    'homepageUrl', // 홈페이지
    'messenger', // 메신저
    'birthday', // 생일
    'marriageDay', // 결혼기념일
    'genderFlag', // 성별
    'residentNo',
    'accountNo', // 계좌번호
  ];
  /* 전화번호에 관련된 value */
  // const phoneNumberKey = ['keyCommNo', 'coNumber', 'homeNumber'];
  const phoneNumberKey = ['keyCommNo'];
  /* 그룹 리스트 캐싱 데이터 */
  // const groupListQueryData: any = queryClient.getQueryData(['groupList2']);
  /* 메인 주소록 기본 기능 hook */
  const hooks = useAddressBookTable();
  // 메인 주소록 리스트 데이터 체크박스 관리 hook
  const checkBoxHooks = useAddressBookTableCheckBox();
  /* 주소록 간편추가 컴포넌트 표출상태제어 recoil */
  const [tableAddOpenS, setTableAddOpenS] = useRecoilState(tableAddOpenRecoil);
  /* 로딩 컴포넌트 표출 시간제어 hook */
  const timerHooks = useLoaderTimer({ isLoading: hooks.inFiniteResult.isLoading });
  /* 주소록 메인테이블 width 관리 */
  const mainTableWidthR = useRecoilValue(mainTableWidthRecoil);
  /* 주소록 개별 호버에 대한 훅 */
  const hoverHooks = useAddressTableHover();
  /* 항목추가 모달 토글 Recoil */
  const setIsModalOpenS = useSetRecoilState(isModalOpenRecoil);

  const [activeGroupS, setActiveGroupS] = useRecoilState(activeGroupRecoil);

  // ! 주소록 cell (header) drag and drop - START
  const [draggedColIndex, setDraggedColIndex] = useState<any>(null);

  const handleDragStart = (colIndex: any) => {
    setDraggedColIndex(colIndex);
  };

  const handleDrop = (colIndex: number) => {
    if (draggedColIndex !== null) {
      // Reorder headers
      const newHeadersValue = [...hooks.addressColumViewValueR];
      const newHeadersLabel = [...hooks.addressColumViewLabelR];
      const draggedHeaderValue = newHeadersValue.splice(draggedColIndex, 1)[0];
      const draggedHeaderLabel = newHeadersLabel.splice(draggedColIndex, 1)[0];
      newHeadersValue.splice(colIndex, 0, draggedHeaderValue);
      newHeadersLabel.splice(colIndex, 0, draggedHeaderLabel);
      hooks.setAddressColumViewValueR(new Set(newHeadersValue));
      hooks.setAddressColumViewLabelR(new Set(newHeadersLabel));

      localStorage.setItem('addressColumViewLabel', JSON.stringify(Array.from(newHeadersLabel)));
      localStorage.setItem('addressColumViewValue', JSON.stringify(Array.from(newHeadersValue)));
      setDraggedColIndex(null);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <S.Container id={'mainTable'} className="mainTable">
        {/* TODO - HW - 주소록 상세보기 */}
        {hoverHooks?.addressDetail[hoverHooks?.detailBuddyDataS?.buddySeqNo as number] && (
          <AddressHistory
            onClickCloseDetailBtn={hoverHooks.onClickCloseDetailBtn}
            buddyData={hoverHooks.detailBuddyDataS}
          />
        )}
        {tableAddOpenS && <TableDataAdd setTableAddOpen={setTableAddOpenS} />}
        {hooks.inFiniteResult.isFetchingNextPage && <Loader backgroundToggle={false} />}
        <S.Wrapper
          className="mainWrapper"
          widthData={mainTableWidthR}
          ref={hooks.tableRef}
          onScroll={(event) =>
            !hooks.inFiniteResult.isFetchingNextPage && hooks.handleInfiniteScroll(event, hooks.inFiniteResult)
          }
        >
          <S.Table>
            <S.TableHead>
              <tr>
                {/* 체크박스 */}
                <th className="fixedBox">
                  <CBS.CheckBoxInput
                    type="checkBox"
                    checked={hooks.checkingAllCheckBox()}
                    onChange={checkBoxHooks.allGroupCheckH}
                  />
                </th>
                {Array.from(hooks.addressColumViewValueR).map((value: any, index) => {
                  // class name 오름차순 asc / 내림차순 desc 클래스명 붙으면 스타일 조정
                  let sortClassName = '';
                  const label = Array.from(hooks.addressColumViewLabelR)[index];
                  if (value === hooks.groupBuddyListSortS.key) {
                    if (hooks.groupBuddyListSortS.type === 'asc') {
                      sortClassName = 'asc';
                    } else if (hooks.groupBuddyListSortS.type === 'desc') {
                      sortClassName = 'desc';
                    }
                  }

                  return window.innerWidth <= 768 ? (
                    (label === '그룹' || label === '이름' || label === '휴대폰번호') && (
                      <th
                        key={value}
                        draggable
                        onDragStart={() => handleDragStart(index)}
                        onDragOver={handleDragOver}
                        onDrop={() => handleDrop(index)}
                        className={sortClassName}
                        onClick={() => {
                          hooks.handleGroupBuddyListSort(value);
                        }}
                      >
                        <div className="arrangeGroup">
                          <p>{label}</p>
                          {/* arrow 아이콘 */}
                          {sortData.includes(value) && (
                            <div className="arrange">
                              <button className="arrowBtn ascBtn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="6"
                                  height="4"
                                  viewBox="0 0 6 4"
                                  fill="none"
                                >
                                  <path d="M2.89784 0.892693L0.738767 2.5899C0.702724 2.61822 0.682617 2.65584 0.682617 2.69496C0.682617 2.73409 0.702724 2.77171 0.738767 2.80003L0.741207 2.80186C0.75868 2.81563 0.779712 2.8266 0.803024 2.8341C0.826336 2.84159 0.85144 2.84546 0.876809 2.84546C0.902179 2.84546 0.927283 2.84159 0.950595 2.8341C0.973907 2.8266 0.994939 2.81563 1.01241 2.80186L3.04543 1.20363L5.07764 2.80186C5.09512 2.81563 5.11615 2.8266 5.13946 2.8341C5.16277 2.84159 5.18788 2.84546 5.21324 2.84546C5.23861 2.84546 5.26372 2.84159 5.28703 2.8341C5.31034 2.8266 5.33137 2.81563 5.34885 2.80186L5.35129 2.80003C5.38733 2.77171 5.40744 2.73409 5.40744 2.69496C5.40744 2.65584 5.38733 2.61822 5.35129 2.5899L3.19222 0.892693C3.17323 0.877767 3.15039 0.865884 3.12509 0.857765C3.09979 0.849646 3.07255 0.845459 3.04503 0.845459C3.0175 0.845459 2.99026 0.849646 2.96496 0.857765C2.93966 0.865884 2.91682 0.877767 2.89784 0.892693Z" />
                                </svg>
                              </button>
                              <button className="arrowBtn descBtn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="6"
                                  height="4"
                                  viewBox="0 0 6 4"
                                  fill="none"
                                >
                                  <path d="M3.19219 2.56263L5.35126 0.865426C5.3873 0.837107 5.40741 0.799484 5.40741 0.760359C5.40741 0.721235 5.3873 0.683612 5.35126 0.655293L5.34882 0.653466C5.33135 0.63969 5.31031 0.628721 5.287 0.621225C5.26369 0.613729 5.23859 0.609863 5.21322 0.609863C5.18785 0.609863 5.16274 0.613729 5.13943 0.621225C5.11612 0.62872 5.09509 0.63969 5.07762 0.653466L3.04459 2.25169L1.01238 0.653465C0.994911 0.63969 0.973879 0.62872 0.950568 0.621224C0.927255 0.613728 0.902151 0.609863 0.876782 0.609863C0.851413 0.609863 0.826309 0.613728 0.802997 0.621224C0.779685 0.62872 0.758652 0.63969 0.74118 0.653465L0.73874 0.655293C0.702697 0.683611 0.682589 0.721235 0.682589 0.760359C0.682589 0.799483 0.702697 0.837107 0.73874 0.865425L2.89781 2.56263C2.9168 2.57755 2.93963 2.58944 2.96493 2.59756C2.99023 2.60568 3.01747 2.60986 3.045 2.60986C3.07253 2.60986 3.09976 2.60568 3.12507 2.59756C3.15037 2.58944 3.1732 2.57756 3.19219 2.56263Z" />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                      </th>
                    )
                  ) : (
                    <th
                      key={value}
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragOver={handleDragOver}
                      onDrop={() => handleDrop(index)}
                      className={sortClassName}
                      onClick={() => {
                        hooks.handleGroupBuddyListSort(value);
                      }}
                    >
                      <div className="arrangeGroup">
                        <p>{label}</p>
                        {/* arrow 아이콘 */}
                        {sortData.includes(value) && (
                          <div className="arrange">
                            <button className="arrowBtn ascBtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="4"
                                viewBox="0 0 6 4"
                                fill="none"
                              >
                                <path d="M2.89784 0.892693L0.738767 2.5899C0.702724 2.61822 0.682617 2.65584 0.682617 2.69496C0.682617 2.73409 0.702724 2.77171 0.738767 2.80003L0.741207 2.80186C0.75868 2.81563 0.779712 2.8266 0.803024 2.8341C0.826336 2.84159 0.85144 2.84546 0.876809 2.84546C0.902179 2.84546 0.927283 2.84159 0.950595 2.8341C0.973907 2.8266 0.994939 2.81563 1.01241 2.80186L3.04543 1.20363L5.07764 2.80186C5.09512 2.81563 5.11615 2.8266 5.13946 2.8341C5.16277 2.84159 5.18788 2.84546 5.21324 2.84546C5.23861 2.84546 5.26372 2.84159 5.28703 2.8341C5.31034 2.8266 5.33137 2.81563 5.34885 2.80186L5.35129 2.80003C5.38733 2.77171 5.40744 2.73409 5.40744 2.69496C5.40744 2.65584 5.38733 2.61822 5.35129 2.5899L3.19222 0.892693C3.17323 0.877767 3.15039 0.865884 3.12509 0.857765C3.09979 0.849646 3.07255 0.845459 3.04503 0.845459C3.0175 0.845459 2.99026 0.849646 2.96496 0.857765C2.93966 0.865884 2.91682 0.877767 2.89784 0.892693Z" />
                              </svg>
                            </button>
                            <button className="arrowBtn descBtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="4"
                                viewBox="0 0 6 4"
                                fill="none"
                              >
                                <path d="M3.19219 2.56263L5.35126 0.865426C5.3873 0.837107 5.40741 0.799484 5.40741 0.760359C5.40741 0.721235 5.3873 0.683612 5.35126 0.655293L5.34882 0.653466C5.33135 0.63969 5.31031 0.628721 5.287 0.621225C5.26369 0.613729 5.23859 0.609863 5.21322 0.609863C5.18785 0.609863 5.16274 0.613729 5.13943 0.621225C5.11612 0.62872 5.09509 0.63969 5.07762 0.653466L3.04459 2.25169L1.01238 0.653465C0.994911 0.63969 0.973879 0.62872 0.950568 0.621224C0.927255 0.613728 0.902151 0.609863 0.876782 0.609863C0.851413 0.609863 0.826309 0.613728 0.802997 0.621224C0.779685 0.62872 0.758652 0.63969 0.74118 0.653465L0.73874 0.655293C0.702697 0.683611 0.682589 0.721235 0.682589 0.760359C0.682589 0.799483 0.702697 0.837107 0.73874 0.865425L2.89781 2.56263C2.9168 2.57755 2.93963 2.58944 2.96493 2.59756C2.99023 2.60568 3.01747 2.60986 3.045 2.60986C3.07253 2.60986 3.09976 2.60568 3.12507 2.59756C3.15037 2.58944 3.1732 2.57756 3.19219 2.56263Z" />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
                <th className="fixedRightHidden" />
              </tr>
            </S.TableHead>
            {/* 로딩 - 스켈레톤 ui */}
            {timerHooks.timer || hooks.inFiniteResult.isLoading ? (
              <S.TableBody className="skeleton" tdWidthData={hooks.tableTdWidth}>
                {[...Array(28)].map((_) => (
                  <tr key={uuidv4()}>
                    <td className="fixedBox">
                      <CBS.CheckBoxInput type="checkBox" />
                    </td>
                    {Array.from(hooks.addressColumViewValueR).map((value, index) => (
                      <td key={value}>
                        <SkeletonBox type={'4'} size={'small'} block active />
                      </td>
                    ))}
                  </tr>
                ))}
              </S.TableBody>
            ) : (
              <S.TableBody
                style={{ height: `${hooks.rowVirtualizer.getTotalSize()}px` }}
                tdWidthData={hooks.tableTdWidth}
              >
                {hooks.rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const { index } = virtualRow;
                  const list = hooks.groupBuddyListDataS[index];

                  return (
                    <tr
                      key={`${list.buddySeqNo}${index}`}
                      className={hoverHooks?.detailBuddyDataS?.buddySeqNo === list.buddySeqNo ? 'active' : ''}
                      style={{
                        position: 'absolute',
                        // top: 0,
                        left: 0,
                        width: '100%',
                        // height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                      onClick={(e) => {
                        if (window.innerWidth <= 768) {
                          e.stopPropagation();
                          hoverHooks.onClickDetailBtn(list);
                        }
                      }}
                    >
                      {/* 체크박스 */}
                      <td className="fixedBox">
                        <CBS.CheckBoxInput
                          type="checkBox"
                          onChange={() => checkBoxHooks.changeChildrenCheck(list.groupSeqNo, list.buddySeqNo)}
                          checked={checkBoxHooks.checkingChildrenCheck(list.groupSeqNo, list.buddySeqNo)}
                          onClick={(e) => e.stopPropagation()}
                        />
                        {/* 주소록 상세페이지 오픈 버튼 */}
                        {window.innerWidth > 768 && (
                          <button className="customer" onClick={() => hoverHooks.onClickDetailBtn(list)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.0044 10.146C15.0044 9.86984 14.7806 9.64598 14.5044 9.64598C14.2283 9.64598 14.0044 9.86984 14.0044 10.146L14.0044 13.2973L9.25827 8.55116C9.063 8.3559 8.74642 8.3559 8.55116 8.55116C8.3559 8.74642 8.3559 9.063 8.55116 9.25826L13.2973 14.0044H10.146C9.86984 14.0044 9.64598 14.2283 9.64598 14.5044C9.64598 14.7806 9.86984 15.0044 10.146 15.0044H14.5044C14.7806 15.0044 15.0044 14.7806 15.0044 14.5044L15.0044 10.146ZM5.7513 6.4584C5.94656 6.65367 6.26314 6.65367 6.4584 6.4584C6.65367 6.26314 6.65367 5.94656 6.4584 5.7513L1.71223 1.00513L4.86358 1.00513C5.13972 1.00513 5.36358 0.78127 5.36358 0.505127C5.36358 0.228985 5.13972 0.00512716 4.86358 0.00512714L0.505127 0.00512695C0.228985 0.00512694 0.00512716 0.228985 0.00512714 0.505127L0.00512695 4.86358C0.00512694 5.13972 0.228985 5.36358 0.505127 5.36358C0.78127 5.36358 1.00513 5.13972 1.00513 4.86358L1.00513 1.71223L5.7513 6.4584ZM10.146 0.00512757C9.86985 0.00512757 9.64599 0.228985 9.64599 0.505128C9.64599 0.78127 9.86985 1.00513 10.146 1.00513H13.2973L8.55117 5.7513C8.35591 5.94656 8.35591 6.26314 8.55117 6.4584C8.74643 6.65367 9.06301 6.65367 9.25828 6.4584L14.0044 1.71223V4.86358C14.0044 5.13972 14.2283 5.36358 14.5044 5.36358C14.7806 5.36358 15.0044 5.13972 15.0044 4.86358V0.505128C15.0044 0.228985 14.7806 0.00512757 14.5044 0.00512757H10.146ZM6.45842 9.25826C6.65368 9.063 6.65368 8.74642 6.45842 8.55116C6.26315 8.3559 5.94657 8.3559 5.75131 8.55116L1.00514 13.2973V10.146C1.00514 9.86984 0.781281 9.64598 0.505139 9.64598C0.228997 9.64598 0.005139 9.86984 0.005139 10.146V14.5044C0.005139 14.7806 0.228997 15.0044 0.505139 15.0044H4.86359C5.13973 15.0044 5.36359 14.7806 5.36359 14.5044C5.36359 14.2283 5.13973 14.0044 4.86359 14.0044H1.71225L6.45842 9.25826Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                        )}
                      </td>
                      {/* 인풋 텍스트 변환 해당 td에 focus라는 class명으로 input 토글 형식 */}
                      {Array.from(hooks.addressColumViewValueR)
                        .slice(0, window.innerWidth <= 768 ? 3 : Array.from(hooks.addressColumViewValueR).length)
                        .map((value) => {
                          const key = value as keyof BuddyData;

                          const elementKey = `${list.buddySeqNo}${value}`;
                          if (value === 'groupNm') {
                            return (
                              <td
                                key={elementKey}
                                className={hooks.focusTargetKeyS === elementKey ? 'focus' : ''}
                                onDoubleClick={() => hooks.handleChangeForm(elementKey)}
                              >
                                <p>{list[key] as string}</p>
                                <div className="text selectBoxtTxt">
                                  <SS.SelectInput className={'active'}>
                                    <button tabIndex={0} title={list[key] as string}>
                                      {list[key] as string}
                                    </button>
                                    {/* 현재 그룹을 제외한 모든 그룹 리스트가 표출되야함 */}
                                    {/* <ul>
                                  <div>
                                    {groupListQueryData?.groupList?.map((item: any) => {
                                      if (item.groupSeqNo !== list.groupSeqNo) {
                                        return (
                                          <li
                                            key={item.groupSeqNo}
                                            onClick={() => hooks.triggerUpdateToLi(item.groupSeqNo, item.groupNm, list)}
                                          >
                                            <button>
                                              <p>{item.groupNm}</p>
                                            </button>
                                          </li>
                                        );
                                      }
                                      return null;
                                    })}
                                  </div>
                                </ul> */}
                                    <GroupListSelect
                                      type="move"
                                      item={list}
                                      data={list}
                                      // onClickClose={() => {}} // 이미 상위에서 처리가 되어있는 로직이라 여기선 불필요
                                      onClickfunction={hooks.onClickUpdateLi}
                                    />
                                  </SS.SelectInput>
                                </div>
                              </td>
                            );
                          }
                          if (value !== 'groupNm' && value !== 'etcInfo' && value === 'genderFlag') {
                            return (
                              <td
                                key={elementKey}
                                id={elementKey}
                                onDoubleClick={() => hooks.handleChangeForm(elementKey)}
                                className={hooks.focusTargetKeyS === elementKey ? 'focus' : ''}
                                title={list[key] as string}
                              >
                                <SS.SelectBoxMax className={hooks.focusTargetKeyS === elementKey ? 'active' : ''}>
                                  <button>
                                    {(() => {
                                      if (list[key] === null || list[key] === undefined) {
                                        return '';
                                      }
                                      if (list[key] === 0 || list[key] === '0') {
                                        return '';
                                      }
                                      if (typeof list[key] === 'number') {
                                        return hooks.genderLabels[list[key] as number]; // 타입 단언 사용
                                      }
                                      if (list[key] && typeof list[key] === 'string') {
                                        const index = Number(list[key]);
                                        return hooks.genderLabels[index] !== undefined ? hooks.genderLabels[index] : '';
                                      }
                                      return ''; // 기본값
                                    })()}
                                  </button>

                                  <ul>
                                    {hooks.genderLabels.map((gender, index) => {
                                      return (
                                        <li key={gender}>
                                          <button
                                            onClick={() =>
                                              hooks.triggerUpdateToSelect(index, list, value as keyof BuddyData)
                                            }
                                          >
                                            {gender}
                                          </button>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </SS.SelectBoxMax>
                              </td>
                            );
                          }
                          if (value !== 'groupNm' && value !== 'etcInfo') {
                            return (
                              <td
                                key={elementKey}
                                id={elementKey}
                                onDoubleClick={() => hooks.handleChangeForm(elementKey)}
                                className={hooks.focusTargetKeyS === elementKey ? 'focus' : ''}
                                title={list[key] as string}
                              >
                                <div>
                                  <div>
                                    {phoneNumberKey.includes(value) ? TelHyphen(list[key] as string) : list[key]}
                                  </div>
                                </div>
                                <div className="text">
                                  <input
                                    type="text"
                                    ref={(el) => {
                                      hooks.listItemRefs.current[elementKey] = el;
                                    }}
                                    // autoFocus={hooks.focusTargetKeyS === `${list.buddySeqNo}${item.value}`}
                                    defaultValue={list[key] as string}
                                    onKeyDown={(event) =>
                                      hooks.triggerUpdateToInput(event, list, value as keyof BuddyData)
                                    }
                                  />
                                </div>
                              </td>
                            );
                          }
                          if (value === 'etcInfo') {
                            return (
                              <td
                                key={elementKey}
                                onDoubleClick={() => hooks.handleChangeForm(elementKey)}
                                className={`memo ${hooks.focusTargetKeyS === elementKey ? 'focus' : ''}`}
                                title={list[key] as string}
                              >
                                <p>{list[key] as string}</p>
                                <div className="text">
                                  <textarea
                                    ref={(el) => {
                                      hooks.listItemRefs.current[elementKey] = el;
                                    }}
                                    defaultValue={list[key] as string}
                                    onKeyDown={(event) => {
                                      hooks.triggerUpdateToTextarea(event, list, value as keyof BuddyData);
                                    }}
                                  />
                                </div>
                              </td>
                            );
                          }
                          return null;
                        })}
                      {/* TODO - HW - 복사, 휴지통 */}
                      <td className="fixedRight">
                        <div className="btnWrap">
                          {/* active 클래스 추가 필요 */}
                          <SS.SelectInput
                            className={`group addGroup right ${hoverHooks.addressCopy[list.buddySeqNo] ? 'active' : null}`}
                          >
                            <button onClick={() => hoverHooks.onClickCopySelect(list)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                              >
                                <path
                                  d="M12.153 0.78418H1.48633V12.652"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.04297 4.43555H15.7096V15.3905C15.7096 15.8747 15.5223 16.3391 15.1889 16.6815C14.8555 17.0239 14.4034 17.2163 13.9319 17.2163H6.82075C6.34925 17.2163 5.89707 17.0239 5.56367 16.6815C5.23027 16.3391 5.04297 15.8747 5.04297 15.3905V4.43555Z"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            {/* 주소록 개별 복사 셀렉트 */}
                            {hoverHooks.addressCopy[list.buddySeqNo] && (
                              <GroupListSelect
                                onClickClose={hoverHooks.onClickoutLine}
                                onClickfunction={hoverHooks.onClickCopyGroup}
                                data={list}
                                type="copy"
                              />
                            )}
                          </SS.SelectInput>
                          <div className="group delGroup">
                            <button onClick={() => hoverHooks.onClickHoverDel(list.buddySeqNo)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M3.58355 3.70499V14.7768C3.58344 15.093 3.64399 15.4061 3.76176 15.6982C3.87952 15.9904 4.05217 16.2558 4.26986 16.4794C4.48755 16.7029 4.74601 16.8803 5.03046 17.0012C5.3149 17.1222 5.61977 17.1843 5.92763 17.1842H12.4903C13.1118 17.1842 13.7078 16.9307 14.1472 16.4794C14.5867 16.0281 14.8336 15.4159 14.8336 14.7777V3.7041M1.70898 3.70499H16.709"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.39648 3.70513V2.26033C6.39648 2.07063 6.43288 1.8828 6.50359 1.70756C6.5743 1.53232 6.67793 1.3731 6.80858 1.23901C6.93922 1.10492 7.09431 0.998576 7.26498 0.926064C7.43566 0.853553 7.61857 0.81629 7.80327 0.816407H10.6151C10.7998 0.81629 10.9827 0.853553 11.1534 0.926064C11.3241 0.998576 11.4792 1.10492 11.6098 1.23901C11.7405 1.3731 11.8441 1.53232 11.9148 1.70756C11.9855 1.8828 12.0219 2.07063 12.0219 2.26033V3.70513M7.33463 13.4171V8.6049M11.0846 13.4171V8.6049"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </S.TableBody>
            )}
          </S.Table>
        </S.Wrapper>
        {/* 항목추가 */}
        <S.AddArticle className="mainAddArticle" onClick={() => setIsModalOpenS(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
            <path
              d="M8.38477 5C8.38477 5.08841 8.34965 5.17319 8.28714 5.2357C8.22462 5.29821 8.13984 5.33333 8.05143 5.33333H4.7181V8.66667C4.7181 8.75507 4.68298 8.83986 4.62047 8.90237C4.55796 8.96488 4.47317 9 4.38477 9C4.29636 9 4.21158 8.96488 4.14906 8.90237C4.08655 8.83986 4.05143 8.75507 4.05143 8.66667V5.33333H0.718099C0.629693 5.33333 0.544909 5.29821 0.482397 5.2357C0.419885 5.17319 0.384766 5.08841 0.384766 5C0.384766 4.91159 0.419885 4.82681 0.482397 4.7643C0.544909 4.70179 0.629693 4.66667 0.718099 4.66667H4.05143V1.33333C4.05143 1.24493 4.08655 1.16014 4.14906 1.09763C4.21158 1.03512 4.29636 1 4.38477 1C4.47317 1 4.55796 1.03512 4.62047 1.09763C4.68298 1.16014 4.7181 1.24493 4.7181 1.33333V4.66667H8.05143C8.13984 4.66667 8.22462 4.70179 8.28714 4.7643C8.34965 4.82681 8.38477 4.91159 8.38477 5Z"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.2"
            />
          </svg>
        </S.AddArticle>
      </S.Container>
    </>
  );
};

export default AddressBookTable;
