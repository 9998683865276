import React, { useEffect, useState } from 'react';
import * as S from '../SaveMessageStyled';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';

import { useModalHook } from '@/components/commons/modals/useModalHook';
import { instance, instanceFile } from '@/shared/lib/clientAxios';
import { useGetSaveMessageList } from '../hooks/UseGetSaveMessageList';
import { usePostSaveMessageList } from '../hooks/UsePostSaveMessageList';

interface SaveInputComponentsProps {
  messageBody: string;
  setMessageBody: React.Dispatch<React.SetStateAction<string>>;

  img: string | null;
  setImg: React.Dispatch<React.SetStateAction<string | null>>;

  messageTabState: string;
  setMessageTabState: React.Dispatch<React.SetStateAction<string>>;

  searchKeyword: string;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
}

const SaveInputComponents: React.FC<SaveInputComponentsProps> = ({
  messageBody,
  setMessageBody,

  img,
  setImg,

  messageTabState,
  setMessageTabState,

  searchKeyword,
  setSearchKeyword,
}) => {
  const [messageTitle, setMessageTitle] = useState<string>(''); // 메세지 제목
  const [imgFile, setImgFile] = useState<File | undefined>(undefined); //이미지 파일
  const [messageType, setMessageType] = useState<string>('SMS'); // 현재 메세지 상태값

  // 컨펌 모달
  const { confirmModal, warningModal, successModal } = useModalHook();

  //리패치
  const { mutate } = usePostSaveMessageList();
  // const { refetch } = useGetSaveMessageList(messageTabState, searchKeyword);

  //메세지 타이틀 변경 핸들러
  const handleMessageTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value;
    const byteLength = calMsgByte(newTitle);

    if (byteLength <= 20) {
      setMessageTitle(newTitle);
    }
  };

  //메세지 바디 변경 핸들러
  const handleMessageBodyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newTitle = event.target.value;
    const byteLength = calMsgByte(newTitle);

    if (byteLength <= 2000) {
      setMessageBody(newTitle);
    }
  };

  //이미지 없애기
  const handleImageDelete = () => {
    setImgFile(undefined);
    setImg(null);
  };

  //이미지 저장
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size > 500 * 1024) {
        // 500KB
        warningModal('경고', '파일 크기가 500KB를 초과합니다.', true);
        return;
      }

      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        warningModal('경고', '허용된 파일 형식은 jpg, jpeg, png 입니다.', true);
        return;
      }

      const img = new Image();
      const reader = new FileReader();

      reader.onloadend = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          // let targetWidth;
          // let targetHeight;

          // if (img.width > img.height) {
          //   //  이미지가 가로가 더 클 때
          //   targetWidth = 391;
          //   targetHeight = 320;
          // } else {
          //   //  이미지가 세로가 더 클 때
          //   targetWidth = 640;
          //   targetHeight = 820;
          // }

          // canvas.width = targetWidth;
          // canvas.height = targetHeight;

          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, { type: file.type });

              const resizedImg = new Image();
              const resizedReader = new FileReader();
              resizedReader.onloadend = () => {
                resizedImg.src = resizedReader.result as string;
              };

              resizedImg.onload = () => {
                // Save the image data
                setImg(resizedReader.result as string);
              };

              resizedReader.readAsDataURL(resizedFile);

              setImgFile(resizedFile);
            }
          }, file.type);

          e.target.value = '';
        }
      };

      reader.readAsDataURL(file);
    }
  };

  // 메세지 저장

  const send = () => {
    confirmModal(
      () => {
        // 제목 검증
        if (!messageTitle) {
          warningModal('경고', '제목을 입력해 주세요', true);
          return;
        }

        // 메시지 내용 검증
        if (messageType === 'SMS' && !messageBody) {
          warningModal('경고', '내용을 입력해 주세요', true);
          return;
        }

        mutate(
          {
            messageType,
            messageTitle,
            messageBody,
            imgFile,
            messageTabState,
            searchKeyword,
          },
          {
            onSuccess: () => {
              let tabState;
              switch (messageType) {
                case 'SMS':
                  tabState = 'SMS';
                  break;
                case 'MMS':
                case 'LMS':
                  tabState = 'MMS';
                  break;
                default:
                  tabState = ''; // 기본값 설정
              }

              // 상태 초기화 및 성공 모달 표시
              setMessageTabState(tabState);
              setImgFile(undefined);
              setImg('');
              setMessageTitle('');
              setMessageBody('');
              successModal('저장', `${tabState} 저장함에 저장 되었습니다.`, true);
            },
            onError: (error) => {
              console.error('메시지 전송 중 오류 발생:', error);
            },
          },
        );
      },
      '템플릿 메세지 저장',
      '작성하신 메세지를 저장 하시겠습니까?',
      true,
    );
  };
  // 메세지 내용이랑 이미지 확인해서 메세지 타입 변경
  useEffect(() => {
    if (img) {
      setMessageType('MMS');
    } else if (calMsgByte(messageBody) <= 90) {
      setMessageType('SMS');
    } else {
      setMessageType('LMS');
    }
  }, [messageBody, img]);

  // 삼항연산자 중첩이 안되서 if문으로 꺼내옴
  let messageInfo = null;
  if (messageType === 'SMS') {
    messageInfo = (
      <S.MessageStateInfo>
        <div>{messageType}</div>
        <div>{calMsgByte(messageBody)} / 90 Byte</div>
      </S.MessageStateInfo>
    );
  } else if (messageType === 'LMS' || messageType === 'MMS') {
    messageInfo = (
      <S.MessageStateInfo>
        <div>{messageType}</div>
        <div>{calMsgByte(messageBody)} / 2000 Byte</div>
      </S.MessageStateInfo>
    );
  }

  return (
    <S.Inner>
      <S.MessageInputSection>
        <S.MessageTitleInput
          value={messageTitle}
          placeholder="제목을 입력해 주세요.(최대20byte)"
          onChange={handleMessageTitleChange}
        />
        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id="imageInput"
        />
        {img && (
          <S.ImageContainer className="imgContainer">
            <S.ImageItemContainer>
              <S.ImagePreview src={img} alt="addImg" />
              <S.DeleteBtn onClick={handleImageDelete} />
            </S.ImageItemContainer>
          </S.ImageContainer>
        )}
        <S.MessageBodyInput
          value={messageBody}
          placeholder="저장할 템플릿 내용을 입력해 주세요."
          rows={6}
          onChange={handleMessageBodyChange}
        />

        <S.BtnContainer>
          <button onClick={() => document.getElementById('imageInput')?.click()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
              <path
                d="M9.20887 18.3026H3.24901C2.65253 18.3026 2.08049 18.103 1.65872 17.7477C1.23695 17.3923 1 16.9104 1 16.4079V3.14474C1 2.64222 1.23695 2.16029 1.65872 1.80496C2.08049 1.44962 2.65253 1.25 3.24901 1.25H18.992C19.5885 1.25 20.1606 1.44962 20.5823 1.80496C21.0041 2.16029 21.241 2.64222 21.241 3.14474V12.6184L17.7551 9.68158C17.3319 9.33217 16.7622 9.13756 16.1697 9.14004C15.5772 9.14253 15.0098 9.3419 14.5907 9.69484L4.37351 18.3026"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.3689 16.8816L16.7424 14.0395M16.7424 14.0395L20.1159 16.8816M16.7424 14.0395V19.25"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.74583 8.82899C8.98792 8.82899 9.99484 7.98069 9.99484 6.93426C9.99484 5.88782 8.98792 5.03952 7.74583 5.03952C6.50374 5.03952 5.49683 5.88782 5.49683 6.93426C5.49683 7.98069 6.50374 8.82899 7.74583 8.82899Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {messageInfo}
        </S.BtnContainer>

        <S.SendBtn
          onClick={() => {
            send();
          }}
        >
          저장하기
        </S.SendBtn>
      </S.MessageInputSection>
    </S.Inner>
  );
};

export default SaveInputComponents;
