import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import React from 'react';

import {
  leftZoneComponentRecoil,
  listToggleRecoil,
  softPhoneComponentRecoil,
  SoftPhoneComponentType,
  softPhoneTopTabRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import TransmissionResult from '@/components/softPhone/DisplayGroups/MessageManagement/TransmissionResult/TransmissionResult';
import ChatRoom from '@/components/softPhone/DisplayGroups/MessageManagement/ChatRoom/ChatRoom';
import POBox015 from '@/components/softPhone/DisplayGroups/POBox015/POBox015';
import ArsMockupPhone from '@/components/softPhone/DisplayGroups/arsContents/ArsMockupPhone';
import { useQueryClient } from '@tanstack/react-query';
import ArsPhone from '@/components/softPhone/DisplayGroups/arsContents/ArsPhone';
import { userMockupViewRecoil } from '@/recoil/atoms/say015Atom';

export const useMessageManagement = () => {
  const queryClient = useQueryClient();
  // 소프트폰 컴포넌트 Handler Recoil
  const [softPhoneComponent, setSoftPhoneComponent] = useRecoilState(softPhoneComponentRecoil);
  // 소프트폰 상단 Handler Recoil
  const softPhoneTopTabState = useRecoilValue(softPhoneTopTabRecoil);
  // 좌측 컴포넌트 Handler Recoil
  const setLeftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  const setListToggleR = useSetRecoilState(listToggleRecoil);
  // 015가입자가 목업을 볼 때 true로 변경해야하는 Recoil
  const userMockupViewS = useRecoilValue(userMockupViewRecoil);

  // 015탭 클릭시 status 받아오는 useQuery data
  const authorityData: any = queryClient.getQueryData(['authorityData']);

  // 미가입자를 확인해서 ars phone을 목업으로 표시할건지 확인
  const needM =
    authorityData &&
    (authorityData.needsGuidePage === null || authorityData.needsGuidePage === undefined || userMockupViewS)
      ? true
      : false;

  // 소프트폰 메뉴바 핸들러 - ars 가 아닌 경우 default 로 좌측에 표출되는 컴포넌트는 주소록임.
  const handleChangeComponent = (componentNm: SoftPhoneComponentType) => {
    if (componentNm === 'ars') {
      setLeftZoneComponentS('ars');
      setListToggleR(true);
    } else {
      setLeftZoneComponentS('address');
      setListToggleR(false);
    }
    setSoftPhoneComponent(componentNm);
  };

  const components: any = {
    send: <TransmissionResult />,
    chatroom: <ChatRoom />,
    // ars: <div>ars사용자</div>,
  };

  /* 소프트폰 탭 값으로 컴포넌트 제어 */
  const singleComponents: any = {
    '0': <POBox015 />,
    '2': needM ? <ArsMockupPhone /> : <ArsPhone />,
    // '2': needM ? <ArsMockupPhone /> : <ChatRoom />,
  };

  return {
    singleComponents,
    softPhoneTopTabState,
    components,
    softPhoneComponent,
    handleChangeComponent,
  };
};
