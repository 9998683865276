import styled from 'styled-components';

type TableBodyType = { tdWidthData: number };

export const TrashBoxWrap = styled.div<{ isActive: boolean }>`
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 768px) {
    z-index: ${(props) => props.isActive && 200};

    td {
      min-width: unset;
    }
  }
`;
/* Beam 뒷배경 */
export const TrashBoxPopupBeam = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
`;

/* Beam 내부에 있는 팝업 껍데기 */
export const TrashBoxPopup = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  width: 60%;
  height: calc(100vh - 40px);
  max-height: 820px;
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid transparent;
  background-image: linear-gradient(
      ${(props) => props.theme.colors.gray300}99,
      ${(props) => props.theme.colors.gray300}99
    ),
    linear-gradient(135deg, ${(props) => props.theme.colors.gray300});
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);

  /* 휴지통 삭제 건수, 닫기 버튼 영역 */
  .trashBoxHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    div {
      &:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.6rem;
        font-weight: 600;
        h6 {
          font-size: 2.4rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors.txt500};
        }
        span {
          font-family: SUIT;
          font-size: 2.4rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: ${(props) => props.theme.colors.txt200};
        }
      }
    }
    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      color: ${(props) => props.theme.colors.txt500};
      background-color: transparent;
    }
  }
  /*  */
  .trashBoxModalWrap {
    .trashBoxFunZone {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 10px;
      div {
        gap: 6px;
      }
      .trashBoxFunBtnGroup {
        display: flex;
        gap: 10px;
        button {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 14px;
          padding: 10px;
          max-height: 36px;
          font-size: 1.4rem;
          font-weight: 600;
          border: none;
          outline: none;
          border-radius: 5px;
          background: transparent;
          color: ${(props) => props.theme.colors.txt100};

          &.allRestoreBtn {
            background: ${(props) => props.theme.colors.grayButton};
          }
          &.selectRestoreBtn {
            background: ${(props) => props.theme.colors.grayButton};
          }

          &.allDeleteBtn {
            background: ${(props) => props.theme.colors.grayButton2};
          }

          &.selectDeleteBtn {
            background: ${(props) => props.theme.colors.grayButton2};
          }
        }
      }
      .trashBoxFunRightZone {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    width: 90%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 0;
  }
`;

/*  */
export const TrashBoxPopupWrap = styled.div`
  padding: 28px;
  height: 100%;
  background: ${(props) => props.theme.colors.gray200};

  @media screen and (max-width: 768px) {
    padding: 12px;
  }
`;

/* Start FunBtnGroup */
export const TrashBoxBody = styled.div`
  height: 100%;
`;

export const TrashBoxFunZone = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  align-items: center;
`;

export const TrashBoxFunRightZone = styled.form`
  width: calc(100% - 46rem);
  max-width: 45rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  @media screen and (max-width: 1024px) {
    width: calc(100% - 12rem);
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
  }
`;

export const TrashBoxFunBtnGroup = styled.div`
  display: flex;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    padding: 1rem;
    max-height: 36px;

    border: none;
    outline: none;
    border-radius: 0.5rem;
    background: transparent;
    cursor: pointer;

    &.allRestoreBtn {
      background: ${(props) => props.theme.colors.grayButton2};
    }
    &.selectRestoreBtn {
      background: ${(props) => props.theme.colors.grayButton2};
    }

    &.allDeleteBtn {
      background: ${(props) => props.theme.colors.grayButton};
    }

    &.selectDeleteBtn {
      background: ${(props) => props.theme.colors.grayButton};
    }
    p {
      font-size: 1.4rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.txt100};
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const TrashBoxResFunBtnGroup = styled.div`
  position: relative;
  display: none;
  button {
    position: relative;
    cursor: pointer;
  }
  .selectBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    padding: 10px;
    min-width: 80px;
    max-height: 36px;
    border: none;
    outline: none;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.grayButton2};
    color: ${(props) => props.theme.colors.txt100};

    cursor: pointer;
    p {
      font-size: 1.4rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.txt100};
    }
  }
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (max-width: 430px) {
    button {
      width: 100%;
    }
  }
`;

export const SelectUl = styled.ul`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;

  z-index: 4;
  width: 200px;
  background: ${(props) => props.theme.colors.gray100};
  border: 2px solid transparent;
  background-image: linear-gradient(
      ${(props) => props.theme.colors.gray300}99,
      ${(props) => props.theme.colors.gray300}99
    ),
    linear-gradient(135deg, ${(props) => props.theme.colors.gray300});
  border-radius: 10px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  li {
    :hover {
      background: ${(props) => props.theme.colors.gray200};
    }
    button {
      cursor: pointer;
      margin: 0 auto;
      padding: 1rem 1.2rem;
      width: calc(100% - 12px);
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > svg {
        width: 2rem;
      }
      p {
        width: calc(100% - 3rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        font-weight: 600;
        color: ${(props) => props.theme.colors.gray800};
      }
    }
  }
  svg {
    fill: ${(props) => props.theme.colors.txt500};
    path {
      fill: ${(props) => props.theme.colors.txt500};
    }
  }
`;

/* End FunBtnGroup */

/* tableWrap */
export const TableWrap = styled.div`
  position: relative;
  max-height: calc(100% - 107px);
  height: 100%;
  overflow: auto;
  /* 스크롤바-배경 */
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    border-radius: 0;
    background: transparent;
  }

  /* 스크롤바-바 */
  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  /* 스크롤바-좌우만나는공간  */
  ::-webkit-scrollbar-corner {
    background: transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-button:start {
    background-color: transparent;
    width: 0px;
    height: 31px;
    background-clip: padding-box;
  }
`;

// 주소록 테이블 start
export const Table = styled.table`
  position: relative;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  white-space: nowrap;
  border-collapse: separate; // td, th간 간격

  /* 공통 체크박스 */

  .fixedBox {
    text-align: center;
    min-width: 40px;
    width: 40px;
    max-width: 40px;
    padding: 0;
    border-radius: 5px 0 0 5px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }
  /* body fixed */
  .fixedBoxLeft {
    z-index: 2;
    position: sticky;
    left: -0.5px;
    border-right-color: transparent;
  }
  .fixedBoxTop {
    position: sticky;
    top: -0.5px;
    border-right-color: transparent;
  }

  tr {
    border-left-color: transparent;
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    min-width: 20rem;
    :last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  th.fixedBoxLeft {
    border-top: 0;
    border-bottom: 0;
  }

  tbody {
    td {
      height: 40px;
    }
  }
  @media screen and (max-width: 768px) {
    .fixedBox {
      min-width: 2rem;
      max-width: 2rem;
    }
    th,
    td {
      min-width: unset;
      text-align: start;
    }
  }
`;

export const TableHead = styled.thead`
  z-index: 3;
  border-radius: 0.5rem;
  font-weight: 600;

  th {
    padding: 0 3rem;
    height: 30px;
    color: ${(props) => props.theme.colors.txt300};
    border-top: 0;
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
    background: ${(props) => props.theme.colors.gray300};
  }

  /* 배열 - 공통 스타일 */

  .arrangeGroup {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrange {
      display: flex;
      flex-direction: column;

      button {
        padding: 2px;
      }
    }
  }

  /* 배열 - 오름차순 */

  th.asc {
    .descBtn {
      path {
        /* c3c3c3 */
        fill: ${(props) => props.theme.colors.gray400};
        stroke: ${(props) => props.theme.colors.gray400};
      }
    }
  }

  /* 배열 - 내림차순 */

  th.desc {
    .ascBtn {
      path {
        /* c3c3c3 */
        fill: ${(props) => props.theme.colors.gray400};
        stroke: ${(props) => props.theme.colors.gray400};
      }
    }
  }

  /* 헤드 체크박스 */

  @media screen and (max-width: 768px) {
    top: -1px;
    th {
      padding: 0 1rem;
    }
  }
`;

export const TableBody = styled.tbody<TableBodyType>`
  position: relative;
  line-height: normal;

  &.skeleton {
    td {
      > div {
        position: relative;
        opacity: 1;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }

  td {
    background: ${(props) => props.theme.colors.white};
    width: ${(props) => `${props.tdWidthData}px`};
    padding: 1rem 2rem;
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    position: relative;
    color: ${(props) => props.theme.colors.txt500};
    font-size: 1.4rem;

    > p {
      width: 100%;
      max-width: 13rem;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 auto;
    }

    > div {
      opacity: 0;
      display: none;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 100%;
      text-align: center;
    }

    &.focus {
      > p {
        opacity: 0;
        display: none;
      }

      > div {
        opacity: 1;
        z-index: 1;
        display: flex;
      }
    }
  }

  /* body 체크박스 */

  tr:has(input[type='checkbox']:checked) {
    td {
      background: ${(props) => props.theme.colors.gray100};
    }

    .fixedBox {
      box-sizing: border-box;
    }
  }

  input[type='text'] {
    border: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    outline-color: ${(props) => props.theme.colors.main};
    padding: 0.8rem 0;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt500};
  }

  tr:has(td.focus) {
    z-index: 1;
  }

  .memo {
    position: relative;

    > div {
      top: 5px;
      transform: translateX(-50%);
      max-width: 96%;
      align-items: flex-start;
    }

    &.focus {
      textarea {
        height: 200px;
      }
    }

    textarea {
      width: 100%;
      padding: 10px;
      font-size: 1.4rem;
      box-sizing: border-box;
      background-color: ${(props) => props.theme.colors.white};
      border: none;
      outline-color: #d6d6dc;
      resize: none;
      font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
      color: ${(props) => props.theme.colors.gray700};
      // overflow 스크롤

      ::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }

      ::-webkit-scrollbar {
        width: 0.4rem;
        background-color: #e5e4e4;
        border-radius: 40px;
      }

      ::-webkit-scrollbar-thumb {
        background: #98999a;
        border-radius: 40px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    td {
      padding: 0 1rem;
    }
  }
`;
// 주소록 테이블 end

/* Start Select */
export const SelectWrapper = styled.div`
  // 셀렉트 박스
  position: relative;
  width: 112px;
  height: 30px;
  @media screen and (max-width: 1024px) {
    width: 90px;
  }
  &.active {
    > button {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  > button {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    font-weight: 600;
    font-size: 1.2rem;
    border: 1px solid #d6d6dc;
    border-radius: 15px;
    color: ${(props) => props.theme.colors.txt500};
    > svg {
      position: relative;
      right: -5px;
    }
  }

  /* 자식 태그 포괄적으로 CSS 적용 */
  button {
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.colors.white};
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
  }
  &.active {
    > button {
      border-color: #adc1f4;
      border-bottom-color: #d6d6dc;
      border-radius: 15px 15px 0 0;
      box-shadow: none;
    }
    ul {
      border: 1px solid #adc1f4;
      border-top: none;
    }
  }
`;

export const SelectOptions = styled.ul`
  // 셀렉트 옵션 박스
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 1;
  width: 100%;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.03) 5px 5px 10px 0px;
  box-sizing: border-box;
  background-color: rgb(244, 244, 247);
  overflow: hidden;
  li {
    height: 30px;
    button {
      padding: 0 12px;
      font-weight: 500;
      font-size: 1.2rem;
      border-radius: 0;
      box-shadow: none;
      border: none;
      text-align: left;
      color: ${(props) => props.theme.colors.txt500};
    }
    &:hover {
      button {
        position: relative;
        background-color: #f6f7fe;
        font-weight: 600;
      }
    }
  }
`;
/* End Select */

// 검색 input Wrap
export const InputBox = styled.div`
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 240px;
  height: 30px;
  border-radius: 50px;
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    width: 180px;
  }
  button {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: none;
    border: none;
    border-radius: 0;
  }
`;

// 검색 input
export const SearchInput = styled.input`
  width: 200px;
  transform-origin: right center;
  background-color: ${(props) => props.theme.colors.white};
  outline: none;
  border: none;
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  &::placeholder {
    font-size: 1.2rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.placeholder};
  }
`;
