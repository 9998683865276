import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContainerX = styled.div`
  // 전체 와이드 max-
  // max-width: 1920px;
  width: 100%;
`;

export const GobackHistoryWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 10px 0;
  & > div {
    // 자식 div에 공통 css 속성 부여
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 1440px) {
    padding-right: 1%;
  }
  @media screen and (max-width: 480px) {
    gap: 8px;
    padding: 0 2% 10px 2%;
  }
  h1 {
    width: 290px;
    font-size: 2.5rem;
    font-weight: 600;
    font-family: 'Jura';
    text-align: center;
    @media screen and (max-width: 1800px) {
      // 반응형
      width: 20%;
    }
    @media screen and (max-width: 768px) {
      // 반응형
      font-size: 1.8rem;
    }
  }
`;

export const AddAdressDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SearchWrap = styled.div``;
export const AddressSearchWrap = styled.div``;
// 중복정리 박스
export const AddressDuplicateCheckWrap = styled.div``;

export const AddressHeadGroup = styled.div`
  // 그룹추가, 그룹삭제, 주소록추가, 주소록삭제 박스 + 중복정리,보여질,이름,검색란 박스
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 290px);
  @media screen and (max-width: 1800px) {
    width: 80%;
  }
  @media screen and (max-width: 1024px) {
    justify-content: flex-end;
  }
`;
export const AddAddressbook = styled.div`
  // 그룹추가, 그룹삭제, 주소록추가, 주소록삭제 박스
  display: flex;
  align-items: center;
  gap: 6px;
  /* 반응형 Tablet 대응 1024px ~ */
  @media screen and (max-width: 1024px) {
    display: none;
  }
  button {
    padding: 0;
    width: 80px;
    height: 30px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
    color: #191919;
    @media screen and (max-width: 1800px) {
      width: 60px;
      font-size: 1rem;
    }
    @media screen and (max-width: 1540px) {
      width: 62px;
      font-size: 1rem;
    }
  }
`;

export const AddressCopy = styled.div`
  // 주소록 복사
  position: relative;
`;
export const AddressCopySelectWrapper = styled.div`
  // 주소록 복사 select
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 2;
  width: max-content;
  border: 1px solid #adc1f4;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  p {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 30px;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: #fff;
    color: #191919;
  }
`;
export const AddressCopySelectOptions = styled.ul`
  // 주소록 복사 옵션 박스
  max-height: 122px;
  border-top: 1px solid #d6d6dc;
  border-bottom: 1px solid #d6d6dc;
  overflow-y: auto;
  // overflow 스크롤
  ::-webkit-scrollbar {
    width: 0.4rem;
    background-color: #d6d6dc;
    border-radius: 40px;
  }
  ::-webkit-scrollbar-thumb {
    background: #98999a;
    border-radius: 40px;
  }
  li {
    border-radius: 0;
    background-color: #fff;
    &.active {
      button {
        font-weight: 600;
        background-color: #f6f7fe;
        color: #191919;
      }
    }
    button {
      padding: 0 15px;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: normal;
      border-radius: 0;
      text-align: left;
      color: #191919;
      cursor: pointer;
    }
  }
`;
export const AddressCopySelectBtns = styled.div`
  // 주소록 복사 select 확인 취소 버튼
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 5px 0;
  background-color: #fff;
  button {
    width: 40px;
    height: 25px;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 5px;
    &:first-of-type {
      border: 1px solid ${(props) => props.theme.colors.main};
      color: ${(props) => props.theme.colors.main};
    }
    &:last-of-type {
      border: 1px solid #98999a;
      color: #98999a;
    }
  }
`;

/* Start 주소록 추가 */
export const SelectWrapper = styled.div`
  // 셀렉트 박스
  position: relative;
  height: 3rem;
  @media screen and (max-width: 1800px) {
    width: 9rem;
  }
  &.active {
    > button {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  > button {
    position: relative;
    /* z-index:10; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    font-weight: 600;
    font-size: 1.2rem;
    border: 1px solid transparent;
    border-radius: 1.5rem;
    color: #191919;
    > svg {
      position: relative;
      right: -5px;
      @media screen and (max-width: 1800px) {
        right: 0;
      }
    }
  }
  /* 자식 태그 포괄적으로 CSS 적용 */
  button {
    width: 100%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    @media screen and (max-width: 1800px) {
      padding: 0 10px;
      font-size: 1.1rem;
    }
    @media screen and (max-width: 1800px) {
      padding: 0 8px;
      font-size: 1rem;
    }
  }
  &.active {
    > button {
      border-color: #adc1f4;
      border-bottom-color: #d6d6dc;
      /* d6d6dc */
      border-radius: 1.5rem 1.5rem 0 0;
      box-shadow: none;
    }
    ul {
      border: 1px solid #adc1f4;
      border-top: none;
    }
  }
`;

export const SelectOptions = styled.ul`
  // 셀렉트 옵션 박스
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 2;
  width: 100%;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.03) 5px 5px 10px 0px;
  box-sizing: border-box;
  background-color: rgb(244, 244, 247);
  overflow: hidden;
  li {
    height: 30px;
    button {
      padding: 0 12px;
      font-weight: 500;
      font-size: 1.2rem;
      border-radius: 0;
      box-shadow: none;
      border: none;
      text-align: left;
      color: #191919;
      @media screen and (max-width: 1800px) {
        padding: 0 10px;
        font-size: 1.1rem;
      }

      @media screen and (max-width: 1540px) {
        padding: 0 8px;
        font-size: 1rem;
      }
    }
    &:hover {
      button {
        position: relative;
        background-color: #f6f7fe;
        font-weight: 600;
      }
    }
  }
`;
/* End 주소록 추가  */

export const AddressFunBtnBox = styled.div`
  // 중복정리,보여질,이름,검색란 박스
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px; */
  max-width: 440px;
  width: calc(100% - 340px);

  > button {
    padding: 0 8px;
    height: 30px;
    font-weight: 600;
    border-radius: 50px;
    background: #fff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
    color: #191919;
    &.addressItemBtn {
      // 보여질 항목 선택 버튼
      width: 124px;
      font-size: 1.2rem;
      @media screen and (max-width: 1800px) {
        width: 90px;
        font-size: 1rem;
      }
      @media screen and (max-width: 1540px) {
        width: 90px;
        font-size: 1rem;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  .DuplicateCheckZone {
    // 중복정리 버튼
    .DuplicateCheckBtn {
      width: 102px;
      height: 30px;
      font-weight: 600;
      border-radius: 50px;
      background: #fff;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
      color: #191919;
      @media screen and (max-width: 1800px) {
        width: 60px;
        font-size: 1rem;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  .addressSearchBox {
    // Select / 검색 영역
    select {
      width: 96px;
    }
  }
`;

export const AddressTableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  .SoftPhoneContainer {
    .SoftPhoneInner {
      transition: 140ms;
      transition-delay: 240ms;
      position: sticky;
      top: 0;
    }
  }
  /* &.chatOn {
    .SoftPhoneContainer {
      .SoftPhoneInner {
        padding: 7px;
        transition: 100ms;
        border-left: transparent;
        border-radius: 0 4rem 4rem 0;
        background: linear-gradient(90deg, transparent 0%, #f2f4f6 10%);
        @media screen and (max-width: 1440px) {
          padding: 7px;
          background: initial;
        }
      }
    }
  } */
  /* 왼쪽 공통 */
  .tableWrap {
    position: relative;
    width: calc(100% - 52rem);
    display: flex;
    flex-direction: column;
    &:has(.mockup) {
      background: ${(props) => props.theme.colors.gray300};
    }
    @media screen and (max-width: 1920px) {
      // 반응형
      width: calc(100% - 45rem);
    }
    @media screen and (max-width: 1440px) {
      width: 100%;
      /* padding: 0 5%; */
    }
  }

  @media screen and (max-width: 1440px) {
    flex-wrap: wrap;
    /* &.chatOn {
      .SoftPhoneContainer {
        .SoftPhoneInner {
          border-left: 3px solid #d6d6dc;
          border-radius: 4rem;
        }
      }
    } */
  }
`;

export const AddressLeftZone = styled.div`
  // 왼쪽 체크박스 / 테이블 박스
  width: 100%;
`;
export const AddressLeftMiddle = styled.div`
  // 테이블 영역
  display: flex;
  justify-content: space-between;
  /* gap:10px; */
  min-height: calc(100vh - 60px);
  max-height: 100%;
`;

export const ResLeftBtnZone = styled.div`
  // 반응형 1024 부터 나올 주소록 좌측 버튼들
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    margin-top: 90px;
    width: 54px;
    min-width: 54px;
    border-top: 1px solid #adc1f4;
    border-right: 1px solid #adc1f4;
    border-radius: 0 5px 0px 0px;
    > div {
      display: flex;
      flex-direction: column;
      > button {
        width: initial;
        min-height: 74px;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: normal;
        border-bottom: 1px solid #adc1f4;
        word-break: keep-all;
        background-color: transparent;
        color: #191919;
        @media screen and (max-height: 700px) {
          min-height: 54px;
        }
      }
    }
  }
`;
/* 기본 버튼 영역들 */
export const ResContentNormal = styled.div``;
export const ResContentLarge = styled.div``;
export const IsLogin = styled.div``;
export const NotLogin = styled.div`
  font-size: 6rem;
  color: #adb5bd;
  width: 100%;
  height: calc(100vh - 280px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 40px;
  }
`;

export const EtcSaveMessageComponent = styled.div`
  position: absolute;
  top: 0;
  z-index: 5;
  right: 0;
  width: 380px;
  max-width: 380px;
  height: 100vh;
  border-left: 1px solid #ececec;
  overflow: hidden;
  background: ${(props) => props.theme.colors.gray100};
  box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.05);

  &.etcStart {
    transform: translateX(50%);
    opacity: 0;
    pointer-events: none;
    transition-duration: 0.6s;
    transition-property: transform, opacity;
  }

  &.etcEnd {
    transform: translateX(0);
    opacity: 1;
    pointer-events: visible;
    transition-duration: 0.85s;
    transition-property: transform, opacity;
  }
  @media screen and (max-width: 1440px) {
    z-index: 101;
    position: fixed;
    right: 0;
  }
  @media screen and (max-width: 768px) {
    top: 0;
    right: 0;
    width: 100%;
    max-width: unset;
  }
`;
