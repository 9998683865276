import React from 'react';
import styled from 'styled-components';

const Arrow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  span {
    display: block;
    background: ${(props) => props.theme.colors.gray500};
    :nth-of-type(1) {
      width: 4.2rem;
      height: 0.2rem;
    }
    :nth-of-type(2) {
      width: 0.2rem;
      height: 1.2rem;
    }
  }
`;

/**
 * @title ARS 위젯에서 '인사말 설정', '녹음 재생', '텍스트 재생' 일 경우 우측에 있는 화살표
 *
 * */
const ArrowIcon = () => {
  return (
    <Arrow className="arrowIcon">
      <span />
      <span />
    </Arrow>
  );
};

export default ArrowIcon;
