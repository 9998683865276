import { usePointHistory } from '@/components/hooks/customs/charge/usePointHistory';
import * as S from './MobileEtcUsage.style';
import React from 'react';
import ATitle from '@/components/Atom/ATitle';
import ChargeDateSearch from '@/components/Molecules/Charge/ChargeDateSearch';
import BaseTable from '@/components/Organism/Table';
import Loader from '@/components/common/Loader';
import Paginations01Index from '@/components/common/paginations/Pagination02/Pagination02.index';
import BaseButton from '@/components/Atom/BaseButton';

const MobileEtcUsage = () => {
  const {
    thead,
    totalPage,
    handlePageChange,
    activePage,
    setActivePage,
    startPage,
    setStartPage,
    currentPage,
    pointHistoryData,
    selectedValue,
    disabledDate,
    setSearchSelectValue,
    setSearchDates,
    setCurrentPage,
    isSuccess,
    excelDownload,
    isLoading,
  } = usePointHistory();

  return (
    <S.UsageHistory>
      <ATitle type="sub" text="사용내역" />
      <ChargeDateSearch
        chargeOptionsProp={selectedValue}
        name1="사용내역"
        disabledDate={disabledDate}
        setSearchSelectValue={setSearchSelectValue}
        setSearchDates={setSearchDates}
        setStartPage={setStartPage}
        setActivePage={setActivePage}
        setCurrentPage={setCurrentPage}
      />
      <div className="table">
        <BaseTable name="use" type="line" thead={thead} tbody={pointHistoryData} onclick={(e: any) => {}} />
        {/* {isSuccess || (isLoading && <Loader />)} */}
        {isLoading && <Loader backgroundToggle />}
      </div>
      <S.TableFoot>
        <Paginations01Index
          dataCount={totalPage}
          startPage={startPage}
          setStartPage={setStartPage}
          activePage={activePage}
          setActivePage={setActivePage}
          eventHook={handlePageChange}
          pageSize={10}
        />

        <BaseButton width="10rem" fontSize="1.4rem" padding=".6rem 1rem" fontWeight="bold" onClick={excelDownload}>
          엑셀 다운
        </BaseButton>
      </S.TableFoot>
      <p style={{ fontSize: '1.2rem' }}>사용내역은 전월, 당월만 검색이 가능하며 이전 자료는 삭제됩니다. </p>
    </S.UsageHistory>
  );
};
export default MobileEtcUsage;
