import ATitle from '@/components/Atom/ATitle';
import BaseCheckBox from '@/components/Atom/BaseCheckBox';
import BaseInput from '@/components/Atom/BaseInput';
import ButtonList from '@/components/common/buttons/ButtonList';
import Loader from '@/components/common/Loader';
import { useMemberLeave } from '@/components/hooks/customs/myPage/useMemberLeave';
import React from 'react';
import { AiFillEye } from 'react-icons/ai';
import { RiEyeOffFill } from 'react-icons/ri';
import * as S from './MobileEtcMemberLeave.style';
import * as SS from '../MobileEtc.style';

const MobileEtcMemberLeave = () => {
  const {
    isLoading,
    getUseInfo,
    checkedAgreeHandle,
    agreeItem,
    visible,
    register,
    errors,
    originPwHandleVisible,
    buttonList,
  } = useMemberLeave();

  return (
    <S.MobileEtcMemberLeaveContainer className="mobileEtcMemberLeave">
      <SS.AWrapper>
        {window.innerWidth > 768 ? (
          <SS.TitleWrapper className="titleWrap">
            <ATitle type="sub" text="회원탈퇴" />
          </SS.TitleWrapper>
        ) : null}
        <SS.LiStyleWrapper className="disc">
          <h3>회원탈퇴 신청에 앞서 아래의 사항을 반드시 확인하시기 바랍니다.</h3>
          <li>
            회원탈퇴 신청을 통해 아이디를 해지하시면 해당 아이디는 즉시 탈퇴 처리됩니다. 또한 회원님의 캐쉬/포인트,
            주소록, 기타 서비스 이용내역이 모두 자동 삭제되고, 서울이동통신의 모든 서비스를 이용하실 수 없게 됩니다.
          </li>
          <li>이용약관에 따라 탈퇴 후 1개월 동안 동일한 번호로 재가입이 불가능하오니 이점 양지하시기 바랍니다.</li>
        </SS.LiStyleWrapper>
      </SS.AWrapper>
      <SS.Wrapper className="basicTable">
        <SS.TitleWrapper className="titleWrap">
          <ATitle type="sub" text="기본정보" />
        </SS.TitleWrapper>
        {isLoading ? (
          <Loader backgroundToggle />
        ) : (
          <SS.MyPageTable>
            <tbody>
              <tr>
                <th>이름</th>
                <td>
                  <div className="automatic">{getUseInfo?.usrNm}</div>
                </td>
              </tr>
              <tr>
                <th>아이디</th>
                <td>
                  <div className="automatic">{getUseInfo?.phnId}</div>
                </td>
              </tr>
              <tr>
                <th>잔여캐쉬</th>
                <td>
                  <div className="flexBox gap10 automatic">
                    <p>{getUseInfo?.usePg} ⓒ</p>
                    <p>{getUseInfo?.useCc} ⓟ</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>주소록</th>
                <td>
                  <div className="automatic">{getUseInfo?.buddyCnt}명</div>
                </td>
              </tr>
            </tbody>
          </SS.MyPageTable>
        )}
      </SS.Wrapper>
      <SS.Wrapper>
        <SS.TitleWrapper className="titleWrap">
          <ATitle type="sub" text="동의항목" />
        </SS.TitleWrapper>
        <SS.LiStyleWrapper className="decimal" type="1">
          <li>포인트 환불 불가</li>
          <li>주소록에 보관중인 모든 주소 데이터 자동 삭제 및 공유중인 주소록 업데이트 중단</li>
          <li>신청한 015번호 관련 서비스 이용 불가 및 문자/팩스/음성보관함 보관함에 저장중인 모든 데이터 삭제</li>
          <li>이메일 계정 및 보관중인 메일 데이터 삭제</li>
          <li>015 사서함 이용 제한</li>
        </SS.LiStyleWrapper>
        <SS.AgreeCheckBoxWrapper>
          <BaseCheckBox
            className="roundCheck"
            name="agree"
            label="위의 내용을 숙지하였으며, 5가지 항목에 대해 모두 동의합니다."
            onChange={(e) => checkedAgreeHandle(e)}
            checked={agreeItem}
          />
        </SS.AgreeCheckBoxWrapper>
      </SS.Wrapper>
      <SS.Wrapper className="basicTable">
        <SS.LiStyleWrapper>
          <p>회원탈퇴 신청을 진행하기에 앞서 패스워드 인증을 확인합니다.회원탈퇴 신청을 하시겠습니까?</p>
        </SS.LiStyleWrapper>
        <SS.MyPageTable>
          <tbody>
            <tr>
              <th>비밀번호 확인</th>
              <td>
                <div className="flexAc">
                  <BaseInput
                    type={visible ? 'text' : 'password'}
                    {...register('originPw', {
                      required: true,
                    })}
                    border={errors.originPw ? '1px solid red' : '0px solid'}
                  />
                  {visible ? (
                    <div style={{ marginLeft: '1rem', fontSize: '2.2rem' }}>
                      <AiFillEye onClick={originPwHandleVisible} />
                    </div>
                  ) : (
                    <div style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <RiEyeOffFill onClick={originPwHandleVisible} />
                    </div>
                  )}
                </div>
                <span className="inputErrorMessage">{errors.originPw?.message}</span>
              </td>
            </tr>
          </tbody>
        </SS.MyPageTable>
      </SS.Wrapper>
      <SS.ButtonWrapper>
        <ButtonList buttonList={buttonList} />
      </SS.ButtonWrapper>
    </S.MobileEtcMemberLeaveContainer>
  );
};
export default MobileEtcMemberLeave;
