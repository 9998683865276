import Loader from '@/components/common/Loader';
import { useAddressBookTable } from '@/components/hooks/customs/useAddressBookTable';
import * as CBS from '@/styles/checkBox.styles';
import React from 'react';
import * as S from '../TrashBoxPopup.styles';
import { ITrashBoxTable } from './TrashBoxTable.types';

/**
 * @title TrashBoxTable.tsx 휴지통 팝업 테이블 리스트 컴포넌트
 *
 * @author 김남규
 * @since 2024.03.14
 * */
const TrashBoxTable = (props: ITrashBoxTable): React.JSX.Element => {
  /* 메인 주소록 기본 기능 hook */
  const hooks = useAddressBookTable();

  return (
    <S.TableWrap onScroll={props.handleScroll}>
      {/* Start TableHead Zone */}
      <S.Table>
        <S.TableHead className="fixedBox fixedBoxTop">
          <tr>
            <th className="fixedBox fixedBoxLeft">
              {/* <CBS.CheckBoxInput type="checkBox" onChange={props.toggleSelectAll} checked={props.binSelectAll} /> */}
            </th>
            <th>
              <p>그룹</p>
            </th>
            <th>
              <p>이름</p>
            </th>
            <th>
              <p>휴대폰 번호</p>
            </th>
            {window.innerWidth > 768 && (
              <>
                <th>
                  <p>이메일</p>
                </th>
                <th>
                  <p>메모</p>
                </th>
              </>
            )}
          </tr>
        </S.TableHead>
        {/* End TableHead Zone */}

        {/* Start TableBody Zone */}
        <S.TableBody tdWidthData={hooks.tableTdWidth}>
          {props.recycleBinListLoading ? (
            <tr>
              <td>
                <Loader backgroundToggle />
              </td>
            </tr>
          ) : (
            props.recycleBinList &&
            props.recycleBinList?.pages?.map((page: any) => {
              return (
                <React.Fragment key={page.pageable.pageNumber}>
                  {page?.content?.map((item: any) => {
                    return (
                      <tr key={item.buddySeqNo} className="tableBodyList">
                        <td className="fixedBox fixedBoxLeft">
                          {/* <AddressCheckBox
                            onChange={() => {
                              props.checkedBinListHandle(item.buddySeqNo);
                            }}
                            checked={props.checkedBinList.includes(item.buddySeqNo)}
                          /> */}
                          <CBS.CheckBoxInput
                            type="checkBox"
                            onChange={() => {
                              props.checkedBinListHandle(item.buddySeqNo);
                            }}
                            checked={props.checkedBinList.includes(item.buddySeqNo)}
                          />
                        </td>
                        <td>
                          <p>{item.groupNm}</p>
                        </td>
                        <td>
                          <p>{item.buddyNm}</p>
                        </td>
                        <td>
                          <p>{item.keyCommNo}</p>
                        </td>
                        {window.innerWidth > 768 && (
                          <>
                            <td>
                              <p>{item.emailId}</p>
                            </td>
                            <td>
                              <p>{item.etcInfo}</p>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })
          )}
        </S.TableBody>
      </S.Table>

      {/* End TableBody Zone */}
    </S.TableWrap>
  );
};

export default TrashBoxTable;
