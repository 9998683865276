import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconAddAddressGroup: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M8 14C8 11.172 8 9.757 8.879 8.879C9.757 8 11.172 8 14 8H15C17.828 8 19.243 8 20.121 8.879C21 9.757 21 11.172 21 14V15C21 17.828 21 19.243 20.121 20.121C19.243 21 17.828 21 15 21H14C11.172 21 9.757 21 8.879 20.121C8 19.243 8 17.828 8 15V14Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8C15.997 5.043 15.953 3.511 15.092 2.462C14.9259 2.25965 14.7404 2.07409 14.538 1.908C13.43 1 11.788 1 8.5 1C5.213 1 3.569 1 2.462 1.908C2.25965 2.07409 2.07409 2.25965 1.908 2.462C1 3.57 1 5.212 1 8.5C1 11.787 1 13.431 1.908 14.538C2.07409 14.7404 2.25965 14.9259 2.462 15.092C3.512 15.952 5.042 15.998 8 16M17 14.5H12M14.5 12V17"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconAddAddressGroup;
