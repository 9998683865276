import { usePOBox015 } from '@/components/hooks/customs/say015/pobox015/usePOBox015';
import SignUp015Page from '@/components/softPhone/DisplayGroups/POBox015/SignUp015Page/SignUp015Page';
import Sub015Page from '@/components/softPhone/DisplayGroups/POBox015/Sub015Page/Sub015Page';
import { ReSubscription } from '@/components/softPhone/DisplayGroups/ReSubscription/ReSubscription.styles';
import SelectNumber from '@/components/softPhone/DisplayGroups/ServiceSignUp/SelectNumber/SelectNumber';
import { instance } from '@/shared/lib/clientAxios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Mobile015SignupComponent from './MobileSignupComponent';

export const Container = styled.section`
  width: 100%;
  height: 100dvh;
`;

interface IAuthorityData {
  availabilityStatus: boolean;
  monthlyPrice: number;
  number015: string;
  say015User: boolean;
  subsEndDate: string;
  subscriptionStatus: boolean;
}

/**
 * @title 015 미가입시 015 가입 페이지
 *
 */
const Mobile015Signup = () => {
  const { pageState, authorityData, authorityDataIsLoading, authorityDataRefetch } = usePOBox015();
  const storageUser = localStorage.getItem('user'); // 현재 로그인중인 user
  const [authorityDataState, setAuthorityDataState] = useState<IAuthorityData | null>(null);

  useEffect(() => {
    if (authorityData) {
      const initailAuthorityData = {
        availabilityStatus: authorityData?.availabilityStatus,
        monthlyPrice: authorityData?.monthlyPrice,
        number015: authorityData?.number015,
        say015User: authorityData?.say015User,
        subsEndDate: authorityData?.subsEndDate,
        subscriptionStatus: authorityData?.subscriptionStatus,
      };
      setAuthorityDataState(initailAuthorityData);
    }
  }, [authorityData]);

  return (
    <Container className="015singup">
      <Mobile015SignupComponent authorityDataRefetch={authorityDataRefetch} authorityDataState={authorityDataState} />
    </Container>
  );
};

export default Mobile015Signup;
