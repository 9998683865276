import DatePickerDateTime from '@/components/common/date/DatePickerDateTime';
import { useSchedule } from '@/components/hooks/customs/sendResult/sendReservation/useSchedule';
import { useScheduleTable } from '@/components/hooks/customs/sendResult/sendReservation/useScheduleTable';
import { useCloseResult } from '@/components/hooks/customs/useCloseResult';
import { ENQUIRY_PHONE } from '@/shared/constants';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import React from 'react';
import * as IS from '@/styles/input.styles';
import * as S from './ScheduleDetailBox.styles';
import * as SS from '../sendRoom.styles';
import * as IMS from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/ChatRoom015Body.styled';
import NoData from '@/components/common/NoData';
import { useChatRoom015Image } from '@/components/hooks/customs/chatRoom015/useChatRoom015Image';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';

const ScheduleDetailBox = () => {
  const scheduleHooks = useSchedule();
  const closeHooks = useCloseResult();
  const scheduleTableHooks = useScheduleTable({
    pageSize: scheduleHooks.pageSize,
    tableData: scheduleHooks.tableData,
    datePicker: scheduleHooks.datePicker,
    setDatePicker: scheduleHooks.setDatePicker,
    sndMsg: scheduleHooks.sndMsg,
    subject: scheduleHooks.subject,
    msgGb: scheduleHooks.msgGb,
    detailInfo: scheduleHooks.detailInfo,
    setSearchAllValue: scheduleHooks.setSearchAllValue,
    scheduleDetailParamsState: scheduleHooks.scheduleDetailParamsState,
  });
  const imageHooks = useChatRoom015Image();

  return (
    // ScheduleDetailBox - 전송예약
    <S.ScheduleDetail className="scheduleDetail">
      <SS.SendRoomWrap>
        <div className="topWrap">
          <button className="closeBtn" onClick={() => closeHooks.onClickCloseBtn()}>
            <IconArrowLongLeft />
          </button>
          <div className="sendNum">
            <p>{formatPhoneNumber(scheduleHooks.detailInfo?.callback) ?? ENQUIRY_PHONE}</p>
          </div>
        </div>
        <SS.MessageContent>
          <div className="messageGroup">
            {/* 제목 */}
            {(scheduleHooks.msgGb === 'LMS' || scheduleHooks.msgGb === 'MMS') && (
              <input
                className="title"
                type="text"
                value={scheduleHooks.subject}
                onChange={(e) => scheduleHooks.setSubject(e.currentTarget.value)}
                placeholder="제목없음"
              />
            )}
            {/* 이미지 */}
            {scheduleHooks.detailInfo && scheduleHooks.detailInfo.imageData && (
              <div className="imgWrap">
                <img
                  src={`data:image/jpeg;base64,${scheduleHooks.detailInfo.imageData}`}
                  alt="이미지"
                  onClick={() => imageHooks.closeUpImgHook(scheduleHooks.detailInfo.imageData)}
                  onError={imageHooks.handleImgError}
                />
              </div>
            )}
            {/* 내용 */}
            <textarea
              className="messageTxt"
              value={scheduleHooks.sndMsg ?? ''}
              onChange={(e) => scheduleHooks.setSndMsg(e.target.value)}
            />
          </div>
          <div className="stateWrap">
            <div className="date">
              <DatePickerDateTime
                onChange={(e: any, dateString: any) => scheduleTableHooks.onChangeDate(dateString)}
                sndDttm={scheduleHooks.datePicker ? scheduleHooks.datePicker : '20240101000000'}
              />
            </div>
            <div className="byte">
              <p>
                <span className="sndMsgState">{scheduleHooks.detailInfo?.msgGb ?? 'SMS'}</span>{' '}
                <span className="count">{calMsgByte(scheduleHooks.sndMsg ?? '0')}</span> <small>Byte</small>
              </p>
            </div>
          </div>
        </SS.MessageContent>
        <SS.SendEdit>
          <button
            className={`editBtn ${scheduleTableHooks.editBtnS ? 'active' : ''}`}
            type="button"
            onClick={
              scheduleHooks.tableData.length > 0 && !scheduleTableHooks.isUpdateLoading
                ? () => scheduleTableHooks.onClickUpdateMsg(scheduleHooks.msgGb, scheduleHooks.detailInfo)
                : () => {}
            }
            disabled={!scheduleTableHooks.editBtnS}
          >
            수정하기
          </button>
        </SS.SendEdit>
        <form onSubmit={scheduleTableHooks.onClickSearch}>
          <IS.InputBox className="searchInput">
            <input
              type="text"
              placeholder="검색"
              value={scheduleTableHooks.searchInputState}
              onChange={(e: any) => scheduleTableHooks.handleSearchInput(e.currentTarget.value)}
            />
            <button>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path
                  d="M12.2346 11.9104L15.3457 15.0215M1.3457 7.24371C1.3457 8.89394 2.00126 10.4766 3.16815 11.6435C4.33504 12.8104 5.91769 13.4659 7.56793 13.4659C9.21816 13.4659 10.8008 12.8104 11.9677 11.6435C13.1346 10.4766 13.7901 8.89394 13.7901 7.24371C13.7901 5.59347 13.1346 4.01082 11.9677 2.84393C10.8008 1.67704 9.21816 1.02148 7.56793 1.02148C5.91769 1.02148 4.33504 1.67704 3.16815 2.84393C2.00126 4.01082 1.3457 5.59347 1.3457 7.24371Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </IS.InputBox>
        </form>
        <SS.SendResultWrap className="sendResultWrap" onScroll={(e) => scheduleHooks.handleScroll(e)}>
          {scheduleHooks.tableData && scheduleHooks.tableData[0]?.cmpMsgId ? (
            <ol>
              {scheduleHooks.tableData.map((item: any) => {
                return (
                  <li key={item?.cmpMsgId}>
                    <h4>{item.buddyNm ? item.buddyNm : item.phoneNumber}</h4>
                  </li>
                );
              })}
            </ol>
          ) : (
            <NoData />
          )}
        </SS.SendResultWrap>
        <SS.SendEdit className="marT20">
          <button
            className="delBtn"
            type="button"
            onClick={() =>
              scheduleTableHooks.onDelete(
                {
                  deleteRequests: [
                    {
                      prepayPayNo: scheduleHooks.scheduleDetailParamsState.prepayPayNo,
                      callback: scheduleHooks.scheduleDetailParamsState.callback,
                    },
                  ],
                },
                scheduleHooks.datePicker,
              )
            }
          >
            전송 예약 삭제하기
          </button>
        </SS.SendEdit>
      </SS.SendRoomWrap>
      {/* 이미지 확대 */}
      {imageHooks.modalSwitch ? (
        <IMS.EtcChattingBigImageModal onClick={imageHooks.autoClosePopup}>
          <IMS.EtcChattingBigImageWrap id={'closeUpView'} ref={imageHooks.closeUpViewRef}>
            <IMS.EtcChattingBigImage src={`data:image/jpeg;base64,${imageHooks.closeUpImg}`} alt="testimage" />
            <IMS.EtcChattingBigImageModalcloseBtn type="button" onClick={imageHooks.closeUpImgPopupClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                <path
                  opacity="0.9"
                  d="M4.54078 29.389C4.1518 29.778 3.62424 29.9966 3.07414 29.9966C2.52404 29.9966 1.99648 29.778 1.6075 29.389C1.21853 29 1 28.4724 1 27.9223C1 27.3722 1.21853 26.8446 1.6075 26.4556L12.5659 15.5L1.61095 4.54098C1.22198 4.15198 1.00345 3.62439 1.00345 3.07426C1.00345 2.52413 1.22198 1.99654 1.61095 1.60754C1.99993 1.21854 2.52749 0.999999 3.07759 0.999999C3.62769 0.999999 4.15525 1.21854 4.54423 1.60754L15.4991 12.5666L26.4575 1.61099C26.8465 1.22199 27.374 1.00345 27.9241 1.00345C28.4742 1.00345 29.0018 1.22199 29.3908 1.61099C29.7798 1.99999 29.9983 2.52758 29.9983 3.07771C29.9983 3.62784 29.7797 4.15544 29.3908 4.54444L18.4324 15.5L29.3925 26.459C29.7815 26.848 30 27.3756 30 27.9257C30 28.4759 29.7815 29.0035 29.3925 29.3925C29.0035 29.7815 28.476 30 27.9259 30C27.3758 30 26.8482 29.7815 26.4592 29.3925L15.4991 18.4335L4.54078 29.389Z"
                  fill="white"
                />
              </svg>
            </IMS.EtcChattingBigImageModalcloseBtn>
          </IMS.EtcChattingBigImageWrap>
        </IMS.EtcChattingBigImageModal>
      ) : null}
    </S.ScheduleDetail>
  );
};
export default ScheduleDetailBox;
