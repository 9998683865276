import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { skeletonLoadingRecoil } from '@/recoil/atoms/atoms';

const SkeletonWrap = styled.div`
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  text-align: center;

  ::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }

  .box {
    border-radius: 5px;
    padding: 20px 24px;
    box-sizing: border-box;
    background-color: #fff;
    width: 416px;
    height: 182px;

    vertical-align: middle;
    display: inline-block;
    margin: 20px auto;
  }

  li {
    margin-bottom: 5px;
  }

  .ant-skeleton {
    display: table;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;

    > div {
      width: 40px;

      .ant-skeleton-button {
        width: 100%;
        min-width: unset;
      }
    }
  }
`;

/**
 * @title 스켈레톤 UI 팝업창  컴포넌트 ( 조합해서 새로운 케이스 만들어서 사용가능 )
 *
 * @author 정휘학
 * @since 2024-05-14
 */
const SkeletonPopup = ({ type, ...rest }: any) => {
  const skeletonLoading = useRecoilValue(skeletonLoadingRecoil);
  /* 노출 전후 boolean STATE */
  const [timer, setTimer] = useState<boolean>(false);
  const handleTimer = () => {
    // setTimeout(() => {
    setTimer(false);
    // }, 500);
  };
  // 렌더링시 Loader 강제로 2초간 표출할 데이터
  useEffect(() => {
    if (skeletonLoading) setTimer(true);
    if (!skeletonLoading) handleTimer();
  }, [skeletonLoading]);

  return skeletonLoading || timer ? (
    <SkeletonWrap className={'skeletonWrap'}>
      <ul className={'box'}>
        <li className={'top'}>
          <Skeleton.Button {...rest} />
        </li>
        <li>
          <Skeleton {...rest} />
        </li>
        <li className={'buttonGroup'}>
          <Skeleton.Button {...rest} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Skeleton.Button {...rest} />
        </li>
      </ul>
    </SkeletonWrap>
  ) : null;
};

export default SkeletonPopup;
