import React from 'react';
import { fetchMutation } from '@/components/hooks/mutations/fetchMutation';
import { useQueryClient } from '@tanstack/react-query';
import { IArsUpdateMutation } from '@/features/ars/arsScenario/ui/ArsScenario.types';

interface IUseScenarioAPI {
  // 015 serviceStatus api refetch set boolean
  setRefetchS: React.Dispatch<React.SetStateAction<boolean>>;
  // 업데이트될 ARS 시나리오 파일 이름 set state
  setUpdatedArsFileNameS: React.Dispatch<React.SetStateAction<string | null>>;
  // ARS 시나리오 파일 이름
  arsFileNameS: string;
}

/**
 * @title ARS 시나리오 API HOOKS
 *
 * @author 정휘학
 * @since 2024.09.02
 * */
export const useScenarioAPI = ({ setRefetchS, setUpdatedArsFileNameS, arsFileNameS }: IUseScenarioAPI) => {
  const queryClient = useQueryClient();

  // =======================
  // ARS 시나리오 추가 Mutation
  // =======================
  const { mutate: arsAddM } = fetchMutation({
    method: 'POST',
    mutationKey: ['/ars'],
    onSuccess: () => {
      queryClient.invalidateQueries(['/say015/serviceStatus']).then(() => {
        setRefetchS(true);
      });
    },
  });

  // =======================
  // ARS 시나리오 수정 Mutation
  // =======================
  const { mutate: arsUpdateM } = fetchMutation<IArsUpdateMutation, any>({
    method: 'PUT',
    mutationKey: ['/ars'],
    onSuccess: () => {
      queryClient.invalidateQueries(['/say015/serviceStatus']).then(() => {
        setUpdatedArsFileNameS(arsFileNameS);
        setRefetchS(true);
      });
    },
  });

  // =======================
  // ARS 시나리오 삭제 Mutation
  // =======================
  const { mutate: arsDeleteM } = fetchMutation<{ fileName: string }, any>({
    method: 'DELETE',
    mutationKey: ['/ars'],
    onSuccess: () => {
      queryClient.invalidateQueries(['/say015/serviceStatus']).then(() => {
        setRefetchS(true);
      });
    },
  });

  return {
    arsAddM,
    arsUpdateM,
    arsDeleteM,
  };
};
