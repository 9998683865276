import { motion } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const MotionWrapper = styled(motion.div)`
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

interface FramerMotionProps extends PropsWithChildren {}

const FramerMotion: React.FC<FramerMotionProps> = ({ children }) => {
  const animationVariants = {
    hidden: { x: '100%' }, // 오른쪽에서 시작
    visible: { x: '0%', transition: { duration: 0.3 } }, // 화면에 나타날 때
    exit: { x: '100%', transition: { duration: 0.3 } }, // 오른쪽으로 사라짐
  };

  return (
    <MotionWrapper variants={animationVariants} initial="hidden" animate="visible" exit="exit">
      {children}
    </MotionWrapper>
  );
};

export default FramerMotion;
