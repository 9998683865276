import styled from 'styled-components';

export const SignUpFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 72rem;
  padding: 9rem 4rem;
  margin: 10rem 0;
  flex-shrink: 0;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.background};
  border-radius: 1rem;

  /* 서울이동통신 회원가입 */
  &::before {
    content: '비밀번호 변경';
    display: block;
    color: ${(props) => props.theme.colors.txt500};
    text-align: center;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 5rem;
  }
  @media screen and (max-width: 520px) {
    margin: 0;
    padding: 6rem 1.6rem;
  }
`;

export const Say015ApplyTitle = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.6em;
  @media screen and (max-width: 640px) {
    font-size: 1.6rem;
  }
`;

// 인증 번호
export const CertifyTitle = styled.h2`
  font-size: 1.5rem;
  display: inline-block;
`;

export const CertifyWrap = styled.div`
  margin-bottom: 5rem;
`;

export const CertifyInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const CertifyTimer = styled.time`
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.notify};
`;

// 인증 번호

export const RecoveryPwWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const RecoveryPwInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  h3 {
    font-size: 1.4rem;
    margin: 0 0 1.6rem;
  }
  button {
    font-size: 1.4rem;
  }
  .inputWrap {
    margin: 0 0 2rem;
  }
  input {
    padding: 1rem;
    min-height: 4.2rem;
    width: 100%;
    border-radius: 0.5rem;
    font-size: 1.4rem;
  }
`;

export const AgreementButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5rem auto 0;
  box-sizing: border-box;
  button {
    padding: 1.6rem 4.2rem;
    font-size: 1.8rem;
    font-weight: 700;
    border-radius: 0.8rem;
  }
`;

export const ValidationError = styled.p`
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.notify};
`;

export const ValidationMessage = styled.p`
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.txt300};
  line-height: normal;
  .red {
    margin: 0 0.2rem 0 0;
  }
`;

export const ValidationSuccess = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.green};
`;
