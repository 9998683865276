import { INiceCertificationUrlAxios } from '../types/niceCertificationApiTypes';
import { instance } from '@/shared/lib/clientAxios';
import { useMutation } from '@tanstack/react-query';

/**
 * @title 나이스 인증 팝업 URL 요청 AXIOS
 *
 * @method [POST]
 * */
const niceCertificationUrlAxios = async ({ errorUrl, callbackUrl }: INiceCertificationUrlAxios) => {
  const callback: String = encodeURIComponent(callbackUrl);
  const error: String = encodeURIComponent(errorUrl);
  const { config } = await instance.get(`/nice/authorize?callbackUrl=${callback}&errorUrl=${error}`);

  return config.url;
};

/**
 * @title 나이스 인증 팝업 URL 요청 Query
 * */
export const useNiceCertificationUrlQuery = () => {
  return useMutation(niceCertificationUrlAxios);
};
