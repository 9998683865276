import ATitle from '@/components/Atom/ATitle';
import BaseGuide from '@/components/Atom/BaseGuide';
import BaseInput from '@/components/Atom/BaseInput';
import ButtonList from '@/components/common/buttons/ButtonList';
import { usePwChange } from '@/components/hooks/customs/myPage/usePwChange';
import React from 'react';
import { AiFillEye } from 'react-icons/ai';
import { RiEyeOffFill } from 'react-icons/ri';
import * as S from './MobileEtcPwChange.style';
import * as SS from '../MobileEtc.style';

const MobileEtcPwChange = () => {
  const {
    userInfoS,
    visible,
    register,
    originPwHandleVisible,
    visible1,
    newPwHandleVisible,
    errors,
    visible2,
    checkNewPwHandleVisible,
    pwNewOriginCheck,
    buttonList,
  } = usePwChange();

  return (
    <SS.Wrapper>
      <SS.AWrapper className="basicTable">
        <SS.TitleWrapper className="titleWrap">
          <ATitle type="sub" text="기본정보" />
        </SS.TitleWrapper>
        <SS.MyPageTable className="tw30">
          <tbody>
            <tr>
              <th>이름</th>
              <td>
                <div className="automatic">{userInfoS?.usrNm}</div>
              </td>
            </tr>
            <tr>
              <th>아이디</th>
              <td>
                <div className="automatic">{userInfoS?.phnId}</div>
              </td>
            </tr>
          </tbody>
        </SS.MyPageTable>
      </SS.AWrapper>
      <SS.AWrapper className="basicTable">
        <SS.TitleWrapper className="titleWrap">
          <ATitle type="sub" text="비밀번호 변경" />
          <span>_최소 8자, 최대 16자 이내(영문,숫자 조합)</span>
        </SS.TitleWrapper>

        <SS.MyPageTable className="tw30">
          <tbody>
            <tr>
              <th>*기존 비밀번호</th>
              <td>
                <div className="flexAc">
                  <BaseInput
                    type={visible ? 'text' : 'password'}
                    {...register('originPw', {
                      required: true,
                    })}
                  />
                  {visible ? (
                    <div style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <AiFillEye className="on" onClick={originPwHandleVisible} />
                    </div>
                  ) : (
                    <div style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <RiEyeOffFill className="off" onClick={originPwHandleVisible} />
                    </div>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <th>*새 비밀번호</th>
              <td>
                <div className="flexAc">
                  <BaseInput type={visible1 ? 'text' : 'password'} height="2.2vh" {...register('newPw')} />
                  {visible1 ? (
                    <div style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <AiFillEye className="on" onClick={newPwHandleVisible} />
                    </div>
                  ) : (
                    <div style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <RiEyeOffFill className="off" onClick={newPwHandleVisible} />
                    </div>
                  )}
                  {errors?.newPw ? <p className="inputErrorMessage">{errors.newPw?.message}</p> : null}
                </div>
              </td>
            </tr>
            <tr>
              <th>*새 비밀번호 확인</th>
              <td>
                <div className="flexAc">
                  <BaseInput type={visible2 ? 'text' : 'password'} height="2.2vh" {...register('checkNewPw')} />

                  {visible2 ? (
                    <div style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <AiFillEye className="on" onClick={checkNewPwHandleVisible} />
                    </div>
                  ) : (
                    <div style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <RiEyeOffFill className="off" onClick={checkNewPwHandleVisible} />
                    </div>
                  )}
                  {pwNewOriginCheck && <p className="inputErrorMessage">비밀번호가 일치하지 않습니다.</p>}
                </div>
              </td>
            </tr>
          </tbody>
        </SS.MyPageTable>
      </SS.AWrapper>
      <SS.ButtonWrapper>
        <ButtonList buttonList={buttonList} />
      </SS.ButtonWrapper>
      <BaseGuide text="개인정보취급방침에 따라 개인정보를 보호하고, 회원님의 동의 없이 절대 외부에 유출하지 않을 것을 약속드립니다." />
    </SS.Wrapper>
  );
};
export default MobileEtcPwChange;
