import * as NS from '@/components/commons/modals/noticeModal/NoticeModal.styles';
import React, { useState, useRef, useEffect } from 'react';
import * as S from './ToolBarListPage.styles';

import NoticeModal from '@/components/commons/modals/noticeModal/NoticeModal';
import { useToolBarListPage } from '@/components/hooks/customs/softPhone/useToolBarListPage';
import { useMessageManagement } from '@/components/hooks/customs/useMessageManagement';
import NotifyComponent from '@/components/softPhone/Toolbar/Components/NotifyComponent';
import ToolBarCallingNum from '@/components/softPhone/Toolbar/ToolBarCallingNum';
import { selectNumberState } from '@/recoil/atoms/addressList';
import { ARREO_SMS, WORKS015_SMS } from '@/shared/constants';
import { useNoticeModal } from '@/shared/hooks/useNoticeModal';
import { amountFormat } from '@/shared/util/format/amountFormatUtil';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import IconHuman from '@/styles/icons/IconHuman';
import { mobileNavRecoil, selectedComponentRecoil, windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import IconCassetteTape from '@/styles/icons/IconCassetteTape';
import IconX from '@/styles/icons/IconX';
import { useTopNaviTab } from '@/components/hooks/customs/softPhone/useTopNaviTap';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useQueryClient } from '@tanstack/react-query';

export const ToolBarListPage = () => {
  const queryClient = useQueryClient();

  const hooks = useToolBarListPage();
  const noticeHooks = useNoticeModal();
  const msgHooks = useMessageManagement();
  const naviHooks = useTopNaviTab();

  // TODO - 충전 : 앱 충전로직 끝나기 전까지 막을 기능
  const environmentS = useRecoilValue(environmentRecoil);
  const naviUrlS = environmentS === 'app' ? '/mobile/etc/etcCharge' : '/charge';

  // testCode수정
  const { warningModal } = useModalHook();

  // 반응형 상태값
  const windowWidth = useRecoilValue(windowWidthRecoil);

  // 반응형 nav바 상태값
  const setMobileNavState = useSetRecoilState(mobileNavRecoil);
  const setSelectedComponent = useSetRecoilState(selectedComponentRecoil);

  /* 선택한 발신번호 */
  const selectNumberS = useRecoilValue(selectNumberState);

  // 팝업
  const [isPopupPoint, setIsPopupPoint] = useState(false);

  // 015 가입여부 확인
  const authorityData: any = queryClient.getQueryData(['authorityData']);

  // 팝업을 감지할 ref 생성 (타입을 명시)
  const popupPointRef = useRef<HTMLDivElement | null>(null);

  // 팝업 열기 함수
  const popupState = (item: boolean) => {
    setIsPopupPoint(item);
  };

  // cash onClick hook
  const onClickCashBtn = () => {
    if (windowWidth > 768) return;
    setMobileNavState('회원정보');
    setSelectedComponent('mypage');
  };

  // 팝업 외부 클릭 감지
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Popup Point 외부 클릭 시 닫기
      if (popupPointRef.current && !popupPointRef.current.contains(event.target as Node)) {
        setIsPopupPoint(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupPointRef]);

  return (
    <S.ToolBarListPage>
      <S.Container>
        <div className="customerGroup">
          <div className="flexBox">
            <div className="nameGroup">
              {windowWidth > 768 && (
                <p>
                  <b onClick={hooks.onClickMyPage}>{hooks.userInfoS.usrNm}</b> <span>님</span>
                </p>
              )}
              {/* 발신번호 */}
              <ToolBarCallingNum />
            </div>

            {/* 알림 설정 */}
            <NotifyComponent />
          </div>
          <S.ToolBarTabGroup className="tabGroup">
            {/* Click시 className에 active */}
            {windowWidth > 768 && (
              <>
                {/* 주소록 */}
                <div
                  className={`group ${naviHooks.tabMenuS[0].s && 'active'}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    naviHooks.onClickTabMenu('주소록');
                    hooks.onClickTap('0');
                    msgHooks.handleChangeComponent('chatroom');
                    noticeHooks.setNoticeModalToggle(false);
                  }}
                >
                  <div>
                    <IconHuman />
                  </div>
                  <p>주소록</p>
                </div>
                {/* ARS */}
                <div
                  className={`group arsGroup ${naviHooks.tabMenuS[1].s && 'active'}`}
                  // testCode수정
                  // onClick={() => {
                  //   warningModal('점검중', '해당 기능은 점검중 입니다.', true);
                  // }}
                  onClick={() => {
                    naviHooks.onClickTabMenu('인사말설정');
                    hooks.onClickTap('2');
                    msgHooks.handleChangeComponent('ars');
                    noticeHooks.setNoticeModalToggle(false);
                  }}
                >
                  <div>
                    <IconCassetteTape />
                  </div>
                  <p>인사말설정</p>
                </div>
              </>
            )}
            {/* 포인트 */}
            {/* TODO - 충전소 : 앱 충전소 기능 끝나면 변경 */}
            <Link to={`${windowWidth > 768 ? '/charge' : '/mypage'}`} onClick={() => onClickCashBtn()}>
              <div className={`group pointGroup ${naviHooks.tabMenuS[2].s && 'active'}`}>
                <div>
                  {amountFormat(hooks.userPointS ? hooks.userPointS.use_pg : 0)}
                  <span>C</span>
                </div>
                <p>Cash</p>
              </div>
            </Link>
            {/* 무료발송건수 */}
            <div className="freeGroup">
              {hooks.softPhoneTopTabState === '1' ? (
                <div
                  className={`group point ${naviHooks.tabMenuS[4].s && 'active'}`}
                  onClick={() => {
                    naviHooks.onClickTabMenu('Point');
                    popupState(true);
                  }}
                >
                  <div>{amountFormat(hooks.userPointS ? hooks.userPointS.use_cc : 0)}</div>
                  <p>Point</p>
                </div>
              ) : (
                <div
                  className={`group freePoint ${naviHooks.tabMenuS[3].s && 'active'}`}
                  onClick={() => {
                    naviHooks.onClickTabMenu('무료발송건수');
                    popupState(true);
                  }}
                >
                  <div>{hooks.free015Msg.SMS ?? '0'}건</div>
                  <p>무료발송건수 </p>
                </div>
              )}

              {isPopupPoint && hooks.softPhoneTopTabState === '1' && (
                <S.PopTextBox ref={popupPointRef} className="popTextBox">
                  <div className="textGroup">
                    <p>
                      포인트는 결제 금액에 따라 차등 적립되며, 사용 시 캐시가 먼저 차감된 후 포인트를 사용할 수
                      있습니다. 적립된 포인트의 유효 기간은 적립일로부터 1년이며, 환불 시 해당 결제 건으로 적립된
                      포인트는 자동 소멸됩니다.
                    </p>
                    <small>
                      포인트 사용 내역은 <Link to={'/usage'}>사용 내역 페이지</Link>에서, 적립 내역은{' '}
                      <Link to={'/payment'}>결제 내역 페이지</Link>확인 가능합니다.
                    </small>
                  </div>
                  <button
                    onClick={() => {
                      popupState(false);
                    }}
                  >
                    <IconX />
                  </button>
                </S.PopTextBox>
              )}

              {isPopupPoint && hooks.softPhoneTopTabState === '0' && (
                <S.PopTextBox ref={popupPointRef} className="popTextBox">
                  <div className="textGroup">
                    <p>
                      총 {hooks.free015Msg.SMS}건의 SMS를 무료 발송할 수 있으며, 발송 유형에 따라 차감되는 건수가
                      다릅니다. (SMS 1건, LMS 3건, MMS 6건)
                    </p>
                    {/* <small>
                      발송 내역은 <Link to={'/usage'}>사용 내역 페이지</Link>에서 확인 가능합니다.
                    </small> */}
                  </div>
                  <button
                    onClick={() => {
                      popupState(false);
                    }}
                  >
                    <IconX />
                  </button>
                </S.PopTextBox>
              )}
            </div>
          </S.ToolBarTabGroup>
        </div>

        <div id={'menuGroup'} className="rowMenuGroup">
          <div
            className={`tabButton tabButton015 ${hooks.softPhoneTopTabState === '0' || hooks.softPhoneTopTabState === '2' ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              hooks.onClickTap('0');
              msgHooks.handleChangeComponent('chatroom');
              noticeHooks.setNoticeModalToggle(false);
            }}
          >
            <div className="mode mode015">
              <div className="money money015">
                <p>{WORKS015_SMS}원</p>
                <NS.IButton
                  className="attentionIcon"
                  onClick={(e) => {
                    e.stopPropagation();
                    noticeHooks.onClickModalBtn('015');
                  }}
                >
                  i
                </NS.IButton>
              </div>
              {hooks.menuList[0].name}
              {hooks.softPhoneTopTabState === '0' && (
                <div className="userNumber">
                  <p>{formatPhoneNumber(selectNumberS['015'])}</p>
                </div>
              )}
            </div>
            {/* 음성모양아이콘 - 음성사서함 */}
            {/* {(hooks.softPhoneTopTabState === '0' || hooks.softPhoneTopTabState === '2') && (
              <button
                className="arsTab"
                title="ARS 설정"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  // TODO - ARS 소프트폰 배포시 주석
                  hooks.onClickTap('2');
                  // hooks.onClickTap('0');
                  msgHooks.handleChangeComponent('ars');
                }}
                // testCode수정
                disabled={hooks.softPhoneTopTabState === '2' && msgHooks.softPhoneComponent === 'ars'}
                // disabled
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
                  <path
                    d="M10.2758 1V9.74046V18.4809M5.63792 6.46279V13.0181M19.5517 7.55535V11.9256M1 7.55535V11.9256M14.9137 4.27767V15.2033"
                    stroke="#E2E2E2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )} */}
          </div>
          <S.Mode010Wrap
            sub015User={authorityData && !authorityData?.say015User}
            className={`tabButton ${hooks.softPhoneTopTabState === '1' ? 'active' : ''}`}
            onClick={() => {
              hooks.onClickTap('1');
              noticeHooks.setNoticeModalToggle(false);
            }}
          >
            <div className="mode mode010">
              <div className="money money010">
                <p>{ARREO_SMS}원</p>
                <NS.IButton
                  className="attentionIcon"
                  onClick={(e) => {
                    e.stopPropagation();
                    noticeHooks.onClickModalBtn('010');
                  }}
                >
                  i
                </NS.IButton>
              </div>
              {hooks.menuList[1].name}
              {hooks.softPhoneTopTabState === '1' && (
                <div className="userNumber">
                  <p>{formatPhoneNumber(selectNumberS['010'])}</p>
                </div>
              )}
            </div>
          </S.Mode010Wrap>
        </div>
        {/* 검색창 */}
        {/* <AddressSearch handleSelectBtn={searchHooks.handleSelectBtn} btnSelectToggle={searchHooks.btnSelectToggle} /> */}
      </S.Container>
      {/* 010, 015탭의 설명 모달 */}
      {noticeHooks.noticeModalToggle && (
        <NoticeModal modalType={noticeHooks.modalType} setNoticeModalToggle={noticeHooks.setNoticeModalToggle} />
      )}
    </S.ToolBarListPage>
  );
};

export default ToolBarListPage;
