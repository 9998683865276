import { useEffect, useState } from 'react';
import { IMenus } from '@/components/hooks/customs/charge/chargeStation/useChargeMenuItems';

export type AmountCardHoverStateType = 'monthly_pass' | 'annual_pass';
export const useChargeStateHandler = () => {
  const [payMethodTypeCodeState, setPayMethodTypeCodeState] = useState<IMenus>({
    title: '신용카드',
    payMethodTypeCode: '11',
    subTitle: '1% 포인트 추가 충전',
    pointPercent: 1,
  });
  // 결제금액 state
  const [amountS, setAmountS] = useState<number>(0);
  // 마우스 올려진 결제창에 관한 state
  const [amountCardHoverS, setAmountCardHoverS] = useState<AmountCardHoverStateType>('monthly_pass');
  const [pointValueState, setPointValueState] = useState<number>(0);
  const [pointPercentState, setPointPercentState] = useState<number>(0);
  // ========================
  // 결제금액 변경 handler hook
  // ========================
  const handleAmount = (amount: number) => {
    if (amount > 9999999) {
      setAmountS(9999999);
      return;
    }

    if (!amount || amount < 1) {
      setAmountS(0);
    } else {
      setAmountS(amount);
    }
  };
  // =================
  // 결제 방식 선택 hook
  // =================
  const handlerPayMethodTypes = (payMethodTypeCode: '0' | '11' | '21' | '31' | '1') => {
    setPayMethodTypeCodeState((prevState) => {
      switch (payMethodTypeCode) {
        case '0':
          return { title: '무통장 입금', payMethodTypeCode, subTitle: '5% 포인트 추가 충전', pointPercent: 5 };
        case '11':
          return { title: '신용카드', payMethodTypeCode, subTitle: '1% 포인트 추가 충전', pointPercent: 1 };
        case '21':
          return { title: '계좌이체', payMethodTypeCode, subTitle: '2% 포인트 추가 충전', pointPercent: 2 };
        case '31':
          return { title: '휴대폰 결제', payMethodTypeCode, subTitle: '0% 포인트 추가 충전', pointPercent: 0 };
        case '1':
          return { title: '캐시 결제', payMethodTypeCode, subTitle: '', pointPercent: 0 };
        default:
          return prevState;
      }
    });
  };
  // =====================================================
  // 결제창 마우스 올렸을 때 state 변경 ( 우측 결제 정보 카드 변경 )
  // =====================================================
  const handleChangeInfoCard = (hoverCardType: AmountCardHoverStateType) => {
    setAmountCardHoverS(hoverCardType);
  };

  // ? 적립 포인트 계산 함수
  useEffect(() => {
    let pointValue = 0;
    let pointPercent = 0;
    if (amountS >= 10000 && amountS < 100000) {
      pointValue = amountS * 0.05;
      pointPercent += 5;
    } else if (amountS >= 100000 && amountS < 500000) {
      pointValue = amountS * 0.1;
      pointPercent += 10;
    } else if (amountS >= 500000 && amountS < 1000000) {
      pointValue = amountS * 0.2;
      pointPercent += 20;
    } else if (amountS >= 1000000) {
      pointValue = amountS * 0.3;
      pointPercent += 30;
    }

    if (amountS >= 5000) {
      switch (payMethodTypeCodeState.payMethodTypeCode) {
        case '11':
          pointValue += amountS * 0.01; // 신용카드
          pointPercent += 1;
          break;
        case '21':
          pointValue += amountS * 0.02; // 계좌이체
          pointPercent += 2;
          break;
        case '0':
          pointValue += amountS * 0.05; // 무통장
          pointPercent += 5;
          break;
        case '31':
          pointValue += 0; // 핸드폰
          pointPercent += 0;
          break;
        default:
          break;
      }
    }
    setPointValueState(Math.floor(pointValue));
    setPointPercentState(pointPercent);
  }, [amountS, payMethodTypeCodeState]);

  return {
    payMethodTypeCodeState,
    handlerPayMethodTypes,
    handleAmount,
    amountS,
    amountCardHoverS,
    handleChangeInfoCard,
    pointValueState,
    pointPercentState,
  };
};
