import ATitle from '@/components/Atom/ATitle';
import BaseButton from '@/components/Atom/BaseButton';
import Loader from '@/components/common/Loader';
import Paginations01Index from '@/components/common/paginations/Pagination02/Pagination02.index';
import { usePayHistory } from '@/components/hooks/customs/charge/usePayHistory';
import ChargeDateSearch from '@/components/Molecules/Charge/ChargeDateSearch';
import BaseTable from '@/components/Organism/Table';
import React from 'react';
import { useMobileEtcPointHistoryM } from '../../mobileHooks/useMobileEtcPointHistoryM';
import * as S from './MobileEtcPayment.style';

const MobileEtcPayment = () => {
  const {
    totalPage,
    handlePageChange,
    activePage,
    setActivePage,
    startPage,
    setStartPage,
    thead,
    payResultData,
    selectedValue,
    disabledDate,
    setSearchSelectValue,
    setSearchDates,
    setCurrentPage,
    handlePrint,
    isLoading,
  } = usePayHistory();

  const {
    theadM,
    totalPageM,
    handlePageChangeM,
    activePageM,
    setActivePageM,
    startPageM,
    setStartPageM,
    currentPageM,
    pointHistoryDataM,
    selectedValueM,
    disabledDateM,
    setSearchSelectValueM,
    setSearchDatesM,
    setCurrentPageM,
    isSuccessM,
    excelDownloadM,
    isLoadingM,
  } = useMobileEtcPointHistoryM();

  return (
    <>
      <S.PaymentHistory className="paymentHistory">
        <S.Wrapper>
          <ATitle type="sub" text="결제내역" />
          <div className="top">
            <ChargeDateSearch
              chargeOptionsProp={selectedValue}
              name1="결제내역"
              disabledDate={disabledDate}
              setSearchSelectValue={setSearchSelectValue}
              setSearchDates={setSearchDates}
              setStartPage={setStartPage}
              setActivePage={setActivePage}
              setCurrentPage={setCurrentPage}
            />
            <BaseButton padding=".5rem 1rem" fontSize="1.4rem" fontWeight="bold" onClick={handlePrint}>
              조회 기간 전체 영수증 인쇄
            </BaseButton>
          </div>
          <S.BaseTableCover>
            <BaseTable type="line" thead={thead} tbody={payResultData} />
            {isLoading && <Loader backgroundToggle />}
          </S.BaseTableCover>
          <S.TableFoot>
            <Paginations01Index
              dataCount={totalPage}
              startPage={startPage}
              setStartPage={setStartPage}
              activePage={activePage}
              setActivePage={setActivePage}
              eventHook={handlePageChange}
              pageSize={10}
            />
          </S.TableFoot>
          <p className="payText">
            결제 내역은 최근 3년간의 내역을 검색할 수 있으며, 이전 자료는 자동 삭제 됩니다.
            <br />
            결제한 내역에 대해 영수증을 인쇄할 수 있습니다.
          </p>
        </S.Wrapper>
      </S.PaymentHistory>
      <S.UsageHistory>
        <ATitle type="sub" text="사용내역" />
        <ChargeDateSearch
          chargeOptionsProp={selectedValueM}
          name1="사용내역"
          disabledDate={disabledDateM}
          setSearchSelectValue={setSearchSelectValueM}
          setSearchDates={setSearchDatesM}
          setStartPage={setStartPageM}
          setActivePage={setActivePageM}
          setCurrentPage={setCurrentPageM}
        />
        <div className="table">
          <BaseTable name="use" type="line" thead={theadM} tbody={pointHistoryDataM} onclick={(e: any) => {}} />
          {/* {isSuccess || (isLoading && <Loader />)} */}
          {isLoadingM && <Loader backgroundToggle />}
        </div>
        <S.TableFoot>
          <Paginations01Index
            dataCount={totalPageM}
            startPage={startPageM}
            setStartPage={setStartPageM}
            activePage={activePageM}
            setActivePage={setActivePageM}
            eventHook={handlePageChangeM}
            pageSize={10}
          />

          <BaseButton width="10rem" fontSize="1.4rem" padding=".6rem 1rem" fontWeight="bold" onClick={excelDownloadM}>
            엑셀 다운
          </BaseButton>
        </S.TableFoot>
        <p style={{ fontSize: '1.2rem' }}>사용내역은 전월, 당월만 검색이 가능하며 이전 자료는 삭제됩니다. </p>
      </S.UsageHistory>
    </>
  );
};
export default MobileEtcPayment;
