import React, { useRef, useState } from 'react';
import * as S from './MobileEtcRegisterTaxInvoice.style';
import RegisterTaxInvoice from '@/components/Organism/Charge/RegisterTaxInvoice';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { instance } from '@/shared/lib/clientAxios';
import ATitle from '@/components/Atom/ATitle';
import * as CBS from '@/styles/checkBox.styles';
import BaseInput from '@/components/Atom/BaseInput';
import ButtonList from '@/components/common/buttons/ButtonList';
import BaseGuide from '@/components/Atom/BaseGuide';

const MobileEtcRegisterTaxInvoice = () => {
  const checkRef = useRef<HTMLInputElement | null>(null);
  // 신청구분 타입 지정 1: 소비자 소득공제용 2: 사업자 지출 증빙용
  type TIsOn = 1 | 2 | null;
  const [isOnS, setIsOnS] = useState<TIsOn>(1);

  const [flag, setFlag] = useState('off');

  const { confirmModal, successModal, warningModal } = useModalHook();

  const flagClick = () => {
    if (flag === 'off') {
      setFlag('on');
    } else {
      setFlag('off');
    }
  };

  const initInputValue = {
    name: '',
    number: '',
    secondPhoneNum: '',
    thirdPhoneNum: '',
    taxNumber: '',
    secondTaxNum: '',
    thirdTaxNum: '',
    payAmt: '',
    actGb: 'P',
  };

  const [inputValue, setInputValue] = useState(initInputValue);

  // 이름 2글자 이상
  const [showWarningName, setShowWarningName] = useState(false);
  const [showWarningNumber, setShowWarningNumber] = useState(false);
  const [showWarningTaxNumber, setShowWarningTaxNumber] = useState(false);
  const [showWarningPayAmt, setShowWarningPayAmt] = useState(false);

  // 숫자를 3자리마다 쉼표를 추가한 문자열로 변환하는 함수
  const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const NamehandleChange = (e: any) => {
    const { value, name } = e.target;

    // 이름 2글자 이상
    if (name === 'name') {
      setInputValue((prev) => ({ ...prev, [name]: value }));
      if (value.length >= 2) {
        setShowWarningName(false);
      }
    }
  };

  const handleChange = (e: any) => {
    const { value, name } = e.target;

    // 이름 2글자 이상
    // if (name === 'name' && value.length >= 2) {
    //   setInputValue(prev => ({ ...prev, [name]: value }));
    //   setShowWarningName(false);
    // } else {
    //   setInputValue(prev => ({ ...prev, [name]: value }));
    //   setShowWarningName(true);
    // }

    // payAmt 필드에 대한 처리
    if (name === 'payAmt') {
      // 숫자만 입력 가능
      const numericValue = value.replace(/\D/g, '');
      // 숫자만 남긴 후 3자리마다 쉼표 추가
      const formattedValue = numberWithCommas(numericValue);
      // 상태 업데이트
      setInputValue((prev) => ({ ...prev, [name]: formattedValue }));
      if (value.length > 0) {
        setShowWarningPayAmt(false);
      }
    }
  };

  const handleChangeNumber = (e: any) => {
    const { value, name } = e.target;

    if (name === 'number' || name === 'secondPhoneNum' || name === 'thirdPhoneNum') {
      // 숫자만 입력 가능하도록 정규 표현식 사용
      const numericValue = value.replace(/\D/g, '');
      // 최대 3자리까지 입력 허용
      if (name === 'number') {
        const truncatedValue = numericValue.slice(0, 3);
        setInputValue((prev) => ({ ...prev, [name]: truncatedValue }));
        // if (value.length >= 3) {
        //   setShowWarningNumber(false)
        // }
      } else {
        // 최대 4자리까지 입력 허용
        const truncatedValue = numericValue.slice(0, 4);
        setInputValue((prev) => ({ ...prev, [name]: truncatedValue }));
        // if (value.length >= 4) {
        //   setShowWarningNumber(false);
        // }
      }
    }

    if (
      inputValue.number.length >= 2 &&
      inputValue.secondPhoneNum.length >= 3 &&
      inputValue.thirdPhoneNum.length >= 3
    ) {
      setShowWarningNumber(false);
    }
  };

  const handleChangeTaxNumber = (e: any) => {
    const { value, name } = e.target;

    if (name === 'taxNumber' || name === 'secondTaxNum' || name === 'thirdTaxNum') {
      // 숫자만 입력 가능하도록 정규 표현식 사용
      const numericValue = value.replace(/\D/g, '');
      // 최대 3자리까지 입력 허용
      if (name === 'taxNumber') {
        const truncatedValue = numericValue.slice(0, 3);
        setInputValue((prev) => ({ ...prev, [name]: truncatedValue }));
        // if (value.length >= 3) {
        //   setShowWarningTaxNumber(false);
        // }
      }
      if (name === 'secondTaxNum') {
        // 최대 2자리까지 입력 허용
        const truncatedValue = numericValue.slice(0, 2);
        setInputValue((prev) => ({ ...prev, [name]: truncatedValue }));
        // if (value.length >= 2) {
        //   setShowWarningTaxNumber(false);
        // }
      } else if (name === 'thirdTaxNum') {
        // 최대 5자리까지 입력 허용
        const truncatedValue = numericValue.slice(0, 5);
        setInputValue((prev) => ({ ...prev, [name]: truncatedValue }));
        // if (value.length >= 5) {
        //   setShowWarningTaxNumber(false);
        // }
      }
    }

    if (inputValue.thirdTaxNum.length >= 4 && inputValue.taxNumber.length >= 2 && inputValue.secondTaxNum.length >= 1) {
      setShowWarningTaxNumber(false);
    }
  };

  // const [typeChange, setTypeChange] = useState('submit');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isOnS) return;
    if (isOnS === 1) {
      inputValue.actGb = 'P';
    }
    if (isOnS === 2) {
      inputValue.actGb = 'C';
    }

    // 경고 메시지 표시
    if (inputValue.actGb !== '') {
      if (inputValue.name.length <= 1) {
        // setTypeChange('button')
        setShowWarningName(true);
        return;
      }
      if (inputValue.payAmt.length === 0) {
        // setTypeChange('button')
        setShowWarningPayAmt(true);
        return;
      }
      if (inputValue.actGb === 'P') {
        if (
          inputValue.number.length !== 3 ||
          inputValue.secondPhoneNum.length !== 4 ||
          inputValue.thirdPhoneNum.length !== 4
        ) {
          // setTypeChange('button')
          setShowWarningNumber(true);
          return;
        }
      }
      if (inputValue.actGb === 'C') {
        if (
          inputValue.taxNumber.length !== 3 ||
          inputValue.secondTaxNum.length !== 2 ||
          inputValue.thirdTaxNum.length !== 5
        ) {
          // setTypeChange('button')
          setShowWarningTaxNumber(true);
          return;
        }
      }
    }
    if (checkRef.current?.value !== 'on') {
      // setTypeChange('button')
      warningModal('현금영수증 발행신청', '개인정보 수집 및 이용에 대한 동의가 필요한 서비스 입니다.', true);
      return;
    }

    confirmModal(
      async () => {
        try {
          // Axios를 사용하여 서버에 POST 요청을 보냅니다.
          if (inputValue.actGb === 'P') {
            inputValue.number += `-${inputValue.secondPhoneNum}-${inputValue.thirdPhoneNum}`;
          } else {
            inputValue.taxNumber += `-${inputValue.secondTaxNum}-${inputValue.thirdTaxNum}`;
          }

          await instance.post('/registerTaxInvoice', inputValue);
          successModal('현금연수증 발행신청', '현금영수증 발행신청이 완료되었습니다.', true);
          setInputValue(initInputValue);
          // 서버 응답을 처리하거나 다른 작업을 수행합니다.
          // alert('성공');
        } catch (error) {
          // alert('실패');
          warningModal('현금연수증 발행신청', '현금영수증 발행신청이 실패했습니다.', true);
        }
      },
      '현금영수증 발행신청',
      '현금영수증 발행을 하시겠습니까?',
      true,
    );
    // }

    //   try {

    //     // Axios를 사용하여 서버에 POST 요청을 보냅니다.
    //     inputValue.number += inputValue.secondPhoneNum + inputValue.thirdPhoneNum;
    //     const response = await instance.post('/registerTaxInvoice', inputValue);

    //     // 서버 응답을 처리하거나 다른 작업을 수행합니다.
    //     alert('성공');
    //     return response.data;
    //   } catch (error) {
    //     alert('실패');
    //     return error
    //   }

    // setTypeChange('submit')
  };

  const onClickCheckBox = (type: TIsOn) => {
    setIsOnS(type);
    setShowWarningTaxNumber(false);
  };

  const buttonList = [{ type: 'submit', func: () => handleSubmit, text: '신청하기' }];

  return (
    <S.FormContainer className="basicTable FormContainer">
      <ATitle type="sub" text="현금영수증 발행신청" />
      <S.CashReceiptForm onSubmit={handleSubmit}>
        <S.MyPageTable className="tw20 marB30">
          <tbody>
            <tr>
              <th>신청구분</th>
              <td>
                <div className="flexBox checkGroup">
                  <S.CheckBoxLabel htmlFor="checkBoxP">
                    <CBS.CheckBoxInput
                      type="checkBox"
                      name="actGb"
                      value="P"
                      id="checkBoxP"
                      onChange={() => onClickCheckBox(1)}
                      checked={isOnS === 1}
                      required={isOnS ? false : true}
                    />
                    <span>소비자 소득공제용</span>
                  </S.CheckBoxLabel>
                  <S.CheckBoxLabel htmlFor="checkBoxC">
                    <CBS.CheckBoxInput
                      type="checkBox"
                      name="actGb"
                      value="C"
                      id="checkBoxC"
                      onChange={() => onClickCheckBox(2)}
                      checked={isOnS === 2}
                      required={isOnS ? false : true}
                    />
                    <span>사업자 지출 증빙용</span>
                  </S.CheckBoxLabel>
                </div>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                <div>
                  <BaseInput
                    name="name"
                    type="text"
                    value={inputValue.name}
                    width="160px"
                    placeholder="2글자 이상 입력하세요"
                    onChange={NamehandleChange}
                  />
                  {showWarningName && <p className="inputErrorMessage">이름을 정확히 입력해 주세요.</p>}
                </div>
              </td>
            </tr>
            {isOnS === 1 && (
              <tr className="numberRow">
                <th>연락처</th>
                <td>
                  <div>
                    <BaseInput
                      name="number"
                      type="text"
                      value={inputValue.number}
                      width="7rem"
                      height="2.2rem"
                      onChange={handleChangeNumber}
                    />
                    <span>-</span>
                    <BaseInput
                      name="secondPhoneNum"
                      type="text"
                      value={inputValue.secondPhoneNum}
                      width="7rem"
                      height="2.2rem"
                      onChange={handleChangeNumber}
                    />
                    <span>-</span>
                    <BaseInput
                      name="thirdPhoneNum"
                      type="text"
                      value={inputValue.thirdPhoneNum}
                      width="7rem"
                      height="2.2rem"
                      onChange={handleChangeNumber}
                    />
                  </div>
                  {showWarningNumber && <p className="inputErrorMessage">연락처를 정확히 입력해 주세요.</p>}
                </td>
              </tr>
            )}
            {isOnS === 2 && (
              <tr className="numberRow">
                <th>사업자 등록번호</th>
                <td>
                  <div>
                    <BaseInput
                      name="taxNumber"
                      type="text"
                      value={inputValue.taxNumber}
                      width="10rem"
                      onChange={handleChangeTaxNumber}
                    />
                    <span>-</span>
                    <BaseInput
                      name="secondTaxNum"
                      type="text"
                      value={inputValue.secondTaxNum}
                      width="10rem"
                      onChange={handleChangeTaxNumber}
                    />
                    <span>-</span>
                    <BaseInput
                      name="thirdTaxNum"
                      type="text"
                      value={inputValue.thirdTaxNum}
                      width="10rem"
                      onChange={handleChangeTaxNumber}
                    />
                  </div>
                  {showWarningTaxNumber && <p className="inputErrorMessage">사업자 등록번호를 정확히 입력해 주세요.</p>}
                </td>
              </tr>
            )}
            <tr>
              <th>충전금액</th>
              <td>
                <BaseInput
                  name="payAmt"
                  type="text"
                  width="12rem"
                  height="2.2rem"
                  onChange={handleChange}
                  value={inputValue.payAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
                {showWarningPayAmt && <p className="inputErrorMessage">충전 금액을 정확히 입력해 주세요.</p>}
              </td>
            </tr>
          </tbody>
        </S.MyPageTable>

        <ATitle type="sub" text="개인정보 수집 및 이용에 대한 안내" />

        <S.TextP>
          수집하는 개인정보 항목당사는 현금영수증 발행요청을 위해 아래와 같은 개인정보를 수집하고 있습니다.
          <br />
          - 수집항목 : 사업자등록번호, 휴대폰번호, 성명
          <br />
          - 개인정보 수집방법 : 홈페이지(현금영수증 요청)
          <br />
          개인정보의 수집 및 이용목적당사는 수집한 개인정보를 다음 목적을 위해 활용합니다.
          <br />
          - 현금영수증 발행
          <br />
          개인정보의 보유 및 이용기간
          <br />
          - 당사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 파기합니다.
          <br />* 그 밖의 사항은 개인정보취급방침을 준수합니다.
        </S.TextP>

        <S.CheckBoxCover>
          <CBS.CheckBoxInput type="checkBox" id="check" ref={checkRef} value={flag} onChange={flagClick} />
          <S.CheckBoxLabel htmlFor="check" style={{ lineHeight: '2.1rem' }}>
            <span>[필수]</span>위의 내용을 숙지하였으며, 5가지 항목에 대해 모두 동의합니다.
          </S.CheckBoxLabel>
        </S.CheckBoxCover>
        <ButtonList buttonList={buttonList} />
      </S.CashReceiptForm>

      <S.BottomP>
        <BaseGuide text="현금영수증은 당일만 발행 가능합니다. (이전 날짜에 대한 충전금액 발행 불가)" />
        <p className="mainColor">
          신용카드 결제는 세금계산서 발행이 불가능하며 영수증은 충전소 &gt; 결제내역에서 출력하거나 카드사 홈페이지를
          방문하여 주시기 바랍니다.
        </p>
      </S.BottomP>
    </S.FormContainer>
  );
};
export default MobileEtcRegisterTaxInvoice;
