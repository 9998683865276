import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { LuSaveAll } from 'react-icons/lu';

import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';
import { softPhoneSaveMessageRecoil } from '@/recoil/atoms/router/mainRouterRecoil';

const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.txt200};

  &:hover,
  &.active {
    color: ${(props) => props.theme.colors.main};
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const SaveMessageBtn = () => {
  /* 메인 주소록 테이블 width  */
  const [, setMainTableWidth] = useRecoilState(mainTableWidthRecoil);
  const [saveMessage, setSaveMessage] = useRecoilState(softPhoneSaveMessageRecoil);

  const toggleModal = () => {
    const newSaveMessage = saveMessage === false ? true : false;
    setSaveMessage(newSaveMessage);

    const tableWidth = (document.getElementById('mainTable')?.clientWidth ?? 1100) - 450;
    if (newSaveMessage === false) {
      setMainTableWidth(0);
    } else {
      setMainTableWidth(tableWidth);
    }
  };

  return (
    <SaveButtonContainer className={`${saveMessage ? 'active' : ''}`} onClick={toggleModal} title="문자 저장함">
      {/* <SaveIcon /> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
          d="M12.4997 2.28424C11.2767 2.22848 10.0514 2.24083 8.82973 2.32124C4.64573 2.59924 1.31373 5.97824 1.03973 10.2212C0.986757 11.0604 0.986757 11.9021 1.03973 12.7412C1.13973 14.2862 1.82273 15.7172 2.62773 16.9252C3.09473 17.7702 2.78673 18.8252 2.29973 19.7482C1.94973 20.4132 1.77373 20.7452 1.91473 20.9852C2.05473 21.2252 2.36973 21.2332 2.99873 21.2482C4.24373 21.2782 5.08273 20.9262 5.74873 20.4352C6.12573 20.1562 6.31473 20.0172 6.44473 20.0012C6.57473 19.9852 6.83173 20.0912 7.34373 20.3012C7.80373 20.4912 8.33873 20.6082 8.82873 20.6412C10.2537 20.7352 11.7427 20.7352 13.1707 20.6412C17.3537 20.3632 20.6857 16.9832 20.9597 12.7412C20.9804 12.4132 20.9931 12.0829 20.9977 11.7502M7.49973 14.2502H14.4997M7.49973 9.25024H10.9997"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9997 6.75024C15.4907 7.25624 16.7997 9.25024 17.4997 9.25024M17.4997 9.25024C18.1997 9.25024 19.5087 7.25624 19.9997 6.75024M17.4997 9.25024V1.25024"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SaveButtonContainer>
  );
};

export default SaveMessageBtn;
