import { atom } from 'recoil';
import { IGroupBuddyListParams } from '@/components/hooks/queries/queryTypes';
import { TSelectNumber } from '@/types/hook/useSelectNumber.type';
import { BuddyData } from '@/types/index.types';

export interface IClickGroupName {
  groupSeqNo: number;
  groupNm: string;
}
// 그룹이름클릭시 저장할 그룹데이터
export const clickGroupNameRecoil = atom<IClickGroupName | null>({
  key: 'clickGroupNameRecoil',
  default: null,
});

// 휴지통 클릭시 삭제 리스트를 위한 토글 상태
export const recycleBinListToggleState = atom<boolean>({
  key: 'recycleBinListToggleState',
  default: false,
});

// 휴지통 검색시 저장할 select 벨류
export const binSelectValueState = atom<string>({
  key: 'binSelectValueState',
  default: 'name',
});

// 휴지통 검색시 저장할 input 벨류
export const binSearchTextState = atom<string>({
  key: 'binSearchTextState',
  default: '',
});

// 휴지통 검색 성공시 저장할 밸류(검색 후 해당 리스트 전체 복원, 삭제 사용)
export const binSuccessSearchRecoil = atom<string>({
  key: 'binSuccessSearchRecoil',
  default: '',
});

export const currentPageState3 = atom({
  key: 'currentPageState3',
  default: 1,
}); // 현재 페이지를 관리합니다.

// 모달창 상태
export const viewModalAtom = atom({
  key: 'viewModal',
  default: false,
});

/** 리뉴얼 주소록 */
// 그룹 체크박스
export const checkGroupListState = atom<number[]>({
  key: 'checkGroupListState',
  default: [],
});

// 휴지통 체크박스
export const checkedBinListState = atom<number[]>({
  key: 'checkedBinListState',
  default: [],
});

// 발신번호 리스트
export const callbackNumberState = atom<any[] | undefined>({
  key: 'callbackNumberState',
  default: [],
});

// 선택한 발신번호 List
export const selectNumberState = atom<TSelectNumber>({
  key: 'selectNumberState',
  default: {
    '010': '',
    '015': '',
  },
});

// 메세지 전송시 미등록 연락처
export const unRegisteredPhoneNum = atom<string[] | null>({
  key: 'unRegisteredPhoneNum',
  default: null,
});

// 대량추가 그룹 label 상태
export const selectGroupRecoil = atom<string>({
  key: 'selectGroupRecoil',
  default: '그룹 선택',
});

// 간편추가 toggle
export const tableAddOpenRecoil = atom<boolean>({
  key: 'tableAddOpenRecoil',
  default: false,
});

// 대량추가 toggle
export const openLargeToggleRecoil = atom<boolean>({
  key: 'openLargeToggleRecoil',
  default: false,
});

// 주소록 리스트 데이터 GET API PARAMS
export const groupBuddyListParamsR = atom<IGroupBuddyListParams>({
  key: 'groupBuddyListParamsR',
  default: { groupSeqNo: null, keyword: null, keywordValue: 'name', pageSize: 30, pageNumber: 0 },
});

// 주소록 중복정리 모달 toggle
export const dupliModalToggleRecoil = atom<boolean>({
  key: 'dupliModalToggleRecoil',
  default: false,
});

// 주소록 복사 Select toggle
export const copySelectToggleRecoil = atom<boolean>({
  key: 'copySelectToggleRecoil',
  default: false,
});

// 주소록 개별 복사 buddySeqNo
export const copyBuddySeqNoRecoil = atom<number | null>({
  key: 'copyBuddySeqNoRecoil',
  default: null,
});

// 주소록 상세페이지 buddyData
export const detailBuddyDataRecoil = atom<BuddyData | null>({
  key: 'detailBuddyDataRecoil',
  default: null,
});

// 그룹 검색어가 없을때 전체 체크박스 토글상태를 위한 트리거 Recoil
export const groupSeachTextToggleRecoil = atom<boolean>({
  key: 'groupSeachTextToggleRecoil',
  default: false,
});
