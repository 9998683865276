import { useModalHook } from '@/components/commons/modals/useModalHook';
import { theme } from '@/styles/theme';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SignUpContainer } from '../SignUpPage/signUp/SignUp.style';
import * as SS from '../SignUpPage/signUpIdCheck/SignUpIdCheck.style';
import { getFindId } from '@/apis/api/findAccountApis';
import { useDeleteAccount } from '@/apis/hooks/useDeleteAccount';
import useRecoveryAccount from '@/apis/hooks/useRecoveryAccount';
import BaseButton from '@/components/Atom/BaseButton';
import { useQuery } from '@tanstack/react-query';

interface PhnIdProps {
  dealGb: string;
  phnId: string;
  regDate: string;
  rsrvdId: string;
}

const FindAccountList = () => {
  const location = useLocation();
  const info: any = location.state;
  const { warningModal } = useModalHook();
  const { data, isLoading } = useQuery(['/users/id'], () => getFindId(info.encodeData));

  const { handleIdRecovery } = useRecoveryAccount();
  const { handleAccountDelete } = useDeleteAccount();

  if (isLoading) {
    return <div>로딩중</div>;
  }
  if (data.phnIds.length <= 0) {
    warningModal('경고', '아이디가 존재하지 않습니다.</br> 회원가입을 진행해주세요.', true, '/findaccount');
  }

  return (
    <SignUpContainer>
      <SS.SignUpFormWrapper>
        <SS.Say015ApplyTitle>
          <span style={{ color: theme.colors.main }}>아이디 조회결과 </span>입력하신 정보와 일치하는 아이디는 아래와
          같습니다.
        </SS.Say015ApplyTitle>
        <SS.SignUpLine className="top" />

        {data?.phnIds.map((number: PhnIdProps, id: number) => {
          return (
            <>
              {/* 구분선 */}
              {number.dealGb === '1' ? null : <SS.DividingLine />}
              <SS.SignUpSubTitleWrap key={number.phnId}>
                <SS.IdCheckSmallWrap>
                  {/* <SS.SignUpSubTitle>아이디{id + 1}</SS.SignUpSubTitle> */}
                  {number.dealGb === '1' ? (
                    <SS.SignUpTitle>사용계정</SS.SignUpTitle>
                  ) : (
                    <SS.SignUpTitle>휴면아이디</SS.SignUpTitle>
                  )}
                </SS.IdCheckSmallWrap>
                <SS.IdCheckBigWrap>
                  <SS.SignUpSubTitle className={`${number.dealGb === '1' ? '' : 'dormant'}`}>
                    {number.phnId.replace(/(\d{7})\d{4}(\D*)/, (match, group1, group2) => {
                      const lastFourDigits = group1; // 숫자 중 마지막 4자리
                      const remainingChars = group2; // 숫자 이후의 문자열
                      if (group2 === '') {
                        return `${lastFourDigits}****`; // 마지막 4자리 외의 숫자를 ****로 변경
                      }
                      return `${lastFourDigits}****`; // 마지막 4자리 외의 숫자를 ****로 변경
                      // return `${lastFourDigits}****${remainingChars}`; // 마지막 4자리 외의 숫자를 ****로 변경
                    })}
                    {/* (가입일 : {number.regDate}){number.dealGb !== '1' && ' -- 휴면아이디'} */}
                  </SS.SignUpSubTitle>
                  {number.dealGb === '1' ? (
                    <>
                      {/* <BaseButton>
                      <Link to="/signin">로그인</Link>
                    </BaseButton> */}

                      <BaseButton
                        className="dark"
                        name="findPw"
                        value={number.phnId}
                        onClick={(e: React.MouseEvent<HTMLElement>) =>
                          handleIdRecovery(e, number.rsrvdId, data.encodeData, data.inputNumber, number.dealGb)
                        }
                      >
                        비밀번호 변경
                      </BaseButton>
                    </>
                  ) : (
                    <>
                      <BaseButton
                        name="RecoveryId"
                        value={number.phnId}
                        onClick={(e: React.MouseEvent<HTMLElement>) =>
                          handleIdRecovery(e, number.rsrvdId, data.encodeData, data.inputNumber, number.dealGb)
                        }
                      >
                        아이디 복원
                      </BaseButton>
                      <BaseButton
                        className="dark"
                        value={number.phnId}
                        onClick={(e) => handleAccountDelete({ e, data: info.encodeData })}
                      >
                        아이디 삭제
                      </BaseButton>
                    </>
                  )}
                </SS.IdCheckBigWrap>
                <SS.SignUpSub>
                  (가입일 : {number.regDate}){/* {number.dealGb !== '1' && ' -- 휴면아이디'} */}
                </SS.SignUpSub>
              </SS.SignUpSubTitleWrap>
            </>
          );
        })}
        <SS.SignUpLine className="bottom" />
        <SS.AgreementButtonWrap>
          <Link to="/signin">
            <BaseButton fontWeight="bold">로그인하러 가기</BaseButton>
          </Link>
        </SS.AgreementButtonWrap>
        <SS.Say015ApplyServiceGuide>
          개인정보 도용에 따른 피해방지를 위해 끝4자리는 *로 표시합니다.
          <br /> 만일 도용으로 가입된 아이디가 있는 경우, 고객센터(015-8504-0006)를 통해 본인 확인 절차를 거친 후 탈퇴도
          가능합니다.
          <br />
          <br /> 휴면아이디란?
          <br /> 아이디는 가입이 되어 있으나 사용할 수 없는 아이디를 말합니다.
          <br />
          <br /> 1. 사이트를 오랫동안 방문하지 않은 회원의 아이디
          <br /> 2. 휴대폰번호가 변경이 되었으나 변경하지 못해, 이미 다른 회원이 동일한 휴대폰번호로 가입한 경우
          <br />
          <br /> * 사용하시려면 아이디 복원을, 더 이상 사용을 원치 않을 경우 아이디 삭제 버튼을 클릭하여 주세요.
          <br />
          <br /> 아이디 삭제와 동시에 현재 회원님의 서울이동통신 캐쉬 및 포인트,
          <br /> 그리고 주소록 내의 연락처가 모두 삭제되며 회원님이 이용하셨던 모든 정보서비스 이용이 불가능하게 됩니다.
          <br /> 또한 회원약관에 따라 아이디 삭제 후, 1개월 동안은 동일한 번호로 재가입이 불가능하오니 이점 양지하시기
          바랍니다.
        </SS.Say015ApplyServiceGuide>
      </SS.SignUpFormWrapper>
    </SignUpContainer>
  );
};

export default FindAccountList;
