import { atom } from 'recoil';

/**
 * @title 015 부가 서비스 상태
 */

type My015StatusRecoilType = { ttsCount: number; sttTime: number; say015Point: number; messageNotiStatus: boolean };

export const my015StatusRecoil = atom<My015StatusRecoilType | null>({
  key: 'my015StatusRecoil',
  default: null,
  //   default: { ttsCount: 0, sttTime: 0, say015Point: 0, messageNotiStatus: false },
});
