import styled from 'styled-components';

export const ReSubscription = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const ReSubscriptionWrap = styled.div`
  /* overflow-y: auto;
	overflow-x: hidden; */
  height: 100%;
  overflow-y: auto;
  margin: 0 -1rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:last-child {
    overflow: unset !important;
  }

  .amountGroup {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    /* margin-bottom: 3rem; */
  }
`;

export const ReSubscriptionGroup = styled.div`
  color: #191919;
  margin-bottom: 4rem;
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .phoneGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .phone {
      width: calc(100% - 10rem);
      overflow-x: auto;
      padding: 1rem;
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      letter-spacing: 6.4px;
      border-radius: 50px;
      background: #fff;
      border: 1px solid #98999a;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
      ::-webkit-scrollbar {
        display: none;
      }
      span {
        margin: 0 5%;
      }
    }
  }
  .comment {
    margin-top: 1rem;
    text-align: right;
    color: #6e6e71;
    font-size: 1.2rem;
    line-height: normal;
    font-weight: 600;
    padding: 0 2%;
  }

  @media screen and (max-width: 540px) {
    .phoneGroup {
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      h2 {
        font-size: 1.8rem;
      }
      .phone {
        width: 100%;
      }
    }
  }
`;
