import React from 'react';
import * as S from './AddressSearch.styles';
import * as IS from '@/styles/input.styles';
import { useAddressSearch } from '@/components/hooks/customs/addressBook/useAddressSearch';
import BaseButton from '@/components/Atom/BaseButton';

// 검색창 - 그룹, 이름 등 검색 (현재 주소록 상단에 위치)
const AddressSearch = () => {
  const { onChangeSearchInput, searchTextR, onClickSearchButton, onClickSearchDelBtn } = useAddressSearch();
  return (
    <S.SearchWrap className="searchWrap">
      <form onSubmit={() => onClickSearchButton} aria-label="검색창">
        <S.SearchWrapper>
          <IS.InputBox>
            {/* 돋보기 */}
            <BaseButton type={'submit'} backgroundColor="transition" onClick={onClickSearchButton}>
              {/* <RxMagnifyingGlass size={20}/> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                <path
                  d="M8.66667 16.3333C12.9009 16.3333 16.3333 12.9009 16.3333 8.66667C16.3333 4.43248 12.9009 1 8.66667 1C4.43248 1 1 4.43248 1 8.66667C1 12.9009 4.43248 16.3333 8.66667 16.3333Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.25 18.25L14.0813 14.0812"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </BaseButton>
            <input
              type="text"
              onChange={onChangeSearchInput}
              value={searchTextR}
              placeholder="이름 및 휴대폰번호 검색"
            />
            {/* 삭제 버튼 */}
            {searchTextR && <BaseButton className="delBtn" onClick={onClickSearchDelBtn} />}
          </IS.InputBox>
        </S.SearchWrapper>
      </form>
    </S.SearchWrap>
  );
};

export default AddressSearch;
