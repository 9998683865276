import styled from 'styled-components';

export const UserInfoChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

// 버튼 컨테이너
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
// 이메일박스 폼
export const EmailBoxForm = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  select {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 640px) {
    select {
      font-size: 1rem;
    }
  }
`;

// 서울이동통신 이메일 지정 체크박스
export const AgreeCheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;
// 이메일 입력인풋
export const EmailInput = styled.input`
  height: 100%;
  width: 50%;
  max-width: 200px;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.txt500};
  background: transparent;
  /* font-weight: lighter; */

  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;
// 주소창 컨테이너
export const AddressContainer = styled.div`
  width: 100%;
`;
// 주소창 이름, 인풋 워랩
export const AddressWrap = styled.div`
  width: 100%;
  display: flex;
  margin: 0.5rem 0;
  .inputWrap {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    width: calc(100% - 24rem);
    max-width: 60rem;
    margin-right: 1.8rem;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;

    .inputWrap {
      width: 100%;
      input {
        padding: 1rem;
        font-size: 1.4rem;
        :nth-child(1) {
          width: 7rem;
        }
        :nth-child(2) {
          width: calc(100% - 7rem);
          background: transparent;
          border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
        }
      }
    }
  }
  @media screen and (max-width: 460px) {
    flex-wrap: wrap;
    .inputWrap {
      width: 100%;
      flex-wrap: wrap;
      gap: 0;
      margin-right: 0;
      input {
        :nth-child(1) {
          width: 60%;
          min-width: 7rem;
          margin: 0 0 0.5rem;
        }
        :nth-child(2) {
          width: 100%;
          margin: 0 0 0.5rem;
        }
      }
    }
  }
`;
// 주소창 이름 디브
export const InputName = styled.div`
  width: 6%;
  padding-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    width: 100%;
    /* margin-bottom: 1rem; */
  }
`;
// 닉네임 입력 디브
export const NicknameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > span {
    line-height: 20px;
  }
`;
// 닉네임 입력 인풋
export const NicknameInput = styled.input`
  height: 100%;
  width: 50%;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.txt500};
  background: transparent;
  /* font-weight: lighter; */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;
// 연락처 인풋 워랩
export const PhonenumberWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    input {
      width: calc(40% - 3rem);
    }
    span {
      width: 100%;
    }
  }
`;
// 연락처 입력인풋
export const FirstPhoneumberInput = styled.input`
  height: 100%;
  width: 5%;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  /* font-weight: lighter; */
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;
export const SecondPhoneumberInput = styled.input`
  height: 100%;
  width: 5%;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: #000000;
  /* font-weight: lighter; */
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;
// 체크박스 워랩
export const CCheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  & > fieldset {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  & label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  & label:nth-child(2) {
    margin-left: 5px;
  }

  & input {
    margin-top: 2px;
  }

  p {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    & > fieldset {
      flex-wrap: wrap;
    }
    p {
      width: 100%;
      order: -1;
    }
  }
`;
// 광고문자 전체 워랩
export const AdsmsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > fieldset {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  & label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  & label:nth-child(2) {
    margin-left: 5px;
  }

  & input {
    margin-top: 2px;
  }

  p {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  @media screen and (max-width: 768px) {
    & > fieldset {
      flex-wrap: wrap;
    }
    p {
      width: 100%;
      order: -1;
    }
  }
`;
// 광고문자 수신제한 워랩
export const LimitedWrap = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
// 광고문자 수신제한 인풋
export const LimitedInput = styled.input`
  width: 15%;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: #000000;
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;
//
export const AgreeCheckbox = styled.input``;

// 통합 셀렉트
export const Selected = styled.select`
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: #000000;
`;

export const BirthDateWrap = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
