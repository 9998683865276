import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.6rem;
  height: 2.1rem;
`;

const IconHuman: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, width, height, fill }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      width={width ?? '16'}
      height={height ?? '21'}
      viewBox="0 0 16 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.05811 0.341309C6.83679 0.341309 5.6655 0.826475 4.80189 1.69008C3.93829 2.55368 3.45313 3.72497 3.45312 4.94629C3.45313 6.16761 3.93829 7.3389 4.80189 8.20251C5.6655 9.06611 6.83679 9.55127 8.05811 9.55127C9.27943 9.55127 10.4507 9.06611 11.3143 8.20251C12.1779 7.3389 12.6631 6.16761 12.6631 4.94629C12.6631 3.72497 12.1779 2.55368 11.3143 1.69008C10.4507 0.826475 9.27943 0.341309 8.05811 0.341309ZM4.90733 4.94629C4.90733 4.11065 5.23929 3.30924 5.83017 2.71836C6.42106 2.12747 7.22247 1.79551 8.05811 1.79551C8.89375 1.79551 9.69516 2.12747 10.286 2.71836C10.8769 3.30924 11.2089 4.11065 11.2089 4.94629C11.2089 5.78193 10.8769 6.58334 10.286 7.17423C9.69516 7.76511 8.89375 8.09707 8.05811 8.09707C7.22247 8.09707 6.42106 7.76511 5.83017 7.17423C5.23929 6.58334 4.90733 5.78193 4.90733 4.94629Z"
        fill={fill ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8988 12.2368C4.25605 11.4613 6.08157 11.0056 8.05831 11.0056C10.036 11.0056 11.8606 11.4613 13.2178 12.2368C14.5538 13.0008 15.5717 14.1661 15.5717 15.6106C15.5717 17.0551 14.5538 18.2204 13.2178 18.9844C11.8606 19.7599 10.0351 20.2156 8.05831 20.2156C6.0806 20.2156 4.25605 19.7599 2.8988 18.9844C1.56287 18.2204 0.544922 17.0551 0.544922 15.6106C0.544922 14.1661 1.56287 13.0008 2.8988 12.2368ZM3.62008 13.5001C2.50034 14.1399 1.99913 14.9126 1.99913 15.6106C1.99913 16.3086 2.50034 17.0813 3.62008 17.7211C4.71946 18.3494 6.28709 18.7614 8.05831 18.7614C9.82954 18.7614 11.3972 18.3494 12.4965 17.7211C13.6163 17.0813 14.1175 16.3076 14.1175 15.6106C14.1175 14.9126 13.6163 14.1399 12.4965 13.5001C11.3972 12.8718 9.82954 12.4598 8.05831 12.4598C6.28709 12.4598 4.71946 12.8718 3.62008 13.5001Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconHuman;
