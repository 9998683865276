/* eslint-disable */
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { instance } from '@/shared/lib/clientAxios';
import { audioController } from '@/shared/hooks/useAudio';
import { useRecoilState } from 'recoil';
import { vmsGetApiIsLoadingRecoil } from '@/recoil/atoms/chatRoom';

interface IUseMusicPlayer {
  filename: string;
}

export const useMusicPlayer = ({ filename }: IUseMusicPlayer) => {
  const [, setVmsGetApiIsLoadingS] = useRecoilState(vmsGetApiIsLoadingRecoil);

  const {
    setAudioUrl,
    playSwitchBtn,
    handlePlay,
    handleTimeUpdate,
    handleBackTime,
    handleFrontTime,
    handleEnded,
    currentTime,
    handleLoadedMetadata,
    audioTime,
    audioUrl,
    audioRef,
    duration,
    durationView,
  } = audioController();

  /* 오디오 파일 요청 API */
  const { data, isLoading } = useQuery(['audio', filename], async () => {
    const response = await instance.get(`/vms/voice-record/${filename}`, {
      responseType: 'blob',
    });
    return response.data;
  });

  // url 변환
  useEffect(() => {
    setVmsGetApiIsLoadingS(isLoading);
    if (data && !isLoading) {
      const audioUrl = URL.createObjectURL(data);
      setAudioUrl(audioUrl);
    }
  }, [data, isLoading]);

  return {
    audioTime,
    durationView,
    currentTime,
    playSwitchBtn,
    handlePlay,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleBackTime,
    handleFrontTime,
    handleEnded,
    audioRef,
    duration,
    audioUrl,
  };
};
