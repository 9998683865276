import { QueryClient, QueryClientProvider, useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { Reset } from 'styled-reset';
import Footer from './components/common/Footer';
import * as S from './styles/Global.styles';

import Body from '@/components/common/Body';
import SkeletonPopup from '@/components/common/SkeletonPopup';
import InitSetting from '@/pages/InitSettingPage/InitSettingPage';
import axios from 'axios';
import { CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga4';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { ThemeProvider } from 'styled-components';
import { postAddMobileGroup } from './apis/api/addressApis';
import { useModalHook } from './components/commons/modals/useModalHook';
import MobileLoader from './components/mobileApp/MobileLoader';
import GlobalModal from './pages/main/components/modal/globalModal/GlobalModal';
import { environmentRecoil, prevPathNameRecoil, syncLoadingRecoil } from './recoil/atoms/MobileRecoil/MobileRecoil';
import { globalModalStateRecoil, isDarkModeRecoil, windowWidthRecoil } from './recoil/atoms/router/mainRouterRecoil';
import AppRoutes from './routes/AppRoutes';
import MobileRoutes from './routes/MobileRoutes';
import ScrollToTop from './routes/SrollToTop';
import { darkTheme, theme } from './styles/theme';

// 높이값 가져오기위함
function setScreenSize(): void {
  const vh: number = window.innerHeight;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// 높이 resize
const setVh = () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
};
window.addEventListener('resize', setVh);
setVh();

const AppContent = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // 현재 경로 가져오기
  const location = useLocation();
  const { isOpen } = useRecoilValue(globalModalStateRecoil);
  const [windowWidth, setWindowWidth] = useRecoilState(windowWidthRecoil);
  const { customSuccessModal, customWarningModal } = useModalHook();

  //모바일 환경을 체크 하는 유즈 이펙트
  //기준: 처음 들어올때 webView라는게 안들어와서 navigator.userAgent 를 게속
  // 주시 후 webView 업데이트
  // ************************************************************************
  const environmentS = useRecoilValue(environmentRecoil);
  const setPrevPathNameS = useSetRecoilState(prevPathNameRecoil);

  // TODO - css를 사용하고 있는 곳에서 너무 많이 사용중 -> 없앴다가 다시 생성했습니다.
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // 리스너 등록
    window.addEventListener('resize', handleResize);

    // 컴포넌트가 언마운트될 때 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setPrevPathNameS(location.pathname);
  }, [location]);

  // ************************************************************************

  //모바일 주소록 동기화 시킬때 로딩창
  const [syncLoading, setSyncLoading] = useRecoilState(syncLoadingRecoil);

  const { mutate: addGroupMutate } = useMutation(postAddMobileGroup, {
    onMutate: () => {
      setSyncLoading({ state: true, contents: '모바일 기기에서 주소록을 동기화 중입니다.' });
    },
    onSuccess: () => {
      customSuccessModal(
        () => {
          setSyncLoading({ state: false, contents: '' });
          queryClient.refetchQueries(['groupList2']);
        },
        '성공',
        '주소록 정보가 동기화 되었습니다.',
        true,
      );
    },
    onError: (error) => {
      customWarningModal(
        () => {
          setSyncLoading({ state: false, contents: '' });
        },
        '실패',
        `주소록 정보 동기화에 실패 했습니다.(주소록에서 모바일 주소록 동기화를 다시 한번 진행해 주세요): ${error}`,
        true,
      );
    },
    onSettled: () => {
      navigate('/mobile/address/groupList');
    },
  });

  useEffect(() => {
    if (environmentS === 'app') {
      window.getAppToken = (fcmToken: any) => {
        try {
          const payload = {
            token: fcmToken,
            clientEnv: 'app',
          };

          const token = localStorage.getItem('authorizationToken');

          // API 요청으로 서버에 전송
          axios.post(`${process.env.REACT_APP_SERVER_URL}/api/push/chrome/subscribe`, payload, {
            headers: {
              Authorization: `Bearer ${token}`, // 토큰을 헤더에 추가
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          alert(`토큰 오류 발생: ${error}`);
        }
      };

      window.autoAddressSync = (addressList, id) => {
        if (id) {
          type TAddress = { name: string; phone: string };

          const parseAddressList: TAddress[] = JSON.parse(addressList).filter(
            (item: any): item is TAddress => typeof item.phone === 'string' && typeof item.name === 'string',
          );

          type TPayload = {
            contacts: { buddyNm: string; keyCommNo: string }[];
          };

          const payload: TPayload = {
            contacts: parseAddressList.map((item: TAddress) => ({
              buddyNm: item.name,
              keyCommNo: item.phone.replace(/\D/g, ''), // 전화번호에서 숫자만 추출
            })),
          };
          setSyncLoading({ state: false, contents: '' });
          addGroupMutate(payload);
        } else {
          customWarningModal(
            () => {
              setSyncLoading({ state: false, contents: '' });
            },
            '실패',
            '주소록 권한을 확인 해주세요.',
            true,
          );
        }
      };

      window.autoRefresh = () => {
        // 백그라운드에서 포그라운드 넘어갈 때 쓰이는 함수
        // navigate(location.pathname);
        // window.location.reload();
      };
    }
  }, [environmentS]);

  return (
    <>
      <InitSetting />
      {/* <Header /> */}
      <SkeletonPopup
        style={{ height: '25px' }}
        title={false}
        paragraph={{ rows: 3, width: ['50%', '35%', '80%'] }}
        active
      />
      <Body>
        <>
          {/* 페이지 라우터 */}
          {environmentS === 'app' ? <MobileRoutes /> : <AppRoutes />}
          {isOpen && <GlobalModal />}
        </>
      </Body>
      {(windowWidth > 768 || location.pathname === '/signin') &&
        !location.pathname.startsWith('/mobile') &&
        environmentS !== 'app' && <Footer />}
    </>
  );
};
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
//**************************************************************************************************************************************************************************************** */
const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error: any) => {
          const status = error.response ? error.response.status : null;
          return status === 401;
        },
      },
      mutations: {
        retry: (failureCount, error: any) => {
          const status = error.response ? error.response.status : null;
          return status === 401;
        },
      },
    },
  });

  // 다크모드 타입
  const isDarkModeS = useRecoilValue(isDarkModeRecoil);

  // 구글 애널리틱스 연결
  useEffect(() => {
    setScreenSize();
    ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);
  }, []);

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <ThemeProvider theme={isDarkModeS === 'darkTheme' ? darkTheme : theme}>
          {/* <ThemeWrap className="themeToggle">
            {isDarkMode === 'lightTheme' ? (
              <button onClick={() => onClickThemeMode('darkTheme')}>🐥</button>
            ) : (
              <button
                onClick={() => {
                  onClickThemeMode('lightTheme');
                }}
              >
                🦉
              </button>
            )}
          </ThemeWrap> */}
          <S.Container>
            <Reset />
            <S.GlobalStyle />
            <BrowserRouter>
              <ScrollToTop /> {/* 페이지 이동시 스크롤 상단으로 이동 */}
              <AppContent />
            </BrowserRouter>
          </S.Container>
        </ThemeProvider>
      </QueryClientProvider>
    </CookiesProvider>
  );
};

export default App;
