import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.9rem;
  height: 1.3rem;
`;

const IconHam: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, width, height, fill }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      width={width ?? '19'}
      height={height ?? '13'}
      viewBox="0 0 19 13"
      fill="none"
    >
      <path
        d="M1.71094 12.0146H17.7109C18.2609 12.0146 18.7109 11.5646 18.7109 11.0146C18.7109 10.4646 18.2609 10.0146 17.7109 10.0146H1.71094C1.16094 10.0146 0.710938 10.4646 0.710938 11.0146C0.710938 11.5646 1.16094 12.0146 1.71094 12.0146ZM1.71094 7.01465H17.7109C18.2609 7.01465 18.7109 6.56465 18.7109 6.01465C18.7109 5.46465 18.2609 5.01465 17.7109 5.01465H1.71094C1.16094 5.01465 0.710938 5.46465 0.710938 6.01465C0.710938 6.56465 1.16094 7.01465 1.71094 7.01465ZM0.710938 1.01465C0.710938 1.56465 1.16094 2.01465 1.71094 2.01465H17.7109C18.2609 2.01465 18.7109 1.56465 18.7109 1.01465C18.7109 0.464648 18.2609 0.0146484 17.7109 0.0146484H1.71094C1.16094 0.0146484 0.710938 0.464648 0.710938 1.01465Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconHam;
