import ArsTreeComponent from '@/widgets/ars/components/arsTree/ui/ArsTree.index';
import React from 'react';
import * as S from './MobileArsTree.style';

const MobileArsTree = () => {
  return (
    <S.MobileArsContainer className="mobileArsTree">
      {/* <ArsWidgetIndex /> */}
      <ArsTreeComponent />
    </S.MobileArsContainer>
  );
};

export default MobileArsTree;
