import styled from 'styled-components';

export const SignUpFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 72rem;
  padding: 9rem 4rem;
  margin: 10rem 0;
  flex-shrink: 0;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.background};
  border-radius: 1rem;

  /* 서울이동통신 회원가입 */
  &::before {
    content: '회원가입';
    display: block;
    color: ${(props) => props.theme.colors.txt500};
    text-align: center;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 5rem;
  }
  @media screen and (max-width: 520px) {
    margin: 0;
    padding: 6rem 1.6rem;
  }
`;

export const SignUpSubTitle = styled.p`
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
`;

export const SignUpInputForm = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 5rem 0;
`;

export const SignUpPhoneInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 0.5rem;
  .num015 {
    display: flex;
    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: 340px) {
    &:has(.num015) {
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
    }
  }
`;

export const SignUpInputSpan = styled.span`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.txt200};
  margin: 0 0.2rem;
`;

// 입력되어있는상태
export const SignUpEntered = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  min-width: 2rem;
  min-height: 4.2rem;
  border-radius: 0.5rem;
  background: ${(props) => props.theme.colors.gray100};
  color: ${(props) => props.theme.colors.txt300};
  font-size: 1.4rem;
  @media screen and (max-width: 520px) {
    width: auto;
    min-width: unset;
  }
`;

export const Row = styled.div`
  margin-bottom: 2rem;
  white-space: nowrap;
  input {
    width: 100%;
    min-height: 4.2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt500};
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    &.entered {
      background: ${(props) => props.theme.colors.gray100};
      color: ${(props) => props.theme.colors.txt300};
      border: 0;
    }
  }
  @media screen and (max-width: 520px) {
    input {
      &.entered {
        width: inherit;
      }
    }
  }
`;

export const Label = styled.h3`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.txt500};
  margin: 0 0 1rem;
`;

export const SignUpEmailInputWrap = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  .emailBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    margin-right: 0;
  }
  > * {
    margin-right: 1rem;
    :last-of-type {
      margin-right: 0;
    }
  }
  .select {
    width: 100%;
    select {
      padding: 1rem;
      width: 100%;
      min-height: 4.2rem;
      border: 1px solid transparent;
      font-size: 1.4rem;
      /* background: ${(props) => props.theme.colors.gray100}; */
      background: transparent;
      border-radius: 0.5rem;
      border-color: ${(props) => props.theme.colors.gray300};
    }
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    > * {
      margin-right: 0;
    }
  }
`;

export const SignUpEmailSpan = styled.span`
  padding: 0px 0.43vw;
  box-sizing: border-box;
  font-size: 1.5rem;
`;

export const SignUpSelect = styled.select``;
export const SignUpCheckEmail = styled.div`
  box-sizing: border-box;
  /* padding: 0 0 0 9.4vw; */
  margin-left: 130px;
  label,
  input {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const SignUpAdAgreeWrap = styled.div`
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 4rem;
`;
export const AdAgreeCheckBox = styled.input``;

export const AdAgreeLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 1.2rem;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const AdLabelWrap = styled.div`
  display: flex;
  width: 54px;
  input,
  label {
    cursor: pointer;
  }
`;
export const AgreeLabel = styled.label``;
export const DisagreeLabel = styled.label``;

export const SignCustomButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  button {
    padding: 1.2rem;
    width: 100%;
    max-width: 20rem;
    min-height: 5.2rem;
    font-size: 1.8rem;
    font-weight: 700;
  }
`;

export const ValidationError = styled.p`
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.red};
  @media screen and (max-width: 768px) {
    min-width: auto;
  }
`;

export const ValidationMessage = styled.p`
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt400};
`;

export const ValidationNumber = styled.p`
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt400};
  &.green {
    color: ${(props) => props.theme.colors.green};
  }
  &.red {
    color: ${(props) => props.theme.colors.red};
  }
`;

export const PhoneGroup = styled.div`
  position: relative;
  width: 100%;
  max-width: 12rem;
  .btn {
    width: 100%;
    height: 4.2rem;
    padding: 1rem;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt100};
    background: ${(props) => props.theme.colors.grayButton2};
    border-radius: 0.5rem;
  }
  .open {
    background: ${(props) => props.theme.colors.main};
  }
  .numberGroup {
    position: absolute;
    bottom: -0.6rem;
    left: 50%;
    transform: translateX(-50%);
  }
  ul {
    max-height: 21rem;
  }
  @media screen and (max-width: 520px) {
    width: auto;
  }
`;
