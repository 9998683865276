import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconFile: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      className={className ?? ''}
      fontSize={fontSize ?? '2.5rem'}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9403 11.5243L12.7503 20.7143C11.6244 21.8401 10.0975 22.4726 8.50529 22.4726C6.91311 22.4726 5.38613 21.8401 4.26029 20.7143C3.13445 19.5884 2.50195 18.0615 2.50195 16.4693C2.50195 14.8771 3.13445 13.3501 4.26029 12.2243L12.8303 3.65428C13.5809 2.90239 14.5994 2.47945 15.6618 2.47852C16.7241 2.47758 17.7434 2.89871 18.4953 3.64928C19.2472 4.39984 19.6701 5.41835 19.671 6.48074C19.672 7.54314 19.2509 8.56239 18.5003 9.31428L9.91029 17.8843C9.53501 18.2596 9.02602 18.4704 8.49529 18.4704C7.96456 18.4704 7.45557 18.2596 7.08029 17.8843C6.70501 17.509 6.49418 17 6.49418 16.4693C6.49418 15.9385 6.70501 15.4296 7.08029 15.0543L15.5703 6.57428"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconFile;
