import styled from 'styled-components';

// 검색어 인풋박스
export const InputBox = styled.div`
  padding: 0.8rem 1rem;
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 3.6rem;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray500};
  box-sizing: border-box;
  overflow: hidden;

  &:has(input:focus) {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  }

  button {
    display: inline-block;
    width: auto;
    height: auto;
    background-color: none;
    border: none;
    padding: 0;
    border-radius: 0;
    svg {
      width: 1.6rem;
      height: 1.6rem;
      color: ${(props) => props.theme.colors.placeholder};
    }
    @media screen and (max-width: 1800px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 480px) {
      font-size: 1rem;
    }
  }

  /* 삭제버튼 */
  .delBtn {
    position: relative;
    border: 0.2rem solid ${(props) => props.theme.colors.gray600};
    border-radius: 50%;
    min-width: 1.6rem;
    min-height: 1.6rem;
    background: transparent;
    ::before,
    ::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0.8rem;
      height: 0.2rem;
      background: ${(props) => props.theme.colors.gray600};
      border-radius: 20px;
    }
    ::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    ::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  input {
    width: 100%;
    transform-origin: right center;
    outline: none;
    border: none;
    color: ${(props) => props.theme.colors.txt500};
    background: transparent;
    font-size: 1.4rem;
    font-weight: 600;
    font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;

    &::placeholder {
      font-weight: 400;
      font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
      color: ${(props) => props.theme.colors.placeholder};
    }
  }

  /* @media screen and (max-width: 540px) {
    input {
      font-size: 1.2rem;
    }
  } */
`;
