import { useCallingNiceAdmin } from '@/components/hooks/customs/myPage/useCallingNiceAdmin';
import { useCallingNumberAdmin } from '@/components/hooks/customs/myPage/useCallingNumberAdmin';
import CallingNumberAdmin from '@/components/Organism/MyPage/CallingNumberAdmin';
import * as S from './MobileEtcCallbackNumber.style';
import React from 'react';
import CallingNumberTitle from '@/components/Molecules/MyPage/CallingNumberMoleCules/CallingNumberTitle';
import ATitle from '@/components/Atom/ATitle';
import CallingNumberList from '@/components/Molecules/MyPage/CallingNumberMoleCules/CallingNumberList';
import BaseButton from '@/components/Atom/BaseButton';
import CallingNumberInput from '@/components/Molecules/MyPage/CallingNumberMoleCules/CallingNumberInput';
import CallingNumberBottom from '@/components/Molecules/MyPage/CallingNumberMoleCules/CallingNumberBottom';

const MobileEtcCallbackNumber = () => {
  const {
    callbackListS,
    handleSubmit,
    handleDelete,
    register,
    errors,
    handleCertifyClick,
    allCallingNumberList,
    radioToggle,
    onChangeRadioToggle,
    allCallingNumberListRefetch,
  } = useCallingNumberAdmin();

  const niceHooks = useCallingNiceAdmin({ allCallingNumberListRefetch });

  return (
    <S.CallingNumberAdminWrap>
      {/* 발신번호 사전등록 ~ 발신번호 등록*/}
      <CallingNumberTitle />

      <ATitle type="sub" text="발신번호 등록" />
      <S.BWrapper>
        <CallingNumberList
          allCallingNumberList={allCallingNumberList}
          callbackListS={callbackListS}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
        />
        <S.ContentWrap>
          <S.RadioWrapper className="radioWrapper">
            <label htmlFor="nice" className={radioToggle === 'nice' ? 'acitve' : ''}>
              <input
                type="radio"
                id="nice"
                name="auth"
                value="nice"
                checked={radioToggle === 'nice'}
                onChange={(e) => onChangeRadioToggle(e)}
              />
              <p>본인인증</p>
            </label>
            <label htmlFor="paper" className={radioToggle === 'paper' ? 'acitve' : ''}>
              <input
                type="radio"
                id="paper"
                name="auth"
                value="paper"
                checked={radioToggle === 'paper'}
                onChange={(e) => onChangeRadioToggle(e)}
              />
              <p>서류제출</p>
            </label>
          </S.RadioWrapper>
          <S.CertifyWrapper>
            {radioToggle === 'nice' && (
              <S.ButtonWrapper className="mine">
                <BaseButton
                  width="12rem"
                  height="3rem"
                  fontSize="1.4rem"
                  fontWeight="bold"
                  onClick={() => niceHooks.onClickNiceBtn()}
                >
                  본인인증 등록
                </BaseButton>
              </S.ButtonWrapper>
            )}
            {radioToggle === 'paper' && <CallingNumberInput register={register} errors={errors} />}
            {radioToggle === 'paper' && (
              <S.ButtonWrapper className="document">
                <BaseButton
                  width="12rem"
                  height="3rem"
                  fontSize="1.4rem"
                  fontWeight="bold"
                  onClick={handleSubmit(handleCertifyClick)}
                >
                  등록하기
                </BaseButton>
              </S.ButtonWrapper>
            )}
          </S.CertifyWrapper>
        </S.ContentWrap>
      </S.BWrapper>
      <CallingNumberBottom />
    </S.CallingNumberAdminWrap>
  );
};
export default MobileEtcCallbackNumber;
