/* eslint-disable */
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useModalHook } from '@/components/commons/modals/useModalHook';
import {
  leftZoneComponentRecoil,
  listToggleRecoil,
  softPhoneComponentRecoil,
  softPhoneTopTabRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import {
  resultDetailParamsRecoil,
  scheduleDetailParamsRecoil,
  scheduleQueryKeyRecoil,
  scheduleTableDataRecoil,
} from '@/recoil/atoms/sendResult/sendResult';
import { useMutationStDelete } from '../../../mutations/useMutationStDelete';
import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';
import { msgResultPageRecoil, msgResultToggleRecoil } from '@/recoil/atoms/say015Atom';

/**
 * 전송/예약 관리 => 예약전송 관리 페이지
 */
export const useSendReservation = () => {
  const queryClient = useQueryClient();
  const pageSize: number = 10;
  const listRef = useRef<any>(null);
  const [totalPage] = useState(10);
  const [tableData, setTableData] = useRecoilState(scheduleTableDataRecoil);
  const [currentPage, setCurrentPage] = useState(0);
  const [checkBoxState, setCheckBoxState] = useState<{ prepayPayNo: string; callback: string }[]>([]);
  const softPhoneTopTabState = useRecoilValue(softPhoneTopTabRecoil);
  const [, setListToggleState] = useRecoilState(listToggleRecoil);
  const [scheduleDetailParamsState, setScheduleDetailParamsState] = useRecoilState(scheduleDetailParamsRecoil);
  const setResultDetailParamsState = useSetRecoilState(resultDetailParamsRecoil);

  const [searchAllValue, setSearchAllValue] = useRecoilState(scheduleQueryKeyRecoil);
  const softPhoneComponentS = useRecoilValue(softPhoneComponentRecoil);
  const setLeftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  /* 010 전송결과 페이지 토글 Recoil */
  const setMsgResultToggleS = useSetRecoilState(msgResultToggleRecoil);
  /* 메인 주소록 테이블 width  */
  const setMainTableWidth = useSetRecoilState(mainTableWidthRecoil);
  /* 010 전송결과인지 예약전송인지 Recoil */
  const setMsgResultPageS = useSetRecoilState(msgResultPageRecoil);

  const { warningModal } = useModalHook();

  const dayjs = require('dayjs');

  // api params 초기화
  useEffect(() => {
    setSearchAllValue({ pageNumber: 0, pageSize: 10, searchCondition: null, keyword: null });
  }, [softPhoneTopTabState, softPhoneComponentS]);

  // 페이싱네이션 - 페이지 변경
  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };
  // 예약전송 삭제 Mutation`
  const { mutationDelete, isDeleteLoading } = useMutationStDelete();

  const handleDelete = ({ deleteRequests }: any) => {
    setTimeout(() => {
      const cacheData: any = queryClient.getQueryData(['/sendList', searchAllValue.keyword, softPhoneTopTabState]);
      if (cacheData?.pages) {
        setScheduleDetailParamsState({ prepayPayNo: '', callback: '' });
        setListToggleState(false);
        setCheckBoxState([]);
        const cacheContent = cacheData.pages.map((item: any) => item.content);
        let removeData: any = [];
        if (deleteRequests.length === 1) {
          removeData = cacheContent.map((arr: any, index: number) => {
            return arr.filter((f: any) => f.prepayPayNo !== deleteRequests[0].prepayPayNo);
          });
        } else {
          removeData = cacheContent.map((arr: any) => {
            return arr.filter((f: any) => !deleteRequests.some((dr: any) => dr.prepayPayNo === f.prepayPayNo));
          });
        }

        const updatedData = {
          ...cacheData,
          pages: cacheData.pages.map((p: any, pageIndex: number) => {
            return {
              ...p,
              content: removeData[pageIndex],
            };
          }),
        };
        queryClient.setQueryData(
          ['getReserveSendList', searchAllValue.searchCondition, searchAllValue.keyword, softPhoneTopTabState],
          updatedData,
        );
        setTableData(updatedData);
      }
    }, 500);
  };

  // 선택된 리스트 예약 취소 함수
  const onCheckedListDelete = (e: any) => {
    e.preventDefault();
    if (checkBoxState.length === 0) {
      warningModal('예약전송 취소', '예약전송 취소하실 메세지를 선택해주세요.', true);
    } else {
      mutationDelete(
        { deleteRequests: checkBoxState },
        {
          onSuccess: () => {
            handleDelete({ deleteRequests: checkBoxState });
          },
        },
      );
    }
  };
  // 단일 리스트 예약 취소 함수
  const onDelete = ({ deleteRequests }: any, sndDttm: string) => {
    // 현재 시간
    const now = dayjs();
    // 받아온 데이터의 시간
    const receivedTime = dayjs(sndDttm, 'YYYYMMDDHHmmss');
    const tenMinutesLater = Math.abs(now.diff(receivedTime, 'minute'));
    if (tenMinutesLater <= 10) {
      return warningModal('실패', '전송 10분 전에는 취소할 수 없습니다.', true);
    }
    mutationDelete(
      { deleteRequests },
      {
        onSuccess: () => {
          handleDelete({ deleteRequests });
        },
        onError: () => {},
      },
    );
  };

  // 선택 리스트 ACTIVE 클래스 추가
  const handleActiveList = (prepayPayNo: string, callback: string) => {
    // 주소록 width 값 줄이기
    const tableWidth = (document.getElementById('mainTable')?.clientWidth ?? 1100) - 450;

    setResultDetailParamsState({ prepayPayNo: '', callback: '' });
    if (scheduleDetailParamsState.prepayPayNo !== prepayPayNo) {
      setMsgResultPageS('schedule');
      setLeftZoneComponentS('address');
      setMsgResultToggleS(true);
      setScheduleDetailParamsState({ prepayPayNo, callback });
      setMainTableWidth(tableWidth);
    } else {
      setMsgResultPageS(null);
      setLeftZoneComponentS('address');
      setMsgResultToggleS(false);
      setScheduleDetailParamsState({ prepayPayNo: '', callback: '' });
      setMainTableWidth(0);
    }
  };

  // 체크 박스 STATE 변경
  const handleCheckBox = (prepayPayNo: string, callback: string) => {
    setCheckBoxState((prevState: any) => {
      if (prevState.some((obj: any) => obj.prepayPayNo === prepayPayNo)) {
        return prevState.filter((el: any) => el.prepayPayNo !== prepayPayNo);
      }
      return [...prevState, { prepayPayNo, callback }];
    });
  };

  return {
    tableData,
    handleActiveList,
    handleCheckBox,
    checkBoxState,
    onCheckedListDelete,
    onDelete,
    listRef,
    scheduleDetailParamsState,
    totalPage,
    pageSize,
    currentPage,
    handlePageChange,
    setCurrentPage,
    isDeleteLoading,
  };
};
