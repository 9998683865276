import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  activeNumberRecoil,
  activePlayRecordIdRecoil,
  animationNumberRecoil,
  selectedArsScenarioInfoRecoil,
} from '@/recoil/atoms/say015Atom';

import { use015ServiceStatus } from '@/components/hooks/customs/use015ServiceStatus';
import { debounce } from 'lodash';
import { IArsScenarioInfoListS, IArsUpdateMutation } from '@/features/ars/arsScenario/ui/ArsScenario.types';
import { useScenarioAPI } from '@/features/ars/arsScenario/hooks/useScenarioAPI';

/**
 * @title ARS 시나리오 핸들러 HOOKS
 *
 * @author 정휘학
 * @since 2024.09.02
 * */
export const useScenario = () => {
  // 왼쪽 그룹 시나리오 셀렉트 박스
  const [arsSelect, setArsSelect] = useState<boolean>(false);
  /* ARS TTS 파일 재생중인 ARS 카드 ID */
  const [, setActivePlayRecordIdS] = useRecoilState(activePlayRecordIdRecoil);
  /* 휴대폰 클릭 number */
  const [, setActiveNumberS] = useRecoilState(activeNumberRecoil);
  /* 휴대폰 클릭 number 로 focus 되어 애니메이션 될 ID */
  const [, setAnimationNumberS] = useRecoilState(animationNumberRecoil);
  /* ARS 시나리오 정보 배열 */
  const [arsScenarioInfoListS, setArsScenarioInfoListS] = useState<IArsScenarioInfoListS[] | null>(null);
  /* 선택된 ARS 시나리오 */
  const [selectedArsScenarioInfoS, setSelectedArsScenarioInfoS] = useRecoilState(selectedArsScenarioInfoRecoil);
  /* 수정한 ARS 시나리오 파일 이름 */
  const [updatedArsFileNameS, setUpdatedArsFileNameS] = useState<string | null>(null);
  /* ARS 시나리오 파일 이름 */
  const [arsFileNameS, setArsFileNameS] = useState<string>('');
  /* ARS 시나리오 제목 */
  const [arsTitleS, setArsTitleS] = useState<string>('');
  /* ARS 시나리오 메모 */
  const [arsMemoS, setArsMemoS] = useState<string>('');
  /* ARS 시나리오 활성화 상태 */
  const [arsActiveS, setArsActiveS] = useState<boolean>(false);

  // =====================
  // 015 서비스 상태값 HOOK
  // =====================
  const { statusData, setRefetchS } = use015ServiceStatus();

  // ===================
  // ARS 시나리오 API HOOK
  // ===================
  const { arsAddM, arsUpdateM, arsDeleteM } = useScenarioAPI({ setRefetchS, arsFileNameS, setUpdatedArsFileNameS });

  // ===================
  // 시나리오 데이터 세팅
  // ===================
  useEffect(() => {
    if (!statusData) return;
    setArsScenarioInfoListS(statusData.scenarioInfoList);
    if (updatedArsFileNameS) {
      statusData.scenarioInfoList.map(
        (item: IArsScenarioInfoListS) => item.fileName === updatedArsFileNameS && setSelectedArsScenarioInfoS(item),
      );
    } else {
      statusData.scenarioInfoList.map(
        (item: IArsScenarioInfoListS) => item.active && setSelectedArsScenarioInfoS(item),
      );
    }
    setUpdatedArsFileNameS(null);
  }, [statusData]);

  // =======================
  // ARS 시나리오 드랍다운 핸들러
  // =======================
  const arsSelectClick = () => {
    setArsSelect((prevState) => !prevState);
  };

  // ==========================
  // 시나리오 리스트 selected 핸들러
  // ==========================
  const handleScenarioSelected = (fileName: string): void => {
    if (!arsScenarioInfoListS) return;
    setActiveNumberS(null);
    setAnimationNumberS(null);
    setActivePlayRecordIdS(null);
    setArsSelect(false);
    arsScenarioInfoListS.map(
      (item: IArsScenarioInfoListS) => item.fileName === fileName && setSelectedArsScenarioInfoS(item),
    );
  };

  // =====================
  // ARS 시나리오 삭제 핸들러
  // =====================
  const handleDeleteARS = (e: MouseEvent<HTMLDivElement>, fileName: string) => {
    e.stopPropagation(); // 이벤트 버블링 방지
    arsDeleteM({ fileName });
  };

  // ===================
  // ARS 시나리오 추가 핸들러
  // ===================
  const handleAddARS = () => {
    arsAddM({});
  };

  // ===================
  // ARS 시나리오 수정 핸들러
  // ===================
  const handleUpdateARS = ({ memo, active, fileName, title }: IArsUpdateMutation) => {
    arsUpdateM({ fileName, memo, title, active });
  };
  const arsUpdateThrottle = useRef(debounce(handleUpdateARS, 1000));

  useEffect(() => {
    if (!selectedArsScenarioInfoS) return;

    const { active, fileName, title, memo } = selectedArsScenarioInfoS;
    if (!fileName) return;

    setArsFileNameS(fileName as string);
    setArsTitleS(title as string);
    setArsMemoS(memo as string);
    setArsActiveS(active as boolean);
  }, [selectedArsScenarioInfoS]);

  // ====================
  // ARS 시나리오 이름 핸들러
  // ====================
  const handleArsTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (value.length < 11) {
      setArsTitleS(value);
      arsUpdateThrottle.current({ memo: arsMemoS, active: arsActiveS, fileName: arsFileNameS, title: value });
    }
  };

  // ====================
  // ARS 시나리오 메모 핸들러
  // ====================
  const handleArsMemo = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;

    if (value.length < 51) {
      setArsMemoS(value);
      arsUpdateThrottle.current({ memo: value, active: arsActiveS, fileName: arsFileNameS, title: arsTitleS });
    }
  };

  // ====================
  // ARS 시나리오 대표설정 핸들러
  // ====================
  const handleArsActive = () => {
    if (!arsActiveS) {
      setArsActiveS(true);
      arsUpdateThrottle.current({ memo: arsMemoS, active: true, fileName: arsFileNameS, title: arsTitleS });
    }
  };

  return {
    arsSelect,
    arsSelectClick,
    selectedArsScenarioInfoS,
    arsTitleS,
    arsScenarioInfoListS,
    handleScenarioSelected,
    arsFileNameS,
    handleDeleteARS,
    handleAddARS,
    handleArsTitle,
    arsMemoS,
    handleArsMemo,
    arsActiveS,
    handleArsActive,
  };
};
