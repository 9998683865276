/**
 * @title 그룹리스트 캐싱 작업 hooks
 */

import { BuddyData } from '@/types/index.types';
import { useQueryClient } from '@tanstack/react-query';
import { IEditData } from '../hooks/customs/addressBook/useEditGroup';
import {
  MainTableCopyParamsType,
  MainTableSimpleAddParamsType,
} from '../hooks/mutations/addressBook/useAddressBookMutations';

export const groupListCacheUpdate = () => {
  const queryClient = useQueryClient();

  /* 캐싱 작업을 위한 현재 그룹리스트 값 */
  const prevData: any = queryClient.getQueryData(['groupList2']);

  // ==============================
  // 그룹리스트 추가 cache update hook
  // ==============================
  const handleGroupAddCacheUpdate = (data: any) => {
    // groupList의 data 컬럼에 맞게 지정
    const dataFomat = { groupSeqNo: data.groupSeqNo, usrKey: data.usrKey, buddyCount: 0, groupNm: data.groupNm };
    const newList = [dataFomat, ...prevData.groupList];
    const newData = { ...prevData, groupList: newList };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 그룹리스트 수정 캐시 업데이트 hook
  // ==============================
  const handleGroupEditCacheUpdate = (editData: IEditData) => {
    const updateData = prevData?.groupList?.map((field: any) => {
      if (field.groupSeqNo === editData.groupSeqNo) {
        return {
          ...field,
          groupNm: editData.groupNm,
        };
      }
      return field;
    });
    const newData = { ...prevData, groupList: updateData };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 그룹리스트 삭제 캐시 업데이트 hook
  // ==============================
  const handleGroupDeleteCacheUpdate = (variables: number[]) => {
    const notRmoveData = prevData?.groupList?.filter((field: any) => !variables.includes(field.groupSeqNo));
    const rmoveData = prevData?.groupList?.filter((field: any) => variables.includes(field.groupSeqNo));

    // buddyCount 값을 추출하여 모두 더하기
    const updateRecycleCount = rmoveData.reduce((acc: number, group: any) => {
      return acc + group.buddyCount;
    }, 0);

    const newData = {
      ...prevData,
      groupList: notRmoveData,
      recycleCount: prevData.recycleCount + updateRecycleCount,
    };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 대량추가 저장 캐시 업데이트 hook
  // ==============================
  const handleLargeAddCacheUpdate = (data: BuddyData[]) => {
    const updateData = prevData.groupList.map((group: any) => {
      if (group.groupSeqNo === data[0].groupSeqNo) {
        return {
          ...group,
          buddyCount: group.buddyCount + data.length,
        };
      }
      return group;
    });
    const newData = { ...prevData, groupList: updateData };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 휴지통 (전체, 선택)복원 캐시 업데이트 hook
  // ==============================
  const handleRestoreCacheUpdate = (data: BuddyData[]) => {
    const updateData = prevData.groupList.map((group: any) => {
      // data 배열에서 현재 group의 groupSeqNo와 일치하는 객체를 찾음
      const matchedData = data.filter((item) => item.groupSeqNo === group.groupSeqNo);
      if (matchedData.length > 0) {
        // 일치하는 객체의 수를 buddyCount에 할당
        return { ...group, buddyCount: group.buddyCount + matchedData.length };
      }
      return group;
    });

    // 이미 존재하는 groupSeqNo를 추적하기 위한 Set
    const existingGroupSeqNos = new Set(prevData.groupList.map((group: any) => group.groupSeqNo));

    // 새로운 그룹 객체를 추가
    const newGroups = data.reduce((acc: any, item) => {
      // prevData에 없고, 이미 추가된 그룹 중에 없는 경우에만 추가
      if (
        !existingGroupSeqNos.has(item.groupSeqNo) &&
        !acc.some((group: any) => group.groupSeqNo === item.groupSeqNo)
      ) {
        const buddyCountIncrement = data.filter((d) => d.groupSeqNo === item.groupSeqNo).length;
        acc.push({
          groupSeqNo: item.groupSeqNo,
          usrKey: item.usrKey,
          buddyCount: buddyCountIncrement,
          groupNm: item.groupNm,
        });
      }
      return acc;
    }, []);

    const newData = {
      ...prevData,
      groupList: [...newGroups, ...updateData],
      recycleCount: prevData.recycleCount - data.length,
    };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 휴지통 삭제 캐시 업데이트 hook
  // ==============================
  const handleBinDeleteCacheUpdate = (data: number) => {
    const deleteLength = data;
    const newData = { ...prevData, recycleCount: prevData.recycleCount - deleteLength };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 간편추가 저장 캐시 업데이트 hook
  // ==============================
  const handleTableAddCacheUpdate = (variables: MainTableSimpleAddParamsType) => {
    const updateData = prevData.groupList.map((group: any) => {
      if (group.groupSeqNo === variables.groupSeqNo) {
        return {
          ...group,
          buddyCount: group.buddyCount + 1,
        };
      }
      return group;
    });
    const newData = { ...prevData, groupList: updateData };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 주소록 수정 캐시 업데이트 hook
  // ==============================
  const handleTableEditCacheUpdate = (data: any, variables: any) => {
    const updateData = prevData.groupList.map((group: any) => {
      if (group.groupSeqNo === variables.groupSeqNo) {
        // 만약 그룹을 수정이 아닌 다른 컬럼만 수정하면 그룹 리스트는 그대로
        if (variables.groupSeqNo === data.groupSeqNo) {
          return {
            ...group,
          };
        }
        return {
          ...group,
          buddyCount: group.buddyCount + 1,
        };
      }
      if (group.groupSeqNo === data.groupSeqNo) {
        return {
          ...group,
          buddyCount: group.buddyCount - 1,
        };
      }
      return group;
    });
    const newData = { ...prevData, groupList: updateData };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 주소록 삭제 캐시 업데이트 hook
  // ==============================
  const handleTableDeleteCacheUpdate = (data: any) => {
    // 각 그룹시퀀스넘버에 해당하는 삭제 리스트 값들의 합
    const sumCount = Object.values(data).reduce((acc: number, value: any) => acc + value, 0);
    const updateData = prevData.groupList.map((group: any) => {
      const matchingData = data[group.groupSeqNo];
      if (matchingData) {
        return {
          ...group,
          buddyCount: group.buddyCount - matchingData,
        };
      }
      return group;
    });
    const newData = { ...prevData, groupList: updateData, recycleCount: prevData.recycleCount + sumCount };
    queryClient.setQueryData(['groupList2'], newData);
  };

  // ==============================
  // 주소록 복사 캐시 업데이트 hook
  // ==============================
  const handleCopyTableCacheUpdate = (variables: MainTableCopyParamsType) => {
    const copyLength = variables.buddySeqNos.length;
    const updateData = prevData.groupList.map((group: any) => {
      if (group.groupSeqNo === variables.groupSeqNo) {
        return {
          ...group,
          buddyCount: group.buddyCount + copyLength,
        };
      }
      return group;
    });
    const newData = { ...prevData, groupList: updateData };
    queryClient.setQueryData(['groupList2'], newData);
  };

  return {
    handleGroupAddCacheUpdate,
    handleGroupEditCacheUpdate,
    handleGroupDeleteCacheUpdate,
    handleLargeAddCacheUpdate,
    handleTableAddCacheUpdate,
    handleRestoreCacheUpdate,
    handleBinDeleteCacheUpdate,
    handleTableEditCacheUpdate,
    handleTableDeleteCacheUpdate,
    handleCopyTableCacheUpdate,
  };
};
