import React from 'react';
import styled from 'styled-components';
import BaseButton from '../../../Atom/BaseButton';
import { formatPhoneString } from '@/shared/util/format/phoneNumberFormatUtil';

const DivWrapper = styled.div`
  width: 30%;
  max-height: 27rem;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 2rem;
  > div {
    height: 3rem;
    display: flex;
    align-items: center;
    button {
      padding: 0;
    }
  }
  @media screen and (max-width: 1080px) {
    width: 100%;
    height: auto;
    max-height: 18rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 540px) {
    gap: 2rem;
    height: auto;
    margin: 1rem auto;
    overflow: unset;

    > div {
      height: auto;
    }
  }
`;

interface CallingNumberListProps {
  callback: string;
  phnId: string;
  regMethod: string;
  rsrvdId: string;
  usedDttm: string;
  verifiedYn: string;
  wrtDttm: string;
  originCallback: string;
}

const CallingNumberList = ({ allCallingNumberList, callbackListS, handleSubmit, handleDelete }: any) => {
  const lastNumber =
    allCallingNumberList?.filter(
      (el: CallingNumberListProps) =>
        el.verifiedYn === 'Y' && !el.callback.startsWith('015') && !el.callback.includes('(심사중)'),
    ).length <= 1;

  return (
    <DivWrapper>
      {allCallingNumberList?.map((el: CallingNumberListProps) => {
        return (
          <div key={el.callback}>
            {formatPhoneString(el.callback)}
            {!el.callback.startsWith('015') && !lastNumber ? (
              <BaseButton
                type="button"
                width="auto"
                backgroundColor="transparent"
                marginLeft="1.2rem"
                fontSize="1.2rem"
                fontWeight="600"
                value={el.callback}
                onClick={(e) => handleDelete(e, el.originCallback)}
              >
                X
              </BaseButton>
            ) : (
              el.callback.includes('(심사중)') && (
                <BaseButton
                  type="button"
                  width="auto"
                  backgroundColor="transparent"
                  marginLeft="1.2rem"
                  fontSize="1.2rem"
                  fontWeight="600"
                  value={el.callback}
                  onClick={(e) => handleDelete(e, el.originCallback)}
                >
                  X
                </BaseButton>
              )
            )}
          </div>
        );
      })}
    </DivWrapper>
  );
};

export default CallingNumberList;
