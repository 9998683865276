import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useInitApis } from '@/components/hooks/customs/useInitApis';
import { fetchMutation } from '@/components/hooks/mutations/fetchMutation';
import { useNice } from '@/shared/components/niceCertification/hooks/useNice';
import { useNiceCertification } from '@/shared/components/niceCertification/hooks/useNiceCertification';
import { client } from '@/shared/lib/clientAxios';
import { removeCookie, setCookie } from '@/shared/util/cookie';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { signInRecoil } from '../../recoil/atoms/signInAtom';

type UpdateUserInfoBodyType = {
  accessKey: string;
  encodeData: string;
};

type UpdateUserInfoResponseType = {
  usrNm: string;
  token: string;
  refreshToken: string;
  msgCount: number;
  say015User: boolean;
  availabilityStatus: boolean;
};

/**
 * @title 구아레오에서 링크타고 들어올때 자동으로 로그인 해주게 하는 컴포넌트 ( 구아레오 서비스 종료하면 삭제 )
 *
 * @author 정휘학
 * @since 2024.03.29
 * */
const LinkSignInPage = () => {
  const setSignInState = useSetRecoilState(signInRecoil);
  const navigate = useNavigate();

  /* 베너로 타고 들어올때 가져오는 accessKey */
  const [accessKeyS, setAccessKeyS] = useState<string>('');

  /* 나이스 인증 초기 encodeData 받아오는 Hook */
  const { niceData } = useNice();

  const { warningModal, confirmModal } = useModalHook();

  const loginApi = async (accessKey: string) => {
    const data = { accessKey };
    const response = await client.post('/signin/getToken', data);
    return response.data;
  };

  /* 나이스 인증 팝업 띄우는 Hook */
  const { niceCertification } = useNiceCertification();

  /* 구 아레오 베너로 로그인 시도 api */
  const { mutate, data, isLoading, status } = useMutation((accessKey: string) => loginApi(accessKey));

  /* 구 아레오 배너 로그인 실패 -> 본인인증 성공 이후 로그인 시도 api */
  const { mutate: updateUserInfoM } = fetchMutation<UpdateUserInfoBodyType, UpdateUserInfoResponseType>({
    method: 'POST',
    mutationKey: ['/signin/updateUserInfo'],
    noToken: true,
    onSuccess: (data, variables, context) => {
      if (!data.token) {
        warningModal('로그인 실패', '로그인에 실패했습니다. 다시 로그인부탁드립니다.', true, '/signin');
      }
      removeCookie('Refresh_Token');
      setSignInState(true);
      localStorage.setItem('authorizationToken', data.token);
      localStorage.setItem('user', data.usrNm);
      localStorage.setItem('say015User', String(data?.say015User));
      localStorage.setItem('availabilityStatus', String(data?.availabilityStatus));
      setCookie('Refresh_Token', `${data.refreshToken}`, { path: '/' });
      navigate('/');
    },
    onError: (error, variables, context) => {
      warningModal('로그인 실패', '로그인에 실패했습니다. 다시 로그인부탁드립니다.', true, '/signin');
    },
  });

  const onSuccess = (response: any) => {
    if (response?.failMsg && response?.needAuthInfo) {
      /* CI, DI 값이 없을 경우 NICE 인증 요청 */
      confirmModal(niceCertification, '로그인 실패', response?.failMsg, true);
    } else {
      removeCookie('Refresh_Token');
      setSignInState(true);
      localStorage.setItem('authorizationToken', response.token);
      localStorage.setItem('user', response.usrNm);
      localStorage.setItem('say015User', String(response?.say015User));
      localStorage.setItem('availabilityStatus', String(response?.availabilityStatus));
      // TODO:배포 -> 문제시 해당 코드 삭제
      // 구아레오에서 새로운 아이디로 로그인 했을 때 추가로 수정할 값들
      // localStorage.removeItem('contacts');
      // useInitApis();
      // TODO:배포 <-

      setCookie('Refresh_Token', `${response.refreshToken}`, { path: '/' });
      navigate('/');
    }
  };
  const onError = () => {
    navigate('/signin');
  };
  /* 베너 링크로 로그인 첫 시도 */
  useEffect(() => {
    if (data && !isLoading && status === 'success') {
      onSuccess(data);
    } else if (status === 'error') {
      onError();
    }
  }, [isLoading, data]);

  /* 페이지 진입시 동작 */
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const params = new URLSearchParams(window.location.search);
    /* URL 에 담겨있는 accessKey 값 콘솔 */
    const accessKeyParams = params.get('AccessKey') ? params.get('AccessKey') : params.get('accessKey');
    if (accessKeyParams) {
      setAccessKeyS(accessKeyParams);
      // 값이 있다면 state 에 저장
      mutate(accessKeyParams);
    }
  }, []);

  /**
   * @title 나이스 인증 완료 후 로직 ( [ci,di] 없을 경우 / 최초 로그인 시도 )
   *
   * @author 정휘학
   * @since 2024.03.18
   * */
  useEffect(() => {
    if (niceData) {
      updateUserInfoM({
        accessKey: accessKeyS,
        encodeData: niceData,
      });
    }
  }, [niceData]);

  return <> </>;
};

export default LinkSignInPage;
