import Loader from '@/components/common/Loader';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * @title easyPay 팝업창에서 결제데이터 가져오는 컴포넌트
 * */

const EasyComponent = () => {
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  // 각 파라미터를 개별적으로 추출하여 easyData 객체에 담기
  const easyData = {
    mallId: query.get('mallId'),
    shopTransactionId: query.get('shopTransactionId'),
    authorizationId: query.get('authorizationId'),
    shopOrderNo: query.get('shopOrderNo'),
    approvalReqDate: query.get('approvalReqDate'),
    type: query.get('type'),
    productType: query.get('productType'),
  };

  useEffect(() => {
    const prevPage = localStorage.getItem('prevPage');
    if (easyData) {
      if (environmentS !== 'app') {
        // 데스크탑 환경: 팝업에서 postMessage로 처리
        if (window.opener) {
          window.opener.postMessage({ easyData }, window.location.origin);
          window.close(); // 데이터를 성공적으로 보냈다면 팝업 종료
        } else {
          console.error('window.opener is null. Cannot post message.');
          window.close();
        }
      } else {
        // 앱 환경: 리다이렉션된 페이지에서 처리
        navigate(prevPage as string, { state: { easyData } });
        localStorage.removeItem('prevPage');
      }
    }
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Loader backgroundToggle />
    </div>
  );
};

export default EasyComponent;
