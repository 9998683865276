import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContainerX = styled.div`
  // 전체 와이드 max-
  // max-width: 1920px;
  width: 100%;
`;

export const AddressLeftZone = styled.div`
  // 왼쪽 체크박스 / 테이블 박스
  width: 100%;
  position: relative;
  display: flex;
`;

export const HeadAndtMiddle = styled.div`
  // Head 와 Middle 합쳐놓은 컴포넌트
  width: 100%;
  &.expansion {
    width: 70%;
  }
`;

export const EtcChattingTest = styled.div`
  // 대화방 컴포넌트
  width: 0;
  &.expansion {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 100vh;
    font-size: 60px;
    background-color: hotpink;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 500px;
    color: #fff;
  }
`;

export const GobackHistoryWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap:10px; */
  padding: 0 0 10px 0;
  img {
    max-width: 100%;
    max-height: 27px;
  }
  @media screen and (max-width: 1440px) {
    padding-right: 1%;
  }
  @media screen and (max-width: 480px) {
    gap: 8px;
    padding: 0 2% 10px 2%;
  }
  h1 {
    width: 290px;
    max-height: 30px;
    font-size: 2.5rem;
    font-weight: 600;
    font-family: 'Jura';
    text-align: center;
    @media screen and (max-width: 768px) {
      // 반응형
      font-size: 1.8rem;
    }
  }
  & > div {
    // 자식 div에 공통 css 속성 부여
    display: flex;
    align-items: center;
  }
`;

export const AddAdressDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SearchWrap = styled.div``;

export const AddressSearchWrap = styled.div``;

// 중복정리 박스
export const AddressDuplicateCheckWrap = styled.div`
  @media screen and (max-width: 1024px) {
  }
`;

export const AddressHeadGroup = styled.div`
  // 그룹추가, 그룹삭제, 주소록추가, 주소록삭제 박스 + 중복정리,보여질,이름,검색란 박스
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 290px);
  @media screen and (max-width: 1800px) {
    width: 80%;
  }
  @media screen and (max-width: 1024px) {
    justify-content: flex-end;
  }
`;
export const AddAddressbook = styled.div`
  // 그룹추가, 그룹삭제, 주소록추가, 주소록삭제 박스
  display: flex;
  align-items: center;
  gap: 6px;
  button {
    padding: 0;
    width: 104px;
    height: 30px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
    color: #191919;
    @media screen and (max-width: 1800px) {
      width: 70px;
      font-size: 1.1rem;
    }

    @media screen and (max-width: 1540px) {
      width: 62px;
      font-size: 1rem;
    }
  }
  /* 반응형 Tablet 대응 1024px ~ */
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

/* Start 주소록 추가 */
export const SelectWrapper = styled.div`
  // 셀렉트 박스
  position: relative;
  height: 3rem;
  &.active {
    > button {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  > button {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    font-weight: 600;
    font-size: 1.2rem;
    border: 1px solid transparent;
    border-radius: 1.5rem;
    color: #191919;
    > svg {
      position: relative;
      right: -5px;
      @media screen and (max-width: 1800px) {
        right: 0;
      }
    }
  }
  /* 자식 태그 포괄적으로 CSS 적용 */
  button {
    width: 100%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
  }
  &.active {
    > button {
      border-color: #adc1f4;
      border-bottom-color: #d6d6dc;
      border-radius: 1.5rem 1.5rem 0 0;
      box-shadow: none;
    }
    ul {
      border: 1px solid #adc1f4;
      border-top: none;
    }
  }
  @media screen and (max-width: 1800px) {
    width: 9rem;
    button {
      padding: 0 10px;
      font-size: 1.1rem;
    }
  }
  @media screen and (max-width: 1540px) {
    button {
      padding: 0 8px;
      font-size: 1rem;
    }
  }
`;

export const SelectOptions = styled.ul`
  // 셀렉트 옵션 박스
  position: absolute;
  top: 3rem;
  left: 0;
  /* z-index:10000; */
  width: 100%;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.03) 5px 5px 10px 0px;
  box-sizing: border-box;
  background-color: rgb(244, 244, 247);
  overflow: hidden;
  li {
    height: 30px;
    button {
      padding: 0 12px;
      font-weight: 500;
      font-size: 1.2rem;
      border-radius: 0;
      box-shadow: none;
      border: none;
      text-align: left;
      color: #191919;
      @media screen and (max-width: 1800px) {
        padding: 0 10px;
        font-size: 1.1rem;
      }

      @media screen and (max-width: 1540px) {
        padding: 0 8px;
        font-size: 1rem;
      }
    }
    &:hover {
      button {
        position: relative;
        background-color: #f6f7fe;
        font-weight: 600;
      }
    }
  }
`;
/* End 주소록 추가  */

export const AddressFunBtnBox = styled.div`
  // 중복정리,보여질,이름,검색란 박스
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  button {
    height: 30px;
    font-weight: 600;
    border-radius: 50px;
    background: #fff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
    color: #191919;
    &.addressItemBtn {
      // 보여질 항목 선택 버튼
      width: 124px;
      font-size: 1.2rem;
      @media screen and (max-width: 1800px) {
        width: 100px;
        font-size: 1.1rem;
      }
      @media screen and (max-width: 1540px) {
        width: 90px;
        font-size: 1rem;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  .DuplicateCheckZone {
    // 중복정리 버튼
    .DuplicateCheckBtn {
      width: 102px;
      @media screen and (max-width: 1800px) {
        width: 80px;
        font-size: 1.1rem;
      }
      @media screen and (max-width: 1540px) {
        width: 55px;
        font-size: 1rem;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  .addressSearchBox {
    // Select / 검색 영역
    select {
      width: 96px;
    }
  }
`;

export const AddressTableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  /* gap:10px; */
  /* padding-top:20px; */
  @media screen and (max-width: 1440px) {
    flex-wrap: wrap;
  }
`;

export const AddressLeftMiddle = styled.div`
  // 테이블 영역
  display: flex;
  justify-content: space-between;
  min-height: calc(var(--dvh, 100vh));
  max-height: calc(var(--dvh, 100vh));
  @media screen and (max-width: 768px) {
    min-height: calc(var(--vh, 100vh) - 6rem);
  }
`;

// 주소록 테이블
export const AddressTable = styled.div<{ isActive: boolean; mobileNavState: string }>`
  position: relative;
  width: calc(100% - 30rem);
  padding: 2rem 0 0 0;
  white-space: nowrap;

  @media screen and (max-width: 1920px) {
    width: calc(100% - 29rem);
  }

  @media screen and (max-width: 768px) {
    z-index: ${(props) => (props.mobileNavState === '주소록' && props.isActive ? 101 : -1)};
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 1rem 0;
    background: ${(props) => props.theme.colors.white};
    left: 0;
    transform: ${(props) =>
      props.mobileNavState === '주소록' && props.isActive ? 'translateX(0)' : 'translateX(40%)'};
    opacity: ${(props) => (props.mobileNavState === '주소록' && props.isActive ? 1 : 0)};
    transition:
      transform 0.3s ease-out,
      opacity 0.3s ease-out; /* transform과 opacity 애니메이션 */

    &.active {
      width: 80%;
    }
  }
`;

export const ResLeftBtnZone = styled.div`
  // 반응형 1024 부터 나올 주소록 좌측 버튼들
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    width: 54px;
    min-width: 54px;
    border-top: 1px solid #adc1f4;
    border-right: 1px solid #adc1f4;
    border-radius: 0 5px 0px 0px;
    > div {
      display: flex;
      flex-direction: column;
      button {
        width: initial;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: normal;
        border-bottom: 1px solid #adc1f4;
        word-break: keep-all;
        background-color: transparent;
        color: #191919;
      }
      .DuplicateCheckZone {
        button {
          width: 100%;
          height: initial;
        }
      }
    }
  }
`;
/* 기본 버튼 영역들 */
export const ResContentNormal = styled.div`
  button {
  }
`;

export const ResContentLarge = styled.div``;

export const IsLogin = styled.div``;

export const NotLogin = styled.div`
  font-size: 6rem;
  color: #adb5bd;
  width: 100%;
  height: calc(100vh - 280px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 40px;
  }
`;
