import { postAdressBinDelete } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { recyclebinCacheUpdate } from '@/components/cache/recyclebinCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { checkedBinListState } from '@/recoil/atoms/addressList';
import { fullScreenSpinLoadingRecoil } from '@/recoil/atoms/atoms';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

export const useDeleteBin = () => {
  const [deleteBinList, setDeleteBinList] = useState<number[]>([]); // 삭제할 버디시퀀스넘버 담으면 됩니다.
  const [binDeleteState, setBinDeleteState] = useState(false); // 버튼 중복 클릭 방지
  const [, setCheckedBinList] = useRecoilState(checkedBinListState);

  const setFullScreenSpinLoadingR = useSetRecoilState(fullScreenSpinLoadingRecoil);

  const { confirmModal, successModal, warningModal } = useModalHook();

  const cacheGHooks = groupListCacheUpdate();
  const cacheRHooks = recyclebinCacheUpdate();

  const { mutate: binListDeleteM, isLoading: binListDeleteIsLoading } = useMutation(postAdressBinDelete, {
    onSuccess: (data: number, variables: number[]) => {
      setBinDeleteState((prev) => prev && false);
      setDeleteBinList([]);
      setCheckedBinList([]);
      cacheRHooks.recyclebinDeleteCacheUpdate(variables);
      cacheGHooks.handleBinDeleteCacheUpdate(data);
      successModal('연락처 삭제', '연락처를 삭제하였습니다.', true);
    },
    onError: (error: any) => {
      setBinDeleteState((prev) => prev && false);
      warningModal('연락처 삭제', error.response.data, true);
    },
  });

  const binListDeleteHandle = () => {
    setBinDeleteState((prev) => !prev && true);

    if (!(deleteBinList.length > 0)) {
      setBinDeleteState((prev) => prev && false);
      warningModal('연락처 삭제', '선택된 연락처가 없습니다.</br>삭제할 연락처를 선택해주세요.', true);
      return;
    }
    confirmModal(
      async () => binListDeleteM(deleteBinList),
      '연락처 삭제',
      '선택한 연락처를 영구 삭제 하시겠습니까?',
      true,
      undefined,
      async () => setBinDeleteState(false),
    );
  };

  // ==============================
  // 휴지통 선택 삭제 mutate loading시 로더 useEffect
  // ==============================
  useEffect(() => {
    setFullScreenSpinLoadingR(binListDeleteIsLoading);
  }, [binListDeleteIsLoading]);

  return { binListDeleteHandle, setDeleteBinList, binDeleteState };
};
