import { useNiceCertification } from '@/shared/components/niceCertification/hooks/useNiceCertification';
import { useEffect, useState } from 'react';
import { fetchMutation } from '../../mutations/fetchMutation';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import { useNice } from '@/shared/components/niceCertification/hooks/useNice';

interface IUseCallingNiceAdmin {
  allCallingNumberListRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>;
}
/**
 * @title 나이스인증을 통한 발신번호 등록
 */
export const useCallingNiceAdmin = (props: IUseCallingNiceAdmin) => {
  // 발신번호 추가 나이스인증 데이터 mutate에 보낼 STATE
  const [niceDataS, setNiceDataS] = useState(null);
  // 나이스인증 팝업 로직
  const { niceCertification } = useNiceCertification();

  const { warningModal, successModal } = useModalHook();

  // nice 인증 return 값 받아오는 Hook
  const { niceData } = useNice();

  // 본인인증 발신번호 등록 mutate
  const { mutate: niceCallbackM } = fetchMutation({
    mutationKey: ['/niceRegCallback'],
    method: 'POST',
    onSuccess: (data: any) => {
      props.allCallingNumberListRefetch();
      successModal('발신번호 등록', `${formatPhoneNumber(data)}로 등록 성공했습니다.`, true);
    },
    onError: (error: any) => {
      console.error('error', error.response.data);
      warningModal('발신번호 등록 실패', error.response.data, true);
    },
  });

  // ==============================
  // onClick - 본인인증 버튼 클릭 hook
  // ==============================
  const onClickNiceBtn = async () => {
    await niceCertification();
  };

  // ==============================
  // useEffect - encodeData (나이스인증 후 받는 값)가 들어오면 실행할 hook
  // ==============================
  useEffect(() => {
    // 나이스인증 값을 state에 저장
    if (niceData) {
      setNiceDataS(niceData);
    }
  }, [niceData]);

  useEffect(() => {
    if (niceData !== null) {
      niceCallbackM(niceData);
    }
  }, [niceDataS]);

  return { onClickNiceBtn };
};
