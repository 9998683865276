import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Client } from '@stomp/stompjs';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { webSocketDataRecoil } from '@/recoil/atoms/webSocket';
import { callbackNumberState } from '@/recoil/atoms/addressList';
import { mhQueryParamsRecoil, rcvMsgCacheToggleRecoil, rcvMsgPopupToggleRecoil } from '@/recoil/atoms/chatRoom';
import { useMessagePushAlert } from '@/components/hooks/customs/useMessagePushAlert';
import { useChatRoom015Comm } from '@/components/hooks/customs/chatRoom015/useChatRoom015Comm';
import { callbackListRecoil } from '@/recoil/atoms/useCallbackList';

/**
 * @title 웹 소켓 Hooks
 *
 * @author 정휘학
 * @since 2024.04.13
 * */
export const useWebSocket = () => {
  const navigator = useNavigate();
  const queryClient = useQueryClient();
  const client = useRef<any>(null);
  /* 웹 소켓으로 온 데이터 담는 state */
  const [webSocketData, setWebSocketData] = useRecoilState(webSocketDataRecoil);
  /* 수신 메세지 알림 */
  /* 좌측 015 채팅방 api params */
  const [mhQueryParamsS] = useRecoilState(mhQueryParamsRecoil);
  /* 웹 소켓으로 들어온 데이터를 이용해 캐시 작업중인지 아닌지 파악하는 recoil */
  const [, setRcvMsgCacheToggle] = useRecoilState(rcvMsgCacheToggleRecoil);
  /* 채팅방 하단에 표출되는 새로운 메세지 팝업창 toggle  */
  const [, setRcvMsgPopupToggle] = useRecoilState(rcvMsgPopupToggleRecoil);

  const callbackNumber = useRecoilValue(callbackNumberState);
  /* 승인된 발신번호 List Recoil */
  const callbackListS = useRecoilValue(callbackListRecoil);
  const commHooks = useChatRoom015Comm();
  /* 메세지 알림 토글이 true 일 경우 userPoint refetch */
  useMessagePushAlert();
  /**
   * @title 웹 소켓으로 부터 메세지 받는 Hook
   * */
  const getWebSocketMsg = (message: any) => {
    setWebSocketData(message);
  };
  /**
   * =================================
   * @title 웹 소켓으로 부터 메세지가 온 경우
   * =================================
   * */
  useEffect(() => {
    if (!webSocketData) return;
    // 웹 소켓으로 구독 만료 데이터가 올 경우 로그인 페이지로 이동
    if (JSON.parse(webSocketData).subscribe === false) {
      window.location.reload();
      // navigator('/signin');
    }
    /* 현재 웹 소켓으로 들어온 메세지가 활성화되어있는 채팅방인지 아닌지 구분는 boolean */
    const activeChatRoom = JSON.parse(webSocketData).contactNumber === mhQueryParamsS.contactNumber;
    /* 웹소켓으로 메세지가 오면 소프트폰 리스트 캐시 데이터 업데이트 - 카운트, 상단이동, 가장 최근메세지 표출, 날짜 변경 */
    commHooks.updateCache015SoftPhoneListToWebSocket(JSON.parse(webSocketData), activeChatRoom);

    /* 웹 소켓으로 온 메세지가 활성화 채팅방에 대한 메세지가 아닌 경우 useEffect 종료 */
    if (!activeChatRoom) return;

    if (JSON.parse(webSocketData).direction === 'mo') setRcvMsgPopupToggle(true);

    /* 캐쉬 작업임을 알리는 recoil */
    setRcvMsgCacheToggle(true);

    const currentData: any = queryClient.getQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber]);

    if (currentData) {
      const newPages = [...currentData.pages];
      /* 수정중 */
      let newContent = [...newPages[0].content];
      /* MT 메세지 일 경우 기존 CACHE 데이터에 Websocket 으로 받은 데이터의 messageId 값과 messageId가 동일한 기존 데이터 제거 */
      if (JSON.parse(webSocketData).direction === 'mt') {
        newContent = newContent.filter((f) => f.messageId !== JSON.parse(webSocketData).messageId);
      }
      newContent = [...newContent, JSON.parse(webSocketData)];
      newPages[0] = { ...newPages[0], content: newContent };

      const updatedData = {
        ...currentData,
        pages: newPages,
      };
      // 변경된 데이터를 캐시에 다시 저장합니다.
      queryClient.setQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber], updatedData);
      setWebSocketData(null);
    }
  }, [webSocketData]);

  /**
   * ======================
   * @title 웹 소켓 연결 Hook
   * ======================
   * */
  const connect = (number015: string) => {
    if (number015 === undefined) return;
    const lastNumber015: string = '';
    let retryCount: number = 0;
    const maxRetries: number = 10;

    client.current = new Client({
      brokerURL: process.env.REACT_APP_WEBSOCKET_URL,
      // brokerURL: webSocketURL,
      connectHeaders: {
        Authorization: `Bearer ${localStorage.getItem('authorizationToken')}`,
      },
      debug: (str) => {},
      onWebSocketError: (error) => {
        console.error(error);
      },
      onStompError: (error) => {
        console.error(error);
      },
      onConnect: async () => {
        await client.current.subscribe(`/topic/chat/${number015}`, (message: any) => {
          getWebSocketMsg(message.body);
        });
        client.current.onWebSocketClose = () => {
          // (' ========================== 웹 소켓 연결 끊김 ========================== ');
          if (retryCount < maxRetries) {
            setTimeout(() => {
              // (`재연결 시도 ${retryCount + 1}`);
              connect(lastNumber015);
              retryCount++;
            }, 5000);
          }
        };

        client.current.onWebSocketError = (error: any) => {
          // ('웹 소켓 에러 발생', error);
          // (' ========================== 웹 소켓 에러 발생 ========================== ');
          if (retryCount < maxRetries) {
            setTimeout(() => {
              // (`재연결 시도 ${retryCount + 1}`);
              connect(lastNumber015);
              retryCount++;
            }, 5000);
          }
        };
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.current.activate();
  };

  /**
   * @title 웹 소켓 연결 close Hook
   * */
  const disconnect = () => {
    if (client.current) client.current.deactivate();
  };

  // useEffect(() => {
  //   if (typeof window === 'undefined') return undefined;
  //   if (callbackNumber && callbackNumber?.length > 0) {
  //     const number015 = callbackNumber?.find((f: string) => f.startsWith('015'));
  //     if (
  //       !localStorage.getItem('authorizationToken') &&
  //       !localStorage.getItem('say015User') &&
  //       !JSON.parse?.(localStorage.getItem('say015User') as string) &&
  //       !number015
  //     )
  //       return undefined;
  //     connect(number015);
  //   }

  //   return () => {
  //     disconnect();
  //   };
  // }, [callbackNumber?.find((f: string) => f.startsWith('015'))]);

  useEffect(() => {
    if (typeof window === 'undefined') return undefined;
    if (callbackListS && callbackListS?.length > 0) {
      const callbackFilter = callbackListS.filter((item) => item.regMethod === '015');
      const number015 = callbackFilter[0]?.callback;
      if (
        !localStorage.getItem('authorizationToken') &&
        !localStorage.getItem('say015User') &&
        !JSON.parse?.(localStorage.getItem('say015User') as string) &&
        !number015
      )
        return undefined;
      connect(number015);
    }

    return () => {
      disconnect();
    };
  }, [callbackListS]);
};
