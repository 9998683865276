import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconCheck: React.FC<React.SVGProps<SVGSVGElement>> = ({
  className,
  fontSize,
  stroke,
  fill,
  strokeWidth,
}: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.39634 13.9225L4.53906 10.0643L5.82452 8.77885L8.39634 11.3498L13.5382 6.20703L14.8245 7.49339L8.39634 13.9225Z"
        fill={fill ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47727 4.47727 0 10 0C15.5227 0 20 4.47727 20 10C20 15.5227 15.5227 20 10 20C4.47727 20 0 15.5227 0 10ZM10 18.1818C8.92555 18.1818 7.86162 17.9702 6.86895 17.559C5.87629 17.1478 4.97433 16.5452 4.21458 15.7854C3.45483 15.0257 2.85216 14.1237 2.44099 13.131C2.02981 12.1384 1.81818 11.0745 1.81818 10C1.81818 8.92555 2.02981 7.86162 2.44099 6.86895C2.85216 5.87629 3.45483 4.97433 4.21458 4.21458C4.97433 3.45483 5.87629 2.85216 6.86895 2.44099C7.86162 2.02981 8.92555 1.81818 10 1.81818C12.17 1.81818 14.251 2.68019 15.7854 4.21458C17.3198 5.74897 18.1818 7.83005 18.1818 10C18.1818 12.17 17.3198 14.251 15.7854 15.7854C14.251 17.3198 12.17 18.1818 10 18.1818Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconCheck;
