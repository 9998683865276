import React from 'react';
import * as S from './SignUpProcess.style';

import { SignUpProcesseEllipseProps, SignUpProcesseNameProps } from '../../../apis/utils/typeGuard/signUpGuard';

const SignUpProcess = ({ process }: { process: string }) => {
  const steps = [
    { id: '1', name: '약관동의 및 본인인증' },
    { id: '2', name: '회원 정보 입력' },
    { id: '3', name: '가입 완료' },
  ];
  const activeStep = parseInt(process, 10); // 현재 활성화된 단계 (1, 2, 3)

  return (
    <S.SignUpProcessWrap className="signUpProcess">
      {/* {[
        { id: '1', name: '약관동의 및 본인인증' },
        { id: '2', name: '회원 정보 입력' },
        { id: '4', name: '가입 완료' },
      ].map((process) => (
        <S.SignUpProcessTag key={process.id} className={props?.process === process.id ? 'active' : ''}>
          <S.SignUpProcesseEllipse />
          <S.SignUpProcesseName>{process.name}</S.SignUpProcesseName>
        </S.SignUpProcessTag>
      ))} */}

      {steps.map((step, index) =>
        activeStep === index + 1 ? (
          <React.Fragment key={step.id}>
            {/* 현재 활성화된 단계 이름 */}
            <S.SignUpProcessName>{step.name}</S.SignUpProcessName>

            <S.SignUpProcessBarWrap>
              {steps.map((step) => (
                <S.SignUpProcessBar
                  key={step.id} // 고유한 id 사용
                  className={activeStep === parseInt(step.id, 10) ? 'active' : ''} // 해당 단계만 active
                />
              ))}
            </S.SignUpProcessBarWrap>
          </React.Fragment>
        ) : null,
      )}
    </S.SignUpProcessWrap>
  );
};

export default SignUpProcess;
