/* eslint-disable */
import ArsCardModal from '@/components/say015/Modal/arsCards/ArsCardModal';
import ArrowIcon from '@/components/say015/Modal/icons/ArrowIcon';
import PlusIcon from '@/components/say015/Modal/icons/PlusIcon';
import React, { useEffect, useRef, useState } from 'react';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useRecoilState } from 'recoil';
import { activeNumberRecoil, animationNumberRecoil } from '@/recoil/atoms/say015Atom';
import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';
import ArsDropBox from '@/components/say015/Modal/icons/ArsDropBox';

const ArsCardListComponent = ({
  id,
  arsData,
  isUpdatedIdS,
  handleUpdateIdState,
  handleAddIdState,
  isAddIdS,
  updatedJsonFile,
  selectAddCardS,
  setSelectAddCardS,
  setArsData,
  isAddS,
}: any) => {
  const { findDataById, checkArrow, findParentOfData, findParentOfId } = use015ArsJSON({ arsData });
  const [activeNumberS, setActiveNumberS] = useRecoilState(activeNumberRecoil);
  const [animationNumberS, setAnimationNumberS] = useRecoilState(animationNumberRecoil);
  const [dragTagIdS, setDragTagIdS] = useState<string | null>(null);
  const [dragOfParentIdS, setDragOfParentIdS] = useState<string | null>(null);
  const cardRef = useRef<any>({});

  useEffect(() => {
    if (activeNumberS && cardRef.current[activeNumberS]) {
      cardRef.current[activeNumberS].focus();
      cardRef.current[activeNumberS].scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
    }
  }, [activeNumberS, cardRef]);

  const setRef = (key: any, element: any) => {
    if (element) {
      cardRef.current[key] = element;
    } else {
      delete cardRef.current[key]; // 요소가 사라질 때 참조값을 삭제
    }
  };

  useEffect(() => {
    if (!arsData || !activeNumberS) {
      setActiveNumberS(null);
      setAnimationNumberS(null);
      return;
    }

    const { s015Data } = findDataById(arsData, activeNumberS);

    setAnimationNumberS(activeNumberS);

    if (s015Data?.type !== 'READ') {
      setTimeout(() => {
        setActiveNumberS(null);
        setAnimationNumberS(null);
      }, 1000);
    }
  }, [activeNumberS]);

  const [draggedColIndex, setDraggedColIndex] = useState<any>(null);

  const handleDragStart = (colIndex: any) => {
    const parentId = findParentOfId(arsData, colIndex);
    if (parentId) {
      setDragOfParentIdS(parentId);
      setDraggedColIndex(colIndex);
    }
  };
  const handleDrop = (colIndex: number) => {
    if (
      draggedColIndex &&
      dragTagIdS &&
      draggedColIndex.length === dragTagIdS.length &&
      draggedColIndex !== dragTagIdS
    ) {
      const tempArsData = { ...arsData };
      const parentData = findParentOfData(tempArsData, draggedColIndex);
      if (parentData) {
        swapAndChangeIds(parentData);
      }
    }
  };
  const handleDragOver = (e: any, id: string) => {
    e.preventDefault();
    setDragTagIdS(id);
  };

  const handleDragEnd = () => {
    setDraggedColIndex(null);
    setDragTagIdS(null);
    setDragOfParentIdS(null);
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const swapAndChangeIds = (parentData: NextItem) => {
    try {
      const draggedIndex = parentData.next.findIndex((item) => item.id === draggedColIndex);
      const dragOverIndex = parentData.next.findIndex((item) => item.id === dragTagIdS);
      if (draggedIndex === -1 || dragOverIndex === -1) return;

      const temp = parentData.next[draggedIndex];
      parentData.next[draggedIndex] = parentData.next[dragOverIndex];
      parentData.next[dragOverIndex] = temp;

      parentData.next[draggedIndex].id = draggedColIndex;
      parentData.next[draggedIndex].source.userinput = draggedColIndex;

      parentData.next[dragOverIndex].id = dragTagIdS as string;
      parentData.next[dragOverIndex].source.userinput = dragTagIdS as string;

      const updateNextIds = (items: any, oldPrefix: string, newPrefix: string) => {
        items.forEach((item: any) => {
          item.id = item.id.replace(oldPrefix, newPrefix);

          if (item.next && item.next.length > 0) {
            const newOldPrefix = `${oldPrefix}${item.id.charAt(oldPrefix.length)}`;
            const newNewPrefix = `${newPrefix}${item.id.charAt(newPrefix.length)}`;
            updateNextIds(item.next, newOldPrefix, newNewPrefix);
          }
        });
      };

      if (parentData.next[draggedIndex].next && parentData.next[draggedIndex].next.length > 0) {
        updateNextIds(parentData.next[draggedIndex].next, dragTagIdS as string, draggedColIndex);
      }
      if (parentData.next[dragOverIndex].next && parentData.next[dragOverIndex].next.length > 0) {
        updateNextIds(parentData.next[dragOverIndex].next, draggedColIndex, dragTagIdS as string);
      }

      handleDragEnd();
      updatedJsonFile();
    } catch (error) {
      console.error(error);
    }
  };

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // arsList 리스트 담는 변수
  const arsList = findDataById(arsData, id)?.next;

  return (
    <>
      {/* @TODO ARS 카드 추가 예시???? 느낌 코드 */}
      {/* <ArsCardModal content={'추가'} /> */}
      {/* <PlusIcon
        arsData={arsData}
        handleAddIdState={handleAddIdState}
        isAddIdS={isAddIdS}
        id={'123'}
        updatedJsonFile={updatedJsonFile}
        selectAddCardS={selectAddCardS}
        setSelectAddCardS={setSelectAddCardS}
      /> */}
      {findDataById(arsData, id)?.next.map((nextItem: any, index: number) => {
        let number: string;
        if (index === 9) {
          number = '0';
        } else if (index === 10) {
          number = '*';
        } else if (index === 11) {
          number = '#';
        } else {
          number = String(index + 1);
        }
        // const lastItem = arsList[index > 0 ? index - 1 : index].s015Data.action;
        const lastItem = index > 0 ? arsList[index - 1].s015Data.action : '초기값';
        return (
          <React.Fragment key={nextItem?.id || index}>
            {/* ARS CARD - 해당 ID 의 action 이 있으면 ARS 카드 생성 */}
            {nextItem?.s015Data.action && (
              <li>
                {/* action 이 있는 JSON 데이터만 표출 */}
                <div id={nextItem?.id}>
                  <p>{number}</p>
                  <div
                    ref={(el) => {
                      setRef(nextItem?.id, el);
                      cardRef.current[nextItem?.id] = el;
                    }}
                    draggable
                    onDragStart={() => {
                      timerRef.current = setTimeout(() => {
                        handleDragStart(nextItem?.id);
                      }, 100);
                    }}
                    onMouseUp={() => {
                      if (timerRef.current) {
                        clearTimeout(timerRef.current);
                        timerRef.current = null;
                        handleDragEnd();
                      }
                    }}
                    onDragOver={(e) => handleDragOver(e, nextItem?.id)}
                    onDrop={() => handleDrop(nextItem?.id)}
                    onDragEnd={handleDragEnd}
                    className={`arsCardModalWrap ${nextItem?.id === animationNumberS ? 'active_ars' : ''}`}
                  >
                    <ArsCardModal
                      content={nextItem.s015Data.action}
                      data={findDataById(arsData, nextItem?.id)}
                      type={isUpdatedIdS === nextItem?.id ? 'update' : 'view'}
                      handleUpdateIdState={handleUpdateIdState}
                      arsData={arsData}
                      updatedJsonFile={updatedJsonFile}
                    />
                  </div>
                  {/* 음성재생 좌측 아이콘 분기 컴포넌트 */}
                  {nextItem?.s015Data?.type === 'READ' && (checkArrow(nextItem?.id) || isAddS) && !dragOfParentIdS && (
                    <ArrowIcon />
                  )}
                  {/*{nextItem?.s015Data?.type === 'READ' && <ArrowIcon />}*/}
                  {/* active 키패드 카드 focus를 위한 input */}
                </div>
                {/* 음정재생에 연결된 데이터가 있을 경우 */}
                {nextItem?.s015Data?.type === 'READ' && (
                  <ul
                    style={{
                      opacity: dragOfParentIdS ? '0' : '1',
                      height: dragOfParentIdS ? '0px' : 'auto',
                    }}
                  >
                    <ArsCardListComponent
                      setArsData={setArsData}
                      id={nextItem?.id}
                      arsData={arsData}
                      isUpdatedIdS={isUpdatedIdS}
                      handleUpdateIdState={handleUpdateIdState}
                      handleAddIdState={handleAddIdState}
                      isAddIdS={isAddIdS}
                      selectAddCardS={selectAddCardS}
                      setSelectAddCardS={setSelectAddCardS}
                      updatedJsonFile={updatedJsonFile}
                      isAddS={isAddS}
                    />
                  </ul>
                )}
              </li>
            )}
            {/* PLUS ICON - plus 주석*/}
            {/* {nextItem?.s015Data.action === '' && (isAddS || dragOfParentIdS) && ( */}
            {nextItem?.s015Data.action === '' && ((lastItem && isAddS) || dragOfParentIdS) && (
              <li>
                <div id={nextItem?.id}>
                  {/* {(lastItem !== '초기값' || dragOfParentIdS || id === 'root') && <p>{number}</p>} */}
                  <p>{number}</p>
                  <div
                    className="arsCardModalWrap"
                    ref={(el) => {
                      setRef(nextItem?.id, el);
                      cardRef.current[nextItem?.id] = el;
                    }}
                    onDrop={() => handleDrop(nextItem?.id)}
                    onDragOver={(e) => handleDragOver(e, nextItem?.id)}
                    onDragEnd={handleDragEnd}
                  >
                    {dragOfParentIdS ? (
                      <ArsDropBox id={nextItem?.id} />
                    ) : (
                      <PlusIcon
                        arsData={arsData}
                        handleAddIdState={handleAddIdState}
                        isAddIdS={isAddIdS}
                        id={nextItem?.id}
                        updatedJsonFile={updatedJsonFile}
                        selectAddCardS={selectAddCardS}
                        setSelectAddCardS={setSelectAddCardS}
                        lastItem={lastItem}
                      />
                    )}
                  </div>
                </div>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ArsCardListComponent;
