import { useQueryClient } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { amlQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

export const useAddTableCache = () => {
  const queryClient = useQueryClient();
  const environmentS = useRecoilValue(environmentRecoil);

  /* 015 리스트 캐시업데이트를 위한 recoil */
  const [amlQueryParams] = useRecoilState(amlQueryParamsRecoil);

  // ==============================
  // 간편추가 저장 성공 Hook
  // ==============================
  const onSuccessAddTable = (data: any) => {
    const prevData: any = queryClient.getQueryData(['/smt/history/latest/app', amlQueryParams]);
    const newPages = prevData.pages.map((page: any) => {
      return {
        ...page,
        content: page.content.map((content: any) => {
          if (content.phoneNumber === data.keyCommNo) {
            return {
              ...content,
              buddyName: data.buddyNm,
            };
          }
          return content;
        }),
      };
    });
    const newData = { ...prevData, pages: newPages };
    queryClient.setQueryData(['/smt/history/latest/app', amlQueryParams], newData);
  };

  return { onSuccessAddTable };
};
