import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const use015ServiceStatus = () => {
  const queryClient = useQueryClient();
  /* 015 서비스 상태 get 요청 API */
  const [statusData, setStatusData] = useState<any>(queryClient.getQueryData(['/say015/serviceStatus']));
  const [refetchS, setRefetchS] = useState<boolean>(false);

  useEffect(() => {
    if (!refetchS) return;
    setStatusData(queryClient.getQueryData(['/say015/serviceStatus']));
    setRefetchS(false);
  }, [refetchS]);
  // ==============================
  // 015ServiceStatus stt free time 캐시 update hook
  // ==============================
  const updateCache015Status = (sttTime: any) => {
    const prevData: any = queryClient.getQueryData(['/say015/serviceStatus']);
    const newData = { ...prevData, sttTime };
    queryClient.setQueryData(['/say015/serviceStatus'], newData);
  };

  // ==============================
  // 015ServiceStatus tts count 캐시 update hook
  // ==============================
  const updateCacheTtsStatus = (usedTssCount: any) => {
    const prevData: any = queryClient.getQueryData(['/say015/serviceStatus']);
    const newData = { ...prevData, ttsCount: usedTssCount };
    queryClient.setQueryData(['/say015/serviceStatus'], newData);
  };
  return { statusData, updateCache015Status, updateCacheTtsStatus, setRefetchS };
};
