/**
 * @title 초기에 API 요청 해야하는 Query, Mutaiton
 */

import { getCallbackList, getProfileInfo } from '@/apis/api/myPageApis';
import { getPointApi } from '@/apis/api/pointApi';
import { get015ServiceStatus } from '@/apis/api/say015Apis';
import { userPointRecoil } from '@/recoil/atoms/atoms';
import { my015StatusRecoil } from '@/recoil/atoms/use015Status';
import { callbackListRecoil } from '@/recoil/atoms/useCallbackList';
import { userInfoRecoil } from '@/recoil/atoms/useInfo';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

export const useInitApis = () => {
  const token = localStorage.getItem('authorizationToken');
  // 사용자 회원정보 recoil
  const setUserInfoS = useSetRecoilState(userInfoRecoil);
  // 사용자 015 부가서비스 status recoil
  const setMy015StatusS = useSetRecoilState(my015StatusRecoil);
  // 사용자 015 무료 혜택 서비스 현황 recoil ( ttsCount, sttTime ... )
  const setUserPointS = useSetRecoilState(userPointRecoil);
  // 사용자 발신번호 List recoil
  const setCallbackListS = useSetRecoilState(callbackListRecoil);

  // 015 status get api useQuery
  const { data: serviceStatusD } = useQuery(['/say015/serviceStatus'], get015ServiceStatus, {
    /* testCode수정 */
    enabled: token !== null,
    // enabled: false,

    // refetchOnWindowFocus: false,
  });
  // setMy015StatuS(serviceStatusD);
  // login user profile get api useQuery
  const { data: profileInfoD } = useQuery(['profile/info'], getProfileInfo, {
    enabled: token !== null,
  });
  // setUserInfoS(profileInfoD);
  // 사용자 포인트 get api useQuery
  const { data: userPointD } = useQuery(['/userPoint'], getPointApi, {
    enabled: token !== null,
    staleTime: 0,
    cacheTime: 0,
  });
  // 승인된 발신번호 리스트 get api useQuery
  const { data: callbackListD } = useQuery(['/message/getCallbackList'], getCallbackList, {
    enabled: token !== null,
  });
  // setUserPointS(userPointD)
  useEffect(() => {
    if (serviceStatusD) {
      setMy015StatusS(serviceStatusD);
    }
  }, [serviceStatusD, setMy015StatusS]);
  ``;

  useEffect(() => {
    if (profileInfoD) {
      setUserInfoS(profileInfoD);
    }
  }, [profileInfoD, setUserInfoS]);

  useEffect(() => {
    if (userPointD) {
      setUserPointS(userPointD);
    }
  }, [userPointD, setUserPointS]);

  useEffect(() => {
    if (callbackListD) {
      setCallbackListS(callbackListD);
    }
  }, [callbackListD, setCallbackListS]);
};
