import React, { useState } from 'react';
import ArsCardSpeak from './ArsCardSpeak';
import ArsCardMsg from './ArsCardMsg';
import ArsCardCall from './ArsCardCall';
import ArsCardRecord from './ArsCardRecord';
// import * as S from './ArsCardModal.styles';
import * as S from '@/widgets/ars/components/arsTree/components/arsCardModal/ui/ArsCardModal.styles';
import ArsCardSpeak2 from '@/components/say015/Modal/arsCards/ArsCardSpeak2';
import ArsCardSpeak3 from '@/components/say015/Modal/arsCards/ArsCardSpeak3';
import ArsCardADD from '@/components/say015/Modal/arsCards/ArsCardADD';
import ArsCardSpeakSet from './ArsCardSpeakSet';

export type TArsCard =
  | '인사말'
  | '문자발송'
  | '착신전환'
  | '안내멘트'
  | '음성녹음 받기'
  | '음성재생'
  | '텍스트재생'
  | '녹음재생'
  | '추가';

export interface ArsCardModalProps {
  content?: TArsCard; // 어떤 ARS 카드인지
  data?: any; // 각각의 Ars Card ID 기준으로 부터 시작되는 JSON 데이터
  handlePlus?(): void; // 플러스 아이콘 즉, 생성 버튼 클릭 함수
  type?: 'update' | 'view' | 'add' | 'mock'; // 각각의 카드의 현재 타입
  handleUpdateIdState?: (isUpdatedId: string | null) => void; // 수정하려는 ARS Card 의 ID 변경 함수
  handleAddIdState?: (isAddId: string | null) => void; // 생성하려고 하는 ARS Card 의 ID 변경 함수
  arsData?: any; // JSON 데이터
  handleCancel?: () => void; // 닫기 버튼 클릭 함수
  updatedJsonFile?(): void; // ARS Json 업데이트
  cancelEdit?: boolean; // 수정 취소시 텍스트 초기화 boolean
  cardTitle?: string; // title input 값
}

const ArsCardModal = (props: ArsCardModalProps) => {
  const [cancelEdit, setCancelEdit] = useState<boolean>(false);
  // ==========================
  // 수정 및 생성 취소 버튼 이벤트 함수
  // ==========================
  const handleCancel = () => {
    setCancelEdit(true);
    switch (props.type) {
      case 'add':
        if (!props.handleAddIdState) return;
        props.handleAddIdState(null);
        break;
      case 'update':
        if (!props.handleUpdateIdState) return;
        props.handleUpdateIdState(null);
        break;
      default:
        break;
    }
  };
  return (
    <S.ArsCardWrap className="arsCardWrap">
      {props.content && props.content === '추가' && <ArsCardADD />}
      {props.content && props.content === '인사말' && (
        <ArsCardSpeak {...props} handleCancel={handleCancel} cancelEdit={cancelEdit} />
      )}
      {props.content && props.content === '문자발송' && <ArsCardMsg {...props} handleCancel={handleCancel} />}
      {props.content && props.content === '착신전환' && <ArsCardCall {...props} handleCancel={handleCancel} />}
      {/* TTS 카드 추가시 사용되는 컴포넌트 */}
      {/* {props.content && props.content === '텍스트재생' && <ArsCardSpeak2 {...props} handleCancel={handleCancel} />} */}
      {/* TTS 카드 표출시 사용되는 컴포넌트 */}
      {/* {props.content && props.content === '안내멘트' && props.data.s015Data.ttsText !== '' && (
        <ArsCardSpeak2 {...props} handleCancel={handleCancel} />
      )} */}
      {/* 녹음 ARS 카드 추가 컴포넌트 표출 */}
      {/* {props.content && props.content === '녹음재생' && <ArsCardSpeak3 {...props} handleCancel={handleCancel} />} */}
      {/* 녹음 ARS 카드 view 컴포넌트 표출 */}
      {/* {props.content && props.content === '안내멘트' && props.data.s015Data.ttsText === '' && (
        <ArsCardSpeak3 {...props} handleCancel={handleCancel} />
      )} */}
      {props.content &&
        (props.content === '안내멘트' || props.content === '텍스트재생' || props.content === '녹음재생') && (
          <ArsCardSpeakSet {...props} handleCancel={handleCancel} />
        )}
      {props.content && props.content === '음성녹음 받기' && <ArsCardRecord {...props} handleCancel={handleCancel} />}
    </S.ArsCardWrap>
  );
};

export default ArsCardModal;
