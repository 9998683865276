import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.25em;
  height: 1em;
`;

const IconFolder: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '1.6rem'}
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        opacity=".8"
        d="M8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8.59 0.59Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconFolder;
