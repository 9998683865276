import MessageResultTable from '@/components/Organism/Charge/MessageResultTable';
import PaymentHistoryTable from '@/components/Organism/Charge/PaymentHistoryTable';
import RegisterTaxInvoice from '@/components/Organism/Charge/RegisterTaxInvoice';
import TaxInvoiceAuto from '@/components/Organism/Charge/TaxInvoiceAuto';
import TaxInvoiceHistoryTable from '@/components/Organism/Charge/TaxInvoiceHistoryTable';
import TaxInvoiceList from '@/components/Organism/Charge/TaxInvoiceList';
import UsageHistoryTable from '@/components/Organism/Charge/UsageHistoryTable';
import OneDepthSideNav from '@/components/common/OneTapSideNav';
import CashCharge from '@/pages/CashCharge/CashCharge';
import My015Charge from '@/pages/My015Charge/My015Charge';
import My015SubInfo from '@/pages/My015Charge/My015SubInfo';
import { selectedComponentRecoil, windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { my015StatusRecoil } from '@/recoil/atoms/use015Status';
import { SERVICE_NAME } from '@/shared/constants';
import IconArrowRight from '@/styles/icons/IconArrowRight';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import TitleLine from '../../components/Molecules/Titles/TitleLine';
import CallingNumberAdmin from '../../components/Organism/MyPage/CallingNumberAdmin';
import MemberLeave from '../../components/Organism/MyPage/MemberLeave';
import PhoneNumberChange from '../../components/Organism/MyPage/PhoneNumberChange';
import PwChange from '../../components/Organism/MyPage/PwChange';
import UserInfoChange from '../../components/Organism/MyPage/UserInfoChange';
import UserNameChange from '../../components/Organism/MyPage/UserNameChange';
import WhiteDomainPost from '../../components/Organism/MyPage/WhiteDomainPost';
import * as S from './MyPage.style';

const MyPage = ({ link = 'mypage' }) => {
  const subNavLinkUser = [
    { label: '회원정보 변경', to: '/userinfochange' },
    { label: '비밀번호 변경', to: '/passwordchange' },
    { label: '휴대폰번호 변경', to: '/phonenumberchange' },
    { label: '명의 변경', to: '/usernamechange' },
    { label: '발신번호 관리', to: '/callingnumber' },
  ];
  const subNavLink015 = [
    { label: `${SERVICE_NAME} 발급 정보`, to: '/my015subinfo' },
    // TODO - 이용요금 결제 탭 주석합니다. 서버 문제없을시 다시 살리면됩니다.
    { label: `${SERVICE_NAME} 이용요금 결제`, to: '/my015charge' },
  ];
  const subNavLinkCharge = [
    // 충전소 관련
    { label: '캐시 충전하기', to: '/charge' },
    { label: '결제내역', to: '/payment' },
    { label: '사용내역', to: '/usage' },
    { label: '전송결과 조회', to: '/messageResult' },
    { label: '세금계산서 자동 발행', to: '/TaxInvoiceAuto' },
    { label: '현금영수증 발행신청', to: '/registerTaxInvoice' },
    { label: '세금계산서/현금영수증 신청내역', to: '/taxCashReceiptsDetail' },
    { label: '세금계산서 발행내역', to: '/taxInvoiceDetail' },
    { label: '회원 탈퇴', to: '/memberleave' },
  ];

  const currComponent: { [key: string]: React.ReactElement } = {
    userinfochange: <UserInfoChange />, // 회원정보 변경
    passwordchange: <PwChange />, // 비밀번호 변경
    phonenumberchange: <PhoneNumberChange />, // 휴대폰번호 변경
    usernamechange: <UserNameChange />, // 명의 변경
    callingnumber: <CallingNumberAdmin />, // 발신번호 관리

    // 015 서비스
    my015subinfo: <My015SubInfo />, // 015 발급정보
    my015charge: <My015Charge />,

    // 결제정보
    charge: <CashCharge />, // 캐시 충전하기
    payment: <PaymentHistoryTable />, // 결제내역
    usage: <UsageHistoryTable />, // 사용내역
    messageResult: <MessageResultTable />, // 전송결과 조회
    TaxInvoiceAuto: <TaxInvoiceAuto />, // 세금계산서 자동 발행
    RegisterTaxInvoice: <RegisterTaxInvoice />, // 현금영수증 발행신청
    taxCashReceiptsDetail: <TaxInvoiceList />, // 세금계산서/현금영수증 신청내역
    taxInvoiceDetail: <TaxInvoiceHistoryTable />, // 세금계산서 발행내역
    memberleave: <MemberLeave />, // 회원 탈퇴

    // 잘못된 경로
    whitedomainpost: <WhiteDomainPost />,

    detail: <PaymentHistoryTable />,
  };

  const navigate = useNavigate();

  // 반응형 넓이 가져오는 RECOIL 768이하 부터 반응형
  const windowWidth = useRecoilValue(windowWidthRecoil);

  const my015StatusS = useRecoilValue(my015StatusRecoil);

  // 반응형때 뒤로가기 버튼을위한 테이블 표시 STATE
  const [selectedComponent, setSelectedComponent] = useRecoilState(selectedComponentRecoil);

  // 뒤로가기 버튼 클릭 이벤트
  const handleBackClick = () => {
    setSelectedComponent('mypage');
    navigate('/mypage');
  };

  const handleNavigationClick = (component: string) => {
    setSelectedComponent(component);
  };

  // link에 해당하는 label 값을 찾는 함수
  const findLabelByLink = (linkValue: any) => {
    const allLinks = [...subNavLinkUser, ...subNavLink015, ...subNavLinkCharge];
    // 슬래시(/)를 제거한 linkValue를 사용하여 비교
    const cleanedLink = linkValue.replace(/\//g, '');
    const foundLink = allLinks.find((item) => item.to.replace(/\//g, '') === cleanedLink);
    return foundLink ? foundLink.label : null; // 해당 link가 없으면 null 반환
  };

  const labelValue = findLabelByLink(link); // link에 해당하는 label 값을 가져옴

  return (
    <S.Container className="myPageContainer">
      <S.Wrapper>
        <S.Title
          className={`title ${selectedComponent === 'mypage' ? '' : 'none'}`}
          isActive={selectedComponent === 'mypage'}
        >
          {/* <S.Title className={`title ${selectedComponent === 'mypage' ? '' : 'none'}`}> */}
          <TitleLine text="회원정보" type="main" marginBottom="0" marginTop="0" />
        </S.Title>
        <S.SubWrapper className={` ${windowWidth > 768 ? 'pc' : 'mobile'}`}>
          <>
            <S.SideNav className="sideNav" isActive={selectedComponent === 'mypage'}>
              <div>
                <h5>계정 정보</h5>
                <OneDepthSideNav list={subNavLinkUser} handleNavigationClick={handleNavigationClick} />
              </div>
              {my015StatusS?.say015Point !== undefined ? (
                <div>
                  <h5>{SERVICE_NAME} 서비스</h5>
                  <OneDepthSideNav list={subNavLink015} handleNavigationClick={handleNavigationClick} />
                </div>
              ) : null}
              <div>
                <h5>결제정보</h5>
                <OneDepthSideNav list={subNavLinkCharge} handleNavigationClick={handleNavigationClick} />
              </div>
            </S.SideNav>
            <S.Content className={`contents ${selectedComponent === 'mypage' ? '' : 'active'}`}>
              {/* 뒤로가기 버튼 */}
              {selectedComponent !== 'mypage' && !(windowWidth > 768) && (
                <div className="headerWrap">
                  <button type="button" onClick={() => handleBackClick()}>
                    <IconArrowRight fontSize={'2rem'} className="left" />
                  </button>
                  <p>{labelValue}</p>
                </div>
              )}
              <div className="currentGroup">{currComponent[link]}</div>
            </S.Content>
          </>
        </S.SubWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default MyPage;
