import { atom } from 'recoil';

/**
 * @title 주소록에 관련된 recoil 파일
 *
 * @since 2024.05.24
 * */

export type groupBuddyListParamsType = {
  groupSeqNo: number | null;
  /* 한 페이지에 표시할 포스트 개수	 */
  pageSize: number;
  /* 검색 단어	 */
  keyword: string;
};

export const groupBuddyListParamsRecoil = atom<groupBuddyListParamsType>({
  key: 'groupBuddyListParams',
  default: {
    groupSeqNo: null,
    pageSize: 50,
    keyword: '',
  },
});

/**
 * @title 주소록 메인 테이블에 체크된 데이터들을 가공해서 삭제, 소프트폰 전송 수신인 표출 등 에 사용되는 recoil
 * */
export type MainTableCheckDataFormatType =
  | {
      groupSeqNo: number;
      groupCheck: boolean;
      groupNm: string;
      buddySeqNo: number[] | null;
      count: number;
      api: boolean;
    }[]
  | null;
export const mainTableCheckDataFormatRecoil = atom<MainTableCheckDataFormatType>({
  key: 'mainTableCheckDataFormatRecoil',
  default: null,
});

/**
 * @title 주소록, 소프트폰 검색어
 * */
export const searchTextRecoil = atom<string>({
  key: 'searchTextRecoil',
  default: '',
});

/**
 * @title 그룹리스트 로더 상태 Recoil
 */
export const delLoadingRecoil = atom<boolean>({
  key: 'delLoadingRecoil',
  default: false,
});
