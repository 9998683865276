import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance, instanceFile } from '@/shared/lib/clientAxios';
import { useModalHook } from '@/components/commons/modals/useModalHook';

interface MutationVariables {
  messageType: string;
  messageTitle: string;
  messageBody: string;
  imgFile?: File; // imgFile은 선택적(optional)으로 정의
  messageTabState: string;
  searchKeyword: string;
}

// API 호출을 분리한 함수
const saveMessageApi = async ({
  messageType,
  messageTitle,
  messageBody,
  imgFile,
  messageTabState,
  searchKeyword,
}: MutationVariables) => {
  // SMS 저장 로직
  if (messageType === 'SMS') {
    return instance.post('/message/archive/sms', {
      title: messageTitle,
      body: messageBody,
    });
  }

  // MMS/LMS 저장 로직
  const formData = new FormData();
  if (imgFile) {
    formData.append('file', imgFile);
  }
  if (messageTitle) {
    formData.append('title', messageTitle);
  }
  if (messageBody) {
    formData.append('body', messageBody);
  }
  return instanceFile.post('/message/archive/mms', formData);
};

export const usePostSaveMessageList = () => {
  const { warningModal } = useModalHook();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: MutationVariables) => {
      const { messageType, messageTitle, messageBody, messageTabState, searchKeyword } = variables;
      // API 호출
      const response = await saveMessageApi(variables);
      return {
        data: response,
        variables, // variables를 결과로 포함
      };
    },
    onSuccess: (data) => {
      const { variables } = data; // variables를 가져옵니다
      const { messageTabState, searchKeyword } = variables;

      // 메시지 리스트 쿼리 무효화
      queryClient.invalidateQueries(['messages', messageTabState, searchKeyword]);

      // successModal('저장', `${messageTabState} 저장함에 저장 되었습니다.`, true);
    },
    onError: (error) => {
      console.error('메시지 전송 중 오류 발생:', error);
      warningModal('오류', '메시지 전송 중 오류가 발생했습니다. 다시 시도해 주세요.', true);
    },
  });
};
