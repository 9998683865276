/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  amlQueryParamsRecoil,
  chatListDataRecoil,
  mhQueryParamsRecoil,
  rcvMsgCacheToggleRecoil,
  rcvMsgPopupToggleRecoil,
  sendMsgCacheToggleRecoil,
  vmsGetApiIsLoadingRecoil,
} from '@/recoil/atoms/chatRoom';
import { useQueryMessageHistory } from '@/components/hooks/queries/useQueryMessageHistory';
import { useMutationDeleteMtMessage, useMutationResendMtMessage } from '@/components/hooks/mutations/postApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { calScrollEvent, calScrollUpEvent } from '@/apis/hooks/useScrollInfinity';
import { WORKS015_LMS, WORKS015_MMS, WORKS015_SMS } from '@/shared/constants';
import { useChatRoom015Comm } from '@/components/hooks/customs/chatRoom015/useChatRoom015Comm';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

interface IUseChatRoom015Body {
  chatRoomRef: React.RefObject<HTMLDivElement>;
}

/**
 * @title ChatRoom015Body Hooks
 *
 * @author 정휘학
 * @since 2024.04.08
 * */
export const useChatRoom015Body = (props: IUseChatRoom015Body) => {
  const [chatListData, setChatListData] = useRecoilState(chatListDataRecoil);
  const [mhQueryParamsS] = useRecoilState(mhQueryParamsRecoil);
  const [sendMsgCacheToggle, setSendMsgCacheToggle] = useRecoilState(sendMsgCacheToggleRecoil);
  const [rcvMsgCacheToggle, setRcvMsgCacheToggle] = useRecoilState(rcvMsgCacheToggleRecoil);
  const [vmsGetApiIsLoadingS] = useRecoilState(vmsGetApiIsLoadingRecoil);
  const [rcvMsgPopupToggle, setRcvMsgPopupToggle] = useRecoilState(rcvMsgPopupToggleRecoil);
  const [viewScrollS, setViewScrollS] = useState<boolean>(false);
  /* 사용자 포인트 selector 초기화 */
  const queryClient = useQueryClient();
  const environmentS = useRecoilValue(environmentRecoil);

  const { confirmModal } = useModalHook();

  /* 웹 소켓으로 받은 mo 데이터 */

  const [amlQueryParams] = useRecoilState(amlQueryParamsRecoil);

  const commHooks = useChatRoom015Comm();
  /* 기존 스크롤 높이 유지 */
  const [previousHeight, setPreviousHeight] = useState<number>(0);
  /**
   * @title 채팅방 리스트 GET API
   * */
  const { mhStatus, mhLoading, mhData, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useQueryMessageHistory(mhQueryParamsS);

  /**
   * @title 015 채팅방 전송 실패한 메세지 재전송 POST API
   * */
  const { mutate: resendMtMsgM } = useMutation(useMutationResendMtMessage);
  /**
   * @title 015 채팅방 전송 실패한 메세지 삭제 POST API
   * */
  const { mutate: deleteMtMsgM } = useMutation(useMutationDeleteMtMessage);
  /**
   * @title 채팅방 리스트 스크롤 Hook
   * */
  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (calScrollUpEvent({ scrollTop, scrollHeight, clientHeight }) && hasNextPage && !isFetchingNextPage) {
      setPreviousHeight(props.chatRoomRef.current?.scrollHeight as number);
      fetchNextPage();
    }
    /* 새로운 메시지 알림 팝업 false */
    if (calScrollEvent({ scrollTop, scrollHeight, clientHeight })) setRcvMsgPopupToggle(false);
  };
  /**
   * @title 채팅방 입장시
   * */
  useEffect(() => {
    if (!mhQueryParamsS.contactNumber) return;
    // 수신 카운트 0
    commHooks?.softChatListCount(mhQueryParamsS.contactNumber, amlQueryParams);
    // 리스트 데이터 초기화
    setChatListData(null);
    // 전 스크롤 초기화
    setPreviousHeight(0);
    setViewScrollS(false);
  }, [mhQueryParamsS.contactNumber]);

  useEffect(() => {
    if (mhData && !mhLoading) {
      setChatListData(commHooks.formatReduceMhData(mhData));
    }
  }, [mhData, mhLoading]);

  /**
   * @title 스크롤 위치 설정 useEffect
   * */
  useEffect(() => {
    const chatListQueryData: any = queryClient.getQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber]);

    if (!props.chatRoomRef.current || !chatListQueryData) return;
    const chatRoomRefCurrent = props.chatRoomRef.current;

    if (rcvMsgCacheToggle) {
      setRcvMsgCacheToggle(false);
      return;
    }

    // 현재 상태와 조건 분리
    const isFirstPage = chatListQueryData.pages.length === 1;
    const isCacheToggleActive = sendMsgCacheToggle;
    if (isFirstPage || sendMsgCacheToggle) {
      // 새로운 메시지가 도착했거나 전송 시, 스크롤을 맨 아래로
      setSendMsgCacheToggle(false);
      setRcvMsgPopupToggle(false);
      setTimeout(() => {
        chatRoomRefCurrent.scrollTop = chatRoomRefCurrent.scrollHeight;
        setViewScrollS(true);
      }, 100);
    } else if (!isFirstPage) {
      // 2페이지 이상일 경우 이전 스크롤 높이를 계산
      const newScrollTop = chatRoomRefCurrent.scrollHeight - previousHeight;

      if (newScrollTop >= 0) {
        chatRoomRefCurrent.scrollTop = newScrollTop;
      }
      // 스크롤 상태 업데이트
      setPreviousHeight(chatRoomRefCurrent.scrollHeight);
    }
    // 캐시 토글 리셋
    if (isCacheToggleActive) {
      setSendMsgCacheToggle(false);
      setRcvMsgCacheToggle(false);
    }
  }, [chatListData, mhLoading, props.chatRoomRef, vmsGetApiIsLoadingS]);

  // 주석 - 스크롤 위치 설정 useEffect 수정 전 기존 로직
  // useEffect(() => {
  //   const chatListQueryData: any = queryClient.getQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber]);
  //   // 새로고침 후 엘리먼트 스크롤 값 bottom 으로 위치 고정

  //   if (!props.chatRoomRef.current || !chatListQueryData) return;
  //   const chatRoomRefCurrent = props.chatRoomRef.current;

  //   /* 캐쉬로 변경된 경우 스크롤 로직 패스 */
  //   if (rcvMsgCacheToggle) {
  //     setRcvMsgCacheToggle(false);
  //     return;
  //   }
  //   /* sendMsgCacheToggle ==> 메세지 전송시 하단으로 이동 */
  //   if (chatListQueryData.pages.length === 1 || sendMsgCacheToggle) {
  //     setSendMsgCacheToggle(false);
  //     setTimeout(() => {
  //       chatRoomRefCurrent.scrollTop = chatRoomRefCurrent.scrollHeight;
  //       setViewScrollS(true);
  //     }, 100);
  //   } else if (chatListQueryData.pages.length > 1) {
  //     chatRoomRefCurrent.scrollTop = chatRoomRefCurrent.scrollHeight - previousHeight;
  //   }
  // }, [chatListData, mhLoading, props.chatRoomRef, mhData, vmsGetApiIsLoadingS]);

  /**
   * @title 015 채팅 전송 실패한 메세지 재전송 Hook
   * */
  const reSend015Msg = (messageId: string, msgType: string) => {
    const reSendAmount = msgType === 'SMS' ? WORKS015_SMS : msgType === 'LMS' ? WORKS015_LMS : WORKS015_MMS;
    confirmModal(
      async () => {
        resendMtMsgM({ messageId }, { onSuccess: (response) => reSend015MsgOnSuccess(messageId, response) });
      },
      '재전송',
      `서비스 이용에 불편을 드려 죄송합니다.</br>실패한 전송 금액은 2시간 이내에 환불되며</br>재전송 금액은 ${reSendAmount}입니다.`,
      true,
    );
  };
  /**
   * @title 015 채팅 전송 실패한 메세지 재전송 API 완료 Hook
   * */
  const reSend015MsgOnSuccess = (messageId: string, response: any) => {
    // 소프트폰 015 채팅방 리스트 refetch
    queryClient.refetchQueries(['/smt/history/latest/app', amlQueryParams]);
    // 사용자 포인트 refetch
    queryClient.refetchQueries(['/userPoint']);
    // 캐시 데이터 업데이트
    commHooks.reSendMsgUpdate(messageId, response);
  };

  /**
   * @title 015 채팅 전송 실패한 메세지 삭제 Hook
   * */
  const cancelSend015Msg = (messageId: string) => {
    confirmModal(
      async () => {
        deleteMtMsgM({ messageId }, { onSuccess: () => delete015MsgOnSuccess(messageId) });
      },
      '전송취소',
      `서비스 이용에 불편을 드려 죄송합니다.</br>실패한 전송 금액은 2시간 이내에 환불됩니다.`,
      true,
    );
  };

  ('서비스 이용에 불편을 드려 죄송합니다 ');

  /**
   * @title 015 채팅 전송 실패한 메세지 삭제 API 완료 Hook
   * */
  const delete015MsgOnSuccess = (messageId: string) => {
    // 소프트폰 015 채팅방 리스트 refetch
    queryClient.invalidateQueries(['/smt/history/latest/app', amlQueryParams]);
    // 캐시 데이터 업데이트
    commHooks.deleteMsgUpdate(messageId);
  };

  return {
    handleScroll,
    mhStatus,
    mhLoading,
    chatListData,
    hasNextPage,
    reSend015Msg,
    cancelSend015Msg,
    viewScrollS,
  };
};
