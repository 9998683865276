import styled from 'styled-components';

export const Column = styled.span`
  margin: 0 5rem;
`;

interface IPageProps {
  isActive?: boolean;
}
export const Page = styled.span<IPageProps>`
  cursor: pointer;
  margin: 0 1rem;
  color: ${(props) => (props.isActive ? props.theme.colors.main : props.theme.colors.txt500)};
  font-weight: ${(props) => (props.isActive === true ? 'bold' : 'normal')};
  font-size: ${(props) => (props.isActive === true ? '1.8rem' : '1.2rem')};
`;
