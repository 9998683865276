import { detectRouter } from '@/shared/util/detectEnvUtils';
import { atom } from 'recoil';

export interface BuddyItem {
  buddySeqNo?: number;
  buddyNm?: string;
  keyCommNo?: string;
}

export interface GroupItem {
  groupSeqNo: number;
  usrKey: string;
  buddyCount: number;
  groupNm: string;
}

interface Content {
  prepayPayNo: string;
  subject: string;
  sndMsg: string;
  sndDttm: string;
  callback: string;
  msgGb: string;
  imgData: string | null;
  fileData: string | null;
}

//이전 페이지 pathName 가져오기
export const prevPathNameRecoil = atom<string>({
  key: 'prevPathNameRecoil',
  default: '',
});

//모바일 대량 전송을 위한 그룹 리스트 선택
export const mobileGroupSeqNoListAddRecoil = atom<GroupItem[]>({
  key: 'mobileGroupSeqNoListAddRecoil',
  default: [],
});

//모바일 대량 전송을 위한 버디 리스트 선택
export const mobileBuddySeqNoListAddRecoil = atom<BuddyItem[]>({
  key: 'mobileBuddySeqNoListAddRecoil',
  default: [],
});

//모바일 대화상대리스트 추가 리코일
export const mobileBuddyListAddRecoil = atom<BuddyItem[]>({
  key: 'mobileBuddyListAddRecoil',
  default: [],
});

// 모바일 주소록 그룹에서 선택된 그룹 값
export const selectedMobileGroupListRecoil = atom<GroupItem>({
  key: 'selectedMobileGroupListRecoil',
  default: {
    groupSeqNo: 0,
    usrKey: '',
    buddyCount: 0,
    groupNm: '',
  },
});

type mobileEnv = 'app' | 'pc' | 'mobile';

// 모바일 환경 구축 리코일
export const environmentRecoil = atom<mobileEnv>({
  key: 'environmentRecoil',
  default: detectRouter(),
  // default: 'app',
});

// TODO - 점검용
// testCode수정
// app 로그인창 이후 진입 막는 RECOIL - true : 접속불가, false : 접속가능
export const mobileAdRecoil = atom<boolean>({
  key: 'mobileAdRecoil',
  default: false,
});

export const sendImgFileRecoil = atom<any>({
  key: 'sendImgFileRecoil',
  default: null,
});
export const sendImgFileUrlRecoil = atom<string | null>({
  key: 'sendImgFileUrlRecoil',
  default: null,
});

// 추가하는 모든 컴포넌트 상태 리코일
export const addModalOpenRecoil = atom<boolean>({
  key: 'addModalOpenRecoil',
  default: false,
});

// 추가하는 모든 컴포넌트 Title 리코일
export const addModalTitleRecoil = atom<string>({
  key: 'addModalTitleRecoil',
  default: '',
});

// 단체 전송 전송 결과 컴포넌트 Open 리코일
export const resultOpenRecoil = atom<boolean>({
  key: 'resultOpenRecoil',
  default: false,
});

export const resultDataRecoil = atom<Content>({
  key: 'resultDataRecoil',
  default: {
    prepayPayNo: '',
    subject: '',
    sndMsg: '',
    sndDttm: '',
    callback: '',
    msgGb: '',
    imgData: '',
    fileData: '',
  },
});

/**
 * 단체 채팅방 메세지 리스트 선택했을때 or 새로 만들 때 id
 */
export const multiChatRoomIdRecoil = atom<string>({
  key: 'multiChatRoomIdRecoil',
  default: '',
});

interface loaderType {
  state: boolean;
  contents: string;
}

export const syncLoadingRecoil = atom<loaderType>({
  key: 'syncLoadingRecoil',
  default: {
    state: false,
    contents: '',
  },
});
