import React from 'react';
import styled from 'styled-components';
import ATitle from '@/components/Atom/ATitle';
import BaseBar from '../../Atom/BaseBar';
import { useToolBarListPage } from '@/components/hooks/customs/softPhone/useToolBarListPage';
import { windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useRecoilValue } from 'recoil';
import IconSpeaker from '@/styles/icons/IconSpeaker';
import IconLogout from '@/styles/icons/IconLogout';

interface ITitleLine {
  type: 'main' | 'sub';
  text: string;
  color?: string;
  marginBottom: string;
  marginTop: string;
}

export const TitleLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
`;

export const IconGroup = styled.div`
  display: flex;
  gap: 1rem;
  svg {
    width: 2.2rem;
    height: 2.2rem;
  }
  button {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt400};

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  button:hover {
    color: ${(props) => props.theme.colors.main};
  }
  @media screen and (max-width: 768px) {
    gap: 1.4rem;
    button {
      font-size: 1.7rem;
    }
  }
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  button {
    padding: 0 1rem 0 0;
  }
`;

const TitleLine = (props: ITitleLine) => {
  const windowWidth = useRecoilValue(windowWidthRecoil);
  const hooks = useToolBarListPage();

  return (
    <>
      {/* 아톰 */}
      <TitleLineContainer className="TitleLine">
        <TitleGroup className="titleGroup titleLine">
          <ATitle type={props.type} text={props.text} color={props.color} />
        </TitleGroup>
        {windowWidth > 768 ? null : (
          <IconGroup>
            <button className="notice" type="button" onClick={() => hooks.onClickNavigation('/customer/-1')}>
              <IconSpeaker /> <span>공지사항</span>
            </button>
            <button className="logout" type="button" onClick={() => hooks.onClickSignOut()}>
              <IconLogout /> <span>로그아웃</span>
            </button>
          </IconGroup>
        )}
      </TitleLineContainer>
      {/* 아톰 */}
      <BaseBar marginBottom={props.marginBottom} marginTop={props.marginTop} />
    </>
  );
};

export default TitleLine;
