import { useEffect, useState } from 'react';

export const useWindowPopup = () => {
  // 팝업 반응형 start
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /* 도움말 팝업 띄우는 로직 */
  type TOpenPopup = {
    url: string;
    target: '_blank' | '_self' | '_parent' | '_top' | string;
    size: '1' | '2' | '3' | '4' | '5' | string;
  };
  const openPopup = ({ url, target, size }: TOpenPopup) => {
    // 팝업 초기 사이즈를 지정해주기 위한 로직
    let width;
    let height;
    if (size === '1') {
      width = 1200;
      height = 900;
    }
    if (size === '2') {
      width = 1200;
      height = 895;
    }

    // 반응형에 맞춰서 팝업 사이즈 조절해주기 위한 로직
    if (size === '1' && screenWidth <= 640) {
      width = screenWidth;
      height = screenHeight;
    }

    window.open(url, target, `width=${width}, height=${height}, top=${screenHeight * 0.1}, left=${screenWidth * 0.1}`);
  };
  return { openPopup };
};
