import React from 'react';
import * as S from './ArsMockMsg.styles';
import { arsMsgToggleRecoil } from '@/recoil/atoms/say015Atom';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';
import { formatDateAll, getDateYYYYMMDDHHmmss } from '@/shared/util/format/dateFormatUtil';

interface IArsRealMsg {
  activeJsonData: NextItem;
}

const ArsRealMsg = ({ activeJsonData }: IArsRealMsg) => {
  // ars 폰 토글하는 STATS
  const setArsMsgToggleS = useSetRecoilState<boolean>(arsMsgToggleRecoil);

  // ==============================
  // onClick close msg hook
  // ==============================
  const onClickCloseMsg = () => {
    setArsMsgToggleS(false);
  };

  return (
    <S.ArsMockMsgWrap>
      {/* 상단 */}
      <div className="top">
        <div className="left">
          {/* 닫기버튼 */}
          <button className="backBtn" onClick={() => onClickCloseMsg()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none">
              <path
                d="M1.49247 9.51431L7.43268 17.0711C7.53179 17.1972 7.66348 17.2676 7.80041 17.2676C7.93735 17.2676 8.06903 17.1972 8.16814 17.0711L8.17454 17.0625C8.22275 17.0014 8.26115 16.9277 8.28738 16.8462C8.31362 16.7646 8.32715 16.6767 8.32715 16.5879C8.32715 16.4991 8.31362 16.4112 8.28738 16.3297C8.26115 16.2481 8.22275 16.1745 8.17454 16.1133L2.58074 8.99772L8.17454 1.88499C8.22275 1.82383 8.26115 1.75022 8.28738 1.66863C8.31362 1.58704 8.32715 1.49918 8.32715 1.41038C8.32715 1.32159 8.31362 1.23372 8.28738 1.15213C8.26115 1.07054 8.22275 0.996929 8.17454 0.935774L8.16814 0.927235C8.06903 0.801083 7.93735 0.730707 7.80041 0.730707C7.66348 0.730707 7.53179 0.801083 7.43268 0.927235L1.49247 8.48398C1.44023 8.55043 1.39864 8.63036 1.37022 8.71891C1.3418 8.80747 1.32715 8.9028 1.32715 8.99914C1.32715 9.09548 1.3418 9.19082 1.37022 9.27937C1.39864 9.36793 1.44023 9.44786 1.49247 9.51431Z"
                fill="currentColor"
                stroke="currentColor"
              />
            </svg>
          </button>
          <h2>015-8504-1234</h2>
        </div>
        {/* 닫기버튼 */}
        <button className="callBtn" onClick={() => onClickCloseMsg()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="31" viewBox="0 0 24 31" fill="none">
            <path
              d="M6.15575 0.161771C6.98716 -0.0889017 7.87934 -0.0472565 8.68378 0.279774C9.48822 0.606805 10.1564 1.19949 10.5771 1.95917L10.7177 2.24227L11.9755 5.03717C12.3564 5.88564 12.4799 6.82729 12.3307 7.74532C12.1814 8.66334 11.7659 9.51734 11.1357 10.2014L10.883 10.4522L8.90125 12.3009C8.54405 12.6391 8.81195 13.9482 10.104 16.1864C11.2668 18.2004 12.213 19.1409 12.6785 19.1922H12.7602L12.8609 19.1732L16.7559 17.9819C17.2793 17.8213 17.838 17.815 18.3649 17.9636C18.8919 18.1122 19.365 18.4094 19.7275 18.8198L19.9004 19.0383L22.4787 22.6103C22.984 23.3104 23.2358 24.1618 23.1926 25.0241C23.1494 25.8865 22.8138 26.7084 22.2412 27.3546L22.0094 27.594L20.9796 28.5706C20.0548 29.4463 18.898 30.0381 17.6468 30.2756C16.3955 30.513 15.1023 30.3862 13.9211 29.9101C10.2446 28.4281 6.90435 25.0423 3.87005 19.7869C0.830052 14.5182 -0.433448 9.92587 0.130852 5.99287C0.301612 4.80398 0.791179 3.68353 1.54756 2.75051C2.30394 1.8175 3.29889 1.10676 4.42675 0.693771L4.79345 0.572171L6.15575 0.161771Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>

      {/* 채팅방 */}
      <div className="chatRoom">
        <div className="chatWrap">
          <div>{activeJsonData && <p>{activeJsonData?.target?.level}</p>}</div>
          {/*<span className="time">오후 3:00</span>*/}
          <span className="time">{formatDateAll(getDateYYYYMMDDHHmmss())}</span>
        </div>
      </div>

      {/* 채팅입력창 */}
      <div className="chatInputWrap">
        <button className="imgBtn">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <path
              d="M9.26265 18.5526H3.96265C3.43221 18.5526 2.92351 18.353 2.54843 17.9977C2.17336 17.6423 1.96265 17.1604 1.96265 16.6579V3.39474C1.96265 2.89222 2.17336 2.41029 2.54843 2.05496C2.92351 1.69962 3.43221 1.5 3.96265 1.5H17.9626C18.4931 1.5 19.0018 1.69962 19.3769 2.05496C19.7519 2.41029 19.9626 2.89222 19.9626 3.39474V12.8684L16.8626 9.93158C16.4863 9.58217 15.9797 9.38756 15.4528 9.39004C14.9259 9.39253 14.4213 9.5919 14.0486 9.94484L4.96265 18.5526"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.9617 17.1312L15.9617 14.2891M15.9617 14.2891L18.9617 17.1312M15.9617 14.2891V19.4996"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.96143 9.07854C9.066 9.07854 9.96143 8.23023 9.96143 7.1838C9.96143 6.13737 9.066 5.28906 7.96143 5.28906C6.85686 5.28906 5.96143 6.13737 5.96143 7.1838C5.96143 8.23023 6.85686 9.07854 7.96143 9.07854Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <input type="text" />
        <button className="sendBtn">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
            <path
              d="M19.0049 11.0452C20.3087 10.4309 20.3087 8.5664 19.0042 7.953L3.06866 0.44804C1.76013 -0.16778 0.332962 1.03257 0.704733 2.43495L2.27755 8.36081L8.94929 8.36062C9.24638 8.3658 9.52956 8.48801 9.73783 8.70091C9.9461 8.91382 10.0628 9.2004 10.0628 9.49892C10.0628 9.79744 9.94607 10.084 9.73779 10.2969C9.5295 10.5099 9.24632 10.6321 8.94923 10.6373L2.27749 10.6375L0.705152 16.5642C0.332501 17.9658 1.76041 19.1653 3.06817 18.5502L19.0049 11.0452Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
    </S.ArsMockMsgWrap>
  );
};
export default ArsRealMsg;
