import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @title nice 인증 return 값 받아오는 Hook
 *
 * @author 정휘학
 * @since 2024.03.18
 * */
export const useNice = () => {
  const [niceData, setNiceData] = useState<any>(null);

  // 페이지 리다이렉트 형식일 경우 사용할 로직 start --
  const location = useLocation();
  const sEncodeData = location.state?.sEncodeData;

  useEffect(() => {
    if (sEncodeData) {
      setNiceData(sEncodeData);
    }
  }, [sEncodeData]);

  // -- end

  /**
   * @title 나이스 인증 window.opener.postMessage 이벤트로 부터 받아온 값을 저장하는 Hook
   * */
  const messageListener = (event: any) => {
    if (event.data.sEncodeData) {
      setNiceData(event.data.sEncodeData);
    }
  };

  /**
   * @title 나이스 인증 완료시 컴포넌트의 로직인 window.opener.postMessage 을 통해 데이터 가져오는 useEffect
   *
   * @author 정휘학
   * @since 2024.03.18
   * */
  useEffect(() => {
    // message 이벤트 리스너를 등록
    window.addEventListener('message', messageListener);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  return {
    niceData,
  };
};
