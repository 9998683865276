import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppRouter from './AppRouter';
import ComUseContent from '@/pages/Terms/ComUseContent';
import ComUseContent015 from '@/pages/Terms/ComUseContent015';
import ComProtect from '@/pages/Terms/ComProtect';
import Mobile015Signup from '@/components/mobileApp/mobile015Signup/Mobile015Signup';
import Mobile015SubSignup from '@/components/mobileApp/mobile015Signup/Mobile015SubSignup';
import Mobile015SubCharge from '@/components/mobileApp/mobile015Signup/Mobile015SubCharge';
import MobileSignin from '@/components/mobileApp/mobileSignin/MobileSignin';
import MobileFindAccount from '@/components/mobileApp/mobileFindAccount/MobileFindAccount';
import MobileFindAccountList from '@/components/mobileApp/mobileFindAccountList/MobileFindAccountList';
import MobileSignUp from '@/components/mobileApp/mobileSignUp/MobileSignUp';
import MobileSignUpForm from '@/components/mobileApp/mobileSignUpForm/MobileSignUpForm';
import MobileSignUpIdCheck from '@/components/mobileApp/mobileSignUpIdCheck/MobileSignUpIdCheck';
import MobileSignUpRecovery from '@/components/mobileApp/mobileSignUpRecovery/MobileSignUpRecovery';
import MobileLayout from '@/components/mobileApp/mobileLayout/MobileLayout';
import MobilePrivateRoute from '@/components/mobileApp/mobileRouter/MobilePrivateRoute';
import MobileAddress from '@/components/mobileApp/mobileAddress/MobileAddress';
import MobileAddressGroupList from '@/components/mobileApp/mobileAddress/mobileAddressGroupList/MobileAddressGroupList';
import MobileAddressBuddyList from '@/components/mobileApp/mobileAddress/mobileAddressBuddyList/MobileAddressBuddyList';
import MobileMessage from '@/components/mobileApp/mobileMessage/MobileMessage';
import MobileMessageList from '@/components/mobileApp/mobileMessage/mobileMessageList/MobileMessageList';
import MobileMessageAdd from '@/components/mobileApp/mobileMessage/mobileMessageAdd/MobileMessageAdd';
import MobileMessageChatRoom from '@/components/mobileApp/mobileMessage/mobileMessageChatRoom/MobileMessageChatRoom';
import MobileArs from '@/components/mobileApp/mobileArs/MobileArs';
import MobileArsTree from '@/components/mobileApp/mobileArs/mobileArsTree/MobileArsTree';
import MobileArsScenario from '@/components/mobileApp/mobileArs/mobileArsScenario/MobileArsScenario';
import MobileEtc from '@/components/mobileApp/mobileEtc/MobileEtc';
import MobileEtcList from '@/components/mobileApp/mobileEtc/mobileEtcList/MobileEtcList';
import MobileEtcCharge from '@/components/mobileApp/mobileEtc/mobileEtcCharge/MobileEtcCharge';
import MobileEtc015SubCharge from '@/components/mobileApp/mobileEtc/mobileEtc015SubCharge/MobileEtc015SubCharge';
import MobileEtcUserInfo from '@/components/mobileApp/mobileEtc/mobileEtcUserInfo/MobileEtcUserInfo';
import MobileEtcPwChange from '@/components/mobileApp/mobileEtc/mobileEtcPwChange/MobileEtcPwChange';
import MobileEtcPhoneNumberChange from '@/components/mobileApp/mobileEtc/mobileEtcPhoneNumberChange/MobileEtcPhoneNumberChange';
import MobileEtcUserNameChange from '@/components/mobileApp/mobileEtc/mobileEtcUserNameChange/MobileEtcUserNameChange';
import MobileEtcCallbackNumber from '@/components/mobileApp/mobileEtc/mobileEtcCallbackNumber/MobileEtcCallbackNumber';
import MobileEtcSubInfo from '@/components/mobileApp/mobileEtc/mobileEtcSubInfo/MobileEtcSubInfo';
import MobileEtcPayment from '@/components/mobileApp/mobileEtc/mobileEtcPayment/MobileEtcPayment';
import MobileEtcUsage from '@/components/mobileApp/mobileEtc/mobileEtcUsage/MobileEtcUsage';
import MobileEtcMessageResult from '@/components/mobileApp/mobileEtc/mobileEtcMessageResult/MobileEtcMessageResult';
import MobileEtcTaxInvoiceAuto from '@/components/mobileApp/mobileEtc/mobileEtcTaxInvoiceAuto/MobileEtcTaxInvoiceAuto';
import MobileEtcRegisterTaxInvoice from '@/components/mobileApp/mobileEtc/mobileEtcRegisterTaxInvoice/MobileEtcRegisterTaxInvoice';
import MobileEtcTaxCashReceiptsDetail from '@/components/mobileApp/mobileEtc/mobileEtcTaxCashReceiptsDetail/MobileEtcTaxCashReceiptsDetail';
import MobileEtcTaxInvoiceDetail from '@/components/mobileApp/mobileEtc/mobileEtcTaxInvoiceDetail/MobileEtcTaxInvoiceDetail';
import MobileEtcMemberLeave from '@/components/mobileApp/mobileEtc/mobileEtcMemberLeave/MobileEtcMemberLeave';
import MobileEtcNotice from '@/components/mobileApp/mobileEtc/mobileEtcCustomer/MobileEtcNotice';
import MobileEtcQA from '@/components/mobileApp/mobileEtc/mobileEtcCustomer/MobileEtcQA';
import MobileEtcVOC from '@/components/mobileApp/mobileEtc/mobileEtcCustomer/MobileEtcVOC';
import MobileEtcEvent from '@/components/mobileApp/mobileEtc/mobileEtcCustomer/MobileEtcEvent';
import EasyComponent from '@/shared/components/easyPay/ui/EasyComponent';
import AuthComponent from '@/shared/components/niceCertification/ui/AuthComponent';
import MobileEtcUserInfoChange from '@/components/mobileApp/mobileEtc/mobileEtcUserInfoChange/MobileEtcUserInfoChange';
import MultiMessageAdd from '@/components/mobileApp/mobileMessage/multiMessageAdd/MultiMessageAdd';
import MultiChatroom from '@/components/mobileApp/mobileMessage/multiChatroom/MultiChatroom';

const MobileRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AppRouter />}>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route index element={<Navigate to="/mobile" />} />
          <Route path="/mobile">
            {/* 이용약관 */}
            <Route path="ComUseContent" element={<ComUseContent />} />
            <Route path="ComUseContent015" element={<ComUseContent015 />} />
            {/* 개인정보 처리방침 */}
            <Route path="ComProtect" element={<ComProtect />} />

            <Route index element={<Navigate to="mobileSignin" />} />
            {/* <Route path="thanos" element={<Thanos />} /> */}

            {/* 015 미가입시 015 회원가입 */}
            <Route path="015Signup" element={<Mobile015Signup />} />
            {/* 015 가입 했는데 구독 만료 됐을때 다시 가입 하기 */}
            <Route path="015SubSignup" element={<Mobile015SubSignup />} />
            {/* 015 .구독만료시 충전할 수 있는 페이지 */}
            <Route path="015SubCharge" element={<Mobile015SubCharge />} />

            {/* 모바일 로그인페이지 */}
            <Route path="mobileSignin" element={<MobileSignin />} />
            {/* 모바일 ID/PW 찾기페이지 */}
            <Route path="mobileFindAccount" element={<MobileFindAccount />} />
            <Route path="mobileFindAccountList" element={<MobileFindAccountList />} />
            {/* 모바일 회원가입페이지 */}
            <Route path="mobileSignUp" element={<MobileSignUp />} />
            <Route path="mobileSignUpForm" element={<MobileSignUpForm />} />
            <Route path="mobileSignUpIdCheck" element={<MobileSignUpIdCheck />} />
            <Route path="mobileSignUpRecovery" element={<MobileSignUpRecovery />} />

            {/* 모바일 화면 레이아웃  *************************************************************************************************/}
            <Route path="/mobile" element={<MobileLayout />}>
              {/* 모바일 로그인 확인 ************************************************************************************************ */}
              <Route element={<MobilePrivateRoute />}>
                {/* 주소록 */}
                <Route path="address" element={<MobileAddress />}>
                  <Route index element={<Navigate to="groupList" />} />

                  <Route path="groupList" element={<MobileAddressGroupList />} />
                  <Route path="groupList/:groupSeqNo" element={<MobileAddressBuddyList />} />
                  <Route path="groupList/:groupSeqNo/:keyCommNo" element={<MobileMessageChatRoom />} />
                </Route>

                {/* 메세지 */}
                <Route path="message" element={<MobileMessage />}>
                  <Route index element={<Navigate to="messageList" />} />
                  <Route path="messageList" element={<MobileMessageList />} />
                  <Route path="messageList/messageAdd" element={<MobileMessageAdd />} />

                  <Route path="messageList/multiMessageAdd" element={<MultiMessageAdd />} />
                  <Route path="messageList/:keyCommNo" element={<MobileMessageChatRoom />} />
                  <Route path="messageList/multiChatroom" element={<MultiChatroom />} />
                </Route>

                {/* ARS */}
                <Route path="ars" element={<MobileArs />}>
                  <Route index element={<Navigate to="arsTree" />} />
                  <Route path="arsTree" element={<MobileArsTree />} />
                  <Route path="arsScenario" element={<MobileArsScenario />} />
                </Route>

                {/* 더보기(마이페이지) */}
                <Route path="etc" element={<MobileEtc />}>
                  <Route index element={<Navigate to="etcList" />} />
                  <Route path="etcList" element={<MobileEtcList />} />
                  <Route path="etcCharge" element={<MobileEtcCharge />} />
                  <Route path="etc015SubCharge" element={<MobileEtc015SubCharge />} />
                  {/* 더보기 - 회원정보 */}
                  <Route path="etcUserInfo" element={<MobileEtcUserInfo />} />
                  <Route path="etcUserInfo/infoChange" element={<MobileEtcUserInfoChange />} />
                  <Route path="etcUserInfo/numberChange" element={<MobileEtcPhoneNumberChange />} />
                  <Route path="etcUserInfo/subInfo" element={<MobileEtcSubInfo />} />
                  <Route path="etcUserInfo/userChange" element={<MobileEtcUserNameChange />} />
                  <Route path="etcUserInfo/memberLeave" element={<MobileEtcMemberLeave />} />

                  <Route path="etcPwChange" element={<MobileEtcPwChange />} />
                  <Route path="etcCallbackNumber" element={<MobileEtcCallbackNumber />} />
                  <Route path="etcPayment" element={<MobileEtcPayment />} />
                  <Route path="etcUsage" element={<MobileEtcUsage />} />
                  <Route path="etcMessageResult" element={<MobileEtcMessageResult />} />
                  <Route path="etcTaxInvoiceAuto" element={<MobileEtcTaxInvoiceAuto />} />
                  <Route path="etcRegisterTaxInvoice" element={<MobileEtcRegisterTaxInvoice />} />
                  <Route path="etcTaxCashReceiptsDetail" element={<MobileEtcTaxCashReceiptsDetail />} />
                  <Route path="etcTaxInvoiceDetail" element={<MobileEtcTaxInvoiceDetail />} />
                  {/* 고객센터 */}
                  <Route path="etcNotice" element={<MobileEtcNotice />} />
                  <Route path="etcQA" element={<MobileEtcQA />} />
                  <Route path="etcVOC" element={<MobileEtcVOC />} />
                  <Route path="etcEvent" element={<MobileEtcEvent />} />
                </Route>
                {/* 여기 까지 Route privateRoute */}
              </Route>
            </Route>

            {/* 기타페이지 */}
            <Route path="EasyComponent" element={<EasyComponent />} />
            <Route path="AuthComponent" element={<AuthComponent />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default MobileRoutes;
