import styled from 'styled-components';

export const AdPopupContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  background: #222;

  /* background: url(img/adNotice/adNotice_notice.png) no-repeat;
  background-size: contain; */
  img {
    object-fit: cover;
    width: 1200px;
    height: 675px;
  }
  .link {
    cursor: pointer;
    position: absolute;
    top: 48rem;
    left: 49.1rem;
    border-radius: 222px;
    width: 22rem;
    height: 8rem;
    animation: 1s bor infinite ease-in-out;
  }
`;
