import styled from 'styled-components';

// CommonListStyle 공통 리스트 스타일

// 문자 전송 스타일 010
export const MessageList = styled.div<any>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* padding: 0 0.6rem;
  margin: 0 -0.6rem; */

  /* groups */
  > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 1.4rem;
    margin-bottom: 1.2rem;
    border: 1px solid transparent;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0.2rem 1.1rem 0px rgba(0, 0, 0, 0.08);
    :hover {
      border-color: ${(props) => props.theme.colors.gray100};
    }
    &.active {
      background: ${(props) => props.theme.colors.gray100};
      border-color: ${(props) => props.theme.colors.gray200};
      .titleGroup {
        .peopleCount {
          background: transparent;
        }
      }
    }

    /* titleGroup */
    > div {
      position: relative;
      width: 100%;
      max-width: 100%;

      .reservationIcon {
        position: absolute;
      }

      /* topGroup */
      > div {
        display: flex;
        margin-bottom: 3.6rem;
        align-items: center;
        justify-content: space-between;
        .title {
          display: flex;
          max-width: 32rem;
          width: calc(100% - 10rem);
          color: ${(props) => props.theme.colors.txt500};
          font-size: 1.5rem;
          font-weight: 600;
          .messageTitle {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            font-size: 1.7rem;
          }
        }
        .peopleNumber {
          font-size: 1.6rem;
          color: ${(props) => props.theme.colors.txt300};
        }
        /* peopleCount */
        .peopleCount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 5.8rem;
          padding: 0.5rem 0.6rem;
          border-radius: 2rem;
          color: ${(props) => props.theme.colors.txt200};
          font-size: 1.4rem;
        }
      }
      .dateGroup {
        display: flex;
        font-size: 1.2rem;
        color: ${(props) => props.theme.colors.txt200};
        gap: 1.6rem;
        font-weight: 500;
      }
    }
    .iconGroup {
      margin-right: 0.6rem;
      min-width: 1.7rem;
    }
    /* 세부내용 */
    .textContent {
      display: flex;
      font-size: 1.6rem;
      color: ${(props) => props.theme.colors.txt200};
      line-height: 2rem;
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .reservation {
    svg {
      path {
        fill: ${(props) => props.theme.colors.main};
      }
    }
    &:has(.titleGroup .reservationIcon) .title {
      padding-left: 2.4rem;
    }
  }
  @media screen and (max-width: 375px) {
    .textContent {
      line-height: normal;
    }
  }
`;

// 문자 예약하기 스타일
export const Reservation = styled.div`
  /* groups */
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid transparent;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0.2rem 1.1rem 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 1.2rem;
    padding: 1.4rem;
    :hover {
      border-color: ${(props) => props.theme.colors.gray100};
    }
    &.active {
      background: ${(props) => props.theme.colors.gray200};
      border-color: ${(props) => props.theme.colors.red};
      .titleGroup {
        .countGroup {
          .peopleCount {
            background: transparent;
          }
        }
      }
    }
    .titleGroup {
      width: 100%;
      cursor: pointer;
      .topGroup {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        .title {
          display: flex;
          align-items: center;
          font-size: 1.5rem;
          font-weight: 600;
          max-width: 20rem;
          width: calc(100% - 10rem);
          .messageTitle {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .countGroup {
        display: flex;
        align-items: center;
        .peopleCount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 58px;
          min-height: 22px;
          padding: 0 6px;
          border-radius: 20px;
          background: #f6f6fb;
          color: #191919;
          font-size: 11px;
          p {
            margin-left: 1.2rem;
          }
        }
      }
      /* 예약시간 */
      .dateGroup {
        display: flex;
        font-size: 1rem;
        color: ${(props) => props.theme.colors.txt200};
        gap: 1.6rem;
        font-weight: 500;
        margin-bottom: 2.6rem;
      }
    }
    .iconGroup {
      margin-right: 6px;
      min-width: 17px;
    }
    .textContent {
      display: flex;
      font-size: 1.2rem;
      color: #98999a;
      line-height: 2rem;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        line-break: anywhere;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .checkButtonGroup.active {
      button {
        background: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain 100%;
        border: 2px solid ${(props) => props.theme.colors.main};
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9' viewBox='0 0 10 9' fill='none'%3E%3Cpath d='M8.48 1L3.244 7.35L1 4.81' stroke='%230D42E5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
      }
    }
  }
  @media screen and (max-width: 375px) {
    .textContent {
      line-height: normal;
    }
  }
`;

// 015 채팅방 스타일
export const ChatList = styled.div`
  > div {
    cursor: pointer;
    position: relative;
    padding: 1.4rem;
    margin-bottom: 1.2rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray100};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
    :hover {
      border-color: ${(props) => props.theme.colors.gray100};
    }
    &.active {
      background: ${(props) => props.theme.colors.gray100};
      border-color: ${(props) => props.theme.colors.gray200};
    }
    .imageIcon {
      color: ${(props) => props.theme.colors.main};
    }

    /* 수신인 */
    .titleGroup {
      margin-bottom: 3rem;
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.6rem;
        h3 {
          display: flex;
          align-items: center;
          gap: 0.4rem;
          font-size: 1.7rem;
          color: ${(props) => props.theme.colors.txt500};
          font-weight: 600;
          max-width: calc(100% - 6rem);
          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .iconWrap {
          svg {
            max-width: 1.8rem;
          }
        }
        p {
          font-size: 1.2rem;
          color: ${(props) => props.theme.colors.txt200};
        }
      }
    }
    .peopleNumber {
      font-size: 1.3rem;
      color: ${(props) => props.theme.colors.txt300};
    }
    /* 내용 */
    .textContent {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 1.6rem;
      line-height: 2rem;
      color: ${(props) => props.theme.colors.txt200};
      .iconGroup {
        margin-right: 0.5rem;
      }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        /* line-break: anywhere;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
      }
    }
  }

  /* 알림 */
  .receive {
    position: absolute;
    bottom: 12px;
    right: 10px;
    p {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.notify};
      border-radius: 100px;
      width: 25px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

// 채팅 팝업
export const ChatPop = styled.div`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray600};
  border-radius: 5px;
  overflow: hidden;
  padding: 5px 0;
  ul {
    ::after {
      content: '';
      display: block;
      width: 90%;
      margin: 5px auto;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
    }
    :last-of-type {
      ::after {
        display: none;
      }
    }
  }
  button {
    text-align: left;
    padding: 8px 10px;
    min-width: 120px;
    :hover {
      background: ${(props) => props.theme.colors.gray100};
    }
  }
`;

export const MessageTitle = styled.h3<any>`
  color: ${(props) => props.theme.colors.txt500};

  &.noTitle {
    color: ${(props) => `${props.theme.colors.txt500}99`};
  }
`;
