import { atom } from 'recoil';
import { Dayjs } from 'dayjs';

// 전송 결과 상세페이지 API 요청에 필요한 Reco
export const resultDetailParamsRecoil = atom({
  key: 'resultDetailParamsRecoil',
  default: { prepayPayNo: '', callback: '' },
});

// 예약전송 관리 상세페이지 API 요청에 필요한 Recoil
export const scheduleDetailParamsRecoil = atom({
  key: 'scheduleDetailParamsRecoil',
  default: { prepayPayNo: '', callback: '' },
});

// 전송 결과 소프트폰 리스트 표출 데이터 Recoil
export const transTableDataRecoil = atom<any>({
  key: 'transTableDataRecoil',
  default: [{}],
});

// 예약 전송 소프트폰 리스트 표출 데이터 Recoil
export const scheduleTableDataRecoil = atom<any>({
  key: 'scheduleTableDataRecoil',
  default: [{}],
});

export type SearchData = {
  pageNumber: number;
  pageSize: number;
  searchStartDate: Dayjs | null;
  searchEndDate: Dayjs | null;
  keyword: string | null;
};

// 전송 결과 queryKey Recoil
export const transQueryKeyRecoil = atom<SearchData>({
  key: 'transQueryKeyRecoil',
  default: {
    pageNumber: 0,
    pageSize: 20,
    searchStartDate: null,
    searchEndDate: null,
    keyword: null,
  },
});

export type ScheduleSearchData = {
  pageNumber: number;
  pageSize: number;
  searchCondition: string | null;
  keyword: string | null;
};

// 예약 전송 queryKey Recoil
export const scheduleQueryKeyRecoil = atom<ScheduleSearchData>({
  key: 'scheduleQueryKeyRecoil',
  default: { pageNumber: 0, pageSize: 10, searchCondition: null, keyword: null },
});
