import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ISttRequest, IUseStt } from './types/Hooks.types';
import { use015ServiceStatus } from './use015ServiceStatus';
import { useCashCalculate } from './useCashCalculate';
import { useSttModal } from './useSttModal';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { mhQueryParamsRecoil, rcvMsgCacheToggleRecoil } from '@/recoil/atoms/chatRoom';
import { getSttData } from '@/apis/api/say015Apis';
import { WORKS015_STT } from '@/shared/constants';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

export const useStt = ({ filename, duration }: IUseStt) => {
  const queryClient = useQueryClient();

  // stt 건당 가격 저장하는 변수
  const sttPrice = parseInt(WORKS015_STT as string, 10);

  const [sttState, setSttState] = useState<ISttRequest>({ sttRequest: false, sttResponse: null, sttPgResult: null });
  /* msgHistory 캐싱 recoil */
  const [mhQueryParamsS, setMhQueryParamsS] = useRecoilState(mhQueryParamsRecoil);
  /* stt 변환 후 스크롤 고정시키는 recoil */
  const [, setRcvMsgCacheToggle] = useRecoilState(rcvMsgCacheToggleRecoil);

  // TODO - 충전 : 앱 충전로직 끝나기 전까지 막을 기능
  const environmentS = useRecoilValue(environmentRecoil);

  const { confirmModal } = useSttModal();
  const { successModal, warningModal } = useModalHook();
  const calculateHooks = useCashCalculate();
  const statusHooks = use015ServiceStatus();

  /* stt 변환 get 요청 API */
  const { isLoading: sttDataIsLoading } = useQuery(
    ['/vms/convert-stt'],
    () =>
      getSttData({
        filename,
        sttSeconds: duration,
      }),
    {
      enabled: sttState.sttRequest,
      onSuccess: (sttText) => {
        if (!sttState.sttPgResult) return;
        // 포인트 값 업데이트
        queryClient.refetchQueries(['/userPoint']);
        // stt msg 담아놓는 변환 data state 초기화
        setSttState((prev) => {
          return { ...prev, sttRequest: false, sttResponse: null, sttPgResult: null };
        });
        // 해당 채팅룸 아이디 캐싱 업데이트
        const sttMessageId = sttState.sttResponse;
        if (sttMessageId) {
          updateCacheMsgList(sttText.sndMsg, sttMessageId);
        }
        if (!sttPrice && sttPrice !== 0) return;
        // stt 무료시간 캐싱 업데이트
        const statusUpdate =
          sttPrice * duration >= statusHooks.statusData.sttTime
            ? 0
            : statusHooks.statusData.sttTime - sttPrice * duration;
        if (statusUpdate >= 0) {
          statusHooks.updateCache015Status(statusUpdate);
        }
      },
      onError: () => {
        setSttState((prev) => {
          return { ...prev, sttRequest: false, sttResponse: null, sttPgResult: null };
        });
      },
    },
  );
  // ==============================
  // onClick stt 버튼 hook
  // ==============================
  const onClickSttBtn = (list: any) => {
    // 여기서 포인트 여부에 따라 분기처리 후 해당 함수 실행
    const confirmStt = calculateHooks.handleSttCalculate(duration, statusHooks.statusData.sttTime);
    if (confirmStt.type === 'freeE') {
      confirmModal(
        async () => handleIsOk(list, confirmStt.pgResult),
        '서비스 안내',
        1,
        { duration, sttTime: statusHooks.statusData.sttTime },
        true,
      );
    }
    if (confirmStt.type === 'cashE') {
      confirmModal(
        async () => handleIsOk(list, confirmStt.pgResult),
        '서비스 안내',
        2,
        { duration, sttTime: statusHooks.statusData.sttTime },
        true,
      );
    }
    // if (confirmStt.type === 'cashE') {
    //   confirmModal(
    //     async () => handleIsOk(list, confirmStt.pgResult),
    //     '서비스 안내',
    //     `녹음된 내용을 텍스트로 변환해드리는 서비스입니다.</br>매 월 5분의 무료 변환시간을 제공하며, 무료 변환시간(${
    //       statusHooks.statusData.sttTime
    //     }초) 소진 시 초당 1원의 요금이 발생합니다.</br>${300 - statusHooks.statusData.sttTime}/300`,
    //     true,
    //   );
    // }
    if (confirmStt.type === 'charge') {
      // TODO - 충전소 : 앱 충전소 기능 끝나면 변경
      const naviUrlS = environmentS === 'app' ? '/mobile/etc/etcCharge' : '/charge';
      confirmModal(
        async () => {},
        '캐시 부족',
        3,
        { duration, sttTime: statusHooks.statusData.sttTime },
        true,
        naviUrlS,
      );
    }
    if (confirmStt.type === 'error') {
      warningModal('오류', '정보를 확인할 수 없습니다.</br>새로고침 또는 재로그인 후 이용해 주세요', true);
    }
  };

  // ==============================
  // onClick stt confirmModal isOk hook
  // ==============================
  const handleIsOk = (list: any, pgResult: any) => {
    // if (typeof list.sndMsg === 'string' && pgResult) {
    if (list.sndMsg === '' && pgResult) {
      setRcvMsgCacheToggle(true);
      setSttState((prev) => {
        return { ...prev, sttRequest: true, sttResponse: list.messageId, sttPgResult: pgResult };
      });
    } else {
      successModal('서비스 이용', '텍스트로 변환된 파일입니다.', true);
    }
  };

  // ==============================
  // stt 변환 성공시 캐시업데이트 hook
  // ==============================
  const updateCacheMsgList = (sttText: string | null, sttMessageId: number) => {
    const prevData: any = queryClient.getQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber]);
    if (prevData) {
      const newPages = prevData.pages.map((page: any) => {
        return {
          ...page,
          content: page.content.map((item: any) => {
            if (item.messageId === sttMessageId) {
              return {
                ...item,
                // 여기에 텍스트 받아온거 넣으면 됍니다.
                sndMsg: sttText,
              };
            }
            return item;
          }),
        };
      });
      const newData = { ...prevData, pages: newPages };
      queryClient.setQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber], newData);
    }
    setSttState((prev) => {
      return { ...prev, sttRequest: false, sttResponse: null, sttPgResult: null };
    });
  };

  return { onClickSttBtn, sttDataIsLoading, sttState };
};
