import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconChatHuman: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2.4rem'}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M17.8009 7.03144C17.8541 7.08438 17.8839 7.15618 17.8839 7.23105V8.64305H19.3006C19.3757 8.64305 19.4478 8.67279 19.5009 8.72573C19.5541 8.77867 19.5839 8.85047 19.5839 8.92534C19.5839 9.0002 19.5541 9.072 19.5009 9.12494C19.4478 9.17788 19.3757 9.20762 19.3006 9.20762H17.8839V10.6191C17.8839 10.6939 17.8541 10.7657 17.8009 10.8187C17.7478 10.8716 17.6757 10.9013 17.6006 10.9013C17.5255 10.9013 17.4534 10.8716 17.4003 10.8187C17.3471 10.7657 17.3173 10.6939 17.3173 10.6191V9.20762H15.9006C15.8255 9.20762 15.7534 9.17788 15.7003 9.12494C15.6471 9.072 15.6173 9.0002 15.6173 8.92534C15.6173 8.85047 15.6471 8.77867 15.7003 8.72573C15.7534 8.67279 15.8255 8.64305 15.9006 8.64305H17.3173V7.23105C17.3173 7.15618 17.3471 7.08438 17.4003 7.03144C17.4534 6.9785 17.5255 6.94876 17.6006 6.94876C17.6757 6.94876 17.7478 6.9785 17.8009 7.03144Z"
        fill={fill ?? 'currentColor'}
        stroke={stroke ?? 'currentColor'}
        strokeWidth="0.4"
      />
      <path
        d="M12.1447 8.58792C12.7679 8.58792 13.3656 8.83549 13.8062 9.27616C14.2469 9.71684 14.4945 10.3145 14.4945 10.9377C14.4945 11.5609 14.2469 12.1586 13.8062 12.5993C13.3656 13.04 12.7679 13.2875 12.1447 13.2875C11.5215 13.2875 10.9238 13.04 10.4831 12.5993C10.0424 12.1586 9.79486 11.5609 9.79486 10.9377C9.79486 10.3145 10.0424 9.71684 10.4831 9.27616C10.9238 8.83549 11.5215 8.58792 12.1447 8.58792ZM12.1447 15.2854C14.7412 15.2854 16.8443 16.3369 16.8443 17.6352V18.8101H7.44505V17.6352C7.44505 16.3369 9.54813 15.2854 12.1447 15.2854Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M18.2359 2.15855C16.605 1.36755 14.8161 0.956095 13.0034 0.955048C9.8199 0.955048 6.76679 2.21932 4.51571 4.46975C2.26464 6.72017 1 9.7724 1 12.955L1.0105 13.474L1.04801 14.047C1.18589 15.5561 1.60845 17.0255 2.29337 18.3774L2.38789 18.5574L1.02251 24.0234L1.0015 24.1479V24.2709C1.01103 24.379 1.0439 24.4837 1.09785 24.5779C1.1518 24.672 1.22554 24.7533 1.31398 24.8162C1.40242 24.8791 1.50345 24.9221 1.61011 24.9421C1.71677 24.9622 1.82651 24.9589 1.93176 24.9324L7.40081 23.5674L7.58236 23.6649C9.20018 24.4823 10.9822 24.9227 12.7946 24.9532C14.607 24.9837 16.4028 24.6034 18.0472 23.8409C19.6916 23.0784 21.1419 21.9534 22.2893 20.5505C23.4366 19.1476 24.2512 17.5031 24.672 15.7404C25.0928 13.9778 25.1087 12.1427 24.7187 10.373C24.3287 8.6033 23.5428 6.94488 22.42 5.5222C21.2972 4.09952 19.8667 2.94954 18.2359 2.15855Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="1.4"
      />
    </Svg>
  );
};

export default IconChatHuman;
