import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post015Resub } from '@/apis/api/say015Apis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { SERVICE_NAME } from '@/shared/constants';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useRecoilValue } from 'recoil';

export const usePOBox015Resub = ({ authorityDataRefetch }: any) => {
  const [postResub015State, setPostResub015State] = useState<boolean>(false); // 버튼 중복 클릭 방지
  // TODO - 충전 : 앱 충전로직 끝나기 전까지 막을 기능
  const environmentS = useRecoilValue(environmentRecoil);
  /* 사용자 포인트 selector 초기화 */
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { confirmModal, warningModal } = useModalHook();

  const { mutate: resub015Mutate } = useMutation(post015Resub, {
    onSuccess: () => {
      authorityDataRefetch();
      setPostResub015State((prev) => prev && false);
      queryClient.refetchQueries(['/userPoint']);
    },
    onError: (error: any) => {
      setPostResub015State((prev) => prev && false);
      if (error.response.data === '충전이 필요합니다.') {
        // TODO - 충전소 : 앱 충전소 기능 끝나면 변경
        const naviUrlS = environmentS === 'app' ? '/mobile/etc/etcCharge' : '/charge';
        confirmModal(async () => navigate(naviUrlS), '포인트 부족', '충전소로 이동하시겠습니까?', true);
      } else {
        warningModal('구독 실패', error.response.data, true);
      }
    },
  });

  const resub015OnClickHandle = (authorityDataState: any) => {
    setPostResub015State((prev) => !prev && true);
    // confirmModal(
    //   async () => resub015Mutate(),
    //   'My015 구독',
    //   `${authorityDataState.monthlyPrice}포인트 · 캐쉬를 사용합니다.`,
    //   true,
    //   undefined,
    //   async () => setPostResub015State((prev) => prev && false),
    // );
    confirmModal(
      async () => (environmentS === 'app' ? navigate('/mobile/015SubCharge') : navigate('/my015charge')),
      `${SERVICE_NAME} 통신료 결제하기`,
      `${SERVICE_NAME} 서비스 통신료 결제를 위해 결제 페이지로 이동합니다.`,
      true,
      undefined,
      async () => setPostResub015State((prev) => prev && false),
    );
  };

  const completeHandle = (authorityDataState: any) => {
    resub015OnClickHandle(authorityDataState);
  };

  return { completeHandle, postResub015State };
};
