import MobileMoveSendMessage from '@/pages/main/components/addressLeftZone/MobileMoveSendMessage';
import { detailBuddyDataRecoil } from '@/recoil/atoms/addressList';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { editModeRecoil, mobileNavRecoil, windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { msg015ChatRoomToggleRecoil } from '@/recoil/atoms/say015Atom';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import PageNav from './PageNav';

const PrivateRoute = () => {
  const [authenticated, setAuthenticated] = useState(Boolean(localStorage.getItem('authorizationToken')));
  const [windowWidth, setWindowWidth] = useRecoilState(windowWidthRecoil);

  const [environmentS, setEnvironmentS] = useRecoilState(environmentRecoil);

  /* 015 채팅방 표출 toggle */
  const msg015ChatRoomToggleR = useRecoilValue(msg015ChatRoomToggleRecoil);

  const [editMode, setEditMode] = useRecoilState(editModeRecoil);

  useEffect(() => {
    const tokenChangeHandler = () => {
      setAuthenticated(Boolean(localStorage.getItem('authorizationToken')));
    };
    // 이벤트 리스너 등록
    window.addEventListener('storage', tokenChangeHandler);
    // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
    return () => {
      window.removeEventListener('storage', tokenChangeHandler);
    };
  }, []);

  const mainTableCheckDataFormatS = useRecoilValue(mainTableCheckDataFormatRecoil);

  const mobileNavState = useRecoilValue(mobileNavRecoil);
  const detailBuddyDataS = useRecoilValue(detailBuddyDataRecoil);

  return authenticated ? (
    <div>
      <Outlet />
      {mainTableCheckDataFormatS &&
        mobileNavState === '주소록' &&
        !editMode &&
        detailBuddyDataS === null &&
        mainTableCheckDataFormatS.reduce((accumulator, item) => accumulator + item.count, 0) > 0 && (
          <MobileMoveSendMessage />
        )}
      {environmentS !== 'app' && windowWidth <= 768 && !msg015ChatRoomToggleR && <PageNav type="private" />}
    </div>
  ) : (
    <Navigate to="/signin" />
  );
};
export default PrivateRoute;
