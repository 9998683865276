import { useRecoilState } from 'recoil';
import { checkChildrenArrR, checkGroupArrR, unCheckChildrenArrR } from '@/recoil/atoms/messageSend/messageSend';
import { checkGroupListState } from '@/recoil/atoms/addressList';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';

/**
 * @title 메인 페이지에 관한 recoil 을 초기화 하는 함수를 모아둔 hook
 *
 * @author 정휘학
 * */
export const useMainTableReset = () => {
  const [, setCheckGroupArrS] = useRecoilState(checkGroupArrR);
  const [, setCheckChildrenArrS] = useRecoilState(checkChildrenArrR);
  const [, setUnCheckChildrenArrS] = useRecoilState(unCheckChildrenArrR);
  const [, setCheckGroupListState] = useRecoilState(checkGroupListState);
  /* 체크 상태 데이터를 가공해서 삭제 및 표출에 사용되는 recoil */
  const [mainTableCheckDataFormatS, setMainTableCheckDataFormatS] = useRecoilState(mainTableCheckDataFormatRecoil);

  // ======================================
  // 주소록 및 좌측 체크박스에 관한 recoil 리셋 함수
  // ======================================
  const mainTableResetRecoil = () => {
    setCheckGroupArrS((prevState) => {
      if (prevState === null) return prevState;
      return prevState.map((item) => {
        return {
          ...item,
          isChecked: false,
          isChildrenChecked: false,
        };
      });
    });
    setCheckChildrenArrS(null);
    setUnCheckChildrenArrS(null);
    setMainTableCheckDataFormatS(null);
    setCheckGroupListState([]);
  };

  // ==============================================
  // 주소록 및 좌측 체크박스에 관한 recoil 을 매개변수로 들어온
  // groupSeqNo 배열 데이터만 리셋하는 함수
  // ==============================================
  const mainTableResetToGroupSeqNo = (groupSeqNos: number[]) => {
    setCheckGroupArrS((prevState) => {
      if (prevState === null) return prevState;
      return prevState.map((item) => {
        if (groupSeqNos.includes(item.groupSeqNo)) {
          return {
            ...item,
            isChecked: false,
            isChildrenChecked: false,
          };
        }
        return item;
      });
    });
    setCheckChildrenArrS((prevState) => {
      if (!prevState) return prevState;
      return prevState.filter((item) => !groupSeqNos.includes(item.groupSeqNo));
    });
    setUnCheckChildrenArrS((prevState) => {
      if (!prevState) return prevState;
      return prevState.filter((item) => !groupSeqNos.includes(item.groupSeqNo));
    });
    setMainTableCheckDataFormatS((prevState) => {
      if (!prevState) return prevState;

      return prevState.map((item) => {
        if (groupSeqNos.includes(item.groupSeqNo)) {
          return {
            ...item,
            groupCheck: false,
            buddySeqNo: null,
            api: false,
            count: 0,
          };
        }
        return item;
      });
    });
    setCheckGroupListState((prevState) => prevState.filter((item) => !groupSeqNos.includes(item)));
  };

  return {
    mainTableResetToGroupSeqNo,
    mainTableResetRecoil,
  };
};
