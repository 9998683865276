import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { copySelectToggleRecoil, dupliModalToggleRecoil } from '@/recoil/atoms/addressList';

/* 주소록 셀렉트 종류 타입 */
export interface IBtnSelectList {
  selectGroup: boolean;
  selectAddress: boolean;
  selectEtc: boolean;
  selectSearch: boolean;
}

/* 주소록 셀렉트 종류와 default value */
const btnSelectList: IBtnSelectList = {
  selectGroup: false,
  selectAddress: false,
  selectEtc: false,
  selectSearch: false,
};

/**
 * @title 주소록관련 셀렉트 버튼 토글을 관리하는 Hooks
 *
 * @author 김남규
 * @since 2024-04-26
 */
export const useAddressSelect = () => {
  /* 주소록 셀렉트 field toggle STATE */
  const [btnSelectToggle, setBtnSelectToggle] = useState<IBtnSelectList>(btnSelectList);
  /* 주소록 복사 select toggle STATE */
  const [toggleCopySelect, setToggleCopySelect] = useRecoilState<boolean>(copySelectToggleRecoil);
  /* 중복정리 모달 STATE */
  const [isModalActive, setIsModalActive] = useRecoilState<boolean>(dupliModalToggleRecoil);

  // ==============================
  // 특정 셀렉트 상태 toggle과 나머지 toggle off hook
  // ==============================
  const handleSelectBtn = (item: keyof IBtnSelectList) => {
    setBtnSelectToggle((prevState) => {
      // updatedState는 IBtnSelectList타입을 확신한다는 문법
      const updatedState: IBtnSelectList = {} as IBtnSelectList;

      // 선택한 item만 toggle하고 나머지는 false로 설정
      Object.keys(prevState).forEach((key) => {
        updatedState[key as keyof IBtnSelectList] = key === item ? !prevState[key] : false;
      });

      return updatedState;
    });
    // selectAddress가 false이면 toggleCopySelect도 false로 설정
    if (item === 'selectAddress' && !btnSelectToggle.selectAddress) {
      setToggleCopySelect(false);
    }
  };

  const handleSelectAlloff = () => {
    setBtnSelectToggle({
      selectGroup: false,
      selectAddress: false,
      selectEtc: false,
      selectSearch: false,
    });
  };

  // ==============================
  // 주소록 셀렉트 toggle 상태일 때 바깥 클릭시 select off hook
  // ==============================
  useEffect(() => {
    // 셀렉트 내부에서 동작 안할 엘리먼트 id 배열 추가하고 사용
    const excludedIds = ['copySelect', 'copyOption'];

    const isDescendantOf = (element: HTMLElement | null, parentId: string): boolean => {
      let currentElement = element;
      while (currentElement) {
        if (currentElement.id === parentId) return true;
        currentElement = currentElement.parentElement;
      }
      return false;
    };

    const handleOutsideClick = (event: MouseEvent) => {
      // btnSelectToggle의 값 중 하나라도 true이면 클릭 처리
      if (Object.values(btnSelectToggle).some((value) => value)) {
        const buttonIds = ['selectGroup', 'selectAddress', 'selectEtc', 'selectSearch'];
        const clickedInsideComponent = buttonIds.some((id) => {
          const target = event.target as HTMLElement;
          return target && target.id === id;
        });

        // 클릭된 요소의 id가 excludedIds에 포함되어 있는지 확인
        const clickedExcludedElement = excludedIds.some((id) => {
          const target = event.target as HTMLElement;
          return target && target.id === id;
        });

        const isClickedInsideCopyOption = isDescendantOf(event.target as HTMLElement, 'copyOption');

        if (!clickedInsideComponent && !isClickedInsideCopyOption && !clickedExcludedElement) {
          // 모든 값을 false로 변경
          handleSelectAlloff();
        }
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [btnSelectToggle]);

  return { btnSelectToggle, handleSelectBtn, toggleCopySelect, setToggleCopySelect, setIsModalActive, isModalActive };
};
