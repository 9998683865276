import React from 'react';
import * as S from '../MobileHeader.style';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  addModalOpenRecoil,
  addModalTitleRecoil,
  mobileBuddySeqNoListAddRecoil,
  mobileGroupSeqNoListAddRecoil,
} from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useNavigate } from 'react-router-dom';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import MobileLoader from '../../MobileLoader';
import { useCreateMultiChat } from '../../mobileHooks/useCreateMultiChat';

const MultiMessageAddHeader = () => {
  const navigate = useNavigate();
  //모바일 대량 전송을 위한 버디 리스트 리코일
  const mobileBuddySeqNoListAddS = useRecoilValue(mobileBuddySeqNoListAddRecoil);
  //모바일 대량 전송을 위한 그룹 리스트 리코일
  const mobileGroupSeqNoListAddS = useRecoilValue(mobileGroupSeqNoListAddRecoil);

  // 리스트 선택 후 방의 이름 입력
  const [addModalOpenS, setAddModalOpenS] = useRecoilState(addModalOpenRecoil);
  const [addModalTitleS, setAddModalTitleS] = useRecoilState(addModalTitleRecoil);

  // 두 리스트 중 하나라도 값이 들어 있는지 확인
  const isActive = mobileBuddySeqNoListAddS.length > 0 || mobileGroupSeqNoListAddS.length > 0;

  // 폴더를 선택했을 때 폴더안에 있는 리스트 카운트
  const totalBuddyCount = mobileGroupSeqNoListAddS.reduce((sum, item) => {
    return sum + item.buddyCount;
  }, 0);

  // 훅

  const onClickCreateMultiMessage = async () => {
    setAddModalOpenS(true);
    setAddModalTitleS('단체 전송방');
    window.location.hash = '#add';
  };

  return (
    <>
      <S.IconContainer>
        <S.HeaderButtonFont onClick={() => onClickCreateMultiMessage()} className={`${isActive && 'active'}`}>
          {isActive && `(${totalBuddyCount + mobileBuddySeqNoListAddS.length}) `}
          확인
        </S.HeaderButtonFont>
      </S.IconContainer>
    </>
  );
};

export default MultiMessageAddHeader;
