import styled from 'styled-components';

/* SendResult.tsx */

/* 왼쪽 구역 */
export const SendResultLeftZone = styled.div`
  position: relative;
  box-sizing: border-box;
  /* Logo */
  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    /* text-indent: 1rem; */
    color: ${(props) => props.theme.colors.txt500};
    /* @media screen and (max-width: 768px) {
      font-size: 1.6rem;
      span {
        font-size: 1.2em;
      }
    }
    @media screen and (max-width: 460px) {
      font-size: 1.2rem;
    } */
  }
  /* 전송결과 상세페이지 텍스트 */
  /* h2 {
    text-indent: 2rem;
    font-size: 2rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.txt500};
  } */
`;
/* 전송결과 상세내용 구역 Wrap */
export const SendResultLeftDataView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
  > div {
    position: relative;
    width: 70%;
    @media screen and (max-width: 768px) {
      width: 60%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .messageContent {
    position: relative;
    display: flex;
    gap: 3rem;
    padding: 1.4rem;
    height: 28rem;
    line-height: 3rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.txt500};
    border-radius: 20px;
    background-color: ${(props) => props.theme.colors.gray100};
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    img {
      width: 47%;
      object-fit: contain;
    }
    p {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 1rem;
      width: 100%;
      max-height: calc(100% - 17px);
      box-sizing: border-box;
      overflow-y: auto;
      word-break: break-all;
    }
    /* 메시지 데이터 출력 */
    .byteWrap {
      position: absolute;
      right: 15px;
      bottom: 10px;
      line-height: 1;
      font-size: 1.3rem;
      font-weight: 700;
      color: ${(props) => props.theme.colors.main};
      text-align: right;
    }
  }

  /* 전송 데이터 출력  */
  dl {
    width: 30%;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    @media screen and (max-width: 768px) {
      width: 40%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
    div {
      display: flex;
      align-items: center;
      height: 4rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
      box-sizing: border-box;
      &:last-of-type {
        border: none;
      }
      dt {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36.75%;
        height: 100%;
        font-size: 1.4rem;
        font-weight: 600;
        background-color: ${(props) => props.theme.colors.gray100};
        color: ${(props) => props.theme.colors.txt500};
      }
      dd {
        display: flex;
        align-items: center;
        padding-left: 2rem;
        width: 63.25%;
        height: 100%;
        font-size: 1.3rem;
        font-weight: 500;
        background-color: ${(props) => props.theme.colors.white};
        @media screen and (max-width: 768px) {
          padding-left: 1rem;
        }
      }
      @media screen and (max-width: 1600px) {
        dt,
        dd {
          font-size: 1.2rem;
        }
      }
      @media screen and (max-width: 768px) {
        dt,
        dd {
          font-size: 1.1rem;
        }
      }
    }
  }
`;

/* 전송결과 테이블 Wrap */
export const SendResultTable = styled.div`
  margin-bottom: 50px;
  form {
    /* 연락처, 검색, 이름 / Select, input Wrap */
    .headFunWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.4rem;
      margin: 4.4rem 0 1rem 0;
      .borBtn {
        button {
          padding: 1rem 1.2rem;
          height: 3.6rem;
        }
      }
      div {
        /* Left / Select, input */
        &:nth-of-type(1) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.6rem;

          @media screen and (max-width: 768px) {
            justify-content: initial;
          }
        }
        /* Right / Select */
      }
    }
    /* 페이징, 장기보관함 / 재전송 Wrap */
    .footFunWrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 26rem;
      margin-top: 2rem;
      @media screen and (max-width: 768px) {
        display: block;
      }
      .sendResultPaging {
        justify-content: center;
      }
      @media screen and (max-width: 768px) {
        .sendResultPaging {
          justify-content: center;
        }
      }
      .reuseFunBtnWrap {
        position: absolute;
        right: 0;
        display: flex;
        gap: 0.7rem;
        @media screen and (max-width: 768px) {
          position: initial;
          justify-content: flex-end;
          gap: 6px;
          margin-top: 2.4rem;
        }
        @media screen and (max-width: 480px) {
          justify-content: center;
        }
        button {
          width: 11.2rem;
          @media screen and (max-width: 1024px) {
            width: 8rem;
          }
          @media screen and (max-width: 480px) {
            width: 6rem;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .sendListWrap {
    /* List 공통 ul css */
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.6rem;
      border-radius: 5px;
      background-color: ${(props) => props.theme.colors.white};
      box-shadow: 0.5rem 0.5rem 1rem 0px rgba(0, 0, 0, 0.1);
      &.sendHeadList {
        li {
          height: 37px;
          line-height: 37px;
          strong {
            font-size: 1.4rem;
            font-weight: 600;
            @media screen and (max-width: 768px) {
              font-size: 1rem;
            }
          }
        }
      }
      /* 공통 li css */
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        font-size: 1.3rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors.txt500};
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.w10 {
          width: 10%;
        }
        &.w20 {
          width: 20%;
        }
        &.w30 {
          width: 30%;
        }
        /* 수신인 표기 데이터 className */
        &.myDataText {
          gap: 4px;
          span {
            text-align: center;
            /* &:first-child {margin-right:5rem;} */
          }
        }
        /* List 날짜 표기 데이터 className */
        &.dateText {
          border-left: 1px solid ${(props) => props.theme.colors.gray100};
          border-right: 1px solid ${(props) => props.theme.colors.gray100};
        }
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
    /* Body List */
    .sendBodyListWrap {
      ul {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

/* 2024.02.02 18:10 기준 */
