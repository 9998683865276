/* eslint-disable */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { theme } from 'antd';

interface ISendMsgByte {
  file: any;
  sndMsg: string;
  phoneType: string;
  adMessage: boolean; // 광고 구분
  msgTypeS: 'SMS' | 'LMS' | 'MMS' | 'FILE';
  setMsgTypeS: React.Dispatch<React.SetStateAction<'SMS' | 'LMS' | 'MMS' | 'FILE'>>;
  byteS: number;
  setByteS: React.Dispatch<React.SetStateAction<number>>;
  styleType?: 'a' | 'b' | 'c';
}

type ByteCountType = {
  maxByte: boolean;
};

const ByteCount = styled.div<ByteCountType>`
  display: flex;
  font-size: 1.4rem;
  background: ${(props) => props.theme.colors.gray800}33;
  padding: 1rem;
  border-radius: 5rem;
  color: ${({ maxByte, theme }) => (maxByte ? theme.colors.txt100 : theme.colors.red)};
  font-weight: 400;
  .msg {
    margin-right: 0.5rem;
  }
  /* 줄바꿈 되는 스타일 */
  &.a {
    text-align: right;
    flex-direction: column;
    color: ${({ maxByte, theme }) => (maxByte ? theme.colors.txt400 : theme.colors.red)};
    background: transparent;
    .msg {
      margin-right: 0;
    }
  }
`;

const SendMsgByte = ({
  sndMsg,
  file,
  phoneType,
  adMessage,
  byteS,
  msgTypeS,
  setMsgTypeS,
  setByteS,
  styleType,
}: ISendMsgByte) => {
  useEffect(() => {
    const phoneTypeBoolean = phoneType === '015';
    /* 광고 + 파일 + lms */
    if (adMessage && (file || calMsgByte(sndMsg) > (phoneTypeBoolean ? 45 : 60))) {
      setByteS(phoneTypeBoolean ? 1956 : 1971);
      setMsgTypeS(() => (file ? 'MMS' : 'LMS'));
    } else if (adMessage && !file && calMsgByte(sndMsg) < (phoneTypeBoolean ? 46 : 61)) {
      setByteS(phoneTypeBoolean ? 45 : 60);
      setMsgTypeS('SMS');
    }

    /* 광고 x + 파일 + lms  */
    if (!adMessage && (file || calMsgByte(sndMsg) > (phoneTypeBoolean ? 75 : 90))) {
      setByteS(phoneTypeBoolean ? 1985 : 2000);
      setMsgTypeS(() => (file ? 'MMS' : 'LMS'));
    } else if (!adMessage && !file && calMsgByte(sndMsg) < (phoneTypeBoolean ? 76 : 91)) {
      setByteS(phoneTypeBoolean ? 75 : 90);
      setMsgTypeS('SMS');
    }
  }, [sndMsg, file, adMessage, phoneType]);

  return (
    <ByteCount className={`byteCount ${styleType}`} maxByte={calMsgByte(sndMsg) < byteS}>
      <p className="msg">{msgTypeS}</p>
      <p>
        <span className="num">
          {calMsgByte(sndMsg)} / {byteS}
        </span>{' '}
        <span className="byte">Byte</span>
      </p>
    </ByteCount>
  );
};

export default SendMsgByte;
