import { groupListEnabledRecoil, groupSearchTextRecoil, searchGroupOpenRecoil } from '@/recoil/atoms/addressBookGroup';
import { checkGroupListState, groupSeachTextToggleRecoil } from '@/recoil/atoms/addressList';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { checkGroupArrR } from '@/recoil/atoms/messageSend/messageSend';
import { useEffect, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

export const useSearchGroup = () => {
  /* 그룹 리스트 검색 컴포넌트 표출제어 recoil */
  const [searchGroupOpenS, setSearchGroupOpenS] = useRecoilState(searchGroupOpenRecoil);
  /* 그룹리스트 검색 keyword 받을 Recoil */
  const [groupSearchTextS, setGroupSearchTextS] = useRecoilState(groupSearchTextRecoil);
  // ? 그룹 리스트 체크 array
  const [checkGroupArrS, setCheckGroupArrS] = useRecoilState(checkGroupArrR);
  /* 주소록 메인 테이블 체크박스 상태 데이터를 가공해서 삭제 및 소프트폰 메세지 전송 수신인에 표출 등... 에 사용되는 데이터 가공 recoil */
  const [mainTableCheckDataFormatS, setMainTableCheckDataFormatS] = useRecoilState(mainTableCheckDataFormatRecoil);
  const [checkedGroupListS, setCheckedGroupListS] = useRecoilState(checkGroupListState);
  // 검색 후 검색 텍스트를 변경해서 제출하거나, 초기화 했을 때 체크박스 최신화를 위한 트리거
  const [groupSeachTextToggleS, setGroupSeachTextToggleS] = useRecoilState(groupSeachTextToggleRecoil);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchGroupOpenS && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchGroupOpenS]);

  // ==============================
  // onClick 그룹검색 닫기 버튼
  // ==============================
  const onClickSearchClose = () => {
    setSearchGroupOpenS(false);
    // setGroupSearchTextS('');
    // setGroupListEnabledS(true);
  };

  // ==============================
  // onChange 그룹검색 keyword 입력
  // ==============================
  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupSearchTextS(e.target.value);
  };

  // ==============================
  // onSubmit 그룹검색 keyword 제출
  // ==============================
  const onSubmitSearchText = (e: React.FormEvent) => {
    e.preventDefault();
    handleGroupCheckList();
    if (groupSearchTextS) {
      setGroupSeachTextToggleS(true);
    } else {
      setGroupSeachTextToggleS(false);
    }
  };

  // ==============================
  // onClick 그룹검색 초기화 버튼
  // ==============================
  const onClickResetSearchGroup = () => {
    setGroupSearchTextS('');
    handleGroupCheckList();
    setGroupSeachTextToggleS(false);
  };

  // ==============================
  // handle 그룹 체크 최신화 Hook
  // ==============================
  const handleGroupCheckList = () => {
    if (mainTableCheckDataFormatS && checkGroupArrS) {
      const groupCheckList = checkGroupArrS.filter((group) => {
        // mainTableCheckDataFormatS에서 groupSeqNo가 일치하는 요소를 찾음
        const matchingItem = mainTableCheckDataFormatS.find((item) => item.groupSeqNo === group.groupSeqNo);
        // count가 totalCount와 같고 isChecked가 true인 경우
        return matchingItem && matchingItem.count === group.totalChildren && matchingItem.groupCheck === true;
      });
      // groupSeqNo만 추출하여 새로운 배열에 저장
      const groupSeqNoList = groupCheckList.map((group) => group.groupSeqNo);
      setCheckedGroupListS(groupSeqNoList);
    }
  };

  return {
    onClickSearchClose,
    searchGroupOpenS,
    inputRef,
    onChangeSearchText,
    onSubmitSearchText,
    groupSearchTextS,
    onClickResetSearchGroup,
    groupSeachTextToggleS,
  };
};
