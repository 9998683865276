import styled from 'styled-components';

interface headerContainerProps {
  border: boolean;
}

// 헤더 컨테이너
export const HeaderContainer = styled.section<headerContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 5rem;
  padding: 0 1.6rem;
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  border-bottom: ${(props) => props.border && `1px solid ${props.theme.colors.gray200}`};
`;

//왼쪽 타이틀
export const HeaderTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
`;

//왼쪽 컨텐츠
export const HeaderContents = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt200};
  @media screen and (max-width: 380px) {
    font-size: 1.2rem;
  }
`;

// 왼쪽 아이콘 컨테이너
export const HeaderLeft = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  overflow: hidden;
  line-height: normal;
  button {
    color: ${(props) => props.theme.colors.txt500};
  }
`;

//중앙 이름 컨테이너
export const CenterContainer = styled.h3`
  max-width: 65%;
  font-size: 2rem;
  white-space: nowrap;
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis;

  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  text-align: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* 호버 시 텍스트 확장 */
  &:hover {
    z-index: 3;
    top: 6rem;
    transform: translateX(-50%);
    white-space: normal; /* 줄바꿈 허용 */
    word-wrap: break-word; /* 줄바꿈을 강제로 발생 */
    overflow: visible; /* 숨기지 않음 */
    max-width: none; /* 최대 폭 제한 해제 */
    background: ${(props) => props.theme.colors.gray200}; /* 가독성을 위한 배경색 */
    padding: 0.5rem; /* 배경 여백 */
    border-radius: 0.5rem; /* 경계선 둥글게 */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* 약간의 그림자 */

    /* 삼각형 추가 */
    &::after {
      content: '';
      position: absolute;
      top: -1rem; /* 삼각형 위치 */
      left: 50%;
      transform: translateX(-50%);
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent transparent ${(props) => props.theme.colors.gray200} transparent;
    }
  }
`;

export const CenterBubble = styled.div``;

// 오른쪽 아이콘 컨테이너
export const IconContainer = styled.section`
  display: flex;
  gap: 2rem;
  button {
    color: ${(props) => props.theme.colors.txt500};
  }
`;

//버튼일 경우 아이콘일 때
export const HeaderButtonIcon = styled.button`
  font-size: 2.4rem;
  border-radius: 100%;
  transition: background-color 0.4s ease; // 듀레이션 0.4초 추가
`;

export const HeaderRightButtonIcon = styled.button`
  transition: background-color 0.4s ease; // 듀레이션 0.4초 추가
  font-size: 1rem;
  white-space: nowrap; /* 텍스트가 한 줄로 나오도록 설정 */
  text-align: center; /* 텍스트를 가운데 정렬 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  /* position: relative; */
  span {
    /* position: absolute;
    bottom: -65%;
    left: 50%;
    transform: translateX(-50%); */
    color: ${(props) => props.theme.colors.gray600};
    font-weight: 600;
  }
`;

//버튼일 경우 폰트일 때
export const HeaderButtonFont = styled.button`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt200};
  &.active {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const GroupListHeaderModalContainer = styled.div`
  width: 100%;
  margin-top: 5rem;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 0.7rem 1.3rem 0px rgba(0, 0, 0, 0.1);
`;

export const AddGroupComponent = styled.form`
  z-index: 1;
  gap: 1rem;
  border-top: 1px solid ${(props) => props.theme.colors.gray300};
  padding: 1rem 1.3rem;
  border-radius: 0 0 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  > svg {
    margin: 1rem 0 0;
    width: 1.7rem;
    height: 1.7rem;
  }
  .addGroup {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 3.8rem;
    background-color: ${(props) => props.theme.colors.white};
    outline: 1px solid ${(props) => props.theme.colors.gray300};
    padding: 0 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.gray600};

    :has(input:focus) {
      outline-color: ${(props) => props.theme.colors.gray400};
    }
  }
  input {
    border: 0;
    font-size: 1.6rem;
    font-weight: 600;
    width: 100%;
    :focus {
      border: 0;
      outline: 0;
    }
    ::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
      border: 0;
      font-size: 1.4rem;
    }
  }

  /* 저장, 취소 스타일 */
  .btnGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    button {
      cursor: pointer;
      width: 48%;
      border-radius: 0.5rem;
      padding: 0.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      background: ${(props) => props.theme.colors.gray600};
      color: ${(props) => props.theme.colors.txt100};
      &.addBtn {
        background: ${(props) => props.theme.colors.gray800};
      }
    }
  }
`;
