import { instanceFile } from '@/shared/lib/clientAxios';

interface IUseMutationSendMsg {
  dto: any;
  file: any;
  fomatCallingNum: string; // 010, 015인지 비교하기위한 값
}

export const useMutationSendMsg = async ({ file, dto, fomatCallingNum }: IUseMutationSendMsg): Promise<void> => {
  const json = JSON.stringify(dto);
  const formData = new FormData();
  const blob = new Blob([json], { type: 'application/json' });

  // 발신번호(010, 015)에 따른 엔드포인트 변경
  let endpoint: string;

  // '015'일 경우 or '010'일 경우 - 010일 경우 'mcmp' or 'legacy'
  if (fomatCallingNum === '015') {
    endpoint = '/smt/send';
  } else if (fomatCallingNum === '010') {
    // endpoint = localStorage.getItem('callBack') === '01067873947' ? '/mcmp/send' : '/message/legacy/send';
    endpoint = '/mcmp/send';
  } else {
    throw new Error('Unsupported calling number format');
  }

  formData.append('file', file);
  formData.append('dto', blob);
  const response = await instanceFile.post(endpoint, formData);
  return response.data;
};
