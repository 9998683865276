import React from 'react';
import { THelpPage, useHelpContentPopup } from '../../hooks/customs/windowPopup/helpPopup/useHelpContentPopup';
import * as S from './HelpPopup.styles';
import HelpPopupPages from './HelpPopupPages';
import { useHelpPopupCookie } from '@/components/hooks/customs/windowPopup/helpPopup/useHelpPopupCookie';

const HelpPopupContent: React.FC = () => {
  const helpHooks = useHelpContentPopup();
  const cookieHooks = useHelpPopupCookie();

  return (
    <S.PopupContent className="popupContent">
      <S.PopupHeadMenu className={`headMenuGroup`}>
        <ul className="headMenus">
          {helpHooks.helpPages.map((page: THelpPage) => {
            return (
              <li key={page} className={`${helpHooks.currentPage === page ? 'active' : ''}`}>
                <button onClick={() => helpHooks.onClickPagesChange(page)}>{page}</button>
              </li>
            );
          })}
        </ul>
      </S.PopupHeadMenu>
      <S.PopupBody className="popupBody">
        {helpHooks.helpPages.map((page: THelpPage, index: number) => {
          return <HelpPopupPages key={page} page={page} index={index} handleSetRef={helpHooks.handleSetRef} />;
        })}
        {/* 일주일간 보지않기 */}
        {/* <div className="hidePopup">
          <button onClick={() => cookieHooks.onClickHidePopup()}>일주일간 보지않기</button>
        </div> */}
      </S.PopupBody>
    </S.PopupContent>
  );
};
export default HelpPopupContent;
