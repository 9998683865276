import axios from 'axios';

export const getFindId = async (nInfo: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/id?encodeData=${nInfo}`);

    return response.data;
  } catch (error) {
    return error;
  }
};
