import styled from 'styled-components';

export const MobileEtcUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -1.4rem;
  > h4 {
    font-size: 1.2rem;
    padding: 0 1.4rem;
    margin: 0 0 1rem;
  }
  .tabMenu {
    :hover {
      background: ${(props) => props.theme.colors.gray100};
      transition: 0.2s ease-in-out;
    }
    p {
      padding: 2rem 0;
      margin: 0 1.4rem;
      font-size: 1.6rem;
      color: ${(props) => props.theme.colors.txt500};
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }

    &:last-of-type {
      p {
        border-bottom: 0;
      }
    }
  }
`;
