import styled from 'styled-components';

export const MessageResult = styled.div`
  > h2 {
    margin-bottom: 2rem;
  }
  .table {
    margin-top: 1rem;
  }
`;
export const TableFoot = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2.5rem 0;
`;
