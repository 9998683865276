import React, { useState } from 'react';
import * as S from './AddLargeTable.index.styles';
import { useAddLargeSubmit } from '@/components/hooks/customs/addLargeTable/useAddLargeSubmit';
import { DefaultRowData } from '@/apis/api/addressApis';
import { useAddLargeCancel } from '@/components/hooks/customs/addLargeTable/useAddLargeCancel';
import { useAddLargeReset } from '@/components/hooks/customs/addLargeTable/useAddLargeReset';
import AddressAddColumModal from '@/pages/main/components/modal/addressAddColumModal/AddressAddColumModal';

interface IUseAddLargeSubmit {
  rows: DefaultRowData[];
  setRows: React.Dispatch<React.SetStateAction<DefaultRowData[]>>;
  inputRefs: any;
}

const AddLargeHead = (props: IUseAddLargeSubmit) => {
  const resetHooks = useAddLargeReset({ setRows: props.setRows });
  const submitHooks = useAddLargeSubmit({
    handleLargeTableReset: resetHooks.handleLargeTableReset,
    inputRefs: props.inputRefs,
  });
  const cancelHooks = useAddLargeCancel({ handleLargeTableReset: resetHooks.handleLargeTableReset });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <S.AddLargeBtnGroup>
      {/* 항목추가 팝업 컴포넌트 */}
      <AddressAddColumModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} useComponent={'addLarge'} />
      <S.AddLargeResetBtn type="button" onClick={() => resetHooks.onClickResetBtn()}>
        초기화
      </S.AddLargeResetBtn>
      <S.AddLargeSCBtnGroup>
        <S.AddLargeSaveBtn type="button" onClick={() => setIsModalOpen(true)}>
          항목추가
        </S.AddLargeSaveBtn>
        <S.AddLargeCanceltBtn type="button" onClick={() => cancelHooks.onClickCancelBtn()}>
          취소
        </S.AddLargeCanceltBtn>
        <S.AddLargeSaveBtn type="button" onClick={() => submitHooks.handleSaveChanges(props.rows)}>
          저장
        </S.AddLargeSaveBtn>
      </S.AddLargeSCBtnGroup>
    </S.AddLargeBtnGroup>
  );
};

export default AddLargeHead;
