import NoData from '@/components/common/NoData';
import { msgResultPageRecoil, msgResultToggleRecoil } from '@/recoil/atoms/say015Atom';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { EtcChattingComponent } from '../../chatRoom015/ChatRoom015.styled';
import ScheduleDetailBox from '../schedule/ScheduleDetailBox';
import ResultDetailBox from './ResultDetailBox';

/**
 * @title : 좌측 전송 결과 상세 페이지 컴포넌트
 *
 * @author 정휘학
 * @since 2024.03.19
 */
const SendResultDetail = () => {
  /* 010 전송결과 페이지 토글 Recoil */
  const msgResultToggleS = useRecoilValue(msgResultToggleRecoil);
  /* 010 전송결과 or 예약전송 확인 Recoil */
  const msgResultPageS = useRecoilValue(msgResultPageRecoil);

  return (
    <EtcChattingComponent className={`${msgResultToggleS ? 'etcEnd' : 'etcStart'}`}>
      {msgResultPageS === 'result' && <ResultDetailBox />}
      {msgResultPageS === 'schedule' && <ScheduleDetailBox />}
      {msgResultPageS === null && <NoData />}
    </EtcChattingComponent>
  );
};

export default SendResultDetail;
