import { instance } from '@/shared/lib/clientAxios';

type SearchData = {
  prepayPayNo: string;
  pageSize: number;
  pageNumber: number;
  // keywordValue: string | null;
  keyword: string | null;
  rsltVal: string | null;
};

export const getDataTransferDetailList = async (searchAllValue: SearchData) => {
  const response = await instance.get(
    // localStorage.getItem('callBack') === '01067873947' ? '/mcmp/result/direct' : '/resultDetail',
    '/mcmp/result/direct',
    {
      params: searchAllValue,
    },
  );
  return response.data;
};
