import styled from 'styled-components';

// 테이블을 감싸는 컨테이너
export const AWrapper = styled.div`
  margin: 0 0 4rem 0;
  > h2 {
    margin-bottom: 2.8rem;
  }

  @media screen and (max-width: 768px) {
    > h2 {
      margin-bottom: 1.6rem;
    }
  }
`;
// 테이블 요소
export const AContents = styled.table`
  width: 100%;
  border-top: 2px solid #a1a1a1;
  background-color: transparent;
  th,
  td {
    padding: 1.2rem;
    font-size: 1.4rem;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    thead,
    tbody {
      width: 100%;
      border-right: 0;
      tr {
        display: flex;
        flex-wrap: wrap;
      }
      th,
      td {
        width: 100%;
        border-right: 0;
        border-left: 0;
      }
    }
    th {
      background: #d6d9e4;
    }
  }
`;

export const AContentsBody = styled.tbody`
  width: 100%;
`;
export const AContentsRow = styled.tr``;
export const AContentsHeadCell = styled.th`
  width: 30%;
  border: 1px solid #d1d1d1;
  border-left: 0;
`;
export const AContentsBodyCell = styled.td`
  border: 1px solid #d1d1d1;
  border-right: 0;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: auto;
    height: auto;
    padding: 0.6rem 1.2rem;
    line-height: normal;
  }
`;
