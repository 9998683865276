/**
 * @title 메인 주소록 체크박스 관리 hook
 * */
import { checkGroupListState } from '@/recoil/atoms/addressList';
import {
  groupBuddyListParamsRecoil,
  mainTableCheckDataFormatRecoil,
  MainTableCheckDataFormatType,
} from '@/recoil/atoms/mainTableRecoil';
import { checkChildrenArrR, checkGroupArrR, unCheckChildrenArrR } from '@/recoil/atoms/messageSend/messageSend';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect } from 'react';
import { useRecoilState } from 'recoil';

export const useAddressBookTableCheckBox = () => {
  const queryClient = useQueryClient();
  const groupListQueryData: any = queryClient.getQueryData(['groupList2']);
  // ? 그룹 리스트 체크 array
  const [checkGroupArrS, setCheckGroupArrS] = useRecoilState(checkGroupArrR);
  // ? 하위 리스트 체크 true array
  const [checkChildrenArrS, setCheckChildrenArrS] = useRecoilState(checkChildrenArrR);
  // ? 하위 리스트 체크 false array
  const [unCheckChildrenArrS, setUnCheckChildrenArrS] = useRecoilState(unCheckChildrenArrR);
  // ? 좌측 그룹 리스트 체크박스 상태를 관리하는 recoil
  const [, setCheckGroupListState] = useRecoilState(checkGroupListState);
  /* 주소록 메인 테이블 체크박스 상태 데이터를 가공해서 삭제 및 소프트폰 메세지 전송 수신인에 표출 등... 에 사용되는 데이터 가공 recoil */
  const [, setMainTableCheckDataFormatS] = useRecoilState(mainTableCheckDataFormatRecoil);
  /* 주소록 groupBuddyList params recoil */
  const [groupBuddyListParamsS] = useRecoilState(groupBuddyListParamsRecoil);

  // =========================================================================================================
  // 주소록 메인 테이블 체크박스 상태 데이터를 가공해서 삭제 및 소프트폰 메세지 전송 수신인에 표출 등... 에 사용되는 데이터 가공 useEffect
  // =========================================================================================================
  useEffect(() => {
    const groupList: any = queryClient.getQueryData(['groupList2']);

    if (!groupList || !checkGroupArrS) return;

    const addressCheckedData = checkGroupArrS?.filter((f) => f.isChecked || f.isChildrenChecked);

    // 전체 체크 후 하위 리스트에서 몇가지 하위 리스트만 빠진 상태
    const GroupTrueChildTrue = checkGroupArrS?.filter((f) => f.isChecked && f.isChildrenChecked);
    // 전체 체크
    const GroupTrueChildFalse = checkGroupArrS?.filter((f) => f.isChecked && !f.isChildrenChecked);
    // 몇가지 하위 리스트만 별도로 체크된 상태
    const GroupFalseChildTrue = checkGroupArrS?.filter((f) => !f.isChecked && f.isChildrenChecked);

    // TODO - 주소록 체크박스 여기 시작
    let makeViewS: MainTableCheckDataFormatType = [];
    if ((addressCheckedData && addressCheckedData.length > 0) || (checkChildrenArrS && checkChildrenArrS.length > 0)) {
      // 전체 하위 리스트에서 몇가지 하위 리스트만 빠진 아이템을 골라 상단에 표출할 데이터 가공
      if (
        GroupTrueChildTrue &&
        GroupTrueChildTrue.length > 0 &&
        unCheckChildrenArrS &&
        unCheckChildrenArrS.length > 0
      ) {
        for (const item of GroupTrueChildTrue) {
          const targetGroupSeqNo = item.groupSeqNo;
          const targetGroupTotalChildren = item.totalChildren;
          // const targetGroupName: string = groupList.groupList.find((f: any) => f.groupSeqNo === targetGroupSeqNo)
          const targetGroupName: string = checkGroupArrS.find((f: any) => f.groupSeqNo === targetGroupSeqNo)
            ?.groupNm as string;
          const targetUnCheckChildrenLength = unCheckChildrenArrS?.filter(
            ({ groupSeqNo }) => groupSeqNo === targetGroupSeqNo,
          )?.length;

          makeViewS = makeViewS.concat({
            groupSeqNo: targetGroupSeqNo,
            buddySeqNo: null,
            groupNm: targetGroupName,
            api: true,
            groupCheck: false,
            count: targetGroupTotalChildren - targetUnCheckChildrenLength,
          });
        }
      }
      // 전체체크 된 데이터
      if (GroupTrueChildFalse && GroupTrueChildFalse.length > 0) {
        for (const item of GroupTrueChildFalse) {
          const targetGroupSeqNo = item.groupSeqNo;
          const targetGroupTotalChildren = item.totalChildren;
          // const targetGroupName: string = groupList.groupList.find((f: any) => f.groupSeqNo === targetGroupSeqNo)
          const targetGroupName: string = checkGroupArrS.find((f: any) => f.groupSeqNo === targetGroupSeqNo)
            ?.groupNm as string;
          makeViewS = makeViewS.concat({
            groupSeqNo: targetGroupSeqNo,
            groupCheck: true,
            buddySeqNo: null,
            api: false,
            groupNm: targetGroupName,
            count: targetGroupTotalChildren,
          });
        }
      }
      // 몇개 하위 리스트만 체크한 데이터
      if (GroupFalseChildTrue && GroupFalseChildTrue.length > 0 && checkChildrenArrS && checkChildrenArrS.length > 0) {
        for (const item of GroupFalseChildTrue) {
          const targetGroupSeqNo = item.groupSeqNo;
          // const targetGroupName: string = groupList.groupList.find((f: any) => f.groupSeqNo === targetGroupSeqNo)
          const targetGroupName: string = checkGroupArrS.find((f: any) => f.groupSeqNo === targetGroupSeqNo)
            ?.groupNm as string;
          const targetCheckChildrenArrS: any = checkChildrenArrS?.filter((f) => f.groupSeqNo === targetGroupSeqNo);
          const targetCheckChildrenArrSLength: number = targetCheckChildrenArrS?.length as number;
          const targetCheckChildrenBuddySeqNoArr: number[] = targetCheckChildrenArrS.flatMap(
            (item: any) => item.buddySeqNo,
          ) as number[];
          makeViewS = makeViewS.concat({
            groupSeqNo: targetGroupSeqNo,
            groupCheck: false,
            buddySeqNo: targetCheckChildrenBuddySeqNoArr,
            api: false,
            groupNm: targetGroupName,
            count: targetCheckChildrenArrSLength,
          });
        }
      }
      // TODO - 주소록 체크박스 여기 makeViewS값 확인
      setMainTableCheckDataFormatS(makeViewS);
    } else {
      // TODO - checkGroupArrS 사용하세요!
      setMainTableCheckDataFormatS((prevState) => {
        if (!prevState) return prevState;
        return prevState.map((item) => {
          return {
            ...item,
            groupCheck: false,
            buddySeqNo: null,
            api: false,
            count: 0,
          };
        });
      });
    }
  }, [checkGroupArrS, checkChildrenArrS, unCheckChildrenArrS]);

  /**
   * @title 그룹 리스트 캐쉬 데이터 가져오는 useEffect
   * */
  useEffect(() => {
    if (groupListQueryData) {
      new Promise((resolve) => {
        let checkGroupArr: {
          groupNm: string;
          groupSeqNo: number;
          isChecked: boolean;
          isChildrenChecked: boolean;
          totalChildren: number;
        }[] = [];
        for (const list of groupListQueryData.groupList) {
          checkGroupArr = checkGroupArr.concat({
            groupNm: list.groupNm,
            groupSeqNo: list.groupSeqNo,
            // isChecked: checkGroupFilter?.includes(list.groupSeqNo) ?? false,
            isChecked: false,
            isChildrenChecked: false,
            totalChildren: list.buddyCount,
          });
        }
        resolve(checkGroupArr);
      }).then((checkGroupArr: any) => {
        setCheckGroupArrS(checkGroupArr);
      });
    }
  }, [groupListQueryData]);
  // ==========================================================
  // 아래에 구현된 Hook 들은 체크박스를 유지 및 관리하는 Hook
  // ==========================================================
  /**
   * @title 메인 주소록 전체 체크박스 handle trigger hook
   * */
  const allGroupCheckH = (e: ChangeEvent<HTMLInputElement>) => {
    const checkState = e.currentTarget.checked;
    const focusGroupSeqNo = groupBuddyListParamsS.groupSeqNo;
    // 각각의 그룹 체크박스 상태 변경
    const updatedCheckGroupArrS = (checkGroupArrS || []).map((group) => {
      if (focusGroupSeqNo && focusGroupSeqNo === group.groupSeqNo) {
        if (checkState) {
          setCheckGroupListState((prevState) => {
            return [...prevState, group.groupSeqNo];
          });
        } else {
          setCheckGroupListState((prevState) => {
            return prevState.filter((f) => f !== group.groupSeqNo);
          });
        }
        return {
          ...group,
          isChecked: checkState,
          isChildrenChecked: false,
        };
      }
      if (!focusGroupSeqNo) {
        const filterGroupSeqNo = groupListQueryData?.groupList.flatMap((f: any) => f.groupSeqNo);
        if (checkState) {
          setCheckGroupListState(filterGroupSeqNo);
        } else {
          setCheckGroupListState([]);
        }
        return {
          ...group,
          isChecked: checkState,
          isChildrenChecked: false,
        };
      }
      return group;
    });
    setCheckGroupArrS(updatedCheckGroupArrS);
    updatedChildrenCheckState('allCheckBox');
  };
  /**
   * @title 전체그룹의 체크박스의 상태가 true 상태값으로 변경될지 정하는 Hook
   * */
  const checkingAllGroupListCheckBoxTrue = (groupSeqNo: number, check: boolean) => {
    setCheckGroupListState((prevState) => {
      return [...prevState, groupSeqNo];
    });
  };
  /**
   * @title 각 그룹의 체크박스 상태를 확인하는 Hook
   * */
  const checkingGroupCheck = (targetGroupSeqNo: number): boolean => {
    if (!checkGroupArrS) return false;
    const targetGroup = checkGroupArrS?.find(({ groupSeqNo }) => groupSeqNo === targetGroupSeqNo);
    const targetGroupIsChecked: boolean = targetGroup?.isChecked as boolean;
    const targetGroupIsChildrenChecked: boolean = targetGroup?.isChildrenChecked as boolean;
    return targetGroupIsChecked && !targetGroupIsChildrenChecked;
  };
  /**
   * @title 하위 리스트의 체크박스 상태를 확인하는 Hook
   * */
  const checkingChildrenCheck = (targetGroupSeqNo: number, targetBuddySeqNo: number) => {
    // 해당 하위 리스트가 속한 그룹의 체크박스 상태값
    const targetGroupData = checkGroupArrS?.find(({ groupSeqNo }) => groupSeqNo === targetGroupSeqNo);
    const targetGroupCheckBoolean = targetGroupData?.isChecked;
    // 해당 하위 리스트의 체크박스 상태값
    const targetChildrenCheckBoolean = checkChildrenArrS?.find(({ buddySeqNo }) => buddySeqNo === targetBuddySeqNo);
    const targetChildrenUnCheckBoolean = unCheckChildrenArrS?.find(({ buddySeqNo }) => buddySeqNo === targetBuddySeqNo);
    return !!((!targetChildrenUnCheckBoolean && targetGroupCheckBoolean) || targetChildrenCheckBoolean);
  };
  /**
   * @title 타입 모든 체크박스 state 관리 Hook
   * */
  const updatedChildrenCheckState = (
    type: 'allCheckBox' | 'groupCheckBox', // 1 : 메인 주소록 전체체크박스 ,
    targetGroupSeqNo?: number,
    targetBuddySeqNo?: number,
  ) => {
    // 메인 주소록 전체 체크
    if (type === 'allCheckBox') {
      setCheckChildrenArrS(null);
      setUnCheckChildrenArrS(null);
    }
    // 그룹의 체크박스 상태가 변경될 때
    if (type === 'groupCheckBox') {
      // 하위 리스트 상태 관리 로직
      const updatedCheckChildrenArrS = checkChildrenArrS?.filter((f) => f.groupSeqNo !== targetGroupSeqNo);
      setCheckChildrenArrS(updatedCheckChildrenArrS ?? []);
      const updatedUnCheckChildrenArrS = unCheckChildrenArrS?.filter((f) => f.groupSeqNo !== targetGroupSeqNo);
      setUnCheckChildrenArrS(updatedUnCheckChildrenArrS ?? []);
    }
  };
  /**
   * @title 각 그룹의 체크박스 상태를 관리하는 Hook
   * */
  const changeGroupCheck = (targetGroupSeqNo: number) => {
    const targetGroupData = checkGroupArrS?.find(({ groupSeqNo }) => groupSeqNo === targetGroupSeqNo);
    const targetGroupIsChecked = targetGroupData?.isChecked;
    const targetGroupIsChildrenChecked = targetGroupData?.isChildrenChecked;
    // const checkingState = targetGroupData?.isChecked;
    const updatedCheckGroupArrS: any = checkGroupArrS?.map((group) => {
      if (group.groupSeqNo === targetGroupSeqNo) {
        return {
          ...group,
          // isChecked: (targetGroupIsChecked && !targetGroupIsChildrenChecked) || (!targetGroupIsChecked),
          // isChecked: (targetGroupIsChecked && targetGroupIsChildrenChecked) ? true : !targetGroupIsChecked,
          isChecked: targetGroupIsChecked && targetGroupIsChildrenChecked ? true : !targetGroupIsChecked,
          isChildrenChecked: false,
        };
      }
      return group;
    });
    setCheckGroupArrS(updatedCheckGroupArrS);
    // 하위 리스트 상태 관리 로직
    updatedChildrenCheckState('groupCheckBox', targetGroupSeqNo);
  };
  /**
   * @title 하위 리스트 체크박스 상태 관리 Hook
   * */
  const changeChildrenCheck = (targetGroupSeqNo: number, targetBuddySeqNo: number) => {
    // 상태가 변경되는 리스트가 속하는 그룹에 관한 state 값 가져오기
    const targetGroupItem = checkGroupArrS?.find(({ groupSeqNo }) => groupSeqNo === targetGroupSeqNo);
    // 현재 상태가 변경되는 하위 리스트가 속한 그룹의 체크박스의 상태
    const targetGroupCheckBoolean = targetGroupItem?.isChecked;
    const targetChildrenCheckBoolean = targetGroupItem?.isChildrenChecked;
    const targetGroupListLength = targetGroupItem?.totalChildren;
    const targetChildTrueCheck = checkChildrenArrS?.find((f) => f.buddySeqNo === targetBuddySeqNo);
    const targetChildFalseCheck = unCheckChildrenArrS?.find((f) => f.buddySeqNo === targetBuddySeqNo);
    const targetAllChildTrueCheckLength = checkChildrenArrS
      ? checkChildrenArrS?.filter((f) => f.groupSeqNo === targetGroupSeqNo).length
      : 0;
    const targetAllChildFalseCheckLength = unCheckChildrenArrS
      ? unCheckChildrenArrS?.filter((f) => f?.groupSeqNo === targetGroupSeqNo)?.length
      : 0;
    // isChecked : true, isChildrenChecked : false ( 무조건 하위 리스트의 체크 상태가 false 로 되는 경우 )
    if (targetGroupCheckBoolean && !targetChildrenCheckBoolean) {
      let updatedCheckGroupArrS;
      // 체크해제한 배열에 추가
      if (targetGroupListLength === 1) {
        updatedCheckGroupArrS = checkGroupArrS?.map((group: any) => {
          if (group.groupSeqNo === targetGroupSeqNo) {
            setCheckGroupListState((prevState) => {
              return prevState.filter((f) => f !== targetGroupSeqNo);
            });
            return {
              ...group,
              isChecked: false,
              isChildrenChecked: false,
            };
          }
          return group;
        });
      } else {
        setUnCheckChildrenArrS((prevState) => {
          if (!prevState) return [{ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo }];
          return prevState.concat({ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo });
        });
        updatedCheckGroupArrS = checkGroupArrS?.map((group: any) => {
          if (group.groupSeqNo === targetGroupSeqNo) {
            setCheckGroupListState((prevState) => {
              return prevState.filter((f) => f !== targetGroupSeqNo);
            });
            return {
              ...group,
              isChildrenChecked: true,
            };
          }
          return group;
        });
      }
      updatedCheckGroupArrS ? setCheckGroupArrS(updatedCheckGroupArrS) : setCheckGroupArrS(null);
    }
    // isChecked : true, isChildrenChecked : true ( 전체체크 한 후 하위 리스트의 체크박스 몇개만 false 로 변경되어있다는 뜻 )
    if (targetGroupCheckBoolean && targetChildrenCheckBoolean) {
      // 현재 상태가 변경되는 하위 리스트가 기존에 true state 에 담겨있었다면 false state 로 이동
      if (targetChildTrueCheck || (!targetChildTrueCheck && !targetChildFalseCheck)) {
        setCheckChildrenArrS((prevState: any) => prevState?.filter((f: any) => f?.buddySeqNo !== targetBuddySeqNo));
        setUnCheckChildrenArrS((prevState: any) => {
          if (!prevState) return [{ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo }];
          return prevState.concat({ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo });
        });
        // 만약에 이번 하위 리스트를 마지막으로 해당 그룹의 하위 리스트의 체크박스가 전부 false 일 경우
        if (targetAllChildFalseCheckLength && targetGroupListLength === targetAllChildFalseCheckLength + 1) {
          setUnCheckChildrenArrS((prevState: any) => {
            if (!prevState) return null;
            return prevState.filter((f: any) => f.groupSeqNo !== targetGroupSeqNo);
          });
          const updatedCheckGroupArrS = checkGroupArrS?.map((group: any) => {
            if (group.groupSeqNo === targetGroupSeqNo) {
              return {
                ...group,
                isChecked: false,
                isChildrenChecked: false,
              };
            }
            return group;
          });
          // isChildrenChecked 값을 true 로 변경
          updatedCheckGroupArrS ? setCheckGroupArrS(updatedCheckGroupArrS) : setCheckGroupArrS(null);
        }
      }
      // 현재 상태가 변경되는 하위 리스트가 기존에 false state 에 담겨있었다면 true state 로 이동
      if (targetChildFalseCheck) {
        setUnCheckChildrenArrS((prevState: any) => prevState?.filter((f: any) => f?.buddySeqNo !== targetBuddySeqNo));
        setCheckChildrenArrS((prevState: any) => {
          if (!prevState) return [{ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo }];
          return prevState.concat({ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo });
        });
        // 만약에 이번 하위 리스트를 마지막으로 해당 그룹의 하위 리스트의 체크박스가 전부 true 일 경우
        if (targetAllChildFalseCheckLength && targetAllChildFalseCheckLength - 1 === 0) {
          checkingAllGroupListCheckBoxTrue(targetGroupSeqNo, true);
          setCheckChildrenArrS((prevState: any) => {
            if (!prevState) return null;
            return prevState.filter((f: any) => f.groupSeqNo !== targetGroupSeqNo);
          });
          const updatedCheckGroupArrS = checkGroupArrS?.map((group: any) => {
            if (group.groupSeqNo === targetGroupSeqNo) {
              return {
                ...group,
                isChecked: true,
                isChildrenChecked: false,
              };
            }
            return group;
          });
          updatedCheckGroupArrS ? setCheckGroupArrS(updatedCheckGroupArrS) : setCheckGroupArrS(null);
        }
      }
    }
    // isChecked : false, isChildrenChecked : false ( 해당 그룹과 하위 리스트의 체크박스가 false 인 경우 )
    if (!targetGroupCheckBoolean && !targetChildrenCheckBoolean) {
      let updatedCheckGroupArrS;
      if (targetGroupListLength === 1) {
        updatedChildrenCheckState('groupCheckBox', targetGroupSeqNo);
        checkingAllGroupListCheckBoxTrue(targetGroupSeqNo, true);
        updatedCheckGroupArrS = checkGroupArrS?.map((group: any) => {
          if (group.groupSeqNo === targetGroupSeqNo) {
            return {
              ...group,
              isChecked: true,
            };
          }
          return group;
        });
      } else {
        setCheckChildrenArrS((prevState) => {
          if (!prevState) return [{ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo }];
          return prevState.concat({ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo });
        });
        updatedCheckGroupArrS = checkGroupArrS?.map((group: any) => {
          if (group.groupSeqNo === targetGroupSeqNo) {
            return {
              ...group,
              isChildrenChecked: true,
            };
          }
          return group;
        });
      }
      updatedCheckGroupArrS ? setCheckGroupArrS(updatedCheckGroupArrS) : setCheckGroupArrS(null);
    }
    // isChecked : false, isChildrenChecked : true ( 하위 리스트만 체크박스 변동이 있을 경우 )
    if (!targetGroupCheckBoolean && targetChildrenCheckBoolean) {
      // 현재 상태가 변경되는 하위 리스트가 기존에 false state 에 담겨있었다면 true state 로 이동
      if (targetChildFalseCheck || (!targetChildTrueCheck && !targetChildFalseCheck)) {
        setUnCheckChildrenArrS((prevState: any) => prevState?.filter((f: any) => f?.buddySeqNo !== targetBuddySeqNo));
        setCheckChildrenArrS((prevState: any) => {
          if (!prevState) return [{ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo }];
          return prevState.concat({ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo });
        });
        // 만약에 이번 하위 리스트를 마지막으로 해당 그룹의 하위 리스트의 체크박스가 전부 true 일 경우
        if (targetAllChildTrueCheckLength && targetGroupListLength === targetAllChildTrueCheckLength + 1) {
          checkingAllGroupListCheckBoxTrue(targetGroupSeqNo, true);
          setCheckChildrenArrS((prevState: any) => {
            if (!prevState) return null;
            return prevState.filter((f: any) => f.groupSeqNo !== targetGroupSeqNo);
          });
          const updatedCheckGroupArrS = checkGroupArrS?.map((group: any) => {
            if (group.groupSeqNo === targetGroupSeqNo) {
              return {
                ...group,
                isChecked: true,
                isChildrenChecked: false,
              };
            }
            return group;
          });
          updatedCheckGroupArrS ? setCheckGroupArrS(updatedCheckGroupArrS) : setCheckGroupArrS(null);
        }
      }
      // 현재 상태가 변경되는 하위 리스트가 기존에 true state 에 담겨있었다면 false state 로 이동
      if (targetChildTrueCheck) {
        setCheckChildrenArrS((prevState: any) => prevState?.filter((f: any) => f?.buddySeqNo !== targetBuddySeqNo));
        setUnCheckChildrenArrS((prevState: any) => {
          if (!prevState) return [{ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo }];
          return prevState.concat({ groupSeqNo: targetGroupSeqNo, buddySeqNo: targetBuddySeqNo });
        });
        // 만약에 이번 하위 리스트를 마지막으로 해당 그룹의 하위 리스트의 체크박스가 전부 false 일 경우
        // TODO - 주소록 체크박스 여기 확인
        if (targetAllChildFalseCheckLength && targetAllChildFalseCheckLength - 1 === 0) {
          setUnCheckChildrenArrS((prevState: any) => {
            if (!prevState) return null;
            return prevState.filter((f: any) => f.groupSeqNo !== targetGroupSeqNo);
          });
          const updatedCheckGroupArrS = checkGroupArrS?.map((group: any) => {
            if (group.groupSeqNo === targetGroupSeqNo) {
              return {
                ...group,
                isChecked: false,
                isChildrenChecked: false,
              };
            }
            return group;
          });
          updatedCheckGroupArrS ? setCheckGroupArrS(updatedCheckGroupArrS) : setCheckGroupArrS(null);
        }
      }
    }
  };
  return {
    allGroupCheckH,
    checkGroupArrS,
    checkingGroupCheck,
    changeGroupCheck,
    changeChildrenCheck,
    checkingChildrenCheck,
    groupBuddyListParamsS,
  };
};
