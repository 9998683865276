import styled from 'styled-components';

// button 형태 checkbox
export const CheckBoxButton = styled.div`
  button {
    width: 15px;
    height: 15px;
    border-radius: 0.3rem;
    background: #d6d6dc;
  }

  &.active {
    button {
      position: relative;
      background: #fff;
      border: 2px solid ${(props) => props.theme.colors.main};
      &:after {
        position: absolute;
        top: 0px;
        left: 4px;
        transform: translate(-50%, -50%);
        content: ''; /* 가상 요소 생성 */
        display: block;
        width: 2px;
        height: 6px;
        border: 1px solid ${(props) => props.theme.colors.main};
        border-width: 0 0.2rem 0.2rem 0;
        transform: rotate(45deg);
      }
    }
  }
`;

// input 형태 checkbox
export const CheckBoxInput = styled.input`
  /* 체크되지 않은 상태의 스타일 */
  cursor: pointer;
  position: relative;
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.3rem;
  border: 1px solid ${(props) => props.theme.colors.buttonDisabledBg};
  outline: none; /* 포커스 효과 제거 */
  margin: 0;

  /* 체크된 상태의 스타일 */
  &:checked {
    background: ${(props) => props.theme.colors.main};
    border-color: ${(props) => props.theme.colors.main};
  }

  /* 체크 표시 제거 */
  &:after {
    content: ''; /* 가상 요소 생성 */
    display: block;
    width: 0.5rem;
    height: 0.9rem;
    border: solid transparent;
    border-width: 0 0.2rem 0.2rem 0; /* 체크 모양 생성 */
    transform: rotate(45deg);
    position: absolute;
    top: 0.1rem;
    left: 0.4rem;
    opacity: 0; /* 체크 표시 숨김 */
  }

  /* 체크된 상태일 때 체크 표시 보이기 */
  &:checked:after {
    opacity: 1;
    border-color: ${(props) => props.theme.colors.buttonText};
  }

  @media screen and (max-width: 320px) {
    transform: scale(0.8);
  }
`;

export const CheckBoxInputBack = styled.input`
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border: 0;
  border-radius: 0.2rem;
  background: #ebebef;

  &:after {
    content: ''; /* 가상 요소 생성 */
    display: block;
    width: 0.5rem;
    height: 0.9rem;
    border: 1px solid #fff;
    border-width: 0 0.2rem 0.2rem 0; /* 체크 모양 생성 */
    transform: rotate(45deg);
    position: absolute;
    top: 0.2rem;
    left: 0.5rem;
    opacity: 0; /* 체크 표시 숨김 */
  }
  &:checked {
    background-color: ${(props) => props.theme.colors.buttonActiveBg}; /* 체크되었을 때 배경색 변경 */
    border-color: ${(props) => props.theme.colors.buttonActiveBg};
    &:after {
      opacity: 1;
    }
  }
`;

// svg 아이콘 들어가는 checkBox
export const CheckBoxIcon1 = styled.div`
  button {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background: #d6d6dc;
    border: 2px solid #d6d6dc;
  }
  &.active {
    button {
      background: #fff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain 100%;
      border: 2px solid ${(props) => props.theme.colors.main};
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'%3E%3Cpath d='M12.2202 5.44482L6.98423 11.7948L4.74023 9.25482' stroke='%230D42E5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M14.09 1H2.87C1.83723 1 1 1.8529 1 2.905V14.335C1 15.3871 1.83723 16.24 2.87 16.24H14.09C15.1228 16.24 15.96 15.3871 15.96 14.335V2.905C15.96 1.8529 15.1228 1 14.09 1Z' stroke='%230D42E5' strokeWidth='1.5' strokeLinejoin='round'/%3E%3C/svg%3E");
    }
  }
`;

export const CheckBoxIcon2 = styled.div`
  button {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background: #d6d6dc;
    border: 2px solid #d6d6dc;
  }
  &.active {
    button {
      background: #fff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain 100%;
      border: 2px solid ${(props) => props.theme.colors.main};
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9' viewBox='0 0 10 9' fill='none'%3E%3Cpath d='M8.48 1L3.244 7.35L1 4.81' stroke='%230D42E5' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
    }
  }
`;

// 토글 알림 버튼
// <CH.SwitchButton>
//    <input id="chargeFree" type="checkbox" />
//    <label htmlFor="chargeFree">
//       <span className="button" />
//    </label>
// </CH.SwitchButton>
export const SwitchButton = styled.div`
  &.active {
    label {
      > span {
        position: relative;
        overflow: hidden;

        :after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: '';
          display: block;
          width: 2.4rem;
          height: 2.4rem;
          background: linear-gradient(90deg, #3a2f31, #7e7c7c, #2d2626);
          animation: 1s buttoncolor linear infinite;
        }
      }

      @keyframes buttoncolor {
        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }
  }

  label,
  span {
    transition: all 0.2s ease-in;
  }

  > input {
    display: none;
  }

  label {
    cursor: pointer;
    position: relative;
    display: block;
    width: 3.6rem;
    height: 2rem;
    background: transparent;
    border-radius: 5rem;
    /* border: 2px solid #e5e5e5; */
    background: ${(props) => props.theme.colors.txt400};

    > span {
      position: absolute;
      top: 50%;
      left: 0.2rem;
      transform: translateY(-50%);
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      background: #fff;
      /* border: 1px solid #e5e5e5; */
      border-radius: 50%;
      /* box-shadow: 0px 0.2rem 0.2rem rgba(0, 0, 0, 0.5); */
    }
  }

  > input:checked ~ label {
    background: #4cd964;
    border-color: transparent;

    > span {
      left: calc(100% - 1.8rem);
    }
  }
`;
