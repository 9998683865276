import styled from 'styled-components';

export const MessageManagementWrap = styled.div`
  background: ${(props) => props.theme.colors.gray200};
  padding: 2rem;
  height: 100%;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    > div:nth-of-type(2) {
      z-index: 0;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      overscroll-behavior: contain;
      /* margin: 0 -1rem;
      padding: 0 1rem; */
      ::-webkit-scrollbar {
        border-radius: 40px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 40px;
      }
    }
  }

  input {
    border: 0;
    input:focus {
      outline: none;
    }
  }
  button {
    cursor: pointer;
    /* border: 0; */
  }

  /* 통합 : 전송결과조회, 장기보관함, (예약전송관리) */
  .TransferList {
    .groups {
      background: #fff;
      border: 2px solid #fff;
      :hover {
        background: #fff;
        box-shadow: 5px 5px 10px 0px #d6d6dc;
        border: 2px solid #adc1f4;
      }
    }
    .groups.active {
      background: #f6f7fe;
      border: 2px solid #adc1f4;
    }
  }
  /* 예약전송관리 */
  /* .SendReservation{
		.TransferList{
			height: calc(var(--vh, 100vh) - 43rem);
			@media screen and (max-width: 1440px){
				height: calc(var(--vh, 100vh) - 42rem);
			}
			@media screen and (max-width: 640px){
				height: calc(var(--vh, 100vh) - 38rem);
				
			}
		}
	} */

  @media screen and (max-width: 768px) {
    padding: 1.2rem 1.4rem 0;
  }
`;
