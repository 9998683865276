import Header from '@/components/common/Header';
import SoftPhone from '@/components/softPhone/SoftPhone';
import Notice from '@/pages/CustomerPage/Notice/Notice';
import { customerRouterRecoil } from '@/recoil/atoms/customer/customer';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as S from './CustomerPage.styles';
import EventBoard from './EventBoard/EventBoard';
import QA from './QA/QA';
import { windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import PageNav from '@/components/common/PageNav';
import ApiInstructionModal from './apiInstructionModal/ApiInstructionModal';

const CustomerPage = () => {
  const windowWidth = useRecoilValue(windowWidthRecoil);
  const [customerRouterState, setCustomerRouterState] = useRecoilState(customerRouterRecoil);
  useEffect(() => {
    setCustomerRouterState(0);
  }, []);

  // const components = [<Notice key={0} />, <QA key={1} />, <EventBoard key={2} />];
  const components = [<Notice key={0} />, <QA key={1} />, <EventBoard key={2} />, <ApiInstructionModal key={3} />];

  return (
    <>
      <S.CustomerPageContainer className="CustomerPageContainer">
        {/* 고객센터내 버튼 */}
        <S.Customer>
          <Header />
          {components[customerRouterState]}
        </S.Customer>
        <SoftPhone />
      </S.CustomerPageContainer>
      {windowWidth <= 768 && <PageNav type="custom" />}
    </>
  );
};

export default CustomerPage;
