/* eslint-disable */
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

export const useSortGroup = () => {
  const queryClient = useQueryClient();
  const sortData: any = queryClient.getQueryData(['groupList2']);
  const savedIndexes = JSON.parse(localStorage.getItem('groupIndexes') || '[]');

  // 그룹 정렬 list를 위한 STATE
  const [sortListS, setSortListS] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState<'custom' | 'asc' | 'desc'>('custom'); // 정렬 순서 상태 추가
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  // 드래그시 가장자리에서 스크롤 하기위한 ref
  const scrollRef = useRef<HTMLDivElement>(null);

  // 유효한 인덱스 확인 함수
  const isValidIndexes = (savedIndexes: number[], dataList: any[]) => savedIndexes.length === dataList.length;

  // reorderedList 계산 함수
  const getReorderedList = (savedIndexes: number[], dataList: any[]) => {
    return isValidIndexes(savedIndexes, dataList) ? savedIndexes.map((index: number) => dataList[index]) : dataList;
  };

  // 데이터가 로드될 때 초기화 로직
  useEffect(() => {
    if (sortData && sortData.groupList) {
      // 로컬스토리지의 인덱스와 리스트 길이 비교
      const reorderedList = getReorderedList(savedIndexes, sortData.groupList);

      setSortListS(reorderedList);
    }
  }, [sortData]);

  // 드래그 시작 핸들러
  const handleDragStart = (index: number, e: React.DragEvent<HTMLDivElement>) => {
    setDraggedItemIndex(index);
    e.dataTransfer.effectAllowed = 'move';
  };

  // 드래그 끝 핸들러
  const handleDragEnd = () => {
    setDraggedItemIndex(null);
  };

  // 드래그 오버 핸들러
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (scrollRef.current) {
      const boundingRect = scrollRef.current.getBoundingClientRect();
      const scrollZoneHeight = 160; // 스크롤 감지 범위
      if (e.clientY < boundingRect.top + scrollZoneHeight) {
        scrollRef.current.scrollBy({ top: -40, behavior: 'smooth' });
      }
      if (e.clientY > boundingRect.bottom - scrollZoneHeight) {
        scrollRef.current.scrollBy({ top: -40, behavior: 'smooth' });
      }
    }
  };

  // 드롭 핸들러
  const handleDrop = (index: number) => {
    if (draggedItemIndex !== null && draggedItemIndex !== index) {
      const updatedList = [...sortListS];
      const [movedItem] = updatedList.splice(draggedItemIndex, 1);
      updatedList.splice(index, 0, movedItem);
      setSortListS(updatedList);

      // 인덱스 정보를 로컬스토리지에 저장
      if (sortOrder === 'custom') {
        const newIndexes = updatedList.map((item) => sortData.groupList.findIndex((group: any) => group === item));
        localStorage.setItem('groupIndexes', JSON.stringify(newIndexes));
      }
    }
  };

  // 항목을 드래그 가능한 상태로 설정
  const getDraggableProps = (index: number) => ({
    draggable: true,
    onDragStart: (e: React.DragEvent<HTMLDivElement>) => handleDragStart(index, e),
    onDragEnd: handleDragEnd,
    onDragOver: handleDragOver,
    onDrop: () => handleDrop(index),
  });

  // 이름 기준으로 정렬하는 함수
  const sortByName = () => {
    setSortOrder((prevOrder) => {
      const newOrder = prevOrder === 'custom' ? 'asc' : prevOrder === 'asc' ? 'desc' : 'custom';

      if (newOrder === 'custom') {
        const reorderedList = getReorderedList(savedIndexes, sortData.groupList);
        setSortListS(reorderedList);
      } else {
        const sortedList = [...sortListS].sort((a, b) => {
          if (newOrder === 'asc') return a.groupNm.localeCompare(b.groupNm);
          if (newOrder === 'desc') return b.groupNm.localeCompare(a.groupNm);
          return 0;
        });
        setSortListS(sortedList);
      }

      return newOrder;
    });
  };
  return { sortListS, sortByName, sortOrder, getDraggableProps, scrollRef };
};
