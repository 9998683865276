import styled from 'styled-components';

export const ArsCardWrap = styled.div`
  z-index: 2;
  font-size: 1.6rem;
  background: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); */
  max-width: 24rem;
  border: 1px solid ${(props) => props.theme.colors.gray300};

  /* 간편보기 클릭시 */
  &:has(.simpleCard) {
    width: auto;
    .txtTop {
      padding: 0.8rem;
    }
  }

  &.active {
    outline: 1px solid ${(props) => props.theme.colors.main};
    box-shadow: 0px 0px 1.5rem 0px ${(props) => props.theme.colors.main}26;
  }

  .btnGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0 1.5rem 1.5rem;
    button {
      border-radius: 0.8rem;
      padding: 0.8rem 1rem;
      background: ${(props) => props.theme.colors.grayButton};
      color: ${(props) => props.theme.colors.txt100};
    }
    .cancelBtn {
      background: ${(props) => props.theme.colors.grayButton2};
    }
  }
  .noList {
    .txtTop {
      border-bottom: 0;
    }
  }
  .txtTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.6rem 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
    white-space: normal;
    h3 {
      align-items: flex-start;
      word-break: break-all; /* 긴 문자열이 중간에서 잘리도록 설정 */
    }
    .group {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      > div {
        width: 100%;
      }
      &:has(+ .del) {
        max-width: calc(100% - 2rem);
      }
      input {
        width: 100%;
      }
    }

    .grab,
    .del {
      color: ${(props) => props.theme.colors.gray600};
    }
    .grab {
      svg {
        width: 1rem;
        height: 1.5rem;
      }
    }
    h3.txt {
      font-size: 1.6rem;
    }
    /* 대표 아이콘 */
    .icon {
      color: ${(props) => props.theme.colors.txt500};
      svg {
        width: 2.6rem;
        height: 2.6rem;
      }
    }
    /* 음성재생 아이콘 */
    .voicePlayBtn {
      color: ${(props) => props.theme.colors.txt100};
      &.active {
        circle {
          color: ${(props) => props.theme.colors.main};
        }
      }
      circle {
        color: ${(props) => props.theme.colors.grayButton};
      }
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
    .del {
      justify-self: end;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  // 안내멘트 텍스트, 음성 토글 버튼 스타일
  .updateBtnGroup {
    width: 100%;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    button {
      font-size: 1.4rem;
      font-weight: 600;
      padding: 0.8rem 0.4rem;
      border-bottom: 2px solid transparent;
      &.active {
        border-color: ${(props) => props.theme.colors.main};
        color: ${(props) => props.theme.colors.main};
      }
    }
  }

  // 텍스트 바이트 스타일
  .txtByte {
    padding: 0.5rem 0 0;
    font-size: 1.2rem;
    text-align: right;
    color: ${(props) => props.theme.colors.txt200};
  }

  .noList {
    .list {
      padding: 0;
      height: 0;
      min-height: unset;
    }
  }
  .noList + .btnGroup {
    padding-top: 1.5rem;
  }

  .list {
    max-width: 24rem;
    width: 24rem;
    padding: 1.5rem;
    min-height: 5rem;
    input {
      width: 100%;
      :focus {
        outline: unset;
      }
    }
    .audioWrap {
      width: 100%;
      display: flex;
    }
    .updateWrap {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .btn {
        color: ${(props) => props.theme.colors.red};
        margin: 0 auto;
        width: 2.8rem;
        height: 2.8rem;
        border: 2px solid ${(props) => props.theme.colors.red};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: block;
          width: 2rem;
          height: 2rem;
          background: ${(props) => props.theme.colors.red};
          border-radius: 50%;
          transition: all 0.2s linear;
        }
      }
      &.update {
        .btn {
          color: ${(props) => props.theme.colors.main};
          border-color: ${(props) => props.theme.colors.main};
          span {
            width: 1.4rem;
            height: 1.4rem;
            background: ${(props) => props.theme.colors.main};
            border-radius: 0.3rem;
            transition: all 0.2s linear;
          }
        }
      }
    }
    > p {
      width: 100%;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.4em;
      /* color: ${(props) => props.theme.colors.txt200}; */
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      white-space: pre-wrap;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 20;
    }
    .timeCountCenter {
      display: flex;
      justify-content: center;
      gap: 0.2rem;
      font-size: 1.2rem;
      font-weight: 500;
      white-space: nowrap;
      color: ${(props) => props.theme.colors.txt400};
    }

    .timeCount {
      padding-left: 1.3rem;
      font-size: 1.2rem;
      font-weight: 500;
      white-space: nowrap;
      color: ${(props) => props.theme.colors.txt400};
    }
    textarea {
      width: 100%;
      height: 100%;
      min-height: 14rem;
      border-radius: 0.8rem;
      font-size: 1.4rem;
      border: 0;
      resize: none;
      outline: none;
      color: ${(props) => props.theme.colors.txt500};
      ::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }
    }
  }

  @media screen and (max-width: 540px) {
    max-width: 20rem;
    .txtTop {
      gap: 0.6rem;
      .icon,
      .voicePlayBtn {
        svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      h3.txt {
        font-size: 1.3rem;
      }
      .grab {
        svg {
          height: 1.2rem;
        }
      }
      .group {
        font-size: 1.3rem;
        gap: 0.6rem;
        input {
          ::placeholder {
            font-size: 1.2rem;
          }
        }
      }
    }
    .btnGroup {
      padding: 0 0.6rem 0.6rem;
      gap: 0.6rem;
    }
    .list {
      max-width: 20rem;
      padding: 1rem;
      min-height: 4rem;
      > p {
        -webkit-box-orient: unset;
        max-height: 30rem;
        overflow: auto;
        font-size: 1.3rem;
      }
      textarea {
        ::placeholder {
          font-size: 1.3rem;
        }
      }
    }
    .updateBtnGroup {
      button {
        font-size: 1.3rem;
        padding: 0.5rem;
      }
    }
  }
`;

// 안내멘트 텍스트, 음성 토글 버튼 스타일
export const UpdateBtnGroup = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  button {
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0.8rem 0.4rem;
    border-bottom: 2px solid transparent;
    color: ${(props) => props.theme.colors.txt300};
    &.active {
      border-color: ${(props) => props.theme.colors.main};
      color: ${(props) => props.theme.colors.main};
    }
  }
`;

// 텍스트 바이트 스타일
export const TxtByte = styled.div`
  padding: 0.5rem 0 0;
  font-size: 1.2rem;
  text-align: right;
  color: ${(props) => props.theme.colors.txt200};
`;
