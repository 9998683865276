import QA from '@/pages/CustomerPage/QA/QA';
import React from 'react';

const MobileEtcQA = () => {
  return (
    <>
      <QA />
    </>
  );
};
export default MobileEtcQA;
