import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
);

// // 서비스 워커 등록
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/service-worker.js') // service-worker.js 파일을 등록
//       .then((registration) => {
//         console.log('Service Worker 등록됨: ', registration);
//       })
//       .catch((error) => {
//         console.error('Service Worker 등록 실패: ', error);
//       });
//   });

//   // 메시지 수신 처리: 서비스 워커에서 보낸 메시지 수신
//   navigator.serviceWorker.addEventListener('message', (event) => {
//     if (event.data === 'updateAvailable') {
//       alert('새로운 버전이 배포되었습니다! 새로고침을 진행해주세요.');
//     }
//   });
// }
