export const apiCodeExamples = {
  requestSample1: `{
	"clientId": "test1234",
	"clientSecret": "pass12141",
	"clientKey": "6cb986745ee55e26e3375ab31c953d93a79fa3e49d084dc70a8c1f1acb3be014"
}
`,
  responseSample1: `{
	"tokenType": "Bearer",
	"accessToken": "String"
}
`,
  responseSample2_1: `{
	"fileId": "0GBBSVB5JSAYS",
	"expireTime": "2024-06-15T10:13:15.822439906",
	"resultCode": "10001",
	"resultMessage": "이미지 등록 성공"
}
`,
  responseSample2_2: `{
	"fileId": "0GBBSVB5JSAYS",
	"expireTime": "2024-06-15T10:13:15",
	"resultCode": "41000",
	"resultMessage": "이미 등록된 이미지 입니다."
}
`,
  responseSample3: `{
	"fileData":
	[
		{
				"fileId": "0GBBSVB5JSAYS",
				"fileName": "2mb--930x609.jpg",
				"fileExtension": "jpg",
				"fileMd5": "92e1b5375ccd76fb2cccd3d2785e2e42",
				"expireTime": "2024-06-15T10:13:15",
				"exist": true
		}
	]
}
`,
  requestSample2: `{
	"srcMsgId" : "12345",
	"callback": "01585041234",
	"content": "test message.",
	"receiver": "01059120601"
}
`,
  responseSample4_1: `{
	"resultCode": "10000",
	"resultMessage": "transfer request successful",
	"transferTime": "2024-06-14T10:52:06.053+00:00",
	"umsMsgId": "589406775975211890",
	"srcMsgId": "12345"
}
`,
  responseSample4_2: `{
	"resultMessage": "00113515553는 잘못된 수신번호 형식입니다.",
	"resultCode": "40015"
}
`,
  responseSample4_3: `{
	"resultMessage": "전송가능한 TPS가 초과되었습니다.",
	"resultCode": "42900"
}
`,
  requestSample3: `{
	"srcMsgId" : "12345",
	"callback": "01585041234",
	"subject":"LMS 제목",
	"content": "LMS TEST",
	"receiver": "01059120601",
	"fileIds":[]
}
`,
  responseSample5_1: `{
	"resultCode": "10000",
	"resultMessage": "transfer request successful",
	"transferTime": "2024-06-14T10:52:06.053+00:00",
	"umsMsgId": "589406775975211890",
	"srcMsgId": "12345"
}
`,
  responseSample5_2: `{
	"resultMessage": "00113515553는 잘못된 수신번호 형식입니다.",
	"resultCode": "40015"
}
`,
  responseSample5_3: `{
	"resultMessage": "전송가능한 TPS가 초과되었습니다.",
	"resultCode": "42900"
}
`,
  responseSample6: `[
	{
		"cmpMsgId": "",
		"srcMsgId": "123456789",
		"umsMsgId": "588629768964934500",
		"channel": "SMS",
		"resultCode": "-100",
		"resultMessage": "SM_STATE_DELIVERED",
		"serviceProvider": "SKT",
		"srcSndDttm": "20240612072433",
		"pfmRcvDttm": "20240612072458",
		"pfmSndDttm": "20240612072430"
	}
]
`,
  moHeader1: `POST http://{your.host}/v1/mo/sms HTTP/1.1
host: {your.server.ip}:{your.server.port} or {your.domain}

body
	messageType: string; // 메세지 타입 (SMS, LMS, MMS)
	sender: string; // 메세지 발신자
	receiver: string; // 메세지 수신자
	messageKey: string; // 메세지 식별 키
	requestTime: string; // 요청 시간 - yyyyMMddHHmmss
	message: string; // 메세지 본문 - max 150 bytes

{
	"messageType": "SMS",
	"sender": "01012341234",
	"receiver": "01512341234"
	"messageKey": "1234567890",
	"requestTime": "20241111000000",
	"message": "안녕하세요"
}`,
  moHeader2: `POST http://{your.host}/v1/mo/lms HTTP/1.1
host: {your.server.ip}:{your.server.port} or {your.domain}

body
	messageType: string; // 메세지 타입 (SMS, LMS, MMS)
	sender: string; // 메세지 발신자
	receiver: string; // 메세지 수신자
	messageKey: string; // 메세지 식별 키
	requestTime: string; // 요청 시간 - yyyyMMddHHmmss
	subject: string, // 메세지 제문 - max 20 bytes
	message: string; // 메세지 본문 - max 2000 bytes
{
	"messageType": "LMS",
	"sender": "01012341234",
	"receiver": "01512341234"
	"messageKey": "1234567890",
	"requestTime": "20241111000000",
	"subject": "제목없음",
	"message": "안녕하세요"
}`,
  moHeader3: `POST http://{your.host}/v1/mo/mms HTTP/1.1
host: {your.server.ip}:{your.server.port} or {your.domain}
body
	messageType: string; // 메세지 타입 (SMS, LMS, MMS)
	sender: string; // 메세지 발신자
	receiver: string; // 메세지 수신자
	messageKey: string; // 메세지 식별 키
	requestTime: string; // 요청 시간 - yyyyMMddHHmmss
	subject: string, // 메세지 제문 - max 20 bytes
	message: string, // 메세지 본문 - max 150 bytes
	files: List<FilePart>; // 첨부 파일 - max 3, 500MB
{
	"messageType": "MMS",
	"sender": "01012341234",
	"receiver": "01512341234"
	"messageKey": "1234567890",
	"requestTime": "20241111000000",
	"subject": "제목없음",
	"message": "안녕하세요"
	"files" : files
}`,
};
