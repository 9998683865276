/* eslint-disable */
import { useSignIn } from '@/components/hooks/customs/signIn/useSignIn';
import { removeCookie, setCookie } from '@/shared/util/cookie';
import React, { useEffect, useRef, useState } from 'react';
import { PiEye, PiEyeClosed } from 'react-icons/pi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BaseButton from '../../Atom/BaseButton';
import BaseInput from '../../Atom/BaseInput';
import * as S from './MobileSignin.style';
import { useRecoilState } from 'recoil';
import { signInRecoil } from '@/recoil/atoms/signInAtom';
import { useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { mobileAdRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

/**
 * @title : 로그인 페이지
 */

const MobileSignin = () => {
  const [signInState, setSignInState] = useRecoilState(signInRecoil);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const token = localStorage.getItem('authorizationToken');

  const [idOpen, setIdOpen] = useState<boolean>(false);
  const [passWordOpen, setPassWordOpen] = useState<boolean>(false);
  const [isCapsLockWarningShown, setIsCapsLockWarningShown] = useState(false);

  const [mobileAdS, setMobileAdS] = useRecoilState(mobileAdRecoil);

  const { warningModal } = useModalHook();

  // TODO - 점검용
  useEffect(() => {
    if (!mobileAdS) return;
    warningModal(
      '[임시점검 안내]',
      '서비스 안정화 작업으로 인해 점검이 진행될 예정입니다.</br>일시 : ~ 24.12.05 23:00</br>*작업시간은 연장될 수 있습니다.',
      true,
    );
  }, []);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.getModifierState('CapsLock') && !isCapsLockWarningShown) {
      notification.warning({
        message: 'Caps Lock 경고',
        description: 'Caps Lock이 켜져 있습니다.',
        placement: 'topRight',
      });
      setIsCapsLockWarningShown(true);
    } else if (!e.getModifierState('CapsLock') && isCapsLockWarningShown) {
      setIsCapsLockWarningShown(false); // Caps Lock이 꺼지면 다시 경고를 띄울 수 있도록 초기화
    }
  };

  useEffect(() => {
    if (signInState && token) {
      if (localStorage.getItem('say015User') === 'true' && localStorage.getItem('availabilityStatus') === 'true') {
        navigate('/mobile/message');
      }
    } else {
      const excludedQueryKeys = ['/customer/list'];
      // 모든 쿼리 캐시 중 제외할 쿼리 키를 제외하고 제거
      queryClient
        .getQueryCache()
        .getAll()
        .forEach((query: any) => {
          if (!excludedQueryKeys.includes(query.queryKey[0])) {
            queryClient.removeQueries(query.queryKey);
          }
        });

      setSignInState(false);
      removeCookie('Refresh_Token');
      setCookie('init', `init`, { path: '/' });
      /* 제거하지 않을 localStorage 키  */
      const keepKeys = [
        'dontShowAgain',
        'deviceId',
        'bizAgreement',
        'contacts',
        'loginDeviceIds',
        'addressColumViewLabel',
        'addressColumViewValue',
        'groupIndexes',
        'hotfix',
        'event1',
      ];

      const tempData: any = {};
      /* 제거하지 않을 localStorage 키값 저장시켜두기 */
      keepKeys.forEach((key) => {
        const value = localStorage.getItem(key);
        if (value) {
          tempData[key] = value;
        }
      });

      localStorage.clear();

      Object.keys(tempData).forEach((key) => {
        localStorage.setItem(key, tempData[key]);
      });
    }
  }, [signInState, token]);

  const { handleSubmit, onClickLogin, isLoading, errors, register, viewable, onClickPwViewableToggle } = useSignIn();

  return (
    <S.Container className="mobileSignin">
      <section>
        <S.ContentTitle>
          <h2
          // onClick={() => {
          //   window.location.reload();
          // }}
          >
            <img src="/img/logo.png" alt="logo" />
          </h2>
        </S.ContentTitle>

        <S.LoginWrap>
          <form onSubmit={handleSubmit(onClickLogin)}>
            <S.LoginContentWrap>
              <S.ArreoLoginInfo>
                <S.ArreoLoginInput className={'' + (errors?.signInId ? 'active' : '')}>
                  <BaseInput
                    type="text"
                    paddingLeft="0"
                    paddingRight="0"
                    width="100%"
                    height="3rem"
                    backgroundColor="transparent"
                    {...register('signInId')}
                    onFocus={() => {
                      setIdOpen(true);
                    }}
                    onBlur={(e) => setIdOpen(!!e.target.value)}
                  />

                  <S.InputLabel
                    // isActive={idOpen}
                    className={`${idOpen ? 'active' : ''}`}
                  >
                    <div>전화번호</div>
                  </S.InputLabel>
                </S.ArreoLoginInput>
                <S.ArreoLoginInput className={'' + (errors?.signInPassword ? 'active' : '')}>
                  <BaseInput
                    type={viewable ? 'text' : 'password'}
                    paddingLeft="0"
                    paddingRight="0"
                    width="100%"
                    height="3rem"
                    backgroundColor="transparent"
                    {...register('signInPassword')}
                    onFocus={() => {
                      setPassWordOpen(true);
                    }}
                    onBlur={(e) => setPassWordOpen(!!e.target.value)}
                    onKeyUp={handleKeyUp}
                  />
                  <S.InputLabel className={`${passWordOpen ? 'active' : ''}`}>
                    <div>비밀번호</div>
                  </S.InputLabel>
                  <S.Viewable>
                    {viewable ? (
                      <PiEye className="on" onClick={() => onClickPwViewableToggle()} />
                    ) : (
                      <PiEyeClosed className="off" onClick={() => onClickPwViewableToggle()} />
                    )}
                  </S.Viewable>
                </S.ArreoLoginInput>

                <S.DataFunZone>
                  <Link to="/mobile/mobileFindAccount">아이디 / 비밀번호 찾기</Link>
                  <Link to="/mobile/mobileSignUp">회원가입</Link>
                </S.DataFunZone>
              </S.ArreoLoginInfo>
              <S.AgreementButtonWrap>
                <BaseButton
                  type="submit"
                  height="7rem"
                  fontWeight="800"
                  padding="0"
                  backgroundColor="#191919"
                  borderRadius="5rem"
                  boxShadow="0px 0px 1rem 0px rgba(54, 93, 178, 0.2)"
                  color="#fff"
                  disabled={isLoading && true}
                >
                  로그인
                </BaseButton>
              </S.AgreementButtonWrap>
            </S.LoginContentWrap>
          </form>
        </S.LoginWrap>
        {/* End NoneSoftPhoneWrap Zone */}
      </section>
    </S.Container>
  );
};

export default MobileSignin;
