import { get015Authority } from '@/apis/api/say015Apis';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { say015AuthState, say015Number, say015PageState } from '@/recoil/atoms/say015Atom';
import { signInRecoil } from '@/recoil/atoms/signInAtom';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const usePOBox015 = () => {
  const setSay015NumberState = useSetRecoilState(say015Number);
  const [, setAuthState] = useRecoilState(say015AuthState);
  const [pageState, setPageState] = useRecoilState(say015PageState);
  const [signInState, setSignInState] = useRecoilState(signInRecoil);

  const environmentS = useRecoilValue(environmentRecoil);

  const {
    data: authorityData,
    isLoading: authorityDataIsLoading,
    refetch: authorityDataRefetch,
    isError,
    error,
  } = useQuery(
    ['authorityData'],
    get015Authority,
    // testCode수정 이건 문제되면 주석해제 테스트끝나면 삭제
    // {
    //   enabled: false,
    // },
  );

  const navigate = useNavigate();

  const onClickSignOut = () => {
    setSignInState(false);
  };

  useEffect(() => {
    if (!signInState) {
      environmentS === 'app' ? navigate('/') : navigate('/signin');
    }
  }, [signInState]);

  useEffect(() => {
    if (authorityData && !authorityDataIsLoading) {
      setSay015NumberState(authorityData.number015);
      setAuthState({
        say015User: authorityData.say015User,
        availabilityStatus: authorityData.availabilityStatus,
      });

      if (authorityData.subscriptionStatus) {
        setPageState('정기구독');
        return;
      }
      if (authorityData.availabilityStatus && !authorityData.subscriptionStatus) {
        setPageState('단기구독');
        return;
      }
      if (authorityData.say015User && !authorityData.availabilityStatus) {
        setPageState('구독만료');
        return;
      }
      if (!authorityData.say015User) {
        setPageState('미가입');
      }
    }
    if (isError) {
      // AxiosError 타입으로 error 변수 지정
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 400) {
        onClickSignOut();
      }
    }
  }, [localStorage, authorityData, authorityDataIsLoading, error]);

  return {
    authorityData,
    pageState,
    setPageState,
    authorityDataIsLoading,
    authorityDataRefetch,
  };
};
