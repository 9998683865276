import React from 'react';
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';

/**
 * ==================
 * @title 문장 라인 정렬
 * ==================
 **/
export const formatContentUtil = (content: string) => {
  return DOMPurify.sanitize(content.replace(/(\r\n|\n|\n|\r)/g, '<br />'));
};
/**
 * @title 문장 줄 바꿈 해주는 유틸 Hook
 *
 * @author 정휘학
 * @since 2024.03.18
 * */
export const underLine = (text: string) => {
  if (text) {
    const lines = text.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={uuidv4()}>
        {line}
        <br />
      </React.Fragment>
    ));
  }
  return null;
};

/**
 * @title [Web발신] 문자 제거 하는 유틸 Hook ( [Web발신] 문장 제거 후 라인 제거 )
 *
 * @author 정휘학
 * @since 2024.04.16
 * */
export const removeWebCharacter = (str: string, charToRemove: string) => {
  // 정규 표현식을 사용하여 지정한 문자를 전역적으로 찾아 제거합니다.
  // const regex = new RegExp(charToRemove, 'g');
  if (str) {
    const splitMsg = str.split(charToRemove);
    const filterMessages = splitMsg.filter((msg) => msg !== '');
    return filterMessages.join().replace('\n', '');
  }
  return '';
};
