/* eslint-disable */
import { getDataTransferDetailList } from '@/apis/api/transferResultDetailApi';
import { resultDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

type SearchData = {
  prepayPayNo: string;
  pageSize: number;
  pageNumber: number;
  // keywordValue: string | null;
  keyword: string | null;
  callback: string;
  rsltVal: string | null;
};

/**
 * @title SendResultDetail.tsx 전송결과 조회 상세 페이지 Hooks
 *
 * @author 정휘학
 * @since 2024.03.21
 * */
export const useSendResultDetail = () => {
  const [pageSize] = useState<number>(10);
  const [tableData, setTableData] = useState<any[]>([]);
  const [detailInfo, setDetailInfo] = useState<any>(null);

  const resultDetailParamsState = useRecoilValue(resultDetailParamsRecoil);

  const [searchAllValue, setSearchAllValue] = useState<SearchData>({
    pageSize,
    pageNumber: 0,
    // keywordValue: null,
    keyword: null,
    prepayPayNo: resultDetailParamsState.prepayPayNo,
    callback: resultDetailParamsState.callback,
    rsltVal: null,
  });

  // 리스트 클릭 대상 변경
  useEffect(() => {
    setSearchAllValue((prev) => ({
      ...prev,
      pageNumber: 0,
      prepayPayNo: resultDetailParamsState.prepayPayNo,
      callback: resultDetailParamsState.callback,
    }));
    setTableData([]);
  }, [resultDetailParamsState.prepayPayNo, resultDetailParamsState.callback]);

  const queryKey = ['getResultDetail', searchAllValue];
  const {
    data: trContentDataList,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 0 }) => getDataTransferDetailList({ ...searchAllValue, pageNumber: pageParam }),
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length;
        const isLastPage = nextPage * pageSize >= lastPage.phnList.totalElements;
        return isLastPage ? undefined : nextPage; // 다음 페이지가 없으면 null
      },
      enabled: searchAllValue.prepayPayNo !== '',
    },
  );

  // 데이터를 업데이트하는 useEffect
  useEffect(() => {
    if (!isLoading && !isError && !(typeof trContentDataList === 'string')) {
      const allPagesData = trContentDataList.pages.flatMap((page) => page.phnList.content);
      setTableData(allPagesData);
      const lastPageData = trContentDataList.pages[trContentDataList.pages.length - 1];
      setDetailInfo({
        ...lastPageData,
        failCnt: lastPageData.totalCount - (lastPageData.sendingCount + lastPageData.successCount),
      });
    }
  }, [trContentDataList, isLoading, isError]);

  // 무한 스크롤 이벤트 핸들러
  const handleScroll = (e: any) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && hasNextPage) {
      fetchNextPage(); // 페이지 끝에 도달하면 다음 페이지 불러오기
    }
  };

  return {
    setTableData,
    trContentDataList,
    setSearchAllValue,
    pageSize,
    detailInfo,
    tableData,
    resultDetailParamsState,
    handleScroll,
  };
};
