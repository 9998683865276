import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as S from './MobileHeader.style';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import IconAddAddress from '@/styles/icons/IconAddAddress';
import { instance } from '@/shared/lib/clientAxios';
import { lastPathSegment, secondLastPathSegment } from '@/shared/util/appUtil';
import { formatPhoneNumber, TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import AddressGroupListHeader from './mobileHeaderList/AddressGroupListHeader';
import AddressBuddyListHeader from './mobileHeaderList/AddressBuddyListHeader';
import MessageListHeader from './mobileHeaderList/MessageListHeader';
import MessageAddHeader from './mobileHeaderList/MessageAddHeader';
import MultiMessageAddHeader from './mobileHeaderList/MultiMessageAddHeader';
import EtcListHeader from './mobileHeaderList/EtcListHeader';
import { useRecoilValue } from 'recoil';
import { callbackListRecoil } from '@/recoil/atoms/useCallbackList';

const MobileHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [headerName, setHeaderName] = useState<string>('');
  const callbackListS = useRecoilValue(callbackListRecoil);

  const pathLabelMap: Record<string, string> = {
    '/mobile/address/groupList': '주소록 그룹',
    '/mobile/address/groupList/groupAdd': '폴더 추가',
    '/mobile/message/messageList/messageAdd': '대화 상대 추가',
    '/mobile/message/messageList/messageNewAdd': '신규 대화 생성',
    '/mobile/message/messageList/multiMessageAdd': '대량 전송 생성',

    '/mobile/message/messageList': `채팅 ${TelHyphen(String(callbackListS && callbackListS.filter((item) => item.callback.startsWith('015'))[0].callback))}`,
    '/mobile/ars/arsTree': '인사말 설정',
    '/mobile/etc/etcList': '더보기',

    '/mobile/etc/etcUserInfo': '회원 정보',
    '/mobile/etc/etcUserInfo/infoChange': '회원 정보 변경',
    '/mobile/etc/etcUserInfo/numberChange': '휴대폰번호 변경',
    '/mobile/etc/etcUserInfo/subInfo': '발급 정보',
    '/mobile/etc/etcUserInfo/userChange': '명의 변경',
    '/mobile/etc/etcUserInfo/memberLeave': '회원 탈퇴',

    '/mobile/etc/etcPwChange': '비밀번호 변경',
    '/mobile/etc/etc015SubCharge': '015 이용권',
    '/mobile/etc/etcCallbackNumber': '발신 번호 관리',
    '/mobile/etc/etcPayment': '결제정보 관리',
    '/mobile/etc/etcTaxInvoiceAuto': '세금 계산서 관리',
    '/mobile/etc/etcRegisterTaxInvoice': '현금영수증 관리',
    '/mobile/etc/etcMessageResult': '전송결과 조회',
    '/mobile/etc/etcTaxCashReceiptsDetail': '발행 신청내역',
    '/mobile/etc/etcNotice': '고객센터',
    '/mobile/etc/etcQA': '자주묻는 질문',
    '/mobile/etc/etcVOC': '문의 하기',

    '/mobile/015SubCharge': '구독만료 충전',
  };

  // 헤더 네임 넣어주는 useEffect()
  useEffect(() => {
    // 예외 처리 - 채팅 리스트 -> 채팅룸
    if (/\/mobile\/message\/messageList\/(\d+)/.test(location.pathname)) {
      setHeaderName(TelHyphen(lastPathSegment(location.pathname)));
      return;
    }

    // 조건 2: /mobile/address/groupList/ 뒤에 숫자만 있을 때
    if (/\/mobile\/address\/groupList\/(\d+)$/.test(location.pathname)) {
      const fetchGroupBuddyList = async ({ keyword }: any) => {
        const response = await instance.get('/groupList2');

        // keyword를 숫자로 변환하여 비교하도록 수정
        const groupSeqNo = Number(keyword); // keyword를 숫자로 변환

        // find 메서드를 사용하여 조건에 맞는 항목 찾기
        const foundGroup = response.data.groupList.find((item: any) => item.groupSeqNo === groupSeqNo);

        // 찾은 그룹이 있으면 setHeaderName에 groupNm을 설정
        if (foundGroup) {
          setHeaderName(foundGroup.groupNm);
        } else {
          setHeaderName(''); // 그룹이 없다면 빈 문자열로 설정
        }
      };

      fetchGroupBuddyList({ keyword: lastPathSegment(location.pathname) });
      return;
    }

    // 예외 처리 - 버디 리스트 -> 채팅룸
    if (/\/mobile\/address\/groupList\/(\d+)\/(\d+)/.test(location.pathname)) {
      setHeaderName(TelHyphen(lastPathSegment(location.pathname)));
      return;
    }

    if (/\/mobile\/address\/groupList\/(\d+)(\w+)/.test(location.pathname)) {
      // 조건 1: /mobile/address/groupList/ 뒤에 숫자 + 문자 조합이 있을 때
      setHeaderName('연락처 추가');
      return;
    }

    // 기본 매핑 처리
    setHeaderName(pathLabelMap[location.pathname]);
  }, [location.pathname]);

  return (
    <>
      <S.HeaderContainer
        className="headerContainer"
        border={location.pathname !== '/mobile/message/messageList/multiMessageAdd'}
      >
        <S.HeaderLeft>
          {(() => {
            if (
              location.pathname !== '/mobile/address/groupList' &&
              location.pathname !== '/mobile/message/messageList' &&
              location.pathname !== '/mobile/ars/arsTree' &&
              !/\/mobile\/address\/groupList\/\d+\/\d+$/.test(location.pathname) &&
              location.pathname !== '/mobile/etc/etcList'
            ) {
              return (
                <>
                  <S.HeaderButtonIcon
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <IconArrowLongLeft />
                  </S.HeaderButtonIcon>
                </>
              );
            }

            if (/\/mobile\/address\/groupList\/\d+\/\d+$/.test(location.pathname)) {
              return (
                <>
                  <S.HeaderButtonIcon
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <IconArrowLongLeft />
                  </S.HeaderButtonIcon>
                  <S.HeaderTitle>{headerName}</S.HeaderTitle>
                </>
              );
            }

            return <S.HeaderTitle>{headerName}</S.HeaderTitle>;
          })()}
        </S.HeaderLeft>

        {/* 중앙 컨테이너 */}
        {location.pathname !== '/mobile/address/groupList' &&
        location.pathname !== '/mobile/message/messageList' &&
        location.pathname !== '/mobile/ars/arsTree' &&
        location.pathname !== '/mobile/ars/arsTree' &&
        location.pathname !== '/mobile/etc/etcList' &&
        !/\/mobile\/address\/groupList\/(\d+)\/(\d+)/.test(location.pathname) ? (
          <S.CenterContainer>{headerName}</S.CenterContainer>
        ) : null}

        {/* 오른쪽 컨테이너 */}
        {location.pathname === '/mobile/address/groupList' && <AddressGroupListHeader />}
        {location.pathname === '/mobile/message/messageList' && <MessageListHeader />}
        {location.pathname === '/mobile/message/messageList/messageAdd' && <MessageAddHeader />}
        {location.pathname === '/mobile/message/messageList/multiMessageAdd' && <MultiMessageAddHeader />}
        {location.pathname === '/mobile/etc/etcList' && <EtcListHeader />}

        {/\/mobile\/address\/groupList\/(\d+)/.test(location.pathname) &&
          !/\/mobile\/address\/groupList\/\d+\/[a-zA-Z]+/.test(location.pathname) && <AddressBuddyListHeader />}
      </S.HeaderContainer>
    </>
  );
};

export default MobileHeader;
