import React, { useEffect, useState } from 'react';
import SignUp015Page from './SignUp015Page/SignUp015Page';
import Sub015Page from './Sub015Page/Sub015Page';
import { useRecoilState } from 'recoil';
import { usePOBox015 } from '@/components/hooks/customs/say015/pobox015/usePOBox015';
import { softPhoneComponentRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import ChatRoom from '@/components/softPhone/DisplayGroups/MessageManagement/ChatRoom/ChatRoom';
import MessageSend from '@/components/softPhone/DisplayGroups/MessageSend/MessageSend';
import ArsPhone from '../arsContents/ArsPhone';

interface IAuthorityData {
  availabilityStatus: boolean;
  monthlyPrice: number;
  number015: string;
  say015User: boolean;
  subsEndDate: string;
  subscriptionStatus: boolean;
}

/**
 * @title 소프트폰 015 탭에서 015 구독 미구독에 따른 소프트폰 컴포넌트 분기 처리
 * */
const POBox015 = () => {
  const { pageState, authorityData, authorityDataIsLoading, authorityDataRefetch } = usePOBox015();
  const storageUser = localStorage.getItem('user'); // 현재 로그인중인 user
  const [authorityDataState, setAuthorityDataState] = useState<IAuthorityData | null>(null);
  // 소프트폰 컴포넌트 Handler Recoil
  const [softPhoneComponent] = useRecoilState(softPhoneComponentRecoil);
  /**/

  useEffect(() => {
    if (authorityData) {
      const initailAuthorityData = {
        availabilityStatus: authorityData?.availabilityStatus,
        monthlyPrice: authorityData?.monthlyPrice,
        number015: authorityData?.number015,
        say015User: authorityData?.say015User,
        subsEndDate: authorityData?.subsEndDate,
        subscriptionStatus: authorityData?.subscriptionStatus,
      };
      setAuthorityDataState(initailAuthorityData);
    }
  }, [authorityData]);

  let componentToRender = null;

  if (pageState && (pageState === '정기구독' || pageState === '단기구독')) {
    if (softPhoneComponent === 'sms') {
      componentToRender = <MessageSend />;
    } else if (softPhoneComponent === 'schedule') {
      componentToRender = <MessageSend />;
    } else if (softPhoneComponent === 'send') {
      componentToRender = <MessageSend />;
    } else if (softPhoneComponent === 'chatroom') {
      componentToRender = <ChatRoom />;
    } else if (softPhoneComponent === 'ars') {
      componentToRender = <ArsPhone />;
    }
  }
  if (pageState && pageState === '구독만료') {
    componentToRender = (
      <Sub015Page
        authorityDataState={authorityDataState}
        storageUser={storageUser}
        authorityDataRefetch={authorityDataRefetch}
      />
    );
  }
  if (pageState && pageState === '미가입') {
    componentToRender = (
      <SignUp015Page
        authorityDataRefetch={authorityDataRefetch}
        storageUser={storageUser}
        authorityDataState={authorityDataState}
      />
    );
  }
  return componentToRender;
};

export default POBox015;
