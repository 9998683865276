/**
 * @title 015 ARS 착신번호 Hooks
 * */
import React, { ChangeEvent, useState } from 'react';
import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';

interface IUseArsCall {
  data: NextItem;
  arsData: NextItem;
  updatedJsonFile?(): void; // ARS Json 업데이트
}

/* 착신번호 AFTER Items */
const addressSelect = [
  { value: '010', label: '010' },
  { value: '02', label: '02' },
  { value: '031', label: '031' },
  { value: '032', label: '032' },
  { value: '033', label: '033' },
  { value: '041', label: '041' },
  { value: '042', label: '042' },
  { value: '043', label: '043' },
  { value: '044', label: '044' },
  { value: '051', label: '051' },
  { value: '052', label: '052' },
  { value: '053', label: '053' },
  { value: '054', label: '054' },
  { value: '055', label: '055' },
  { value: '061', label: '061' },
  { value: '062', label: '062' },
  { value: '064', label: '064' },
];

export const useArsCall = ({ data, arsData, updatedJsonFile }: IUseArsCall) => {
  // 수정시 셀렉트 초기값 설정
  const initialSelect = addressSelect?.find((item) => data?.target?.level.startsWith(item.value))?.value || '선택';

  /* 착신번호 STATE */
  const [callNumberState, setCallNumberState] = useState<string>(data?.target?.level);
  /* 지역번호 토글 STATE */
  const [selectSwitch, setSelectSwitch] = useState(false); // select 누르면 oprions 나올 스위치 버튼 역할
  /* 지역번호 선택 STATE */
  const [selectState, setSelectState] = useState(initialSelect); // 검색항목 State

  /* 착신전환 TITLE */
  const [callTitleState, setCallTitleState] = useState<string>(data?.s015Data.title);

  /* 착신전환 Title STATE 핸들러 Hook */
  const handleCallTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setCallTitleState(e.target.value);
  };

  /* 지역번호 핸들러 Hook */
  const onClickSelectListButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    // select option 클릭 이벤트
    const result = e.target as HTMLButtonElement;
    if (typeof result.textContent === 'string') {
      setSelectState(result.textContent);
    }
  };

  /* 착신번호 핸들러 Hook */
  const handleCallNumber = (e: any) => {
    setCallNumberState(e.currentTarget.value.replace(/[^0-9]/g, ''));
  };

  const updatedJSON = (data: any, id: string, value: string, title: string) => {
    if (data.id === id && data.s015Data) {
      data.s015Data.action = '착신전환';
      data.s015Data.type = 'ROUTE';
      data.s015Data.title = title;
      data.target.type = 'ROUTE';
      data.target.level = value;
      data.source.userinput = data?.id.slice(-1);
      return true;
    }
    if (data.next && data.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        if (updatedJSON(data.next[i], id, value, callTitleState)) {
          return true;
        }
      }
    }
    return false;
  };

  /* 착신번호 수정 핸들러 Hook */
  const handleSaveCall = () => {
    const updatedData = { ...arsData };
    if (updatedJSON(updatedData, data?.id, callNumberState, callTitleState) && updatedJsonFile) {
      updatedJsonFile();
    }
  };

  /* 015 ARS 착신번호 취소 Hook */
  const handleCancelCall = () => {
    if (data?.s015Data.title) {
      setCallTitleState(data?.s015Data.title);
    } else {
      setCallTitleState('');
    }
  };

  return {
    callNumberState,
    handleCallNumber,
    addressSelect,
    onClickSelectListButton,
    selectState,
    selectSwitch,
    setSelectSwitch,
    handleSaveCall,
    handleCancelCall,
    handleCallTitle,
    callTitleState,
  };
};
