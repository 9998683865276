import { DefaultRowData } from '@/apis/api/addressApis';
import { selectGroupRecoil, unRegisteredPhoneNum } from '@/recoil/atoms/addressList';
import { addAddressExcelItemsRecoil } from '@/recoil/atoms/atoms';
import { useMemo } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { defaultAddData } from './useAddLargeTable';
import { useModalHook } from '@/components/commons/modals/useModalHook';

interface IUseAddLargeReset {
  setRows: React.Dispatch<React.SetStateAction<DefaultRowData[]>>;
}

export const useAddLargeReset = (props: IUseAddLargeReset) => {
  /* rows 초기값 지정 (행 갯수) */
  const initialRows = useMemo(() => Array.from({ length: 10 }, () => ({ ...defaultAddData })), []);
  /* Select group 표시할 STATE */
  const selectGroupReset = useResetRecoilState(selectGroupRecoil);
  /* 엑셀 추가 */
  const [, setAddAddressExcelItemsS] = useRecoilState(addAddressExcelItemsRecoil);
  /* 미등록 핸드폰 번호 */
  const [, setUnRegisteredPhoneNumS] = useRecoilState(unRegisteredPhoneNum);

  const { confirmModal } = useModalHook();

  // ==============================
  // 대량추가 테이블 초기화 Hook
  // ==============================
  const handleLargeTableReset = () => {
    // 엑셀로 받아온 데이터 recoil 초기화
    setAddAddressExcelItemsS(null);
    // 미등록 번호 추가 데이터 recoil 초기화
    setUnRegisteredPhoneNumS(null);
    // 그룹 select 초기화
    selectGroupReset();
    // 테이블 input 초기화
    props.setRows(initialRows);
  };

  // ==============================
  // onClick 대량추가 초기화 버튼 hook
  // ==============================
  const onClickResetBtn = () => {
    confirmModal(
      async () => handleLargeTableReset(),
      '주소록 대량추가 초기화',
      '입력 중인 주소록을 모두 삭제하시겠습니까?',
      true,
      undefined,
      async () => {},
    );
  };

  return { handleLargeTableReset, onClickResetBtn };
};
