import React from 'react';
import { ITrashBoxHead } from './TrashBoxHead.types';
import IconTrash from '@/styles/icons/IconTrash';
import IconX from '@/styles/icons/IconX';

const TrashBoxHead = (props: ITrashBoxHead) => {
  return (
    <div className="trashBoxHead">
      <div>
        <h6>휴지통 </h6>
        <span className="trashCanNum">({props.groupList?.recycleCount || '0'})</span>
      </div>
      <button onClick={props.recycleBinClickHandle}>
        <IconX strokeWidth={'1.3'} fontSize={'1.6rem'} />
      </button>
    </div>
  );
};

export default TrashBoxHead;
