import React, { useState } from 'react';
import styled from 'styled-components';

import AgreementCheckBoxInner from '../../Atom/SignUp/AgreementCheckBoxInner';
import { SignUpDataProps } from '../../../apis/utils/typeGuard/signUpGuard';
import IconClose from '@/components/Atom/icon/IconClose';

const AgreementInner = styled.li`
  padding: 2rem 0;
`;

const AgreementTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .agreementLeft {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  > button {
    color: ${(props) => props.theme.colors.disable};
    font-weight: 700;
  }
`;

const AgreementTitle = styled.h3`
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
`;

const AgreementCheck = styled.div``;

const ModalOverlay = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  width: 90%;
  max-width: 96rem;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 3rem;
  background: ${(props) => props.theme.colors.white};
  @media screen and (max-width: 540px) {
    width: 95%;
    max-height: 95%;
    border-radius: 0;
    padding: 1.6rem;
    border-radius: 1.6rem;
  }
`;

const CloseButton = styled.button`
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};
  background: none;
  border: none;
  font-size: 8rem;
`;
const ModalTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 2rem;
  font-size: 2.4rem;
  color: ${(props) => props.theme.colors.txt500};
  @media screen and (max-width: 540px) {
    font-size: 1.8rem;
  }
`;

const Agreement = ({ element, checkItemHandler, checked }: SignUpDataProps) => {
  const { id, checkLabel, children, contents } = element;

  const [isModalToggle, setIsModalToggle] = useState<boolean>(false);

  const onClickToggleModal = () => {
    setIsModalToggle((prev) => !prev);
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClickToggleModal();
    }
  };

  return (
    <AgreementInner>
      <AgreementTitleWrap>
        <div className="agreementLeft">
          <AgreementCheck>
            <AgreementCheckBoxInner
              id={id}
              checkLabel={checkLabel}
              checkItemHandler={checkItemHandler}
              checked={checked}
            />
          </AgreementCheck>
          <AgreementTitle>{children}</AgreementTitle>
        </div>
        <button type="button" onClick={() => onClickToggleModal()}>
          내용보기
        </button>
      </AgreementTitleWrap>

      {isModalToggle && (
        <ModalOverlay onClick={(e) => handleOverlayClick(e)}>
          <ModalContent>
            <ModalTop>
              {children}
              <CloseButton type="button" onClick={() => onClickToggleModal()}>
                <IconClose />
              </CloseButton>
            </ModalTop>
            {contents}
          </ModalContent>
        </ModalOverlay>
      )}
    </AgreementInner>
  );
};

export default Agreement;
