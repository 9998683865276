import styled from 'styled-components';

export const FormContainer = styled.div`
  > h2 {
    margin-bottom: 2rem;
  }
`;

export const CashReceiptForm = styled.form`
  .checkGroup {
    gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    .buttonList {
      width: 100%;
    }
  }
`;

export const CRTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  display: flex;
  margin: 3rem 0 5.5rem;
  border-top: 2px solid ${(props) => props.theme.colors.gray400};

  & tr {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 540px) {
    flex-wrap: wrap;
    thead,
    tbody {
      width: 100%;
      th {
        border-right: 0;
        /* background: rgba(0,0,0,0.6);
				color: #fff; */
        background-color: ${(props) => props.theme.colors.gray100};
        color: ${(props) => props.theme.colors.txt500};
      }
      input {
        width: 100%;
      }
      input[type='radio'] {
        width: 15px;
      }
    }
  }
`;

export const CRThead = styled.thead`
  width: 30%;

  & th {
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
  }
`;

export const CRTbody = styled.tbody`
  width: 70%;

  & td {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }

  & td > span {
    margin: 0 10px;
    font-size: 2rem;
  }
`;

export const CheckBoxLabel = styled.label`
  cursor: pointer;
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.3rem;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const TextP = styled.p`
  margin: 3rem 0 0;
  color: ${(props) => props.theme.colors.txt500};
  line-height: 1.8em;
  font-size: 1.3rem;
`;

export const CheckBoxCover = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 3rem;
  font-size: 1.3rem;
  /* flex-wrap: wrap; */
  label {
    display: block;
    flex-wrap: wrap;
    margin-right: 0;
    @media screen and (max-width: 540px) {
      width: calc(100% - 20px);
    }
  }
  & span {
    color: ${(props) => props.theme.colors.main};
    margin-right: 0.5rem;
  }
`;

export const SubmitInput = styled.input`
  background-color: ${(props) => props.theme.colors.main};
  color: #fff;
  border: 0;
  width: 100px;
  height: 30px;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 35px 0 40px;
  cursor: pointer;
  float: right;
`;

export const BottomP = styled.div`
  clear: both;
  line-height: 20px;

  & > * {
    font-size: 1.3rem;
  }
`;
