import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 0.8em;
  height: 1em;
`;

const IconIdea: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 18 23"
      fill="none"
    >
      <path
        d="M1.96324 13.5833C1.2129 12.3546 0.817292 10.9421 0.820237 9.50234C0.820237 5.12834 4.40224 1.58334 8.82024 1.58334C13.2382 1.58334 16.8202 5.12834 16.8202 9.50234C16.8232 10.9421 16.4276 12.3546 15.6772 13.5833M11.8202 18.5833L11.6902 19.2303C11.5502 19.9373 11.4792 20.2903 11.3202 20.5703C11.0752 21.0019 10.6785 21.327 10.2072 21.4823C9.90224 21.5833 9.54024 21.5833 8.82024 21.5833C8.10024 21.5833 7.73824 21.5833 7.43324 21.4833C6.96177 21.3277 6.56505 21.0023 6.32024 20.5703C6.16124 20.2903 6.09024 19.9373 5.95024 19.2303L5.82024 18.5833M8.82024 15.5833V10.5833M4.20324 16.6813C4.11124 16.4053 4.06524 16.2663 4.07024 16.1543C4.07589 16.0385 4.11498 15.9269 4.18278 15.8328C4.25057 15.7387 4.34416 15.6663 4.45224 15.6243C4.55624 15.5833 4.70224 15.5833 4.99224 15.5833H12.6482C12.9392 15.5833 13.0842 15.5833 13.1882 15.6233C13.2965 15.6654 13.3902 15.738 13.458 15.8322C13.5258 15.9265 13.5648 16.0384 13.5702 16.1543C13.5752 16.2663 13.5292 16.4043 13.4372 16.6813C13.2672 17.1923 13.1822 17.4483 13.0512 17.6553C12.7772 18.088 12.3477 18.399 11.8512 18.5243C11.6132 18.5833 11.3452 18.5833 10.8082 18.5833H6.83224C6.29524 18.5833 6.02624 18.5833 5.78924 18.5233C5.2929 18.3982 4.86343 18.0876 4.58924 17.6553C4.45824 17.4483 4.37324 17.1923 4.20324 16.6813Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconIdea;
