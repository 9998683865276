import React from 'react';
import styled from 'styled-components';

const DotsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.2rem;
  span {
    display: block;
    width: 0.2rem;
    height: 0.2rem;
    background: ${(props) => props.theme.colors.txt500};
  }
`;

const MoveDots = () => {
  return (
    <DotsWrap className="dots">
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </DotsWrap>
  );
};

export default MoveDots;
