import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconMoon: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M21.3427 13.0965C20.9262 14.9215 20.0057 16.593 18.6862 17.9208C17.3666 19.2486 15.7009 20.1795 13.8785 20.6074C12.0561 21.0354 10.1502 20.9433 8.37749 20.3417C6.60482 19.74 5.03659 18.6529 3.85118 17.2041C2.66577 15.7553 1.91077 14.0028 1.67205 12.1461C1.43334 10.2894 1.72049 8.403 2.50086 6.70143C3.28123 4.99986 4.52347 3.55144 6.0863 2.52095C7.64912 1.49046 9.46978 0.919256 11.3412 0.872314C9.72015 2.19861 8.69239 4.11452 8.48398 6.19859C8.27558 8.28265 8.9036 10.3641 10.2299 11.9852C11.5562 13.6062 13.4721 14.634 15.5562 14.8424C17.6402 15.0508 19.7217 14.4227 21.3427 13.0965Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconMoon;
