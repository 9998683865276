import React from 'react';
import * as S from '../TrashBoxPopup.styles';

interface ITrashBoxBtnGroup {
  restoreBinAllListOnClick: any;
  binAllBtnState: boolean;
  binRestoreState: boolean;
  binDeleteState: boolean;
  deleteBinAllListOnClick: any;
  checkedBinList: number[];
  binListRestoreHandle: () => void;
  binListDeleteHandle: () => void;
  onClickMenuBtn: () => void;
  resMenuSwitch: boolean;
  layoutRef: React.RefObject<HTMLDivElement>;
  binSuccessSearchS: string;
}

const TrashBoxBtnGroup = (props: ITrashBoxBtnGroup) => {
  return (
    <div>
      <S.TrashBoxFunBtnGroup>
        <button
          onClick={() => props.restoreBinAllListOnClick()}
          disabled={props.binAllBtnState || props.binRestoreState || props.binDeleteState}
          className="allRestoreBtn"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path
              d="M14.8208 7.75109C14.6219 7.75109 14.4311 7.83011 14.2905 7.97076C14.1498 8.11141 14.0708 8.30218 14.0708 8.50109C14.0797 9.90586 13.5985 11.2698 12.71 12.3579C11.8216 13.4461 10.5814 14.1903 9.20326 14.4625C7.82509 14.7347 6.39513 14.5178 5.15967 13.8492C3.92422 13.1805 2.9606 12.102 2.43479 10.7993C1.90898 9.49659 1.85389 8.05133 2.27901 6.7124C2.70414 5.37347 3.58285 4.2247 4.76382 3.46393C5.94479 2.70316 7.35409 2.37803 8.74899 2.54453C10.1439 2.71103 11.4371 3.35873 12.4058 4.37609H10.6058C10.4069 4.37609 10.2161 4.45511 10.0755 4.59576C9.93481 4.73642 9.85579 4.92718 9.85579 5.12609C9.85579 5.325 9.93481 5.51577 10.0755 5.65642C10.2161 5.79707 10.4069 5.87609 10.6058 5.87609H14.0033C14.2022 5.87609 14.393 5.79707 14.5336 5.65642C14.6743 5.51577 14.7533 5.325 14.7533 5.12609V1.7511C14.7533 1.55219 14.6743 1.36142 14.5336 1.22077C14.393 1.08012 14.2022 1.0011 14.0033 1.0011C13.8044 1.0011 13.6136 1.08012 13.473 1.22077C13.3323 1.36142 13.2533 1.55219 13.2533 1.7511V3.0786C12.0043 1.88464 10.3814 1.15856 8.6588 1.0231C6.93626 0.887628 5.21977 1.35108 3.79949 2.33511C2.37921 3.31915 1.34226 4.7634 0.863924 6.42373C0.385589 8.08407 0.495211 9.85864 1.17426 11.4475C1.85331 13.0363 3.06013 14.342 4.59072 15.1437C6.12131 15.9455 7.88179 16.1941 9.57457 15.8477C11.2673 15.5012 12.7886 14.5809 13.8811 13.2423C14.9737 11.9037 15.5705 10.229 15.5708 8.50109C15.5708 8.30218 15.4918 8.11141 15.3511 7.97076C15.2105 7.83011 15.0197 7.75109 14.8208 7.75109Z"
              fill="white"
              stroke="white"
              strokeWidth="0.1"
            />
          </svg>
          <p>{props.binSuccessSearchS ? '검색 결과 전체 복원' : '전체 복원'}</p>
        </button>
        <button
          onClick={() => props.deleteBinAllListOnClick()}
          disabled={props.binAllBtnState || props.binRestoreState || props.binDeleteState}
          className="allDeleteBtn"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <path
              d="M11.2603 11.8035C11.1431 11.9207 10.9842 11.9865 10.8184 11.9865C10.6526 11.9865 10.4937 11.9207 10.3764 11.8035L5.95703 7.38404L1.53761 11.8035C1.4204 11.9207 1.26143 11.9865 1.09567 11.9865C0.929912 11.9865 0.770941 11.9207 0.65373 11.8035C0.53652 11.6862 0.470672 11.5273 0.470672 11.3615C0.470672 11.1958 0.53652 11.0368 0.65373 10.9196L5.07315 6.50016L0.65373 2.08074C0.53652 1.96353 0.470672 1.80456 0.470672 1.6388C0.470672 1.47304 0.53652 1.31407 0.65373 1.19686C0.770941 1.07965 0.929912 1.0138 1.09567 1.0138C1.26143 1.0138 1.4204 1.07965 1.53761 1.19686L5.95703 5.61627L10.3764 1.19686C10.4937 1.07965 10.6526 1.0138 10.8184 1.0138C10.9842 1.0138 11.1431 1.07965 11.2603 1.19686C11.3775 1.31407 11.4434 1.47304 11.4434 1.6388C11.4434 1.80456 11.3775 1.96353 11.2603 2.08074L6.84091 6.50016L11.2603 10.9196C11.3775 11.0368 11.4434 11.1958 11.4434 11.3615C11.4434 11.5273 11.3775 11.6862 11.2603 11.8035Z"
              fill="white"
              stroke="white"
              strokeWidth="0.5"
            />
          </svg>
          <p>{props.binSuccessSearchS ? '검색 결과 전체 삭제' : '전체 삭제'}</p>
        </button>
        {/* 체크된 항목이 있을때 표시될 버튼 */}
        {props.checkedBinList.length > 0 && (
          <>
            <button
              onClick={() => props.binListRestoreHandle()}
              disabled={props.binAllBtnState || props.binRestoreState || props.binDeleteState}
              className="selectRestoreBtn"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path
                  d="M14.8208 7.75109C14.6219 7.75109 14.4311 7.83011 14.2905 7.97076C14.1498 8.11141 14.0708 8.30218 14.0708 8.50109C14.0797 9.90586 13.5985 11.2698 12.71 12.3579C11.8216 13.4461 10.5814 14.1903 9.20326 14.4625C7.82509 14.7347 6.39513 14.5178 5.15967 13.8492C3.92422 13.1805 2.9606 12.102 2.43479 10.7993C1.90898 9.49659 1.85389 8.05133 2.27901 6.7124C2.70414 5.37347 3.58285 4.2247 4.76382 3.46393C5.94479 2.70316 7.35409 2.37803 8.74899 2.54453C10.1439 2.71103 11.4371 3.35873 12.4058 4.37609H10.6058C10.4069 4.37609 10.2161 4.45511 10.0755 4.59576C9.93481 4.73642 9.85579 4.92718 9.85579 5.12609C9.85579 5.325 9.93481 5.51577 10.0755 5.65642C10.2161 5.79707 10.4069 5.87609 10.6058 5.87609H14.0033C14.2022 5.87609 14.393 5.79707 14.5336 5.65642C14.6743 5.51577 14.7533 5.325 14.7533 5.12609V1.7511C14.7533 1.55219 14.6743 1.36142 14.5336 1.22077C14.393 1.08012 14.2022 1.0011 14.0033 1.0011C13.8044 1.0011 13.6136 1.08012 13.473 1.22077C13.3323 1.36142 13.2533 1.55219 13.2533 1.7511V3.0786C12.0043 1.88464 10.3814 1.15856 8.6588 1.0231C6.93626 0.887628 5.21977 1.35108 3.79949 2.33511C2.37921 3.31915 1.34226 4.7634 0.863924 6.42373C0.385589 8.08407 0.495211 9.85864 1.17426 11.4475C1.85331 13.0363 3.06013 14.342 4.59072 15.1437C6.12131 15.9455 7.88179 16.1941 9.57457 15.8477C11.2673 15.5012 12.7886 14.5809 13.8811 13.2423C14.9737 11.9037 15.5705 10.229 15.5708 8.50109C15.5708 8.30218 15.4918 8.11141 15.3511 7.97076C15.2105 7.83011 15.0197 7.75109 14.8208 7.75109Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.1"
                />
              </svg>
              <p>선택 복원</p>
            </button>
            <button
              onClick={() => props.binListDeleteHandle()}
              disabled={props.binAllBtnState || props.binRestoreState || props.binDeleteState}
              className="selectDeleteBtn"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                <path
                  d="M11.2603 11.8035C11.1431 11.9207 10.9842 11.9865 10.8184 11.9865C10.6526 11.9865 10.4937 11.9207 10.3764 11.8035L5.95703 7.38404L1.53761 11.8035C1.4204 11.9207 1.26143 11.9865 1.09567 11.9865C0.929912 11.9865 0.770941 11.9207 0.65373 11.8035C0.53652 11.6862 0.470672 11.5273 0.470672 11.3615C0.470672 11.1958 0.53652 11.0368 0.65373 10.9196L5.07315 6.50016L0.65373 2.08074C0.53652 1.96353 0.470672 1.80456 0.470672 1.6388C0.470672 1.47304 0.53652 1.31407 0.65373 1.19686C0.770941 1.07965 0.929912 1.0138 1.09567 1.0138C1.26143 1.0138 1.4204 1.07965 1.53761 1.19686L5.95703 5.61627L10.3764 1.19686C10.4937 1.07965 10.6526 1.0138 10.8184 1.0138C10.9842 1.0138 11.1431 1.07965 11.2603 1.19686C11.3775 1.31407 11.4434 1.47304 11.4434 1.6388C11.4434 1.80456 11.3775 1.96353 11.2603 2.08074L6.84091 6.50016L11.2603 10.9196C11.3775 11.0368 11.4434 11.1958 11.4434 11.3615C11.4434 11.5273 11.3775 11.6862 11.2603 11.8035Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.5"
                />
              </svg>
              <p>선택 삭제</p>
            </button>
          </>
        )}
      </S.TrashBoxFunBtnGroup>
      {/* 반응형 */}
      <S.TrashBoxResFunBtnGroup ref={props.layoutRef}>
        <button className="selectBtn" type="button" onClick={() => props.onClickMenuBtn()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path
              d="M15.5 8.5C15.5 8.66576 15.4342 8.82473 15.3169 8.94194C15.1997 9.05915 15.0408 9.125 14.875 9.125H8.625V15.375C8.625 15.5408 8.55915 15.6997 8.44194 15.8169C8.32473 15.9342 8.16576 16 8 16C7.83424 16 7.67527 15.9342 7.55806 15.8169C7.44085 15.6997 7.375 15.5408 7.375 15.375V9.125H1.125C0.95924 9.125 0.800269 9.05915 0.683058 8.94194C0.565848 8.82473 0.5 8.66576 0.5 8.5C0.5 8.33424 0.565848 8.17527 0.683058 8.05806C0.800269 7.94085 0.95924 7.875 1.125 7.875H7.375V1.625C7.375 1.45924 7.44085 1.30027 7.55806 1.18306C7.67527 1.06585 7.83424 1 8 1C8.16576 1 8.32473 1.06585 8.44194 1.18306C8.55915 1.30027 8.625 1.45924 8.625 1.625V7.875H14.875C15.0408 7.875 15.1997 7.94085 15.3169 8.05806C15.4342 8.17527 15.5 8.33424 15.5 8.5Z"
              fill="white"
              stroke="white"
              strokeWidth="0.5"
            />
          </svg>
          <p>버튼</p>
        </button>

        {props.resMenuSwitch ? (
          <S.SelectUl>
            <li>
              <button
                onClick={() => props.restoreBinAllListOnClick()}
                disabled={props.binAllBtnState || props.binRestoreState || props.binDeleteState}
                className="allRestoreBtn"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M14.8208 7.75109C14.6219 7.75109 14.4311 7.83011 14.2905 7.97076C14.1498 8.11141 14.0708 8.30218 14.0708 8.50109C14.0797 9.90586 13.5985 11.2698 12.71 12.3579C11.8216 13.4461 10.5814 14.1903 9.20326 14.4625C7.82509 14.7347 6.39513 14.5178 5.15967 13.8492C3.92422 13.1805 2.9606 12.102 2.43479 10.7993C1.90898 9.49659 1.85389 8.05133 2.27901 6.7124C2.70414 5.37347 3.58285 4.2247 4.76382 3.46393C5.94479 2.70316 7.35409 2.37803 8.74899 2.54453C10.1439 2.71103 11.4371 3.35873 12.4058 4.37609H10.6058C10.4069 4.37609 10.2161 4.45511 10.0755 4.59576C9.93481 4.73642 9.85579 4.92718 9.85579 5.12609C9.85579 5.325 9.93481 5.51577 10.0755 5.65642C10.2161 5.79707 10.4069 5.87609 10.6058 5.87609H14.0033C14.2022 5.87609 14.393 5.79707 14.5336 5.65642C14.6743 5.51577 14.7533 5.325 14.7533 5.12609V1.7511C14.7533 1.55219 14.6743 1.36142 14.5336 1.22077C14.393 1.08012 14.2022 1.0011 14.0033 1.0011C13.8044 1.0011 13.6136 1.08012 13.473 1.22077C13.3323 1.36142 13.2533 1.55219 13.2533 1.7511V3.0786C12.0043 1.88464 10.3814 1.15856 8.6588 1.0231C6.93626 0.887628 5.21977 1.35108 3.79949 2.33511C2.37921 3.31915 1.34226 4.7634 0.863924 6.42373C0.385589 8.08407 0.495211 9.85864 1.17426 11.4475C1.85331 13.0363 3.06013 14.342 4.59072 15.1437C6.12131 15.9455 7.88179 16.1941 9.57457 15.8477C11.2673 15.5012 12.7886 14.5809 13.8811 13.2423C14.9737 11.9037 15.5705 10.229 15.5708 8.50109C15.5708 8.30218 15.4918 8.11141 15.3511 7.97076C15.2105 7.83011 15.0197 7.75109 14.8208 7.75109Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.1"
                  />
                </svg>
                <p>
                  {props.binSuccessSearchS ? '검색 결과 전체 복원' : '전체 복원'}
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.85706 5.38005L5.85889 5.38191L5.31892 5.92952L5.31718 5.92775L1.55398 9.74553L1.01596 9.19962L4.77903 5.38176L0.999946 1.54762L1.53991 1L5.31889 4.83404L5.32086 4.83203L5.85889 5.37819L5.85706 5.38005Z"
                      fill="#B0B0B0"
                    />
                    <path
                      d="M5.85889 5.38191L6.03695 5.20642L6.20994 5.38195L6.0369 5.55744L5.85889 5.38191ZM5.85706 5.38005L5.679 5.55553L5.50603 5.38003L5.67902 5.20454L5.85706 5.38005ZM5.31892 5.92952L5.49694 6.10505L5.31888 6.28564L5.14086 6.10501L5.31892 5.92952ZM5.31718 5.92775L5.13914 5.75226L5.31719 5.57161L5.49524 5.75227L5.31718 5.92775ZM1.55398 9.74553L1.73202 9.92103L1.55396 10.1017L1.37592 9.92102L1.55398 9.74553ZM1.01596 9.19962L0.837896 9.3751L0.66494 9.19961L0.837906 9.02412L1.01596 9.19962ZM4.77903 5.38176L4.95708 5.20627L5.13005 5.38176L4.95707 5.55725L4.77903 5.38176ZM0.999946 1.54762L0.821896 1.72311L0.648889 1.54758L0.82193 1.37209L0.999946 1.54762ZM1.53991 1L1.36189 0.824472L1.53994 0.643897L1.71796 0.824506L1.53991 1ZM5.31889 4.83404L5.49694 5.00953L5.31889 5.19018L5.14084 5.00953L5.31889 4.83404ZM5.32086 4.83203L5.14281 4.65654L5.32091 4.47584L5.49896 4.65659L5.32086 4.83203ZM5.85889 5.37819L6.03699 5.20275L6.20988 5.37825L6.03692 5.5537L5.85889 5.37819ZM5.68083 5.55739L5.679 5.55553L6.03511 5.20456L6.03695 5.20642L5.68083 5.55739ZM5.14091 5.754L5.68087 5.20638L6.0369 5.55744L5.49694 6.10505L5.14091 5.754ZM5.49524 5.75227L5.49698 5.75404L5.14086 6.10501L5.13912 6.10324L5.49524 5.75227ZM1.37593 9.57004L5.13914 5.75226L5.49522 6.10325L1.73202 9.92103L1.37593 9.57004ZM1.19402 9.02413L1.73204 9.57005L1.37592 9.92102L0.837896 9.3751L1.19402 9.02413ZM4.95707 5.55725L1.19401 9.37511L0.837906 9.02412L4.60098 5.20626L4.95707 5.55725ZM1.178 1.37212L4.95708 5.20627L4.60097 5.55725L0.821896 1.72311L1.178 1.37212ZM1.71793 1.17553L1.17796 1.72315L0.82193 1.37209L1.36189 0.824472L1.71793 1.17553ZM5.14084 5.00953L1.36186 1.17549L1.71796 0.824506L5.49694 4.65854L5.14084 5.00953ZM5.49892 5.00752L5.49694 5.00953L5.14083 4.65855L5.14281 4.65654L5.49892 5.00752ZM5.68079 5.55364L5.14277 5.00748L5.49896 4.65659L6.03699 5.20275L5.68079 5.55364ZM5.67902 5.20454L5.68085 5.20268L6.03692 5.5537L6.03509 5.55556L5.67902 5.20454Z"
                      fill="#B0B0B0"
                    />
                  </svg>
                </p>
              </button>
            </li>
            <li>
              <button
                onClick={() => props.deleteBinAllListOnClick()}
                disabled={props.binAllBtnState || props.binRestoreState || props.binDeleteState}
                className="allDeleteBtn"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                  <path
                    d="M11.2603 11.8035C11.1431 11.9207 10.9842 11.9865 10.8184 11.9865C10.6526 11.9865 10.4937 11.9207 10.3764 11.8035L5.95703 7.38404L1.53761 11.8035C1.4204 11.9207 1.26143 11.9865 1.09567 11.9865C0.929912 11.9865 0.770941 11.9207 0.65373 11.8035C0.53652 11.6862 0.470672 11.5273 0.470672 11.3615C0.470672 11.1958 0.53652 11.0368 0.65373 10.9196L5.07315 6.50016L0.65373 2.08074C0.53652 1.96353 0.470672 1.80456 0.470672 1.6388C0.470672 1.47304 0.53652 1.31407 0.65373 1.19686C0.770941 1.07965 0.929912 1.0138 1.09567 1.0138C1.26143 1.0138 1.4204 1.07965 1.53761 1.19686L5.95703 5.61627L10.3764 1.19686C10.4937 1.07965 10.6526 1.0138 10.8184 1.0138C10.9842 1.0138 11.1431 1.07965 11.2603 1.19686C11.3775 1.31407 11.4434 1.47304 11.4434 1.6388C11.4434 1.80456 11.3775 1.96353 11.2603 2.08074L6.84091 6.50016L11.2603 10.9196C11.3775 11.0368 11.4434 11.1958 11.4434 11.3615C11.4434 11.5273 11.3775 11.6862 11.2603 11.8035Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                  />
                </svg>
                <p>
                  {props.binSuccessSearchS ? '검색 결과 전체 삭제' : '전체 삭제'}
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.85706 5.38005L5.85889 5.38191L5.31892 5.92952L5.31718 5.92775L1.55398 9.74553L1.01596 9.19962L4.77903 5.38176L0.999946 1.54762L1.53991 1L5.31889 4.83404L5.32086 4.83203L5.85889 5.37819L5.85706 5.38005Z"
                      fill="#B0B0B0"
                    />
                    <path
                      d="M5.85889 5.38191L6.03695 5.20642L6.20994 5.38195L6.0369 5.55744L5.85889 5.38191ZM5.85706 5.38005L5.679 5.55553L5.50603 5.38003L5.67902 5.20454L5.85706 5.38005ZM5.31892 5.92952L5.49694 6.10505L5.31888 6.28564L5.14086 6.10501L5.31892 5.92952ZM5.31718 5.92775L5.13914 5.75226L5.31719 5.57161L5.49524 5.75227L5.31718 5.92775ZM1.55398 9.74553L1.73202 9.92103L1.55396 10.1017L1.37592 9.92102L1.55398 9.74553ZM1.01596 9.19962L0.837896 9.3751L0.66494 9.19961L0.837906 9.02412L1.01596 9.19962ZM4.77903 5.38176L4.95708 5.20627L5.13005 5.38176L4.95707 5.55725L4.77903 5.38176ZM0.999946 1.54762L0.821896 1.72311L0.648889 1.54758L0.82193 1.37209L0.999946 1.54762ZM1.53991 1L1.36189 0.824472L1.53994 0.643897L1.71796 0.824506L1.53991 1ZM5.31889 4.83404L5.49694 5.00953L5.31889 5.19018L5.14084 5.00953L5.31889 4.83404ZM5.32086 4.83203L5.14281 4.65654L5.32091 4.47584L5.49896 4.65659L5.32086 4.83203ZM5.85889 5.37819L6.03699 5.20275L6.20988 5.37825L6.03692 5.5537L5.85889 5.37819ZM5.68083 5.55739L5.679 5.55553L6.03511 5.20456L6.03695 5.20642L5.68083 5.55739ZM5.14091 5.754L5.68087 5.20638L6.0369 5.55744L5.49694 6.10505L5.14091 5.754ZM5.49524 5.75227L5.49698 5.75404L5.14086 6.10501L5.13912 6.10324L5.49524 5.75227ZM1.37593 9.57004L5.13914 5.75226L5.49522 6.10325L1.73202 9.92103L1.37593 9.57004ZM1.19402 9.02413L1.73204 9.57005L1.37592 9.92102L0.837896 9.3751L1.19402 9.02413ZM4.95707 5.55725L1.19401 9.37511L0.837906 9.02412L4.60098 5.20626L4.95707 5.55725ZM1.178 1.37212L4.95708 5.20627L4.60097 5.55725L0.821896 1.72311L1.178 1.37212ZM1.71793 1.17553L1.17796 1.72315L0.82193 1.37209L1.36189 0.824472L1.71793 1.17553ZM5.14084 5.00953L1.36186 1.17549L1.71796 0.824506L5.49694 4.65854L5.14084 5.00953ZM5.49892 5.00752L5.49694 5.00953L5.14083 4.65855L5.14281 4.65654L5.49892 5.00752ZM5.68079 5.55364L5.14277 5.00748L5.49896 4.65659L6.03699 5.20275L5.68079 5.55364ZM5.67902 5.20454L5.68085 5.20268L6.03692 5.5537L6.03509 5.55556L5.67902 5.20454Z"
                      fill="#B0B0B0"
                    />
                  </svg>
                </p>
              </button>
            </li>
            <li>
              <button
                onClick={() => props.binListRestoreHandle()}
                disabled={props.binAllBtnState || props.binRestoreState || props.binDeleteState}
                className="selectRestoreBtn"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M14.8208 7.75109C14.6219 7.75109 14.4311 7.83011 14.2905 7.97076C14.1498 8.11141 14.0708 8.30218 14.0708 8.50109C14.0797 9.90586 13.5985 11.2698 12.71 12.3579C11.8216 13.4461 10.5814 14.1903 9.20326 14.4625C7.82509 14.7347 6.39513 14.5178 5.15967 13.8492C3.92422 13.1805 2.9606 12.102 2.43479 10.7993C1.90898 9.49659 1.85389 8.05133 2.27901 6.7124C2.70414 5.37347 3.58285 4.2247 4.76382 3.46393C5.94479 2.70316 7.35409 2.37803 8.74899 2.54453C10.1439 2.71103 11.4371 3.35873 12.4058 4.37609H10.6058C10.4069 4.37609 10.2161 4.45511 10.0755 4.59576C9.93481 4.73642 9.85579 4.92718 9.85579 5.12609C9.85579 5.325 9.93481 5.51577 10.0755 5.65642C10.2161 5.79707 10.4069 5.87609 10.6058 5.87609H14.0033C14.2022 5.87609 14.393 5.79707 14.5336 5.65642C14.6743 5.51577 14.7533 5.325 14.7533 5.12609V1.7511C14.7533 1.55219 14.6743 1.36142 14.5336 1.22077C14.393 1.08012 14.2022 1.0011 14.0033 1.0011C13.8044 1.0011 13.6136 1.08012 13.473 1.22077C13.3323 1.36142 13.2533 1.55219 13.2533 1.7511V3.0786C12.0043 1.88464 10.3814 1.15856 8.6588 1.0231C6.93626 0.887628 5.21977 1.35108 3.79949 2.33511C2.37921 3.31915 1.34226 4.7634 0.863924 6.42373C0.385589 8.08407 0.495211 9.85864 1.17426 11.4475C1.85331 13.0363 3.06013 14.342 4.59072 15.1437C6.12131 15.9455 7.88179 16.1941 9.57457 15.8477C11.2673 15.5012 12.7886 14.5809 13.8811 13.2423C14.9737 11.9037 15.5705 10.229 15.5708 8.50109C15.5708 8.30218 15.4918 8.11141 15.3511 7.97076C15.2105 7.83011 15.0197 7.75109 14.8208 7.75109Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.1"
                  />
                </svg>
                <p>
                  선택 복원
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.85706 5.38005L5.85889 5.38191L5.31892 5.92952L5.31718 5.92775L1.55398 9.74553L1.01596 9.19962L4.77903 5.38176L0.999946 1.54762L1.53991 1L5.31889 4.83404L5.32086 4.83203L5.85889 5.37819L5.85706 5.38005Z"
                      fill="#B0B0B0"
                    />
                    <path
                      d="M5.85889 5.38191L6.03695 5.20642L6.20994 5.38195L6.0369 5.55744L5.85889 5.38191ZM5.85706 5.38005L5.679 5.55553L5.50603 5.38003L5.67902 5.20454L5.85706 5.38005ZM5.31892 5.92952L5.49694 6.10505L5.31888 6.28564L5.14086 6.10501L5.31892 5.92952ZM5.31718 5.92775L5.13914 5.75226L5.31719 5.57161L5.49524 5.75227L5.31718 5.92775ZM1.55398 9.74553L1.73202 9.92103L1.55396 10.1017L1.37592 9.92102L1.55398 9.74553ZM1.01596 9.19962L0.837896 9.3751L0.66494 9.19961L0.837906 9.02412L1.01596 9.19962ZM4.77903 5.38176L4.95708 5.20627L5.13005 5.38176L4.95707 5.55725L4.77903 5.38176ZM0.999946 1.54762L0.821896 1.72311L0.648889 1.54758L0.82193 1.37209L0.999946 1.54762ZM1.53991 1L1.36189 0.824472L1.53994 0.643897L1.71796 0.824506L1.53991 1ZM5.31889 4.83404L5.49694 5.00953L5.31889 5.19018L5.14084 5.00953L5.31889 4.83404ZM5.32086 4.83203L5.14281 4.65654L5.32091 4.47584L5.49896 4.65659L5.32086 4.83203ZM5.85889 5.37819L6.03699 5.20275L6.20988 5.37825L6.03692 5.5537L5.85889 5.37819ZM5.68083 5.55739L5.679 5.55553L6.03511 5.20456L6.03695 5.20642L5.68083 5.55739ZM5.14091 5.754L5.68087 5.20638L6.0369 5.55744L5.49694 6.10505L5.14091 5.754ZM5.49524 5.75227L5.49698 5.75404L5.14086 6.10501L5.13912 6.10324L5.49524 5.75227ZM1.37593 9.57004L5.13914 5.75226L5.49522 6.10325L1.73202 9.92103L1.37593 9.57004ZM1.19402 9.02413L1.73204 9.57005L1.37592 9.92102L0.837896 9.3751L1.19402 9.02413ZM4.95707 5.55725L1.19401 9.37511L0.837906 9.02412L4.60098 5.20626L4.95707 5.55725ZM1.178 1.37212L4.95708 5.20627L4.60097 5.55725L0.821896 1.72311L1.178 1.37212ZM1.71793 1.17553L1.17796 1.72315L0.82193 1.37209L1.36189 0.824472L1.71793 1.17553ZM5.14084 5.00953L1.36186 1.17549L1.71796 0.824506L5.49694 4.65854L5.14084 5.00953ZM5.49892 5.00752L5.49694 5.00953L5.14083 4.65855L5.14281 4.65654L5.49892 5.00752ZM5.68079 5.55364L5.14277 5.00748L5.49896 4.65659L6.03699 5.20275L5.68079 5.55364ZM5.67902 5.20454L5.68085 5.20268L6.03692 5.5537L6.03509 5.55556L5.67902 5.20454Z"
                      fill="#B0B0B0"
                    />
                  </svg>
                </p>
              </button>
            </li>
            <li>
              <button
                onClick={() => props.binListDeleteHandle()}
                disabled={props.binAllBtnState || props.binRestoreState || props.binDeleteState}
                className="selectDeleteBtn"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                  <path
                    d="M11.2603 11.8035C11.1431 11.9207 10.9842 11.9865 10.8184 11.9865C10.6526 11.9865 10.4937 11.9207 10.3764 11.8035L5.95703 7.38404L1.53761 11.8035C1.4204 11.9207 1.26143 11.9865 1.09567 11.9865C0.929912 11.9865 0.770941 11.9207 0.65373 11.8035C0.53652 11.6862 0.470672 11.5273 0.470672 11.3615C0.470672 11.1958 0.53652 11.0368 0.65373 10.9196L5.07315 6.50016L0.65373 2.08074C0.53652 1.96353 0.470672 1.80456 0.470672 1.6388C0.470672 1.47304 0.53652 1.31407 0.65373 1.19686C0.770941 1.07965 0.929912 1.0138 1.09567 1.0138C1.26143 1.0138 1.4204 1.07965 1.53761 1.19686L5.95703 5.61627L10.3764 1.19686C10.4937 1.07965 10.6526 1.0138 10.8184 1.0138C10.9842 1.0138 11.1431 1.07965 11.2603 1.19686C11.3775 1.31407 11.4434 1.47304 11.4434 1.6388C11.4434 1.80456 11.3775 1.96353 11.2603 2.08074L6.84091 6.50016L11.2603 10.9196C11.3775 11.0368 11.4434 11.1958 11.4434 11.3615C11.4434 11.5273 11.3775 11.6862 11.2603 11.8035Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                  />
                </svg>
                <p>
                  선택 삭제
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.85706 5.38005L5.85889 5.38191L5.31892 5.92952L5.31718 5.92775L1.55398 9.74553L1.01596 9.19962L4.77903 5.38176L0.999946 1.54762L1.53991 1L5.31889 4.83404L5.32086 4.83203L5.85889 5.37819L5.85706 5.38005Z"
                      fill="#B0B0B0"
                    />
                    <path
                      d="M5.85889 5.38191L6.03695 5.20642L6.20994 5.38195L6.0369 5.55744L5.85889 5.38191ZM5.85706 5.38005L5.679 5.55553L5.50603 5.38003L5.67902 5.20454L5.85706 5.38005ZM5.31892 5.92952L5.49694 6.10505L5.31888 6.28564L5.14086 6.10501L5.31892 5.92952ZM5.31718 5.92775L5.13914 5.75226L5.31719 5.57161L5.49524 5.75227L5.31718 5.92775ZM1.55398 9.74553L1.73202 9.92103L1.55396 10.1017L1.37592 9.92102L1.55398 9.74553ZM1.01596 9.19962L0.837896 9.3751L0.66494 9.19961L0.837906 9.02412L1.01596 9.19962ZM4.77903 5.38176L4.95708 5.20627L5.13005 5.38176L4.95707 5.55725L4.77903 5.38176ZM0.999946 1.54762L0.821896 1.72311L0.648889 1.54758L0.82193 1.37209L0.999946 1.54762ZM1.53991 1L1.36189 0.824472L1.53994 0.643897L1.71796 0.824506L1.53991 1ZM5.31889 4.83404L5.49694 5.00953L5.31889 5.19018L5.14084 5.00953L5.31889 4.83404ZM5.32086 4.83203L5.14281 4.65654L5.32091 4.47584L5.49896 4.65659L5.32086 4.83203ZM5.85889 5.37819L6.03699 5.20275L6.20988 5.37825L6.03692 5.5537L5.85889 5.37819ZM5.68083 5.55739L5.679 5.55553L6.03511 5.20456L6.03695 5.20642L5.68083 5.55739ZM5.14091 5.754L5.68087 5.20638L6.0369 5.55744L5.49694 6.10505L5.14091 5.754ZM5.49524 5.75227L5.49698 5.75404L5.14086 6.10501L5.13912 6.10324L5.49524 5.75227ZM1.37593 9.57004L5.13914 5.75226L5.49522 6.10325L1.73202 9.92103L1.37593 9.57004ZM1.19402 9.02413L1.73204 9.57005L1.37592 9.92102L0.837896 9.3751L1.19402 9.02413ZM4.95707 5.55725L1.19401 9.37511L0.837906 9.02412L4.60098 5.20626L4.95707 5.55725ZM1.178 1.37212L4.95708 5.20627L4.60097 5.55725L0.821896 1.72311L1.178 1.37212ZM1.71793 1.17553L1.17796 1.72315L0.82193 1.37209L1.36189 0.824472L1.71793 1.17553ZM5.14084 5.00953L1.36186 1.17549L1.71796 0.824506L5.49694 4.65854L5.14084 5.00953ZM5.49892 5.00752L5.49694 5.00953L5.14083 4.65855L5.14281 4.65654L5.49892 5.00752ZM5.68079 5.55364L5.14277 5.00748L5.49896 4.65659L6.03699 5.20275L5.68079 5.55364ZM5.67902 5.20454L5.68085 5.20268L6.03692 5.5537L6.03509 5.55556L5.67902 5.20454Z"
                      fill="#B0B0B0"
                    />
                  </svg>
                </p>
              </button>
            </li>
          </S.SelectUl>
        ) : null}
      </S.TrashBoxResFunBtnGroup>
    </div>
  );
};
export default TrashBoxBtnGroup;
