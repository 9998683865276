import Logo from '@/components/Atom/Logo';
import { use015ARSMock } from '@/components/hooks/customs/say015/say015ARS/use015ARSMock';
import React from 'react';
import * as SSS from '@/widgets/ars/components/arsTree/ui/ArsTree.styles';
import * as SS from '@/widgets/ars/components/arsTree/components/arsCardModal/ui/ArsCardModal.styles';
import * as S from '@/widgets/ars/components/arsMock/ui/ArsMock.styles';
import ArsCardModal from '@/components/say015/Modal/arsCards/ArsCardModal';
import ArrowIcon from '@/components/say015/Modal/icons/ArrowIcon';
import ArsMockLeftNavComponent from '../components/ArsMockLeftNav/ArsMockLeftNav';

const ArsMockFeatures = () => {
  const arsMockHooks = use015ARSMock();

  return (
    // 015 목업
    <S.Say015MockWrap className="say015MockWrap ">
      <Logo eType="Home" />
      <SSS.Wrapper className="wrapper">
        {/* 좌측 위젯 */}
        <ArsMockLeftNavComponent
          arsLeftActive={arsMockHooks.arsLeftActive}
          onClickArsLeftClick={arsMockHooks.onClickArsLeftClick}
          join015ToggleS={arsMockHooks.join015ToggleS}
          onClickArsStartBtn={arsMockHooks.onClickArsStartBtn}
        />
        {/* 메인 위젯 */}
        <SSS.Contents className="contents">
          <div className="topGroup marB20">
            <h3>
              <span>요식업 사장님들은 015 음성사서함을</span>
              <span>이렇게 이용하고 있어요</span>
            </h3>
            <span className="line" />
            <button onClick={() => arsMockHooks.onClickArsMockClose()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                <path
                  d="M18.8388 18.5165C18.6435 18.7119 18.3785 18.8216 18.1023 18.8216C17.826 18.8216 17.561 18.7119 17.3657 18.5165L10 11.1508L2.6343 18.5165C2.43895 18.7119 2.174 18.8216 1.89774 18.8216C1.62147 18.8216 1.35652 18.7119 1.16117 18.5165C0.965815 18.3212 0.856068 18.0562 0.856069 17.7799C0.856069 17.5037 0.965815 17.2387 1.16117 17.0434L8.52686 9.67767L1.16117 2.31197C0.965815 2.11662 0.856068 1.85167 0.856068 1.5754C0.856068 1.29914 0.965815 1.03418 1.16117 0.838835C1.35652 0.643484 1.62147 0.533737 1.89773 0.533738C2.174 0.533738 2.43895 0.643484 2.6343 0.838834L10 8.20453L17.3657 0.838834C17.561 0.643484 17.826 0.533738 18.1023 0.533738C18.3785 0.533737 18.6435 0.643484 18.8388 0.838835C19.0342 1.03418 19.1439 1.29914 19.1439 1.5754C19.1439 1.85167 19.0342 2.11662 18.8388 2.31197L11.4731 9.67767L18.8388 17.0434C19.0342 17.2387 19.1439 17.5037 19.1439 17.7799C19.1439 18.0562 19.0342 18.3212 18.8388 18.5165Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          {/* @TODO 아래 컨텐츠 부분도 분할해야함 우선 실제 사용 ARS 부터 시작 - 정휘학  */}
          <SSS.ArsGroup className="arsGroup">
            <ol>
              <li>
                <div>
                  <SS.ArsCardWrap
                    className={arsMockHooks.isPlayS.intro ? 'active' : ''}
                    onClick={() => arsMockHooks.onClickPlayPauseBtn('intro')}
                  >
                    <ArsCardModal content="인사말" type={'mock'} />
                  </SS.ArsCardWrap>
                  <ArrowIcon />
                </div>
                <ul>
                  {/* TAB - 1 음성 재생 */}
                  <li>
                    <div>
                      <p>1</p>
                      <SS.ArsCardWrap
                        className={arsMockHooks.isPlayS.choice1 ? 'active' : ''}
                        onClick={() => arsMockHooks.onClickPlayPauseBtn('choice1')}
                      >
                        <div className="txtTop">
                          <div className="group">
                            {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
                            <p className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="25"
                                viewBox="0 0 26 25"
                                fill="none"
                              >
                                <path
                                  d="M19.7889 1.68185C19.8955 1.50422 20.0684 1.37622 20.2694 1.32602C20.4704 1.27582 20.6831 1.30751 20.8608 1.41414L20.8639 1.41623L20.867 1.41831L20.8774 1.42456C20.9181 1.44923 20.9577 1.47564 20.9962 1.50373C21.0691 1.55477 21.168 1.62873 21.2847 1.72456C21.516 1.91727 21.818 2.20581 22.1201 2.59748C22.7264 3.38602 23.3233 4.59123 23.3233 6.2506C23.3233 7.90998 22.7264 9.11518 22.1201 9.90373C21.818 10.2954 21.516 10.5839 21.2847 10.7766C21.1552 10.8845 21.0196 10.9846 20.8785 11.0766L20.868 11.0829L20.8639 11.085L20.8618 11.086C20.8618 11.086 20.8608 11.0871 20.4587 10.4173L20.8608 11.0871C20.6838 11.1875 20.4747 11.2149 20.2778 11.1635C20.081 11.1121 19.912 10.986 19.8067 10.8119C19.7014 10.6378 19.6681 10.4296 19.714 10.2314C19.7599 10.0332 19.8813 9.86068 20.0524 9.7506L20.0576 9.74643L20.0993 9.71831C20.141 9.68914 20.2045 9.64227 20.2837 9.57664C20.443 9.44331 20.6618 9.23706 20.8805 8.95164C21.316 8.38602 21.7608 7.50789 21.7608 6.2506C21.7608 4.99331 21.316 4.11518 20.8816 3.54956C20.7057 3.32003 20.5052 3.11045 20.2837 2.92456C20.2112 2.86417 20.1358 2.8075 20.0576 2.75477L20.0524 2.7506C19.8763 2.64329 19.7498 2.47073 19.7005 2.27052C19.6511 2.07032 19.6829 1.85872 19.7889 1.68185Z"
                                  fill="currentColor"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.0837 1.30273C9.77139 1.30273 8.51287 1.82403 7.58495 2.75195C6.65704 3.67986 6.13574 4.93838 6.13574 6.25065C6.13574 7.56292 6.65704 8.82144 7.58495 9.74936C8.51287 10.6773 9.77139 11.1986 11.0837 11.1986C12.3959 11.1986 13.6545 10.6773 14.5824 9.74936C15.5103 8.82144 16.0316 7.56292 16.0316 6.25065C16.0316 4.93838 15.5103 3.67986 14.5824 2.75195C13.6545 1.82403 12.3959 1.30273 11.0837 1.30273ZM7.69824 6.25065C7.69824 5.35278 8.05492 4.49169 8.68981 3.8568C9.3247 3.22191 10.1858 2.86523 11.0837 2.86523C11.9815 2.86523 12.8426 3.22191 13.4775 3.8568C14.1124 4.49169 14.4691 5.35278 14.4691 6.25065C14.4691 7.14852 14.1124 8.00961 13.4775 8.6445C12.8426 9.27939 11.9815 9.63607 11.0837 9.63607C10.1858 9.63607 9.3247 9.27939 8.68981 8.6445C8.05492 8.00961 7.69824 7.14852 7.69824 6.25065ZM11.0837 12.7611C8.95866 12.7611 6.99824 13.2507 5.53991 14.084C4.10449 14.9048 3.01074 16.1569 3.01074 17.709C3.01074 19.2611 4.10449 20.5131 5.53991 21.334C6.99824 22.1673 8.9597 22.6569 11.0837 22.6569C13.2087 22.6569 15.1691 22.1673 16.6274 21.334C18.0628 20.5131 19.1566 19.2611 19.1566 17.709C19.1566 16.1569 18.0628 14.9048 16.6274 14.084C15.1691 13.2507 13.2076 12.7611 11.0837 12.7611ZM4.57324 17.709C4.57324 16.959 5.11178 16.1288 6.31491 15.4413C7.49616 14.7663 9.18053 14.3236 11.0837 14.3236C12.9868 14.3236 14.6712 14.7663 15.8524 15.4413C17.0555 16.1288 17.5941 16.96 17.5941 17.709C17.5941 18.459 17.0555 19.2892 15.8524 19.9767C14.6712 20.6517 12.9868 21.0944 11.0837 21.0944C9.18053 21.0944 7.49616 20.6517 6.31491 19.9767C5.11178 19.2892 4.57324 18.459 4.57324 17.709Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M18.7779 3.49663C18.6899 3.44367 18.5923 3.40858 18.4907 3.39338C18.3891 3.37817 18.2856 3.38314 18.1859 3.40801C18.0863 3.43287 17.9925 3.47714 17.91 3.53829C17.8274 3.59943 17.7578 3.67625 17.705 3.76434L17.9654 4.83101L17.9727 4.83622C18.0876 4.92263 18.1903 5.02425 18.2779 5.1383C18.5196 5.45761 18.6459 5.84941 18.6362 6.24976C18.6362 6.77788 18.4518 7.13517 18.2779 7.36226C18.1903 7.4763 18.0876 7.57792 17.9727 7.66434L17.9643 7.66851C17.7911 7.77802 17.6679 7.95119 17.6212 8.15071C17.5744 8.35024 17.608 8.56013 17.7146 8.73516C17.8211 8.91018 17.9922 9.03633 18.1909 9.08641C18.3896 9.13649 18.6 9.10649 18.7768 9.00288H18.7789L18.781 9.0008L18.7841 8.99976L18.7914 8.99455L18.8123 8.98205C18.8916 8.93132 18.9671 8.87492 19.0383 8.8133C19.1727 8.70184 19.3446 8.53726 19.5164 8.31434C19.9682 7.72176 20.2085 6.99487 20.1987 6.24976C20.2085 5.50464 19.9682 4.77776 19.5164 4.18517C19.3317 3.94549 19.1135 3.7336 18.8685 3.55601C18.85 3.54284 18.8312 3.52999 18.8123 3.51747L18.7914 3.50497L18.7841 3.49976L18.781 3.49872L18.7789 3.49767C18.7789 3.49767 18.7779 3.49663 18.3758 4.16642L18.7779 3.49663Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </p>
                            <h3 className="txt">음성 재생</h3>
                          </div>
                        </div>
                        <div className="list">
                          <p>
                            영업시간 안내입니다. 저희 매장은 월요일부터 일요일까지 오전 10시부터 오후 10시까지
                            영업합니다.
                          </p>
                        </div>
                      </SS.ArsCardWrap>
                    </div>
                  </li>
                  {/* TAB - 2 문자발송 */}
                  <li>
                    <div>
                      <p>2</p>
                      <SS.ArsCardWrap
                        className={arsMockHooks.isPlayS.choice2 ? 'active' : ''}
                        onClick={() => arsMockHooks.onClickArsMsgBtn()}
                      >
                        <div>
                          <div className="txtTop">
                            <div className="group">
                              {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
                              <p className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <path
                                    d="M9.00033 9.40365H17.3337M9.00033 13.5703H15.2503M14.2087 18.7786L9.00033 21.9036V18.7786H6.91699C6.08819 18.7786 5.29333 18.4494 4.70728 17.8634C4.12123 17.2773 3.79199 16.4824 3.79199 15.6536V7.32031C3.79199 6.49151 4.12123 5.69666 4.70728 5.1106C5.29333 4.52455 6.08819 4.19531 6.91699 4.19531H19.417C20.2458 4.19531 21.0406 4.52455 21.6267 5.1106C22.2128 5.69666 22.542 6.49151 22.542 7.32031V13.5703M17.3337 22.9453L22.542 17.737M22.542 17.737V22.4245M22.542 17.737H17.8545"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </p>
                              <h3 className="txt">문자발송</h3>
                            </div>
                          </div>
                          <div className="list">
                            <p>
                              영업시간 안내입니다. 저희 매장은 월요일부터 일요일까지 오전 10시부터 오후 10시까지
                              영업합니다.
                            </p>
                          </div>
                        </div>
                      </SS.ArsCardWrap>
                    </div>
                  </li>
                  {/* TAB - 3 음성 녹음 받기 */}
                  <li>
                    <div>
                      <p>3</p>
                      <SS.ArsCardWrap
                        className={arsMockHooks.isPlayS.choice3 ? 'active' : ''}
                        onClick={() => arsMockHooks.onClickPlayPauseBtn('choice3')}
                      >
                        <div className="txtTop">
                          <div className="group">
                            {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
                            <p className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_482_26144)">
                                  <path
                                    d="M8.99988 25.0579C9.5728 25.0579 10.0416 24.5892 10.0416 24.0163C10.0416 23.4434 9.5728 22.9746 8.99988 22.9746C8.42697 22.9746 7.95822 23.4434 7.95822 24.0163C7.95822 24.5892 8.42697 25.0579 8.99988 25.0579ZM13.1666 25.0579C13.7395 25.0579 14.2082 24.5892 14.2082 24.0163C14.2082 23.4434 13.7395 22.9746 13.1666 22.9746C12.5936 22.9746 12.1249 23.4434 12.1249 24.0163C12.1249 24.5892 12.5936 25.0579 13.1666 25.0579ZM17.3332 25.0579C17.9061 25.0579 18.3749 24.5892 18.3749 24.0163C18.3749 23.4434 17.9061 22.9746 17.3332 22.9746C16.7603 22.9746 16.2916 23.4434 16.2916 24.0163C16.2916 24.5892 16.7603 25.0579 17.3332 25.0579ZM10.0416 10.4746V4.22461C10.0416 2.49544 11.4374 1.09961 13.1666 1.09961C14.8957 1.09961 16.2916 2.49544 16.2916 4.22461V10.4746C16.2916 12.2038 14.8957 13.5996 13.1666 13.5996C11.4374 13.5996 10.0416 12.2038 10.0416 10.4746ZM19.3228 10.4746C19.9582 10.4746 20.4582 11.0371 20.3645 11.6621C19.854 14.7871 17.354 17.2246 14.2082 17.6829V19.8496C14.2082 20.4225 13.7395 20.8913 13.1666 20.8913C12.5936 20.8913 12.1249 20.4225 12.1249 19.8496V17.6829C8.97905 17.2246 6.47905 14.7871 5.96863 11.6621C5.86447 11.0371 6.37488 10.4746 7.0103 10.4746C7.52072 10.4746 7.9478 10.8496 8.03113 11.36C8.45822 13.8184 10.5936 15.6829 13.1666 15.6829C15.7395 15.6829 17.8749 13.8184 18.302 11.36C18.3853 10.8496 18.8124 10.4746 19.3228 10.4746Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </svg>
                            </p>
                            <h3 className="txt">음성 녹음 받기</h3>
                          </div>
                        </div>
                        <div className="list">
                          {/* <p>메뉴 안내입니다. 메뉴 정보를 문자로 발송했습니다. 감사합니다.</p> */}
                        </div>
                      </SS.ArsCardWrap>
                    </div>
                  </li>
                  {/* TAB - 4 음성 재생 */}
                  <li>
                    <div>
                      <p>4</p>
                      <SS.ArsCardWrap
                        className={arsMockHooks.isPlayS.choice4 ? 'active' : ''}
                        onClick={() => arsMockHooks.onClickPlayPauseBtn('choice4')}
                      >
                        <div className="txtTop">
                          <div className="group">
                            {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
                            <p className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="25"
                                viewBox="0 0 26 25"
                                fill="none"
                              >
                                <path
                                  d="M19.7889 1.68185C19.8955 1.50422 20.0684 1.37622 20.2694 1.32602C20.4704 1.27582 20.6831 1.30751 20.8608 1.41414L20.8639 1.41623L20.867 1.41831L20.8774 1.42456C20.9181 1.44923 20.9577 1.47564 20.9962 1.50373C21.0691 1.55477 21.168 1.62873 21.2847 1.72456C21.516 1.91727 21.818 2.20581 22.1201 2.59748C22.7264 3.38602 23.3233 4.59123 23.3233 6.2506C23.3233 7.90998 22.7264 9.11518 22.1201 9.90373C21.818 10.2954 21.516 10.5839 21.2847 10.7766C21.1552 10.8845 21.0196 10.9846 20.8785 11.0766L20.868 11.0829L20.8639 11.085L20.8618 11.086C20.8618 11.086 20.8608 11.0871 20.4587 10.4173L20.8608 11.0871C20.6838 11.1875 20.4747 11.2149 20.2778 11.1635C20.081 11.1121 19.912 10.986 19.8067 10.8119C19.7014 10.6378 19.6681 10.4296 19.714 10.2314C19.7599 10.0332 19.8813 9.86068 20.0524 9.7506L20.0576 9.74643L20.0993 9.71831C20.141 9.68914 20.2045 9.64227 20.2837 9.57664C20.443 9.44331 20.6618 9.23706 20.8805 8.95164C21.316 8.38602 21.7608 7.50789 21.7608 6.2506C21.7608 4.99331 21.316 4.11518 20.8816 3.54956C20.7057 3.32003 20.5052 3.11045 20.2837 2.92456C20.2112 2.86417 20.1358 2.8075 20.0576 2.75477L20.0524 2.7506C19.8763 2.64329 19.7498 2.47073 19.7005 2.27052C19.6511 2.07032 19.6829 1.85872 19.7889 1.68185Z"
                                  fill="currentColor"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.0837 1.30273C9.77139 1.30273 8.51287 1.82403 7.58495 2.75195C6.65704 3.67986 6.13574 4.93838 6.13574 6.25065C6.13574 7.56292 6.65704 8.82144 7.58495 9.74936C8.51287 10.6773 9.77139 11.1986 11.0837 11.1986C12.3959 11.1986 13.6545 10.6773 14.5824 9.74936C15.5103 8.82144 16.0316 7.56292 16.0316 6.25065C16.0316 4.93838 15.5103 3.67986 14.5824 2.75195C13.6545 1.82403 12.3959 1.30273 11.0837 1.30273ZM7.69824 6.25065C7.69824 5.35278 8.05492 4.49169 8.68981 3.8568C9.3247 3.22191 10.1858 2.86523 11.0837 2.86523C11.9815 2.86523 12.8426 3.22191 13.4775 3.8568C14.1124 4.49169 14.4691 5.35278 14.4691 6.25065C14.4691 7.14852 14.1124 8.00961 13.4775 8.6445C12.8426 9.27939 11.9815 9.63607 11.0837 9.63607C10.1858 9.63607 9.3247 9.27939 8.68981 8.6445C8.05492 8.00961 7.69824 7.14852 7.69824 6.25065ZM11.0837 12.7611C8.95866 12.7611 6.99824 13.2507 5.53991 14.084C4.10449 14.9048 3.01074 16.1569 3.01074 17.709C3.01074 19.2611 4.10449 20.5131 5.53991 21.334C6.99824 22.1673 8.9597 22.6569 11.0837 22.6569C13.2087 22.6569 15.1691 22.1673 16.6274 21.334C18.0628 20.5131 19.1566 19.2611 19.1566 17.709C19.1566 16.1569 18.0628 14.9048 16.6274 14.084C15.1691 13.2507 13.2076 12.7611 11.0837 12.7611ZM4.57324 17.709C4.57324 16.959 5.11178 16.1288 6.31491 15.4413C7.49616 14.7663 9.18053 14.3236 11.0837 14.3236C12.9868 14.3236 14.6712 14.7663 15.8524 15.4413C17.0555 16.1288 17.5941 16.96 17.5941 17.709C17.5941 18.459 17.0555 19.2892 15.8524 19.9767C14.6712 20.6517 12.9868 21.0944 11.0837 21.0944C9.18053 21.0944 7.49616 20.6517 6.31491 19.9767C5.11178 19.2892 4.57324 18.459 4.57324 17.709Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M18.7779 3.49663C18.6899 3.44367 18.5923 3.40858 18.4907 3.39338C18.3891 3.37817 18.2856 3.38314 18.1859 3.40801C18.0863 3.43287 17.9925 3.47714 17.91 3.53829C17.8274 3.59943 17.7578 3.67625 17.705 3.76434L17.9654 4.83101L17.9727 4.83622C18.0876 4.92263 18.1903 5.02425 18.2779 5.1383C18.5196 5.45761 18.6459 5.84941 18.6362 6.24976C18.6362 6.77788 18.4518 7.13517 18.2779 7.36226C18.1903 7.4763 18.0876 7.57792 17.9727 7.66434L17.9643 7.66851C17.7911 7.77802 17.6679 7.95119 17.6212 8.15071C17.5744 8.35024 17.608 8.56013 17.7146 8.73516C17.8211 8.91018 17.9922 9.03633 18.1909 9.08641C18.3896 9.13649 18.6 9.10649 18.7768 9.00288H18.7789L18.781 9.0008L18.7841 8.99976L18.7914 8.99455L18.8123 8.98205C18.8916 8.93132 18.9671 8.87492 19.0383 8.8133C19.1727 8.70184 19.3446 8.53726 19.5164 8.31434C19.9682 7.72176 20.2085 6.99487 20.1987 6.24976C20.2085 5.50464 19.9682 4.77776 19.5164 4.18517C19.3317 3.94549 19.1135 3.7336 18.8685 3.55601C18.85 3.54284 18.8312 3.52999 18.8123 3.51747L18.7914 3.50497L18.7841 3.49976L18.781 3.49872L18.7789 3.49767C18.7789 3.49767 18.7779 3.49663 18.3758 4.16642L18.7779 3.49663Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </p>
                            <h3 className="txt">음성 재생</h3>
                          </div>
                        </div>
                        <div className="list">
                          <p>매장은 서울특별시 000에 위치해있습니다. 전용 추차장이 마련되어 있습니다.</p>
                        </div>
                      </SS.ArsCardWrap>
                    </div>
                  </li>
                  {/* TAB - 5 착신전환 */}
                  <li>
                    <div>
                      <p>5</p>
                      <SS.ArsCardWrap
                        className={arsMockHooks.isPlayS.calling ? 'active' : ''}
                        onClick={() => arsMockHooks.onClickPlayPauseBtn('calling')}
                      >
                        <div className="txtTop">
                          <div className="group">
                            {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
                            <p className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                              >
                                <path
                                  d="M16.292 9.49284L20.4587 5.32617M20.4587 5.32617V8.45117M20.4587 5.32617H17.3337"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.871 15.2714L16.3971 15.7714C16.3971 15.7714 15.2689 16.9579 12.1908 13.7172C9.11268 10.4766 10.2408 9.29015 10.2408 9.29015L10.5398 8.97453C11.2752 8.19953 11.345 6.95578 10.7023 6.04745L9.38976 4.19015C8.59393 3.06515 7.05747 2.91724 6.14601 3.87661L4.51163 5.5964C4.06059 6.07245 3.75851 6.68807 3.79497 7.37245C3.88872 9.12245 4.63663 12.886 8.80747 17.2787C13.2314 21.9349 17.3825 22.1204 19.0793 21.9527C19.6168 21.9006 20.0835 21.6099 20.4596 21.2141L21.9387 19.6558C22.9387 18.6037 22.6575 16.8016 21.3783 16.0662L19.3887 14.9204C18.5491 14.4381 17.5273 14.5797 16.871 15.2704"
                                  fill="currentColor"
                                />
                              </svg>
                            </p>
                            <h3 className="txt">착신 전환</h3>
                          </div>
                        </div>
                        <div className="list">
                          <p>010-0000-0000</p>
                        </div>
                      </SS.ArsCardWrap>
                    </div>
                  </li>
                </ul>
              </li>
            </ol>
          </SSS.ArsGroup>
        </SSS.Contents>
      </SSS.Wrapper>
    </S.Say015MockWrap>
  );
};
export default ArsMockFeatures;
