import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @title easy pay data return 값 받아오는 Hook
 *
 * @author 김남규
 * @since 2024.10.22
 * */
export const useEasy = () => {
  const [easyData, seteasyData] = useState<any>(null);

  // 페이지 리다이렉트 형식일 경우 사용할 로직 start --
  const location = useLocation();
  const sEasyData = location.state?.easyData;

  useEffect(() => {
    if (sEasyData) {
      seteasyData(sEasyData);
    }
  }, [sEasyData]);

  // -- end

  /**
   * @title easy pay window.opener.postMessage 이벤트로 부터 받아온 값을 저장하는 Hook
   * */
  const messageListener = (event: any) => {
    if (event.data.easyData) {
      seteasyData(event.data.easyData);
    }
  };

  /**
   * @title easy pay 결제창 완료 컴포넌트의 로직인 window.opener.postMessage 을 통해 데이터 가져오는 useEffect
   *
   * @author 김남규
   * @since 2024.10.22
   * */
  useEffect(() => {
    // message 이벤트 리스너를 등록
    window.addEventListener('message', messageListener);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  return {
    easyData,
  };
};
