import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 0.8em;
  height: 1em;
`;

const IconBell: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 18 22"
      fill="none"
    >
      <path
        d="M2.77857 15.8837H15.223V9.40093C15.223 5.78884 12.4372 2.86047 9.00079 2.86047C5.56435 2.86047 2.77857 5.78884 2.77857 9.40093V15.8837ZM9.00079 1C13.4186 1 17.0008 4.76093 17.0008 9.40093V17.7442H1.00079V9.40093C1.00079 4.76093 4.58302 1 9.00079 1ZM6.77857 18.6744H11.223C11.223 19.2912 10.9889 19.8827 10.5721 20.3189C10.1554 20.755 9.59016 21 9.00079 21C8.41142 21 7.84619 20.755 7.42945 20.3189C7.0127 19.8827 6.77857 19.2912 6.77857 18.6744Z"
        fill={fill ?? 'currentColor'}
        stroke={stroke ?? 'currentColor'}
        strokeWidth="0.2"
      />
    </Svg>
  );
};

export default IconBell;
