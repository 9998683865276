import { useGetBuddySeqNo } from '@/components/hooks/customs/commons/useGetBuddySeqNo';
import { useAddressBookMutations } from '@/components/hooks/mutations/addressBook/useAddressBookMutations';
import { useModalHook } from '@/components/commons/modals/useModalHook';

/**
 * @title 주소록 개별삭제 관련 Hooks
 *
 * @author 김남규
 * @since 2024.03.22
 **/
export const useDeleteTableList = () => {
  const { getBuddySeqNosToMainTableCheckDataFormatRecoil } = useGetBuddySeqNo(); // 주소록 그룹 시퀀스
  const { deletedM } = useAddressBookMutations(); // 주소록 삭제 뮤테이션
  const { confirmModal } = useModalHook(); // 모달
  const onDeleteTableList = () => {
    confirmModal(
      async () => {
        const deleteBuddySeqNoArr = await getBuddySeqNosToMainTableCheckDataFormatRecoil();
        deletedM(deleteBuddySeqNoArr);
      },
      '주소록 삭제',
      '선택하신 주소록을 삭제하시겠습니까?',
      true,
    );
  };

  return {
    onDeleteTableList,
  };
};
