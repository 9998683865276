import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listToggleRecoil, softPhoneComponentRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { amlQueryParamsRecoil, chatUserInfoRecoil } from '@/recoil/atoms/chatRoom';
import { msg015ChatRoomToggleRecoil, pickItemRecoil } from '@/recoil/atoms/say015Atom';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { deleteAllMessage } from '@/apis/api/say015Apis';
import { deleteChatModalText } from '@/components/hooks/customs/useModalText';
import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

interface IUseChatRoomSelect {
  itemOption: { label: string; value: string }[];
  pickItemState: any;
}

export const useChatRoomSelect = (props: IUseChatRoomSelect) => {
  // 리스트 클릭 상태 recoil
  const [, setListToggleState] = useRecoilState(listToggleRecoil);
  const [softPhoneComponent] = useRecoilState(softPhoneComponentRecoil);
  // 개별 메시지 리스트
  const amlQueryParams = useRecoilValue(amlQueryParamsRecoil);
  // 그룹 대화방
  // const gmlQueryParams = useRecoilValue(gmlQueryParamsRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const [chatUserInfo, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);
  /* 좌측 컴포넌트 표출상태 recoil */
  const [, setMsg015ChatRoomToggleR] = useRecoilState(msg015ChatRoomToggleRecoil);
  /* 우클릭 선택한 아이템 data STATE */
  const [pickItemState, setPickItemState] = useRecoilState(pickItemRecoil);

  const environmentS = useRecoilValue(environmentRecoil);
  const queryClient = useQueryClient();
  const { confirmModal, warningModal } = useModalHook();

  /* 해당 번호 내역 Msg 전체 삭제하는 mutate */
  const { mutate: chatDelMutate } = useMutation(deleteAllMessage);

  /* 메인 주소록 테이블 width  */
  const [, setMainTableWidth] = useRecoilState(mainTableWidthRecoil);
  // ==============================
  // 015사서함 리스트 아이템 우클릭 option onClick hook
  // ==============================
  const onClickItemOption = (option: any) => {
    let rightClickItem: string;
    let queryKey: string;
    let queryParams: any;
    if (props.pickItemState.phoneNumber) {
      rightClickItem = props.pickItemState.phoneNumber;
      queryKey = '/smt/history/latest/app';
      queryParams = amlQueryParams;
    }
    if (option.value === props.itemOption[0].value) {
      const { content, title } = deleteChatModalText(props.pickItemState);
      confirmModal(
        async () =>
          chatDelMutate([rightClickItem], {
            onSuccess: () => {
              const prevData: any = queryClient.getQueryData([queryKey, queryParams]);
              const updateData: any = prevData?.pages.map((page: any) => {
                return {
                  ...page,
                  content: page.content.filter(
                    (item: any) =>
                      (queryKey === '/smt/history/latest/app' ? item.phoneNumber : item.chatRoomId) !== rightClickItem,
                  ),
                };
              });
              const newData = { ...prevData, pages: updateData };
              queryClient.setQueryData([queryKey, queryParams], newData);

              if (softPhoneComponent !== 'ars') {
                setListToggleState(false);
              }
              // 채팅방이 열려있을 때 해당 대화방 나가면 toggle
              if (chatUserInfo.phoneNumber === pickItemState?.phoneNumber) {
                setMainTableWidth(0); // 주소록 테이블 width 초기화
                setChatUserInfo({ phoneNumber: null, buddyName: null });
                setPickItemState(null);
                setMsg015ChatRoomToggleR(false);
              }
              setPickItemState(null);
            },
            onError: (error: any) => {
              warningModal('실패', error.response.data, true);
            },
          }),
        title,
        content,
        true,
      );
    }
  };

  return { onClickItemOption };
};
