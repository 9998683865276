import React from 'react';
import * as S from './Signupterms.styles';

const ComProtectPer = () => {
  return (
    <S.SignuptermsContainer className="com_protect com_protect_per">
      <h5>1. 개인정보 수집항목과 그 목적</h5>
      <table>
        <thead>
          <tr>
            <th>구분</th>
            <th>수집항목</th>
            <th>수집목적</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={7}>필수 수집항목</td>
            <td>성명(상호), 생년월일(사업자번호)</td>
            <td>
              <span>＊ 본인 확인제에 따른 본인확인, 회원 식별을 위한 목적</span>
              <span>
                ＊ 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입의사 확인, 가입 및 가입횟수 제한, 연령확인
              </span>
            </td>
          </tr>
          <tr>
            <td>아이디, 비밀번호</td>
            <td>
              <span>＊ 회원제 서비스 제공을 위한 목적</span>
            </td>
          </tr>
          <tr>
            <td>휴대전화번호, 전자우편</td>
            <td>
              <span>
                ＊ 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의 여부 확인, 불만처리 등 민원처리,공지사항 전달 등
              </span>
            </td>
          </tr>
          <tr>
            <td>서비스 이용기록, 쿠키</td>
            <td>
              <span>＊ 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</span>
            </td>
          </tr>
          <tr>
            <td>결제 기록</td>
            <td>
              <span>＊ 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 구매 및 요금 결제, 요금추심</span>
            </td>
          </tr>
          <tr>
            <td>접속로그기록 및 기타 통신 사실 확인자료,접속 IP 정보</td>
            <td>
              <span>＊ 분쟁 조정을 위한 기록 보존</span>
            </td>
          </tr>
          <tr>
            <td>메시지 내용</td>
            <td>
              <span>＊전송내역 확인을 요청하는 고객에게 제공</span>
            </td>
          </tr>
          <tr>
            <td> 선택 수집항목</td>
            <td>주소, 닉네임, 긴급연락처, 생일, 결혼여부, 결혼기념일, 직업</td>
            <td>
              <span>＊ 마케팅 및 광고에 활용, 이벤트 등 광고성 정보 전달</span>
              <span>＊ 본인 확인제에 따른 본인확인, 회원 식별을 위한 목적</span>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>2. 개인정보의 보유 기간</h5>

      <ul>
        <li>
          1) 회사는 다음과 같은 사유가 발생할 경우 해당 개인정보를 지체 없이 파기하며, 개인정보취급이 제3자에게 위탁된
          경우에는 수탁자에게 파기하도록 지시합니다.
          <ul>
            <li>- 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우</li>
            <li>- 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우</li>
          </ul>
        </li>
        <li>2) 관련 법률 및 회사 규정에 의해 다음과 같이 회원의 정보를 일정기간 보존합니다.</li>
      </ul>

      <table>
        <thead>
          <tr>
            <th>보존 회원 정보</th>
            <th>보존 이유</th>
            <th>보존 기간</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>표시,광고에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>6개월</td>
          </tr>
          <tr>
            <td>계약 또는 청약철회 등에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td> 대금결제 및 재화 등의 공급에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>3년</td>
          </tr>
          <tr>
            <td>전자금융 거래에 관한 기록</td>
            <td>전자금융거래법</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>본인확인에 관한 기록</td>
            <td>정보통신망 이용촉진 및 정보보호 등에 관한 법률</td>
            <td>6개월</td>
          </tr>
          <tr>
            <td>접속 로그 기록</td>
            <td>통신비밀보호법</td>
            <td>3개월</td>
          </tr>
          <tr>
            <td>기타 통신사실확인자료</td>
            <td>통신비밀보호법</td>
            <td>12개월</td>
          </tr>
        </tbody>
      </table>
    </S.SignuptermsContainer>
  );
};
export default ComProtectPer;
