import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100%;
    /* position: fixed; */
    /* overflow: hidden; */
  }
`;

/* 네비게이션 */
export const LeftNav = styled.div`
  z-index: 5;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: none;

  p {
    display: none;
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.txt500};
    font-weight: 600;
  }

  &.active {
    p {
      color: ${(props) => props.theme.colors.main};
    }
    button {
      border-color: ${(props) => props.theme.colors.main};
      color: ${(props) => props.theme.colors.main};
    }
  }

  button {
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    border-radius: 123px;
    color: ${(props) => props.theme.colors.txt500};
    background-color: ${(props) => props.theme.colors.gray100}bf;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.2);
    svg {
      width: 70%;
      max-width: 3rem;
      height: 50%;
    }
  }

  @media screen and (max-width: 1040px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 8rem;
    right: 2rem;
    top: unset;
    transform: unset;
    transition: all 0.2s;

    &.hidden {
      transform: scale(0.5) translate(3rem, 4rem);
      opacity: 0.8;
    }
  }
`;

// 왼쪽 탭
export const LeftGroup = styled.div`
  width: 100%;
  max-width: 28rem;
  border-right: 1px solid ${(props) => props.theme.colors.gray300};

  /* 목업 스타일 */
  &.cardLeftGroup {
    background: transparent;
    border-right: 0;

    /* 목업 카드 */
    > .card {
      width: 100%;
      padding: 0 1rem;
      margin-top: 8rem;
      border-radius: 1rem;
      background: ${(props) => props.theme.colors.white};
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
      > p {
        position: relative;
        height: 14.2rem;
        img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          max-width: 95%;
          max-height: 23rem;
          object-fit: contain;
        }
      }
      .txtBox {
        padding: 4rem 1.8rem 1.8rem;
        color: ${(props) => props.theme.colors.txt500};

        h3 {
          font-size: 2.6rem;
          color: ${(props) => props.theme.colors.main};
          font-weight: 600;
          margin-bottom: 2.4rem;
        }
        p {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.6em;
        }
      }
    }

    > button {
      cursor: pointer;
      display: block;
      margin: 3rem auto;
      width: 100%;
      height: 5rem;
      font-size: 1.7rem;
      padding: 1.4rem;
      border-radius: 1rem;
      background: ${(props) => props.theme.colors.main};
      color: ${(props) => props.theme.colors.txt100};
    }
  }

  &.arsLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.white};
  }

  @media screen and (max-width: 1040px) {
    z-index: 4;
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
    transition:
      left 0.3s ease-in-out,
      right 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    opacity: 0;

    &.active {
      opacity: 1;
      left: 0;
      transition:
        left 0.3s ease-in-out,
        right 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
      &.cardLeftGroup {
        left: 5%;
      }
    }

    /* 목업 */
    &.cardLeftGroup {
      top: 12rem;
      width: 90%;
      height: auto;
      padding: 0;
      background: transparent;
      box-shadow: unset;
    }
  }

  @media screen and (max-width: 768px) {
    left: 100%;
    max-width: unset;
    height: calc(100% - 6rem);
    box-shadow: unset;
  }
`;

// 컨텐츠
export const Contents = styled.div`
  width: calc(100% - 28rem);

  .topGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.txt400};
    h3 {
      font-size: 2rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.txt500};
    }
    .line {
      display: block;
      width: calc(100% - 52rem);
      height: 1px;
      background: ${(props) => props.theme.colors.txt500};
    }
    button {
      cursor: pointer;
      color: ${(props) => props.theme.colors.txt400};
    }
    svg {
      max-width: 2rem;
      max-height: 2rem;
    }
  }
  @media screen and (max-width: 1040px) {
    width: 100%;
    .topGroup {
      h3 {
        display: flex;
        flex-direction: column;
      }
      .line {
        width: calc(100% - 36rem);
      }
    }
  }
  @media screen and (max-width: 768px) {
    .topGroup {
      h3 {
        font-size: 1.6rem;
      }
      .line {
        width: calc(100% - 30rem);
      }
    }
  }
`;

export const ArsGroup = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;

  /* 화살표 아이콘 위치조정 */
  .arrowIcon {
    margin: 0 4rem 0 -2.2rem;
    span {
      position: absolute;
      right: -4rem;
    }
  }
  /* 카드를 감싸는 div */
  .arsCardModalWrap {
    z-index: 2;
    &:has(.plus.active) {
      display: flex;
      gap: 2rem;
      :has(.edit) {
        align-items: center;
      }
    }
  }

  &.full {
    ol {
      height: calc(var(--vh, 100vh));
    }
  }
  ol {
    box-sizing: border-box;
    width: 100%;
    height: calc(var(--vh, 100vh) - 11rem);
    overflow: auto;
    padding: 8rem 4rem 8rem;
    /* 스크롤바 스타일 */
    ::-webkit-scrollbar {
      width: 1.4rem;
      height: 1.4rem;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #98999a;
      border: 0.5rem solid transparent;
      border-radius: 1rem;
      background-clip: padding-box;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    > li {
      > div {
        .plus {
          position: relative !important;
          bottom: unset !important;
          left: unset !important;
          transform: unset !important;
        }
      }
    }
  }
  ul {
    position: relative;
    border-left: 2px solid ${(props) => props.theme.colors.gray300};
    padding: 0 4rem 0 2rem;

    li:has(.selectBottom) {
      margin-bottom: 18rem;
    }
    /* 플러스 버튼 클릭시 하단 위젯과 겹치지않기위해 작업 */
    /*  li:has(.plus.active) > div */
    li:has(.addArsCard) > div {
      margin-bottom: 22rem;
    }
    :has(li:only-of-type) {
      align-self: center;
    }
  }
  li {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 3.5rem;

    :has(ul) li {
      &:last-of-type {
        margin: 0;
      }
    }
    &:only-of-type {
      margin-bottom: 0;
    }

    /* :has(.plus.active) {
      margin-bottom: 0;
    } */
    /* :has(.plus.active + .arsCardWrap) {
      margin-bottom: 3.5rem;
    } */

    &:nth-of-type(n + 12) {
      > div {
        .plus:last-of-type {
          position: relative;
          bottom: unset;
          left: unset;
          transform: unset;
        }
      }
    }
    @keyframes borderPulse {
      0% {
        box-shadow: 0 0 0 0 ${(props) => props.theme.colors.main}4d;
      }
      100% {
        box-shadow: 0 0 1rem 1rem ${(props) => props.theme.colors.main}4d;
      }
    }

    .active_ars {
      animation: borderPulse 1s ease-out forwards;
    }
    > div {
      position: relative;
      display: flex;
      align-items: center;
      gap: 2rem;
      :has(.grab, .del) > p {
        color: ${(props) => props.theme.colors.main};
      }
      > p {
        width: 1rem;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        color: ${(props) => props.theme.colors.txt400};
      }
      /* :has(button.plus) {
        margin-right: 2rem;
      } */
    }
  }

  @media screen and (max-width: 768px) {
    &.full {
      ol {
        padding: 2rem;
        height: calc(var(--vh, 100vh) - 6rem);
      }
    }
  }
  @media screen and (max-width: 540px) {
    .arrowIcon {
      margin: 0 4rem 0 -3.5rem;
    }
    ol {
      li {
        gap: 1rem;
        > div {
          gap: 1.5rem;
        }
      }
    }
    ul {
      padding: 0px 2rem 0px 1.5rem;
      button.plus {
        width: 20rem;
      }
    }
  }
`;

/* Ars 위젯 세팅 버튼 */
export const ArsWidgetSetting = styled.div`
  width: 100%;
  height: 5rem;
  padding: 1rem 1rem 0;
  display: flex;
  justify-content: flex-end;
  ol {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    li {
      height: 100%;
      &.active {
        button {
          background: ${(props) => props.theme.colors.active};
          color: ${(props) => props.theme.colors.txt100};
          p {
            color: ${(props) => props.theme.colors.txt100};
          }
        }
      }
      button {
        display: flex;
        align-items: center;
        gap: 1.4rem;
        width: 100%;
        height: 100%;
        padding: 1rem 1.5rem;
        font-size: 1.4rem;
        border-radius: 0.6rem;
        background: ${(props) => props.theme.colors.gray300};
        border: 1px solid ${(props) => props.theme.colors.gray400};
        color: ${(props) => props.theme.colors.main};
        p {
          color: ${(props) => props.theme.colors.txt500};
        }
        :hover {
          color: ${(props) => props.theme.colors.txt100};
          background: ${(props) => props.theme.colors.hover};
          p {
            color: ${(props) => props.theme.colors.txt100};
          }
        }
      }
    }
  }
  @media screen and (min-width: 640px) {
    z-index: 2;
    position: absolute;
    width: auto;
    top: 1rem;
    right: 2rem;
  }
`;
