import React from 'react';
import styled, { css } from 'styled-components';

interface Option {
  value: string | number;
  label: string;
}

interface BaseSelectProps {
  name?: string;
  options: Option[];
  value?: string | number;
  onChange?: (value: string) => void;
  defaultValue?: string;

  customStyle?: any; // Add a new prop for styles
}

const StyledSelect = styled.select`
  background: ${(props) => props.theme.colors.gray100};
  color: ${(props) => props.theme.colors.txt500};
  padding: 0.4rem 0.6rem;
  option {
    background: ${(props) => props.theme.colors.gray100};
  }
`;

const BaseSelect: React.FC<BaseSelectProps> = ({
  name,
  options,
  value = undefined,
  onChange,
  customStyle,
  defaultValue,
}): React.JSX.Element => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <>
      {/* Apply the custom style here */}
      <StyledSelect name={name} value={value} defaultValue={defaultValue} onChange={handleChange} style={customStyle}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </> // Wrap the Styled Select with a div
  );
};

export default BaseSelect;
