import React from 'react';
import styled from 'styled-components';

interface IconProps {
  width?: string;
  height?: string;
  className?: string;
}

const Svg = styled.svg<IconProps>`
  width: ${(props) => (props.width ? props.width : '2.8rem')};
  height: ${(props) => (props.height ? props.height : '2.1rem')};
`;

const IconMessage = ({ className, width, height }: IconProps) => {
  return (
    <Svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 23"
      fill="none"
    >
      <path
        d="M23.9171 1H11.0829C10.2652 1 9.4811 1.3212 8.90295 1.89293C8.3248 2.46467 8 3.24011 8 4.04867V13.0212C8 13.8298 8.3248 14.6052 8.90295 15.177C9.4811 15.7487 10.2652 16.0699 11.0829 16.0699H15.39L21.9345 19V16.0706H23.9171C24.7348 16.0706 25.5189 15.7494 26.0971 15.1776C26.6752 14.6059 27 13.8305 27 13.0219V4.04867C27 3.24011 26.6752 2.46467 26.0971 1.89293C25.5189 1.3212 24.7348 1 23.9171 1Z"
        stroke="currentColor"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16.6566V17.9179C14 19.0899 13.0112 20.0355 11.7927 20.0355H8.95179L4.53026 22V20.0355H3.20728C1.98875 20.0355 1 19.0899 1 17.9179V12.111C1 10.9456 1.98875 10 3.20728 10H7.32941"
        stroke="currentColor"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconMessage;
