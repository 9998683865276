import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconPlayRound: React.FC<React.SVGProps<SVGSVGElement>> = ({
  className,
  fontSize,
  fill,
  fillOut,
  stroke,
}: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '1.9rem'}
      viewBox="0 0 19 19"
      fill="none"
    >
      <circle cx="9.21643" cy="9.21643" r="9.21643" fill={fillOut ?? 'currentColor'} />
      <path
        d="M8.41834 5.66236C7.84234 5.32353 7.11621 5.73883 7.11621 6.4071V12.0261C7.11621 12.6944 7.84234 13.1097 8.41834 12.7708L13.1945 9.96134C13.7624 9.62726 13.7624 8.80594 13.1945 8.47185L8.41834 5.66236Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconPlayRound;
