import { TOpenModal } from '@/components/hooks/customs/useHotfixModal';
import React from 'react';
import styled from 'styled-components';

const HotfixModalWrap = styled.div`
  z-index: 102;
  position: fixed;
  top: 2rem;
  left: 5%;
  max-width: 90%;
  background: ${(props) => props.theme.colors.gray300};
  color: ${(props) => props.theme.colors.txt400};
  border-radius: 1rem;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3);
  outline: 2px solid ${(props) => props.theme.colors.gray400};

  .mbtnGroup {
    display: none;
  }

  h2 {
    font-size: 3rem;
    background: ${(props) => props.theme.colors.gray400};
    padding: 3rem;
    border-radius: 1rem 1rem 0 0;
    color: ${(props) => props.theme.colors.txt500};
    /* margin-bottom: 2rem; */
    width: 100%;
  }
  p {
    position: relative;
    padding: 2rem 3rem;
    font-size: 1.6rem;
    line-height: 1.8em;
  }
  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    width: 90%;
    padding: 1rem;
    margin: 3rem auto 2rem;
    border-radius: 0.5rem;
    background: ${(props) => props.theme.colors.gray700};
    color: ${(props) => props.theme.colors.white};
    svg {
      width: 1.6rem;
      height: 1.5rem;
    }
  }
  .btnGroup {
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
    display: flex;
    button {
      position: relative;
      margin: 0;
      padding: 1.2rem 1rem;
      font-size: 1.6rem;
      border-radius: 0;
      white-space: nowrap;
      :nth-of-type(1) {
        background: ${(props) => props.theme.colors.gray600};
      }
    }
  }

  @media screen and (max-width: 768px), screen and (max-height: 750px) {
    .contents {
      height: 100%;
      max-height: calc(var(--vh, 40vh) - 18rem); /* contents div 높이 제한 */
      overflow-y: auto; /* contents div 스크롤 추가 */
      p {
        font-size: 1.2rem;
        padding: 1rem 2rem;
      }
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: ${(props) => props.theme.colors.gray400};
      h2 {
        padding: 2rem;
        margin: 0;
        font-size: 2rem;
        font-weight: 800;
      }
      .mbtnGroup {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.4rem;
        button {
          position: relative;
          width: 100%;
          padding: 1rem 1rem;
          font-size: 1.6rem;
          :nth-of-type(1) {
            border-right: 1px solid ${(props) => props.theme.colors.gray400};
          }
        }
      }
    }
    .btnGroup {
      button {
        font-size: 1.2rem;
      }
    }
  }
`;

interface IHotfixModal {
  onClickHotfixClose: (type: keyof TOpenModal, button?: '1day') => void;
}

const HotfixModal = (props: IHotfixModal) => {
  return (
    <HotfixModalWrap>
      <div className="title">
        <h2>서비스 점검 안내</h2>
      </div>
      <div className="contents">
        <p>
          안녕하세요. 015WORKS입니다.
          <br />
          015WORKS에서 원활한 서비스를 위한 점검 작업이 예정되어 안내드립니다.
          <br />
          <br />
          1. 서비스 개선 작업
          {/* <span style={{ textDecoration: 'line-through', color: 'gray' }}>
            2024년 10월 10일 목요일 오후 21:00~24:00 (3시간)
          </span>
          <br />
           *점검연장 2024년 10월 11일 금요일 오전 09:00까지 */}
          <br />
          1)일시 : 2024년 11월 08일 금요일 오후 21:00~24:00 (3시간)
          <br />
          *점검시간은 연장될 수 있습니다.
          <br />
          <br />
          2)작업 내용 : 서비스 안정화를 위한 점검 작업
          <br />
          <br />
          3)작업 영향 : 015WORKS.COM 의 015 Mode 전송 중단 (015 번호로 통화서비스, 문자 발신 및 수신 중단)
          <br />
          <br />
          기존 arreo.com 사이트와 015works.com 의 010 Mode는 정상적으로 이용 가능합니다.
          <br />
          서비스 안정화를 위한 작업이니, 양해 부탁드립니다.
          <br />
          <br />
          감사합니다.
        </p>
      </div>
      <div className="btnGroup">
        <button onClick={() => props.onClickHotfixClose('hotfix', '1day')}>오늘 하루 열지 않기</button>
        <button onClick={() => props.onClickHotfixClose('hotfix')}>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M14.5 1.24902L1.5 14.249M1.5 1.24902L14.5 14.249"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> */}
          닫기
        </button>
      </div>
    </HotfixModalWrap>
  );
};
export default HotfixModal;

/* <HotfixModalWrap>
<h2>서비스 정상화 공지</h2>

<p>
  현재 015Mode의 수신 및 발신 서비스가 정상화 되었습니다.
  <br />
  더 나은 서비스 제공을 위해 최선을 다하겠습니다.
  <br />
  감사합니다.
</p>
<button onClick={() => props.onClickHotfixClose('hotfix')}>
  닫기
</button>
</HotfixModalWrap> */
