import React, { useEffect } from 'react';

import styled from 'styled-components';
import * as TS from '@/styles/table.styles';
import { v4 as uuidv4 } from 'uuid';
import BaseButton from '../Atom/BaseButton';
import { cshPubDetail } from '../../apis/api/pointApi';
import useGlobalModalHook from '@/pages/main/components/modal/globalModal/useGlobalModalHook';
import { useSendResultDetail } from '../hooks/customs/useSendResultDetail';
import { resultDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import { useRecoilValue } from 'recoil';
import SendResultDetailInfo from '@/pages/main/components/etcLeftZone/sendRoom010/sendResultDetail/sendResultDetailInfo/SendResultDetailInfo';
import IconImage from '@/styles/icons/IconImage';

const TableWrap = styled.div`
  @media screen and (max-width: 768px) {
    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: rgba(140, 140, 140, 0.4);
    }
    ::-webkit-scrollbar-thumb {
      background: #98999a;
    }

    tr {
      display: table-row;
      th,
      td {
        padding: 1rem 2rem;
        flex: none;
        white-space: nowrap;
      }
      td:last-child {
        padding: 1rem;
      }
    }
  }
`;
const Table = styled.table`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;

  th,
  td {
    padding: 1rem;
  }
`;

const TableHeadColumn = styled.th`
  font-weight: bold;
  font-size: 1.4rem;
`;

const TableBodyColumn = styled.td`
  font-size: 1.3rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 20rem;
`;
const SendMsgWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  p {
    width: auto;
    max-width: 20rem;
  }
`;
const TableBodyColumnSpan = styled.p`
  display: block;
  font-size: 1.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%; /* 부모 요소의 너비를 상속받도록 설정 */

  &.textLeft {
    display: flex;
    justify-content: center;
  }

  &.textLeft span {
    width: 50%;
  }

  &.payText {
    text-align: right;
    padding-right: 50px;
  }
  @media screen and (max-width: 768px) {
    &.textLeft {
      gap: 2rem;
    }
    &.textLeft span {
      width: auto;
    }
  }
`;
const TableBodyColumnSpanCursor = styled.span`
  font-size: 1.3rem;
  cursor: pointer;
`;

const TableImg = styled.img`
  width: 5rem;
`;

const DetailWrap = styled.div`
  width: calc(80vw - 6rem);
  max-width: 80rem;
  height: 100%;
  /* 마이페이지 - 전송결과 조회 팝업 스타일  */
  .sendResultLeftDataView {
    > div {
      width: 100%;
    }
    .messageContent {
      min-height: auto;
      height: calc(var(--vh, 40vh) - 60vh);
      max-height: 40rem;
      p {
        ::-webkit-scrollbar {
          width: 1.4rem;
          height: 1.4rem;
          background: transparent;
        }

        ::-webkit-scrollbar-thumb {
          border: 0.5rem solid transparent;
          background: ${(props) => props.theme.colors.gray600};
          background-clip: padding-box;
          border-radius: 40px;
        }
      }
    }
  }
`;

export interface ITableRow {
  id: string;
  imgData?: string;
  // [key: string]: string | React.ReactElement;
  [key: string]: string | undefined | { data: string; func: () => void };
}
/**
 * type : line ( 밑줄 포함 ), default ( 밑줄 미포함 )
 * thead : 테이블 목록 배열
 * tbody : 테이블 ROW 데이터 오브젝트 배열
 * option => checkbox : 체크박스를 포함 또는 미포함을 선택하는 boolean 값
 * onclick : 테이블 row 클릭 이벤트 함수
 * handleCheckBoxAll : 테이블 헤더 체크박스 이벤트 함수
 * handleCheckBox : 테이블 로우 체크박스 이벤트 함수
 * allChecked : 테이블 헤더 체크박스의 state
 * checkedId : 테이블 로우 체크박스의 state
 */
interface ITable {
  type: 'line' | 'default';
  thead: string[];
  tbody: ITableRow[];
  option?: {
    checkbox?: boolean; // 체크박스 포함한 테이블 사용 시 true, default : false
  };
  onclick?: Function;
  handleCheckBoxAll?: (tbody: ITableRow[]) => void;
  handleCheckBox?: (tbody: ITableRow[], id: string) => void;
  allChecked?: boolean;
  checkedId?: string[];
  name?: string;
}

const BaseTable = ({
  type = 'default',
  thead = [],
  tbody = [],
  onclick,
  option = { checkbox: false },
  handleCheckBoxAll,
  handleCheckBox,
  allChecked,
  checkedId,
  name,
}: ITable) => {
  const openPopup = (url: any) => {
    const test = process.env.REACT_APP_PAYMENT_URL;
    window.open(
      `${test}${url}&payment=01`,
      // `http://testoffice.easypay.co.kr/receipt/ReceiptBranch.jsp?controlNo=${url}&payment=01`,
      // `http://office.easypay.co.kr/receipt/ReceiptBranch.jsp?controlNo=${url}&payment=01`,
      '_blank',
      'toolbar=0,scroll=1,menubar=0,status=0,resizable=0,width=515,height=700',
    );
  };

  const openPopupCshPubDetail = (serialnum: any) => {
    cshPubDetail(serialnum).then((data) => {
      const cshPubDetailPop = window.open(
        `/cshPubDetail?serialnum=${serialnum}`,
        '_blank',
        'toolbar=0,scroll=1,menubar=0,status=0,resizable=0,width=960,height=600',
      );
      cshPubDetailPop?.document.write(data);
    });
  };

  // 글로벌 모달 훅
  const popupHooks = useGlobalModalHook();
  // 전송결과 디테일 훅
  const hooks = useSendResultDetail();
  // 클릭한 리스트 pararms recoil
  const resultDetailParamsS = useRecoilValue(resultDetailParamsRecoil);

  useEffect(() => {
    if (resultDetailParamsS.prepayPayNo !== '' && hooks.trContentDataList)
      popupHooks.openModal(
        <DetailWrap className="detailWrap">
          <SendResultDetailInfo detailInfo={hooks.trContentDataList} pageType="simple" />
        </DetailWrap>,
      );
  }, [resultDetailParamsS, hooks.trContentDataList]);

  return (
    // <TableWrap>
    <TS.MyPageTableWrap className="scrollTable">
      <TS.MyPageTableCol>
        {/* <Table> */}
        <thead>
          <tr>
            {option.checkbox && (
              <td>
                <input
                  type="checkbox"
                  checked={allChecked}
                  onChange={() => {
                    if (handleCheckBoxAll) {
                      handleCheckBoxAll(tbody); // 여기서 handleCheckBoxAll을 호출
                    }
                  }}
                />
              </td>
            )}
            {thead.map((e) => (
              <TableHeadColumn key={uuidv4()}>{e}</TableHeadColumn>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbody.map((row) => {
            return (
              <tr key={uuidv4()}>
                {option.checkbox && (
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedId?.includes(row.id)}
                      onChange={() => {
                        if (handleCheckBox) {
                          handleCheckBox(tbody, row.id); // 여기서 handleCheckBoxAll을 호출
                        }
                      }}
                    />
                  </td>
                )}
                {thead.map((head) => {
                  if (typeof row?.[head] === 'object') {
                    return (
                      <TableBodyColumn key={uuidv4()}>
                        <TableBodyColumnSpanCursor
                          onClick={() => {
                            const rowData = row?.[head] as { data: string; func: (id?: string) => void };
                            rowData.func?.(row?.id);
                          }}
                        >
                          {row.imgData && <TableImg src={`data:image/jpeg;base64,${row.imgData}`} alt="" />}
                          {(row?.[head] as { data: string; func: (id?: string) => void })?.data}
                        </TableBodyColumnSpanCursor>
                      </TableBodyColumn>
                    );
                  }
                  if (name === 'use' && head === '사용금액') {
                    return (
                      <TableBodyColumn key={uuidv4()}>
                        <TableBodyColumnSpan className="textLeft">
                          <span>{row[head] as string}</span>
                          <span>{row['사용포인트'] as string}</span>
                        </TableBodyColumnSpan>
                      </TableBodyColumn>
                    );
                  }

                  if (head === '영수증인쇄' && row[head] !== '무통장입금') {
                    return (
                      <TableBodyColumn key={uuidv4()}>
                        <BaseButton
                          type="button"
                          width="8rem"
                          // height="20px"
                          fontSize="1.3rem"
                          fontWeight="bold"
                          onClick={() => openPopup(row['영수증id'])}
                        >
                          인쇄
                        </BaseButton>
                      </TableBodyColumn>
                    );
                  }

                  if (head === '상세보기') {
                    return (
                      <TableBodyColumn key={uuidv4()}>
                        <BaseButton
                          type="button"
                          width="80px"
                          // height="25px"
                          fontSize="1.3rem"
                          fontWeight="bold"
                          backgroundColor="#222"
                          color="#fff"
                          onClick={() => openPopupCshPubDetail(row['시리얼넘버'])}
                        >
                          상세보기
                        </BaseButton>
                      </TableBodyColumn>
                    );
                  }

                  if (name === 'message' && head === '전송내용') {
                    return (
                      <TableBodyColumn
                        key={uuidv4()}
                        onClick={() => {
                          onclick ? onclick(row) : null;
                          // onclick ? popupHooks.openModal(<SendResultDetailInfo detailInfo={hooks.detailInfo} />) : null;
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <SendMsgWrap>
                          {row.구분 === '포토전송' && <IconImage className="mainColor" />}
                          {row[head] !== '' && <TableBodyColumnSpan>{row[head] as string}</TableBodyColumnSpan>}
                        </SendMsgWrap>
                      </TableBodyColumn>
                    );
                  }

                  return (
                    <TableBodyColumn key={uuidv4()}>
                      <TableBodyColumnSpan>{row[head] as string}</TableBodyColumnSpan>
                    </TableBodyColumn>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {/* </Table> */}
      </TS.MyPageTableCol>
    </TS.MyPageTableWrap>
    // </TableWrap>
  );
};
export default BaseTable;
