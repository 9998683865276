import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

export interface IFileTypes {
  id: number;
  object: File;
}

interface IUseDragDropFile {
  readExcel: (file: any) => void;
  // handleResetColumn(): void;
  handleLargeTableReset: () => void;
}

export const useDragDropFile = ({ readExcel, handleLargeTableReset }: IUseDragDropFile) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [fileName, setFilesName] = useState<string | null>(null);

  const dragRef = useRef<HTMLLabelElement | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onChangeFiles = (e: ChangeEvent<HTMLInputElement> | any): void => {
    let selectFiles: File[] = [];

    if (e.type === 'drop') {
      selectFiles = e.dataTransfer.files;
    } else {
      selectFiles = e.target.files;
    }
    const file = selectFiles[0];

    // if(fileName && (fileName === selectFiles[0].name)) return;
    if (
      file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file?.type === 'application/vnd.ms-excel'
    ) {
      // handleResetColumn();
      handleLargeTableReset();
      setFilesName(selectFiles[0].name);
      readExcel(selectFiles[0]);
    }
  };

  const handleDragIn = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer!.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles],
  );

  const initDragEvents = useCallback((): void => {
    // 앞서 말했던 4개의 이벤트에 Listener를 등록합니다. (마운트 될때)

    if (dragRef.current !== null) {
      dragRef.current.addEventListener('dragenter', handleDragIn);
      dragRef.current.addEventListener('dragleave', handleDragOut);
      dragRef.current.addEventListener('dragover', handleDragOver);
      dragRef.current.addEventListener('drop', handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener('dragenter', handleDragIn);
      dragRef.current.removeEventListener('dragleave', handleDragOut);
      dragRef.current.removeEventListener('dragover', handleDragOver);
      dragRef.current.removeEventListener('drop', handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();
    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  return {
    dragRef,
    // files,
    // handleFilterFile,
    onChangeFiles,
    fileInputRef,
    // setFiles
  };
};
