import { formatDateDate } from '@/shared/util/format/dateFormatUtil';
import React from 'react';
import * as MLS from '../../../styles/CommonListStyles/CommonList.styles';
import IconImage from '@/styles/icons/IconImage';

interface IMsgReserveBox {
  prepayPayNo: any;
  callback: any;
  subject: any;
  rcvInfo: any;
  sndDttm: string;
  imageExists: any;
  sndMsg: any;
  scheduleDetailParamsState: {
    prepayPayNo: string;
    callback: string;
  };
  handleActiveList: (prepayPayNo: string, callback: string) => void;
}

const MsgReserveBox = (props: IMsgReserveBox) => {
  return (
    <div
      key={props.prepayPayNo}
      role="button"
      tabIndex={0}
      className={`groups reservation ${props?.prepayPayNo === props.scheduleDetailParamsState.prepayPayNo ? 'active' : ''}`}
      onClick={() => props.handleActiveList(props?.prepayPayNo, props?.callback)}
    >
      <div className="titleGroup">
        <div className="topGroup">
          <svg
            className="reservationIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path d="M9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0ZM9 1.8C7.09044 1.8 5.25909 2.55857 3.90883 3.90883C2.55857 5.25909 1.8 7.09044 1.8 9C1.8 10.9096 2.55857 12.7409 3.90883 14.0912C5.25909 15.4414 7.09044 16.2 9 16.2C10.9096 16.2 12.7409 15.4414 14.0912 14.0912C15.4414 12.7409 16.2 10.9096 16.2 9C16.2 7.09044 15.4414 5.25909 14.0912 3.90883C12.7409 2.55857 10.9096 1.8 9 1.8ZM9 3.6C9.22044 3.60003 9.4332 3.68096 9.59793 3.82744C9.76267 3.97393 9.86791 4.17577 9.8937 4.3947L9.9 4.5V8.6274L12.3363 11.0637C12.4977 11.2257 12.5914 11.443 12.5984 11.6715C12.6054 11.9001 12.5251 12.1227 12.3739 12.2943C12.2226 12.4658 12.0118 12.5733 11.7842 12.595C11.5565 12.6166 11.3292 12.5509 11.1483 12.411L11.0637 12.3363L8.3637 9.6363C8.22382 9.4963 8.13399 9.3141 8.1081 9.1179L8.1 9V4.5C8.1 4.2613 8.19482 4.03239 8.3636 3.8636C8.53239 3.69482 8.7613 3.6 9 3.6Z" />
          </svg>
          <div className="title">
            <MLS.MessageTitle className={`messageTitle ${props?.subject !== '제목없음' ? '' : 'noTitle'}`}>
              {props?.subject}
            </MLS.MessageTitle>
          </div>
          {/* peopleCount 명수 */}
          {/* <div className="peopleCount">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <path
                              d="M5.31006 4.72809C6.55318 4.72809 7.56006 3.72121 7.56006 2.47809C7.56006 1.23496 6.55318 0.228088 5.31006 0.228088C4.06693 0.228088 3.06006 1.23496 3.06006 2.47809C3.06006 3.72121 4.06693 4.72809 5.31006 4.72809ZM5.31006 5.85309C3.80818 5.85309 0.810059 6.60684 0.810059 8.10309V8.66559C0.810059 8.97496 1.06318 9.22809 1.37256 9.22809H9.24756C9.55693 9.22809 9.81006 8.97496 9.81006 8.66559V8.10309C9.81006 6.60684 6.81193 5.85309 5.31006 5.85309Z"
                              fill="#191919"
                            />
                          </svg>
                          <p>
                            <span>{el?.rcvInfo.totalCount}</span> 명
                          </p>
                        </div> */}
          <div className="dateGroup">
            <p className="date">{formatDateDate(props?.sndDttm) ?? ''}</p>
          </div>
        </div>
      </div>
      <div className="textContent">
        {props?.imageExists !== '' && props?.imageExists && (
          <p className="iconGroup">
            <IconImage />
          </p>
        )}
        <p className="text">{props.sndMsg ? props.sndMsg : '이미지'}</p>
      </div>
    </div>
  );
};
export default MsgReserveBox;
