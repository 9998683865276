import { useEffect, useRef, useState } from 'react';

export const useResBtnBin = () => {
  // menu Switch
  const [resMenuSwitch, setResMenuSwitch] = useState<boolean>(false);

  const layoutRef = useRef<HTMLDivElement>(null);

  const onClickMenuBtn = () => {
    setResMenuSwitch((prev) => !prev);
  };

  // ===========================================
  // 상단 버튼 및 팝업창 layout 을 제외한 다른 곳 클릭시 active 해제
  // ===========================================
  const handleClickOutside = (event: any) => {
    /* 컴포넌트 최상단 useRef */
    const layoutCurrent = layoutRef.current;
    if (layoutCurrent && !layoutCurrent.contains(event.target as Node)) {
      setResMenuSwitch(false);
    }
    if (layoutCurrent && !layoutCurrent.contains(event.target as Node)) {
      setResMenuSwitch(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return { layoutRef, onClickMenuBtn, resMenuSwitch };
};
