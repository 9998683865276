import { useState } from 'react';

export type TModalType = string | null;

/**
 * @title 통지 모달창 관련 커스텀 hooks
 *
 * @author 김남규
 * @since 2024-05-11
 */
export const useNoticeModal = () => {
  /* 모달창 토글 STATE */
  const [noticeModalToggle, setNoticeModalToggle] = useState<boolean>(false);
  /* 모달창 type STATE */
  const [modalType, setModalType] = useState<TModalType>(null);

  // ==============================
  // 모달 토글버튼 onClick hook
  // ==============================
  const onClickModalBtn = (contentType: string) => {
    setModalType(contentType);
    setNoticeModalToggle((prev) => !prev);
  };

  return { noticeModalToggle, modalType, setNoticeModalToggle, onClickModalBtn };
};
