// 주소록 좌측 그룹 리스트 사이드 바 HOOK
import { getGroupList } from '@/apis/api/addressApis';
import {
  addGroupOpenRecoil,
  groupListEnabledRecoil,
  groupSearchTextRecoil,
  resTableSwitchRecoil,
} from '@/recoil/atoms/addressBookGroup';
import { groupListDataRecoil } from '@/recoil/atoms/atoms';
import { theme } from '@/styles/theme';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

/**
 * @title 주소록 그룹리스트 GET Hooks
 *
 * @author 김남규
 * @since 2024.03.15
 */

export const useGroupList = () => {
  // 클릭시 스타일
  const clickStyle = {
    borderRadius: '5px',
    backgroundColor: theme.colors.gray300,
    boxShadow: 'none',
  };
  const childrenClickStyle = {
    fontWeight: '600',
    color: theme.colors.txt500,
  };
  /* 클릭한 그룹 groupSeqNo STATE */
  const [checkedGroup, setCheckedGroup] = useState<number | null>(null);
  /* 그룹 추가 컴포넌트 toggle STATE */
  const [addGroupOpen, setAddGroupOpen] = useRecoilState<boolean>(addGroupOpenRecoil);
  /* 클릭한 그룹 styles STATE */
  const [clickGroupStyles, setGroupClickStyles] = useState<Record<number, boolean>>({});
  /* 받아온 그룹 리스트 groupSeqNo, groupNm 필드 ( 객체 배열 ) STATE */
  const [groupArr, setGroupArr] = useState<any>(null); // 현재 인포카드에서만 사용중. getQueryData로 수정 예정
  /* 주소록 리뉴얼에 사용중인 그룹 리스트 데이터 recoil  */
  const [, setGroupListDataS] = useRecoilState(groupListDataRecoil);
  /* 반응형에서 나올 메인테이블 좌측 상단 사이즈 조절 스위치 */
  const [resTableSwitchS, setResTableSwitchS] = useRecoilState(resTableSwitchRecoil);
  /* 그룹리스트 검색 keyword 받을 Recoil */
  const [groupSearchTextS, setGroupSearchTextS] = useRecoilState(groupSearchTextRecoil);
  /* 그룹리스트 enable state */
  const [groupListEnabledS, setGroupListEnabledS] = useRecoilState(groupListEnabledRecoil);

  // ==============================
  // 그룹 리스트 데이터 GET API Query
  // ==============================
  const { data: groupList, isLoading: groupListIsLoading } = useQuery(
    ['groupList2'],
    () => getGroupList(groupSearchTextS),
    {
      enabled: groupListEnabledS,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setGroupListEnabledS(false);
      },
      onError: () => {
        setGroupSearchTextS('');
        setGroupListEnabledS(false);
      },
    },
  );

  // ==============================
  // 그룹 리스트 변경시 groupArr에 적용
  // ==============================
  useEffect(() => {
    if ((groupList === null || groupList === undefined) && groupListIsLoading) {
      setGroupListEnabledS(true);
    }
    if (groupList && !groupListIsLoading) {
      const arr = groupList?.groupList?.map((el: any) => {
        return { groupSeqNo: el.groupSeqNo, groupNm: el.groupNm };
      });
      if (JSON.stringify(groupArr) !== JSON.stringify(arr)) {
        setGroupArr(arr);
        setGroupListDataS(arr);
      }
    }
  }, [groupList, groupListIsLoading, groupListEnabledS]);

  // ==============================
  // '그룹 추가' 클릭시 그룹 추가 컴포넌트 toggle Hook
  // ==============================
  const addGroupOpenHandle: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setAddGroupOpen((prev) => !prev);
  }, [addGroupOpen]);

  // ==============================
  // 반응형 그룹리스트 컴포넌트 토글 hook
  // ==============================
  const onClickGroupComponentToggle = () => {
    setResTableSwitchS((prev) => !prev);
  };

  return {
    groupArr,
    clickStyle,
    childrenClickStyle,
    clickGroupStyles,
    setGroupClickStyles,
    groupList,
    groupListIsLoading,
    setCheckedGroup,
    checkedGroup,
    addGroupOpenHandle,
    addGroupOpen,
    resTableSwitchS,
    onClickGroupComponentToggle,
  };
};
