import NoData from '@/components/common/NoData';
import { mobileBuddyListAddRecoil, multiChatRoomIdRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { amlQueryParamsRecoil, chatUserInfoRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { removeWebCharacter } from '@/shared/util/format/contentFormatUtil';
import { formatDateAll } from '@/shared/util/format/dateFormatUtil';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import IconCassetteTape from '@/styles/icons/IconCassetteTape';
import IconFile from '@/styles/icons/IconFile';
import IconImage from '@/styles/icons/IconImage';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { IoChatbubbleSharp, IoLogoWechat } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import MobileLoader from '../../MobileLoader';
import { useGetChatroomList } from '../../mobileHooks/useGetChatroomList';
import * as S from './MobileMessageList.style';

const MobileMessageList = () => {
  const navigate = useNavigate();
  const setMobileBuddyListAddRecoilS = useSetRecoilState(mobileBuddyListAddRecoil);
  /* messageHistory api 요청 params */
  const [, setMhQueryParams] = useRecoilState(mhQueryParamsRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const [chatUserInfo, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);
  // 단체 채팅방 ID 리코일
  const setMultiChatRoomId = useSetRecoilState(multiChatRoomIdRecoil);

  // const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
  const setAmlQueryParamsS = useSetRecoilState(amlQueryParamsRecoil);

  // 디바운스를 위해 searchValue가 변경될 때마다 0.4초 후 debouncedSearchValue를 업데이트
  useEffect(() => {
    const handler = setTimeout(() => {
      setAmlQueryParamsS((prev) => ({ ...prev, keyword: debouncedSearchValue }));
    }, 400);
    // cleanup: 타이머 초기화
    return () => clearTimeout(handler);
  }, [debouncedSearchValue]);

  // onChange 이벤트
  const onChangeSearch = (e: any) => {
    // setSearchValue(e.target.value);
    setDebouncedSearchValue(e.target.value);
  };

  // 훅
  const useGetChatroomListHook = useGetChatroomList(debouncedSearchValue);

  //인피니티 스크롤
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (
        useGetChatroomListHook.hasNextPage &&
        !useGetChatroomListHook.isFetchingNextPage &&
        !useGetChatroomListHook.isLoading
      ) {
        useGetChatroomListHook.fetchNextPage();
      }
    }
  };

  return (
    <>
      <MobileLoader isLoading={useGetChatroomListHook.isLoading} />
      <S.MessageContainer onScroll={handleScroll} id={'eachChatRoom'}>
        <S.SearchContainer className="messageAddSearch">
          <MobileLoader isLoading={useGetChatroomListHook.isLoading} />
          <S.SearchInput
            type="text"
            placeholder="이름 및 번호 검색"
            value={debouncedSearchValue}
            onChange={(e) => onChangeSearch(e)}
          />
        </S.SearchContainer>

        {useGetChatroomListHook.formatData &&
        useGetChatroomListHook.formatData.length < 1 &&
        !useGetChatroomListHook.isLoading ? (
          <NoData />
        ) : (
          useGetChatroomListHook.formatData &&
          useGetChatroomListHook.formatData.map((list) => {
            return (
              <S.MessageListContainer
                key={list.phoneNumber}
                onClick={() => {
                  const { batch, chatRoomId, phoneNumber, buddyName } = list;

                  if (batch && chatRoomId) {
                    navigate(`/mobile/message/messageList/multiChatroom`);
                    setMultiChatRoomId(list.chatRoomId);
                    return; // 조건이 만족하면 바로 리턴
                  }

                  if (phoneNumber) {
                    navigate(`/mobile/message/messageList/${phoneNumber}`);
                    setChatUserInfo({ phoneNumber, buddyName });
                    setMobileBuddyListAddRecoilS([{ buddyNm: buddyName, keyCommNo: phoneNumber }]);

                    setMhQueryParams((prevState) => ({
                      ...prevState,
                      contactNumber: phoneNumber,
                    }));
                  }
                }}
              >
                <S.MessageImage batch={list.batch}>
                  {list.batch ? <IoLogoWechat size={'2.4rem'} /> : <IoChatbubbleSharp size={'2.4rem'} />}
                </S.MessageImage>

                <S.MessageContents>
                  <S.MessageContentsTop>
                    {/* {list.buddyName && list.batch ? <h3>{list.chatName}</h3> : <h3>{list.buddyName}</h3>} */}
                    {list.buddyName && <h3>{list.buddyName}</h3>}
                    {list.batch ? null : <div>{list.phoneNumber && TelHyphen(list.phoneNumber)}</div>}
                  </S.MessageContentsTop>

                  <S.MessageContentsBottom>
                    <div className="textContent">
                      {list.msgType === 'MMS' && (
                        <div className="iconGroup">
                          <p className="imageIcon">
                            <IconImage />
                          </p>
                        </div>
                      )}
                      {list.msgType === 'VMS' && (
                        <div className="iconGroup">
                          <p className="voiceIcon">
                            <IconCassetteTape fontSize={'1rem'} />
                          </p>
                        </div>
                      )}
                      {list.msgType === 'FILE' && (
                        <div className="iconGroup">
                          <p className="fileIcon">
                            <IconFile />
                          </p>
                        </div>
                      )}
                      {list.msgType === 'MMS' && (
                        <p className="text">
                          {list.sndMsg && removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '이미지'}
                        </p>
                      )}
                      {list.msgType === 'VMS' && (
                        <p className="text">
                          {list.sndMsg && removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '음성메시지'}
                        </p>
                      )}
                      {list.msgType === 'FILE' && (
                        <p className="text">
                          {list.sndMsg && removeWebCharacter(list.sndMsg, '[Web발신]').length < 1 && '파일'}
                        </p>
                      )}
                      {list.sndMsg && removeWebCharacter(list.sndMsg, '[Web발신]').length > 0 && (
                        <p className="text">{removeWebCharacter(list.sndMsg, '[Web발신]')}</p>
                      )}
                    </div>
                  </S.MessageContentsBottom>
                </S.MessageContents>

                <S.MessageInfo>
                  <div>{formatDateAll(list.msgDttm)}</div>
                  {list.rcvMsgCount > 0 && <span>{list.rcvMsgCount}</span>}
                </S.MessageInfo>
              </S.MessageListContainer>
            );
          })
        )}

        {useGetChatroomListHook.isFetchingNextPage && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.6rem',
              padding: '2rem 0',
            }}
          >
            <Spin size="default" />
            <span style={{ marginLeft: 8 }}>로딩 중...</span>
          </div>
        )}
      </S.MessageContainer>
    </>
  );
};

export default MobileMessageList;
