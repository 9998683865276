/**
 * @title 회원가입 사용자 정보 및 계정 입력 컴포넌트에서 사용하는 hook
 * */
import { getFormData, postFormData } from '@/apis/api/signUpApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import useValidation from '@/components/hooks/customs/signUp/useValidation';
import { formDataType } from '@/pages/SignUpPage/signUpForm/SignUpForm.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCheck015Number } from '../say015/say015Signup/useCheck015Number';
import { checkConfirm015Recoil } from '@/recoil/atoms/say015Atom';
import { useRecoilState } from 'recoil';

export const useSignUpForm = () => {
  const process: string = '2';
  const location = useLocation();
  const navigate = useNavigate();
  const info = location.state;
  const [checkInfo, setCheckInfo] = useState('');
  // 015 추천번호 Params STATE
  const [size, setSize] = useState<number>(10);
  // 개인, 법인 번호를 구분할 때 사용할 STATE 'personal', 'company'
  const [accountTypeState, setAccountType] = useState<string>('personal');
  // 추천번호 List 받는 STATE
  const [sug015List, setSug015List] = useState<[] | null>(null);

  const { schema, emailData } = useValidation();
  const { warningModal, successModal } = useModalHook();

  // TODO - 리팩토링후 삭제할 코드 015 번호발급 : true면 회원가입때
  const [checkConfirm015S, setCheckConfirm015S] = useRecoilState(checkConfirm015Recoil);

  // 015 번호처크 훅
  const numCheckHooks = useCheck015Number();

  // 회원가입 mutate
  const { mutate, isLoading } = useMutation(postFormData, {
    onSuccess: (response) => {
      if (response) {
        successModal('회원가입', '회원가입에 성공하셨습니다.', true, '/');
        setCheckConfirm015S(false);
      }
    },
    onError: (response: any) => {
      warningModal('회원가입', response, true);
    },
  });

  // 회원가입 화면에서 나이스인증을 통해 받아온 데이터로 이름, 휴대폰번호를 받아오는 useQuery
  const { data: niceData } = useQuery(['/signup/form-data'], () => getFormData(checkInfo, size, accountTypeState), {
    enabled: checkInfo !== '',
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<formDataType>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',

    defaultValues: {
      name: '',
      password: '',
      isPassword: '',
      numOne: '',
      numTwo: '',
      numThree: '',
      selectEmailOne: '',
      selectEmailTwo: 'arreo.com',
      smsRcvYn: 'N',
      emailRcvYn: 'N',
      // 015 관련 컬럼 추가
      num015One: '015',
      num015Two: '8504',
      num015Three: '',
    },
  });
  // encodeData 를 사용하여 state에 저장
  useEffect(() => {
    setCheckConfirm015S(true);
    setSize(10);
    setAccountType('personal');
    if (info && info.data2) {
      setCheckInfo(info.data);
    } else {
      setCheckInfo(info.data.encodeData);
    }
  }, [info, setValue]);

  // nice 인증으로 받아온 데이터를 form 초기 데이터에 넣어줌
  useEffect(() => {
    if (niceData) {
      setValue('name', niceData.inputName);
      setValue('numOne', niceData.inputNumber.slice(0, 3));
      setValue('numTwo', niceData.inputNumber.slice(3, 7));
      setValue('numThree', niceData.inputNumber.slice(7, 11));

      // 추천번호 리스트 담는 STATE
      setSug015List(niceData.recommends015.content);
    }
  }, [niceData, setValue]);

  // 이메일 선택값으로 selectEmailTwo 값 변경
  const handleSelectEmail = (email: any) => {
    setValue('selectEmailTwo', email);
  };

  const onSubmit = (data: formDataType) => {
    const signUpData: any = {
      phnId: data.numOne + data.numTwo + data.numThree,
      usrNm: data?.name,
      usrEmail: `${data?.selectEmailOne}@${data?.selectEmailTwo}`,
      smsRcvYn: data.smsRcvYn,
      emailRcvYn: data.emailRcvYn,
      usrPass: data?.password,
      number015: data.num015One + numCheckHooks.pickNumber.firstPart + numCheckHooks.pickNumber.secondPart,
      encodeData: checkInfo,
      subsPeriod: 6,
      certTerms1: true,
      certTerms2: true,
      certTerms3: true,
      serviceTerms1: true,
      serviceTerms2: true,
      serviceTerms3: true,
    };

    mutate(signUpData);
  };

  const onError = (errors: any) => {
    console.error('Form validation errors:', errors);
  };

  return {
    process,
    errors,
    watch,
    register,
    handleSubmit,
    onSubmit,
    handleSelectEmail,
    emailData,
    isLoading,
    navigate,
    onError,
    ...numCheckHooks,
    sug015List,
  };
};
