import React from 'react';
import * as S from '../GroupList.styles';
import { IGroupListBottom } from './GroupListBottom.types';
import IconTrash from '@/styles/icons/IconTrash';

/**
 * @title GroupList.tsx 컴포넌트의 bottom 컴포넌트
 *
 * @author 정휘학
 * @since 2024.03.15
 * */
const GroupListBottomIndex = (props: IGroupListBottom) => {
  return (
    <S.BottomWrap>
      <S.Bottom onClick={props.recycleBinClickHandle}>
        <S.BinBtnWrap>
          {/* <S.CustomDeleteIcon /> */}
          <IconTrash />
          <span>휴지통</span>
        </S.BinBtnWrap>
        <S.TrashNumber>{props.groupList?.recycleCount || '0'}</S.TrashNumber>
      </S.Bottom>
    </S.BottomWrap>
  );
};

export default GroupListBottomIndex;
