import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconAddChatting: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M13.3494 5.43744H21.3494M17.3494 1.43744V9.43744"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3494 1.43759C5.82642 1.43759 1.34943 5.91458 1.34943 11.4376C1.34943 13.0376 1.72543 14.5496 2.39243 15.8906C2.57042 16.2466 2.62942 16.6536 2.52643 17.0386L1.93143 19.2646C1.8725 19.4849 1.87255 19.7168 1.93158 19.937C1.99061 20.1573 2.10654 20.3582 2.26774 20.5195C2.42894 20.6808 2.62972 20.7968 2.84995 20.856C3.07018 20.9152 3.3021 20.9154 3.52242 20.8566L5.74842 20.2606C6.1348 20.1629 6.54356 20.2103 6.89742 20.3936C8.28037 21.0821 9.80453 21.4395 11.3494 21.4376C16.8724 21.4376 21.3494 16.9606 21.3494 11.4376"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default IconAddChatting;
