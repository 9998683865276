import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const ArsDropBoxWrap = styled.div`
  position: relative;
  width: 24rem;
  height: 10rem;
  background: transparent;
  border-radius: 1rem;
  border: 2px dashed ${(props) => props.theme.colors.gray400};

  &.dragOver {
    border-color: transparent;
    background: ${(props) => props.theme.colors.gray400};
    //box-shadow: rgba(0, 0, 0, 0.05) 0px 0.5rem 1rem 0px;
    box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.05);
    span {
      background-color: ${(props) => props.theme.colors.gray600};
    }
    transition: transform 0.3s ease;
    transform: scaleY(1.5) scaleX(1.1);
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 1.2rem;
    height: 0.2rem;
    border-radius: 20px;
    background: ${(props) => props.theme.colors.gray500};
    :nth-of-type(2) {
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
      left: 50%;
    }
  }
`;

interface IArsDropBox {
  id: string;
}

const ArsDropBox = ({ id }: IArsDropBox) => {
  const arsDropBoxRef = useRef<any>({});
  const arsCardOver = (id: string) => {
    if (arsDropBoxRef.current[id]) {
      arsDropBoxRef.current[id].classList.add('dragOver');
    }
  };

  return (
    <ArsDropBoxWrap
      ref={(el) => {
        arsDropBoxRef.current[id] = el;
      }}
      onDragOver={() => arsCardOver(id)}
      onDragLeave={() => arsDropBoxRef.current[id].classList.remove('dragOver')}
    >
      <span />
      <span />
    </ArsDropBoxWrap>
  );
};

export default ArsDropBox;
