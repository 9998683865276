import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.1em;
  height: 1em;
`;

const IconArrowLongLeft: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '1.8rem'}
      viewBox="0 0 21 19"
      fill="none"
    >
      <path
        d="M1.55902 9.54389H19.559M1.55902 9.54389L9.52056 1.54388M1.55902 9.54389L9.52056 17.5439"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconArrowLongLeft;
