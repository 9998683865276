import { getUserAddressRecycleBinList } from '@/apis/api/addressApis';
import { calScrollEvent } from '@/apis/hooks/useScrollInfinity';
import { IUseGetBinList } from '@/components/hooks/customs/addressBook/recycleBin/types/RecycleBinTypes';
import { binSearchTextState, binSelectValueState, binSuccessSearchRecoil } from '@/recoil/atoms/addressList';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

/**
 * @title 휴지통 리스트 데이터 GET Hooks
 *
 * @author 김남규
 * @since 2024.03.14
 * */
export const useGetBinList = ({ recycleBinOpen }: IUseGetBinList) => {
  const binSelectValueS = useRecoilValue(binSelectValueState);
  const binSearchTextS = useRecoilValue(binSearchTextState);
  // 검색 성공 후 검색어 담을 RECOIL
  const [binSuccessSearchS, setBinSuccessSearchS] = useRecoilState(binSuccessSearchRecoil);

  // ==============================
  // 휴지통 리스트 데이터 GET API Query
  // ==============================
  const {
    data: recycleBinList,
    isLoading: recycleBinListLoading,
    refetch: recycleBinRefetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['recycleBinList'],
    async ({ pageParam = 0 }) => {
      if (recycleBinOpen) {
        return getUserAddressRecycleBinList({
          currentPage: pageParam,
          keyword: binSearchTextS,
          // keywordValue: binSelectValueS,
        });
      }
      return null;
    },
    {
      enabled: recycleBinOpen, // recycleBinOpen이 true일 때만 쿼리 활성화
      getNextPageParam: (lastPage: any) => {
        const nextPage = lastPage.pageable.pageNumber + 1;
        return nextPage < lastPage.totalPages ? nextPage : undefined;
      },
      onSuccess: () => {
        setBinSuccessSearchS(binSearchTextS);
      },
      onError: (error) => {
        console.error('error', error);
      },
    },
  );

  // ==============================
  // 휴지통 리스트 스크롤 Hook
  // ==============================
  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (calScrollEvent({ scrollHeight, clientHeight, scrollTop }) && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  // ==============================
  // 휴지통 toggle 시 recycleBinList query refetch
  // ==============================
  useEffect(() => {
    if (recycleBinOpen) {
      // 페이지를 처음부터 다시 불러옵니다.
      recycleBinRefetch();
      setBinSuccessSearchS('');
    }
  }, [recycleBinOpen, binSearchTextS, binSelectValueS, recycleBinRefetch]);

  return {
    recycleBinList,
    recycleBinListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    recycleBinRefetch,
    handleScroll,
    binSuccessSearchS,
  };
};
