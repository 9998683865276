import styled from 'styled-components';

// 알림설정
export const NotifyGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 2.6rem;

  .gray {
    color: ${(props) => props.theme.colors.txt400};
  }
  > button {
    cursor: pointer;
    padding: 0;
  }
  /* 로그아웃 */
  .logout {
    color: ${(props) => props.theme.colors.txt400};
    font-size: 1.2rem;
    font-weight: 600;
  }
  /* 알림설정 */
  .pushTab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1em;
    font-weight: 600;
    color: ${(props) => props.theme.colors.txt400};
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      border: 2px solid ${(props) => props.theme.colors.sub1};
      border-radius: 50%;
    }
  }

  .menuBox {
    ul {
      left: auto;
      right: 0;
      transform: none;
    }
  }

  .menuTab {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.white};
    padding: 0;
    color: ${(props) => props.theme.colors.txt400};
    svg {
      margin-right: 0;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      width: 3rem;
      height: 3rem;
      border: 2px solid ${(props) => props.theme.colors.sub1};
      border-radius: 50%;
    }
    span {
      display: block;
      width: 3px;
      height: 3px;
      background: ${(props) => props.theme.colors.gray600};
      border-radius: 50px;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

// 알림 팝업
export const NotifyBox = styled.div`
  z-index: 200;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: ${(props) => props.theme.colors.white};
  font-size: 1.4rem;
  padding: 3rem 5%;
  backdrop-filter: blur(3px);
  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pushGroup {
    display: flex;
    gap: 2rem;

    h4 {
      font-size: 2rem;
      font-weight: 700;
      color: ${(props) => props.theme.colors.main};
    }
    > p {
      font-weight: 500;
      line-height: 1.4em;
      text-align: center;
    }
    /* 각 선택창 */
    > div {
      cursor: pointer;
      max-width: 51rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3rem;
      flex-direction: column;
      width: 50%;
      padding: 3rem;
      border-radius: 1rem;
      background: ${(props) => props.theme.colors.gray100};
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
      transition: 0.2s ease-in-out;
      transition-property: transform;
      :hover {
        transform: translateY(-2rem);
      }
      .txtBox {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        color: ${(props) => props.theme.colors.txt400};
        svg {
          margin-bottom: 2.4rem;
          width: 3.4rem;
          height: 3.4rem;
        }
        &.active {
          svg {
            path {
              :nth-of-type(1) {
                animation: test 2s linear infinite;
                stroke-dasharray: 82;
                stroke-dashoffset: 164;
              }
            }
          }
          @keyframes test {
            from {
              stroke-dashoffset: 0;
            }
          }
        }
        h4 {
          margin-bottom: 1.6rem;
        }
        p {
          line-height: 1.6em;
        }
      }
      img {
        max-width: 100%;
        height: auto;
      }
      &.active {
        outline: 3px solid ${(props) => props.theme.colors.main};
        svg {
          path {
            stroke: ${(props) => props.theme.colors.main};
          }
        }
      }
      /* 메세지알림 */
      &.messagePush {
        img {
          margin-bottom: -3rem;
        }
      }
    }
  }

  @media screen and (max-width: 968px) {
    .pushGroup {
      flex-wrap: wrap;
      > div {
        width: 100%;
        max-width: unset;
        padding: 2rem;
        font-size: 1.2rem;
        h4 {
          font-size: 1.6rem;
        }
        img {
          max-height: 20vmin;
        }
        &.messagePush {
          img {
            margin-bottom: -2rem;
          }
        }
      }
    }
  }
`;
