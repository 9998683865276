import styled from 'styled-components';

export const ApiInstructionModalContainer = styled.div`
  font-size: 1.4rem;
  line-height: 1.4em;

  h1 {
    padding: 0 0 0.6rem;
    margin: 0 0 1.2rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
    font-size: 2rem;
    font-weight: 800;
  }
  b {
    font-weight: 800;
  }

  table {
    width: 100%;
    margin: 2rem 0;

    th,
    td {
      border: 1px solid ${(props) => props.theme.colors.gray200};
      padding: 1rem;
      p + p {
        margin: 2rem 0 0;
      }
      .code {
        width: fit-content;
        padding: 0.4rem 0.6rem;
        background: ${(props) => props.theme.colors.gray100};
        color: ${(props) => props.theme.colors.red};
        border-radius: 0.4rem;
      }
    }
  }

  > div {
    padding: 0 0 3rem;
    > h2 {
      font-size: 2rem;
      font-weight: 600;
      margin: 0 0 1rem;
    }
    > h3 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0 0 1rem;
      padding: 1rem 0 0;
    }
  }

  .line {
    width: 100%;
    height: 0.2rem;
    background: ${(props) => props.theme.colors.gray500};
    margin: 3rem 0;
  }

  .tableBack {
    thead {
      background: ${(props) => props.theme.colors.gray100};
    }
  }

  @media screen and (max-width: 768px) {
    padding: 3rem 0 6rem;
  }
`;

export const Advance = styled.div`
  font-size: 1.6rem;
  h4 {
    margin: 0 0 1.8rem;
    font-size: 2rem;
    font-weight: 700;
  }
  .box {
    position: relative;
    padding: 0 0 0 1rem;
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 0.3rem;
      height: 100%;
      background: ${(props) => props.theme.colors.gray600};
    }
    p {
      margin: 0 0 2rem;
      &:last-of-type {
        margin: 0;
      }
    }
  }
`;

// 서비스 안내 박스
export const Announcement = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background: ${(props) => props.theme.colors.gray100};

  .top {
    display: flex;
    align-items: center;
    gap: 1rem;
    svg {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
  ul {
    padding: 1rem 0 1rem 2.8rem;
    li {
      margin: 0 0 1rem;
      &:last-of-type {
        margin: 0;
      }
    }
  }
`;

export const CodeWrap = styled.div`
  h3 {
    background: ${(props) => props.theme.colors.gray100};
    font-size: 1.6rem;
    font-style: italic;
    text-decoration: underline;
    padding: 0.6rem;
    margin: 0 0 1rem;
  }
  h4 {
    font-size: 1.6rem;
    padding: 0.6rem;
  }
`;
export const CodeBlock = styled.div`
  padding: 1.6rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray100};
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 0.5rem;
  font-size: 1.4rem;
  overflow-x: auto;
  margin: 0 0 1rem;
`;
