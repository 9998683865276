import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconTax: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2.4rem'}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.01761 1.4143C1.85781 1.4143 1.70456 1.47778 1.59157 1.59077C1.47857 1.70377 1.41509 1.85702 1.41509 2.01682V22.9816C1.41509 23.1414 1.47857 23.2946 1.59157 23.4076C1.70456 23.5206 1.85781 23.5841 2.01761 23.5841H19.0516C19.2114 23.5841 19.3646 23.5206 19.4776 23.4076C19.5906 23.2946 19.6541 23.1414 19.6541 22.9816V17.7401C19.6541 17.3493 19.9709 17.0325 20.3616 17.0325C20.7524 17.0325 21.0692 17.3493 21.0692 17.7401V22.9816C21.0692 23.5167 20.8566 24.0299 20.4782 24.4083C20.0999 24.7866 19.5867 24.9992 19.0516 24.9992H2.01761C1.48251 24.9992 0.96932 24.7866 0.590944 24.4083C0.212569 24.0299 0 23.5167 0 22.9816V2.01682C0 1.48171 0.212569 0.968526 0.590944 0.590151C0.969319 0.211775 1.48251 -0.000793457 2.01761 -0.000793457H11.1899C11.5807 -0.000793457 11.8975 0.315986 11.8975 0.706754C11.8975 1.09752 11.5807 1.4143 11.1899 1.4143H2.01761ZM24.7928 4.13726C25.0691 4.41357 25.0691 4.86157 24.7928 5.13788L16.9311 12.9995C16.6548 13.2758 16.2068 13.2758 15.9305 12.9995C15.6542 12.7232 15.6542 12.2752 15.9305 11.9989L23.7921 4.13726C24.0685 3.86095 24.5164 3.86095 24.7928 4.13726ZM16.3132 5.29291C16.3132 4.90214 16.63 4.58537 17.0207 4.58537H17.1516C17.5423 4.58537 17.8591 4.90214 17.8591 5.29291C17.8591 5.68368 17.5423 6.00046 17.1516 6.00046H17.0207C16.63 6.00046 16.3132 5.68368 16.3132 5.29291ZM22.8641 11.8439C22.8641 11.4531 23.1809 11.1363 23.5717 11.1363H23.7031C24.0939 11.1363 24.4107 11.4531 24.4107 11.8439C24.4107 12.2346 24.0939 12.5514 23.7031 12.5514H23.5717C23.1809 12.5514 22.8641 12.2346 22.8641 11.8439ZM5.24088 13.8093C5.24088 13.4185 5.55766 13.1017 5.94843 13.1017H11.1899C11.5807 13.1017 11.8975 13.4185 11.8975 13.8093C11.8975 14.2 11.5807 14.5168 11.1899 14.5168H5.94843C5.55766 14.5168 5.24088 14.2 5.24088 13.8093ZM5.24088 19.0508C5.24088 18.66 5.55766 18.3432 5.94843 18.3432H15.1208C15.5115 18.3432 15.8283 18.66 15.8283 19.0508C15.8283 19.4415 15.5115 19.7583 15.1208 19.7583H5.94843C5.55766 19.7583 5.24088 19.4415 5.24088 19.0508Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconTax;
