import { mobileNavRecoil, softPhoneComponentRecoil, windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { arsIsActiveRecoil } from '@/recoil/atoms/say015Atom';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import DisplayIndex from './DisplayGroups/DisplayIndex';
import * as S from './SoftPhone.styles';
import ToolBarIndex from './Toolbar/ToolBarIndex';

const SoftPhone = () => {
  const [arsIsActiveS, setArsIsActiveS] = useRecoilState(arsIsActiveRecoil);

  // 소프트폰 컴포넌트 상태
  const softPhoneComponentS = useRecoilValue(softPhoneComponentRecoil);
  // 반응형 값 가져오기 768 기준
  const windowWidth = useRecoilValue(windowWidthRecoil);

  const handleButtonClick = () => {
    setArsIsActiveS((prev) => !prev);
  };

  // 반응형일때 ars 소프트폰 안띄우기 위함
  useEffect(() => {
    if (windowWidth > 768) return;
    setArsIsActiveS(false);
  }, [windowWidth]);

  const mobileNavState = useRecoilValue(mobileNavRecoil);

  return (
    <>
      {windowWidth > 768 ? (
        <S.SoftPhoneContainer
          className={arsIsActiveS ? 'SoftPhoneContainer active' : 'SoftPhoneContainer'}
          isActive={mobileNavState === '메세지'}
        >
          <div className="openArrow">
            <button onClick={handleButtonClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="33" height="42" viewBox="0 0 33 42" fill="none">
                <path
                  d="M10.6583 41.6667C9.69861 41.6667 8.89792 41.3458 8.25625 40.7042C7.61458 40.0625 7.29306 39.2618 7.29167 38.3021V27.0833H9.375V34.375H30.2083V7.29167H9.375V14.5833H7.29167V3.36667C7.29167 2.40694 7.61319 1.60625 8.25625 0.964583C8.8993 0.322917 9.7 0.00138889 10.6583 0H28.9271C29.8854 0 30.6854 0.321528 31.3271 0.964583C31.9688 1.60764 32.2903 2.40833 32.2917 3.36667V38.3021C32.2917 39.2604 31.9708 40.0611 31.3292 40.7042C30.6875 41.3472 29.8861 41.6681 28.925 41.6667H10.6583ZM15.625 27.725L14.15 26.25L18.5021 21.875H0V19.7917H18.5L14.1521 15.4167L15.625 13.9417L22.5167 20.8333L15.625 27.725Z"
                  fill="currentColor"
                />
              </svg>
              <p>문자전송</p>
            </button>
          </div>

          <S.SoftPhoneInner className="SoftPhoneInner">
            <S.SoftPhoneBlock>
              {/* 상단툴바 */}
              <ToolBarIndex />
              {/* 디스플레이 */}
              <DisplayIndex />
            </S.SoftPhoneBlock>
          </S.SoftPhoneInner>
        </S.SoftPhoneContainer>
      ) : (
        softPhoneComponentS !== 'ars' && (
          <S.SoftPhoneContainer
            className={arsIsActiveS ? 'SoftPhoneContainer active' : 'SoftPhoneContainer'}
            isActive={mobileNavState === '메세지'}
          >
            <div className="openArrow">
              <button onClick={handleButtonClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="42" viewBox="0 0 33 42" fill="none">
                  <path
                    d="M10.6583 41.6667C9.69861 41.6667 8.89792 41.3458 8.25625 40.7042C7.61458 40.0625 7.29306 39.2618 7.29167 38.3021V27.0833H9.375V34.375H30.2083V7.29167H9.375V14.5833H7.29167V3.36667C7.29167 2.40694 7.61319 1.60625 8.25625 0.964583C8.8993 0.322917 9.7 0.00138889 10.6583 0H28.9271C29.8854 0 30.6854 0.321528 31.3271 0.964583C31.9688 1.60764 32.2903 2.40833 32.2917 3.36667V38.3021C32.2917 39.2604 31.9708 40.0611 31.3292 40.7042C30.6875 41.3472 29.8861 41.6681 28.925 41.6667H10.6583ZM15.625 27.725L14.15 26.25L18.5021 21.875H0V19.7917H18.5L14.1521 15.4167L15.625 13.9417L22.5167 20.8333L15.625 27.725Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>

            <S.SoftPhoneInner className="SoftPhoneInner">
              <S.SoftPhoneBlock>
                {/* 상단툴바 */}
                <ToolBarIndex />
                {/* 디스플레이 */}
                <DisplayIndex />
              </S.SoftPhoneBlock>
            </S.SoftPhoneInner>
          </S.SoftPhoneContainer>
        )
      )}
    </>
  );
};

export default SoftPhone;
