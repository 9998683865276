import * as yup from 'yup';

export const tableAddSchema = yup.object().shape({
  buddyNm: yup.string().required(),
  keyCommNo: yup
    .string()
    .matches(/^(01[0156789]|02|0[3-9][0-9])[- ]?[0-9]{3,4}[- ]?[0-9]{4}$/)
    .required(), // 숫자만 허용하는 패턴
  emailId: yup.string().test((value) => {
    if (value === null || value === '') return true;
    const trimmedValue = value?.trim() || '';
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedValue);
  }),
  etcInfo: yup.string(),
});

export const infoCardSchema = yup.object().shape({
  buddyNm: yup.string().required(),
  keyCommNo: yup
    .string()
    .matches(/^\d{2,3}-?\d{3,4}-?\d{4}$/)
    .required(), // 숫자와 하이픈이 있는 전화번호 패턴
  emailId: yup.string().test((value) => {
    if (value === null || value === '') return true;
    const trimmedValue = value?.trim() || '';
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedValue);
  }),
  etcInfo: yup.string(),
});
