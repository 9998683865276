import { postAddLargeList } from '@/apis/api/addressApis';
import { fullScreenSpinLoadingRecoil } from '@/recoil/atoms/atoms';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

/**
 * @title 주소록 대량추가 post Mutate Hooks
 *
 * @author 김남규
 * @since 2024-06-15
 */
export const useMutationAdd = () => {
  const setFullScreenSpinLoadingR = useSetRecoilState(fullScreenSpinLoadingRecoil);

  const { mutate: postLargeListM, isLoading: postLargeListIsLoading } = useMutation(postAddLargeList);

  useEffect(() => {
    setFullScreenSpinLoadingR(postLargeListIsLoading);
  }, [postLargeListIsLoading]);

  return { postLargeListM, postLargeListIsLoading };
};
