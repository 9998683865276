import styled from 'styled-components';

export const MessageSend = styled.div`
  margin: 0 0 1rem;
  max-height: 100%;

  @media screen and (max-height: 880px) {
    /* overflow-y: auto; */
    padding-bottom: 1rem;
    padding: 0 1rem;
    margin: 0 -1rem 1rem -1rem;
    ::-webkit-scrollbar {
      /* display: none; */
      width: 4px;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }
  }
`;
export const MessageSendWrap = styled.div`
  /* z-index: 1000; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1rem;
  font-weight: 600;
  background: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  &:has(.sendContentGroup textarea:focus) {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  }

  .none {
    display: none;
  }
  .flexBox {
    display: flex;
    flex-wrap: wrap;
  }
  .flexBox-js {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* @media screen and (max-width: 768px) {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  } */
`;

// 이미지확대 Wrap
export const MessageSendImgModal = styled.div`
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  > div {
    position: relative;
    max-width: calc(90% - 30px);
    max-height: 90%;
  }
  button {
    position: absolute;
    top: -30px;
    right: -30px;
  }
  img {
    width: 100%;
    max-height: calc(90vh - 30px);
    object-fit: contain;
  }
`;

export const MessageSendGroup = styled.div`
  height: 100%;
  overflow: hidden auto;
  overscroll-behavior: contain;
  margin: 0px -1rem;
  padding: 0px 1rem;
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    border-radius: 3rem;
  }
`;

export const Toggle = styled.div`
  margin-left: 10px;
  button {
    width: 80px;
    padding: 0.55rem 1.2rem;
    border: 0;
    border-radius: 50px;
    background: ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
    font-size: 1.2rem;
  }
  button.active {
    //background: rgba(0,0,0,0.8);
    background: ${(props) => props.theme.colors.gray800};
    color: ${(props) => props.theme.colors.white};
  }
`;

// 수신인 등록
export const AddressDestinationGroup = styled.div`
  margin-bottom: 2rem;

  .OpenArrowBtn {
    z-index: 99;
    cursor: pointer;
    position: relative;
    /* top: calc(100% - 1rem); */
    left: 50%;
    transform: translateX(-50%);
    border: 0;
    padding: 0.8rem 3.1rem;
    background-color: ${(props) => props.theme.colors.red};
    border-radius: 0 0 1.5rem 1.5rem;
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      margin-bottom: 0;
      svg {
        transform: rotate(-180deg);
        margin-bottom: 0;
      }
    }
  }
`;

// 타이틀
export const TextTitle = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.4rem;
`;

export const AddBtn = styled.button`
  background-color: red;
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const AddressAdd = styled.div`
  position: relative;
  width: calc(100% - 8rem);
  label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 25px;
    max-width: calc(100% - 3rem);
    padding: 0 1rem;
    background: ${(props) => props.theme.colors.gray100};
    border-radius: 5px;
    input {
      padding-left: 0;
      padding-right: 1rem;
      font-size: 1.4rem;
      width: 100%;
      background: transparent;
      color: ${(props) => props.theme.colors.txt500};
    }
    input::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
    }
    input:focus {
      outline: none;
    }
    button {
      border: 0;
      padding: 0;
    }
    button svg {
      width: 1.4rem;
      height: 1.3rem;
    }
  }
  @media screen and (max-width: 768px) {
    border-radius: 0.4rem;
    width: 100%;
    label {
      max-width: unset;
      background: transparent;
    }
  }
`;

export const RecipientsList = styled.div`
  position: relative;
  display: flex;
  gap: 0.6rem;
  justify-content: space-between;
  font-size: 1.3rem;
  padding: 0.7rem 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  white-space: nowrap;
  min-height: 3.8rem;

  :has(.recipients li) form {
    margin-bottom: 0.5rem;
  }

  &.active {
    margin-bottom: 0;
  }

  .addressBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    min-width: 2.3rem;
    height: 2.5rem;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.main};
    .arrowRight {
      color: ${(props) => props.theme.colors.gray600};
    }
    .btn {
      position: relative;
      span {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 0.8rem;
        height: 0.1rem;
        background: ${(props) => props.theme.colors.txt500};
      }
    }
    .plusBtn {
      span {
        :nth-of-type(2) {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }
  > h3 {
    margin-top: 6px;
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.txt400};
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 1rem;
  }
`;

export const RecipientsGroup = styled.div`
  width: calc(100% - 2.4rem);

  &.active {
    max-height: 14rem;
    overflow-y: auto;
    .recipients {
      width: 100%;
      flex-wrap: wrap;
      white-space: normal;
      align-items: flex-start;
      .smallContents {
        cursor: pointer;
      }

      .recipientTitle {
        width: auto;
        height: auto;
        line-height: normal;
        padding: 0;

        p {
          word-break: break-all;
          overflow-wrap: break-word;
          white-space: normal;
        }
      }

      > li {
        width: 100%;
        max-width: calc(100% - 0.5rem);
        margin-bottom: 0.6rem;
        .smallContents {
          flex-wrap: wrap;
          gap: 0.2rem;
          padding: 0.35rem 1rem;
        }
        button {
          padding-right: 0;
        }
      }
    }
    .arrowBtn {
      position: absolute;
      background: ${(props) => props.theme.colors.gray800};

      svg {
        color: ${(props) => props.theme.colors.txt500};
        transform: rotate(180deg);
      }
    }
  }

  .recipients {
    width: calc(100% - 6px);
    display: flex;
    overflow-x: auto;
    column-gap: 0.5rem;
    color: ${(props) => props.theme.colors.txt500};

    :last-child {
      margin-bottom: 0;
    }
    // overflow 스크롤
    ::-webkit-scrollbar {
      display: none;
      width: 0.4rem;
      height: 0.4rem;
      background-color: #e5e4e4;
      border-radius: 40px;
    }
    ::-webkit-scrollbar-thumb {
      background: #98999a;
      border-radius: 40px;
    }
    .name {
      color: ${(props) => props.theme.colors.txt200};
      font-weight: 500;
    }

    > li {
      position: relative;
      background: ${(props) => props.theme.colors.gray100};
      border-radius: 0.5rem;

      &.active {
        background: ${(props) => props.theme.colors.gray400};
      }
      /* overflow scroll시 margin-bottom 필요함 */
      /* margin-bottom: 0.6rem; */

      button.arrowDown {
        display: none;
      }
    }
    /* 버튼 active */
    > li ul {
      display: none;
    }

    /* 명수 */
    .countNum {
      color: ${(props) => props.theme.colors.txt200};
      font-weight: 500;
      cursor: pointer;
    }
    /* 버튼 active */
    .recipientTitle {
      width: 100%;
      height: 2.5rem;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
      }
    }
    .smallContents {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      padding: 0 1rem;
      line-height: normal;

      .state {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        margin-left: auto;
      }
    }
    .numberList {
      max-height: 12rem;
      overflow-y: auto;
      margin: 2rem 0.5rem 0 0;
      padding: 0 2rem 0 0;
      // overflow 스크롤
      ::-webkit-scrollbar {
        width: 0.4rem;
        background-color: #e5e4e4;
        border-radius: 40px;
      }
      ::-webkit-scrollbar-thumb {
        background: #98999a;
        border-radius: 40px;
      }

      li {
        margin-bottom: 1.4rem;
        &:last-child {
          margin-bottom: 0.1rem;
        }
      }
      .moreDetails {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 3rem;
      }
    }
  }

  .arrowBtn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    margin-top: 2px;
    background: ${(props) => props.theme.colors.gray800};
    border-radius: 50%;
    svg {
      path {
        stroke: ${(props) => props.theme.colors.gray100};
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const BtnWhiteGray = styled.div`
  button {
    color: ${(props) => props.theme.colors.txt200};
  }
`;

export const AddresContentGroup = styled.div<any>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.5rem 1.2rem;
  min-height: 5rem;

  button {
    color: ${(props) => props.theme.colors.txt200};
  }

  &:has(.ant-picker) .byteSend {
    width: 100%;
    margin-top: 0.5rem;
  }
  .byteSend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tabBox {
    position: relative;
    display: flex;
    align-items: center;

    :has(.dateTable.active) {
      > .dateTable {
        height: auto;
      }
    }
    > div {
      height: 2rem;
    }

    :has(.dateTable.active) {
      width: 100%;
    }
    button {
      cursor: pointer;
      padding: 0;
      line-height: 0;
    }

    svg {
      max-width: 2.5rem;
      margin: 0 0.5rem;
      width: 2rem;
      height: 2rem;
      :hover {
        &.stroke {
          path {
            stroke: ${(props) => props.theme.colors.main};
          }
        }
        &.fill {
          path {
            fill: ${(props) => props.theme.colors.main};
          }
        }
      }
    }
    > div {
      :first-of-type {
        svg {
          margin-left: 0;
        }
      }
    }
    .deleteButton {
      position: absolute;
      top: -12px;
      right: -12px;
      background: ${(props) => props.theme.colors.gray500};
      border-radius: 50%;
      width: 24px;
      height: 24px;
      border: 2px solid ${(props) => props.theme.colors.white};
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  /* 이미지 버튼스타일 */
  .imageButton {
    button {
      max-width: 35px;
      position: relative;
    }
  }
  /* ad 광고 버튼스타일 */
  .adButton {
    &.active {
      svg {
        path {
          stroke: ${(props) => props.theme.colors.main};
        }
      }
    }
  }

  .mockSend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  /* 날짜 테이블 */
  .dateTable {
    position: relative;
    width: 100%;

    &.active {
      display: flex;
      align-items: center;
      background-color: ${(props) => props.theme.colors.gray200};
      max-height: 3.6rem;
      border-radius: 0.5rem;
      input {
        color: ${(props) => props.theme.colors.txt500};
      }
      .clockIcon {
        svg {
          path {
            fill: ${(props) => props.theme.colors.txt500};
          }
        }
      }
    }
    .clockIcon {
      cursor: pointer;
      z-index: 1;
    }
    > div {
      width: 100%;
      background: transparent;
      border-color: transparent;
      > div {
        > input {
          text-align: center;
          font-size: 1.6rem;
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    /* 예약이 있을때 반응형 */
    &:has(.active.dateTable) {
      flex-direction: column;
      gap: 1rem;
      .byteSend {
        width: 100%;
      }
      .tabBox {
        width: 100%;
      }
      .dateTable {
        > div {
          min-width: auto;
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    /* flex-direction: column;
    gap: 1rem; */

    /* .byteSend {
      width: 100%;
    }
    .tabBox {
      width: 100%;
    } */
    .dateTable {
      > div {
        min-width: auto;
      }
    }
  }
`;

export const Transmissiondetails = styled.div<any>`
  position: relative;
  /* 제목 스타일 */
  .titleGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.4rem 0.4rem;
    color: ${(props) => props.theme.colors.gray700};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
    font-size: 1.6rem;
    font-weight: 500;
    input {
      width: calc(100% - 42px);
      border: 0;
      color: ${(props) => props.theme.colors.gray700};
      font-size: 1.6rem;
      :focus {
        outline: none;
      }
    }
  }
  /* 광고 스타일 */
  .adGroup {
    padding: 1rem 1.4rem 0;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.gray500};
    display: flex;
    align-items: center;
    .adNotice {
      width: 1.4rem;
      height: 1.4rem;
      border: 1px solid ${(props) => props.theme.colors.red};
      font-size: 1.2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.red};
      margin-right: 0.5rem;
      font-weight: 600;
      :hover {
        background-color: ${(props) => props.theme.colors.red};
        color: ${(props) => props.theme.colors.white};
      }
    }
    button {
      font-size: 1.2rem;
      color: ${(props) => props.theme.colors.red};
      border: 1px solid ${(props) => props.theme.colors.red};
      border-radius: 8px;
      padding: 0.2rem;
      :hover {
        background-color: ${(props) => props.theme.colors.red};
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
  /* 내용 스타일 */
  .SendTextBox {
    position: relative;
    width: 100%;
    background: transparent;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
    .sendContentGroup {
      padding: ${({ subjectBoolean }) => (subjectBoolean ? '1rem 1.2rem 1.5rem' : '1rem 1.2rem 1.5rem')};
      border-bottom: 1px solid ${(props) => props.theme.colors.gray300};

      textarea {
        width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
        resize: none;
        /* min-height: calc(var(--vh, 1vh) - 82rem); */
        font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        //max-height: calc(var(--vh, 10vh) - 78rem);
        /* max-height: calc(var(--vh, 10vh) - 65rem); */
        max-height: 20rem;
        min-height: 49px;
        color: ${(props) => props.theme.colors.txt500};
        // overflow 스크롤
        ::placeholder {
          color: ${(props) => props.theme.colors.placeholder};
          font-size: 1.6rem;
        }
        ::-webkit-scrollbar {
          width: 0.4rem;
          background-color: #e5e4e4;
          border-radius: 40px;
        }
        ::-webkit-scrollbar-thumb {
          background: #98999a;
          border-radius: 40px;
        }
      }
    }
  }
  /* 무료거부 스타일 */
  .freeRefusal {
    p {
      font-size: 1.4rem;
      /* color: ${(props) => props.theme.colors.gray500}; */
      padding: 1rem 0;
      /* border-bottom: 1px solid ${(props) => props.theme.colors.gray300}; */
    }
  }
  .sendImage {
    display: flex;
    max-width: 25rem;
    width: 80%;
    &:has(audio) {
      max-width: 100%;
    }

    .imgBox {
      cursor: pointer;
      width: 100%;
      margin-bottom: 1rem;
      max-width: calc(100% - 2.4rem);

      img,
      video,
      audio {
        display: block;
        width: 100%;
        max-height: 150px;
        border-radius: 10px;
        object-fit: contain;
      }
      audio {
        max-height: 4rem;
      }

      .fileName {
        font-size: 1.2rem;
        background-color: ${(props) => props.theme.colors.gray300};
        padding: 1rem;
        border-radius: 0.5rem;
        font-weight: 700;
        word-break: break-all;
      }
    }
    &:has(audio) {
      width: 100%;
      .imgBox {
        max-width: unset;
      }
    }
  }
  .deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    background-color: ${(props) => props.theme.colors.gray700};
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.colors.gray100};
    margin: -1rem 0 0 -0.5rem;
    padding: 0;
    svg {
      path {
        stroke: ${(props) => props.theme.colors.gray100};
        fill: ${(props) => props.theme.colors.gray100};
      }
    }
  }
`;

export const ActionButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${(props) => props.theme.colors.gray200};
  padding: 2px;
  border-radius: 1.5rem;
  border: 1px solid ${(props) => props.theme.colors.gray400};
  margin-top: 1rem;
  button {
    width: 100%;
    padding: 1.2rem 1rem;
    color: ${(props) => props.theme.colors.gray600};
    font-weight: 900;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    > p {
      font-size: 1.3rem;
    }
    :first-child {
      border-radius: 1.4rem 0 0 1.4rem;
      border-left-color: transparent;
    }
    :last-child {
      background: ${(props) => props.theme.colors.main};
      color: ${(props) => props.theme.colors.txt100};
      border-radius: 0 1.4rem 1.4rem 0;
      border: 1px solid ${(props) => props.theme.colors.main};
    }
    &.unactive {
      background: ${(props) => props.theme.colors.deactivation};
      border-color: ${(props) => props.theme.colors.deactivation};
      color: ${(props) => props.theme.colors.txt100};
    }
    /*
    &.grayactive {
      border: 1px solid #98999a;
    }
    */
  }
  button.active {
    border: 1px solid ${(props) => props.theme.colors.main};
    border-right: 1px solid ${(props) => props.theme.colors.main};
    border-left: 1px solid ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.main};
    font-weight: 900;
  }
`;

export const SendButtonGroup = styled.div`
  > div {
    text-align: center;
    width: 100%;
    svg {
      path {
        fill: ${(props) => props.theme.colors.disable};
      }
    }
    /* 확인 클릭시 */
    &.active {
      svg {
        path {
          fill: ${(props) => props.theme.colors.active};
        }
      }
    }
  }
  .checkButton {
    display: flex;
    align-items: center;
    padding: 0 0 0 0.5rem;
    font-size: 1.8rem;
    font-weight: 400;
    border-radius: 100px;
    svg {
      width: 2.2rem;
      height: 2rem;
    }
    div {
      > p {
        min-width: calc(50% - 1rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
      }
      b {
        font-size: 1.5rem;
        color: #191919;
        font-weight: 700;
      }
    }
  }
  .sendButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.main};
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  @media screen and (max-width: 460px) {
    .checkButton {
      width: 100%;
      height: auto;
    }
    .sendButton {
      width: 100%;
      border-radius: 50px;
    }
    > div {
      align-items: center;
      flex-direction: column;
    }
  }
  @media screen and (max-width: 375px) {
    .checkButton {
      font-size: 3vw;
    }
  }
`;
