import { useMemberLeave } from '@/components/hooks/customs/myPage/useMemberLeave';
import { mainTableWidthRecoil, userPointRecoil } from '@/recoil/atoms/atoms';
import { activeChatRoomIdRecoil, chatUserInfoRecoil } from '@/recoil/atoms/chatRoom';
import {
  adStateRecoil,
  fileResultRecoil,
  fileStateRecoil,
  sndMsgRecoil,
  sndMsgSubjectRecoil,
} from '@/recoil/atoms/messageSend/messageSend';
import {
  leftZoneComponentRecoil,
  softPhoneComponentRecoil,
  softPhoneTopTabRecoil,
  SoftPhoneTopTabRecoilType,
} from '@/recoil/atoms/router/mainRouterRecoil';
import { msg015ChatRoomToggleRecoil, msgResultPageRecoil, msgResultToggleRecoil } from '@/recoil/atoms/say015Atom';
import { resultDetailParamsRecoil, scheduleDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import { signInRecoil } from '@/recoil/atoms/signInAtom';
import { my015StatusRecoil } from '@/recoil/atoms/use015Status';
import { userInfoRecoil } from '@/recoil/atoms/useInfo';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMsgRecoilReset } from '../useMsgRecoilReset';
import { usePushNotification } from '@/apis/hooks/usePushNotification';
import { calculateCounts } from '@/shared/util/format/amountFormatUtil';

export const useToolBarListPage = () => {
  const [signInState, setSignInState] = useRecoilState(signInRecoil);
  const [softPhoneTopTabState, setSoftPhoneTopTabState] = useRecoilState(softPhoneTopTabRecoil);
  const setSoftPhoneComponent = useSetRecoilState(softPhoneComponentRecoil);
  /* 유저 프로필 정보 */
  const userInfoS = useRecoilValue(userInfoRecoil);
  /* 유저 포인트 정보 */
  const userPointS = useRecoilValue(userPointRecoil);

  /* 015 무료 발송 포인트 */
  const my015StatusS = useRecoilValue(my015StatusRecoil);
  /* 015 무료 발송 건수 */
  const [free015Msg, setFree015Msg] = useState<any>({});

  /* 이미지 파일 URL STATE */
  const [, setFileResult] = useRecoilState(fileResultRecoil);
  /* 이미지 파일 STATE */
  const [, setFileState] = useRecoilState(fileStateRecoil);
  /* 메세지 전송 제목 STATE */
  const [, setSndMsgSubjectState] = useRecoilState(sndMsgSubjectRecoil);
  /* 메세지 전송 내용 STATE */
  const [, setSndMsgState] = useRecoilState(sndMsgRecoil);

  /* 광고 문자인지 아닌지 판단하는 state */
  const [, setAdMessageS] = useRecoilState(adStateRecoil);

  // 채팅방
  /* 메인 주소록 테이블 width  */
  const [, setMainTableWidth] = useRecoilState(mainTableWidthRecoil);
  /* 현재 활성화된 채팅방 아이디 */
  const [, setActiveChatRoomId] = useRecoilState(activeChatRoomIdRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const [, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);
  /* 015채팅받 닫기 */
  const setMsg015ChatRoomToggleR = useSetRecoilState(msg015ChatRoomToggleRecoil);

  // 전송결과
  const setResultDetailParamsState = useSetRecoilState(resultDetailParamsRecoil);
  const leftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  /* 010 전송결과 페이지 토글 Recoil */
  const setMsgResultToggleS = useSetRecoilState(msgResultToggleRecoil);
  /* 010 전송결과인지 예약전송인지 Recoil */
  const setMsgResultPageS = useSetRecoilState(msgResultPageRecoil);

  // 예약전송
  const [, setScheduleDetailParamsState] = useRecoilState(scheduleDetailParamsRecoil);

  /* 웹푸시 hooks */
  const pushHooks = usePushNotification();

  /* 리코일 reset hooks */
  const resetHooks = useMsgRecoilReset();

  const [menuList] = useState<any>([
    { name: '015 Mode', tap: '0' },
    { name: '010 Mode', tap: '1' },
  ]);

  const user = localStorage.getItem('user');

  const { getUseInfoRefetch } = useMemberLeave();

  const navigate = useNavigate();

  const onClickSignOut = () => {
    pushHooks.unsubscribe();
    setSignInState(false);
    navigate('/');
  };

  useEffect(() => {
    if (!signInState) {
      navigate('/signin');
    }
  }, [signInState]);

  const onClickMyPage = () => {
    getUseInfoRefetch();
    // 리코일 초기화
    resetHooks.resetRecoil();
    navigate('/userinfochange');
  };

  const onClickNavigation = (path: string) => {
    navigate(path);
  };
  const onClickTap = (tap: SoftPhoneTopTabRecoilType) => {
    setFileResult(null);
    setFileState(null);
    setSndMsgSubjectState('');
    setSndMsgState('');
    setAdMessageS(false);
    // 015 채팅방
    setMsg015ChatRoomToggleR(false);
    setActiveChatRoomId(null);
    setChatUserInfo({ phoneNumber: null, buddyName: null });
    // 010 토글 공통
    setMsgResultPageS(null);
    setMsgResultToggleS(false);
    leftZoneComponentS('address');
    // 010 전송결과
    setResultDetailParamsState({ prepayPayNo: '', callback: '' });
    // 010 예약전송
    setScheduleDetailParamsState({ prepayPayNo: '', callback: '' });
    // 주소록 넓이
    setMainTableWidth(0);

    if (tap === '0') {
      setSoftPhoneComponent('chatroom');
    } else if (tap === '1') {
      setSoftPhoneComponent('send');
    } else if (tap === '2') {
      setSoftPhoneComponent('chatroom');
    }
    setSoftPhoneTopTabState(tap);
  };

  useEffect(() => {
    if (typeof my015StatusS?.say015Point !== 'number') return;
    const totalAmount = my015StatusS.say015Point;
    const counts = calculateCounts(totalAmount);
    setFree015Msg(counts);
  }, [my015StatusS]);

  return {
    onClickNavigation,
    onClickMyPage,
    onClickSignOut,
    user,
    menuList,
    softPhoneTopTabState,
    onClickTap,
    setSoftPhoneComponent,
    userPointS,
    userInfoS,
    free015Msg,
  };
};
