import { Skeleton } from 'antd';
import { SkeletonNodeProps } from 'antd/es/skeleton/Node';
import { SkeletonProps } from 'antd/lib';
import { SkeletonButtonProps } from 'antd/lib/skeleton/Button';
import { SkeletonImageProps } from 'antd/lib/skeleton/Image';
import { SkeletonInputProps } from 'antd/lib/skeleton/Input';
import React from 'react';

interface ISkeletonBox
  extends SkeletonProps,
    SkeletonInputProps,
    SkeletonButtonProps,
    SkeletonImageProps,
    SkeletonNodeProps {
  type: '1' | '2' | '3' | '4' | '5';
}

/**
 * @title 스켈레톤 UI 컴포넌트 ( 조합해서 새로운 케이스 만들어서 사용가능 )
 *
 * @author 김남규
 * @since 2024-05-03
 */
const SkeletonBox = ({ type, ...rest }: ISkeletonBox) => {
  const initClassName = 'skeletonInvert';

  // 사용하는 곳에서 호출한뒤 타입 지정해주고 원하는 스타일(...rest에 포함됨) 적용 하면됩니다.
  switch (type) {
    case '1':
      return <Skeleton {...rest} className={initClassName} />;
    // case '2':
    //   return <Skeleton.Avatar {...rest} />;
    case '2':
      return <Skeleton.Button {...rest} className={initClassName} />;
    case '3':
      return <Skeleton.Image {...rest} className={initClassName} />;
    case '4':
      return <Skeleton.Input {...rest} className={initClassName} />;
    case '5':
      return <Skeleton.Node {...rest} className={initClassName} />;
    default:
      return null;
  }
};

export default SkeletonBox;
