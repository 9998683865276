import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line import/no-cycle
import { messageResultExcelDownload } from '../../../../apis/api/pointApi';

export interface messageExcelDownload {
  searchDates: string[] | string;
  searchSelectValue: string | null;
}

export const useMutationMessageExcelDown = ({ searchDates, searchSelectValue }: messageExcelDownload) => {
  const { mutate: mutationExcelDown, isLoading: isExcelLoading } = useMutation(() =>
    messageResultExcelDownload({ searchDates, searchSelectValue }),
  );

  return {
    mutationExcelDown,
    isExcelLoading,
  };
};
