import styled from 'styled-components';

// 테이블을 감싸는 컨테이너
export const AWrapper = styled.div`
  margin: 0 0 4rem 0;
`;
// 테이블을 감싸거나 분류용 - 타이틀이 있음
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 4rem;
  input[type='text'] {
    border-color: ${(props) => props.theme.colors.gray500};
  }
  @media screen and (max-width: 768px) {
    gap: 0.5rem;
  }
`;

export const CallingWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 0 0 4rem 0;
  gap: 2rem;
  border-top: 2px solid ${(props) => props.theme.colors.gray500};

  @media screen and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;

// 버튼 컨테이너
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0 0 1rem;
  @media screen and (max-width: 768px) {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    padding: 0 0 0.5rem;
  }
`;

// 이메일박스 폼
export const EmailBoxForm = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  select {
    font-size: 1.4rem;
  }
  .emailInputWrap {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    .emailInputWrap {
      width: 100%;
      gap: 0.5rem;
    }
  }
  @media screen and (max-width: 640px) {
    select {
      font-size: 1rem;
    }
  }
`;

// 서울이동통신 이메일 지정 체크박스
export const AgreeCheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  .roundCheck {
    font-size: 1.4rem;
    gap: 0.5rem;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    .roundCheck {
      font-size: 1.3rem;
    }
  }
`;
// 이메일 입력인풋
export const EmailInput = styled.input`
  height: 100%;
  width: 50%;
  max-width: 200px;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.txt500};
  background: transparent;
  /* font-weight: lighter; */

  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;
// 주소창 컨테이너
export const AddressContainer = styled.div`
  width: 100%;
`;
// 주소창 이름, 인풋 워랩
export const AddressWrap = styled.div`
  width: 100%;
  display: flex;
  margin: 0.5rem 0;
  .inputWrap {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    max-width: 60rem;
    margin: 0 1.8rem 0 0;
  }
  .btnWrap {
    display: flex;
    gap: 1rem;

    button:nth-of-type(2) {
      background: ${(props) => props.theme.colors.grayButton};
    }
  }
  .flexBtn {
    display: flex;
    gap: 1rem;
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;

    .inputWrap {
      width: 100%;
      flex-wrap: wrap;
      max-width: unset;
      margin: 0;
      > div {
        width: 100%;
        p {
          font-size: 1.4rem;
          margin: 0 0 0.5rem;
        }
      }
      input {
        padding: 1rem;
        font-size: 1.4rem;
        :nth-child(1) {
          width: 7rem;
        }
        :nth-child(2) {
          background: transparent;
          /* border-bottom: 1px solid ${(props) => props.theme.colors.gray200}; */
        }
      }
    }
  }
  /* @media screen and (max-width: 460px) {
    flex-wrap: wrap;
    .inputWrap {
      width: 100%;
      flex-wrap: wrap;
      margin-right: 0;
      input {
        :nth-child(1) {
          width: 60%;
          min-width: 7rem;
          margin: 0 0 0.5rem;
        }
        :nth-child(2) {
          width: 100%;
          margin: 0 0 0.5rem;
        }
      }
    }
  } */
`;

// 주소창 이름 디브
export const InputName = styled.div`
  width: 6%;
  padding-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    width: 100%;
    font-weight: 700;
    font-size: 1.6rem;
    padding: 0 0 0.5rem;
    color: ${(props) => props.theme.colors.main};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  }
`;
// 닉네임 입력 디브
export const NicknameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > span {
    line-height: 20px;
  }
`;
// 닉네임 입력 인풋
export const NicknameInput = styled.input`
  height: 100%;
  width: 50%;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.txt500};
  background: transparent;

  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
// 연락처 인풋 워랩
export const PhonenumberWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .numberWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }
  .text {
    font-size: 1.3rem;
    color: ${(props) => props.theme.colors.txt300};
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    .numberWrap {
      width: 100%;
    }
    input {
      width: calc(40% - 3rem);
    }
    span {
      width: 100%;
    }
  }
`;
// 연락처 입력인풋
export const FirstPhoneumberInput = styled.input`
  height: 100%;
  width: 5%;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  /* font-weight: lighter; */
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;
export const SecondPhoneumberInput = styled.input`
  height: 100%;
  width: 5%;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: #000000;
  /* font-weight: lighter; */
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;
// 체크박스 워랩
export const CCheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  & > fieldset {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  & label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  & label:nth-child(2) {
    margin-left: 5px;
  }

  & input {
    margin-top: 2px;
  }

  p {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    & > fieldset {
      flex-wrap: wrap;
    }
    p {
      width: 100%;
      order: -1;
    }
  }
`;
// 광고문자 전체 워랩
export const AdsmsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > fieldset {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  & label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  & label:nth-child(2) {
    margin-left: 5px;
  }

  & input {
    margin-top: 2px;
  }

  p {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  @media screen and (max-width: 768px) {
    & > fieldset {
      flex-wrap: wrap;
    }
    p {
      width: 100%;
      order: -1;
    }
  }
`;

// 광고문자 수신제한 인풋
export const LimitedInput = styled.input`
  width: 15%;
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: #000000;
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`;
//
// 통합 셀렉트
export const Selected = styled.select`
  padding: 0.5rem;
  border: none;
  text-align: start;
  font-size: 1.4rem;
  color: #000000;
`;

export const BirthDateWrap = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999;
  overflow: auto;
`;

// TS 스타일 컴포넌트로 추천 import * as TS from '@/styles/table.styles';

type TableBodyType = { tdWidthData: number };

// 테이블 반응형 스크롤 세팅을 위함 (테이블을 깜싸는 포멧)
export const MyPageTableWrap = styled.div`
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
`;

export const TableRowWrap = styled.div`
  overflow-y: auto;
  thead {
    position: sticky;
    top: 0;
  }
`;

export const TableColWrap = styled.div`
  overflow-x: auto;
`;

// ----------------------------------------------------------------

// 기본적인 반응형이있는 테이블 스타일
export const MyPageTable = styled.table`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.gray400};
  font-size: 1.4rem;
  line-height: normal;

  .on {
    color: ${(props) => props.theme.colors.main};
  }
  .off {
    color: ${(props) => props.theme.colors.deactivation};
  }

  .flex {
    display: flex;
  }
  /* border 겹침현상 제거 */
  &,
  th,
  td {
    border-collapse: collapse;
    border-spacing: 0;
    padding: 1rem;
    vertical-align: middle;
  }
  input[type='checkBox'] {
    width: 1.5rem;
    height: 1.5rem;
  }

  /* thead 기본세팅*/
  thead {
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.txt500};
  }
  /* tbody 기본세팅*/
  tbody {
    color: ${(props) => props.theme.colors.txt500};
    td,
    th {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }

    /* tbody 첫번째 강조를 위함 */
    th {
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
    }
    button {
      background: ${(props) => props.theme.colors.main};
      color: ${(props) => props.theme.colors.txt100};
      padding: 1rem 2rem;
      border-radius: 0.5rem;
    }
  }

  /* ------------------- 테이블에 사이즈 조정 css -------------------  */
  /* 테이블 너비 20% 조정 */
  &.tw20 {
    tbody {
      th {
        width: 20%;
      }
    }
  }
  &.tw30 {
    tbody {
      th {
        width: 30%;
      }
    }
  }

  /* ------------------- 특정 스타일 css -------------------  */
  /* 테이블 border로 감싸있는 스타일 */
  &.borderStyles {
    th,
    td {
      border: 1px solid ${(props) => props.theme.colors.gray200};
      font-size: 1.4rem;
    }
    thead {
      text-align: center;
      font-weight: 700;
    }
    tbody {
      th {
        text-align: center;
        background: transparent;
      }
    }
  }

  /* th에 background 색 추가 */
  &.thBackStyles {
    th {
      background: ${(props) => props.theme.colors.gray100};
    }
  }
  &.thBorderLeft {
    th {
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }

  /* ------------------- border styles -------------------  */
  &.borTop0 {
    border-top: 0;
  }
  &.borBottom0 {
    border-bottom: 0;
  }
  &.borLeft0 {
    border-left: 0;
  }
  &.borRight0 {
    border-right: 0;
  }

  /* ------------------- 테이블 반응형 -------------------  */
  @media screen and (max-width: 1080px) {
    &.borTop0 {
      border-top: 2px solid ${(props) => props.theme.colors.gray400};
    }
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    border-top: 0;
    input {
      width: 100%;
    }
    tr {
      display: flex;
      flex-direction: column;
    }
    &.borderCenter {
      thead {
        background: transparent;
      }
      border-top: 1px solid ${(props) => props.theme.colors.gray400};
      border-bottom: 1px solid ${(props) => props.theme.colors.gray400};
      td,
      th {
        text-align: center;
      }
    }
    td,
    th {
      border: 0;
      text-align: left;
      &.flexBox {
        justify-content: center;
      }
    }
    tbody {
      tr {
        margin: 0 0 1.6rem;
        /* border-bottom: 1px solid ${(props) => props.theme.colors.gray200}; */
        &:has(input) {
          border-bottom: 0;
        }
        &:last-of-type {
          margin: 0;
        }
      }
      td,
      th {
        border-right: 0;
        border-bottom: 0;
        padding: 1rem 0;
        color: ${(props) => props.theme.colors.txt400};

        &:has(.automatic) {
          padding: 0;
          .automatic {
            display: flex;
            align-items: center;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
            width: 100%;
            min-height: 4.2rem;
            padding: 1rem;
            background: ${(props) => props.theme.colors.gray100};
            border-radius: 0.5rem;
            color: ${(props) => props.theme.colors.txt200};
            input {
              min-height: auto;
              width: 100%;
              padding: 0;
              border: 0;
              background: transparent;
            }
          }
        }
        &:has(input) {
          padding: 0;
        }

        input {
          padding: 1rem;
          border: 1px solid ${(props) => props.theme.colors.gray400};
          border-radius: 0.5rem;
          min-height: 4.2rem;
          max-width: unset;
          color: ${(props) => props.theme.colors.txt500};
          background: transparent;
        }
        .phonenumberWrap {
          select {
            width: auto;
          }
        }
        select {
          display: flex;
          min-height: 4.2rem;
          border-radius: 0.5rem;
          width: 100%;
          font-size: 1.4rem;
          background: unset;
          border-color: ${(props) => props.theme.colors.gray500};
          color: ${(props) => props.theme.colors.txt500};
        }
      }
    }
    &.flexNon {
      tr {
        display: table-row;
        flex-direction: unset;
      }
    }

    &.borBottom0 {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }
    &.borLeft0 {
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
    }
    &.borRight0 {
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
    }

    &.tw20 {
      tbody {
        th {
          width: 100%;
        }
      }
    }
    &.tw30 {
      tbody {
        th {
          width: 100%;
        }
      }
    }
  }
`;

// 스크롤 가로 - 테이블 스타일
export const MyPageTableCol = styled.table`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.gray400};
  font-size: 1.4rem;
  line-height: normal;
  text-align: center;

  .flex {
    display: flex;
  }
  /* border 겹침현상 제거 */
  &,
  th,
  td {
    border-collapse: collapse;
    border-spacing: 0;
    padding: 1rem;
    vertical-align: middle;
    flex: none;
    white-space: nowrap;
  }
  tr {
    display: table-row;
  }
  /* thead 기본세팅*/
  thead {
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.txt500};
  }
  /* tbody 기본세팅*/
  tbody {
    color: ${(props) => props.theme.colors.txt500};
    td,
    th {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }
    /* tbody 첫번째 강조를 위함 */
    th {
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }
`;

// 스크롤 세로 - 테이블 스타일
export const MyPageTableRow = styled.table`
  width: 100%;
  font-size: 1.4rem;
  line-height: normal;
  text-align: center;

  /* border 겹침현상 제거 */
  &,
  th,
  td {
    border-collapse: collapse;
    border-spacing: 0;
    padding: 1rem;
    vertical-align: middle;
    flex: none;
    white-space: nowrap;
  }
  tr {
    display: table-row;
  }
  /* thead 기본세팅*/
  thead {
    z-index: 1;
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.txt500};
  }
  /* tbody 기본세팅*/
  tbody {
    color: ${(props) => props.theme.colors.txt500};
    td,
    th {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }
    /* tbody 첫번째 강조를 위함 */
    th {
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }
`;

// 테이블이 포멧 (스크롤 등 세부 조정은 제거)
export const AddressTable = styled.table`
  position: relative;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  white-space: nowrap;
  border-collapse: separate; // td, th간 간격

  tr {
    border-left-color: transparent;
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    :first-of-type {
      border-radius: 5px 0 0 5px;
    }
    :last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  thead {
    z-index: 3;
    position: sticky;
    top: 0;
    background: ${(props) => props.theme.colors.gray100};
    border-radius: 5px;
    font-weight: 600;

    tr {
      height: 30px;
    }

    th {
      padding: 0 3rem;
      border-top: 0;
      color: ${(props) => props.theme.colors.txt300};
      border: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }

  tbody {
    line-height: normal;

    &.skeleton {
      td {
        > div {
          position: relative;
          opacity: 1;
          top: auto;
          left: auto;
          transform: none;
        }
      }
    }

    tr {
      .fixedBox {
        background: #fff;
        border-right-color: transparent;
      }
    }

    td {
      min-width: 20rem;
      border: 1px solid #ebebeb;
      position: relative;
      color: ${(props) => props.theme.colors.txt500};
      font-size: 1.4rem;

      > p {
        width: 100%;
      }

      &.focus {
        > p {
          opacity: 0;
          display: none;
        }

        > div {
          opacity: 1;
          z-index: 1;
          display: flex;
        }
      }
    }

    /* body 체크박스 */
    .fixedBox {
      z-index: 2;
      position: sticky;
      left: 0;
    }

    tr:has(input[type='checkbox']:checked) {
      td {
        background: #f4f4f4;
      }

      .fixedBox {
        box-sizing: border-box;
      }
    }

    input[type='text'] {
      border: 0;
      text-align: center;
      width: 100%;
      height: 100%;
      outline-color: ${(props) => props.theme.colors.main};
      padding: 0.8rem 0;
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.txt500};
    }
    input {
      ::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }
    }

    tr:has(td.focus) {
      z-index: 1;
    }

    td {
      > p {
        max-width: 13rem;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    thead {
      top: -1px;
    }
  }
`;

// 리스트 class로 관리
export const LiStyleWrapper = styled.ol`
  /* none : 지정하지 않음
	disc : 검은 원형
	lower-roman : 소문자 로마자
	decimal : 1부터 시작하는 10진수
	upper-alpha : 대문자 알파벳
	circle : 원형
	square : 사각형
	lower-alpha : 소문자 알파벳
	upper-roman : 대문자 로마자 */
  line-height: normal;
  color: ${(props) => props.theme.colors.txt500};
  h3 {
    margin: 0 0 1rem;
    font-size: 1.6rem;
    font-weight: 600;
  }
  p {
    font-size: 1.4rem;
    margin: 0 0 1rem;
  }
  &.disc {
    list-style-type: disc;
  }
  &.decimal {
    list-style-type: decimal;
  }

  li {
    margin: 0 0 0.8rem 1.6rem;
    font-size: 1.4rem;
    line-height: normal;
  }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 1.4rem;
    }
    p,
    li {
      font-size: 1.3rem;
    }
  }
`;
