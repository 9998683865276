import { useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';

/**
 * @title 015 채팅방 캐시 업데이트 hook
 *
 * @author 정휘학
 * @since 2024.07.04
 * */
export const chatRoom015CacheUpdate = () => {
  const queryClient = useQueryClient();
  /* 015 채팅방 리스트 API params */
  const [mhQueryParamsS] = useRecoilState(mhQueryParamsRecoil);
  /* 채팅방 기존 캐시 데이터 가져옴 */
  const cacheData: any = queryClient.getQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber]);

  /**
   * ================================================================
   * @title 채팅방에서 메세지 전송시 보여지는 채팅방 텍스트 리스트 캐시 데이터 업데이트
   * ================================================================
   **/
  const cacheUpdateToSendMsg = (r: any) => {
    /* 이 아래부터는 015 채팅방 캐시 데이터 작업 ( response 로 받아온 데이터를 추가하는 작업 ) */
    const newResponse = { ...r };
    if (cacheData) {
      /* 메세지 전송하고 받은 데이터와 기존 캐시 데이터에 추가 작업 */
      const newPages = [...cacheData.pages];
      const newContent = [...newPages[0].content, newResponse]; // 맨 첫번쨰 데이터
      newPages[0] = { ...newPages[0], content: newContent };

      const updatedData = {
        ...cacheData,
        pages: newPages,
      };
      // 변경된 데이터를 캐시에 다시 저장합니다.
      queryClient.setQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber], updatedData);
    }
  };

  return {
    cacheUpdateToSendMsg,
  };
};
