import styled from 'styled-components';

export const ArsMockMsgWrap = styled.div`
  /* 상단 */
  .top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 2rem;
    font-size: 2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.txt500};

    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: ${(props) => props.theme.colors.gray300};
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    }
    .left {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
    .backBtn {
      color: ${(props) => props.theme.colors.txt500};
      svg {
        width: 0.9rem;
        height: 1.8rem;
      }
    }
    .callBtn {
      width: 2.4rem;
      height: 3.1rem;
      color: ${(props) => props.theme.colors.main};
    }
  }

  /* 채팅방 */
  .chatRoom {
    padding: 2rem;
    height: 48rem;
    max-height: 48rem;
    overflow-y: auto;
    > div {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      span {
        font-size: 1.2rem;
        color: ${(props) => props.theme.colors.txt200};
      }
      > div {
        max-width: 24rem;
        width: calc(90% - 6rem);
        padding: 1.6rem;
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.txt500};
        background: ${(props) => props.theme.colors.white};
        border-radius: 0 1.5rem 1.5rem;
        line-height: 1.4em;
        a {
          border-bottom: 1px solid ${(props) => props.theme.colors.txt500};
        }
      }
    }
  }

  /* 채팅 입력창 */
  .chatInputWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 1rem);
    margin: 0 auto 2rem;
    padding: 1rem 1.5rem;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 0.8rem;

    input {
      background: transparent;
      width: calc(100% - 6rem);
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.gray800};

      :focus {
        outline: none;
      }
    }
    .imgBtn {
      color: ${(props) => props.theme.colors.gray500};
      svg {
        width: 2.1rem;
        height: 2.1rem;
      }
    }
    .sendBtn {
      color: ${(props) => props.theme.colors.main};
      svg {
        width: 2rem;
        height: 1.9rem;
      }
    }
  }
`;
