import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 218px);

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    h1 {
      font-size: 3rem;
    }

    button {
      cursor: pointer;
      color: #fff;
      font-size: 2rem;
      border-radius: 5px;
      background: ${(props) => props.theme.colors.main};
      padding: 2rem 3rem;
      display: block;
    }
  }

  img {
    filter: ${(props) => props.theme.colors.invert};
  }
`;

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <Wrap>
      <div>
        <h1>잘못된 경로의 페이지입니다.</h1>
        <button onClick={() => navigate('/')}>홈으로 돌아가기</button>
        <img src="/img/404.jpeg" alt="" />
      </div>
    </Wrap>
  );
};
export default Error404;
