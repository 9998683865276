/**
 * @title 015 ARS JSON 데이터 가공 HOOK
 *
 * */
import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';
import { useModalHook } from '@/components/commons/modals/useModalHook';

interface IUse015ArsJSON {
  arsData: NextItem;
  data?: NextItem;
  updatedJsonFile?(): void; // ARS Json 업데이트
}

export const use015ArsJSON = ({ arsData, data, updatedJsonFile }: IUse015ArsJSON) => {
  const { confirmModal } = useModalHook();

  // ===============================================
  // JSON data 에 특정 id 로 부터 시작하는 JSON data 뽑아옴
  // ===============================================
  const findDataById = (arsData: NextItem, id: string | null) => {
    if (!id) return null;
    if (arsData?.id === id) return arsData;
    if (arsData?.next && arsData?.next?.length > 0) {
      for (let i = 0; i < arsData?.next?.length; i++) {
        const result: any = findDataById(arsData?.next[i], id);
        if (result) return result;
      }
    }
    return null;
  };

  // ==================================
  // 음성 재생 우측에 트리가 있는지 파악하는 함수
  // ==================================
  const checkArrowCondition = (data: any, id: string) => {
    // 데이터가 존재하고 id가 일치하고 s015Data.type이 READ 인지 확인
    if (data?.id === id && data?.s015Data && data?.s015Data?.type === 'READ') {
      // next 배열에 있는 모든 요소들을 확인
      if (data?.next && data?.next.length > 0) {
        for (let i = 0; i < data.next.length; i++) {
          // next 배열의 요소 중 하나라도 s015Data.action이 빈 문자열이 아닌 경우 true 반환
          if (data?.next[i]?.s015Data && data?.next[i]?.s015Data?.action !== '') {
            return true;
          }
        }
      }
    }
    // next 배열을 재귀적으로 탐색
    if (data?.next && data?.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        const result: any = checkArrowCondition(data.next[i], id);
        if (result) return result;
      }
    }
    return false;
  };

  const checkArrow = (id: string) => {
    return checkArrowCondition(arsData, id);
  };

  // ============================
  // ARS 제거하기 위한 JSON 데이터 변경
  // ============================
  const deleteArsJsonData = (data: NextItem, id: string) => {
    if (data.id === id && data.s015Data) {
      data.parameter = '';
      data.s015Data.action = '';
      data.s015Data.type = '';
      data.s015Data.ttsText = '';
      data.target.level = '';
      data.target.type = '';
      data.source.userinput = 'none';
      data.next = [];
      return true;
    }
    if (data.next && data.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        if (deleteArsJsonData(data.next[i], id)) {
          return true;
        }
      }
    }
    return false;
  };
  /* ARS 삭제 */
  const deleteArs = async () => {
    confirmModal(
      () => {
        const updatedData = { ...arsData };
        if (data && deleteArsJsonData(updatedData, data?.id) && updatedJsonFile) {
          updatedJsonFile();
        }
      },
      'ARS 삭제',
      '해당 ARS 위젯을 삭제하시겠습니까?',
      true,
    );
  };

  // ==========================
  // next 배열의 부모 data 찾기
  // ==========================
  const findParentOfData = (data: NextItem, childrenId: string) => {
    if (data.next && data.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        if (data.next[i].id === childrenId) {
          return data;
        }
        const foundParent = findParentOfData(data.next[i], childrenId) as NextItem;
        if (foundParent) return foundParent;
      }
    }
    return null;
  };

  // ==========================
  // next 배열의 부모 id 찾기
  // ==========================
  const findParentOfId = (data: NextItem, childrenId: string) => {
    if (data.next && data.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        if (data.next[i].id === childrenId) {
          return data.id;
        }
        const foundParent = findParentOfId(data.next[i], childrenId) as string;
        if (foundParent) return foundParent;
      }
    }
    return null;
  };

  return {
    deleteArs,
    checkArrow,
    findDataById,
    findParentOfData,
    findParentOfId,
  };
};
