import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { update015JsonFile } from '@/apis/api/say015Apis';
import { say015Number, selectedArsScenarioInfoRecoil } from '@/recoil/atoms/say015Atom';

interface IUseUpdateJsonFile {
  arsData: any;
  setArsData: React.Dispatch<any>;
}
export const useUpdateJsonFile = ({ arsData, setArsData }: IUseUpdateJsonFile) => {
  const queryClient = useQueryClient();

  const [selectedArsScenarioInfoS] = useRecoilState(selectedArsScenarioInfoRecoil);
  /* JSON FILE UPDATED Mutation */

  const navigate = useNavigate();

  const { confirmModal } = useModalHook();

  const { mutate: mutateUpdatedJsonFile } = useMutation(
    () => update015JsonFile({ arsData, fileName: selectedArsScenarioInfoS?.fileName as string }),
    {
      onSuccess: (res: any) => {
        queryClient.setQueryData(['arsJsonData', selectedArsScenarioInfoS?.fileName], res);
        setArsData(res);
      },
      onError: (error: any) => {
        confirmModal(
          async () => {
            navigate('/');
          },
          '저장 실패',
          '015서비스 이용이 불가한 상태입니다. 구독 상태를 확인해 주세요.',
          true,
        );
      },
    },
  );
  return {
    mutateUpdatedJsonFile,
  };
};
