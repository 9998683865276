import { useModalHook } from '@/components/commons/modals/useModalHook';
import { infoCardSchema } from '@/pages/main/components/addTable/tableDataAdd.validation';
import { detailBuddyDataRecoil } from '@/recoil/atoms/addressList';
import { validateAddressRow } from '@/shared/util/AddressUtils';
import { BuddyData } from '@/types/index.types';
import { useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';
import { useAddressBookMutations } from '../mutations/addressBook/useAddressBookMutations';
import { useAddressBookTable } from './useAddressBookTable';
import { fomatOnlyNum } from '@/shared/util/format/phoneNumberFormatUtil';

// 주소록 상세페이지 셀렉트 토글 초기 상태, 타입
type TInitColumnSelect = {
  companySelect: boolean; // 회사
  homeSelect: boolean; // 집
  etcSelect: boolean; // 상세정보
};
const initColumnSelect = {
  companySelect: false,
  homeSelect: false,
  etcSelect: false,
};

// 주소록 각 컬럼의 태그 토글 초기 상태, 타입
type TInitColumnTag = {
  buddyNm: boolean; // 이름
  groupNm: boolean; // 그룹이름
  keyCommNo: boolean; // 휴대폰번호
  emailId: boolean; // 이메일
  coNm: boolean; // 회사이름
  homeAddr: boolean; // 집주소
  homeZipCd: boolean; // 집우편번호
  coZipCd: boolean; // 회사우편번호
  coAddr: boolean; // 회사주소
  accountNo: boolean; // 계좌번호
  coDept: boolean; // 부서
  coHandle: boolean; // 직함
  etcInfo: boolean; // 메모
};

const initColumnTag: TInitColumnTag = {
  buddyNm: false, // 이름
  groupNm: false, // 그룹이름
  keyCommNo: false, // 휴대폰번호
  emailId: false, // 이메일
  coNm: false, // 회사이름
  homeAddr: false, // 집주소
  homeZipCd: false, // 집우편번호
  coZipCd: false, // 회사우편번호
  coAddr: false, // 회사주소
  accountNo: false, // 계좌번호
  coDept: false, // 부서
  coHandle: false, // 직함
  etcInfo: false, // 메모
};

export const useAddressDetail = () => {
  // 그룹 수정 셀렉트 토글 STATE
  const [groupSelectS, setGroupSelectS] = useState<boolean>(false);
  /* 주소록 상세 페이지를 위해 해당 BuddyData 값만 따로 담아놓는 recoil */
  const [detailBuddyDataS, setDetailBuddyDataS] = useRecoilState(detailBuddyDataRecoil);
  /* 주소록 상세 페이지 컬럼 셀렉트 toggle STATE */
  const [columnSelectS, setColumnSelectS] = useState<TInitColumnSelect>(initColumnSelect);
  /* 주소록 상세 페이지 컬럼 태그 toggle STATE */
  const [columnTagS, setColumnTagS] = useState<TInitColumnTag>(initColumnTag);
  /* 주소록 상세 페이지 수정 form STATE */
  const [editValueS, setEditValueS] = useState<BuddyData>((detailBuddyDataS as BuddyData) ?? {});
  /* 주소록 상세 페이지 전체 수정 버튼 toglle STATE */
  const [allEditBtn, setAllEditBtn] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { confirmModal, warningModal } = useModalHook();

  /* 주소록 메인 테이블 api hook */
  const editHooks = useAddressBookTable();
  const { updatedM } = useAddressBookMutations();

  // ==============================
  // onClick 주소록 상세페이지 그룹 수정 hook
  // ==============================
  const onClickGroupBtn = () => {
    setGroupSelectS((prev) => !prev);
  };

  // ==============================
  // onClick 그룹 셀렉트 외 클릭시 셀렉트 off hook
  // ==============================
  const onClickoutLine = () => {
    setGroupSelectS(false);
  };

  // ==============================
  // onClick 그룹 셀렉트 리스트(li) 클릭시 edit hook
  // ==============================
  const onClickEditGroup = (group: any) => {
    if (detailBuddyDataS) {
      confirmModal(
        async () => onSuccessEditGroup(group),
        '그룹 이동',
        `'${group.groupNm}' 그룹으로 수정하시겠습니까?`,
        true,
      );
    }
  };

  // 성공시 수정하고 토글
  const onSuccessEditGroup = (group: any) => {
    // edit mutation 그룹 시퀀스넘버, 그룹 네임, 버디데이터 넘김
    if (detailBuddyDataS) editHooks.triggerUpdateToLi(group.groupSeqNo, group.groupNm, detailBuddyDataS);
    setGroupSelectS(false);
  };

  // ==============================
  // onClick 상세페이지 Select toggle hook
  // ==============================
  const onClickColumnSelect = (column: keyof TInitColumnSelect) => {
    setColumnSelectS((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  // ==============================
  // onClick 상세페이지 Tag toggle hook
  // 더블클릭시 토글되는 로직 - 2024-08-09 기준 미사용.
  // ==============================
  const onDoubleClickColumnTag = (column: keyof TInitColumnTag) => {
    setColumnTagS((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  // ==============================
  // 상세페이지 Tag 전체 toggle hook
  // ==============================
  const handleToggleAllColumns = (value: boolean) => {
    const newState = Object.fromEntries(Object.keys(initColumnTag).map((key) => [key, value])) as {
      [K in keyof BuddyData]: boolean;
    };
    setColumnTagS(newState);
  };

  // ==============================
  // onClick 상세페이지 수정 btn toggle hook
  // ==============================
  const onClickEditTagsBtn = () => {
    handleToggleAllColumns(!allEditBtn);
    setAllEditBtn((prev) => !prev);
  };

  // ==============================
  // onChange tag value edit hook
  // ==============================
  const onChangeEditValue = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setEditValueS((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    // 기존 타이머 클리어
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // 디바운싱 타이머 설정
    debounceTimer.current = setTimeout(() => {
      try {
        const fieldSchema = yup.reach(infoCardSchema, name) as yup.Schema<any>;
        fieldSchema.validateSync(value);
        setErrors((prev) => ({ ...prev, [name]: null }));
      } catch (error) {
        setErrors((prev) => ({ ...prev, [name]: (error as yup.ValidationError).message }));
      }
    }, 500);
  };

  // ==============================
  // submit change value hook
  // ==============================
  const handleEditDetailForm = () => {
    if (!validateAddressRow(editValueS)) return;

    updatedM(
      { ...editValueS, keyCommNo: fomatOnlyNum(editValueS.keyCommNo) },
      {
        onSuccess: () => {
          onClickEditTagsBtn();
        },
        onError: () => {
          warningModal('저장 실패', '주소록 수정에 실패했습니다. 새로고침 후 다시 시도해주세요.');
        },
      },
    );
  };

  // ==============================
  // onClick submit(save) button hook
  // ==============================
  const onClickSaveBtn = () => {
    handleEditDetailForm();
  };

  return {
    onClickGroupBtn,
    groupSelectS,
    onClickoutLine,
    onClickEditGroup,
    columnSelectS,
    onClickColumnSelect,
    onDoubleClickColumnTag,
    columnTagS,
    allEditBtn,
    onClickEditTagsBtn,
    onChangeEditValue,
    onClickSaveBtn,
    editValueS,
    errors,
  };
};
