import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { checkGroupListState } from '@/recoil/atoms/addressList';
import {
  addressOpenToggleRecoil,
  messageSendArrayRecoil,
  messageSendPhoneNumberRecoil,
  messageSendViewArrayRecoil,
  reSendFileRecoil,
  reSendMsgRecoil,
  reSendMsgToggleRecoil,
  reSendSubjectRecoil,
} from '@/recoil/atoms/messageSend/messageSend';
import { activeChatRoomIdRecoil, chatUserInfoRecoil } from '@/recoil/atoms/chatRoom';
import { listToggleRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { resultDetailParamsRecoil, scheduleDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import {
  arsMsgToggleRecoil,
  detailMassageIdState,
  join015ToggleRecoil,
  msg015ChatRoomToggleRecoil,
  msgResultToggleRecoil,
} from '@/recoil/atoms/say015Atom';
import { useChatRoom015Reset } from '@/components/hooks/customs/chatRoom015/useChatRoom015Reset';
import { groupBuddyListParamsRecoil } from '@/recoil/atoms/mainTableRecoil';
import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';

/**
 * @title useMsgRecoilReset.ts 페이지 이동 및 특정 구간에 recoil 초기화 해주는 Hook
 *
 * @author 정휘학
 * @since 2024.03.15
 * */
export const useMsgRecoilReset = () => {
  // 015 채팅방 상대 info
  const [, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);
  // 015 메세지 내역 active
  const [, setActiveCharRoomId] = useRecoilState(activeChatRoomIdRecoil);
  // 그룹 리스트 Recoil
  const setCheckedGroupList = useSetRecoilState(checkGroupListState);
  // 메세지 전송 수신인 관련
  const setMessageSendViewArrayState = useSetRecoilState(messageSendViewArrayRecoil);
  const setMessageSendArrayState = useSetRecoilState(messageSendArrayRecoil);
  const setMessageSendPhoneNumberState = useSetRecoilState(messageSendPhoneNumberRecoil);
  // 메세지 재전송 toggle Recoil
  const [reSendMsgToggleState, setReSendMsgToggleRecoil] = useRecoilState(reSendMsgToggleRecoil);
  // 주소록 열기 toggle Recoil
  const setAddressOpenToggleState = useSetRecoilState(addressOpenToggleRecoil);
  // 메세지 재전송 - 보낼 메세지
  const setReSendMsgState = useSetRecoilState(reSendMsgRecoil);
  // 메세지 재전송 - 보낼 파일
  const setReSendFileState = useSetRecoilState(reSendFileRecoil);
  // 메세지 재전송 - 보낼 제목
  const setReSendSubjectState = useSetRecoilState(reSendSubjectRecoil);
  // 리스트 active
  const setListToggleState = useSetRecoilState(listToggleRecoil);
  // 전송결과 클릭 대상의 prepayno
  const setResultDetailParamsState = useSetRecoilState(resultDetailParamsRecoil);
  // 예약전송 관리 클릭 대상의 prepayno
  const setScheduleDetailParamsRecoil = useSetRecoilState(scheduleDetailParamsRecoil);
  /* 015 탭 초기화 Recoil */
  const setDetailMassageId = useSetRecoilState(detailMassageIdState); // 클릭된 아이템의 ID를 저장할 state
  const setMsg015ChatRoomToggleR = useSetRecoilState(msg015ChatRoomToggleRecoil);
  const setMsgResultToggleR = useSetRecoilState(msgResultToggleRecoil);
  /* 주소록 메인 테이블 GET API PARAMS */
  const resetGroupBuddyListParamsR = useResetRecoilState(groupBuddyListParamsRecoil);
  /* 주소록 메인 테이블 width 관리 */
  const resetMainTableWidthR = useResetRecoilState(mainTableWidthRecoil);
  /* ars softphone msg 타입 화면 목업 상태 */
  const setArsMsgToggleS = useSetRecoilState(arsMsgToggleRecoil);
  // 목업 화면에서 서비스 시작하기 버튼 클릭시 가입으로 보여줄 STATE
  const setJoin015ToggleS = useSetRecoilState(join015ToggleRecoil);
  // 메세지 전송결과 페이지 토글
  const setMsgResultToggleS = useSetRecoilState(msgResultToggleRecoil);
  const etcResetHook = useChatRoom015Reset();

  /* recoil 초기화 */
  const resetRecoil = () => {
    etcResetHook.allResetRecoil();
    setChatUserInfo({ phoneNumber: null, buddyName: null });
    setActiveCharRoomId(null);
    setDetailMassageId(null);
    setMsg015ChatRoomToggleR(false);
    setMsgResultToggleR(false);
    setListToggleState(false);
    setReSendMsgState('');
    setReSendFileState(null);
    setReSendSubjectState('');
    setAddressOpenToggleState(false); // 재전송 - 주소록 토글
    setReSendMsgToggleRecoil(false); // 재전송 - 재전송 토글
    setCheckedGroupList([]); // 그룹 체크 리스트
    setMessageSendViewArrayState(null); //
    setMessageSendArrayState([]);
    setMessageSendPhoneNumberState(null);
    setResultDetailParamsState({ prepayPayNo: '', callback: '' });
    setScheduleDetailParamsRecoil({ prepayPayNo: '', callback: '' });
    resetGroupBuddyListParamsR();
    resetMainTableWidthR();
    setArsMsgToggleS(false);
    setJoin015ToggleS(false);
    setMsgResultToggleS(false);
  };

  /* 탭 이동시 초기화 해주는 Hook */
  const resetMsgInfo = () => {
    // 주소록 메인 테이블
    // resetRecoil();
  };

  /* 재전송 toggle 닫을 때 recoil 초기화 해주는 Hook*/
  const resetReMsgInfo = () => {
    setReSendMsgState('');
    setReSendFileState(null);
    setAddressOpenToggleState(false); // 재전송 - 주소록 토글
    setReSendMsgToggleRecoil(false); // 재전송 - 재전송 토글
    setCheckedGroupList([]); // 그룹 체크 리스트
    setMessageSendViewArrayState(null);
    setMessageSendArrayState([]);
    setMessageSendPhoneNumberState(null);
  };

  return {
    resetReMsgInfo,
    resetMsgInfo,
    reSendMsgToggleState,
    resetRecoil,
  };
};
