import { useEffect, useRef, useState } from 'react';

// 수신인 추가시 알림창 띄우는 훅
export const useAddresseeNotify = ({ messageSendPhoneNumberState, mainTableCheckDataFormatS }: any) => {
  // 수신인 리스트 존재 확인 STATE
  const [addList, setAddList] = useState<boolean>(false);
  // 수신인 리스트 존재하고 notify modal 띄울 STATE
  const [notifyToggle, setNotifyToggle] = useState<boolean>(false);
  // 타이머 참조를 위한 useRef
  const timerRef = useRef<any>(null);

  // 수신인 리스트 있는지 체크하는 useEffect
  useEffect(() => {
    if (
      (messageSendPhoneNumberState && messageSendPhoneNumberState.length > 0) ||
      (mainTableCheckDataFormatS && mainTableCheckDataFormatS.some((list: any) => list.count > 0))
    ) {
      setAddList(true);
    } else {
      setAddList(false);
    }
  }, [messageSendPhoneNumberState, mainTableCheckDataFormatS]);

  // 수신인 리스트 있으면 4초간 toggle시키는 useEffect
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (addList) {
      setNotifyToggle(true);
      timerRef.current = setTimeout(() => {
        setNotifyToggle(false);
      }, 4000);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [addList]);
  return { addList, notifyToggle };
};
