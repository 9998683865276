import React from 'react';
import * as S from './MobileEtc015SubCharge.style';
import ATitle from '@/components/Atom/ATitle';
import My015ChargeTop from '@/pages/My015Charge/My015ChargeTop/My015ChargeTop';
import My015ChargeMidCard from '@/pages/My015Charge/My015ChargeMid/My015ChargeMidCard';
import My015ChargeMidSubInfo from '@/pages/My015Charge/My015ChargeMid/My015ChargeMidSubInfo';
import My015ChargeBottom from '@/pages/My015Charge/My015ChargeBottom/My015ChargeBottom';
import { useChargeStateHandler } from '@/components/hooks/customs/charge/chargeStation/useChargeStateHandler';
import { SERVICE_NAME } from '@/shared/constants';

const MobileEtc015SubCharge = () => {
  const hooks = useChargeStateHandler();
  return (
    <S.Management>
      <S.Wrapper>
        <ATitle type="sub" text={`${SERVICE_NAME} 이용요금 결제`} color="#366EFF" />
        {/* 결제수단 컴포넌트 */}
        <My015ChargeTop
          handlerPayMethodTypes={hooks.handlerPayMethodTypes}
          payMethodTypeCodeState={hooks.payMethodTypeCodeState.payMethodTypeCode}
        />

        <S.DateGroup className="dateGroup">
          {/* 015 월간 이용권 컴포넌트 */}
          <My015ChargeMidCard
            payMethodTypeCodeState={hooks.payMethodTypeCodeState.payMethodTypeCode}
            amount={3300}
            productType={'monthly_pass'}
            handleChangeInfoCard={hooks.handleChangeInfoCard}
          />
          {/* 015 연간 이용권 컴포넌트 */}
          <My015ChargeMidCard
            payMethodTypeCodeState={hooks.payMethodTypeCodeState.payMethodTypeCode}
            amount={33000}
            productType={'annual_pass'}
            handleChangeInfoCard={hooks.handleChangeInfoCard}
          />
          {/* 015 이용권 혜택에 대한 간략한 설명 컴포넌트 */}
          <My015ChargeMidSubInfo amountCardHoverS={hooks.amountCardHoverS} />
        </S.DateGroup>
        {/* 015 이용권 혜택에 대한 상세한 설명 컴포넌트 */}
        <My015ChargeBottom />
      </S.Wrapper>
    </S.Management>
  );
};
export default MobileEtc015SubCharge;
