import { postAddGroup } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useAddressBookMutations } from '@/components/hooks/mutations/addressBook/useAddressBookMutations';
import { addGroupOpenRecoil } from '@/recoil/atoms/addressBookGroup';
import { useMutation } from '@tanstack/react-query';
import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useGroupList } from '../newAddressBook/useGroupList';

/**
 * @title 그룹 리스트 추가 POST API 관련 Hooks
 *
 * @author 김남규
 * @since 2024.03.18
 **/
export const useAddGroup = () => {
  /* input 태그 입력 값 STATE ( 추가 그룹 이름 ) */
  const [addGroupValue, setAddgroupValue] = useState('');
  /* api 중복 요청 방지 버튼 STATE */
  const [addGroupState, setAddGroupState] = useState(false);
  const { mainTableResetRecoil } = useAddressBookMutations();
  const setAddGroupOpenR = useSetRecoilState(addGroupOpenRecoil);
  const [addGroupOpen, setAddGroupOpen] = useRecoilState<boolean>(addGroupOpenRecoil);

  const cacheHooks = groupListCacheUpdate();

  /**
   * @title 그룹 추가 Mutate (POST)
   *
   * @author 김남규
   * @since 2024.03.20
   **/
  const { mutate: addGroupMutate } = useMutation(postAddGroup);

  const { warningModal } = useModalHook();

  /**
   * @title 그룹 이름 onChange
   *
   * @author 김남규
   * @since 2024.03.20
   **/
  const addGroupValueHandle = useCallback((e: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    if (e.type === 'change') {
      setAddgroupValue(e.currentTarget.value);
    }

    if (e.type === 'keyup') {
      const keyEvent = e as KeyboardEvent<HTMLInputElement>;
      if (keyEvent.key === 'Escape') {
        setAddGroupState(false);
        setAddGroupOpenR(false);
        setAddgroupValue('');
      }
    }
  }, []);

  /**
   * @title 추가할 그룹 검증 후 mutation 보내는 핸들
   *
   * @author 김남규
   * @since 2024.03.20
   **/
  const addGroupSubmitHandle = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setAddGroupState(true);

      const addNewGroup = addGroupValue?.trim();
      if (!addNewGroup) {
        setAddGroupState(false);
        setAddgroupValue('');
        return;
      }

      const newGroup = {
        groupNm: addNewGroup,
      };

      addGroupMutate(newGroup, {
        onSuccess: (data: any) => {
          handleAddSuccess(data);
          setAddGroupOpen(false);
        },
        onError: (error: any) => {
          setAddGroupState(false);
          warningModal('그룹 등록', `에러 발생: ${error.message}`, true);
        },
      });
    },
    [addGroupValue],
  );

  // ==============================
  // 그룹 추가 성공시 실행될 hook
  // ==============================
  const handleAddSuccess = (data: any) => {
    mainTableResetRecoil();
    setAddGroupState(false);
    setAddgroupValue('');
    // 그룹 추가 성공시 cache update
    cacheHooks.handleGroupAddCacheUpdate(data);
  };

  // ==============================
  // onClick 그룹 추가 저장 버튼 hook
  // ==============================
  const onClickAddGroupBtn = () => {
    addGroupSubmitHandle;
  };

  return { addGroupSubmitHandle, addGroupValueHandle, addGroupValue, addGroupState, onClickAddGroupBtn };
};
