import React from 'react';
import * as S from './ApiInstructionModal.styles';
import IconIdea from '@/styles/icons/IconIdea';
import { apiCodeExamples } from './apiCodeBlock/ApiCodeExamples';
import ApiCodeBlock from './apiCodeBlock/ApiCodeBlock';

const ApiInstructionModal = () => {
  return (
    <S.ApiInstructionModalContainer className="apiInstructionModal">
      <S.Advance>
        <h1>1. 사전준비</h1>
        <div>
          <h4>015번호 또는 Client 계정발급</h4>
          <div className="box">
            <p>
              <b>기존 015 Works 회원</b>이라면 Client 계정만 발급하면 API를 이용하실 수 있습니다.
            </p>
            <p>
              <b>신규 회원</b>이라면 015번호와 Client 계정 모두 발급을 받아야합니다.
            </p>
            <p>
              자세한 사항은
              <br />
              <b>015-8504-0008</b> 번으로 전화 또는 문자를 남겨주시거나 <b>help@arreo.com</b>으로 메일을 보내주시면 안내
              도와드리겠습니다.
            </p>
          </div>
        </div>
      </S.Advance>
      <div className="apiWrap">
        <h1>2. API</h1>
        <h2>2.1 인증(토큰 발급) 및 파일 API</h2>
        <h3>a. 토큰 요청 API</h3>
        {/* 안내박스 */}
        <S.Announcement className="announcement">
          <div className="top">
            <IconIdea />
            서비스 이용 시 인증을 위한 토큰을 발급받기 위해 사용되는 API입니다.
          </div>
          <ul>
            <li>1. 발급 된 토큰은 유효 기간이 있으며, 유효기간이 지나면 사용할 수 없으며,재발급이 필요합니다.</li>
            <li>2. 토큰이 외부에 노출될 경우 악용될 수 있으니 유의하시기 바랍니다.</li>
            <li>3. 본 API는 메시지 전송 API 서비스를 이용하기 위한 토큰을 발급받기 위한 것입니다.</li>
            <li>
              4. 발송 TPS, Report URL, 인증 정보, 인증 키가 변경 또는 추가되거나 인증 키 재발급이 필요한 경우 토큰
              재발급이 필요합니다.
            </li>
          </ul>
        </S.Announcement>
        <table>
          <tbody>
            <tr>
              <th>
                <p>Description</p>
              </th>
              <td>
                <p>토큰 요청</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>URL</p>
              </th>
              <td>
                <p className="code">/v1/auth/token</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Auth Required</p>
              </th>
              <td>
                <p>N</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Method</p>
              </th>
              <td>
                <p>POST</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <p>Request</p>
              </th>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">headers</p>
              </td>
              <td>
                <p>Content-Type</p>
              </td>
              <td>
                <p>String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>application/json; charset=UTF-8;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>clientId</p>
              </td>
              <td>
                <p>String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>계정 ID</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>clientSecret</p>
              </td>
              <td>
                <p>String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>계정 비밀번호</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>clientKey</p>
              </td>
              <td>
                <p>String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>클라이언트 고유 키</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Request Sample</h3>
          <ApiCodeBlock code={apiCodeExamples.requestSample1} />
        </S.CodeWrap>
        <table>
          <thead>
            <tr>
              <th>Request</th>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>accessToken</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>SD에 API 요청 시 클라이언트 인증을 하는데 사용.</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>tokenType</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>Access token 의 유형“Bearerˮ.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Response Sample</h3>
          <h4>
            1. Response <b className="green">200</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample1} />
          <h4>
            2. Response <b className="red">400</b>
          </h4>
          <S.CodeBlock>정보와 번호가 일치하지 않습니다.</S.CodeBlock>
        </S.CodeWrap>
        <div className="line" />
        <h3>b. 파일 업로드 API</h3>
        {/* 안내박스 */}
        <S.Announcement className="announcement">
          <div className="top">
            <IconIdea />
            MMS(Multimedia Messaging Service)에 사용할 이미지 파일을 업로드하기 위한 API입니다.
          </div>
          <ul>
            <li>1. 파일 업로드 후 발급받은 File Id 값은 이후 MMS 메시지 전송에 사용됩니다.</li>
            <li>2. File Id 발급 시 유효기간이 있습니다. 유효기간이(30일) 지나면 파일은 삭제됩니다.</li>
          </ul>
        </S.Announcement>
        <table>
          <tbody>
            <tr>
              <th>
                <p>Description</p>
              </th>
              <td>
                <p>MMS 전송에 사용될 파일 업로드를 위한 API</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>URL</p>
              </th>
              <td>
                <p className="code">/v1/storage/file</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Auth Required</p>
              </th>
              <td>
                <p>Y</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Method</p>
              </th>
              <td>
                <p>GET</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <th>Request</th>
            <td />
            <td />
            <td />
            <td />
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">headers</p>
              </td>
              <td>
                <p>Content-Type</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>multipart/form-data</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>Authorization</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>authenticate token</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>img</p>
              </td>
              <td>
                <p className="code">byte[]</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>image bytes (사용 가능한 확장자는 jpg 또는 jpeg, 최대 크기 1048576 Byte)</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <th>Response</th>
            <td />
            <td />
            <td />
            <td />
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>fileId</p>
              </td>
              <td>
                <p className="code">byte[]</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>MMS 전송에 필요한 File ID</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>expireTime</p>
              </td>
              <td>
                <p className="code">Date</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>파일 만료 시간</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>resultCode</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>결과 코드</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>resultMessage</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>결과 메시지</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Response Sample</h3>
          <h4>
            1. Response <b className="green">200</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample2_1} />
          <h4>
            2. Response <b className="red">400</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample2_2} />
        </S.CodeWrap>
        <h3>c. 파일 등록 확인 API</h3>
        <table>
          <tbody>
            <tr>
              <th>
                <p>Description</p>
              </th>
              <td>
                <p>파일 등록 확인 API</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>URL</p>
              </th>
              <td>
                <p className="code">/v1/storage/&#123;fileIds&#125;</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Auth Required</p>
              </th>
              <td>
                <p>Y</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Method</p>
              </th>
              <td>
                <p>GET</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Request</th>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">headers</p>
              </td>
              <td>
                <p>Authorization</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>토큰</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="code">url Parmas</p>
              </td>
              <td>
                <p>fileIds</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>서울이동통신에서 발급한 FileId</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Response</th>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>fileData</p>
              </td>
              <td>
                <p className="code">Object</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>파일 데이터 정보</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="code">fileData</p>
              </td>
              <td>
                <p>fileId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>MMS 전송에 필요한 FileId</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>fileName</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>파일 이름</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>fileExtension</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>파일 확장자</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>fileMd5</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>MD5 데이터</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>expireTime</p>
              </td>
              <td>
                <p className="code">Date</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>만료 시간</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>exist</p>
              </td>
              <td>
                <p className="code">boolean</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>존재 여부</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Response Sample</h3>
          <h4>
            1. Response <b className="green">200</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample3} />
        </S.CodeWrap>
        <div className="line" />
        <h2>2.2 메시지 전송 API</h2>
        <S.Announcement className="announcement">
          <div className="top">
            <IconIdea />
            메시지 전송을 요청하는 API입니다.
          </div>
          <ul>
            <li>1. 015 번호로 메시지를 전송 할 수 있습니다.</li>
            <li>
              2. 인증 요청에 대한 응답에 제공되는 TPS(초당 트랜잭션 수)를 초과할 경우 응답에 Status Code 429 요청 수 초
              과 상태가 반환됩니다.
            </li>
            <li>
              3. MMS(Multimedia Messaging Service) 전송 시, 파일 업로드 요청에서 응답으로 받은 FileId List를 생성하 고
              메시지 전송 시 해당 List를 포함해야합니다.
            </li>
          </ul>
        </S.Announcement>
        <h3>a. SMS 전송 API</h3>
        <table>
          <tbody>
            <tr>
              <th>
                <p>Description</p>
              </th>
              <td>
                <p>SMS 전송</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>URL</p>
              </th>
              <td>
                <p className="code">/v1/message/sms</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Auth Required</p>
              </th>
              <td>
                <p>Y</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Method</p>
              </th>
              <td>
                <p>POST</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Request</th>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
              <th>
                <p>길이</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">headers</p>
              </td>
              <td>
                <p>Authorization</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>authenticate token</p>
              </td>
              <td />
            </tr>
            <tr>
              <td />
              <td>
                <p>billCode</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>고객 정산 코드</p>
              </td>
              <td>
                <p>5</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>srcMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>20</p>
              </td>
              <td>
                <p>클라이언트에서 생성하는 메시지 고유 키</p>
              </td>
              <td>
                <p>20</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>callback</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>발급받은 015 번호</p>
              </td>
              <td>
                <p>15</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>content</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>N</p>
              </td>
              <td>
                <p>메시지 본문</p>
              </td>
              <td>
                <p>90</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>receiver</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>수신자 번호</p>
              </td>
              <td>
                <p>15</p>
              </td>
            </tr>
          </tbody>
        </table>
        <ApiCodeBlock code={apiCodeExamples.requestSample2} />
        <table>
          <thead>
            <tr>
              <th>Reponse</th>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p>body</p>
              </td>
              <td>
                <p>resultCode</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>결과 코드</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>resultMessage</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>결과 메시지</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>transferTime</p>
              </td>
              <td>
                <p className="code">Date</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>API G/W로 전송된 시간</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>umsMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>서울이동통신에서 생성하는 메시지 고유 키</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>srcMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>클라이언트에서 생성하는 메시지 고유 키</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Response Sample</h3>
          <h4>
            1. Response <b className="green">200</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample4_1} />
          <h4>
            2. Response <b className="red">400 (RECEIVER_FORMAT_ERROR)</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample4_2} />
          <h4>
            3. Response <b className="red">429 (RECEIVER_FORMAT_ERROR)</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample4_3} />
        </S.CodeWrap>
        <div className="line" />
        <h3>b. MMS/LMS 전송 API</h3>
        <table>
          <tbody>
            <tr>
              <th>
                <p>Description</p>
              </th>
              <td>
                <p>MMS/LMS 전송</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>URL</p>
              </th>
              <td>
                <p className="code">/v1/message/mms</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Auth Required</p>
              </th>
              <td>
                <p>Y</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Method</p>
              </th>
              <td>
                <p>POST</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Request</th>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
              <th>
                <p>길이</p>
              </th>
              <th>
                <p>기본 값</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">headers</p>
              </td>
              <td>
                <p>Authorization</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>authenticate token</p>
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td />
              <td>
                <p>billCode</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>고객 정산 코드</p>
              </td>
              <td>
                <p>5</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>srcMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>클라이언트에서 생성하는 메시지 고유 키</p>
              </td>
              <td>
                <p>20</p>
              </td>
              <td />
            </tr>
            <tr>
              <td />
              <td>
                <p>subject</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>N</p>
              </td>
              <td>
                <p>메시지 제목</p>
              </td>
              <td />
              <td>
                <p>제목없음</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>callback</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>메시지 발신자 번 호</p>
              </td>
              <td>
                <p>15</p>
              </td>
              <td />
            </tr>
            <tr>
              <td />
              <td>
                <p>content</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>N</p>
              </td>
              <td>
                <p>메시지 본문</p>
              </td>
              <td>
                <p>90</p>
              </td>
              <td />
            </tr>
            <tr>
              <td />
              <td>
                <p>receiver</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>수신자 번호</p>
              </td>
              <td>
                <p>15</p>
              </td>
              <td />
            </tr>
            <tr>
              <td />
              <td>
                <p>fileIds</p>
              </td>
              <td>
                <p className="code">String[]</p>
              </td>
              <td>
                <p>N</p>
              </td>
              <td>
                <p>이미지 등록 시 서 울이동통신에서 발급하는 FileId (max : 3)</p>
              </td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Request Sample</h3>
          <ApiCodeBlock code={apiCodeExamples.requestSample3} />
        </S.CodeWrap>
        <table>
          <thead>
            <tr>
              <th>Response</th>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>resultCode</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>결과 코드</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>resultMessage</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>결과 메시지</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>transferTime</p>
              </td>
              <td>
                <p className="code">Date</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>API G/W로 전송된 시간</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>umsMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>Server 에서 생성하는 메시지 고유 키</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>srcMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>Client 에서 생성하는 메시지 고유 키</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Response Sample</h3>
          <h4>
            1. Response <b className="green">200</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample5_1} />
          <h4>
            2. Response <b className="red">400 (RECEIVER_FORMAT_ERROR)</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample5_2} />
          <h4>
            3. Response <b className="red">429 (RECEIVER_FORMAT_ERROR)</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample5_3} />
        </S.CodeWrap>
        <div className="line" />
        <h2>2.4 Report API</h2>
        <h3>a. SMS 전송 API</h3>
        <S.Announcement className="announcement">
          <div className="top">
            <IconIdea />
            리포트는 Web Hook 방식으로 API를 통해 메시지 전송 결과를 수신 받을 수 있습니다.
          </div>
          <ul>
            <li>1. 전송 결과 코드에 대한 내용은 Report 결과 코드 문서를 참조하시면 됩니다.</li>
          </ul>
        </S.Announcement>
        <table>
          <tr>
            <th>
              <p>Description</p>
            </th>
            <td>
              <p>SMS / MMS Report 수신</p>
            </td>
          </tr>
          <tr>
            <th>
              <p>URL</p>
            </th>
            <td>
              <p className="code">/v1/message/report</p>
            </td>
          </tr>
          <tr>
            <th>
              <p>Auth Required</p>
            </th>
            <td>
              <p>Y</p>
            </td>
          </tr>
          <tr>
            <th>
              <p>Method</p>
            </th>
            <td>
              <p>POST</p>
            </td>
          </tr>
        </table>
        <table>
          <thead>
            <tr>
              <th>Response</th>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td>
                <p>cmpMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td />
            </tr>
            <tr>
              <td />
              <td>
                <p>srcMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>클라이언트 생성 메시지 고유 식별 키</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>umsMsgId</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>서울이동통신에서 생성한 메시지 고유 식별 키</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>channel</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>메시지 유형</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>resultCode</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>처리 결과 코드</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>resultMessage</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td />
              <td>
                <p>처리 결과 메시지</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>serviceProvider</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>이동통신사</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>srcSndDttm</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>클라이언트가 메시지를 전송한 시간</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>pfmRcvDttm</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>서울이동통신이 이동통신사로 부터 메시지 전송 결과를 수 신한 시간</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>pfmSndDttm</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>서울이동통신이 이동통신사로 메시지를 전송한 시간</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Response Sample</h3>
          <h4>
            1. Response <b className="green">200</b>
          </h4>
          <S.CodeBlock>OK</S.CodeBlock>
          <h4>
            1. Response <b className="red">500</b>
          </h4>
          <S.CodeBlock>INTERNAL_SERVER_ERROR</S.CodeBlock>
        </S.CodeWrap>
        <S.Announcement className="announcement">
          <div className="top">
            <IconIdea />
            Report 전달 실패 시 재 시도 정책
          </div>
          <ul>
            <li>1. Client는 Response로 OK를 반환해 주어야 하며, OK 가 아닐 시 재시도 합니다.</li>
            <li>2. 최대 100회까지 재전송을 시도하며, 90초 간격으로 시도합니다.</li>
          </ul>
        </S.Announcement>
        <div className="line" />
        <h3>b. Report Check API</h3>
        <S.Announcement className="announcement">
          <div className="top">
            <IconIdea />
            미수신 Report 데이터를 수신하기 위해 API로 요청할 수 있습니다.
          </div>
          <ul>
            <li>1. 요청 시 최대 요청 수는 1000건 입니다.</li>
            <li>2. 최대 요청 TPS는 30초당 1회 입니다.</li>
            <li>3. 만약 1000개의 Report를 요청하더라도 5개의 Re</li>
            <li>
              4. 규격은 다 같으며, Message Channel 에 맞는 URL 로 요청하시면 됩니다.port 데이터만 존재하는 경우 해당
              5개의 Report 데이터만 반환됩니다.
            </li>
          </ul>
        </S.Announcement>
        <table>
          <tbody>
            <tr>
              <th>
                <p>Description</p>
              </th>
              <td>
                <p>Report Checker</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>URL</p>
              </th>
              <td>
                <p>
                  <span className="code">/v1/agent/rpt/sms</span>
                  <span>&#47;&#47; SMS Report 조회</span>
                </p>
                <p>
                  <span className="code">/v1/agent/rpt/mms</span>
                  <span>&#47;&#47; LMS/MMS Report 조회</span>
                </p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Auth Required</p>
              </th>
              <td>
                <p>Y</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>Method</p>
              </th>
              <td>
                <p>POST</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Request</th>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>구분</p>
              </th>
              <th>
                <p>필드 명</p>
              </th>
              <th>
                <p>속성</p>
              </th>
              <th>
                <p>필수 여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
            <tr>
              <td>
                <p className="code">headers</p>
              </td>
              <td>
                <p>Authorization</p>
              </td>
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>authenticate token</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="code">body</p>
              </td>
              <td />
              <td>
                <p className="code">String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>요청 할 메시지의umsMsgId 리스트(max : 1000)</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <h3>Request Sample</h3>
          <S.CodeBlock>
            &#91;&#34;588629768964934500&#34;, &#34;588629768964934532&#34;, &#34;588629768968473929&#34;,
            &#34;58862976895839923&#34;&#93;
          </S.CodeBlock>
          <h3>Response Sample</h3>
          <h4>
            1. Response <b className="green">200</b>
          </h4>
          <ApiCodeBlock code={apiCodeExamples.responseSample6} />
        </S.CodeWrap>
      </div>
      <div className="moWrap">
        <h1 className="red">MO API</h1>
        {/* 안내박스 */}
        <S.Announcement className="announcement">
          <div className="top">
            <IconIdea />
            MO API는 발급 받으신 015번호에 들어오는 메시지를 WebHook 방식으로 직접 받으실 수 있는 API 입니다.
          </div>
        </S.Announcement>
        <h2>MO-SMS</h2>
        <table className="tableBack">
          <thead>
            <tr>
              <th>
                <p>항목</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Method</p>
              </td>
              <td>
                <p>POST</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>enpoint</p>
              </td>
              <td>
                <p>/v1/mo/sms</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Description</p>
              </td>
              <td>
                <p>SMS mo 수신 API</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Content-Type</p>
              </td>
              <td>
                <p>application/json</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Headers</h3>
        <table className="tableBack">
          <thead>
            <tr>
              <th>
                <p>항목</p>
              </th>
              <th>
                <p>타입</p>
              </th>
              <th>
                <p>필수여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Content-Type</p>
              </td>
              <td>
                <p>String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>application/json</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <ApiCodeBlock code={apiCodeExamples.moHeader1} />
        </S.CodeWrap>
        <h2>MO-LMS</h2>
        <table className="tableBack">
          <thead>
            <tr>
              <th>
                <p>항목</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Method</p>
              </td>
              <td>
                <p>POST</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>enpoint</p>
              </td>
              <td>
                <p>/v1/mo/lms</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Description</p>
              </td>
              <td>
                <p>LMS mo 수신 API</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Content-Type</p>
              </td>
              <td>
                <p>application/json</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Headers</h3>
        <table className="tableBack">
          <thead>
            <tr>
              <th>
                <p>항목</p>
              </th>
              <th>
                <p>타입</p>
              </th>
              <th>
                <p>필수여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Content-Type</p>
              </td>
              <td>
                <p>String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>application/json</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <ApiCodeBlock code={apiCodeExamples.moHeader2} />
        </S.CodeWrap>
        <h2>MO-MMS</h2>
        <table className="tableBack">
          <thead>
            <tr>
              <th>
                <p>항목</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Method</p>
              </td>
              <td>
                <p>POST</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>enpoint</p>
              </td>
              <td>
                <p>/v1/mo/mms</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Description</p>
              </td>
              <td>
                <p>MMS mo 수신 API</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Content-Type</p>
              </td>
              <td>
                <p>multipart/form-data</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Headers</h3>
        <table className="tableBack">
          <thead>
            <tr>
              <th>
                <p>항목</p>
              </th>
              <th>
                <p>타입</p>
              </th>
              <th>
                <p>필수여부</p>
              </th>
              <th>
                <p>설명</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Content-Type</p>
              </td>
              <td>
                <p>String</p>
              </td>
              <td>
                <p>Y</p>
              </td>
              <td>
                <p>multipart/form-data</p>
              </td>
            </tr>
          </tbody>
        </table>
        <S.CodeWrap className="codeWrap">
          <ApiCodeBlock code={apiCodeExamples.moHeader3} />
        </S.CodeWrap>
      </div>
      <S.Advance>
        <h1>참고사항</h1>
        <div>
          <div className="box">
            <p>
              - 200번대 응답이 아니거나 10초 이내에 응답이 오지 않을 경우, &#34;호출 실패&#34;로 판단하고 재시도 (10초
              간격으로 총 3회)를 시도합니다.
            </p>

            <p>
              - 웹훅 이벤트 수신 후, 내부 로직 수행 시 5초 이상 지연 될 수 있다면, timeout으로 인해 재전송 될 수 있으니,
              이벤트 수신 시 응답을(200번대 응답) 먼저 회신 후 내부 작업 진행을 권장합니다.
            </p>
          </div>
        </div>
      </S.Advance>
    </S.ApiInstructionModalContainer>
  );
};

export default ApiInstructionModal;
