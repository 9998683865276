import React, { useEffect, useState } from 'react';
import * as S from './MobileEtcList.style';
import { SERVICE_NAME } from '@/shared/constants';
import { Link } from 'react-router-dom';
import IconLogo from '@/styles/icons/IconLogo';
import { my015StatusRecoil } from '@/recoil/atoms/use015Status';
import { useRecoilValue } from 'recoil';
import { userPointRecoil } from '@/recoil/atoms/atoms';
import IconHumanSearch from '@/styles/icons/IconHumanSearch';
import IconPassword from '@/styles/icons/IconPassword';
import IconBook from '@/styles/icons/IconBook';
import IconCoin from '@/styles/icons/IconCoin';
import IconTax from '@/styles/icons/IconTax';
import IconTaxWon from '@/styles/icons/IconTaxWon';
import IconLoudSpeaker from '@/styles/icons/IconLoudSpeaker';
import IconQuestion from '@/styles/icons/IconQuestion';
import { amountFormat, calculateCounts } from '@/shared/util/format/amountFormatUtil';
import IconTaxSearch from '@/styles/icons/IconTaxSearch';
import IconHeadSet from '@/styles/icons/IconHeadSet';
import MobileLogo from '@/components/Atom/MobileLogo';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import IconCoupon from '@/styles/icons/IconCoupon';

/**
 * @title APP에서 표출할 더보기 탭의 메인 페이지
 */
const MobileEtcList = () => {
  /* 유저 포인트 정보 */
  const userPointS = useRecoilValue(userPointRecoil);
  /* 015 무료 발송 포인트 */
  const my015StatusS = useRecoilValue(my015StatusRecoil);
  /* 015 무료 발송 건수 */
  const [free015Msg, setFree015Msg] = useState<any>({});

  useEffect(() => {
    if (typeof my015StatusS?.say015Point !== 'number') return;
    const totalAmount = my015StatusS.say015Point;
    const counts = calculateCounts(totalAmount);
    setFree015Msg(counts);
  }, [my015StatusS]);

  /* 그리드 링크 관리 */
  const gridItems = [
    { to: '/mobile/etc/etcUserInfo', label: '회원정보', img: <IconHumanSearch /> },
    { to: '/mobile/etc/etcPwChange', label: '비밀번호 변경', img: <IconPassword /> },
    { to: '/mobile/etc/etc015SubCharge', label: `${SERVICE_NAME} 이용권`, img: <IconCoupon /> },

    // { to: '/mobile/etc/etcPhoneNumberChange', label: '휴대폰번호 변경' },
    { to: '/mobile/etc/etcCallbackNumber', label: '발신번호 관리', img: <IconBook /> },
    { to: '/mobile/etc/etcPayment', label: '결제정보 관리', img: <IconCoin /> },
    // { to: '/mobile/etc/etcUsage', label: '사용내역' },
    // { to: '/mobile/etc/etcUserNameChange', label: '명의 변경' },
    // { to: '/mobile/etc/etcSubInfo', label: `${SERVICE_NAME} 발급 정보` },
    { to: '/mobile/etc/etcTaxInvoiceAuto', label: '세금계산서 관리', img: <IconTax /> },
    { to: '/mobile/etc/etcRegisterTaxInvoice', label: '현금영수증 관리', img: <IconTaxWon /> },
    { to: '/mobile/etc/etcMessageResult', label: '전송결과 조회', img: <IconLoudSpeaker /> },
    { to: '/mobile/etc/etcTaxCashReceiptsDetail', label: '발행 신청내역', img: <IconTaxSearch /> },
    // { to: '/mobile/etc/etcTaxInvoiceDetail', label: '세금계산서 발행내역' },
    // { to: '/mobile/etc/etcMemberLeave', label: '회원 탈퇴' },
    { to: '/mobile/etc/etcNotice', label: '고객센터', img: <IconLoudSpeaker /> },
    { to: '/mobile/etc/etcQA', label: '자주묻는 질문', img: <IconQuestion /> },
    { to: '/mobile/etc/etcVOC', label: '문의하기', img: <IconHeadSet /> },
  ];

  return (
    <S.EtcContainer className="etcContainer">
      <S.CashContainer>
        <div className="cashDetail">
          {/* <IconLogo fontSize={'0.4rem'} /> */}
          {/* <MobileLogo /> */}
          <img src="/img/logo.png" alt="MobileLogoImage" />
          <p>무료 {free015Msg.SMS}건</p>
          <span className="row" />
          <p>캐쉬 {amountFormat(userPointS ? userPointS?.use_pg : 0)}원</p>
        </div>
        {/* TODO - 충전소 작업 끝나면 수정 */}
        <Link className="cash" to="/mobile/etc/etcCharge">
          충전
        </Link>
      </S.CashContainer>
      <S.GridContainer>
        {gridItems.map((item) => (
          <S.GridItem key={item.label} to={item.to}>
            <div>{item.img}</div>
            <p>{item.label}</p>
          </S.GridItem>
        ))}
      </S.GridContainer>
      <S.Advertising>
        <Link to={'https://www.instagram.com/015_coffee/'} target="_blank" className="banner" />
        <div className="advertisingBottom">
          <p>015 Coffee</p>
        </div>
      </S.Advertising>
      {/* <S.Advertising>
        <Link to={'https://www.instagram.com/015_coffee/'} target="_blank" className="banner2" />
        <div className="advertisingBottom">
          <p>015 Menu</p>
        </div>
      </S.Advertising> */}
    </S.EtcContainer>
  );
};
export default MobileEtcList;
