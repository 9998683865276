import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { formatDateBase } from '@/shared/util/format/dateFormatUtil';
import dayjs from 'dayjs';
import { useMutationPointExcelDown } from '@/components/hooks/customs/charge/usePointExcel';
import { getPointResult } from '@/apis/api/pointApi';

export const useMobileEtcPointHistoryM = () => {
  const pageSize: number = 10;

  const [totalPageM, setTotalPageM] = useState(0);
  const [activePageM, setActivePageM] = useState(1);
  const [startPageM, setStartPageM] = useState(1);

  const navigate = useNavigate();

  // 현재 날짜, 이전 달 첫 날짜 - 리스트 초기 요청값
  const initToday = dayjs(new Date()).format('YYYYMMDD');
  const initPrevMonth = dayjs().subtract(1, 'month').startOf('month').format('YYYYMMDD');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get('page') as string, 10) || 1;
  const initialDates = searchParams.get('searchDates')?.split(',') ?? [initPrevMonth, initToday];
  const initialSelectValue = searchParams.get('searchSelectValue');

  const [currentPageM, setCurrentPageM] = useState(initialPage);
  const [searchSelectValueM, setSearchSelectValueM] = useState<string | null>(initialSelectValue);
  const [searchDatesM, setSearchDatesM] = useState<string[] | string>(initialDates);

  // 페이싱네이션 - 페이지 변경
  const handlePageChangeM = (pageNumber: any) => {
    navigate(`?page=${pageNumber}`);
    setCurrentPageM(pageNumber);
  };
  useEffect(() => {
    navigate(`?page=${currentPageM}&searchDates=${searchDatesM}&searchSelectValue=${searchSelectValueM}`);
    setActivePageM(currentPageM);
  }, [searchDatesM, searchSelectValueM, currentPageM]);

  const theadM = ['번호', '결제일', '사용내역', '구분', '사용금액'];

  const {
    data: pointHistoryDataM,
    isSuccess: isSuccessM,
    isLoading: isLoadingM,
  } = useQuery(['pointHistory', currentPageM, pageSize, searchDatesM, searchSelectValueM], () =>
    getPointResult(currentPageM - 1, pageSize, searchDatesM, searchSelectValueM).then((data) =>
      data.content.map((e: any, idx: number) => {
        return {
          id: e.msgId,
          번호: String((currentPageM - 1) * 10 + (idx + 1)),
          결제일: formatDateBase(e.wrtDttm),
          사용내역: e.usedCd,
          구분: e.payGb,
          사용금액: `${e.cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ⓒ`,
          사용포인트: `${e.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ⓟ`,
        };
      }, setTotalPageM(data.totalElements)),
    ),
  );

  // useEffect(() => {
  //   getPointResult(currentPage - 1, pageSize, searchDates, searchSelectValue).then((data) =>
  //     setTotalPage(data.totalElements),
  //   );
  //   // handlePageChange(currentPage-1);
  // }, [currentPage]);

  const selectedValueM = [
    { value: 'all', label: '전체조회' },
    { value: '1', label: '수입(충전)' },
    { value: '2', label: '사용(지출)' },
    { value: '3', label: '취소(예약취소)' },
    { value: '9', label: '재충전(환불)' },
  ];

  // 이전 달의 첫 번째 날짜 계산
  const lastMonthStart = dayjs().subtract(1, 'month').startOf('month');

  // 이번 달의 마지막 날짜 계산
  const currentMonthEnd = dayjs().endOf('month');

  const disabledDateM = (current: any) => {
    return current < lastMonthStart || current > currentMonthEnd;
  };

  // 엑셀 다운
  const { isExcelLoading, mutationExcelDown } = useMutationPointExcelDown({
    searchDates: searchDatesM,
    searchSelectValue: searchSelectValueM,
  });

  const excelDownloadM = () => {
    mutationExcelDown();
  };

  return {
    theadM,
    pointHistoryDataM,
    totalPageM,
    handlePageChangeM,
    startPageM,
    setStartPageM,
    activePageM,
    setActivePageM,
    currentPageM,
    setCurrentPageM,
    selectedValueM,
    disabledDateM,
    setSearchSelectValueM,
    searchDatesM,
    setSearchDatesM,
    isSuccessM,
    excelDownloadM,
    mutationExcelDown,
    isExcelLoading,
    isLoadingM,
  };
};
