import { useState } from 'react';

export const useTopNaviTab = () => {
  type TabMenuItem = {
    label: string;
    s: boolean;
  };

  const tabMenu: TabMenuItem[] = [
    { label: '주소록', s: false },
    { label: '인사말설정', s: false },
    { label: 'Cash', s: false },
    { label: '무료발송건수', s: false },
    { label: 'Point', s: false },
  ];

  const [tabMenuS, setTabMenuS] = useState<TabMenuItem[]>(tabMenu);

  // 특정 탭을 클릭했을 때 s 값을 토글하고, 이벤트를 실행
  const onClickTabMenu = (label: string) => {
    // 클릭한 탭만 s 값을 토글, 나머지는 false로 설정
    setTabMenuS((prevState) =>
      prevState.map((menu) => (menu.label === label ? { ...menu, s: !menu.s } : { ...menu, s: false })),
    );
  };

  return { tabMenuS, onClickTabMenu };
};
