import React from 'react';
import ChargeOnlinePopup from '../../../components/Molecules/Charge/charge/chargeOnlienPopup/ChargeOnlinePopup';
// import ChargePopup from '../../../components/Molecules/Charge/ChargePopup';
import { IMenus } from '@/components/hooks/customs/charge/chargeStation/useChargeMenuItems';
import { amountFormat, chargeCashSmsAmountFormat } from '@/shared/util/format/amountFormatUtil';
import { usePageCharge } from '@/components/hooks/customs/charge/chargeStation/useCharge';
import { WORKS015_SMS } from '@/shared/constants';
import { windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useRecoilValue } from 'recoil';

/**
 * @title 캐시 충전에 대한 상태를 표출하는 컴포넌트
 *
 * @author 정휘학
 * @since 2024.05.18
 * */

interface ICaseChargeStateView {
  // 결제 수단 코드 0 : 캐시, 11 : 카드, 21 : 무통장, 31 : 휴대폰
  payMethodTypeCodeState: IMenus;
  // 결제 금액
  amountS: number;
  // 포인트 금액
  pointValueState: number;
  // 포인트 퍼센트
  pointPercentState: number;
}

const CashChargeStateView = (props: ICaseChargeStateView) => {
  // 이지페이 결재 API HOOKS
  const hooks = usePageCharge({ returnUrl: 'EasyComponent' });
  // 반응형 상태값
  const windowWidth = useRecoilValue(windowWidthRecoil);

  const { errors } = hooks.formState;
  return (
    <>
      {/* 입금요청서 팝업창 */}
      {hooks.bankTransferOpenS && (
        <ChargeOnlinePopup
          setBankTransferOpenS={hooks.setBankTransferOpenS}
          yupGetValues={hooks.getValues}
          amount={props.amountS}
        />
      )}
      {/* 이지페이 팝업창 */}
      {/* {hooks.easyPayPopupOpen && (
        <ChargePopup
          easyPayPopupDataS={hooks.easyPayPopupDataS}
          isChargeKiccLoading={hooks.isChargeKiccLoading}
          closePopup={hooks.closePopup}
        />
      )} */}

      <div className="rightGroup">
        <div className="wrapper">
          <div className="paymentGroup payBox">
            <h4>총 결제금액</h4>
            <div className="money">
              <h3>
                {amountFormat(props.amountS)}
                <span>원</span>
              </h3>
              <p>VAT 포함</p>
              <div className="amount smsBox">
                <p>
                  010 Mode SMS 건당 <span>{chargeCashSmsAmountFormat(props.amountS, props.pointValueState)}</span>원
                </p>
                <p>
                  015 Mode SMS 건당 <span>{WORKS015_SMS}</span>원
                </p>
              </div>
            </div>
          </div>
          <span className="row" />
          <div className="paymentGroup pointBox">
            <h4>예상적립 포인트</h4>
            <p>
              결제금액의&nbsp;
              <span className="par">{props.pointPercentState}%&nbsp;</span>
              적립
            </p>
            <div className="money point">
              <h3>
                {/* {amountFormat(props.amountS)} */}
                {amountFormat(props.pointValueState)}
                <span>포인트</span>
              </h3>
              <p>적립된 포인트는 010 Mode에서만 사용 가능합니다</p>
            </div>
          </div>

          <div className="bottom">
            {props.payMethodTypeCodeState.payMethodTypeCode === '0' ? (
              <>
                <div className="depositWrap">
                  {/* 입금자명 htmlFor depositName 임시 명칭 */}
                  <label htmlFor="depositName" className="depositName">
                    <p>입금자명</p>
                    <input type="text" placeholder={'입금자명을 입력해주세요.'} {...hooks.register('name')} />
                    <p className="errorRed">{errors.name?.message}</p>
                  </label>
                  <label htmlFor="depositName" className="depositName">
                    <p>알림받을 전화번호 ( 선택 )</p>
                    <input
                      type="string"
                      placeholder={"'-' 제거"}
                      value={hooks.bankTransferPhoneNumberS}
                      {...hooks.register('phoneNumber')}
                    />
                  </label>
                </div>
                {/*                <div className="messageReceive">
                  <CBS.CheckBoxInputBack type="checkbox" id="confirmmsg" name="confirmmsg" />
                  <label className="txt" htmlFor="confirmmsg">
                    입금완료 시, 확인 문자를 수신하겠습니다. <span>(선택)</span>
                  </label>
                </div>*/}
                <button onClick={hooks.handleSubmit(() => hooks.onClickDepositRequest(props.amountS))}>
                  입금요청서 작성하기
                </button>
              </>
            ) : (
              <>
                {/*                <div className="messageReceive">
                  <CBS.CheckBoxInputBack type="checkbox" id="confirmmsg" name="confirmmsg" />
                  <label className="txt" htmlFor="confirmmsg">
                    입금완료 시, 확인 문자를 수신하겠습니다. <span>(선택)</span>
                  </label>
                </div>*/}

                <button
                  onClick={() =>
                    hooks.onChargeApi({
                      amount: props.amountS,
                      returnUrl: 'EasyComponent',
                      productType: 'cash',
                      payMethodTypeCode: props.payMethodTypeCodeState.payMethodTypeCode,
                    })
                  }
                >
                  결제하기
                </button>
              </>
            )}
          </div>
        </div>
        <div className="earnTxt">
          <div className="top">
            {props.payMethodTypeCodeState.payMethodTypeCode !== '31' && (
              <p>
                {props.payMethodTypeCodeState.title} 5,000원 이상 결제하면{' '}
                <span className="par">{props.payMethodTypeCodeState.pointPercent}%</span>&nbsp;추가 적립
              </p>
            )}
            {props.amountS < 10000 && (
              <p>
                1만원 미만 결제시 금액의 <span className="par">0%</span>&nbsp;적립
              </p>
            )}
            {props.amountS >= 10000 && props.amountS < 100000 && (
              <p>
                1만원 이상 ~ 10만원 미만 결제시 금액의 <span className="par">5%</span>&nbsp;적립
              </p>
            )}
            {props.amountS >= 100000 && props.amountS < 500000 && (
              <p>
                10만원 이상 ~ 50만원 미만 결제시 금액의 <span className="par">10%</span>&nbsp;적립
              </p>
            )}
            {props.amountS >= 500000 && props.amountS < 1000000 && (
              <p>
                50만원 이상 ~ 100만원 미만 결제시 금액의 <span className="par">20%</span>&nbsp;적립
              </p>
            )}
            {props.amountS >= 1000000 && (
              <p>
                100만원 이상 결제시 금액의 <span className="par">30%</span>&nbsp;적립
              </p>
            )}
          </div>
          <div className="bottom">
            <p>건당 발송단가는 적립포인트가 적용된 단가입니다.</p>
            <p>문자발송 시에는 25원으로 차감됩니다.</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default CashChargeStateView;
