import React, { useState } from 'react';
import * as S from './NoticeModal.styles';
import { v4 as uuidv4 } from 'uuid';
import { TModalType } from '@/shared/hooks/useNoticeModal';
import { ARREO_LMS, ARREO_MMS, ARREO_SMS, WORKS015_SMS } from '@/shared/constants';

/**
 * @title 해당 메뉴 정보를 보여줄 모달창
 * @param props 모달에 띄울 내용 값
 *
 * @author 김남규
 * @since 2024-05-11
 */
const NoticeModal = (props: any) => {
  /* 'ars'Type의 표시할 페이지 관리 STATE */
  const [arsPage, setArsPage] = useState(0);
  // 'ars'Type 전체 페이지 수
  const TOTAL_PAGES = 4;

  // ==============================
  // 'ars'Type에서 페이지이동 onClick hook
  // ==============================
  const arsNextPage = () => {
    if (arsPage < TOTAL_PAGES - 1) {
      setArsPage((prev) => prev + 1);
    }
  };

  const arsPrevPage = () => {
    if (arsPage > 0) {
      setArsPage((prev) => prev - 1);
    }
  };

  // 페이지 circle 관리 변수
  const pageCircle = Array.from({
    length: TOTAL_PAGES,
  });

  // ==============================
  // 강조할 특정 텍스트 담는 hook
  // ==============================

  // 직접 지정할 스타일 객체 타입 정의
  type TextStyle = {};
  const handleFocusText = (text: string) => {
    const textStyle = {
      fontWeight: '800',
      color: '#AAD1F4',
    };

    // 스타일 적용 반환
    return <span style={textStyle}>{text}</span>;
  };

  // ==============================
  // 모달 내 close button onClick hook
  // ==============================
  const onClickCloseBtn = () => {
    props.setNoticeModalToggle(false);
  };

  /* 모달 타입을 담는 변수 */
  const noticeDetailType = props.modalType;

  const renderComponent = (type: TModalType) => {
    switch (type) {
      case '015':
        return (
          <S.Notification>
            <p>발급받은 015 번호를 사용하여 양방향 문자전송이 가능하며, 할인된 단가({WORKS015_SMS}원)로 발송합니다.</p>
          </S.Notification>
        );
      case '010':
        return (
          <S.Notification>
            <p>010 번호로 문자를 발송합니다.</p>
            <p>
              * 단문: {ARREO_SMS}원 / 장문: {ARREO_LMS}원 / 포토: {ARREO_MMS}원
            </p>
          </S.Notification>
        );
      case 'ars':
        return (
          <S.NoticeArs>
            <p>
              &apos;음성사서함&apos;은 사용자가 ARS 안내 시나리오를 미리 작성하여 자동으로 음성&문자 등으로 응답할 수
              있는 서비스입니다.
            </p>
            <div className="group">
              <button className="arrow arrowLeft" type="button" onClick={arsPrevPage} disabled={arsPage === 0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" fill="none">
                  <path
                    d="M0.95757 4.65178L3.50337 7.89038C3.54585 7.94445 3.60229 7.97461 3.66097 7.97461C3.71966 7.97461 3.7761 7.94445 3.81857 7.89038L3.82132 7.88672C3.84198 7.86052 3.85843 7.82897 3.86968 7.794C3.88092 7.75903 3.88672 7.72138 3.88672 7.68332C3.88672 7.64527 3.88092 7.60761 3.86968 7.57264C3.85843 7.53768 3.84198 7.50613 3.82132 7.47992L1.42397 4.43038L3.82132 1.38207C3.84198 1.35586 3.85843 1.32431 3.86968 1.28935C3.88092 1.25438 3.88672 1.21672 3.88672 1.17867C3.88672 1.14061 3.88092 1.10296 3.86968 1.06799C3.85843 1.03302 3.84198 1.00147 3.82132 0.975264L3.81857 0.971604C3.7761 0.917539 3.71966 0.887379 3.66097 0.887379C3.60229 0.887379 3.54585 0.917539 3.50337 0.971604L0.95757 4.21021C0.935181 4.23869 0.917357 4.27294 0.905178 4.3109C0.892999 4.34885 0.886719 4.38971 0.886719 4.43099C0.886719 4.47228 0.892999 4.51314 0.905178 4.55109C0.917357 4.58904 0.935181 4.6233 0.95757 4.65178Z"
                    fill="#AEAEAE"
                    stroke="#AEAEAE"
                  />
                </svg>
              </button>
              {arsPage === 0 && (
                <div>
                  <h4>1. 착신전환 서비스</h4>
                  <p>
                    015 번호로 전화를 걸면 설정한 ARS 안내 시나리오에 따라 &apos;착신전환&apos; 기능의 다이얼을 선택할
                    수 있습니다. 착신전환 다이얼을 선택 시, 015번호로 수신된 전화를
                    {handleFocusText(' 사전에 저장해둔 번호로 자동 착신전환')}시켜 드립니다.
                  </p>
                </div>
              )}
              {arsPage === 1 && (
                <div>
                  <h4>2. 음성녹음 받기 서비스</h4>
                  <p>
                    &apos;음성녹음 받기&apos; 다이얼을 선택하여 음성 메시지를 남길 수 있는 기능입니다.
                    {handleFocusText('수신된 음성 메시지는 자동으로 텍스트로 변환되어 저장')}되며, 메시지 내역은
                    &apos;015 메시지 내역&apos; 메뉴에서 음성으로 녹음된 오디오 파일과 텍스트로 변환된 문서를 함께
                    열람할 수 있습니다.
                  </p>
                </div>
              )}
              {arsPage === 2 && (
                <div>
                  <h4>3. 안내멘트 서비스</h4>
                  <p>
                    안내멘트를 미리 설정할 수 있는 기능입니다. &apos;안내멘트&apos; 다이얼 선택 시,{' '}
                    {handleFocusText('사전에 저장된 안내멘트 텍스트가 음성(TTS)으로 변환되어 자동재생')}됩니다.
                  </p>
                </div>
              )}
              {arsPage === 3 && (
                <div>
                  <h4>4. 문자발송 서비스</h4>
                  <p>
                    안내문자를 자동으로 전송하는 기능입니다. &apos;문자 발송&apos; 다이얼 선택 시,
                    {handleFocusText(' 사전에 저장된 문자 내용이 자동으로 발송')}됩니다.
                  </p>
                </div>
              )}
              <button
                className="arrow arrowRight"
                type="button"
                onClick={arsNextPage}
                disabled={arsPage === TOTAL_PAGES - 1}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" fill="none">
                  <path
                    d="M3.93305 4.21052L1.38725 0.97192C1.34477 0.917855 1.28834 0.887695 1.22965 0.887695C1.17096 0.887695 1.11453 0.917855 1.07205 0.97192L1.06931 0.97558C1.04865 1.00179 1.03219 1.03334 1.02095 1.06831C1.0097 1.10327 1.00391 1.14093 1.00391 1.17898C1.00391 1.21704 1.0097 1.25469 1.02095 1.28966C1.03219 1.32463 1.04865 1.35618 1.06931 1.38239L3.46665 4.43192L1.06931 7.48023C1.04865 7.50644 1.03219 7.53799 1.02095 7.57296C1.0097 7.60793 1.00391 7.64558 1.00391 7.68364C1.00391 7.72169 1.0097 7.75935 1.02095 7.79432C1.03219 7.82928 1.04865 7.86083 1.06931 7.88704L1.07205 7.8907C1.11453 7.94477 1.17096 7.97493 1.22965 7.97493C1.28834 7.97493 1.34477 7.94477 1.38725 7.8907L3.93305 4.6521C3.95544 4.62362 3.97327 4.58936 3.98545 4.55141C3.99763 4.51346 4.00391 4.4726 4.00391 4.43131C4.00391 4.39002 3.99763 4.34916 3.98545 4.31121C3.97327 4.27326 3.95544 4.23901 3.93305 4.21052Z"
                    fill="#AEAEAE"
                    stroke="#AEAEAE"
                  />
                </svg>
              </button>
            </div>
            <div className="pageCircle">
              {pageCircle.map((_, page: any) => {
                return arsPage === page ? <span className="active" key={uuidv4()} /> : <span key={uuidv4()} />;
              })}
            </div>
          </S.NoticeArs>
        );
      default:
        return null;
    }
  };
  return (
    <S.OffButton>
      <button type="button" onClick={() => onClickCloseBtn()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20.5 20.5" fill="none">
          <path
            d="M14.2219 13.4782C14.2595 13.5159 14.2894 13.5606 14.3098 13.6098C14.3302 13.659 14.3407 13.7118 14.3407 13.7651C14.3407 13.8183 14.3302 13.8711 14.3098 13.9203C14.2894 13.9695 14.2595 14.0143 14.2219 14.0519C14.1842 14.0896 14.1395 14.1195 14.0902 14.1399C14.041 14.1603 13.9883 14.1708 13.935 14.1708C13.8817 14.1708 13.8289 14.1603 13.7797 14.1399C13.7305 14.1195 13.6858 14.0896 13.6481 14.0519L10.2856 10.6889L6.92307 14.0519C6.84698 14.128 6.74379 14.1708 6.63618 14.1708C6.52858 14.1708 6.42539 14.128 6.3493 14.0519C6.27321 13.9759 6.23047 13.8727 6.23047 13.7651C6.23047 13.6575 6.27321 13.5543 6.3493 13.4782L9.71232 10.1157L6.3493 6.75314C6.27321 6.67706 6.23047 6.57386 6.23047 6.46626C6.23047 6.35866 6.27321 6.25546 6.3493 6.17938C6.42539 6.10329 6.52858 6.06055 6.63618 6.06055C6.74379 6.06055 6.84698 6.10329 6.92307 6.17938L10.2856 9.5424L13.6481 6.17938C13.7242 6.10329 13.8274 6.06055 13.935 6.06055C14.0426 6.06055 14.1458 6.10329 14.2219 6.17938C14.298 6.25546 14.3407 6.35866 14.3407 6.46626C14.3407 6.57386 14.298 6.67706 14.2219 6.75314L10.8588 10.1157L14.2219 13.4782Z"
            fill="white"
            stroke="white"
          />
        </svg>
      </button>
      <div>{renderComponent(noticeDetailType)}</div>
    </S.OffButton>
  );
};
export default NoticeModal;
