import { useEffect, useState } from 'react';

/**
 * @title 로더를 강제적으로 노출하는 시간을 늘리는 Hooks
 *
 * @author 김남규
 * @since 2024-05-03
 */
interface IUseLoaderTimer {
  isLoading?: boolean;
}

export const useLoaderTimer = ({ isLoading }: IUseLoaderTimer) => {
  /* 노출 전후 boolean STATE */
  const [timer, setTimer] = useState<boolean>(true);

  const handleTimer = () => {
    setTimeout(() => {
      setTimer(false);
    }, 400);
  };
  // 렌더링시 Loader 강제로 n초간 표출할 데이터
  useEffect(() => {
    if (isLoading) setTimer(true);
    if (!isLoading) handleTimer();
  }, [isLoading]);

  return { timer };
};
