/* eslint-disable */
import MultiChatList from '@/components/softPhone/DisplayGroups/MessageManagement/ChatRoom/multichatlist/MultiChatList';
import EachChatRoom015 from '@/pages/main/components/etcLeftZone/chatRoom015/eachChatRoom015/EachChatRoom015';
import { chatRoomTypeRecoil } from '@/recoil/atoms/chatRoom';
import { windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { msg015ChatRoomToggleRecoil } from '@/recoil/atoms/say015Atom';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as S from 'src/pages/main/components/etcLeftZone/chatRoom015/ChatRoom015.styled';

const ChatRoom015 = () => {
  /* 채팅방 scroll div */
  const chatRoomRef = useRef<HTMLDivElement>(null);
  const chatFootRef = useRef<HTMLDivElement>(null);

  /* 015 채팅방 표출 toggle */
  const msg015ChatRoomToggleR = useRecoilValue(msg015ChatRoomToggleRecoil);
  /* 채팅방 type Recoil */
  const [chatRoomTypeS, setChatRoomTypeS] = useRecoilState(chatRoomTypeRecoil);

  // 반응형 넓이 가져오는 RECOIL 768이하 부터 반응형
  const windowWidth = useRecoilValue(windowWidthRecoil);
  const navigate = useNavigate();

  useEffect(() => {
    if (!(windowWidth > 768)) {
      const handleBackButton = (e: any) => {
        e.preventDefault();
        // 여기에 뒤로가기 버튼 클릭 시 실행할 동작 작성
      };

      // 현재 상태를 pushState로 기록하여 popstate 이벤트를 트리거할 수 있도록 함
      window.history.pushState(null, '', window.location.href);

      // popstate 이벤트를 감지하여 뒤로가기 동작 제어
      window.addEventListener('popstate', handleBackButton);

      // 컴포넌트 언마운트 시 이벤트 제거
      return () => {
        window.removeEventListener('popstate', handleBackButton);
      };
    }
  }, [windowWidth, location.pathname]);

  return (
    <S.EtcChattingComponent className={`${msg015ChatRoomToggleR ? 'etcEnd' : 'etcStart'}`}>
      {chatRoomTypeS === 'single' && <EachChatRoom015 chatRoomRef={chatRoomRef} chatFootRef={chatFootRef} />}
      {chatRoomTypeS === 'multi' && <MultiChatList />}
    </S.EtcChattingComponent>
  );
};
export default ChatRoom015;
