import { atom } from 'recoil';

/**
 * @title 웹 소켓으로 부터 받은 메세지
 * */
export const webSocketDataRecoil = atom({ key: 'webSocketDataRecoil', default: null });

export const webSocketSendDataRecoil = atom<{ data: null | string; time: null | string }>({
  key: 'webSocketSendDataRecoil',
  default: { data: null, time: null },
});
