import { useQueryClient } from '@tanstack/react-query';

export const recyclebinCacheUpdate = () => {
  const queryClient = useQueryClient();

  const prevData: any = queryClient.getQueryData(['recycleBinList']);

  // ==============================
  // 휴지통 선택 복원 캐시 업데이트 hook
  // ==============================
  const recyclebinRestoreCacheUpdate = (data: any) => {
    // data 배열에 buddySeqNo만 추출
    const buddySeqNos = data.map((item: any) => item.buddySeqNo);

    const newPages = prevData.pages.map((page: any) => {
      return {
        ...page,
        content: page.content.filter((item: any) => {
          // data 배열에 포함된 buddySeqNo를 가진 항목을 제거합니다.
          return !buddySeqNos.includes(item.buddySeqNo);
        }),
      };
    });
    const newData = { ...prevData, pages: newPages };
    queryClient.setQueryData(['recycleBinList'], newData);
  };

  // ==============================
  // 휴지통 선택 삭제 캐시 업데이트 hook
  // ==============================
  const recyclebinDeleteCacheUpdate = (variables: number[]) => {
    const newPages = prevData.pages.map((page: any) => {
      return {
        ...page,
        content: page.content.filter((item: any) => {
          return !variables.includes(item.buddySeqNo);
        }),
      };
    });
    const newData = { ...prevData, pages: newPages };
    queryClient.setQueryData(['recycleBinList'], newData);
  };

  // ==============================
  // 휴지통 전체 복원, 삭제 캐시 업데이트 hook
  // ==============================
  const recyclebinAllCleanCacheUpdate = () => {
    const newPages = prevData.pages.map((page: any) => {
      return {
        ...page,
        content: [],
      };
    });
    const newData = { ...prevData, pages: newPages };
    queryClient.setQueryData(['recycleBinList'], newData);
  };

  return { recyclebinRestoreCacheUpdate, recyclebinDeleteCacheUpdate, recyclebinAllCleanCacheUpdate };
};
