import React from 'react';
import { ArsCardModalProps } from '@/components/say015/Modal/arsCards/ArsCardModal';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { useArsCall } from '@/components/hooks/customs/say015/say105Handle/useArsCall';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useRecoilState } from 'recoil';
import { simpleViewArsTreeRecoil } from '@/recoil/atoms/say015Atom';

const ArsCardCall = ({
  data,
  type,
  handleUpdateIdState,
  handleCancel,
  arsData,
  updatedJsonFile,
}: ArsCardModalProps) => {
  const [simpleViewArsTreeS] = useRecoilState(simpleViewArsTreeRecoil);
  const { handleCallNumber, callNumberState, handleSaveCall, handleCancelCall, handleCallTitle, callTitleState } =
    useArsCall({ data, arsData, updatedJsonFile });
  const { deleteArs } = use015ArsJSON({ arsData, data, updatedJsonFile });
  return (
    <>
      {/* 간편보기 O */}
      {simpleViewArsTreeS && (
        <div className={'simpleCard'}>
          <div className="txtTop">
            <div className="group">
              <p
                className="icon"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!handleUpdateIdState) return;
                  handleUpdateIdState(data?.id);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path
                    d="M16.292 9.49284L20.4587 5.32617M20.4587 5.32617V8.45117M20.4587 5.32617H17.3337"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.871 15.2714L16.3971 15.7714C16.3971 15.7714 15.2689 16.9579 12.1908 13.7172C9.11268 10.4766 10.2408 9.29015 10.2408 9.29015L10.5398 8.97453C11.2752 8.19953 11.345 6.95578 10.7023 6.04745L9.38976 4.19015C8.59393 3.06515 7.05747 2.91724 6.14601 3.87661L4.51163 5.5964C4.06059 6.07245 3.75851 6.68807 3.79497 7.37245C3.88872 9.12245 4.63663 12.886 8.80747 17.2787C13.2314 21.9349 17.3825 22.1204 19.0793 21.9527C19.6168 21.9006 20.0835 21.6099 20.4596 21.2141L21.9387 19.6558C22.9387 18.6037 22.6575 16.8016 21.3783 16.0662L19.3887 14.9204C18.5491 14.4381 17.5273 14.5797 16.871 15.2704"
                    fill="currentColor"
                  />
                </svg>
              </p>
              {type === 'update' && <h3 className="txt">착신전환</h3>}
            </div>
            {type === 'update' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
          {type !== 'view' && (
            <div className={'list'}>
              <input placeholder="착신전화할 번호를 입력해주세요" value={callNumberState} onChange={handleCallNumber} />
            </div>
          )}
        </div>
      )}
      {/* 간편보기 X */}
      {!simpleViewArsTreeS && (
        <div className={'card'}>
          <div className="txtTop">
            <div className="group">
              {type === 'view' && (
                <button className="grab">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.33333 1.66667C3.33333 2.58712 2.58712 3.33333 1.66667 3.33333C0.746208 3.33333 0 2.58712 0 1.66667C0 0.746192 0.746208 0 1.66667 0C2.58712 0 3.33333 0.746192 3.33333 1.66667ZM1.66667 9.16667C2.58712 9.16667 3.33333 8.42046 3.33333 7.5C3.33333 6.57954 2.58712 5.83333 1.66667 5.83333C0.746208 5.83333 0 6.57954 0 7.5C0 8.42046 0.746208 9.16667 1.66667 9.16667ZM1.66667 15C2.58712 15 3.33333 14.2538 3.33333 13.3333C3.33333 12.4129 2.58712 11.6667 1.66667 11.6667C0.746208 11.6667 0 12.4129 0 13.3333C0 14.2538 0.746208 15 1.66667 15Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.66732 1.66667C9.66732 2.58712 8.92111 3.33333 8.00065 3.33333C7.08019 3.33333 6.33398 2.58712 6.33398 1.66667C6.33398 0.746192 7.08019 0 8.00065 0C8.92111 0 9.66732 0.746192 9.66732 1.66667ZM8.00065 9.16667C8.92111 9.16667 9.66732 8.42046 9.66732 7.5C9.66732 6.57954 8.92111 5.83333 8.00065 5.83333C7.08019 5.83333 6.33398 6.57954 6.33398 7.5C6.33398 8.42046 7.08019 9.16667 8.00065 9.16667ZM8.00065 15C8.92111 15 9.66732 14.2538 9.66732 13.3333C9.66732 12.4129 8.92111 11.6667 8.00065 11.6667C7.08019 11.6667 6.33398 12.4129 6.33398 13.3333C6.33398 14.2538 7.08019 15 8.00065 15Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
              <p className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path
                    d="M16.292 9.49284L20.4587 5.32617M20.4587 5.32617V8.45117M20.4587 5.32617H17.3337"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.871 15.2714L16.3971 15.7714C16.3971 15.7714 15.2689 16.9579 12.1908 13.7172C9.11268 10.4766 10.2408 9.29015 10.2408 9.29015L10.5398 8.97453C11.2752 8.19953 11.345 6.95578 10.7023 6.04745L9.38976 4.19015C8.59393 3.06515 7.05747 2.91724 6.14601 3.87661L4.51163 5.5964C4.06059 6.07245 3.75851 6.68807 3.79497 7.37245C3.88872 9.12245 4.63663 12.886 8.80747 17.2787C13.2314 21.9349 17.3825 22.1204 19.0793 21.9527C19.6168 21.9006 20.0835 21.6099 20.4596 21.2141L21.9387 19.6558C22.9387 18.6037 22.6575 16.8016 21.3783 16.0662L19.3887 14.9204C18.5491 14.4381 17.5273 14.5797 16.871 15.2704"
                    fill="currentColor"
                  />
                </svg>
              </p>
              {/* 착신전환 카드 제목 */}
              {type === 'update' || type === 'add' ? (
                <div>
                  <input
                    placeholder={type === 'add' ? '착신전환' : '16자 이내로 입력해주세요'}
                    value={callTitleState}
                    onChange={handleCallTitle}
                    maxLength={16}
                  />
                </div>
              ) : (
                <h3 className="txt">{callTitleState ? callTitleState : '착신전환'}</h3>
              )}
            </div>
            {type !== 'add' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
          <div
            className="list"
            onClick={(e) => {
              if (!handleUpdateIdState) return;
              handleUpdateIdState(data?.id);
            }}
          >
            {type === 'view' && <p>{TelHyphen(data?.target?.level)}</p>}
            {type === 'update' && (
              <input placeholder="착신전화할 번호를 입력해주세요" value={callNumberState} onChange={handleCallNumber} />
            )}
            {type === 'add' && (
              <input placeholder="착신전화할 번호를 입력해주세요" value={callNumberState} onChange={handleCallNumber} />
            )}
          </div>
        </div>
      )}
      {/* 하단 버튼 */}
      {type !== 'view' && (
        <div className="btnGroup">
          <button
            className="cancelBtn"
            onClick={() => {
              if (handleCancel) {
                handleCancel();
                handleCancelCall();
              }
            }}
          >
            취소
          </button>
          <button
            className="saveBtn"
            onClick={() => {
              if (handleCancel) handleCancel();
              handleSaveCall();
            }}
          >
            저장
          </button>
        </div>
      )}
    </>
  );
};
export default ArsCardCall;
