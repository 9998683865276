import styled from 'styled-components';

export const TaxCashReceiptsHistoryWrap = styled.div`
  > h2 {
    margin-bottom: 2rem;
  }
`;

export const TableFoot = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
`;
