import styled from 'styled-components';

export const SubscriptionGuideWrap = styled.div`
  .subGuide {
    h2 {
      font-size: 2rem;
      font-weight: 700;
      line-height: 3rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.3rem;
      font-weight: 700;
      color: ${(props) => props.theme.colors.main};
      line-height: normal;
    }
  }
  .rowSpan {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2rem;
    background: #d6d6dc;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
    margin: 2.4rem 0 4rem;
  }
`;
