import styled from 'styled-components';

export const SignUpProcessWrap = styled.div`
  width: 100%;
  margin: 5rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const SignUpProcessName = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
`;

export const SignUpProcessBarWrap = styled.div`
  display: flex;
  gap: 1.6rem;
  max-width: 20rem;
  width: 100%;
`;
export const SignUpProcessBar = styled.div`
  display: block;
  width: 100%;
  height: 0.6rem;
  background: ${(props) => props.theme.colors.deactivation};
  &.active {
    background: ${(props) => props.theme.colors.main};
  }
`;

// 하단 3개 - 이름 전체 표출
export const SignUpProcessTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% / 3);

  span {
    background: ${(props) => props.theme.colors.deactivation};
  }
  p {
    color: ${(props) => props.theme.colors.deactivation};
  }

  &.active {
    span {
      background: ${(props) => props.theme.colors.main};
    }
    p {
      color: ${(props) => props.theme.colors.main};
    }
  }
`;

export const SignUpProcesseEllipse = styled.span`
  display: block;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 100%;
`;

export const SignUpProcesseName = styled.p`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.main};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 360px) {
    font-size: 1rem;
  }
`;
