import React from 'react';
import * as S from './Signupterms.styles';

const ComProtectInfo3rd = () => {
  return (
    <S.SignuptermsContainer className="com_protect com_protect_info3rd">
      <h5>1. 개인정보 제공항목과 그 목적</h5>
      <table>
        <thead>
          <tr>
            <th>제공받는 자</th>
            <th>이용 목적</th>
            <th>제공 항목</th>
            <th>개인정보 보유 및 이용기간</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>(주)브로컬리마켓</td>
            <td>모바일상품권 컨텐츠 제휴</td>
            <td>아이디, 성명, 휴대전화번호</td>
            <td rowSpan={3}>이용자가 제3자의 서비스를 제공받아 이용목적이 달성된 시점까지</td>
          </tr>
          <tr>
            <td>(주)서울이동통신</td>
            <td>무선호출서비스 이용</td>
            <td>아이디, 성명, 휴대전화번호, 주소 </td>
          </tr>
        </tbody>
      </table>

      <h5>2. 동의를 거부할 권리 및 거부할 경우의 불이익</h5>

      <p>
        귀하께서는 개인정보 제공 및 활용에 대하여 동의하지 않을 수 있습니다. 단, 위 1항의 제공사항은 무선호출서비스 등을
        이용하기 위해서 반드시 필요한 사항으로, 동의하지 않을 경우 무선호출서비스 등 위 1항의 서비스 이용이 불가능하거나
        제한될 수 있음을 알려드립니다.
      </p>
    </S.SignuptermsContainer>
  );
};
export default ComProtectInfo3rd;
