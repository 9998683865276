import React from 'react';
import * as S from './SignIn.styles';
import { Link } from 'react-router-dom';
import Logo from '@/components/Atom/Logo';

const SigninHeader = () => {
  return (
    <S.SigninHeader>
      <S.SigninLogo>
        <Logo eType="Reload" sType="W" />
      </S.SigninLogo>
    </S.SigninHeader>
  );
};

export default SigninHeader;
