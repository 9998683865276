import React from 'react';
import { useChargeMenuItems } from '../../../components/hooks/customs/charge/chargeStation/useChargeMenuItems';
import { amountFormat } from '@/shared/util/format/amountFormatUtil';
import { parseInt } from 'lodash';

/**
 * @title 캐시 충전금액 설정 컴포넌트
 *
 * @author 정휘학
 * @since 2024.05.18
 * */

interface ICashChargeAmount {
  // 결제금액 변경 hook
  handleAmount(amount: number): void;
  // 결제금액
  amountS: number;
}

const CashChargeAmount = (props: ICashChargeAmount) => {
  const itemHooks = useChargeMenuItems();

  return (
    <>
      <div className="amountEnter">
        <p className="text">충전금액 직접 입력하기 ( 최소 1,000원 / 최대 9,999,999원 )</p>
        <div>
          <input
            className="amount"
            type="number"
            placeholder="충전금액 직접 입력하기 ( 최소 1,000원 / 최대 9,999,999원 )"
            value={props.amountS === 0 ? '' : props.amountS}
            onChange={(e) => props.handleAmount(parseInt(e.target.value))}
          />
          <span>원</span>
        </div>
      </div>
      <ul className="sumGroup">
        {itemHooks.amountItems.map((item) => {
          return (
            <li key={item}>
              <label htmlFor={`sum ${item}`}>
                <input id={`sum ${item}`} type="radio" checked={props.amountS === item} onChange={() => {}} />
                <div onClick={() => props.handleAmount(item)}>
                  <p className="pay">
                    {amountFormat(item)} <small>원</small>
                  </p>
                </div>
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CashChargeAmount;
