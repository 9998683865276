import React from 'react';
import styled from 'styled-components';

interface IconProps {
  width?: string;
  height?: string;
  className?: string;
}

const Svg = styled.svg<IconProps>`
  width: ${(props) => (props.width ? props.width : '1.6rem')};
  height: ${(props) => (props.height ? props.height : '2.1rem')};
`;

const IconHuman = ({ className, width, height }: IconProps) => {
  return (
    <Svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71261 0.0806885C6.48355 0.0806885 5.30484 0.568927 4.43577 1.438C3.5667 2.30707 3.07846 3.48578 3.07846 4.71483C3.07846 5.94389 3.5667 7.1226 4.43577 7.99167C5.30484 8.86074 6.48355 9.34898 7.71261 9.34898C8.94166 9.34898 10.1204 8.86074 10.9894 7.99167C11.8585 7.1226 12.3468 5.94389 12.3468 4.71483C12.3468 3.48578 11.8585 2.30707 10.9894 1.438C10.1204 0.568927 8.94166 0.0806885 7.71261 0.0806885ZM4.54188 4.71483C4.54188 3.8739 4.87593 3.06742 5.47056 2.47279C6.06519 1.87816 6.87168 1.5441 7.71261 1.5441C8.55354 1.5441 9.36002 1.87816 9.95465 2.47279C10.5493 3.06742 10.8833 3.8739 10.8833 4.71483C10.8833 5.55577 10.5493 6.36225 9.95465 6.95688C9.36002 7.55151 8.55354 7.88557 7.71261 7.88557C6.87168 7.88557 6.06519 7.55151 5.47056 6.95688C4.87593 6.36225 4.54188 5.55577 4.54188 4.71483Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.52054 12.0516C3.8864 11.2711 5.72347 10.8125 7.71274 10.8125C9.70298 10.8125 11.5391 11.2711 12.9049 12.0516C14.2493 12.8203 15.2737 13.993 15.2737 15.4467C15.2737 16.9003 14.2493 18.073 12.9049 18.8418C11.5391 19.6223 9.70201 20.0808 7.71274 20.0808C5.7225 20.0808 3.8864 19.6223 2.52054 18.8418C1.17615 18.073 0.151764 16.9003 0.151764 15.4467C0.151764 13.993 1.17615 12.8203 2.52054 12.0516ZM3.2464 13.3228C2.11957 13.9667 1.61518 14.7442 1.61518 15.4467C1.61518 16.1491 2.11957 16.9267 3.2464 17.5706C4.35274 18.2028 5.9303 18.6174 7.71274 18.6174C9.49518 18.6174 11.0727 18.2028 12.1791 17.5706C13.3059 16.9267 13.8103 16.1481 13.8103 15.4467C13.8103 14.7442 13.3059 13.9667 12.1791 13.3228C11.0727 12.6906 9.49518 12.2759 7.71274 12.2759C5.9303 12.2759 4.35274 12.6906 3.2464 13.3228Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default IconHuman;
