import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { pickItemRecoil } from '@/recoil/atoms/say015Atom';
import { Message015Type } from '@/components/hooks/customs/useEachChatRoomHook';
import { softPhoneSaveMessageRecoil } from '@/recoil/atoms/router/mainRouterRecoil';

export const rightClickOption = [{ label: '대화방 나가기', value: 'itemDeleteAllMsg' }];

export const useChatRoomStatus = () => {
  /* List 우클릭 좌표 STATE */
  const [vectorState, setVectorState] = useState<{ x: number; y: number } | null>(null);
  /* 우클릭 Select toggle STATE */
  const [itemSelectToggle, setItemSelectToggle] = useState<boolean>(false);
  /* 우클릭 Select option STATE */
  const [itemOption, setItemOption] = useState<{ label: string; value: string }[]>(rightClickOption);
  /* 우클릭 선택한 아이템 data STATE */
  const [pickItemState, setPickItemState] = useRecoilState(pickItemRecoil);

  // ==============================
  // 015사서함 리스트 아이템 우클릭 hook
  // ==============================
  const onContextMenuItem = (e: React.MouseEvent<Element, MouseEvent>, item: Message015Type) => {
    e.preventDefault();
    const vector = { x: e.clientX, y: e.clientY };
    setVectorState(vector);
    setPickItemState(item);
    if (item.phoneNumber) {
      if (pickItemState === null || pickItemState?.phoneNumber !== item.phoneNumber) {
        setItemSelectToggle(true);
      } else {
        setItemSelectToggle((prev) => !prev);
      }
    }
    // 그룹 대화방용
    // if (item.chatRoomId) {
    //   if (pickItemState === null || pickItemState?.chatRoomId !== item.chatRoomId) {
    //     setItemSelectToggle(true);
    //   } else {
    //     setItemSelectToggle((prev) => !prev);
    //   }
    // }
  };

  // ==============================
  // 015사서함 리스트 아이템 Select true시 마우스 휠이나, 클릭 이벤트시 select off hook
  // ==============================
  useEffect(() => {
    const handleOutsideClick = () => {
      setItemSelectToggle(false);
    };

    const handleWheelOutside = () => {
      setItemSelectToggle(false);
    };

    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('wheel', handleWheelOutside);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('wheel', handleWheelOutside);
    };
  }, []);

  return { onContextMenuItem, itemSelectToggle, vectorState, itemOption, pickItemState };
};
