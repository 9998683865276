import React from 'react';
import * as SS from '@/widgets/ars/components/arsTree/components/arsCardModal/ui/ArsCardModal.styles';
import { ArsCardModalProps } from '@/components/say015/Modal/arsCards/ArsCardModal';
import { useArsSms } from '@/components/hooks/customs/say015/say105Handle/useArsSms';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useRecoilState } from 'recoil';
import { simpleViewArsTreeRecoil } from '@/recoil/atoms/say015Atom';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';

const ArsCardMsg = ({ data, type, handleUpdateIdState, handleCancel, arsData, updatedJsonFile }: ArsCardModalProps) => {
  /* 간편보기 사용여부 */
  const [simpleViewArsTreeS] = useRecoilState(simpleViewArsTreeRecoil);
  const { handleSaveMSG, handleCancelMSG, smsTextState, handleSmsText, smsTitleState, handleSmsTitle } = useArsSms({
    arsData,
    data,
    updatedJsonFile,
  });
  const { deleteArs } = use015ArsJSON({ arsData, data, updatedJsonFile });
  return (
    <>
      {/* 간편보기 O */}
      {simpleViewArsTreeS && (
        <div className={'simpleCard'}>
          <div className="txtTop">
            <div className="group">
              {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
              <p
                className="icon"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!handleUpdateIdState) return;
                  handleUpdateIdState(data?.id);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <path
                    d="M11.281 2.04428C10.1875 1.99443 9.09216 2.00548 7.99993 2.07736C4.25935 2.3259 1.28048 5.34679 1.03552 9.14011C0.98816 9.89034 0.98816 10.6428 1.03552 11.393C1.12492 12.7743 1.73554 14.0536 2.45522 15.1336C2.87273 15.8891 2.59737 16.8323 2.16198 17.6574C1.84908 18.252 1.69173 18.5488 1.81779 18.7633C1.94295 18.9779 2.22457 18.9851 2.7869 18.9985C3.89996 19.0253 4.65004 18.7106 5.24546 18.2716C5.5825 18.0222 5.75147 17.8979 5.8677 17.8836C5.98392 17.8693 6.21368 17.9641 6.67142 18.1518C7.08267 18.3217 7.56097 18.4263 7.99904 18.4558C9.27302 18.5398 10.6042 18.5398 11.8809 18.4558C15.6206 18.2073 18.5994 15.1855 18.8444 11.393C18.8629 11.0998 18.8743 10.8009 18.8784 10.5071M6.81089 12.7421H13.069M6.81089 8.27202H9.93995"
                    stroke="currentColor"
                    strokeWidth="1.49282"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5967 0.999995C20.5967 0.725174 20.3739 0.502387 20.0991 0.502387H15.6207C15.3458 0.502387 15.123 0.725174 15.123 0.999995C15.123 1.27482 15.3458 1.4976 15.6207 1.4976L19.6015 1.4976L19.6015 5.47846C19.6015 5.75329 19.8243 5.97607 20.0991 5.97607C20.3739 5.97607 20.5967 5.75329 20.5967 5.47846V0.999995ZM14.6932 7.10967L20.451 1.35186L19.7473 0.648133L13.9894 6.40595L14.6932 7.10967Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
              {type === 'update' && <h3 className="txt">문자발송</h3>}
            </div>
            {/* 삭제 버튼 */}
            {type === 'update' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
          {type !== 'view' && (
            <div className="list">
              <textarea placeholder="내용을 입력해주세요" defaultValue={smsTextState} onChange={handleSmsText} />
            </div>
          )}
        </div>
      )}
      {/* 간편보기 X */}
      {!simpleViewArsTreeS && (
        <div className={'card'}>
          <div className="txtTop">
            <div className="group">
              {/* 드래그앤 드롭시 필요한 그랩 아이콘 */}
              {type === 'view' && (
                <button className="grab">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.33333 1.66667C3.33333 2.58712 2.58712 3.33333 1.66667 3.33333C0.746208 3.33333 0 2.58712 0 1.66667C0 0.746192 0.746208 0 1.66667 0C2.58712 0 3.33333 0.746192 3.33333 1.66667ZM1.66667 9.16667C2.58712 9.16667 3.33333 8.42046 3.33333 7.5C3.33333 6.57954 2.58712 5.83333 1.66667 5.83333C0.746208 5.83333 0 6.57954 0 7.5C0 8.42046 0.746208 9.16667 1.66667 9.16667ZM1.66667 15C2.58712 15 3.33333 14.2538 3.33333 13.3333C3.33333 12.4129 2.58712 11.6667 1.66667 11.6667C0.746208 11.6667 0 12.4129 0 13.3333C0 14.2538 0.746208 15 1.66667 15Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.66732 1.66667C9.66732 2.58712 8.92111 3.33333 8.00065 3.33333C7.08019 3.33333 6.33398 2.58712 6.33398 1.66667C6.33398 0.746192 7.08019 0 8.00065 0C8.92111 0 9.66732 0.746192 9.66732 1.66667ZM8.00065 9.16667C8.92111 9.16667 9.66732 8.42046 9.66732 7.5C9.66732 6.57954 8.92111 5.83333 8.00065 5.83333C7.08019 5.83333 6.33398 6.57954 6.33398 7.5C6.33398 8.42046 7.08019 9.16667 8.00065 9.16667ZM8.00065 15C8.92111 15 9.66732 14.2538 9.66732 13.3333C9.66732 12.4129 8.92111 11.6667 8.00065 11.6667C7.08019 11.6667 6.33398 12.4129 6.33398 13.3333C6.33398 14.2538 7.08019 15 8.00065 15Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}

              {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
              <p className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
                  <path
                    d="M14.5788 3.70446C13.2054 3.64185 11.8295 3.65572 10.4576 3.74601C5.75907 4.0582 2.01735 7.8527 1.70965 12.6174C1.65017 13.5598 1.65017 14.505 1.70965 15.4473C1.82195 17.1823 2.58894 18.7893 3.49292 20.1458C4.01735 21.0947 3.67148 22.2794 3.12459 23.3159C2.73155 24.0627 2.53391 24.4355 2.69225 24.7051C2.84946 24.9746 3.2032 24.9835 3.90954 25.0004C5.30764 25.0341 6.24981 24.6388 6.9977 24.0874C7.42106 23.7741 7.6333 23.618 7.77929 23.6001C7.92527 23.5821 8.21387 23.7011 8.78883 23.9369C9.3054 24.1503 9.90618 24.2817 10.4564 24.3188C12.0567 24.4243 13.7288 24.4243 15.3324 24.3188C20.0297 24.0066 23.7714 20.2109 24.0791 15.4473C24.1023 15.079 24.1167 14.7035 24.1218 14.3345M8.96401 17.1419H16.8248M8.96401 11.527H12.8944"
                    stroke="currentColor"
                    strokeWidth="1.875"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M26.2802 2.39268C26.2802 2.0475 26.0003 1.76768 25.6552 1.76768H20.0302C19.685 1.76768 19.4052 2.0475 19.4052 2.39268C19.4052 2.73786 19.685 3.01768 20.0302 3.01768H25.0302V8.01768C25.0302 8.36286 25.31 8.64268 25.6552 8.64268C26.0003 8.64268 26.2802 8.36286 26.2802 8.01768V2.39268ZM18.8648 10.0669L26.0971 2.83462L25.2132 1.95074L17.9809 9.18306L18.8648 10.0669Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
              {/* 문자 카드 제목 */}
              {type === 'update' || type === 'add' ? (
                <div>
                  <input
                    placeholder={type === 'add' ? '문자발송' : '16자 이내로 입력해주세요'}
                    value={smsTitleState}
                    onChange={handleSmsTitle}
                    maxLength={16}
                  />
                </div>
              ) : (
                <h3 className="txt">{smsTitleState ? smsTitleState : '문자발송'}</h3>
              )}
            </div>
            {/* 삭제 버튼 */}
            {type !== 'add' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
          <div
            className="list"
            onClick={() => {
              if (!handleUpdateIdState) return;
              handleUpdateIdState(data?.id);
            }}
          >
            {type === 'mock' && (
              <p>영업시간 안내입니다. 저희 매장은 월요일부터 일요일까지 오전 10시부터 오후 10시까지 영업합니다.</p>
            )}
            {type === 'view' && <p>{data?.target?.level}</p>}
            {type === 'update' && (
              <div>
                <textarea placeholder="내용을 입력해주세요" value={smsTextState} onChange={handleSmsText} />
                <SS.TxtByte>
                  <p>{calMsgByte(smsTextState)} / 1985 Byte</p>
                </SS.TxtByte>
              </div>
            )}
            {type === 'add' && (
              <div>
                <textarea placeholder="내용을 입력해주세요" value={smsTextState} onChange={handleSmsText} />
                <SS.TxtByte>
                  <p>{calMsgByte(smsTextState)} / 1985 Byte</p>
                </SS.TxtByte>
              </div>
            )}
          </div>
        </div>
      )}
      {/* 하단 버튼 */}
      {type !== 'view' && type !== 'mock' && (
        <div className="btnGroup">
          <button
            className="cancelBtn"
            onClick={() => {
              if (handleCancel) {
                handleCancel();
                handleCancelMSG();
              }
            }}
          >
            취소
          </button>
          <button
            className="saveBtn"
            onClick={() => {
              if (handleCancel) handleCancel();
              handleSaveMSG();
            }}
          >
            저장
          </button>
        </div>
      )}
    </>
  );
};
export default ArsCardMsg;
