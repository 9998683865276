import { callbackNumberState, selectNumberState } from '@/recoil/atoms/addressList';
import { softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useCallingNumberAdmin } from '../myPage/useCallingNumberAdmin';

/**
 * @title softPhone 상단 발신번호 관련 Hooks
 *
 * @author 김남규
 * @since 2024.03.22
 **/
export const useToolBarCallingNum = () => {
  /* 발신번호 Select toggle STATE */
  const [selectToggle, setSelectToggle] = useState(false);
  /* 로컬 스토리지에 담을 선택 발신번호 */
  const [selectNumber, setSelectNumber] = useRecoilState(selectNumberState);
  const [softPhoneTopTabState] = useRecoilState(softPhoneTopTabRecoil);
  /* 선택 가능한 발신번호 List recoil */
  const [callbackNumbers, setCallbackNumbers] = useRecoilState<any[] | undefined>(callbackNumberState);
  /* 로컬스토리지 발신번호 값 */
  const storageNumberString = localStorage.getItem('contacts');
  const storageNumber = storageNumberString && JSON.parse(storageNumberString);

  const { callbackListS } = useCallingNumberAdmin();

  // ==============================
  // Select option onClick Hook
  // ==============================
  const onClickSelectNumber = (number: string) => {
    const checkNumber = number.startsWith('015') ? '015' : '010';
    const updateNumber = { ...selectNumber, [checkNumber]: number };
    localStorage.setItem('contacts', JSON.stringify(updateNumber));
    setSelectNumber(updateNumber);
    setSelectToggle((prev) => !prev);
  };

  // ==============================
  // softPhone 상단 탭 메뉴 발신번호 변경 useEffect
  // ==============================
  useEffect(() => {
    const numbers = callbackListS?.map((item) => item.callback);
    const checkNumber = softPhoneTopTabState === '0' || softPhoneTopTabState === '2' ? '015' : '010';
    setCallbackNumbers(numbers);

    // 로컬스토리지 발신번호가 없고, 발신번호 리스트가 없으면 리턴
    if (!storageNumber && !numbers) return;

    // 로컬스토리지 발신번호가 없고, 발신번호 리스트가 있으면 타는 로직
    if (!storageNumber?.[checkNumber] && numbers) {
      const filteredNumbers: string[] = numbers.filter((number) => {
        if (checkNumber === '015') {
          return number.startsWith(checkNumber);
        }
        return !number.startsWith('015');
      });
      const selectedNumber = filteredNumbers[0];
      const updateNumber = { ...selectNumber, [checkNumber]: selectedNumber };
      setSelectNumber(updateNumber);
      return;
    }

    // 로컬스토리지 발신번호가 있고, 발신번호 리스트가 있으면 타는 로직
    if (storageNumber && numbers) {
      // 발신번호 인증된 List에 존재하는 번호인지 검증하는 단계
      const checkUpdateNumber = callbackListS?.some((item) => item.callback.includes(storageNumber[checkNumber]));
      if (checkUpdateNumber) {
        setSelectNumber((prev) => ({ ...prev, [checkNumber]: storageNumber[checkNumber] }));
      } else {
        localStorage.removeItem('contacts');
        // warningModal('주의', '잘못된 발신번호입니다.( -___- ^)', true);
      }
    }
  }, [callbackListS, softPhoneTopTabState, storageNumberString]);

  return {
    selectNumber,
    selectToggle,
    setSelectToggle,
    callbackListS,
    softPhoneTopTabState,
    onClickSelectNumber,
    callbackNumbers,
  };
};
