/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';

interface IATitle {
  type: 'main' | 'sub';
  text?: string;
  color?: string;
  className?: string;
}

const SubTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.main};

  &.marB {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
    &.borderBottom {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
      padding: 0 0 0.5rem;
    }
  }
`;

const MainTitle = styled.h1`
  font-size: 2.2rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.main};
  @media screen and (max-width: 768px) {
    font-size: 2.4rem;
    &.borderBottom {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
      padding: 0 0 0.5rem;
    }
  }
  @media screen and (max-width: 400px) {
    font-size: 1.6rem;
  }
`;

const ATitle = ({ type, text, color, className }: IATitle) => {
  return type === 'main' ? (
    <MainTitle className={`title mainTilte ${className ? `${className}` : ''}`} color={color}>
      {text}
    </MainTitle>
  ) : (
    <SubTitle className={`title subTitle ${className ? `${className}` : ''}`} color={color}>
      {text}
    </SubTitle>
  );
};
// ATitle.defaultProps = {
//   color: '#000',
// };

export default ATitle;
