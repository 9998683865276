import React from 'react';
import * as S from './MobileEtcUserNameChange.style';
import * as SS from '../MobileEtc.style';
import UserNameChange from '@/components/Organism/MyPage/UserNameChange';
import ATitle from '@/components/Atom/ATitle';
import BaseGuide from '@/components/Atom/BaseGuide';

const MobileEtcUserNameChange = () => {
  const fileUrl = '/file/015WORKS_명의변경신청서.pdf';

  return (
    <S.MobileEtcUserNameChangeContainer className="MobileEtcUserNameChange">
      <SS.AWrapper className="basicTable">
        <SS.TitleWrapper className="titleWrap">
          <ATitle type="sub" text="명의 변경" />
        </SS.TitleWrapper>
        <SS.MyPageTable>
          <tbody>
            <tr>
              <th>전화</th>
              <td>
                <div className="automatic">015-8504-0006</div>
              </td>
            </tr>
            <tr>
              <th>팩스</th>
              <td>
                <div className="automatic">02-3485-6710</div>
              </td>
            </tr>
            <tr>
              <th>전자메일</th>
              <td>
                <div className="automatic">help@arreo.com</div>
              </td>
            </tr>
            <tr>
              <th>명의변경 신청서</th>
              <td>
                <a href={fileUrl} download="015WORKS_명의변경신청서.pdf">
                  <button type="button">다운로드_PDF</button>
                </a>
              </td>
            </tr>
          </tbody>
        </SS.MyPageTable>
      </SS.AWrapper>
      <BaseGuide
        text="본인(현재 아이디 소유자)의 신분증과 명의를 이전 받고자 하시는 분의 신분증 사본을 고객상담실로 보내주셔야 합니다.
                <br />명의변경 신청서를 다운받아 내용을 작성하여 팩스 및 전자메일로 보내주세요.
                <br />팩스 발송 후 고객상담실에서 내용 확인하여 회원님의 휴대폰으로 연락드린 후 바로 처리해 드리겠습니다. 
                <br />요청하는 개인정보는 고객의 업무처리 요청 및 동의에 따라 해당 업무처리를 위하여만 수집·이용하며, 업무처리 종료시에는 관계법령에 의거, 
                즉시 파기하며 이와 별도로 서비스 처리에 관한 단순 이력은 6개월간 보관 후 파기하오니, 서비스 이용에 참고하시기 바랍니다."
      />
    </S.MobileEtcUserNameChangeContainer>
  );
};
export default MobileEtcUserNameChange;
