import React from 'react';
import My015SubInfo from '@/pages/My015Charge/My015SubInfo';
import * as S from './MobileEtcSubInfo.style';
import * as SS from '../MobileEtc.style';
import { useSay015SubInfo } from '@/components/hooks/customs/myPage/useSay015SubInfo';
import ATitle from '@/components/Atom/ATitle';
import Say015NotInfo from '@/components/Organism/MyPage/Say015NotInfo';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import { formatDateBase } from '@/shared/util/format/dateFormatUtil';
import BaseGuide from '@/components/Atom/BaseGuide';

const MobileEtcSubInfo = () => {
  const subHooks = useSay015SubInfo();
  return (
    <S.MobileEtcSubInfoContainer className="mobileEtcSubInfo">
      <SS.AWrapper className="basicTable">
        <SS.TitleWrapper className="titleWrap">
          <ATitle type="sub" text="015 발급 정보" />
        </SS.TitleWrapper>
        {subHooks.pageState === '미가입' ? (
          <Say015NotInfo subHooks={subHooks} />
        ) : (
          <SS.MyPageTable className="tw30">
            <tbody>
              {/* 유저 이름 */}
              <tr>
                <th>이름</th>

                <td>
                  <div className="automatic">{subHooks.user}</div>
                </td>
              </tr>
              {/* 015 발급 번호 */}
              <tr>
                <th>015 번호</th>

                <td>
                  <div className="automatic">{formatPhoneNumber(subHooks.authorityData?.number015)}</div>
                </td>
              </tr>
              {/* 이용기간 */}
              <tr>
                {subHooks.pageState === '구독만료' ? (
                  <>
                    <th>구독 만료일</th>
                    <td>
                      <div className="automatic">{formatDateBase(subHooks.authorityData?.subsEndDate)}</div>
                    </td>
                  </>
                ) : (
                  <>
                    <th>이용기간</th>
                    <td>
                      <div className="automatic">
                        {formatDateBase(subHooks.authorityData?.subsStartDate)} ~{' '}
                        {formatDateBase(subHooks.authorityData?.subsEndDate)}
                      </div>
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </SS.MyPageTable>
        )}
      </SS.AWrapper>
      {/* <BaseGuide
        text="이용기간 도중 구독해지를 신청할 경우,
                <br />당월 결제기간 동안 서비스 이용이 가능하며,
                <br />서비스 이용 중단 6개월이 지나면, 발급된 015 번호와 내역은 모두 삭제됩니다."
      /> */}
      <BaseGuide
        text="이용기간 동안 서비스 이용이 가능하며,
              <br />서비스 이용 만료일로부터 6개월이 지나면, 발급된 015 번호와 내역은 모두 삭제됩니다."
      />
    </S.MobileEtcSubInfoContainer>
  );
};
export default MobileEtcSubInfo;
