/* eslint-disable */
import NextLoader from '@/components/common/NextLoader';
import NoData from '@/components/common/NoData';
import SkeletonBox from '@/components/common/SkeletonBox';
import { useTrcContent } from '@/components/hooks/customs/messgeManagement/trContent/useTrContent';
import { useSendReservation } from '@/components/hooks/customs/sendResult/sendReservation/useSendReservation';
import { useLoaderTimer } from '@/shared/hooks/useLoaderTimer';
import React from 'react';
import * as MLS from '../../../styles/CommonListStyles/CommonList.styles';
import * as S from '../TransmissionResult.styles';
import MsgReserveBox from './MsgReserveBox';
import MsgResultBox from './MsgResultBox';

// 010탭 예약전송, 전송결과 리스트 컴포넌트
const SendSoftPhoneBottom = () => {
  const trcHooks = useTrcContent();
  const reserHooks = useSendReservation();
  const timerHooks = useLoaderTimer({});

  return (
    <S.TransmissionResult className="TransmissionResult" ref={trcHooks.listRef} onScroll={trcHooks.onScrollHandler}>
      {timerHooks.timer || trcHooks.isLoading ? (
        // 로딩시 스켈레톤 UI
        <MLS.MessageList className="messageList">
          {[...Array(5)].map((_, index) => (
            <SkeletonBox
              key={index}
              type="1"
              title={{ width: '30%' }}
              paragraph={{ rows: 2, width: ['20%', '80%'] }}
              active
            />
          ))}
        </MLS.MessageList>
      ) : trcHooks.transTableDataState?.pages?.[0]?.content.length > 0 ? (
        <MLS.MessageList className="messageList">
          {trcHooks.transTableDataState.pages.map((page: any) => (
            <React.Fragment key={page.pageable.pageNumber}>
              {page.content.map((el: any) => {
                return el.sentGb === '0' ? (
                  // 전송결과 리스트 박스
                  <MsgResultBox
                    key={el.prepayPayNo}
                    prepayPayNo={el.prepayPayNo}
                    callback={el.callback}
                    subject={el.subject}
                    rcvInfo={el.rcvInfo}
                    sndDttm={el.sndDttm}
                    imageExists={el.imageExists}
                    sndMsg={el.sndMsg}
                    resultDetailParamsState={trcHooks.resultDetailParamsState}
                    onClickList={trcHooks.onClickList}
                  />
                ) : (
                  // 예약전송 리스트 박스
                  <MsgReserveBox
                    key={el.prepayPayNo}
                    prepayPayNo={el.prepayPayNo}
                    callback={el.callback}
                    subject={el.subject}
                    rcvInfo={el.rcvInfo}
                    sndDttm={el.sndDttm}
                    imageExists={el.imageExists}
                    sndMsg={el.sndMsg}
                    scheduleDetailParamsState={reserHooks.scheduleDetailParamsState}
                    handleActiveList={reserHooks.handleActiveList}
                  />
                );
              })}
              {trcHooks.isFetchingNextPage && <NextLoader />}
            </React.Fragment>
          ))}
        </MLS.MessageList>
      ) : trcHooks.isLoading || timerHooks.timer ? null : (
        <NoData />
      )}
    </S.TransmissionResult>
  );
};

export default SendSoftPhoneBottom;
