import { SendInfoResponseType } from '@/apis/api/addressApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { SendMsgParamsType, useSendCommon } from '@/components/hooks/customs/messageSend/useSendCommon';
import { useSendMessageMutations } from '@/components/hooks/mutations/useSendMessageMutations';
import { activeChatRoomIdRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { instanceFile } from '@/shared/lib/clientAxios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sendMsgModalText } from '../useModalText';

/**
 * @title 015 채팅방 메세지 전송 hook
 *
 * @author 정휘학
 * @since 2024.07.03
 * */

export const useSend015Chat = (chatRoomRef: React.RefObject<HTMLDivElement>) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { confirmModal } = useModalHook();

  // // 중복 메세지 전송 제어 state
  // const [blockS, setBlockS] = useState<boolean>(false);
  /* 채팅방 open시 focus를 위한 STATE */
  const [openFocus, setOpenFocus] = useState<boolean>(false);
  /* 좌측 채팅방 리스트 API params - 해당 recoil 에 담겨있는 상대방 번호 사용 */
  const [mhQueryParamsS] = useRecoilState(mhQueryParamsRecoil);
  /* 채팅방 리스트 상대방 번호 - 이전값과 비교하기 위한 STATE */
  const [copyNum, setCopyNum] = useState<string | null>(null);
  /* 현재 활성화된 채팅방 아이디 */
  const activeChatRoomId = useRecoilValue(activeChatRoomIdRecoil);
  /* 팝업창 */
  const { warningModal } = useModalHook();
  /* 메세지 전송에 관련된 공통 hook */
  const {
    resetMsgState,
    onCheckMsgType,
    sendMsgText,
    onChangeSendMsgText,
    sendImgFile,
    sendImgFileUrl,
    sendMsgByte,
    cancelImgFile,
    onChangeUploadImg,
    selectNumberS,
    textAreaRef,
    sendImgFileRef,
    isProcessingToSend,
    setIsProcessingToSend,
    adMessageS,
    setAdMessageS,
    msgTypeS,
    setMsgTypeS,
    byteS,
    setByteS,
    onClickInputFile,
  } = useSendCommon({ type: '015' });

  const environmentS = useRecoilValue(environmentRecoil);

  /* 메세지 전송에 관련된 Mutations */
  const { sendMsgM, sendMsgInfoM, sendMsgInfoL, sendMsgL, onSuccessToSendMsgInfoM } = useSendMessageMutations({
    resetMsgState,
  });

  useEffect(() => {
    resetMsgState();
  }, [mhQueryParamsS.contactNumber]);

  /**
   * ===========================================
   * @title 메세지 전송전에 형식이 올바른지 확인하는 hook
   * ===========================================
   * */
  const sendMsgValidate = ({ rcvPhoneNumber }: { rcvPhoneNumber: string[] }) => {
    const callback = selectNumberS['015'];
    /* 받는 사람의 번호 및 발신 번호가 정상적으로 담기지 않은 경우 */
    if (!(rcvPhoneNumber.length > 0) && !callback) {
      warningModal(
        '메세지 전송',
        '메세지 전송에 있어 오류가 발생했습니다.</br> 새로고침 후 다시 시도 부탁드립니다. 죄송합니다',
        true,
      );
      return false;
    }

    const usrCd = onCheckMsgType(); // 메세지 타입 체크
    /* 파일 및 텍스트 내용을 작성하지 않고 전송하려고 했을 경우 */
    if (!usrCd) {
      warningModal('메세지 전송', '메세지 전송 형식이 올바르지 않습니다.', true);
      return false;
    }

    const dto: SendMsgParamsType = {
      callback,
      sndMsg: sendMsgText,
      usrCd,
      etcPhoneNumberList: rcvPhoneNumber,
      buddySeqList: [],
      groupSeqList: [],
      reSendPhoneNumberList: [],
      subject: '[제목없음]',
    };
    return dto;
  };

  /**
   * ==========================
   * @title 메세지 전송 이벤트 핸들러
   * ==========================
   **/
  const sendMsgHandler = async (e: any) => {
    e.preventDefault();
    const rcvPhoneNumber = mhQueryParamsS.contactNumber; // 메세지 받을 사람의 번호
    if (isProcessingToSend || !rcvPhoneNumber || sendMsgL || sendMsgInfoL) return;
    setIsProcessingToSend(true);

    const dto = sendMsgValidate({ rcvPhoneNumber: [rcvPhoneNumber] });
    if (!dto) {
      setIsProcessingToSend(false);
      return;
    }
    const json = JSON.stringify(dto);
    const formData = new FormData();
    const blob = new Blob([json], { type: 'application/json' });

    formData.append('file', sendImgFile);
    formData.append('dto', blob);

    // TODO - 발신번호 관련 동의 여기서
    /* 수신받는 번호가 015 번호일 경우 바로 전송 */
    if (rcvPhoneNumber.startsWith('015')) {
      try {
        await sendMsgM(formData);
      } catch (error) {
        console.error('Error send message:', error);
      } finally {
        setIsProcessingToSend(false);
      }
    } else {
      try {
        await sendMsgInfoM(dto, {
          onSuccess: (r: SendInfoResponseType) => {
            onSuccessToSendMsgInfoM({
              setIsProcessingToSend,
              isProcessingToSend,
              sendParams: formData,
              response: r,
            });
          },
        });
      } catch (error) {
        console.error('Error send message info:', error);
      }
    }
  };

  /**
   * ==========================
   * @title 단체 메세지 전송 이벤트 핸들러
   * ==========================
   **/

  interface MultiSendInfoDto {
    callback: string;
    usrCd: string | boolean;
    groupSeqList: number[];
    buddySeqList: number[];
    etcPhoneNumberList: string[];
    reSendPhoneNumberList: string[];
    chatRoomId: string;
    repeatCount: number;
  }

  interface Dto {
    callback: string;
    sndDttm: string;
    sndMsg: string;
    usrCd: string | boolean;
    groupSeqList: number[];
    buddySeqList: number[];
    etcPhoneNumberList: string[];
    reSendPhoneNumberList: string[];
    chatRoomId: string;
    subject: string;
  }

  //Todo 리패치 고치기

  /* 단체 전송에 관련된 Mutations */
  const { mutateAsync: multiSendMsgM, isLoading: multiSendMsgMLoading } = useMutation<any, Error, FormData>( // 반환값 타입을 any로 설정하거나 적절한 타입으로 수정
    async (formData: FormData) => {
      const response = await instanceFile.post('/smt/send/batch', formData);
      return response.data;
    },
    {
      onError: (err) => {
        console.error('Failed to send multi-message:', err);
      },
    },
  );

  const multiSendMsgHandler = async (e: any, chatRoomId: string) => {
    e.preventDefault();

    const callback = selectNumberS['015'];
    const usrCd = onCheckMsgType(); // 메세지 타입 체크

    const multiSendInfoDto: MultiSendInfoDto = {
      callback,
      usrCd,
      groupSeqList: [],
      buddySeqList: [],
      etcPhoneNumberList: [],
      reSendPhoneNumberList: [],
      chatRoomId,
      repeatCount: 0,
    };

    const dto: Dto = {
      callback, // 필요한 값으로 수정
      sndDttm: '',
      sndMsg: sendMsgText,
      usrCd, // 사용자 코드 추가 필요
      groupSeqList: [], // 그룹 번호 리스트 필요
      buddySeqList: [], // 친구 번호 리스트 필요
      etcPhoneNumberList: [], // 기타 전화번호 리스트 필요
      reSendPhoneNumberList: [], // 재전송 전화번호 리스트 필요
      chatRoomId,
      subject: '', // 제목 필요
    };

    const json = JSON.stringify(dto);
    const formData = new FormData();
    const blob = new Blob([json], { type: 'application/json' });

    formData.append('file', sendImgFile); // sendImgFile이 실제 파일 객체이어야 함
    formData.append('dto', blob);

    await sendMsgInfoM(multiSendInfoDto, {
      onSuccess: (r: SendInfoResponseType) => {
        /* 팝업창에 표출되는 텍스트 */
        const { content, title } = sendMsgModalText(r, '015');

        confirmModal(
          async () => {
            try {
              await multiSendMsgM(formData); // formData를 서버로 전송
              resetMsgState();
              queryClient.refetchQueries(['/smt/history/batch/room']);
              // .5초를 안주니까 최하단을 안찍음
              setTimeout(() => {
                if (chatRoomRef.current) {
                  chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
                }
              }, 500);
            } catch (error) {
              console.error('Error send message:', error);
            }
          },
          title,
          content,
          true,
        );
      },
    });
  };

  // ==============================
  // 015채팅방에서 메시지 전송후 또는 채팅방 open시 textarea에 focus하는 useEffect
  // ==============================
  useEffect(() => {
    if (!isProcessingToSend || openFocus) {
      environmentS !== 'app' && textAreaRef.current.focus();
    }
  }, [isProcessingToSend, copyNum]);

  useEffect(() => {
    setCopyNum(activeChatRoomId);
    if (activeChatRoomId !== copyNum) {
      setOpenFocus(true);
    } else {
      setOpenFocus(false);
      setCopyNum(null);
    }
  }, [activeChatRoomId]);

  return {
    sendMsgHandler,
    resetMsgState,
    onCheckMsgType,
    sendMsgText,
    onChangeSendMsgText,
    sendImgFile,
    sendImgFileUrl,
    sendMsgByte,
    cancelImgFile,
    onChangeUploadImg,
    selectNumberS,
    textAreaRef,
    sendImgFileRef,
    sendMsgInfoL,
    sendMsgL,
    isProcessingToSend,
    adMessageS,
    setAdMessageS,
    msgTypeS,
    setMsgTypeS,
    byteS,
    setByteS,
    onClickInputFile,
    multiSendMsgHandler,
    multiSendMsgMLoading,
  };
};
