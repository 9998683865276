/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import toWav from 'audiobuffer-to-wav';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useArsInitData } from '@/components/hooks/customs/say015/say105Handle/useArsInitData';
import { convertBase64ToWavFile, convertBlobToBase64 } from '@/shared/util/createWavFile';
import { update015ArsRecord } from '@/apis/api/say015Apis';
import { getUnixTime } from '@/shared/util/time/timeUtils';
import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';
import { useRecoilValue } from 'recoil';
import { say015Number } from '@/recoil/atoms/say015Atom';

interface IUseRecord {
  data: NextItem;
  arsData: NextItem;
  updatedJsonFile?(): void;
  setRecordAudioUrl: React.Dispatch<React.SetStateAction<string | null>>;
  cardTitle?: string;
}

/**
 * @title : say015 안내멘트 녹음 Hooks
 * */
export const useArsGuideRecord = ({ data, arsData, updatedJsonFile, setRecordAudioUrl, cardTitle }: IUseRecord) => {
  const { warningModal } = useModalHook();

  const phone015Number = useRecoilValue(say015Number);

  /* 녹음 태그 useRef */
  const mediaRecorder = useRef<any>(null);

  /* 녹음 아이콘 토글 STATE */
  const [audioIconToggle, setAudioIconToggle] = useState<boolean>(false);

  /* 재녹음 STATE */
  const [audioReRecordState, setAudioReRecordState] = useState<boolean>(false);

  /* 음성파일 Blob STATE */
  const [audioBlob, setAudioBlob] = useState<any>(null);

  /* Ars 초기 데이터 */
  const { initNextData } = useArsInitData({ id: data?.id });

  /* 녹음을 진행했는지에 대한 상태 */
  const [isChangeRecordS, setIsChangeRecordS] = useState<boolean>(false);

  /* 녹음 시간 */
  const [recordingTimeS, setRecordingTimeS] = useState<number>(0);
  /* 녹음 최대 시간 표시 - 초 */
  const maxRecordingTimeSec = 50;
  const maxFileSizeBytes = 1 * 1024 * 1024; // 1MB = 1,048,576 bytes
  /* 표출 시간 포맷 Hook */
  const formatTime = (recordingTime: number) => {
    const minutes = Math.floor(recordingTime / 60)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor(recordingTime % 60)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  };
  // ================
  // 녹음 시간 표출
  // ================
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (audioIconToggle) {
      timeout = setTimeout(() => {
        setRecordingTimeS((prevState) => prevState + 1);
      }, 1000);
    }
    if (recordingTimeS > maxRecordingTimeSec - 1) {
      stopRecording();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [audioIconToggle, recordingTimeS]);

  /* 음성파일 저장 Mutation */
  const { mutate: mutateUpdateRecord } = useMutation(update015ArsRecord, {
    onSuccess: (res) => {
      // tts 횟수 count
      const updatedData = { ...arsData };
      if (updatedJSON(updatedData, data?.id, res.filename) && updatedJsonFile) {
        updatedJsonFile();
      }
    },
  });

  /* 마지막으로 인사말 설정 JSON 데이터 형식에 맞게끔 데이터 업데이트  */
  const updatedJSON = (data: any, id: string, fileName: string) => {
    if (data.id === id && data.s015Data) {
      data.target.type = 'READ';
      data.s015Data.action = id === 'root' ? '인사말' : '안내멘트';
      data.s015Data.type = 'READ';
      data.s015Data.ttsText = '';
      data.s015Data.title = cardTitle;
      data.target.level = fileName;
      if (data?.id !== 'root') data.source.userinput = data?.id.slice(-1);
      const nodeNextCheck = data?.next.filter((el: any) => el.target.type !== 'BLANK');
      if (data.id !== 'root' && !(nodeNextCheck.length > 0)) {
        // 인사말이 아니고 우측에 데이터가 없다면
        data.next = initNextData;
      }
      return true;
    }
    if (data.next && data.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        if (updatedJSON(data.next[i], id, fileName)) {
          return true;
        }
      }
    }
    return false;
  };

  /* 녹음 시작 Hook */
  const startRecording = () => {
    setRecordingTimeS(0);

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder: any = new MediaRecorder(stream);
        const chunks: any = [];
        let currentFileSize = 0; // 파일 크기 추적

        // 데이터 청크를 자주 수집하기 위한 timeslice 설정
        const timeslice = 1000; // 1초마다 데이터를 수집

        recorder.ondataavailable = (e: BlobEvent) => {
          chunks.push(e.data);

          // 실시간으로 파일 크기를 추적
          const currentChunkSize = e.data.size;
          currentFileSize += currentChunkSize;
          // 파일 크기가 1MB 초과 시 녹음 중단
          if (currentFileSize > maxFileSizeBytes) {
            stopRecording();
            warningModal('녹음', '녹음 파일 크기가 초과하여 녹음을 중단합니다.', true);
          }
        };

        recorder.onstop = () => {
          const audioBlob: any = new Blob(chunks, { type: 'audio/wav' });
          setAudioIconToggle(false);
          setAudioBlob(audioBlob);
          setRecordAudioUrl(URL.createObjectURL(audioBlob));
          setIsChangeRecordS(true);
        };

        setAudioIconToggle(true);
        recorder.start(timeslice);
        mediaRecorder.current = recorder;
      })
      .catch(() => {
        warningModal('녹음', '녹음을 하기 위해서는 마이크 허용을 해야합니다.', true);
      });
  };

  /* 녹음 중단 Hook */
  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
    }
  };

  /* 녹음 저장 + Json 파일 수정 Hook */
  const uploadAudio = async () => {
    // 녹음 저장
    const unixTime = getUnixTime();
    const formData = new FormData();
    const fileName = `${phone015Number}_record_${unixTime}.wav`;
    const response = new Response(audioBlob);
    const arrayBuffer: any = await response.arrayBuffer();

    const audioContext = new window.AudioContext();
    audioContext
      .decodeAudioData(arrayBuffer)
      .then((audioBuffer) => {
        const wav = toWav(audioBuffer);
        const wavBlob = new Blob([wav], { type: 'audio/wav' });
        convertBlobToBase64(wavBlob)
          .then((r: any) => {
            return r.replace('data:audio/wav;base64,', '');
          })
          .then((base64Str: string) => {
            return convertBase64ToWavFile(base64Str, 'fileName');
          })
          .then((file: any) => {
            formData.append('file', file);
            formData.append('filename', fileName);
            mutateUpdateRecord({ formData });
          });
      })
      .catch((err) => console.error(err));
  };

  /* 제목 변경해도 저장 가능하도록 수정 */
  useEffect(() => {
    setIsChangeRecordS(false);
  }, [data?.s015Data.title, cardTitle]);

  /* 재녹음 핸들러 Hook */
  /*  const handleReRecord = () => {
    setAudioReRecordState((prevState) => !prevState);
    stopRecording();
    if (audioRef && audioRef.current) audioRef.current.pause();
  };*/

  return {
    formatTime,
    recordingTimeS,
    mediaRecorder,
    startRecording,
    stopRecording,
    uploadAudio,
    audioIconToggle,
    // handleReRecord,
    audioReRecordState,
    isChangeRecordS,
    audioBlob,
    maxRecordingTimeSec,
  };
};
