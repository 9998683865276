import { instance } from '@/shared/lib/clientAxios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

interface Content {
  prepayPayNo: string;
  subject: string;
  sndMsg: string;
  sndDttm: string;
  callback: string;
  msgGb: string;
  imgData: string | null;
  fileData: string | null;
}

interface Pageable {
  sort: any[]; // 구체적으로 정할 수 있으면 정하세요.
  offset: number;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

interface SearchMultiChatroomResponse {
  chatRoomName: string;
  totalCount: number;
  pages: {
    content: Content[];
    pageable: Pageable;
    totalElements: number;
    totalPages: number;
    size: number;
    numberOfElements: number;
    first: boolean;
    last: boolean;
    empty: boolean;
    number: number;
  };
}

interface fetchMultiMessageProps {
  chatRoomId: string;
  pageNumber: number;
  pageSize: number;
}

interface useSearchMultiChatroomDataProps {
  chatRoomId: string;
}

const fetchMultiMessage = async ({
  chatRoomId,
  pageSize = 20,
  pageNumber = 0,
}: fetchMultiMessageProps): Promise<SearchMultiChatroomResponse> => {
  const response = await instance.get('/smt/history/batch/room', {
    params: {
      chatRoomId,
      pageNumber,
      pageSize,
    },
  });
  return response.data;
};

export const useSearchMultiChatroomData = ({ chatRoomId }: useSearchMultiChatroomDataProps) => {
  // chatRoomId가 빈 문자열일 경우 쿼리 호출을 하지 않도록 enabled 옵션 사용
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    fetchStatus,
    status,
  } = useInfiniteQuery<SearchMultiChatroomResponse>(
    ['/smt/history/batch/room', chatRoomId], // queryKey에 chatRoomId 추가
    ({ pageParam = 0 }) =>
      fetchMultiMessage({
        chatRoomId,
        pageSize: 20,
        pageNumber: pageParam, // pageParam을 사용하여 페이지 번호 동적 처리
      }),
    {
      enabled: chatRoomId !== '', // chatRoomId가 빈 값이면 쿼리 실행되지 않도록 설정
      getNextPageParam: (lastPage) => {
        // 다음 페이지가 있으면 페이지 번호 반환
        return lastPage.pages.last ? undefined : lastPage.pages.pageable.pageNumber + 1;
      },
      onError: (error: any) => {
        console.error('API 호출 실패: ', error?.message);
      },
    },
  );

  const formatData = data?.pages.flatMap((page) => page.pages.content).reverse();

  return {
    data,
    formatData,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    fetchStatus,
    status,
  };
};
