import { amlQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { instance } from '@/shared/lib/clientAxios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

// 각 메시지 항목에 대한 타입
interface ChatRoomItem {
  phoneNumber: string;
  msgDttm: string;
  direction: string;
  sndMsg: string;
  msgType: string;
  chatRoomId: string;
  buddyName: string;
  rcvMsgCount: number;
  batch: boolean;
}

// 서버 응답 데이터 구조 타입
interface ChatRoomResponse {
  content: ChatRoomItem[]; // 페이지의 메시지 목록
  totalPages: number; // 전체 페이지 수
}

export const useGetChatroomList = (debouncedSearchValue: string) => {
  const [amlQueryParamsS, setAmlQueryParamsS] = useRecoilState(amlQueryParamsRecoil);

  const fetchChatroomList = async ({ pageSize = 20, pageParam = 0, keyword = debouncedSearchValue }) => {
    const response = await instance.get<ChatRoomResponse>('/smt/history/latest/app', {
      params: {
        pageNumber: pageParam,
        pageSize,
        keyword,
      },
    });
    return response.data;
  };

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    fetchStatus,
    status,
  } = useInfiniteQuery(
    ['/smt/history/latest/app', amlQueryParamsS],
    ({ pageParam = 0 }) =>
      fetchChatroomList({
        pageParam,
        keyword: amlQueryParamsS.keyword ?? '',
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        return pages.length < lastPage.totalPages ? pages.length : undefined;
      },
    },
  );

  // 타입을 적용한 formatData
  const formatData: ChatRoomItem[] | undefined = data && data.pages.flatMap((page) => page.content || []);

  return {
    data,
    formatData,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    fetchStatus,
    status,
  };
};
