import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { groupBuddyListParamsRecoil } from '@/recoil/atoms/mainTableRecoil';
import { BuddyData } from '@/types/index.types';
import { IGroupBuddyListResult } from '@/components/hooks/queries/queryTypes';
import { IEditData } from '@/components/hooks/customs/addressBook/useEditGroup';
import {
  MainTableCopyParamsType,
  MainTableSimpleAddParamsType,
} from '@/components/hooks/mutations/addressBook/useAddressBookMutations';
import { addressBuddyData } from '@/shared/constants';
import { DefaultRowData } from '@/apis/api/addressApis';

/**
 * @title 주소록 캐싱 작업 hook
 * */
export const mainTableCacheUpdate = () => {
  const queryClient = useQueryClient();
  /* 주소록 메인 테이블 현재 표출 데이터의 params */
  const groupBuddyListParamsS = useRecoilValue(groupBuddyListParamsRecoil);
  /* 주소록 메인 테이블의 전체 캐싱 데이터 */
  const allCacheData: InfiniteData<IGroupBuddyListResult>[] | unknown[] = queryClient.getQueriesData([
    '/groupBuddyList',
  ]);
  /* 현재 표출중인 주소록 메인 테이블 캐싱 데이터 */
  const cacheData: InfiniteData<IGroupBuddyListResult> | undefined = queryClient.getQueryData([
    '/groupBuddyList',
    groupBuddyListParamsS,
  ]);
  /* 그룹 리스트 캐싱 데이터 */
  const groupListCacheData: any = queryClient.getQueryData(['groupList2']);
  // ===============================
  // 그룹 시퀀스로 그룹명 알아내는 hook
  // ===============================
  const getGroupNmToGroupSeqNo = (groupSeqNoArr: number[]): string[] => {
    return groupListCacheData.groupList
      .flatMap((item: any) => {
        if (groupSeqNoArr.includes(item.groupSeqNo)) {
          return item.groupNm;
        }
        return null;
      })
      .filter((f: any) => f !== null);
  };

  // ================================================================
  // 모든 주소록 메인 테이블 캐싱 데이터를 확인해서 특정 GroupSeqNo 를 삭제하는 hook
  // ================================================================
  const deleteCacheDataToGroupSeqNo = (groupSeqNoArr: number[]) => {
    return allCacheData.map((cacheInfo: any) => {
      /* 캐싱 업데이트 대상 */
      const cacheDataKeyData = cacheInfo[0];
      /* 캐싱 업데이트 데이터 */
      const cacheRemoveData = {
        ...cacheInfo[1],
        pages: cacheInfo[1].pages.map((page: any) => {
          return {
            ...page,
            content: page.content.filter((f: any) => !groupSeqNoArr.includes(f.buddySeqNo)),
          };
        }),
      };
      return [[cacheDataKeyData[0], cacheDataKeyData[1]], cacheRemoveData];
    });
  };

  // ================================================================
  // 모든 주소록 메인 테이블 캐싱 데이터를 확인해서 특정 BuddySeqNo 를 삭제하는 hook
  // ================================================================
  const deleteCacheDataToBuddySeqNo = (buddySeqNoArr: number[]) => {
    return allCacheData.map((cacheInfo: any) => {
      /* 캐싱 업데이트 대상 */
      const cacheDataKeyData = cacheInfo[0];
      /* 캐싱 업데이트 데이터 */
      const cacheRemoveData = {
        ...cacheInfo[1],
        pages: cacheInfo[1].pages.map((page: any) => {
          return {
            ...page,
            content: page.content.filter((f: any) => !buddySeqNoArr.includes(f.buddySeqNo)),
          };
        }),
      };
      return [[cacheDataKeyData[0], cacheDataKeyData[1]], cacheRemoveData];
      // queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], cacheRemoveData);
    });
  };
  // ================================================================
  // 주소록 메인 테이블에 원하는 그룹시퀀스를 가진 캐싱 데이터 반환해주는 hook
  // ================================================================
  const getCacheDataToGroupSeqNo = (groupSeqNo: (number | null)[]) => {
    return allCacheData.filter((cacheInfo: any) => groupSeqNo.includes(cacheInfo[0][1].groupSeqNo));
  };

  // =================================================================================
  // 주소록 수정시 사용되는 캐싱 업데이트 hook - ['/contacts/updateDetail']
  // 주소록 수정은 그룹을 수정했을땐 삭제가 필요하고 그 외 수정은 삭제가 필요없음... 이에 맞게 로직을 읽어야함
  // =================================================================================
  const mainTableUpdateToCacheUpdate = (updatedData: BuddyData) => {
    /* 캐싱 업데이트가 되야하는 그룹 시퀀스 넘버 */
    const viewPlaceGroupSeqNoArr = [null, updatedData.groupSeqNo];
    /* 캐싱 업데이트가 되야하는 캐싱 데이터 */
    const updatedTargetCacheDataArr: any = getCacheDataToGroupSeqNo(viewPlaceGroupSeqNoArr);
    /* 그룹 수정이 아닌지 확인 */
    let isNotGroupUpdate = false;
    /* 그룹 수정이 아닌지 파악하는 로직  */
    updatedTargetCacheDataArr.forEach((cacheData: any) => {
      cacheData[1].pages.forEach((page: any) => {
        page.content.forEach((data: BuddyData) => {
          if (isNotGroupUpdate) return;
          isNotGroupUpdate = data.buddySeqNo === updatedData.buddySeqNo && data.groupSeqNo === updatedData.groupSeqNo;
        });
      });
    });
    /* 그룹 수정일 경우 */
    if (!isNotGroupUpdate) {
      /* 업데이트한 데이터의 buddySeqNo 를 기준으로 모든 캐싱 데이터에서 삭제 */
      const deleteBuddySeqNoCacheData = deleteCacheDataToBuddySeqNo([updatedData.buddySeqNo]);
      deleteBuddySeqNoCacheData.forEach((cacheInfo: any) => {
        /* 캐싱 업데이트 대상 */
        const cacheDataKeyData = cacheInfo[0];
        /* 업데이트가 되야하는 groupSeqNo 와 동일하지 않은 캐싱 데이터는 별도의 로직없이 setQuery */
        if (!viewPlaceGroupSeqNoArr.includes(cacheDataKeyData[1].groupSeqNo)) {
          queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], cacheInfo[1]);
        } else {
          let cacheAddData;
          /* 전체 그룹 캐시 데이터 업데이트시 그룹 수정이면 제자리에 고정 */
          if (cacheDataKeyData[1].groupSeqNo === null) {
            const nullGroup = updatedTargetCacheDataArr[0];
            cacheAddData = {
              ...nullGroup[1],
              pages: nullGroup[1].pages.map((page: any) => {
                return {
                  ...page,
                  content: page.content.map((data: BuddyData) => {
                    if (data.buddySeqNo === updatedData.buddySeqNo) {
                      return updatedData;
                    }
                    return data;
                  }),
                };
              }),
            };
          } else {
            /* 업데이트가 되야하는 groupSeqNo 일 경우 추가해줌 */
            cacheAddData = {
              ...cacheInfo[1],
              pages: cacheInfo[1].pages.map((page: any) => {
                return {
                  ...page,
                  content: [updatedData, ...page.content],
                };
              }),
            };
          }
          queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], cacheAddData);
        }
      });
    }
    /* 그룹 수정이 아닐 경우 */
    if (isNotGroupUpdate) {
      updatedTargetCacheDataArr.forEach((cacheInfo: any) => {
        /* 캐싱 업데이트 대상 */
        const cacheDataKeyData = cacheInfo[0];
        /* 캐싱 업데이트 데이터 */
        const cacheAddData = {
          ...cacheInfo[1],
          pages: cacheInfo[1].pages.map((page: any) => {
            return {
              ...page,
              /* 그룹 수정이 아니면 기존 캐싱 데이터를 수정 */
              content: page.content.map((data: BuddyData) => {
                if (data.buddySeqNo === updatedData.buddySeqNo) {
                  return updatedData;
                }
                return data;
              }),
            };
          }),
        };
        queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], cacheAddData);
      });
    }
  };

  // ========================================================
  // 주소록 삭제시 사용하는 캐싱 업데이트 hook - ['/contacts/delete']
  // ========================================================
  const mainTableDeleteToCacheUpdate = (deletedBuddySeqNos: number[]) => {
    /* 버디시퀀스 배열 데이터를 캐시 데이터에서 제거 */
    const deleteBuddySeqNoCacheData = deleteCacheDataToBuddySeqNo(deletedBuddySeqNos);
    deleteBuddySeqNoCacheData.forEach((cacheInfo: any) => {
      /* 캐싱 업데이트 대상 */
      const cacheDataKeyData = cacheInfo[0];
      queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], cacheInfo[1]);
    });
  };
  // ==============================================================
  // 주소록 복사시 사용하는 캐싱 업데이트 hook - ['/contacts/copyContacts']
  // ==============================================================
  // TODO 반환값에 buddySeqNo 랑 groupNm 등 뭔가 부족한게 많음 - 2024.06.08
  const mainTableCopyToCacheUpdate = (data: BuddyData[], variables: MainTableCopyParamsType) => {
    const updatedBuddyData: BuddyData[] | any = data.map((buddyData) => {
      return {
        ...addressBuddyData,
        ...buddyData,
        groupNm: getGroupNmToGroupSeqNo([buddyData.groupSeqNo])[0],
      };
    });
    /* 캐싱 업데이트가 되야하는 그룹 시퀀스 넘버 */
    const viewPlaceGroupSeqNoArr = [null, variables.groupSeqNo];
    /* 캐싱 업데이트가 되야하는 캐싱데이터 배열 */
    const updatedTargetCacheDataArr = getCacheDataToGroupSeqNo(viewPlaceGroupSeqNoArr);
    updatedTargetCacheDataArr.forEach((cacheInfo: any) => {
      /* 캐싱 업데이트 대상 */
      const cacheDataKeyData = cacheInfo[0];
      const updatedCacheData = {
        ...cacheInfo[1],
        pages: cacheInfo[1].pages.map((page: any, index: number) => {
          if (index === 0) {
            return {
              ...page,
              content: updatedBuddyData.concat(...page.content),
            };
          }
          return page;
        }),
      };
      queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], updatedCacheData);
    });
  };

  // =============================================================
  // 주소록 간편 추가시 사용하는 캐싱 업데이트 hook - ['/contacts/create']
  // =============================================================
  const mainTableSimpleAddToCacheUpdate = (data: any, variables: MainTableSimpleAddParamsType) => {
    /* 캐싱 업데이트가 되야하는 그룹 시퀀스 넘버 */
    const viewPlaceGroupSeqNoArr = [null, variables.groupSeqNo];
    /* 캐싱 업데이트가 되야하는 캐싱데이터 배열 */
    const updatedTargetCacheDataArr = getCacheDataToGroupSeqNo(viewPlaceGroupSeqNoArr);

    const initBuddyData: BuddyData = {
      ...addressBuddyData,
      buddySeqNo: data.buddySeqNo,
      buddyNm: variables.buddyNm,
      emailId: variables.emailId,
      etcInfo: variables.etcInfo,
      groupSeqNo: variables.groupSeqNo,
      groupNm: getGroupNmToGroupSeqNo([variables.groupSeqNo])[0],
      keyCommNo: variables.keyCommNo,
    };
    updatedTargetCacheDataArr.forEach((cacheInfo: any) => {
      /* 캐싱 업데이트 대상 */
      const cacheDataKeyData = cacheInfo[0];
      /* 캐싱 업데이트 데이터 */
      const cacheUpdatedData = {
        ...cacheInfo[1],
        pages: cacheInfo[1].pages.map((page: any, index: number) => {
          if (index === 0) {
            return {
              ...page,
              content: [initBuddyData, ...page.content],
            };
          }
          return page;
        }),
      };
      /* 캐싱 데이터 업데이트 */
      queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], cacheUpdatedData);
    });
  };
  // ========================================================
  // 좌측 그룹명이 변경시 사용하는 캐싱 업데이트 hook - [/group/update]
  // ========================================================
  const mainTableGroupNmChangeToCacheUpdate = (updatedGroupData: IEditData) => {
    /* 캐싱 업데이트가 되야하는 그룹 시퀀스 넘버 */
    const viewPlaceGroupSeqNoArr = [null, updatedGroupData.groupSeqNo];
    /* 캐싱 업데이트가 되야하는 캐싱데이터 배열 */
    const updatedTargetCacheDataArr = getCacheDataToGroupSeqNo(viewPlaceGroupSeqNoArr);
    updatedTargetCacheDataArr.forEach((cacheInfo: any) => {
      /* 캐싱 업데이트 대상 */
      const cacheDataKeyData = cacheInfo[0];
      /* 캐싱 업데이트 데이터 */
      const cacheUpdatedData = {
        ...cacheInfo[1],
        pages: cacheInfo[1].pages.map((page: any) => {
          return {
            ...page,
            content: page.content.map((item: any) => {
              if (item.groupSeqNo === updatedGroupData.groupSeqNo) {
                return {
                  ...item,
                  groupNm: updatedGroupData.groupNm,
                };
              }
              return item;
            }),
          };
        }),
      };
      /* 캐싱 데이터 업데이트 */
      queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], cacheUpdatedData);
    });
  };

  // ==================================
  // 좌측 그룹 삭세시 사용하는 캐싱 업데이트 hook - ['/group/delete']
  // ==================================
  const mainTableGroupDeleteToCacheUpdate = (deletedGroupData: number[]) => {
    /* 캐싱 업데이트가 되야하는 그룹 시퀀스 넘버 */
    const viewPlaceGroupSeqNoArr = [null];
    /* 캐싱 업데이트가 되야하는 캐싱데이터 배열 */
    const updatedTargetCacheDataArr = getCacheDataToGroupSeqNo(viewPlaceGroupSeqNoArr);
    updatedTargetCacheDataArr.forEach((cacheInfo: any) => {
      /* 캐싱 업데이트 대상 */
      const cacheDataKeyData = cacheInfo[0];
      /* 캐싱 업데이트 데이터 */
      const cacheUpdatedData = {
        ...cacheInfo[1],
        pages: cacheInfo[1].pages.map((page: any) => {
          return {
            ...page,
            content: page.content.filter((item: any) => !deletedGroupData.includes(item.groupSeqNo)),
          };
        }),
      };
      /* 캐싱 데이터 업데이트 */
      queryClient.setQueryData([cacheDataKeyData[0], cacheDataKeyData[1]], cacheUpdatedData);
    });
  };

  // ==============================
  // 대량추가 저장 메인테이블 캐시 업데이트 hook
  // ==============================
  const mainTableLargeAddToCacheUpdate = (data: BuddyData[], variables: DefaultRowData[]) => {
    /* 캐싱 업데이트가 되야하는 그룹 시퀀스 넘버 */
    const viewPlaceGroupSeqNoArr = [null, variables[0].groupSeqNo];
    /* 캐싱 업데이트가 되야하는 캐싱데이터 배열 */
    const updatedTargetCacheDataArr = getCacheDataToGroupSeqNo(viewPlaceGroupSeqNoArr);
    /* 그룹 시퀀스넘버로 그룹이름 GET */
    const getGroupNm = getGroupNmToGroupSeqNo([variables[0].groupSeqNo] as number[]);

    const initBuddyData: BuddyData[] = data.map((buddyList) => {
      return {
        // ...addressBuddyData,
        ...buddyList,
        groupNm: getGroupNm[0],
      };
    });
    updatedTargetCacheDataArr.forEach((cacheInfo: any) => {
      const cacheDataKey = cacheInfo[0];
      const cacheUpdatedData = {
        ...cacheInfo[1],
        pages: cacheInfo[1].pages.map((page: any, index: number) => {
          if (index === 0) {
            return {
              ...page,
              content: [...initBuddyData, ...page.content],
            };
          }
          return page;
        }),
      };
      queryClient.setQueryData([cacheDataKey[0], cacheDataKey[1]], cacheUpdatedData);
    });
  };

  // ==============================
  // 휴지통 전체, 선택 복원 메인테이블 캐시 업데이트 hook
  // ==============================
  const mainTableRestoreCacheUpdate = (data: BuddyData[]) => {
    /* 업데이트 되어야하는 그룹 시퀀스 넘버 배열 */
    const existingGroupSeqNos = new Set(data.map((item: any) => item.groupSeqNo));
    /* 캐싱 업데이트가 되야하는 그룹 시퀀스 넘버 */
    const viewPlaceGroupSeqNoArr = [null, ...existingGroupSeqNos];
    /* 캐싱 업데이트가 되야하는 캐싱데이터 배열 */
    const updatedTargetCacheDataArr = getCacheDataToGroupSeqNo(viewPlaceGroupSeqNoArr);

    const initBuddyData: BuddyData[] = data.map((buddyList) => {
      return {
        ...buddyList,
      };
    });
    updatedTargetCacheDataArr.forEach((cacheInfo: any) => {
      const cacheDataKey = cacheInfo[0];
      const filterBuddyList = initBuddyData.filter((item: BuddyData) => item.groupSeqNo === cacheDataKey[1].groupSeqNo);
      let cacheUpdatedData;
      if (cacheDataKey[1].groupSeqNo === null) {
        const nullGroup: any = updatedTargetCacheDataArr[0];
        cacheUpdatedData = {
          ...nullGroup[1],
          pages: nullGroup[1].pages.map((page: any, index: number) => {
            if (index === 0) {
              return {
                ...page,
                content: [...initBuddyData, ...page.content],
              };
            }
            return page;
          }),
        };
      } else {
        cacheUpdatedData = {
          ...cacheInfo[1],
          pages: cacheInfo[1].pages.map((page: any, index: number) => {
            if (index === 0) {
              return {
                ...page,
                content: [...filterBuddyList, ...page.content],
              };
            }
            return page;
          }),
        };
      }
      queryClient.setQueryData([cacheDataKey[0], cacheDataKey[1]], cacheUpdatedData);
    });
  };

  return {
    getCacheDataToGroupSeqNo,
    mainTableUpdateToCacheUpdate,
    mainTableDeleteToCacheUpdate,
    mainTableGroupNmChangeToCacheUpdate,
    mainTableCopyToCacheUpdate,
    mainTableSimpleAddToCacheUpdate,
    mainTableGroupDeleteToCacheUpdate,
    mainTableLargeAddToCacheUpdate,
    mainTableRestoreCacheUpdate,
  };
};
