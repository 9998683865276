import { fomatOnlyNum } from '@/shared/util/format/phoneNumberFormatUtil';
import { useAddressBookMutations } from '@/components/hooks/mutations/addressBook/useAddressBookMutations';
import { validatePhoneNumber } from '@/shared/util/AddressUtils';
import { tableAddSchema } from '@/pages/main/components/addTable/tableDataAdd.validation';
import { clickGroupNameRecoil, openLargeToggleRecoil, tableAddOpenRecoil } from '@/recoil/atoms/addressList';
import { chatSaveNumberRecoil } from '@/recoil/atoms/chatRoom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

interface IUseAddTable {
  propSetTableAddOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @title 주소록 추가 Select 컴포넌트 관련 Hooks
 *
 * @author 김남규
 * @since 2024.03.20
 **/
export const useAddTable = ({ propSetTableAddOpen }: IUseAddTable) => {
  const queryClient = useQueryClient();
  /* 주소록 추가 -> 간편 추가시 나올 컴포넌트 toggle STATE */
  const [tableAddOpen, setTableAddOpen] = useRecoilState(tableAddOpenRecoil);
  /* 주소록 추가 Select toggle STATE */
  const [selectSwitch, setSelectSwitch] = useState(false);
  /* 간편 추가 컴포넌트에서 그룹 리스트 담는 option STATE */
  const [selectState, setSelectState] = useState({ groupNm: '', groupSeqNo: 0 });
  /* table toggle STATE ? 대량추가 : 메인테이블 */
  const [openLargeToggle, setOpenLargeToggle] = useRecoilState<boolean>(openLargeToggleRecoil);
  /* 주소록 간편추가 그룹 리스트 Select option STATE */
  const [selectArrValue, setSelectArrValue] = useState([{ id: 0, groupSeqNo: '', koname: '' }]);
  const [clickGroupName, setClickGroupName] = useRecoilState(clickGroupNameRecoil);
  /* 활성화 채팅방 상대방 번호 담아놓는 STATE */
  const [chatSaveNumber, setChatSaveNumber] = useRecoilState(chatSaveNumberRecoil);

  /* 컴포넌트 최상단 useRef */
  const layoutRef = useRef<any>(null);

  /* 메인 주소록 테이블 mutation 모음 hook */
  const { simpleAddM } = useAddressBookMutations();

  // ==============================
  // 간편추가 컴포넌트 toggle onClick Hook
  // ==============================
  const tableAddOpenHandle: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setTableAddOpen((prev) => !prev);
  }, [tableAddOpen]);

  const groupList: any = queryClient.getQueryData(['groupList2']);

  // ==============================
  // 간편추가 저장 성공 Hooks ( mutate hook에 들어가면 추가 OnSuccess 존재 )
  // ==============================
  const handleAddOnSuccess = () => {
    setChatSaveNumber(null);
    // 개별 추가 모달 열려있다면 닫기
    if (propSetTableAddOpen) propSetTableAddOpen(false);
  };

  // ==============================
  // 간편추가 useForm
  // ==============================
  const { register, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(tableAddSchema),
    mode: 'onSubmit',
    defaultValues: {
      emailId: '',
      buddyNm: '',
      etcInfo: '',
      keyCommNo: '',
    },
  });

  // ==============================
  // 간편추가 그룹 리스트 Select 디폴트값 정하는 useEffect
  // 015 채팅룸을 통해 toggle시 번호 자동입력
  // ==============================
  useEffect(() => {
    if (!groupList) return;
    const selectArr = groupList?.groupList?.map((el: any, index: number) => {
      return { id: index, groupSeqNo: el.groupSeqNo, koname: el.groupNm };
    });
    setSelectArrValue(selectArr);
    if (clickGroupName) {
      setSelectState({ groupNm: clickGroupName?.groupNm, groupSeqNo: clickGroupName?.groupSeqNo });
    } else {
      setSelectState({ groupNm: selectArr[0]?.koname, groupSeqNo: selectArr[0]?.groupSeqNo });
    }
    if (chatSaveNumber) {
      setValue('keyCommNo', chatSaveNumber);
    } else {
      setValue('keyCommNo', '');
    }
  }, [groupList, clickGroupName, chatSaveNumber]);

  // ==============================
  // 간편추가 그룹 리스트 Select option onClick Hook
  // ==============================
  const onClickSelectListButton = (item: any) => {
    // select option 클릭 이벤트
    setSelectState({ groupNm: item?.koname, groupSeqNo: item?.groupSeqNo });
  };

  // ==============================
  // 간편추가 저장시 mutate로 보낼 값과 validation Hook
  // ==============================
  const onAddTableHandler = (data: any) => {
    const params = { ...data };
    params.groupSeqNo = selectState.groupSeqNo;
    const valiPhoneNumber = validatePhoneNumber(params.keyCommNo);
    if (!valiPhoneNumber) {
      // 주소록 추가시 휴대폰번호 벨리데이션 실패했을 경우 로직 추가가능
      return;
    }
    params.keyCommNo = fomatOnlyNum(params.keyCommNo);
    simpleAddM(params, {
      onSuccess: () => {
        handleAddOnSuccess();
      },
    });
  };

  // ===========================================
  // layout 을 제외한 다른 곳 클릭시 active 해제
  // ===========================================
  const handleClickOutside = (event: any) => {
    const layoutCurrent = layoutRef.current;
    if (layoutCurrent && !layoutCurrent.contains(event.target as Node)) {
      setSelectSwitch(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return {
    onAddTableHandler,
    handleSubmit,
    register,
    selectState,
    selectArrValue,
    selectSwitch,
    setSelectSwitch,
    onClickSelectListButton,
    tableAddOpenHandle,
    tableAddOpen,
    setTableAddOpen,
    openLargeToggle,
    setOpenLargeToggle,
    setValue,
    layoutRef,
  };
};
