import React from 'react';
import styled from 'styled-components';

interface IBaseGuide {
  text: string;
}

const ManagementGuide = styled.div`
  margin: 3rem 0;
  color: ${(props) => props.theme.colors.txt200};
  line-height: 1.6em;
  font-size: 1.3rem;
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const BaseGuide = (props: IBaseGuide) => {
  return <ManagementGuide dangerouslySetInnerHTML={{ __html: props.text }} />;
};

export default BaseGuide;
