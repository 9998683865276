/* eslint-disable */
import { get015ArsTTS, update015ArsRecord } from '@/apis/api/say015Apis';
import { useArsInitData } from '@/components/hooks/customs/say015/say105Handle/useArsInitData';
import { useTts } from '@/components/hooks/customs/useTts';
import { say015Number } from '@/recoil/atoms/say015Atom';
import { assertMsglen } from '@/shared/util/byteCalculatorUtil';
import { getUnixTime } from '@/shared/util/time/timeUtils';
import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';
import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

interface IUseArsGuideTTS {
  data: NextItem;
  arsData: NextItem;
  updatedJsonFile?(): void;
  type?: 'update' | 'view' | 'add' | 'mock'; // 각각의 카드의 현재 타입
  cardTitle?: string;
}

/**
 * @title 015 안내멘트 - 텍스트 tts Hooks
 * */
export const useArsGuideTts = ({ data, arsData, updatedJsonFile, type, cardTitle }: IUseArsGuideTTS) => {
  /* 015 번호 */
  // const phone015Number = queryClient.getQueryData(['authorityData'])?.number015;

  const phone015Number = useRecoilValue(say015Number);
  /* 변경된 TTS text STATE */
  const [ttsTextState, setTtsTextState] = useState<string>(data?.s015Data?.ttsText);
  /* 기존 TTS text STATE */
  const [initialTtsTextState, setInitialTtsTextState] = useState<string>('');
  /* TTS text 가 변경됐는지 체크 */
  const [isChangeTTS, setIsChangeTTS] = useState<boolean>(false);
  /* TTS 변경 및 수정시 금액 지불에 관련된 HOOKS */
  const { onClickTtsBtn, ttsCountM } = useTts();
  /* MAX 텍스트 Byte */
  const maxByte = 800;

  /* Ars 초기 데이터 */
  const { initNextData } = useArsInitData({ id: data?.id });
  /* 입력된 텍스트를 구글 TTS api 요청해 response 로 TTs 받음 GET API */
  const { mutate: mutateArsTTS } = useMutation(() => get015ArsTTS({ text: ttsTextState }), {
    onSuccess: async (res) => {
      if (ttsTextState === '') return;
      /* 구글 TTS 파일을 audioBlob 형태로 변환 */
      const audioBlob = await handleGetTTS({ ttsData: res });
      /* 변환된 TTS 파일을 서버에 저장 */
      handleSaveTTS({ audioBlob });
    },
    onError: () => {},
  });

  /* 음성파일 저장 Mutation -2 */
  const { mutate: mutateUpdateRecord } = useMutation(update015ArsRecord, {
    onSuccess: (res) => {
      // tts 횟수 count
      ttsCountM();
      const updatedData = { ...arsData };
      if (updatedJSON(updatedData, data?.id, ttsTextState, res.filename) && updatedJsonFile) {
        updatedJsonFile();
      }
    },
  });
  /* 마지막으로 인사말 설정 JSON 데이터 형식에 맞게끔 데이터 업데이트  */
  const updatedJSON = (data: any, id: string, ttsText: string, fileName: string) => {
    if (data.id === id && data.s015Data) {
      data.target.type = 'READ';
      data.s015Data.action = id === 'root' ? '인사말' : '안내멘트';
      data.s015Data.type = 'READ';
      data.s015Data.ttsText = ttsText;
      data.s015Data.title = cardTitle;
      data.target.level = fileName;
      if (data?.id !== 'root') data.source.userinput = data?.id.slice(-1);
      const nodeNextCheck = data?.next.filter((el: any) => el.target.type !== 'BLANK');
      if (data.id !== 'root' && !(nodeNextCheck.length > 0)) {
        // 인사말이 아니고 우측에 데이터가 없다면
        data.next = initNextData;
      }
      return true;
    }
    if (data.next && data.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        if (updatedJSON(data.next[i], id, ttsText, fileName)) {
          return true;
        }
      }
    }
    return false;
  };
  // ==============================
  // 안내멘트 초기값 useEffect hook
  // ==============================
  useEffect(() => {
    setTtsTextState(data?.s015Data?.ttsText);
    setInitialTtsTextState(data?.s015Data?.ttsText);
  }, [data]);

  // ==============================
  // 텍스트 변경 시 ttsSaveDisabled 업데이트
  // ==============================
  useEffect(() => {
    setIsChangeTTS(ttsTextState !== initialTtsTextState || data?.s015Data.title !== cardTitle);
  }, [ttsTextState, initialTtsTextState, cardTitle, data?.s015Data.title]);

  /* 안내멘트 TTS GET Hook */
  const handleGetTTS = ({ ttsData }: any) => {
    const decodedData = atob(ttsData);
    const dataArray = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      dataArray[i] = decodedData.charCodeAt(i);
    }
    const audioBlob: any = new Blob([dataArray], { type: 'audio/wav' });
    return audioBlob;
  };
  /* 안내멘트 TTS 저장 핸들러 Hook */
  const handleSaveTTS = ({ audioBlob }: any) => {
    const unixTime = getUnixTime();
    const formData = new FormData();
    const fileName = `${phone015Number}_tts_${unixTime}.wav`;
    formData.append('file', audioBlob);
    formData.append('filename', fileName);
    mutateUpdateRecord({ formData });
  };
  /* 안내멘트 TTS 저장 Hook */
  const handleSave = () => {
    if (ttsTextState === '') return;
    // tts 시간, 포인트따라 분기처리하는 hooks
    onClickTtsBtn(mutateArsTTS);
  };

  /* 안내멘트 TTS STATE 핸들러 Hook */
  const handleTtsText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    // 메시지의 길이가 1000 바이트를 넘지 않도록 제한
    const truncatedMsg = assertMsglen(text, maxByte);

    setTtsTextState(truncatedMsg);
  };

  // useEffect(() => {
  //   setTtsTextState(data?.s015Data?.ttsText);
  // }, [type]);

  return {
    ttsTextState,
    handleTtsText,
    handleSave,
    isChangeTTS,
    mutateArsTTS,
    onClickTtsBtn,
    maxByte,
  };
};
