import { postAdressBinRestore } from '@/apis/api/addressApis';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import { mainTableCacheUpdate } from '@/components/cache/mainTableCacheUpdate';
import { recyclebinCacheUpdate } from '@/components/cache/recyclebinCacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { checkedBinListState } from '@/recoil/atoms/addressList';
import { fullScreenSpinLoadingRecoil } from '@/recoil/atoms/atoms';
import { BuddyData } from '@/types/index.types';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

export const useRestoreBin = () => {
  const [restoreBinList, setRestoreBinList] = useState<number[]>([]); // 복원할 버디시퀀스넘버 담으면 됩니다.
  const [binRestoreState, setBinRestoreState] = useState(false); // 버튼 중복 클릭 방지
  const [, setCheckedBinList] = useRecoilState(checkedBinListState); // 휴지통 체크박스

  // api 호출중 로딩 상태 표시할 recoil
  const setFullScreenSpinLoadingR = useSetRecoilState(fullScreenSpinLoadingRecoil);

  const { confirmModal, successModal, warningModal } = useModalHook();

  const cacheGHooks = groupListCacheUpdate();
  const cacheMHooks = mainTableCacheUpdate();
  const cacheRHooks = recyclebinCacheUpdate();

  // 휴지통 선택 복원 mutate
  const { mutate: binListRestoreM, isLoading: binListRestoreIsLoading } = useMutation(postAdressBinRestore, {
    onSuccess: (data: BuddyData[]) => {
      setBinRestoreState(false);
      setRestoreBinList([]);
      setCheckedBinList([]);
      // 휴지통 리스트 cache update
      cacheRHooks.recyclebinRestoreCacheUpdate(data);
      // 그룹 리스트 cache update
      cacheGHooks.handleRestoreCacheUpdate(data);
      // main table cache update
      cacheMHooks.mainTableRestoreCacheUpdate(data);

      successModal('연락처 복원', '연락처를 이전 그룹으로 복원하였습니다.', true);
    },
    onError: (error: any) => {
      setBinRestoreState(false);
      warningModal('연락처 복원', error.response.message, true);
    },
  });

  const binListRestoreHandle = () => {
    setBinRestoreState(true);
    if (!(restoreBinList.length > 0)) {
      setBinRestoreState(false);
      warningModal('연락처 복원', '선택된 연락처가 없습니다.</br>복원할 연락처를 선택해주세요.', true);
      return;
    }
    confirmModal(
      async () => binListRestoreM(restoreBinList),
      '연락처 복원',
      '해당 연락처를 이전 그룹으로 복원 하시겠습니까?',
      true,
      undefined,
      async () => setBinRestoreState(false),
    );
  };

  // ==============================
  // 휴지통 선택 복원 mutate loading시 로더 useEffect
  // ==============================
  useEffect(() => {
    setFullScreenSpinLoadingR(binListRestoreIsLoading);
  }, [binListRestoreIsLoading]);

  return { binListRestoreHandle, setRestoreBinList, binRestoreState };
};
