import React, { useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { globalModalStateRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { IoCloseSharp } from 'react-icons/io5';
import useGlobalModalHook from './useGlobalModalHook';

const Overlay = styled.section`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* border-radius: 1rem; */
  justify-content: center;
  align-items: center;
  /* box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3); */
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -4rem;
  right: -4rem;
  padding: 3px;
  background-color: ${(props) => props.theme.colors.gray700};
  transition: 0.3s ease;
  color: ${(props) => props.theme.colors.white};
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray800};
  }
`;

const ListDeleteBtn = styled(IoCloseSharp)`
  font-size: 3rem;
  border-radius: 100%;
  transition: 0.3s ease;
`;

const GlobalModal = () => {
  const { isOpen, content, closeOnOverlayClick, closeModal } = useGlobalModalHook();
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (overlayRef.current === event.target && closeOnOverlayClick) {
      closeModal();
    }
  };

  const handleCloseClick = () => {
    closeModal();
  };

  if (!isOpen) return null;

  return (
    <Overlay ref={overlayRef} onClick={handleOverlayClick}>
      <Container>
        <CloseButton onClick={handleCloseClick}>
          <ListDeleteBtn />
        </CloseButton>
        {content}
      </Container>
    </Overlay>
  );
};

export default GlobalModal;

// const GlobalModal = () => {
//   const setGlobalModalState = useSetRecoilState(globalModalStateRecoil);
//   const { isOpen, content, closeOnOverlayClick } = useRecoilValue(globalModalStateRecoil);
//   const overlayRef = useRef<HTMLDivElement>(null);

//   const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
//     if (overlayRef.current === event.target && closeOnOverlayClick) {
// setGlobalModalState((prevState) => ({ ...prevState, isOpen: false, content: null }));
//     }
//   };

//   const handleCloseClick = () => {
// setGlobalModalState((prevState) => ({ ...prevState, isOpen: false, content: null }));
//   };

//   if (!isOpen) return null;

//   return (
//     <Overlay ref={overlayRef} onClick={handleOverlayClick}>
//       <Container>
//         <CloseButton onClick={handleCloseClick}>
//           <ListDeleteBtn />
//         </CloseButton>
//         {content}
//       </Container>
//     </Overlay>
//   );
// };
