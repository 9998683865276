import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as S from '../SaveMessageStyled';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { useInfiniteQuery } from '@tanstack/react-query';
import { calScrollEvent } from '@/apis/hooks/useScrollInfinity';
import { instance } from '@/shared/lib/clientAxios';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { LoadingDiv } from '@/components/Molecules/Charge/charge/chargeOnlienPopup/ChargeOnlienPopup.styles';
import Loader from '@/components/common/Loader';
import { useMessageSend } from '@/components/hooks/customs/addressBook/useMessageSend';
import { useMessageSender } from '@/components/hooks/customs/addressBook/useMessageSender';
import { useGetSaveMessageList } from '../hooks/UseGetSaveMessageList';
import useGlobalModalHook from '@/pages/main/components/modal/globalModal/useGlobalModalHook';
import { editItem, ListData } from '../SaveMessageType';
import { useDeleteSaveMessageList } from '../hooks/UseDeleteSaveMessageList';

interface SaveListComponentsProps {
  messageTabState: string;
  setMessageTabState: React.Dispatch<React.SetStateAction<string>>;
  searchKeyword: string;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
  editState: boolean;
  setEditState: React.Dispatch<React.SetStateAction<boolean>>;
  editItem: editItem;
  setEditItem: React.Dispatch<React.SetStateAction<editItem>>;
}

const SaveListComponents: React.FC<SaveListComponentsProps> = ({
  messageTabState,
  setMessageTabState,
  searchKeyword,
  setSearchKeyword,
  setEditState,
  setEditItem,
  editState,
  editItem,
}) => {
  const [searchState, setSearchState] = useState<boolean>(false); // 검색창 온오프
  const [newMessages, setNewMessages] = useState<ListData[] | undefined>([]);

  // 컨펌 모달
  const { confirmModal, successModal } = useModalHook();

  // 리스트 데이터 가져오는 훅
  const {
    data: messagesData,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  } = useGetSaveMessageList(messageTabState, searchKeyword);
  // useMutation 훅 사용
  const { mutate } = useDeleteSaveMessageList(messageTabState, searchKeyword);

  // 탭 클릭해서 상태 변경
  const handleTabClick = (item: string) => {
    setMessageTabState(item);
    setSearchState(false);
    setSearchKeyword('');
  };

  useEffect(() => {
    refetch();
  }, [messageTabState]);

  //검색창 닫기
  const handleSearchClose = () => {
    setSearchState(false);
    setSearchKeyword('');
    setTimeout(() => {
      refetch();
    }, 0);
  };

  // 검색어 입력 핸들러
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  // 검색 버튼 클릭 핸들러
  const handleSearchSubmit = async () => {
    refetch();
  };

  //검색 엔터로도 할 수 있게
  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchSubmit(); // Enter 키 누르면 검색 함수 호출
    }
  };

  // 메세지 보내기
  const { selectNumber, fomatCallingNum } = useMessageSender();
  const hooks = useMessageSend({ selectNumber, fomatCallingNum });

  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (calScrollEvent({ scrollTop, scrollHeight, clientHeight }) && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  // mms 일 경우 이미지 상태를 업데이트
  // back에서 넘어오는 base64 이미지
  useEffect(() => {
    const formatMessagesData = messagesData?.pages.flatMap((page) => page.content);
    const formatMessages = async () => {
      if (formatMessagesData) {
        if (messageTabState === 'MMS') {
          // Process each message to update the file URLs
          const updatedMessages = await Promise.all(
            formatMessagesData.map(async (message) => {
              // Check if message.file exists before creating the image URL
              const updatedMessage = message.file
                ? {
                    ...message,
                    file: `data:image/png;base64,${message.file}`,
                  }
                : message; // Return the message object as-is if file does not exist
              return updatedMessage;
            }),
          );
          setNewMessages(updatedMessages);
        } else {
          setNewMessages(formatMessagesData);
        }
      }
    };
    formatMessages();
  }, [messagesData]);

  //메세지 삭제
  const handleMessageDelete = (item?: editItem) => {
    confirmModal(
      () => {
        item &&
          mutate(
            { messageTabState, item },
            {
              onSuccess: () => {
                successModal('성공', `${item.title} 메세지가 삭제되었습니다.`, true);
              },
              onError: (error) => {
                console.error('메세지를 삭제하는데 실패했습니다.:', error);
              },
            },
          );
      },
      '메세지 삭제',
      `${item?.title} 메세지를 삭제하시겠습니까?`,
      true,
    );
  };

  // 수정 버튼 클릭 시 호출되는 함수
  const handleEditBtnClick = (item?: editItem) => {
    confirmModal(
      () => {
        setEditState(true);
        if (item) {
          setEditItem(item);
        }
      },
      `메세지 수정`,
      `${item?.title} 메세지를 수정 하시겠습니까?`,
      true,
    );
  };

  return (
    <>
      <S.TabSaveMessageSection>
        <div className="tabWrap">
          <S.Inner>
            <S.TabContainer>
              <S.TabList>
                <S.TabItemSMS
                  messageTabState={messageTabState}
                  onClick={() => {
                    handleTabClick('SMS');
                  }}
                >
                  SMS(일반)
                </S.TabItemSMS>
                <S.TabItemMMS
                  messageTabState={messageTabState}
                  onClick={() => {
                    handleTabClick('MMS');
                  }}
                >
                  MMS(포토/1,000자)
                </S.TabItemMMS>
              </S.TabList>
              <button
                onClick={() => {
                  setSearchState(true);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path
                    d="M12.8278 12.7409L16.1611 16.0742M1.16113 7.74089C1.16113 9.509 1.86351 11.2047 3.11375 12.4549C4.364 13.7052 6.05969 14.4076 7.8278 14.4076C9.59591 14.4076 11.2916 13.7052 12.5418 12.4549C13.7921 11.2047 14.4945 9.509 14.4945 7.74089C14.4945 5.97278 13.7921 4.27708 12.5418 3.02684C11.2916 1.7766 9.59591 1.07422 7.8278 1.07422C6.05969 1.07422 4.364 1.7766 3.11375 3.02684C1.86351 4.27708 1.16113 5.97278 1.16113 7.74089Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </S.TabContainer>
          </S.Inner>

          {searchState && (
            <S.SearchSection>
              <S.SearchContainer>
                <S.SearchInput
                  value={searchKeyword}
                  onChange={(e) => handleSearchInputChange(e)}
                  type="text"
                  placeholder="검색하실 문자 제목을 입력해 주세요"
                  onKeyDown={handleEnterKeyPress}
                />

                <S.SearchBtnContainer onClick={() => handleSearchSubmit()} absolute>
                  <S.SearchBtn />
                </S.SearchBtnContainer>
              </S.SearchContainer>

              <button onClick={() => handleSearchClose()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M15 0.750244L1 14.7502M1 0.750244L15 14.7502"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </S.SearchSection>
          )}
        </div>

        {newMessages?.length !== 0 ? (
          <S.ListContainer onScroll={handleScroll}>
            {(isLoading || isFetchingNextPage) && (
              <LoadingDiv>
                <Loader backgroundToggle />
              </LoadingDiv>
            )}
            <S.MessageListContainer>
              {newMessages?.map((item, index) => (
                <S.MessageListItem
                  key={item.seqNo || item.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    hooks.HandleClickMessagList(item, messageTabState);
                  }}
                  isEditing={
                    messageTabState === 'SMS'
                      ? editState && editItem?.seqNo === item?.seqNo
                      : editState && editItem?.id === item.id
                  }
                >
                  <S.MessageListItemHeader
                    isEditing={
                      messageTabState === 'SMS'
                        ? editState && editItem?.seqNo === item?.seqNo
                        : editState && editItem?.id === item.id
                    }
                  >
                    <>
                      <div>{item.title}</div>
                      <S.EditBtnContainer
                        isEditing={
                          messageTabState === 'SMS'
                            ? editState && editItem?.seqNo === item?.seqNo
                            : editState && editItem?.id === item.id
                        }
                      >
                        {/* 수정버튼 */}
                        <S.EditBtn
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditBtnClick(item);
                          }}
                        />
                      </S.EditBtnContainer>
                    </>
                    <S.ListDeleteBtnConctainer>
                      <S.ListDeleteBtn
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMessageDelete(item);
                        }}
                      />
                    </S.ListDeleteBtnConctainer>
                  </S.MessageListItemHeader>

                  <S.MessageListItemBody>
                    {item?.file ? <S.ImagePreview src={`${item?.file}`} alt="리스트 이미지" /> : null}
                    <div>{item.body}</div>
                  </S.MessageListItemBody>
                </S.MessageListItem>
              ))}
            </S.MessageListContainer>
          </S.ListContainer>
        ) : (
          <S.NoData>No Data</S.NoData>
        )}
      </S.TabSaveMessageSection>
    </>
  );
};

export default SaveListComponents;
