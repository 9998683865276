import React from 'react';
import * as S from '../styles/My015Charge.styles';
import { IMenus015, useChargeMenuItems } from '@/components/hooks/customs/charge/chargeStation/useChargeMenuItems';
/**
 * @title 015 이용요금 결제 결제 종류 컴포넌트
 *
 * @author 정휘학
 * @since 2024.05.17
 * */

interface IMy015ChargeTop {
  // 결제 수단 변경 Hook
  handlerPayMethodTypes(payMethodTypeCode: string | undefined): void;
  // 결제 수단 코드
  payMethodTypeCodeState: IMenus015['payMethodTypeCode'];
}

const My015ChargeTop = (props: IMy015ChargeTop) => {
  const itemHooks = useChargeMenuItems();
  return (
    <S.Payment className="payment">
      {itemHooks.Menus015.map((item) => (
        <button
          key={item.payMethodTypeCode}
          className={props.payMethodTypeCodeState === item.payMethodTypeCode ? 'active' : ''}
          onClick={() => props.handlerPayMethodTypes(item.payMethodTypeCode)}
        >
          {item.title}
        </button>
      ))}
    </S.Payment>
  );
};

export default My015ChargeTop;
