import { useRecoilState, useRecoilValue } from 'recoil';

import React from 'react';
import {
  leftZoneComponentRecoil,
  listToggleRecoil,
  softPhoneTopTabRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import { addressOpenToggleRecoil, reSendMsgToggleRecoil } from '@/recoil/atoms/messageSend/messageSend';
import { say015AuthState } from '@/recoil/atoms/say015Atom';
// import SendResultDetail from '@/pages/main/components/etcLeftZone/sendResultDetail/SendResultDetail';
// import ScheduleDetail from '@/pages/main/components/etcLeftZone/schedule/ScheduleDetail';
import AddLargeTableIndex from '@/pages/AddLargeTable/AddLargeTable.index';
import ArsWidget from '@/widgets/ars/ui/ArsWidget.index';
import ArsWidgetIndex from '@/widgets/ars/ui/ArsWidget.index';

/**
 * @title EtcLeftZone.tsx Hooks
 *
 * @author 정휘학
 * @since 2024.03.19
 * */
export const useEtcLeftZone = () => {
  const [listToggleState] = useRecoilState(listToggleRecoil);
  const [reSendMsgToggleS] = useRecoilState(reSendMsgToggleRecoil);
  const [addressOpenToggleState] = useRecoilState(addressOpenToggleRecoil);
  const leftZoneComponentS = useRecoilValue(leftZoneComponentRecoil);
  const [softPhoneTopTabState] = useRecoilState(softPhoneTopTabRecoil);
  const [say015Auth] = useRecoilState(say015AuthState);

  /* 좌측에 표출되는 컴포넌트 Object */
  const components: any = {
    // send: <SendResultDetail />,
    // schedule: <ScheduleDetail />,
    ars: <ArsWidgetIndex />, // ARS 위젯
    addLargeTable: <AddLargeTableIndex />,
  };

  return {
    listToggleState,
    addressOpenToggleState,
    softPhoneTopTabState,
    say015Auth,
    leftZoneComponentS,
    components,
    reSendMsgToggleS,
  };
};
