import * as D from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/ChatRoom015Body.styled';
import ChatRoom015MO from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015MO/ChatRoom015MO';
import ChatRoom015MT from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015MT/chatRoom015MT';
import { environmentRecoil, mobileBuddyListAddRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { rcvMsgPopupToggleRecoil } from '@/recoil/atoms/chatRoom';
import { formatDate } from '@/shared/util/format/dateFormatUtil';
import React, { Fragment, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import MobileLoader from '../../MobileLoader';
import { useMobileChatRoomBody } from '../../mobileHooks/useMobileChatRoomBody';
import { useMobileChatRoomImg } from '../../mobileHooks/useMobileChatRoomImg';
import MobileMessageChatRoomFooter from './MobileMessageChatRoomFooter';
import { detectEnvUtils } from '@/shared/util/detectEnvUtils';

export const ChatroomContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.chatBack};
  padding: 0 1.6rem;
`;

export const ChatroomChatting = styled.div`
  width: 100%;
`;

const MobileMessageChatRoom = () => {
  const chatRoomRef = useRef<HTMLDivElement>(null);
  const chatFootRef = useRef<HTMLDivElement>(null);
  const hooks = useMobileChatRoomBody({ chatRoomRef });
  const imageHooks = useMobileChatRoomImg();
  const navigate = useNavigate();
  const mobileBuddyListAddS = useRecoilValue(mobileBuddyListAddRecoil);

  // 채팅방 하단에 메세지 도착했다는 팝업 표출 toggle state
  const setRcvMsgPopupToggle = useSetRecoilState(rcvMsgPopupToggleRecoil);

  // 메시지 방에서 새로고침 했을 때 메인으로 이동
  useEffect(() => {
    if (mobileBuddyListAddS && Object.keys(mobileBuddyListAddS).length === 0) {
      navigate('/');
    }
  }, [mobileBuddyListAddS]);

  // 처음 진입 시와 새로운 데이터가 쌓일 때 최하단으로 스크롤 이동
  useLayoutEffect(() => {
    if (chatRoomRef.current) {
      chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
      setRcvMsgPopupToggle(false);
    }
  }, []);

  // 앱에서 이미지 다운로드
  const onClickImgDown = () => {
    if (detectEnvUtils() === 'app') {
      // 앱 환경에서 처리
      const base64Data = imageHooks.closeUpImg; // Base64 이미지 데이터
      const fileName = 'downloaded-image.jpg'; // 다운로드할 파일 이름
      const message = JSON.stringify({ base64Data, fileName });

      // 앱에 다운로드 요청
      window.fromWebToAppReqDownload(message);
    } else {
      // 웹 환경에서 처리
      const link = document.createElement('a');
      link.href = `data:image/jpeg;base64,${imageHooks.closeUpImg}`; // Base64 URL
      link.download = 'downloaded-image.jpg'; // 다운로드할 파일 이름
      link.click(); // 다운로드 실행
    }
  };

  return (
    <>
      <MobileLoader isLoading={hooks.mhLoading} />
      <ChatroomContainer className="mobileMessageChatRoom" ref={chatRoomRef} onScroll={hooks.handleScroll}>
        {hooks.mhStatus === 'success' &&
          hooks.chatListData &&
          Object.entries(hooks.chatListData).map((content: any) => {
            return (
              <Fragment key={content[0]}>
                <D.EtcChattingDateText>
                  <span>{formatDate(content?.[0] ?? '--------')}</span>
                </D.EtcChattingDateText>
                {content[1].map((list: any, index: number) =>
                  list.direction === 'mo'
                    ? ChatRoom015MO({
                        list,
                        content,
                        index,
                        reSend015Msg: hooks.reSend015Msg,
                        cancelSend015Msg: hooks.cancelSend015Msg,
                        closeUpImgHook: imageHooks.closeUpImgHook,
                        handleImgError: imageHooks.handleImgError,
                      })
                    : ChatRoom015MT({
                        list,
                        content,
                        index,
                        reSend015Msg: hooks.reSend015Msg,
                        cancelSend015Msg: hooks.cancelSend015Msg,
                        closeUpImgHook: imageHooks.closeUpImgHook,
                        handleImgError: imageHooks.handleImgError,
                      }),
                )}
              </Fragment>
            );
          })}
        {/* 이미지 확대 */}
        {imageHooks.modalSwitch ? (
          <D.MobileChattingBigImgModal className="mobileBigImg" onClick={imageHooks.autoClosePopup}>
            <D.MobileChattingBigImgWrap className="closeUpView">
              <D.MobileChattingBigImg src={`data:image/jpeg;base64,${imageHooks.closeUpImg}`} alt="testimage" />
              <D.EtcChattingBigImageModalBtnWrap className="imgBtnWrap">
                <D.MobileEtcChattingBtn className="imgDownload" type="button" onClick={() => onClickImgDown()}>
                  {/* 다운로드 버튼 */}
                  <p>다운로드</p>
                </D.MobileEtcChattingBtn>
                <D.MobileEtcChattingBtn className="imgClose" type="button" onClick={imageHooks.closeUpImgPopupClose}>
                  <p>닫기</p>
                </D.MobileEtcChattingBtn>
              </D.EtcChattingBigImageModalBtnWrap>
            </D.MobileChattingBigImgWrap>
          </D.MobileChattingBigImgModal>
        ) : null}
      </ChatroomContainer>

      <ChatroomChatting>
        <MobileMessageChatRoomFooter chatRoomRef={chatRoomRef} chatFootRef={chatFootRef} />
      </ChatroomChatting>
    </>
  );
};

export default MobileMessageChatRoom;
