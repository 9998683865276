/**
 * @title 표출되는 채팅방이 달라질 때 사용중인 recoil 값을 초기화 하는 hook
 *
 * @author 정휘학
 * @since 2024.05.04
 * */
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { groupBuddyListParamsR } from '@/recoil/atoms/addressList';
import {
  allCheckGroupR,
  checkChildrenArrR,
  checkGroupArrR,
  rcvMsgEtcPeople015R,
  rcvMsgGroupPeople015R,
  unCheckChildrenArrR,
} from '@/recoil/atoms/messageSend/messageSend';
import {
  activeChatRoomIdRecoil,
  amlQueryParamsRecoil,
  chatUserInfoRecoil,
  mhQueryParamsRecoil,
} from '@/recoil/atoms/chatRoom';
import { gmhQueryParamsRecoil, groupChatRoomInfoRecoil } from '@/recoil/atoms/atoms';
import { listToggleRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { msg015ChatRoomToggleRecoil, msgResultToggleRecoil } from '@/recoil/atoms/say015Atom';

export const useChatRoom015Reset = () => {
  const [, setActiveChatRoomIdS] = useRecoilState(activeChatRoomIdRecoil);

  const resetGroupChatRoomInfoR = useResetRecoilState(groupChatRoomInfoRecoil);
  const resetGmhQueryParamsR = useResetRecoilState(gmhQueryParamsRecoil);
  const resetAmlQueryParamsR = useResetRecoilState(amlQueryParamsRecoil);
  const resetChatUserInfoR = useResetRecoilState(chatUserInfoRecoil);
  const resetMhQueryParamsR = useResetRecoilState(mhQueryParamsRecoil);
  const setCheckGroupArrR = useSetRecoilState(checkGroupArrR);
  const resetCheckChildrenArrR = useResetRecoilState(checkChildrenArrR);
  const resetUnCheckChildrenArrR = useResetRecoilState(unCheckChildrenArrR);
  const resetRcvMsgGroupPeople015R = useResetRecoilState(rcvMsgGroupPeople015R);
  const resetGroupBuddyListParamsR = useResetRecoilState(groupBuddyListParamsR);
  const resetRcvMsgEtcPeople015R = useResetRecoilState(rcvMsgEtcPeople015R);
  const resetAllCheckGroupR = useResetRecoilState(allCheckGroupR);
  const [, setResetListToggleR] = useRecoilState(listToggleRecoil);
  const [, setMsg015ChatRoomToggleR] = useRecoilState(msg015ChatRoomToggleRecoil);
  const setMsgResultToggleR = useSetRecoilState(msgResultToggleRecoil);

  const allResetRecoil = () => {
    setResetListToggleR(false);
    setMsg015ChatRoomToggleR(false);
    setMsgResultToggleR(false);
    setActiveChatRoomIdS(null);
    resetAmlQueryParamsR();
    resetGroupChatRoomInfoR();
    resetGmhQueryParamsR();
    resetChatUserInfoR();
    resetMhQueryParamsR();
    resetCheckChildrenArrR();
    resetUnCheckChildrenArrR();
    resetRcvMsgGroupPeople015R();
    resetGroupBuddyListParamsR();
    resetRcvMsgEtcPeople015R();
    resetAllCheckGroupR();
    setCheckGroupArrR((prevState) => {
      if (!prevState) return prevState;
      return prevState.map((item) => {
        return {
          ...item,
          isChecked: false,
          isChildrenChecked: false,
        };
      });
    });
  };

  return {
    // resetRecoil,
    setResetListToggleR,
    setMsg015ChatRoomToggleR,
    setActiveChatRoomIdS,
    resetGmhQueryParamsR,
    resetGroupChatRoomInfoR,
    resetMhQueryParamsR,
    resetChatUserInfoR,
    allResetRecoil,
  };
};
