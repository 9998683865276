import { useMutation } from '@tanstack/react-query';
import { instance } from '@/shared/lib/clientAxios';

interface CreateBuddyParams {
  groupSeqNo: string;
  buddyNm: string;
  keyCommNo: string;
}

interface CreateBuddyResponse {
  groupNm: string;
  groupSeqNo: number;
  groupSeqNos: null; // 뭔지 모르겠음
  usrKey: string;
}

export const useCreateBuddyList = () => {
  const { mutateAsync, isLoading, isError, error } = useMutation(
    async ({ groupSeqNo, buddyNm, keyCommNo }: CreateBuddyParams): Promise<CreateBuddyResponse> => {
      try {
        const response = await instance.post('/contacts/create', {
          groupSeqNo,
          buddyNm,
          keyCommNo,
        });
        return response.data; // 응답 데이터 반환
      } catch (err: unknown) {
        console.error('Failed to create group:', err);
        throw err; // 오류를 상위로 전달
      }
    },
  );

  return { mutateAsync, isLoading, isError, error };
};
