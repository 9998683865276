import { useSearchGroup } from '@/components/hooks/customs/addressBook/useSearchGroup';
import React from 'react';
import * as S from './SearchGroup.styles';
import * as SB from '@/styles/button.styles';

const SearchGroup = () => {
  const searchHooks = useSearchGroup();

  return (
    <S.SearchGroupComponent className="searchGroup" onSubmit={(e) => searchHooks.onSubmitSearchText(e)}>
      <div className="addGroupRightWrap">
        <div className="searchGroup">
          <button type="submit" className="btn addBtn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
              <path
                d="M11.6111 11.3601L14.5 14.249M1.5 7.0268C1.5 8.55916 2.10873 10.0288 3.19227 11.1123C4.27582 12.1959 5.74542 12.8046 7.27778 12.8046C8.81014 12.8046 10.2797 12.1959 11.3633 11.1123C12.4468 10.0288 13.0556 8.55916 13.0556 7.0268C13.0556 5.49444 12.4468 4.02484 11.3633 2.9413C10.2797 1.85775 8.81014 1.24902 7.27778 1.24902C5.74542 1.24902 4.27582 1.85775 3.19227 2.9413C2.10873 4.02484 1.5 5.49444 1.5 7.0268Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <input
            type="text"
            placeholder="그룹명 검색 "
            value={searchHooks.groupSearchTextS}
            onChange={(e) => searchHooks.onChangeSearchText(e)}
            ref={searchHooks.inputRef}
          />
          {searchHooks.groupSearchTextS.length > 0 && (
            <SB.DelBtn className="searchdelBtn" type="button" onClick={() => searchHooks.onClickResetSearchGroup()} />
          )}
        </div>
        <div className="btnGroup">
          <button type="button" className="btn delBtn" onClick={() => searchHooks.onClickSearchClose()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
              <path
                d="M14.5 1.24902L1.5 14.249M1.5 1.24902L14.5 14.249"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </S.SearchGroupComponent>
  );
};
export default SearchGroup;
