import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const TransmissionResultWrap = styled.div`
  .searchGroup {
    .searchDateBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.2rem;
      button {
        border: 0;
        width: 1.7rem;
        height: 1.8rem;
        padding: 0;
      }
    }
  }
`;
export const TransmissionResult = styled.div``;
