import { SendInfoResponseType } from '@/apis/api/addressApis';
import { formatPhoneNumber, TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { Message015Type } from '@/components/hooks/customs/useEachChatRoomHook';

// =======================================================================
// 문자 발송시 모달창에 표출되는 텍스트 handle hook
// =======================================================================
const sendMsgModalText = (sendInfo: SendInfoResponseType, numberType: '010' | '015') => {
  const freeMsgInfo = { type: 'SMS', freeCount: 0, remainingFreeCount: 0 };
  switch (sendInfo.usrCd) {
    case '00000':
      freeMsgInfo.type = 'SMS';
      freeMsgInfo.freeCount = sendInfo.freeSmsCount;
      freeMsgInfo.remainingFreeCount = sendInfo.freeSmsCountAfterCharge;
      break;
    case 'LMS01':
      freeMsgInfo.type = 'LMS';
      // freeMsgInfo.freeCount = sendInfo.freeLmsCount;
      // freeMsgInfo.remainingFreeCount = sendInfo.freeLmsCountAfterCharge;
      freeMsgInfo.freeCount = sendInfo.freeSmsCount;
      freeMsgInfo.remainingFreeCount = sendInfo.freeSmsCountAfterCharge;
      break;
    case 'MMS01':
      freeMsgInfo.type = 'MMS';
      // freeMsgInfo.freeCount = sendInfo.freeMmsCount;
      // freeMsgInfo.remainingFreeCount = sendInfo.freeMmsCountAfterCharge;
      freeMsgInfo.freeCount = sendInfo.freeSmsCount;
      freeMsgInfo.remainingFreeCount = sendInfo.freeSmsCountAfterCharge;
      break;
    default:
      break;
  }

  const title = `[ ${freeMsgInfo.type} ] 전송하시겠습니까?`;

  const trueModalContent = `
    발신 번호 : ${formatPhoneNumber(sendInfo.callback)}</br>
    수신 인원 : ${sendInfo.totalReceivers} 명</br>
    결제 금액 : ${sendInfo.totalCost} 원</br>
    ------------------------------------------------------</br>
    ${numberType === '015' ? `보유 금액 : ${sendInfo.remainingCash} C -> ${sendInfo.cashAfterCharge} C</br>` : `보유 금액 : ${sendInfo.remainingCash} C -> ${sendInfo.cashAfterCharge} C</br>`}
    ${numberType === '015' ? `보유 무료발송 건수 : ${freeMsgInfo.freeCount} 건 -> ${freeMsgInfo.remainingFreeCount} 건</br>` : `보유 포인트 : ${sendInfo.remainingPoint} P -> ${sendInfo.pointAfterCharge} P`}
     ${numberType === '015' ? `------------------------------------------------------</br>무료 발송 건수는</br>[SMS 1건, LMS 3건, MMS 6건]만큼 차감됩니다.` : ''}
  `;
  const falseModalContent = `
      잔액이 부족하여 메세지를 전송할 수 없습니다.</br>
      서울이동통신 캐쉬를 충전하시겠습니까?</br>
      ------------------------------------------------------</br>
      결제 금액 : ${sendInfo.totalCost} 원</br>
      ${numberType === '015' ? `보유 금액 : ${sendInfo.remainingCash} C</br>` : `보유 금액 : ${sendInfo.remainingCash} C / ${sendInfo.remainingPoint} P</br>`}
      ${numberType === '015' ? `보유 무료발송 건수 : ${freeMsgInfo.freeCount} 건` : ''}
      부족한 금액 : ${sendInfo.shortageCost} 원
    `;

  return { title, content: sendInfo.pointEnough ? trueModalContent : falseModalContent };
};

// =======================================================================
// 채팅방 리스트 삭제시 표출되는 모달 내용 handle hook
// =======================================================================
const deleteChatModalText = (pickItemState: Message015Type) => {
  const { phoneNumber, buddyName } = pickItemState;

  const title = '대화방 나가기';
  const content = `${TelHyphen(phoneNumber)}(${buddyName ?? '정보없음'})님과 </br>대화방 내용이 전부 삭제됩니다.`;

  return { title, content };
};

export { sendMsgModalText, deleteChatModalText };
