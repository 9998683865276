import { client } from '@/shared/lib/clientAxios';

/**
 * @title useMutationAddAuth.tsx 매개변수 인터페이스
 *
 * @author 정휘학
 * @since 2024.03.18
 * */
export interface IUseMutationAddAuth {
  /* nice 인증 후 서버로 부터 받은 encodeData */
  encodeData: string;
  /* 현재 로그인 하려고 하는 값 */
  loginId: string;
  /* 디바이스 변경 여부 STATE */
  device: boolean;
}
/**
 * @title useMutationAddAuth.tsx 리턴 인터페이스
 *
 * @author 정휘학
 * @since 2024.03.18
 * */
export interface IUseMutationAddAuthReturn {
  /* ci, di 값이 정상적으로 되었는지 */
  updateResult: boolean;
  /* 결과에 관한 메세지 */
  msg: string;
}

/**
 * @title CI, DI 누락회원 업데이트 API
 *
 * @author 정휘학
 * @since 2024.03.18
 * */
// export const useMutationAddAuth = async (params: IUseMutationAddAuth): Promise<IUseMutationAddAuthReturn> => {
export const useMutationAddAuth = async (params: IUseMutationAddAuth): Promise<any> => {
  const deviceConfirm = params.device;
  const data = {
    encodeData: params.encodeData,
    loginId: params.loginId,
  };
  const response = await client.post(`${deviceConfirm ? '/device/v1/register' : '/signin/add-auth'}`, data);

  return response.data;
};

// 디바이스 변경

// export const useMutationDevice = async (params: ) => {
//   const data = {

//   }
//   const response = await client.post('/device/v1/register', data)
//   const response.data;
// };
