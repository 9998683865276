import { useRecoilState } from 'recoil';
import { callbackNumberState, selectNumberState } from '../../../../recoil/atoms/addressList';
import { useNavigate } from 'react-router-dom';
import { softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useEffect, useState } from 'react';

/**
 * @title useMessageSender.ts 발신인 선택에 관련된 Hooks
 *
 * @author 김남규
 * @since 2024.03.15
 * */
export const useMessageSender = () => {
  const [callbackNumbers, setCallbackNumbers] = useRecoilState<any[] | undefined>(callbackNumberState);
  // 소프트폰 탭 상태 recoil
  const [softPhoneTopTabState] = useRecoilState(softPhoneTopTabRecoil);
  // 발신번호 010, 015 recoil
  const [selectNumber, setSelectNumber] = useRecoilState(selectNumberState);
  // 010, 015에 따른 문자 계산 STATE
  const [fomatCallingNum, setFomatCallingNum] = useState<string>('015');

  const navigate = useNavigate();

  /**
   * @title 발신인 선택 이벤트 Hook
   *
   * @author 김남규
   * @since 2024.03.15
   * */
  const callbackNumHandle = (number: any) => {
    setSelectNumber(number);
  };
  /**
   * @title 번호등록 버튼 이벤트 Hook
   *
   * @author 김남규
   * @since 2024.03.15
   * */
  const addCallbackNumHandle = () => {
    navigate('/callingnumber');
  };

  // ==============================
  // 010, 015에 따라 문자내용 포맷이 달라지기에 번호 확인 useEffect
  // ==============================
  useEffect(() => {
    if (softPhoneTopTabState === '0') {
      setFomatCallingNum('015');
    }
    if (softPhoneTopTabState === '1') {
      setFomatCallingNum('010');
    }
  }, [softPhoneTopTabState]);

  return { callbackNumbers, selectNumber, callbackNumHandle, addCallbackNumHandle, fomatCallingNum };
};
