import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '@/shared/lib/clientAxios';
import { editItem } from '../SaveMessageType';

interface Message {
  body: string;
  title: string;
  seqNo: number;
  id: number;
}

interface Pageable {
  sort: any[];
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

interface Page {
  content: Message[];
  pageable: Pageable;
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: any[];
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

interface PaginatedMessages {
  pages: Page[];
  pageParams: any[];
}

const deleteMessage = async (params: { messageTabState: string; item: editItem }) => {
  const { messageTabState, item } = params;

  if (messageTabState === 'SMS') {
    await instance.delete('/message/archive/sms', {
      data: { seqNo: item?.seqNo },
    });
  } else if (messageTabState === 'MMS') {
    await instance.delete('/message/archive/mms', {
      data: { id: item?.id },
    });
  }
};

export const useDeleteSaveMessageList = (messageTabState: string, searchKeyword: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { messageTabState: string; item: editItem }) => deleteMessage(params),
    onMutate: async (variables) => {
      const { messageTabState, item } = variables;
      const queryKey = ['messages', messageTabState, searchKeyword]; // 검색 키워드를 queryKey에 포함

      // 캐시에서 이전 데이터 가져오기
      const previousMessages = queryClient.getQueryData<PaginatedMessages>(queryKey);

      // previousMessages가 undefined나 배열이 아니고, 올바른 데이터 구조인지를 확인
      if (!previousMessages || !Array.isArray(previousMessages.pages)) {
        return undefined; // 명시적으로 undefined 반환
      }

      // 삭제된 아이템을 제외한 새로운 메시지 리스트 생성
      const updatedMessages = previousMessages.pages.map((page) => ({
        ...page,
        content: page.content.filter((msg) =>
          messageTabState === 'SMS' ? msg.seqNo !== item.seqNo : msg.id !== item.id,
        ),
      }));

      // 캐시에 새로운 데이터 설정
      queryClient.setQueryData(queryKey, { ...previousMessages, pages: updatedMessages });

      // 롤백을 위한 컨텍스트 반환
      return { previousMessages, queryKey };
    },
    onError: (error, variables, context) => {
      const { queryKey, previousMessages } = context || {};
      if (queryKey) {
        // 에러 발생 시 캐시 롤백
        queryClient.setQueryData(queryKey, previousMessages);
      }
      console.error('Message deletion failed:', error);
    },
    onSuccess: () => {
      // 성공 후 별도 작업 없음
    },
  });
};
