import styled from 'styled-components';

export const MessageContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
`;

export const NoDataMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.txt400};
  font-size: 1.4rem;
`;

export const SearchContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 1.4rem;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 1rem 1.4rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  background-color: ${(props) => props.theme.colors.gray100};
  ::placeholder {
    color: ${(props) => props.theme.colors.disable};
  }
`;

export const MessageListContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.4rem;
  gap: 1.2rem;
  transition: background-color 0.4s ease-in-out; // 듀레이션 0.4초 추가
  cursor: pointer;
  &:nth-of-type(2n) {
    background: ${(props) => props.theme.colors.gray100};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.gray200};
  }
`;

interface MessageImageProps {
  batch: boolean;
}

export const MessageImage = styled.div<MessageImageProps>`
  width: 4.4rem;
  min-width: 4.4rem;
  height: 4.4rem;
  background-color: ${(props) => props.theme.colors.chatIconBack};
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.4rem;
  font-weight: 700;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);

  /* 글자 간격 추가 */
  letter-spacing: 0.05rem; /* 원하는 간격으로 조절 */
`;

export const BuddyImg = styled.div`
  width: 4.4rem;
  height: 4.4rem;
  background-color: ${(props) => props.theme.colors.chatIconBack};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 4rem;
  font-weight: 900;
  overflow: hidden;
  svg {
    font-size: 3.4rem;
    margin: 1.2rem 0 0;
  }
`;

export const MessageContents = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;

  font-size: 1.6rem;
  font-weight: 300;
  @media screen and (max-width: 380px) {
    font-size: 1.2rem;
  }
`;

export const MessageContentsTop = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  > h3 {
    color: ${(props) => props.theme.colors.txt500};
    display: inline-block;
    max-width: 16rem; /* 최대 7글자로 나오게 ... 포함 */
    white-space: nowrap;
    overflow: hidden; /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;
    font-weight: 700;
    line-height: normal;
  }
  > div {
    color: ${(props) => props.theme.colors.txt300};
    font-weight: 500;
    font-size: 1.2rem;
  }
`;

export const MessageContentsBottom = styled.div`
  color: ${(props) => props.theme.colors.txt300};
  .textContent {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    font-size: 1.2rem;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: normal;
      word-break: break-all;
      overflow-wrap: break-word;
      white-space: normal;
      word-wrap: break-word;
    }
  }
`;

export const MessageInfo = styled.div`
  white-space: nowrap;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 1rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt300};
  white-space: nowrap;

  > span {
    background-color: ${(props) => props.theme.colors.notify};
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    color: white;
  }
`;
