import { formatDateDate } from '@/shared/util/format/dateFormatUtil';
import { SERVICE_NAME } from '@/shared/constants';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as SS from '../MessageSend/MessageSend.styles';
import * as MLS from '../styles/CommonListStyles/CommonList.styles';
import * as S from './POBox015.styles';

interface IPOBox015Mock {
  nextPage?: any;
  storageUser?: string | null;
  authorityDataState?: any;
}

const POBox015Mock = ({ nextPage, storageUser, authorityDataState }: IPOBox015Mock) => {
  return (
    <S.POBox015Wrap className="POBox015Wrap">
      <SS.MessageSend className="messageSend">
        <SS.MessageSendWrap className="MessageSendWrap">
          <SS.MessageSendGroup>
            <SS.RecipientsList>
              <h3>수신인 :</h3>
              <button className="addressBtn" type="button" onClick={() => {}}>
                <p className="btn plusBtn">
                  <span />
                  <span />
                </p>
              </button>
              <SS.AddressAdd className="addressAdd">
                <SS.RecipientsGroup className="recipientsGroup">
                  <button className="arrowBtn" type="button" onClick={() => {}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="4" viewBox="0 0 10 4" fill="none">
                      <path
                        d="M5.22112 3.42915L8.45972 0.883344C8.51379 0.840866 8.54395 0.784431 8.54395 0.725744C8.54395 0.667058 8.51379 0.610623 8.45972 0.568145L8.45606 0.565404C8.42985 0.54474 8.3983 0.528286 8.36334 0.517042C8.32837 0.505798 8.29071 0.5 8.25266 0.5C8.2146 0.5 8.17695 0.505798 8.14198 0.517042C8.10701 0.528286 8.07546 0.54474 8.04925 0.565404L4.99972 2.96274L1.95141 0.565403C1.9252 0.54474 1.89365 0.528285 1.85868 0.517042C1.82371 0.505798 1.78606 0.499999 1.748 0.499999C1.70995 0.499999 1.67229 0.505798 1.63733 0.517042C1.60236 0.528285 1.57081 0.54474 1.5446 0.565403L1.54094 0.568144C1.48688 0.610623 1.45672 0.667057 1.45672 0.725744C1.45672 0.78443 1.48688 0.840865 1.54094 0.883343L4.77954 3.42915C4.80802 3.45154 4.84228 3.46936 4.88023 3.48154C4.91818 3.49372 4.95904 3.5 5.00033 3.5C5.04162 3.5 5.08248 3.49372 5.12043 3.48154C5.15838 3.46936 5.19264 3.45154 5.22112 3.42915Z"
                        fill="#FDFDFD"
                        stroke="#FDFDFD"
                      />
                    </svg>
                  </button>
                </SS.RecipientsGroup>
              </SS.AddressAdd>
            </SS.RecipientsList>

            {/* 전송내용 */}
            <SS.Transmissiondetails>
              <div className="SendTextBox">
                <div className="sendContentGroup">
                  <textarea rows={1} name="comment" placeholder="전송할 메시지를 입력해주세요" />
                </div>
              </div>
            </SS.Transmissiondetails>
            <SS.AddresContentGroup>
              <div className="tabBox">
                <div className="imageButton">
                  <input type="file" id={'fileUpload'} accept=".jpg, .jpeg" style={{ display: 'none' }} />
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
                      <path
                        d="M9.20887 18.0526H3.24901C2.65253 18.0526 2.08049 17.853 1.65872 17.4977C1.23695 17.1423 1 16.6604 1 16.1579V2.89474C1 2.39222 1.23695 1.91029 1.65872 1.55496C2.08049 1.19962 2.65253 1 3.24901 1H18.992C19.5885 1 20.1606 1.19962 20.5823 1.55496C21.0041 1.91029 21.241 2.39222 21.241 2.89474V12.3684L17.7551 9.43158C17.3319 9.08217 16.7622 8.88756 16.1697 8.89004C15.5772 8.89253 15.0098 9.0919 14.5907 9.44484L4.37351 18.0526"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.3691 16.6315L16.7426 13.7894M16.7426 13.7894L20.1162 16.6315M16.7426 13.7894V19"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.74608 8.57902C8.98817 8.57902 9.99508 7.73072 9.99508 6.68429C9.99508 5.63785 8.98817 4.78955 7.74608 4.78955C6.50398 4.78955 5.49707 5.63785 5.49707 6.68429C5.49707 7.73072 6.50398 8.57902 7.74608 8.57902Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {/* <p className="text">이미지 첨부</p> */}
                  </button>
                </div>
              </div>

              <div className="mockSend">
                {/* <div className="byteCount" style={{ color: '#b0b0b0' }}>
                  <p className="msg">SMS </p>
                  <p>
                    <span className="num">0 / 90</span> <span className="byte">Byte</span>
                  </p>
                </div> */}

                {/* 메세지 발송 버튼 */}
                <SS.SendButtonGroup>
                  <div className="active">
                    <button className="checkButton">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                        <path
                          d="M20.2539 12.2539C21.6914 11.5798 21.6914 9.53353 20.253 8.86029L2.68245 0.62322C1.23966 -0.0526737 -0.333896 1.26466 0.0760576 2.80375L1.81041 9.30735L9.16661 9.30735C9.49418 9.31305 9.80642 9.44717 10.0361 9.68084C10.2657 9.91451 10.3944 10.229 10.3944 10.5567C10.3944 10.8843 10.2657 11.1988 10.0361 11.4325C9.80642 11.6661 9.49418 11.8003 9.16661 11.806H1.81041L0.0769438 18.3104C-0.333896 19.8486 1.24054 21.1651 2.68245 20.4901L20.2539 12.2539Z"
                          fill="currentColor"
                        />
                      </svg>
                      {/* <div>전송하기</div> */}
                    </button>
                  </div>
                </SS.SendButtonGroup>
              </div>
            </SS.AddresContentGroup>
          </SS.MessageSendGroup>
        </SS.MessageSendWrap>
      </SS.MessageSend>
      <S.POBox015 className="POBox015">
        <MLS.ChatList className="ChatList">
          <div className="groups" key={uuidv4()}>
            <div className="titleGroup">
              <div className="title">
                <h3 className="pepleName">홍길동</h3>
                <p className="dateGroup">방금</p>
              </div>
              <div className="peopleNumber">
                <p>000-0000-0000</p>
              </div>
            </div>
            <div className="textContent">채팅내용</div>
            <div className="receive">
              <p>1</p>
            </div>
          </div>
          <div className="groups" key={uuidv4()}>
            <div className="titleGroup">
              <div className="title">
                <h3 className="pepleName">신길동</h3>
                <p className="dateGroup">4분 전</p>
              </div>
              <div className="peopleNumber">
                <p>000-0000-0000</p>
              </div>
            </div>
            <div className="textContent">채팅내용</div>
          </div>
          <div className="groups" key={uuidv4()}>
            <div className="titleGroup">
              <div className="title">
                <h3 className="pepleName">오길동</h3>
                <p className="dateGroup">7분 전</p>
              </div>
              <div className="peopleNumber">
                <p>000-0000-0000</p>
              </div>
            </div>
            <div className="textContent">채팅내용</div>
            <div className="receive">
              <p>11</p>
            </div>
          </div>
          <div className="groups" key={uuidv4()}>
            <div className="titleGroup">
              <div className="title">
                <h3 className="pepleName">남길동</h3>
                <p className="dateGroup">05-01</p>
              </div>
              <div className="peopleNumber">
                <p>000-0000-0000</p>
              </div>
            </div>
            <div className="textContent">채팅내용</div>
          </div>
          <div className="groups" key={uuidv4()}>
            <div className="titleGroup">
              <div className="title">
                <h3 className="pepleName">마길동</h3>
                <p className="dateGroup">03-11</p>
              </div>
              <div className="peopleNumber">
                <p>000-0000-0000</p>
              </div>
            </div>
            <div className="textContent">채팅내용</div>
          </div>
          <div className="groups" key={uuidv4()}>
            <div className="titleGroup">
              <div className="title">
                <h3 className="pepleName">황길동</h3>
                <p className="dateGroup">02-20</p>
              </div>
              <div className="peopleNumber">
                <p>000-0000-0000</p>
              </div>
            </div>
            <div className="textContent">채팅내용</div>
          </div>
        </MLS.ChatList>

        {/* 015 사서함 미가입 회원이면 뜨는 창 */}
        <S.UnregisteredMember>
          {authorityDataState?.say015User && !authorityDataState?.availabilityStatus ? (
            <div className="UnregisteredWrap">
              <div className="signUpButton">
                <button onClick={() => nextPage()}>{SERVICE_NAME} 서비스 통신료 결제하기</button>
              </div>
              <div className="helpText">
                <p>
                  * 현재 {storageUser}님은 {formatDateDate(authorityDataState?.subsEndDate)} 이후로 서비스 이용기간이
                  만료된 회원입니다.
                  <br />
                  {SERVICE_NAME} 서비스 통신료 결제 후 서비스를 재이용하실 수 있습니다.
                </p>
              </div>
            </div>
          ) : (
            <div className="UnregisteredWrap">
              <div className="signUpButton">
                <button onClick={() => nextPage()}>{SERVICE_NAME} 서비스 가입하기</button>
              </div>
              <div className="helpText">
                <p>
                  * 현재 {storageUser}님은 서비스 미가입 회원입니다.
                  <br />
                  {SERVICE_NAME} 서비스 가입 후 서비스를 이용하실 수 있습니다.
                </p>
              </div>
            </div>
          )}
        </S.UnregisteredMember>
      </S.POBox015>
    </S.POBox015Wrap>
  );
};

export default POBox015Mock;
