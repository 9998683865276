import React, { useState, useRef, useEffect } from 'react';
import * as S from '../MobileHeader.style';

import IconAddChatting from '@/styles/icons/IconAddChatting';
import MobilePopup from '../mobilePopup/MobilePopup';

const MessageListHeader = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
      inputRef.current?.focus();
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible((prev) => !prev);
  };

  return (
    <>
      <S.IconContainer>
        <S.HeaderButtonIcon onClick={togglePopup}>
          <IconAddChatting />
        </S.HeaderButtonIcon>

        {/* 
        <S.HeaderButtonIcon>
          <TbSettings style={{ strokeWidth: 1.5 }} />
        </S.HeaderButtonIcon> */}
      </S.IconContainer>

      {/* 모바일 채팅 */}
      {isPopupVisible && <MobilePopup onClose={togglePopup} />}
    </>
  );
};

export default MessageListHeader;
