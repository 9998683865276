import * as SSS from '@/widgets/ars/components/arsTree/ui/ArsTree.styles';
import React from 'react';
import { IArsMockLeftNav } from './ArsMockLeftNav.types';

const ArsMockLeftNavComponent = ({
  onClickArsLeftClick,
  arsLeftActive,
  onClickArsStartBtn,
  join015ToggleS,
}: IArsMockLeftNav) => {
  return (
    <>
      <SSS.LeftNav className={`leftNav ${arsLeftActive ? 'active' : ''}`}>
        <button onClick={() => onClickArsLeftClick()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23" fill="none">
            <path
              d="M0 0V2.15625C0 8.12188 3.23415 13.34 8.04878 16.2438V23H30V20.125C30 16.3012 22.2 14.375 18.2927 14.375H17.9268C10.9756 14.375 5.12195 8.625 5.12195 2.15625V0M18.2927 0C16.7402 0 15.2513 0.605802 14.1535 1.68414C13.0557 2.76247 12.439 4.22501 12.439 5.75C12.439 7.27499 13.0557 8.73753 14.1535 9.81586C15.2513 10.8942 16.7402 11.5 18.2927 11.5C19.8452 11.5 21.3341 10.8942 22.4318 9.81586C23.5296 8.73753 24.1463 7.27499 24.1463 5.75C24.1463 4.22501 23.5296 2.76247 22.4318 1.68414C21.3341 0.605802 19.8452 0 18.2927 0Z"
              fill="currentColor"
            />
          </svg>
          <p>서비스 안내</p>
        </button>
      </SSS.LeftNav>
      <SSS.LeftGroup className={`cardLeftGroup ${arsLeftActive ? 'active' : ''}`}>
        <div className="card ">
          <p>
            <img src="/img/say015/say_restaurant.png" alt="say_restaurant" />
          </p>
          <div className="txtBox">
            <h3>요식업 사장님</h3>
            <p>매장 운영과 관리를 자동화하여 시간을 절약하고 고객 서비스의 품질을 높이고 싶은 요식업 사장님</p>
          </div>
        </div>
        <button onClick={() => onClickArsStartBtn(join015ToggleS)}>
          {join015ToggleS ? '서비스 체험하기' : '서비스 시작하기'}
        </button>
      </SSS.LeftGroup>
    </>
  );
};

export default ArsMockLeftNavComponent;
