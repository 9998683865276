import { useCookies } from 'react-cookie';

export const useHelpPopupCookie = () => {
  const [appCookies, setAppCookies] = useCookies(['hideHelpPopup']);

  // n일간 보지않기 버튼 클릭 hook
  const onClickHidePopup = () => {
    handleHidePopup();
  };

  const handleHidePopup = () => {
    const oneWeekHide = new Date();
    oneWeekHide.setDate(oneWeekHide.getDate() + 7);
    setAppCookies('hideHelpPopup', 'true', { path: '/', expires: oneWeekHide });
    window.close();
  };

  return { appCookies, onClickHidePopup };
};
