import styled from 'styled-components';

export const UserInfoChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @media screen and (max-width: 768px) {
    gap: unset;
  }
`;
