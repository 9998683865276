import React from 'react';

import ATitle from '@/components/Atom/ATitle';
import * as S from './style/MbrCorpInsert.styles';
import * as TS from '@/styles/table.styles';
import BaseButton from '../../../Atom/BaseButton';
import BaseInput from '../../../Atom/BaseInput';
import DaumPostcode from 'react-daum-postcode';
import useMbrCorpInsert from '../../../hooks/customs/charge/TaxInvoiceAuto/useMbrCorpInsert';

interface BuisnessInfoInsertProps {
  isInsertVisible: boolean;
  setIsInsertVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const MbrCorpInsert: React.FC<BuisnessInfoInsertProps> = ({ isInsertVisible, setIsInsertVisible }) => {
  const {
    modalState,
    closeOverlay,
    postCodeStyle,
    onCompletePost,
    register,
    errors,
    setModalState,
    handleSubmit,
    onSubmit,
  } = useMbrCorpInsert(isInsertVisible, setIsInsertVisible);

  return (
    <>
      {modalState && (
        <S.ModalOverlay onClick={closeOverlay}>
          <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} autoClose />
        </S.ModalOverlay>
      )}

      <S.InfoContainer>
        <ATitle type="sub" text="사업자 정보" color="#366EFF" />
        <TS.MyPageTable>
          <tbody>
            <tr>
              <th>사업자등록번호(주민번호)</th>
              <td>
                <BaseInput
                  type="text"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  placeholder="10자리 또는 13자리 입력"
                  {...register('corpNum', {
                    required: '해당 필드는 필수입니다.',
                  })}
                />
                <S.ValidationError>{errors.corpNum?.message}</S.ValidationError>
              </td>
            </tr>

            <tr>
              <th>법인명</th>{' '}
              <td>
                <BaseInput
                  type="text"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  {...register('corpName', {
                    required: '해당 필드는 필수입니다.',
                  })}
                />
                <S.ValidationError>{errors.corpName?.message}</S.ValidationError>
              </td>
            </tr>

            <tr>
              <th>대표명</th>
              <td>
                <BaseInput
                  type="text"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  {...register('corpCeo', {
                    required: '해당 필드는 필수입니다.',
                    minLength: {
                      value: 2,
                      message: '2글자 이상 입력해주세요.',
                    },
                  })}
                />
                <S.ValidationError>{errors.corpCeo?.message}</S.ValidationError>
              </td>
            </tr>

            <tr className="businessAddress">
              <th>사업장주소</th>
              <td className="flex">
                <BaseInput
                  type="text"
                  width="7rem"
                  // height="25px"
                  marginLeft=".5rem"
                  disabled
                  {...register('postCode', {
                    required: '해당 필드는 필수입니다.',
                  })}
                />
                {/* <BaseInput type="text" width="250px" height="25px" marginLeft=".5rem" disabled {...register('address')} /> */}
                <BaseButton
                  width="6rem"
                  // height="25px"
                  backgroundColor="#8C8C8C"
                  color="#fff"
                  marginLeft=".5rem"
                  onClick={() => setModalState(true)}
                >
                  검색
                </BaseButton>
                <S.InputAddresBox>
                  {/* <BaseInput type="text" width="21rem" height="25px" marginLeft=".5rem" {...register('address2')} /> */}
                  {errors.postCode && <S.ValidationError>{errors.postCode.message}</S.ValidationError>}
                </S.InputAddresBox>
              </td>
            </tr>

            <tr className="gap">
              <th>업태 및 업종</th>
              <td>
                <BaseInput
                  type="text"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  {...register('corpType', {
                    required: '해당 필드는 필수입니다.',
                  })}
                />
                <BaseInput
                  type="text"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  {...register('corpClass', {
                    required: '해당 필드는 필수입니다.',
                  })}
                />
                {(errors.corpType && !errors.corpClass) ||
                (!errors.corpType && errors.corpClass) ||
                (errors.corpType && errors.corpClass) ? (
                  <S.ValidationError>{errors.corpType?.message || errors.corpClass?.message}</S.ValidationError>
                ) : null}
              </td>
            </tr>

            <tr>
              <th>사업자등록증 첨부</th>
              <td className="fileCenter">
                <BaseInput
                  type="file"
                  width="21rem"
                  // height="30px"
                  marginLeft=".5rem"
                  {...register('corpFile', {
                    required: '해당 필드는 필수입니다.',
                  })}
                />
              </td>
            </tr>

            <tr>
              <th>부서</th>
              <td>
                <BaseInput
                  type="text"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  {...register('managerDept', {
                    required: '해당 필드는 필수입니다.',
                  })}
                />
                {errors.managerDept && <S.ValidationError>{errors.managerDept.message}</S.ValidationError>}
              </td>
            </tr>

            <tr>
              <th>담당자</th>
              <td>
                <BaseInput
                  type="text"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  {...register('managerName', {
                    required: '해당 필드는 필수입니다.',
                    minLength: {
                      value: 2,
                      message: '2글자 이상 입력해주세요.',
                    },
                  })}
                />
                {errors.managerName && <S.ValidationError>{errors.managerName.message}</S.ValidationError>}
              </td>
            </tr>

            <tr>
              <th>연락처</th>
              <td>
                <BaseInput
                  type="text"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  {...register('managerPhone', {
                    required: '해당 필드는 필수입니다.',
                    minLength: {
                      value: 11,
                      message: '연락처를 확인해주세요.',
                    },
                  })}
                />
                <S.ValidationError>{errors.managerPhone?.message}</S.ValidationError>
              </td>
            </tr>

            <tr>
              <th>이메일</th>
              <td>
                <BaseInput
                  type="email"
                  width="21rem"
                  // height="25px"
                  marginLeft=".5rem"
                  {...register('managerEmail', {
                    required: '해당 필드는 필수입니다.',
                  })}
                />
                <S.ValidationError>{errors.managerEmail?.message}</S.ValidationError>
              </td>
            </tr>
          </tbody>
        </TS.MyPageTable>
        <BaseButton
          type="submit"
          width="13.8rem"
          // height="4rem"
          padding="1rem"
          fontWeight="bold"
          fontSize="1.4rem"
          marginTop="4rem"
          className="right"
          onClick={handleSubmit(onSubmit)}
        >
          저장하기
        </BaseButton>
      </S.InfoContainer>
    </>
  );
};

export default MbrCorpInsert;
