import { mobileAdRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const MobilePrivateRoute = () => {
  const [authenticated, setAuthenticated] = useState(Boolean(localStorage.getItem('authorizationToken')));
  const navigate = useNavigate();
  const [mobileAdS, setMobileAdS] = useRecoilState(mobileAdRecoil);

  useEffect(() => {
    const tokenChangeHandler = () => {
      setAuthenticated(Boolean(localStorage.getItem('authorizationToken')));
    };
    // 이벤트 리스너 등록
    window.addEventListener('storage', tokenChangeHandler);
    // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
    return () => {
      window.removeEventListener('storage', tokenChangeHandler);
    };
  }, []);

  useEffect(() => {
    // 점검일 때 내보내기 ************************
    if (mobileAdS) {
      localStorage.clear();
      navigate('/mobile');
    }

    // 로그인 했을 때 015 미가입 또는 015 구독 만료시 내보내기 **************************
    if (localStorage.getItem('say015User') !== 'true' && localStorage.getItem('availabilityStatus') !== 'true') {
      navigate('/mobile/mobileSignin');
    }

    // 로그인 안되어 있으면 내보내기 ************************
    if (!authenticated) {
      navigate('/mobile/mobileSignin');
    }
  }, [authenticated, mobileAdS, navigate]);

  return authenticated ? <Outlet /> : <Navigate to="/mobile/mobileSignin" />;
};

export default MobilePrivateRoute;
