import { mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { resultDetailParamsRecoil, scheduleDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useMobileChatRoomImg = () => {
  /* 좌측 개별 채팅방 리스트 API params - 해당 recoil 에 담겨있는 상대방 번호 사용 */
  const mhQueryParamsS = useRecoilValue(mhQueryParamsRecoil);
  /* 전송 결과 상세페이지 API 요청에 필요한 Recoil */
  const resultDetailParamsS = useRecoilValue(resultDetailParamsRecoil);
  /* 예약전송 관리 상세페이지 API 요청에 필요한 Recoil */
  const scheduleDetailParamsS = useRecoilValue(scheduleDetailParamsRecoil);
  /* 이미지 확대 팝업 */
  const [modalSwitch, setModalSwitch] = useState<boolean>(false);
  /* 이미지 확대 데이터 */
  const [closeUpImg, setCloseUpImg] = useState<string | null>(null);
  const closeUpViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    closeUpImgPopupClose();
  }, [mhQueryParamsS.contactNumber, resultDetailParamsS, scheduleDetailParamsS]);

  /**
   * @title 015 채팅 이미지 확대 Hook
   * */
  const closeUpImgHook = (imgData: string) => {
    if (imgData === 'File not found or unreadable.' || !imgData) return;
    setModalSwitch((prevState) => !prevState);
    setCloseUpImg(imgData);
  };
  /**
   * @title 015 채팅 이미지 확대 팝업 닫기 Hook
   * */
  const closeUpImgPopupClose = () => {
    setModalSwitch(false);
    setCloseUpImg(null);
  };
  /**
   * @title 015 채팅 이미지 확대 팝업 배경 클릭시 자동 닫기
   * */
  const autoClosePopup = (e: any) => {
    if (!closeUpViewRef.current) return;
    if (!closeUpViewRef.current.contains(e.target)) {
      setModalSwitch(false);
      setCloseUpImg(null);
    }
  };

  /**
   * @title 이미지 로딩 실패시 대체 이미지 표출 Hook
   * */
  const handleImgError = (e: any) => {
    e.target.onerror = null;
    e.target.src = '/img/noImage.svg';
  };

  return {
    closeUpImgHook,
    closeUpImgPopupClose,
    autoClosePopup,
    handleImgError,
    modalSwitch,
    closeUpImg,
    closeUpViewRef,
  };
};
