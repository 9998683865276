import CashCharge from '@/pages/CashCharge/CashCharge';
import * as S from './MobileEtcCharge.style';
import React from 'react';
import { useChargeStateHandler } from '@/components/hooks/customs/charge/chargeStation/useChargeStateHandler';
import ATitle from '@/components/Atom/ATitle';
import CashChargeTop from '@/pages/CashCharge/CashChargeTop/CashChargeTop';
import CashChargeAmount from '@/pages/CashCharge/CashChargeAmount/CashChargeAmount';
import CashChargeGuide from '@/pages/CashCharge/CashChargeGuide/CashChargeGuide';
import CashChargeStateView from '@/pages/CashCharge/CashChargeStateView/CashChargeStateView';

const MobileEtcCharge = () => {
  const hooks = useChargeStateHandler();

  return (
    <S.CashCharge>
      <S.Wrapper>
        <ATitle type="sub" text="캐시 충전하기" />
        {/* 결제 수단 선택 컴포넌트 */}
        <CashChargeTop
          handlerPayMethodTypes={hooks.handlerPayMethodTypes}
          payMethodTypeCodeState={hooks.payMethodTypeCodeState}
        />
        {/* 금액 */}
        <S.AmountWrapper className="AmountWrapper">
          <div className="leftGroup">
            {/* 금액선택 */}
            <CashChargeAmount amountS={hooks.amountS} handleAmount={hooks.handleAmount} />

            <span className="line" />

            {/* 금액안내 */}
            <CashChargeGuide />
          </div>

          {/* 최종 결제 상태 */}
          <CashChargeStateView
            amountS={hooks.amountS}
            payMethodTypeCodeState={hooks.payMethodTypeCodeState}
            pointValueState={hooks.pointValueState}
            pointPercentState={hooks.pointPercentState}
          />
        </S.AmountWrapper>
      </S.Wrapper>
    </S.CashCharge>
  );
};
export default MobileEtcCharge;
