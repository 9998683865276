import React, { useEffect, useRef } from 'react';
import * as S from './MobilePopup.style';
import ChatPopupListHeader from './mobilePopupList/ChatPopupListHeader';
import { useNavigate } from 'react-router-dom';
import IconChatHuman from '@/styles/icons/IconChatHuman';
import IconChatTel from '@/styles/icons/IconChatTel';
import { IoIosChatbubbles } from 'react-icons/io';
import { callbackListRecoil } from '@/recoil/atoms/useCallbackList';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addModalOpenRecoil, addModalTitleRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import IconChatDouble from '@/styles/icons/IconChatDouble';

interface MobilePopupProps {
  onClose: () => void;
}

const MobilePopup: React.FC<MobilePopupProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const popupRef = useRef<HTMLDivElement>(null);
  const callbackListS = useRecoilValue(callbackListRecoil);

  // 추가 방 상태 리코일
  const setAddModalOpenS = useSetRecoilState(addModalOpenRecoil);
  // 추가 방 제목 리코일
  const setAddModalTitleS = useSetRecoilState(addModalTitleRecoil);

  // 외부 클릭 감지 함수
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <S.MobilePopupContainer>
      <S.PopupWrap className="mobilePopup" ref={popupRef}>
        {/* 쳇 리스트 */}
        <ChatPopupListHeader onClose={onClose} />
        <S.Group>
          <li>
            <button type="button" onClick={() => navigate('/mobile/message/messageList/messageAdd')}>
              {/* <IconSpeechBubble /> */}
              <IconChatHuman />
              <p>주소록</p>
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                setAddModalOpenS(true);
                setAddModalTitleS('채팅방 생성');
                window.location.hash = '#add';
              }}
            >
              {/* <IconSpeechBubblePlus /> */}
              <IconChatTel />
              <p>번호입력</p>
            </button>
          </li>
          <li>
            <button type="button" onClick={() => navigate('/mobile/message/messageList/multiMessageAdd')}>
              <IconChatDouble fontSize={'2.4rem'} />
              <p>단체전송</p>
            </button>
          </li>
        </S.Group>
      </S.PopupWrap>
    </S.MobilePopupContainer>
  );
};
export default MobilePopup;
