import exp from 'constants';
import styled from 'styled-components';

// 015 채팅방 전체 감싸고있는 테이블
export const EtcChattingComponent = styled.div`
  position: absolute;
  top: 0;
  z-index: 5;
  right: 0;
  width: 100%;
  max-width: 380px;
  height: calc(var(--vh, 100vh));
  /* border-left: 1px solid #ececec; */
  overflow: hidden;
  background: ${(props) => props.theme.colors.chatBack};
  /* box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.05); */
  > div {
    height: 100%;
  }

  &.etcStart {
    transform: translateX(50%);
    opacity: 0;
    pointer-events: none;
    transition-duration: 0.6s;
    transition-property: transform, opacity;
    .etcChattingFoot {
      display: none;
    }
  }

  &.etcEnd {
    transform: translateX(0);
    opacity: 1;
    pointer-events: visible;
    transition-duration: 0.85s;
    transition-property: transform, opacity;
  }
  @media screen and (max-width: 1440px) {
    position: fixed;
    z-index: 101;
  }
  @media screen and (max-width: 768px) {
    /* height: calc(var(--vh, 100vh) - 6rem); */
    max-width: unset;
  }
`;

/*** Start 왼쪽 UI ***/
export const EtcChattingProfileWrap = styled.div`
  // 프로필 정보 감싸는 부분
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 22px;
  padding-top: 5px;
  width: 420px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    background: transparent;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

/* Start 등록되어있는 경우 */
export const EtcChattingProfileContent = styled.div`
  // 프로필 정보
  position: relative;
  padding: 36px 34px;
  width: 100%;
  min-height: 296px;
  border: 1px solid transparent;
  border-radius: 15px;
  background: #fbfbfe;
  box-shadow:
    rgba(0, 0, 0, 0.12) -2px -2px 4px,
    rgba(0, 0, 0, 0.14) 2px 2px 4px;
  /* box-shadow: 1px 3px 10px rgba(0,0,0,0.12), 0 2px 10px rgba(0,0,0,0.14); */

  &.active {
    border: 1px solid ${(props) => props.theme.colors.main};
  }
`;

export const EtcChattingProfileCloseBtn = styled.button`
  // 프로필 정보 닫기 버튼
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const EtcChattingProfileHead = styled.div`
  // 프로필 이름 , 그룹명 버튼 감싸는 부분
  text-align: center;
`;

export const EtcChattingProfileName = styled.h6`
  // 프로필 이름
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  font-size: 3rem;
  font-weight: 600;
  color: #000;
`;
export const EtcChattingProfileFunBtn = styled.button`
  // 프로필 버튼 등록되어있을경우
  padding: 0 10px;
  margin: 22px 0 32px 0;
  width: fit-content;
  height: 28px;
  font-size: 1.2rem;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  background: #f1f2f8;
  color: ${(props) => props.theme.colors.main};
  cursor: pointer;
  outline: none;

  &:focus {
    border: none;
    outline: none;
  }
`;

export const EtcChattingProfileInfo = styled.dl`
  // 연락처,이메일,메모 감싸는 부분
`;
export const EtcChattingProfileRow = styled.div`
  // 열
  &:nth-of-type(2) {
    margin: 28px 0 18px 0;
  }

  display: flex;
  justify-content: space-between;
  gap: 20px;

  &:last-of-type {
    dd {
      padding-left: 0;
    }
  }
`;

export const EtcChattingProfileDtWrap = styled.dt`
  // dt
  width: 62px;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.txt500};
`;

export const EtcChattingProfileDdWrap = styled.dd`
  // dd
  padding-left: 10px;
  width: calc(100% - 20%);
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.txt500};
`;

export const EtcChattingProfileDdSpan = styled.span`
  // span
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
`;

export const EtcChattingProfileDdP = styled.p`
  // 메모란
  padding: 10px;
  width: 100%;
  max-height: 54px;
  line-height: 20px;
  border-radius: 10px;
  background: #f1f2f8;
  color: #999;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: rgb(214, 214, 220);
  }

  ::-webkit-scrollbar-thumb {
    background: #999;
  }
`;
/* End 등록되어있는 경우 */
/* Start 등록되어있지 않는 경우 */

export const EtcChattingNotProfileContent = styled.div`
  // 미등록 주소록 프로필 정보
  position: relative;
  padding: 36px 34px;
  width: 100%;
  min-height: 296px;
  border-radius: 15px;
  background: #f8f9fd;
  box-shadow:
    1px 3px 10px rgba(0, 0, 0, 0.12),
    0 2px 10px rgba(0, 0, 0, 0.14);
`;

export const EtcChattingNotProfileCloseBtn = styled.button`
  // 프로필 정보 닫기 버튼
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const EtcChattingNotProfileBtn = styled.button`
  // 프로필 버튼 등록되어있지 않을경우
  display: flex;
  align-items: center;
  gap: 17px;
  padding: 0 10px;
  margin: 22px auto 32px auto;
  width: fit-content;
  height: 28px;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 10px;
  background: #f1f2f8;
  outline: none;
  color: ${(props) => props.theme.colors.main};
  cursor: pointer;
`;

export const EtcChattingNotProfileHead = styled.div`
  // 미등록 프로필 이름 , 그룹명 버튼 감싸는 부분
  text-align: center;
`;

export const EtcChattingNotProfileNameWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
`;

export const EtcChattingNotProfileName = styled.h6`
  // 미등록 프로필 이름
  line-height: normal;
  font-size: 3rem;
  font-weight: 600;
  color: #000;
`;

export const EtcChattingNotProfileBtnSpan = styled.span`
  // span
`;

export const EtcChattingNotProfileInfo = styled.dl`
  // 미등록 연락처,이메일,메모 감싸는 부분
`;
export const EtcChattingNotProfileRow = styled.div`
  // 열
  display: flex;
  justify-content: space-between;
  gap: 20px;

  &:nth-of-type(2) {
    margin: 28px 0 18px 0;
  }

  &:last-of-type {
    dd {
      p {
        min-height: 52px;
      }
    }
  }
`;

export const EtcChattingNotProfileDtWrap = styled.dt`
  // dt
  width: 62px;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.txt500};
`;

export const EtcChattingNotProfileDdWrap = styled.dd`
  // dd
  width: calc(100% - 20%);
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.txt500};
`;

export const EtcChattingNotProfileDdSpan = styled.span`
  // span
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
`;

export const EtcChattingNotProfileDdP = styled.p`
  // 메모란
  width: 100%;
  min-height: 22px;
  line-height: 20px;
  border-radius: 10px;
  background: #f1f2f8;
  color: #999;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: rgb(214, 214, 220);
  }

  ::-webkit-scrollbar-thumb {
    background: #999;
  }
`;
/* End 등록되어있지 않는 경우 */
/*** End 왼쪽 UI ***/

/*** Start 오른쪽 영역 채팅방 UI ***/
export const EtcChattingWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1440px) {
    padding-right: 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    &.active {
      width: 0;
    }
  }
  @media screen and (max-width: 640px) {
    padding: 0;
  }

  /* foot Css */
  form {
    @media screen and (max-width: 640px) {
      padding-right: 0;
    }
  }
`;

export const EtcChattingContent = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

/*** End 오른쪽 영역 채팅방 UI ***/
