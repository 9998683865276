import { get015ResNum, getConfirm015Num, getSignUpConfirm015Num, post015Permit } from '@/apis/api/say015Apis';
import { checkConfirm015Recoil } from '@/recoil/atoms/say015Atom';
import { useNice } from '@/shared/components/niceCertification/hooks/useNice';
import { useNiceCertification } from '@/shared/components/niceCertification/hooks/useNiceCertification';
import { SERVICE_NAME } from '@/shared/constants';
import { removeCookie, setCookie } from '@/shared/util/cookie';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useModalHook } from '../../../../commons/modals/useModalHook';
import { ISubscribe015, TNumber } from '../../types/Hooks.types';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { signInRecoil } from '@/recoil/atoms/signInAtom';

const checkedMessage = {
  success: '사용 가능한 번호입니다.',
  // contact: '해당 번호는 고객센터에 문의 후 이용가능합니다.',
  contact: '사용 불가능한 번호입니다.',
  // goldNumber: '해당 번호로 가입시 캐시 차감됩니다.',
  goldNumber: '사용 불가능한 번호입니다.',
  error: '이미 사용중인 번호입니다.',
  pending: "015번호 입력 후 '돋보기'를 눌러주세요.",
};

export const useCheck015Number = () => {
  // 015 10자리 11자리 선택 radio STATE
  const [corporation015S, setCorporation015S] = useState<boolean>(false);
  // 015 추천번호 Params STATE
  const [accountTypeState, setAccountType] = useState<string>('personal');
  // radio 선택값
  const [, setSelectedValue] = useState<string>('');
  // input 값
  const [inputValue, setInputValue] = useState({ firstPart: '', secondPart: '' });
  // 선택 번호를 서버로 중복 요청할 값
  const [pickNumber, setPickNumber] = useState({ firstPart: '', secondPart: '' });
  // 서버에서 요청값이 거절일 때 실패 메세지 띄울것
  const [errorMessage, setErrorMessage] = useState<string | null>('희망하는 번호를 입력해주세요');
  // 메세지 스타일 적용
  const [messageStyle, setMessageStyle] = useState<any>(false);
  // 번호추천 셀렉트 모달 State
  const [selectOpen, setSelectOpen] = useState<string>('numberGroup');
  // 회원가입 번호추천 셀렉트 모달 State
  const [signUpSelectOpen, setSignUpSelectOpen] = useState<boolean>(false);
  // 구독 요청할 state
  const [subscribe015, setSubscribe015] = useState<ISubscribe015>({
    encodeData: null,
    number015: null,
    subsPeriod: 6,
    certTerms1: false,
    serviceTerms1: false,
    eventTerms1: false,
  });
  // 본인인증(가입하기) 버튼 disabled State
  const [subscribe015Btn, setSubscribe015Btn] = useState<boolean>(true);
  // 본인인증(가입하기) 버튼 style State
  const [subscribe015BtnStyles, setSubscribe015BtnStyles] = useState<string>('startBtn unactive');
  // 나이스인증 팝업 로직
  const { niceCertification } = useNiceCertification();
  // 구독 완료 후 표출할 모달 State
  const [complete, setComplete] = useState<boolean>(false);
  // TODO - 리팩토링후 삭제할 코드 015 번호발급 : true면 회원가입때
  const [checkConfirm015S, setCheckConfirm015S] = useRecoilState(checkConfirm015Recoil);
  // TODO - 충전 : 앱 충전로직 끝나기 전까지 막을 기능
  const environmentS = useRecoilValue(environmentRecoil);

  const setSignInState = useSetRecoilState(signInRecoil);

  /* nice 인증 return 값 받아오는 Hook */
  const { niceData } = useNice();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // 구독완료시 토큰 저장
  const updateTokensInLocalStorage = (authInfo: any) => {
    removeCookie('Refresh_Token');
    // 응답으로 받은 새로운 토큰과 리프레시 토큰을 로컬 스토리지에 저장합니다.
    localStorage.setItem('authorizationToken', authInfo?.token);
    setCookie('Refresh_Token', authInfo?.refreshToken, { path: '/' });
    localStorage.setItem('say015User', authInfo?.say015User);
    setComplete((prev) => !prev && true);
  };

  const { successModal, confirmModal, warningModal, customSuccessModal } = useModalHook();

  // onChange 015번호 개인, 법인 hook
  const onChange015Radio = (e: React.ChangeEvent<HTMLInputElement>) => {
    // corporation이면(corporation015S true) 법인 아니면(corporation015S false) 개인
    const numState = e.target.value === 'corporation';
    setCorporation015S(numState);
  };

  // 개인, 법인 라디오 변경시 번호검증 초기화
  useEffect(() => {
    if (corporation015S) {
      setAccountType('company');
    } else {
      setAccountType('personal');
    }
    setInputValue({ firstPart: '8504', secondPart: '' });
    setPickNumber({ firstPart: '', secondPart: '' });
  }, [corporation015S]);

  // 015추천번호 10개 get api요청
  const { data: sug015NumList, isLoading: sug015NumListIsLoading } = useQuery(
    ['/015-numbers'],
    () => get015ResNum(accountTypeState),
    {
      enabled: selectOpen === 'numberGroup active',
    },
  );

  // 015추천번호 보기 Click 핸들러
  const sug015NumViewHandle = (type?: string) => {
    if (type === 'signUp') {
      setSignUpSelectOpen((prev) => !prev);
    } else {
      setSelectOpen((prev) => (prev === 'numberGroup active' ? 'numberGroup' : 'numberGroup active'));
    }
  };

  // 추천번호 하이픈 처리
  const addHyphen = (str: string) => {
    // 법인일 때 10자리로 보이게 개인일 때 11자리로 보이게
    return corporation015S
      ? `015 - ${str.slice(0, 3)} - ${str.slice(3)}`
      : `015 - ${str.slice(0, 4)} - ${str.slice(4)}`;
  };

  const { refetch: confirm015NumRefetch } = useQuery(
    ['/015-number'],
    () => getConfirm015Num(pickNumber.firstPart, pickNumber.secondPart),
    {
      enabled: false,
      onSuccess: (status: TNumber) => {
        numCheckOnSuccess(status);
      },
      onError: (error: any) => {
        setMessageStyle(false);
        setErrorMessage(`${error?.response?.data}`);
      },
    },
  );

  const { isLoading, refetch: confirmSignUp015NumRefetch } = useQuery(
    ['/signup/015-number'],
    () => getSignUpConfirm015Num(pickNumber.firstPart, pickNumber.secondPart),
    {
      enabled: false,
      onSuccess: (status: TNumber) => {
        numCheckOnSuccess(status);
      },
      onError: (error: any) => {
        setMessageStyle(false);
        setErrorMessage(`${error?.response?.data}`);
      },
    },
  );

  // 번호 체크 분기처리(onSuccess에서 실행) hook
  const numCheckOnSuccess = (status: TNumber) => {
    if (status.usable && !status.goldNumber) {
      // 사용가능, 골드번호아님
      setMessageStyle(true);
      setErrorMessage(checkedMessage.success);
      setSubscribe015((prevState) => ({
        ...prevState,
        number015: `015${pickNumber.firstPart}${pickNumber.secondPart}`,
      }));
    }
    if (status.usable && status.goldNumber) {
      // 사용가능, 골드번호 (등급별 과금 로직 추가 예정)
      setMessageStyle(true);
      setErrorMessage(checkedMessage.goldNumber);
      setSubscribe015((prevState) => ({
        ...prevState,
        number015: `015${pickNumber.firstPart}${pickNumber.secondPart}`,
      }));
    }
    if (!status.usable && !status.goldNumber) {
      // 사용중인번호, 골드번호 여부 모름
      setMessageStyle(false);
      setErrorMessage(checkedMessage.error);
      setSubscribe015((prevState) => ({
        ...prevState,
        number015: `015${pickNumber.firstPart}${pickNumber.secondPart}`,
      }));
    }
    if (!status.usable && status.goldNumber) {
      // 문의 후 사용가능 번호, 골드번호
      setMessageStyle(false);
      setErrorMessage(checkedMessage.contact);
      setSubscribe015((prevState) => ({
        ...prevState,
        number015: `015${pickNumber.firstPart}${pickNumber.secondPart}`,
      }));
    }
  };

  // 가입 요청 mutate
  const { mutate: permit015 } = useMutation(post015Permit, {
    onSuccess: async (authInfo) => {
      if (authInfo && authInfo.authInfo && authInfo.authInfo.refreshToken) {
        updateTokensInLocalStorage(authInfo?.authInfo);
        queryClient.refetchQueries(['/userPoint']);
        queryClient.refetchQueries(['/message/getCallbackList']);
        queryClient.refetchQueries(['/say015/serviceStatus']);
      } else {
        console.error('Failed to retrieve refreshToken from authInfo');
      }

      if (environmentS === 'app') {
        customSuccessModal(
          () => {
            navigate('/');
            localStorage.removeItem('authorizationToken');
            setSignInState(false);
            window.location.reload();
          },
          '015 서비스 가입이 완료되었습니다.',
          '로그인을 완료하고 서비스를 이용해 주시기 바랍니다.',
          true,
        );
      }
    },
    onError: (error: any) => {
      /* error code
       * 201 : 포인트 부족
       * 202 : cidi 불일치
       * 203 : 그 외
       */
      if (error.response.data.code === '201') {
        // TODO - 충전소 : 앱 충전소 기능 끝나면 변경
        const naviUrlS = environmentS === 'app' ? '/mobile/etc/etcCharge' : '/charge';
        confirmModal(async () => navigate(naviUrlS), '포인트 부족', '충전소로 이동하시겠습니까?', true);
      }
      if (error.response.data.code === '202') {
        warningModal('인증실패', error.response.data.failMsg, true);
      }
      if (error.response.data.code === '203') {
        warningModal('인증실패', '고객센터에 문의 바랍니다.', true);
      }
    },
  });

  // onClick 추천번호 리스트 button hook
  const onClickRecommendNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedNumber = event.target.value;
    setSelectedValue(selectedNumber); // 선택된 값 업데이트
    if (corporation015S) {
      setInputValue({
        firstPart: selectedNumber.slice(0, 3),
        secondPart: selectedNumber.slice(3),
      });
    } else {
      setInputValue({
        firstPart: selectedNumber.slice(0, 4),
        secondPart: selectedNumber.slice(4),
      });
    }
    setErrorMessage(checkedMessage.pending);
    setSelectOpen('numberGroup');
    setSignUpSelectOpen(false);
    setMessageStyle(false);
    setSubscribe015Btn(true);
    setSubscribe015BtnStyles('startBtn unactive');
  };

  // 입력 필드의 값이 변경될 때 호출되는 함수
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputValue((prevState) => ({ ...prevState, [name]: value })); // 이전 상태값을 사용하여 업데이트
    setSelectedValue(`015${value.replace('-', '')}`); // 입력된 값으로 선택된 값 업데이트
    if (messageStyle) {
      setMessageStyle(false);
      setErrorMessage(checkedMessage.pending);
      setSubscribe015Btn(true);
      setSubscribe015BtnStyles('startBtn unactive');
    }
  };

  // 적용Btn 클릭시 015를 붙이고 firstPart와 secondPart를 합쳐서 리턴하는 함수
  const generateFullNumber = () => {
    setPickNumber(inputValue);
  };

  // 번호 선택 input을 모두 입력하고 나면 자동으로 검사 실행 useEffect
  useEffect(() => {
    if (inputValue.firstPart.length >= 3 && inputValue.secondPart.length === 4) {
      generateFullNumber();
    }
  }, [inputValue]);
  // pickNumber 값이 변경될 때마다 confirm015NumRefetch 실행
  useEffect(() => {
    if (pickNumber?.firstPart.trim() && pickNumber?.secondPart.trim()) {
      if (checkConfirm015S) {
        confirmSignUp015NumRefetch();
      } else {
        confirm015NumRefetch();
      }
    }
  }, [pickNumber]);

  // // 성공, 실패 메세지 스타일 핸들
  // const messageStyleHandle = () => {};

  // 구독 완료 버튼 클릭시 핸들
  const subscribe015Handle = () => {
    confirmModal(
      async () => permit015(subscribe015),
      `${SERVICE_NAME} 서비스 이용`,
      `015-${pickNumber.firstPart}-${pickNumber.secondPart} 번호로</br>${SERVICE_NAME} 서비스를 이용하시겠습니까?`,
      true,
    );
  };

  // 나이스 본인인증
  const onClickCertify = async () => {
    await niceCertification();
  };

  // 전체 동의 체크박스 상태 변경 핸들러
  const handleAllCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === 'allAgree') {
      setSubscribe015((prevState) => ({
        ...prevState,
        certTerms1: checked,
        serviceTerms1: checked,
        eventTerms1: checked,
      }));
    }
  };
  // 동의서 체크박스 상태 변경 핸들러
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSubscribe015((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const checkAllCheckbox = document.getElementById('check_all') as HTMLInputElement | null;
    const certTerms1Checkbox = document.getElementById('check_use') as HTMLInputElement | null;
    const serviceTerms1Checkbox = document.getElementById('check_personal') as HTMLInputElement | null;
    // const eventTerms1Checkbox = document.getElementById('check_event') as HTMLInputElement | null;

    if (checkAllCheckbox && certTerms1Checkbox && serviceTerms1Checkbox) {
      checkAllCheckbox.checked = certTerms1Checkbox?.checked && serviceTerms1Checkbox?.checked;
    }
  }, [subscribe015?.certTerms1, subscribe015?.serviceTerms1, subscribe015.eventTerms1]);

  useEffect(() => {
    if (subscribe015?.number015 && subscribe015?.certTerms1 && subscribe015?.serviceTerms1) {
      setSubscribe015BtnStyles('startBtn');
      setSubscribe015Btn(false);
    } else {
      setSubscribe015BtnStyles('startBtn unactive');
      setSubscribe015Btn(true);
    }
  }, [subscribe015.certTerms1, subscribe015.serviceTerms1, subscribe015.number015]);

  // 체크번호 유효성 검증후 나이스 본인인증 팝업창 open
  const completeHandle = () => {
    if (inputValue !== pickNumber) {
      setErrorMessage(checkedMessage.pending);
      warningModal(`${SERVICE_NAME} 번호확인`, '입력하신 015번호를 확인해주세요.', true);
      return;
    }
    onClickCertify();
  };

  // nice 인증후 데이터가 들어오면 실행될 useEffect
  useEffect(() => {
    if (niceData) {
      setSubscribe015((prevState) => ({
        ...prevState,
        encodeData: niceData,
      }));
    }
  }, [niceData]);

  useEffect(() => {
    if (niceData !== null && subscribe015.encodeData !== 0 && environmentS !== 'app') {
      subscribe015Handle();
    }
  }, [subscribe015]);

  return {
    inputValue,
    handleInputChange,
    generateFullNumber,
    errorMessage,
    messageStyle,
    sug015NumListIsLoading,
    sug015NumList,
    onClickRecommendNumber,
    addHyphen,
    subscribe015,
    handleCheckboxChange,
    subscribe015Handle,
    subscribe015Btn,
    handleAllCheckboxChange,
    sug015NumViewHandle,
    selectOpen,
    subscribe015BtnStyles,
    complete,
    completeHandle,
    pickNumber,
    corporation015S,
    onChange015Radio,
    accountTypeState,
    setInputValue,
    setSelectedValue,
    checkedMessage,
    signUpSelectOpen,
    onClickCertify,
  };
};
