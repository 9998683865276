import React from 'react';
import { parseInt } from 'lodash';
import { amountFormat } from '@/shared/util/format/amountFormatUtil';
import { AmountCardHoverStateType } from '@/components/hooks/customs/charge/chargeStation/useChargeStateHandler';
import {
  WORKS015_ANNUAL,
  WORKS015_LMS,
  WORKS015_MMS,
  WORKS015_MONTHLY,
  WORKS015_PUSH,
  WORKS015_SMS,
  WORKS015_STT,
  WORKS015_TTS,
} from '@/shared/constants';

/**
 * @title 015 이용요금 결제 - 이용권에 관한 이용혜택 정보 카드
 *
 * @author 정휘학
 * @since 2024.05.17
 * */

interface IMy015ChargeMidSubInfoProps {
  // 마우스가 현재 어떤 결제 카드에 올라가있는지에 대한 state
  amountCardHoverS: AmountCardHoverStateType;
}

const My015ChargeMidSubInfo = (props: IMy015ChargeMidSubInfoProps) => {
  return (
    <li className="w40 payMenu">
      <div className="top">
        <table>
          <thead>
            <tr>
              <th colSpan={2}>음성 사서함</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>음성 받기</th>
              <td>무제한</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colSpan={2}>문자 사서함</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>일반문자(SMS)</th>
              <td>{WORKS015_SMS}원/건(월 100건 제공)</td>
            </tr>
            <tr>
              <th>장문문자(LMS)</th>
              <td>{WORKS015_LMS}원/건</td>
            </tr>
            <tr>
              <th>포토문자(MMS)</th>
              <td>{WORKS015_MMS}원/건</td>
            </tr>
            <tr>
              <th>문자 알림</th>
              <td>{WORKS015_PUSH}원/건(PUSH 기본)</td>
            </tr>
            {/* TODO - stt, tts 주석 */}
            {/* <tr>
              <th>STT 변환</th>
              <td>{parseInt(WORKS015_STT as string) * 60}원/분(월 5분 제공)</td>
            </tr>
            <tr>
              <th>TTS 변환</th>
              <td>{WORKS015_TTS}원/건(일 5건 제공)</td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <span className="row" />
      <div className="bottom">
        <table>
          <tbody>
            <tr>
              <th>합계:</th>
              {props.amountCardHoverS === 'monthly_pass' ? (
                <td>
                  <h5>{amountFormat(parseInt(WORKS015_MONTHLY as string))}원/월</h5>
                </td>
              ) : (
                <td>
                  <div className="sale">
                    <p className="blue">17%</p>
                    <p className="gray">
                      <span>13,200</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="58" height="12" viewBox="0 0 58 12" fill="none">
                        <line x1="0.124023" y1="0.637573" x2="47.1024" y2="0.637573" stroke="#7C7D7F" />
                        <line
                          y1="-0.5"
                          x2="10.8384"
                          y2="-0.5"
                          transform="matrix(0.800212 -0.599717 0.662445 0.749111 38.4292 7.63757)"
                          stroke="#7C7D7F"
                        />
                        <path
                          d="M56.9648 8.03239C57.16 7.83713 57.16 7.52054 56.9648 7.32528L53.7828 4.1433C53.5875 3.94804 53.2709 3.94804 53.0757 4.1433C52.8804 4.33856 52.8804 4.65514 53.0757 4.85041L55.9041 7.67883L53.0757 10.5073C52.8804 10.7025 52.8804 11.0191 53.0757 11.2144C53.2709 11.4096 53.5875 11.4096 53.7828 11.2144L56.9648 8.03239ZM38.4292 8.17883L56.6112 8.17883L56.6112 7.17883L38.4292 7.17883L38.4292 8.17883Z"
                          fill="#7C7D7F"
                        />
                      </svg>
                    </p>
                  </div>
                  <h5>{amountFormat(parseInt(WORKS015_ANNUAL as string))}원/년</h5>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </li>
  );
};
export default My015ChargeMidSubInfo;
