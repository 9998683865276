import React, { useState } from 'react';

import { Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

interface IUseModalReturn {
  successModal: (title?: string, content?: string, centered?: boolean, path?: string) => void;
  warningModal: (title?: string, content?: string, centered?: boolean, path?: string) => void;
  confirmModal: (
    onOk: () => Promise<void> | void,
    title?: string,
    content?: string,
    centered?: boolean,
    path?: string,
    onCancel?: () => Promise<void> | void,
    okText?: string,
    cancelText?: string,
  ) => void;

  customSuccessModal: (onOk: () => Promise<void> | void, title?: string, content?: string, centered?: boolean) => void;
  customWarningModal: (onOk: () => Promise<void> | void, title?: string, content?: string, centered?: boolean) => void;
}

/**
 * @title useModalHook.tsx alert 모달창 유틸 Hook
 *
 * @author 정휘학
 * @since 2024.03.15
 * */

export const useModalHook = (): IUseModalReturn => {
  const native = useNavigate();
  const splitedContentFun = (content: string | undefined) => {
    return (
      <div>
        {content?.split('</br>').map((el) =>
          el.trim() === '' ? (
            <br key={uuidv4()} /> // 빈 줄 처리
          ) : (
            <p key={uuidv4()}>{el}</p> // 일반 텍스트 처리
          ),
        )}
      </div>
    );
  };

  const successModal = (title?: string, content?: string, centered?: boolean, path?: string) => {
    const splitedContent = splitedContentFun(content);
    Modal.success({
      title,
      content: splitedContent,
      centered,
      zIndex: 9999,
      okText: '확인',
      onOk: () => {
        if (path === undefined) return;
        native(path);
      },
    });
  };

  const warningModal = (title?: string, content?: string, centered?: boolean, path?: string) => {
    const splitedContent = splitedContentFun(content);
    Modal.warning({
      title,
      content: splitedContent,
      centered,
      zIndex: 9999,
      okText: '확인',
      onOk: () => {
        if (path === undefined) return;
        native(path);
      },
    });
  };

  const confirmModal = (
    // onOk: () => Promise<void> | void,
    onOk: () => Promise<void> | void,
    title?: string,
    content?: string,
    centered?: boolean,
    path?: string,
    onCancel?: () => Promise<void> | void,
    okText?: string,
    cancelText?: string,
  ) => {
    const splitedContent = splitedContentFun(content);

    let cnt = 0;
    Modal.confirm({
      title,
      content: splitedContent,
      centered,
      onOk: async () => {
        if (cnt > 0) return;
        cnt++;
        try {
          await onOk();
          if (path) native(path);
        } catch (error) {
          console.error('Error : ', error);
        }
      },
      zIndex: 9999,
      okText: okText ?? '확인',
      cancelText: cancelText ?? '취소',
      onCancel,
    });
  };

  const customSuccessModal = (
    onOk: () => Promise<void> | void,
    title?: string,
    content?: string,
    centered?: boolean,
  ) => {
    const splitedContent = splitedContentFun(content);
    Modal.success({
      title,
      content: splitedContent,
      centered,
      zIndex: 9999,
      okText: '확인',
      onOk: () => {
        if (onOk) onOk(); // 콜백 함수 실행
      },
    });
  };

  const customWarningModal = (
    onOk: () => Promise<void> | void,
    title?: string,
    content?: string,
    centered?: boolean,
  ) => {
    const splitedContent = splitedContentFun(content);
    Modal.warning({
      title,
      content: splitedContent,
      centered,
      zIndex: 9999,
      okText: '확인',
      onOk: () => {
        if (onOk) onOk(); // 콜백 함수 실행
      },
    });
  };

  return {
    successModal,
    warningModal,
    confirmModal,
    customSuccessModal,
    customWarningModal,
  };
};
