import styled from 'styled-components';

export const SignUpContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 4rem;
  }
`;

export const SignUpFormWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 4.3vw 0;
  position: relative;
  flex-shrink: 0;
  padding: 0 5%;
  box-sizing: border-box;

  /* 서울이동통신 회원가입 */
  &::before {
    content: '서울이동통신 회원가입';
    display: block;
    color: ${(props) => props.theme.colors.txt500};
    text-align: center;
    font-size: 3rem;
    font-weight: 582;
    line-height: normal;
    margin: 0 0 2rem;
  }
`;

export const SignUpTitle = styled.h3`
  color: ${(props) => props.theme.colors.main};
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SignUpSubTitle = styled.p`
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SignUpResultWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 5.5rem 0 2rem 0;
`;
export const SignUpResultId = styled.div``;
export const SignUpResult015 = styled.div``;
export const SignUpResultSecretary = styled.div``;
export const SignUpMessageAgree = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.txt400};
  font-size: 1rem;
  margin-bottom: 13rem;
`;
export const ArreoContentWrap = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
export const ArreoContentCard = styled.div`
  width: 17%;
  padding: 1.4rem 2rem;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.gray200};
  box-shadow: 0px 7px 7px 1.53px ${(props) => props.theme.colors.gray400};
  margin-bottom: 7rem;
`;
export const ArreoContentCardTitle = styled.div`
  color: ${(props) => props.theme.colors.main};
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 3rem;
`;
export const ArreoContentCardText = styled.div`
  color: ${(props) => props.theme.colors.txt500};
  text-align: center;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
