import React from 'react';
import * as S from '@/features/ars/arsScenario/ui/ArsScenario.styles';
import Logo from '@/components/Atom/Logo';
import ArsWidgetListComponent from '@/features/ars/arsScenario/components/arsWidgetList/ArsWidgetListComponent';
import ArsScenarioListComponent from '@/features/ars/arsScenario/components/arsScenarioList/ArsScenarioListComponent.index';

const ArsScenarioFeature = () => {
  return (
    <S.Wrapper className="arsScenario">
      {/* 로고 */}
      <Logo eType="Home" sType="B" />

      <div className="scrollWrap">
        {/* TODO - 시나리오 info 수정 하는 곳 */}
        <ArsScenarioListComponent />
        {/* ARS 위젯 설명서 */}
        <ArsWidgetListComponent />
      </div>
    </S.Wrapper>
  );
};

export default ArsScenarioFeature;
