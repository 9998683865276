import React from 'react';
import * as S from './MobileEtcTaxCashReceiptsDetail.style';
import TaxInvoiceList from '@/components/Organism/Charge/TaxInvoiceList';
import { useTaxInvoiceList } from '@/components/hooks/customs/charge/useTaxInvoiceList';
import ATitle from '@/components/Atom/ATitle';
import BaseTable from '@/components/Organism/Table';
import Loader from '@/components/common/Loader';
import Paginations01Index from '@/components/common/paginations/Pagination02/Pagination02.index';

const MobileEtcTaxCashReceiptsDetail = () => {
  const {
    totalPage,
    handlePageChange,
    activePage,
    setActivePage,
    startPage,
    setStartPage,
    currentPage,
    taxInvoiceData,
    thead,
    isSuccess,
    isLoading,
  } = useTaxInvoiceList();

  return (
    <S.TaxCashReceiptsHistoryWrap className="TaxCashReceiptsHistoryWrap">
      <ATitle type="sub" text="현금영수증/세금계산서 발행 신청 내역" />
      <div style={{ minHeight: '10rem' }}>
        <BaseTable type="line" thead={thead} tbody={taxInvoiceData} onclick={(e: any) => {}} />
        {isLoading && <Loader backgroundToggle />}
      </div>
      <S.TableFoot>
        <Paginations01Index
          dataCount={totalPage}
          startPage={startPage}
          setStartPage={setStartPage}
          activePage={activePage}
          setActivePage={setActivePage}
          eventHook={handlePageChange}
          pageSize={10}
        />
      </S.TableFoot>
    </S.TaxCashReceiptsHistoryWrap>
  );
};
export default MobileEtcTaxCashReceiptsDetail;
