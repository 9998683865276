/**
 * @title file 확장자 관리하기 위한 파일
 */

/* 015 -> 015 mo, mt 가능한 file Type */
export const acceptedFile015Types = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.svg',
  '.webp',
  '.mp3',
  '.wav',
  '.m4a',
  '.ogg',
  '.mp4',
  '.mov',
  '.avi',
  '.mkv',
  '.pdf',
  '.doc',
  '.docx',
  '.odt',
  '.rtf',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.ods',
  '.odp',
  '.hwp',
  '.zip',
  '.rar',
  '.7z',
  '.gz',
  '.tar',
  '.txt',
  '.json',
  '.xml',
  '.csv',
  '.yaml',
  '.yml',
  '.md',
];

/* 015 -> 010 mt 가능한 file Type */
export const acceptedFile010Types = ['.jpeg', '.jpg', '.png', '.gif', '.amr', '.mp3', '.wav', '.txt'];
