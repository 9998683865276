import React, { useEffect, useRef } from 'react';
import * as S from './AddGroup.styles';
import BaseButton from '@/components/Atom/BaseButton';
import { useAddGroup } from '@/components/hooks/customs/addressBook/useAddGroup';

interface IAddGroup {
  addGroupOpenHandle: React.MouseEventHandler<HTMLButtonElement>;
  addGroupOpen: boolean;
}

const AddGroup = ({ addGroupOpenHandle, addGroupOpen }: IAddGroup) => {
  const { addGroupSubmitHandle, addGroupValueHandle, addGroupValue, addGroupState, onClickAddGroupBtn } = useAddGroup();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (addGroupOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [addGroupOpen]);

  return (
    <S.AddGroupComponent onSubmit={addGroupSubmitHandle}>
      <div className="addGroup">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path
            d="M16 8.63452C16 8.80028 15.9342 8.95925 15.8169 9.07646C15.6997 9.19367 15.5408 9.25952 15.375 9.25952H9.125V15.5095C9.125 15.6753 9.05915 15.8343 8.94194 15.9515C8.82473 16.0687 8.66576 16.1345 8.5 16.1345C8.33424 16.1345 8.17527 16.0687 8.05806 15.9515C7.94085 15.8343 7.875 15.6753 7.875 15.5095V9.25952H1.625C1.45924 9.25952 1.30027 9.19367 1.18306 9.07646C1.06585 8.95925 1 8.80028 1 8.63452C1 8.46876 1.06585 8.30979 1.18306 8.19258C1.30027 8.07537 1.45924 8.00952 1.625 8.00952H7.875V1.75952C7.875 1.59376 7.94085 1.43479 8.05806 1.31758C8.17527 1.20037 8.33424 1.13452 8.5 1.13452C8.66576 1.13452 8.82473 1.20037 8.94194 1.31758C9.05915 1.43479 9.125 1.59376 9.125 1.75952V8.00952H15.375C15.5408 8.00952 15.6997 8.07537 15.8169 8.19258C15.9342 8.30979 16 8.46876 16 8.63452Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.5"
          />
        </svg>
        <input
          type="text"
          placeholder="그룹 추가"
          onChange={(event) => addGroupValueHandle(event)}
          onKeyUp={(event) => addGroupValueHandle(event)}
          value={addGroupValue}
          ref={inputRef}
        />
      </div>
      <div className="btnGroup">
        <button type="button" className="btn delBtn" onClick={(e) => addGroupOpenHandle(e)}>
          취소
        </button>
        <button
          type="submit"
          className="btn addBtn"
          disabled={addGroupState}
          onClick={() => {
            onClickAddGroupBtn();
          }}
        >
          저장
        </button>
      </div>
    </S.AddGroupComponent>
  );
};

export default AddGroup;
