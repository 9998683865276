import React from 'react';
import * as S from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/ChatRoom015Head.styled';
import ChatRoom015HeadReg from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/ChatRoom015HeadReg';
import ChatRoom015HeadNotReg from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/ChatRoom015HeadNotReg';
import { useRecoilState } from 'recoil';
import { chatUserInfoRecoil } from '@/recoil/atoms/chatRoom';
import { useEachChatRoomHook } from '@/components/hooks/customs/useEachChatRoomHook';

const EachChatRoom015Head = () => {
  const [chatUserInfo] = useRecoilState(chatUserInfoRecoil);

  const hooks = useEachChatRoomHook();

  return (
    <S.EtcChattingHead>
      {chatUserInfo.buddyName && chatUserInfo.phoneNumber ? (
        // 주소록 등록
        <ChatRoom015HeadReg chatUserInfo={chatUserInfo} clickEachListH={hooks.clickEachListH} />
      ) : (
        // 주소록 미등록
        <ChatRoom015HeadNotReg chatUserInfo={chatUserInfo} clickEachListH={hooks.clickEachListH} />
      )}
    </S.EtcChattingHead>
  );
};

export default EachChatRoom015Head;
