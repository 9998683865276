// ******************************************
// * MUTATION POST APIS
// ******************************************

import { client, instance } from '@/shared/lib/clientAxios';
import { IUseMutationDeleteMtMessage, IUseMutationPurchasePass, IUseMutationResendMtMessage } from './apiTypes';

/**
 * @title 015 채팅방 전송 실패한 메세지 재전송 POST API
 *
 * @author 정휘학
 * @since 2024.04.18
 * */
export const useMutationResendMtMessage = async ({ messageId }: IUseMutationResendMtMessage) => {
  const response = await instance.post('/say015/resendMtMessage', messageId);
  return response.data;
};

/**
 * @title 015 채팅방 전송 실패한 메세지 삭제 POST API
 *
 * @author 정휘학
 * @since 2024.04.18
 * */
export const useMutationDeleteMtMessage = async ({ messageId }: IUseMutationDeleteMtMessage) => {
  const response = await instance.post('/say015/deleteMtMessage', { messageId });
  return response.data;
};

/**
 * @title 015 이용권 캐시로 결제 POST API
 *
 * @author 정휘학
 * @since 2024.05.20
 * */
export const useMutationPurchasePass = async (data: IUseMutationPurchasePass) => {
  const response = await instance.post('/purchase/pass', data);
  return response.data;
};

/**
 * @title 로그인 POST API
 *
 * @author 정휘학
 * @since  2024.05.21
 * */
export const useMutationLogin = async (data: any) => {
  const response = await client.post('/signin/say_arreo', data);
  return response.data;
};

/**
 * @title 주소록 리스트 삭제 POST API
 *
 * @author 정휘학
 * @since 2024.06.05
 * */
export const useMutationDeleteList = async (checkedListArr: any) => {
  const response = await instance.post('contacts/delete', checkedListArr);
  return response.data;
};
