import styled from 'styled-components';

export const SearchWrap = styled.div`
  width: calc(100% - 48rem);
  max-width: 447px;
  min-width: 20rem;
  @media screen and (max-width: 1080px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: calc(100% - 50px);
    min-width: unset;
  }
`;

export const SearchWrapper = styled.div`
  button {
    cursor: pointer;
  }
`;
