// 클라이언트 환경 라우터
export const detectRouter = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('mobile')) {
    return 'app';
  }
  return 'pc';
};

// 클라이언트 환경 확인하는 util 페이지
//  주소록 동기화시 처럼 *********************************
//  모바일과 앱을 구분 해야할 때 쓰이는 유틸
export const detectEnvUtils = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('mobile')) {
    if (userAgent.includes('webview')) {
      return 'app';
    }
    return 'mobile';
  }
  return 'pc';
};
