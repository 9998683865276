import { useModalHook } from '@/components/commons/modals/useModalHook';
import { client } from '@/shared/lib/clientAxios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 218px);

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    h1 {
      font-size: 3rem;
      white-space: nowrap; /* 텍스트를 한 줄로 유지 */
      @media screen and (max-width: 768px) {
        font-size: 1.6rem;
      }
    }

    button {
      cursor: pointer;
      color: #fff;
      font-size: 2rem;
      border-radius: 5px;
      background: ${(props) => props.theme.colors.main};
      padding: 2rem 3rem;
      display: block;
      white-space: nowrap; /* 텍스트를 한 줄로 유지 */
      @media screen and (max-width: 768px) {
        font-size: 1.4rem;
      }
    }

    .btnWrap {
      display: flex;
      gap: 2rem;
    }
  }
  img {
    margin: 0 auto;
    width: 80%;
    max-width: 30rem;
  }
`;

const AppDownloadComponent = () => {
  const navigate = useNavigate();

  const { warningModal } = useModalHook();

  // 앱 다운로드 함수
  // const handleAppDownload = async () => {
  //   try {
  //     const response = await client.get('/download/apk', {
  //       responseType: 'blob', // 응답을 Blob 형식으로 설정
  //     });

  //     // 성공적으로 다운로드 요청을 보냈을 때 추가 작업
  //     if (response.status === 200) {
  //       const url = window.URL.createObjectURL(new Blob([response.data])); // Blob URL 생성
  //       const link = document.createElement('a'); // 다운로드를 위한 링크 생성
  //       link.href = url; // Blob URL 설정
  //       link.setAttribute('download', '015talk.apk'); // 파일 이름 설정
  //       document.body.appendChild(link);
  //       link.click(); // 링크 클릭하여 다운로드 시작
  //       link.remove(); // 링크 요소 제거
  //     } else {
  //       alert('다운로드 요청에 문제가 발생했습니다.');
  //     }
  //   } catch (error) {
  //     console.error('다운로드 요청 실패:', error);
  //     alert('다운로드에 실패했습니다. 다시 시도해 주세요.');
  //   }
  // };

  // 페이지 진입시 앱 다운로드
  useEffect(() => {
    // handleAppDownload();
    if (typeof window !== 'undefined' && window) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.app015.talk';
    } else {
      warningModal(
        '실패',
        '이 환경에서는 해당 페이지로 이동할 수 없습니다. 구글 플레이스토어에서 "015 톡"을 검색하세요.',
        true,
      );
    }
  }, []);

  // 페이지 닫기, 뒤로가기 함수
  const handleClose = () => {
    if (window.opener) {
      window.close();
    } else {
      navigate(-1);
    }
  };

  // 닫기 버튼 함수
  const onClickCloseBtn = () => {
    handleClose();
  };

  // 수동 다운로드 함수
  const onClickDownloadBtn = () => {
    // handleAppDownload();
    window.location.href = 'https://play.google.com/store/apps/details?id=com.app015.talk';
  };

  return (
    <Wrap>
      <div>
        {/* <img src="/img/logo.png" alt="logoImage`" /> */}
        <h1>015톡 앱 다운로드 (안드로이드)</h1>
        <div className="btnWrap">
          <button onClick={() => onClickDownloadBtn()}>앱 다운로드</button>
          {/* <button style={{ backgroundColor: 'gray' }} onClick={() => onClickCloseBtn()}>
            돌아가기
          </button> */}
        </div>
      </div>
    </Wrap>
  );
};
export default AppDownloadComponent;
