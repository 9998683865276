import styled from 'styled-components';

export const MessageAddContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const MessageAddSearchContainer = styled.div`
  z-index: 2;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  padding: 0 1.6rem;
  background: ${(props) => props.theme.colors.white};
`;

export const MessageAddListContainer = styled.div`
  width: 100%;
  .mobileBuddyListAdd {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    background: ${(props) => props.theme.colors.gray100};
    transition:
      background-color 0.3s ease,
      box-shadow 0.3s ease;

    &.active {
      background: ${(props) => props.theme.colors.main};
      border-color: ${(props) => props.theme.colors.main};
      box-shadow: 0 0 1rem ${(props) => props.theme.colors.main}33;
    }
  }
`;

export const MessageAddSearchInput = styled.input`
  width: 100%;
  height: 4.4rem;
  padding: 0 1.6rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  background-color: ${(props) => props.theme.colors.gray100};
  ::placeholder {
    color: ${(props) => props.theme.colors.disable};
  }
`;
