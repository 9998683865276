import { instance } from '@/shared/lib/clientAxios';
import { useInfiniteQuery } from '@tanstack/react-query';

interface Buddy {
  buddySeqNo: number;
  buddyNm: string;
  keyCommNo: string;
}

interface PageResponse {
  content: Buddy[];
  totalElements: number;
  totalPages: number;
}

export const useContactsData = (debouncedSearchValue: string) => {
  const fetchContacts = async ({ pageParam = 0, queryKey }: any) => {
    const [, searchData] = queryKey;
    const response = await instance.get<PageResponse>('/soft-phone/contacts/mobile', {
      params: { searchData, page: pageParam, size: 20 },
    });
    return response.data;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: contactsLoading,
  } = useInfiniteQuery(['contacts', debouncedSearchValue], fetchContacts, {
    getNextPageParam: (lastPage, pages) => {
      return pages.length < lastPage.totalPages ? pages.length : undefined;
    },
  });

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (hasNextPage && !isFetchingNextPage && !contactsLoading) {
        fetchNextPage();
      }
    }
  };

  const contactsData = data ? data.pages.flatMap((page) => page.content) : [];

  return {
    contactsData,
    handleScroll,
    contactsLoading,
    isFetchingNextPage,
  };
};
