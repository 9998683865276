import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutationReadMessage } from '@/components/hooks/mutations/getApis';
import { amlQueryParamsRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

/**
 * @title 채팅방 공통 Hook
 *
 * @author 정휘학
 * @since 2024.04.08
 * */
export const useChatRoom015Comm = () => {
  const queryClient = useQueryClient();
  const [mhQueryParamsS] = useRecoilState(mhQueryParamsRecoil);
  const [amlQueryParamsS] = useRecoilState(amlQueryParamsRecoil);
  const environmentS = useRecoilValue(environmentRecoil);

  /**
   * @title useInfinityQuery content 로 flat 하는 hook
   * */
  const flatContentFromInfinity = (data: any) => {
    return data?.pages?.flatMap((item: any) => item.content.map((contentItem: any) => contentItem));
  };

  /**
   * @title 채팅방 입장시 읽지 않은 수신 메세지 카운트를 0으로 변경하는 API
   * */
  const { mutate: readMessageM } = useMutation(useMutationReadMessage);

  /* 채팅방 메세지 내역 리스트 데이터 표출 순서 가공 Hook ( 날짜 기준 ) */
  const formatReduceMhData = (data: any) => {
    const flatMhData = data?.pages?.flatMap((item: any) => item.content.map((contentItem: any) => contentItem));

    return flatMhData.reduce((acc: any, content: any) => {
      const dateKey = content.messageDttm.substring(0, 8);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(content);

      acc[dateKey].sort((a: any, b: any) => {
        return a.messageDttm.localeCompare(b.messageDttm);
      });

      return acc;
    }, {});
  };

  /* 그룹 채팅방 메세지 내역 리스트 데이터 표출 순서 가공 Hook ( 날짜 기준 ) */
  const formatReduceGMhData = (data: any) => {
    const flatMhData = data?.pages?.flatMap((item: any) =>
      item.messageDto.content.map((contentItem: any) => contentItem),
    );
    return flatMhData.reduce((acc: any, content: any) => {
      const dateKey = content.messageDttm.substring(0, 8);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(content);

      acc[dateKey].sort((a: any, b: any) => {
        return a.messageDttm.localeCompare(b.messageDttm);
      });

      return acc;
    }, {});
  };

  /**
   * @title 소프트폰 활성화 리스트 카운트 0 으로 캐시 데이터 업데이트
   * */
  const softChatListCount = (contactNumber: string, amlQueryParams: any) => {
    if (contactNumber) {
      const a: any = queryClient.getQueryData(['/smt/history/latest/app', amlQueryParams]);
      const b = a?.pages?.map((page: any) => {
        return {
          ...page,
          content: page.content.map((item: any) => {
            if (item.phoneNumber === contactNumber) {
              return {
                ...item,
                rcvMsgCount: 0,
              };
            }
            return item;
          }),
        };
      });
      const newData = { ...a, pages: b };
      queryClient.setQueryData(['/smt/history/latest/app', amlQueryParams], newData);
      readMessageM({ contactNumber }); // 카운트 0 으로 만드는 api
    }
  };

  /**
   * @title 소프트폰 재전송시 리스트 캐시 데이터 업데이트
   * */
  const reSendMsgUpdate = (messageId: string, response: any) => {
    const currentData: any = queryClient.getQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber]);
    if (currentData) {
      const newPages = currentData.pages.map((page: any) => {
        return {
          ...page,
          content: page.content.map((item: any) => {
            if (item.messageId === messageId) {
              return response;
            }
            return item;
          }),
        };
      });
      const updatedData = {
        ...currentData,
        pages: newPages,
      };
      queryClient.setQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber], updatedData);
    }
  };
  /**
   * @title 소프트폰 삭제시 리스트 캐시 데이터 업데이트
   * */
  const deleteMsgUpdate = (messageId: string) => {
    const currentData: any = queryClient.getQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber]);
    if (currentData) {
      const newPages = currentData.pages.map((page: any) => {
        return {
          ...page,
          content: page.content.filter((item: any) => item.messageId !== messageId),
        };
      });
      const updatedData = {
        ...currentData,
        pages: newPages,
      };
      queryClient.setQueryData(['/smt/history/chat', mhQueryParamsS.contactNumber], updatedData);
    }
  };

  /**
   * @title 채팅방에서 메세지 전송시 소프트폰 리스트 캐시 데이터 업데이트 ( 최상단 이동 및 표출 text )
   * */
  interface IUpdateCache015SoftPhoneList {
    // 메세지 아이디
    messageId: string;
    // 제목
    subject: string;
    // 메세지 전송 내용
    sndMsg: string;
    // mo, mt
    direction: string;
    // 메세지 타입
    msgType: 'SMS' | 'MMS' | 'LMS' | 'FILE';
    // 받는 사람의 번호
    contactNumber: string;
    // 전송 상태
    rsltVal: number;
    // 메세지 보낸 시간
    messageDttm: string;
    // 이미지 파일
    imgData: null | string;
    // 음성 파일
    vmsData: null | string;
  }

  /* webSocket 으로 메세지가 온 경우 소프트폰 표출 리스트 캐싱 데이터 업데이트 */
  const updateCache015SoftPhoneListToWebSocket = (webSocket: any, active: boolean) => {
    const { sndMsg, msgType, contactNumber, messageDttm, direction, buddyName } = webSocket;
    // {"messageId":"20240520235057124744","subject":null,"sndMsg":"stomp test message","direction":"mo","msgType":"MMS",
    // "contactNumber":"01029182732","rsltVal":-100,"messageDttm":"20240520235057","imgData":null,"vmsData":null}
    const softPhoneListCacheData: any = queryClient.getQueryData(['/smt/history/latest/app', amlQueryParamsS]);

    if (softPhoneListCacheData) {
      // content 필드로 flatData 생성
      const flatData = flatContentFromInfinity(softPhoneListCacheData);
      // websocket 으로 받은 메세지가 속한 채팅방 찾기
      let itemToMove = flatData.find((item: any) => item.phoneNumber === contactNumber);
      // 위 find 내장함수를 이용해서 못찾을 경우 이는 소프트폰 리스트에 없는 사람이 보낸 mo 메세지
      if (!itemToMove) {
        // 소프트폰 리스트에 없는 새로운 사람에 대한 mo 일 경우
        itemToMove = {
          phoneNumber: contactNumber,
          buddyName: buddyName ? buddyName : null,
          rcvMsgCount: 0,
          sndMsg: '',
          msgDttm: '',
          msgType: '',
          direction: '',
        };
      }
      // 받은 메세지의 채팅방 리스트 제외한 나머지 채팅방
      const filteredData = flatData.filter((item: any) => item.phoneNumber !== contactNumber);
      // 메세지 전송한 채팅방을 0 번째 배열로 이동
      const formatData = [itemToMove].concat(filteredData).map((item: any) => {
        if (item.phoneNumber === contactNumber) {
          let { rcvMsgCount } = item;
          if (active) {
            rcvMsgCount = 0;
          } else if (direction === 'mo') {
            rcvMsgCount += 1;
          }

          return {
            ...item,
            // active - true : 활성화 채팅방, 읽지않은 카운트 0 / false : 비활성화 채팅방, 읽지않은 카운트 + 1
            rcvMsgCount,
            buddyName: buddyName ? buddyName : null,
            sndMsg,
            msgType,
            msgDttm: messageDttm,
            direction,
          };
        }
        return item;
      });
      // 배열을 10개 단위로 쪼겜
      const chunked = chunkArray(formatData, 10);
      // 기존 데이터의 pages 값 업데이트
      const formatQueryCachePages: any = softPhoneListCacheData.pages.map((prevData: any, index: number) => {
        return {
          ...prevData,
          content: chunked[index],
        };
      });
      // 소프트폰 015 개별 채팅방 리스트 캐싱 업데이트
      queryClient.setQueryData(['/smt/history/latest/app', amlQueryParamsS], {
        ...softPhoneListCacheData,
        pages: formatQueryCachePages,
      });
    }
  };

  /* 015 채팅방에서 메세지 전송시 소프트폰 표출되고 있는 리스트의 캐싱 데이터를 업데이트 하는 hook */
  const updateCache015SoftPhoneList = (response: IUpdateCache015SoftPhoneList) => {
    // 메세지 전송한 데이터
    const { sndMsg, msgType, contactNumber, messageDttm } = response;
    // 소프트폰 015 개별 채팅방 리스트 캐싱데이터
    const softPhoneListCacheData: any = queryClient.getQueryData(['/smt/history/latest/app', amlQueryParamsS]);

    if (softPhoneListCacheData) {
      // content 필드로 flatData 생성
      const flatData = flatContentFromInfinity(softPhoneListCacheData);
      // 메세지 전송한 채팅방
      const itemToMove = flatData.find((item: any) => item.phoneNumber === contactNumber);
      // 메세지 전송한 채팅방을 제외한 나머지 채팅방
      const filteredData = flatData.filter((item: any) => item.phoneNumber !== contactNumber);
      // 메세지 전송한 채팅방을 0 번째 배열로 이동
      const formatData = [itemToMove].concat(filteredData).map((item: any) => {
        if (item.phoneNumber === contactNumber) {
          return {
            ...item,
            // rcvMsgCount: active ? 0 : item.rcvMsgCount + 1,
            rcvMsgCount: 0,
            sndMsg,
            msgType,
            msgDttm: messageDttm,
            direction: 'mt',
          };
        }
        return item;
      });
      // 배열을 10개 단위로 쪼갬
      const chunked = chunkArray(formatData, 10);
      // 기존 데이터의 pages 값 업데이트
      const formatQueryCachePages: any = softPhoneListCacheData.pages.map((prevData: any, index: number) => {
        return {
          ...prevData,
          content: chunked[index],
        };
      });
      // 소프트폰 015 개별 채팅방 리스트 캐싱 업데이트
      queryClient.setQueryData(['/smt/history/latest/app', amlQueryParamsS], {
        ...softPhoneListCacheData,
        pages: formatQueryCachePages,
      });
    }
  };

  /**
   * @title 배열을 10개씩 나누는 hook
   * */
  const chunkArray = (array: any[], size: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  return {
    formatReduceMhData,
    softChatListCount,
    reSendMsgUpdate,
    deleteMsgUpdate,
    formatReduceGMhData,
    updateCache015SoftPhoneList,
    flatContentFromInfinity,
    updateCache015SoftPhoneListToWebSocket,
  };
};
