import styled from 'styled-components';

export const SignUpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background: ${(props) => props.theme.colors.gray100}; */
  margin: -2rem 0 0;

  @media screen and (max-height: 750px) {
    position: relative;
    top: 4rem;
  }
`;

export const SignUpFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 72rem;
  padding: 9rem 4rem;
  margin: 10rem 0;
  flex-shrink: 0;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.background};
  border-radius: 1rem;

  /* 서울이동통신 회원가입 */
  &::before {
    content: '회원가입';
    display: block;
    color: ${(props) => props.theme.colors.txt500};
    text-align: center;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 5rem;
  }
  @media screen and (max-width: 520px) {
    margin: 0;
    padding: 6rem 1.6rem;
  }
`;

export const TermsHeading = styled.div`
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.2rem;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 5rem;
  p.gray {
    margin: 1rem 0 0;
    color: ${(props) => props.theme.colors.txt200};
  }
`;
export const TermsSubHeading = styled.span``;

export const TermLine = styled.hr`
  width: 100%;
  margin-bottom: 1.36vw;
`;

export const AgreementWrap = styled.ul`
  margin: 0 0 5rem;
  > li {
    border-top: 1px solid ${(props) => props.theme.colors.gray200};

    :last-child {
      margin: 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }
  > label {
    padding: 2rem 0;
    font-size: 1.8rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.txt500};
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
    gap: 1rem;
    margin: 0;
    &.roundCheck {
      gap: 1rem;
    }
  }
`;

export const AgreementButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    padding: 1.2rem;
    width: 100%;
    max-width: 20rem;
    min-height: 5.2rem;
    font-size: 1.8rem;
    font-weight: 700;
  }
`;
