import React, { useEffect, useState } from 'react';
import * as S from './MultiMessageAdd.style';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  BuddyItem,
  GroupItem,
  mobileBuddyListAddRecoil,
  mobileBuddySeqNoListAddRecoil,
  mobileGroupSeqNoListAddRecoil,
  syncLoadingRecoil,
} from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useNavigate } from 'react-router-dom';
import IconFolder from '@/styles/icons/IconFolder';
import NoData from '@/components/common/NoData';
import MobileLoader from '../../MobileLoader';
import { useContactsData } from '../../mobileHooks/useContactsData';
import { Spin } from 'antd';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { useGroupListData } from '../../mobileHooks/useGroupListData';
import { FaCheck } from 'react-icons/fa';
import IconChatUser from '@/styles/icons/IconChatUser';

const MultiMessageAdd = () => {
  const navigate = useNavigate();
  // 현재 선택된 탭을 나타내는 상태 (초기값: 'folder'로 설정)
  const [activeTab, setActiveTab] = useState('group');
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>(searchValue);

  // 그룹 리스트 검색 훅
  const { groupData, groupLoading } = useGroupListData(debouncedSearchValue);
  // 전체 버디리스트 검색 훅
  const { contactsData, handleScroll, contactsLoading, isFetchingNextPage } = useContactsData(debouncedSearchValue);

  //모바일 대량 전송을 위한 버디 리스트 리코일
  const [mobileBuddySeqNoListAddS, SetMobileBuddySeqNoListAddS] = useRecoilState(mobileBuddySeqNoListAddRecoil);
  //모바일 대량 전송을 위한 그룹 리스트 리코일
  const [mobileGroupSeqNoListAddS, setMobileGroupSeqNoListAddS] = useRecoilState(mobileGroupSeqNoListAddRecoil);

  // 탭 클릭 시 활성화된 탭 상태 변경
  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    setSearchValue('');
  };

  // 디바운스를 위해 searchValue가 변경될 때마다 0.4초 후 debouncedSearchValue를 업데이트
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 400);

    // cleanup: 타이머 초기화
    return () => clearTimeout(handler);
  }, [searchValue]);

  // ******************************************************************************************************************
  //
  // ******************************************************************************************************************

  /**
   *
   * @param type group 인지 contacts인지
   * @param listData  groupSeqNo & buddySeqNo
   */

  const onClickList = (type: string, listData: BuddyItem | GroupItem) => {
    if (type === 'group') {
      const groupData = listData as GroupItem; // 전체 객체를 저장하기 위해 구조 분해 X
      if (groupData.groupSeqNo !== undefined) {
        setMobileGroupSeqNoListAddS(
          (prev) =>
            prev.some((item) => item.groupSeqNo === groupData.groupSeqNo)
              ? prev.filter((item) => item.groupSeqNo !== groupData.groupSeqNo) // 동일 seqNo 제거
              : [...prev, groupData], // 객체 추가
        );
      }
    } else {
      const buddyData = listData as BuddyItem; // 전체 객체를 저장하기 위해 구조 분해 X
      if (buddyData.buddySeqNo !== undefined) {
        SetMobileBuddySeqNoListAddS(
          (prev) =>
            prev.some((item) => item.buddySeqNo === buddyData.buddySeqNo)
              ? prev.filter((item) => item.buddySeqNo !== buddyData.buddySeqNo) // 동일 seqNo 제거
              : [...prev, buddyData], // 객체 추가
        );
      }
    }
  };

  useEffect(() => {
    SetMobileBuddySeqNoListAddS([]);
    setMobileGroupSeqNoListAddS([]);
  }, []);

  return (
    <>
      <MobileLoader isLoading={contactsLoading || groupLoading} />

      <S.MenuContainer>
        <S.MenuTab activeTab={activeTab}>
          <S.TabGroup activeTab={activeTab} onClick={() => handleTabClick('group')}>
            그룹으로 추가
          </S.TabGroup>
          <S.TabContact activeTab={activeTab} onClick={() => handleTabClick('contacts')}>
            주소록으로 추가
          </S.TabContact>
          <S.Slider activeTab={activeTab} />
        </S.MenuTab>

        <S.SearchContainer className="messageAddSearch">
          <S.SearchInput
            type="text"
            placeholder={activeTab === 'group' ? '그룹 이름 검색' : '이름 및 번호 검색'}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </S.SearchContainer>
      </S.MenuContainer>

      {activeTab === 'group' && (
        <S.AddressContainer>
          {groupData && groupData?.groupList.length < 1 && !groupLoading && <NoData />}
          {groupData?.groupList?.map((group) => (
            <S.GroupListContainer
              key={group.groupSeqNo}
              onClick={() => {
                onClickList('group', group);
              }}
            >
              <S.GroupListLeft>
                {mobileGroupSeqNoListAddS.includes(group) ? (
                  <S.GroupImageContainer isChecked>
                    <FaCheck /> {/* 초록색 배경 */}
                  </S.GroupImageContainer>
                ) : (
                  <S.GroupImageContainer>
                    <IconFolder /> {/* 기본 폴더 배경 */}
                  </S.GroupImageContainer>
                )}

                <S.MessageContents>
                  <h3>{group.groupNm}</h3>
                </S.MessageContents>
              </S.GroupListLeft>
              <S.BuddyCount>{group.buddyCount} 명</S.BuddyCount>
            </S.GroupListContainer>
          ))}
        </S.AddressContainer>
      )}

      {activeTab === 'contacts' && (
        <S.AddressContainer onScroll={handleScroll}>
          {contactsData.map((buddy) => (
            <S.GroupListContainer
              key={buddy.buddySeqNo}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => {
                onClickList('contacts', buddy);
              }}
            >
              {mobileBuddySeqNoListAddS.includes(buddy) ? (
                <S.GroupImageContainer isChecked>
                  <FaCheck />
                </S.GroupImageContainer>
              ) : (
                <S.BuddyImg>
                  <IconChatUser />
                </S.BuddyImg>
              )}

              <S.MessageContents className="messageContents">
                <h3>{buddy.buddyNm}</h3>
                <div>{TelHyphen(buddy.keyCommNo)}</div>
              </S.MessageContents>
            </S.GroupListContainer>
          ))}

          {isFetchingNextPage && (
            <div
              style={{
                zIndex: '1000000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.6rem',
                padding: '2rem 0',
              }}
            >
              <Spin size="default" />
              <span style={{ marginLeft: 8 }}>로딩 중...</span>
            </div>
          )}
        </S.AddressContainer>
      )}
    </>
  );
};

export default MultiMessageAdd;
