import { ChangeEvent, useEffect, useState } from 'react';
import { useArsInitData } from '@/components/hooks/customs/say015/say105Handle/useArsInitData';
import { assertMsglen } from '@/shared/util/byteCalculatorUtil';

/**
 * @title 015 Ars 문자 팝업 Hooks
 * */
interface IUseArsSms {
  data: any;
  arsData: any;
  updatedJsonFile?(): void;
}
export const useArsSms = ({ data, arsData, updatedJsonFile }: IUseArsSms) => {
  /* 문자 발송 STATE */
  const [smsTextState, setSmsTextState] = useState<string>(data?.target.level);
  /* 문자 발송 TITLE */
  const [smsTitleState, setSmsTitleState] = useState<string>(data?.s015Data.title);

  const { initSmsNextData } = useArsInitData({ id: data?.id });

  /* 초기 작업 */
  useEffect(() => {
    // if (data?.target.type !== 'BLANK')
    setSmsTextState(data?.target.level);
    setSmsTitleState(data?.s015Data.title);
  }, [data]);

  /* 문자 메세지 STATE 핸들러 Hook */
  const handleSmsText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const maxByte = 1985;
    const text = e.target.value;
    // 메시지의 길이가 2000 바이트를 넘지 않도록 제한
    const truncatedMsg = assertMsglen(text, maxByte);
    setSmsTextState(truncatedMsg);
  };

  /* 문자 카드 Title STATE 핸들러 Hook */
  const handleSmsTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setSmsTitleState(e.target.value);
  };

  /* 마지막으로 인사말 설정 JSON 데이터 형식에 맞게끔 데이터 업데이트  */
  const updatedJSON = (data: any, id: string, value: string, title: string) => {
    if (data.id === id && data.s015Data) {
      data.s015Data.action = '문자발송';
      data.s015Data.type = 'LINK_SMS';
      data.s015Data.title = title;
      data.target.type = 'LINK';
      data.target.level = value;
      data.next = initSmsNextData;
      if (data?.id !== 'root') data.source.userinput = data?.id.slice(-1);
      return true;
    }
    if (data.next && data.next.length > 0) {
      for (let i = 0; i < data.next.length; i++) {
        if (updatedJSON(data.next[i], id, value, title)) {
          return true;
        }
      }
    }
    return false;
  };
  /* 015 ARS 문자 수정 Hook */
  const handleSaveMSG = () => {
    const updatedData = { ...arsData };
    if (updatedJSON(updatedData, data?.id, smsTextState, smsTitleState) && updatedJsonFile) {
      updatedJsonFile();
    }
  };

  /* 015 ARS 문자 취소 Hook */
  const handleCancelMSG = () => {
    if (data?.s015Data.title) {
      setSmsTitleState(data?.s015Data.title);
    } else {
      setSmsTitleState('');
    }
  };

  return {
    handleSaveMSG,
    handleCancelMSG,
    handleSmsText,
    handleSmsTitle,
    smsTextState,
    smsTitleState,
  };
};
