import { atom } from 'recoil';

// ! test
// ? 전체크룹 체크박스 상태 recoil
export const allCheckGroupR = atom<boolean>({
  key: 'allCheckGroupR',
  default: false,
});
// ? 주소록 메인 테이블의 각각의 그룹에 맞게 묶음 처리하고 개별 체크박스에 상태 및 그룹 체크박스 상태를 파악할 수 있게 함
export const checkGroupArrR = atom<
  | {
      groupNm: string;
      groupSeqNo: number;
      isChecked: boolean;
      isChildrenChecked: boolean;
      totalChildren: number;
    }[]
  | null
>({
  key: 'checkGroupArrR',
  default: null,
});
// ? 하위 리스트 체크 true array
export const checkChildrenArrR = atom<{ groupSeqNo: number; buddySeqNo: number }[] | null>({
  key: 'checkChildrenArrR',
  default: null,
});
// ? 하위 리스트 체크 false array
export const unCheckChildrenArrR = atom<{ groupSeqNo: number; buddySeqNo: number }[] | null>({
  key: 'unCheckChildrenArrR',
  default: null,
});

/* 015 채팅 메세지 보낼 수신인 데이터 배열 recoil */
export const rcvMsgGroupPeople015R = atom<
  | {
      groupSeqNo: number;
      groupCheck: boolean;
      groupNm: string;
      buddySeqNo: number[] | null;
      count: number;
      api: boolean;
    }[]
  | null
>({
  key: 'rcvMsgGroupPeople015R',
  default: null,
});

// ? 015 채팅 메세지 보낼 직접 작성한 수신인 데이터 배열 recoil
export const rcvMsgEtcPeople015R = atom<{ buddyNm: string; phoneNumber: string }[] | null>({
  key: 'rcvMsgEtcPeople015R',
  default: null,
});

// !test

/* 메세지 전송할 사람의 buddySeqNo array recoil */
export const messageSendArrayRecoil = atom<number[]>({
  key: 'messageSendArrayRecoil',
  default: [],
});

/* 소프트폰 메세지 전송 수신인에 표출되는 리스트 recoil */
export const messageSendViewArrayRecoil = atom<
  | [
      {
        groupNm: string;
        groupSeqNo: number;
        buddySeqNos: number[];
        totalCount?: number;
      },
    ]
  | null
>({
  key: 'messageSendViewArrayRecoil',
  default: null,
});

/* 소프트폰 메세지 전송 수신인 직접 입력한 번호 담는 recoil */
export const messageSendPhoneNumberRecoil = atom<[{ buddyNm: string; phoneNumber: string }] | null>({
  key: 'messageSendPhoneNumberRecoil',
  default: null,
});
// 메세지 재전송 토글
export const reSendMsgToggleRecoil = atom({
  key: 'reSendMsgToggleRecoil',
  default: false,
});
// 메세지 재전송 주소록 표출 토글
export const addressOpenToggleRecoil = atom<boolean>({
  key: 'addressOpenToggleRecoil',
  default: false,
});

// 메세지 재전송 - 전송 내용
export const reSendMsgRecoil = atom<string>({
  key: 'reSendMsgRecoil',
  default: '',
});
// 메세지 재전송 - 전송 파일
export const reSendFileRecoil = atom<any>({
  key: 'reSendMsgFileRecoil',
  default: null,
});
// 메세지 재전송 - 전송 제목
export const reSendSubjectRecoil = atom<string>({
  key: 'reSendSubjectRecoil',
  default: '',
});

// saveMessage -> sendMessage 로 보내기 위해 리코일로 변환
//메세지 제목
export const sndMsgSubjectRecoil = atom<string>({
  key: 'sndMsgSubjectRecoil',
  default: '',
});

//메세지 내용
export const sndMsgRecoil = atom<string>({
  key: 'sndMsgRecoil',
  default: '',
});

//메세지 URL
export const fileResultRecoil = atom<any>({
  key: 'fileResultRecoil',
  default: null,
});

//메세지
export const fileStateRecoil = atom<File | null>({
  key: 'fileStateRecoil',
  default: null,
});

//광고 상태
export const adStateRecoil = atom<boolean>({
  key: 'adStateRecoil',
  default: false,
});

// 광고 모달 상태 RECOIL
export const modalToggleRecoil = atom<boolean>({
  key: 'modalToggleRecoil',
  default: false,
});
