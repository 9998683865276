import styled from 'styled-components';

export const Wrap = styled.div`
  .txtGroup {
    text-align: center;
    margin: 2rem auto 3.6rem;
    h3 {
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 1.2rem;
      span {
        color: ${(props) => props.theme.colors.main};
      }
    }
    p {
      max-width: 30rem;
      margin: 0 auto;
      font-size: 1.4rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors.txt400};
      line-height: 1.4em;
    }
  }
`;
export const ArsPhone = styled.div`
  height: auto !important;
  padding: 1.2rem 1rem 0 !important;
  margin: -1.2rem -1rem -2rem !important;

  .wrap {
    width: calc(100% - 3rem);
    max-width: 34rem;
    max-height: 70rem;
    /* min-height: 62rem; */
    margin: 0 auto;
    color: ${(props) => props.theme.colors.txt100};
    border: 0.6rem solid #121212;
    border-bottom: 0;
    border-radius: 3rem 3rem 0 0;
    box-shadow:
      0.5rem 0.8rem 2rem 0 rgba(0, 0, 0, 0.2),
      0.5rem 0.8rem 2rem 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;

    .people {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      padding: 6rem 2rem 0 2rem;

      h4 {
        font-size: 3rem;
        margin: 0 auto 7rem;
      }
    }
  }
  .numWrap {
    /* background: linear-gradient(
      180deg,
      ${(props) => props.theme.colors.gray700},
      ${(props) => props.theme.colors.gray800}
    ); */
    background: linear-gradient(0deg, #282828, #414141, #5d5d5d);
  }
  .chatWrap {
    background: ${(props) => props.theme.colors.gray200};
  }
  .numberPad {
    display: grid;
    margin: 0 auto;
    padding: 0 2rem 4rem 2rem;
    width: 80%;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    button {
      font-size: 2.6rem;
      aspect-ratio: 1/ 1;
      border-radius: 50%;
      background: rgba(51, 51, 51, 0.45);
      /* background: ${(props) => props.theme.colors.gray800}; */
      color: ${(props) => props.theme.colors.txt100};
      &.active {
        outline: 2px solid ${(props) => props.theme.colors.main};
      }
      :last-of-type {
        grid-column: 2;
        margin-top: 3rem;
        &.active {
          outline: unset;
        }
      }
    }

    /* 음성 아이콘 조정 */
    .control {
      position: relative;
      background: ${(props) => props.theme.colors.red};
      &.active {
        background: ${(props) => props.theme.colors.green};
        .start {
          display: flex;
        }
        .stop {
          display: none;
        }
      }

      p {
        align-items: center;
        justify-content: center;
      }

      .start {
        display: none;
        svg {
          width: 50%;
          height: 50%;
          max-height: 5rem;
          max-width: 5rem;
        }
      }
      .stop {
        display: flex;
        svg {
          width: 70%;
          height: 60%;
          max-height: 5rem;
          max-width: 5rem;
        }
      }
      :disabled {
        .start {
          display: flex;
        }
        .stop {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 460px) {
    .wrap {
      .people {
        h4 {
          font-size: 6vmin;
          margin: 0 auto 6vmin;
        }
        .control {
          margin: 0 auto 6vmin;
        }
      }
    }
    .numberPad {
      gap: 3vmin;
      button {
        font-size: 5vmin;
      }
    }
  }

  /* 높이 반응형 */
  @media screen and (max-height: 1024px) {
    .wrap {
      .people {
        h4 {
          font-size: 2rem;
          margin: 0 auto 3rem;
        }
      }
    }
    .numberPad {
      gap: 1rem;
      width: 80%;
    }
    .numWrap {
      width: calc(var(--vh, 50vh) - 70vh);
      min-width: 24rem;
    }
  }
`;
