import styled from 'styled-components';

interface MenuTabProps {
  activeTab: string;
}

export const MenuContainer = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
`;

export const MenuTab = styled.section<MenuTabProps>`
  width: 100%;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const TabGroup = styled.div<MenuTabProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%; /* 각 탭이 50% 차지하도록 설정 */
  height: 100%;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  color: ${(props) => (props.activeTab === 'group' ? props.theme.colors.active : props.theme.colors.txt500)};
`;

export const TabContact = styled.div<MenuTabProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%; /* 각 탭이 50% 차지하도록 설정 */
  height: 100%;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  color: ${(props) => (props.activeTab === 'contacts' ? props.theme.colors.active : props.theme.colors.txt500)};
`;

export const Slider = styled.span<MenuTabProps>`
  position: absolute;
  bottom: 0;
  left: ${({ activeTab }) => (activeTab === 'group' ? '0' : '50%')}; /* 슬라이더 위치 조정 */
  width: 50%; /* 슬라이더의 너비 */
  height: 0.2rem;
  background-color: ${(props) => props.theme.colors.active};
  transition: left 0.3s ease; /* 애니메이션 효과 */
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 1.4rem;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 1rem 1.4rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  background-color: ${(props) => props.theme.colors.gray100};
  ::placeholder {
    color: ${(props) => props.theme.colors.disable};
  }
`;

export const AddressContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
`;

export const GroupListContainer = styled.div`
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.4rem;
  gap: 1rem;

  transition: background-color 0.4s ease-in-out; // 듀레이션 0.4초 추가
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  cursor: pointer;

  > div {
    display: flex;
    gap: 1rem;
  }
`;
export const GroupListLeft = styled.div`
  display: flex;
  align-items: center;
  > h3 {
    display: inline-block;
    max-width: 12ch; /* 최대 7글자로 나오게 ... 포함 */
    white-space: nowrap;
    overflow: hidden; /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;
    color: ${(props) => props.theme.colors.txt500};
    font-weight: 700;
    font-size: 1.4rem;
  }
`;

interface GroupImageContainerProps {
  isChecked?: boolean; // 선택 여부를 나타내는 속성 (optional)
}

export const GroupImageContainer = styled.div<GroupImageContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  background-color: ${(props) =>
    props.isChecked ? props.theme.colors.buttonActiveBg : props.theme.colors.chatIconBack}; /* 초록색 배경 추가 */
  border-radius: 2rem;
  color: ${(props) => (props.isChecked ? props.theme.colors.white : props.theme.colors.txt100)}; /* 체크 시 흰색으로 */
  font-size: 1.6rem; /* 아이콘 크기 조금 더 키움 */
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
  transition:
    background-color 0.3s ease,
    transform 0.3s ease; /* 부드러운 전환 효과 */
  overflow: hidden;
`;

export const BuddyImg = styled.div<GroupImageContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  background-color: ${(props) =>
    props.isChecked ? props.theme.colors.buttonActiveBg : props.theme.colors.chatIconBack}; /* 초록색 배경 추가 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  font-size: 1.6rem; /* 아이콘 크기 조금 더 키움 */
  font-weight: 900;
  overflow: hidden;
  color: ${(props) => (props.isChecked ? props.theme.colors.white : props.theme.colors.txt100)}; /* 체크 시 흰색으로 */
  transition:
    background-color 0.3s ease,
    transform 0.3s ease; /* 부드러운 전환 효과 */
  overflow: hidden;
  svg {
    font-size: 3.4rem;
    margin: 1.2rem 0 0;
  }
`;

export const BuddyCount = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.txt400};
  white-space: nowrap;
`;

export const MessageAddContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const MessageAddSearchContainer = styled.div`
  z-index: 2;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  padding: 0 1.6rem;
  background: ${(props) => props.theme.colors.white};
`;

export const MessageAddListContainer = styled.div`
  width: 100%;
  .mobileBuddyListAdd {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    background: ${(props) => props.theme.colors.gray100};
    transition:
      background-color 0.3s ease,
      box-shadow 0.3s ease;

    &.active {
      background: ${(props) => props.theme.colors.main};
      border-color: ${(props) => props.theme.colors.main};
      box-shadow: 0 0 1rem ${(props) => props.theme.colors.main}33;
    }
  }
`;

export const MessageAddSearchInput = styled.input`
  width: 100%;
  height: 4.4rem;
  padding: 0 1.6rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  background-color: ${(props) => props.theme.colors.gray100};
  ::placeholder {
    color: ${(props) => props.theme.colors.disable};
  }
`;

//***************************************************************************************** */

export const MessageContents = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex: 1;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  font-weight: 400;
  h3 {
    display: inline-block;
    max-width: 16rem; /* 최대 너비를 40px로 설정 */
    white-space: nowrap;
    color: ${(props) => props.theme.colors.txt500};
    text-overflow: ellipsis; /* 말줄임표 효과 */
    font-weight: 700;
    overflow: hidden; /* 넘치는 텍스트 숨김 */
    @media screen and (max-width: 380px) {
      max-width: 10rem;
    }
  }
  > div {
    color: ${(props) => props.theme.colors.txt300};
  }

  @media screen and (max-width: 380px) {
    font-size: 1.2rem;
  }
`;

export const MessageContentsTop = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const MessageContentsBottom = styled.div`
  color: ${(props) => props.theme.colors.txt300};

  .textContent {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: normal;
      word-break: break-all;
      overflow-wrap: break-word;
      white-space: normal;
      word-wrap: break-word;
    }
  }
`;

export const MessageInfo = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  font-size: 1.2rem;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.txt300};

  > span {
    background-color: ${(props) => props.theme.colors.notify};
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    color: ${(props) => props.theme.colors.txt100};
  }
`;
