import styled from 'styled-components';

export const Management = styled.div``;

export const Wrapper = styled.div`
  h2 {
    margin-bottom: 30px;
  }
`;

export const Payment = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  max-width: 675px;
  margin: 0 auto;
  padding: 1.2rem;
  background: ${(props) => props.theme.colors.gray100};
  margin-bottom: 4rem;
  border-radius: 1rem;
  button {
    cursor: pointer;
    width: 25%;
    padding: 12px;
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.txt300};
    transition: all 0.2s ease-in;
  }
  button:hover {
    border-radius: 6px;
    background: ${(props) => props.theme.colors.txt500};
    color: ${(props) => props.theme.colors.txt100};
  }
  button.active {
    border-radius: 6px;
    background: ${(props) => props.theme.colors.txt500};
    color: ${(props) => props.theme.colors.txt100};
  }
  @media screen and (max-width: 760px) {
    flex-wrap: wrap;
    gap: 1rem;
    button {
      width: calc(50% - 0.5rem);
    }
  }
  @media screen and (max-width: 540px) {
    button {
      width: 100%;
    }
  }
`;

export const DateGroup = styled.ol`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 1.2rem;
  margin-bottom: 6rem;
  > li {
    border: 2px solid transparent;
    padding: 3rem;
    border-radius: 10px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    /* 월간 연간 마우스 오버시 */
    background: ${(props) => props.theme.colors.gray100};
    &.hov {
      background: ${(props) => props.theme.colors.gray100};
    }
    &.hov:hover {
      /* cursor: pointer; */
      transition: all 0.5s;
      border-color: ${(props) => props.theme.colors.main};
      .middle {
        button {
          cursor: pointer;
          transition: all 0.2s;
          color: ${(props) => props.theme.colors.buttonText};
          background: ${(props) => props.theme.colors.main};
        }
      }
      .bottom {
        svg {
          path {
            stroke: #687ec2;
          }
        }
        .box.blue {
          color: ${(props) => props.theme.colors.main};
          svg {
            path {
              stroke: ${(props) => props.theme.colors.main};
            }
          }
        }
      }
    }

    .top {
      margin-bottom: 3rem;
      h4 {
        font-size: 2rem;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.4rem;
        color: #999;
      }
    }
    .middle {
      margin-bottom: 5rem;
      > h5 {
        font-size: 3rem;
        margin-bottom: 1rem;
        span {
          font-size: 0.5em;
        }
      }
      > p {
        font-size: 1rem;
        margin-bottom: 3rem;
        color: #999;
      }
      button {
        width: 100%;
        height: 4.8rem;
        font-size: 1.6rem;
        padding: 10px;
        border-radius: 1rem;
        color: ${(props) => props.theme.colors.buttonText};
        background: ${(props) => props.theme.colors.deactivation};
      }
      /* 입금자명 Error */
      .errorRed {
        margin-top: 0.5rem;
        text-align: right;
        color: red;
      }
      .depositName {
        display: block;
        margin-bottom: 2rem;
        p {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }
        input {
          font-size: 1.4rem;
          width: 100%;
          height: 3.6rem;
          border: 0;
          background: #f0f1f5;
          padding: 1rem;
          border-radius: 5px;
        }
      }
    }
    .bottom {
      .box {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.3rem;
        font-weight: 500;
        color: #676767;
        margin-bottom: 1rem;
        svg {
          width: 1.8rem;
        }
      }
    }

    /* 음성사서함 문자사서함 요금안내 */
    &.payMenu {
      table {
        width: 100%;
        font-size: 1.4rem;
        margin-bottom: 2rem;
        :last-of-type {
          margin-bottom: 0;
        }
        th,
        td {
          padding: 1.2rem 1rem;
        }
        th {
          width: 40%;
          color: ${(props) => props.theme.colors.txt500};
          font-weight: 600;
        }
        td {
          width: 60%;
          color: ${(props) => props.theme.colors.txt300};
          font-weight: 500;
        }

        thead {
          th {
            font-size: 1.3rem;
            color: ${(props) => props.theme.colors.main};
            font-weight: 800;
          }
        }
        tbody {
        }
      }
      .top {
        margin-bottom: 1.4rem;
      }
      .row {
        display: block;
        width: 100%;
        height: 1px;
        background: #98999a;
        margin-bottom: 1.4rem;
      }
      .bottom {
        th {
          font-size: 1.8rem;
          color: ${(props) => props.theme.colors.txt500};
          font-size: 600;
        }
        td {
          position: relative;
          .sale {
            position: absolute;
            top: calc(50% - 6px);
            left: -76px;
            transform: translateY(-50%);
            display: flex;
            font-size: 1.2rem;

            .blue {
              margin-right: 5px;
              font-size: 1rem;
              font-weight: 600;
              color: ${(props) => props.theme.colors.main};
            }
            svg {
              position: absolute;
              top: calc(50% + 0.5rem);
              right: -20px;
              transform: translateY(-50%);
            }
          }
          h5 {
            font-size: 2rem;
            color: ${(props) => props.theme.colors.txt500};
            font-weight: 700;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1080px) {
    flex-wrap: wrap;
    gap: unset;
    justify-content: space-between;
    > li {
      width: 100%;
      &.hov {
        width: 49%;
        margin-bottom: 1.2rem;
      }
    }
  }
  @media screen and (max-width: 540px) {
    > li {
      padding: 2rem;
      tbody {
        th {
          border-top: 2px solid ${(props) => props.theme.colors.gray400};
          background: ${(props) => props.theme.colors.gray100};
        }
        /* tr:last-of-type {
          td {
            border-bottom: 1px solid #c9c9c9;
          }
        } */
      }
      .middle {
        .depositName {
          input {
            font-size: 1.2rem;
          }
        }
      }
      &.hov {
        width: 100%;
        .middle {
          button {
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &.payMenu {
        table {
          tr {
            display: flex;
            flex-direction: column;
            :last-of-type {
              border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
            }
            th,
            td {
              padding: 1rem 0.6rem;
              width: 100%;
            }
          }
        }
        .bottom {
          tr {
            td {
              .sale {
                left: calc(100% - 21rem);
                svg {
                  max-width: 90%;
                  right: -30%;
                }
              }
              text-align: right;
            }
          }
        }
      }
    }
  }
`;

export const Guide = styled.ul`
  > li {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt300};
    font-weight: 500;
    margin-bottom: 2rem;
  }
  > p {
    margin-top: 3rem;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.main};
    line-height: 1.8em;
  }
`;
