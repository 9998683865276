import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Spin } from 'antd';

interface LoaderWrapperProps {
  contents: boolean;
}

const LoaderWrapper = styled.div<LoaderWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(contents) => (contents ? 'rgba(255,255,255,.5)' : 'transparent')};
  z-index: 1000;
`;

const Contents = styled.h3`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 1.8rem;
`;

interface MobileLoaderProps {
  isLoading: boolean;
  contents?: string;
}

const MobileLoader: React.FC<MobileLoaderProps> = ({ isLoading, contents }) => {
  const [dots, setDots] = useState<string>(''); // dots 상태 관리

  useEffect(() => {
    if (!isLoading) {
      return () => {}; // Ensure it always returns a cleanup function, even if `isLoading` is false
    }

    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length < 3) {
          return `${prevDots}.`;
        }
        return '';
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isLoading]);

  // Ensure that it returns a consistent JSX structure
  if (!isLoading) return null; // isLoading이 false일 때 null을 반환하여 경고 해결

  return (
    <LoaderWrapper contents={contents ? true : false}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '1.6rem',
          padding: '2rem 0',
        }}
      >
        <Spin size="default" />
        {isLoading && contents ? (
          <Contents>
            {contents}
            {dots}
          </Contents>
        ) : (
          <span style={{ marginLeft: 8 }}>로딩 중...</span>
        )}
      </div>
    </LoaderWrapper>
  );
};

export default MobileLoader;
