import styled from 'styled-components';

// TS 스타일 컴포넌트로 추천 import * as TS from '@/styles/table.styles';

type TableBodyType = { tdWidthData: number };

// 테이블 반응형 스크롤 세팅을 위함 (테이블을 깜싸는 포멧)
export const MyPageTableWrap = styled.div`
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
`;

export const TableRowWrap = styled.div`
  overflow-y: auto;
  thead {
    position: sticky;
    top: 0;
  }
`;

export const TableColWrap = styled.div`
  overflow-x: auto;
`;

// 테이블을 감싸거나 분류용 - 타이틀이 있음
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 0 4rem;
  input[type='text'] {
    border-color: ${(props) => props.theme.colors.gray500};
  }
  @media screen and (max-width: 768px) {
    gap: 1.6rem;
  }
`;

// ----------------------------------------------------------------

// 기본적인 반응형이있는 테이블 스타일
export const MyPageTable = styled.table`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.gray400};
  font-size: 1.4rem;
  line-height: normal;

  .on {
    color: ${(props) => props.theme.colors.main};
  }
  .off {
    color: ${(props) => props.theme.colors.deactivation};
  }

  .flex {
    display: flex;
  }
  /* border 겹침현상 제거 */
  &,
  th,
  td {
    border-collapse: collapse;
    border-spacing: 0;
    padding: 1rem;
    vertical-align: middle;
  }
  input[type='checkBox'] {
    width: 1.5rem;
    height: 1.5rem;
  }

  /* thead 기본세팅*/
  thead {
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.txt500};
  }
  /* tbody 기본세팅*/
  tbody {
    color: ${(props) => props.theme.colors.txt500};
    td,
    th {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }
    /* tbody 첫번째 강조를 위함 */
    th {
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }

  /* ------------------- 테이블에 사이즈 조정 css -------------------  */
  /* 테이블 너비 20% 조정 */
  &.tw20 {
    tbody {
      th {
        width: 20%;
      }
    }
  }
  &.tw30 {
    tbody {
      th {
        width: 30%;
      }
    }
  }

  /* ------------------- 특정 스타일 css -------------------  */
  /* 테이블 border로 감싸있는 스타일 */
  &.borderStyles {
    th,
    td {
      border: 1px solid ${(props) => props.theme.colors.gray200};
      font-size: 1.4rem;
    }
    thead {
      text-align: center;
      font-weight: 700;
    }
    tbody {
      th {
        text-align: center;
        background: transparent;
      }
    }
  }

  /* th에 background 색 추가 */
  &.thBackStyles {
    th {
      background: ${(props) => props.theme.colors.gray100};
    }
  }
  &.thBorderLeft {
    th {
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }

  /* ------------------- border styles -------------------  */
  &.borTop0 {
    border-top: 0;
  }
  &.borBottom0 {
    border-bottom: 0;
  }
  &.borLeft0 {
    border-left: 0;
  }
  &.borRight0 {
    border-right: 0;
  }

  /* ------------------- 테이블 반응형 -------------------  */
  @media screen and (max-width: 1080px) {
    &.borTop0 {
      border-top: 2px solid ${(props) => props.theme.colors.gray400};
    }
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    border-top: 0;
    input {
      width: 100%;
    }
    tr {
      display: flex;
      flex-direction: column;
    }
    td,
    th {
      border: 0;
      text-align: left;
      &.flexBox {
        justify-content: center;
      }
    }
    tbody {
      tr {
        margin: 0 0 1.6rem;
        border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
        &:has(input) {
          border-bottom: 0;
        }
        &:last-of-type {
          margin: 0;
        }
      }
      td,
      th {
        border-right: 0;
        border-bottom: 0;
        padding: 1rem;
      }
      td {
        input {
          border-bottom: 1px solid ${(props) => props.theme.colors.gray400};
        }
      }
    }
    &.flexNon {
      tr {
        display: table-row;
        flex-direction: unset;
      }
    }

    &.borBottom0 {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }
    &.borLeft0 {
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
    }
    &.borRight0 {
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
    }

    &.tw20 {
      tbody {
        th {
          width: 100%;
        }
      }
    }
    &.tw30 {
      tbody {
        th {
          width: 100%;
        }
      }
    }
  }
`;

// 스크롤 가로 - 테이블 스타일
export const MyPageTableCol = styled.table`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.gray400};
  font-size: 1.4rem;
  line-height: normal;
  text-align: center;

  .flex {
    display: flex;
  }
  /* border 겹침현상 제거 */
  &,
  th,
  td {
    border-collapse: collapse;
    border-spacing: 0;
    padding: 1rem;
    vertical-align: middle;
    flex: none;
    white-space: nowrap;
  }
  tr {
    display: table-row;
  }
  /* thead 기본세팅*/
  thead {
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.txt500};
  }
  /* tbody 기본세팅*/
  tbody {
    color: ${(props) => props.theme.colors.txt500};
    td,
    th {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }
    /* tbody 첫번째 강조를 위함 */
    th {
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }
`;

// 스크롤 세로 - 테이블 스타일
export const MyPageTableRow = styled.table`
  width: 100%;
  font-size: 1.4rem;
  line-height: normal;
  text-align: center;

  /* border 겹침현상 제거 */
  &,
  th,
  td {
    border-collapse: collapse;
    border-spacing: 0;
    padding: 1rem;
    vertical-align: middle;
    flex: none;
    white-space: nowrap;
  }
  tr {
    display: table-row;
  }
  /* thead 기본세팅*/
  thead {
    z-index: 1;
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.txt500};
  }
  /* tbody 기본세팅*/
  tbody {
    color: ${(props) => props.theme.colors.txt500};
    td,
    th {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    }
    /* tbody 첫번째 강조를 위함 */
    th {
      border-right: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }
`;

// 테이블이 포멧 (스크롤 등 세부 조정은 제거)
export const AddressTable = styled.table`
  position: relative;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  white-space: nowrap;
  border-collapse: separate; // td, th간 간격

  tr {
    border-left-color: transparent;
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    :first-of-type {
      border-radius: 5px 0 0 5px;
    }
    :last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  thead {
    z-index: 3;
    position: sticky;
    top: 0;
    background: ${(props) => props.theme.colors.gray100};
    border-radius: 5px;
    font-weight: 600;

    tr {
      height: 30px;
    }

    th {
      padding: 0 3rem;
      border-top: 0;
      color: ${(props) => props.theme.colors.txt300};
      border: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }

  tbody {
    line-height: normal;

    &.skeleton {
      td {
        > div {
          position: relative;
          opacity: 1;
          top: auto;
          left: auto;
          transform: none;
        }
      }
    }

    tr {
      .fixedBox {
        background: #fff;
        border-right-color: transparent;
      }
    }

    td {
      min-width: 20rem;
      border: 1px solid #ebebeb;
      position: relative;
      color: ${(props) => props.theme.colors.txt500};
      font-size: 1.4rem;

      > p {
        width: 100%;
      }

      &.focus {
        > p {
          opacity: 0;
          display: none;
        }

        > div {
          opacity: 1;
          z-index: 1;
          display: flex;
        }
      }
    }

    /* body 체크박스 */
    .fixedBox {
      z-index: 2;
      position: sticky;
      left: 0;
    }

    tr:has(input[type='checkbox']:checked) {
      td {
        background: #f4f4f4;
      }

      .fixedBox {
        box-sizing: border-box;
      }
    }

    input[type='text'] {
      border: 0;
      text-align: center;
      width: 100%;
      height: 100%;
      outline-color: ${(props) => props.theme.colors.main};
      padding: 0.8rem 0;
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.txt500};
    }
    input {
      ::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }
    }

    tr:has(td.focus) {
      z-index: 1;
    }

    td {
      > p {
        max-width: 13rem;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    thead {
      top: -1px;
    }
  }
`;
