import React, { useEffect } from 'react';
import { ArsCardModalProps } from '@/components/say015/Modal/arsCards/ArsCardModal';
import { useArsGuideTts } from '@/components/hooks/customs/say015/say105Handle/useArsGuideTts';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useArsGuidePlayAudio } from '@/components/hooks/customs/say015/say105Handle/useArsGuidePlayAudio';
import { useRecoilState } from 'recoil';
import { simpleViewArsTreeRecoil } from '@/recoil/atoms/say015Atom';
import { useArsGuideRecord } from '@/components/hooks/customs/say015/say105Handle/useArsGuideRecord';
import AudioWave from '@/shared/atoms/AudioWave';
import AudioPause from '@/shared/atoms/AudioPause';
import { formatTime } from '@/shared/util/format/dateFormatUtil';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useArsCardTitle } from '@/components/hooks/customs/say015/say105Handle/useArsCardTitle';

/**
 * @title 텍스트 재생 ARS 카드 컴포넌트
 *
 * */
const ArsCardSpeak3 = ({
  data,
  type,
  handleUpdateIdState,
  handleCancel,
  arsData,
  updatedJsonFile,
  cardTitle,
}: ArsCardModalProps) => {
  /* 간편보기 사용여부 */
  const [simpleViewArsTreeS] = useRecoilState(simpleViewArsTreeRecoil);

  const { deleteArs, findDataById } = use015ArsJSON({ arsData, data, updatedJsonFile });

  const audioPlayHooks = useArsGuidePlayAudio({ data: findDataById(arsData, data?.id) });

  const {
    formatTime,
    startRecording,
    uploadAudio,
    stopRecording,
    audioIconToggle,
    audioBlob,
    recordingTimeS,
    maxRecordingTimeSec,
  } = useArsGuideRecord({
    arsData,
    data,
    setRecordAudioUrl: audioPlayHooks.setRecordAudioUrl,
    updatedJsonFile,
    cardTitle,
  });

  const { confirmModal } = useModalHook();

  useEffect(() => {
    if (audioPlayHooks.activePlayRecordIdS && audioPlayHooks.activePlayRecordIdS === data?.id) {
      audioPlayHooks.handlePlay();
    } else {
      audioPlayHooks.handleEnded();
    }
  }, [audioPlayHooks.activePlayRecordIdS]);

  // 저장 버튼 클릭시 분기처리
  const onClickSave = () => {
    if (handleCancel) handleCancel();
    if (type === 'add') {
      uploadAudio();
    } else {
      confirmModal(
        async () => {
          await uploadAudio();
        },
        '안내멘트 저장',
        '기존 안내멘트 텍스트와 녹음은 제거되고, 현재 설정으로 저장합니다.',
        true,
        undefined,
        async () => {},
      );
    }
  };

  return (
    <>
      {/* 간편보기 O */}
      {simpleViewArsTreeS && (
        <div className={'simpleCard'}>
          <div className="txtTop">
            <div className="group">
              {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
              <p
                className="icon"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!handleUpdateIdState) return;
                  handleUpdateIdState(data?.id);
                  audioPlayHooks.handleAudioPlay(null);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                  <path
                    d="M19.7889 1.68185C19.8955 1.50422 20.0684 1.37622 20.2694 1.32602C20.4704 1.27582 20.6831 1.30751 20.8608 1.41414L20.8639 1.41623L20.867 1.41831L20.8774 1.42456C20.9181 1.44923 20.9577 1.47564 20.9962 1.50373C21.0691 1.55477 21.168 1.62873 21.2847 1.72456C21.516 1.91727 21.818 2.20581 22.1201 2.59748C22.7264 3.38602 23.3233 4.59123 23.3233 6.2506C23.3233 7.90998 22.7264 9.11518 22.1201 9.90373C21.818 10.2954 21.516 10.5839 21.2847 10.7766C21.1552 10.8845 21.0196 10.9846 20.8785 11.0766L20.868 11.0829L20.8639 11.085L20.8618 11.086C20.8618 11.086 20.8608 11.0871 20.4587 10.4173L20.8608 11.0871C20.6838 11.1875 20.4747 11.2149 20.2778 11.1635C20.081 11.1121 19.912 10.986 19.8067 10.8119C19.7014 10.6378 19.6681 10.4296 19.714 10.2314C19.7599 10.0332 19.8813 9.86068 20.0524 9.7506L20.0576 9.74643L20.0993 9.71831C20.141 9.68914 20.2045 9.64227 20.2837 9.57664C20.443 9.44331 20.6618 9.23706 20.8805 8.95164C21.316 8.38602 21.7608 7.50789 21.7608 6.2506C21.7608 4.99331 21.316 4.11518 20.8816 3.54956C20.7057 3.32003 20.5052 3.11045 20.2837 2.92456C20.2112 2.86417 20.1358 2.8075 20.0576 2.75477L20.0524 2.7506C19.8763 2.64329 19.7498 2.47073 19.7005 2.27052C19.6511 2.07032 19.6829 1.85872 19.7889 1.68185Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.0837 1.30273C9.77139 1.30273 8.51287 1.82403 7.58495 2.75195C6.65704 3.67986 6.13574 4.93838 6.13574 6.25065C6.13574 7.56292 6.65704 8.82144 7.58495 9.74936C8.51287 10.6773 9.77139 11.1986 11.0837 11.1986C12.3959 11.1986 13.6545 10.6773 14.5824 9.74936C15.5103 8.82144 16.0316 7.56292 16.0316 6.25065C16.0316 4.93838 15.5103 3.67986 14.5824 2.75195C13.6545 1.82403 12.3959 1.30273 11.0837 1.30273ZM7.69824 6.25065C7.69824 5.35278 8.05492 4.49169 8.68981 3.8568C9.3247 3.22191 10.1858 2.86523 11.0837 2.86523C11.9815 2.86523 12.8426 3.22191 13.4775 3.8568C14.1124 4.49169 14.4691 5.35278 14.4691 6.25065C14.4691 7.14852 14.1124 8.00961 13.4775 8.6445C12.8426 9.27939 11.9815 9.63607 11.0837 9.63607C10.1858 9.63607 9.3247 9.27939 8.68981 8.6445C8.05492 8.00961 7.69824 7.14852 7.69824 6.25065ZM11.0837 12.7611C8.95866 12.7611 6.99824 13.2507 5.53991 14.084C4.10449 14.9048 3.01074 16.1569 3.01074 17.709C3.01074 19.2611 4.10449 20.5131 5.53991 21.334C6.99824 22.1673 8.9597 22.6569 11.0837 22.6569C13.2087 22.6569 15.1691 22.1673 16.6274 21.334C18.0628 20.5131 19.1566 19.2611 19.1566 17.709C19.1566 16.1569 18.0628 14.9048 16.6274 14.084C15.1691 13.2507 13.2076 12.7611 11.0837 12.7611ZM4.57324 17.709C4.57324 16.959 5.11178 16.1288 6.31491 15.4413C7.49616 14.7663 9.18053 14.3236 11.0837 14.3236C12.9868 14.3236 14.6712 14.7663 15.8524 15.4413C17.0555 16.1288 17.5941 16.96 17.5941 17.709C17.5941 18.459 17.0555 19.2892 15.8524 19.9767C14.6712 20.6517 12.9868 21.0944 11.0837 21.0944C9.18053 21.0944 7.49616 20.6517 6.31491 19.9767C5.11178 19.2892 4.57324 18.459 4.57324 17.709Z"
                    fill="currentColor"
                  />
                  <path
                    d="M18.7779 3.49663C18.6899 3.44367 18.5923 3.40858 18.4907 3.39338C18.3891 3.37817 18.2856 3.38314 18.1859 3.40801C18.0863 3.43287 17.9925 3.47714 17.91 3.53829C17.8274 3.59943 17.7578 3.67625 17.705 3.76434L17.9654 4.83101L17.9727 4.83622C18.0876 4.92263 18.1903 5.02425 18.2779 5.1383C18.5196 5.45761 18.6459 5.84941 18.6362 6.24976C18.6362 6.77788 18.4518 7.13517 18.2779 7.36226C18.1903 7.4763 18.0876 7.57792 17.9727 7.66434L17.9643 7.66851C17.7911 7.77802 17.6679 7.95119 17.6212 8.15071C17.5744 8.35024 17.608 8.56013 17.7146 8.73516C17.8211 8.91018 17.9922 9.03633 18.1909 9.08641C18.3896 9.13649 18.6 9.10649 18.7768 9.00288H18.7789L18.781 9.0008L18.7841 8.99976L18.7914 8.99455L18.8123 8.98205C18.8916 8.93132 18.9671 8.87492 19.0383 8.8133C19.1727 8.70184 19.3446 8.53726 19.5164 8.31434C19.9682 7.72176 20.2085 6.99487 20.1987 6.24976C20.2085 5.50464 19.9682 4.77776 19.5164 4.18517C19.3317 3.94549 19.1135 3.7336 18.8685 3.55601C18.85 3.54284 18.8312 3.52999 18.8123 3.51747L18.7914 3.50497L18.7841 3.49976L18.781 3.49872L18.7789 3.49767C18.7789 3.49767 18.7779 3.49663 18.3758 4.16642L18.7779 3.49663Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
              {type !== 'view' && <h3 className="txt">녹음 재생</h3>}
              {type === 'view' && (
                <button
                  className={`${audioPlayHooks.activePlayRecordIdS === data?.id ? 'voicePlayBtn active' : 'voicePlayBtn'}`}
                  onClick={() => audioPlayHooks.handleAudioPlay(data?.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <circle cx="9.21643" cy="9.21643" r="9.21643" fill="currentColor" />
                    <path
                      d="M8.41834 5.66236C7.84234 5.32353 7.11621 5.73883 7.11621 6.4071V12.0261C7.11621 12.6944 7.84234 13.1097 8.41834 12.7708L13.1945 9.96134C13.7624 9.62726 13.7624 8.80594 13.1945 8.47185L8.41834 5.66236Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
            </div>
            {/* 삭제 버튼 */}
            {type === 'update' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
          {type !== 'view' && (
            <div className="list">
              {audioIconToggle ? (
                <div className="updateWrap update">
                  {/* 오디오 애니메이션 */}
                  <div className="flexAc flexJsb audioWrap">
                    <AudioWave />
                    <p className="timeCount">{formatTime(recordingTimeS)}</p>
                  </div>
                  <button className="btn" onClick={stopRecording}>
                    <span />
                  </button>
                </div>
              ) : (
                <div className="updateWrap pasuse">
                  {/* 오디오 기본 */}
                  <div className="flexAc flexJsb audioWrap">
                    <AudioPause />
                    <p className="timeCount">{formatTime(recordingTimeS)}</p>
                  </div>

                  <button className="btn" onClick={startRecording}>
                    <span />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {/* 간편보기 X */}
      {!simpleViewArsTreeS && (
        <div className={'card'}>
          {/* <div className="txtTop"> */}
          {/* <div className="group"> */}
          {/* 드래그앤 드롭시 필요한 그랩 아이콘 */}
          {/* {type === 'view' && (
                <button className="grab">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.33333 1.66667C3.33333 2.58712 2.58712 3.33333 1.66667 3.33333C0.746208 3.33333 0 2.58712 0 1.66667C0 0.746192 0.746208 0 1.66667 0C2.58712 0 3.33333 0.746192 3.33333 1.66667ZM1.66667 9.16667C2.58712 9.16667 3.33333 8.42046 3.33333 7.5C3.33333 6.57954 2.58712 5.83333 1.66667 5.83333C0.746208 5.83333 0 6.57954 0 7.5C0 8.42046 0.746208 9.16667 1.66667 9.16667ZM1.66667 15C2.58712 15 3.33333 14.2538 3.33333 13.3333C3.33333 12.4129 2.58712 11.6667 1.66667 11.6667C0.746208 11.6667 0 12.4129 0 13.3333C0 14.2538 0.746208 15 1.66667 15Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.66732 1.66667C9.66732 2.58712 8.92111 3.33333 8.00065 3.33333C7.08019 3.33333 6.33398 2.58712 6.33398 1.66667C6.33398 0.746192 7.08019 0 8.00065 0C8.92111 0 9.66732 0.746192 9.66732 1.66667ZM8.00065 9.16667C8.92111 9.16667 9.66732 8.42046 9.66732 7.5C9.66732 6.57954 8.92111 5.83333 8.00065 5.83333C7.08019 5.83333 6.33398 6.57954 6.33398 7.5C6.33398 8.42046 7.08019 9.16667 8.00065 9.16667ZM8.00065 15C8.92111 15 9.66732 14.2538 9.66732 13.3333C9.66732 12.4129 8.92111 11.6667 8.00065 11.6667C7.08019 11.6667 6.33398 12.4129 6.33398 13.3333C6.33398 14.2538 7.08019 15 8.00065 15Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )} */}
          {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
          {/* <p className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                  <path
                    d="M19.7889 1.68185C19.8955 1.50422 20.0684 1.37622 20.2694 1.32602C20.4704 1.27582 20.6831 1.30751 20.8608 1.41414L20.8639 1.41623L20.867 1.41831L20.8774 1.42456C20.9181 1.44923 20.9577 1.47564 20.9962 1.50373C21.0691 1.55477 21.168 1.62873 21.2847 1.72456C21.516 1.91727 21.818 2.20581 22.1201 2.59748C22.7264 3.38602 23.3233 4.59123 23.3233 6.2506C23.3233 7.90998 22.7264 9.11518 22.1201 9.90373C21.818 10.2954 21.516 10.5839 21.2847 10.7766C21.1552 10.8845 21.0196 10.9846 20.8785 11.0766L20.868 11.0829L20.8639 11.085L20.8618 11.086C20.8618 11.086 20.8608 11.0871 20.4587 10.4173L20.8608 11.0871C20.6838 11.1875 20.4747 11.2149 20.2778 11.1635C20.081 11.1121 19.912 10.986 19.8067 10.8119C19.7014 10.6378 19.6681 10.4296 19.714 10.2314C19.7599 10.0332 19.8813 9.86068 20.0524 9.7506L20.0576 9.74643L20.0993 9.71831C20.141 9.68914 20.2045 9.64227 20.2837 9.57664C20.443 9.44331 20.6618 9.23706 20.8805 8.95164C21.316 8.38602 21.7608 7.50789 21.7608 6.2506C21.7608 4.99331 21.316 4.11518 20.8816 3.54956C20.7057 3.32003 20.5052 3.11045 20.2837 2.92456C20.2112 2.86417 20.1358 2.8075 20.0576 2.75477L20.0524 2.7506C19.8763 2.64329 19.7498 2.47073 19.7005 2.27052C19.6511 2.07032 19.6829 1.85872 19.7889 1.68185Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.0837 1.30273C9.77139 1.30273 8.51287 1.82403 7.58495 2.75195C6.65704 3.67986 6.13574 4.93838 6.13574 6.25065C6.13574 7.56292 6.65704 8.82144 7.58495 9.74936C8.51287 10.6773 9.77139 11.1986 11.0837 11.1986C12.3959 11.1986 13.6545 10.6773 14.5824 9.74936C15.5103 8.82144 16.0316 7.56292 16.0316 6.25065C16.0316 4.93838 15.5103 3.67986 14.5824 2.75195C13.6545 1.82403 12.3959 1.30273 11.0837 1.30273ZM7.69824 6.25065C7.69824 5.35278 8.05492 4.49169 8.68981 3.8568C9.3247 3.22191 10.1858 2.86523 11.0837 2.86523C11.9815 2.86523 12.8426 3.22191 13.4775 3.8568C14.1124 4.49169 14.4691 5.35278 14.4691 6.25065C14.4691 7.14852 14.1124 8.00961 13.4775 8.6445C12.8426 9.27939 11.9815 9.63607 11.0837 9.63607C10.1858 9.63607 9.3247 9.27939 8.68981 8.6445C8.05492 8.00961 7.69824 7.14852 7.69824 6.25065ZM11.0837 12.7611C8.95866 12.7611 6.99824 13.2507 5.53991 14.084C4.10449 14.9048 3.01074 16.1569 3.01074 17.709C3.01074 19.2611 4.10449 20.5131 5.53991 21.334C6.99824 22.1673 8.9597 22.6569 11.0837 22.6569C13.2087 22.6569 15.1691 22.1673 16.6274 21.334C18.0628 20.5131 19.1566 19.2611 19.1566 17.709C19.1566 16.1569 18.0628 14.9048 16.6274 14.084C15.1691 13.2507 13.2076 12.7611 11.0837 12.7611ZM4.57324 17.709C4.57324 16.959 5.11178 16.1288 6.31491 15.4413C7.49616 14.7663 9.18053 14.3236 11.0837 14.3236C12.9868 14.3236 14.6712 14.7663 15.8524 15.4413C17.0555 16.1288 17.5941 16.96 17.5941 17.709C17.5941 18.459 17.0555 19.2892 15.8524 19.9767C14.6712 20.6517 12.9868 21.0944 11.0837 21.0944C9.18053 21.0944 7.49616 20.6517 6.31491 19.9767C5.11178 19.2892 4.57324 18.459 4.57324 17.709Z"
                    fill="currentColor"
                  />
                  <path
                    d="M18.7779 3.49663C18.6899 3.44367 18.5923 3.40858 18.4907 3.39338C18.3891 3.37817 18.2856 3.38314 18.1859 3.40801C18.0863 3.43287 17.9925 3.47714 17.91 3.53829C17.8274 3.59943 17.7578 3.67625 17.705 3.76434L17.9654 4.83101L17.9727 4.83622C18.0876 4.92263 18.1903 5.02425 18.2779 5.1383C18.5196 5.45761 18.6459 5.84941 18.6362 6.24976C18.6362 6.77788 18.4518 7.13517 18.2779 7.36226C18.1903 7.4763 18.0876 7.57792 17.9727 7.66434L17.9643 7.66851C17.7911 7.77802 17.6679 7.95119 17.6212 8.15071C17.5744 8.35024 17.608 8.56013 17.7146 8.73516C17.8211 8.91018 17.9922 9.03633 18.1909 9.08641C18.3896 9.13649 18.6 9.10649 18.7768 9.00288H18.7789L18.781 9.0008L18.7841 8.99976L18.7914 8.99455L18.8123 8.98205C18.8916 8.93132 18.9671 8.87492 19.0383 8.8133C19.1727 8.70184 19.3446 8.53726 19.5164 8.31434C19.9682 7.72176 20.2085 6.99487 20.1987 6.24976C20.2085 5.50464 19.9682 4.77776 19.5164 4.18517C19.3317 3.94549 19.1135 3.7336 18.8685 3.55601C18.85 3.54284 18.8312 3.52999 18.8123 3.51747L18.7914 3.50497L18.7841 3.49976L18.781 3.49872L18.7789 3.49767C18.7789 3.49767 18.7779 3.49663 18.3758 4.16642L18.7779 3.49663Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
              <h3 className="txt">녹음 재생</h3> */}
          {/* ARS TTS 카드 재생 버튼. 클래스명은 임의로 저렇게 해둠 */}
          {/* {type === 'view' && (
                <button
                  className={`${audioPlayHooks.activePlayRecordIdS === data?.id ? 'voicePlayBtn active' : 'voicePlayBtn'}`}
                  onClick={() => audioPlayHooks.handleAudioPlay(data?.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <circle cx="9.21643" cy="9.21643" r="9.21643" fill="currentColor" />
                    <path
                      d="M8.41834 5.66236C7.84234 5.32353 7.11621 5.73883 7.11621 6.4071V12.0261C7.11621 12.6944 7.84234 13.1097 8.41834 12.7708L13.1945 9.96134C13.7624 9.62726 13.7624 8.80594 13.1945 8.47185L8.41834 5.66236Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )} */}
          {/* </div> */}
          {/* 삭제 버튼 */}
          {/* {type !== 'add' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )} */}
          {/* </div> */}
          <div
            className="list"
            onClick={() => {
              if (!handleUpdateIdState) return;
              handleUpdateIdState(data?.id);
              audioPlayHooks.handleAudioPlay(null);
            }}
          >
            {type === 'view' && (
              <div className="viewWrap">
                {data?.s015Data?.ttsText}
                <div className="flexAc flexJsb audioWrap">
                  {audioPlayHooks.playSwitchBtn ? <AudioWave /> : <AudioPause />}
                  <p className="timeCount">
                    {audioPlayHooks.playSwitchBtn ? audioPlayHooks.audioTime : audioPlayHooks.durationView}
                  </p>
                </div>
              </div>
            )}
            {type === 'update' &&
              (audioIconToggle ? (
                <div className="updateWrap update">
                  {/* 오디오 애니메이션 */}
                  <div className="flexAc flexJsb audioWrap">
                    <AudioWave />
                  </div>
                  <p className="timeCountCenter">
                    {formatTime(recordingTimeS)} <span>/</span> {formatTime(maxRecordingTimeSec)}
                  </p>
                  <button className="btn" onClick={stopRecording}>
                    <span />
                  </button>
                </div>
              ) : (
                <div className="updateWrap pasuse">
                  {/* 오디오 기본 */}
                  <div className="flexAc flexJsb audioWrap">
                    <AudioPause />
                  </div>
                  <p className="timeCountCenter">
                    {formatTime(recordingTimeS)} <span>/</span> {formatTime(maxRecordingTimeSec)}
                  </p>
                  <button className="btn" onClick={startRecording}>
                    <span />
                  </button>
                </div>
              ))}
            {type === 'add' &&
              (audioIconToggle ? (
                <div className="updateWrap update">
                  {/* 오디오 애니메이션 */}
                  <div className="flexAc flexJsb audioWrap">
                    <AudioWave />
                  </div>
                  <p className="timeCountCenter">
                    {formatTime(recordingTimeS)} <span>/</span> {formatTime(maxRecordingTimeSec)}
                  </p>
                  <button className="btn" onClick={stopRecording}>
                    <span />
                  </button>
                </div>
              ) : (
                <div className="updateWrap pasuse">
                  {/* 오디오 기본 */}
                  <div className="flexAc flexJsb audioWrap">
                    <AudioPause />
                  </div>
                  <p className="timeCountCenter">
                    {formatTime(recordingTimeS)} <span>/</span> {formatTime(maxRecordingTimeSec)}
                  </p>
                  <button className="btn" onClick={startRecording}>
                    <span />
                  </button>
                </div>
              ))}
          </div>
        </div>
      )}
      {/* 하단 버튼 */}
      {type !== 'view' && (
        <div className="btnGroup">
          <button className="cancelBtn" onClick={handleCancel}>
            취소
          </button>
          <button
            className="saveBtn"
            onClick={async () => onClickSave()}
            disabled={!(cardTitle !== data?.s015Data.title || audioBlob)}
          >
            저장
          </button>
        </div>
      )}
      {audioPlayHooks.audioRef && audioPlayHooks.ttsAudioUrl && (
        <>
          <audio
            style={{ display: 'none' }}
            ref={audioPlayHooks.audioRef}
            onLoadedMetadata={audioPlayHooks.handleLoadedMetadata}
            onEnded={() => {
              audioPlayHooks.handleAudioPlay(null);
              audioPlayHooks.handleEnded();
            }}
          >
            <source src={audioPlayHooks.ttsAudioUrl} type="audio/wav" />
            <track kind="captions" src="" label="인사말 설정 음성파일입니다." />
          </audio>
        </>
      )}
    </>
  );
};

export default ArsCardSpeak3;
