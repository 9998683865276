import { instance } from '@/shared/lib/clientAxios';

// ==============================
// 그룹 리스트 관련 api
// ==============================
export type GroupListType = {
  groupSeqNo: number;
  usrKey: string;
  buddyCount: number;
  groupNm: string;
};

/* 그룹 리스트 GET API */
export const getGroupList = async (keyword: string) => {
  const response = await instance.get('/groupList2', {
    params: {
      keyword,
    },
  });
  return response.data;
};

/* 그룹 추가하기 */
export const postAddGroup = async (post: any) => {
  const response = await instance.post(`/group/create`, {
    groupNm: post.groupNm,
  });
  return response.data;
};

/* 그룹 삭제하기 */
export const deleteDelGroups = async (groupCheckBoxList: number[]) => {
  const response = await instance.post(`/group/delete`, {
    groupSeqNos: groupCheckBoxList,
  });
  return response.data;
};

type TPayload = {
  contacts: { buddyNm: string; keyCommNo: string }[];
};

/* 모바일 동기화 추가하기 */
export const postAddMobileGroup = async (payload: TPayload) => {
  await instance.post(`/contacts/mobile-sync`, payload);
};

// ==============================
// 기준 : 모바일 그룹 연락처 리스트 관련 api
// ==============================
/* 연락처 추가하기 */
// export const postAddBuddy = async (post: any) => {
//   const response = await instance.post(`/contacts/create`, {
//     buddyNm: post.buddyNm,
//     keyCommNo: post.keyCommNo,
//     groupSeqNo: post.groupSeqNo,
//   });

//   return response.data;
// };

// ==============================
// 개별 리스트 관련 api
// ==============================

/* 개별리스트 삭제하기 */
export const deleteRemoveItems = async (buddySeqNoArray: number[] | undefined) => {
  const response = await instance.post('/contacts/delete', buddySeqNoArray);
  return response.data;
};

export interface IPostCopyBuddyList {
  buddySeqNos?: number[];
  groupSeqNo?: number;
}

// ==============================
// 휴지통 관련 api
// ==============================
export interface IGetBinListProps {
  currentPage: number;
  buddyNm?: string | undefined;
  emailId?: string | undefined;
  keyCommNo?: string | undefined;
  coNm?: string | undefined;
  // keywordValue?: string;
  keyword?: string | null;
}

/* 휴지통 list GET API */
export const getUserAddressRecycleBinList = async ({
  currentPage,
  buddyNm,
  emailId,
  keyCommNo,
  coNm,
  // keywordValue,
  keyword,
}: IGetBinListProps) => {
  const response = await instance.get('/contacts/recycle', {
    params: {
      pageNumber: currentPage,
      pageSize: 20,
      buddyNm,
      emailId,
      keyCommNo,
      coNm,
      // keywordValue,
      keyword,
    },
  });
  return response.data;
};

/* 휴지통 restore */
export const postAdressBinRestore = async (buddySeqNo: number[]) => {
  const response = await instance.post('/contacts/recycle/restore', buddySeqNo);
  return response.data;
};
/* 휴지통 delete */
export const postAdressBinDelete = async (buddySeqNo: number[]) => {
  const response = await instance.post('/contacts/recycle/delete', buddySeqNo);
  return response.data;
};

/* 휴지통 전체 restore */
export const postAdressBinAllList = async (search: string) => {
  const response = await instance.post('/contacts/recycle/restore/all', {
    keyword: search,
  });
  return response.data;
};

/* 휴지통 전체 delete */
export const deleteAddressBinAllList = async (search: string) => {
  const response = await instance.delete('/contacts/recycle/delete', {
    data: {
      keyword: search,
    },
  });
  return response.data;
};

// ==============================
// 주소록 관련 api
// ==============================

interface DupData {
  currentPage: number;
}
// 주소록 중복체크 버튼 클릭시 get api
export const getDuplicationList = async ({ currentPage }: DupData) => {
  const response = await instance.get('/contacts/duplicate', {
    params: {
      pageNumber: currentPage,
      pageSize: 10,
    },
  });
  return response.data;
};

export interface DefaultRowData {
  [key: string]: string | number | null; // 인덱스 시그니처 변경
  buddyNm: string; // 이름
  // groupNm: string; // 그룹이름
  keyCommNo: string; // 휴대폰번호
  emailId: string; // 이메일
  groupSeqNo: number | null; // 그룹고유번호0
  siteId: string | null; // 서비스이용 사이트 이름(아레오, 네티앙)
  usrId: string; // ?
  usrKey: string; // ?
  birthday: string | null; // 생일
  coNm: string | null; // 회사이름
  genderFlag: string | null; // 성별
  homeAddr: string | null; // 집주소
  homeNumber: string; // 집전화
  homeZipCd: string; // 집우편번호
  coNumber: string; // 회사전화
  coZipCd: string; // 회사우편번호
  coAddr: string | null; // 회사주소
  faxNumber: string; // 팩스
  homepageUrl: string | null; // 홈페이지
  accountNo: string; // 계좌번호
  marriageDay: string | null; // 결혼기념일
  messenger: string | null; // 메신저
  coDept: string | null; // 부서
  coHandle: string | null; // 직함
  etcInfo: string | null; // 메모
  natCd: string | null; // 국가번호
  residentNo: string; // 주민번호
  number015: string; // 015번호
}
// 주소록 대량추가 주소록 저장 버튼 클릭시 post api
export const postAddLargeList = async (addData: DefaultRowData[]) => {
  const response = await instance.post('/contacts/large-buddy', addData);
  return response.data;
};

// ==============================
// 문자전송전 분기처리 관련 데이터 POST API
// ==============================
export type ISendInfoParams = {
  callback: string;
  /* 문자 타입 */
  usrCd: '00000' | 'LMS01' | 'MMS01';
  /* 그룹 리스트에서 전체 체크한 그룹 시퀀스 */
  groupSeqList?: number[];
  /* 주소록에서 개별로 체크한 수신인 버디 시퀀스 */
  buddySeqList?: number[];
  /* 직접 작성한 수신인 */
  etcPhoneNumberList?: string[];
  /* 재전송 시 번호리스트 */
  reSendPhoneNumberList?: string[];
  /* 반복횟수 */
  repeatCount?: number;
};

export type SendInfoResponseType = {
  // 발신번호
  callback: string;
  // 메세지 타입
  usrCd: '00000' | 'LMS01' | 'MMS01';
  // 중복제거 총 수신 인원
  totalReceivers: number;
  // 총 발송비용
  totalCost: number;
  // 015 비즈망 발송 변작 동의 컨펌 표출 여부
  agreeToAlterations: boolean;

  // 사용 가능 아레오 캐시
  remainingCash: number;
  // 차감될 금액
  chargedCash: number;
  // 차감 후 금액
  cashAfterCharge: number;
  // 사용 가능 아레오 포인트 (010)
  remainingPoint: number;

  // 차감될 포인트 (010)
  chargedPoint: number;
  // 차감 후 포인트 (010)
  pointAfterCharge: number;

  // 무료문자 SMS기준 잔여량 (015)
  freeSmsCount: number;
  // 차감 후 SMS 무료건수 (015)
  freeSmsCountAfterCharge: number;

  // 무료문자 LMS기준 잔여량 (015)
  freeLmsCount: number;
  // 차감 후 LMS 무료건수 (015)
  freeLmsCountAfterCharge: number;

  // 무료문자 MMS기준 잔여량 (015)
  freeMmsCount: number;
  // 차감 후 MMS 무료건수 (015)
  freeMmsCountAfterCharge: number;

  // 결제 가능 여부
  pointEnough: boolean;
  // 부족한 금액(pointEnough: false 일 때만)
  shortageCost?: number;
};

export const postSendInfo = async (sendInfoParams: ISendInfoParams) => {
  const { data } = await instance.post('/message/legacy/send-info', sendInfoParams);
  return data;
};

// 015 2건이상 발송시 변작 동의 api
export const postAgree015Send = async (callback: string) => {
  const response = await instance.post('/consent/callback', { callback });
  return response.data;
};
