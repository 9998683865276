import React, { useEffect, useState } from 'react';
import * as S from './AddressHistory.styles';
import { BuddyData } from '@/types/index.types';
import { useAddressDetail } from '@/components/hooks/customs/useAddressDetail';
import { useQueryClient } from '@tanstack/react-query';
import { formatPhoneNumber, removeHyphens } from '@/shared/util/format/phoneNumberFormatUtil';
import { useModalHook } from '@/components/commons/modals/useModalHook';

interface Field {
  name: string;
  label: string;
  placeholder: string;
  value: string | null; // 값은 문자열 또는 null일 수 있음
  dataValue: string | null | undefined; // dataValue도 문자열, null 또는 undefined일 수 있음
}

// 각 정보 그룹에 대한 타입 정의
interface Fields {
  companyInfo: Field[];
  homeInfo: Field[];
  detailInfo: Field[];
}

interface BuddyComponentdata {
  data: BuddyData | null;
  onClickCloseDetailBtn: () => void;
}

const MobileAddressHistory: React.FC<BuddyComponentdata> = ({ data, onClickCloseDetailBtn }) => {
  const detailHooks = useAddressDetail();
  const { successModal, warningModal, confirmModal } = useModalHook();

  const queryClient = useQueryClient();

  // 검색한 리스트
  const [filteredList, setFilteredList] = useState<any[]>([]);

  const groupListQueryData: any = queryClient.getQueryData(['groupList2']);

  useEffect(() => {
    if (groupListQueryData) {
      // 필터링된 리스트 설정
      const initialList = detailHooks.editValueS
        ? groupListQueryData.groupList.filter(
            (list: any) => detailHooks.editValueS && data && list.groupSeqNo !== detailHooks.editValueS.groupSeqNo,
          )
        : groupListQueryData.groupList;

      setFilteredList(initialList);
    }
  }, [groupListQueryData, detailHooks.editValueS]);

  const onChangeGroup = (value: unknown, option: any) => {
    // 선택된 그룹의 seqNo로 filteredList에서 해당 아이템 찾기
    const selectedGroup = filteredList.find((item) => item.groupSeqNo === Number(value));
    if (selectedGroup) {
      // selectedGroup의 키-값 쌍을 editValueS에 업데이트
      Object.keys(selectedGroup).forEach((key) => {
        // onChangeEditValue를 호출하여 각 키-값 쌍을 업데이트
        detailHooks.onChangeEditValue({
          target: {
            name: key,
            value: selectedGroup[key],
          },
        } as React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>);
      });
    }
  };

  // 저장 버튼 클릭 시 nextData 콘솔 출력 및 상세 정보 변경
  const handleSave = () => {
    if (detailHooks.editValueS !== data) {
      confirmModal(
        () => {
          detailHooks.onClickSaveBtn();
          onClickCloseDetailBtn();
          successModal('성공', '수정이 완료되었습니다.', true);
        },
        '수정',
        '수정된 내용을 저장하시겠습니까?',
        true,
      );
    }
  };

  const handleCancel = () => {
    if (detailHooks.editValueS !== data) {
      confirmModal(
        () => {
          onClickCloseDetailBtn();
        },
        '취소',
        '수정된 내역이 있습니다 취소하시겠습니까?',
        true,
      );
    } else {
      onClickCloseDetailBtn();
    }
  };

  const fields: Fields = {
    companyInfo: [
      {
        name: 'coNm',
        label: '회사 이름',
        placeholder: '회사 이름을 입력해 주세요.',
        value: detailHooks.editValueS.coNm,
        dataValue: data?.coNm,
      },
      {
        name: 'coNumber',
        label: '회사 전화',
        placeholder: '회사 전화번호를 입력해 주세요.',
        value: detailHooks.editValueS.coNumber,
        dataValue: data?.coNumber,
      },
      {
        name: 'coZipCd',
        label: '회사 우편번호',
        placeholder: '회사 우편번호를 입력해 주세요.',
        value: detailHooks.editValueS.coZipCd,
        dataValue: data?.coZipCd,
      },
      {
        name: 'coAddr',
        label: '회사 주소',
        placeholder: '회사 주소를 입력해 주세요.',
        value: detailHooks.editValueS.coAddr,
        dataValue: data?.coAddr,
      },
      {
        name: 'coDept',
        label: '부서',
        placeholder: '부서를 입력해 주세요.',
        value: detailHooks.editValueS.coDept,
        dataValue: data?.coDept,
      },
      {
        name: 'coHandle',
        label: '직함',
        placeholder: '직함을 입력해 주세요.',
        value: detailHooks.editValueS.coHandle,
        dataValue: data?.coHandle,
      },
    ],
    homeInfo: [
      {
        name: 'homeAddr',
        label: '집 주소',
        placeholder: '집 주소를 입력해 주세요.',
        value: detailHooks.editValueS.homeAddr,
        dataValue: data?.homeAddr,
      },
      {
        name: 'homeNumber',
        label: '집 전화',
        placeholder: '집 전화를 입력해 주세요.',
        value: detailHooks.editValueS.homeNumber,
        dataValue: data?.homeNumber,
      },
      {
        name: 'homeZipCd',
        label: '집 우편번호',
        placeholder: '집 우편번호를 입력해 주세요.',
        value: detailHooks.editValueS.homeZipCd,
        dataValue: data?.homeZipCd,
      },
    ],
    detailInfo: [
      {
        name: 'homepageUrl',
        label: '홈페이지',
        placeholder: '홈페이지 URL을 입력해 주세요.',
        value: detailHooks.editValueS.homepageUrl,
        dataValue: data?.homepageUrl,
      },
      {
        name: 'messenger',
        label: '메신저',
        placeholder: '메신저 아이디를 입력해 주세요.',
        value: detailHooks.editValueS.messenger,
        dataValue: data?.messenger,
      },
      {
        name: 'birthday',
        label: '생일',
        placeholder: '생일을 입력해 주세요.',
        value: detailHooks.editValueS.birthday,
        dataValue: data?.birthday,
      },
      {
        name: 'marriageDay',
        label: '결혼기념일',
        placeholder: '결혼기념일을 입력해 주세요.',
        value: detailHooks.editValueS.marriageDay,
        dataValue: data?.marriageDay,
      },
      {
        name: 'genderFlag',
        label: '성별',
        placeholder: '성별을 입력해 주세요.',
        value: detailHooks.editValueS.genderFlag,
        dataValue: data?.genderFlag,
      },
      {
        name: 'residentNo',
        label: '주민번호',
        placeholder: '주민번호를 입력해 주세요.',
        value: detailHooks.editValueS.residentNo,
        dataValue: data?.residentNo,
      },
      {
        name: 'accountNo',
        label: '계좌번호',
        placeholder: '계좌번호를 입력해 주세요.',
        value: detailHooks.editValueS.accountNo,
        dataValue: data?.accountNo,
      },
    ],
  };

  return (
    <>
      <S.AddressHistoryWrap>
        <div className="wrap">
          {/* 기본 정보 탭 */}
          <S.Information className="information">
            <div>
              <h4>그룹</h4>
              <div style={{ position: 'relative' }}>
                <S.CustomSelect
                  active={detailHooks.editValueS.groupNm !== data?.groupNm}
                  value={detailHooks.editValueS.groupNm}
                  // showSearch
                  placeholder="그룹을 선택해 주세요"
                  options={filteredList.map((item) => {
                    const isDefaultGroup = item.groupNm === data?.groupNm; // 디폴트 그룹 여부 확인
                    return {
                      label: (
                        <div>
                          {item.groupNm}
                          {isDefaultGroup && (
                            <span
                              style={{
                                left: '100%', // 글자 오른쪽에 위치
                                marginLeft: '8px', // 약간의 간격 추가
                                color: 'rgba(0, 0, 0, 0.5)', // 잘 보이게 하기 위한 색상
                                fontWeight: 'normal', // 기본 두께
                                fontSize: '0.9em', // 폰트 크기 조정
                              }}
                            >
                              (Default Group)
                            </span>
                          )}
                        </div>
                      ),
                      value: item.groupSeqNo, // value에 고유한 groupSeqNo 할당
                    };
                  })}
                  onChange={onChangeGroup} // 선택한 그룹의 seqNo 전달
                  style={{ width: '100%' }}
                />
              </div>
            </div>

            <div>
              <h4>이름</h4>
              <div>
                <input
                  type="text"
                  name="buddyNm"
                  placeholder={data?.buddyNm}
                  value={detailHooks.editValueS.buddyNm}
                  onChange={detailHooks.onChangeEditValue}
                  style={{ borderColor: detailHooks.editValueS.buddyNm !== data?.buddyNm ? '#1549CE' : '' }}
                />
              </div>
            </div>

            <div>
              <h4>연락처</h4>
              <div>
                <input
                  type="text"
                  name="keyCommNo"
                  placeholder={formatPhoneNumber(data?.keyCommNo)}
                  defaultValue={removeHyphens(detailHooks.editValueS.keyCommNo)}
                  onChange={detailHooks.onChangeEditValue}
                  style={{ borderColor: detailHooks.editValueS.keyCommNo !== data?.keyCommNo ? '#1549CE' : '' }}
                />
              </div>
            </div>

            <div>
              <h4>이메일</h4>
              <div className={data?.emailId ? '' : 'notText'}>
                <input
                  type="text"
                  name="emailId"
                  placeholder={data?.emailId ? data?.emailId : 'email@arreo.com'}
                  value={detailHooks.editValueS.emailId}
                  onChange={detailHooks.onChangeEditValue}
                  style={{ borderColor: detailHooks.editValueS.emailId !== data?.emailId ? '#1549CE' : '' }}
                />
              </div>
            </div>

            <div>
              <h4>메모</h4>
              {/* 내용이 없을때 표출 */}
              <div className={data?.etcInfo ? '' : 'notText'}>
                <textarea
                  name="etcInfo"
                  placeholder="메모하기"
                  value={detailHooks.editValueS.etcInfo ?? ''}
                  onChange={detailHooks.onChangeEditValue}
                  style={{ borderColor: detailHooks.editValueS.etcInfo !== data?.etcInfo ? '#1549CE' : '' }}
                />
              </div>
            </div>
          </S.Information>

          <S.CardWrap className="cardWrap">
            <div className={detailHooks.columnSelectS.companySelect ? 'active' : ''}>
              <button onClick={() => detailHooks.onClickColumnSelect('companySelect')}>
                <p>직장</p>
                <p className="arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                    <path
                      d="M4.80437 3.93061L0.764606 1.38481C0.697167 1.34233 0.659546 1.2859 0.659546 1.22721C0.659546 1.16852 0.697167 1.11209 0.764606 1.06961L0.769171 1.06687C0.801864 1.0462 0.841217 1.02975 0.884835 1.01851C0.928453 1.00726 0.975425 1.00146 1.02289 1.00146C1.07036 1.00146 1.11733 1.00726 1.16095 1.01851C1.20457 1.02975 1.24392 1.0462 1.27661 1.06687L5.08053 3.46421L8.88293 1.06687C8.91562 1.0462 8.95497 1.02975 8.99859 1.01851C9.04221 1.00726 9.08918 1.00146 9.13665 1.00146C9.18412 1.00146 9.23109 1.00726 9.27471 1.01851C9.31832 1.02975 9.35768 1.0462 9.39037 1.06687L9.39493 1.06961C9.46237 1.11209 9.5 1.16852 9.5 1.22721C9.5 1.2859 9.46237 1.34233 9.39493 1.38481L5.35517 3.93061C5.31965 3.953 5.27692 3.97083 5.22958 3.98301C5.18224 3.99518 5.13127 4.00146 5.07977 4.00146C5.02827 4.00146 4.9773 3.99518 4.92996 3.98301C4.88262 3.97083 4.83989 3.953 4.80437 3.93061Z"
                      stroke="currentColor"
                      strokeWidth="0.887761"
                    />
                  </svg>
                </p>
              </button>

              <ul>
                {fields.companyInfo.map((field) => (
                  <li key={field.name}>
                    <h4>{field.label}</h4>
                    <div className={field.dataValue ? '' : 'notText'}>
                      <input
                        name={field.name}
                        placeholder={field.placeholder}
                        value={field.value ?? ''}
                        onChange={detailHooks.onChangeEditValue}
                        style={{ borderColor: field.value !== field.dataValue ? '#1549CE' : '' }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className={detailHooks.columnSelectS.homeSelect ? 'active' : ''}>
              <button onClick={() => detailHooks.onClickColumnSelect('homeSelect')}>
                <p>집</p>
                <p className="arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                    <path
                      d="M4.80437 3.93061L0.764606 1.38481C0.697167 1.34233 0.659546 1.2859 0.659546 1.22721C0.659546 1.16852 0.697167 1.11209 0.764606 1.06961L0.769171 1.06687C0.801864 1.0462 0.841217 1.02975 0.884835 1.01851C0.928453 1.00726 0.975425 1.00146 1.02289 1.00146C1.07036 1.00146 1.11733 1.00726 1.16095 1.01851C1.20457 1.02975 1.24392 1.0462 1.27661 1.06687L5.08053 3.46421L8.88293 1.06687C8.91562 1.0462 8.95497 1.02975 8.99859 1.01851C9.04221 1.00726 9.08918 1.00146 9.13665 1.00146C9.18412 1.00146 9.23109 1.00726 9.27471 1.01851C9.31832 1.02975 9.35768 1.0462 9.39037 1.06687L9.39493 1.06961C9.46237 1.11209 9.5 1.16852 9.5 1.22721C9.5 1.2859 9.46237 1.34233 9.39493 1.38481L5.35517 3.93061C5.31965 3.953 5.27692 3.97083 5.22958 3.98301C5.18224 3.99518 5.13127 4.00146 5.07977 4.00146C5.02827 4.00146 4.9773 3.99518 4.92996 3.98301C4.88262 3.97083 4.83989 3.953 4.80437 3.93061Z"
                      stroke="currentColor"
                      strokeWidth="0.887761"
                    />
                  </svg>
                </p>
              </button>

              <ul>
                {fields.homeInfo.map((field) => (
                  <li key={field.name}>
                    <h4>{field.label}</h4>
                    <div className={field.dataValue ? '' : 'notText'}>
                      <input
                        name={field.name}
                        placeholder={field.placeholder}
                        value={field.value ?? ''}
                        onChange={detailHooks.onChangeEditValue}
                        style={{ borderColor: field.value !== field.dataValue ? '#1549CE' : '' }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className={detailHooks.columnSelectS.etcSelect ? 'active' : ''}>
              <button onClick={() => detailHooks.onClickColumnSelect('etcSelect')}>
                <p>상세정보</p>
                <p className="arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                    <path
                      d="M4.80437 3.93061L0.764606 1.38481C0.697167 1.34233 0.659546 1.2859 0.659546 1.22721C0.659546 1.16852 0.697167 1.11209 0.764606 1.06961L0.769171 1.06687C0.801864 1.0462 0.841217 1.02975 0.884835 1.01851C0.928453 1.00726 0.975425 1.00146 1.02289 1.00146C1.07036 1.00146 1.11733 1.00726 1.16095 1.01851C1.20457 1.02975 1.24392 1.0462 1.27661 1.06687L5.08053 3.46421L8.88293 1.06687C8.91562 1.0462 8.95497 1.02975 8.99859 1.01851C9.04221 1.00726 9.08918 1.00146 9.13665 1.00146C9.18412 1.00146 9.23109 1.00726 9.27471 1.01851C9.31832 1.02975 9.35768 1.0462 9.39037 1.06687L9.39493 1.06961C9.46237 1.11209 9.5 1.16852 9.5 1.22721C9.5 1.2859 9.46237 1.34233 9.39493 1.38481L5.35517 3.93061C5.31965 3.953 5.27692 3.97083 5.22958 3.98301C5.18224 3.99518 5.13127 4.00146 5.07977 4.00146C5.02827 4.00146 4.9773 3.99518 4.92996 3.98301C4.88262 3.97083 4.83989 3.953 4.80437 3.93061Z"
                      stroke="currentColor"
                      strokeWidth="0.887761"
                    />
                  </svg>
                </p>
              </button>

              <ul>
                {fields.detailInfo.map((field) => (
                  <li key={field.name}>
                    <h4>{field.label}</h4>
                    <div className={field.dataValue ? '' : 'notText'}>
                      <input
                        name={field.name}
                        placeholder={field.placeholder}
                        value={field.value ?? ''}
                        onChange={detailHooks.onChangeEditValue}
                        style={{ borderColor: field.value !== field.dataValue ? '#1549CE' : '' }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </S.CardWrap>
        </div>
      </S.AddressHistoryWrap>

      {/* 하단 버튼  */}
      <S.AddressEdit>
        <button className="addBtn" type="button" onClick={handleCancel}>
          취소
        </button>
        <button className="addBtn" type="button" onClick={handleSave}>
          저장
        </button>
      </S.AddressEdit>
    </>
  );
};

export default MobileAddressHistory;
