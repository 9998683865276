import React, { useRef, useState } from 'react';
import * as S from '../MobileHeader.style';
import { useNavigate } from 'react-router-dom'; // useNavigate import 추가
import { TbAddressBook, TbFolderPlus, TbSearch, TbSettings } from 'react-icons/tb';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import styled from 'styled-components';
import AddGroup from '@/pages/main/components/addressLeftZone/groupList/addGroup/AddGroup.index';
import { useAddGroup } from '@/components/hooks/customs/addressBook/useAddGroup';
import { useMutation } from '@tanstack/react-query';
import { groupListCacheUpdate } from '@/components/cache/groupListCacheUpdate';
import IconAddressSync from '@/styles/icons/IconAddressSync';
import IconAddAddressGroup from '@/styles/icons/IconAddAddressGroup';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { addModalOpenRecoil, addModalTitleRecoil, syncLoadingRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { postAddMobileGroup } from '@/apis/api/addressApis';
import { detectEnvUtils } from '@/shared/util/detectEnvUtils';

const AddressGroupListHeader = () => {
  const navigate = useNavigate();
  const { confirmModal, warningModal } = useModalHook();
  const setSyncLoadingS = useSetRecoilState(syncLoadingRecoil);
  // 추가 방 상태 리코일
  const setAddModalOpenS = useSetRecoilState(addModalOpenRecoil);
  // 추가 방 제목 리코일
  const setAddModalTitleS = useSetRecoilState(addModalTitleRecoil);

  // 그룹 추가 버튼 눌렀을 때 동작하는 함수
  const onClickAddModalOpen = () => {
    setAddModalOpenS(true);
    setAddModalTitleS('그룹 추가');
    window.location.hash = '#add';
  };

  // 주소록 동기화 클릭 했을 때 동작하는 함수
  const onClickMobileAddressAdd = () => {
    if (detectEnvUtils() === 'app') {
      confirmModal(
        () => {
          setSyncLoadingS({ state: true, contents: '모바일 기기에서 연락처를 불러오는 중입니다' });
          window.fromWebToAppReqAddressList(localStorage.getItem('callBack') ?? '');
        },
        '주소록 동기화',
        '핸드폰의 주소록을 동기화하시겠습니까?',
        true,
        undefined,
        () => {
          warningModal('동기화 실패', '주소록에서 동기화를 다시 시도할 수 있습니다.', true);
        },
      );
    } else {
      warningModal('실패', '주소록 동기화 기능은 015톡비서 앱에서 이용 가능 합니다.', true);
    }
  };

  return (
    <>
      <S.IconContainer>
        {/* 폴더 추가 아이콘 */}
        <S.HeaderRightButtonIcon
          onClick={() => {
            onClickAddModalOpen();
          }}
        >
          <IconAddAddressGroup />
        </S.HeaderRightButtonIcon>

        {/* 주소록 동기화 아이콘 */}
        <S.HeaderRightButtonIcon
          onClick={() => {
            onClickMobileAddressAdd();
          }}
        >
          <IconAddressSync />
        </S.HeaderRightButtonIcon>

        {/* 설정 아이콘 */}
        {/* <S.HeaderRightButtonIcon>
          <TbSettings style={{ strokeWidth: 1.5 }} />
        </S.HeaderRightButtonIcon> */}
      </S.IconContainer>
    </>
  );
};

export default AddressGroupListHeader;
