import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as S from './SignIn.styles';
import { ICustomerListParams } from '@/apis/api/customer/customer.types';
import { customerListApi } from '@/apis/api/customer/customerApis';
import { useQuery } from '@tanstack/react-query';
import { client, instance } from '@/shared/lib/clientAxios';
import axios from 'axios';

const NewsGroupWrap = () => {
  const navigate = useNavigate();
  /* API STATE */
  const [customerListParams, setCustomerListParams] = useState<ICustomerListParams>({
    pageNumber: 0,
    pageSize: 3,
    type: 'notice',
    keyword: '',
    keywordValue: 'title',
    category: null,
  });

  /* 공지사항 리스트 조회 API */
  const { data } = useQuery(['/customer/list', customerListParams], () => customerListApi(customerListParams));
  const handleDynamicClick = (artId: number) => {
    // 동적으로 URL 생성
    navigate(`/customer/${artId}`);
  };

  const onClickAppDownload = async () => {
    try {
      const response = await client.get('/download/apk', {
        responseType: 'blob', // 응답을 Blob 형식으로 설정
      });

      // 성공적으로 다운로드 요청을 보냈을 때 추가 작업
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data])); // Blob URL 생성
        const link = document.createElement('a'); // 다운로드를 위한 링크 생성
        link.href = url; // Blob URL 설정
        link.setAttribute('download', '015talk.apk'); // 파일 이름 설정
        document.body.appendChild(link);
        link.click(); // 링크 클릭하여 다운로드 시작
        link.remove(); // 링크 요소 제거
      } else {
        alert('다운로드 요청에 문제가 발생했습니다.');
      }
    } catch (error) {
      console.error('다운로드 요청 실패:', error);
      alert('다운로드에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  return (
    <S.NewsGroupWrap className="newsGroup">
      <S.ResGroupWrap>
        <S.Notice>
          <S.NoticeTitleWrap>
            <h4>공지사항</h4>
            <button
              type="button"
              onClick={() => {
                navigate('/customer/-1');
              }}
            >
              MORE
            </button>
          </S.NoticeTitleWrap>
          <S.NoticeList>
            {data?.content.map((item) => (
              <li
                className={`${item.newYn === 'Y' ? 'active' : ''}`}
                key={item.artId}
                title={item.title}
                onClick={() => handleDynamicClick(item.artId)}
              >
                <Link to={'/'}>{item.title}</Link>
                {/*<span>{item.title}</span>*/}
                <span />
              </li>
            ))}
          </S.NoticeList>
        </S.Notice>
        <S.FunWrap>
          <S.ServiceCS>
            <div className="top">
              <h4>고객센터</h4>
              <p>015-8504-0006</p>
            </div>
            <span>상담시간: 평일 9:00~18:00(점심시간: 12:00~13:00)</span>
            <span className="csTimeText">
              상담시간
              <br />
              평일 9:00~18:00
              <br />
              (점심시간: 12:00~13:00)
            </span>
          </S.ServiceCS>
          {/* <S.FunLast className="appDownloadWrap">
            <button
              onClick={(e) => {
                e.preventDefault();
                onClickAppDownload();
              }}
              type="button"
            >
              <p>015톡 앱 다운로드 (안드로이드)</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path
                  d="M4.93812 5.76727L1.34601 1.19764C1.28608 1.12135 1.20645 1.0788 1.12364 1.0788C1.04084 1.0788 0.961209 1.12135 0.901273 1.19764L0.897405 1.2028C0.868248 1.23978 0.845032 1.2843 0.829167 1.33364C0.813303 1.38297 0.805121 1.43611 0.805121 1.4898C0.805121 1.54349 0.813303 1.59663 0.829167 1.64597C0.845032 1.69531 0.868248 1.73982 0.897405 1.7768L4.28003 6.07966L0.897405 10.3808C0.868249 10.4178 0.845032 10.4623 0.829167 10.5116C0.813303 10.561 0.805121 10.6141 0.805121 10.6678C0.805121 10.7215 0.813303 10.7746 0.829167 10.824C0.845032 10.8733 0.868249 10.9178 0.897405 10.9548L0.901273 10.96C0.961209 11.0362 1.04084 11.0788 1.12364 11.0788C1.20645 11.0788 1.28608 11.0362 1.34601 10.96L4.93812 6.39032C4.96971 6.35014 4.99486 6.3018 5.01204 6.24825C5.02923 6.1947 5.03809 6.13705 5.03809 6.0788C5.03809 6.02054 5.02923 5.96289 5.01204 5.90934C4.99486 5.85579 4.96971 5.80746 4.93812 5.76727Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </button>
          </S.FunLast> */}
        </S.FunWrap>
      </S.ResGroupWrap>

      <S.FunWrap>
        <S.FunFirst>
          <Link to={'https://www.arreo.com/usr/com/msgr_download.vw'} target="_blank">
            <S.FunFirstTitleWrap>
              <h4>양방향 엑셀 메신저</h4>
              <button type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                  <path
                    d="M4.93812 5.76727L1.34601 1.19764C1.28608 1.12135 1.20645 1.0788 1.12364 1.0788C1.04084 1.0788 0.961209 1.12135 0.901273 1.19764L0.897405 1.2028C0.868248 1.23978 0.845032 1.2843 0.829167 1.33364C0.813303 1.38297 0.805121 1.43611 0.805121 1.4898C0.805121 1.54349 0.813303 1.59663 0.829167 1.64597C0.845032 1.69531 0.868248 1.73982 0.897405 1.7768L4.28003 6.07966L0.897405 10.3808C0.868249 10.4178 0.845032 10.4623 0.829167 10.5116C0.813303 10.561 0.805121 10.6141 0.805121 10.6678C0.805121 10.7215 0.813303 10.7746 0.829167 10.824C0.845032 10.8733 0.868249 10.9178 0.897405 10.9548L0.901273 10.96C0.961209 11.0362 1.04084 11.0788 1.12364 11.0788C1.20645 11.0788 1.28608 11.0362 1.34601 10.96L4.93812 6.39032C4.96971 6.35014 4.99486 6.3018 5.01204 6.24825C5.02923 6.1947 5.03809 6.13705 5.03809 6.0788C5.03809 6.02054 5.02923 5.96289 5.01204 5.90934C4.99486 5.85579 4.96971 5.80746 4.93812 5.76727Z"
                    fill="white"
                    stroke="white"
                  />
                </svg>
              </button>
            </S.FunFirstTitleWrap>
          </Link>
        </S.FunFirst>
        <S.FunLast>
          <Link to={'https://em.seoultel.co.kr/login'} target="_blank">
            <p>엑셀 메신저 웹 버전 바로가기</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
              <path
                d="M4.93812 5.76727L1.34601 1.19764C1.28608 1.12135 1.20645 1.0788 1.12364 1.0788C1.04084 1.0788 0.961209 1.12135 0.901273 1.19764L0.897405 1.2028C0.868248 1.23978 0.845032 1.2843 0.829167 1.33364C0.813303 1.38297 0.805121 1.43611 0.805121 1.4898C0.805121 1.54349 0.813303 1.59663 0.829167 1.64597C0.845032 1.69531 0.868248 1.73982 0.897405 1.7768L4.28003 6.07966L0.897405 10.3808C0.868249 10.4178 0.845032 10.4623 0.829167 10.5116C0.813303 10.561 0.805121 10.6141 0.805121 10.6678C0.805121 10.7215 0.813303 10.7746 0.829167 10.824C0.845032 10.8733 0.868249 10.9178 0.897405 10.9548L0.901273 10.96C0.961209 11.0362 1.04084 11.0788 1.12364 11.0788C1.20645 11.0788 1.28608 11.0362 1.34601 10.96L4.93812 6.39032C4.96971 6.35014 4.99486 6.3018 5.01204 6.24825C5.02923 6.1947 5.03809 6.13705 5.03809 6.0788C5.03809 6.02054 5.02923 5.96289 5.01204 5.90934C4.99486 5.85579 4.96971 5.80746 4.93812 5.76727Z"
                fill="white"
                stroke="white"
              />
            </svg>
          </Link>
        </S.FunLast>
      </S.FunWrap>
    </S.NewsGroupWrap>
  );
};

export default NewsGroupWrap;
