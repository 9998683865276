import { ARREO_SMS } from '@/shared/constants';

/**
 * 숫자를 한국 돈, 숫자 형식으로 변환하는 커스텀 훅
 * ex) 100,000,000
 *
 * @param {number} price 변환할 숫자
 * @returns {string} 형식에 맞춘 문자열
 * */
export const amountFormat = (price: number): string => {
  const formatter = new Intl.NumberFormat('ko-KR', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(price);
};

// 결제금액에 맞춰 010 sms단가 계산
export const chargeCashSmsAmountFormat = (cash: number, point: number): string => {
  const price = (cash * +ARREO_SMS) / (cash + point);

  const formatter = new Intl.NumberFormat('ko-KR', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

  return formatter.format(price ? price : 0);
};

// 015 무료발송건수 계산 - 010 건수 계산하려면 해당 주석 살리고 함수 사용하는 곳에서 unitPrices 담으면 됌
// unitPrices 형식
const unitPrices = {
  SMS: 14,
  LMS: 42,
  MMS: 84,
};
// export const calculateCounts = (totalAmount: number, unitPrices: { [key: string]: number }) => {
export const calculateCounts = (totalAmount: number) => {
  const result: { [key: string]: number } = {};

  for (const [key, price] of Object.entries(unitPrices)) {
    const count = Math.floor(totalAmount / price);
    result[key] = count;
  }

  return result;
};
