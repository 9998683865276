import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { recycleBinListToggleState } from '@/recoil/atoms/addressList';
import { amlQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { IUseQueryAllMessageList } from '@/components/softPhone/DisplayGroups/MessageManagement/ChatRoom/ChatRoom.types';
import {
  scheduleQueryKeyRecoil,
  ScheduleSearchData,
  SearchData,
  transQueryKeyRecoil,
} from '@/recoil/atoms/sendResult/sendResult';
import { groupBuddyListParamsRecoil, searchTextRecoil } from '@/recoil/atoms/mainTableRecoil';

/**
 * @title 주소록 검색 관련 Hooks
 *
 * @author 김남규
 * @since 2024.03.20
 **/
export const useAddressSearch = () => {
  /* 주소록 메인 테이블 GET API PARAMS */
  const [, setGroupBuddyListParamsR] = useRecoilState(groupBuddyListParamsRecoil);
  /* 소프트폰 015 채팅방 리스트 GET API PARAMS */
  const [, setAmlQueryParams] = useRecoilState(amlQueryParamsRecoil);
  /* 소프트폰 010 전송 리스트 GET API PARAMS */
  const [, setTransQueryKey] = useRecoilState(transQueryKeyRecoil);
  /* 소프트폰 010 예약 리스트 GET API PARAMS */
  const [, setScheduleQueryKey] = useRecoilState(scheduleQueryKeyRecoil);
  /* 검색 항목 Select Option Value STATE */
  const [selectState, setSelectState] = useState('이름');
  /* 검색어 Input STATE */
  const [searchTextR, setSearchTextR] = useRecoilState(searchTextRecoil);
  /* 검색 항목 Select toggle STATE */
  const [selectSwitch, setSelectSwitch] = useState(false);
  /* 검색 항목 Select Option STATE */
  const [selectArrValue] = useState([
    { id: 0, name: 'name', koname: '이름' },
    { id: 1, name: 'number', koname: '휴대폰번호' },
    // { id: 2, name: 'email', koname: '이메일' },
  ]);
  const [recycleBinListToggle] = useRecoilState(recycleBinListToggleState);
  /* 검색 초기화 버튼 클릭을 감지하는 state */
  const [searchResetToggle, setSearchResetToggle] = useState<boolean>(false);

  const queryClient = useQueryClient();

  /**
   * @title 주소록 검색어 onChange Hook
   *
   * @author 김남규
   * @since 2024.03.20
   **/
  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTextR(value);
  };

  /**
   * @title 주소록 검색어 onSubmit Hook ( form 태그 enter로 실행가능 )
   *
   * @author 김남규
   * @since 2024.03.20
   **/
  const onSubmitSearchPost = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (recycleBinListToggle) {
      queryClient.refetchQueries(['recyclebinlist']);
    } else {
      queryClient.refetchQueries(['maingrouplist']);
    }
  };

  /**
   * @title 주소록 검색항목 onChange Hook
   *
   * @author 김남규
   * @since 2024.03.20
   **/
  const onClickSelectListButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    // select option 클릭 이벤트
    const result = e.target as HTMLButtonElement;
    if (typeof result.textContent === 'string') {
      setSelectState(result.textContent);
    }
  };

  /**
   * @title 주소록 검색어 onSubmit Hook ( 돋보기 클릭 )
   * 검색어, 검색항목을 제출합니다.
   * @author 김남규
   * @since 2024.03.20
   **/
  const onClickSearchButton = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) e.preventDefault();
    const searchTrimText = searchTextR.trim();
    // 주소록 검색 params
    setGroupBuddyListParamsR((prev) => {
      return { ...prev, keyword: searchTrimText };
    });
    // 015 채팅룸 검색 params
    setAmlQueryParams((prev: IUseQueryAllMessageList) => {
      return { ...prev, keyword: searchTrimText };
    });
    // 010 전송결과 리스트 검색 params
    setTransQueryKey((prev: SearchData) => {
      return { ...prev, keyword: searchTrimText };
    });
    // 010 예약전송 리스트 검색 params
    setScheduleQueryKey((prev: ScheduleSearchData) => {
      return { ...prev, keyword: searchTrimText };
    });
    const formEvent = new Event('submit') as unknown as React.FormEvent<HTMLFormElement>;
    onSubmitSearchPost(formEvent);
  };

  /**
   * @title 주소록 검색창 초기화 hook
   * */
  const onClickSearchDelBtn = () => {
    setSearchTextR('');
    setSearchResetToggle(true);
  };
  useEffect(() => {
    if (searchResetToggle) {
      onClickSearchButton();
      setSearchResetToggle(false);
    }
  }, [searchResetToggle]);

  return {
    onSubmitSearchPost,
    selectSwitch,
    setSelectSwitch,
    selectState,
    selectArrValue,
    onClickSelectListButton,
    onChangeSearchInput,
    searchTextR,
    onClickSearchButton,
    onClickSearchDelBtn,
  };
};
