import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import Loader from '@/components/common/Loader';
import { theme } from '@/styles/theme';
import * as S from './MobileSignUpIdCheck.style';
import { PhnIdProps } from '@/pages/SignUpPage/signUpIdCheck/SignUpIdCheck.types';
import { useMobileSignUpIdCheck } from './useMobileSignUpIdCheck';
import BaseButton from '@/components/Atom/BaseButton';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useRecoilValue } from 'recoil';

const MobileSignUpIdCheck = () => {
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);
  const naviState = environmentS === 'app' ? '/mobile/mobileSignin' : '/signin';

  const { handleIdRecovery, keepSignUp, handleAccountDelete, signUpInfo, fetchData, dataListLoading } =
    useMobileSignUpIdCheck();

  return (
    <S.SignUpContainer>
      <Suspense fallback={<Loader backgroundToggle />}>
        <S.SignUpFormWrapper>
          <S.Say015ApplyTitle>
            <span className="mainColor">아이디 조회결과 </span>입력하신 정보와 일치하는 아이디는 아래와 같습니다.
          </S.Say015ApplyTitle>
          <S.SignUpLine className="top" />
          {fetchData?.phnIds?.map((number: PhnIdProps, id: number) => {
            return (
              <>
                {/* 구분선 */}
                {number.dealGb === '1' ? null : <S.DividingLine />}
                <S.SignUpSubTitleWrap key={number.phnId}>
                  <S.IdCheckSmallWrap>
                    {/* <SignUpSubTitle>아이디 {id + 1}</SignUpSubTitle> */}

                    {number.dealGb === '1' ? (
                      <S.SignUpTitle>사용계정</S.SignUpTitle>
                    ) : (
                      <S.SignUpTitle>휴면아이디</S.SignUpTitle>
                    )}
                  </S.IdCheckSmallWrap>
                  <S.IdCheckBigWrap>
                    <S.SignUpSubTitle className={`${number.dealGb === '1' ? '' : 'dormant'}`}>
                      {number.phnId.replace(/(\d{7})\d{4}(\D*)/, (match, group1, group2) => {
                        const lastFourDigits = group1; // 숫자 중 마지막 4자리
                        if (group2 === '') {
                          return `${lastFourDigits}****`; // 마지막 4자리 외의 숫자를 ****로 변경
                        }
                        return `${lastFourDigits}****`; // 마지막 4자리 외의 숫자를 ****로 변경
                        // return `${lastFourDigits}****${remainingChars}`; // 마지막 4자리 외의 숫자를 ****로 변경
                      })}
                    </S.SignUpSubTitle>
                    {number.dealGb === '1' ? (
                      <>
                        {/* <BaseButton className="main">
											<Link to="/signin">로그인</Link>
										</BaseButton> */}

                        <BaseButton
                          className="dark"
                          name="findPw"
                          value={number.phnId}
                          onClick={
                            (e: React.MouseEvent<HTMLElement>) =>
                              handleIdRecovery(
                                e,
                                number.rsrvdId,
                                fetchData.encodeData,
                                fetchData.inputNumber,
                                number.dealGb,
                              )
                            // handleIdRecovery(e, fetchData.encodeData, fetchData.inputNumber, number.dealGb)
                          }
                        >
                          비밀번호 변경
                        </BaseButton>
                      </>
                    ) : (
                      <>
                        <BaseButton
                          className="main"
                          name="RecoveryId"
                          value={number.phnId}
                          onClick={(e: React.MouseEvent<HTMLElement>) =>
                            handleIdRecovery(
                              e,
                              number.rsrvdId,
                              fetchData.encodeData,
                              fetchData.inputNumber,
                              number.dealGb,
                            )
                          }
                        >
                          아이디 복원
                        </BaseButton>
                        <BaseButton
                          className="dark"
                          value={number.phnId}
                          onClick={(e) => handleAccountDelete({ e, data: signUpInfo.data.encodeData })}
                        >
                          아이디 삭제
                        </BaseButton>
                      </>
                    )}
                  </S.IdCheckBigWrap>
                  <S.SignUpSub>
                    (가입일 : {number.regDate}){/* {number.dealGb !== '1' && ' -- 휴면아이디'} */}
                  </S.SignUpSub>
                </S.SignUpSubTitleWrap>
              </>
            );
          })}
          <S.SignUpLine className="bottom" />
          <S.Say015ApplyServiceGuide>
            개인정보 도용에 따른 피해방지를 위해 끝4자리는 *로 표시합니다.
            <br /> 만일 도용으로 가입된 아이디가 있는 경우, 고객센터(015-8504-0006)를 통해 본인 확인 절차를 거친 후
            탈퇴도 가능합니다.
            <br /> 휴면아이디란?
            <br /> 아이디는 가입이 되어 있으나 사용할 수 없는 아이디를 말합니다.
            <br /> 1. 사이트를 오랫동안 방문하지 않은 회원의 아이디
            <br /> 2. 휴대폰번호가 변경이 되었으나 변경하지 못해, 이미 다른 회원이 동일한 휴대폰번호로 가입한 경우
            <br /> * 사용하시려면 아이디 복원을, 더 이상 사용을 원치 않을 경우 아이디 삭제 버튼을 클릭하여 주세요.
            <br /> 아이디 삭제와 동시에 현재 회원님의 서울이동통신 캐쉬 및 포인트,
            <br /> 그리고 주소록 내의 연락처가 모두 삭제되며 회원님이 이용하셨던 모든 정보서비스 이용이 불가능하게
            됩니다.
            <br /> 또한 회원약관에 따라 아이디 삭제 후, 1개월 동안은 동일한 번호로 재가입이 불가능하오니 이점 양지하시기
            바랍니다.
          </S.Say015ApplyServiceGuide>
          <S.AgreementButtonWrap>
            <BaseButton padding="1rem 2rem" fontWeight="bold" onClick={keepSignUp}>
              계속 가입
            </BaseButton>
          </S.AgreementButtonWrap>
        </S.SignUpFormWrapper>
      </Suspense>
    </S.SignUpContainer>
  );
};
export default MobileSignUpIdCheck;
