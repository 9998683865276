import React, { useEffect, useState } from 'react';
import * as S from './MultiChatResult.style';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { environmentRecoil, resultDataRecoil, resultOpenRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { instance } from '@/shared/lib/clientAxios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { useLocation } from 'react-router-dom';
import MobileLoader from '../MobileLoader';
import NoData from '@/components/common/NoData';
import { formatDateBase } from '@/shared/util/format/dateFormatUtil';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import { useMobileChatRoomImg } from '../mobileHooks/useMobileChatRoomImg';
import { detectEnvUtils } from '@/shared/util/detectEnvUtils';
import { Spin } from 'antd';
import { chatRoomTypeRecoil } from '@/recoil/atoms/chatRoom';

const MultiChatResult: React.FC = () => {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>(''); // 디바운스된 값

  //리코일
  const [resultOpenS, setResultOpens] = useRecoilState(resultOpenRecoil);
  const [resultDataS, setResultDataS] = useRecoilState(resultDataRecoil);
  const resultResultDataS = useResetRecoilState(resultDataRecoil);
  const environmentS = useRecoilValue(environmentRecoil);

  const [activeTab, setActiveTab] = useState<string>('전송완료'); // 활성화된 탭
  // 상태 조건 배열
  const rsltValArr = [
    { label: '전송완료', value: '-100' },
    { label: '전송중', value: '99' },
    { label: '전송실패', value: '100' },
  ];

  const activeStatus = rsltValArr.find((item) => item.label === activeTab)?.value; // 현재 탭 상태 값 조회

  const onClickResultBox = () => {
    // 모달창 닫기
    setResultOpens(false);
    // 전송창에 있던 데이터 지우기
    resultResultDataS();
    if (environmentS !== 'pc') {
      // 브라우저 뒤로가기
      window.history.back();
    }
  };

  //뒤로가기 눌렀을 시 실행될 로직
  useEffect(() => {
    if (window.location.hash !== '#result' && environmentS === 'app') {
      // 모달창 닫기
      setResultOpens(false);
      // 전송창에 있던 데이터 지우기
      resultResultDataS();
    }
  }, [location.hash]);

  // 검색 디바운스 처리
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchValue(searchValue); // searchValue가 변경된 후 일정 시간이 지나면 debouncedSearchValue 업데이트
    }, 500); // 500ms 후에 값 업데이트

    return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 클리어
  }, [searchValue]);

  // API 호출 함수
  const fetchResultData = async ({ pageParam = 0 }) => {
    const response = await instance.get('/smt/history/batch/result/list', {
      params: {
        prepayPayNo: resultDataS.prepayPayNo,
        status: activeStatus, // 현재 탭 상태를 쿼리로 전송
        keyword: debouncedSearchValue, // 디바운스된 검색어 사용
        pageNumber: pageParam,
        pageSize: 60, // 한 번에 가져올 데이터 개수
      },
    });
    return response.data;
  };

  // 인피니티 스크롤 데이터 관리
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, refetch } = useInfiniteQuery(
    ['resultData', activeTab, debouncedSearchValue],
    fetchResultData,
    {
      getNextPageParam: (lastPage, pages) => {
        return pages.length < lastPage.totalPages ? pages.length + 1 : undefined; // 페이지 인덱스 증가
      },
    },
  );

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  // 이미지 팝업을 위한 훅
  const imageHooks = useMobileChatRoomImg();

  // 앱에서 이미지 다운로드
  const onClickImgDown = () => {
    if (detectEnvUtils() === 'app') {
      // 앱 환경에서 처리
      const base64Data = imageHooks.closeUpImg; // Base64 이미지 데이터
      const fileName = 'downloaded-image.jpg'; // 다운로드할 파일 이름
      const message = JSON.stringify({ base64Data, fileName });

      // 앱에 다운로드 요청
      window.fromWebToAppReqDownload(message);
    } else {
      // 웹 환경에서 처리
      const link = document.createElement('a');
      link.href = `data:image/jpeg;base64,${imageHooks.closeUpImg}`; // Base64 URL
      link.download = 'downloaded-image.jpg'; // 다운로드할 파일 이름
      link.click(); // 다운로드 실행
    }
  };

  const formatData = data?.pages.flatMap((page) => page.content || []);

  return (
    <S.ResultContainer>
      <S.ResultHeader>
        <S.ResultHeaderLeft>
          <div
            onClick={() => {
              onClickResultBox();
            }}
          >
            <IconArrowLongLeft />
          </div>
          <div>전송 결과</div>
        </S.ResultHeaderLeft>
      </S.ResultHeader>

      <S.ResultMain>
        <S.ResultMessage>
          {resultDataS.imgData && (
            <img
              onClick={() => {
                resultDataS.imgData && imageHooks.closeUpImgHook(resultDataS.imgData);
              }}
              src={`data:image/jpeg;base64,${resultDataS.imgData}`}
              alt="Message"
              style={{ maxWidth: '8rem', maxHeight: '8rem', height: 'auto' }}
            />
          )}
          <div>{resultDataS.sndMsg}</div>
        </S.ResultMessage>
        <S.ResultMessageInfo>
          <div>{formatDateBase(resultDataS.sndDttm)}</div>
          <div>
            {resultDataS.msgGb} {calMsgByte(resultDataS.sndMsg)}Byte
          </div>
        </S.ResultMessageInfo>

        <S.CheckTabContainer>
          {rsltValArr.map((tab) => (
            <S.CheckTab
              key={tab.label}
              isActive={activeTab === tab.label} // 활성화된 탭인지 여부 전달
              onClick={() => setActiveTab(tab.label)} // 클릭 시 활성화된 탭 업데이트
            >
              {tab.label}
            </S.CheckTab>
          ))}
        </S.CheckTabContainer>

        <S.ResultCheck onScroll={handleScroll}>
          <S.InputContainer>
            <S.ListSearchInput
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value); // 검색어 입력 시 상태 업데이트
              }}
              placeholder="이름 및 번호로 검색"
            />
          </S.InputContainer>

          {formatData && formatData.length > 0 ? (
            formatData.map((item, index) => (
              <S.CheckListItem key={item.rcvPhnId}>
                <div>
                  {item.buddyName} / {TelHyphen(item.rcvPhnId)}
                </div>
                <div>
                  {String(item?.rsltVal) === '-100' && '성공'}
                  {String(item?.rsltVal) === '99' && '전송중'}
                  {!(String(item?.rsltVal) === '99' || String(item?.rsltVal) === '-100') && '실패'}
                </div>
              </S.CheckListItem>
            ))
          ) : (
            <NoData />
          )}
          <MobileLoader isLoading={isLoading} />
          {isFetchingNextPage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.6rem',
                padding: '2rem 0',
              }}
            >
              <Spin size="default" />
              <span style={{ marginLeft: 8 }}>로딩 중...</span>
            </div>
          )}
        </S.ResultCheck>
      </S.ResultMain>

      {imageHooks.modalSwitch ? (
        <S.MobileChattingBigImgModal className="mobileBigImg" onClick={imageHooks.autoClosePopup}>
          <S.MobileChattingBigImgWrap className="closeUpView">
            <S.MobileChattingBigImg src={`data:image/jpeg;base64,${imageHooks.closeUpImg}`} alt="testimage" />
            <S.EtcChattingBigImageModalBtnWrap className="imgBtnWrap">
              <S.MobileEtcChattingBtn className="imgDownload" type="button" onClick={() => onClickImgDown()}>
                {/* 다운로드 버튼 */}
                <p>다운로드</p>
              </S.MobileEtcChattingBtn>
              <S.MobileEtcChattingBtn className="imgClose" type="button" onClick={imageHooks.closeUpImgPopupClose}>
                <p>닫기</p>
              </S.MobileEtcChattingBtn>
            </S.EtcChattingBigImageModalBtnWrap>
          </S.MobileChattingBigImgWrap>
        </S.MobileChattingBigImgModal>
      ) : null}
    </S.ResultContainer>
  );
};

export default MultiChatResult;
