import { checkGroupListState } from '@/recoil/atoms/addressList';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { checkChildrenArrR, checkGroupArrR, unCheckChildrenArrR } from '@/recoil/atoms/messageSend/messageSend';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
/**
 * @title 주소록 그룹리스트 체크박스 관련 Hooks
 *
 * @author 김남규
 * @since 2024.03.22
 **/
export const useCheckGroup = ({ groupList }: any) => {
  /* 주소록 테이블 체크상태 관리 하는 recoil */
  // ? 그룹 리스트 체크 array
  const [checkGroupArrS, setCheckGroupArrS] = useRecoilState(checkGroupArrR);
  // ? 하위 리스트 체크 true array
  const [, setCheckChildrenArrS] = useRecoilState(checkChildrenArrR);
  // ? 하위 리스트 체크 false array
  const [, setUnCheckChildrenArrS] = useRecoilState(unCheckChildrenArrR);
  /* 그룹리스트 전체 체크박스 toggle STATE */
  const [selectAllS, setSelectAllS] = useState(false);
  const [checkedGroupListS, setCheckedGroupListS] = useRecoilState(checkGroupListState);
  const [mainTableCheckDataFormatR, setMainTableCheckDataFormatR] = useRecoilState(mainTableCheckDataFormatRecoil);
  // ========================================
  // 그룹 체크박스 상태 변경에 따른 주소록 체크박스 변경
  // ========================================
  const updatedAddressTableCheckBox = (type: 'all' | 'group', groupSeqNo?: number, allChecked?: boolean) => {
    if (!checkGroupArrS) return;
    if (type === 'group' && groupSeqNo) {
      setCheckChildrenArrS((prevState) => (prevState || []).filter((f) => f.groupSeqNo !== groupSeqNo));
      setUnCheckChildrenArrS((prevState) => (prevState || []).filter((f) => f.groupSeqNo !== groupSeqNo));
      const checkedState = checkedGroupListS.includes(groupSeqNo);
      setCheckGroupArrS((prevState) => {
        return (prevState || []).map((item) => {
          if (item.groupSeqNo === groupSeqNo) {
            return {
              ...item,
              isChecked: !checkedState,
              isChildrenChecked: false,
            };
          }
          return item;
        });
      });
    }
    if (type === 'all' && allChecked !== undefined) {
      setCheckChildrenArrS(null);
      setUnCheckChildrenArrS(null);
      setCheckGroupArrS((prevState) => {
        return (prevState || []).map((item) => {
          return {
            ...item,
            isChecked: allChecked,
            isChildrenChecked: false,
          };
        });
      });
    }
  };
  // ==============================
  // 그룹 체크박스 toggle Hook
  // ==============================
  const updateDeleteGroupList = (groupSeqNo: number) => {
    const checkedState = checkedGroupListS.includes(groupSeqNo);
    updatedAddressTableCheckBox('group', groupSeqNo);
    // mutate({ groupSeqNos: [groupSeqNo] });
    const updatedGroupList = checkedState
      ? checkedGroupListS.filter((id: any) => id !== groupSeqNo)
      : [...checkedGroupListS, groupSeqNo];
    setCheckedGroupListS(updatedGroupList);
  };
  // ==============================
  // 그룹 전체 체크박스 Hook
  // ==============================
  const updateAllGroups = (selectAll: boolean) => {
    updatedAddressTableCheckBox('all', 0, selectAll);
    /* 사용자가 가지고 있는 그룹의 시퀀스 값을 담는 변수 */
    const allGroupSeqNos = groupList?.groupList?.map((el: any) => el.groupSeqNo) || [];
    /* 기존에 체크 되어있던 그룹을 제외한 나머지 그룹의 시퀀스 값 담는 변수 */
    const updatedGroupList = Array.from(new Set([...checkedGroupListS, ...allGroupSeqNos])); // 중복 제거
    if (selectAll) {
      setCheckedGroupListS(updatedGroupList);
      // mutate({ groupSeqNos: updatedGroupList });
    } else {
      setCheckedGroupListS([]);
      setMainTableCheckDataFormatR(null);
      // mutate({ groupSeqNos: updatedGroupList });
    }
  };
  // ==============================
  // 개별 체크박스 상태에 따라 전체 체크박스 상태 변경 useEffect
  // ==============================
  useEffect(() => {
    // 모든 하위 체크박스가 체크된 경우 전체 체크박스도 체크되도록 설정
    if (groupList && checkedGroupListS.length < 1) {
      setSelectAllS(false);
      return;
    }
    setSelectAllS(groupList?.groupList?.every((el: any) => checkedGroupListS.includes(el.groupSeqNo)) || false);
  }, [checkedGroupListS, groupList]);
  // ==============================
  // 전체 체크박스 toggle STATE
  // ==============================
  const toggleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectAll = event.target.checked;
    setSelectAllS(newSelectAll);
    if (newSelectAll) {
      // 전체 선택일 경우
      updateAllGroups(true);
    } else {
      // 전체 선택이 아닐 경우
      updateAllGroups(false);
    }
  };
  return { selectAllS, toggleSelectAll, updateDeleteGroupList, checkedGroupListS };
};
