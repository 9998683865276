import { useModalHook } from '@/components/commons/modals/useModalHook';
import { clickGroupNameRecoil, copyBuddySeqNoRecoil, detailBuddyDataRecoil } from '@/recoil/atoms/addressList';
import { listToggleRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { BuddyData } from '@/types/index.types';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAddressBookMutations } from '../mutations/addressBook/useAddressBookMutations';

export const useAddressTableHover = () => {
  /* 주소록 삭제 mutation */
  const deleteHook = useAddressBookMutations();
  /* 주소록 개별 상세페이지 toggle STATE */
  const [addressDetail, setAddressDetail] = useState<{ [key: number]: boolean }>({});
  /* 주소록 개별 복사 select toggle STATE */
  const [addressCopy, setAddressCopy] = useState<{ [key: number]: boolean }>({});
  /* 주소록 개별 복사를 위해 해당 값만 따로 담아놓는 recoil */
  const [copyBuddySeqNoS, setCopyBuddySeqNoS] = useRecoilState(copyBuddySeqNoRecoil);
  /* 주소록 상세 페이지를 위해 해당 값만 따로 담아놓는 recoil */
  const [detailBuddyDataS, setDetailBuddyDataS] = useRecoilState(detailBuddyDataRecoil);
  /* 좌측페이지 주소록 상태 STATE */
  const listToggleS = useRecoilValue(listToggleRecoil);
  /* 표출하는 그룹이 변하면 상세페이지 닫기위해 받아오는 선택 그룹 Recoil */
  const clickGroupNameS = useRecoilValue(clickGroupNameRecoil);

  // 주소록 복사 훅
  const { copyM, copyL } = useAddressBookMutations();

  const { confirmModal, warningModal } = useModalHook();

  // ==============================
  // 소프트폰탭이 ars로 변경되면 상세페이지 off 를 위한 hook
  // ==============================
  useEffect(() => {
    onClickCloseDetailBtn();
  }, []);

  useEffect(() => {
    if (listToggleS || clickGroupNameS) onClickCloseDetailBtn();
  }, [listToggleS, clickGroupNameS]);

  useEffect(() => {
    const hasTrueValue = Object.values(addressDetail).some((value) => value === true);
    if (!hasTrueValue && detailBuddyDataS) {
      setDetailBuddyDataS(null);
    }
  }, [addressDetail, detailBuddyDataS]);

  // ==============================
  // onClick 주소록 개별 호버 삭제버튼 hook
  // ==============================
  const onClickHoverDel = (buddySeqNo: number) => {
    confirmModal(
      async () => deleteHook.deletedM([buddySeqNo]),
      '주소록 삭제',
      '해당 연락처를 삭제하시겠습니까?</br>삭제한 연락처는 휴지통에서 복원 가능합니다.',
      true,
    );
  };

  // ==============================
  // onClick 주소록 개별 호버 복사버튼 hook
  // ==============================
  const onClickHoverCopy = (id: any) => {
    setAddressCopy((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // ==============================
  // onClick 개별 호버 복사 select 외부 클릭 hook
  // ==============================
  const onClickoutLine = () => {
    setAddressCopy({});
  };

  // ==============================
  // onClick 주소록 상세페이지 버튼 hook
  // ==============================
  const onClickDetailBtn = (list: BuddyData) => {
    handleOpenDetailModal(list.buddySeqNo);
    if (detailBuddyDataS && detailBuddyDataS.buddySeqNo === list.buddySeqNo) {
      setDetailBuddyDataS(null);
    } else {
      setDetailBuddyDataS(list);
    }
  };

  // 주소록 상세페이지 버튼 hook
  const handleOpenDetailModal = (id: number) => {
    setAddressDetail((prev) => {
      const newState = Object.keys(prev).reduce((acc: any, key) => {
        acc[key] = false; // 모든 키를 false로 설정
        return acc;
      }, {});

      newState[id] = !prev?.[id]; // 특정 id만 토글하고, undefined인 경우 false로 설정
      return newState;
    });
  };
  // ==============================
  // onClick 주소록 상세페이지 닫기 버튼 hook
  // ==============================
  const onClickCloseDetailBtn = () => {
    setAddressDetail((prev) => {
      const newState = Object.keys(prev).reduce((acc: any, key) => {
        acc[key] = false; // 모든 키를 false로 설정
        return acc;
      }, {});
      return newState;
    });
    setDetailBuddyDataS(null);
  };

  // onClick 개별 주소록 복사 select
  const onClickCopySelect = (list: BuddyData) => {
    onClickHoverCopy(list.buddySeqNo);
    setCopyBuddySeqNoS(list.buddySeqNo);
  };

  // onClick 개별 주소록 리스트 선택 hook
  const onClickCopyGroup = (group: any) => {
    if (copyBuddySeqNoS) {
      confirmModal(
        async () =>
          copyM(
            {
              groupSeqNo: group.groupSeqNo,
              buddySeqNos: [copyBuddySeqNoS],
            },
            {
              onSuccess: () => {
                setAddressCopy({});
              },
              onError: () => {
                setAddressCopy({});
                warningModal('실패', '주소록 복사를 실패했습니다. 새로고침 후 다시 진행해주세요.', true);
              },
            },
          ),
        '주소록 복사',
        `'${group.groupNm}' 그룹으로 복사 하시겠습니까?`,
        true,
      );
    } else {
      warningModal('주소록 복사', '선택된 연락처가 없습니다.', true);
    }
  };

  return {
    onClickHoverDel,
    onClickDetailBtn,
    addressDetail,
    onClickCloseDetailBtn,
    onClickHoverCopy,
    addressCopy,
    onClickoutLine,
    onClickCopySelect,
    onClickCopyGroup,
    detailBuddyDataS,
  };
};
