import ATitle from '@/components/Atom/ATitle';
import BaseTable from '@/components/Organism/Table';
import Loader from '@/components/common/Loader';
import Paginations01Index from '@/components/common/paginations/Pagination02/Pagination02.index';
import { useTaxInvoiceHistory } from '@/components/hooks/customs/charge/useTaxInvoiceHistory';
import React from 'react';
import * as S from './MobileEtcTaxInvoiceDetail.style';

const MobileEtcTaxInvoiceDetail = () => {
  const {
    totalPage,
    handlePageChange,
    activePage,
    setActivePage,
    startPage,
    setStartPage,
    currentPage,
    taxBliiData,
    thead,
    isSuccess,
    isLoading,
  } = useTaxInvoiceHistory();

  return (
    <S.TaxInvoiceHistoryWrap className="TaxInvoiceHistoryWrap">
      <ATitle type="sub" text="세금계산서 발행 내역" />
      <S.TableDiv>
        <BaseTable type="line" thead={thead} tbody={taxBliiData} onclick={(e: any) => {}} />
        {isLoading && <Loader backgroundToggle />}
      </S.TableDiv>
      <S.TableFoot>
        <Paginations01Index
          dataCount={totalPage}
          startPage={startPage}
          setStartPage={setStartPage}
          activePage={activePage}
          setActivePage={setActivePage}
          eventHook={handlePageChange}
          pageSize={10}
        />
      </S.TableFoot>
    </S.TaxInvoiceHistoryWrap>
  );
};
export default MobileEtcTaxInvoiceDetail;
