import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { pushAlertRecoil } from '@/recoil/atoms/atoms';

/**
 * @title NotifyComponent.tsx hook 크롬 웹 푸시, 메세지 푸시 hooks
 *
 * @author 정휘학
 * @since 2024.05.21
 * */
export const useNotifyComponentHook = () => {
  // 푸시알림 팝업창 표출 토글 state
  const [pushToggleS, setPushToggleS] = useState<boolean>(false);
  // 푸시알림 팝업창 표출 토글 핸들러 hook
  const handleTogglePushPopup = () => {
    setPushToggleS((prev) => !prev);
  };
  // 푸시알림 체크 상태 state
  const pushAlertS = useRecoilValue(pushAlertRecoil);
  // 종 이미지
  const [pushIconUrlS, setPushIconUrlS] = useState<string>('notifyIcon_n.png');

  useEffect(() => {
    if (pushAlertS.smsAlertStatus && pushAlertS.chromeAlertStatus) {
      setPushIconUrlS('notifyIcon_f.png');
    } else if (pushAlertS.smsAlertStatus || pushAlertS.chromeAlertStatus) {
      setPushIconUrlS('notifyIcon_h.png');
    } else {
      setPushIconUrlS('notifyIcon_n.png');
    }
  }, [pushAlertS]);

  return {
    pushToggleS,
    handleTogglePushPopup,
    pushAlertS,
    pushIconUrlS,
    setPushToggleS,
  };
};
