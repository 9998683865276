import React from 'react';
import * as S from '../../MobileHeader.style';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';

interface ChatPopupListHeaderProps {
  onClose: () => void;
}

const ChatPopupListHeader: React.FC<ChatPopupListHeaderProps> = ({ onClose }) => {
  return (
    <S.HeaderLeft className="addChatHeader">
      <S.HeaderButtonIcon onClick={onClose}>
        <IconArrowLongLeft />
      </S.HeaderButtonIcon>
      <S.HeaderTitle>새로운 채팅</S.HeaderTitle>
    </S.HeaderLeft>
  );
};

export default ChatPopupListHeader;
