import React from 'react';
import * as SA from '../ServiceSignUp/AmountHelp/AmountHelp.styles';
import SubscriptionGuide from '../ServiceSignUp/SubscriptionGuide/SubscriptionGuide';
import * as SB from '../../../../styles/button.styles';
import * as SH from '../styles/fixedStyle/height.styles';
import * as S from './ReSubscription.styles';
import { usePOBox015Resub } from '@/components/hooks/customs/say015/pobox015/usePOBox015Resub';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import { amountFormat } from '@/shared/util/format/amountFormatUtil';
import { SERVICE_NAME } from '@/shared/constants';

const ReSubscription = ({ authorityDataRefetch, authorityDataState }: any) => {
  const { completeHandle, postResub015State } = usePOBox015Resub({
    authorityDataRefetch,
  });

  return (
    // 소프트폰 재구독 페이지
    <S.ReSubscription className="ReSubscription">
      <S.ReSubscriptionWrap>
        {/* 구독 안내 */}
        <SubscriptionGuide />

        <SH.GroupWrap className="amountGroup">
          {/* 안내 */}
          <S.ReSubscriptionGroup>
            <div className="phoneGroup">
              <h2>가입 정보</h2>
              <p className="phone">{formatPhoneNumber(authorityDataState?.number015)}</p>
            </div>
            {/* <p className="comment">
              발급 번호 변경 불가, 이전 구독 정보에 대한 안내 문구 추가하기 <br /> 발급 번호 변경 불가, 이전 구독 정보에
              대한 안내 문구 추가하기
            </p> */}
          </S.ReSubscriptionGroup>

          {/* 구독료 */}
          <SA.AmountGroup>
            <div className="top">
              {/* <p>My 015 가입 첫 달 무료 </p> */}
              <h3>월 통신료 {amountFormat(authorityDataState.monthlyPrice)}원</h3>
            </div>
            {/* <p className="arrowRow">
              <span className="arrowRowSpan" />
            </p>
            <div className="bottom">
              <p>
                무료 기간 이후 <b>월 {authorityDataState?.monthlyPrice}원</b>의 통신료가
                <br />
                서울이동통신 충전캐쉬에서 자동 결제됩니다.
              </p>
            </div> */}
          </SA.AmountGroup>
        </SH.GroupWrap>
      </S.ReSubscriptionWrap>

      <SB.Btn100>
        <button className="startBtn" onClick={() => completeHandle(authorityDataState)} disabled={postResub015State}>
          {SERVICE_NAME} 통신료 결제하기
        </button>
      </SB.Btn100>
    </S.ReSubscription>
  );
};

export default ReSubscription;
