/* 소프트폰 표출 컴포넌트 키 */
import { atom } from 'recoil';

// * ============================================================================================
// * 좌측에 표출되는 컴포넌트와 소프트폰에 표출되는 컴포넌트를 관리하는 Recoil 파일
// * ============================================================================================

/**
 * @titlge 소프트폰에 표출될 컴포넌트를 관리하는 recoil
 * */
export type SoftPhoneComponentType = 'chatroom' | 'ars' | 'send' | 'schedule' | 'sms';
export const softPhoneComponentRecoil = atom<SoftPhoneComponentType>({
  key: 'softPhoneComponentRecoil',
  default: 'chatroom',
});

// 소프트폰 010 문자발송 하위탭 버튼 Recoil - 문자 전송하기, 문자 예약하기
export const softPhoneRowTabRecoil = atom<string>({
  key: 'softPhoneRowTabRecoil',
  default: '0',
});

// 소프트 폰 상위 라우팅 버튼 Recoil - 015, 010, ars
export type SoftPhoneTopTabRecoilType = '0' | '1' | '2';
export const softPhoneTopTabRecoil = atom<SoftPhoneTopTabRecoilType>({
  key: 'softPhoneTopTabRecoil',
  default: '0',
});

/**
 * @title 좌측에 표출될 컴포넌트를 관리하는 recoil
 * */
export type LeftZoneComponentRecoilType = 'address' | 'ars' | 'send' | 'schedule' | 'addLargeTable';
export const leftZoneComponentRecoil = atom<LeftZoneComponentRecoilType>({
  key: 'leftZoneComponentRecoil',
  default: 'address',
});

// 주소록을 제외한 나머지를 표출하기 위해서는 해당 recoil 값이 true 여야함 주소록은 false
export const listToggleRecoil = atom<boolean>({
  key: 'listToggleRecoil',
  default: false,
});

// 메세지 저장함 토글 on & off
export const softPhoneSaveMessageRecoil = atom<boolean>({
  key: 'softPhoneSaveMessageRecoil',
  default: false,
});

interface GlobalModalState {
  isOpen: boolean;
  content: React.ReactNode | null;
  closeOnOverlayClick: boolean;
}

export const globalModalStateRecoil = atom<GlobalModalState>({
  key: 'globalModalStateRecoil',
  default: {
    isOpen: false,
    content: null,
    closeOnOverlayClick: false,
  },
});

export type TMNav = '주소록' | '인사말설정' | '메세지' | '회원정보' | '공지사항' | 'QnA' | 'VOC';
//모바일 네비 체크 상태
export const mobileNavRecoil = atom<TMNav>({
  key: 'mobileNavRecoil',
  default: '메세지',
});

//전체 화면 가로 사이즈
export const windowWidthRecoil = atom<number>({
  key: 'windowWidthRecoil',
  default: window.innerWidth,
});

//모바일 리스트 on&off
export const mobileBuddyListRecoil = atom<boolean>({
  key: 'mobileBuddyListRecoil',
  default: false,
});

// 모바일 유저페이지 nav 상태 RECOIL
export const selectedComponentRecoil = atom<string | null>({
  key: 'selectedComponentRecoil',
  default: 'mypage',
});

// 그룹 편집 상태 리코일
export const editModeRecoil = atom<boolean>({
  key: 'editModeRecoil',
  default: false,
});

export const isLongPressRecoil = atom<boolean>({
  key: 'isLongPressRecoil',
  default: false,
});

// 다크모드 타입
export type TThemeMode = 'lightTheme' | 'darkTheme';
// 다크모드 관리할 RECOIL
export const isDarkModeRecoil = atom<TThemeMode>({
  key: 'isDarkModeRecoil',
  default: (localStorage.getItem('theme') as TThemeMode) ?? 'lightTheme',
});
