/* eslint-disable */
import { GroupListType } from '@/apis/api/addressApis';
import { useAddLargeTable } from '@/components/hooks/customs/addLargeTable/useAddLargeTable';
import React, { useEffect, useRef, useState } from 'react';
import AddLargeExcel from './AddLargeExcel';
import AddLargeHead from './AddLargeHead';
import * as S from './AddLargeTable.index.styles';
import * as SS from '@/styles/selectBox.styles';
import * as MS from '@/pages/main/components/addressLeftZone/addressBookTable/AddressBookTable.styles';
import { addressLargeColumViewLabelRecoil, addressLargeColumViewValueRecoil } from '@/recoil/atoms/addressColum';
import { useRecoilValue } from 'recoil';
import GroupListSelect from '../main/components/addressLeftZone/groupList/GroupListSelect';

/**
 * @title 주소록 대량 추가 컴포넌트
 * */
const AddLargeTable = () => {
  const addLargeHooks = useAddLargeTable();

  const inputRefs = useRef<Array<{ [key: string]: HTMLInputElement | null }>>([]);

  // 특정 인덱스와 필드에 대한 참조를 설정
  const setInputRef = (index: number, value: string, el: HTMLInputElement | null) => {
    if (!inputRefs.current[index]) {
      inputRefs.current[index] = {};
    }
    inputRefs.current[index][value] = el;
  };

  const addressLargeColumViewValueR = useRecoilValue(addressLargeColumViewValueRecoil);
  const addressLargeColumViewLabelR = useRecoilValue(addressLargeColumViewLabelRecoil);

  // // ====================================================
  // // 주소록 메인 테이블 리스트의를 감지해서 열 너비 사이즈를 맞춰주는 로직
  // // ====================================================
  const [tableTdWidth, setTableTdWidth] = useState<number>(200);
  const syncColumnWidths = () => {
    const table = document.getElementById('largeTable');

    if (table) {
      const thead = table.querySelector('#largeTable thead');
      if (!thead) return;
      setTableTdWidth(thead.clientWidth / addressLargeColumViewValueR.size - 5);
    }
  };
  // // ====================================================
  // // 주소록 메인 테이블 리스트의를 감지해서 열 너비 사이즈를 맞춰주는 로직
  // // ====================================================
  useEffect(() => {
    // 열 너비 동기화 함수 호출
    syncColumnWidths();
    // 윈도우 리사이즈 이벤트 핸들러 등록
    window.addEventListener('resize', syncColumnWidths);
    // 컴포넌트 언마운트 시 이벤트 핸들러 제거 및 MutationObserver 해제
    return () => {
      window.removeEventListener('resize', syncColumnWidths);
      // observer.disconnect();
    };
  }, [addressLargeColumViewValueR]);

  return (
    <S.AddLarge>
      <AddLargeExcel
        handleAddRow={addLargeHooks.handleAddRow}
        rows={addLargeHooks.rows}
        setRows={addLargeHooks.setRows}
      />
      <S.AddLargeTableRightZone>
        <AddLargeHead rows={addLargeHooks.rows} setRows={addLargeHooks.setRows} inputRefs={inputRefs} />
        <S.AddLargeTableWrap ref={addLargeHooks.tableRef} id="largeTable">
          <MS.Table>
            <MS.TableHead>
              <tr>
                <th className="fixedBox" />
                <th className="numBox">순번</th>
                <th>
                  {/* TODO - 추후 검색창이 필요할때 쓰는곳 */}
                  {/* <SS.SelectInput className={`center arrow ${addLargeHooks.selectToggleS ? 'active' : ''}`}>
                    <button type="button" onClick={() => addLargeHooks.onClickSelect()}>
                      <p>그룹 선택</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                        <path
                          d="M4.70451 3.55415L7.94312 1.00834C7.99718 0.965866 8.02734 0.909431 8.02734 0.850744C8.02734 0.792058 7.99718 0.735623 7.94312 0.693145L7.93946 0.690404C7.91325 0.66974 7.8817 0.653286 7.84673 0.642042C7.81177 0.630798 7.77411 0.625 7.73606 0.625C7.698 0.625 7.66035 0.630798 7.62538 0.642042C7.59041 0.653286 7.55886 0.66974 7.53265 0.690404L4.48312 3.08774L1.43481 0.690404C1.4086 0.66974 1.37705 0.653286 1.34208 0.642042C1.30711 0.630798 1.26946 0.625 1.2314 0.625C1.19335 0.625 1.15569 0.630798 1.12072 0.642042C1.08576 0.653286 1.05421 0.66974 1.028 0.690403L1.02434 0.693145C0.970274 0.735623 0.940113 0.792057 0.940113 0.850744C0.940113 0.909431 0.970274 0.965865 1.02434 1.00834L4.26294 3.55415C4.29142 3.57654 4.32568 3.59436 4.36363 3.60654C4.40158 3.61872 4.44244 3.625 4.48373 3.625C4.52502 3.625 4.56588 3.61872 4.60383 3.60654C4.64178 3.59436 4.67603 3.57654 4.70451 3.55415Z"
                          fill="currentColor"
                          stroke="currentColor"
                        />
                      </svg>
                    </button>
                    <GroupListSelect />
                  </SS.SelectInput> */}

                  {/* 셀렉트박스 */}
                  <SS.SelectBoxMax className={`arrow ${addLargeHooks.selectToggleS ? 'active' : ''}`}>
                    <button type="button" onClick={() => addLargeHooks.onClickSelect()}>
                      <p>그룹 선택</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                        <path
                          d="M4.70451 3.55415L7.94312 1.00834C7.99718 0.965866 8.02734 0.909431 8.02734 0.850744C8.02734 0.792058 7.99718 0.735623 7.94312 0.693145L7.93946 0.690404C7.91325 0.66974 7.8817 0.653286 7.84673 0.642042C7.81177 0.630798 7.77411 0.625 7.73606 0.625C7.698 0.625 7.66035 0.630798 7.62538 0.642042C7.59041 0.653286 7.55886 0.66974 7.53265 0.690404L4.48312 3.08774L1.43481 0.690404C1.4086 0.66974 1.37705 0.653286 1.34208 0.642042C1.30711 0.630798 1.26946 0.625 1.2314 0.625C1.19335 0.625 1.15569 0.630798 1.12072 0.642042C1.08576 0.653286 1.05421 0.66974 1.028 0.690403L1.02434 0.693145C0.970274 0.735623 0.940113 0.792057 0.940113 0.850744C0.940113 0.909431 0.970274 0.965865 1.02434 1.00834L4.26294 3.55415C4.29142 3.57654 4.32568 3.59436 4.36363 3.60654C4.40158 3.61872 4.44244 3.625 4.48373 3.625C4.52502 3.625 4.56588 3.61872 4.60383 3.60654C4.64178 3.59436 4.67603 3.57654 4.70451 3.55415Z"
                          fill="currentColor"
                          stroke="currentColor"
                        />
                      </svg>
                    </button>
                    {addLargeHooks.selectToggleS ? (
                      <ul>
                        <div>
                          {addLargeHooks.groupListD &&
                            addLargeHooks.groupListD.groupList.map((group: GroupListType) => {
                              return (
                                <li key={group.groupSeqNo}>
                                  <button type="button" onClick={() => addLargeHooks.onClickSelectGroup(group)}>
                                    {group.groupNm}
                                  </button>
                                </li>
                              );
                            })}
                        </div>
                      </ul>
                    ) : null}
                  </SS.SelectBoxMax>
                </th>
                {/* header */}
                {Array.from(addressLargeColumViewLabelR).map((label) => (
                  <th key={label}>
                    <S.ArrangeGroup className="arrangeGroup">
                      <p>{label}</p>
                    </S.ArrangeGroup>
                  </th>
                ))}
              </tr>
            </MS.TableHead>
            {/* tableBody */}
            <MS.TableBody
              style={{ height: `${addLargeHooks.rowVirtualizer.getTotalSize()}px` }}
              tdWidthData={tableTdWidth}
            >
              {/* row map 뿌리는 곳 */}
              {addLargeHooks.rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const { index } = virtualRow;
                const row = addLargeHooks.rows[index];
                return (
                  <tr
                    key={index}
                    style={{
                      position: 'absolute',
                      // top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                    onClick={() => {
                      index === addLargeHooks.rows.length - 1 ? addLargeHooks.handleAddRow() : null;
                    }}
                  >
                    <td className="fixedBox">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          addLargeHooks.onClickDeleteRow(index);
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                          <g clipPath="url(#clip0_115_5389)">
                            <path
                              d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.375 5.625L5.625 9.375"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5.625 5.625L9.375 9.375"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_115_5389">
                              <rect width="15" height="15" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </td>
                    <td className="numBox">{index + 1}</td>
                    <td>
                      <p>{addLargeHooks.selectGroupS}</p>
                    </td>
                    {Array.from(addressLargeColumViewLabelR).map((label, number) => {
                      const value: string = Array.from(addressLargeColumViewValueR)[number];
                      /* 메모 */
                      if (value === 'etcInfo') {
                        return (
                          <td className="memo memoLarge" key={`${index}${label}`}>
                            <div className="text">
                              <textarea
                                rows={2}
                                value={row.etcInfo ?? ''}
                                onChange={(e) => addLargeHooks.handleInputChange(index, 'etcInfo', e.target.value)}
                              />
                            </div>
                          </td>
                        );
                      }
                      if (value !== 'etcInfo' && value !== 'genderFlag') {
                        return (
                          <td key={`${index}${label}`}>
                            <input
                              type="text"
                              value={row[value] ?? ''}
                              onChange={(e) => addLargeHooks.handleInputChange(index, value, e.target.value)}
                              onFocus={() => addLargeHooks.handleFocus(index)}
                              placeholder={index === 0 ? label : ''}
                              ref={(el) => setInputRef(index, value, el)}
                            />
                          </td>
                        );
                      }
                      if (value !== 'etcInfo' && value === 'genderFlag') {
                        return (
                          <td key={`${index}${label}`} className={addLargeHooks.genderToggle[index] ? 'focus' : ''}>
                            <SS.SelectBoxMax className={addLargeHooks.genderToggle[index] ? 'active' : ''}>
                              <button
                                onClick={() =>
                                  addLargeHooks.setGenderToggle((prev) => ({ ...prev, [index]: !prev[index] }))
                                }
                              >
                                {(() => {
                                  if (row.genderFlag === null || row.genderFlag === undefined) {
                                    return '';
                                  }
                                  if (
                                    row.genderFlag !== '남' &&
                                    row.genderFlag !== '여' &&
                                    row.genderFlag !== '1' &&
                                    row.genderFlag !== '2'
                                  ) {
                                    return '';
                                  }
                                  // '1'이면 '여', '2'이면 '남', 그 외의 값은 그대로 반환
                                  if (row.genderFlag === '1') {
                                    return '여';
                                  } else if (row.genderFlag === '2') {
                                    return '남';
                                  }
                                  return row.genderFlag; // 기본값
                                })()}
                              </button>
                              <ul>
                                {addLargeHooks.genderLabels.map((gender) => {
                                  return (
                                    <li key={gender}>
                                      <button
                                        onClick={() => {
                                          addLargeHooks.handleInputChange(index, 'genderFlag', gender);
                                        }}
                                      >
                                        {gender}
                                      </button>
                                    </li>
                                  );
                                })}
                              </ul>
                            </SS.SelectBoxMax>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </MS.TableBody>
          </MS.Table>
        </S.AddLargeTableWrap>
      </S.AddLargeTableRightZone>
    </S.AddLarge>
  );
};

export default AddLargeTable;
