export interface IMenus {
  title: '신용카드' | '계좌이체' | '무통장 입금' | '휴대폰 결제' | '캐시 결제';
  payMethodTypeCode: '0' | '11' | '21' | '31' | '1';
  subTitle: string;
  pointPercent: number;
}
export interface IMenus015 {
  title: '신용카드' | '계좌이체' | '무통장 입금' | '휴대폰 결제' | '캐시 결제';
  payMethodTypeCode: '0' | '11' | '21' | '31' | '1';
  subTitle: string;
}
export const useChargeMenuItems = () => {
  // 캐시충전 결제 수단
  const Menus: IMenus[] = [
    {
      title: '신용카드',
      payMethodTypeCode: '11',
      subTitle: '1% 포인트 추가 충전',
      pointPercent: 1,
      // subTitle: '',
    },
    {
      title: '계좌이체',
      payMethodTypeCode: '21',
      subTitle: '2% 포인트 추가 충전',
      pointPercent: 2,
      // subTitle: '',
    },
    {
      title: '무통장 입금',
      payMethodTypeCode: '0',
      subTitle: '5% 포인트 추가 충전',
      pointPercent: 5,
      // subTitle: '',
    },
    {
      title: '휴대폰 결제',
      payMethodTypeCode: '31',
      // subTitle: '0% 포인트 추가 충전',
      subTitle: '',
      pointPercent: 0,
    },
  ];
  // 015 이용권 결제수단
  const Menus015: IMenus015[] = [
    {
      title: '신용카드',
      payMethodTypeCode: '11',
      // subTitle: '1% 포인트 추가 충전',
      subTitle: '',
    },
    {
      title: '계좌이체',
      payMethodTypeCode: '21',
      // subTitle: '2% 포인트 추가 충전',
      subTitle: '',
    },
    {
      title: '휴대폰 결제',
      payMethodTypeCode: '31',
      // subTitle: 'ㅡ',
      subTitle: '',
    },
    {
      title: '캐시 결제',
      payMethodTypeCode: '1',
      // subTitle: 'ㅡ',
      subTitle: '',
    },
  ];
  // 1,000 / 3,000 / 5,000 / 10,0000 / 30,000 / 50,000 / 100,000 / 300,000 / 500,000 / 700,000 / 1,000,000
  const amountItems = [1000, 3000, 5000, 10000, 30000, 50000, 100000, 300000, 500000, 700000, 1000000];

  const amountCardItems = [
    [
      {
        left: {
          // text: '1,000원 (25.0원/건)',
          text: '1,000원',
          value: 1000,
        },
        right: {
          // text: '100,000원 (22.5원/건)',
          text: '100,000원',
          value: 100000,
        },
      },
      {
        left: {
          // text: '5,000원 (24.8원/건)',
          text: '5,000원',
          value: 5000,
        },
        right: {
          // text: '300,000원 (22.5원/건)',
          text: '300,000원',
          value: 300000,
        },
      },
      {
        left: {
          // text: '10,000원 (23.6원/건)',
          text: '10,000원',
          value: 10000,
        },
        right: {
          // text: '500,000원 (20.7원/건)',
          text: '500,000원',
          value: 500000,
        },
      },
      {
        left: {
          // text: '30,000원 (23.6원/건)',
          text: '30,000원',
          value: 30000,
        },
        right: {
          // text: '700,000원 (20.7원/건)',
          text: '700,000원',
          value: 700000,
        },
      },
      {
        left: {
          // text: '50,000원 (23.6원/건)',
          text: '50,000원',
          value: 50000,
        },
        right: {
          // text: '1,000,000원 (19.1원/건)',
          text: '1,000,000원',
          value: 1000000,
        },
      },
    ],
    [
      {
        left: {
          // text: '1,000원 (25.0원/건)',
          text: '1,000원',
          value: 1000,
        },
        right: {
          // text: '100,000원 (22.3원/건)',
          text: '100,000원',
          value: 100000,
        },
      },
      {
        left: {
          // text: '5,000원 (24.5원/건)',
          text: '5,000원',
          value: 5000,
        },
        right: {
          // text: '300,000원 (22.3원/건)',
          text: '300,000원',
          value: 300000,
        },
      },
      {
        left: {
          // text: '10,000원 (23.4원/건)',
          text: '10,000원',
          value: 10000,
        },
        right: {
          // text: '500,000원 (20.5원/건)',
          text: '500,000원',
          value: 500000,
        },
      },
      {
        left: {
          // text: '30,000원 (23.4원/건)',
          text: '30,000원',
          value: 30000,
        },
        right: {
          // text: '700,000원 (20.5원/건)',
          text: '700,000원',
          value: 700000,
        },
      },
      {
        left: {
          // text: '50,000원 (23.4원/건)',
          text: '50,000원',
          value: 50000,
        },
        right: {
          // text: '1,000,000원 (18.9원/건)',
          text: '1,000,000원',
          value: 1000000,
        },
      },
    ],
    [
      {
        left: {
          // text: '1,000원 (25.0원/건)',
          text: '1,000원',
          value: 1000,
        },
        right: {
          // text: '100,000원 (21.7원/건)',
          text: '100,000원',
          value: 100000,
        },
      },
      {
        left: {
          // text: '5,000원 (23.8원/건)',
          text: '5,000원',
          value: 5000,
        },
        right: {
          // text: '300,000원 (21.7원/건)',
          text: '300,000원',
          value: 300000,
        },
      },
      {
        left: {
          // text: '10,000원 (22.7원/건)',
          text: '10,000원',
          value: 10000,
        },
        right: {
          // text: '500,000원 (20.0원/건)',
          text: '500,000원',
          value: 500000,
        },
      },
      {
        left: {
          // text: '30,000원 (22.7원/건)',
          text: '30,000원',
          value: 30000,
        },
        right: {
          // text: '700,000원 (20.0원/건)',
          text: '700,000원',
          value: 700000,
        },
      },
      {
        left: {
          // text: '50,000원 (22.7원/건)',
          text: '50,000원',
          value: 50000,
        },
        right: {
          // text: '1,000,000원 (18.5원/건)',
          text: '1,000,000원',
          value: 1000000,
        },
      },
    ],
    [
      {
        left: {
          // text: '1,000원 (25.0원/건)',
          text: '1,000원',
          value: 1000,
        },
        right: {
          // text: '100,000원 (22.7원/건)',
          text: '100,000원',
          value: 100000,
        },
      },
      {
        left: {
          // text: '5,000원 (25.0원/건)',
          text: '5,000원',
          value: 5000,
        },
        right: {
          // text: '300,000원 (22.7원/건)',
          text: '300,000원',
          value: 300000,
        },
      },
      {
        left: {
          // text: '10,000원 (23.8원/건)',
          text: '10,000원',
          value: 10000,
        },
        right: {
          // text: '500,000원 (20.8원/건)',
          text: '500,000원',
          value: 500000,
        },
      },
      {
        left: {
          // text: '30,000원 (23.8원/건)',
          text: '30,000원',
          value: 30000,
        },
        right: {
          // text: '700,000원 (20.8원/건)',
          text: '700,000원',
          value: 700000,
        },
      },
      {
        left: {
          // text: '50,000원 (23.8원/건)',
          text: '50,000원',
          value: 50000,
        },
        right: {
          // text: '1,000,000원 (19.2원/건)',
          text: '1,000,000원',
          value: 1000000,
        },
      },
    ],
  ];

  return {
    Menus,
    amountCardItems,
    amountItems,
    Menus015,
  };
};
