import { useAddressBook } from '@/components/hooks/customs/addressBook/useAddressBook';
import { useHotfixModal } from '@/components/hooks/customs/useHotfixModal';
import SaveMessageModal from '@/components/softPhone/DisplayGroups/MessageSend/components/saveMessage/SaveMessageModal';
import SoftPhone from '@/components/softPhone/SoftPhone';
import AddressLeftZone from '@/pages/main/components/addressLeftZone/AddressLeftZone';
import ChatRoom015 from '@/pages/main/components/etcLeftZone/chatRoom015/ChatRoom015';
import EtcLeftZoneComponent from '@/pages/main/components/etcLeftZone/ui/EtcLeftZone.index';
import HotfixModal from '@/pages/main/components/modal/hotfixModal/HotfixModal';
import * as S from '@/pages/main/components/styles/AddressBook.styles';
import { openModalRecoil } from '@/recoil/atoms/modalRecoil';
import { softPhoneSaveMessageRecoil, softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SendResultDetail from './components/etcLeftZone/sendRoom010/sendResultDetail/SendResultDetail';
import Event1Modal from './components/modal/hotfixModal/Event1Modal';
import Event2Modal from './components/modal/hotfixModal/Event2Modal';

/**
 * EtcLeftZone.ArsWidgetIndex.tsx MainPage 주소록 메인 페이지
 *
 * @author 정휘학
 * @since 2024.03.14
 * */
const MainPage = () => {
  /* HOOKS */
  useAddressBook();
  const softPhoneTopTabR = useRecoilValue(softPhoneTopTabRecoil);
  const hotfixHooks = useHotfixModal();
  const saveMessage = useRecoilValue(softPhoneSaveMessageRecoil);
  const openModalS = useRecoilValue(openModalRecoil);

  return (
    <S.Container>
      <S.ContainerX>
        {/* 모달 섹션 */}
        {hotfixHooks.hotfixS && <HotfixModal onClickHotfixClose={hotfixHooks.onClickHotfixClose} />}
        {hotfixHooks.event1S && <Event1Modal onClickHotfixClose={hotfixHooks.onClickHotfixClose} />}
        {hotfixHooks.event2S && openModalS && <Event2Modal onClickHotfixClose={hotfixHooks.onClickHotfixClose} />}

        <S.AddressTableWrap>
          <div className="tableWrap">
            {/* 소프트폰 015 탭일 경우 015 채팅방 표출 가능 */}
            {(softPhoneTopTabR === '0' || softPhoneTopTabR === '2') && <ChatRoom015 />}
            {/* 전송결과(예약전송)조회 페이지 표출 */}
            {softPhoneTopTabR === '1' && <SendResultDetail />}
            {/* TODO - 배포후 주석 해제 */}
            {/* 자주 쓰는 템플릿 */}
            <S.EtcSaveMessageComponent className={`${saveMessage ? 'etcEnd' : 'etcStart'}`}>
              <SaveMessageModal />
            </S.EtcSaveMessageComponent>
            {/* 주소록 관련 좌측 컴포넌트 */}

            {<AddressLeftZone />}

            {/* 주소록을 제외한 그외 좌측 컴포넌트 ( 전송 결과 상세 페이지, 음성 사서함 ... ) */}
            <EtcLeftZoneComponent />
          </div>
          {/* 소프트 폰 컴포넌트 */}
          <SoftPhone />
        </S.AddressTableWrap>
      </S.ContainerX>
    </S.Container>
  );
};

export default MainPage;
