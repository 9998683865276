import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconSun: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 22 23"
      fill="none"
    >
      <path
        d="M11.1202 1.67566V3.17566M11.1202 20.1757V21.6757M18.1902 18.7467L17.1302 17.6867M5.11024 5.66466L4.04824 4.60566M21.1202 11.6757H19.6202M2.62024 11.6757H1.12024M18.1912 4.60466L17.1312 5.66466M5.11024 17.6867L4.05024 18.7467M16.1202 11.6757C16.1202 13.0017 15.5935 14.2735 14.6558 15.2112C13.7181 16.1489 12.4463 16.6757 11.1202 16.6757C9.79416 16.6757 8.52239 16.1489 7.58471 15.2112C6.64702 14.2735 6.12024 13.0017 6.12024 11.6757C6.12024 10.3496 6.64702 9.07781 7.58471 8.14013C8.52239 7.20244 9.79416 6.67566 11.1202 6.67566C12.4463 6.67566 13.7181 7.20244 14.6558 8.14013C15.5935 9.07781 16.1202 10.3496 16.1202 11.6757Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconSun;
