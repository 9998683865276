import React from 'react';

import { useTaxInvoiceHistory } from '../../hooks/customs/charge/useTaxInvoiceHistory';

import ATitle from '@/components/Atom/ATitle';
import BaseTable from '../Table';
import Paginations01Index from '../../common/paginations/Pagination02/Pagination02.index';
import styled from 'styled-components';
import Loader from '../../common/Loader';
import { Link, useLocation } from 'react-router-dom';

const TaxInvoiceHistoryWrap = styled.div`
  > h2 {
    margin-bottom: 2rem;
  }
`;

const TableFoot = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2.5rem 0;
`;

const TableDiv = styled.div`
  min-height: 10rem;

  & td {
    white-space: wrap;
    line-height: 1.8rem;
  }
`;

const TaxInvoiceHistoryTable = () => {
  const {
    totalPage,
    handlePageChange,
    activePage,
    setActivePage,
    startPage,
    setStartPage,
    currentPage,
    taxBliiData,
    thead,
    isSuccess,
    isLoading,
  } = useTaxInvoiceHistory();

  return (
    <TaxInvoiceHistoryWrap className="TaxInvoiceHistoryWrap">
      <ATitle type="sub" text="세금계산서 발행 내역" />
      <TableDiv>
        <BaseTable type="line" thead={thead} tbody={taxBliiData} onclick={(e: any) => {}} />
        {isLoading && <Loader backgroundToggle />}
      </TableDiv>
      <TableFoot>
        <Paginations01Index
          dataCount={totalPage}
          startPage={startPage}
          setStartPage={setStartPage}
          activePage={activePage}
          setActivePage={setActivePage}
          eventHook={handlePageChange}
          pageSize={10}
        />
      </TableFoot>
    </TaxInvoiceHistoryWrap>
  );
};

export default TaxInvoiceHistoryTable;
