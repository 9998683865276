import React, { ChangeEvent, useEffect, useState } from 'react';
import * as SS from '@/widgets/ars/components/arsTree/components/arsCardModal/ui/ArsCardModal.styles';
import { ArsCardModalProps } from '@/components/say015/Modal/arsCards/ArsCardModal';
import { useArsGuideTts } from '@/components/hooks/customs/say015/say105Handle/useArsGuideTts';
import { useArsGuidePlayAudio } from '@/components/hooks/customs/say015/say105Handle/useArsGuidePlayAudio';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useRecoilState } from 'recoil';
import { simpleViewArsTreeRecoil } from '@/recoil/atoms/say015Atom';
import AudioWave from '@/shared/atoms/AudioWave';
import AudioPause from '@/shared/atoms/AudioPause';
import { useArsGuideRecord } from '@/components/hooks/customs/say015/say105Handle/useArsGuideRecord';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import * as CB from '@/styles/checkBox.styles';
import { useArsCardTitle } from '@/components/hooks/customs/say015/say105Handle/useArsCardTitle';

/**
 * @title ARS 인사말 설정 카드 컴포넌트
 * */
const ArsCardSpeak = ({
  data,
  type,
  handleUpdateIdState,
  arsData,
  handleCancel,
  updatedJsonFile,
}: ArsCardModalProps) => {
  /* 간편보기 사용여부 */
  const [simpleViewArsTreeS] = useRecoilState(simpleViewArsTreeRecoil);

  /* title 바꾸는 Hooks */
  const { cardTitle, onChangeCardTitle } = useArsCardTitle({ data });

  const { handleSave, handleTtsText, isChangeTTS, ttsTextState, maxByte } = useArsGuideTts({
    type,
    data,
    arsData,
    updatedJsonFile,
    cardTitle,
  });

  const { confirmModal } = useModalHook();

  const { findDataById } = use015ArsJSON({ arsData });
  const audioPlayHooks = useArsGuidePlayAudio({ data: findDataById(arsData, 'root') });

  const {
    formatTime,
    startRecording,
    uploadAudio,
    stopRecording,
    audioIconToggle,
    audioBlob,
    recordingTimeS,
    maxRecordingTimeSec,
  } = useArsGuideRecord({
    arsData,
    data,
    setRecordAudioUrl: audioPlayHooks.setRecordAudioUrl,
    updatedJsonFile,
    cardTitle,
  });

  type TSelect = '음성' | '텍스트';
  const initSelectType: TSelect = data && data.s015Data.ttsText === '' ? '음성' : '텍스트';
  const [selectType, setSelectType] = useState<TSelect>(initSelectType);
  const onClickEditType = (data: TSelect) => {
    setSelectType(data);
  };

  useEffect(() => {
    if (type === 'view') setSelectType(initSelectType);
  }, [ttsTextState, type, initSelectType]);

  useEffect(() => {
    if (audioPlayHooks.activePlayRecordIdS && audioPlayHooks.activePlayRecordIdS === data?.id) {
      audioPlayHooks.handlePlay();
    } else {
      audioPlayHooks.handleEnded();
    }
  }, [audioPlayHooks.activePlayRecordIdS]);

  // 저장 버튼 클릭시 분기처리
  const onClickSave = () => {
    if (handleCancel) handleCancel();
    confirmModal(
      async () => {
        selectType === '텍스트' && (audioPlayHooks.handleAudioPlay(null), handleSave());
        selectType === '음성' && (audioPlayHooks.handleAudioPlay(null), uploadAudio());
      },
      '인사말 저장',
      '기존 인사말의 텍스트와 녹음은 제거되고, 현재 설정으로 저장합니다.',
      true,
      undefined,
      async () => {},
    );
  };

  return (
    <>
      {/* 간편보기 O */}
      {simpleViewArsTreeS && (
        <div className={'simpleCard'}>
          <div className="txtTop">
            <div className="group">
              {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
              <p
                className="icon"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!handleUpdateIdState) return;
                  handleUpdateIdState(data?.id);
                  audioPlayHooks.handleAudioPlay(null);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path
                    d="M12.4997 22.9173C18.2526 22.9173 22.9163 18.2536 22.9163 12.5007C22.9163 6.74768 18.2526 2.08398 12.4997 2.08398C6.74671 2.08398 2.08301 6.74768 2.08301 12.5007C2.08301 18.2536 6.74671 22.9173 12.4997 22.9173Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.33301 14.5833C8.33301 14.5833 9.89551 16.6667 12.4997 16.6667C15.1038 16.6667 16.6663 14.5833 16.6663 14.5833M9.37467 9.375H9.38509M15.6247 9.375H15.6351"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
              {type === 'update' && <h3 className="txt">인사말 설정</h3>}
              {type === 'view' && (
                <button
                  className={`${audioPlayHooks.activePlayRecordIdS === data?.id ? 'voicePlayBtn active' : 'voicePlayBtn'}`}
                  onClick={() => audioPlayHooks.handleAudioPlay(data?.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <circle cx="9.21643" cy="9.21643" r="9.21643" fill="currentColor" />
                    <path
                      d="M8.41834 5.66236C7.84234 5.32353 7.11621 5.73883 7.11621 6.4071V12.0261C7.11621 12.6944 7.84234 13.1097 8.41834 12.7708L13.1945 9.96134C13.7624 9.62726 13.7624 8.80594 13.1945 8.47185L8.41834 5.66236Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {type === 'update' && (
            <div className="list">
              <textarea
                placeholder={`음성변환 할 텍스트를 입력해주세요.
매일 5건의 작성회수를 제공하며,
무료 변환 건수 소진 시 건당 100원의 요금이 발생합니다.`}
                defaultValue={data?.s015Data?.ttsText}
                onChange={handleTtsText}
              />
            </div>
          )}
        </div>
      )}
      {/* 간편보기 X */}
      {!simpleViewArsTreeS && (
        <div className={'card'}>
          <div className="txtTop">
            <div className="group">
              {/* 각 인사말설정, 음성재생, 문자발송, 착신전환, 음성 녹음받기 등 아이콘*/}
              <p className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path
                    d="M12.4997 22.9173C18.2526 22.9173 22.9163 18.2536 22.9163 12.5007C22.9163 6.74768 18.2526 2.08398 12.4997 2.08398C6.74671 2.08398 2.08301 6.74768 2.08301 12.5007C2.08301 18.2536 6.74671 22.9173 12.4997 22.9173Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.33301 14.5833C8.33301 14.5833 9.89551 16.6667 12.4997 16.6667C15.1038 16.6667 16.6663 14.5833 16.6663 14.5833M9.37467 9.375H9.38509M15.6247 9.375H15.6351"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
              {type === 'update' ? (
                <div>
                  <input
                    placeholder="16자 이내로 입력해주세요"
                    value={cardTitle}
                    onChange={onChangeCardTitle}
                    maxLength={16}
                  />
                </div>
              ) : (
                <h3 className="txt">{cardTitle ? cardTitle : '인사말 설정'}</h3>
              )}
              {type === 'view' && (
                <button
                  className={`${audioPlayHooks.activePlayRecordIdS === data?.id ? 'voicePlayBtn active' : 'voicePlayBtn'}`}
                  onClick={() => audioPlayHooks.handleAudioPlay(data?.id)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <circle cx="9.21643" cy="9.21643" r="9.21643" fill="currentColor" />
                    <path
                      d="M8.41834 5.66236C7.84234 5.32353 7.11621 5.73883 7.11621 6.4071V12.0261C7.11621 12.6944 7.84234 13.1097 8.41834 12.7708L13.1945 9.96134C13.7624 9.62726 13.7624 8.80594 13.1945 8.47185L8.41834 5.66236Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {type === 'update' && (
            <SS.UpdateBtnGroup className="updateBtnGroup">
              <button
                className={selectType === '텍스트' ? 'active' : ''}
                type="button"
                onClick={() => onClickEditType('텍스트')}
              >
                텍스트
              </button>
              <button
                className={selectType === '음성' ? 'active' : ''}
                type="button"
                onClick={() => onClickEditType('음성')}
              >
                음성
              </button>
            </SS.UpdateBtnGroup>
          )}
          {selectType === '텍스트' ? (
            <div
              className="list"
              onClick={() => {
                if (!handleUpdateIdState) return;
                handleUpdateIdState(data?.id);
                audioPlayHooks.handleAudioPlay(null);
              }}
            >
              {type === 'mock' && (
                <p>
                  안녕하세요. 015 음식점입니다.
                  <br />
                  영업시간 안내를 원하시면 1번을, 매장 예약을 원하시면 2번을, 메뉴안내를 원하시면 3번을, 위치 및 주차
                  안내를 원하시면 4번을, 기타 문의 사항 및 매장과의 연결을 원하시면 5번을 눌러주세요. 다시듣기는 #을
                  눌러주세요.
                </p>
              )}
              {type === 'view' && <p>{data?.s015Data?.ttsText}</p>}
              {type === 'update' && (
                <div>
                  <textarea
                    placeholder={`음성변환 할 텍스트를 입력해주세요.
  매일 5건의 작성회수를 제공하며,
  무료 변환 건수 소진 시 건당 100원의 요금이 발생합니다.`}
                    value={ttsTextState}
                    onChange={handleTtsText}
                  />
                  {/* TODO (보류) - ARS 자동 등록 제목 입력창 : 추가, 수정 가능
                   * 안내멘트 하위트리의 각 Card title 정보를 담습니다.
                   * Card title이 존재하면 title로 표시하고 없다면 action으로 표시합니다.
                   */}
                  {/* <div>
                    <CB.CheckBoxButton style={{ cursor: 'pointer' }} onClick={() => {}}>
                      <p>ARS설정에 따라 자동 등록하기</p>
                      <button type="button" />
                    </CB.CheckBoxButton>
                    <div>
                      {data.next.map((card: any) => {
                        return card.s015Data.action ? (
                          <div className="flex" key={card.id}>
                            <input
                              value={card.s015Data.title !== '' ? card.s015Data.title : card.s015Data.action}
                              maxLength={16}
                            />
                            <p>
                              은(는) <span>{card.id}</span> 번을,
                            </p>
                          </div>
                        ) : null;
                      })}
                      <div>asdasd</div>
                    </div>
                  </div> */}
                  <div className="txtByte">
                    <p>
                      {calMsgByte(ttsTextState)} / {maxByte} Byte
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className="list"
              onClick={() => {
                if (!handleUpdateIdState) return;
                handleUpdateIdState(data?.id);
                audioPlayHooks.handleAudioPlay(null);
              }}
            >
              {type === 'view' && (
                <>
                  {/* <p>{data?.s015Data?.ttsText}</p> */}
                  <div className="viewWrap">
                    <div className="flexAc flexJsb audioWrap">
                      {audioPlayHooks.playSwitchBtn ? <AudioWave /> : <AudioPause />}
                      <p className="timeCount">
                        {audioPlayHooks.playSwitchBtn ? audioPlayHooks.audioTime : audioPlayHooks.durationView}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {type === 'update' &&
                (audioIconToggle ? (
                  <div className="updateWrap update">
                    {/* 오디오 애니메이션 */}
                    <div className="flexAc flexJsb audioWrap">
                      <AudioWave />
                    </div>
                    <p className="timeCountCenter">
                      {formatTime(recordingTimeS)} <span>/</span> {formatTime(maxRecordingTimeSec)}
                    </p>
                    <button className="btn" onClick={stopRecording}>
                      <span />
                    </button>
                  </div>
                ) : (
                  <div className="updateWrap pasuse">
                    {/* 오디오 기본 */}
                    <div className="flexAc flexJsb audioWrap">
                      <AudioPause />
                    </div>
                    <p className="timeCountCenter">
                      {formatTime(recordingTimeS)} <span>/</span> {formatTime(maxRecordingTimeSec)}
                    </p>
                    <button className="btn" onClick={startRecording}>
                      <span />
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}

      {type !== 'view' && type !== 'mock' && (
        <div className="btnGroup">
          <button
            className="cancelBtn"
            onClick={() => {
              if (handleCancel) handleCancel();
              audioPlayHooks.handleAudioPlay(null);
            }}
          >
            취소
          </button>
          <button
            className="saveBtn"
            onClick={() => onClickSave()}
            disabled={selectType === '텍스트' ? !isChangeTTS : !audioBlob && cardTitle === data.s015Data.title}
          >
            저장
          </button>
        </div>
      )}
      {audioPlayHooks.audioRef && audioPlayHooks.ttsAudioUrl && (
        <>
          <audio
            style={{ display: 'none' }}
            ref={audioPlayHooks.audioRef}
            onLoadedMetadata={audioPlayHooks.handleLoadedMetadata}
            onEnded={() => {
              audioPlayHooks.handleAudioPlay(null);
              audioPlayHooks.handleEnded();
            }}
          >
            <source src={audioPlayHooks.ttsAudioUrl} type="audio/wav" />
            <track kind="captions" src="" label="인사말 설정 음성파일입니다." />
          </audio>
        </>
      )}
    </>
  );
};
export default ArsCardSpeak;
