import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconLogout: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2rem'}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M16.1093 8.1771C15.9579 8.01462 15.8755 7.79971 15.8794 7.57765C15.8833 7.35559 15.9733 7.14372 16.1303 6.98668C16.2874 6.82964 16.4993 6.73968 16.7213 6.73576C16.9434 6.73184 17.1583 6.81427 17.3208 6.96568L20.7493 10.3942C20.9099 10.555 21 10.7728 21 11C21 11.2271 20.9099 11.445 20.7493 11.6057L17.3208 15.0342C17.2423 15.1185 17.1477 15.186 17.0425 15.2329C16.9374 15.2797 16.8239 15.3049 16.7088 15.3069C16.5937 15.309 16.4794 15.2878 16.3727 15.2447C16.2659 15.2016 16.169 15.1374 16.0876 15.056C16.0062 14.9746 15.942 14.8777 15.8989 14.7709C15.8558 14.6642 15.8346 14.5499 15.8367 14.4348C15.8387 14.3197 15.8639 14.2062 15.9107 14.1011C15.9576 13.9959 16.0251 13.9013 16.1093 13.8228L18.0751 11.8571H8.71505C8.48773 11.8571 8.26971 11.7668 8.10896 11.6061C7.94822 11.4453 7.85791 11.2273 7.85791 11C7.85791 10.7726 7.94822 10.5546 8.10896 10.3939C8.26971 10.2331 8.48773 10.1428 8.71505 10.1428H18.0751L16.1093 8.1771Z"
        fill={fill ?? 'currentColor'}
      />
      <path
        d="M11.0008 1C11.2281 1 11.4461 1.09031 11.6069 1.25105C11.7676 1.4118 11.8579 1.62981 11.8579 1.85714C11.8579 2.08447 11.7676 2.30249 11.6069 2.46323C11.4461 2.62398 11.2281 2.71429 11.0008 2.71429C8.80329 2.71429 6.69578 3.58724 5.14191 5.14112C3.58804 6.69499 2.71508 8.80249 2.71508 11C2.71508 13.1975 3.58804 15.305 5.14191 16.8589C6.69578 18.4128 8.80329 19.2857 11.0008 19.2857C11.2281 19.2857 11.4461 19.376 11.6069 19.5368C11.7676 19.6975 11.8579 19.9155 11.8579 20.1429C11.8579 20.3702 11.7676 20.5882 11.6069 20.7489C11.4461 20.9097 11.2281 21 11.0008 21C8.34863 21 5.80509 19.9464 3.92973 18.0711C2.05436 16.1957 1.00079 13.6522 1.00079 11C1.00079 8.34784 2.05436 5.8043 3.92973 3.92893C5.80509 2.05357 8.34863 1 11.0008 1Z"
        fill={fill ?? 'currentColor'}
      />
      <path
        d="M16.1093 8.1771C15.9579 8.01462 15.8755 7.79971 15.8794 7.57765C15.8833 7.35559 15.9733 7.14372 16.1303 6.98668C16.2874 6.82964 16.4993 6.73968 16.7213 6.73576C16.9434 6.73184 17.1583 6.81427 17.3208 6.96568L20.7493 10.3942C20.9099 10.555 21 10.7728 21 11C21 11.2271 20.9099 11.445 20.7493 11.6057L17.3208 15.0342C17.2423 15.1185 17.1477 15.186 17.0425 15.2329C16.9374 15.2797 16.8239 15.3049 16.7088 15.3069C16.5937 15.309 16.4794 15.2878 16.3727 15.2447C16.2659 15.2016 16.169 15.1374 16.0876 15.056C16.0062 14.9746 15.942 14.8777 15.8989 14.7709C15.8558 14.6642 15.8346 14.5499 15.8367 14.4348C15.8387 14.3197 15.8639 14.2062 15.9107 14.1011C15.9576 13.9959 16.0251 13.9013 16.1093 13.8228L18.0751 11.8571H8.71505C8.48773 11.8571 8.26971 11.7668 8.10896 11.6061C7.94822 11.4453 7.85791 11.2273 7.85791 11C7.85791 10.7726 7.94822 10.5546 8.10896 10.3939C8.26971 10.2331 8.48773 10.1428 8.71505 10.1428H18.0751L16.1093 8.1771Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="0.5"
      />
      <path
        d="M11.0008 1C11.2281 1 11.4461 1.09031 11.6069 1.25105C11.7676 1.4118 11.8579 1.62981 11.8579 1.85714C11.8579 2.08447 11.7676 2.30249 11.6069 2.46323C11.4461 2.62398 11.2281 2.71429 11.0008 2.71429C8.80329 2.71429 6.69578 3.58724 5.14191 5.14112C3.58804 6.69499 2.71508 8.80249 2.71508 11C2.71508 13.1975 3.58804 15.305 5.14191 16.8589C6.69578 18.4128 8.80329 19.2857 11.0008 19.2857C11.2281 19.2857 11.4461 19.376 11.6069 19.5368C11.7676 19.6975 11.8579 19.9155 11.8579 20.1429C11.8579 20.3702 11.7676 20.5882 11.6069 20.7489C11.4461 20.9097 11.2281 21 11.0008 21C8.34863 21 5.80509 19.9464 3.92973 18.0711C2.05436 16.1957 1.00079 13.6522 1.00079 11C1.00079 8.34784 2.05436 5.8043 3.92973 3.92893C5.80509 2.05357 8.34863 1 11.0008 1Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="0.5"
      />
    </Svg>
  );
};

export default IconLogout;
