import { modalToggleRecoil } from '@/recoil/atoms/messageSend/messageSend';
import { ExclamationCircleOutlined } from '@ant-design/icons'; // antd 아이콘 사용
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* 배경을 어둡게, 반투명으로 설정 */
  z-index: 97; /* 모달보다 뒤에 위치하도록 */
`;

const EventModalWrap = styled.div`
  z-index: 98;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.txt400};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.txt400};
    font-weight: bold;
    font-size: 2rem;
    text-align: left;

    .icon {
      font-size: 2rem;
      color: #faad14; /* antd의 주의 색상 */

      margin-right: 8px;
    }
  }

  .content {
    padding: 1.5rem;
    text-align: center;
    font-size: 1.4rem;
    background-color: ${(props) => props.theme.colors.white};
    line-height: normal;
    strong {
      font-size: 1.6rem;
    }
    p {
      text-align: start;
    }
  }

  .btnGroup {
    display: flex;
    padding: 0 1rem 1rem;
    gap: 1rem;
    button {
      cursor: pointer;
      flex: 1;
      padding: 1rem;
      font-size: 1.4rem;
      border-radius: 0.5rem;
      border: none;
      outline: none;

      &:first-child {
        color: ${(props) => props.theme.colors.txt300};
        background: ${(props) => props.theme.colors.gray200};
      }

      &:last-child {
        color: ${(props) => props.theme.colors.txt100};
        background: ${(props) => props.theme.colors.gray700};
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.main};
        color: ${(props) => props.theme.colors.txt100};
      }
    }
  }
`;

interface IAdNoticeModal {
  onClickModalClose: () => void;
}

const AdNoticeModal = (props: IAdNoticeModal) => {
  /* 광고 모달 toggle STATE */
  const [modalToggleS, setModalToggleS] = useRecoilState(modalToggleRecoil);

  const handleConfirmBtn = () => {
    // 해제
    props.onClickModalClose();
    setModalToggleS(false);
  };
  const handleCancelBtn = () => {
    // 모달 닫기
    setModalToggleS(false);
  };

  return (
    <>
      <Overlay />
      <EventModalWrap>
        <div className="header">
          <ExclamationCircleOutlined className="icon" />
          <span>경고</span>
        </div>
        <div className="content">
          <strong>광고 표기 및 무료 수신거부 내용을 해제하시겠습니까?</strong>
          <br />
          <br />
          <p>
            광고 표기 및 무료 수신거부 해제는 수신자의 동의 없이 광고성 메시지를 발송하는 것으로 간주될 수 있으며, 이는
            관련 법률(정보통신망법)을 위반할 가능성이 있습니다.
          </p>
          <p>*회원에게 보내는 광고성 문자는 해당 회원으로부터 광고 수신동의를 받은 지 6개월 이내임을 전제로 합니다.</p>
          <br />
          <p>⚠ 해제 시 발생할 수 있는 문제:</p>
          <p>- 광고 메시지 전송 시 준수사항을 위반하면, 최대 3,000만원의 과태료가 부과될 수 있습니다.</p>
          <p>
            - 광고 표기를 하지 않고 광고 메시지를 보내 스팸신고를 당할 경우, 서비스 이용 제한 또는 정지 가능성이
            있습니다.
          </p>
          <p>- 광고 메시지는 반드시 [광고] 표기와 무료 수신거부 번호를 포함하여 발송하시기 바랍니다.</p>
        </div>
        <div className="btnGroup">
          <button onClick={() => handleCancelBtn()}>취소</button>
          <button onClick={() => handleConfirmBtn()}>해제</button>
        </div>
      </EventModalWrap>
    </>
  );
};

export default AdNoticeModal;
