import { instance } from '@/shared/lib/clientAxios';

/**
 * 예약전송 리스트 조회 API GET 방식
 */

type ScheduleSearchData = {
  pageNumber: number;
  pageSize: number;
  keyword: string | null;
};

export const getReserveSendList = async (
  searchAllValue: ScheduleSearchData,
  pageNumber: number,
  softPhoneRowTabState: string,
) => {
  // const apiUrl = softPhoneTopTabState === '0' ? '/send015' : '/send010';

  const response = await instance.get(
    // localStorage.getItem('callBack') === '01067873947' ? '/mcmp/result' : '/send010',
    '/mcmp/result',
    {
      params: {
        pageNumber,
        pageSize: searchAllValue.pageSize,
        keyword: searchAllValue.keyword,
      },
    },
  );
  return response.data;
};

/**
 * 예약전송 예약 취소 API POST 방식
 */
interface ICancelReserveXms {
  deleteRequests: { prepayPayNo: string; callback: string }[];
}

export const cancelReserveXms = async ({ deleteRequests }: ICancelReserveXms) => {
  try {
    const response = await instance.post(
      // localStorage.getItem('callBack') === '01067873947' ? '/mcmp/result/reserve/cancel' : '/cancelReserveXms',
      '/mcmp/result/reserve/cancel',
      deleteRequests,
    );
    return response.data;
  } catch (error) {
    return '';
  }
};
