import { instance } from '@/shared/lib/clientAxios';
import { useInfiniteQuery, InfiniteQueryObserverResult } from '@tanstack/react-query';

// fetchMessages 함수
interface FetchMessagesParams {
  pageParam?: number;
  queryKey: [string, string, string];
}

const fetchMessages = async ({ pageParam = 0, queryKey }: FetchMessagesParams) => {
  const [, messageTabState, searchKeyword] = queryKey;
  const endpoint = messageTabState === 'SMS' ? '/message/archive/sms' : '/message/archive/mms';
  const response = await instance.get(`${endpoint}?title=${searchKeyword}&pageNumber=${pageParam}&pageSize=20`);
  return response.data;
};

// UseGetSaveMessageList 훅
export const useGetSaveMessageList = (messageTabState: string, searchKeyword: string) => {
  return useInfiniteQuery(
    ['messages', messageTabState, searchKeyword], // 기본 값 설정
    fetchMessages,
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length;
        return nextPage < lastPage.totalPages ? nextPage : undefined;
      },
      // 새로운 데이터를 가져올 때 캐시된 데이터를 사용하도록 설정
      cacheTime: 1000 * 60 * 10, // 10분간 캐시 유지
      staleTime: 1000 * 60 * 3, // 3분 동안 데이터가 stale로 간주되지 않음
      enabled: !!messageTabState, // messageTabState가 있을 때만 쿼리 활성화
    },
  );
};
