import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Dayjs } from 'dayjs';

import { getReserveSendList } from '@/apis/api/stContentApis';
import { calScrollEvent } from '@/apis/hooks/useScrollInfinity';
import {
  leftZoneComponentRecoil,
  listToggleRecoil,
  softPhoneComponentRecoil,
  softPhoneRowTabRecoil,
  softPhoneTopTabRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import {
  SearchData,
  resultDetailParamsRecoil,
  scheduleDetailParamsRecoil,
  transQueryKeyRecoil,
  transTableDataRecoil,
} from '@/recoil/atoms/sendResult/sendResult';
import { formatDateRangePickerDate, formatDateYYYYMMDD } from '@/shared/util/format/dateFormatUtil';
import { msgResultPageRecoil, msgResultToggleRecoil } from '@/recoil/atoms/say015Atom';
import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';

export const useTrcContent = () => {
  // ? 퍼블리싱 영역

  // 버튼 클릭시 width 값 따라오게 하는 코드 start
  const searchBtnRef = useRef<HTMLButtonElement>(null);
  const searchBoxRef = useRef<HTMLUListElement>(null);

  const [isActive, setIsActive] = useState<boolean>(false);

  const adjustButtonWidth = () => {
    if (searchBtnRef.current && searchBoxRef.current) {
      const boxWidth = searchBoxRef.current.offsetWidth;
      searchBtnRef.current.style.width = `${boxWidth}px`;
    }
  };
  useEffect(() => {
    adjustButtonWidth();
  }, [isActive]);

  const listRef = useRef<any>(null);
  const pageSize: number = 10;
  const [currentPage] = useState(0);
  const [searchDates, setSearchDates] = useState<Dayjs[] | null[]>([null, null]);
  const [searchAllValue, setSearchAllValue] = useRecoilState<SearchData>(transQueryKeyRecoil);
  const [resultDetailParamsState, setResultDetailParamsState] = useRecoilState(resultDetailParamsRecoil);
  const setScheduleDetailParamsState = useSetRecoilState(scheduleDetailParamsRecoil);

  /* 리스트 클릭 ACTIVE RECOIL */
  const [, setListToggleState] = useRecoilState(listToggleRecoil);
  const [transTableDataState, setTransTableDataState] = useRecoilState(transTableDataRecoil);
  const softPhoneTopTabState = useRecoilValue(softPhoneTopTabRecoil);
  const softPhoneRowTabState = useRecoilValue(softPhoneRowTabRecoil);
  const softPhoneComponentS = useRecoilValue(softPhoneComponentRecoil);
  const leftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  /* 010 전송결과 페이지 토글 Recoil */
  const setMsgResultToggleS = useSetRecoilState(msgResultToggleRecoil);
  /* 010 전송결과인지 예약전송인지 Recoil */
  const setMsgResultPageS = useSetRecoilState(msgResultPageRecoil);
  /* 메인 주소록 테이블 width  */
  const setMainTableWidth = useSetRecoilState(mainTableWidthRecoil);

  // api params 초기화
  useEffect(() => {
    setSearchAllValue({
      pageNumber: 0,
      pageSize: 20,
      searchStartDate: null,
      searchEndDate: null,
      keyword: null,
    });
    setSearchDates([null, null]);
  }, [softPhoneTopTabState, softPhoneComponentS]);

  // 전송결과 조회페이지 리스트 조회 Query
  const queryKey = ['/sendList', searchAllValue.keyword, softPhoneTopTabState];
  const {
    data: tableData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 0 }) => getReserveSendList(searchAllValue, pageParam, softPhoneTopTabState),
    {
      enabled: softPhoneTopTabState === '1',
      getNextPageParam: (lastPage: any) => {
        const nextPage = lastPage.pageable.pageNumber + 1;
        return nextPage < lastPage.totalPages ? nextPage : undefined;
      },
    },
  );

  useEffect(() => {
    if (tableData && !isLoading && softPhoneRowTabState === '0') {
      // 이미 처리된 prepayPayNo를 추적하기 위한 Set
      const seenPrepayPayNos = new Set<string>();

      const cacheUpdate = {
        ...tableData,
        pages: tableData.pages.map((page: any) => {
          const filteredContent = page.content.filter((item: any) => {
            if (seenPrepayPayNos.has(item.prepayPayNo)) {
              return false; // 이미 본 prepayPayNo이면 제거
            }
            seenPrepayPayNos.add(item.prepayPayNo); // 처음 보는 prepayPayNo이면 추가
            return true; // 유지
          });

          return {
            ...page,
            content: filteredContent,
          };
        }),
      };

      setTransTableDataState(cacheUpdate);
    }
  }, [tableData]);

  // 전송결과 조회 리스트 클릭 함수
  const onClickList = (prepayPayNo: string, callback: string) => {
    // 주소록 width 값 줄이기
    const tableWidth = (document.getElementById('mainTable')?.clientWidth ?? 1100) - 450;

    setScheduleDetailParamsState({ prepayPayNo: '', callback: '' });
    if (resultDetailParamsState.prepayPayNo !== prepayPayNo) {
      setMsgResultPageS('result');
      leftZoneComponentS('address');
      setResultDetailParamsState({ prepayPayNo, callback });
      setMsgResultToggleS(true);
      setMainTableWidth(tableWidth);
    } else {
      setMsgResultPageS(null);
      leftZoneComponentS('address');
      setResultDetailParamsState({ prepayPayNo: '', callback: '' });
      setMsgResultToggleS(false);
      setMainTableWidth(0);
    }
  };

  const onScrollHandler = async (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (calScrollEvent({ scrollHeight, scrollTop, clientHeight }) && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const onChangeRangePickerDate = (val: any) => {
    if (val === null) {
      setSearchDates([null, null]);
    } else {
      const dateArray = formatDateRangePickerDate(val);
      const formatDates = dateArray.map(formatDateYYYYMMDD);
      setSearchDates(formatDates);
    }
  };

  // 검색 버튼 클릭 함수
  const onSearchButton = () => {
    const searchData = {
      pageSize,
      pageNumber: currentPage,
      keyword: null,
      searchStartDate: searchDates?.[0] ?? null,
      searchEndDate: searchDates?.[1] ?? null,
    };
    setSearchAllValue(searchData);
  };

  return {
    searchDates,
    onSearchButton,
    onChangeRangePickerDate,
    listRef,
    onScrollHandler,
    tableData,
    isActive,
    setIsActive,
    searchBtnRef,
    searchBoxRef,
    onClickList,
    isLoading,
    resultDetailParamsState,
    transTableDataState,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
