/* eslint-disable */
import { useChatRoom015File } from '@/components/hooks/customs/chatRoom015/useChatRoom015File';
import * as S from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/ChatRoom015Body.styled';
import { formatTime } from '@/shared/util/format/dateFormatUtil';
import React, { useState } from 'react';
import { IChatRoom015File } from './ChatRoom015File.types';
import { CloseOutlined } from '@ant-design/icons';
import IconDownload from '@/styles/icons/IconDownload';

const ChatRoom015File = (props: IChatRoom015File) => {
  const fileHooks = useChatRoom015File(props);

  const [hoverSwitch, setHoverSwitch] = useState<boolean>(true);

  return (
    <>
      <S.EtcChattingYourZone className={props.direction === 'mt' ? 'mt' : 'mo'}>
        <S.EtcChattingFileWrap className="etcChattingFileWrap">
          {/* 파일 미리보기 */}
          <S.FilePreviewContainer className="filePreviewContainer">
            {/* {<img src="img/thanos.webp" alt="파일이름" />} */}
            {/* 이미지 미리보기 */}
            {fileHooks.fileCategory === '이미지' && fileHooks.previewUrl && (
              <img src={fileHooks.previewUrl} alt={`${props.list.fileData.fileName} 이미지 미리보기`} />
            )}
            {/* {<video src="img/testMp.mp4" controls />} */}
            {/* 비디오 미리보기 */}
            {fileHooks.fileCategory === '비디오' && fileHooks.previewUrl && (
              <video src={fileHooks.previewUrl} controls>
                <p>비디오 미리보기를 지원하지 않는 브라우저입니다.</p>
              </video>
            )}
            {/* {<audio src="img/testWav.wav" controls />} */}
            {/* 오디오 미리보기 */}
            {fileHooks.fileCategory === '오디오' && fileHooks.previewUrl && (
              <>
                <audio src={fileHooks.previewUrl} controls style={{ display: 'none' }}>
                  <p>{fileHooks.fileType}</p>
                </audio>
                <div className="documentMessage">{fileHooks.fileType}</div>
              </>
            )}
            {/* 기타 파일 타입에 대한 처리 */}
            {/* {<div className="documentMessage">미리보기를 지원하지 않는 문서 파일입니다.</div>} */}
            {!fileHooks.previewUrl && <div className="documentMessage">{fileHooks.fileType}</div>}
          </S.FilePreviewContainer>

          <S.FileBtn
            className="round fileDownload"
            aria-label="Download File"
            onClick={() => {
              fileHooks.onClickFileDownload();
            }}
            onMouseOver={() => {
              setHoverSwitch(false);
            }}
            onMouseOut={() => {
              setHoverSwitch(true);
            }}
          >
            {/* 다운로드 버튼 */}
            <IconDownload />
          </S.FileBtn>
        </S.EtcChattingFileWrap>
        <S.EtcChattingHourText className={` fileText ${!hoverSwitch ? 'active' : ''}`}>
          {/* {props.list.rsltVal === -100 &&
            formatTime(props.list?.messageDttm) !==
              (props.content[1][props.index + 1]?.direction === props.content[1][props.index]?.direction
                ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>} */}

          {/* 100 : 전송 완료 , 99 : 전송 중 , 그 외 : 전송 실패 */}
          {props.list.rsltVal === -100 &&
            formatTime(props.list?.messageDttm) !==
              (props.content[1][props.index + 1]?.direction === props.content[1][props.index]?.direction
                ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>}
          {props.list.rsltVal === 99 && <S.EtcChattingHourText>전송중</S.EtcChattingHourText>}
          {props.list.rsltVal !== -100 && props.list.rsltVal !== 99 && (
            <S.EtcChattingHourText>
              {/* <S.EtcChattingReSendBtn
                      onClick={() => props.reSend015Msg(props.list.messageId, props.list.msgType)}
                    >
                      <RedoOutlined />
                    </S.EtcChattingReSendBtn> */}
              <S.EtcChattingSnedCancelBtn onClick={() => props.cancelSend015Msg(props.list.messageId)}>
                <CloseOutlined />
              </S.EtcChattingSnedCancelBtn>
            </S.EtcChattingHourText>
          )}
        </S.EtcChattingHourText>
      </S.EtcChattingYourZone>
    </>
  );
};

export default ChatRoom015File;
