import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { IUseSendResultDetailInfo } from '@/components/hooks/customs/types/Hooks.types';
import {
  reSendFileRecoil,
  reSendMsgRecoil,
  reSendMsgToggleRecoil,
  reSendSubjectRecoil,
} from '@/recoil/atoms/messageSend/messageSend';

/**
 * @title SendResultDetailInfo.tsx Hooks
 *
 * @author 정휘학
 * @since 2024.03.19
 * */
export const useSendResultDetailInfo = (props: IUseSendResultDetailInfo) => {
  const setReSendMsgState = useSetRecoilState(reSendMsgRecoil);
  const setReSendFileState = useSetRecoilState(reSendFileRecoil);
  const setReSendSubjectState = useSetRecoilState(reSendSubjectRecoil);
  const reSendMsgToggleState = useRecoilValue(reSendMsgToggleRecoil);

  // ============================================================================
  // 전송결과 조회 재전송 버튼 토글시 실행되는 useEffect ( 메세지 내용, 파일 set recoil )
  // ============================================================================
  useEffect(() => {
    if (!reSendMsgToggleState) return;
    if (props.detailInfo.pages[0].msgGb !== 'SMS') setReSendSubjectState(props.detailInfo.pages[0].subject);
    setReSendMsgState(props.detailInfo.pages[0].sndMsg);
    setReSendFileState(props.detailInfo.pages[0].imageData);
  }, [props.detailInfo, reSendMsgToggleState]);
};
