import axios from 'axios';

/**
 *
 * @param callbackUrl : 인증 후 콜백 url
 * @param errorUrl  : 인증 실패 시 콜백 url
 * @returns
 */
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const encodeData = (callbackUrl: string | number | boolean, errorUrl: string | number | boolean) => {
  const callback: String = encodeURIComponent(callbackUrl);
  const error: String = encodeURIComponent(errorUrl);
  return axios({
    url: `${process.env.REACT_APP_SERVER_URL}/nice/authorize?callbackUrl=${callback}&errorUrl=${error}`,
    method: 'GET',
  });
};

export const postNiceValidate = async (nInfo: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/signup/identity-verification`, nInfo);

    return response.data;
  } catch (error) {
    return error;
  }
};

// 아이디 복원
export const postRecoveryId = async (props: any) => {
  const data = {
    encodeData: props.parseInfo.EncodeData,
    phnId: props.handleNumber,
  };

  const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/sleep/restore`, data);

  return response;
};

// 휴먼 아이디 삭제
type TSleepIdLeave = {
  encodeData: string;
  phnId: string;
};
export const postSleepIdLeave = async (datas: TSleepIdLeave) => {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/sleep/leave`, datas);
  return response.data;
};

// 회원 정보 입력 - encodeData로 이름, 전화번호 get
export const getFormData = async (checkInfo: string, size: number, accountType: string) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/signup/form-data`, {
    params: {
      encodeData: checkInfo,
      size,
      accountType,
    },
  });
  return response.data;
};

// 회원 정보 입력 - 회원가입 form post
export const postFormData = async (formData: any) => {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/signup/confirm`, formData);
  return response.data;
};

// 비밀번호 재설정 post
export const postResetPassword = async (resetData: any) => {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/password`, resetData);
  return response.data;
};

// 휴먼 아이디 인증 메시지 전송 post
export const postCertifyNum = async (certifyData: any) => {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/sleep/restore/send-message`, certifyData);
  return response.data;
};

// 휴먼 아이디 인증 메시지 전송 확인 post
export const postCertifyMsg = async (checkData: any) => {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/sleep/restore/check-message`, checkData);
  return response.data;
};
