import { useState, useEffect, useCallback } from 'react';

export const useArsNav = () => {
  const [isVisible, setIsVisible] = useState(true);
  let hideTimeout: NodeJS.Timeout;

  // 버튼 숨기기 로직
  const hideButton = useCallback(() => {
    setIsVisible(false);

    // 2초 후 버튼 다시 표시 (선택)
    clearTimeout(hideTimeout);
    hideTimeout = setTimeout(() => setIsVisible(true), 2000);
  }, []);

  // 스크롤 및 터치 이벤트 리스너 설정
  useEffect(() => {
    const handleScrollOrTouch = () => hideButton();

    window.addEventListener('scroll', handleScrollOrTouch);
    window.addEventListener('touchmove', handleScrollOrTouch);

    return () => {
      window.removeEventListener('scroll', handleScrollOrTouch);
      window.removeEventListener('touchmove', handleScrollOrTouch);
      clearTimeout(hideTimeout);
    };
  }, [hideButton]);

  return { isVisible };
};
