import { useCopyTable } from '@/components/hooks/customs/addressBook/useCopyTable';
import { useDeleteGroup } from '@/components/hooks/customs/addressBook/useDeleteGroup';
import { useDeleteTableList } from '@/components/hooks/customs/addressBook/useDeleteTableList';
import { useDownExcelTableList } from '@/components/hooks/customs/addressBook/useDownExcelTableList';
import { addGroupOpenRecoil } from '@/recoil/atoms/addressBookGroup';
import { tableAddOpenRecoil } from '@/recoil/atoms/addressList';
import { fullScreenSpinLoadingRecoil } from '@/recoil/atoms/atoms';
import { chatSaveNumberRecoil } from '@/recoil/atoms/chatRoom';
import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { leftZoneComponentRecoil, listToggleRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

/* 상단 버튼 상태 관리 state type */
type ActiveFunBtnSType = 'addSelect' | 'copySelect' | 'delSelect' | null;

/**
 * @title 주소록 상단 버튼 기능에 대한 hook
 *
 * @author 정휘학
 * @since 2024.06.18
 * */

export const useAddressBookFunBtn = () => {
  const queryClient = useQueryClient();

  /* 컴포넌트 최상단 useRef */
  const layoutRef = useRef<HTMLDivElement>(null);

  /* 그룹 리스트 캐싱 데이터 */
  const groupListQueryData: any = queryClient.getQueryData(['groupList2']);

  /* 주소록 체크박스 상태 관리 recoil */
  const [mainTableCheckDataFormatR, setMainTableCheckDataFormatR] = useRecoilState(mainTableCheckDataFormatRecoil);

  /* 상단 버튼 active 상태 관리 state */
  const [activeFunBtnS, setActiveFunBtnS] = useState<ActiveFunBtnSType>(null);

  /* 상단 버튼 view 상태 관리 state */
  const [viewFunBtnS, setViewFunBtnS] = useState<boolean>(false);

  /* 좌측 그룹 리스트 추가 컴포넌트 표출제어 recoil */
  const setAddGroupOpenR = useSetRecoilState(addGroupOpenRecoil);

  /* 주소록 간편추가 컴포넌트 표출제어 recoil */
  const setTableAddOpenS = useSetRecoilState(tableAddOpenRecoil);

  /* 활성화 채팅방 상대방 번호 담아놓는 STATE */
  const setChatSaveNumber = useSetRecoilState(chatSaveNumberRecoil);

  /* 대량추가 컴포넌트 표출제어 recoil */
  const setLeftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  const setListToggleS = useSetRecoilState(listToggleRecoil);

  /* 주소록 복사 hook */
  const copyHook = useCopyTable();
  /* 주소록 삭제 hook */
  const deleteHook = useDeleteTableList();
  /* 그룹 삭제 hook */
  const deleteGroupHook = useDeleteGroup();
  /* 주소록 엑셀다운 hook */
  const excelHook = useDownExcelTableList();

  const setFullScreenSpinLoadingR = useSetRecoilState(fullScreenSpinLoadingRecoil);
  useEffect(() => {
    setFullScreenSpinLoadingR(copyHook.copyL);
  }, [copyHook.copyL]);

  // ===========================
  // 주소록 상단 버튼 클릭 핸들러 hook
  // ===========================
  const handleButtonClick = (activeNm: ActiveFunBtnSType) => {
    setActiveFunBtnS((prevState) => {
      if (activeNm === prevState) return null;
      return activeNm;
    });
  };

  // ===================================
  // 상단 버튼 내부에 있는 버튼 클릭 핸들러 hook
  // ===================================
  const handleButtonInsideClick = () => {
    /* 상단 버튼 active 제거 */
    setActiveFunBtnS(null);
  };

  // ==============================
  // 간편추가 버튼 클릭 핸들러 hook
  // ==============================
  const onClickTableAddBtn = () => {
    setTableAddOpenS((prev) => !prev);
    setChatSaveNumber(null);
  };

  // ===========================================
  // 상단 버튼 및 팝업창 layout 을 제외한 다른 곳 클릭시 active 해제
  // ===========================================
  const handleClickOutside = (event: any) => {
    const popupCurrent: any = document.querySelectorAll('.ant-modal-root')[0];
    const layoutCurrent = layoutRef.current;
    if (popupCurrent) {
      if (
        layoutCurrent &&
        !layoutCurrent.contains(event.target as Node) &&
        !popupCurrent.contains(event.target as Node)
      ) {
        setActiveFunBtnS(null);
      }
    }
    if (!popupCurrent) {
      if (layoutCurrent && !layoutCurrent.contains(event.target as Node)) {
        setActiveFunBtnS(null);
      }
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // ===============================
  // 주소록 상단 버튼 표출 분기 useEffect
  // ===============================
  useEffect(() => {
    if (!mainTableCheckDataFormatR) {
      setViewFunBtnS(false);
    } else {
      const isTableCheck =
        mainTableCheckDataFormatR.find((data) => data.groupCheck || data.buddySeqNo || data.api) ?? null;
      setViewFunBtnS(!!isTableCheck);
    }
  }, [mainTableCheckDataFormatR]);

  return {
    layoutRef,
    activeFunBtnS,
    handleButtonClick,
    handleButtonInsideClick,
    setAddGroupOpenR,
    onClickTableAddBtn,
    setLeftZoneComponentS,
    deleteHook,
    excelHook,
    deleteGroupHook,
    viewFunBtnS,
    groupListQueryData,
    copyHook,
    setListToggleS,
  };
};
