import React from 'react';
import styled from 'styled-components';
import * as TS from '@/styles/table.styles';
import ATitle from '@/components/Atom/ATitle';

const AWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 4rem 0;
  gap: 2rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
// 타이틀 컨테이너
const ATitleBox = styled.div`
  /* width: 40%; */
  margin-bottom: 2rem;
`;
// 텍스트 컨테이너
const TextWrapper = styled.div``;
// 텍스트 타이틀
const TextBox = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.txt400};
  line-height: normal;
  p {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-bottom: 2rem;
  }
`;

const LiStyle = styled.li`
  margin: 0 0 1rem;
  font-size: 1.3rem;
  list-style: none;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;
const CallingNumberBottom = () => {
  return (
    <AWrapper className="callingNumberBottom">
      <TextWrapper className="texthideTable">
        <ATitleBox>
          <ATitle type="sub" text="발신 번호 등록 서류" />
        </ATitleBox>
        <TS.MyPageTable className="mypageTable marB40 borderStyles flexNon">
          <thead>
            <tr>
              <th>발신번호 유형 기준</th>
              <th>인증 방법 및 제출 서류</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>가입자 본인 휴대폰</th>
              <td>
                <p>가입자 본인 휴대폰</p>
                <div>휴대폰번호 본인 인증</div>
              </td>
            </tr>
            <tr>
              <th>가입자 본인 일반 번호</th>
              <td>
                <p>가입자 본인 일반 번호</p>
                <div>통신서비스이용증명원 1부, 신분증 사본 1부</div>
              </td>
            </tr>
            <tr>
              <th>가입자의 기업 번호</th>
              <td>
                <p>가입자의 기업 번호</p>
                <div>
                  기업의 사업자등록증 1부, 통신서비스가입증명원1부, 재직증명서 1부
                  <br />
                  (가입자가 대표자일 경우 재직증명서 필요없음)
                </div>
              </td>
            </tr>
            <tr>
              <th>타인명의 일반 및 휴대폰 번호</th>
              <td>
                <p>타인명의 일반 및 휴대폰 번호</p>
                <div>통신서비스이용증명원 1부, 위임장 1부, 수임자,위임자 각각 신분증 사본 1부</div>
              </td>
            </tr>
          </tbody>
        </TS.MyPageTable>
        <TextBox>
          <p>
            <span>*</span> 위임장에는 위임자, 수임자에 대한 정보(상호명, 주소지, 성명 등)이 정확히 명시되어야 하며,
            수암자가 등록하려는 위임자의 전화번호 목록과 위임하는 목적이 포함되어야합니다. 또한 위임자, 수입자 모두 인감
            날이이 되어야합니다.
          </p>
          <p>
            <span>*</span> 전기통신사법 제 84조에 의거 및 KISA(한국인터넷진흥원)으로 부터 [거짓으로 표시된 전화번호로
            인한 이용자의 피해 예방 등에 관한 고시] 개정에 따라 발신번호 추가 등록 방법 및 등록 서류가 강화되었음을
            안내드립니다.
          </p>
        </TextBox>
      </TextWrapper>
      <TextWrapper>
        <ATitleBox>
          <ATitle type="sub" text="-  서류 인증 절차 안내 -" />
        </ATitleBox>
        <LiStyle>1. 인증수단에서 서류인증을 선택합니다.</LiStyle>
        <LiStyle>2. 등록하고자 하는 발신번호를 입력합니다.</LiStyle>
        <LiStyle>3. 서류 인증 심사 후 안내 받으 실 휴대폰 번호를 입력합니다.</LiStyle>
        <LiStyle>4. 인증 받으실번호에 대한 필요 서류를 첨부파일에 등록합니다.</LiStyle>
        <LiStyle>5. 서류심사 관련 사항, 요청사항을 비고란에 기재해 주시기 바랍니다.</LiStyle>
        <LiStyle>6. 신청하기 버튼을 누르시면 서류인증 심사가 접수되고 1~2일 정도 소요됩니다.</LiStyle>
      </TextWrapper>
    </AWrapper>
  );
};

export default CallingNumberBottom;
