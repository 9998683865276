import React from 'react';
import styled from 'styled-components';
import ATitle from '@/components/Atom/ATitle';
import BaseButton from '../../Atom/BaseButton';

import CallingNumberBottom from '../../Molecules/MyPage/CallingNumberMoleCules/CallingNumberBottom';
import CallingNumberInput from '../../Molecules/MyPage/CallingNumberMoleCules/CallingNumberInput';
import CallingNumberList from '../../Molecules/MyPage/CallingNumberMoleCules/CallingNumberList';
import CallingNumberTitle from '../../Molecules/MyPage/CallingNumberMoleCules/CallingNumberTitle';
import { useCallingNumberAdmin } from '../../hooks/customs/myPage/useCallingNumberAdmin';
import { useCallingNiceAdmin } from '@/components/hooks/customs/myPage/useCallingNiceAdmin';
/** 스타일 컴포넌트 파일분리 */
// 테이블을 감싸는 컨테이너

const CallingNumberAdminWrap = styled.div`
  > h2 {
    margin-bottom: 2rem;
  }
`;

// 테이블을 감싸는 컨테이너
const BWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  /* flex-direction: column; */
  margin: 0 0 4rem 0;
  gap: 2rem;
  border-top: 2px solid ${(props) => props.theme.colors.gray500};

  @media screen and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;

// 라디오, 인증 묶음
const ContentWrap = styled.div`
  display: flex;
  width: 70%;

  > div {
    :nth-of-type(2) {
      width: calc(100% - 16rem);
      min-height: 30rem;
    }
    &:has(.mine) {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid ${(props) => props.theme.colors.gray200};
    }
  }
  @media screen and (max-width: 1080px) {
    width: 100%;
    > div {
      :nth-of-type(2) {
        min-height: unset;
      }
    }
  }
  @media screen and (max-width: 540px) {
    flex-direction: column;
    gap: 1.4rem;
    > div {
      width: 100%;
      :nth-of-type(2) {
        width: 100%;
      }
      &:has(.mine) {
        border-left: 0;
      }
    }
    .radioWrapper {
      flex-direction: unset;
      label {
        width: 50%;
      }
    }
  }
`;

const CertifyWrapper = styled.div`
  .document {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }
`;

// 라디오 Wrap
const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label {
    cursor: pointer;
    width: 16rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 3rem;
    font-size: 1.4rem;
    height: 4rem;
    color: ${(props) => props.theme.colors.txt200};
    input {
      appearance: none;
      left: 0;
      width: 5px;
      height: 100%;
      background: ${(props) => props.theme.colors.disable};
      margin: 0;
      padding: 0;
      :checked {
        background: ${(props) => props.theme.colors.active};
      }
    }
    &:has(input:checked) {
      background: ${(props) => props.theme.colors.gray100};
      color: ${(props) => props.theme.colors.active};
    }
  }
  @media screen and (max-width: 768px) {
    label {
      font-size: 1.6rem;
      height: auto;
      p {
        padding: 1.4rem 0;
      }
    }
  }
`;

// 버튼 컨테이너
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    button {
      /* width: 100%; */
      height: auto;
      padding: 1rem;
      font-size: 1.6rem;
    }
  }
`;

const CallingNumberAdmin = () => {
  const {
    callbackListS,
    handleSubmit,
    handleDelete,
    register,
    errors,
    handleCertifyClick,
    allCallingNumberList,
    radioToggle,
    onChangeRadioToggle,
    allCallingNumberListRefetch,
  } = useCallingNumberAdmin();

  const niceHooks = useCallingNiceAdmin({ allCallingNumberListRefetch });

  return (
    <CallingNumberAdminWrap>
      {/* 발신번호 사전등록 ~ 발신번호 등록*/}
      <CallingNumberTitle />

      <ATitle type="sub" text="발신번호 등록" />
      <BWrapper>
        <CallingNumberList
          allCallingNumberList={allCallingNumberList}
          callbackListS={callbackListS}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
        />
        <ContentWrap>
          <RadioWrapper className="radioWrapper">
            <label htmlFor="nice" className={radioToggle === 'nice' ? 'acitve' : ''}>
              <input
                type="radio"
                id="nice"
                name="auth"
                value="nice"
                checked={radioToggle === 'nice'}
                onChange={(e) => onChangeRadioToggle(e)}
              />
              <p>본인인증</p>
            </label>
            <label htmlFor="paper" className={radioToggle === 'paper' ? 'acitve' : ''}>
              <input
                type="radio"
                id="paper"
                name="auth"
                value="paper"
                checked={radioToggle === 'paper'}
                onChange={(e) => onChangeRadioToggle(e)}
              />
              <p>서류제출</p>
            </label>
          </RadioWrapper>
          <CertifyWrapper>
            {radioToggle === 'nice' && (
              <ButtonWrapper className="mine">
                <BaseButton
                  width="12rem"
                  height="3rem"
                  fontSize="1.4rem"
                  fontWeight="bold"
                  onClick={() => niceHooks.onClickNiceBtn()}
                >
                  본인인증 등록
                </BaseButton>
              </ButtonWrapper>
            )}
            {radioToggle === 'paper' && <CallingNumberInput register={register} errors={errors} />}
            {radioToggle === 'paper' && (
              <ButtonWrapper className="document">
                <BaseButton
                  width="12rem"
                  height="3rem"
                  fontSize="1.4rem"
                  fontWeight="bold"
                  onClick={handleSubmit(handleCertifyClick)}
                >
                  등록하기
                </BaseButton>
              </ButtonWrapper>
            )}
          </CertifyWrapper>
        </ContentWrap>
      </BWrapper>
      <CallingNumberBottom />
    </CallingNumberAdminWrap>
  );
};
export default CallingNumberAdmin;
