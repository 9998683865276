import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.2em;
  height: 1em;
`;

const IconPassword: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2.4rem'}
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01947 12.0332C3.13979 12.147 2.64663 12.3588 2.28964 12.7027C1.93225 13.0462 1.7116 13.5213 1.59329 14.3678C1.47215 15.2346 1.47059 16.3792 1.47059 18C1.47059 19.6208 1.47215 20.7654 1.59329 21.6322C1.7116 22.4787 1.93165 22.9532 2.28905 23.2968C2.64603 23.6407 3.13979 23.853 4.01947 23.9668C4.92025 24.0834 6.1098 24.0849 7.79412 24.0849H17.2059C18.8902 24.0849 20.0797 24.0834 20.9805 23.9668C21.8602 23.853 22.3534 23.6412 22.7104 23.2973C23.0678 22.9538 23.2884 22.4787 23.4067 21.6322C23.5278 20.7654 23.5294 19.6208 23.5294 18C23.5294 16.3792 23.5278 15.2346 23.4067 14.3678C23.2884 13.5213 23.0683 13.0468 22.711 12.7032C22.354 12.3593 21.8602 12.147 20.9805 12.0332C20.0797 11.9166 18.8902 11.9151 17.2059 11.9151H7.79412C6.1098 11.9151 4.92025 11.9166 4.01947 12.0332ZM7.73932 10.5H17.2607C18.8777 10.5 20.1663 10.5 21.1765 10.6307C22.2189 10.7656 23.0744 11.0513 23.7502 11.702C24.4269 12.3525 24.7239 13.1759 24.8642 14.1792C25 15.1513 25 16.3913 25 17.9473V18.0527C25 19.6087 25 20.8487 24.8642 21.8208C24.7239 22.8241 24.4269 23.6475 23.7502 24.298C23.0744 24.9487 22.2189 25.2344 21.1765 25.3693C20.1663 25.5 18.8777 25.5 17.2607 25.5H7.73931C6.12229 25.5 4.83366 25.5 3.82347 25.3693C2.7808 25.2344 1.92514 24.9486 1.24918 24.2974C0.57288 23.647 0.276009 22.8238 0.135826 21.8208C-3.05176e-05 20.8487 -1.68358e-05 19.6087 3.86458e-07 18.0527V17.9473C-1.68358e-05 16.3913 -3.05176e-05 15.1513 0.135826 14.1792C0.27603 13.176 0.572966 12.3527 1.24948 11.7023C1.92539 11.0513 2.78095 10.7656 3.82347 10.6307C4.83366 10.5 6.1223 10.5 7.73932 10.5Z"
        fill={fill ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 1.94928C10.8862 1.94928 9.33846 2.60585 8.19732 3.77455C7.05618 4.94326 6.41509 6.52836 6.41509 8.18116V10.5C6.41509 10.9002 6.09831 11.2246 5.70755 11.2246C5.31678 11.2246 5 10.9002 5 10.5V8.18116C5 6.14399 5.79018 4.19026 7.1967 2.74976C8.60322 1.30926 10.5109 0.5 12.5 0.5C14.4891 0.5 16.3968 1.30926 17.8033 2.74976C19.2098 4.19026 20 6.14399 20 8.18116V10.5C20 10.9002 19.6832 11.2246 19.2925 11.2246C18.9017 11.2246 18.5849 10.9002 18.5849 10.5V8.18116C18.5849 6.52836 17.9438 4.94326 16.8027 3.77455C15.6615 2.60585 14.1138 1.94928 12.5 1.94928ZM12.5 14.413C12.8908 14.413 13.2075 14.7375 13.2075 15.1377V19.7754C13.2075 20.1756 12.8908 20.5 12.5 20.5C12.1092 20.5 11.7925 20.1756 11.7925 19.7754V15.1377C11.7925 14.7375 12.1092 14.413 12.5 14.413Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconPassword;
