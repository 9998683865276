interface IUseScrollInfinity {
  clientHeight: number;
  scrollTop: number;
  scrollHeight: number;
}

/* 스크롤 계산기 - 아래로 */
export const calScrollEvent = ({ scrollTop, scrollHeight, clientHeight }: IUseScrollInfinity) => {
  return scrollHeight - clientHeight <= scrollTop + 5;
};

/* 스크롤 계산기 - 위로 */
export const calScrollUpEvent = ({ scrollTop }: IUseScrollInfinity) => {
  return scrollTop === 0;
};
