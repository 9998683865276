import React from 'react';
import { fetchMutation } from '@/components/hooks/mutations/fetchMutation';
import { SendInfoResponseType } from '@/apis/api/addressApis';
import { sendMsgModalText } from '@/components/hooks/customs/useModalText';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useNavigate } from 'react-router-dom';
import { useChatRoom015Comm } from '@/components/hooks/customs/chatRoom015/useChatRoom015Comm';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sendMsgCacheToggleRecoil } from '@/recoil/atoms/chatRoom';
import { useQueryClient } from '@tanstack/react-query';
import { chatRoom015CacheUpdate } from '@/components/cache/chatRoom015CacheUpdate';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

interface IUseSendMessageMutations {
  resetMsgState?: () => void;
}

interface IOnSuccessToSendMsgInfoM {
  response: SendInfoResponseType;
  sendParams: any;
  isProcessingToSend: boolean;
  setIsProcessingToSend: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @title 메세지 전송에 관련된 Mutations
 *
 * @author 정휘학
 * @since 2024.07.04
 **/
export const useSendMessageMutations = (props: IUseSendMessageMutations) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // TODO - 충전 : 앱 충전로직 끝나기 전까지 막을 기능
  const environmentS = useRecoilValue(environmentRecoil);

  /* 캐쉬 작업 toggle */
  const [, setSendMsgCacheToggle] = useRecoilState(sendMsgCacheToggleRecoil);

  const { confirmModal, warningModal } = useModalHook();

  // 소프트폰 015 개별 채팅방 리스트 데이터 캐싱 업데이트 작업
  const { updateCache015SoftPhoneList } = useChatRoom015Comm();

  // 015 채팅방 데이터 캐시 업데이트
  const { cacheUpdateToSendMsg } = chatRoom015CacheUpdate();
  /**
   * ========================
   * @title 메세지 전송 mutation
   * ========================
   **/
  const onSuccessToSendMsgM = (r: any) => {
    if (!props.resetMsgState) return;
    props.resetMsgState(); // 메세지 전송완료후 텍스트 입력창 초기화

    // if (envS !== 'app') {
    //   updateCache015SoftPhoneList(r);
    // }

    /* 잔액 API REFETCH */
    if (!r.contactNumber.startsWith('015')) {
      queryClient.refetchQueries(['/userPoint']);
      queryClient.refetchQueries(['/say015/serviceStatus']);
    } // 금액
    /* 캐쉬 작업 toggle => true ( 메세지 전송시 스크롤을 하단으로 이동시키기 위함 ) */
    setSendMsgCacheToggle(true);

    cacheUpdateToSendMsg(r); // 015 채팅방에서 메세지 전송후 캐시 업데이트 hook
  };

  const { mutateAsync: sendMsgM, isLoading: sendMsgL } = fetchMutation({
    method: 'POST',
    mutationKey: ['/smt/send/chat'],
    file: true,
    onSuccess: (r) => onSuccessToSendMsgM(r),
  });

  /**
   * ==============================================
   * @title 현재 전송하려고 하는 메세지에 관한 정보 mutation
   * ==============================================
   **/

  const onSuccessToSendMsgInfoM = ({ setIsProcessingToSend, sendParams, response }: IOnSuccessToSendMsgInfoM) => {
    /* 확인 버튼 클릭시 */
    const onOk = response.pointEnough
      ? async () => {
          try {
            await sendMsgM(sendParams);
          } catch (error) {
            console.error('Error send message:', error);
          } finally {
            setIsProcessingToSend(false);
          }
        }
      : async () => {
          setIsProcessingToSend(false);
          // TODO - 충전소 : 앱 충전소 기능 끝나면 변경
          const naviUrlS = environmentS === 'app' ? '/mobile/etc/etcCharge' : '/charge';
          navigate(naviUrlS);
        };

    const onCancel = () => {
      setIsProcessingToSend(false);
    };

    /* 팝업창에 표출되는 텍스트 */
    const { content, title } = sendMsgModalText(response, '015');

    confirmModal(onOk, title, content, true, '', onCancel);
  };
  const { mutateAsync: sendMsgInfoM, isLoading: sendMsgInfoL } = fetchMutation<any, SendInfoResponseType>({
    method: 'POST',
    mutationKey: ['/message/legacy/send-info'],
  });

  return {
    sendMsgM,
    sendMsgL,
    sendMsgInfoM,
    sendMsgInfoL,
    onSuccessToSendMsgInfoM,
  };
};
