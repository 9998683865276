import { postAgree015Send, postSendInfo, SendInfoResponseType } from '@/apis/api/addressApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { sendMsgModalText } from '@/components/hooks/customs/useModalText';
import { skeletonLoadingRecoil } from '@/recoil/atoms/atoms';
import { checkGroupArrR, messageSendPhoneNumberRecoil } from '@/recoil/atoms/messageSend/messageSend';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

interface IUseMessageSendInfo {
  onClickSendValidation: any;
  softPhoneRowTabState: string;
  fileResult: any;
  sndMsgState: string;
  onClickSend: (tap: number) => void;
}

/**
 * @title 메시지발송 버튼 관련 로직 Hook
 *
 * @author 김남규
 * @since 2024-04-24
 */
export const useMessageSendInfo = (props: IUseMessageSendInfo) => {
  const messageSendPhoneNumberState = useRecoilValue(messageSendPhoneNumberRecoil);
  const checkGroupArrS = useRecoilValue(checkGroupArrR);
  /* 발송버튼 STATE */
  const [sendBtnState, setSendBtnState] = useState(true);
  /* 소프트폰 상위 탭 상태 */
  const softPhoneTopTabS = useRecoilValue(softPhoneTopTabRecoil);
  /* 팝업 스켈레톤 활성화 recoil */
  const [, setSkeletonLoadingS] = useRecoilState(skeletonLoadingRecoil);

  // TODO - 충전 : 앱 충전로직 끝나기 전까지 막을 기능
  const environmentS = useRecoilValue(environmentRecoil);

  const { warningModal, confirmModal } = useModalHook();
  const navigator = useNavigate();

  const { mutate: agree015SendM } = useMutation(postAgree015Send);

  // ==============================
  // 전송 요청후 처리
  // ==============================
  const { mutate: sendInfoData, isLoading: sendInfoIsLoading } = useMutation(postSendInfo, {
    onSuccess: async (data: SendInfoResponseType) => {
      // 015 변작 동의 api 호출
      // TODO - 발신번호 관련 동의 여기서
      // 015에서 010으로 메시지를 보낼 경우, 기간망이 아닌 비즈망으로 전송될 수 있어 발신번호 변작 동의가 필요합니다.
      // 최초 전송시에만 1회 확인이 필요하며, 이후에는 안내창이 뜨지 않습니다.
      if (data.callback.startsWith('015') && data.agreeToAlterations) {
        confirmModal(
          async () => {
            agree015SendM(data.callback);
            // 포인트 여부에 따른 로직
            handleConfirmStatus(data);
          },
          '메시지 발송 안내',
          '메시지를 발송하실 경우, 등록된 010 번호를 통해 발송될 수 있습니다. 단, 수신자에게는 기존처럼 발신 번호가 015 번호로 표시되므로 참고 부탁드립니다.',
          true,
        );
      } else {
        handleConfirmStatus(data);
      }
    },
    onError: () => {
      setSendBtnState(true);
    },
  });
  useEffect(() => {
    setSkeletonLoadingS(sendInfoIsLoading);
  }, [sendInfoIsLoading]);

  // ==============================
  // 전송 api Hook
  // ==============================
  const sendMsgHandler = (sendData: any) => {
    if (
      (sendData.dto.sndMsg !== '' || sendData.file) &&
      sendData.dto.callback &&
      (sendData.dto.groupSeqList?.length > 0 ||
        sendData.dto.buddySeqList?.length > 0 ||
        sendData.dto.reSendPhoneNumberList?.length > 0 ||
        sendData.dto.etcPhoneNumberList?.length > 0)
    ) {
      // 전송 성공 - 전송 양식 완료
      // sendInfoData(sendInfoParams);
      sendInfoData(sendData.dto);
    } else if (!sendData.dto.callback) {
      // 전송 실패 - 발신번호 없음
      warningModal(
        '전송 실패',
        '발신번호가 존재하지 않습니다. 발신번호를 확인 또는 다시 로그인 후 진행해 주세요.',
        true,
      );
    } else if (
      !(
        sendData.dto.groupSeqList?.length > 0 ||
        sendData.dto.buddySeqList?.length > 0 ||
        sendData.dto.reSendPhoneNumberList?.length > 0 ||
        sendData.dto.etcPhoneNumberList?.length > 0
      )
    ) {
      // 전송 실패 - 수신인 없음
      warningModal('전송 실패', '수신인이 존재하지 않습니다.', true);
    } else if (!(sendData.dto.sndMsg !== '' || sendData.file)) {
      // 전송 실패 - 내용 없음
      warningModal('전송 실패', '전송할 내용이 존재하지 않습니다.', true);
    }
  };

  // ==============================
  // 전송하기 버튼 클릭시 015 번호일 경우 51 건 이상이면 사용자에게 비즈망 동의서 받게 하는 onClick Hook
  // ==============================
  const onClickSendConfirm = async () => {
    const sendData = await props.onClickSendValidation(Number(props.softPhoneRowTabState));
    // 기존의 비즈망 발송건수 막습니다.
    sendMsgHandler(sendData);
  };

  // ==============================
  // send-info api 에서 받은 전송 가능 여부에 따른 모달창 표출
  // ==============================
  const handleConfirmStatus = (sendInfo: SendInfoResponseType) => {
    const naviUrlS = environmentS === 'app' ? '/mobile/etc/etcCharge' : '/charge';
    const onOk = sendInfo.pointEnough
      ? async () => props.onClickSend(Number(props.softPhoneRowTabState))
      : async () =>
          // TODO - 충전소 : 앱 충전소 기능 끝나면 변경
          navigator(naviUrlS);
    const { title, content } = sendMsgModalText(sendInfo, softPhoneTopTabS === '0' ? '015' : '010');
    confirmModal(onOk, title, content, true, undefined, async () => {
      setSendBtnState(false);
    });
  };

  // ==============================
  // 전송 양식이 지켜지면 버튼에 active 들어오는 useEffcet
  // ==============================
  useEffect(() => {
    const isCheckedGroup = checkGroupArrS?.filter((group) => group.isChecked || group.isChildrenChecked);
    if (
      (props.sndMsgState !== '' || props.fileResult) &&
      ((messageSendPhoneNumberState && messageSendPhoneNumberState.length > 0) ||
        (isCheckedGroup && isCheckedGroup.length > 0))
    ) {
      setSendBtnState(false);
    } else {
      setSendBtnState(true);
    }
  }, [props.sndMsgState, props.fileResult, messageSendPhoneNumberState, checkGroupArrS]);

  return { sendBtnState, onClickSendConfirm };
};
