import React from 'react';
import * as S from './AdNoticePopup.styles';
import { useAdNoticePopup } from '@/components/hooks/customs/windowPopup/adNoticePopup/useAdNoticePopup';

const AdNoticePopupContent = () => {
  const adHooks = useAdNoticePopup();

  return (
    <S.AdPopupContent className="adPopupContent">
      <S.Wrapper>
        <img src="img/adNotice/adNotice_notice.png" alt="adNotice_notice" />
        <button className="link" type="button" onClick={() => window.open(adHooks.url, '_blank')} />
      </S.Wrapper>
    </S.AdPopupContent>
  );
};
export default AdNoticePopupContent;
