import { formatDateDate } from '@/shared/util/format/dateFormatUtil';
import React from 'react';
import * as MLS from '../../../styles/CommonListStyles/CommonList.styles';
import IconImage from '@/styles/icons/IconImage';

interface IMsgResultBox {
  prepayPayNo: any;
  callback: any;
  subject: any;
  rcvInfo: any;
  sndDttm: string;
  imageExists: any;
  sndMsg: any;
  resultDetailParamsState: {
    prepayPayNo: string;
    callback: string;
  };
  onClickList: (prepayPayNo: string, callback: string) => void;
}

const MsgResultBox = (props: IMsgResultBox) => {
  return (
    <div
      key={props.prepayPayNo}
      role="button"
      tabIndex={0}
      className={`groups ${props?.prepayPayNo === props.resultDetailParamsState.prepayPayNo ? 'active' : ''}`}
      onClick={() => props.onClickList(props?.prepayPayNo, props?.callback)}
    >
      <div className="titleGroup">
        <div className="topGroup">
          <div className="title">
            <MLS.MessageTitle className={`messageTitle ${props?.subject !== '제목없음' ? '' : 'noTitle'}`}>
              {props?.subject}
            </MLS.MessageTitle>
          </div>
          {/* peopleCount 명수 */}
          {/* <div className="peopleCount">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M5.31006 4.72809C6.55318 4.72809 7.56006 3.72121 7.56006 2.47809C7.56006 1.23496 6.55318 0.228088 5.31006 0.228088C4.06693 0.228088 3.06006 1.23496 3.06006 2.47809C3.06006 3.72121 4.06693 4.72809 5.31006 4.72809ZM5.31006 5.85309C3.80818 5.85309 0.810059 6.60684 0.810059 8.10309V8.66559C0.810059 8.97496 1.06318 9.22809 1.37256 9.22809H9.24756C9.55693 9.22809 9.81006 8.97496 9.81006 8.66559V8.10309C9.81006 6.60684 6.81193 5.85309 5.31006 5.85309Z"
              fill="#191919"
            />
          </svg>
          <p>
            <span>{el?.rcvInfo.totalCount}</span> 명
          </p>
        </div> */}
          <div className="dateGroup">
            <p className="date">{formatDateDate(props?.sndDttm) ?? ''}</p>
          </div>
        </div>
      </div>
      <div className="textContent">
        {props?.imageExists !== '' && props?.imageExists && (
          <p className="iconGroup">
            <IconImage className="mainColor" />
          </p>
        )}
        <p className="text">{props.sndMsg ? props.sndMsg : '이미지'}</p>
      </div>
    </div>
  );
};
export default MsgResultBox;
