import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const MobileEtcContainer = styled.div`
  padding: 1.2rem 1.4rem;
`;

const MobileEtc = () => {
  return (
    <MobileEtcContainer className="mobileEtc">
      <Outlet />
    </MobileEtcContainer>
  );
};

export default MobileEtc;
