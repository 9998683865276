/* eslint-disable */
import React from 'react';
import * as MLS from '../../styles/CommonListStyles/CommonList.styles';
import * as S from './ChatRoom.styles';
import { useChatRoomSelect } from '@/components/hooks/customs/chatRoom/useChatRoomSelect';
import { useChatRoomStatus } from '@/components/hooks/customs/chatRoom/useChatRoomStatus';
import EachChatRoom from '@/components/softPhone/DisplayGroups/MessageManagement/ChatRoom/EachChatRoom/EachChatRoom';
import MessageSend from '@/components/softPhone/DisplayGroups/MessageSend/MessageSend';

// softPhone 015 탭 메뉴
const ChatRoom = () => {
  const statusHooks = useChatRoomStatus();
  const selectHooks = useChatRoomSelect({
    itemOption: statusHooks.itemOption,
    pickItemState: statusHooks.pickItemState,
  });

  return (
    <S.ChatRoom>
      {/* testCode수정 */}
      {/* <S.Overlay>
        <h2>015 Works 점검안내</h2>
        <h2>
          <span>2024/11/08 (금)</span>
          <span style={{ textDecoration: 'line-through', color: 'gray' }}>2024/10/31 (목)</span>
        </h2>
        <h2>
          <span>21:00 - 24:00까지 이용하실 수 없습니다.</span>
        </h2>
        <h2>
          <span>서비스 안정화를 위한 작업이니, 양해 부탁드립니다.</span>
        </h2>
        <h2>감사합니다.</h2>
      </S.Overlay> */}

      {/* 메시지 전송 컴포넌트 */}
      <MessageSend />

      <EachChatRoom onContextMenuItem={statusHooks.onContextMenuItem} />
    </S.ChatRoom>
  );
};
export default ChatRoom;

// {/* 리스트 우클릭시 표출될 박스 */}
// {statusHooks.itemSelectToggle && (
//   <MLS.ChatPop style={{ position: 'fixed', top: statusHooks.vectorState?.y, left: statusHooks.vectorState?.x }}>
//     {/* 추가할때 구분 ul로 묶어서 할것 */}
//     <ul>
//       {statusHooks.itemOption.map((option: any) => {
//         return (
//           // 대화방 나가기 버튼
//           <li key={option.value}>
//             <button type="button" onClick={() => selectHooks.onClickItemOption(option)}>
//               {option.label}
//             </button>
//           </li>
//         );
//       })}
//     </ul>
//   </MLS.ChatPop>
// )}
