import { usePOBox015 } from '@/components/hooks/customs/say015/pobox015/usePOBox015';
import Sub015Page from '@/components/softPhone/DisplayGroups/POBox015/Sub015Page/Sub015Page';
import ReSubscription from '@/components/softPhone/DisplayGroups/ReSubscription/ReSubscription';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface IAuthorityData {
  availabilityStatus: boolean;
  monthlyPrice: number;
  number015: string;
  say015User: boolean;
  subsEndDate: string;
  subscriptionStatus: boolean;
}

export const Container = styled.section`
  width: 100%;
  height: 100dvh;
  padding: 2rem;
`;

/**
 * @title 모바일 015 구독 만료 됐을때 다시 가입 페이지
 *
 */
const Mobile015SubSignup = () => {
  const { pageState, authorityData, authorityDataIsLoading, authorityDataRefetch } = usePOBox015();
  const storageUser = localStorage.getItem('user'); // 현재 로그인중인 user
  const [authorityDataState, setAuthorityDataState] = useState<IAuthorityData | null>(null);

  useEffect(() => {
    if (authorityData) {
      const initailAuthorityData = {
        availabilityStatus: authorityData?.availabilityStatus,
        monthlyPrice: authorityData?.monthlyPrice,
        number015: authorityData?.number015,
        say015User: authorityData?.say015User,
        subsEndDate: authorityData?.subsEndDate,
        subscriptionStatus: authorityData?.subscriptionStatus,
      };
      setAuthorityDataState(initailAuthorityData);
    }
  }, [authorityData]);

  return (
    <Container className="015subsingup">
      {authorityDataState && (
        <ReSubscription authorityDataState={authorityDataState} authorityDataRefetch={authorityDataRefetch} />
      )}
    </Container>
  );
};

export default Mobile015SubSignup;
