import styled, { keyframes } from 'styled-components';

export const ResultContainer = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
`;

export const ResultHeader = styled.section`
  width: 100%;
  height: 4rem;
  min-height: 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  background-color: ${(props) => props.theme.colors.white};
`;

export const ResultHeaderLeft = styled.section`
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;

  display: flex;
  gap: 1rem;
`;

export const ResultMain = styled.section`
  width: 100%;
  height: calc(100dvh - 4rem);
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  overflow-y: auto;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 0 0 2rem 2rem;
`;

export const ResultMessage = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.gray200};
  border-radius: 2rem 2rem 0 2rem;
  padding: 1.6rem;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  overflow-y: auto;

  /* 텍스트 줄 바꿈 속성 추가 */
  word-wrap: break-word; /* 긴 단어를 영역에 맞게 줄 바꿈 */
  word-break: break-word; /* 긴 단어가 깨져도 줄 바꿈되게 */
  white-space: pre-wrap; /* 공백과 줄바꿈 유지 */
`;

export const ResultMessageInfo = styled.div`
  display: flex;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.black};
  font-weight: 700;
  justify-content: space-between;
  gap: 2rem;
  padding: 0.8rem 1rem 2rem;
`;

export const CheckTabContainer = styled.div`
  width: 100%;
  display: flex;
  height: 4rem;
`;

export const CheckTab = styled.div<{ isActive: boolean }>`
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? props.theme.colors.gray200 : props.theme.colors.gray300)};

  border-radius: 2rem 2rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) => (props.isActive ? '0 2px 4px rgba(0, 0, 0, 0.1)' : null)}; /* 부드럽고 약한 그림자 추가 */
`;

export const ResultCheck = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.gray200};
  border-radius: 0 0 2rem 2rem;
  position: relative;
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 1rem;
  height: 5rem;
  min-height: 5rem;
  background-color: ${(props) => props.theme.colors.gray200};
  position: sticky;
  top: 0;
`;

export const ListSearchInput = styled.input`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray100};
  border-radius: 0.8rem;
  padding: 1rem 0.8rem;
`;

export const CheckListItem = styled.div`
  width: 100%;
  padding: 1rem 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileChattingBigImgModal = styled.div`
  // Modal
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  &.mobileBigImg {
    background: ${(props) => props.theme.colors.gray800};
  }
`;

export const MobileChattingBigImgWrap = styled.div`
  position: relative;
  max-width: unset;
  min-width: 100%;
  max-height: calc(100% - 10.2rem);
  padding: 2rem;
`;

export const MobileChattingBigImg = styled.img`
  width: 100%;
  max-height: calc(-10.2rem + 90vh);

  object-fit: contain;
`;

export const EtcChattingBigImageModalBtnWrap = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  background-color: ${(props) => props.theme.colors.gray100};
  button {
    flex: 1;
  }
`;

export const MobileEtcChattingBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  height: 5.2rem;
  font-size: 1.8rem;
  color: ${(props) => props.theme.colors.txt100};

  &.imgDownload {
    background: ${(props) => props.theme.colors.grayButton2};
  }

  &.imgClose {
    background: ${(props) => props.theme.colors.grayButton};
  }
`;
