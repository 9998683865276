import { atom } from 'recoil';
import { IUseQuery015GroupMessageHistory, IUseQuery015GroupMessageList } from '@/components/hooks/queries/queryTypes';
import { GetPointApiType } from '@/apis/api/pointApi';
/**
 * @title 소프트폰 015 리스트 activeRoomKind ( 개별 : each , 그룹 : group )
 * */
export const activeRoomKindRecoil = atom<string>({
  key: 'activeRoomKindRecoil',
  default: 'each',
});

/**
 * @title 소프트폰 015 리스트 activeMessageKind ( 전체 : all, 미열람 : notRead )
 * */
export const activeMessageKindRecoil = atom<string>({
  key: 'activeMessageKindRecoil',
  default: 'all',
});

/**
 * @title groupMessageHistory api 요청 params recoil
 * */
export const gmhQueryParamsRecoil = atom<IUseQuery015GroupMessageHistory>({
  key: 'gmhQueryParamsRecoil',
  default: { chatRoomId: null, pageNumber: 0, pageSize: 30 },
});

/**
 * @title 015GroupMessageList api 요청 params recoil ( 소프트폰 015 그룹 채팅방 리스트 데이터 api params )
 * */
export const gmlQueryParamsRecoil = atom<IUseQuery015GroupMessageList>({
  key: 'gmlQueryParamsRecoil',
  default: { pageSize: 30, pageNumber: 0, searchCondition: 'number', keyword: null },
});

/**
 * @title 015 그룹 채팅방 정보가 담기는  recoil ( 방 아이디, 방 이름 )
 * */
export const groupChatRoomInfoRecoil = atom<{ chatRoomId: string; getChatRoomName: string }>({
  key: 'groupChatRoomInfoRecoil',
  default: { chatRoomId: '', getChatRoomName: '' },
});

/**
 * @title 메인 주소록 테이블 셀 width 값 ( 015 채팅방 표출될 때 와 아닐때 다름 )
 * */
export const mainTableWidthRecoil = atom<number>({
  key: 'mainTableWidthRecoil',
  default: 0,
});

/**
 * @title 팝업 스켈레톤 api 로딩
 * */
export const skeletonLoadingRecoil = atom<boolean>({
  key: 'skeletonLoadingRecoil',
  default: false,
});

/**
 * @title 주소록 엑셀 대량 추가 items
 * */
export const addAddressExcelItemsRecoil = atom<any>({
  key: 'addAddressExcelItemsRecoil',
  default: null,
});

/**
 * @title 유저 포인트 data recoil
 * */
type userPointRecoilType = GetPointApiType | undefined;
export const userPointRecoil = atom<userPointRecoilType>({
  key: 'userPointRecoil',
  default: undefined,
});

/**
 * @title 푸시알림 상태 recoil ( 크롬 웹 푸시, 메세지 푸시 )
 * */
export const pushAlertRecoil = atom<{ chromeAlertStatus: boolean; smsAlertStatus: boolean }>({
  key: 'pushAlertRecoil',
  default: { chromeAlertStatus: false, smsAlertStatus: false },
});

/**
 * @title 그룹 리스트 데이터 recoil
 *
 * 주소록 리뉴얼 할 때 사용중
 * */
export type GroupListDataRecoilType = { groupSeqNo: number; groupNm: string }[] | null;
export const groupListDataRecoil = atom<GroupListDataRecoilType>({
  key: 'groupListDataRecoil',
  default: null,
});

/**
 * @title FullScreenSpinLoading.tsx api 로딩
 * */
export const fullScreenSpinLoadingRecoil = atom<boolean>({
  key: 'fullScreenSpinLoadingRecoil',
  default: false,
});
