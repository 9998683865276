import React, { useEffect, useRef, useState } from 'react';
import * as S from './MultiChatroom.style';
import MobileLoader from '../../MobileLoader';
import { useSearchMultiChatroomData } from '../../mobileHooks/useSearchMultiChatroomData';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '@/shared/util/format/dateFormatUtil';
import MobileMessageChatRoomFooter from '../mobileMessageChatRoom/MobileMessageChatRoomFooter';
import { removeWebCharacter, underLine } from '@/shared/util/format/contentFormatUtil';
import { calScrollUpEvent } from '@/apis/hooks/useScrollInfinity';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { multiChatRoomIdRecoil, resultDataRecoil, resultOpenRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { Spin } from 'antd';
import FramerMotion from '../../mobileLayout/FramerMotion';
import MultiChatResult from '../../mobileLayout/MultiChatResult';
import { AnimatePresence } from 'framer-motion';
import ChatroomMenu from '../../mobileLayout/ChatroomMenu';
import IconHam from '@/styles/icons/IconHam';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import IconAddAddress from '@/styles/icons/IconAddAddress';
import IconAddress from '@/styles/icons/IconAddress';
import IconChatUser from '@/styles/icons/IconChatUser';

interface Content {
  prepayPayNo: string;
  subject: string;
  sndMsg: string;
  sndDttm: string;
  callback: string;
  msgGb: string;
  imgData: string | null;
  fileData: string | null;
}

const MultiChatroom = () => {
  const navigate = useNavigate();
  const chatRoomRef = useRef<HTMLDivElement>(null);
  const chatFootRef = useRef<HTMLDivElement>(null);
  /* 기존 스크롤 높이 유지 */
  const [previousHeight, setPreviousHeight] = useState<number>(0);
  // 전송결과창 오픈
  const [resultOpenS, setResultOpenS] = useRecoilState(resultOpenRecoil);
  // 전송결과 메세지 데이터
  const setResultDataS = useSetRecoilState(resultDataRecoil);
  //메뉴 오픈
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  //단체 채팅방 데이터 리코일
  const multiChatRoomId = useRecoilValue(multiChatRoomIdRecoil);

  // 멀티채팅방 데이터가 초기화 됐을때 밖으로 나가게
  useEffect(() => {
    if (!multiChatRoomId) {
      navigate('/mobile/message/messageList');
    }
  }, [multiChatRoomId]);

  // 단체채팅방 데이터 가져오는 훅
  const multiChatRoomMessageDataHook = useSearchMultiChatroomData({ chatRoomId: multiChatRoomId });

  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      calScrollUpEvent({ scrollTop, scrollHeight, clientHeight }) &&
      multiChatRoomMessageDataHook.hasNextPage &&
      !multiChatRoomMessageDataHook.isFetchingNextPage
    ) {
      setPreviousHeight(chatRoomRef.current?.scrollHeight as number);
      multiChatRoomMessageDataHook.fetchNextPage();
    }
  };

  useEffect(() => {
    if (multiChatRoomMessageDataHook.data) {
      const isInitialLoad = multiChatRoomMessageDataHook.data.pages.length === 1;
      const isScrollLoad = multiChatRoomMessageDataHook.data.pages.length > 1;

      setTimeout(() => {
        if (chatRoomRef.current) {
          // 처음 들어왔을 때 하단으로 내리기
          if (isInitialLoad) {
            chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
            // 인피니티 스크롤로 상단 찍었을 때 받아온 데이터 높이값 - 이전 데이터 높이값
          } else if (isScrollLoad) {
            chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight - previousHeight;
          }
        }
      }, 100);
    }
  }, [multiChatRoomMessageDataHook.data, multiChatRoomMessageDataHook.isFetchingNextPage]);

  const onClickMessageResultOpen = (message: Content) => {
    setResultOpenS(true);
    setResultDataS(message);
    // 현재 페이지의 해시를 '#result'로 설정
    window.location.hash = '#result';
  };

  // const onClickChatroomMenuOpen = () => {
  //   setMenuOpen(true);
  //   window.location.hash = '#chatroomMenu';
  // };

  return (
    <>
      <S.HeaderContainer>
        {multiChatRoomMessageDataHook.data && (
          <>
            <S.HeaderButtonIcon
              onClick={() => {
                navigate('/mobile/message/messageList');
              }}
            >
              <IconArrowLongLeft />
            </S.HeaderButtonIcon>
            <S.MainTitle>
              {` ${multiChatRoomMessageDataHook.data && multiChatRoomMessageDataHook.data.pages[0].chatRoomName}`}
            </S.MainTitle>
            <S.IconContainer
              onClick={() => {
                // onClickChatroomMenuOpen();
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  gap: '.4rem',
                  fontSize: '1.6rem',
                  fontWeight: '600',
                }}
              >
                <div style={{ lineHeight: '.7' }}>
                  {multiChatRoomMessageDataHook.data && multiChatRoomMessageDataHook.data.pages[0].totalCount}
                </div>
                <div style={{ lineHeight: '.7' }}>
                  <IconChatUser />
                </div>
              </div>
            </S.IconContainer>
          </>
        )}
      </S.HeaderContainer>

      <S.ChatroomContainer className="mobileMessageChatRoom" ref={chatRoomRef} onScroll={handleScroll}>
        {multiChatRoomMessageDataHook.isFetchingNextPage && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.6rem',
              padding: '2rem 0',
            }}
          >
            <Spin size="default" />
            <span style={{ marginLeft: 8 }}>로딩 중...</span>
          </div>
        )}

        {!multiChatRoomMessageDataHook.isLoading &&
          multiChatRoomMessageDataHook.formatData &&
          multiChatRoomMessageDataHook.formatData.length > 0 &&
          multiChatRoomMessageDataHook.formatData.map((message, index) => {
            const currentDate = message.sndDttm.slice(0, 8); // 현재 메시지의 날짜 (YYYYMMDD)
            const prevDate =
              index > 0
                ? multiChatRoomMessageDataHook.formatData &&
                  multiChatRoomMessageDataHook.formatData[index - 1].sndDttm.slice(0, 8)
                : null; // 이전 메시지의 날짜

            const shouldShowDate = currentDate !== prevDate; // 날짜가 바뀌는 경우만 true
            return (
              <S.MessageItem key={`${message.prepayPayNo}-${message.sndDttm}`}>
                {/* 날짜 헤더는 날짜가 바뀌는 경우에만 렌더링 */}
                {shouldShowDate && (
                  <S.EtcChattingDateText>
                    <span>{formatDate(currentDate)} </span>
                  </S.EtcChattingDateText>
                )}
                <S.EtcChattingMyZone>
                  <S.EtcChattingContentWrap>
                    <S.EtcChattingHourText>
                      <S.EtcChattingHourText>
                        <div>{`결과 보기👉`}</div>
                        <div>{formatTime(message.sndDttm)}</div>
                      </S.EtcChattingHourText>
                    </S.EtcChattingHourText>
                    <S.EtcChattingMyTextBox
                      className="chattingMyTextBox"
                      onClick={() => onClickMessageResultOpen(message)}
                    >
                      {message.imgData && (
                        <img
                          src={`data:image/jpeg;base64,${message.imgData}`}
                          alt="Message"
                          style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
                        />
                      )}
                      <p>{underLine(removeWebCharacter(message.sndMsg, '[Web발신]'))}</p>
                    </S.EtcChattingMyTextBox>
                  </S.EtcChattingContentWrap>
                </S.EtcChattingMyZone>
              </S.MessageItem>
            );
          })}
      </S.ChatroomContainer>

      <S.ChatroomChatting>
        <MobileMessageChatRoomFooter chatRoomRef={chatRoomRef} chatFootRef={chatFootRef} />
      </S.ChatroomChatting>

      <MobileLoader isLoading={multiChatRoomMessageDataHook.isLoading} />

      <AnimatePresence>
        {resultOpenS && (
          <FramerMotion>
            <MultiChatResult />
          </FramerMotion>
        )}

        {menuOpen && (
          <FramerMotion>
            <ChatroomMenu chatRoomId={multiChatRoomId} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          </FramerMotion>
        )}
      </AnimatePresence>
    </>
  );
};

export default MultiChatroom;
