/* eslint-disable */
import React, { useEffect, useState } from 'react';
import * as S from './DisplayIndex.styles';
import MessageManagement from './MessageManagement/MessageManagement';
import { useRecoilValue } from 'recoil';

import CustomerService from './CustomerService/CustomerService';
import { softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { useLocation } from 'react-router-dom';

const DisplayIndex = () => {
  const [componentToRender, setComponentToRender] = useState<any>();

  const softPhoneTopTabState = useRecoilValue(softPhoneTopTabRecoil);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/') {
      setComponentToRender(<MessageManagement />);
    } else if (`/${location.pathname.split('/')[1]}` === '/customer') {
      setComponentToRender(<CustomerService />);
    }
  }, [softPhoneTopTabState, location]);

  return (
    <S.MessageSendHistoryContainer className="MessageSendHistoryContainer">
      {componentToRender}
    </S.MessageSendHistoryContainer>
  );
};

export default DisplayIndex;
