/* eslint-disable */

import {
  activeNumberRecoil,
  arsMsgToggleRecoil,
  say015Number,
  selectedArsScenarioInfoRecoil,
} from '@/recoil/atoms/say015Atom';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as S from './ArsMockupPhone.styles';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';
import { useQueryClient } from '@tanstack/react-query';
import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useArsGuidePlayAudio } from '@/components/hooks/customs/say015/say105Handle/useArsGuidePlayAudio';
import ArsRealMsg from '@/components/softPhone/DisplayGroups/arsContents/ArsRealMsg';

/**
 * @title ARS 핸드폰 컴포넌트
 *
 * */
const ArsPhone = () => {
  const queryClient = useQueryClient();

  /* 선택된 ARS 시나리오 */
  const [selectedArsScenarioInfoS, setSelectedArsScenarioInfoS] = useRecoilState(selectedArsScenarioInfoRecoil);

  const [arsJsonDataS, setArsJsonDataS] = useState<NextItem>(
    queryClient.getQueryData(['arsJsonData', '01585041722.json']) as any,
  );
  const { findDataById } = use015ArsJSON({ arsData: arsJsonDataS });
  /* 휴대폰 키값 */
  const numberKey = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  /* 015 번호 */
  const say015NumberS = useRecoilValue(say015Number);
  /* 현재 눌러진 휴대폰 키값 */
  const [activeNumberS, setActiveNumberS] = useRecoilState(activeNumberRecoil);
  /* 현재 눌러진 휴대폰 키값의 JSON 데이터 */
  const [activeJsonDataS, setActiveJsonDataS] = useState<NextItem>(findDataById(arsJsonDataS, activeNumberS));
  const audioPlayHooks = useArsGuidePlayAudio({ data: findDataById(arsJsonDataS, activeNumberS) });

  /* 선택된 ARS 시나리오  */
  const selectedArsScenarioInfoR = useRecoilValue(selectedArsScenarioInfoRecoil);

  /* 휴대폰 클릭 딜레이 */
  const [isBlockS, setIsBlockS] = useState<boolean>(false);

  // =======================================================
  // 초기 진입 및 시나리오 변경되면 출력 오디오, active 핸드폰 번호 초기화
  // =======================================================
  useEffect(() => {
    audioPlayHooks.setTTSAudioUrl(null);
    audioPlayHooks.setAudioUrl(null);
    setActiveNumberS(null);
  }, [selectedArsScenarioInfoS]);

  // ================================================
  // 선택된 시나리오 및 핸드폰 active keyNumber 가 변경시 수행
  // ================================================
  useEffect(() => {
    const arsJsonData: any = queryClient.getQueryData(['arsJsonData', selectedArsScenarioInfoR?.fileName]);
    if (activeNumberS && arsJsonData && say015NumberS) {
      setArsJsonDataS(arsJsonData);
      const activeNumberJSON = findDataById(arsJsonData, activeNumberS);
      setActiveJsonDataS(activeNumberJSON);
      /* 인사말 or 음성 재생 */
      if (activeNumberJSON.s015Data.type === 'LINK_SMS') {
        audioPlayHooks.setTTSAudioUrl(null);
        audioPlayHooks.setAudioUrl(null);
        setArsMsgToggleS(true);
      }
    }
  }, [activeNumberS, say015NumberS, selectedArsScenarioInfoR]);

  // =====================
  // 휴대폰 키 클릭 이벤트 핸들러
  // =====================
  const handleActiveNumber = (number: string) => {
    if (!activeNumberS || isBlockS) return;
    setIsBlockS(true);
    setTimeout(() => {
      setIsBlockS(false);
    }, 1500);
    if (audioPlayHooks.audioRef.current) audioPlayHooks.audioRef.current.pause();
    if (number === 'root') {
      setActiveNumberS('root');
    } else {
      setActiveNumberS((prevState) => {
        if (prevState === 'root') {
          return number;
        } else {
          const { s015Data } = findDataById(arsJsonDataS, prevState);
          if (prevState && s015Data?.type === 'READ') {
            return `${prevState}${number}`;
          }
          return number;
        }
      });
    }
  };
  // ================================
  // 휴대폰 ARS 테스트 종료 클릭 이벤트 핸들러
  // ================================
  const handleControlBtn = () => {
    if (audioPlayHooks.audioRef.current) audioPlayHooks.audioRef.current.pause();
    if (!activeNumberS) {
      setActiveNumberS('root');
    } else {
      setActiveNumberS(null);
    }
  };

  // ars 폰 토글하는 STATS
  const [arsMsgToggleS, setArsMsgToggleS] = useRecoilState<boolean>(arsMsgToggleRecoil);

  return (
    <S.Wrap className="ArsMockupPhone">
      <div className="txtGroup">
        <h3>음성사서함 보기</h3>
        <p>
          설정된 인사말 부터 시나리오가 재생되며, 위젯 클릭 시 해당 위젯부터 재생됩니다. 실제 통화화면이 아닌, 이해를
          돕기 위한 가상의 전화 화면입니다.
        </p>
        {/*        <span>-</span>
        <h3>
          <p>현재 추가중인 기능입니다.</p>
          <p>아래 모바일을 클릭해 미리 체험해보실 수 있습니다.</p>
        </h3>*/}
      </div>
      <S.ArsPhone>
        {arsMsgToggleS ? (
          <div className="wrap chatWrap">
            <ArsRealMsg activeJsonData={activeJsonDataS} />
          </div>
        ) : (
          <div className="wrap numWrap">
            <div className="people">
              <h4>{say015NumberS ? formatPhoneNumber(say015NumberS) : '015-0000-0000'}</h4>
            </div>
            <div className="numberPad">
              {numberKey.map((numberKey) => (
                <button
                  key={numberKey}
                  onClick={() => handleActiveNumber(numberKey)}
                  className={activeNumberS?.slice(-1) === numberKey ? 'active' : ''}
                >
                  {numberKey}
                </button>
              ))}

              <button className={`${!activeNumberS ? 'control active' : 'control'}`} onClick={handleControlBtn}>
                <p className="stop">
                  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="21" viewBox="0 0 54 21" fill="none">
                    <path
                      d="M10.7678 19.5103L15.2237 15.9676C15.7492 15.5526 16.1738 15.0239 16.4656 14.4212C16.7574 13.8185 16.9088 13.1575 16.9084 12.4879L16.9085 6.69368C23.6515 4.49716 30.9177 4.49716 37.6607 6.69368L37.6607 12.5194C37.6607 13.8735 38.2748 15.1489 39.3454 15.9991L43.7699 19.5103C44.6272 20.1891 45.7043 20.529 46.796 20.4651C47.8877 20.4012 48.9177 19.9381 49.6901 19.1639L52.414 16.44C54.1932 14.6608 54.1932 11.7007 52.3038 10.0317C38.0228 -2.58028 16.5306 -2.56453 2.26536 10.0317C0.375929 11.7007 0.375931 14.6608 2.15514 16.44L4.87907 19.1639C6.4536 20.7699 8.98859 20.9116 10.7678 19.5103Z"
                      fill="currentColor"
                    />
                  </svg>
                </p>
                <p className="start">
                  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                    <path
                      d="M48.7091 36.8241L41.0737 35.9524C40.1759 35.8469 39.2659 35.9463 38.412 36.2431C37.5581 36.5399 36.7826 37.0263 36.1438 37.6658L30.6126 43.1969C22.079 38.8569 15.1428 31.9207 10.8028 23.3871L16.364 17.8259C17.6566 16.5333 18.2878 14.7297 18.0774 12.896L17.2057 5.32071C17.0352 3.85429 16.3315 2.50175 15.2284 1.52061C14.1253 0.539466 12.6999 -0.00175029 11.2236 4.25262e-06L6.02314 4.25262e-06C2.62631 4.25262e-06 -0.199381 2.82569 0.0110427 6.22253C1.60425 31.8942 22.1356 52.3955 47.7772 53.9887C51.174 54.1991 53.9997 51.3734 53.9997 47.9766V42.7761C54.0298 39.74 51.7452 37.1848 48.7091 36.8241Z"
                      fill="currentColor"
                    />
                  </svg>
                </p>
              </button>
            </div>
            {audioPlayHooks.audioRef && audioPlayHooks.ttsAudioUrl && (
              <>
                <audio
                  style={{ display: 'none' }}
                  autoPlay
                  ref={audioPlayHooks.audioRef}
                  onLoadedMetadata={audioPlayHooks.handleLoadedMetadata}
                  onEnded={audioPlayHooks.handleEnded}
                >
                  <source src={audioPlayHooks.ttsAudioUrl} type="audio/wav" />
                  <track kind="captions" src="" label="ARS 음성 파일입니다." />
                </audio>
              </>
            )}
          </div>
        )}
      </S.ArsPhone>
    </S.Wrap>
  );
};
export default ArsPhone;
