import styled from 'styled-components';

type WrapperType = { widthData: number };
type TableBodyType = { tdWidthData: number };
export const Container = styled.div`
  z-index: 0;
  overflow-x: hidden;
  display: flex;
  align-items: flex-start;
  button {
    cursor: pointer;
  }

  @media screen and (max-width: 1440px) {
    width: calc(100% - 1rem);
  }
  @media screen and (max-width: 768px) {
    position: relative;
    padding: 0 1rem;
    width: 100%;
    &.active {
      width: 80%;
    }

    /* 메인 주소록 start */
    &.mainTable {
      .mainWrapper {
        height: calc(var(--vh, 100vh) - 11rem - 5rem);
      }
      .mainAddArticle {
        display: none;
      }
      table {
        tr {
          display: flex;
          align-items: center;
        }
        th,
        td {
          width: unset;
          min-width: 8rem;
          padding: 0;
          flex: 1;
          &:nth-of-type(4) {
            flex: 1.6;
          }
        }

        .fixedRightHidden,
        .fixedRight {
          display: none;
        }

        tbody {
          tr {
            position: relative;
            td {
              display: flex;
              align-items: center;
              > p,
              > div {
                max-width: 100%;
                padding: 0 1rem;
                margin: 0;
              }
            }
          }
        }
        .fixedBox {
          width: 3rem;
          max-width: 3rem;
          min-width: 3rem;
          padding: 0;
          align-items: center;
          justify-content: center;
          /* display: none; */
        }
      }
    }
    /* 메인 주소록 end */
  }
  @media screen and (max-width: 540px) {
    &.mainTable {
      table {
        th,
        td {
          font-size: 1.2rem;
        }
      }
    }
  }
`;

// 항목추가
export const AddArticle = styled.button`
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 0 0 -1.2rem;
  border-radius: 0 0.5rem 0.5rem 0;
  border-left: 1px solid ${(props) => props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray200};
  color: ${(props) => props.theme.colors.txt400};
`;

export const Wrapper = styled.div<WrapperType>`
  position: relative;
  width: calc(${(props) => (props.widthData ? `${props.widthData}px` : '100%')} - 3rem);
  min-width: calc(100% - 38rem);
  height: calc(var(--vh, 100vh) - 82px);
  max-height: calc(var(--vh, 100vh) - 82px);
  overflow: auto;
  transition: width 0.8s ease 0s;
  :has(tbody.skeleton) {
    overflow: hidden;
  }

  /* 스크롤바-배경 */
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    border-radius: 0;
    background: transparent;
  }

  /* 스크롤바-바 */
  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  /* 스크롤바-좌우만나는공간  */
  ::-webkit-scrollbar-corner {
    background: transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-button:start {
    background-color: transparent;
    width: 0px;
    height: 31px;
    background-clip: padding-box;
  }

  @media screen and (max-width: 1440px) {
    width: ${(props) => (props.widthData ? `${props.widthData}px` : '100%')};
  }
`;

export const Table = styled.table`
  position: relative;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  white-space: nowrap;
  border-collapse: separate; // td, th간 간격

  tr {
    border-left-color: transparent;
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    min-width: 20rem;

    :last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }
  tbody {
    td {
      height: 40px;
      > div {
        > div {
          max-width: 13rem;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0px auto;
          text-align: left;
        }
      }
    }
  }

  /* 공통 체크박스 */

  .fixedBox {
    text-align: center;
    min-width: 40px;
    width: 40px;
    max-width: 40px;
    padding: 0;
    border-radius: 5px 0 0 5px;
  }

  /* 복사, 삭제 버튼 스타일 */
  .fixedRightHidden,
  .fixedRight {
    min-width: 8rem;
    max-width: 8rem;
    width: 8rem;
  }
  .fixedRight {
    right: 0;

    .btnWrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .group {
      margin: 0 0.6rem;
      &.active {
        button {
          color: ${(props) => props.theme.colors.txt500};
        }
      }
      > button {
        display: flex;
        padding: 0;
        color: ${(props) => props.theme.colors.txt200};
        :hover {
          color: ${(props) => props.theme.colors.txt500};
        }
      }
    }
  }

  /* 순번 */

  .numBox {
    max-width: 6rem;
    min-width: 6rem;
    padding: 0 0.4rem;
    border-left: 1px solid ${(props) => props.theme.colors.gray200};
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
  }
  th.numBox {
    border-color: ${(props) => props.theme.colors.white};
  }

  tr {
    &.active {
      .customer {
        display: block;
        color: ${(props) => props.theme.colors.txt500};
      }
      box-shadow: 0px 0.4rem 0.4rem 0px rgba(191, 191, 191, 0.5);
    }
    &:has(.addGroup.active) {
      z-index: 2;
      .fixedRight {
        position: sticky;
      }
    }
    :hover {
      z-index: 1;
      .customer {
        display: block;
      }
      .fixedRight {
        position: sticky;
        ::before {
          content: '';
          position: absolute;
          top: 0;
          display: block;
          width: 1px;
          height: 100%;
          background-color: ${(props) => props.theme.colors.gray300};
        }
      }
    }
  }
`;
export const TableHead = styled.thead`
  z-index: 3;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.colors.gray200};
  border-radius: 5px;
  font-weight: 500;
  font-size: 1.3rem;
  tr {
    border: 1px solid ${(props) => props.theme.colors.gray300};
    height: 30px;
  }

  th {
    position: relative;
    padding: 0 3rem;
    min-width: 20rem;
    border-top: 0;
    color: ${(props) => props.theme.colors.txt300};
    border-right: 1px solid ${(props) => props.theme.colors.white};
    &:last-of-type {
      border-right: 0;
    }
  }

  /* 배열 - 공통 스타일 */

  .arrangeGroup {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrange {
      display: flex;
      flex-direction: column;
      svg {
        path {
          fill: ${(props) => props.theme.colors.gray600};
          stroke: ${(props) => props.theme.colors.gray600};
        }
      }
      button {
        cursor: pointer;
        padding: 2px;
      }
    }
  }

  /* 배열 - 오름차순 */
  th.asc {
    .descBtn {
      path {
        /* #c3c3c3 */
        fill: ${(props) => props.theme.colors.gray300};
        stroke: ${(props) => props.theme.colors.gray300};
      }
    }
  }

  /* 배열 - 내림차순 */
  th.desc {
    .ascBtn {
      path {
        /* #c3c3c3 */
        fill: ${(props) => props.theme.colors.gray300};
        stroke: ${(props) => props.theme.colors.gray300};
      }
    }
  }

  /* 헤드 체크박스 */

  .fixedBox {
    z-index: 2;
    position: sticky;
    left: 0;
    background: ${(props) => props.theme.colors.gray200};
    border-right: 0;
    /* background: green; */
  }
  @media screen and (max-width: 768px) {
    top: -1px;
    .arrangeGroup {
      padding: 0 1rem;
    }
  }
`;
export const TableBody = styled.tbody<TableBodyType>`
  position: relative;
  line-height: normal;

  &.skeleton {
    td {
      > div {
        position: relative;
        opacity: 1;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }

  tr {
    .fixedBox {
      background: ${(props) => props.theme.colors.white};
      border-right-color: transparent;
      border-left: 1px solid ${(props) => props.theme.colors.gray300};
      border-right: 0;
    }
    :hover {
      box-shadow: 0px 0.2rem 0.4rem 0px ${(props) => props.theme.colors.gray200};
    }
  }

  .selectBoxtTxt {
    height: 100%;
  }
  td {
    background: ${(props) => props.theme.colors.white};
    width: ${(props) => `${props.tdWidthData}px`};
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    position: relative;
    color: ${(props) => props.theme.colors.txt500};
    font-size: 1.4rem;

    > p {
      width: 100%;
    }

    .text {
      opacity: 0;
      display: none;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 100%;
      text-align: center;
    }

    &.focus {
      > p {
        opacity: 0;
        display: none;
      }

      > div {
        opacity: 1;
        z-index: 1;
        display: flex;
      }
    }
  }

  /* body 체크박스 */

  .fixedBox {
    z-index: 2;
    position: sticky;
    left: 0;
    input[type='checkbox'] {
      margin-top: 0.2rem;
    }
    .customer {
      display: none;
      position: absolute;
      top: 0;
      right: -2.5rem;
      padding: 0 1rem 0 0;
      margin: 0;
      color: ${(props) => props.theme.colors.gray500};
      background: ${(props) => props.theme.colors.white};
      height: 100%;
      :hover {
        color: ${(props) => props.theme.colors.gray700};
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
      &.active {
        color: ${(props) => props.theme.colors.gray700};
      }
    }
  }

  tr:has(input[type='checkbox']:checked) {
    td,
    .customer {
      background: ${(props) => props.theme.colors.gray100};
    }

    .fixedBox {
      box-sizing: border-box;
    }
  }

  input[type='text'] {
    border: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    outline-color: ${(props) => props.theme.colors.main};
    padding: 0.8rem 0;
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.txt500};
    background: ${(props) => props.theme.colors.white};
  }

  tr:has(td.focus),
  tr:has(textarea:focus) {
    z-index: 2;
  }

  td {
    > p {
      max-width: 13rem;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 auto;
      text-align: left;
    }
  }

  .memo {
    position: relative;

    > div {
      top: 5px;
      transform: translateX(-50%);
      max-width: 96%;
      align-items: flex-start;
    }

    &.focus {
      textarea {
        height: 200px;
        box-shadow: 0 0.7rem 1.3rem 0 rgba(0, 0, 0, 0.1);
      }
    }

    textarea {
      width: 100%;
      padding: 10px;
      font-size: 1.4rem;
      box-sizing: border-box;
      background-color: ${(props) => props.theme.colors.white};
      border: none;
      outline-color: ${(props) => props.theme.colors.white};
      resize: none;
      font-family: 'SUIT', 'Noto Sans KR', Roboto, 'Nanum Gothic', 'Open Sans', sans-serif;
      color: ${(props) => props.theme.colors.gray700};
      // overflow 스크롤

      ::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
      }

      ::-webkit-scrollbar {
        width: 0.4rem;
        background-color: #e5e4e4;
        border-radius: 40px;
      }

      ::-webkit-scrollbar-thumb {
        background: #98999a;
        border-radius: 40px;
      }
    }
  }

  .memoLarge {
    .text {
      opacity: 1;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 38px;
      text-align: center;
      textarea {
        height: 100%;
        :focus {
          height: 20rem;
          box-shadow: 0 0.7rem 1.3rem 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`;

// 메인 테이블 셀렉트박스
export const SelectBox = styled.div`
  position: relative;

  button {
    border: 0;
    cursor: pointer;
    gap: 1rem;
  }

  &.active {
    border-radius: 0;
    width: 100%;
    height: 100%;

    ul {
      display: block;
      border-top: 0;
    }

    > button {
      background: ${(props) => props.theme.colors.white};
      overflow: hidden;

      border-radius: 0;
      border-bottom-color: #d6d6dc;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  > button {
    width: 100%;
    height: 100%;
    padding: 0.6rem 1rem;
    color: #5b5b5c;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 1.5rem;
    border: 1px solid transparent;

    span {
      margin-left: 10px;
    }
  }

  ul {
    z-index: 1;
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: ${(props) => props.theme.colors.white};
    overflow-y: auto;
    max-height: 120px;

    // overflow 스크롤

    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      background-color: transparent;
      border-radius: 40px;
      margin: 20px 0;
      scrollbar-gutter: both;
    }

    ::-webkit-scrollbar-thumb {
      background: #98999a;
      border-radius: 40px;
      padding: 1rem;
      background-clip: padding-box;
    }

    li {
      position: relative;

      :last-child {
        margin-bottom: 0;
      }

      button {
        width: 100%;
        padding: 1rem;
        color: #5b5b5c;
        background: transparent;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        /* overflow-x: hidden; */
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      :hover {
        background: #f6f7fe;
        font-weight: 700;

        button {
          font-weight: 700;
        }
      }
    }
  }
`;
