import { post015Resub, postCancel015Sub } from '@/apis/api/say015Apis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { userInfoRecoil } from '@/recoil/atoms/useInfo';
import { SERVICE_NAME } from '@/shared/constants';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { usePOBox015 } from '../say015/pobox015/usePOBox015';

export const useSay015SubInfo = () => {
  const navigate = useNavigate();
  const [btnState, setBtnState] = useState<boolean>(false); // 버튼 중복클릭 방지

  /* 사용자 회원정보 recoil */
  const userInfoS = useRecoilValue(userInfoRecoil);
  const user = userInfoS.usrNm;

  // Say015 구독 해지
  const { mutate: cancel015Sub } = useMutation(() => postCancel015Sub(), {
    onSuccess: () => {
      authorityDataRefetch();
      setBtnState((prev) => prev && false);
      successModal(`${SERVICE_NAME} 구독해지`, `${SERVICE_NAME} 구독을 해지했습니다.`, true);
    },
    onError: () => {
      authorityDataRefetch();
      setBtnState((prev) => prev && false);
    },
  });

  // 구독 해지 철회
  const { mutate: postResub015 } = useMutation(() => post015Resub(), {
    onSuccess: () => {
      authorityDataRefetch();
      setBtnState((prev) => prev && false);
      successModal(`${SERVICE_NAME} 구독`, `${SERVICE_NAME} 구독 해지를 철회했습니다.`, true);
    },
    onError: () => {
      authorityDataRefetch();
      setBtnState((prev) => prev && false);
    },
  });

  // pageState는 string으로 '정기구독', '단기구독', '구독만료', '미가입' 네가지 상태가 존재
  const { pageState, authorityData, authorityDataIsLoading, authorityDataRefetch } = usePOBox015();

  const { confirmModal, successModal } = useModalHook();

  // 구독 취소하기
  const cancel015SubHandle: React.MouseEventHandler<HTMLButtonElement> = () => {
    setBtnState((prev) => !prev && true);
    confirmModal(
      async () => cancel015Sub(),
      `${SERVICE_NAME} 구독해지`,
      `${SERVICE_NAME} 구독을 해지하시겠습니까?`,
      true,
      undefined,
      async () => setBtnState((prev) => prev && false),
    );
  };

  // 구독 취소 철회하기
  const postResub015Handle: React.MouseEventHandler<HTMLButtonElement> = () => {
    setBtnState((prev) => !prev && true);
    confirmModal(
      async () => postResub015(),
      `${SERVICE_NAME} 구독`,
      `${SERVICE_NAME} 구독 해지를 철회하시겠습니까?`,
      true,
      undefined,
      async () => setBtnState((prev) => prev && false),
    );
  };

  // 재구독 015로 보내기
  const naviResub015Handle: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate('/');
  };

  return { btnState, cancel015SubHandle, user, authorityData, pageState, postResub015Handle, naviResub015Handle };
};
