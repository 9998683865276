import React from 'react';
import { useRecoilState } from 'recoil';
import * as S from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/ChatRoom015Head.styled';
import { tableAddOpenRecoil } from '@/recoil/atoms/addressList';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import { IChatRoom015HeadNotReg } from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/ChatRoom015Head.types';
import IconAddAddress from '@/styles/icons/IconAddAddress';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';

const ChatRoom015HeadNotReg = (props: IChatRoom015HeadNotReg) => {
  const [, setTableAddOpen] = useRecoilState(tableAddOpenRecoil);

  const onClickAddAddress = () => {
    setTableAddOpen(true);
    if (props.chatUserInfo.phoneNumber !== null) {
      props.clickEachListH({
        buddyName: props.chatUserInfo.buddyName,
        phoneNumber: props.chatUserInfo.phoneNumber,
        batch: false,
      });
    }
  };
  return (
    // 연락처가 등록되어있지 않았을 경우 UI 컴포넌트
    <S.EtcChattingHeadNotReg>
      <S.EtcChattingHeadNotRegLeftWrap>
        <S.NotRegContactInfoWrap>
          <S.EtcChattingHideBtn
            type="button"
            onClick={() => {
              if (props.chatUserInfo.phoneNumber !== null)
                props.clickEachListH({
                  buddyName: props.chatUserInfo.buddyName,
                  phoneNumber: props.chatUserInfo.phoneNumber,
                  batch: false,
                });
            }}
          >
            <IconArrowLongLeft />
          </S.EtcChattingHideBtn>
          {/* 핸드폰 번호 */}
          <S.NotRegContacPhoneNumber>
            {props.chatUserInfo.phoneNumber && TelHyphen(props.chatUserInfo.phoneNumber)}
          </S.NotRegContacPhoneNumber>

          {/*<S.AddressAddBtn>*/}
          {/*    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">*/}
          {/*        <path d="M11.752 6.23694C11.752 6.33005 11.715 6.41934 11.6491 6.48518C11.5833 6.55102 11.494 6.588 11.4009 6.588H6.60302V11.3859C6.60302 11.479 6.56603 11.5683 6.50019 11.6341C6.43436 11.7 6.34506 11.7369 6.25195 11.7369C6.15885 11.7369 6.06955 11.7 6.00371 11.6341C5.93788 11.5683 5.90089 11.479 5.90089 11.3859V6.588H1.10302C1.00991 6.588 0.920615 6.55102 0.854777 6.48518C0.78894 6.41934 0.751953 6.33005 0.751953 6.23694C0.751953 6.14383 0.78894 6.05454 0.854777 5.9887C0.920615 5.92286 1.00991 5.88587 1.10302 5.88587H5.90089V1.088C5.90089 0.994894 5.93788 0.9056 6.00371 0.839763C6.06955 0.773925 6.15885 0.736938 6.25195 0.736938C6.34506 0.736938 6.43436 0.773925 6.50019 0.839763C6.56603 0.9056 6.60302 0.994894 6.60302 1.088V5.88587H11.4009C11.494 5.88587 11.5833 5.92286 11.6491 5.9887C11.715 6.05454 11.752 6.14383 11.752 6.23694Z" fill="#366EFF" stroke="#366EFF" />*/}
          {/*    </svg>*/}
          {/*    <span>주소록 등록하기</span>*/}
          {/*</S.AddressAddBtn>*/}
        </S.NotRegContactInfoWrap>
      </S.EtcChattingHeadNotRegLeftWrap>
      {/* 주소록 추가 */}
      <S.RegAddressBookAddBtn type="button" onClick={() => onClickAddAddress()}>
        <IconAddAddress />
      </S.RegAddressBookAddBtn>
      {/*<S.EtcChattingHeadNotRegRightWrap>*/}
      {/*    <S.SearchBtn>*/}
      {/*        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">*/}
      {/*            <path d="M22.6591 23.1203L16.3963 16.8575M16.3963 16.8575C17.2135 16.0403 17.8618 15.0701 18.3041 14.0024C18.7463 12.9347 18.974 11.7903 18.974 10.6346C18.974 9.47885 18.7463 8.33446 18.3041 7.26673C17.8618 6.19899 17.2135 5.22882 16.3963 4.41161C15.5791 3.5944 14.609 2.94616 13.5412 2.50389C12.4735 2.06162 11.3291 1.83398 10.1734 1.83398C9.01767 1.83398 7.87328 2.06162 6.80554 2.50389C5.73781 2.94616 4.76764 3.5944 3.95043 4.41161C2.3 6.06204 1.3728 8.3005 1.3728 10.6346C1.3728 12.9686 2.3 15.2071 3.95043 16.8575C5.60086 18.5079 7.83932 19.4351 10.1734 19.4351C12.5074 19.4351 14.7459 18.5079 16.3963 16.8575Z" stroke="#001F49" />*/}
      {/*        </svg>*/}
      {/*    </S.SearchBtn>*/}
      {/*</S.EtcChattingHeadNotRegRightWrap>*/}
    </S.EtcChattingHeadNotReg>
  );
};

export default ChatRoom015HeadNotReg;
