import React, { useEffect, useRef, useState } from 'react';
import * as S from '../SaveMessageStyled';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { instance, instanceFile } from '@/shared/lib/clientAxios';
import { useGetSaveMessageList } from '../hooks/UseGetSaveMessageList';
import { editItem } from '../SaveMessageType';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useRecoilState } from 'recoil';

interface SaveMsgEditComponentProps {
  messageTabState: string;
  searchKeyword: string;
  editItem: editItem;
  setEditState: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageTabState: React.Dispatch<React.SetStateAction<string>>;
  editRef: React.RefObject<HTMLDivElement>;
  editState: boolean;
}

const SaveMsgEditComponent: React.FC<SaveMsgEditComponentProps> = ({
  messageTabState,
  searchKeyword,
  editItem,
  setEditState,
  setMessageTabState,
  editRef,
  editState,
}) => {
  // 상태 변수 추가
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  //수정
  const [editTitle, setEditTitle] = useState<string>('');
  const [editBody, setEditBody] = useState<string>('');
  const [editSeqNo, setEditSeqNo] = useState<number | null>(null);
  const [editId, setEditId] = useState<number | null>(null);

  const [preveMsgType, setPreveMsgType] = useState<string>(''); // 수정버튼 클릭시에 메세지 상태값
  const [messageType, setMessageType] = useState<string>('SMS'); // 현재 메세지 상태값
  const [editImg, setEditImg] = useState<string | undefined>(undefined);

  const [editImgFile, setEditImgFile] = useState<File | undefined>(undefined);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  //리패치
  const { refetch } = useGetSaveMessageList(messageTabState, searchKeyword);

  // 컨펌 모달
  const { confirmModal, warningModal, successModal } = useModalHook();

  // 타이틀 또는 바디가 변경된 경우
  const isTitleOrBodyChanged = editItem.title !== editTitle || editItem.body !== editBody;
  // 이미지가 추가되었거나 기존 이미지와 변경된 경우
  const isImageChanged = editImg && (!editItem.file || editItem.file !== editImg);
  // 이미지가 삭제된 경우
  const isImageRemoved = !editImg && editItem.file;

  // 컴포넌트가 마운트 되면서 리스트에서 상세 값을 가져옵니다.
  useEffect(() => {
    setEditTitle(editItem.title ? editItem.title : '');
    setEditBody(editItem.body ? editItem.body : '');
    setEditSeqNo(editItem.seqNo ? editItem.seqNo : 0);
    setEditId(editItem.id ? editItem.id : 0);
    setEditImg(editItem.file ? editItem.file : undefined);

    if (editItem.file) {
      // Base64 데이터를 File 객체로 변환하여 setEditImgFile에 넣기
      const base64ToFile = (base64: string, filename: string, mimeType: string) => {
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeType });
        return new File([blob], filename, { type: mimeType });
      };

      const mimeType = editItem.file.split(',')[0].match(/:(.*?);/)?.[1] || 'image/png';
      const file = base64ToFile(editItem.file, 'image.png', mimeType);

      setEditImgFile(file); // 파일로 변환한 후 설정
      setPreveMsgType('MMS');
    } else if (editItem.body && calMsgByte(editItem.body) <= 90) {
      setPreveMsgType('SMS');
    } else {
      setPreveMsgType('LMS');
    }
  }, [editItem]);

  //수정 버튼 클릭시 타이틀 인풋 변경
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value;
    const byteLength = calMsgByte(newTitle);

    if (byteLength <= 20) {
      setEditTitle(newTitle);
    }
  };

  //수정 버튼 클릭시 바디 인풋 변경
  const handleBodyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newTitle = event.target.value;
    const byteLength = calMsgByte(newTitle);

    if (byteLength <= 2000) {
      setEditBody(newTitle);
    }
  };

  //이미지 버튼 클릭해서 변경
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size > 500 * 1024) {
        // 500KB
        warningModal('경고', '파일 크기가 500KB를 초과합니다.', true);

        return;
      }

      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        warningModal('경고', '허용된 파일 형식은 jpg, jpeg, png 입니다.', true);

        return;
      }

      const img = new Image();
      const reader = new FileReader();

      reader.onloadend = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          // let targetWidth;
          // let targetHeight;

          // if (img.width > img.height) {
          //   //  이미지가 가로가 더 클 때
          //   targetWidth = 391;
          //   targetHeight = 320;
          // } else {
          //   //  이미지가 세로가 더 클 때

          //   targetWidth = 640;
          //   targetHeight = 820;
          // }

          // canvas.width = targetWidth;
          // canvas.height = targetHeight;

          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, { type: file.type });

              const resizedImg = new Image();
              const resizedReader = new FileReader();
              resizedReader.onloadend = () => {
                resizedImg.src = resizedReader.result as string;
              };

              resizedImg.onload = () => {
                // Save the image data
                setEditImg(resizedReader.result as string);
              };

              resizedReader.readAsDataURL(resizedFile);

              setEditImgFile(resizedFile);
            }
          }, file.type);

          e.target.value = '';
        }
      };

      reader.readAsDataURL(file);
    }
  };

  // 메세지 바이트 보여주기
  let messageInfo = null;
  if (messageType === 'SMS') {
    messageInfo = (
      <S.MessageStateInfo>
        <div>{messageType}</div>
        <div>{calMsgByte(editBody)} / 90 Byte</div>
      </S.MessageStateInfo>
    );
  } else if (messageType === 'LMS' || messageType === 'MMS') {
    messageInfo = (
      <S.MessageStateInfo>
        <div>{messageType}</div>
        <div>{calMsgByte(editBody)} / 2000 Byte</div>
      </S.MessageStateInfo>
    );
  }

  // 메세지 내용이랑 이미지 확인해서 메세지 타입 변경
  useEffect(() => {
    if (editImg) {
      setMessageType('MMS');
    } else if (calMsgByte(editBody) <= 90) {
      setMessageType('SMS');
    } else {
      setMessageType('LMS');
    }
  }, [editBody, editImg]);

  //이미지 없애기
  const handleImageDelete = () => {
    setEditImgFile(undefined);
    setEditImg(undefined);
  };

  const handleEditCancelBtnClick = () => {
    if (isTitleOrBodyChanged || isImageChanged || isImageRemoved) {
      setIsCancelClicked(true); // 클릭 상태를 설정
      confirmModal(
        () => {
          setEditState(false);
          setIsCancelClicked(false);
        },
        '경고',
        '수정된 내용이 존재 합니다. 정말 취소하시겠습니까?',
        true,
        undefined,
        () => {
          setIsCancelClicked(false);
        },
      );
    } else {
      setEditState(false);
    }
  };

  useEffect(() => {
    // 포커스 처리
    if (editState && textareaRef.current) {
      textareaRef.current.focus();
    }

    // 외부 클릭시 수정 종료
    // const handleClickOutside = (event: MouseEvent) => {
    //   // 클릭 외부에서 클릭한 경우
    //   if (!editRef.current?.contains(event.target as Node)) {
    //     if (!isCancelClicked) {
    //   // setEditState(false);
    //       handleEditCancelBtnClick();
    //     }
    //   }
    // };

    // document.addEventListener('mousedown', handleClickOutside);
    // return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [editState, isCancelClicked, editRef, textareaRef]);

  // 저장 버튼 클릭 시 호출되는 함수
  const handleEditSaveBtnClick = async () => {
    if (!(isTitleOrBodyChanged || isImageChanged || isImageRemoved)) {
      warningModal('경고', '수정된 내용이 없습니다.', true);
      return;
    }

    const axiosData = {
      prevMsgType: preveMsgType,
      nextMsgType: messageType,
      id: preveMsgType === 'SMS' ? editSeqNo : editId,
      title: editTitle,
      body: editBody,
    };

    confirmModal(
      async () => {
        try {
          if (!editTitle || !editBody) {
            warningModal('경고', '제목과 본문을 모두 입력해 주세요.', true);
            return;
          }

          // 멀티파트 폼 데이터 생성
          const formData = new FormData();
          formData.append('json', new Blob([JSON.stringify(axiosData)], { type: 'application/json' }));

          // 파일이 존재할 경우 추가
          if (editImgFile) {
            formData.append('file', editImgFile ? editImgFile : '');
          }
          // 데이터 전송
          const response = await instanceFile.put('/message/archive', formData);
          // 확인 누른 후 실행할 코드
          successModal('성공', `${editTitle} 메세지가 수정되었습니다`, true);
          setMessageTabState(response.data.msgType); // {msgType: 'SMS' || 'MMS'} 로 탭 상태를 반환해줌
          setEditState(false);
          refetch();
        } catch (error) {
          console.error(error);
        }
      },
      '내용 수정',
      '수정된 내용을 저장 하시겠습니까?',
      true, // centered 기본값
      undefined, // path 생략
    );
  };

  // useEffect(() => {
  //   if (editState && textareaRef.current) {
  //     textareaRef.current.focus();
  //   }
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (editRef.current && !editRef.current.contains(event.target as Node)) {
  //       // setEditState(false);
  //       handleEditCancelBtnClick();
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [editRef]);

  return (
    <S.Inner>
      <S.MessageInputSection ref={editRef} isEditing={editState}>
        <S.MessageTitleInput
          value={editTitle}
          placeholder="제목을 입력해 주세요.(최대20byte)"
          onChange={handleTitleChange}
        />
        <input
          type="file"
          accept="image/jpeg"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id="imageInput"
        />
        {editImg && (
          <S.ImageContainer className="imgContainer">
            <S.ImageItemContainer>
              <S.ImagePreview src={editImg} alt="addImg" />
              <S.DeleteBtn onClick={handleImageDelete} />
            </S.ImageItemContainer>
          </S.ImageContainer>
        )}

        <S.MessageBodyInput
          rows={6}
          value={editBody}
          placeholder="저장할 템플릿 내용을 입력해 주세요."
          onChange={handleBodyChange}
          ref={textareaRef}
        />

        <S.BtnContainer>
          <button onClick={() => document.getElementById('imageInput')?.click()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
              <path
                d="M9.20887 18.3026H3.24901C2.65253 18.3026 2.08049 18.103 1.65872 17.7477C1.23695 17.3923 1 16.9104 1 16.4079V3.14474C1 2.64222 1.23695 2.16029 1.65872 1.80496C2.08049 1.44962 2.65253 1.25 3.24901 1.25H18.992C19.5885 1.25 20.1606 1.44962 20.5823 1.80496C21.0041 2.16029 21.241 2.64222 21.241 3.14474V12.6184L17.7551 9.68158C17.3319 9.33217 16.7622 9.13756 16.1697 9.14004C15.5772 9.14253 15.0098 9.3419 14.5907 9.69484L4.37351 18.3026"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.3689 16.8816L16.7424 14.0395M16.7424 14.0395L20.1159 16.8816M16.7424 14.0395V19.25"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.74583 8.82899C8.98792 8.82899 9.99484 7.98069 9.99484 6.93426C9.99484 5.88782 8.98792 5.03952 7.74583 5.03952C6.50374 5.03952 5.49683 5.88782 5.49683 6.93426C5.49683 7.98069 6.50374 8.82899 7.74583 8.82899Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {messageInfo}
        </S.BtnContainer>

        <section style={{ display: 'flex' }}>
          <S.SendCancelBtn
            onClick={() => {
              handleEditCancelBtnClick();
            }}
          >
            취소하기
          </S.SendCancelBtn>
          <S.SendEditBtn
            style={{
              backgroundColor: isTitleOrBodyChanged || isImageChanged || isImageRemoved ? '#366EFF' : '',
            }}
            onClick={() => {
              handleEditSaveBtnClick();
            }}
          >
            수정하기
          </S.SendEditBtn>
        </section>
      </S.MessageInputSection>
    </S.Inner>
  );
};

export default SaveMsgEditComponent;
