/* eslint-disable */
/**
 * @title 015 채팅방 음성 컴포넌트
 *
 * @author 정휘학
 * @since 2024.04.05
 * */
import * as S from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/ChatRoom015Body.styled';
import React, { useEffect, useState } from 'react';
import { IChatRoom015VMS } from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015VMS/ChatRoom015VMS.types';
import { useMusicPlayer } from '@/components/hooks/customs/say015/say015Ok/useMusicPlayer';
import { useStt } from '@/components/hooks/customs/useStt';
import SkeletonBox from '@/components/common/SkeletonBox';
import { formatTime } from '@/shared/util/format/dateFormatUtil';
import { underLine } from '@/shared/util/format/contentFormatUtil';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import IconTextConversion from '@/styles/icons/IconTextConversion';
import IconDownload from '@/styles/icons/IconDownload';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useRecoilValue } from 'recoil';

const ChatRoom015VMS = (props: IChatRoom015VMS) => {
  const environmentS = useRecoilValue(environmentRecoil);
  const vmsHooks = useMusicPlayer({ filename: props.filename });
  const sttHooks = useStt({ duration: vmsHooks.duration, filename: props.filename });
  const { confirmModal, warningModal } = useModalHook();

  const [activeBars, setActiveBars] = useState<any>([]);

  const [hoverSwitch, setHoverSwitch] = useState<boolean>(true);
  useEffect(() => {
    if (vmsHooks.playSwitchBtn) {
      const intervals: any = [];
      // 오디오 총 시간을 3초로 가정, 15개의 li 태그가 있으므로 각 태그의 애니메이션 시작 시간을 계산
      const delay = parseInt(`${vmsHooks.duration}000`) / 15; // 총 시간을 li 태그 개수로 나눔

      for (let i = 0; i < 15; i++) {
        const timeoutId = setTimeout(() => {
          setActiveBars((prev: any) => [...prev, i + 1]); // 순차적으로 activeBars 상태 업데이트
        }, i * delay);
        intervals.push(timeoutId);
      }

      return () => {
        intervals.forEach(clearTimeout); // 컴포넌트 언마운트 시 setTimeout 취소
        setActiveBars([]); // 상태 초기화
      };
    }
  }, [vmsHooks.playSwitchBtn]); // playSwitchBtn 상태가 변경될 때마다 효과 실행

  const onClickVmsDown = () => {
    confirmModal(
      async () => {
        try {
          if (environmentS === 'app') {
            const base64Data = props.list.fileData.fileBytes;
            const fileName = `${props.filename}.wav`;
            const message = JSON.stringify({ base64Data, fileName });
            window.fromWebToAppReqDownload(message);
          } else {
            const url = vmsHooks.audioUrl; // 이미 Blob URL이므로 fetch 불필요
            const a = document.createElement('a');
            a.href = url;
            a.download = `${props.filename}.wav`; // 파일 이름에 확장자를 명시적으로 설정
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a); // 다운로드 후 요소 제거
            window.URL.revokeObjectURL(url); // URL 해제
          }
        } catch (error) {
          console.error('Download failed', error);
        }
      },
      '오디오 파일 다운로드',
      '오디오 파일을 다운로드하시겠습니까?',
      true,
    );
  };

  return (
    <>
      <S.EtcChattingYourZone>
        <S.EtcChattingMusicWrap className="chattingTextChange">
          <S.EtcChattingYourMuiscBox className={`musicBox ${vmsHooks.playSwitchBtn ? 'active' : ''}`}>
            {/* Start Equalizer Ui */}
            <audio
              style={{ display: 'none' }}
              controls
              ref={vmsHooks.audioRef}
              onTimeUpdate={vmsHooks.handleTimeUpdate}
              onLoadedMetadata={vmsHooks.handleLoadedMetadata}
              onEnded={vmsHooks.handleEnded}
            >
              <source src={vmsHooks.audioUrl} type="audio/wav" />
            </audio>
            <button
              type="button"
              onClick={() => {
                vmsHooks.handlePlay();
              }}
            >
              {!vmsHooks.playSwitchBtn ? (
                <svg
                  className="triangle"
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                >
                  <path
                    d="M11.9096 4.87497C12.2129 5.04098 12.4667 5.2888 12.6436 5.59189C12.8206 5.89497 12.9141 6.24189 12.9141 6.59547C12.9141 6.94905 12.8206 7.29596 12.6436 7.59904C12.4667 7.90213 12.2129 8.14995 11.9096 8.31596L3.81753 12.8444C2.51454 13.5743 0.914062 12.6253 0.914062 11.1245V2.06705C0.914062 0.565595 2.51454 -0.382727 3.81753 0.345901L11.9096 4.87497Z"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                //   <path
                //     d="M4.875 3.08118H7.125C7.5375 3.08118 7.875 3.41868 7.875 3.83118V15.8312C7.875 16.2437 7.5375 16.5812 7.125 16.5812H4.875C4.4625 16.5812 4.125 16.2437 4.125 15.8312V3.83118C4.125 3.41868 4.4625 3.08118 4.875 3.08118ZM10.875 3.08118H13.125C13.5375 3.08118 13.875 3.41868 13.875 3.83118V15.8312C13.875 16.2437 13.5375 16.5812 13.125 16.5812H10.875C10.4625 16.5812 10.125 16.2437 10.125 15.8312V3.83118C10.125 3.41868 10.4625 3.08118 10.875 3.08118Z"
                //     fill="currentColor"
                //   />
                // </svg>
                <p className="square" />
              )}
            </button>
            <S.EtcChattingYourMuiscEqualizer className={`${vmsHooks.playSwitchBtn ? 'active' : ''}`}>
              {Array.from({ length: 13 }, (_, i) => (
                <li key={i} className={activeBars.includes(i + 1) ? 'active' : ''} />
              ))}
            </S.EtcChattingYourMuiscEqualizer>
            {vmsHooks.playSwitchBtn ? <span>{vmsHooks.audioTime}</span> : <span>{vmsHooks.durationView}</span>}
          </S.EtcChattingYourMuiscBox>

          <div className="chatControlWrap">
            <S.EtcChattingControlWrap className="chatControl TextChange">
              <S.EtcChattingMusicTextChange
                type="button"
                onClick={() => sttHooks.onClickSttBtn(props.list)}
                onMouseOver={() => {
                  setHoverSwitch(false);
                }}
                onMouseOut={() => {
                  setHoverSwitch(true);
                }}
              >
                {/* <S.EtcChattingMusicHoverText>텍스트 변환하기</S.EtcChattingMusicHoverText>
              <div className="iconwrap textConverIcon">
                <IconTextConversion />
              </div> */}
                <p>텍스트 변환하기</p>
              </S.EtcChattingMusicTextChange>
            </S.EtcChattingControlWrap>
            <p className="dot" />
            <S.EtcChattingControlWrap className="chatControl voiceDownload">
              <S.EtcChattingMusicDownload
                aria-label="Download Audio"
                onClick={async () => onClickVmsDown()}
                onMouseOver={() => setHoverSwitch(false)}
                onMouseOut={() => setHoverSwitch(true)}
              >
                {/* <S.EtcChattingMusicHoverText>음성 다운로드</S.EtcChattingMusicHoverText>
              <div className="iconwrap downloadIcon">
                <IconDownload />
              </div> */}
                <p>음성 다운로드</p>
              </S.EtcChattingMusicDownload>
            </S.EtcChattingControlWrap>
          </div>
        </S.EtcChattingMusicWrap>
        {formatTime(props.list?.messageDttm) !==
          (props.content[1][props.index + 1]?.direction === 'mo'
            ? formatTime(props.content[1][props.index + 1]?.messageDttm)
            : null) && (
          <S.EtcChattingHourText className={` musicText ${!hoverSwitch ? 'active' : ''}`}>
            {formatTime(props.list.messageDttm)}
          </S.EtcChattingHourText>
        )}
      </S.EtcChattingYourZone>
      {props.list.msgType === 'VMS' && props.list.sndMsg !== 'Fail' && sttHooks.sttState.sttRequest ? (
        <S.EtcChattingYourZone>
          <S.EtcChattingContentWrap>
            <S.EtcChattingYourTextBox>
              <SkeletonBox type="1" title={false} paragraph={{ rows: 1, width: ['200px'] }} active />
            </S.EtcChattingYourTextBox>
          </S.EtcChattingContentWrap>
        </S.EtcChattingYourZone>
      ) : (
        props.list.sndMsg && (
          <S.EtcChattingYourZone>
            <S.EtcChattingContentWrap>
              <S.EtcChattingYourTextBox>
                <p>
                  {underLine(
                    props.list?.sndMsg !== '' ? props.list?.sndMsg : '텍스트로 변환할 음성이 감지되지 않았습니다.',
                  )}
                </p>
              </S.EtcChattingYourTextBox>

              {formatTime(props.list?.messageDttm) !==
                (props.content[1][props.index + 1]?.direction === 'mo'
                  ? formatTime(props.content[1][props.index + 1]?.messageDttm)
                  : null) && <S.EtcChattingHourText>{formatTime(props.list.messageDttm)}</S.EtcChattingHourText>}
            </S.EtcChattingContentWrap>
          </S.EtcChattingYourZone>
        )
      )}
    </>
  );
};

export default ChatRoom015VMS;
