import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.1em;
  height: 1em;
`;

const IconSend: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2.1rem'}
      viewBox="0 0 22 21"
      fill="none"
    >
      <path
        d="M20.2534 11.9308C21.6909 11.2536 21.6909 9.19781 20.2525 8.52144L2.68196 0.246054C1.23917 -0.432984 -0.334384 0.890474 0.0755703 2.43673L1.80992 8.97058L9.16612 8.97058C9.4937 8.9763 9.80593 9.11105 10.0356 9.3458C10.2652 9.58056 10.3939 9.89654 10.3939 10.2257C10.3939 10.5548 10.2652 10.8708 10.0356 11.1056C9.80593 11.3403 9.4937 11.4751 9.16612 11.4808H1.80992L0.0764562 18.0155C-0.334382 19.5609 1.24005 20.8835 2.68196 20.2053L20.2534 11.9308Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconSend;
