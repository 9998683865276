import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const AppRouter = () => {
  return <Outlet />;
};

export default AppRouter;
