import { selectNumberState } from '@/recoil/atoms/addressList';
import { callbackListRecoil } from '@/recoil/atoms/useCallbackList';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import MobileHeader from '../mobileHeader/MobileHeader';
import MobilePageNavigation from '../MobilePageNavigation';
import * as S from './MobileLayout.style';
import styled from 'styled-components';
import { addModalOpenRecoil, syncLoadingRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import AddComponents from './AddComponents';
import MultiChatResult from './MultiChatResult';
import MobileLoader from '../MobileLoader';
import { lastPathSegment } from '@/shared/util/appUtil';
import { useQueryClient } from '@tanstack/react-query';
import FramerMotion from './FramerMotion';
import { AnimatePresence } from 'framer-motion';

export const MainContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  scrollbar-width: none;
`;

const MobileLayout = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const callbackListS = useRecoilValue(callbackListRecoil);
  const [selectNumberStateS, setSelectNumberStateS] = useRecoilState(selectNumberState);
  const addModalOpenValueS = useRecoilValue(addModalOpenRecoil);
  const syncLoading = useRecoilValue(syncLoadingRecoil);

  // 콜백 리스트 추가해주기(010, 015 탭이 없어서 모바일에서는 강제로 넣어줘야함)
  useEffect(() => {
    if (!(callbackListS && selectNumberStateS['015'] === '')) return;
    const test = callbackListS.find((item) => item.regMethod === '015');

    if (test) {
      setSelectNumberStateS((prev) => ({
        ...prev,
        '015': test.callback,
      }));
    }
  }, [callbackListS, localStorage]);

  useEffect(() => {
    if (lastPathSegment(location.pathname) === 'messageList') {
      queryClient.removeQueries(['/smt/history/batch/room']);
      queryClient.removeQueries(['/room/members']);
    }
  }, [location.pathname]);

  return (
    <S.MobileMainLayout className="mobileMainLayout">
      {location.pathname !== '/mobile/mobileSignin' &&
        location.pathname !== '/mobile/015Signup' &&
        location.pathname !== '/mobile/015SubSignup' &&
        location.pathname !== '/mobile/message/messageList/multiChatroom' && <MobileHeader />}

      <MainContainer>
        <Outlet />
      </MainContainer>

      {location.pathname !== '/mobile/015SubCharge' &&
        location.pathname !== '/mobile/015Singup' &&
        location.pathname !== '/mobile/015SubSignup' &&
        location.pathname !== '/mobile/015Signup' &&
        location.pathname !== '/mobile/address/groupAdd' &&
        location.pathname !== '/mobile/address/buddyAdd' &&
        location.pathname !== '/mobile/message/messageList/multiMessageAdd' &&
        !/\/mobile\/address\/groupList\/(\d+)\/(\d+)/.test(location.pathname) &&
        !/\/mobile\/message\/messageList\/(\d+)/.test(location.pathname) &&
        !location.pathname.startsWith('/mobile/message/messageList/multiChatroom') && <MobilePageNavigation />}

      {/* 추가 되는 컴포넌트들 정리 */}

      <AnimatePresence>
        {addModalOpenValueS && (
          <FramerMotion>
            <AddComponents />
          </FramerMotion>
        )}
      </AnimatePresence>
      <MobileLoader isLoading={syncLoading.state} contents={syncLoading.contents} />
    </S.MobileMainLayout>
  );
};

export default MobileLayout;
