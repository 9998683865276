import { openInfoModalRecoil, openModalRecoil } from '@/recoil/atoms/modalRecoil';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

// hotfix 컴포넌트
export type TOpenModal = {
  hotfix: boolean;
  event1: boolean;
  event2: boolean;
};

export const useHotfixModal = () => {
  // 해당하는 컴포넌트 false로 배포하면 로컬스토리지와 상관없이 항상 none처리
  const initOpenModal: TOpenModal = {
    hotfix: false,
    event1: true,
    event2: true,
  };

  const [openModal, setOpenModal] = useState<TOpenModal>(initOpenModal);
  const setOpenInfoModalS = useSetRecoilState(openInfoModalRecoil);
  const setOpenModalS = useSetRecoilState(openModalRecoil);

  // 로컬 스토리지에서 남은 시간이 있는지 확인하는 함수
  const shouldShowModal = (key: string) => {
    const hideUntil = localStorage.getItem(key);
    const permanent = localStorage.getItem(key) === 'permanent';

    if (permanent) {
      return false; // 영구적으로 보지 않기 설정 시 모달 표시하지 않음
    }

    if (!hideUntil || hideUntil === 'Y') {
      return true; // 처음 실행이거나 설정된 값이 없으면 모달 표시
    }
    const now = new Date().getTime();
    return now > parseInt(hideUntil, 10); // 저장된 시간이 현재 시간보다 이전이면 모달 표시
  };

  // hotfix close 이벤트. 로컬스토리지 값 변경해 로그아웃이나 브라우저 재시작 전까지 다시 동작 X
  const onClickHotfixClose = (type: keyof TOpenModal, button?: '1day' | '7days' | '30days' | 'permanent') => {
    const hideForDays = (days: number) => {
      const now = new Date().getTime();
      const hideUntil = now + days * 24 * 60 * 60 * 1000; // n일 후의 시간 계산
      localStorage.setItem(type, hideUntil.toString());
    };

    // 핫픽스 n기간동안 다시보지않기
    if (type === 'hotfix') {
      setOpenModal((prev) => ({
        ...prev,
        hotfix: false,
      }));
      // 안보기 기간 정할꺼면 아래 if문 주석 코드와 교체
      // localStorage.setItem('hotfix', 'permanent'); // 영구적으로 숨기기
      if (button === '1day')
        hideForDays(1); // 1일간 숨기기
      else if (button === '7days')
        hideForDays(7); // 7일간 숨기기
      else if (button === '30days')
        hideForDays(30); // 30일간 숨기기
      else if (button === 'permanent') localStorage.setItem('hotfix', 'permanent'); // 영구적으로 숨기기
    }
    // 이벤트1 n기간동안 다시보지않기
    if (type === 'event1') {
      setOpenModal((prev) => ({
        ...prev,
        event1: false,
      }));
      if (button === '1day')
        hideForDays(1); // 1일간 숨기기
      else if (button === '7days')
        hideForDays(7); // 7일간 숨기기
      else if (button === '30days')
        hideForDays(30); // 30일간 숨기기
      else if (button === 'permanent') localStorage.setItem('event1', 'permanent'); // 영구적으로 숨기기
    }
    // 이벤트2 n기간동안 다시보지않기
    if (type === 'event2') {
      setOpenInfoModalS(true);
      setOpenModalS(false);
      // setOpenModal((prev) => ({
      //   ...prev,
      //   event2: false,
      // }));
      if (button === '1day')
        hideForDays(1); // 1일간 숨기기
      else if (button === '7days')
        hideForDays(7); // 7일간 숨기기
      else if (button === '30days')
        hideForDays(30); // 30일간 숨기기
      else if (button === 'permanent') localStorage.setItem('event2', 'permanent'); // 영구적으로 숨기기
    }
  };

  const hotfixS =
    // openModal.hotfix && (localStorage.getItem('hotfix') === 'Y' || localStorage.getItem('hotfix') === null);
    openModal.hotfix && shouldShowModal('hotfix');
  const event1S =
    // openModal.event1 && (localStorage.getItem('event1') === 'Y' || localStorage.getItem('event1') === null);
    openModal.event1 && shouldShowModal('event1');
  const event2S = openModal.event2 && shouldShowModal('event2');

  return { hotfixS, event1S, event2S, onClickHotfixClose, setOpenModal };
};
