/* eslint-disable */
import { useCheckNotification } from '@/components/hooks/customs/useCheckNotification';
import { useInitApis } from '@/components/hooks/customs/useInitApis';
import { useInitCookies } from '@/components/hooks/customs/useInitCookies';
import { useWebSocket } from '@/components/hooks/customs/useWebSocket';
import { addressColumViewLabelRecoil, addressColumViewValueRecoil } from '@/recoil/atoms/addressColum';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

/**
 * @title 웹 소켓, 웹 푸쉬 초기 작업
 *
 * @author 정휘학
 * @since 2024.04.02
 * */
const InitSetting = () => {
  /* 사용자 항목선택 유지 */
  const setAddressColumViewLabelR = useSetRecoilState(addressColumViewLabelRecoil);
  const setAddressColumViewValueR = useSetRecoilState(addressColumViewValueRecoil);

  useEffect(() => {
    const addressColumViewLabel = localStorage.getItem('addressColumViewLabel');
    const addressColumViewValue = localStorage.getItem('addressColumViewValue');

    if (addressColumViewLabel && addressColumViewValue) {
      setAddressColumViewValueR(new Set(JSON.parse(addressColumViewValue)));
      setAddressColumViewLabelR(new Set(JSON.parse(addressColumViewLabel)));
    }
  }, []);

  useWebSocket();

  useCheckNotification();

  useInitApis();

  useInitCookies();

  return <> </>;
};

export default InitSetting;
