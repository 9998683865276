/**
 * @title 015 소프트 탭 캐시 업데이트 hook
 * */
import { Message015Type } from '@/components/hooks/customs/useEachChatRoomHook';
import { amlQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { BuddyData } from '@/types/index.types';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';

export const softPhone015CacheUpdate = () => {
  const queryClient = useQueryClient();
  const environmentS = useRecoilValue(environmentRecoil);

  /* 소프트폰 채팅방 리스트 GET API PARAMS */
  const [amlQueryParams] = useRecoilState(amlQueryParamsRecoil);

  /* 소프트폰 채팅방 리스트 현재 캐싱 데이터 */
  const cacheData: any = queryClient.getQueryData(['/smt/history/latest/app', amlQueryParams]);

  /* 소프트폰 015 채팅방 리스트 캐시 데이터 */

  // =========================================================================
  // 주소록 이름 수정시 현재 표출되고 있는 015 채팅방에서 관련된 채팅방의 이름 캐시 데이터 업데이트
  // =========================================================================
  const cacheUpdateToTableUpdated = (updatedData: BuddyData) => {
    if (!cacheData) return;
    const cachePagesData = cacheData.pages;

    const newCacheData = {
      ...cacheData,
      pages: cachePagesData.map((page: any) => {
        return {
          ...page,
          content: page.content.map((content: Message015Type) => {
            if (content.phoneNumber === updatedData.keyCommNo) {
              return {
                ...content,
                buddyName: updatedData.buddyNm,
              };
            }
            return content;
          }),
        };
      }),
    };

    queryClient.setQueryData(['/smt/history/latest/app', amlQueryParams], newCacheData);
  };

  return {
    cacheUpdateToTableUpdated,
  };
};
