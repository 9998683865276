import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface BaseCheckBoxProps {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  top?: string;
  name?: string;
  value?: string;
  className?: string;
}
const StyledCheckBox = styled.input<BaseCheckBoxProps>`
  /* 체크되지 않은 상태의 스타일 */
  cursor: pointer;
  position: relative;
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  border-radius: 0.3rem;
  border: 1px solid ${(props) => props.theme.colors.buttonDisabledBg};
  outline: none; /* 포커스 효과 제거 */
  margin: 0 0.5rem 0 0;

  /* 체크된 상태의 스타일 */
  &:checked {
    background: ${(props) => props.theme.colors.main}; /* 체크되었을 때 배경색 변경 */
    border-color: ${(props) => props.theme.colors.main};
  }

  /* 체크 표시 제거 */
  &:after {
    content: ''; /* 가상 요소 생성 */
    display: block;
    width: 0.5rem;
    height: 0.9rem;
    border: solid transparent;
    border-width: 0 0.2rem 0.2rem 0; /* 체크 모양 생성 */
    transform: rotate(45deg);
    position: absolute;
    top: 0.1rem;
    left: 0.4rem;
  }

  /* 체크된 상태일 때 체크 표시 보이기 */
  &:checked:after {
    border-color: ${(props) => props.theme.colors.txt100};
  }
`;

const StyledCheckBoxLabel = styled.label`
  color: ${(props) => props.theme.colors.txt500};
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 1rem;
  line-height: normal;
  display: flex;
  align-items: center;
  &.roundCheck {
    input {
      /* 체크되지 않은 상태의 스타일 */
      cursor: pointer;
      position: relative;
      appearance: none;
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
      border-radius: 50rem;
      border: 0.2rem solid ${(props) => props.theme.colors.buttonDisabledBg};
      outline: none; /* 포커스 효과 제거 */
      margin: 0;

      /* 체크된 상태의 스타일 */
      &:checked {
        background: transparent;
        border-color: ${(props) => props.theme.colors.main};
      }

      /* 체크 표시 제거 */
      &:after {
        content: ''; /* 가상 요소 생성 */
        display: block;
        width: 0.5rem;
        height: 0.9rem;
        border: solid transparent;
        border-width: 0 0.2rem 0.2rem 0; /* 체크 모양 생성 */
        transform: rotate(45deg);
        position: absolute;
        top: 0.25rem;
        left: 0.6rem;
        border-color: ${(props) => props.theme.colors.buttonDisabledBg};
      }

      /* 체크된 상태일 때 체크 표시 보이기 */
      &:checked:after {
        border-color: ${(props) => props.theme.colors.main};
      }
    }
  }
`;

const BaseCheckBox = forwardRef<HTMLInputElement, BaseCheckBoxProps>((props, ref) => {
  const { label = '', top = '3px', ...rest } = props;

  return (
    <StyledCheckBoxLabel className={props.className}>
      <StyledCheckBox
        ref={ref}
        type="checkbox"
        // onChange={props.onChange}
        // value={props.value}
        // checked={props.checked}
        // top={props.top}
        // name={props.name}

        {...rest}
      />
      {label}
    </StyledCheckBoxLabel>
  );
});

// BaseCheckBox.defaultProps = {
//   label: '',
//   top: '3px',
// };

export default BaseCheckBox;
