import {
  leftZoneComponentRecoil,
  listToggleRecoil,
  softPhoneComponentRecoil,
  softPhoneTopTabRecoil,
} from '@/recoil/atoms/router/mainRouterRecoil';
import {
  arsIsActiveRecoil,
  arsMsgToggleRecoil,
  isPlayRecoil,
  join015ToggleRecoil,
  needsGuidePageRecoil,
} from '@/recoil/atoms/say015Atom';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMsgRecoilReset } from '../../useMsgRecoilReset';

// 015 ARS 목업 시나리오를 위한 훅
export const use015ARSMock = () => {
  // ars 서비스 사작하기 버튼 클릭시 변경할 Recoil
  const setSoftPhoneTopTabS = useSetRecoilState(softPhoneTopTabRecoil);
  const setListToggleS = useSetRecoilState(listToggleRecoil);
  const setLeftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  const [, setSoftPhoneComponent] = useRecoilState(softPhoneComponentRecoil);

  // 목업 화면에서 서비스 시작하기 버튼 클릭시 가입으로 보여줄 STATE
  const [join015ToggleS, setJoin015ToggleS] = useRecoilState(join015ToggleRecoil);

  // ars 폰 토글하는 STATS
  const [arsMsgToggleS, setArsMsgToggleS] = useRecoilState<boolean>(arsMsgToggleRecoil);
  const setArsIsActiveS = useSetRecoilState(arsIsActiveRecoil);

  // 왼쪽 그룹을 조절하기위해서 생성 - 반응형
  const [arsLeftActive, setArsLeftActive] = useState<boolean>(false);
  // 반응형 ars 좌측 패널 토글 버튼
  const onClickArsLeftClick = () => {
    setArsLeftActive((prevState) => !prevState);
  };

  // ars 페이지 분기처리를 위한 STATE
  const setNeedsGuidePageS = useSetRecoilState(needsGuidePageRecoil);

  const { resetRecoil } = useMsgRecoilReset();

  const navigate = useNavigate();

  const wavfiles = {
    restaurant: {
      intro: '/file/wavFile/restaurant/mock_restaurant_intro.wav',
      choice1: '/file/wavFile/restaurant/mock_restaurant_choice_1.wav',
      choice3: '/file/wavFile/mock_sound_vm-intro.wav',
      choice4: '/file/wavFile/restaurant/mock_restaurant_choice_4.wav',
      calling: '/file/wavFile/mock_sound_calling.wav',
    },
  };
  const [isPlayS, setIsPlayS] = useRecoilState(isPlayRecoil);
  const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({
    intro: null,
    choice1: null,
    choice2: null,
    choice3: null,
    choice4: null,
    calling: null,
  });

  // ==============================
  // 오디오 참조 초기화 useEffect
  // ==============================
  useEffect(() => {
    setArsMsgToggleS(false);
    audioRefs.current.intro = new Audio(wavfiles.restaurant.intro);
    audioRefs.current.choice1 = new Audio(wavfiles.restaurant.choice1);
    audioRefs.current.choice3 = new Audio(wavfiles.restaurant.choice3);
    audioRefs.current.choice4 = new Audio(wavfiles.restaurant.choice4);
    audioRefs.current.calling = new Audio(wavfiles.restaurant.calling);
    setIsPlayS({
      intro: false,
      choice1: false,
      choice2: false,
      choice3: false,
      choice4: false,
      calling: false,
    });

    // 오디오 파일 끝날 때 재생 상태를 업데이트
    Object.keys(audioRefs.current).forEach((key) => {
      if (audioRefs.current[key]) {
        audioRefs.current[key]?.addEventListener('ended', () => {
          setIsPlayS((prev) => ({
            ...prev,
            [key]: false,
          }));
        });
      }
    });

    // 컴포넌트 언마운트 시 이벤트 리스너 제거 (메모리 누수 방지)
    return () => {
      Object.keys(audioRefs.current).forEach((key) => {
        if (audioRefs.current[key]) {
          audioRefs.current[key]?.removeEventListener('ended', () => {
            setIsPlayS((prev) => ({
              ...prev,
              [key]: false,
            }));
          });
        }
      });
    };
  }, []);

  // ==============================
  // onClick wav 재생, 정지 hook
  // ==============================
  const onClickPlayPauseBtn = (key: string, e?: any) => {
    // 메시지 표출중이면 다시 토글
    setArsMsgToggleS(false);
    // 현재 재생 중인 모든 오디오를 멈추기
    Object.keys(audioRefs.current).forEach((k) => {
      const audio = audioRefs.current[k];
      if (audio && k !== key) {
        audio.pause();
        audio.currentTime = 0; // 재생 위치를 처음으로 되돌림
      }
    });

    // 현재 재생 중인 상태 업데이트
    setIsPlayS((prev) => {
      const newState = Object.keys(prev).reduce(
        (acc, k) => {
          acc[k] = k === key ? !prev[key] : false;
          return acc;
        },
        {} as { [key: string]: boolean },
      );
      return newState;
    });

    // 클릭한 오디오 파일 재생/일시정지
    // TODO - ars wav파일 동기화 (같은 파일 재생안되도록)
    const clickedAudio = audioRefs.current[key];
    if (clickedAudio) {
      if (isPlayS[key]) {
        clickedAudio.pause();
      } else {
        clickedAudio.play();
      }
    }
  };

  // ==============================
  // onClick ARS Init 화면으로 되돌아가는 버튼
  // ==============================
  const onClickArsMockClose = () => {
    setNeedsGuidePageS(true);
    navigate('/');
    resetRecoil();
    setSoftPhoneComponent('chatroom');
    setSoftPhoneTopTabS('0');
    setLeftZoneComponentS('address');
    setListToggleS(false);
  };

  // ==============================
  // onClick ARS 서비스 시작하기 클릭시 탭 전환하는 버튼
  // ==============================
  const onClickArsStartBtn = (toggle: boolean) => {
    // setLeftZoneComponentS('ars');
    // setSoftPhoneComponent('ars');
    // setListToggleS(true);
    // setSoftPhoneTopTabS('0');
    if (toggle) {
      setJoin015ToggleS(false);
      setArsIsActiveS(true); // 반응형
      setArsLeftActive(false); // 반응형
    } else {
      setJoin015ToggleS(true);
      setArsIsActiveS(true); // 반응형
      setArsLeftActive(true); // 반응형
    }
  };

  // ==============================
  // 모든 오디오 중단 및 상태 초기화
  // ==============================
  const onClickStopAllars = () => {
    // 모든 오디오 파일을 멈추고 재생 위치를 처음으로 되돌림
    Object.keys(audioRefs.current).forEach((key) => {
      const audio = audioRefs.current[key];
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    });

    // 모든 오디오 재생 상태를 false로 설정
    setIsPlayS({
      intro: false,
      choice1: false,
      choice2: false,
      choice3: false,
      choice4: false,
      calling: false,
    });
  };

  // ==============================
  // onClick 문자 발송 버튼 클릭 hook
  // ==============================
  const onClickArsMsgBtn = () => {
    onClickStopAllars();
    setArsMsgToggleS(true);
  };

  return {
    wavfiles,
    audioRefs,
    onClickPlayPauseBtn,
    isPlayS,
    onClickArsMockClose,
    onClickArsStartBtn,
    onClickStopAllars,
    onClickArsMsgBtn,
    arsMsgToggleS,
    arsLeftActive,
    onClickArsLeftClick,
    join015ToggleS,
  };
};
