/**
 * @title 알림 허용 상태 파악 Hook
 *
 * @author 정휘학
 * @since 2024.04.13
 * */
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { callbackNumberState } from '@/recoil/atoms/addressList';
import { usePushNotification } from '@/apis/hooks/usePushNotification';

export const useCheckNotification = () => {
  const callbackNumber = useRecoilValue(callbackNumberState);
  /* 크롬 웹 푸시 구독 Hooks */
  const { checkNotification } = usePushNotification();

  /**
   * @title 015 구독 체크
   * */
  useEffect(() => {
    if (callbackNumber && callbackNumber?.length > 0) {
      const number015 = callbackNumber?.find((f: string) => f.startsWith('015'));
      if (
        !localStorage.getItem('authorizationToken') &&
        !localStorage.getItem('say015User') &&
        !JSON.parse?.(localStorage.getItem('say015User') as string) &&
        !number015
      )
        return;
      checkNotification();
    }
  }, [callbackNumber?.find((f: string) => f.startsWith('015'))]);

  return {
    checkNotification,
  };
};
