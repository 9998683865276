import { useGetBuddySeqNo } from '@/components/hooks/customs/commons/useGetBuddySeqNo';
import { useAddressBookMutations } from '@/components/hooks/mutations/addressBook/useAddressBookMutations';
import { useModalHook } from '@/components/commons/modals/useModalHook';

/**
 * @title 주소록 엑셀다운 관련 Hooks
 *
 * @author 김현승
 * @since 2024.08.01
 **/
export const useDownExcelTableList = () => {
  const { getBuddySeqNosToMainTableCheckDataFormatRecoil } = useGetBuddySeqNo();
  const { downloadContactsExcelFile } = useAddressBookMutations();
  const { confirmModal } = useModalHook();
  const onDownTableList = () => {
    confirmModal(
      async () => {
        const excelBuddySeqNoArr = await getBuddySeqNosToMainTableCheckDataFormatRecoil();
        // 엑셀 다운로드 함수 호출
        try {
          await downloadContactsExcelFile(excelBuddySeqNoArr);
        } catch (error) {
          console.error('엑셀 다운로드 실패:', error);
        }
      },
      '엑셀 다운로드',
      '선택하신 주소록을 다운로드 하시겠습니까?',
      true,
    );
  };

  return {
    onDownTableList,
  };
};
