import styled from 'styled-components';

// ARS 목업전용 스타일 (ARS와 css 겹치는걸 방지)
export const Say015MockWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 3rem;

  > .logo {
    padding: 2rem 5% 4rem;
  }
  .contents {
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    ol {
      height: calc(var(--vh, 100vh) - 13.4rem);
    }
    .leftGroup {
      border-right: 0;
    }
  }

  @media screen and (max-width: 1040px) {
    > .logo {
      padding: 2rem 0 4rem;
    }
    .wrapper {
      ol {
        height: calc(var(--vh, 100vh) - 16rem);
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 6rem 0 0;
    .topGroup {
      padding: 0 2rem;
    }
    > .logo {
      padding: 0 2rem 2rem;
    }
    .wrapper {
      ol {
        height: calc(var(--vh, 100vh) - 22.6rem);
        padding: 2rem;
      }
    }
  }
`;
