import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { get015ArsTTSWav } from '@/apis/api/say015Apis';
import { audioController } from '@/shared/hooks/useAudio';

import { useRecoilState } from 'recoil';
import { activePlayRecordIdRecoil } from '@/recoil/atoms/say015Atom';
import { NextItem } from '@/widgets/ars/components/arsTree/ui/ArsTree.types';

interface IUseArsGuidePlayAudio {
  data?: NextItem;
  // setTabState: React.Dispatch<React.SetStateAction<modalTabType>>;
}

export const useArsGuidePlayAudio = ({ data }: IUseArsGuidePlayAudio) => {
  const {
    audioRef,
    setAudioUrl,
    audioTime,
    currentTime,
    playSwitchBtn,
    handlePlay,
    handleTimeUpdate,
    handleBackTime,
    handleFrontTime,
    handleEnded,
    handleLoadedMetadata,
    duration,
    durationView,
  } = audioController();
  /* TTS 음성 url */
  const [ttsAudioUrl, setTTSAudioUrl] = useState<string | null>(null);
  /* Record 음성 URL */
  const [recordAudioUrl, setRecordAudioUrl] = useState<string | null>(null);
  /* File 음성 url */
  const [fileAudioUrl, setFileAudioUrl] = useState<string | null>(null);
  /* ARS TTS 파일 재생중 또는 재생 하려는 ARS 카드 ID */
  const [activePlayRecordIdS, setActivePlayRecordIdS] = useRecoilState(activePlayRecordIdRecoil);

  const { mutate: mutateGetArsTTSWav, data: arsTTSWav, isLoading } = useMutation(get015ArsTTSWav);
  const playAudio = ({ fileName }: { fileName: string }) => {
    mutateGetArsTTSWav({ filename: fileName });
  };

  // ===============================================================
  // 안내멘트 음성파일의 명칭에 따라 팝업 분류 focus 초기값 설정 ( tts, record )
  // ===============================================================
  useEffect(() => {
    if (!data) return;
    if (data?.target.level !== '' && data?.target.type === 'READ') {
      playAudio({ fileName: `${data?.target?.level}` });
    }
  }, [data]);

  useEffect(() => {
    if (arsTTSWav && !isLoading && data && data?.target.type === 'READ') {
      // const tap = data?.target?.level.split('_')[1];
      const audioUrl = URL.createObjectURL(arsTTSWav);
      // const audioUrl = '/file/wavFile/mock_sound_calling.wav';
      // if (tap === 'tts') {
      setTTSAudioUrl(audioUrl);
      // } else if (tap === 'record') {
      //   setRecordAudioUrl(audioUrl);
      // } else if (tap === 'file') {
      //   setFileAudioUrl(audioUrl);
      // }
      setAudioUrl(audioUrl);
    } else {
      switch (data?.s015Data.type) {
        case 'ROUTE':
          setTTSAudioUrl('/file/wavFile/mock_sound_calling.wav');
          setAudioUrl('/file/wavFile/mock_sound_calling.wav');
          break;
        case 'VMS':
          setTTSAudioUrl('/file/wavFile/mock_sound_vm-intro.wav');
          setAudioUrl('/file/wavFile/mock_sound_vm-intro.wav');
          break;
        /*        case 'LINK_SMS':
          /!*         setTTSAudioUrl('/file/wavFile/auth-thankyou.wav');
          setAudioUrl('/file/wavFile/auth-thankyou.wav');*!/
          break;*/
        case '':
          setTTSAudioUrl('/file/wavFile/inputerror_retry.wav');
          setAudioUrl('/file/wavFile/inputerror_retry.wav');
          break;
        default:
          break;
      }
    }
  }, [arsTTSWav, isLoading, data]);

  // =====================
  // ARS 카드에 재생 버튼 핸들러
  // =====================
  const handleAudioPlay = (id: string | null) => {
    if (!id) {
      setActivePlayRecordIdS(null);
      return;
    }
    setActivePlayRecordIdS((prevState) => {
      if (id && prevState !== id) {
        return id;
      }
      return null;
    });
  };

  return {
    audioRef,
    audioTime,
    currentTime,
    playSwitchBtn,
    handlePlay,
    handleTimeUpdate,
    handleBackTime,
    handleFrontTime,
    handleEnded,
    handleLoadedMetadata,
    duration,
    durationView,
    ttsAudioUrl,
    recordAudioUrl,
    fileAudioUrl,
    setRecordAudioUrl,
    setTTSAudioUrl,
    setFileAudioUrl,
    setAudioUrl,
    activePlayRecordIdS,
    handleAudioPlay,
  };
};
