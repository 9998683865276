import { instance } from '@/shared/lib/clientAxios';
import { lastPathSegment } from '@/shared/util/appUtil';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

interface useGetChatroomMemberProps {
  chatRoomId: string;
}

interface fetchChatroomMemberProps {
  chatRoomId: string;
  pageSize: number;
  pageNumber: number;
  searchValue: string;
}

const fetchChatroomMember = async ({
  chatRoomId,
  pageSize,
  pageNumber,
  searchValue = '',
}: fetchChatroomMemberProps) => {
  const response = await instance.get('/room/members', {
    params: {
      chatRoomId,
      pageNumber,
      pageSize,
      buddyMapping: false,
      searchValue,
    },
  });
  return response.data; // 서버 응답 데이터 확인 필요
};

export const useGetChatroomMember = ({ chatRoomId }: useGetChatroomMemberProps) => {
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    fetchStatus,
    status,
  } = useInfiniteQuery(
    ['/room/members', chatRoomId], // 검색값 포함
    ({ pageParam = 0 }) =>
      fetchChatroomMember({
        chatRoomId,
        pageSize: 20,
        pageNumber: pageParam,
        searchValue: '',
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        return pages.length < lastPage.totalPages ? pages.length : undefined;
      },
    },
  );

  const formatData = data && data.pages.flatMap((page) => page.content || []);

  return {
    formatData,
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    fetchStatus,
    status,
  };
};
