import React from 'react';
import ATitle from '@/components/Atom/ATitle';
import BaseGuide from '../../Atom/BaseGuide';
import BaseInput from '../../Atom/BaseInput';
import * as S from './styles/PwChange.styles';
import * as TS from '@/styles/table.styles';

import { AiFillEye } from 'react-icons/ai';
import { RiEyeOffFill } from 'react-icons/ri';
import { usePwChange } from '../../hooks/customs/myPage/usePwChange';
import ButtonList from '../../common/buttons/ButtonList';

const PwChange = () => {
  const {
    userInfoS,
    visible,
    register,
    originPwHandleVisible,
    visible1,
    newPwHandleVisible,
    errors,
    visible2,
    checkNewPwHandleVisible,
    pwNewOriginCheck,
    buttonList,
  } = usePwChange();

  return (
    <S.Wrapper>
      <S.AWrapper className="basicTable">
        <ATitle type="sub" text="기본정보" />
        <TS.MyPageTable className="tw30">
          <tbody>
            <tr>
              <th>이름</th>
              <td>{userInfoS?.usrNm}</td>
            </tr>
            <tr>
              <th>아이디</th>
              <td>{userInfoS?.phnId}</td>
            </tr>
          </tbody>
        </TS.MyPageTable>
      </S.AWrapper>
      <S.BWrapper className="basicTable">
        <S.TitleWrapper className="titleWrap">
          <ATitle type="sub" text="비밀번호 변경" />
          <span>_최소 8자, 최대 16자 이내(영문,숫자 조합)</span>
        </S.TitleWrapper>

        <TS.MyPageTable className="tw30">
          <tbody>
            <tr>
              <th>*기존 비밀번호</th>
              <td>
                <div className="flex">
                  <BaseInput
                    type={visible ? 'text' : 'password'}
                    {...register('originPw', {
                      required: true,
                    })}
                  />
                  {visible ? (
                    <button style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <AiFillEye className="on" onClick={originPwHandleVisible} />
                    </button>
                  ) : (
                    <button style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <RiEyeOffFill className="off" onClick={originPwHandleVisible} />
                    </button>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <th>*새 비밀번호</th>
              <td>
                <div className="flex">
                  <BaseInput type={visible1 ? 'text' : 'password'} height="2.2vh" {...register('newPw')} />
                  {visible1 ? (
                    <button style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <AiFillEye className="on" onClick={newPwHandleVisible} />
                    </button>
                  ) : (
                    <button style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <RiEyeOffFill className="off" onClick={newPwHandleVisible} />
                    </button>
                  )}
                  {errors?.newPw ? <S.ValidationError>{errors.newPw?.message}</S.ValidationError> : null}
                </div>
              </td>
            </tr>
            <tr>
              <th>*새 비밀번호 확인</th>
              <td>
                <div className="flex">
                  <BaseInput type={visible2 ? 'text' : 'password'} height="2.2vh" {...register('checkNewPw')} />

                  {visible2 ? (
                    <button style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <AiFillEye className="on" onClick={checkNewPwHandleVisible} />
                    </button>
                  ) : (
                    <button style={{ marginLeft: '1rem', fontSize: '2rem' }}>
                      <RiEyeOffFill className="off" onClick={checkNewPwHandleVisible} />
                    </button>
                  )}
                  {pwNewOriginCheck && <S.ValidationError>비밀번호가 일치하지 않습니다.</S.ValidationError>}
                </div>
              </td>
            </tr>
          </tbody>
        </TS.MyPageTable>
      </S.BWrapper>
      <S.ButtonWrapper>
        <ButtonList buttonList={buttonList} />
      </S.ButtonWrapper>
      <BaseGuide text="개인정보취급방침에 따라 개인정보를 보호하고, 회원님의 동의 없이 절대 외부에 유출하지 않을 것을 약속드립니다." />
    </S.Wrapper>
  );
};
export default PwChange;
