import React from 'react';
import styled from 'styled-components';

interface IconCloseProps {
  width?: string;
  height?: string;
  background?: string;
  className?: string;
}

const StyledIconClose = styled.div<IconCloseProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.width ? `calc(${props.width} /2)` : 'calc(3rem/2)')};
  span {
    content: '';
    position: absolute;
    display: block;
    width: ${(props) => (props.width ? props.width : '3rem')};
    height: ${(props) => (props.height ? props.height : '.2rem')};
    background: ${(props) => (props.background ? props.background : props.theme.colors.gray800)};
    &:nth-of-type(1) {
      transform: rotate(45deg);
    }
    &:nth-of-type(2) {
      transform: rotate(-45deg);
    }
  }
  &.border {
    span {
      border-radius: 100px;
    }
  }
  @media screen and (max-width: 540px) {
    span {
      width: ${(props) => (props.width ? props.width : '2.4rem')};
    }
  }
`;

const IconClose = ({ className, width, height, background }: IconCloseProps) => {
  return (
    <StyledIconClose className={`closeIcon ${className}`} width={width} height={height} background={background}>
      <span />
      <span />
    </StyledIconClose>
  );
};

export default IconClose;
