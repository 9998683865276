import { Button, Form, Modal } from 'antd';
import React from 'react';
import * as S from './AddressAddColumModal.styles';
import { useAddressAddColumModal } from '@/components/hooks/customs/addressBook/useAddressAddColumModal';
import { IAddressAddColumnModal } from './AddressAddColumModal.types';
import { addressItemsByGroup } from '@/shared/constants';

/**
 * @title AddressAddColumModal.tsx 보여질 항목 선택 컴포넌트 모달 창
 *
 * @author 김남규
 * @since 2024.03.14
 * */
const AddressAddColumModal = (props: IAddressAddColumnModal) => {
  // Hooks
  const hooks = useAddressAddColumModal(props);

  const disabledItems = ['이름', '휴대폰번호'];

  return (
    <Modal
      title="항목추가"
      open={props.isModalOpen}
      onCancel={hooks.handleCancel}
      footer={[
        <Button key="ok" type="primary" onClick={hooks.handleCancel}>
          확인
        </Button>,
      ]}
    >
      <S.InfoWrap>
        <Form>
          {addressItemsByGroup.map((group) => (
            <div key={group.head}>
              <S.H2>{group.head}</S.H2>
              <S.DivWrap>
                {group.children.map((child) => (
                  <S.InputWrap key={`${props.useComponent}${child.id}`}>
                    <input
                      type="checkbox"
                      id={`${props.useComponent}${child.id}`}
                      name={child.label}
                      value={child.value}
                      onChange={hooks.checkHandler}
                      checked={hooks.localSelectedLabel.has(child.label)}
                      disabled={disabledItems.includes(child.label)}
                    />
                    <label htmlFor={`${props.useComponent}${child.id}`}>{child.label}</label>
                  </S.InputWrap>
                ))}
              </S.DivWrap>
            </div>
          ))}
        </Form>
      </S.InfoWrap>
    </Modal>
  );
};

export default AddressAddColumModal;
