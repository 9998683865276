import React, { useState } from 'react';
import POBox015Mock from '../POBox015Mock';
import ReSubscription from '../../ReSubscription/ReSubscription';

const Sub015Page = ({ authorityDataState, storageUser, authorityDataRefetch }: any) => {
  const initial015SubPage = {
    first: 'POBox015Mock',
    second: 'ReSubscription',
  };
  const [pageState, setPageState] = useState<string>(initial015SubPage.first);

  const nextPage = () => {
    if (pageState === initial015SubPage.first) {
      setPageState(initial015SubPage.second);
    }
  };

  let subToRender;

  if (pageState === initial015SubPage.first) {
    subToRender = (
      <POBox015Mock nextPage={nextPage} storageUser={storageUser} authorityDataState={authorityDataState} />
    );
  }
  if (pageState === initial015SubPage.second) {
    subToRender = (
      <ReSubscription authorityDataState={authorityDataState} authorityDataRefetch={authorityDataRefetch} />
    );
  }

  return subToRender ? subToRender : null;
};
export default Sub015Page;
