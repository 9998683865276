import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconTaxSearch: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2.4rem'}
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.595 10.2391L24.8229 13.467C24.9363 13.5805 25.0001 13.7345 25 13.895C24.9999 14.0555 24.9361 14.2094 24.8226 14.3229C24.7091 14.4363 24.5551 14.5001 24.3946 14.5C24.2341 14.4999 24.0802 14.4361 23.9667 14.3226L20.7389 11.0947C19.774 11.8421 18.5606 12.1938 17.3455 12.0783C16.1305 11.9627 15.0051 11.3887 14.1983 10.4728C13.3915 9.55701 12.9638 8.36821 13.0024 7.14827C13.041 5.92834 13.5428 4.76891 14.4058 3.90586C15.2689 3.04281 16.4283 2.54095 17.6482 2.5024C18.8681 2.46384 20.0568 2.89148 20.9726 3.69831C21.8884 4.50514 22.4625 5.63056 22.578 6.84563C22.6935 8.06069 22.3418 9.27413 21.5944 10.2391M17.8002 10.8997C18.755 10.8997 19.6706 10.5204 20.3457 9.84527C21.0208 9.17016 21.4001 8.2545 21.4001 7.29974C21.4001 6.34498 21.0208 5.42933 20.3457 4.75421C19.6706 4.0791 18.755 3.69982 17.8002 3.69982C16.8455 3.69982 15.9299 4.0791 15.2548 4.75421C14.5797 5.42933 14.2004 6.34498 14.2004 7.29974C14.2004 8.2545 14.5797 9.17016 15.2548 9.84527C15.9299 10.5204 16.8455 10.8997 17.8002 10.8997Z"
        fill={fill ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.01761 1.91509C1.85781 1.91509 1.70456 1.97857 1.59157 2.09157C1.47857 2.20456 1.41509 2.35781 1.41509 2.51761V23.4824C1.41509 23.6422 1.47857 23.7954 1.59157 23.9084C1.70456 24.0214 1.85781 24.0849 2.01761 24.0849H19.0516C19.2114 24.0849 19.3646 24.0214 19.4776 23.9084C19.5906 23.7954 19.6541 23.6422 19.6541 23.4824V18.2409C19.6541 17.8501 19.9709 17.5333 20.3616 17.5333C20.7524 17.5333 21.0692 17.8501 21.0692 18.2409V23.4824C21.0692 24.0175 20.8566 24.5307 20.4782 24.909C20.0999 25.2874 19.5867 25.5 19.0516 25.5H2.01761C1.48251 25.5 0.96932 25.2874 0.590944 24.909C0.212569 24.5307 0 24.0175 0 23.4824V2.51761C0 1.98251 0.212569 1.46932 0.590944 1.09094C0.969319 0.712569 1.48251 0.5 2.01761 0.5H11.1899C11.5807 0.5 11.8975 0.81678 11.8975 1.20755C11.8975 1.59831 11.5807 1.91509 11.1899 1.91509H2.01761ZM5.24088 14.3101C5.24088 13.9193 5.55766 13.6025 5.94843 13.6025H11.1899C11.5807 13.6025 11.8975 13.9193 11.8975 14.3101C11.8975 14.7008 11.5807 15.0176 11.1899 15.0176H5.94843C5.55766 15.0176 5.24088 14.7008 5.24088 14.3101ZM5.24088 19.5516C5.24088 19.1608 5.55766 18.844 5.94843 18.844H15.1208C15.5115 18.844 15.8283 19.1608 15.8283 19.5516C15.8283 19.9423 15.5115 20.2591 15.1208 20.2591H5.94843C5.55766 20.2591 5.24088 19.9423 5.24088 19.5516Z"
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconTaxSearch;
