/**
 * @title 작성된 문장의 바이트 크기를 구하는 Hook
 *
 * @author 정휘학
 * @since 2024.03.18
 * */
export const calMsgByte = (msg: string) => {
  let byteSize = 0;
  for (let i = 0; i < msg.length; i++) {
    const charCode = msg.charCodeAt(i);
    if (charCode >= 0x00 && charCode <= 0x7f) {
      byteSize += 1; // 영문 및 숫자
    } else {
      byteSize += 2; // 한글
    }
  }
  return byteSize;
};

// 유니코드 특수 공백을 ASCII 공백으로 변환하는 화살표 함수
const normalizeSpacesPreservingFormat = (text: string): string => {
  return text
    .replace(/\u00A0/g, ' ') // Non-breaking space (U+00A0)
    .replace(/\u2000/g, ' ') // En quad (U+2000)
    .replace(/\u2001/g, ' ') // Em quad (U+2001)
    .replace(/\u2002/g, ' ') // En space (U+2002)
    .replace(/\u2003/g, ' ') // Em space (U+2003)
    .replace(/\u2009/g, ' ') // Thin space (U+2009)
    .replace(/\u3000/g, ' ') // Ideographic space (U+3000)
    .replace(/\u202F/g, ' '); // Narrow no-break space (U+202F)
};

/**
 * @title 문장의 바이트 크기가 maxByte 를 초과할 경우 maxByte 크기만큼의 문장만 return 유틸 Hook
 *
 * @author 정휘학
 * @since 2024.03.18
 * */
export const assertMsglen = (msg: string, maxByte: number) => {
  const normalizedMsg = normalizeSpacesPreservingFormat(msg);
  let byteSize = 0;
  let msgValue = normalizedMsg;
  for (let i = 0; i < normalizedMsg.length; i++) {
    const charCode = normalizedMsg.charCodeAt(i);
    if (charCode >= 0x00 && charCode <= 0x7f) {
      byteSize += 1; // 영문 및 숫자
    } else {
      byteSize += 2; // 한글
    }

    if (byteSize > maxByte) {
      // 메세지 자르기
      msgValue = normalizedMsg.substring(0, i);
      break;
    }
  }
  return msgValue;
};

// TODO 배포 -> 기존 포맷
// export const assertMsglen = (msg: string, maxByte: number) => {
//   let byteSize = 0;
//   let msgValue = msg;
//   for (let i = 0; i < msg.length; i++) {
//     const charCode = msg.charCodeAt(i);
//     if (charCode >= 0x00 && charCode <= 0x7f) {
//       byteSize += 1; // 영문 및 숫자
//     } else {
//       byteSize += 2; // 한글
//     }

//     if (byteSize > maxByte) {
//       // 메세지 자르기
//       msgValue = msg.substring(0, i);
//       break;
//     }
//   }
//   return msgValue;
// };
// TODO 배포 <-
