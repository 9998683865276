import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import BaseButton from '../../Atom/BaseButton';

const ButtonGroup = styled.div`
  /* display: flex; */
  /* gap: 1em; // 버튼 사이의 간격 */
  /* margin-top: 2.5rem; */
  button {
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 0.4rem;
  }
  @media screen and (max-width: 768px) {
    button {
      padding: 1rem;
      font-size: 1.5rem;
    }
  }
`;

export interface IButtonList {
  type?: any;
  width?: string;
  color?: string;
  text?: string;
  func?: () => void;
}

interface IManagementSubmit {
  buttonList: IButtonList[];
}

const ButtonList = ({ buttonList }: IManagementSubmit) => {
  return (
    <ButtonGroup className="buttonList">
      {buttonList?.map((el) => (
        <BaseButton
          key={uuidv4()}
          type={el?.type}
          width={el.width || '10rem'}
          onClick={el.func} // 각 요소의 func 함수를 클릭 핸들러로 설정
        >
          {el.text}
        </BaseButton>
      ))}
    </ButtonGroup>
  );
};

export default ButtonList;
