import { atom } from 'recoil';

// 이벤트모달 표출하는 RECOIL
export const openModalRecoil = atom<boolean>({
  key: 'openModalRecoil',
  default: false,
});

// 이벤트 모달 닫고 전송 표출하는 RECOIL
export const openInfoModalRecoil = atom<boolean>({
  key: 'openInfoModalRecoil',
  default: false,
});
