import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const EtcContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* height: 100%; */
  /* overflow-y: auto; */
`;
export const CashContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem;
  border-radius: 1rem;
  background: ${(props) => props.theme.colors.mobileMain};
  color: #1f2328;
  font-weight: 800;
  font-size: 1.4rem;
  max-height: 6rem;

  .cashDetail {
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      max-width: 3.2rem;
    }
  }
  .row {
    content: '';
    display: block;
    width: 1px;
    height: 1rem;
    background: ${(props) => props.theme.colors.gray500};
  }
  .cash {
    color: #1f2328;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3개 의 컬럼으로 구성
  gap: 1rem 0;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 1rem;
`;

export const GridItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  gap: 8px;
  /* background-color: #f0f0f0; */
  text-decoration: none;
  font-size: 1.2rem;
  text-align: center;
  &:hover {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const Advertising = styled.div`
  .banner {
    display: block;
    width: 100%;
    height: 20rem;
    background:
      /* linear-gradient(45deg, #333, #666), */ url('https://mblogthumb-phinf.pstatic.net/MjAyNDA4MTJfMjA5/MDAxNzIzNDM3Njc4MTg3.vufpwdgi2pXs-PExSRzDGSWK4l5oy0S-H15YUj5I7KIg.eBLSXhAf2rHsVLqe8K6RXl6tUotv5SPhsZ6MUV4sSe0g.JPEG/output_4275875242.jpg?type=w800')
      no-repeat center center;
    background-size: cover;
    border-radius: 1rem 1rem 0 0;
  }
  .banner2 {
    display: block;
    width: 100%;
    height: 20rem;
    background:
      /* linear-gradient(45deg, #333, #666), */ url('https://assets.epicurious.com/photos/5c000c65b9a90d10c1e5eb03/16:9/w_2580,c_limit/Molasses-Sandwich-Cookies-with-Coffee-Buttercream-process-12112018.jpg')
      no-repeat center center;
    background-size: cover;
    border-radius: 1rem 1rem 0 0;
  }
  .advertisingBottom {
    padding: 1.5rem;
    font-size: 1.4rem;
    border-radius: 0 0 1rem 1rem;
    color: ${(props) => props.theme.colors.txt500};
    background: ${(props) => props.theme.colors.gray100};
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }
`;
