import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 5rem;
  padding: 0 1.6rem;
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.gray200}`};
`;

export const HeaderButtonIcon = styled.button`
  font-size: 2.4rem;
  border-radius: 100%;
  transition: background-color 0.4s ease; // 듀레이션 0.4초 추가
`;

//중앙 이름 컨테이너
export const MainTitle = styled.h3`
  max-width: 65%;
  font-size: 2rem;
  white-space: nowrap;
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis;

  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  text-align: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* 호버 시 텍스트 확장 */
  &:hover {
    z-index: 3;
    top: 6rem;
    transform: translateX(-50%);
    white-space: normal; /* 줄바꿈 허용 */
    word-wrap: break-word; /* 줄바꿈을 강제로 발생 */
    overflow: visible; /* 숨기지 않음 */
    max-width: none; /* 최대 폭 제한 해제 */
    background: ${(props) => props.theme.colors.gray200}; /* 가독성을 위한 배경색 */
    padding: 0.5rem; /* 배경 여백 */
    border-radius: 0.5rem; /* 경계선 둥글게 */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* 약간의 그림자 */

    /* 삼각형 추가 */
    &::after {
      content: '';
      position: absolute;
      top: -1rem; /* 삼각형 위치 */
      left: 50%;
      transform: translateX(-50%);
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent transparent ${(props) => props.theme.colors.gray200} transparent;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  gap: 2rem;
  button {
    color: ${(props) => props.theme.colors.txt500};
  }
`;
export const HeaderRightButtonIcon = styled.button`
  transition: background-color 0.4s ease; // 듀레이션 0.4초 추가
  font-size: 1rem;
  white-space: nowrap; /* 텍스트가 한 줄로 나오도록 설정 */
  text-align: center; /* 텍스트를 가운데 정렬 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  span {
    color: ${(props) => props.theme.colors.gray600};
    font-weight: 600;
  }
`;

export const ChatroomContainer = styled.section`
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.chatBack};
`;

export const ChatroomChatting = styled.div`
  width: 100%;
`;

export const EtcChattingDateText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0 3rem;
  text-align: center;

  &:nth-of-type(n + 2) {
    margin: 3rem 0;
  }

  span {
    padding: 0.5rem 2rem;
    border-radius: 10rem;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.4);
    color: ${(props) => props.theme.colors.txt100};
  }
`;

export const MessageItem = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
`;

/* Start My */

export const EtcChattingMyZone = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
`;

export const EtcChattingContentWrap = styled.div`
  // My, Your 공용
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  max-width: 80%;

  &:last-of-type {
    @media screen and (max-width: 768px) {
      /* margin-bottom: 20px; */
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 90%;
  }
`;

export const EtcChattingHourText = styled.span`
  // My, Your 공용
  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: end;

  color: ${(props) => props.theme.colors.txt300};
  @media screen and (max-width: 540px) {
    font-size: 1.1rem;
  }
`;

export const EtcChattingSnedCancelBtn = styled.span`
  // 전송취소 버튼
  margin-right: 0.4rem;
  font-size: 1.4rem;
  cursor: pointer;
`;

export const EtcChattingMyTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 1.5rem;
  min-height: 5rem;
  border-radius: 1.5rem 0px 1.5rem 1.5rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.main};

  :hover {
    background-color: ${(props) => props.theme.colors.activeMain};
  }

  > p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6em;
    word-break: break-word;
    white-space: pre-wrap;
    color: ${(props) => props.theme.colors.txt100};
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;
