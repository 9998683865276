import React from 'react';
import styled from 'styled-components';

interface IconProps {
  width?: string;
  height?: string;
  className?: string;
}

const Svg = styled.svg`
  width: ${(props) => (props.width ? props.width : '2.7rem')};
  height: ${(props) => (props.height ? props.height : '2.1rem')};
`;

const IconStar = ({ className, width, height }: IconProps) => {
  return (
    <Svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4165 8.55993e-07C12.5633 -0.000231224 12.7063 0.0467343 12.8243 0.133964C12.9423 0.221193 13.0292 0.344062 13.072 0.484432L15.7104 9.15082L23.5995 12.0341C23.7296 12.0818 23.8422 12.1678 23.9223 12.2808C24.0024 12.3939 24.0464 12.5285 24.0483 12.6671C24.0502 12.8056 24.01 12.9415 23.9331 13.0567C23.8561 13.1719 23.746 13.261 23.6173 13.3123L15.7802 16.4255L13.072 25.512C13.0298 25.653 12.9432 25.7767 12.8251 25.8646C12.7071 25.9525 12.5638 26 12.4165 26C12.2693 26 12.126 25.9525 12.0079 25.8646C11.8899 25.7767 11.8033 25.653 11.7611 25.512L9.05289 16.4255L1.21579 13.3123C1.08708 13.261 0.976957 13.1719 0.899998 13.0567C0.823039 12.9415 0.782867 12.8056 0.784795 12.6671C0.786723 12.5285 0.830661 12.3939 0.910796 12.2808C0.990932 12.1678 1.10349 12.0818 1.23358 12.0341L9.12269 9.15082L11.7624 0.484432C11.8052 0.344286 11.8919 0.221579 12.0096 0.134371C12.1274 0.0471632 12.27 6.21567e-05 12.4165 8.55993e-07ZM12.4165 3.03248L10.3324 9.87473C10.3015 9.97612 10.2475 10.069 10.1746 10.1459C10.1017 10.2228 10.0119 10.2818 9.91228 10.3181L3.38889 12.7047L9.86712 15.2774C9.9631 15.3157 10.0492 15.3752 10.1188 15.4515C10.1885 15.5278 10.24 15.6189 10.2694 15.718L12.4179 22.9215L14.5636 15.718C14.5931 15.6189 14.6446 15.5278 14.7142 15.4515C14.7839 15.3752 14.87 15.3157 14.966 15.2774L21.4442 12.7047L14.9208 10.3208C14.8212 10.2845 14.7314 10.2256 14.6585 10.1486C14.5856 10.0717 14.5316 9.97886 14.5007 9.87746L12.4165 3.03248Z"
        fill="currentColor"
      />
      <path
        d="M23.022 25.4805C22.8852 25.4805 22.762 25.371 22.7483 25.2342C22.502 22.7436 22.3104 21.9499 20.0251 21.731C19.8883 21.7173 19.7788 21.5941 19.7788 21.4573C19.7788 21.3204 19.8883 21.1973 20.0251 21.1836C22.3652 20.9646 22.502 20.3625 22.7483 17.8719C22.7483 17.7351 22.8852 17.6256 23.022 17.6256C23.1589 17.6256 23.282 17.7351 23.2957 17.8719C23.542 20.3625 23.6926 20.9646 26.0189 21.1836C26.1558 21.1973 26.2653 21.3204 26.2653 21.4573C26.2653 21.5941 26.1558 21.7173 26.0189 21.731C23.7199 21.9499 23.542 22.7436 23.2957 25.2479C23.2957 25.3847 23.1726 25.4942 23.022 25.4942V25.4805Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default IconStar;
