import { listToggleRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import {
  needsGuidePageRecoil,
  say015AuthState,
  say015Number,
  say015PageState,
  userMockupViewRecoil,
} from '@/recoil/atoms/say015Atom';
import { signInRecoil } from '@/recoil/atoms/signInAtom';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const useArsWidget = () => {
  const queryClient = useQueryClient();
  /* 015 번호 */
  const setSay015NumberState = useSetRecoilState(say015Number);
  /* 015 회원가입 정보 및 상태 */
  const [, setAuthState] = useRecoilState(say015AuthState);
  /* 015 구독상태 */
  const [pageState, setPageState] = useRecoilState(say015PageState);
  // ars 페이지 분기처리를 위한 STATE
  const [, setNeedsGuidePageS] = useRecoilState(needsGuidePageRecoil);
  /* 로그인 상태 */
  const [signInState, setSignInState] = useRecoilState(signInRecoil);
  // 015가입자가 목업을 볼 때 true로 변경해야하는 Recoil
  const userMockupViewS = useRecoilValue(userMockupViewRecoil);
  // listToggleState 값을 true 로 변경해서 주소록 페이지 감추기 위함
  const setListToggleState = useSetRecoilState(listToggleRecoil);

  useEffect(() => {
    setListToggleState(true);
    return () => {
      setListToggleState(false);
    };
  }, []);

  const prevAuthData: any = queryClient.getQueryData(['authorityData']);

  const navigate = useNavigate();

  // =================================
  // 현재 로그인 상태인지 파악하는 state 변경
  // =================================
  const onClickSignOut = () => {
    setSignInState(false);
  };
  // =======================================================
  // 로그인 감지 state 가 false 일 경우 로그인 페이지로 이동 - 로그아웃
  // =======================================================
  useEffect(() => {
    if (!signInState) {
      navigate('/signin');
    }
  }, [signInState]);

  // =========================
  // 015 회원정보에 따라 state 세팅
  // =========================
  useEffect(() => {
    if (prevAuthData) {
      setSay015NumberState(prevAuthData.number015); // 015 번호
      setAuthState({
        say015User: prevAuthData.say015User, // 015 회원 정보
        availabilityStatus: prevAuthData.availabilityStatus, // 구독상태인지
      });

      if (prevAuthData.subscriptionStatus) {
        setPageState('정기구독');
        setNeedsGuidePageS(prevAuthData.needsGuidePage);
        return;
      }
      if (prevAuthData.availabilityStatus && !prevAuthData.subscriptionStatus) {
        setPageState('단기구독');
        setNeedsGuidePageS(prevAuthData.needsGuidePage);
        return;
      }
      if (prevAuthData.say015User && !prevAuthData.availabilityStatus) {
        setPageState('구독만료');
        setNeedsGuidePageS(true);
        return;
      }
      if (!prevAuthData.say015User) {
        setPageState('미가입');
        setNeedsGuidePageS(true);
      }
    }
    if (!prevAuthData) {
      // ars 데이터 문제있을시 여기탐
      navigate('/');
    }
  }, [localStorage, prevAuthData]);

  return {
    pageState,
    userMockupViewS,
  };
};
