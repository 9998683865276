import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import * as S from './MobileEtcUserInfoChange.style';
import * as SS from '../MobileEtc.style';
import { useUserInfoChange } from '@/components/hooks/customs/myPage/useUserInfoChange';
import { useSay015SubInfo } from '@/components/hooks/customs/myPage/useSay015SubInfo';
import { usePhoneNumberChange } from '@/components/hooks/customs/myPage/usePhoneNumberChange';
import ATitle from '@/components/Atom/ATitle';
import BaseSelect from '@/components/Atom/BaseSelect';
import BaseInput from '@/components/Atom/BaseInput';
import BaseButton from '@/components/Atom/BaseButton';
import RegisterSelectBox from '@/components/Atom/RegisterSelectBox';
import ButtonList from '@/components/common/buttons/ButtonList';
import BaseGuide from '@/components/Atom/BaseGuide';

export const domainList = [
  { value: '선택', label: '선택' },
  { value: '직접입력', label: '직접입력' },
  { value: 'arreo.com', label: 'arreo.com' },
  { value: 'netian.com', label: 'netian.com' },
  { value: 'chol.com', label: 'chol.com' },
  { value: 'empal.com', label: 'empal.com' },
  { value: 'freechal.com', label: 'freechal.com' },
  { value: 'gmail.com', label: 'gmail.com' },
  { value: 'hanafos.com', label: 'hanafos.com' },
  { value: 'hanmail.net', label: 'hanmail.net' },
  { value: 'hotmail.com', label: 'hotmail.com' },
  { value: 'korea.com', label: 'korea.com' },
  { value: 'nate.com', label: 'nate.com' },
  { value: 'naver.com', label: 'naver.com' },
  { value: 'paran.com', label: 'paran.com' },
  { value: 'yahoo.co.kr', label: 'yahoo.co.kr' },
  { value: 'yahoo.com', label: 'yahoo.com' },
];

export const phoneNumberList = [
  { label: '010', value: '010' },
  { label: '011', value: '011' },
  { label: '016', value: '016' },
  { label: '017', value: '017' },
  { label: '018', value: '018' },
  { label: '019', value: '019' },
  { label: '02', value: '02' },
  { label: '031', value: '031' },
  { label: '032', value: '032' },
  { label: '033', value: '033' },
  { label: '041', value: '041' },
  { label: '042', value: '042' },
  { label: '043', value: '043' },
  { label: '051', value: '051' },
  { label: '052', value: '052' },
  { label: '053', value: '053' },
  { label: '054', value: '054' },
  { label: '055', value: '055' },
  { label: '061', value: '061' },
  { label: '062', value: '062' },
  { label: '063', value: '063' },
  { label: '064', value: '064' },
  { label: '070', value: '070' },
];

export const jobList = [
  { label: '선택해 주세요', value: '' },
  { label: '초중고등학생', value: '01' },
  { label: '대학생', value: '02' },
  { label: '대학원생', value: '03' },
  { label: '무직', value: '04' },
  { label: '가정주부', value: '05' },
  { label: '자영업', value: '06' },
  { label: '의료계', value: '07' },
  { label: '언론,출판', value: '08' },
  { label: '법조인', value: '09' },
  { label: '초중고교사', value: '10' },
  { label: '대학교수', value: '11' },
  { label: '종교인', value: '12' },
  { label: '방송,연예,예술,스포츠', value: '13' },
  { label: '서비스', value: '14' },
  { label: '공무원', value: '15' },
  { label: '회사원', value: '16' },
  { label: '농축수임업', value: '17' },
  { label: '단순노무일용직', value: '18' },
  { label: '기타', value: '19' },
];

const MobileEtcUserInfoChange = () => {
  const {
    modalState,
    closeOverlay,
    postCodeStyle,
    postCodeStyle1,
    onCompletePost,
    modalState1,
    onCompletePost1,
    userInfoS,
    register,
    handleKeyPress,
    emailDomain,
    handleDomain,
    setModalState,
    setModalState1,
    phoneSelectedOption,
    handleNumberFirst,
    buttonList,
    onClickDelZipCd,
  } = useUserInfoChange();

  return (
    <S.UserInfoChangeContainer className="mobileEtcUserInfoChange">
      <SS.Wrapper>
        {modalState && (
          <SS.ModalOverlay onClick={closeOverlay}>
            <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} autoClose />
          </SS.ModalOverlay>
        )}
        {modalState1 && (
          <SS.ModalOverlay onClick={closeOverlay}>
            <DaumPostcode style={postCodeStyle1} onComplete={onCompletePost1} autoClose />
          </SS.ModalOverlay>
        )}

        {window.innerWidth > 768 ? (
          <SS.TitleWrapper className="titleWrap">
            <ATitle type="sub" text="필수항목" />
          </SS.TitleWrapper>
        ) : null}

        <SS.MyPageTable>
          <tbody>
            <tr>
              <th>
                아이디 <span className="red">*</span>
              </th>
              <td>
                <div className="automatic">{userInfoS?.phnId.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</div>
              </td>
            </tr>

            <tr>
              <th>
                이름 <span className="red">*</span>
              </th>
              <td>
                <div className="automatic">{userInfoS?.usrNm}</div>
              </td>
            </tr>

            <tr className="emailRow">
              <th rowSpan={2}>
                이메일주소 <span className="red">*</span>
              </th>
              <td>
                <SS.EmailBoxForm>
                  <div className="emailInputWrap">
                    <SS.EmailInput
                      type="text"
                      placeholder="이메일을 입력해주세요."
                      {...register('usrEmail', {})}
                      onKeyPress={handleKeyPress}
                    />
                    <p>@</p>
                    <SS.EmailInput type={'text'} {...register('domain', {})} />
                  </div>
                  <BaseSelect options={domainList} value={emailDomain} onChange={handleDomain} />
                </SS.EmailBoxForm>
              </td>
            </tr>
          </tbody>
        </SS.MyPageTable>
      </SS.Wrapper>

      <SS.Wrapper>
        {window.innerWidth > 768 ? (
          <SS.TitleWrapper className="titleWrap">
            <ATitle type="sub" text="선택항목" />
          </SS.TitleWrapper>
        ) : null}
        <SS.MyPageTable>
          <tbody>
            <tr>
              {window.innerWidth > 768 ? <th rowSpan={2}>주소</th> : null}
              <td>
                <SS.AddressWrap>
                  <SS.InputName>자택</SS.InputName>
                  <div className="inputWrap">
                    {window.innerWidth > 768 ? (
                      <BaseInput type="text" width="30%" {...register('zipCd', {})} disabled />
                    ) : (
                      <div>
                        <p>우편번호</p>
                        <div className="flexBtn">
                          <div className="automatic">
                            <BaseInput type="text" width="30%" {...register('zipCd', {})} disabled />
                          </div>
                          {window.innerWidth > 768 ? null : (
                            <div className="btnWrap">
                              <BaseButton onClick={() => setModalState(true)}>우편번호 검색</BaseButton>
                              <BaseButton onClick={() => onClickDelZipCd('home')}>지우기</BaseButton>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {window.innerWidth > 768 ? (
                      <BaseInput type="text" width="70%" {...register('addr')} />
                    ) : (
                      <div>
                        <p>주소</p>
                        <BaseInput type="text" width="70%" {...register('addr')} />
                      </div>
                    )}
                  </div>
                  {window.innerWidth > 768 ? (
                    <div className="btnWrap">
                      <BaseButton onClick={() => setModalState(true)}>우편번호 검색</BaseButton>
                      <BaseButton onClick={() => onClickDelZipCd('home')}>지우기</BaseButton>
                    </div>
                  ) : null}
                </SS.AddressWrap>
              </td>
            </tr>

            <tr>
              <td>
                <SS.AddressWrap>
                  <SS.InputName>직장</SS.InputName>
                  <div className="inputWrap">
                    {window.innerWidth > 768 ? (
                      <BaseInput type="text" width="30%" {...register('cpZipCd', {})} disabled />
                    ) : (
                      <div>
                        <p>우편번호</p>
                        <div className="flexBtn">
                          <div className="automatic">
                            <BaseInput type="text" width="30%" {...register('cpZipCd', {})} disabled />
                          </div>
                          {window.innerWidth > 768 ? null : (
                            <div className="btnWrap">
                              <BaseButton onClick={() => setModalState1(true)}>우편번호 검색</BaseButton>
                              <BaseButton onClick={() => onClickDelZipCd('com')}>지우기</BaseButton>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {window.innerWidth > 768 ? (
                      <BaseInput type="text" width="70%" {...register('cpAddr')} />
                    ) : (
                      <div>
                        <p>주소</p>
                        <BaseInput type="text" width="70%" {...register('cpAddr')} />
                      </div>
                    )}
                  </div>
                  {window.innerWidth > 768 ? (
                    <div className="btnWrap">
                      <BaseButton onClick={() => setModalState1(true)}>우편번호 검색</BaseButton>
                      <BaseButton onClick={() => onClickDelZipCd('com')}>지우기</BaseButton>
                    </div>
                  ) : null}
                </SS.AddressWrap>
              </td>
            </tr>
            <tr className="numberRow">
              <th>연락처</th>
              <td>
                <SS.PhonenumberWrap className="phonenumberWrap">
                  <div className="numberWrap">
                    <BaseSelect options={phoneNumberList} value={phoneSelectedOption} onChange={handleNumberFirst} />
                    <p>-</p>
                    <BaseInput type="text" placeholder="" {...register('userTel2', {})} />
                    <p>-</p>
                    <BaseInput type="text" placeholder="" {...register('userTel3', {})} />
                  </div>
                  <p className="text">(휴대폰 외에 연락가능한 전화번호)</p>
                </SS.PhonenumberWrap>
              </td>
            </tr>

            <tr>
              <th>생일</th>
              <td>
                <SS.BirthDateWrap className="automatic">
                  {userInfoS?.birthDt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')}
                </SS.BirthDateWrap>
              </td>
            </tr>

            <tr className="select">
              <th>직업</th>
              <td>
                <RegisterSelectBox options={jobList} value={userInfoS?.jobGb} register={register} field="jobGb" />
              </td>
            </tr>
          </tbody>
        </SS.MyPageTable>
        <BaseGuide text="개인정보취급방침에 따라 개인정보를 보호하고, 회원님의 동의 없이 절대 외부에 유출하지 않을 것을 약속드립니다." />
        <SS.ButtonWrapper>
          <ButtonList buttonList={buttonList} />
        </SS.ButtonWrapper>
      </SS.Wrapper>
      <SS.ButtonWrapper />
    </S.UserInfoChangeContainer>
  );
};

export default MobileEtcUserInfoChange;
