import React, { useEffect, useRef, useState } from 'react';
import * as S from './AddComponents.style';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  addModalOpenRecoil,
  addModalTitleRecoil,
  mobileBuddyListAddRecoil,
  mobileBuddySeqNoListAddRecoil,
  mobileGroupSeqNoListAddRecoil,
  multiChatRoomIdRecoil,
  syncLoadingRecoil,
} from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { useCreateMultiChat } from '../mobileHooks/useCreateMultiChat';
import MobileLoader from '../MobileLoader';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { lastPathSegment } from '@/shared/util/appUtil';
import { useCreateGroupList } from '../mobileHooks/useCreateGroupList';
import { useCreateBuddyList } from '../mobileHooks/useCreateBuddyList';
import { chatUserInfoRecoil, mhQueryParamsRecoil } from '@/recoil/atoms/chatRoom';
import { formatPhoneNumber, TelHyphen, removeHyphens } from '@/shared/util/format/phoneNumberFormatUtil';
import { validatePhoneNumber } from '@/shared/util/AddressUtils';

const AddComponents: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const inputRef = useRef<HTMLInputElement>(null);

  const [firstInputValue, setFirstInputValue] = useState<string>('');
  const [secondInputValue, setSecondInputValue] = useState<string>('');

  //모바일 로딩 리코일
  const setSyncLoadingS = useSetRecoilState(syncLoadingRecoil);
  //모바일 대량 전송을 위한 버디 리스트 리코일
  const mobileBuddySeqNoListAddS = useRecoilValue(mobileBuddySeqNoListAddRecoil);
  //모바일 대량 전송을 위한 그룹 리스트 리코일
  const mobileGroupSeqNoListAddS = useRecoilValue(mobileGroupSeqNoListAddRecoil);
  // 추가 방 상태 리코일
  const setAddModalOpenS = useSetRecoilState(addModalOpenRecoil);
  // 추가 방 제목 리코일
  const [addModalTitleS, setAddModalTitleS] = useRecoilState(addModalTitleRecoil);

  //단체 채팅방 데이터 리코일
  const setMultiChatRoomId = useSetRecoilState(multiChatRoomIdRecoil);

  //훅
  const createMultiChatHook = useCreateMultiChat();
  const createGroupHook = useCreateGroupList();
  const createBuddyHook = useCreateBuddyList();

  // 1:1 채팅룸 번호로 생성할때 쓰일 아이들
  const [, setMhQueryParams] = useRecoilState(mhQueryParamsRecoil);
  const setMobileBuddyListAddS = useSetRecoilState(mobileBuddyListAddRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const setChatUserInfo = useSetRecoilState(chatUserInfoRecoil);

  // 그룹 새로 만들기 mutation
  // const { mutate: addBuddyMutate } = useMutation(postAddBuddy);

  const { customWarningModal, confirmModal, warningModal } = useModalHook();

  useEffect(() => {
    inputRef.current?.focus(); // 컴포넌트가 마운트되면 포커스
  }, []);

  const onChangeFirstInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFirstInputValue(value);
  };

  const onChangeSecondInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSecondInputValue(value);
  };

  // 단체 전송방 생성 할때 쓰이는 함수
  const onClickMultiChatRoomConfirm = async () => {
    //단체 전송방 생성
    if (addModalTitleS === '단체 전송방') {
      // 그룹 SeqNo 포맷팅 (undefined 값 제외)
      const formatGroupSeqNo = mobileGroupSeqNoListAddS
        ?.map((item) => item.groupSeqNo)
        .filter((seqNo): seqNo is number => seqNo !== undefined);

      // 버디 SeqNo 포맷팅 (undefined 값 제외)
      const formatBuddySeqNo = mobileBuddySeqNoListAddS
        ?.map((item) => item.buddySeqNo)
        .filter((seqNo): seqNo is number => seqNo !== undefined);
      if (typeof firstInputValue === 'string' && firstInputValue.trim().length < 1) {
        customWarningModal(
          () => {
            inputRef.current?.focus();
          },
          '경고',
          '방 이름을 입력해 주세요.',
          true,
        );
        return;
      }

      confirmModal(
        async () => {
          try {
            // 멀티 메시지 생성
            const response = await createMultiChatHook.mutateAsync({
              groupSeqList: formatGroupSeqNo,
              buddySeqList: formatBuddySeqNo,
              name: firstInputValue,
            });
            setAddModalOpenS(false);
            setAddModalTitleS('');
            setMultiChatRoomId(response.chatRoomId);
            navigate(`/mobile/message/messageList/multiChatroom`);
          } catch (error) {
            // 에러 핸들링
            customWarningModal(
              () => {},
              '오류',
              `1. 유효하지 않은 번호 형식을 제외합니다.</br>
2. 중복된 번호를 제외합니다.</br>
3. 유효한 번호들 중 중복을 제거한 결과가 정확히 2개인지 확인해주세요.
              `,
              true,
            );
          }
        },
        `'${firstInputValue}'`,
        `지정한 방 이름으로 채팅방을 생성 하시겠습니까?`,
        true,
      );
    }
  };

  //그룹 새로 만들기
  const onClickCreateGroupConfirm = () => {
    const newGroup = {
      groupNm: firstInputValue,
    };

    confirmModal(
      async () => {
        try {
          await createGroupHook.mutateAsync(newGroup);
          await queryClient.refetchQueries(['groupList2']);
          setAddModalOpenS(false);
          setAddModalTitleS('');
          window.scrollTo(0, 0);
        } catch {
          // 에러 핸들링
          customWarningModal(
            async () => {
              await queryClient.refetchQueries(['groupList2']);
              setAddModalOpenS(false);
              setAddModalTitleS('');
              window.scrollTo(0, 0);
            },
            '오류',
            '그룹 생성 중 오류가 발생했습니다.',
            true,
          );
        }
      },
      `'${firstInputValue}'`,
      `지정한 그룹 이름으로 생성 하시겠습니까?`,
      true,
    );
  };

  //주소록 새로 만들기
  const onClickCreateBuddyConfirm = () => {
    const newBuddy = {
      groupSeqNo: lastPathSegment(location.pathname),
      buddyNm: firstInputValue,
      // 하이픈 제거 하고 넣기
      keyCommNo: removeHyphens(TelHyphen(secondInputValue)),
    };

    confirmModal(
      async () => {
        if (!validatePhoneNumber(removeHyphens(TelHyphen(secondInputValue)))) {
          warningModal('경고', '번호를 확인해 주세요.', true);
        } else {
          try {
            await createBuddyHook.mutateAsync(newBuddy);
            await queryClient.refetchQueries(['groupBuddyList', lastPathSegment(location.pathname)]);
            setAddModalOpenS(false);
            setAddModalTitleS('');
            window.scrollTo(0, 0);
          } catch {
            // 에러 핸들링
            customWarningModal(
              async () => {
                await queryClient.refetchQueries(['groupBuddyList', lastPathSegment(location.pathname)]);
                setAddModalOpenS(false);
                setAddModalTitleS('');
                window.scrollTo(0, 0);
              },
              '오류',
              '주소록 생성 중 오류가 발생했습니다.',
              true,
            );
          }
        }
      },
      `'${newBuddy.buddyNm}' '${TelHyphen(newBuddy.keyCommNo)}'`,
      `지정한 번호와 이름으로 주소록을 생성 하시겠습니까?`,
      true,
    );
  };

  //번호로 채팅방 생성
  const onClickCreateChatroom = () => {
    // 밸리데이트로 핸드폰 번호를 제대로 입력했을 때
    if (validatePhoneNumber(removeHyphens(TelHyphen(firstInputValue)))) {
      confirmModal(
        async () => {
          try {
            navigate(`/mobile/message/messageList/${firstInputValue}`);
            setChatUserInfo({ phoneNumber: removeHyphens(TelHyphen(firstInputValue)), buddyName: '' });
            setMobileBuddyListAddS([
              {
                buddySeqNo: 0,
                buddyNm: '',
                keyCommNo: removeHyphens(TelHyphen(firstInputValue)),
              },
            ]);
            setMhQueryParams((prevState: any) => {
              return {
                ...prevState,
                contactNumber: removeHyphens(TelHyphen(firstInputValue)),
              };
            });
            setAddModalOpenS(false);
            setAddModalTitleS('');
          } catch {
            // 에러 핸들링
            customWarningModal(
              async () => {
                navigate(-1);
                setAddModalOpenS(false);
                setAddModalTitleS('');
              },
              '오류',
              '주소록 생성 중 오류가 발생했습니다.',
              true,
            );
          }
        },
        `'${TelHyphen(firstInputValue)}'`,
        `지정한 번호로 채팅방을 생성 하시겠습니까?`,
        true,
      );
    } else {
      // 번호 이상하게 넣었을 때
      warningModal('경고', '유효하지 않은 번호 형식 입니다.', true);
    }
  };

  const placeHolder = (() => {
    switch (addModalTitleS) {
      case '단체 전송방':
        return '방 이름을 입력해 주세요.';
      case '그룹 추가':
        return '그룹 이름을 입력해 주세요.';
      case '채팅방 생성':
        return '번호를 입력해 주세요.';
      case '주소록 추가':
        return '이름을 입력해 주세요.번호를 입력해 주세요.';
      default:
        return '';
    }
  })();

  const onClick = (() => {
    switch (addModalTitleS) {
      case '단체 전송방':
        return onClickMultiChatRoomConfirm;
      case '그룹 추가':
        return onClickCreateGroupConfirm;
      case '주소록 추가':
        return onClickCreateBuddyConfirm;
      case '채팅방 생성':
        return onClickCreateChatroom;
      default:
        return undefined; // 기본값으로 undefined 반환
    }
  })();

  const onClickAddModalOpen = () => {
    // 모달창 닫기
    setAddModalOpenS(false);
    // 브라우저 뒤로가기
    window.history.back();
  };

  //뒤로가기 눌렀을 시 실행될 로직
  useEffect(() => {
    if (window.location.hash !== '#add') {
      // 모달창 닫기
      setAddModalOpenS(false);
    }
  }, [location.hash]);

  return (
    <>
      <S.AddContainer>
        <S.AddHeader>
          {/* 왼쪽 아이콘 및 뒤로 가기 */}
          <S.HeaderLeft onClick={() => onClickAddModalOpen()}>
            <IconArrowLongLeft />
          </S.HeaderLeft>

          {/* 중앙 타이틀 */}
          <S.CenterContainer>{addModalTitleS}</S.CenterContainer>

          {/* 오른쪽 확인 버튼 */}
          <S.IconContainer>
            <S.HeaderButtonFont
              onClick={() => {
                if (onClick) {
                  onClick(); // 함수 호출
                }
              }}
            >
              확인
            </S.HeaderButtonFont>
          </S.IconContainer>
        </S.AddHeader>

        <S.AddMain>
          <S.AddInput
            value={addModalTitleS === '채팅방 생성' ? TelHyphen(firstInputValue) : firstInputValue}
            onChange={onChangeFirstInputValue}
            type="text"
            placeholder={placeHolder.split('.')[0]}
            maxLength={50}
          />

          {addModalTitleS === '주소록 추가' && (
            <S.AddInput
              value={TelHyphen(secondInputValue)}
              onChange={(e) => {
                onChangeSecondInputValue(e);
              }}
              type="text"
              placeholder={placeHolder.split('.')[1]}
            />
          )}
        </S.AddMain>
      </S.AddContainer>
    </>
  );
};

export default AddComponents;
