import { chatUserInfoRecoil } from '@/recoil/atoms/chatRoom';
import { instance } from '@/shared/lib/clientAxios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

interface Content {
  prepayPayNo: string;
  subject: string;
  sndMsg: string;
  sndDttm: string;
  callback: string;
  msgGb: string;
  imgData: string | null;
  fileData: string | null;
}

interface Pageable {
  sort: any[]; // 구체적으로 정할 수 있으면 정하세요.
  offset: number;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

interface SearchMultiChatroomResponse {
  chatRoomName: string;
  pages: {
    content: Content[];
    pageable: Pageable;
    totalElements: number;
    totalPages: number;
    size: number;
    numberOfElements: number;
    first: boolean;
    last: boolean;
    empty: boolean;
    number: number;
  };
}

export const useMultiChatList = () => {
  /* 활성화 채팅방의 상대방 정보 */
  const [chatUserInfo, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);

  const fetchMultiMessage = async ({
    chatRoomId = chatUserInfo.phoneNumber,
    pageSize = 20,
    pageParam = 0,
  }): Promise<SearchMultiChatroomResponse> => {
    const response = await instance.get('/smt/history/batch/room', {
      params: {
        chatRoomId,
        pageNumber: pageParam,
        pageSize,
      },
    });
    return response.data;
  };

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    fetchStatus,
    status,
  } = useInfiniteQuery<SearchMultiChatroomResponse>(
    ['/smt/history/batch/room', chatUserInfo], // queryKey는 캐싱을 위한 유니크한 키입니다.
    fetchMultiMessage,
    {
      getNextPageParam: (lastPage, pages) => {
        // 다음 페이지가 있는 경우 반환
        if (!lastPage.pages.last) {
          return pages.length; // 다음 페이지 번호
        }
        return undefined; // 더 이상 페이지가 없으면 undefined
      },
      onError: (error: any) => {
        console.error('API호출 실패: ', error?.message);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    fetchStatus,
    status,
  };
};
