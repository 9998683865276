import { useMutation } from '@tanstack/react-query';
import { instance } from '@/shared/lib/clientAxios';

interface CreateGroupParams {
  groupNm: string; // 그룹 이름 (필수)
}

interface CreateGroupResponse {
  groupNm: string;
  groupSeqNo: number;
  groupSeqNos: null; // 뭔지 모르겠음
  usrKey: string;
}

export const useCreateGroupList = () => {
  const { mutateAsync, isLoading, isError, error } = useMutation(
    async ({ groupNm }: CreateGroupParams): Promise<CreateGroupResponse> => {
      try {
        const response = await instance.post('/group/create', {
          groupNm,
        });
        return response.data; // 응답 데이터 반환
      } catch (err: unknown) {
        console.error('Failed to create group:', err);
        throw err; // 오류를 상위로 전달
      }
    },
  );

  return { mutateAsync, isLoading, isError, error };
};
