import { mainTableCheckDataFormatRecoil } from '@/recoil/atoms/mainTableRecoil';
import { mobileNavRecoil, windowWidthRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

const BtnWrap = styled.div`
  width: 100%;
  position: absolute;
  bottom: 1rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
`;

const MoveSendMessageBtn = styled.button`
  width: 95%;
  background-color: rgb(54, 110, 255);
  border-radius: 0.8rem;
  padding: 1rem 0;
  color: white;
`;

const MobileMoveSendMessage = () => {
  const [mobileNavState, setMobileNavState] = useRecoilState(mobileNavRecoil);
  const [mainTableCheckDataFormatS] = useRecoilState(mainTableCheckDataFormatRecoil);
  const windowWidth = useRecoilValue(windowWidthRecoil);

  const count =
    mainTableCheckDataFormatS && mainTableCheckDataFormatS.reduce((accumulator, item) => accumulator + item.count, 0);

  return (
    <>
      {windowWidth <= 768 && (
        <BtnWrap>
          <MoveSendMessageBtn
            onClick={() => {
              setMobileNavState('메세지');
            }}
          >
            메세지 보내기({count}명) <br /> 중복된 번호는 자동 제외 됩니다.
          </MoveSendMessageBtn>
        </BtnWrap>
      )}
    </>
  );
};

export default MobileMoveSendMessage;
