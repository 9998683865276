import { IUseArsCardModal } from '@/widgets/ars/components/arsTree/components/arsCardModal/ui/ArsCardModal.types';

export const useArsCardModal = ({ handleUpdateIdState, handleAddIdState, type }: IUseArsCardModal) => {
  // ==========================
  // 수정 및 생성 취소 버튼 이벤트 함수
  // ==========================
  const handleCancel = () => {
    switch (type) {
      case 'add':
        if (!handleAddIdState) return;
        handleAddIdState(null);
        break;
      case 'update':
        if (!handleUpdateIdState) return;
        handleUpdateIdState(null);
        break;
      default:
        break;
    }
  };

  return {
    handleCancel,
  };
};
