import { resultDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import { useSetRecoilState } from 'recoil';

export const useGlobalModalReset = () => {
  // 전송 결과 상세페이지 API 요청에 필요한 Reco
  const setResultDetailParamsS = useSetRecoilState(resultDetailParamsRecoil);

  // 글로벌 모달 off시 실행할 recoilReset 이벤트
  const modalReset = () => {
    setResultDetailParamsS({ prepayPayNo: '', callback: '' });
  };

  return { modalReset };
};
