import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';

// TODO
// 그룹리스트의 검색 + 셀렉트 기능의 컴포넌트
interface IGroupListSelect {
  type?: 'move' | 'copy' | ''; // 수정(이동), 복사에 맞게 로직을 추가하려면 해당 타입사용
  item?: any; // item(groupSeqNo를 가진 객체)을 받으면 본인이 속한 그룹 제외한 리스트 표출함
  data?: any; // 필요한 데이터 props 받아오는데 타입에 따라 지정해줘도 됌
  onClickfunction?: any; // 클릭 이벤트 받아오는 훅
  onClickClose?: any; // 모달 닫는 훅
}

const GroupListSelect = (props: IGroupListSelect) => {
  const queryClient = useQueryClient();

  const groupListQueryData: any = queryClient.getQueryData(['groupList2']);

  const selectRef: React.RefObject<HTMLDivElement> = useRef(null);
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

  // 검색할 때 바라볼 원본 리스트
  const [selectList, setSelectList] = useState<any[]>([]);
  // 검색 텍스트
  const [searchText, setSearchText] = useState<string>('');
  // 검색한 리스트
  const [filteredList, setFilteredList] = useState<any[]>([]);

  // select 외부 클릭시 onClose 함수 실행해서 select off
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef?.current && !selectRef.current.contains(event.target as Node)) {
        props.onClickClose?.();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  // 초기값 리스트 띄울 useEffect hook
  useEffect(() => {
    if (groupListQueryData) {
      const initialList = props.item
        ? groupListQueryData.groupList.filter((list: any) => list.groupSeqNo !== props.item.groupSeqNo)
        : groupListQueryData.groupList;

      setSelectList(initialList);
      setFilteredList(initialList);
    }

    // 검색 인풋에 포커싱
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [groupListQueryData, props.item]);

  // 1 - 텍스트 변경할때마다 검색되는 형식
  // useEffect(() => {
  //   const result = selectList.filter((list: any) => list.groupNm.includes(searchText));
  //   setFilteredList(result);
  // }, [searchText, selectList]);

  // 2 - 제출할 때 검색되는 형식
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    const result = selectList.filter((list: any) => list.groupNm.includes(searchText));
    setFilteredList(result);
  };

  // 검색 텍스트 onChange hook
  const onChangeInputText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // onClick li hook 그룹 값을 받아와서 처리하는 hook
  const onClickLi = (list: any) => {
    if (props.data) {
      props.onClickfunction(list, props.data);
    } else {
      props.onClickfunction(list);
    }
  };

  return (
    <div ref={selectRef}>
      <form className="search" onSubmit={handleSearch}>
        <button type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M10.3333 10.2064L13 12.873M1 6.20638C1 7.62087 1.5619 8.97742 2.5621 9.97762C3.56229 10.9778 4.91885 11.5397 6.33333 11.5397C7.74782 11.5397 9.10438 10.9778 10.1046 9.97762C11.1048 8.97742 11.6667 7.62087 11.6667 6.20638C11.6667 4.79189 11.1048 3.43534 10.1046 2.43514C9.10438 1.43495 7.74782 0.873047 6.33333 0.873047C4.91885 0.873047 3.56229 1.43495 2.5621 2.43514C1.5619 3.43534 1 4.79189 1 6.20638Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <input
          ref={inputRef}
          type="text"
          placeholder="그룹 검색"
          value={searchText}
          onChange={(e) => onChangeInputText(e)}
        />
      </form>
      <ul>
        <div>
          {filteredList?.map((list: any) => {
            return (
              <li key={list.groupSeqNo} onClick={() => onClickLi(list)}>
                <button>
                  <p>{list.groupNm}</p>
                </button>
              </li>
            );
          })}
        </div>
      </ul>
    </div>
  );
};
export default GroupListSelect;
