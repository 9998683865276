import { instance } from '@/shared/lib/clientAxios';
import { IMenus015 } from '../customs/charge/chargeStation/useChargeMenuItems';

export interface IUseMutationChargeKicc {
  /* 결제 방식 - 0 : 캐시, 11 : 카드, 21 : 무통장, 31 : 휴대폰 */
  payMethodTypeCode: IMenus015['payMethodTypeCode'];
  /* 상품 가격 */
  amount: number;
  /* 리다이렉션 될 URL */
  returnUrl: string;
  /* cash : 기본 캐시 충전 , monthly_pass : 월간 이용권, annual_pass: 연간 이용권 */
  productType: 'cash' | 'monthly_pass' | 'annual_pass';
}

/**
 * @title ( end_point : /charge/kicc ) 이지페이 팝업 표출에 필요한 데이터 요청 MUTATION GET API
 *
 * @param data
 * */
export const useMutationChargeKicc = async (data: IUseMutationChargeKicc) => {
  const response = await instance.post('/charge/kicc', data);
  return response;
};
