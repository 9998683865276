import React from 'react';
import styled from 'styled-components';

// 스타일 설정
const ApiCodeBlockContainer = styled.pre`
  padding: 1.6rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray100};
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 0.5rem;
  white-space: pre-wrap;
  font-size: 1.4rem;
  overflow-x: auto;
  margin: 0 0 1rem;
`;

interface ApiCodeBlockProps {
  code: string;
}

const ApiCodeBlock: React.FC<ApiCodeBlockProps> = ({ code }) => {
  return <ApiCodeBlockContainer>{code}</ApiCodeBlockContainer>;
};

export default ApiCodeBlock;
