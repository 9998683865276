/* eslint-disable */
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  resultDetailParamsRecoil,
  transQueryKeyRecoil,
  transTableDataRecoil,
} from '@/recoil/atoms/sendResult/sendResult';
import { deleteTransferMsg, reSendMsgApi } from '@/apis/api/transferResultApi';
import { useMsgRecoilReset } from '@/components/hooks/customs/useMsgRecoilReset';
import { listToggleRecoil, softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { IUseSendResultTable, SendResultDetailSearchData } from '@/components/hooks/customs/types/Hooks.types';
import { ConfirmModal } from '@/pages/main/components/modal/confirmModal/ConfirmModal.styles';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useSendResultDetail } from '../useSendResultDetail';

/**
 * @title SendResultDetailTable.tsx Hooks
 *
 * @author 정휘학
 * @since 2024.03.19
 * */
export const useSendResultDetailTable = (props: IUseSendResultTable) => {
  const queryClient = useQueryClient();
  const [leftSelectSwitch, setLeftSelectSwitch] = useState(false);
  const [rightSelectSwitch, setRightSelectSwitch] = useState(false);
  /* 전송결과 리스트 STATE */
  const [transTableDataState, setTransTableDataState] = useRecoilState(transTableDataRecoil);
  // 검색 내용 STATE
  const [keywordState, setKeywordState] = useState<string>('');
  // 검색 조건 STATE
  const [keywordValueState, setKeywordValueState] = useState<any>({ label: '연락처', value: 'number' }); // LeftState
  // 상태 조건 STATE
  const [rsltValState, setRsltValState] = useState<any>({ label: '전체', value: null }); // LeftState
  // callback, prePayNo RECOIL
  const [resultDetailParamsState] = useRecoilState(resultDetailParamsRecoil);
  // 삭제시 초기화
  const { resetMsgInfo } = useMsgRecoilReset();
  // 검색 조건 배열
  const keywordValueArr = [{ label: '연락처', value: 'number' }];
  // 상태 조건 배열
  const rsltValArr = [
    { label: '전송완료', value: '-100' },
    { label: '전송중', value: '99' },
    { label: '전송실패', value: '100' },
  ];
  //모달 훅
  const { successModal, warningModal, confirmModal } = useModalHook();

  const softPhoneTopTabState = useRecoilValue(softPhoneTopTabRecoil);
  const setListToggleState = useSetRecoilState(listToggleRecoil);
  const [searchAllValue] = useRecoilState(transQueryKeyRecoil);
  // 전송결과 리스트 삭제
  const { mutate: mutationDelete, isLoading: mutationDeleteLoading } = useMutation(
    () =>
      deleteTransferMsg({
        prepayPayNo: resultDetailParamsState.prepayPayNo,
        callback: resultDetailParamsState.callback,
      }),
    {
      onSuccess: () => {
        const cacheData: any = queryClient.getQueryData(['/sendList', searchAllValue.keyword, softPhoneTopTabState]);
        setListToggleState(false);

        if (cacheData.pages) {
          const cacheContent = cacheData.pages.map((item: any) => item.content);
          const c = cacheContent.map((arr: any, index: number) => {
            return arr.filter((f: any) => f.prepayPayNo !== resultDetailParamsState.prepayPayNo);
          });
          const updatedData = {
            ...cacheData,
            pages: cacheData.pages.map((p: any, pageIndex: number) => {
              return {
                ...p,
                content: c[pageIndex],
              };
            }),
          };
          queryClient.setQueryData(['/sendList', searchAllValue.keyword, softPhoneTopTabState], updatedData);
          setTransTableDataState(updatedData);
        }
        resetMsgInfo();
      },
    },
  );

  /* 전송결과 리스트 삭제 Hook */
  const deleteMsg = () => {
    confirmModal(
      () => {
        mutationDelete();
      },
      '전송 내역을 삭제 하시겠습니까?',
      undefined,
      true,
    );
  };

  const { mutate: reSendMsgQuery, isLoading } = useMutation(reSendMsgApi);

  const reSendMsg = () => {
    confirmModal(
      () => {
        // 전송 결과 재전송
        reSendMsgQuery(resultDetailParamsState.prepayPayNo);
      },
      '전송에 실패한 메시지를 다시 전송하시겠습니까?',
      '실패한 메시지는 24시간 이내에 환불 처리되며, 재전송 시 추가 요금이 부과됩니다.',
      true,
    );
  };

  // 검색 조건 변경 함수
  const onChangeKeywordValue = (item: any) => {
    setKeywordValueState(item);
  };
  // 상태 조건 변경 함수
  const onChangeRsltValue = (item: any) => {
    setRsltValState(item);
    props.setSearchAllValue((prev: SendResultDetailSearchData) => {
      const updatedData = { ...prev };
      updatedData.rsltVal = item.value;
      updatedData.pageNumber = 0;
      return updatedData;
    });
  };
  // 검색 버튼 클릭 or 엔터
  const onClickSearchButton = (e: any) => {
    e.preventDefault();
    props.setSearchAllValue((prev: SendResultDetailSearchData) => {
      const updatedData = { ...prev };
      updatedData.keyword = keywordState === '' ? null : keywordState;
      // updatedData.keywordValue = keywordValueState.value;
      updatedData.rsltVal = rsltValState.value;
      updatedData.pageNumber = 0;

      return updatedData;
    });
  };

  return {
    deleteMsg,
    onClickSearchButton,
    leftSelectSwitch,
    setLeftSelectSwitch,
    keywordValueState,
    keywordValueArr,
    onChangeKeywordValue,
    keywordState,
    setKeywordState,
    rightSelectSwitch,
    setRightSelectSwitch,
    rsltValState,
    rsltValArr,
    onChangeRsltValue,
    mutationDeleteLoading,
    reSendMsg,
  };
};
