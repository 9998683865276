import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconPlus: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '1.2rem'}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6.25 0.75V4.75L5.75 4.75V0.75C5.75 0.611929 5.86193 0.5 6 0.5C6.13807 0.5 6.25 0.611929 6.25 0.75ZM5.75 5.75L6.25 5.75V6.25H5.75V5.75ZM7.25 6.25V5.75H11.25C11.3881 5.75 11.5 5.86193 11.5 6C11.5 6.13807 11.3881 6.25 11.25 6.25L7.25 6.25ZM5.75 7.25L6.25 7.25V11.25C6.25 11.3881 6.13807 11.5 6 11.5C5.86193 11.5 5.75 11.3881 5.75 11.25V7.25ZM4.75 5.75V6.25L0.75 6.25C0.611929 6.25 0.5 6.13807 0.5 6C0.5 5.86193 0.611929 5.75 0.75 5.75H4.75Z"
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
      />
    </Svg>
  );
};

export default IconPlus;
