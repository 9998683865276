import React, { useEffect } from 'react';

import Loader from '@/components/common/Loader';
import { useCheck015Number } from '@/components/hooks/customs/say015/say015Signup/useCheck015Number';
import { SERVICE_NAME } from '@/shared/constants';
import * as SB from '@/styles/button.styles';

import SubscriptionGuide from '@/components/softPhone/DisplayGroups/ServiceSignUp/SubscriptionGuide/SubscriptionGuide';
import styled from 'styled-components';
import JoinCompletion from '@/components/softPhone/DisplayGroups/ServiceSignUp/JoinCompletion/JoinCompletion';

interface ISelectNumber {
  authorityDataRefetch: any;
  authorityDataState: any;
}

export const SelectNumber = styled.div`
  padding: 2rem;
`;

export const SelectNumberWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 -1rem;
  padding: 0 1rem;
  height: 100%;
  button {
    cursor: pointer;
  }
  .flexBox-js {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

export const SelectGroup = styled.div`
  margin-bottom: 4rem;
  h2 {
    color: #191919;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    margin-left: 6px;
  }
  .phoneGroup {
    color: #191919;
    font-size: 1.6rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    gap: 1rem;

    .numberGroup {
      position: relative;
      & + button {
        p.open {
          display: block;
        }
        p.close {
          display: none;
        }
      }
      &.active + button {
        p.open {
          display: none;
        }
        p.close {
          display: block;
        }
      }
      .number {
        display: flex;
        align-items: center;
        padding: 0.5rem 1.2rem;
        background: #fff;
        border: 1px solid #98999a;
        gap: 0.5rem;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 6.4px;
        border-radius: 6rem;
        > p {
          width: 52px;
        }
        > span {
          width: 16px;
          letter-spacing: 6.4px;
        }
        > span:nth-child(4) {
          width: 18px;
          margin: -3px;
        }
        input {
          border: 0;
          outline: #333;
          font-size: 1.6rem;
          font-weight: 600;
          letter-spacing: 6.4px;
          width: 7rem;
          background-color: transparent;
          ::placeholder {
            color: ${(props) => props.theme.colors.placeholder};
            font-size: 1.6rem;
            font-weight: 600;
            letter-spacing: 6.4px;
          }
        }
        button {
          background: transparent;
          border: 0;
          line-height: 0;
          cursor: pointer;
        }
      }
      .recommendationNumberBox {
        z-index: 2;
        /* position: absolute;
				top: 100%;
				left: 0; */
        display: none;
        padding: 0.6rem 0;
        padding-right: 4px;
        background: #fff;
        width: 100%;
        border: 1px solid #98999a;
        border-top: 0;
        border-radius: 0 0 1.5rem 1.5rem;
        overflow: hidden;
      }
      .recommendationNumber {
        width: 100%;
        height: 20vh;
        max-height: 30rem;
        overflow: auto;
        // overflow 스크롤 styles
        ::-webkit-scrollbar {
          width: 0.4rem;
          background-color: #d6d6dc;
          border-radius: 40px;
        }
        ::-webkit-scrollbar-thumb {
          background: #98999a;
          border-radius: 40px;
        }

        li {
          position: relative;
          /* :last-child{
						border-radius: 0 0 1.5rem 1.5rem;
						overflow-x: hidden;
					} */

          button {
            width: 100%;
            font-size: 1.6rem;
            padding: 0.6rem 1rem 0.6rem 1.2rem;
            letter-spacing: 0.6rem;
            font-weight: 500;
            border: 0;
            text-align: left;
            letter-spacing: 6.4px;
            /* ::before{
							content: "';
							display: none;
							position: absolute;
							top: 0;
							left: 0;
							background: ${(props) => props.theme.colors.main};
							width: 2.5px;
							height: 100%;
						} */
            :hover {
              font-weight: 700;
              background: #f6f7fe;
              ::before {
                display: block;
              }
            }
          }
        }

        button {
        }
      }

      /* active css */
      &.active {
        .number {
          border-radius: 1.5rem 1.5rem 0 0;
        }
        .recommendationNumberBox {
          display: block;
        }
        /* .recommendationNumber{
					display: block;
				} */
      }
    }

    > button {
      font-size: 1.3rem;
      font-weight: 600;
      border-radius: 5rem;
      border: 0;
      color: #fff;
      background: #3c445e;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
      padding: 0.85rem 1rem;
      width: 10rem;
    }
  }

  .numberStatus {
    .status {
      display: none;
      font-size: 1.3rem;
      margin-left: 1.2rem;
      color: #6e6e71;
      font-weight: 500;
      &.active {
        display: flex;
      }
      svg {
        margin-right: 4px;
      }
    }
  }
`;

export const Provision = styled.div`
  h2 {
    color: #191919;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .selectGroup {
    li {
      position: relative;
      margin-bottom: 1rem;
      :last-child {
        margin-bottom: 0;
      }
    }

    /* 실제 체크박스 */
    input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    input[type='checkbox'] + label {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.8rem 1.2rem;
      color: #191919;
      border: 1px solid transparent;
      border-radius: 5px;
      background: rgba(248, 248, 248, 0.8);
      font-size: 1.3rem;
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      p {
        width: calc(100% - 3rem);
      }
      span.gray {
        margin-left: 2px;
        color: #6e6e71;
      }
    }

    input.check_all[type='checkbox'] + label {
      border: 0.5px solid ${(props) => props.theme.colors.main};
      font-weight: 600;
    }

    /* 가상 체크박스 */
    input[type='checkbox'] + label:before {
      content: '';
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M8.39634 13.9225L4.53906 10.0643L5.82452 8.77885L8.39634 11.3498L13.5382 6.20703L14.8245 7.49339L8.39634 13.9225Z' fill='%23D6D6DC'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 10C0 4.47727 4.47727 0 10 0C15.5227 0 20 4.47727 20 10C20 15.5227 15.5227 20 10 20C4.47727 20 0 15.5227 0 10ZM10 18.1818C8.92555 18.1818 7.86162 17.9702 6.86895 17.559C5.87629 17.1478 4.97433 16.5452 4.21458 15.7854C3.45483 15.0257 2.85216 14.1237 2.44099 13.131C2.02981 12.1384 1.81818 11.0745 1.81818 10C1.81818 8.92555 2.02981 7.86162 2.44099 6.86895C2.85216 5.87629 3.45483 4.97433 4.21458 4.21458C4.97433 3.45483 5.87629 2.85216 6.86895 2.44099C7.86162 2.02981 8.92555 1.81818 10 1.81818C12.17 1.81818 14.251 2.68019 15.7854 4.21458C17.3198 5.74897 18.1818 7.83005 18.1818 10C18.1818 12.17 17.3198 14.251 15.7854 15.7854C14.251 17.3198 12.17 18.1818 10 18.1818Z' fill='%23D6D6DC'/%3E%3C/svg%3E");
    }

    /* 체크됐을때 순간적인 css 스타일 */
    /* input[type="checkbox"] + label:active:before,
		input[type="checkbox"]:checked + label:active:before {} */

    input[type='checkbox']:checked + label:before {
      /* 체크박스를 체크했을때 */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M8.39634 13.9225L4.53906 10.0643L5.82452 8.77885L8.39634 11.3498L13.5382 6.20703L14.8245 7.49339L8.39634 13.9225Z' fill='%230D42E5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 10C0 4.47727 4.47727 0 10 0C15.5227 0 20 4.47727 20 10C20 15.5227 15.5227 20 10 20C4.47727 20 0 15.5227 0 10ZM10 18.1818C8.92555 18.1818 7.86162 17.9702 6.86895 17.559C5.87629 17.1478 4.97433 16.5452 4.21458 15.7854C3.45483 15.0257 2.85216 14.1237 2.44099 13.131C2.02981 12.1384 1.81818 11.0745 1.81818 10C1.81818 8.92555 2.02981 7.86162 2.44099 6.86895C2.85216 5.87629 3.45483 4.97433 4.21458 4.21458C4.97433 3.45483 5.87629 2.85216 6.86895 2.44099C7.86162 2.02981 8.92555 1.81818 10 1.81818C12.17 1.81818 14.251 2.68019 15.7854 4.21458C17.3198 5.74897 18.1818 7.83005 18.1818 10C18.1818 12.17 17.3198 14.251 15.7854 15.7854C14.251 17.3198 12.17 18.1818 10 18.1818Z' fill='%230D42E5'/%3E%3C/svg%3E");
    }
    input[type='checkbox']:checked + label,
    input[type='checkbox']:checked + label.active {
      /* background: #F6F7FE;
			border: 0.5px solid ${(props) => props.theme.colors.main};;
			font-weight: 600; */
    }
    input.check_all[type='checkbox']:checked + label {
      background: #f6f7fe;
    }
  }
`;

export const NiceButton = styled.button`
  width: 100%;
  padding: 1rem;
  height: 7rem;
  border-radius: 8rem;
  background: ${(props) => props.theme.colors.main};
  border: 0;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
`;

const Mobile015SignupComponent = ({ authorityDataRefetch, authorityDataState }: ISelectNumber) => {
  const {
    inputValue,
    handleInputChange,
    generateFullNumber,
    errorMessage,
    messageStyle,
    sug015NumListIsLoading,
    sug015NumList,
    onClickRecommendNumber,
    addHyphen,
    subscribe015,
    handleCheckboxChange,
    subscribe015Btn,
    handleAllCheckboxChange,
    sug015NumViewHandle,
    selectOpen,
    subscribe015BtnStyles,
    complete,
    completeHandle,
    pickNumber,
    corporation015S,
    onChange015Radio,
    checkedMessage,
    onClickCertify,
    subscribe015Handle,
  } = useCheck015Number();

  return (
    <SelectNumber>
      <SelectNumberWrap>
        {/* 가입완료 */}
        {complete && (
          <JoinCompletion
            authorityDataRefetch={authorityDataRefetch}
            authorityDataState={authorityDataState}
            pickNumber={pickNumber}
          />
        )}
        {/* 구독 안내 */}
        <SubscriptionGuide />

        {!subscribe015.encodeData && (
          <NiceButton
            onClick={() => {
              onClickCertify();
            }}
          >
            본인인증
          </NiceButton>
        )}

        {subscribe015.encodeData && (
          <div className="flexBox-js">
            <SelectGroup>
              <h2>번호 선택</h2>
              <div className="phoneGroup">
                {/* 추천번호 보기 클릭후 numberGroup에 active가 붙으면 펼쳐짐 */}
                <div className={selectOpen}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      generateFullNumber;
                    }}
                  >
                    <div className="number" style={messageStyle ? { borderColor: '' } : { borderColor: 'red' }}>
                      <p>015</p>
                      <span>-</span>
                      <input
                        type="text"
                        maxLength={corporation015S ? 3 : 4}
                        placeholder={corporation015S ? 'XXX' : '8504'}
                        value={inputValue.firstPart}
                        name="firstPart"
                        onChange={handleInputChange}
                      />
                      <span>-</span>
                      <input
                        type="text"
                        maxLength={4}
                        placeholder="XXXX"
                        value={inputValue.secondPart}
                        name="secondPart"
                        onChange={handleInputChange}
                      />
                      <button type="submit" onClick={() => generateFullNumber()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                          <path
                            d="M6.00369 1.50015C4.80948 1.50015 3.66418 1.97457 2.81975 2.81905C1.97532 3.66354 1.50092 4.8089 1.50092 6.00317C1.50092 7.19745 1.97532 8.34281 2.81975 9.18729C3.66418 10.0318 4.80948 10.5062 6.00369 10.5062C7.1979 10.5062 8.34319 10.0318 9.18762 9.18729C10.0321 8.34281 10.5065 7.19745 10.5065 6.00317C10.5065 4.8089 10.0321 3.66354 9.18762 2.81905C8.34319 1.97457 7.1979 1.50015 6.00369 1.50015ZM3.7085e-08 6.00317C0.000136829 5.04768 0.228304 4.10601 0.665538 3.25644C1.10277 2.40686 1.73645 1.67391 2.5139 1.11851C3.29135 0.563101 4.19012 0.201277 5.13552 0.0631071C6.08092 -0.0750631 7.04564 0.0144103 7.94951 0.324091C8.85338 0.633772 9.67029 1.15472 10.3324 1.84363C10.9944 2.53254 11.4825 3.36953 11.756 4.28503C12.0296 5.20052 12.0807 6.16809 11.9051 7.10732C11.7296 8.04655 11.3324 8.9303 10.7466 9.68515L14.7893 13.7281C14.926 13.8697 15.0017 14.0592 15 14.256C14.9983 14.4528 14.9193 14.641 14.7802 14.7802C14.6411 14.9193 14.4528 14.9983 14.2561 15C14.0593 15.0017 13.8697 14.926 13.7282 14.7893L9.68545 10.7464C8.798 11.4354 7.73502 11.8616 6.61739 11.9764C5.49976 12.0913 4.37233 11.8902 3.3633 11.3961C2.35427 10.9019 1.50414 10.1345 0.909578 9.18118C0.315013 8.22782 -0.000124817 7.12675 3.7085e-08 6.00317Z"
                            fill="#191919"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="recommendationNumberBox">
                      <ul className="recommendationNumber">
                        {selectOpen === 'numberGroup active' &&
                          sug015NumList &&
                          sug015NumList?.content.map((el: any) => (
                            <li key={el}>
                              <button
                                type="button"
                                id={el}
                                name="suggest"
                                value={el}
                                onClick={(event: any) => onClickRecommendNumber(event)}
                              >
                                {addHyphen(el)}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </form>
                </div>
                <button type="button" onClick={() => sug015NumViewHandle()}>
                  <p className="open">추천번호 보기</p>
                  <p className="close">추천번호 닫기</p>
                </button>
              </div>
              <div className="numberStatus">
                <p className="status active">
                  {errorMessage ? (
                    <span className={`${messageStyle ? 'green' : 'red'}`}>{errorMessage}</span>
                  ) : (
                    <span>희망하는 번호를 입력해주세요</span>
                  )}
                </p>
                {selectOpen === 'numberGroup active' && sug015NumListIsLoading && <Loader backgroundToggle />}
              </div>
            </SelectGroup>

            <Provision>
              <h2>이용약관</h2>
              <ul className="selectGroup">
                <li>
                  <input
                    id="check_all"
                    className="check_all"
                    type="checkbox"
                    name="allAgree"
                    onChange={handleAllCheckboxChange}
                  />
                  <label htmlFor="check_all">
                    <p>{SERVICE_NAME} 이용약관 전체 동의</p>
                  </label>
                </li>
                <li>
                  <input
                    id="check_use"
                    className="check"
                    type="checkbox"
                    name="certTerms1"
                    checked={subscribe015.certTerms1}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="check_use">
                    <p>
                      서울이동통신(주) 무선호출 이용약관 동의 <span className="gray">(필수)</span>
                    </p>
                  </label>
                </li>
                <li>
                  <input
                    id="check_personal"
                    className="check"
                    type="checkbox"
                    name="serviceTerms1"
                    checked={subscribe015.serviceTerms1}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="check_personal">
                    <p>
                      개인정보의 수집 애용 위탁 제공 및 활용 동의 <span className="gray">(필수)</span>
                    </p>
                  </label>
                </li>
              </ul>
            </Provision>
          </div>
        )}
      </SelectNumberWrap>

      {subscribe015.encodeData && (
        <SB.Btn100>
          <button
            className={errorMessage !== checkedMessage.success ? 'startBtn unactive' : subscribe015BtnStyles}
            onClick={() => subscribe015Handle()}
            disabled={errorMessage !== checkedMessage.success ? true : subscribe015Btn}
          >
            확인
          </button>
        </SB.Btn100>
      )}
    </SelectNumber>
  );
};

export default Mobile015SignupComponent;
