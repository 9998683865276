import { DefaultTheme } from 'styled-components';

/*

	// 약어정리
	btn = button
	sel = select
	clr = color

	// border-gradient 사용시 3가지 css 사용
	border: 10px solid transparent;
	[ 각도지정가능 ]
	border-image: linear-gradient(135deg, ${(props) => props.theme.colors.gray300Color});
	border-image-slice: 1;

  // GRAY SCALE (#FFF ~ #2F3233)

*/

// 테마에 관계없는 값

const fontSizeTheme = {
  title64: '6.4rem',
  title32: '3.2rem',
  title20: '2.0rem',
  title18: '1.8rem',
  title16: '1.6rem',
  title14: '1.4rem',
  content24: '2.4rem',
};

const lineHeightTheme = {
  title64: '6.4rem',
  title32: '3.2rem',
  title20: '2.0rem',
  title18: '1.8rem',
  title16: '1.6rem',
  title14: '1.4rem',
};

const fontWeightTheme = {
  bold: 700,
  medium: 500,
  regular: 400,
};

// ==============================
// 기본모드에 대한 테마
// ==============================
const colors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  ONLY_WHITE: '#FFFFFF',
  ONLY_BLACK: '#000000',
  GREEN: '#0BE606',
  BLUE: '#1C5CE6',
  RED: '#E61C34',
  YELLOW: '#E1B721',
  // Gray Scale
  GRAY_100: '#F6F8FA',
  GRAY_200: '#ECEFF2',
  GRAY_300: '#D1D5DA',
  GRAY_400: '#B3B9C0',
  GRAY_500: '#A0A6AD',
  GRAY_600: '#6D737A',
  GRAY_700: '#4A4F54',
  GRAY_800: '#313539',
  // Text Colors
  TXT_100: '#F4F4F4',
  TXT_200: '#A6A6A6',
  TXT_300: '#797979',
  TXT_400: '#4D4D4D',
  TXT_500: '#1F2328',
  // 반전 필터
  INVERT: 'invert(0)',
  // 테이블 배경
  BACKGROUND: '#FDFDFD',
  // 메인
  MAIN: '#366EFF',
  ACTIVEMAIN: '#1E5DFF',
  MOBILEMAIN: '#F8DF00',
  MOBILESUB: '#381B1C',
  // 알림
  NOTIFY: '#FF5733',
  // 채팅방
  CHATBACK: '#D4DDEC',
  CHATICONBACK: '#9BB2D9',
  // 서브
  SUB: '#EBF2FF',
  SUB1: '#85AFFF',
  // 활성, 비활성화 색
  ACTIVE: '#366EFF',
  HOVER: '#D1D5DA',
  DISABLE: '#7A7A7A',
  DEACTIVATION: '#C2C2C2',
  // Placeholder
  PLACEHOLDER: '#B0B0B0',
  // Button Colors
  BUTTON_ACTIVE_BG: '#366EFF',
  BUTTON_HOVER_BG: '#4A85FF',
  BUTTON_DISABLED_BG: '#B2B2B2',
  BUTTON_TEXT: '#FFFFFF',
  BUTTON_BORDER: '#366EFF',
  // 그레이 Button 색상
  GRAY_BUTTON: '#212224',
  GRAY_BUTTON2: '#64656d',
  GRAY_BUTTON_ACTIVE_BG: '#35383a',
  GRAY_BUTTON_HOVER_BG: '#D1D5DA',
  GRAY_BUTTON_DISABLED_BG: '#6D737A',
  GRAY_BUTTON_TEXT: '#F4F4F4',
  GRAY_BUTTON_BORDER: '#A0A6AD',
} as const;

const theme: DefaultTheme = {
  colors: {
    white: colors.WHITE,
    black: colors.BLACK,
    green: colors.GREEN,
    blue: colors.BLUE,
    red: colors.RED,
    yellow: colors.YELLOW,
    onlyWhite: colors.ONLY_WHITE,
    onlyBlack: colors.ONLY_BLACK,
    gray100: colors.GRAY_100,
    gray200: colors.GRAY_200,
    gray300: colors.GRAY_300,
    gray400: colors.GRAY_400,
    gray500: colors.GRAY_500,
    gray600: colors.GRAY_600,
    gray700: colors.GRAY_700,
    gray800: colors.GRAY_800,
    txt100: colors.TXT_100,
    txt200: colors.TXT_200,
    txt300: colors.TXT_300,
    txt400: colors.TXT_400,
    txt500: colors.TXT_500,
    invert: colors.INVERT,
    background: colors.BACKGROUND,
    main: colors.MAIN,
    activeMain: colors.ACTIVEMAIN,
    mobileMain: colors.MOBILEMAIN,
    mobileSub: colors.MOBILESUB,
    notify: colors.NOTIFY,
    chatBack: colors.CHATBACK,
    chatIconBack: colors.CHATICONBACK,
    sub: colors.SUB,
    sub1: colors.SUB1,
    active: colors.ACTIVE,
    hover: colors.HOVER,
    disable: colors.DISABLE,
    deactivation: colors.DEACTIVATION,
    placeholder: colors.PLACEHOLDER,
    buttonActiveBg: colors.BUTTON_ACTIVE_BG,
    buttonHoverBg: colors.BUTTON_HOVER_BG,
    buttonDisabledBg: colors.BUTTON_DISABLED_BG,
    buttonText: colors.BUTTON_TEXT,
    buttonBorder: colors.BUTTON_BORDER,
    grayButton: colors.GRAY_BUTTON,
    grayButton2: colors.GRAY_BUTTON2,
    grayButtonActiveBg: colors.GRAY_BUTTON_ACTIVE_BG,
    grayButtonHoverBg: colors.GRAY_BUTTON_HOVER_BG,
    grayButtonDisabledBg: colors.GRAY_BUTTON_DISABLED_BG,
    grayButtonText: colors.GRAY_BUTTON_TEXT,
    grayButtonBorder: colors.GRAY_BUTTON_BORDER,
  },
  fontSize: fontSizeTheme,
  lineHeight: lineHeightTheme,
  fontWeight: fontWeightTheme,
};

// ==============================
// 다크모드에 대한 테마
// ==============================
const darkcolors = {
  WHITE: '#000000',
  BLACK: '#FFFFFF',
  GREEN: '#49d885',
  BLUE: '#446cd1',
  RED: '#e05364',
  YELLOW: '#dbbe52',
  ONLY_WHITE: '#A4A9AE',
  ONLY_BLACK: '#0f0f0f',
  GRAY_100: '#24272C',
  GRAY_200: '#363C43',
  GRAY_300: '#4A5056',
  GRAY_400: '#5D6469',
  GRAY_500: '#757C82',
  GRAY_600: '#8D949A',
  GRAY_700: '#A4A9AE',
  GRAY_800: '#BDC3C7',
  TXT_100: '#F1F4F8',
  TXT_200: '#D1D7DC',
  TXT_300: '#B2B8BD',
  TXT_400: '#A0A6AD',
  TXT_500: '#9E9EA6',
  // 반전 필터
  INVERT: 'invert(1)',
  // 테이블 배경
  BACKGROUND: '#1C1F22',
  // 메인
  MAIN: '#6A8FD5',
  MOBILEMAIN: '#F8DF00',
  MOBILESUB: '#c7c1c1',
  // 알림
  NOTIFY: '#cf7a61',
  // 채팅방
  CHATBACK: '#131314',
  CHATICONBACK: '#4B5561',
  // 서브
  SUB: '#7286b4',
  SUB1: '#7176a1',
  // 활성, 비활성화 색
  ACTIVE: '#6A8FD5',
  ACTIVEMAIN: '#1E5DFF',
  HOVER: '#6f7377',
  DISABLE: '#A1A1A1',
  DEACTIVATION: '#C2C2C2',
  // ::placeholder
  PLACEHOLDER: '#9798a0',
  // Button Colors (버튼 색상)
  BUTTON_ACTIVE_BG: '#3D5B8C',
  BUTTON_HOVER_BG: '#2F3D4A',
  BUTTON_DISABLED_BG: '#6A6F77',
  BUTTON_TEXT: '#FFFFFF',
  BUTTON_BORDER: '#6A8FD5',
  // 그레이 Button 색상
  GRAY_BUTTON: '#2A2F36',
  GRAY_BUTTON2: '#4f555a',
  GRAY_BUTTON_ACTIVE_BG: '#5D6469',
  GRAY_BUTTON_HOVER_BG: '#4A5056',
  GRAY_BUTTON_DISABLED_BG: '#8D949A',
  GRAY_BUTTON_TEXT: '#F1F4F8',
  GRAY_BUTTON_BORDER: '#757C82',
} as const;

const darkTheme: DefaultTheme = {
  colors: {
    white: darkcolors.WHITE,
    black: darkcolors.BLACK,
    green: darkcolors.GREEN,
    blue: darkcolors.BLUE,
    red: darkcolors.RED,
    yellow: darkcolors.YELLOW,
    onlyWhite: darkcolors.ONLY_WHITE,
    onlyBlack: darkcolors.ONLY_BLACK,
    gray100: darkcolors.GRAY_100,
    gray200: darkcolors.GRAY_200,
    gray300: darkcolors.GRAY_300,
    gray400: darkcolors.GRAY_400,
    gray500: darkcolors.GRAY_500,
    gray600: darkcolors.GRAY_600,
    gray700: darkcolors.GRAY_700,
    gray800: darkcolors.GRAY_800,
    txt100: darkcolors.TXT_100,
    txt200: darkcolors.TXT_200,
    txt300: darkcolors.TXT_300,
    txt400: darkcolors.TXT_400,
    txt500: darkcolors.TXT_500,
    invert: darkcolors.INVERT,
    background: darkcolors.BACKGROUND,
    main: darkcolors.MAIN,
    activeMain: darkcolors.ACTIVEMAIN,
    mobileMain: darkcolors.MOBILEMAIN,
    mobileSub: darkcolors.MOBILESUB,
    notify: darkcolors.NOTIFY,
    chatBack: darkcolors.CHATBACK,
    chatIconBack: darkcolors.CHATICONBACK,
    sub: darkcolors.SUB,
    sub1: darkcolors.SUB1,
    active: darkcolors.ACTIVE,
    hover: darkcolors.HOVER,
    disable: darkcolors.DISABLE,
    deactivation: darkcolors.DEACTIVATION,
    placeholder: darkcolors.PLACEHOLDER,
    buttonActiveBg: darkcolors.BUTTON_ACTIVE_BG,
    buttonHoverBg: darkcolors.BUTTON_HOVER_BG,
    buttonDisabledBg: darkcolors.BUTTON_DISABLED_BG,
    buttonText: darkcolors.BUTTON_TEXT,
    buttonBorder: darkcolors.BUTTON_BORDER,
    grayButton: darkcolors.GRAY_BUTTON,
    grayButton2: darkcolors.GRAY_BUTTON2,
    grayButtonActiveBg: darkcolors.GRAY_BUTTON_ACTIVE_BG,
    grayButtonHoverBg: darkcolors.GRAY_BUTTON_HOVER_BG,
    grayButtonDisabledBg: darkcolors.GRAY_BUTTON_DISABLED_BG,
    grayButtonText: darkcolors.GRAY_BUTTON_TEXT,
    grayButtonBorder: darkcolors.GRAY_BUTTON_BORDER,
  },
  fontSize: fontSizeTheme,
  lineHeight: lineHeightTheme,
  fontWeight: fontWeightTheme,
};

export { theme, darkTheme };
