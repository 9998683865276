/* eslint-disable */
import { format, isValid, parse } from 'date-fns';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import { ko } from 'date-fns/locale/ko';

/**
 * ex) 2023-10-02T01:23:37.316Z => 20231002
 */
export const formatDateYYYYMMDD = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 2자리로 맞춘다
  const day = String(date.getDate()).padStart(2, '0'); // 날짜도 2자리로 맞춘다
  return `${year}${month}${day}`;
};

export const formatDateYYYYMMDDHHmmss = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 2자리로 맞춘다
  const day = String(date.getDate()).padStart(2, '0'); // 날짜도 2자리로 맞춘다
  const hour = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  const s = String(date.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}${hour}${min}${s}`;
};

export const getDateYYYYMMDDHHmm = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 2자리로 맞춘다
  const day = String(date.getDate()).padStart(2, '0'); // 날짜도 2자리로 맞춘다
  const hour = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  return `${year}${month}${day}${hour}${min}`;
};
// 20240321160658009574
export const getDateYYYYMMDDHHmmss = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 2자리로 맞춘다
  const day = String(date.getDate()).padStart(2, '0'); // 날짜도 2자리로 맞춘다
  const hour = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  const sec = String(date.getSeconds()).padStart(2, '0'); // 초도 2자리로 맞춘다
  return `${year}${month}${day}${hour}${min}${sec}`;
};

/**
 * Ant 날짜 라이브러리로 들어온 값 변환
 * ex) rangePickerDate => ['2023-10-02T02:07:19.762Z', '2023-10-28T02:07:19.762Z']
 */

export const formatDateRangePickerDate = (pickerDate: any) => {
  const dateArray = JSON.parse(JSON.stringify(pickerDate));
  return dateArray;
};

/**
 * ex ) 20230101 => 2023.01.01  // 202301010000 => 2023.01.01 00:00
 */
export const formatDateBase = (date: any): string => {
  const YYYY = date?.slice(0, 4);
  const MM = date?.slice(4, 6);
  const dd = date?.slice(6, 8);
  if (!YYYY) return '';
  if (date?.slice(8, 10)) {
    const HH = date?.slice(8, 10);
    const mm = date?.slice(10, 12);
    return `${YYYY}.${MM}.${dd} ${HH}:${mm}`;
  }
  return `${YYYY}.${MM}.${dd}`;
};

/**
 * ex ) 20240205120000 => 24-02-05
 */
export const formatDateDate = (date: any): string => {
  const YYYY = date?.slice(0, 4);
  const MM = date?.slice(4, 6);
  const dd = date?.slice(6, 8);
  if (!YYYY) return '';

  return `${YYYY}-${MM}-${dd}`;
};

/**
 * ex ) 20240205120000 => 24-02-05
 */
export const formatDateTime = (date: any): string => {
  const HH = date?.slice(8, 10);
  const mm = date?.slice(10, 12);
  const ss = date?.slice(12, 14);
  if (!HH) return '';

  return `${HH}:${mm}:${ss}`;
};

/**
 * @title 202401011200 날짜 스트링 데이터를 현재 날짜와 비교해서 시간의 차이 알려주는 Hook
 * */
export const timeAgo = (dateString: string) => {
  // 날자 문자열을 Date 객체로 변환.
  const date = parse(dateString, 'yyyyMMddHHmmss', new Date());
  //   현재 시간과의 차이를 계산하여 문자열로 변환.
  return formatDistanceToNow(date, { addSuffix: true, locale: ko });
};

/**
 * @title 202401011200 -> 오후 xx:xx
 * */
export const formatTime = (dateString: string) => {
  // 날짜 문자열을 Date 객체로 변환합니다. 'yyyyMMddHHmmss' 형식을 사용합니다.
  const date = parse(dateString, 'yyyyMMddHHmmss', new Date());
  if (!isValid(date)) {
    return '--:--'; // 날짜 포맷이 안되니 강제로 노출할 데이터
  }

  // 'a h:mm' 포맷으로 날짜를 변환합니다. 여기서 'a'는 AM/PM을 나타내고, 'h:mm'은 시간을 나타냅니다.
  return format(date, 'a h:mm', { locale: ko });
};

/**
 * @title '2024년 04월 3일 수요일' 형식의 문자열로 포맷
 * */
export const formatDate = (dateString: string) => {
  const date = parse(dateString, 'yyyyMMdd', new Date(), { locale: ko });

  // 데이터 포맷이 안되는 형식의 데이터가 넘어왔을때 타는 로직 ('yyyyMMdd' 이외의 형식)
  // if (!isValid(date)) {
  //   return '----년 --월 --일 ----'; // 날짜 포맷이 안되니 강제로 노출할 데이터
  // }

  // 유효하지 않은 날짜일 경우 현재 날짜를 사용
  const validDate = isValid(date) ? date : new Date();

  // Date 객체를 '2024년 04월 3일 수요일' 형식의 문자열로 포맷
  return format(validDate, 'yyyy년 MM월 dd일 EEEE', { locale: ko });
};

/**
 * @title 좌측 소프트폰 날짜 표출 공통 함수 ( 오늘, 어제, 올해, 이전 년도에 따라 표출 형식 처리 )
 * */
export const formatDateAll = (dateString: string) => {
  // "20240415110931" 형태의 문자열을 Date 객체로 변환
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1; // JS는 0부터 시작하는 월을 사용
  const day = parseInt(dateString.substring(6, 8), 10);
  const hour = parseInt(dateString.substring(8, 10), 10);
  const minute = parseInt(dateString.substring(10, 12), 10);

  const date = new Date(year, month, day, hour, minute);

  // 현재 날짜와 비교
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // 오늘 수신한 메세지
  if (date >= today) {
    const amPm = hour >= 12 ? '오후' : '오전';
    const formattedHour = hour > 12 ? hour - 12 : hour;
    return `${amPm} ${formattedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
  }
  // 어제 수신한 메세지
  else if (date >= yesterday && date < today) {
    return '어제';
  }
  // 올해 수신한 메세지
  else if (date.getFullYear() === now.getFullYear()) {
    return `${month + 1}월 ${day}일`;
  }
  // 이전 년도에 수신한 메세지
  else {
    return `${year}.${(month + 1).toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}`;
  }
};
