import * as S from 'src/pages/main/components/etcLeftZone/chatRoom015/ChatRoom015.styled';
import ChatRoom015Foot from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Foot/ChatRoom015Foot';
import React from 'react';
import { IEachChatRoom015 } from 'src/pages/main/components/etcLeftZone/chatRoom015/eachChatRoom015/EachChatRoom015.types';
import EachEtcChattingHead from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/eachChatRoom015Head/EachChatRoom015Head';
import EachChatRoom015Body from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Body/eachChatRoom015Body/EachChatRoom015Body';

const EachChatRoom015 = (props: IEachChatRoom015) => {
  return (
    <S.EtcChattingWrap>
      <S.EtcChattingContent>
        <EachEtcChattingHead />
        <EachChatRoom015Body chatRoomRef={props.chatRoomRef} />
      </S.EtcChattingContent>
      <ChatRoom015Foot chatRoomRef={props.chatRoomRef} chatFootRef={props.chatFootRef} />
    </S.EtcChattingWrap>
  );
};

export default EachChatRoom015;
