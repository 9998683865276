import React from 'react';
import * as S from './AddArsCard.styles';
import { TArsCard } from '@/components/say015/Modal/arsCards/ArsCardModal';

interface IAddArsCard {
  className?: string;
  setSelectAddCardS: React.Dispatch<React.SetStateAction<TArsCard | null>>;
  id: string | null;
  handleAddIdState: (isAddId: string | null) => void;
}

/**
 * @title ARS 추가 셀렉트 컴포넌트
 * */
const AddArsCard = (props: IAddArsCard) => {
  const handleSelect = (value: TArsCard | null) => {
    props.setSelectAddCardS(value);
  };

  return (
    <S.AddArsCardWrap className={`${props.className !== undefined ? `${props.className} addArsCard` : 'addArsCard'}`}>
      <button className="top" onClick={() => props.handleAddIdState(null)}>
        <p>ARS 추가하기</p>
      </button>
      {props.id && props.id.length < 5 && (
        <button onClick={() => handleSelect('안내멘트')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path
              d="M20.0936 1.9299C20.2002 1.75227 20.373 1.62427 20.5741 1.57407C20.7751 1.52386 20.9878 1.55556 21.1654 1.66219L21.1686 1.66427L21.1717 1.66636L21.1821 1.67261C21.2228 1.69727 21.2624 1.72369 21.3009 1.75177C21.3738 1.80281 21.4727 1.87677 21.5894 1.97261C21.8206 2.16531 22.1227 2.45386 22.4248 2.84552C23.0311 3.63406 23.6279 4.83927 23.6279 6.49865C23.6279 8.15802 23.0311 9.36323 22.4248 10.1518C22.1227 10.5434 21.8206 10.832 21.5894 11.0247C21.4599 11.1325 21.3243 11.2327 21.1831 11.3247L21.1727 11.3309L21.1686 11.333L21.1665 11.3341C21.1665 11.3341 21.1654 11.3351 20.7634 10.6653L21.1654 11.3351C20.9885 11.4355 20.7794 11.463 20.5825 11.4116C20.3857 11.3602 20.2166 11.2341 20.1113 11.06C20.0061 10.8859 19.9728 10.6776 20.0187 10.4794C20.0646 10.2812 20.186 10.1087 20.3571 9.99865L20.3623 9.99448L20.404 9.96636C20.4456 9.93719 20.5092 9.89031 20.5884 9.82469C20.7477 9.69136 20.9665 9.48511 21.1852 9.19969C21.6206 8.63406 22.0654 7.75594 22.0654 6.49865C22.0654 5.24136 21.6206 4.36323 21.1863 3.79761C21.0104 3.56808 20.8099 3.3585 20.5884 3.17261C20.5159 3.11221 20.4405 3.05554 20.3623 3.00281L20.3571 2.99865C20.181 2.89134 20.0545 2.71877 20.0051 2.51857C19.9558 2.31837 19.9876 2.10676 20.0936 1.9299Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.3883 1.55078C10.0761 1.55078 8.81756 2.07208 7.88964 2.99999C6.96173 3.92791 6.44043 5.18643 6.44043 6.4987C6.44043 7.81097 6.96173 9.06949 7.88964 9.9974C8.81756 10.9253 10.0761 11.4466 11.3883 11.4466C12.7006 11.4466 13.9591 10.9253 14.8871 9.9974C15.815 9.06949 16.3363 7.81097 16.3363 6.4987C16.3363 5.18643 15.815 3.92791 14.8871 2.99999C13.9591 2.07208 12.7006 1.55078 11.3883 1.55078ZM8.00293 6.4987C8.00293 5.60083 8.35961 4.73974 8.9945 4.10485C9.62938 3.46996 10.4905 3.11328 11.3883 3.11328C12.2862 3.11328 13.1473 3.46996 13.7822 4.10485C14.4171 4.73974 14.7738 5.60083 14.7738 6.4987C14.7738 7.39657 14.4171 8.25766 13.7822 8.89255C13.1473 9.52744 12.2862 9.88411 11.3883 9.88411C10.4905 9.88411 9.62938 9.52744 8.9945 8.89255C8.35961 8.25766 8.00293 7.39657 8.00293 6.4987ZM11.3883 13.0091C9.26335 13.0091 7.30293 13.4987 5.8446 14.332C4.40918 15.1529 3.31543 16.4049 3.31543 17.957C3.31543 19.5091 4.40918 20.7612 5.8446 21.582C7.30293 22.4154 9.26439 22.9049 11.3883 22.9049C13.5133 22.9049 15.4738 22.4154 16.9321 21.582C18.3675 20.7612 19.4613 19.5091 19.4613 17.957C19.4613 16.4049 18.3675 15.1529 16.9321 14.332C15.4738 13.4987 13.5123 13.0091 11.3883 13.0091ZM4.87793 17.957C4.87793 17.207 5.41647 16.3768 6.6196 15.6893C7.80085 15.0143 9.48522 14.5716 11.3883 14.5716C13.2915 14.5716 14.9758 15.0143 16.1571 15.6893C17.3602 16.3768 17.8988 17.2081 17.8988 17.957C17.8988 18.707 17.3602 19.5372 16.1571 20.2247C14.9758 20.8997 13.2915 21.3424 11.3883 21.3424C9.48522 21.3424 7.80085 20.8997 6.6196 20.2247C5.41647 19.5372 4.87793 18.707 4.87793 17.957Z"
              fill="currentColor"
            />
            <path
              d="M19.0821 3.74468C18.9941 3.69172 18.8965 3.65663 18.7949 3.64142C18.6933 3.62622 18.5898 3.63119 18.4901 3.65605C18.3905 3.68092 18.2967 3.72519 18.2142 3.78633C18.1316 3.84748 18.062 3.92429 18.0092 4.01239L18.2696 5.07905L18.2769 5.08426C18.3918 5.17068 18.4945 5.2723 18.5821 5.38635C18.8238 5.70566 18.9501 6.09746 18.9404 6.4978C18.9404 7.02593 18.756 7.38322 18.5821 7.6103C18.4945 7.72435 18.3918 7.82597 18.2769 7.91239L18.2685 7.91655C18.0953 8.02606 17.9721 8.19924 17.9254 8.39876C17.8786 8.59828 17.9122 8.80818 18.0188 8.9832C18.1253 9.15823 18.2964 9.28437 18.4951 9.33445C18.6938 9.38453 18.9042 9.35454 19.081 9.25093H19.0831L19.0852 9.24885L19.0883 9.2478L19.0956 9.2426L19.1165 9.2301C19.1958 9.17936 19.2713 9.12297 19.3425 9.06135C19.4769 8.94989 19.6488 8.7853 19.8206 8.56239C20.2724 7.9698 20.5127 7.24292 20.5029 6.4978C20.5127 5.75269 20.2724 5.0258 19.8206 4.43322C19.6359 4.19353 19.4177 3.98164 19.1727 3.80405C19.1542 3.79088 19.1354 3.77803 19.1165 3.76551L19.0956 3.75301L19.0883 3.7478L19.0852 3.74676L19.0831 3.74572C19.0831 3.74572 19.0821 3.74468 18.68 4.41447L19.0821 3.74468Z"
              fill="currentColor"
            />
          </svg>
          <p>안내멘트 재생</p>
        </button>
      )}

      <button onClick={() => handleSelect('문자발송')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
          <path
            d="M11.281 2.04428C10.1875 1.99443 9.09216 2.00548 7.99993 2.07736C4.25935 2.3259 1.28048 5.34679 1.03552 9.14011C0.98816 9.89034 0.98816 10.6428 1.03552 11.393C1.12492 12.7743 1.73554 14.0536 2.45522 15.1336C2.87273 15.8891 2.59737 16.8323 2.16198 17.6574C1.84908 18.252 1.69173 18.5488 1.81779 18.7633C1.94295 18.9779 2.22457 18.9851 2.7869 18.9985C3.89996 19.0253 4.65004 18.7106 5.24546 18.2716C5.5825 18.0222 5.75147 17.8979 5.8677 17.8836C5.98392 17.8693 6.21368 17.9641 6.67142 18.1518C7.08267 18.3217 7.56097 18.4263 7.99904 18.4558C9.27302 18.5398 10.6042 18.5398 11.8809 18.4558C15.6206 18.2073 18.5994 15.1855 18.8444 11.393C18.8629 11.0998 18.8743 10.8009 18.8784 10.5071M6.81089 12.7421H13.069M6.81089 8.27202H9.93995"
            stroke="currentColor"
            strokeWidth="1.49282"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.5967 0.999995C20.5967 0.725174 20.3739 0.502387 20.0991 0.502387H15.6207C15.3458 0.502387 15.123 0.725174 15.123 0.999995C15.123 1.27482 15.3458 1.4976 15.6207 1.4976L19.6015 1.4976L19.6015 5.47846C19.6015 5.75329 19.8243 5.97607 20.0991 5.97607C20.3739 5.97607 20.5967 5.75329 20.5967 5.47846V0.999995ZM14.6932 7.10967L20.451 1.35186L19.7473 0.648133L13.9894 6.40595L14.6932 7.10967Z"
            fill="currentColor"
          />
        </svg>
        <p>문자 발송</p>
      </button>

      <button onClick={() => handleSelect('음성녹음 받기')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path
            d="M8.33338 24.9994C8.9063 24.9994 9.37505 24.5306 9.37505 23.9577C9.37505 23.3848 8.9063 22.916 8.33338 22.916C7.76046 22.916 7.29171 23.3848 7.29171 23.9577C7.29171 24.5306 7.76046 24.9994 8.33338 24.9994ZM12.5 24.9994C13.073 24.9994 13.5417 24.5306 13.5417 23.9577C13.5417 23.3848 13.073 22.916 12.5 22.916C11.9271 22.916 11.4584 23.3848 11.4584 23.9577C11.4584 24.5306 11.9271 24.9994 12.5 24.9994ZM16.6667 24.9994C17.2396 24.9994 17.7084 24.5306 17.7084 23.9577C17.7084 23.3848 17.2396 22.916 16.6667 22.916C16.0938 22.916 15.625 23.3848 15.625 23.9577C15.625 24.5306 16.0938 24.9994 16.6667 24.9994ZM9.37505 10.416V4.16602C9.37505 2.43685 10.7709 1.04102 12.5 1.04102C14.2292 1.04102 15.625 2.43685 15.625 4.16602V10.416C15.625 12.1452 14.2292 13.541 12.5 13.541C10.7709 13.541 9.37505 12.1452 9.37505 10.416ZM18.6563 10.416C19.2917 10.416 19.7917 10.9785 19.698 11.6035C19.1875 14.7285 16.6875 17.166 13.5417 17.6243V19.791C13.5417 20.3639 13.073 20.8327 12.5 20.8327C11.9271 20.8327 11.4584 20.3639 11.4584 19.791V17.6243C8.31255 17.166 5.81255 14.7285 5.30213 11.6035C5.19796 10.9785 5.70838 10.416 6.3438 10.416C6.85421 10.416 7.2813 10.791 7.36463 11.3014C7.79171 13.7598 9.92713 15.6243 12.5 15.6243C15.073 15.6243 17.2084 13.7598 17.6355 11.3014C17.7188 10.791 18.1459 10.416 18.6563 10.416Z"
            fill="currentColor"
          />
        </svg>
        <p>음성 녹음 받기</p>
      </button>

      <button onClick={() => handleSelect('착신전환')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
          <path
            d="M16.292 9.49284L20.4587 5.32617M20.4587 5.32617V8.45117M20.4587 5.32617H17.3337"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.871 15.2714L16.3971 15.7714C16.3971 15.7714 15.2689 16.9579 12.1908 13.7172C9.11268 10.4766 10.2408 9.29015 10.2408 9.29015L10.5398 8.97453C11.2752 8.19953 11.345 6.95578 10.7023 6.04745L9.38976 4.19015C8.59393 3.06515 7.05747 2.91724 6.14601 3.87661L4.51163 5.5964C4.06059 6.07245 3.75851 6.68807 3.79497 7.37245C3.88872 9.12245 4.63663 12.886 8.80747 17.2787C13.2314 21.9349 17.3825 22.1204 19.0793 21.9527C19.6168 21.9006 20.0835 21.6099 20.4596 21.2141L21.9387 19.6558C22.9387 18.6037 22.6575 16.8016 21.3783 16.0662L19.3887 14.9204C18.5491 14.4381 17.5273 14.5797 16.871 15.2704"
            fill="currentColor"
          />
        </svg>
        <p>착신 전환</p>
      </button>
      {/* 트리 하위 5개이상 생성 방지 */}
      {/* {props.id && props.id.length < 5 && (
        <button onClick={() => handleSelect('텍스트재생')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
            <path
              d="M15.762 1.08863C15.8578 0.929055 16.013 0.814068 16.1936 0.768966C16.3742 0.723863 16.5653 0.752341 16.7249 0.848133L16.7277 0.850005L16.7305 0.851876L16.7399 0.857491C16.7764 0.87965 16.812 0.90338 16.8466 0.928612C16.9121 0.974467 17.001 1.04091 17.1058 1.127C17.3135 1.30013 17.5849 1.55934 17.8563 1.91121C18.4009 2.61961 18.9371 3.70234 18.9371 5.19308C18.9371 6.68381 18.4009 7.76654 17.8563 8.47494C17.5849 8.82681 17.3135 9.08603 17.1058 9.25915C16.9895 9.35601 16.8676 9.446 16.7408 9.52866L16.7315 9.53428L16.7277 9.53615L16.7258 9.53708C16.7258 9.53708 16.7249 9.53802 16.3637 8.9363L16.7249 9.53802C16.5659 9.62823 16.3781 9.65287 16.2012 9.60672C16.0244 9.56056 15.8725 9.44723 15.7779 9.29085C15.6833 9.13446 15.6535 8.94734 15.6947 8.76928C15.736 8.59123 15.845 8.43628 15.9987 8.33738L16.0034 8.33364L16.0408 8.30837C16.0783 8.28217 16.1353 8.24006 16.2065 8.1811C16.3496 8.06132 16.5462 7.87603 16.7427 7.61962C17.1338 7.11148 17.5334 6.32259 17.5334 5.19308C17.5334 4.06356 17.1338 3.27468 16.7436 2.76653C16.5856 2.56033 16.4055 2.37205 16.2065 2.20505C16.1414 2.1508 16.0736 2.09988 16.0034 2.05251L15.9987 2.04877C15.8405 1.95237 15.7269 1.79734 15.6825 1.61748C15.6382 1.43762 15.6667 1.24753 15.762 1.08863Z"
              fill="currentColor"
            />
            <path
              d="M14.8531 2.71964C14.774 2.67206 14.6863 2.64054 14.5951 2.62688C14.5038 2.61321 14.4108 2.61768 14.3213 2.64002C14.2317 2.66236 14.1475 2.70213 14.0733 2.75706C13.9992 2.81199 13.9366 2.881 13.8892 2.96014L14.1231 3.9184L14.1297 3.92308C14.2329 4.00072 14.3252 4.09201 14.4039 4.19447C14.621 4.48133 14.7345 4.83331 14.7258 5.19297C14.7258 5.66742 14.5602 5.98841 14.4039 6.19241C14.3252 6.29487 14.2329 6.38616 14.1297 6.46379L14.1222 6.46754C13.9666 6.56592 13.8559 6.72149 13.8139 6.90074C13.7719 7.07998 13.8021 7.26855 13.8978 7.42579C13.9935 7.58302 14.1472 7.69635 14.3257 7.74134C14.5043 7.78633 14.6933 7.75938 14.8521 7.6663H14.854L14.8559 7.66443L14.8587 7.6635L14.8652 7.65882L14.8839 7.64759C14.9552 7.60201 15.0231 7.55135 15.087 7.49599C15.2077 7.39586 15.3621 7.248 15.5165 7.04774C15.9224 6.51537 16.1382 5.86236 16.1295 5.19297C16.1382 4.52358 15.9224 3.87057 15.5165 3.33821C15.3506 3.12288 15.1546 2.93252 14.9345 2.77298C14.9178 2.76115 14.901 2.7496 14.8839 2.73835L14.8652 2.72712L14.8587 2.72245L14.8559 2.72151L14.854 2.72057C14.854 2.72057 14.8531 2.71964 14.4918 3.32136L14.8531 2.71964Z"
              fill="currentColor"
            />
            <path
              d="M11.3027 3.71045C10.2039 3.66035 9.10324 3.67145 8.0057 3.74369C4.2469 3.99343 1.25353 7.02903 1.00737 10.8408C0.959782 11.5947 0.959782 12.3508 1.00737 13.1047C1.09721 14.4927 1.7108 15.7783 2.43399 16.8635C2.85353 17.6226 2.57683 18.5704 2.13932 19.3996C1.82489 19.997 1.66678 20.2953 1.79345 20.5109C1.91922 20.7265 2.20221 20.7337 2.76728 20.7472C3.88576 20.7741 4.63949 20.4579 5.23781 20.0168C5.5765 19.7662 5.74629 19.6413 5.86308 19.6269C5.97987 19.6125 6.21075 19.7078 6.67071 19.8964C7.08397 20.0671 7.5646 20.1722 8.0048 20.2019C9.28498 20.2863 10.6227 20.2863 11.9055 20.2019C15.6634 19.9521 18.6568 16.9156 18.903 13.1047C18.9215 12.8101 18.933 12.5097 18.9371 12.2144M6.81086 14.4604H13.0995M6.81086 9.96851H9.95516"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>텍스트 재생</p>
        </button>
      )}
      {props.id && props.id.length < 5 && (
        <button onClick={() => handleSelect('녹음재생')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path
              d="M20.0936 1.9299C20.2002 1.75227 20.373 1.62427 20.5741 1.57407C20.7751 1.52386 20.9878 1.55556 21.1654 1.66219L21.1686 1.66427L21.1717 1.66636L21.1821 1.67261C21.2228 1.69727 21.2624 1.72369 21.3009 1.75177C21.3738 1.80281 21.4727 1.87677 21.5894 1.97261C21.8206 2.16531 22.1227 2.45386 22.4248 2.84552C23.0311 3.63406 23.6279 4.83927 23.6279 6.49865C23.6279 8.15802 23.0311 9.36323 22.4248 10.1518C22.1227 10.5434 21.8206 10.832 21.5894 11.0247C21.4599 11.1325 21.3243 11.2327 21.1831 11.3247L21.1727 11.3309L21.1686 11.333L21.1665 11.3341C21.1665 11.3341 21.1654 11.3351 20.7634 10.6653L21.1654 11.3351C20.9885 11.4355 20.7794 11.463 20.5825 11.4116C20.3857 11.3602 20.2166 11.2341 20.1113 11.06C20.0061 10.8859 19.9728 10.6776 20.0187 10.4794C20.0646 10.2812 20.186 10.1087 20.3571 9.99865L20.3623 9.99448L20.404 9.96636C20.4456 9.93719 20.5092 9.89031 20.5884 9.82469C20.7477 9.69136 20.9665 9.48511 21.1852 9.19969C21.6206 8.63406 22.0654 7.75594 22.0654 6.49865C22.0654 5.24136 21.6206 4.36323 21.1863 3.79761C21.0104 3.56808 20.8099 3.3585 20.5884 3.17261C20.5159 3.11221 20.4405 3.05554 20.3623 3.00281L20.3571 2.99865C20.181 2.89134 20.0545 2.71877 20.0051 2.51857C19.9558 2.31837 19.9876 2.10676 20.0936 1.9299Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.3883 1.55078C10.0761 1.55078 8.81756 2.07208 7.88964 2.99999C6.96173 3.92791 6.44043 5.18643 6.44043 6.4987C6.44043 7.81097 6.96173 9.06949 7.88964 9.9974C8.81756 10.9253 10.0761 11.4466 11.3883 11.4466C12.7006 11.4466 13.9591 10.9253 14.8871 9.9974C15.815 9.06949 16.3363 7.81097 16.3363 6.4987C16.3363 5.18643 15.815 3.92791 14.8871 2.99999C13.9591 2.07208 12.7006 1.55078 11.3883 1.55078ZM8.00293 6.4987C8.00293 5.60083 8.35961 4.73974 8.9945 4.10485C9.62938 3.46996 10.4905 3.11328 11.3883 3.11328C12.2862 3.11328 13.1473 3.46996 13.7822 4.10485C14.4171 4.73974 14.7738 5.60083 14.7738 6.4987C14.7738 7.39657 14.4171 8.25766 13.7822 8.89255C13.1473 9.52744 12.2862 9.88411 11.3883 9.88411C10.4905 9.88411 9.62938 9.52744 8.9945 8.89255C8.35961 8.25766 8.00293 7.39657 8.00293 6.4987ZM11.3883 13.0091C9.26335 13.0091 7.30293 13.4987 5.8446 14.332C4.40918 15.1529 3.31543 16.4049 3.31543 17.957C3.31543 19.5091 4.40918 20.7612 5.8446 21.582C7.30293 22.4154 9.26439 22.9049 11.3883 22.9049C13.5133 22.9049 15.4738 22.4154 16.9321 21.582C18.3675 20.7612 19.4613 19.5091 19.4613 17.957C19.4613 16.4049 18.3675 15.1529 16.9321 14.332C15.4738 13.4987 13.5123 13.0091 11.3883 13.0091ZM4.87793 17.957C4.87793 17.207 5.41647 16.3768 6.6196 15.6893C7.80085 15.0143 9.48522 14.5716 11.3883 14.5716C13.2915 14.5716 14.9758 15.0143 16.1571 15.6893C17.3602 16.3768 17.8988 17.2081 17.8988 17.957C17.8988 18.707 17.3602 19.5372 16.1571 20.2247C14.9758 20.8997 13.2915 21.3424 11.3883 21.3424C9.48522 21.3424 7.80085 20.8997 6.6196 20.2247C5.41647 19.5372 4.87793 18.707 4.87793 17.957Z"
              fill="currentColor"
            />
            <path
              d="M19.0821 3.74468C18.9941 3.69172 18.8965 3.65663 18.7949 3.64142C18.6933 3.62622 18.5898 3.63119 18.4901 3.65605C18.3905 3.68092 18.2967 3.72519 18.2142 3.78633C18.1316 3.84748 18.062 3.92429 18.0092 4.01239L18.2696 5.07905L18.2769 5.08426C18.3918 5.17068 18.4945 5.2723 18.5821 5.38635C18.8238 5.70566 18.9501 6.09746 18.9404 6.4978C18.9404 7.02593 18.756 7.38322 18.5821 7.6103C18.4945 7.72435 18.3918 7.82597 18.2769 7.91239L18.2685 7.91655C18.0953 8.02606 17.9721 8.19924 17.9254 8.39876C17.8786 8.59828 17.9122 8.80818 18.0188 8.9832C18.1253 9.15823 18.2964 9.28437 18.4951 9.33445C18.6938 9.38453 18.9042 9.35454 19.081 9.25093H19.0831L19.0852 9.24885L19.0883 9.2478L19.0956 9.2426L19.1165 9.2301C19.1958 9.17936 19.2713 9.12297 19.3425 9.06135C19.4769 8.94989 19.6488 8.7853 19.8206 8.56239C20.2724 7.9698 20.5127 7.24292 20.5029 6.4978C20.5127 5.75269 20.2724 5.0258 19.8206 4.43322C19.6359 4.19353 19.4177 3.98164 19.1727 3.80405C19.1542 3.79088 19.1354 3.77803 19.1165 3.76551L19.0956 3.75301L19.0883 3.7478L19.0852 3.74676L19.0831 3.74572C19.0831 3.74572 19.0821 3.74468 18.68 4.41447L19.0821 3.74468Z"
              fill="currentColor"
            />
          </svg>
          <p>녹음 재생</p>
        </button>
      )} */}
    </S.AddArsCardWrap>
  );
};
export default AddArsCard;
