import React from 'react';

import * as S from './MobileSignUp.style';
import SignUpProcess from '@/components/common/SignUpLayout/SignUpProcess';
import Loader from '@/components/common/Loader';
import BaseCheckBox from '@/components/Atom/BaseCheckBox';
import { AgreementProps } from '@/apis/utils/typeGuard/signUpGuard';
import Agreement from '@/components/Molecules/SignUp/Agreement';
import BaseButton from '@/components/Atom/BaseButton';
import { useMobileSignUp } from './useMobileSignUp';

const MobileSignUp = () => {
  const process: string = '1';

  const {
    onClickCertify,
    checkItemHandler,
    isAble,
    toggleAllCheckedById,
    checkSize,
    checkItems,
    signUpData,
    signUpLoading,
  } = useMobileSignUp();

  return (
    <S.SignUpContainer>
      {signUpLoading ? (
        <S.SignUpFormWrapper>
          <SignUpProcess process={process} />
          <div style={{ height: '100vw' }}>
            <Loader backgroundToggle />
          </div>
        </S.SignUpFormWrapper>
      ) : (
        <S.SignUpFormWrapper>
          <SignUpProcess process={process} />
          <S.TermsHeading>
            서울이동통신은 원활한 서비스 이용과 익명사용자로 인한 명예 훼손 등의 피해를 방지하기 위해 안심인증과
            아이핀(i-PIN) 인증을 통한 가입을 시행하고 있습니다. 입력하신 소중한 개인정보는 회원님의 동의없이 공개되거나
            제3자에게 제공되지 않으며, 개인정보 취급방침에 따라 보호하고 있습니다.
          </S.TermsHeading>
          <S.TermsSubHeading>
            14세 이상만 회원가입이 가능합니다. 타인의 개인정보를 부정 사용하는 자는 3년 이하의 징역 또는 1천만원 이하의
            벌금이 부과될 수 있습니다.
          </S.TermsSubHeading>
          <S.TermLine />

          <BaseCheckBox label="전체 동의" onChange={toggleAllCheckedById} checked={checkSize === 4} />
          <S.AgreementWrap>
            {signUpData.slice(0, 4).map((element: AgreementProps) => {
              return (
                <Agreement
                  key={element.id} // 각 요소에 고유한 키를 제공해야 합니다.
                  checkItemHandler={() => checkItemHandler(element.id)}
                  element={element}
                  checked={checkItems}
                />
              );
            })}
          </S.AgreementWrap>
          <S.AgreementButtonWrap>
            <BaseButton onClick={onClickCertify} width="13.8rem" height="3.9rem" disabled={isAble} fontWeight="bold">
              본인 인증
            </BaseButton>
          </S.AgreementButtonWrap>
        </S.SignUpFormWrapper>
      )}
    </S.SignUpContainer>
  );
};
export default MobileSignUp;
