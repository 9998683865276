import { getMyPageChangePhone } from '@/apis/api/myPageApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useNiceCertification } from '@/shared/components/niceCertification/hooks/useNiceCertification';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const usePhoneNumberChange = () => {
  const [popupData, setPopupData] = useState(null);
  const { niceCertification } = useNiceCertification();
  const { warningModal, successModal } = useModalHook();

  const { mutate } = useMutation(getMyPageChangePhone, {
    onSuccess: (response) => {
      if (response === 201) {
        warningModal('경고', '현재계정의 명의와 일치하지않습니다.', true);
      } else if (response === 202) {
        warningModal('경고', '현재번호와 동일합니다.', true);
      } else if (response === 200) {
        successModal('휴대폰번호 변경', '휴대폰번호 변경 성공했습니다.', true);
      }
    },
    onError: (res) => {
      console.error('번호변경 실패', res);
    },
  });

  const popupFunc = async () => {
    await niceCertification();
  };

  const translateNumber = async () => {
    try {
      if (popupData !== null) {
        const encData = {
          encodeData: popupData,
        };
        mutate(encData);
        return null;
      }
    } catch (error) {
      return error;
    }
    return null;
  };

  const location = useLocation();
  const sEncodeData = location.state?.sEncodeData;

  useEffect(() => {
    if (sEncodeData) {
      setPopupData(sEncodeData);
    }
  }, [sEncodeData]);

  // 팝업에서 보내온 메시지를 받는 이벤트 리스너
  const messageListener = (event: any) => {
    if (event.data.sEncodeData) {
      setPopupData(event.data.sEncodeData);
    }
  };

  useEffect(() => {
    // message 이벤트 리스너를 등록
    window.addEventListener('message', messageListener);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  useEffect(() => {
    if (popupData) {
      translateNumber();
    }
  }, [popupData]);

  return { popupFunc };
};
