import { calScrollUpEvent } from '@/apis/hooks/useScrollInfinity';
import { useSearchMultiChatroomData } from '@/components/mobileApp/mobileHooks/useSearchMultiChatroomData';
import MobileLoader from '@/components/mobileApp/MobileLoader';
import { resultDataRecoil, resultOpenRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { formatDate, formatTime } from '@/shared/util/format/dateFormatUtil';
import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as S from './MultiChatList.style';
import * as SS from 'src/pages/main/components/etcLeftZone/chatRoom015/ChatRoom015.styled';
import * as SSS from 'src/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Head/ChatRoom015Head.styled';
import { removeWebCharacter, underLine } from '@/shared/util/format/contentFormatUtil';
import MobileMessageChatRoomFooter from '@/components/mobileApp/mobileMessage/mobileMessageChatRoom/MobileMessageChatRoomFooter';
import { chatUserInfoRecoil } from '@/recoil/atoms/chatRoom';
import { useMultiChatList } from './useMultiChatList';
import ChatRoom015Foot from '@/pages/main/components/etcLeftZone/chatRoom015/chatRoom015Foot/ChatRoom015Foot';
import { useEachChatRoomHook } from '@/components/hooks/customs/useEachChatRoomHook';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import MultiChatResult from '@/components/mobileApp/mobileLayout/MultiChatResult';

interface Content {
  prepayPayNo: string;
  subject: string;
  sndMsg: string;
  sndDttm: string;
  callback: string;
  msgGb: string;
  imgData: string | null;
  fileData: string | null;
}

const MultiChatList = () => {
  const location = useLocation();
  const chatRoomRef = useRef<HTMLDivElement>(null);
  const chatFootRef = useRef<HTMLDivElement>(null);
  const [multiChat, setMultiChat] = useState<Content[]>([]);
  /* 기존 스크롤 높이 유지 */
  const [previousHeight, setPreviousHeight] = useState<number>(0);

  // 전송결과창 오픈
  const [resultOpenS, setResultOpenS] = useRecoilState(resultOpenRecoil);
  // 전송결과 메세지 데이터
  const setResultDataS = useSetRecoilState(resultDataRecoil);
  /* 활성화 채팅방의 상대방 정보 */
  const [chatUserInfo, setChatUserInfo] = useRecoilState(chatUserInfoRecoil);

  const chatHooks = useMultiChatList();
  const eachHooks = useEachChatRoomHook();

  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      calScrollUpEvent({ scrollTop, scrollHeight, clientHeight }) &&
      chatHooks.hasNextPage &&
      !chatHooks.isFetchingNextPage
    ) {
      setPreviousHeight(chatRoomRef.current?.scrollHeight as number);
      chatHooks.fetchNextPage();
    }
  };

  // 데이터 업데이트 시 multiChat을 설정
  useEffect(() => {
    setMultiChat((chatHooks.data && chatHooks.data.pages.flatMap((page) => page.pages.content).reverse()) || []);
  }, [chatHooks.data, location.pathname]);

  useEffect(() => {
    if (chatHooks.data) {
      const isInitialLoad = chatHooks.data.pages.length === 1;
      const isScrollLoad = chatHooks.data.pages.length > 1;

      setTimeout(() => {
        if (chatRoomRef.current) {
          // 처음 들어왔을 때 하단으로 내리기
          if (isInitialLoad) {
            chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
            // 인피니티 스크롤로 상단 찍었을 때 받아온 데이터 높이값 - 이전 데이터 높이값
          } else if (isScrollLoad) {
            chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight - previousHeight;
          }
        }
      }, 100);
    }
  }, [chatHooks.data, chatHooks.isFetchingNextPage]);

  const onClickMessageList = (message: Content) => {
    setResultOpenS(true);
    setResultDataS(message);
    // 현재 페이지의 해시를 '#result'로 설정
    // window.location.hash = '#result';
  };

  return (
    <SS.EtcChattingWrap>
      {resultOpenS && <MultiChatResult />}
      <MobileLoader isLoading={chatHooks.isLoading} />
      <SSS.EtcChattingHead>
        <SSS.EtcChattingHeadReg>
          <SSS.EtcChattingHeadRegLeftWrap>
            <SSS.EtcChattingHideBtn
              type="button"
              onClick={() => {
                if (chatUserInfo.phoneNumber !== null)
                  eachHooks.clickEachListH({
                    buddyName: chatUserInfo.buddyName,
                    phoneNumber: chatUserInfo.phoneNumber,
                    batch: false,
                  });
              }}
            >
              <IconArrowLongLeft />
            </SSS.EtcChattingHideBtn>
            <SSS.RegContactInfoWrap>
              <SSS.RegContactName>{chatUserInfo.buddyName}</SSS.RegContactName>
            </SSS.RegContactInfoWrap>
          </SSS.EtcChattingHeadRegLeftWrap>
        </SSS.EtcChattingHeadReg>
      </SSS.EtcChattingHead>
      <S.ChatroomContainer className="mobileMessageChatRoom" ref={chatRoomRef} onScroll={handleScroll}>
        {chatHooks.isFetchingNextPage && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.6rem',
              padding: '2rem 0',
            }}
          >
            <Spin size="default" />
            <span style={{ marginLeft: 8 }}>로딩 중...</span>
          </div>
        )}

        {!chatHooks.isLoading &&
          multiChat &&
          multiChat.length > 0 &&
          multiChat.map((message, index) => {
            const currentDate = message.sndDttm.slice(0, 8); // 현재 메시지의 날짜 (YYYYMMDD)
            const prevDate = index > 0 ? multiChat[index - 1].sndDttm.slice(0, 8) : null; // 이전 메시지의 날짜

            const shouldShowDate = currentDate !== prevDate; // 날짜가 바뀌는 경우만 true
            return (
              <S.MessageItem key={`${message.prepayPayNo}-${message.sndDttm}`}>
                {/* 날짜 헤더는 날짜가 바뀌는 경우에만 렌더링 */}
                {shouldShowDate && (
                  <S.EtcChattingDateText>
                    <span>{formatDate(currentDate)} </span>
                  </S.EtcChattingDateText>
                )}
                <S.EtcChattingMyZone>
                  <S.EtcChattingContentWrap>
                    <S.EtcChattingHourText>
                      <S.EtcChattingHourText>
                        {/* <div>{message.}</div> */}
                        <div>{`결과 보기👉`}</div>
                        <div>{formatTime(message.sndDttm)}</div>
                      </S.EtcChattingHourText>
                    </S.EtcChattingHourText>
                    <S.EtcChattingMyTextBox className="chattingMyTextBox" onClick={() => onClickMessageList(message)}>
                      {message.imgData && (
                        <img
                          src={`data:image/jpeg;base64,${message.imgData}`}
                          alt="Message"
                          style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
                        />
                      )}
                      <p>{underLine(removeWebCharacter(message.sndMsg, '[Web발신]'))}</p>
                    </S.EtcChattingMyTextBox>
                  </S.EtcChattingContentWrap>
                </S.EtcChattingMyZone>
              </S.MessageItem>
            );
          })}
      </S.ChatroomContainer>
      <S.ChatroomChatting>
        <ChatRoom015Foot chatRoomRef={chatRoomRef} chatFootRef={chatFootRef} />
      </S.ChatroomChatting>
    </SS.EtcChattingWrap>
  );
};
export default MultiChatList;
