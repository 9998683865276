import React, { useEffect, useState } from 'react';
import * as S from './ChatroomMenu.style';
import IconArrowLongLeft from '@/styles/icons/IconArrowLongLeft';
import { useSetRecoilState } from 'recoil';
import { useGetChatroomMember } from '../mobileHooks/useGetChatroomMember';
import IconChatUser from '@/styles/icons/IconChatUser';
import { TelHyphen } from '@/shared/util/format/phoneNumberFormatUtil';
import MobileLoader from '../MobileLoader';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';

interface ChatRoomMenuProps {
  chatRoomId: string;
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatroomMenu = ({ chatRoomId, menuOpen, setMenuOpen }: ChatRoomMenuProps) => {
  // const [searchValue, setSearchValue] = useState<string>('');
  // const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>(searchValue);
  const location = useLocation();

  //훅
  const getChatroomMemberHook = useGetChatroomMember({ chatRoomId });

  // 디바운스를 위해 searchValue가 변경될 때마다 0.4초 후 debouncedSearchValue를 업데이트
  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedSearchValue(searchValue);
  //   }, 400);

  //   // cleanup: 타이머 초기화
  //   return () => clearTimeout(handler);
  // }, [searchValue]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      if (
        getChatroomMemberHook.hasNextPage &&
        !getChatroomMemberHook.isFetchingNextPage &&
        !getChatroomMemberHook.isLoading
      ) {
        getChatroomMemberHook.fetchNextPage();
      }
    }
  };

  //  <S.SearchContainer className="messageAddSearch">
  //     <MobileLoader isLoading={getChatroomMemberHook.isLoading} />
  //     <S.SearchInput
  //       type="text"
  //       placeholder="이름 및 번호 검색"
  //       value={searchValue}
  //       onChange={(e) => setSearchValue(e.target.value)}
  //     />
  //   </S.SearchContainer>

  const onClickChatroomMenuOpen = () => {
    // 모달창 닫기
    setMenuOpen(false);
    // 브라우저 뒤로가기
    window.history.back();
  };

  //뒤로가기 눌렀을 시 실행될 로직
  useEffect(() => {
    if (window.location.hash !== '#chatroomMenu') {
      // 모달창 닫기
      setMenuOpen(false);
    }
  }, [location.hash]);

  return (
    <>
      <MobileLoader isLoading={getChatroomMemberHook.isLoading} />
      <S.ResultContainer onScroll={handleScroll}>
        <S.ResultHeader>
          <S.ResultHeaderLeft
            onClick={() => {
              onClickChatroomMenuOpen();
            }}
          >
            <IconArrowLongLeft />
            <div>채팅 상대({getChatroomMemberHook.data && getChatroomMemberHook.data?.pages[0].totalElements})</div>
          </S.ResultHeaderLeft>
        </S.ResultHeader>

        <S.ResultMain>
          {!getChatroomMemberHook.isLoading &&
            getChatroomMemberHook.formatData &&
            getChatroomMemberHook.formatData.length > 0 &&
            getChatroomMemberHook.formatData.map((message, index) => {
              return (
                <S.ListContainer key={message.rcvPhnId}>
                  <S.BuddyImg>
                    <IconChatUser />
                  </S.BuddyImg>
                  <S.BuddyInfo>
                    <h3>{message.buddyNm}</h3>
                    <div>{TelHyphen(message.rcvPhnId)}</div>
                  </S.BuddyInfo>
                </S.ListContainer>
              );
            })}
          {getChatroomMemberHook.isFetchingNextPage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.6rem',
                padding: '2rem 0',
              }}
            >
              <Spin size="default" />
              <span style={{ marginLeft: 8 }}>로딩 중...</span>
            </div>
          )}
        </S.ResultMain>
      </S.ResultContainer>
    </>
  );
};

export default ChatroomMenu;
