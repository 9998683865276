import React from 'react';
import { useDeleteBin } from '../../../../../components/hooks/customs/addressBook/recycleBin/useDeleteBin';
import { useRestoreBin } from '../../../../../components/hooks/customs/addressBook/recycleBin/useRestoreBin';
import * as S from './TrashBoxPopup.styles';
import { useGetBinList } from '../../../../../components/hooks/customs/addressBook/recycleBin/useGetBinList';
import { ITrashBoxPopup } from './TrashBoxPopup.types';
import TrashBoxHead from './trashBoxHead/TrashBoxHead';
import TrashBoxFunZone from './trashBoxFunZone/TrashBoxFunZone';
import TrashBoxTable from './trashBoxTable/TrashBoxTable';
import { useCheckBin } from '@/components/hooks/customs/addressBook/recycleBin/useCheckBin';
import { useRecoilValue } from 'recoil';
import { mobileNavRecoil } from '@/recoil/atoms/router/mainRouterRecoil';

/**
 * @title TrashBoxPopup.tsx 좌측 그룹 리스트 휴지통 컴포넌트
 *
 * @author 김남규
 * @since 2024.03.14
 * */
const TrashBoxPopup = (props: ITrashBoxPopup) => {
  /* Hooks */
  const getBinListHooks = useGetBinList({ recycleBinOpen: props.recycleBinOpen });
  const useRestoreBinHooks = useRestoreBin();
  const useDeleteBinHooks = useDeleteBin();
  const useCheckBinHooks = useCheckBin({
    recycleBinList: getBinListHooks.recycleBinList,
    setRestoreBinList: useRestoreBinHooks.setRestoreBinList,
    setDeleteBinList: useDeleteBinHooks.setDeleteBinList,
  });

  const mobileNavState = useRecoilValue(mobileNavRecoil);

  return (
    <S.TrashBoxWrap isActive={mobileNavState === '주소록'}>
      {/* 휴지통 뒷 배경 */}
      <S.TrashBoxPopupBeam onClick={props.recycleBinClickHandle} />
      <S.TrashBoxPopup>
        <S.TrashBoxPopupWrap>
          {/* 최상단 X 버튼 및 휴지통 개수 표출 컴포넌트 */}
          <TrashBoxHead groupList={props.groupList} recycleBinClickHandle={props.recycleBinClickHandle} />
          <S.TrashBoxBody>
            {/* 버튼 및 검색 컴포넌트 */}
            <TrashBoxFunZone
              recycleBinRefetch={getBinListHooks.recycleBinRefetch}
              restoreBinAllListOnClick={props.restoreBinAllListOnClick}
              binAllBtnState={props.binAllBtnState}
              binRestoreState={useRestoreBinHooks.binRestoreState}
              binDeleteState={useDeleteBinHooks.binDeleteState}
              binListRestoreHandle={useRestoreBinHooks.binListRestoreHandle}
              deleteBinAllListOnClick={props.deleteBinAllListOnClick}
              binListDeleteHandle={useDeleteBinHooks.binListDeleteHandle}
              checkedBinList={useCheckBinHooks.checkedBinList}
              binSuccessSearchS={getBinListHooks.binSuccessSearchS}
            />

            {/* 휴지통 리스트 테이블 컴포넌트 */}
            <TrashBoxTable
              handleScroll={getBinListHooks.handleScroll}
              recycleBinListLoading={getBinListHooks.recycleBinListLoading}
              recycleBinList={getBinListHooks.recycleBinList}
              toggleSelectAll={useCheckBinHooks.toggleSelectAll}
              binSelectAll={useCheckBinHooks.binSelectAll}
              checkedBinListHandle={useCheckBinHooks.checkedBinListHandle}
              checkedBinList={useCheckBinHooks.checkedBinList}
            />
          </S.TrashBoxBody>
        </S.TrashBoxPopupWrap>
      </S.TrashBoxPopup>
    </S.TrashBoxWrap>
  );
};

export default TrashBoxPopup;
