import styled from 'styled-components';

export const POBox015Wrap = styled.div`
  .messageSend {
    margin-bottom: 10px;
    /* .sendContentGroup {
      height: 103px;
    } */
  }
  .searchGroup {
    display: flex;
    justify-content: space-between;
    > div {
      justify-content: flex-end;
    }
  }
  .tabMenuGroup {
    pointer-events: none;
    button {
      cursor: unset;
    }
  }
`;

export const AddressSearch = styled.div`
  margin-bottom: 10px;
`;

export const POBox015 = styled.div`
  .TransferList {
    margin-bottom: 1rem;
    .groups {
      gap: 0.5rem;
      background: ${(props) => props.theme.colors.gray100};
      border: 2px solid ${(props) => props.theme.colors.gray100};
      :hover {
        background: ${(props) => props.theme.colors.gray100};
        border: 2px solid ${(props) => props.theme.colors.main};
        &.unread {
          border: 2px solid ${(props) => props.theme.colors.main};
        }
      }
      .checkButtonGroup {
        button {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 3px;
          background: ${(props) => props.theme.colors.gray200};
        }
      }
      .titleGroup {
        width: 100%;
        h3 {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.8rem;
          color: ${(props) => props.theme.colors.txt500};
          font-size: 1.5rem;
          font-weight: 700;
          margin-bottom: 0;
          span.round {
            content: '';
            display: none;
            width: 9px;
            height: 9px;
            border-radius: 50px;
            background: ${(props) => props.theme.colors.main};
          }
        }
        .titleBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1.2rem;

          .deleteButton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.7rem;
            height: 1.7rem;
            padding: 0;
            border-radius: 50px;
            background: rgba(248, 248, 248, 0.6);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
            :hover {
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3) inset;
            }
          }
        }

        .receivingWrap {
          width: 64%;
          .message {
            font-size: 1.3rem;
            color: ${(props) => props.theme.colors.txt500};
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .flexWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .dateGroup {
        .date {
          font-weight: 600;
          font-size: 1.1rem;
        }
      }
      &.unread {
        .titleGroup {
          h3 {
            .round {
              display: block;
            }
          }
        }
        .receivingWrap {
          .message {
            color: ${(props) => props.theme.colors.txt500};
          }
        }
      }
      &.active {
        border: 2px solid ${(props) => props.theme.colors.main};
        background: ${(props) => props.theme.colors.gray100};
        box-shadow: 5px 5px 10px 0px ${(props) => props.theme.colors.gray300};
      }
    }

    .groups.unread {
      background: ${(props) => props.theme.colors.gray100};
      border: 2px solid ${(props) => props.theme.colors.main};
      box-shadow: 5px 5px 10px 0px ${(props) => props.theme.colors.gray300};
    }
  }
`;

export const UnregisteredMember = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: calc(100% - 200px);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 270px, rgba(25, 25, 25, 0) 11.36%, #191919 100%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  border-radius: 0 0 2rem 2rem;

  .UnregisteredWrap {
    width: 100%;

    .signUpButton {
      margin: 0 auto 5vh;

      button {
        width: 90%;
        padding: 2.6rem 2rem;
        max-width: 274px;
        border-radius: 6rem;
        background: ${(props) => props.theme.colors.main};
        color: ${(props) => props.theme.colors.txt100};
        font-size: 1.6rem;
      }
    }

    .helpText {
      color: ${(props) => props.theme.colors.txt100};
      font-size: 1.2rem;
      margin-bottom: 2.8rem;
      font-weight: 600;
      line-height: normal;
    }
  }
  @media screen and (max-width: 768px) {
    border-radius: 0;
  }
`;
