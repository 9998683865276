import styled from 'styled-components';

export const SigninWrap = styled.div`
  position: relative;
  padding-top: 0 !important;
  background: url('/img/signin/bigbg.png') center / cover no-repeat;
  @media screen and (max-width: 768px) {
    background: ${(props) => props.theme.colors.white};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100dvh;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  max-width: 768px;
  margin: 0 auto;

  section {
    width: 100%;
    /* background-color: blue; */
  }
`;
/* Start Content Zone */

export const ContentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 70%;
  margin: 0 auto 2rem;
  h2 {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      max-width: 28rem;
    }
  }
`;
/* End Content Zone */
/* Start NoneSoftPhone Zone */
export const LoginWrap = styled.div`
  margin: 0 auto;
  width: 100%;
`;
export const LoginContentWrap = styled.div``;
export const ArreoLoginInfo = styled.div`
  > div {
    &:first-of-type {
      margin: 0 0 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    > div {
      &:first-of-type {
        margin: 0 0 2rem;
      }
    }
  }
`;
export const ArreoLoginInput = styled.div`
  position: relative;
  width: 100%;
  &.active {
    input:placeholder-shown {
      border-color: ${(props) => props.theme.colors.red};
    }
  }
  /* 자동완성 시 input 배경 색 변경 방지 */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${(props) => props.theme.colors.txt400};
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
    @media screen and (max-width: 768px) {
      -webkit-text-fill-color: ${(props) => props.theme.colors.txt400};
    }
  }
  input {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    height: 4.8rem;
    margin-top: 3rem;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: normal;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
    background: transparent;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    border-color: ${(props) => props.theme.colors.mobileSub};
    color: ${(props) => props.theme.colors.txt400};
    outline: none;

    &:placeholder-shown {
      border-color: ${(props) => props.theme.colors.gray500};
    }
    &:focus-visible {
      border-radius: 1rem;
      border-color: ${(props) => props.theme.colors.mobileSub};
    }
    &::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
    }
  }

  .inputLabel {
    opacity: 0;
    position: absolute;
    top: -1.2rem;
    left: 2rem;
    padding: 0.5rem 1rem;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.mobileSub};
    font-size: 1.4rem;
    transition: all 0.2s ease-in-out;
  }
  input + .inputLabel {
    opacity: 1;
  }
  input:placeholder-shown + .inputLabel {
    opacity: 0;
  }
`;

export const Viewable = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.2rem;
  .on {
    color: ${(props) => props.theme.colors.mobileSub};
  }
  .off {
    color: ${(props) => props.theme.colors.deactivation};
  }
`;

export const ArreoIdInputWrap = styled.div`
  position: relative;
  width: 100%;
`;
export const ArreoPasswordInputWrap = styled.div`
  position: relative;
  width: 100%;
  > div {
    &:first-child {
      margin-top: 46px;
    }
  }
`;
export const DataFunZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.6rem 1rem;
  a {
    position: relative;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(props) => props.theme.colors.txt200};
  }
  button {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;
export const AgreementButtonWrap = styled.div`
  margin: 4rem 0 1.8rem 0;

  button {
    width: 100%;
    border-radius: 100px;
    height: 5.6rem;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: normal;
    background: ${(props) => props.theme.colors.mobileMain};
    color: #1f2328;
    transition: none;
    :disabled {
      background: ${(props) => props.theme.colors.mobileSub};
      color: ${(props) => props.theme.colors.txt100};
    }
  }
`;

export const InputLabel = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.8rem;
  font-weight: 700;
  transform: translateX(0) translateY(-50%) scale(1);
  transition: 0.3s ease;
  background: transparent;
  padding: 0.2rem;
  color: ${(props) => props.theme.colors.deactivation};

  &.active {
    top: 0.25rem;
    transform: translateX(-15%) translateY(-50%) scale(0.7);
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.mobileSub};
  }
`;
