import * as yup from 'yup';

const useValidation = () => {
  const emailData = [
    { label: '선택', value: '선택' },
    { label: 'naver.com', value: 'naver.com' },
    { label: 'hanmail.net', value: 'hanmail.net' },
    { label: 'gmail.com', value: 'gmail.com' },
    { label: 'kakao.com', value: 'kakao.com' },
    { label: 'arreo.com', value: 'arreo.com' },
  ];
  // 이메일 앞부분 (로컬 파트) 검증 정규식
  const emailLocalPartRegex = /^[a-zA-Z0-9._%+-]+$/;
  // 이메일 뒷부분 (도메인 파트) 검증 정규식
  const emailDomainPartRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const schema = yup.object().shape({
    // 이름
    name: yup.string().required('반드시 입력해주세요.').max(10, '최대 10글자까지 입력 가능합니다.'),
    // 비밀번호
    password: yup
      .string()
      .required('반드시 입력해주세요.')
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d\S]{8,16}$/,
        '최소 8자, 최대 16자 이내로 입력해주세요 (문자, 숫자 조합)',
      )
      .min(8, '최소 8자, 최대 16자 이내로 입력해주세요 (문자, 숫자 조합)')
      .max(16, '최소 8자, 최대 16자 이내로 입력해주세요 (문자, 숫자 조합)'),
    // 비밀번호 확인
    isPassword: yup
      .string()
      .required('반드시 입력해주세요')
      .oneOf([yup.ref('password')], '비밀번호와 일치하지 않습니다.'),

    // 이메일
    selectEmailOne: yup
      .string()
      .required('반드시 입력해주세요.')
      .matches(emailLocalPartRegex, '올바른 이메일 형식이 아닙니다.'),
    selectEmailTwo: yup
      .string()
      .required('반드시 입력해주세요.')
      .matches(emailDomainPartRegex, '올바른 이메일 형식이 아닙니다.'),
    // .matches(
    //   /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/,
    //   '이메일 형식에 맞지 않습니다.',
    // )

    // 전화번호
    numOne: yup.string().required('반드시 입력해주세요.'),
    numTwo: yup.string().required('반드시 입력해주세요.'),
    numThree: yup.string().required('반드시 입력해주세요.'),

    // 015 번호체크
    // num015One: yup.string().required('015번호 체크 해주세요.(돋보기를 눌러주세요)'),
    // num015Two: yup.string().required('015번호 체크 해주세요.(돋보기를 눌러주세요)'),
    // num015Three: yup.string().required('015번호 체크 해주세요.(돋보기를 눌러주세요)'),

    smsRcvYn: yup.string<'Y' | 'N'>().required('반드시 입력해주세요.'),
    emailRcvYn: yup.string<'Y' | 'N'>().required('반드시 입력해주세요.'),
  });

  return { schema, emailData };
};

export default useValidation;
