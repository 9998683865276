/* eslint-disable */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  getFCMPublicKey,
  subscribePushNotification,
  unsubscribePushNotification,
} from '@/apis/api/pushNotificationApis';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import firebaseConfig from '@/config/firebaseConfig';
import { pushAlertRecoil } from '@/recoil/atoms/atoms';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';

/*
 * usePushNotificationApis.ts
 * Push Notification APIs를 사용하는 커스텀 훅입니다.
 * usePushNotification : Push Notification을 사용하는 커스텀 훅입니다.
 */

export const usePushNotification = () => {
  const { confirmModal, warningModal } = useModalHook();
  const [deviceId] = useState<string | null>(localStorage.getItem('deviceId')); // 이 값이 있다는거는 알림을 켯다는 의미
  /* 웹 푸시 알람 toggle */
  const [pushAlertS, setPushAlertS] = useRecoilState(pushAlertRecoil);
  /* 웹푸시 로딩 상태 STATE */
  const [noticeLoading, setNoticeLoading] = useState<boolean>(false);
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);

  const app = environmentS === 'pc' ? initializeApp(firebaseConfig) : null;

  const messaging = app ? getMessaging(app) : null;

  /**
   * @title 알림 상태 체크 Hook
   * */
  const checkNotification = async () => {
    if (environmentS !== 'app' && !('Notification' in window)) {
      // alert('이 브라우저는 알림을 지원하지 않습니다.');
      return;
    }

    /* 현재 알림 허용 상태에 따른 분기 처리 */
    if (Notification.permission === 'default') {
      // 기본값 ( 아무런 설정 하지 않음 )
      confirmModal(confirmNotification, '푸쉬 알림', '푸쉬 알림을 허용하시겠습니까?', true);
    } else if (Notification.permission === 'denied') {
      warningModal(
        '푸쉬 알림',
        '알림 허용이 차단으로 설정되어있습니다.</br>브라우저 설정에 들어가셔서 알림 허용을 수락으로 변경 부탁드립니다.',
      );
      return;
    } else if (deviceId) {
      // 허락 상태
      await confirmNotification();
    }
  };
  /**
   * @title 푸시 알림 토글 Hook
   * */
  const handlePushAlertToggle = async (pushAlertS: boolean) => {
    if (noticeLoading) return;
    setNoticeLoading(true);
    /* 알림 off */
    if (pushAlertS) {
      await unsubscribe();
    } else {
      // 알림 on
      const pushPermission = Notification.permission;
      if (pushPermission === 'denied') {
        warningModal(
          '푸쉬 알림',
          '알림 허용이 차단으로 설정되어있습니다.</br>브라우저 설정에 들어가셔서 알림 허용을 수락으로 변경 부탁드립니다.',
        );
        return;
      }

      if (Notification.permission === 'default') {
        // 기본값 ( 아무런 설정 하지 않음 )
        confirmModal(confirmNotification, '푸쉬 알림', '푸쉬 알림을 허용하시겠습니까?', true);
      } else {
        // 허락 상태f
        await confirmNotification();
      }
    }
  };

  /**
   * @title 알림 허용 Hook
   * */
  const confirmNotification = async () => {
    if ('serviceWorker' in navigator) {
      /* 활성화 되어있는 서비스 워커가 있는지 */
      navigator.serviceWorker.getRegistration().then((registration) => {
        /* 알림 설정 팝업 허용 or 거절 */
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            startFirebaseServiceWorker();
            // }
          }
        });
      });
    } else {
      alert('이 브라우저는 서비스 워커를 지원하지 않습니다.');
    }
  };

  const getPublicKey = async (): Promise<void> => {
    return await getFCMPublicKey();
  };

  const subscribe = async (registration: ServiceWorkerRegistration): Promise<void> => {
    try {
      if (!messaging) {
        throw new Error('Messaging 서비스가 초기화되지 않았습니다.');
      }

      const vapidKey: any = await getPublicKey();
      await getToken(messaging, {
        vapidKey,
        serviceWorkerRegistration: registration,
      })
        .then((token) => {
          return {
            token: token,
            deviceId: 'undefined',
          };
        })
        .then(subscribePushNotification)
        .then((response) => {
          localStorage.setItem('deviceId', response !== null ? response : '');
          setPushAlertS((prevData) => {
            return {
              ...prevData,
              chromeAlertStatus: true,
            };
          });
          setNoticeLoading(false);
        });
    } catch (error: any) {
      setPushAlertS((prevData) => {
        return {
          ...prevData,
          chromeAlertStatus: false,
        };
      });
      console.error('Error: ', error);
    }
  };

  const unsubscribe = async () => {
    const deviceId = localStorage.getItem('deviceId');

    try {
      if (!deviceId) {
        return;
      }
      await destroyFirebaseServiceWorker().then(async () => {
        await unsubscribePushNotification({ token: 'undefined', deviceId }).then(() => {
          localStorage.removeItem('deviceId');
          setPushAlertS((prevData) => {
            return {
              ...prevData,
              chromeAlertStatus: false,
            };
          });
          setNoticeLoading(false);
        });
      });
    } catch (error: any) {
      console.error('Error: ', error);
    }
  };
  /*
   * Service Worker 제거
   * 서비스워커를 제거합니다.
   */
  const destroyFirebaseServiceWorker = async () => {
    await navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        // unsubscribe();
        registration.unregister();
      }
    });
  };

  const startFirebaseServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        const { active, installing, waiting } = registration;
        if (active) {
          await subscribe(registration);
        } else if (installing) {
          installing.addEventListener('statechange', async () => {
            if (installing.state === 'activated') {
              await subscribe(registration);
            }
          });
        } else if (waiting) {
          waiting.addEventListener('statechange', async () => {
            if (waiting.state === 'activated') {
              await subscribe(registration);
            }
          });
        }
      } catch (error) {
        console.error('Service Worker registration failed:', error);
      }
    }
  };

  return {
    getPublicKey,
    subscribe,
    unsubscribe,
    destroyFirebaseServiceWorker,
    startFirebaseServiceWorker,
    pushAlertS,
    confirmNotification,
    checkNotification,
    handlePushAlertToggle,
    noticeLoading,
    setNoticeLoading,
  };
};
