import { cancelReserveXms } from '@/apis/api/stContentApis';
import { softPhone010CacheUpdate } from '@/components/cache/softPhone010CacheUpdate';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface IUseMutationStDelete {
  deleteRequests: { prepayPayNo: string; callback: string }[];
}

export const useMutationStDelete = () => {
  const queryClient = useQueryClient();

  const { cacheUpdateDeleteReserve } = softPhone010CacheUpdate();
  const { successModal, warningModal } = useModalHook();

  // 예약발송 취소 Mutation
  const { mutate: mutationDelete, isLoading: isDeleteLoading } = useMutation(
    ({ deleteRequests }: IUseMutationStDelete) => cancelReserveXms({ deleteRequests }),
    {
      onSuccess: (data, variables) => {
        onSuccessDeleteReserve(variables);
      },
      onError: () => {
        warningModal('예약문자 취소', '예약문자 취소가 실패했습니다.', true);
      },
    },
  );

  // 예약발송 취소 성공 hooks
  const onSuccessDeleteReserve = (variables: IUseMutationStDelete) => {
    queryClient.refetchQueries(['/userPoint']);
    cacheUpdateDeleteReserve(variables);
    successModal('예약문자 취소', '예약문자가</br> 취소되었습니다.', true);
  };

  return {
    mutationDelete,
    isDeleteLoading,
  };
};
