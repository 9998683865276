import { usePushNotification } from '@/apis/hooks/usePushNotification';
import { signInRecoil } from '@/recoil/atoms/signInAtom';
import IconLogout from '@/styles/icons/IconLogout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as S from '../MobileHeader.style';
import ThemeButtonBox from '@/components/common/ThemeButtonBox';
import { removeCookie } from '@/shared/util/cookie';

const EtcListHeader = () => {
  const navigate = useNavigate();
  const [, setSignInState] = useRecoilState(signInRecoil);
  /* 웹푸시 hooks */
  const pushHooks = usePushNotification();

  const onClickLogout = () => {
    pushHooks.unsubscribe();
    removeCookie('appPw', { path: '/' });
    setSignInState(false);
    navigate('/mobile/mobileSignin');
  };

  return (
    <>
      <S.IconContainer>
        {/* 알림 아이콘 */}
        <ThemeButtonBox type="mobile" />

        {/* 로그아웃 버튼 */}
        <S.HeaderButtonIcon onClick={() => onClickLogout()}>
          <IconLogout />
        </S.HeaderButtonIcon>
      </S.IconContainer>
    </>
  );
};
export default EtcListHeader;
