import axios from 'axios';
import { instance, instanceAudio, instanceFile } from '@/shared/lib/clientAxios';
import {
  IGet015ArsTTS,
  IGet015ArsTTSWav,
  IGetSttData,
  IUpdate015JsonFile,
  IUpdate015Record,
} from '@/apis/api/types/say015Apis.types';
import { ISubscribe015 } from '@/components/hooks/customs/types/Hooks.types';

/**
 * 015번호 추천 api
 */
// export const get015ResNum = async (accountType: string) => {
//   const size = 10;
//   const response = await axios.get(
//     `${process.env.REACT_APP_SERVER_URL}/015-numbers?size=${size}&accountType=${accountType}`,
//   );
//   return response.data;
// };
export const get015ResNum = async (accountType: string) => {
  const size = 10;
  const response = await instance.get(`/015-numbers?size=${size}&accountType=${accountType}`);
  return response.data;
};

// 015번호 사용가능 확인 api
export const getConfirm015Num = async (headNumber: string, tailNumber: string) => {
  const response = await instance.get(`/015-number/${headNumber}-${tailNumber}`);
  return response.data;
};

// 회원가입 015번호 사용가능 확인 api
export const getSignUpConfirm015Num = async (headNumber: string, tailNumber: string) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/signup/015-number/${headNumber}-${tailNumber}`);
  return response.data;
};

// say015 구독 해지 post 요청
export const postCancel015Sub = async () => {
  const response = await instance.post('/say015/cancel-subscription');
  return response.data;
};

// say015 구독 철회 or 재구독 post 요청
export const post015Resub = async () => {
  const response = await instance.post('/say015/subscription');
  return response.data;
};

// 015사서함 전체리스트 get
export interface IGet015Message {
  currentPage: number;
  searchCondition?: string | undefined;
  keyword?: string | undefined;
}

export const get015Message = async ({ currentPage, searchCondition, keyword }: IGet015Message) => {
  const response = await instance.get('/say015/allMessageList', {
    params: {
      pageNumber: currentPage,
      pageSize: 10,
      searchCondition,
      keyword,
    },
  });
  return response.data;
};

// 015사서함 선택번호 메시지 리스트 get
export interface IGet015PickList {
  pageSize: number;
  pageNumber: number;
  sndPhnId: string;
  keywordValue?: string | undefined;
  keyword?: string | undefined;
  searchStartDate?: string | undefined;
  searchEndDate?: string | undefined;
}
export const get015PickList = async (item: IGet015PickList) => {
  const response = await instance.get('/say015/messageList', {
    params: {
      pageNumber: item.pageNumber,
      pageSize: item.pageSize,
      sndPhnId: item.sndPhnId,
      keywordValue: item.keywordValue,
      keyword: item.keyword,
      searchStartDate: item.searchStartDate,
      searchEndDate: item.searchEndDate,
    },
  });
  return response.data;
};

// 015 사서함 해당 item Message All Delete
export const deleteAllMessage = async (contactNumber: string[]) => {
  const response = await instance.post('/say015/deleteMessage', { contactNumber: contactNumber[0] });
  return response.data;
};

// 015 사서함 클릭시 Data get
export const get015Authority = async () => {
  const response = await instance.get('/say015/check-registration');
  return response.data;
};

// 015 사서함 JsonFile Update
export const update015JsonFile = async ({ arsData, fileName }: IUpdate015JsonFile) => {
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(arsData)], { type: 'application/json' });
  formData.append('file', blob);
  // formData.append('filename', `${say015Number}.json`);
  formData.append('filename', `${fileName}`);

  const response = await instanceFile.post('/vms/json', formData);
  return response.data;
};

// 015 사서함 안내멘트 녹음 Update
export const update015ArsRecord = async ({ formData }: IUpdate015Record) => {
  const response = await instanceAudio.post('/vms/wav', formData);

  return response.data;
};

// 015 사서함 안내멘트 텍스트 TTS GET
export const get015ArsTTS = async ({ text }: IGet015ArsTTS) => {
  // 쿠키를 요청에서 제외하기 위해 Axios의 설정을 수정합니다.
  axios.defaults.withCredentials = false;
  try {
    const response = await axios.get('https://cloud.seoultel.co.kr/google/tts', {
      params: {
        language: 'ko-KR',
        voice: 'ko-KR-Wavenet-A',
        pitch: '1.0',
        speakingRate: '1.0',
        text,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching TTS:', error);
    throw error;
  }
};

// 015 사서함 안내멘트 TTS WAV 파일 가져오기
export const get015ArsTTSWav = async ({ filename }: IGet015ArsTTSWav) => {
  const response = await instance.get(`/vms/wav/${filename}`, {
    responseType: 'blob',
  });
  return response.data;
};

// 015 ARS 안내멘트 TTS 생성, 수정 횟수 카운팅
export const postTtsData = async () => {
  const response = await instance.post('/say015/saveTTSUsageCount');
  return response.data;
};

// 015 채팅방 stt 받아오기
export const getSttData = async (data: IGetSttData) => {
  const response = await instance.get(`/vms/convert-stt/${data.filename}`, {
    params: {
      sttSeconds: data.sttSeconds,
    },
  });
  return response.data;
};

// 015 user info get api
export const get015ServiceStatus = async () => {
  const response = await instance.get('/say015/serviceStatus');
  return response.data;
};

// 015 가입 요청 post api
export const post015Permit = async (subscribe015: ISubscribe015) => {
  const response = await instance.post('/say015/join', subscribe015);
  return response.data;
};
