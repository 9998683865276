import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1em;
  height: 1em;
`;

const IconTrash: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '1.6rem'}
      viewBox="0 0 17 18"
      fill="none"
    >
      <path
        d="M2.87457 3.844V14.6245C2.87446 14.9324 2.93501 15.2372 3.05277 15.5217C3.17053 15.8061 3.34319 16.0646 3.56088 16.2823C3.77857 16.5 4.03702 16.6726 4.32147 16.7904C4.60592 16.9081 4.91078 16.9687 5.21864 16.9686H11.7814C12.4028 16.9686 12.9988 16.7217 13.4383 16.2823C13.8777 15.8428 14.1246 15.2468 14.1246 14.6254V3.84314M1 3.844H16"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68719 3.84397V2.43718C5.68719 2.25248 5.72359 2.06958 5.7943 1.89895C5.86501 1.72832 5.96864 1.5733 6.09929 1.44273C6.22993 1.31217 6.38502 1.20863 6.55569 1.13802C6.72637 1.06742 6.90928 1.03114 7.09398 1.03125H9.90584C10.0905 1.03114 10.2735 1.06742 10.4441 1.13802C10.6148 1.20863 10.7699 1.31217 10.9005 1.44273C11.0312 1.5733 11.1348 1.72832 11.2055 1.89895C11.2762 2.06958 11.3126 2.25248 11.3126 2.43718V3.84397M6.62534 13.3004V8.61481M10.3753 13.3004V8.61481"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconTrash;
