/* eslint-disable */
import React from 'react';
export interface IUploadFileHandler {
  fileState: boolean;
  file: any;
  setSendImgFileUrl: React.Dispatch<any>;
  setSendImgFile: React.Dispatch<any>;
  warningModal: (
    title?: string | undefined,
    content?: string | undefined,
    centered?: boolean | undefined,
    path?: string | undefined,
  ) => void;
}

/**
 * @title 메세지 전송 이미지 첨부 파일 업로드 Hook
 *
 * @author 정휘학
 * @since 2024.03.15
 * */
export const uploadFileHandler = async ({
  file,
  setSendImgFileUrl,
  setSendImgFile,
  warningModal,
  fileState,
}: IUploadFileHandler) => {
  const selectedFile = fileState ? file.target.files[0] : file;

  if (!selectedFile) {
    return;
  }

  const { type, name } = selectedFile;

  // 이미지와 미디어 파일 확장자 목록
  const imageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp'];
  const videoTypes = ['video/mp4', 'video/quicktime', 'video/x-msvideo']; // mp4, mov, avi
  const audioTypes = ['audio/mpeg', 'audio/wav'];

  let previewUrl: string = '';

  // 이미지 파일일 경우 미리보기 URL 생성
  if (imageTypes.includes(type)) {
    const checkValidate = await validateImage(selectedFile); // 유효성 검사 호출

    if (typeof checkValidate === 'string') {
      warningModal('이미지', checkValidate, true);
      return;
    }
    if (checkValidate instanceof Blob) {
      const blobWithFileName = new File([checkValidate], name, { type: checkValidate.type }); // Blob에 파일 이름 추가
      previewUrl = URL.createObjectURL(blobWithFileName);
      setSendImgFileUrl(previewUrl);
      setSendImgFile(blobWithFileName); // 유효한 이미지 파일을 설정
    }
  }
  // 비디오 파일일 경우 미리보기 URL 생성
  else if (videoTypes.includes(type)) {
    previewUrl = URL.createObjectURL(selectedFile);
    setSendImgFileUrl(previewUrl);
    setSendImgFile(selectedFile); // 비디오 파일을 설정
  }
  // 오디오 파일일 경우 미리보기 URL 생성
  else if (audioTypes.includes(type)) {
    previewUrl = URL.createObjectURL(selectedFile);
    setSendImgFileUrl(previewUrl);
    setSendImgFile(selectedFile); // 오디오 파일을 설정
  }
  // 기타 파일 형식일 경우
  else {
    setSendImgFileUrl('file');
    setSendImgFile(selectedFile); // 기타 파일을 설정
  }
};

export const base64ToBlob = (base64Data: string, contentType: string) => {
  // 인코딩 파일 blob 변환
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

/**
 * @title 이미지 width, height 크기 변경 유틸 Hook
 *
 * @author 정휘학
 * @since 2024.03.15
 * */
export const canvasImage = (file: any, maxWidth: number, maxHeight: number, width: number, height: number) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (!ctx) return '';
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        resolve(blob);
      }, file.type || 'image/jpeg');
    };

    img.onerror = () => {
      reject(new Error('이미지를 불러오는데 실패했습니다.'));
    };
  });
};

/**
 * @title 메세지 전송시 업로드 이미지 유효성 검사 유틸 Hook
 *
 * @author 정휘학
 * @since 2024.03.15
 * */
export const validateImage = async (file: any) => {
  return new Promise((resolve, reject) => {
    const maxWidth = 640;
    const maxHeight = 820;
    const maxSize = 500 * 1024;
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const { width, height } = img;
      URL.revokeObjectURL(img.src);

      if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
        resolve('이미지는 JPG, JPEG, PNG 형식이어야 합니다.');
      } else if (file.size > maxSize) {
        resolve('이미지 크기는 500KB 이하여야 합니다.');
      } else if (width > maxWidth || height > maxHeight) {
        const newImage = canvasImage(file, maxWidth, maxHeight, width, height);
        resolve(newImage);
      } else {
        resolve(file); // 모든 검사 통과
      }
    };

    img.onerror = () => {
      resolve('');
    };
  });
};
