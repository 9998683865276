/* eslint-disable */
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { useMutationStDelete } from '@/components/hooks/mutations/useMutationStDelete';
import { useMutationUpdateStMsgDetail } from '@/components/hooks/mutations/useMutationUpdateStMsgDetail';
import { listToggleRecoil, softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import {
  scheduleDetailParamsRecoil,
  scheduleQueryKeyRecoil,
  scheduleTableDataRecoil,
} from '@/recoil/atoms/sendResult/sendResult';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { formatDateYYYYMMDDHHmmss } from '@/shared/util/format/dateFormatUtil';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useCloseResult } from '../../useCloseResult';

export const useScheduleTable = (props: any) => {
  const queryClient = useQueryClient();
  const [selectSwitch, setSelectSwitch] = useState(false); // select 누르면 oprions 나올 스위치 버튼 역할
  const [searchSelectState, setSearcgSelectState] = useState<{ label: string; value: string }>({
    label: '연락처',
    value: 'number',
  }); // State
  const [searchInputState, setSearchInputState] = useState<string>('');
  const [scheduleDetailParamsState, setScheduleDetailParamsState] = useRecoilState(scheduleDetailParamsRecoil);
  const [searchAllValue, setSearchAllValue] = useRecoilState(scheduleQueryKeyRecoil);
  const softPhoneTopTabState = useRecoilValue(softPhoneTopTabRecoil);
  const [, setListToggleState] = useRecoilState(listToggleRecoil);
  const [checkBoxState, setCheckBoxState] = useState<{ prepayPayNo: string; callback: string }[]>([]);
  const [tableData, setTableData] = useRecoilState(scheduleTableDataRecoil);
  // 수정버튼 토글 상태 STATE
  const [editBtnS, setEditBtnS] = useState<boolean>(false);

  const closeHooks = useCloseResult();

  const dayjs = require('dayjs');

  const { warningModal } = useModalHook();

  // ? 예약전송 수정 Mutation
  const { mutationUpdateMsg, isUpdateLoading } = useMutationUpdateStMsgDetail({
    sndDttm: formatDateYYYYMMDDHHmmss(props.datePicker),
    sndMsg: props.sndMsg,
    subject: props.subject,
    prepayPayNo: scheduleDetailParamsState.prepayPayNo,
    callback: scheduleDetailParamsState.callback,
  });

  // 수정 함수
  type TMsgGb = 'SMS' | 'MMS' | 'LMS' | null;
  const onClickUpdateMsg = (msgGb: TMsgGb, detailInfo: any) => {
    // 현재 시간
    const now = dayjs();
    const nowTime = dayjs(now, 'YYYYMMDDHHmmss');
    const tenMinutesLater = Math.abs(nowTime.diff(props.datePicker, 'minute'));
    const msgSubject = props.subject === '' || props.subject === null ? '제목없음' : props.subject;

    if (tenMinutesLater <= 10) {
      return warningModal('실패', '전송 10분 전에는 수정할 수 없습니다.', true);
    }
    if (msgGb === 'SMS' && calMsgByte(props.sndMsg ?? '0') < 1) {
      return warningModal(
        '실패',
        '전송할 내용이 존재하지 않습니다.</br>전송을 원치 않으시면 "삭제" 버튼을 눌러 예약을 취소해주세요.',
        true,
      );
    }
    if (msgGb === 'LMS' && calMsgByte(props.sndMsg ?? '0') <= 90) {
      return warningModal(
        '실패',
        '예약전송 수정은 기존 형식(LMS)으로만 수정이 가능합니다.</br>SMS, MMS 형식으로 예약하려면 삭제(취소) 후 다시 예약해주세요.',
        true,
      );
    }
    if (msgGb === 'MMS' && !detailInfo.imageData) {
      return warningModal(
        '실패',
        '예약전송(MMS)의 이미지 수정을 원하시면,</br> 삭제(취소) 후 다시 예약해주세요.',
        true,
      );
    }
    mutationUpdateMsg({
      sndDttm: formatDateYYYYMMDDHHmmss(props.datePicker),
      sndMsg: props.sndMsg,
      subject: msgSubject,
      prepayPayNo: scheduleDetailParamsState.prepayPayNo,
      callback: scheduleDetailParamsState.callback,
    });
  };

  // 예약전송 삭제 Mutation`
  const { mutationDelete, isDeleteLoading } = useMutationStDelete();

  const handleDelete = ({ deleteRequests }: any) => {
    setTimeout(() => {
      const cacheData: any = queryClient.getQueryData([
        'getReserveSendList',
        searchAllValue.searchCondition,
        searchAllValue.keyword,
        softPhoneTopTabState,
      ]);
      if (cacheData?.pages) {
        setScheduleDetailParamsState({ prepayPayNo: '', callback: '' });
        setListToggleState(false);
        setCheckBoxState([]);
        const cacheContent = cacheData.pages.map((item: any) => item.content);
        let removeData: any = [];
        if (deleteRequests.length === 1) {
          removeData = cacheContent.map((arr: any, index: number) => {
            return arr.filter((f: any) => f.prepayPayNo !== deleteRequests[0].prepayPayNo);
          });
        } else {
          removeData = cacheContent.map((arr: any) => {
            return arr.filter((f: any) => !deleteRequests.some((dr: any) => dr.prepayPayNo === f.prepayPayNo));
          });
        }

        const updatedData = {
          ...cacheData,
          pages: cacheData.pages.map((p: any, pageIndex: number) => {
            return {
              ...p,
              content: removeData[pageIndex],
            };
          }),
        };
        queryClient.setQueryData(
          ['getReserveSendList', searchAllValue.searchCondition, searchAllValue.keyword, softPhoneTopTabState],
          updatedData,
        );
        setTableData(updatedData);
      }
    }, 500);
  };

  // 단일 리스트 예약 취소 함수
  const onDelete = ({ deleteRequests }: any, sndDttm: string) => {
    // 현재 시간
    const now = dayjs();
    const nowTime = dayjs(now, 'YYYYMMDDHHmmss');
    const tenMinutesLater = Math.abs(nowTime.diff(sndDttm, 'minute'));

    if (tenMinutesLater <= 10) {
      return warningModal('실패', '전송 10분 전에는 취소할 수 없습니다.', true);
    }
    mutationDelete(
      { deleteRequests },
      {
        onSuccess: () => {
          handleDelete({ deleteRequests });
          closeHooks.onClickCloseBtn();
        },
        onError: () => {},
      },
    );
  };

  // 검색 조건 STATE 변경
  const handleSearchSelect = (value: { label: string; value: string }) => {
    setSearcgSelectState(value);
    setSelectSwitch(false);
  };
  // 검색어 STATE 변경
  const handleSearchInput = (text: string) => {
    setSearchInputState(text);
  };
  // 검색
  const onClickSearch = (e: any) => {
    e.preventDefault();
    props.setSearchAllValue((prevState: any) => {
      const updatedData = { ...prevState };
      updatedData.keyword = searchInputState;
      updatedData.keywordValue = searchSelectState.value;
      return updatedData;
    });
  };
  const typeSelect = [
    {
      value: 'number',
      label: '연락처',
    },
  ];

  // ? 예약일시 수정
  const onChangeDate = (dateString: string) => {
    props.setDatePicker(dateString as string);
  };

  // 제목, 내용, 날짜가 변함에 따라 수정하기 버튼 상태변환 useEffect
  useEffect(() => {
    if (!props.detailInfo) return;
    if (
      props.detailInfo.sndDttm !== formatDateYYYYMMDDHHmmss(props.datePicker) ||
      props.detailInfo.subject !== props.subject ||
      props.detailInfo.sndMsg !== props.sndMsg
    ) {
      setEditBtnS(true);
    } else {
      setEditBtnS(false);
    }
  }, [props.sndMsg, props.subject, props.datePicker]);

  return {
    typeSelect,
    isUpdateLoading,
    searchSelectState,
    handleSearchSelect,
    selectSwitch,
    setSelectSwitch,
    searchInputState,
    handleSearchInput,
    onClickSearch,
    onClickUpdateMsg,
    onDelete,
    onChangeDate,
    editBtnS,
  };
};
