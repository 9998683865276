/* eslint-disable */

import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_AES_SECRET_KEY;

/**
 * =================
 * @title 암호화 util
 * =================
 * */
export const encrypt = (text: string) => {
  if (!secretKey) return;
  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(secretKey), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};
/**
 * =================
 * @title 복호화 util
 * =================
 * */
export const decrypt = (ciphertext: string) => {
  if (!secretKey) return;
  const decrypted = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(secretKey), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
