/*eslint-disable*/

import { ArsJsonData } from '@/apis/api/pointApi';
import { useModalHook } from '@/components/commons/modals/useModalHook';
import { use015ArsFC } from '@/components/hooks/customs/say015/say015ARS/use015ArsFC';
import { useUpdateJsonFile } from '@/components/hooks/customs/say015/say105Handle/useUpdateJsonFile';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import { activeNumberRecoil, selectedArsScenarioInfoRecoil, simpleViewArsTreeRecoil } from '@/recoil/atoms/say015Atom';
import { useArsJsonFX } from '@/widgets/ars/components/arsTree/hooks/useArsJsonFX';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

/**
 * @title 015 ARS 트리 컴포넌트 HOOKS - ArsTree.tsx
 *
 * */
export const useArsTree = () => {
  const navigate = useNavigate();
  /* 인사말 ref */
  const cardRef = useRef<HTMLDivElement>(null);
  /* ars json data state */
  const [arsData, setArsData] = useState<any>(null);
  /* ars 위젯 추가여부 */
  const [isAddS, setIsAddS] = useState<boolean>(true);
  /* 휴대폰 클릭 number */
  const [activeNumberS] = useRecoilState(activeNumberRecoil);
  /* 왼쪽 그룹을 조절 - 반응형시 필요 */
  const [arsLeftNavS, setArsLeftNavS] = useState<boolean>(false);
  /* 간편보기 사용여부 */
  const [simpleViewArsTreeS, setSimpleViewArsTreeS] = useRecoilState(simpleViewArsTreeRecoil);
  /* 선택된 ARS 시나리오 */
  const [selectedArsScenarioInfoS] = useRecoilState(selectedArsScenarioInfoRecoil);
  /* 접속환경 */
  const environmentS = useRecoilValue(environmentRecoil);

  const { warningModal } = useModalHook();

  // =======================
  // ars json 데이터 조작 HOOK
  // =======================
  const { findDataById } = useArsJsonFX({ arsData });

  // ====================
  // ARS 단순 이벤트 함수 모음
  // ====================
  const { isUpdatedIdS, handleUpdateIdState, handleAddIdState, isAddIdS, setSelectAddCardS, selectAddCardS } =
    use015ArsFC();

  // =============================
  // ARS Json 데이터 업데이트 Mutation
  // =============================
  const { mutateUpdatedJsonFile } = useUpdateJsonFile({ arsData, setArsData });

  // =======================================
  // ARS 화면이 작아질때 표출되는 우측 버튼 클릭 핸들러
  // =======================================
  const arsLeftNavClick = () => {
    setArsLeftNavS((prevState) => !prevState);
  };

  // =========================
  // ARS JSON 데이터 가져오는 API
  // =========================
  const { data, isLoading, isError, refetch } = useQuery(
    ['arsJsonData', selectedArsScenarioInfoS?.fileName],
    () => ArsJsonData({ fileName: selectedArsScenarioInfoS?.fileName as string }),
    {
      enabled: selectedArsScenarioInfoS !== null,
      onError: (error: any) => {
        console.error('API 호출 에러: ', error?.message);
      },
    },
  );

  // ===============================
  // 가져온 ARS JSON 데이터 STATE 에 SET
  // ===============================
  useEffect(() => {
    if (data && !isLoading) {
      setArsData(data);
    }
    if (!data && isLoading && !selectedArsScenarioInfoS && environmentS === 'app') {
      warningModal('인사말설정', '파일을 불러오지 못했습니다. 다시 시도해주세요.', true, '/');
      refetch();
    }
    if (!data && isLoading && !selectedArsScenarioInfoS && environmentS !== 'app') {
      navigate('/');
    }
  }, [data, isLoading]);

  // ==================
  // JsonFile 업데이트 Hook
  // ==================
  const updatedJsonFile = () => {
    mutateUpdatedJsonFile();
  };

  // =================
  // ARS 위젯 포커스
  // =================
  useEffect(() => {
    if (activeNumberS && cardRef.current) {
      if (activeNumberS === 'root') {
        cardRef.current.focus();
        cardRef.current.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
      }
    }
  }, [activeNumberS, cardRef]);

  // ========================
  // ARS Tree 간편보기 버튼 핸들러
  // ========================
  const handleSimpleView = () => {
    setSimpleViewArsTreeS((prevState) => !prevState);
  };

  return {
    arsLeftNavS,
    arsLeftNavClick,
    setIsAddS,
    activeNumberS,
    cardRef,
    findDataById,
    arsData,
    isUpdatedIdS,
    handleUpdateIdState,
    updatedJsonFile,
    isAddS,
    setArsData,
    selectAddCardS,
    setSelectAddCardS,
    handleAddIdState,
    isAddIdS,
    handleSimpleView,
    simpleViewArsTreeS,
    selectedArsScenarioInfoS,
    isError,
    isLoading,
  };
};
