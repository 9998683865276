import styled from 'styled-components';

export const AddContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  font-size: 10rem;

  display: flex;
  flex-direction: column;
  z-index: 3;
`;

export const AddHeader = styled.section`
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  padding: 0 1.6rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
`;

// 왼쪽 아이콘 컨테이너
export const HeaderLeft = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  overflow: hidden;
  line-height: normal;
  button {
    color: ${(props) => props.theme.colors.txt500};
  }
`;

//버튼일 경우 아이콘일 때
export const HeaderButtonIcon = styled.button`
  font-size: 2.4rem;
  border-radius: 100%;
  transition: background-color 0.4s ease; // 듀레이션 0.4초 추가
`;

export const CenterContainer = styled.h3`
  width: 100%;
  max-width: 65%;
  font-size: 2rem;
  white-space: nowrap;
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis;

  font-weight: 700;
  color: ${(props) => props.theme.colors.txt500};
  text-align: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* 호버 시 텍스트 확장 */
  &:hover {
    z-index: 3;
    top: 1rem;
    width: calc(100% - 10rem);
    transform: translateX(-50%);
    white-space: normal; /* 줄바꿈 허용 */
    word-wrap: break-word; /* 줄바꿈을 강제로 발생 */
    overflow: visible; /* 숨기지 않음 */
    max-width: none; /* 최대 폭 제한 해제 */
    background: ${(props) => props.theme.colors.gray100}; /* 가독성을 위한 배경색 */
    padding: 0.5rem; /* 배경 여백 */
    border-radius: 0.5rem; /* 경계선 둥글게 */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* 약간의 그림자 */
  }
`;

export const IconContainer = styled.section`
  display: flex;
  gap: 2rem;
  button {
    color: ${(props) => props.theme.colors.txt500};
  }
`;

export const HeaderButtonFont = styled.button`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt200};
  &.active {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const AddMain = styled.section`
  width: 100%;
  height: 100%;

  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const AddInput = styled.input`
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray500};
  color: ${(props) => props.theme.colors.txt500};

  ::placeholder {
    color: ${(props) => props.theme.colors.deactivation};
  }
  :focus {
    outline: 0;
  }
  font-size: 1.6rem;
`;
