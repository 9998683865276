import { IUseAddressAddColumnModal } from '@/components/hooks/customs/types/Hooks.types';
import { IAddressExcelColumModal } from '@/pages/main/components/modal/addressExcelColumModal/AddressExcelColumModal.type';
import { excelColumRecoil } from '@/recoil/atoms/addressColum';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useDownExcelTableList } from './useDownExcelTableList';
import { useModalHook } from '@/components/commons/modals/useModalHook';

/**
 * @title AddressAddColumModal.tsx Hooks
 *
 * @author 김남규
 * @since 2024.03.14
 * */
export const useAddressExcelColumModal = (props: IAddressExcelColumModal) => {
  /* 엑셀다운 header Recoil */
  const [excelColumS, setExcelColumS] = useRecoilState(excelColumRecoil);
  /* 주소록 엑셀다운 hook */
  const excelHook = useDownExcelTableList();

  const { confirmModal } = useModalHook();

  /**
   * @title 보여질 항목 모달 - 체크박스 핸들
   * Localstate에 담는 것은 변경 전, 후를 비교하기 위함
   * @author 김남규
   * @since 2024.03.20
   */
  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const itemLabel: string = e.target.name;

    // 로컬 state만 변경
    const newLocalSelectedLabel = new Set(excelColumS);

    if (newLocalSelectedLabel.has(itemLabel)) {
      newLocalSelectedLabel.delete(itemLabel);
    } else {
      newLocalSelectedLabel.add(itemLabel);
    }

    // 컴포넌트의 state에도 적용
    setExcelColumS(newLocalSelectedLabel);
  };

  /**
   * @title 보여질 항목 모달 - 서브밋 핸들 ( 확인 btn )
   *
   * @author 김남규
   * @since 2024.03.20
   */
  const handleOk = () => {
    props.setIsModalOpen(false);
    excelHook.onDownTableList();
  };

  /**
   * @title 보여질 항목 모달 - 취소 핸들 ( 취소 btn )
   * 취소시 직전 값으로 돌아갑니다.
   * @author 김남규
   * @since 2024.03.20
   */
  const handleCancel = () => {
    props.setIsModalOpen(false);
  };

  return {
    checkHandler,
    handleOk,
    handleCancel,
    excelColumS,
  };
};
