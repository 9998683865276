import styled from 'styled-components';

/* Start ChatRoom015Foot */
export const EtcChattingFoot = styled.div`
  position: relative;
  &:has(.byteText) {
    .etcChattingFootAddImageWrap {
      bottom: calc(100% + 4rem);
    }
  }
`;

export const EtcChattingTextByteText = styled.div<any>`
  position: absolute;
  top: -110%;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

export const EtcChattingSendWrap = styled.div`
  position: relative;
  padding: 1rem 1.4rem;
  background: ${(props) => props.theme.colors.white};

  @media screen and (max-width: 640px) {
    padding: 1rem 1.6rem;
  }
`;
export const EtcChattingFootAddImageWrap = styled.div`
  position: absolute;
  bottom: calc(100% + 2rem);
  left: 1rem;
  max-width: 32rem;
  .fileName {
    font-size: 1.2rem;
    background-color: ${(props) => props.theme.colors.gray300};
    padding: 1.4rem;
    border-radius: 0.5rem;
    font-weight: 700;
    word-break: break-all;
  }
  @media screen and (max-width: 1024px) {
    left: 0;
    padding: 10px;
    max-width: 48%;
  }
  @media screen and (max-width: 640px) {
    left: 16px;
  }
`;

export const EtcChattingFootAddImageHidden = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const EtcChattingFootAddImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: auto;
`;

// S.EtcChattingFootAddVideo 스타일 컴포넌트 정의
export const EtcChattingFootAddVideo = styled.video`
  object-fit: contain;
  width: 100%;
  height: auto; // 비율 유지
`;

// S.EtcChattingFootAddAudio 스타일 컴포넌트 정의
export const EtcChattingFootAddAudio = styled.audio``;

export const EtcChattingFootAddImageCloseBtn = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 2.2rem;
  height: 2.2rem;
  color: ${(props) => props.theme.colors.gray100};
  background-color: ${(props) => props.theme.colors.gray700};
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.gray100};
  cursor: pointer;
  svg {
    path {
      stroke: ${(props) => props.theme.colors.gray100};
      fill: ${(props) => props.theme.colors.gray100};
    }
  }
`;

export const EctChattingNewMsgBtn = styled.button`
  // 새로운 메시지
  position: absolute;
  left: 50%;
  bottom: calc(100% + 4rem);
  transform: translateX(-50%);
  padding: 0 24px;
  min-width: max-content;
  height: 38px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 24px -4px;
  background: ${(props) => props.theme.colors.white};
  transition: all 0.5s;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    padding: 0 12px;
  }
  @media screen and (max-width: 480px) {
    padding: 0 8px;
  }
`;

export const EctChattingNewMsgBtnText = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.txt400};
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

export const EctChattingNewMusicBtn = styled.button`
  // 새로운 음성 메시지
  position: absolute;
  left: 50%;
  bottom: calc(100% + 30px);
  transform: translateX(-50%);
  padding: 0 24px;
  height: 38px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 24px -4px;
  background: ${(props) => props.theme.colors.white};
  transition: all 0.5s;
  cursor: pointer;
`;

export const EctChattingNewMusicBtnText = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.txt500};
`;

export const EtcChattingFootGroupWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

export const EtcChattingSendInput = styled.input`
  width: calc(100% - 25px);
  max-height: 10rem;
  font-size: 1.4rem;
  font-weight: 400;
  appearance: none;
  outline: none;
  border: none;
  color: #999;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: rgb(214, 214, 220);
  }

  ::-webkit-scrollbar-thumb {
    background: #999;
  }

  &:focus {
    outline: none;
  }
`;

export const EtcChattingSendTextArea = styled.textarea`
  width: calc(100% - 2.5rem);
  max-height: 10rem;
  font-size: 1.4rem;
  padding: 0.6rem 1.5rem;
  min-height: 3.2rem;
  font-weight: 400;
  appearance: none;
  outline: none;
  border: none;
  /* border: 1px solid ${(props) => props.theme.colors.gray200}; */
  border: 0;
  background: ${(props) => props.theme.colors.gray100};
  color: ${(props) => props.theme.colors.txt500};
  resize: none;
  border-radius: 1.5rem;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-y: auto;
  ::placeholder {
    color: ${(props) => props.theme.colors.txt200};
    font-weight: 500;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: rgb(214, 214, 220);
  }

  ::-webkit-scrollbar-thumb {
    background: #999;
  }

  &:focus {
    outline: none;
  }
`;
export const EtcChattingImageAddBtn = styled.button`
  cursor: pointer;
  position: relative;
  padding: 0;
  color: ${(props) => props.theme.colors.txt400};
  height: fit-content;
`;

export const EtcChattingImageAddInput = styled.input`
  display: none;
`;

export const EtcChattingImageAddlabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  background: ${(props) => props.theme.colors.gray100};
`;

export const EtcChattingSendBtn = styled.button`
  // 전송 버튼
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${(props) => props.theme.colors.main};
  cursor: pointer;
  svg {
    width: 2.2rem;
    height: 2.1rem;
  }
  &.loadingActive {
    path:nth-of-type(1) {
      /* 0~56px */
      stroke-dasharray: 60;
      animation: loadingani 1.2s linear infinite;
    }

    path:nth-of-type(2) {
      stroke-dasharray: 60;
      animation: loadingani 1.2s linear infinite;
      animation-delay: 0.8s;
    }
  }

  @keyframes loadingani {
    to {
      stroke-dashoffset: 120;
    }
  }
`;
