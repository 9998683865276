import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 1.3em;
  height: 1em;
`;

const IconCoupon: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, fontSize, fill, stroke }: any) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
      fontSize={fontSize ?? '2.4rem'}
      viewBox="0 0 27 21"
      fill="none"
    >
      <path
        d="M9.65385 1.00001V5.22222M9.65385 7.33333V14.7222M9.65385 16.8333V20M1 20H26V14.7222C25.0385 14.3697 23.1154 13.0333 23.1154 10.5C23.1154 7.33333 26 7.33333 26 6.27778V1H1V6.27778C1.96154 6.63033 3.88462 7.96667 3.88462 10.5C3.88462 13.6667 1 13.6667 1 14.7222V20Z"
        stroke={stroke ?? 'currentColor'}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default IconCoupon;
