import React, { useEffect, useState } from 'react';
import * as S from './ToolBarIndex.styles';
import ToolBarListPage from './ToolBarListPage/ToolBarListPage';
import { useLocation } from 'react-router-dom';

const ToolBarIndex = () => {
  const location = useLocation();
  const [componentToRender, setComponentToRender] = useState<any>();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (`/${location.pathname.split('/')[1]}` === '/customer') {
        // setComponentToRender(<TooBarCustomerPage />);
      } else {
        setComponentToRender(<ToolBarListPage />);
      }
    }
  }, [location.pathname]);
  return (
    <S.ToolBarListPageContainer className="toolBarListPageContainer">{componentToRender}</S.ToolBarListPageContainer>
  );
};

export default ToolBarIndex;
