import styled from 'styled-components';

export const AddressHeadMenu = styled.div`
  .responsiveTab {
    display: none;
    height: 3.4rem;
    margin: 0 0 2rem;
  }
  .searchGroup {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    height: 3.6rem;
    margin: 0 0 1rem;
    padding: 0 1rem 0 0;
  }

  @media screen and (max-width: 1440px) {
    .selectPop {
      :last-of-type {
        ul {
          left: -1.2rem;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .responsiveTab {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 1rem;
      margin: 0 0 1rem;
      .back {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        padding: 1rem 1rem 1rem 0;
      }

      h2 {
        font-weight: 700;
        font-size: 2.4rem;
      }

      .logo {
        a {
          img {
            max-width: 100px;
            max-height: 100%;
            width: 100%;
          }
        }
      }
      button {
        span {
          transition: all 0.2s linear;
        }
        &:hover {
          span {
            width: 4rem;
            height: 0.3rem;
            background: ${(props) => props.theme.colors.main};
          }
        }
      }
    }
    .searchGroup {
      padding: 0 1rem;
      justify-content: space-between;
    }
    .searchWrap {
      width: calc(100% - 9rem);
      max-width: unset;
    }
    .addAddressbook {
      .selectBtn {
        height: 3.6rem;
        svg {
          margin-right: 1.5rem;
        }
      }
      .selectPop {
        .selectBox {
          right: 0;
          left: unset;
          max-width: 24rem;
          transform: unset;
          width: 60vw;
        }
      }
      .groupList {
        width: 60vw;
      }
      .delSelect {
        .selectBox {
          right: 0;
          left: auto;
          transform: unset;
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    .searchGroup {
      width: 100%;
    }
    .responsiveTab {
      .logo {
        svg {
          width: 12rem;
        }
      }
    }
    flex-wrap: wrap;
    padding: 0;
    .searchWrap {
      width: 100%;
    }
    .addAddressbook {
      .selectBtn {
        width: auto;
        svg {
          display: none;
        }
      }
      .delSelect {
        .selectBox {
          right: auto;
          left: -100%;
        }
      }
    }
  }
`;
export const MenuTabGroup = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

// 상단 button 스타일
export const AddAddressbook = styled.div`
  // 그룹추가, 그룹삭제, 주소록추가, 주소록삭제 박스
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const AddressCopy = styled.div`
  // 주소록 복사
  position: relative;
`;

export const AddressCopySelectWrapper = styled.div`
  // 주소록 복사 select
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 2;
  width: max-content;
  border: 1px solid #adc1f4;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  p {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 30px;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.txt500};
  }
`;

export const AddressCopySelectOptions = styled.ul`
  // 주소록 복사 옵션 박스
  max-height: 122px;
  border-top: 1px solid #d6d6dc;
  border-bottom: 1px solid #d6d6dc;
  overflow-y: auto;
  // overflow 스크롤
  ::-webkit-scrollbar {
    width: 0.4rem;
    background-color: #d6d6dc;
    border-radius: 40px;
  }
  ::-webkit-scrollbar-thumb {
    background: #98999a;
    border-radius: 40px;
  }
  li {
    border-radius: 0;
    background-color: ${(props) => props.theme.colors.white};
    &.active {
      button {
        font-weight: 600;
        background-color: ${(props) => props.theme.colors.txt200};
        color: ${(props) => props.theme.colors.txt500};
      }
    }
    button {
      padding: 0 15px;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: normal;
      border-radius: 0;
      text-align: left;
      color: ${(props) => props.theme.colors.txt500};
      cursor: pointer;
    }
  }
`;
export const AddressCopySelectBtns = styled.div`
  // 주소록 복사 select 확인 취소 버튼
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 5px 0;
  background-color: ${(props) => props.theme.colors.white};
  button {
    width: 40px;
    height: 25px;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 5px;
    &:first-of-type {
      border: 1px solid ${(props) => props.theme.colors.main};
      color: ${(props) => props.theme.colors.main};
    }
    &:last-of-type {
      border: 1px solid #98999a;
      color: #98999a;
    }
  }
`;
