/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

const AudioPauseWrap = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;
  white-space: nowrap;
  overflow: hidden;

  .wrap {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

const Bar = styled.div`
  width: 0.2rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-radius: 10rem;
  background: ${(props) => props.theme.colors.gray500};

  :nth-of-type(odd) {
    height: 0.6rem;
  }
  :nth-of-type(5n + 3) {
    height: 1.6rem;
  }
  :nth-of-type(4n + 5) {
    height: 2rem;
  }
  :nth-of-type(3n + 3) {
    height: 1.2rem;
  }
  :first-of-type {
    height: 0.4rem;
  }
`;

const AudioPause = () => {
  return (
    <AudioPauseWrap className="AudioPause">
      <div className="wrap">
        {[...Array(30)].map((_, index) => {
          return <Bar className="bar" key={index} />;
        })}
      </div>
    </AudioPauseWrap>
  );
};

export default AudioPause;
