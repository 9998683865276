import * as S from '@/components/softPhone/DisplayGroups/CustomerService/CustomerService.styles';
import React from 'react';

const MobileEtcVOC = () => {
  return (
    <>
      <S.CustomerServiceWrap className="customerService">
        <div className="serviceGroup">
          <S.CustomerService>
            <S.VocHelp
              className="vocHelp"
              onClick={() => window.open('http://www.voc.standardnetworks.co.kr/?origin=standart&code=00')}
            >
              <p className="text">도움이 필요하신가요?</p>
              <S.ArreoService>
                <h2>015 works. 고객센터</h2>
                <div className="addressBook">
                  <div className="smallGroup phoneGroup">
                    <h4>대표번호</h4>
                    <h3>015-8504-0006</h3>
                  </div>
                  <div className="smallGroup workGroup">
                    <p>
                      평일 9:00~18:00 (주말/공휴일 휴무)
                      <br />
                      (점심시간 12:00 ~ 13:00)
                    </p>
                  </div>
                  <div className="smallGroup emailGroup">
                    <h4>실시간 문자 상담</h4>
                    <h3>015-8504-0006</h3>
                  </div>
                  <div className="smallGroup emailGroup">
                    <h4>대표메일</h4>
                    <h3>help@arreo.com</h3>
                  </div>
                </div>
              </S.ArreoService>
              <div className="chat">
                <p>문의하실 내용이 있으면 클릭해주세요</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                  <path
                    d="M18.9263 10.7815C20.23 10.1672 20.2301 8.30273 18.9256 7.68932L2.99005 0.184368C1.68152 -0.431452 0.254349 0.768903 0.62612 2.17127L2.19894 8.09713L8.87067 8.09695C9.16777 8.10213 9.45095 8.22433 9.65922 8.43724C9.86749 8.65015 9.98419 8.93673 9.98418 9.23525C9.98417 9.53377 9.86746 9.82035 9.65917 10.0333C9.45089 10.2462 9.16771 10.3684 8.87061 10.3736L2.19887 10.3738L0.626539 16.3005C0.253888 17.7021 1.6818 18.9016 2.98956 18.2865L18.9263 10.7815Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </S.VocHelp>
          </S.CustomerService>
        </div>
      </S.CustomerServiceWrap>
    </>
  );
};
export default MobileEtcVOC;
