import React from 'react';
import * as S from '@/pages/main/components/etcLeftZone/ui/EtcLeftZone.styles';
import { underLine } from '@/shared/util/format/contentFormatUtil';
import { ISendResultDetailInfo } from '@/pages/main/components/etcLeftZone/sendRoom010/sendResultDetail/sendResultDetailInfo/SendResultDetailInfo.types';
import { useSendResultDetailInfo } from '@/components/hooks/customs/useSendResultDetailInfo';
import { calMsgByte } from '@/shared/util/byteCalculatorUtil';
import { formatDateBase } from '@/shared/util/format/dateFormatUtil';
import { formatPhoneNumber } from '@/shared/util/format/phoneNumberFormatUtil';

/**
 * @title SendResultDetail.tsx 컴포넌트의 상단 상세 정보 표출 컴포넌트
 *
 * @author 정휘학
 * @since 2024.03.19
 * */
const SendResultDetailInfo = ({ detailInfo, pageType }: ISendResultDetailInfo) => {
  /* Hooks */
  useSendResultDetailInfo({ detailInfo });

  return (
    <S.SendResultLeftDataView className={`sendResultLeftDataView ${pageType === 'simple' ? 'simple' : ''}`}>
      {/* 메시지 데이터 출력 */}
      <div>
        <div className="messageContent">
          {detailInfo.pages[0].imageData && (
            <img src={`data:image/jpeg;base64,${detailInfo.pages[0].imageData}`} alt="이미지" />
          )}
          <p>{underLine(detailInfo.pages[0]?.sndMsg) ?? ''}</p>
          {/* MMS Byte */}
          <span className="byteWrap">
            {detailInfo.pages[0].msgGb ?? 'SMS'} {calMsgByte(detailInfo.pages[0].sndMsg ?? '0')}Byte
          </span>
        </div>
      </div>
      {/* 전송 데이터 출력 */}
      {pageType === 'simple' ? null : (
        <dl>
          <div>
            <dt>전송 일자</dt>
            <dd>{detailInfo.pages[0]?.sndDttm ? formatDateBase(detailInfo.pages[0]?.sndDttm) : 'ㅡ'}</dd>
          </div>
          <div>
            <dt>회신 번호</dt>
            <dd>{detailInfo.pages[0]?.callback ? formatPhoneNumber(detailInfo.pages[0].callback) : 'ㅡ'}</dd>
          </div>
          <div>
            <dt>전송 건수</dt>
            <dd>{detailInfo.pages[0]?.totalCount ? detailInfo.pages[0]?.totalCount : '0'}</dd>
          </div>
          <div>
            <dt>성공 건수</dt>
            <dd>{detailInfo.pages[0]?.successCount ? detailInfo.pages[0]?.successCount : '0'}</dd>
          </div>
          <div>
            <dt>실패 건수</dt>
            <dd>{detailInfo.pages[0]?.failCnt ? detailInfo.pages[0]?.failCnt : '0'}</dd>
          </div>
          <div>
            <dt>전송중 건</dt>
            <dd>{detailInfo.pages[0]?.sendingCount ? detailInfo.pages[0]?.sendingCount : '0'}</dd>
          </div>
          <div>
            <dt>사용 금액</dt>
            <dd>
              <span>{detailInfo.pages[0]?.usedCash ?? '0'} C</span>&ensp;
              <span>{detailInfo.pages[0]?.usedPoint ?? '0'} P</span>
            </dd>
          </div>
        </dl>
      )}
    </S.SendResultLeftDataView>
  );
};

export default SendResultDetailInfo;
