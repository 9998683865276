import React from 'react';
import { IUseAddressAddColumnModal } from '@/components/hooks/customs/types/Hooks.types';
import { useRecoilState } from 'recoil';
import {
  addressColumViewLabelRecoil,
  addressColumViewValueRecoil,
  addressLargeColumViewLabelRecoil,
  addressLargeColumViewValueRecoil,
} from '@/recoil/atoms/addressColum';

/**
 * @title AddressAddColumModal.tsx Hooks
 *
 * @author 김남규
 * @since 2024.03.14
 * */
export const useAddressAddColumModal = (props: IUseAddressAddColumnModal) => {
  const processingValue =
    props.useComponent === 'main' ? addressColumViewValueRecoil : addressLargeColumViewValueRecoil;
  const processingLabel =
    props.useComponent === 'main' ? addressColumViewLabelRecoil : addressLargeColumViewLabelRecoil;
  /* 보여질 항목 value STATE */
  const [localSelectedValue, setLocalSelectedValue] = useRecoilState(processingValue);
  /* 보여질 항목 label STATE */
  const [localSelectedLabel, setLocalSelectedLabel] = useRecoilState(processingLabel);

  /**
   * @title 보여질 항목 모달 - 체크박스 핸들
   * Localstate에 담는 것은 변경 전, 후를 비교하기 위함
   * @author 김남규
   * @since 2024.03.20
   */
  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const itemLabel: string = e.target.name;
    const itemValue: string = e.target.value;

    // 로컬 state만 변경
    const newLocalSelectedLabel = new Set(localSelectedLabel);
    const newLocalSelectedValue = new Set(localSelectedValue);

    if (newLocalSelectedLabel.has(itemLabel)) {
      newLocalSelectedLabel.delete(itemLabel);
      newLocalSelectedValue.delete(itemValue);
    } else {
      newLocalSelectedLabel.add(itemLabel);
      newLocalSelectedValue.add(itemValue);
    }

    // 컴포넌트의 state에도 적용
    setLocalSelectedLabel(newLocalSelectedLabel);
    setLocalSelectedValue(newLocalSelectedValue);
    if (props.useComponent === 'main') {
      localStorage.setItem('addressColumViewLabel', JSON.stringify(Array.from(newLocalSelectedLabel)));
      localStorage.setItem('addressColumViewValue', JSON.stringify(Array.from(newLocalSelectedValue)));
    }
  };

  /**
   * @title 보여질 항목 모달 - 서브밋 핸들 ( 확인 btn )
   *
   * @author 김남규
   * @since 2024.03.20
   */
  const handleOk = () => {
    props.setIsModalOpen(false);
  };

  /**
   * @title 보여질 항목 모달 - 취소 핸들 ( 취소 btn )
   * 취소시 직전 값으로 돌아갑니다.
   * @author 김남규
   * @since 2024.03.20
   */
  const handleCancel = () => {
    props.setIsModalOpen(false);
  };

  return {
    checkHandler,
    handleOk,
    handleCancel,
    localSelectedLabel,
  };
};
