import React from 'react';
import { ArsCardModalProps } from '@/components/say015/Modal/arsCards/ArsCardModal';
import { useArsRecording } from '@/components/hooks/customs/say015/say105Handle/useArsRecording';
import { use015ArsJSON } from '@/components/hooks/customs/say015/say015ARS/use015ArsJSON';
import { useRecoilState } from 'recoil';
import { simpleViewArsTreeRecoil } from '@/recoil/atoms/say015Atom';

const ArsCardRecord = ({ type, handleCancel, data, arsData, updatedJsonFile }: ArsCardModalProps) => {
  const [simpleViewArsTreeS] = useRecoilState(simpleViewArsTreeRecoil);
  const { handleSaveRecording, handleRecTitle, recTitleState } = useArsRecording({ data, arsData, updatedJsonFile });
  const { deleteArs } = use015ArsJSON({ arsData, data, updatedJsonFile });
  return (
    <>
      {/* 간편보기 O */}
      {simpleViewArsTreeS && (
        <div className={'simpleCard'}>
          <div className="txtTop">
            <div className="group">
              <p className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path
                    d="M8.33338 24.9994C8.9063 24.9994 9.37505 24.5306 9.37505 23.9577C9.37505 23.3848 8.9063 22.916 8.33338 22.916C7.76046 22.916 7.29171 23.3848 7.29171 23.9577C7.29171 24.5306 7.76046 24.9994 8.33338 24.9994ZM12.5 24.9994C13.073 24.9994 13.5417 24.5306 13.5417 23.9577C13.5417 23.3848 13.073 22.916 12.5 22.916C11.9271 22.916 11.4584 23.3848 11.4584 23.9577C11.4584 24.5306 11.9271 24.9994 12.5 24.9994ZM16.6667 24.9994C17.2396 24.9994 17.7084 24.5306 17.7084 23.9577C17.7084 23.3848 17.2396 22.916 16.6667 22.916C16.0938 22.916 15.625 23.3848 15.625 23.9577C15.625 24.5306 16.0938 24.9994 16.6667 24.9994ZM9.37505 10.416V4.16602C9.37505 2.43685 10.7709 1.04102 12.5 1.04102C14.2292 1.04102 15.625 2.43685 15.625 4.16602V10.416C15.625 12.1452 14.2292 13.541 12.5 13.541C10.7709 13.541 9.37505 12.1452 9.37505 10.416ZM18.6563 10.416C19.2917 10.416 19.7917 10.9785 19.698 11.6035C19.1875 14.7285 16.6875 17.166 13.5417 17.6243V19.791C13.5417 20.3639 13.073 20.8327 12.5 20.8327C11.9271 20.8327 11.4584 20.3639 11.4584 19.791V17.6243C8.31255 17.166 5.81255 14.7285 5.30213 11.6035C5.19796 10.9785 5.70838 10.416 6.3438 10.416C6.85421 10.416 7.2813 10.791 7.36463 11.3014C7.79171 13.7598 9.92713 15.6243 12.5 15.6243C15.073 15.6243 17.2084 13.7598 17.6355 11.3014C17.7188 10.791 18.1459 10.416 18.6563 10.416Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
              {type === 'update' && <h3 className="txt">음성녹음 받기</h3>}
            </div>
            {type === 'update' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
          {type !== 'view' && <div className="list" />}
        </div>
      )}
      {/* 간편보기 X */}
      {!simpleViewArsTreeS && (
        <div className={'card noList'}>
          <div className="txtTop">
            <div className="group">
              {type === 'view' && (
                <button className="grab">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.33333 1.66667C3.33333 2.58712 2.58712 3.33333 1.66667 3.33333C0.746208 3.33333 0 2.58712 0 1.66667C0 0.746192 0.746208 0 1.66667 0C2.58712 0 3.33333 0.746192 3.33333 1.66667ZM1.66667 9.16667C2.58712 9.16667 3.33333 8.42046 3.33333 7.5C3.33333 6.57954 2.58712 5.83333 1.66667 5.83333C0.746208 5.83333 0 6.57954 0 7.5C0 8.42046 0.746208 9.16667 1.66667 9.16667ZM1.66667 15C2.58712 15 3.33333 14.2538 3.33333 13.3333C3.33333 12.4129 2.58712 11.6667 1.66667 11.6667C0.746208 11.6667 0 12.4129 0 13.3333C0 14.2538 0.746208 15 1.66667 15Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.66732 1.66667C9.66732 2.58712 8.92111 3.33333 8.00065 3.33333C7.08019 3.33333 6.33398 2.58712 6.33398 1.66667C6.33398 0.746192 7.08019 0 8.00065 0C8.92111 0 9.66732 0.746192 9.66732 1.66667ZM8.00065 9.16667C8.92111 9.16667 9.66732 8.42046 9.66732 7.5C9.66732 6.57954 8.92111 5.83333 8.00065 5.83333C7.08019 5.83333 6.33398 6.57954 6.33398 7.5C6.33398 8.42046 7.08019 9.16667 8.00065 9.16667ZM8.00065 15C8.92111 15 9.66732 14.2538 9.66732 13.3333C9.66732 12.4129 8.92111 11.6667 8.00065 11.6667C7.08019 11.6667 6.33398 12.4129 6.33398 13.3333C6.33398 14.2538 7.08019 15 8.00065 15Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
              <p className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path
                    d="M8.33338 24.9994C8.9063 24.9994 9.37505 24.5306 9.37505 23.9577C9.37505 23.3848 8.9063 22.916 8.33338 22.916C7.76046 22.916 7.29171 23.3848 7.29171 23.9577C7.29171 24.5306 7.76046 24.9994 8.33338 24.9994ZM12.5 24.9994C13.073 24.9994 13.5417 24.5306 13.5417 23.9577C13.5417 23.3848 13.073 22.916 12.5 22.916C11.9271 22.916 11.4584 23.3848 11.4584 23.9577C11.4584 24.5306 11.9271 24.9994 12.5 24.9994ZM16.6667 24.9994C17.2396 24.9994 17.7084 24.5306 17.7084 23.9577C17.7084 23.3848 17.2396 22.916 16.6667 22.916C16.0938 22.916 15.625 23.3848 15.625 23.9577C15.625 24.5306 16.0938 24.9994 16.6667 24.9994ZM9.37505 10.416V4.16602C9.37505 2.43685 10.7709 1.04102 12.5 1.04102C14.2292 1.04102 15.625 2.43685 15.625 4.16602V10.416C15.625 12.1452 14.2292 13.541 12.5 13.541C10.7709 13.541 9.37505 12.1452 9.37505 10.416ZM18.6563 10.416C19.2917 10.416 19.7917 10.9785 19.698 11.6035C19.1875 14.7285 16.6875 17.166 13.5417 17.6243V19.791C13.5417 20.3639 13.073 20.8327 12.5 20.8327C11.9271 20.8327 11.4584 20.3639 11.4584 19.791V17.6243C8.31255 17.166 5.81255 14.7285 5.30213 11.6035C5.19796 10.9785 5.70838 10.416 6.3438 10.416C6.85421 10.416 7.2813 10.791 7.36463 11.3014C7.79171 13.7598 9.92713 15.6243 12.5 15.6243C15.073 15.6243 17.2084 13.7598 17.6355 11.3014C17.7188 10.791 18.1459 10.416 18.6563 10.416Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
              {/* 문자 카드 제목 */}
              {type === 'update' || type === 'add' ? (
                <div>
                  <input
                    placeholder={type === 'add' ? '음성녹음 받기' : '16자 이내로 입력해주세요'}
                    value={recTitleState}
                    onChange={handleRecTitle}
                    maxLength={16}
                  />
                </div>
              ) : (
                <h3 className="txt">{recTitleState ? recTitleState : '음성녹음 받기'}</h3>
              )}
            </div>
            {type !== 'add' && (
              <button className="del" onClick={deleteArs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path
                    d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 5.625L5.625 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.625 5.625L9.375 9.375"
                    stroke="currentColor"
                    strokeWidth="1.62805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
          <div className="list" />
        </div>
      )}
      {/* 하단 버튼 */}
      {type !== 'view' && (
        <div className="btnGroup">
          <button className="cancelBtn" onClick={handleCancel}>
            취소
          </button>
          <button
            className="saveBtn"
            onClick={() => {
              if (handleCancel) handleCancel();
              handleSaveRecording();
            }}
          >
            저장
          </button>
        </div>
      )}
    </>
  );
};
export default ArsCardRecord;
