/* eslint-disable */
import React, { useState } from 'react';
import * as S from './QA.styles';
import * as SQ from '../CustomerStyles/SelectQA.styles';
import Pagination03Index from '../../../components/common/paginations/Pagination03/Pagination03.index';
import { v4 as uuidv4 } from 'uuid';

import { useQA } from '@/components/hooks/customs/customer/qa/useQA';
import { formatContentUtil } from '@/shared/util/format/contentFormatUtil';
import { theme } from '@/styles/theme';
import IconArrowupTwo from '@/styles/icons/IconArrowupTwo';

const QA = () => {
  /* 퍼블 */
  const [classifyTabAct, setClassifyTabAct] = useState(false);
  const [rightIsActive, setRightIsActive] = useState(false);
  const [bulletinActive, setBulletinActive] = useState<number>(0);

  const toggleClassifyTab = () => {
    setClassifyTabAct(!classifyTabAct);
  };

  const toggleRightActive = () => {
    setRightIsActive(!rightIsActive);
  };

  const toggleBulletin = (id: number) => {
    setBulletinActive((prevState: number) => {
      if (prevState === id) return 0;
      return id;
    });
  };

  /* 개발 */
  const hooks = useQA();
  return (
    <S.QAContainer>
      <SQ.SelectQA>
        <h2>자주 묻는 질문</h2>
        <SQ.SelectTop className="selectTop flexBox-ajs">
          <SQ.flexButtonBox className="leftGroup buttonGroup">
            <div className={`classifyTab top ${classifyTabAct ? 'active' : ''}`}>
              {hooks.categoryItems.map((item) => (
                <button
                  className={`${hooks.categoryState.value === item.value ? 'active' : ''}`}
                  key={item.value}
                  onClick={() => hooks.handleCategoryValue(item)}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </SQ.flexButtonBox>
          <div className="flexBox rightGroup">
            <SQ.SelectBox>
              <div className={`classifyTab ${rightIsActive ? 'active' : ''}`}>
                <button onClick={toggleRightActive}>
                  <span>{hooks.keywordValue.label}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="6" viewBox="0 0 14 6" fill="none">
                    <path d="M12.9144 0.156925L7.44885 4.76826C7.32959 4.8694 7.16862 4.92613 7.00087 4.92613C6.83312 4.92613 6.67214 4.8694 6.55289 4.76826L1.08615 0.157925C0.965866 0.057316 0.804471 0.00100048 0.636419 0.00100048C0.468366 0.00100048 0.306971 0.057316 0.18669 0.157925C0.127602 0.207306 0.0806439 0.266326 0.0485811 0.331507C0.0165183 0.396689 0 0.466713 0 0.537453C0 0.608192 0.0165183 0.678216 0.0485811 0.743398C0.0806439 0.80858 0.127602 0.8676 0.18669 0.916981L5.6511 5.52732C6.01102 5.83027 6.49529 6 6.9997 6C7.50412 6 7.98838 5.83027 8.3483 5.52732L13.8127 0.916981C13.872 0.867584 13.9191 0.808502 13.9513 0.743228C13.9834 0.677954 14 0.607813 14 0.536953C14 0.466093 13.9834 0.395951 13.9513 0.330677C13.9191 0.265403 13.872 0.206321 13.8127 0.156925C13.6924 0.056316 13.531 0 13.363 0C13.1949 0 13.0335 0.056316 12.9133 0.156925" />
                  </svg>
                </button>
                <ul className="selectBox">
                  {hooks.keywordValueItems.map((item) => (
                    <li key={item.value}>
                      <button
                        onClick={() => {
                          hooks.handleKeywordValue(item);
                          toggleRightActive();
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </SQ.SelectBox>
            <SQ.SearchBox>
              <form>
                <input type="text" placeholder="SEARCH" onChange={hooks.handleKeyword} />
                <button type={'submit'} className="searchIcon" onClick={hooks.handleSearch}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path
                      d="M6.80418 1.70017C5.45074 1.70017 4.15274 2.23785 3.19572 3.19493C2.2387 4.15201 1.70105 5.45008 1.70105 6.8036C1.70105 8.15711 2.2387 9.45519 3.19572 10.4123C4.15274 11.3693 5.45074 11.907 6.80418 11.907C8.15762 11.907 9.45562 11.3693 10.4126 10.4123C11.3697 9.45519 11.9073 8.15711 11.9073 6.8036C11.9073 5.45008 11.3697 4.15201 10.4126 3.19493C9.45562 2.23785 8.15762 1.70017 6.80418 1.70017ZM4.20296e-08 6.8036C0.000155072 5.7207 0.258744 4.65348 0.754277 3.69063C1.24981 2.72777 1.96797 1.8971 2.84908 1.26764C3.7302 0.638181 4.7488 0.228114 5.82026 0.0715213C6.89171 -0.0850715 7.98506 0.0163317 9.00945 0.367304C10.0338 0.718275 10.9597 1.30868 11.71 2.08945C12.4603 2.87022 13.0135 3.8188 13.3235 4.85636C13.6335 5.89393 13.6915 6.99051 13.4925 8.05496C13.2935 9.11942 12.8434 10.121 12.1795 10.9765L16.7612 15.5585C16.9162 15.7189 17.0019 15.9338 17 16.1568C16.998 16.3798 16.9086 16.5932 16.7509 16.7509C16.5932 16.9086 16.3799 16.998 16.1569 17C15.9339 17.0019 15.719 16.9162 15.5586 16.7612L10.9768 12.1792C9.97107 12.9601 8.76636 13.4431 7.49971 13.5733C6.23306 13.7034 4.95531 13.4756 3.81174 12.9155C2.66818 12.3555 1.70469 11.4858 1.03085 10.4053C0.357015 9.32487 -0.000141459 8.07699 4.20296e-08 6.8036Z"
                      fill="#ABABAD"
                    />
                  </svg>
                </button>
              </form>
            </SQ.SearchBox>
          </div>
        </SQ.SelectTop>
        <SQ.BulletinBoard>
          <ol>
            {hooks.qaListData
              ? hooks.qaListData?.content?.map((list) => (
                  <li className={`bulletin ${bulletinActive === list.artId ? 'active' : ''}`} key={list.artId}>
                    <div className="subjectGroup" role="button" onClick={() => toggleBulletin(list.artId)} tabIndex={0}>
                      <h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                          <path d="M10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0ZM10.5 14C10.2348 14 9.98043 14.1054 9.79289 14.2929C9.60536 14.4804 9.5 14.7348 9.5 15C9.5 15.2652 9.60536 15.5196 9.79289 15.7071C9.98043 15.8946 10.2348 16 10.5 16C10.7652 16 11.0196 15.8946 11.2071 15.7071C11.3946 15.5196 11.5 15.2652 11.5 15C11.5 14.7348 11.3946 14.4804 11.2071 14.2929C11.0196 14.1054 10.7652 14 10.5 14ZM10.5 4.5C9.53859 4.5 8.61656 4.88192 7.93674 5.56174C7.25692 6.24156 6.875 7.16359 6.875 8.125C6.875 8.39022 6.98036 8.64457 7.16789 8.83211C7.35543 9.01964 7.60978 9.125 7.875 9.125C8.14022 9.125 8.39457 9.01964 8.58211 8.83211C8.76964 8.64457 8.875 8.39022 8.875 8.125C8.87533 7.83004 8.95594 7.54072 9.10818 7.28809C9.26043 7.03545 9.47857 6.82902 9.7392 6.69092C9.99984 6.55282 10.2932 6.48827 10.5877 6.50419C10.8822 6.52011 11.1669 6.61589 11.4111 6.78127C11.6553 6.94666 11.85 7.1754 11.9741 7.44297C12.0982 7.71054 12.1472 8.00686 12.1157 8.30014C12.0843 8.59342 11.9736 8.87261 11.7955 9.10777C11.6175 9.34292 11.3788 9.52518 11.105 9.635C10.429 9.905 9.5 10.597 9.5 11.75V12C9.5 12.2652 9.60536 12.5196 9.79289 12.7071C9.98043 12.8946 10.2348 13 10.5 13C10.7652 13 11.0196 12.8946 11.2071 12.7071C11.3946 12.5196 11.5 12.2652 11.5 12C11.5 11.756 11.55 11.634 11.761 11.53L11.848 11.49C12.6288 11.1759 13.276 10.6 13.6787 9.86092C14.0814 9.12188 14.2145 8.26578 14.0551 7.43938C13.8958 6.61299 13.4539 5.86776 12.8052 5.33147C12.1566 4.79518 11.3416 4.50122 10.5 4.5Z" />
                        </svg>
                      </h2>
                      <div className="subject">
                        <p>{list.title}</p>
                      </div>
                      <div className="dateGroup">
                        <span className="arrowBtn">
                          <IconArrowupTwo />
                        </span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <p dangerouslySetInnerHTML={{ __html: formatContentUtil(list.content) }} />
                      </li>
                    </ul>
                  </li>
                ))
              : Array(10)
                  .fill(1)
                  .map(() => (
                    <li className={`bulletin ${bulletinActive ? 'active' : ''}`} key={uuidv4()}>
                      <div className="subjectGroup" role="button" tabIndex={0}>
                        <h2>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <path
                              d="M10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0ZM10.5 14C10.2348 14 9.98043 14.1054 9.79289 14.2929C9.60536 14.4804 9.5 14.7348 9.5 15C9.5 15.2652 9.60536 15.5196 9.79289 15.7071C9.98043 15.8946 10.2348 16 10.5 16C10.7652 16 11.0196 15.8946 11.2071 15.7071C11.3946 15.5196 11.5 15.2652 11.5 15C11.5 14.7348 11.3946 14.4804 11.2071 14.2929C11.0196 14.1054 10.7652 14 10.5 14ZM10.5 4.5C9.53859 4.5 8.61656 4.88192 7.93674 5.56174C7.25692 6.24156 6.875 7.16359 6.875 8.125C6.875 8.39022 6.98036 8.64457 7.16789 8.83211C7.35543 9.01964 7.60978 9.125 7.875 9.125C8.14022 9.125 8.39457 9.01964 8.58211 8.83211C8.76964 8.64457 8.875 8.39022 8.875 8.125C8.87533 7.83004 8.95594 7.54072 9.10818 7.28809C9.26043 7.03545 9.47857 6.82902 9.7392 6.69092C9.99984 6.55282 10.2932 6.48827 10.5877 6.50419C10.8822 6.52011 11.1669 6.61589 11.4111 6.78127C11.6553 6.94666 11.85 7.1754 11.9741 7.44297C12.0982 7.71054 12.1472 8.00686 12.1157 8.30014C12.0843 8.59342 11.9736 8.87261 11.7955 9.10777C11.6175 9.34292 11.3788 9.52518 11.105 9.635C10.429 9.905 9.5 10.597 9.5 11.75V12C9.5 12.2652 9.60536 12.5196 9.79289 12.7071C9.98043 12.8946 10.2348 13 10.5 13C10.7652 13 11.0196 12.8946 11.2071 12.7071C11.3946 12.5196 11.5 12.2652 11.5 12C11.5 11.756 11.55 11.634 11.761 11.53L11.848 11.49C12.6288 11.1759 13.276 10.6 13.6787 9.86092C14.0814 9.12188 14.2145 8.26578 14.0551 7.43938C13.8958 6.61299 13.4539 5.86776 12.8052 5.33147C12.1566 4.79518 11.3416 4.50122 10.5 4.5Z"
                              fill="#D6D6DC"
                            />
                          </svg>
                        </h2>
                        <div className="subject">
                          <p></p>
                        </div>
                        <div className="dateGroup">
                          <p className="date"></p>
                          <span className="arrowBtn">
                            <IconArrowupTwo />
                          </span>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <p></p>
                        </li>
                      </ul>
                    </li>
                  ))}
          </ol>
        </SQ.BulletinBoard>
        {/* 페이지 넘어가면 버튼생김 */}
        <SQ.Pagination>
          <div className="paginationWrap">
            <Pagination03Index
              pageSize={10}
              count={hooks.totalElements}
              activePage={hooks.activePage}
              setActivePage={hooks.setActivePage}
              eventHook={hooks.handlePagination}
              setStartPage={hooks.setStartPage}
              startPage={hooks.startPage}
            />
          </div>
        </SQ.Pagination>
      </SQ.SelectQA>
    </S.QAContainer>
  );
};
export default QA;
