import { useQueryClient } from '@tanstack/react-query';
import { fetchMutation } from '@/components/hooks/mutations/fetchMutation';

export type ContactsBuddyNameApiParamsType = {
  keyCommNo: string;
};
export type ContactsBuddyNameApiResponseType = {
  buddyNm: string;
  buddySeqNo: number;
  groupNm: string;
  groupSeqNo: number;
};
/**
 * @title 소프트폰 mutations
 * */
export const useSoftPhoneMutations = () => {
  const queryClient = useQueryClient();

  // ===============================================================
  // 소프트폰 수신인 추가 api ( 추가한 번호가 주소록에 있을 경우 buddyNm 가져오기 )
  // ===============================================================
  const onSuccessToContactsBuddyName = () => {};
  const { mutateAsync: contactsBuddyNameM } = fetchMutation<
    ContactsBuddyNameApiParamsType,
    ContactsBuddyNameApiResponseType
  >({
    method: 'GET',
    mutationKey: ['/contacts/buddy-name'],
    onSuccess: onSuccessToContactsBuddyName,
  });

  return {
    contactsBuddyNameM,
  };
};
