import Loader from '@/components/common/Loader';
import { environmentRecoil } from '@/recoil/atoms/MobileRecoil/MobileRecoil';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * @title 나이스 인증 팝업창에서 encode 가져오는 컴포넌트
 * */
const AuthComponent = () => {
  /* 클라이언트 접속 환경 RECOIL */
  const environmentS = useRecoilValue(environmentRecoil);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const sEncodeData = query.get('encodeData');

  useEffect(() => {
    const prevPage = localStorage.getItem('prevPage');
    if (sEncodeData) {
      if (environmentS !== 'app') {
        // 데스크탑 환경: 팝업에서 postMessage로 처리
        if (window.opener) {
          window.opener.postMessage({ sEncodeData }, window.location.origin);
          window.close(); // 데이터를 성공적으로 보냈다면 팝업 종료
        } else {
          console.error('window.opener is null. 팝업 데이터 전송 실패');
          window.close();
        }
      } else {
        // 모바일 환경: 리다이렉션된 페이지에서 처리
        navigate(prevPage as string, { state: { sEncodeData } });
        localStorage.removeItem('prevPage');
      }
    }
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Loader backgroundToggle />
    </div>
  );
};

export default AuthComponent;
