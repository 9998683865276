import React from 'react';

import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';

interface ILoaderBox {
  backgroundToggle: boolean;
}

const LoaderBox = styled.div<ILoaderBox>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => (props.backgroundToggle ? `rgba(0,0,0,0.1);` : ``)};
  z-index: 9999;
`;

interface ILoader {
  backgroundToggle: boolean;
}

const Loader = ({ backgroundToggle }: ILoader) => {
  return (
    <LoaderBox backgroundToggle={backgroundToggle}>
      <TailSpin
        height="140"
        width="140"
        color="#a1a1a1"
        // secondaryColor="#a1a1a1"
        // radius="12.5"
        // ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </LoaderBox>
  );
};
export default Loader;
