import { mainTableWidthRecoil } from '@/recoil/atoms/atoms';
import { leftZoneComponentRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import { msgResultToggleRecoil } from '@/recoil/atoms/say015Atom';
import { resultDetailParamsRecoil, scheduleDetailParamsRecoil } from '@/recoil/atoms/sendResult/sendResult';
import { useSetRecoilState } from 'recoil';

// 전송결과 상세페이지 toggle off
export const useCloseResult = () => {
  // 페이지 on 했을때 담았던 recoil값 초기화 위해 호출
  const leftZoneComponentS = useSetRecoilState(leftZoneComponentRecoil);
  const setResultDetailParamsS = useSetRecoilState(resultDetailParamsRecoil);
  const setSscheduleDetailParamsS = useSetRecoilState(scheduleDetailParamsRecoil);
  /* 010 전송결과 페이지 토글 Recoil */
  const setMsgResultToggleS = useSetRecoilState(msgResultToggleRecoil);
  /* 메인 주소록 테이블 width  */
  const setMainTableWidth = useSetRecoilState(mainTableWidthRecoil);

  const onClickCloseBtn = () => {
    setMainTableWidth(0);
    leftZoneComponentS('address');
    setMsgResultToggleS(false);
    setResultDetailParamsS({ prepayPayNo: '', callback: '' });
    setSscheduleDetailParamsS({ prepayPayNo: '', callback: '' });
  };

  return { onClickCloseBtn };
};
