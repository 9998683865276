import ATitle from '@/components/Atom/ATitle';
import BaseButton from '@/components/Atom/BaseButton';
import Loader from '@/components/common/Loader';
import { useMessageResult } from '@/components/hooks/customs/charge/useMessageResult';
import { useMessageResultDetail } from '@/components/hooks/customs/charge/useMessageResultDetail';
import ChargeDateSearch from '@/components/Molecules/Charge/ChargeDateSearch';
import BaseTable from '@/components/Organism/Table';
import React from 'react';
import Paginations01Index from '../../../common/paginations/Pagination02/Pagination02.index';
import * as S from './MobileEtcMessageResult.style';

const MobileEtcMessageResult = () => {
  const {
    thead,
    totalPage,
    handlePageChange,
    activePage,
    setActivePage,
    startPage,
    setStartPage,
    currentPage,
    result010Data,
    selectedValue,
    disabledDate,
    setSearchSelectValue,
    setSearchDates,
    setCurrentPage,
    isSuccess,
    excelDownload,
    isLoading,
    setSelectMode,
  } = useMessageResult();

  const detailHooks = useMessageResultDetail();

  return (
    <S.MessageResult>
      <ATitle type="sub" text="전송결과" />
      <ChargeDateSearch
        chargeOptionsProp={selectedValue}
        name1="전송결과"
        disabledDate={disabledDate}
        setSearchSelectValue={setSearchSelectValue}
        setSearchDates={setSearchDates}
        setStartPage={setStartPage}
        setActivePage={setActivePage}
        setCurrentPage={setCurrentPage}
        setSelectMode={setSelectMode}
      />
      <div className="table">
        <BaseTable
          name="message"
          type="line"
          thead={thead}
          tbody={result010Data}
          onclick={detailHooks.onClickMessage}
        />
        {/* {isSuccess || (isLoading && <Loader />)} */}
        {isLoading && <Loader backgroundToggle />}
      </div>
      <S.TableFoot>
        <Paginations01Index
          dataCount={totalPage}
          startPage={startPage}
          setStartPage={setStartPage}
          activePage={activePage}
          setActivePage={setActivePage}
          eventHook={handlePageChange}
          pageSize={10}
        />

        <BaseButton width="10rem" fontSize="1.4rem" padding=".6rem 1rem" fontWeight="bold" onClick={excelDownload}>
          엑셀 다운
        </BaseButton>
      </S.TableFoot>
      <p style={{ fontSize: '1.2rem' }}>전송결과는 전월, 당월만 검색이 가능하며 이전 자료는 삭제됩니다. </p>
    </S.MessageResult>
  );
};
export default MobileEtcMessageResult;
