import { listToggleRecoil, softPhoneTopTabRecoil } from '@/recoil/atoms/router/mainRouterRecoil';
import {
  resultDetailParamsRecoil,
  scheduleDetailParamsRecoil,
  scheduleQueryKeyRecoil,
} from '@/recoil/atoms/sendResult/sendResult';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IUseMutationUpdateStMsgDetail } from '../hooks/mutations/useMutationUpdateStMsgDetail';
import { IUseMutationStDelete } from '../hooks/mutations/useMutationStDelete';

export const softPhone010CacheUpdate = () => {
  const queryClient = useQueryClient();

  /* 소프트폰 예약 전송 리스트 queryKey Recoil */
  const softPhoneTopTabS = useRecoilValue(softPhoneTopTabRecoil);
  const searchAllValue = useRecoilValue(scheduleQueryKeyRecoil);

  const setListToggleState = useSetRecoilState(listToggleRecoil);
  const setScheduleDetailParamsState = useSetRecoilState(scheduleDetailParamsRecoil);
  const setResultDetailParamsState = useSetRecoilState(resultDetailParamsRecoil);

  /* 소프트폰 예약/전송 리스트 현재 캐싱 데이터 */
  const prevData: any = queryClient.getQueryData(['/sendList', searchAllValue.keyword, softPhoneTopTabS]);

  // 예약전송 리스트 추가 cache update
  const handleMsgListCacheUpdate = (data: any) => {
    const dataFomat = {
      prepayPayNo: data.prepayPayNo,
      sndDttm: data.sndDttm,
      callback: data.callback,
      subject: data.subject,
      sndMsg: data.sndMsg,
      sentGb: data.sentGb,
      imageExists: data.imageExists,
      rcvInfo: data.rcvInfo,
    };
    const newPages = prevData.pages.map((page: any, index: number) => {
      if (index === 0) {
        return {
          ...page,
          content: [dataFomat, ...page.content],
        };
      }
      return {
        ...page,
        content: page.content.filter((item: any) => item.prepayPayNo !== dataFomat.prepayPayNo),
      };
    });
    const newData = { ...prevData, pages: newPages };
    queryClient.setQueryData(['/sendList', searchAllValue.keyword, softPhoneTopTabS], newData);
  };

  // ==============================
  // 010 전송결과(예약전송) 리스트 수정 cache update hook
  // ==============================
  const cacheUpdateEditReserve = (variables: IUseMutationUpdateStMsgDetail, sndDttm: string) => {
    queryClient.refetchQueries(['getReserveSendDetail']);
    queryClient.refetchQueries(['getReserveSendList']);
    const newPages = prevData.pages.map((page: any) => {
      return {
        ...page,
        content: page.content.map((item: any) => {
          if (item.prepayPayNo === variables.prepayPayNo) {
            return { ...item, sndDttm, sndMsg: variables.sndMsg, subject: variables.subject };
          }
          return item;
        }),
      };
    });
    const newData = { ...prevData, pages: newPages };
    queryClient.setQueryData(['/sendList', searchAllValue.keyword, softPhoneTopTabS], newData);
  };

  // ==============================
  // 010 전송내역(예약발송) 리스트 삭제 cache update hook
  // ==============================
  const cacheUpdateDeleteReserve = (variables: IUseMutationStDelete) => {
    if (prevData.pages) {
      setScheduleDetailParamsState({ prepayPayNo: '', callback: '' });
      setResultDetailParamsState({ prepayPayNo: '', callback: '' });
      setListToggleState(false);
    }

    const newPages = prevData.pages.map((page: any) => {
      return {
        ...page,
        content: page.content.filter((item: any) => {
          return item.prepayPayNo !== variables.deleteRequests[0].prepayPayNo;
        }),
      };
    });
    const newData = { ...prevData, pages: newPages };
    queryClient.setQueryData(['/sendList', searchAllValue.keyword, softPhoneTopTabS], newData);
  };

  return { handleMsgListCacheUpdate, cacheUpdateEditReserve, cacheUpdateDeleteReserve };
};
